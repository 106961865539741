'use strict';

/*@ngInject*/
function EvaluationCtrl($scope, Collection, FormModal, ErrorModal, $translate) {
	let catalogs = $scope.catalogs;
	var evaluation = $scope.catalogs.evaluation = this;

	$scope.$watch('catalogs.elementSelectedVersion', function(element) {
		if (!element) {
			evaluation.collection = Collection.create([]);
		} else {
			if (!element.evaluationDefinitionVersion || element.isCopy) {
				let criteria = [];
				// If the evaluation component is a copy of another evaluation component
				// create a new EvaluationDefinition with a new EvaluationDefinitionVersion,
				// but copy the criteria. However, remove the id and the creation/modification information.
				if (element.isCopy) {
					for (var i in element.evaluationDefinitionVersion.evaluationCriterionVersions) {
						var criterionVersion = element.evaluationDefinitionVersion.evaluationCriterionVersions[i];
						delete criterionVersion.creationDate;
						delete criterionVersion.creationUser;
						delete criterionVersion.id;
						delete criterionVersion.modificationDate;
						delete criterionVersion.modificationUser;
						delete criterionVersion.objectVersion;
						criteria.push(criterionVersion);
					}
				}
				// for new elements or copy of another evaluation component
				element.evaluationDefinitionVersion = {
					name: ' ',
					version: 0,
					evaluationDefinition: {
						alsoSelfEvaluation: false,
						visibleToEmployees: false
					},
					evaluationCriterionVersions : criteria
				};
			}
			for (var i in element.evaluationDefinitionVersion.evaluationCriterionVersions) {
				var criterionVersion = element.evaluationDefinitionVersion.evaluationCriterionVersions[i];
				if (criterionVersion && criterionVersion.competenceVersion && criterionVersion.competenceVersion.competence 
					&& criterionVersion.competenceVersion.competence.currentCompetenceVersion) {
					criterionVersion.competenceVersion = criterionVersion.competenceVersion.competence.currentCompetenceVersion;
				}
				element.evaluationDefinitionVersion.evaluationCriterionVersions[i] = criterionVersion;
			}
			evaluation.collection = Collection.create(element.evaluationDefinitionVersion.evaluationCriterionVersions);
		}
		
		evaluation.options.data = evaluation.collection.data;
		evaluation.computeWeight();
	});

	evaluation.disableSave = $scope.catalogs.disableSave;

	evaluation.tableActionDisabled = function() {
		return catalogs.olderVersionSelected;
	}

	evaluation.options = {
		columnDefs: [
			{
				name: 'name',
				displayName: 'education.catalogs.evaluation.name',
				cellTemplate: '<div>{{row.entity.competenceVersion.name}} <span class="text-muted">v{{row.entity.competenceVersion.version}}</span></div>'
			},
			{
				name: 'norm',
				displayName: 'education.catalogs.evaluation.norm',
				field: 'normPercentage'
			},
			{
				name: 'weight',
				headerTemplateUrl: '/catalogs/evaluation.weight.html',
				field: 'weight'
			},
			{
				name: 'required',
				displayName: 'education.catalogs.evaluation.required',
				field: 'required',
				cellTemplate: '<i class="fa fa-check" ng-if="row.entity.required"></i>'
			},
			{
				name: 'ownScore',
				displayName: 'education.catalogs.evaluation.ownScore',
				field: 'ownScore',
				cellTemplate: '<i class="fa fa-check" ng-if="row.entity.ownScore"></i>'
			},
			{
				name: 'actions',
				width: '32px',
				headerTemplateUrl: '/catalogs/evaluation.add.html',
				cellTemplateUrl: '/catalogs/evaluation.remove.html'
			}
		],
		onRegisterApi: function(gridApi) {
			gridApi.selection.on.rowSelectionChanged(null, function(row) {
				evaluation.edit(row.entity);
			});
		}
	};
	
	evaluation.computeWeight = function() {
		if (!$scope.catalogs.element) {
			return;
		}
		
		var total = 0;
		angular.forEach(evaluation.options.data, function(criterion) {
			total += criterion.weight;
		});
		evaluation.weight = total;
		
		if (evaluation.weight != 100) {
			if (evaluation.weight > 100) {
				// if the total weight exceedes 100, then it throws and error and it removes the last added competence 
				$translate('education.catalogs.evaluation.weight.error').then(function(msg) {
					ErrorModal.open({
						titleId: ErrorModal.VALIDATION_ERROR,
						message: msg
					});
				});
				evaluation.remove(evaluation.options.data[evaluation.options.data.length - 1]);
			}
		}
	};

	evaluation.add = function() {
		// open popup
		// var competences = {};
		// var modal = FormModal.open({
		// 	windowClass: 'modal-full',
		// 	scope: {
		// 		template: require('./../competences/competences-tree.html'),
		// 		title: 'add',
		// 		competences: competences
		// 	},
		// 	actions: ['ok', 'cancel', 'close']
		// });
		// modal.result.then(function() {
		// 	var competence = competences.competence;
		// 	delete competence.currentCompetenceVersion.competence;
		// 	var child = {
		// 		competenceVersion: competence.currentCompetenceVersion,
		// 		normPercentage: 0,
		// 		weight: 0,
		// 		required: false
		// 	};
		// 	evaluation.collection.add(child);
		// });
		if (evaluation.tableActionDisabled()) {
			return;
		}

		evaluation.edit({
			normPercentage: 1,
			weight: 0,
			required: true,
			ownScore: false
		}, function(child) {
			evaluation.collection.add(child);
		});
	};
	
	evaluation.edit = function(child, callback) {
		if (evaluation.tableActionDisabled()) {
			return;
		}

		var totalWeight = evaluation.weight;
		if (child.competenceVersion) {
			// if it's an edit => substract the child's weight from the total weight
			totalWeight -= child.weight;
		}
		var instance = FormModal.open({
			scope: {
				model: angular.copy(child),
				title: child.competenceVersion ? child.competenceVersion.name : 'add',
				template: require('./evaluation-criterion-form.html'),
				isModelInvalid: function(model) {
					return angular.isUndefined(model.weight) || angular.isUndefined(model.normPercentage) || model.normPercentage <= 0 || (angular.isUndefined(model.competenceVersion) || model.competenceVersion == null) ;
				},
				allowOverflowContainer: true,
				totalWeight: totalWeight
			},
			actions: ['ok', 'cancel', 'close']
		});
		
		instance.result.then(function(result) {
			// check add a new competence or edit competence fields
			if ((child.competenceVersion == null || child.competenceVersion.name != result.competenceVersion.name || child.competenceVersion.version != result.competenceVersion.version) ||
				child.normPercentage != result.normPercentage || child.weight != result.weight || child.ownScore != result.ownScore
				|| child.required != result.required) {
				$scope.catalogs.createNewMajorVersion = true;
			}

			angular.merge(child, result);
			if (child.competenceVersion && child.competenceVersion.currentCompetenceVersion) {
				child.competenceVersion = child.competenceVersion.currentCompetenceVersion;
			}
			// Compute weight after the new competence is added to the list.
			// Otherwise, the total weight will not contain the weight of the new added competence.
			callback && callback(child);
			evaluation.computeWeight();

			evaluation.disableSave(false);
		});
	};
	
	evaluation.remove = function(child) {
		if (evaluation.tableActionDisabled()) {
			return;
		}

		$scope.catalogs.createNewMajorVersion = true;
		evaluation.collection.remove(child);
		evaluation.computeWeight();

		evaluation.disableSave(false);
	};
}

module.exports = EvaluationCtrl;