'use strict'

/*@ngInject*/
function InboxItems(DataSource) {

	var config = {
		baseUrl : '/restful/workflowServiceEndpoint/inboxItem',
	}

	var ds = new DataSource(config);

	ds.config.process = ds.config.process || {};

	ds.config.process.method = 'POST';
	ds.config.process.url = '/process';
	
	// the controller will handle the errors
	ds.config.process.silent = true;

	if (!ds.process) {
		ds.registerOperation('process');
	}

	return ds;
};

module.exports = InboxItems;