'use strict';

module.exports = {
	name: 'proteus.tasks',
	path: 'tasks.module.js',
	authRoutes: {
		'/backgroundTasks': {
			templateUrl: 'tasks/background-tasks-page.html',
			controller: 'BackgroundTasksPageCtrl',
			controllerAs: 'backgroundTasksPage'
		}
	}
};