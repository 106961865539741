'use strict'

/*@ngInject*/
function AgendaGridCtrl($scope, $rootScope, $templateCache, $log, Agenda, AgendaConstants, ModalSpinner, Common, RGBAConverter, $uibModal, FormModal, Principal, ConstraintGroups, Resources) {
	
	$log.debug('initializing...');

	var agenda = $scope.agenda;
	
	if (agenda.useOtherAgendaContext) {
		// we don't need a registration type and leave group for the other agenda
		agenda.registrationTypeSet = true;
		agenda.leaveGroupSet = true;
	}
	
	// Define the grid options.
	
	agenda.gridOptions = {
		// scrollbar options: 0 - hidden, 1 - scroll (default), 2 - when needed
		enableHorizontalScrollbar: 0,
		enableVerticalScrollbar: 2,
		virtualizationThreshold: 100,
		columnDefs: [],
		onRegisterApi: function(gridApi) {
			agenda.gridApi = gridApi;
		},
		
		// override to remove check for header
		rowTemplate: "<div ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.uid\" ui-grid-one-bind-id-grid=\"rowRenderIndex + '-' + col.uid + '-cell'\" class=\"ui-grid-cell\" ui-grid-cell></div>"
	}
	
	agenda.createColumnDefs = function(entries) {
		if (!entries) {
			entries = agenda.gridOptions.data;
		}
		
		var columnDefs = [];
		
		if (agenda.mode === AgendaConstants.MODE_MONTH) {
			
			// date column; available only for month, no need to display this on day view
			columnDefs.push({
				name: 'date',
				headerCellClass: 'hide',
				colorField: 'eventColor',
				width: 60,
				cellTemplate: require('./agenda-grid-date.html')
			});

			// work period column
			columnDefs.push({
				name: 'workPeriod',
				headerCellClass: 'hide',
				cellClass: function(grid, row, col) {
					if (row.entity.constraints && !agenda.onlyWaitingRegistrations) {
						var occupancy = 0;
						angular.forEach(row.entity.constraints, function(constraint) {
							var limit = Number.MIN_SAFE_INTEGER;
							if (constraint.available != null && constraint.available != 0) {
								limit = Math.max(limit, constraint.used - constraint.available);
							}
							if (constraint.minimum != 0) {
								limit = Math.max(limit, constraint.minimum - constraint.minimumAvailable);
							}

							if (limit < 0) {
								occupancy |= 0; 		// if all are free => result will be free (occupancy == 0)
							} else if (limit == 0) {
								occupancy |= 1;			// if at least one is full, and none overrun => result will be full (occupancy == 1)
							} else {
								occupancy |= 2;			// if at least one is overrun => result will be overrun (occupancy == 2 or occupancy == 3)
							}
						});
						
						if (occupancy == 0) {
							return 'agenda-work-period-cell agenda-work-period-cell-free';
						} else if (occupancy == 1) {
							return 'agenda-work-period-cell agenda-work-period-cell-full';
						} else {
							return 'agenda-work-period-cell agenda-work-period-cell-overrun';
						}
					}
					return '';
				},
				colorField: 'row.entity',
				width: 25
			});
		} else if (agenda.mode === AgendaConstants.MODE_OPEN_COMPARTMENTS) {
			columnDefs.push({
				name: 'ovd', // open-vakken-date
				headerCellClass: 'agenda-header-cell-hide agenda-constraint-header-cell',
				colorField: 'eventColor',
				// field is needed to precompile all the templates; it works if it is used on any column
				field: 'start',
				width: 60,
				cellTemplate: require('./agenda-grid-open-vakken-date.html'),
				cellClass: 'agenda-constraint-group-cell-height'
			});
			// work period column
			columnDefs.push({
				name: 'ovwk', // open-vakken-work-period
				headerCellClass: 'agenda-header-cell-hide agenda-constraint-header-cell',
				colorField: 'row.entity.workPeriod',
				width: 25,
				cellClass: 'agenda-constraint-group-cell-height'
			});
		} else if (agenda.mode === AgendaConstants.MODE_DAY) {
			columnDefs.push({
				name: 'hours',
				field: 'start',
				cellFilter: 'date:"HH:mm"',
				headerCellClass: 'hide',
				cellClass: 'agenda-hours',
				width: 50
			})
		}

		if (agenda.mode === AgendaConstants.MODE_OPEN_COMPARTMENTS) {
			var firstRow = entries[0];
			for (var i = 0; i < agenda.gridOptions.numberOfConstraintGroups; i++) {
				var group = firstRow.groupStatuses[i].group;
				var columnDef = {
					// unique name
					name: group.groupCode,
					displayName: group.groupName,
					index: i,
					cellClass: function(grid, row, col) {
						var groupStatus = row.entity.groupStatuses[col.colDef.index];
						if (groupStatus == undefined) {
							return;
						}
						var status = groupStatus.status;
						var cssClass = "agenda-constraint-group-cell-height ";
						// when a cell is selected the registration dialog appears only
						// if it has 'can-create-registration' class
						if (status >= 2) {
							cssClass += "agenda-constraint-red";
						} else if (status == 1) {
							cssClass += "agenda-constraint-orange";
						} else if (status == 0){
							cssClass += "agenda-constraint-green ";
							if (col.colDef.isSelectable) {
								cssClass += "can-create-registration ";
							}
						} else {
							cssClass += "agenda-constraint-gray";
						}
						return cssClass;
					},
					headerCellClass: function(grid, row, col) {
						var cssClass = "agenda-constraint-header-cell";
						// a group that is not selectable appears in gray (text-muted)
						if (!col.colDef.isSelectable) {
							cssClass += " text-muted";
						}
						return cssClass;
					},
					enableSorting: false,
					groupCode: group.groupCode,
					enableColumnMenu: false,
					isSelectable: group.isSelectable
				};
				if (!group.isSelectable) {
					// if the group is not selectable, the column should be very narrow, because
					// it doesn't need buttons
					columnDef.maxWidth = 30;
				}

				columnDefs.push(columnDef);
			}
		} else {
			var length = processData(entries, agenda.gridOptions.columnDefs.length);

			for (var i = 0; i < length; i++) {
				columnDefs.push({
					name: 'agendaRegistration' + i,
					// unique name
					index: i,
					cellClass: 'ui-grid-cell-no-border',
					colorField: 'row.entity.agendaRegistration' + i + '.metadata',
					cellTemplate: require('./agenda-grid-registration.html'),
					headerCellClass: 'hide'
				});
			}

			// actions column

			if (agenda.editable) {
				columnDefs.push(agenda.editColumn);
			}

			if (agenda.selectable) {
				columnDefs.push(agenda.selectColumn);
			}
		
		}
		agenda.gridOptions.data = [];
		agenda.gridOptions.columnDefs = columnDefs;
		agenda.gridOptions.data = entries;
	}

	function processConstraintGroupsData(entries) {
		var constraintGroupsData = [];
		for (var i = 0; i < entries.workPeriods.length; i++) {
			var groupStatuses = [];
			var workPeriod = entries.workPeriods[i];
			for (var j = 0; j < entries.groupsConstraints.length; j++) {
				var constraintGroupStatus = entries.groupsConstraints[j];
				var status = {
					group : constraintGroupStatus.group,
					status : constraintGroupStatus.status[i]
				};
				groupStatuses[j] = status;
			}
			constraintGroupsData[i] = {
				start: workPeriod.fromDate,
				workPeriod: workPeriod,
				groupStatuses: groupStatuses,
				workRosterType: workPeriod.workRosterType,
				agendaRegistrations: []
			};
		}
		return constraintGroupsData;
	}
	
	function processData(entries, length) {
		var idxToRegistration = [];
		var ids = {};
		
		// iterate lines
		for (var i = 0; i < entries.length; i++) {
			var entry = entries[i];
			
			// clean first
			for (var j = 0; j < length; j++) {
				delete entry['agendaRegistration' + j];
			}
			delete entry['agendaRegistration'];
			
			// iterate existing columns
			for (var j = 0; j < idxToRegistration.length; j++) {
				// empty
				if (!idxToRegistration[j]) {
					continue;
				}
				
				// continue a registration from the previous line?
				var id = idxToRegistration[j].id;
				var found = false;
				for (var k = 0; k < entry.agendaRegistrations.length; k++) {
					var agendaRegistration = entry.agendaRegistrations[k];
					if (id == agendaRegistration.registration.id) {
						// found it
						found = true;
						entry['agendaRegistration' + j] = agendaRegistration;
						agendaRegistration.first = false;
						break;
					}
				}
				if (!found) {
					idxToRegistration[j] = null ;
				}
			}
			
			// look for new registrations to start
			for (var k = 0; k < entry.agendaRegistrations.length; k++) {
				var agendaRegistration = entry.agendaRegistrations[k];
				var id = agendaRegistration.registration.id;
				if (!id) {
					// skip new registrations
					entry['agendaRegistration'] = agendaRegistration;
					agendaRegistration.first = i == agenda.mode.getIndex(agendaRegistration.registration.fromDate);
					continue;
				} else if (!ids[id]) {
					var idx = findFirstIdxForRegistration(idxToRegistration);
					ids[id] = true;
					idxToRegistration[idx] = agendaRegistration.registration;
					entry['agendaRegistration' + idx] = agendaRegistration;
					agendaRegistration.first = new Date(agendaRegistration.registration.fromDate).getMonth() == new Date(agenda.date).getMonth();
				}
			}
		}
		
		return idxToRegistration.length;
	}
	
	function findFirstIdxForRegistration(idxToRegistration) {
		for (var i = 0; i < idxToRegistration.length; i++) {
			if (idxToRegistration[i]) {
				continue;
			}
			return i;
		}
		return idxToRegistration.length;
	}
	
	/**
	 * Return exchange registration.
	 */
	agenda.getExchange = function(entity) {
		var exchange;
		angular.forEach(entity.agendaRegistrations, function(agendaRegistration) {
			var registration = agendaRegistration.registration;
			if (registration && (registration.type.code == AgendaConstants.CODE_WISSEL || registration.type.code == AgendaConstants.CODE_BEURTWISSEL)) {
				exchange = agendaRegistration;
			}
		});
		return exchange;
	}
	
	// Define registration actions.
	// run(row, agendaRegistration, rowRenderIndex + 1)
	
	$scope.actions = {
		
		/**
		 * Open details popup for the day.
		 */
		details: {
			getLabel: function(scope) {
				return 'agenda.details';
			},
			getIcon: function(scope) {
				return 'fa fa-info-circle fa-fw';
			},
			run: function(scope) {
				var row = scope.row;
				
				FormModal.open({
					windowClass: 'modal-no-padding',
					scope: {
						template: require('./agenda-grid-workperiod-detail.html'),
						icon: 'fa fa-info-circle',
						title: row.entity.workRosterType,
						headingStyle: RGBAConverter.apply(row.entity),
						entity: row.entity,
						exchange: agenda.getExchange(row.entity),
						agenda: agenda,
						FORMAT_WORKPERIOD: 'HH:mm',
						FORMAT_DATE: 'dd/MM/yyyy'
					},
					actions: ['close']
				});
			}
		},
		
		/**
		 * Go into day mode.
		 */
		view: {
			getLabel: function(scope) {
				return 'agenda.view';
			},
			getIcon: function(scope) {
				return 'fa fa-clock-o fa-fw';
			},
			run: function(scope) {
				agenda.mode = AgendaConstants.MODE_DAY;
				agenda.date = scope.row.entity.start;
				agenda.refresh();
			}
		},
		
		/**
		 * Open the detail dialog for the registration, in update mode.
		 */
		edit: {
			getLabel: function(scope) {
				return scope.row.entity[scope.col.colDef.name].metadata[AgendaConstants.UPDATE_ROLE] ? 'edit' : 'read';
			},
			getIcon: function(scope) {
				return scope.row.entity[scope.col.colDef.name].metadata[AgendaConstants.UPDATE_ROLE] ? 'fa fa-pencil fa-fw' : 'fa fa-fw fa-file-text-o';
			},
			isDisabled: function(scope) {
				if (!agenda.editable) {
					return true;
				}
				return false;
			},
			isHidden: function(scope) {
				var agendaRegistration = scope.row.entity[scope.col.colDef.name];
				return !agendaRegistration.metadata[AgendaConstants.UPDATE_ROLE] && !agendaRegistration.metadata[AgendaConstants.READ_ROLE];
			},
			run: function(scope) {
				var agendaRegistration = scope.row.entity[scope.col.colDef.name];
				agenda.openDetail(agendaRegistration, !!agendaRegistration.registration.id);
			}
		},
		
		/**
		 * Split action.
		 */
		split: {
			getLabel: function(scope) {
				return 'agenda.split';
			},
			getIcon: function(scope) {
				return 'fa fa-scissors fa-fw';
			},
			isDisabled: function(scope) {
				if (!agenda.editable) {
					return true;
				}
				var agendaRegistration = scope.row.entity[scope.col.colDef.name];
				return !agendaRegistration.metadata[AgendaConstants.ALLOW_MAP_TO_WORKPERIOD];
			},
			isHidden: function(scope) {
				var agendaRegistration = scope.row.entity[scope.col.colDef.name];
				return !agendaRegistration.metadata[AgendaConstants.CREATE_ROLE] && !agendaRegistration.metadata[AgendaConstants.UPDATE_ROLE];
			},
			run: function(scope) {
				var name = scope.col.colDef.name;
				var agendaRegistration = scope.row.entity[name];
				agenda.splitRegistration(agendaRegistration.registration);
			}
		},
		
		/**
		 * Switch action.
		 */
		switch: {
			getLabel: function(scope) {
				return 'agenda.switch';
			},
			getIcon: function(scope) {
				return 'fa fa-exchange fa-fw';
			},
			isDisabled: function(scope) {
				return false;
			},
			isHidden: function(scope) {
				var name = scope.col.colDef.name;
				var agendaRegistration = scope.row.entity[name];
				return agendaRegistration.registration.type.code != AgendaConstants.CODE_VC && 
					agendaRegistration.registration.type.code != AgendaConstants.CODE_TK &&
					agendaRegistration.registration.type.code != AgendaConstants.CODE_OUV;
			},
			run: function(scope) {
				var name = scope.col.colDef.name;
				var agendaRegistration = scope.row.entity[name];
				agenda.switchRegistration(agendaRegistration);
			}
		},
		
		/**
		 * Remove action.
		 */
		remove: {
			getLabel: function(scope) {
				return 'clear';
			},
			getIcon: function(scope) {
				return 'fa fa-times fa-fw';
			},
			isDisabled: function(scope) {
				if (!agenda.editable) {
					return true;
				}
				var agendaRegistration = scope.row.entity[scope.col.colDef.name];
				return !agendaRegistration.metadata[AgendaConstants.DELETE_ROLE];
			},
			isHidden: function(scope) {
				var agendaRegistration = scope.row.entity[scope.col.colDef.name];
				return !agendaRegistration.metadata[AgendaConstants.CREATE_ROLE] && !agendaRegistration.metadata[AgendaConstants.UPDATE_ROLE];
			},
			run: function(scope) {
				var name = scope.col.colDef.name;
				var agendaRegistration = scope.row.entity[name];
				agenda.removeRegistration(agendaRegistration.registration);
			}
		},
		
		/**
		 * Cancel action.
		 */
		cancel: {
			getLabel: function(scope) {
				return 'cancel';
			},
			getIcon: function(scope) {
				return 'fa fa-ban fa-fw';
			},
			run: function(scope) {
				agenda.cancelRegistration(scope);
			}
		}
	}
	
	$scope.registrationActions = [$scope.actions.edit, $scope.actions.split, $scope.actions.switch, $scope.actions.remove];
	
	$scope.showDayDropdown = function($event, scope) {
		$event.preventDefault();
		$event.stopPropagation();
		
		var entity = scope.row.entity;
		if (entity.workRosterType || entity.constraint || agenda.getExchange(entity)) {
			// working day => open menu to allow details + day view menu
			scope.status.isopen = true;
		} else {
			$scope.actions.view.run(scope);
		}
	}
	
	$scope.showLeaveGroup = function(entity) {
		$scope.agenda.showLeaveGroup({
			name: $scope.getWorkPeriodLabel(entity)
		}, entity.constraint.employeesIds, entity.start);
	}

	agenda.refresh = function() {
		if (!agenda.resource || !agenda.leaveGroupSet || !agenda.registrationTypeSet) {
			// too early
			return;
		}
		
		ModalSpinner.show('agenda.loading.data');
		
		if (agenda.mode === AgendaConstants.MODE_MONTH && 
		agenda.registrationType && // it will be null in the exchange date select context
		agenda.registrationTypesMetadata[agenda.registrationType.code][AgendaConstants.CONSTRAINED] && agenda.leaveGroup) {
			// show constraint only if constrained and possible group selected
			agenda.constraints.visible = true;
		} else {
			agenda.constraints.visible = false;
		}

		if (agenda.mode === AgendaConstants.MODE_OPEN_COMPARTMENTS) {
			// scrollbar options: 0 - hidden, 1 - scroll (default), 2 - when needed; we need scroll only for open vakken screen
			agenda.gridOptions.enableHorizontalScrollbar = 2;
			agenda.getConstraintGroups();
		} else {
			agenda.gridOptions.enableHorizontalScrollbar = 0;
			agenda.refreshAgenda();
		}

		// initialize balance
		agenda.balance = null;
		agenda.getBalance(function(balance) {
		}, true /* silent */);
	}

	agenda.refreshAgenda = function() {
		var config = {
				data: {
					employeeId: agenda.resource.id,
					toDate: agenda.mode.getLastDate(agenda.date),
					registrationTypeCode: agenda.registrationType ? agenda.registrationType.code : null
				}
		}

		Principal.checkPermissions(AgendaConstants.WAITING_AGENDA, function(allowed) {
			if (allowed && (config.data.registrationTypeCode == AgendaConstants.CODE_VC || config.data.registrationTypeCode == AgendaConstants.CODE_OUV || config.data.registrationTypeCode == AgendaConstants.CODE_TK)) {
    			Agenda.getWaitingBalance({}, config).then(function(result) {
    				var balanceString = "U hebt nog:";
    					for (var i in result.data) {
            				var counter = result.data[i];
            				if (counter.value != 0) {
            					balanceString += " " + counter.value + " " + counter.description + ",";
            				}	 
            			}
            			if (balanceString.endsWith(",")) {
            				balanceString = balanceString.slice(0, -1);
            			} else {
            				balanceString += " 0";
            			}
            			agenda.waitingDays = balanceString;
    			}, function() {
    				agenda.waitingDays = "Er is een fout opgetreden bij het ophalen van het saldo";
    			});
			} else {
				agenda.waitingDays = "";
			}
		});

		// get calendar events
		Common.getCalendarEvents({}, {
			data: {
				definitionCode: AgendaConstants.VERLOFKALENDER,
				fromDate: agenda.mode.getFirstDate(agenda.date),
				toDate: agenda.mode.getLastDate(agenda.date)
			}
		}).then(function(result) {
			// highlight calendar events
			agenda.gridOptions.calendarEvents = [];
			angular.forEach(result.data, function(calendarEvent) {
				var date = new Date(calendarEvent.fromDate);
				agenda.gridOptions.calendarEvents[date.getDate()] = true;
			});
			
			// get registrations
			var config = {
				data: {
					employeeId: agenda.resource.id,
					useOtherAgendaContext: agenda.useOtherAgendaContext,
					fromDate: agenda.mode.getFirstDate(agenda.date),
					toDate: agenda.mode.getLastDate(agenda.date),
					registrationTypeCode: agenda.registrationType ? agenda.registrationType.code : null ,
					constraintGroupCode: agenda.leaveGroup ? agenda.leaveGroup.code : null,
					onlyWaitingRegistrations: agenda.onlyWaitingRegistrations,
					leaveGroupSnapshotsByTimePeriod: agenda.leaveGroupSnapshotsByTimePeriod
				}
			}
			Agenda.getAll({}, config).then(function(result) {
				var data = result.data;
				data = agenda.mode.getEntries(data, agenda.date);
				agenda.addPlaceholder(null, data);
				agenda.createColumnDefs(data);
			}).finally(function() {
				if (agenda.reset) {
					agenda.reset();
				}
				ModalSpinner.hide();
			});
		});
	}
	
	/**
	 * Add placeholders for the given registrations. If the parameter is not set, then re-create placeholders
	 * for the current registrations to save.
	 */
	agenda.addPlaceholder = function(agendaRegistrations, data, stopOnIdx) {
		if (!agendaRegistrations && agenda.agendaRegistration && agenda.agendaRegistration.registration.fromDate) {
			agendaRegistrations = [agenda.agendaRegistration];
		}
		if (!data) {
			data = agenda.gridOptions.data;
		}
		angular.forEach(agendaRegistrations, function(agendaRegistration) {
			agenda.addOrRemovePlaceholder(agendaRegistration, data, true, false, stopOnIdx);
		});
	}
	
	/**
	 * Remove placeholders for the given registrations. If the parameter is not set, then remove placeholders
	 * for the current registrations to save.
	 */
	agenda.removePlaceholder = function(agendaRegistration, data) {
		if (!data) {
			data = agenda.gridOptions.data;
		}
		agenda.addOrRemovePlaceholder(agendaRegistration, data, false);
	}
	
	/**
	 * Adds or removes a placeholder registration. Finds the entries (days or half-hours) to highlight,
	 * and for each entry, add a placeholder registration that will be displayed in the calendar.
	 */
	agenda.addOrRemovePlaceholder = function(agendaRegistration, data, add, nonPlaceholder, stopOnIdx) {
		var registration = agendaRegistration.registration;
		var start = agenda.mode.getFirstDate(registration.fromDate).getTime();
		var end = agenda.mode.getFirstDate(registration.toDate).getTime();
		var _this = agenda.mode.getFirstDate(agenda.date).getTime();
		var startIdx = 100, endIdx = -1;
		if (_this == start) {
			// same month/day
			startIdx = endIdx = agenda.mode.getIndex(registration.fromDate);
		} else if (start < _this) {
			// starts in a previous month/day
			startIdx = 0;
		}
		
		if (_this == end) {
			// same month/day
			if (stopOnIdx) {
				endIdx = stopOnIdx;
			} else if (registration.toDate) {
				endIdx = agenda.mode.getIndex(registration.toDate);
			}
		} else if (_this < end) {
			// ends in a next month/day
			endIdx = data.length - 1;
		}
		
		var placeholder = !nonPlaceholder;

		for (var i = startIdx; i <= endIdx; i++) {
			if (!data[i]) {
				continue;
			}
			var e = data[i];
			if (add) {
				if (AgendaConstants.indexOfRegistration(registration, e.agendaRegistrations) >= 0) {
					continue;
				}

				e.agendaRegistrations.push({
					placeholder: placeholder,
					split: agendaRegistration.split,
					metadata: agendaRegistration.metadata,
					registration: registration
				});
			} else {
				var index = AgendaConstants.indexOfRegistration(registration, e.agendaRegistrations);
				if (index >= 0) {
					e.agendaRegistrations.splice(index, 1);
				}
			}
		}

		$log.debug((add ? 'add ' : 'remove ') +
			'registration from ' + startIdx + ' to ' + endIdx + 
			(nonPlaceholder ? + ' (non-placeholder)' : ' (placeholder)'));
	}
	
	agenda.backToMonth = function() {
		agenda.mode = AgendaConstants.MODE_MONTH;
		agenda.refresh();
	}
	
	agenda.previousDay = function() {
		var date = new Date(agenda.date);
		date.setDate(date.getDate() - 1);
		agenda.date = date.getTime();
		agenda.refresh();
	}
	
	agenda.nextDay = function() {
		var date = new Date(agenda.date);
		date.setDate(date.getDate() + 1);
		agenda.date = date.getTime();
		agenda.refresh();
	}

	agenda.previousMonth = function() {
		var date = new Date(agenda.date);
		date.setMonth(date.getMonth() - 1);
		agenda.date = date.getTime();
		agenda.getLeaveGroups();
	}
	
	agenda.nextMonth = function() {
		var date = new Date(agenda.date);
		date.setMonth(date.getMonth() + 1);
		agenda.date = date.getTime();
		agenda.getLeaveGroups();
	}
	
	/**
	 * Open the leave group popup.
	 */
	agenda.showLeaveGroup = function(leaveGroup, employeesIds, fromDate, toDate) {
		FormModal.open({
			scope: {
				icon: 'fa fa-users',
				title: 'agenda.occupancyForGroup',
				titleParam: leaveGroup,
				template: '<resource-grid group="::leaveGroup" ids="::employeesIds" from-date="::fromDate" to-date="::toDate" fetch="true"></resource-grid>',
				leaveGroup: leaveGroup,
				employeesIds: employeesIds,
				fromDate: fromDate,
				toDate: toDate
			},
			actions: ['close']
		});
	}

	agenda.getConstraintGroups = function() {
		Common.getCalendarEvents({}, {
			data: {
				definitionCode: AgendaConstants.VERLOFKALENDER,
				fromDate: agenda.mode.getFirstDate(agenda.date),
				toDate: agenda.mode.getLastDate(agenda.date)
			}
		}).then(function(result) {
			// highlight calendar events
			agenda.gridOptions.calendarEvents = [];
			angular.forEach(result.data, function(calendarEvent) {
				var date = new Date(calendarEvent.fromDate);
				agenda.gridOptions.calendarEvents[date.getDate()] = true;
			});

			var groupCodes = [];
			for (var i in agenda.leaveGroupSnapshots) {
				var group = agenda.leaveGroupSnapshots[i];
				if (group.code != null) {
					groupCodes[i] = group.code;
				}
			}

			// if there are no seelctable groups, don't make any request
			if (groupCodes.length == 0) {
				ModalSpinner.hide();
				agenda.createColumnDefs([]);
				return;
			}
			// get open vakken data
			var config = {
				data: {
					fromDate: agenda.mode.getFirstDate(agenda.date),
					registrationType: agenda.registrationType ? agenda.registrationType.code : null,
					user: agenda.resource.id,
					selectableGroupCodes: groupCodes
				}
			}
			ConstraintGroups.getConstraintGroups({}, config).then(function(result) {
				var data = result.data;
				var constraintGroupsData = processConstraintGroupsData(data);
				agenda.gridOptions.numberOfConstraintGroups = data.groupsConstraints.length;
				agenda.createColumnDefs(constraintGroupsData);
			}).finally(function() {
				if (agenda.reset) {
					agenda.reset();
				}
				ModalSpinner.hide();
			});
		});
	}
	
	agenda.showBalance = function() {
		agenda.getBalance(function(balances) {
			FormModal.open({
				scope: {
					icon: 'fa fa-balance-scale',
					title: agenda.registrationType.name,
					template: $templateCache.get('planning/balance-table.html'),
					balances: balances
				},
				actions: ['close']
			});
		});
	}
	
	agenda.getBalance = function(callback, silent) {
		if (agenda.balance) {
			callback && callback(agenda.balance.items);
			return;
		}
		if (!agenda.registrationType) {
			return;
		}

		var config = {
			silent: silent,
			data: {
				employeeId: agenda.resource.id,
				toDate: agenda.date,
				registrationTypeCode: agenda.registrationType ? agenda.registrationType.code : null
			}
		}
		Agenda.getBalance({}, config).then(function(result) {
			var balanceItems = result.data;
			
			agenda.balance = {};
			var balances = [];
			var map = {}; 
			/* map of {
				prefix: String,
				items: Array,
				headers: Array
			} */
			
			for (var i = 0; i < balanceItems.length; i++) {
				// iterate balance items, and group by balance type
				var balanceItem = balanceItems[i];
				var idx = balanceItem.code.lastIndexOf('_');
				idx < 0 && (idx = 0);
				var prefix = balanceItem.code.substring(0, idx);
				var suffix = balanceItem.code.substring(idx);

				if (suffix == '_INITIAL' && prefix == 'LEAVE') {
					agenda.emptyInitialBalance = true;
					for (var j = 0; j < balanceItem.counters.length; j++) {
						if (balanceItem.counters[j].value != 0) {
							agenda.emptyInitialBalance = false;
							break;
						}
					}
					if (agenda.emptyInitialBalance) {
						break;
					}
				}

				var balance = map[prefix];
				if (!balance) {
					map[prefix] = balance = {
						prefix: prefix,
						items: [],
						headers: []
					};
					for (var j = 0; j < balanceItem.counters.length; j++) {
						var counter = balanceItem.counters[j];
						// none of the counters will have codes, so just stop now
						if (!counter.code) break;
						balance.headers.push(counter.description);
					}
					balances.push(balance);
				}
				balance.items.push(balanceItem);
				
				if (suffix == 'RESULT' || suffix == '_RESULT') {
					var sum = 0;
					for (var j = 0; j < balanceItem.counters.length; j++) {
						sum += balanceItem.counters[j].value;
					}
					if (!prefix || agenda.registrationType.code != 'VC') {
						agenda.balance.result = sum;
					} else {
						agenda.balance[prefix] = sum;
						if (isNaN(agenda.balance.shifts) && balance.headers.length) {
							agenda.balance.shifts = sum;
						}
					}
				}
			}
		
			// set at the end to trigger binding only once
			agenda.balance.items = balances;
			callback && callback(balances);
		});
	}

	agenda.onClickConstraintGroupCell = function($event, scope) {
		// find the selected cell
		var cellElement = $event.target;
		if (cellElement == undefined) {
			return;
		}

		while (!cellElement.classList.contains('ui-grid-cell') && !cellElement.classList.contains('ui-grid-header-cell') ) {
			cellElement = cellElement.parentElement;
			if (cellElement == undefined) {
				return;
			}
		}
		// if a group (header cell) was selected then show the members of that group;
		// otherwise, open the registration dialog
		if (cellElement.classList.contains('ui-grid-header-cell')) {
			showGroupMembers(cellElement, this);
			return;
		}
		openDetailFromOpenCompartmentsView(cellElement, this);
	}

	function openDetailFromOpenCompartmentsView(cellElement, scope) {
		// Open the registration dialog initialized with the current user,
		// the selected registration type, the selected group and the selected work period.
		if (!cellElement.classList.contains('can-create-registration')) {
			// A group can be visible, but the user can't create a registration in that group (e.g. MAIN group, FAMILY group)
			return;
		}

		// obtain the cell using the cell id; e.g. of cell id: 1560936928329-0-uiGrid-005K-cell ([id]-[cellIndex]-[colUid]-cell)
		var idTokens = cellElement.id.split("-");
		var rowIndex = idTokens[1];
		var columnUid = idTokens[2] + "-" + idTokens[3];
		if (rowIndex == undefined || columnUid == undefined) {
			return;
		}
		var row = scope.gridApi.grid.rows[rowIndex]
		var rowCells = row.grid.columns;
		var cell;
		for (var i = 0; i < rowCells.length; i++) {
			if (rowCells[i].uid === columnUid) {
				cell = rowCells[i];
				break;
			}
		}
		if (cell == undefined) {
			return;
		}

		// verify if the group has open slots for that day; if not, do not open the registration dialog
		var availableSlots = cellElement.classList.contains("agenda-constraint-green");
		if (!availableSlots) {
			return;
		}

		// open the registration dialog using employee's id, start date, to date, registration type and selected group
		var entry = row.entity;
		var startDate = new Date(entry.start);
		var agendaRegistration = {
			registration : {
				fromDate: startDate,
				type: scope.registrationType,
				resource: scope.resource.id
			},
			metadata: scope.registrationTypesMetadata[scope.registrationType.code]
		}
		var config = {
			data: {
				date: startDate,
				resource: scope.resource.id
			}
		}

		var workPeriod = null;
		Agenda.getWorkPeriod({}, config).then(function(result) {
			workPeriod = result.data;
		}).finally(function() {
			agendaRegistration.registration.toDate = new Date(workPeriod.toDate);
			agenda.openDetail(agendaRegistration, !!agendaRegistration.registration.id);
			agendaRegistration.registration.environment = AgendaConstants.ENVIRONMENT;
		});

		scope.leaveGroup = {code: cell.colDef.groupCode};
	}

	if (agenda.useOtherAgendaContext) {
		// for the case when other agenda is opened in a modal window (when creating a wissel)
		agenda.refresh();
	}

	function showGroupMembers(cellElement, scope) {
		// show the members of a group; the cell header has a css class: ui-grid-col[columnUid], we need to split the css classes to retrieve the column uid.
		var classTokens = cellElement.classList.toString().split("ui-grid-col");
		var colTokens = classTokens[1].split(" ");

		// the uid is colTokens[0], now we need to iterate through columns
		var columns = scope.gridApi.grid.columns;
		var groupCode;
		for (var i in columns) {
			if (columns[i].uid === colTokens[0]) {
				groupCode = columns[i].name;
				break;
			}
		}

		// we obtained the group code and we will open a modal showing the resources for this group
		ModalSpinner.show('agenda.loading.resources');
		var config = {
			params: {
				groupCode: groupCode
			}
		}
		Agenda.getGroupByCode({}, config).then(function(result) {
			FormModal.open({
				windowClass: 'modal-no-padding',
				scope: {
					icon: 'fa fa-users',
					title: 'agenda.membersOfGroup',
					titleParam: result.data,
					template: require('./agenda-group-members.html'),
					leaveGroup: result.data,
					fromDate: new Date(agenda.mode.getFirstDate(agenda.date)),
					toDate: new Date(agenda.mode.getLastDate(agenda.date))
				},
				actions: ['close']
			});
		}).finally(function() {
			ModalSpinner.hide();
		});
	}

	agenda.showOpenCompartmentsView = function() {
		agenda.mode = AgendaConstants.MODE_OPEN_COMPARTMENTS;
		agenda.refresh();
	}

	// expose for html template
	agenda.isNaN = function(n) {
		return isNaN(n);
	}

	$log.debug('initialized');

};

module.exports = AgendaGridCtrl;