'use strict'

/*@ngInject*/
function RefreshCtrl($scope, $interval, Common) {

	$scope.timer = undefined;
	$scope.refreshInterval = 10;
	$scope.MIN_REFRESH_INTERVAL = 10;
	if (!$scope.buttonType) {
		$scope.buttonType = "btn btn-default btn-action";
	}
	
	$scope.$watch('refreshInterval', function(newVal, oldVal){
		if (newVal != oldVal && angular.isDefined(newVal)) {
			localStorage.setItem('refreshInterval', newVal);
			$scope.setRefreshInterval();						
		} else {
			//restore previous value
			$scope.refreshInterval = oldVal;
		}
	});
	
	$scope.setupTimer = function() {
		if (angular.isDefined($scope.timer)) { 
			return;
		}
		$scope.timer = $interval(function() {	  
			$scope.action.run();
	    }, $scope.refreshInterval * 1000);
	}
	
	$scope.destroyTimer = function() {
		if (angular.isDefined($scope.timer)) {
            $interval.cancel($scope.timer);
            $scope.timer = undefined;
		}
	}
	
	$scope.cancel = function(e) {
	    if (e.keyCode === 27) {
	      $scope.refreshForm.refreshInput.$rollbackViewValue();
	    }
	  };
	
	$scope.setRefreshInterval = function(e) {
		$scope.refreshInterval = parseInt(localStorage.getItem('refreshInterval'));
		if (!isNaN($scope.refreshInterval)) {
			$scope.destroyTimer();
			if ($scope.refreshInterval > $scope.MIN_REFRESH_INTERVAL) {
				$scope.setupTimer();
			}
		} else {
			$scope.setRefreshForDefaultInterval();
		}		
	}
	  
	$scope.setRefreshForDefaultInterval = function(e) {
		Common.getParameterValue({}, {
			data: {
				parameterName: 'AUTO_REFRESH_INTERVAL',
				date: new Date()
			}
		}).then(function(result) {
			$scope.refreshInterval = result.data.AUTO_REFRESH_INTERVAL;
		});
	  };	
};

module.exports = RefreshCtrl;