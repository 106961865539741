import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldEditorProps, FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { CALENDAR_SERVICE_FIND_BY_STRING } from "graphql/queries";
import React from "react";

export const calendarItemDescriptor = new EntityDescriptor({ name: "CalendarItems" })
    .addFieldDescriptor({ name: "calendarItem", type: "CalendarItem" });

export const calendarPropertyDescriptor = new EntityDescriptor({ name: "CalendarProperties" })
    .addFieldDescriptor({ name: "property", type: "CalendarPropertyCt" })
    .addFieldDescriptor({ name: "value", type: FieldType.string });

export const extendDefinitionsDescriptor = new EntityDescriptor({ name: "ExtendDefinitions" })
    .addFieldDescriptor({ name: "calendarDefinition", type: "CalendarDefinition" }, new class extends FieldDescriptor {
        protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
            return React.createElement(CalendarDefinitionFieldEditor as any, props as FieldEditorProps);
        }
    });

export class CalendarItemTableRenderer extends React.Component<FieldRendererProps>{
    render() {
        return this.props.entity?.subject;
    }
}

export class CalendarPropertyTableRenderer extends React.Component<FieldRendererProps>{
    render() {
        return this.props.entity?.property?.code;
    }
}

export class CalendarDefinitionFieldEditor<P> extends AssociationFieldEditor<P>{

    protected createQuery(operationName?: string) {
        return {
            name: `calendarDefinitionService_findByString`,
            query: CALENDAR_SERVICE_FIND_BY_STRING
        }
    }
}