'use strict';

function uibTimepicker() {
	
	return {
		require: '?^^registrationDetailDate', // search on parent, don't throw error
		link: function($scope, elt, attrs, registrationDetailDate) {
			if (registrationDetailDate && window.datePicker) {
				elt.find('input').click(function() {
					var options = {
						mode: 'time',
						is24Hour: true,
						date: new Date($scope.date)
					}

					window.datePicker.show(options, function(date) {
						$scope.$apply(function() {
							var d = new Date($scope.date);
							d.setHours(date.getHours());
							d.setMinutes(date.getMinutes());
							$scope.date = d;
						});
					});
				});
			}
		}
	}
}

module.exports = uibTimepicker;