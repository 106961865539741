import { createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, StateFrom } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { CodeEntity } from "apollo-gen/CodeEntity";
import { push } from "connected-react-router";
import { SECURITY_PROFILE_SERVICE_SAVE_SECURITY_PROFILE } from "graphql/queries";
import _ from "lodash";
import React from "react";
import { Form, Input } from "semantic-ui-react";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import { permissionsEditorEntityDescriptor, permissionsTableEntityDescriptor } from "./customFieldRenderersEditors";

export enum MessageType {
    ADD_SUCCESS = "ADD_SUCCESS",
    ADD_FAIL = "ADD_FAIL",
    DELETE_SUCCESS = "DELETE_SUCCESS"
}

export class SecurityProfileEntityDescriptor extends EntityDescriptor {

    toMiniString(entityWithMiniFields: any) {
        return entityWithMiniFields?.description;
    }

    protected customize() {

        const securityProfileEntityDescriptor = this;
        securityProfileEntityDescriptor.addFieldDescriptor({ name: "permissions", type: "SecurityPermission", isInDefaultColumnConfigForTable: false });

        const sliceSecurityProfileEditor = securityProfileEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceSecurityProfileEditor extends SliceEntityEditorPage {

            /**
             * @override
             */
            getSaveOperationName(): string {
                return `securityProfileService_saveSecurityProfile`;
            }

            initQueries() {
                super.initQueries();
                this.saveMutation = SECURITY_PROFILE_SERVICE_SAVE_SECURITY_PROFILE;
            }

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                showMessage: undefined as unknown as { type: MessageType }
            }

            nestedSlices = {
                ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
                entityTable: sliceEntityTableLight
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceSecurityProfileEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceSecurityProfileEditor>(this),

                superMutation: sliceEntityEditorPageOnlyForExtension.impures.invokeSaveMutation,
                async invokeSaveMutation(options: any) {
                    let localEntity = { ...this.getState().entity };
                    if (this.getState().duplication) {
                        localEntity = {
                            ...localEntity,
                            id: null,
                            creationDate: null,
                            creationUser: null,
                            modificationDate: null,
                            modificationUser: null,
                            objectVersion: null
                        };
                    }
                    options.variables = { entity: localEntity };
                    return await this.superMutation(options);
                },

                superSave: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    const result = await this.superSave(entity);

                    //if no exceptions occured, go back to table
                    if (result !== true) {
                        this.getDispatchers().setInReduxState({ showMessage: undefined });
                        this.getDispatchers().dispatch(push(securityProfileEntityDescriptor.getEntityTableUrl()));
                    }
                }
            }
        }).setEntityDescriptor(securityProfileEntityDescriptor);

        securityProfileEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceSecurityProfileEditor> & EntityEditorPageProps> {
            protected refEntityTableLight = React.createRef<EntityTableLight>();

            setTableEntity(data: CodeEntity[] | null) {
                let entities: CodeEntity[] = [];
                if (data) {
                    entities = data;
                } else {
                    entities = (this.props.entity.permissions !== undefined ? this.props.entity.permissions : []);
                }
                this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(_.sortBy(entities, "code"));
            }

            async load(id: number) {
                const entity = await super.load(id);
                this.setTableEntity(entity?.permissions);
            }

            filterPermissions(searchedPermission: String) {
                if (searchedPermission === "") {
                    this.setTableEntity(null);
                    return;
                }
                let filteredPermissions: CodeEntity[] = [];
                for (let i = 0; i < this.props.entity.permissions.length; i++) {
                    if (this.props.entity.permissions[i].code.toLowerCase().includes(searchedPermission.toLowerCase())) {
                        filteredPermissions.push(this.props.entity.permissions[i]);
                    }
                }
                this.setTableEntity(filteredPermissions);
            }

            /**
            * Overridden method to add custom css properties to EntityEditorPage
            */
            protected getContainerCssClasses() {
                let cssClasses = super.getContainerCssClasses();
                cssClasses.inner = cssClasses.inner + " SecurityProfileEditor_segment";
                return cssClasses;
            }

            protected getTabbedPageCssClasses() {
                return super.getTabbedPageCssClasses() + " SecurityProfileEditor";
            }

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "SecurityProfile" });
                result.entityDescriptor.addFieldDescriptor({ name: "code", type: FieldType.string })
                result.entityDescriptor.addFieldDescriptor({ name: "description", type: FieldType.string })
                return result;
            }

            async removePermission(permission: CodeEntity) {
                await this.onApply();
                let permissions = this.props.entity.permissions.filter((item: CodeEntity) => item.id !== permission.id);
                this.props.dispatchers.setInReduxState({ entity: { ...this.props.entity, permissions }, showMessage: { type: MessageType.DELETE_SUCCESS } });
                setTimeout(() => { this.props.dispatchers.setInReduxState({ showMessage: undefined }); }, 3000);
            }

            async addPermission(permission: CodeEntity) {
                if (permission === undefined || permission === null) {
                    return;
                }
                await this.onApply();
                let permissions = this.props.entity.permissions !== undefined ? [...this.props.entity.permissions] : []
                const permissionAlreadyExists = permissions.filter((item: CodeEntity) => item.id === permission.id).length > 0;
                if (permissionAlreadyExists) {
                    this.props.dispatchers.setInReduxState({ showMessage: { type: MessageType.ADD_FAIL } });
                } else {
                    permissions.push(permission);
                    this.props.dispatchers.setInReduxState({ entity: { ...this.props.entity, permissions }, showMessage: { type: MessageType.ADD_SUCCESS } });
                    this.setTableEntity(permissions);
                }
                setTimeout(() => { this.props.dispatchers.setInReduxState({ showMessage: undefined }); }, 3000);
            }

            renderForm() {
                return (
                    <>
                        {super.renderForm()}
                        {this.props.showMessage?.type === MessageType.ADD_SUCCESS ?
                            <Message className="SecurityProfileEditor_message" success>
                                {_msg("entity.added.message", _msg("PermissionsEditor.permission.label"))}
                            </Message> : null}
                        {this.props.showMessage?.type === MessageType.ADD_FAIL ?
                            <Message error className="SecurityProfileEditor_message" >
                                {_msg("entity.exists.message", _msg("PermissionsEditor.permission.label").toLowerCase())}
                            </Message> : null}
                        {this.props.showMessage?.type === MessageType.DELETE_SUCCESS ?
                            <Message success className="SecurityProfileEditor_message">
                                {_msg("entity.deleted.message", _msg("PermissionsEditor.permission.label"))}
                            </Message> : null}
                        <Form>
                            <Form.Field>
                                <label>{_msg("SecurityProfile.permissions.label")}</label>
                                <Input placeholder={_msg("searchPermission.label")} icon="search"
                                    onChange={(e, data) => {
                                        this.filterPermissions(data.value as string);
                                    }}>
                                </Input>
                            </Form.Field>
                        </Form>
                        <div className="SecurityProfileEditor_tablePermissions">
                            <EntityTableLight {...this.props.entityTable} dispatchers={this.props.dispatchers.entityTable} entityDescriptor={permissionsTableEntityDescriptor} ref={this.refEntityTableLight}
                                formCustomizer={{ customEntityDescriptorForEditor: permissionsEditorEntityDescriptor, headerIcon: "lock", headerContent: _msg("PermissionEditor.addPermission.label") }}
                                actions={{ doNotAddEntityToTable: true, showDeleteButton: false, showEditButton: false }}
                                onDelete={async (remainingSecurityPermissions: CodeEntity[], removedPermissionIndex: number) => {
                                    await this.removePermission(this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[removedPermissionIndex]);
                                }}
                                onSave={async (permissions: CodeEntity[], addedPermission: { rowIndex: number, values: { permission: CodeEntity } }) => {
                                    await this.addPermission(addedPermission.values.permission);
                                }}
                            />
                        </div>
                    </>
                );
            }

            componentDidUpdate(prevProps: PropsFrom<typeof sliceSecurityProfileEditor>) {
                if (prevProps.match?.params.id !== this.props.match?.params.id) {
                    this.props.dispatchers.setInReduxState({ showMessage: undefined });
                }
            }

            componentDidMount() {
                this.props.dispatchers.setInReduxState({ showMessage: undefined });
            }
        }
    }
}