import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { ADD, EntityEditorPage, EntityEditorPageProps, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { EntityTablePage, EntityTablePageProps } from "@crispico/foundation-react";
import { SliceEntityTablePage, sliceEntityTablePageOnlyForExtension } from "@crispico/foundation-react";
import React from "react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { OWNER } from "pages/groupsManagement/GroupsManagement";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { push } from "connected-react-router";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { ProteusConstants } from "ProteusConstants";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { CUSTOM_NUMBERS } from "pages/groupsManagement/customFieldRenderersEditors";
import moment from "moment";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { PILOT_PROGRESS_HISTORY_ITEM_SERVICE_SAVE_PILOT_PROGRESS_HISTORY_ITEM } from "graphql/queries";
import { planningPageInfo, slicePlanning } from "pages/Planning/PlanningPage";
import { infoCompanyResources, sliceCompanyResourcesOnlyForExtension } from "pages/companyResources/CompanyResources";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";

const TARGET_TASKS = "targetTasks";
const TARGET_TOTAL_TASKS = "targetTotalTasks";
const TASKS = "tasks";
const TOTAL_TASKS = "totalTasks";

interface Employee {
    id: number | null | undefined,
    name: string | null | undefined,
    firstName: string | null | undefined
}

/**
 * This component is used in 2 modes:
 * 1. Stand-alone CRUD screen (embeddedMode = false)
 * 2. Embedded CRUD screen in EmployeeEditor - Planning/CompanyResources (embeddedMode = true)
 */
export class PilotProgressHistoryItemEntityDescriptor extends EntityDescriptor {

    getGraphQlFieldsToRequest() {
        return "id objectVersion creationDate creationUser modificationDate modificationUser generated" +
            " tasks extras totalTasks validFrom validUntil owner { id name firstName objectType objectVersion } category {id description tasks totalTasks objectVersion }";
    }

    toMiniString(entityWithMiniFields: any) {
        return entityWithMiniFields?.category?.description || "";
    }

    protected customize() {
        const pilotProgressHistoryItemEntityDescriptor = this;
        pilotProgressHistoryItemEntityDescriptor.addFieldDescriptor({ name: TARGET_TOTAL_TASKS, type: FieldType.number, filterable: false, sortable: false, isInDefaultColumnConfigForEditor: false });
        pilotProgressHistoryItemEntityDescriptor.addFieldDescriptor({ name: TARGET_TASKS, type: FieldType.number, filterable: false, sortable: false, isInDefaultColumnConfigForEditor: false });

        const slicePilotProgressTable = pilotProgressHistoryItemEntityDescriptor.infoTable.slice = createSliceFoundation(class SlicePilotProgressTable extends SliceEntityTablePage {

            initialState = {
                ...sliceEntityTablePageOnlyForExtension.initialState,
                selectedEmployee: undefined as unknown as Employee
            }

            reducers = {
                ...sliceEntityTablePageOnlyForExtension.reducers,
                ...getBaseReducers<SlicePilotProgressTable>(this)
            }

            impures = {
                ...sliceEntityTablePageOnlyForExtension.impures,
                ...getBaseImpures<SlicePilotProgressTable>(this),

                adjustFilterBeforeLoad(filter: Filter): Filter {
                    if (this.getState().selectedEmployee?.id) {
                        filter.filters?.push({ field: OWNER + ".id", operator: "equals", value: this.getState().selectedEmployee?.id ? String(this.getState().selectedEmployee.id) : "" });
                    }
                    return filter;
                },

                adjustSortsBeforeLoad(sorts: Sort[]): Sort[] {
                    return sorts.concat(Object.assign([{ field: ProteusConstants.VALID_FROM, direction: "DESC" }]))
                        .filter(item => item.field !== ProteusConstants.SEQUENCE && item.field !== ProteusConstants.ID);
                },

                tableIsEmbedded() {
                    if (pilotProgressHistoryItemEntityDescriptor.infoTable.routeProps?.routeEntityName === AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().locationForMainRouteEntityName) {
                        return false;
                    }
                    return true;
                },

                getMountedHelperSlice() {
                    if (this.tableIsEmbedded()) {
                        if (AppMetaTempGlobals.appMetaInstance.newRootReducer.isHelperMounted(planningPageInfo.sliceName)) {
                            return AppMetaTempGlobals.appMetaInstance.newRootReducer.getMountedHelper<typeof slicePlanning>(planningPageInfo.sliceName!).dispatchers.employeeEditor;
                        } else if (AppMetaTempGlobals.appMetaInstance.newRootReducer.isHelperMounted(infoCompanyResources.sliceName)) {
                            return AppMetaTempGlobals.appMetaInstance.newRootReducer.getMountedHelper<typeof sliceCompanyResourcesOnlyForExtension>(infoCompanyResources.sliceName!).dispatchers;
                        }
                    }
                    return undefined;
                }
            }
        }).setEntityDescriptor(pilotProgressHistoryItemEntityDescriptor);

        pilotProgressHistoryItemEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<PropsFrom<typeof slicePilotProgressTable> & EntityTablePageProps & { employee: Employee }> {

            adjustEntities(entities: any[]) {
                entities = super.adjustEntities(entities);
                for (let i = 0; i < entities.length; i++) {
                    entities[i] = { ...entities[i], targetTasks: entities[i].category?.tasks, targetTotalTasks: entities[i].category?.totalTasks };
                }
                return entities;
            }

            async deleteEntity(entityDescriptorName: string, id: number, location: any) {
                super.deleteEntity(entityDescriptorName, id, location);
                if (this.props.dispatchers.tableIsEmbedded()) {
                    this.props.dispatchers.getMountedHelperSlice()!.progressionTab.setInReduxState({ shouldReloadPilotProgressionHistory: true });
                }
            }

            async componentDidMount() {
                if (this.props.embeddedMode) {
                    pilotProgressHistoryItemEntityDescriptor.doForFields([OWNER], (fieldDescriptor: FieldDescriptor) => fieldDescriptor.isInDefaultColumnConfig = false);
                    this.props.dispatchers.setInReduxState({ selectedEmployee: this.props.employee });
                }
            }

            componentWillUnmount() {
                this.props.dispatchers.setInReduxState({ selectedEmployee: undefined, customQueryBar: { ...this.props.customQueryBar, customQuery: undefined } });
            }
        }

        const slicePilotProgressEditor = pilotProgressHistoryItemEntityDescriptor.infoEditor.slice = createSliceFoundation(class SlicePilotProgressEditor extends SliceEntityEditorPage {

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            getSaveOperationName(): string {
                return `pilotProgressHistoryItemService_savePilotProgressHistoryItem`;
            }

            initQueries() {
                super.initQueries();
                this.saveMutation = PILOT_PROGRESS_HISTORY_ITEM_SERVICE_SAVE_PILOT_PROGRESS_HISTORY_ITEM;
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SlicePilotProgressEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SlicePilotProgressEditor>(this),

                tableIsEmbedded() {
                    if (pilotProgressHistoryItemEntityDescriptor.infoTable.routeProps?.routeEntityName === AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().locationForMainRouteEntityName) {
                        return false;
                    }
                    return true;
                },

                getMountedHelperSlice() {
                    if (this.tableIsEmbedded()) {
                        if (AppMetaTempGlobals.appMetaInstance.newRootReducer.isHelperMounted(planningPageInfo.sliceName)) {
                            return AppMetaTempGlobals.appMetaInstance.newRootReducer.getMountedHelper<typeof slicePlanning>(planningPageInfo.sliceName!).dispatchers.employeeEditor;
                        } else if (AppMetaTempGlobals.appMetaInstance.newRootReducer.isHelperMounted(infoCompanyResources.sliceName)) {
                            return AppMetaTempGlobals.appMetaInstance.newRootReducer.getMountedHelper<typeof sliceCompanyResourcesOnlyForExtension>(infoCompanyResources.sliceName!).dispatchers;
                        }
                    }
                    return undefined;
                },

                /**
                * Because the save function (server-side) has additional logic, the default "save-service-function" has beed overridden. 
                * Adapt options.variables for the overridden save function
                */
                superMutation: sliceEntityEditorPageOnlyForExtension.impures.invokeSaveMutation,
                async invokeSaveMutation(options: any) {
                    let localEntity = { ...this.getState().entity };
                    if (this.tableIsEmbedded() && !localEntity.owner) {
                        const planningEmployee = this.getMountedHelperSlice()!.getState().employee;
                        localEntity = {
                            ...localEntity,
                            owner: {
                                id: planningEmployee?.id,
                                objectType: planningEmployee?.objectType,
                                objectVersion: planningEmployee?.objectVersion
                            }
                        };
                    }

                    if (this.getState().duplication) {
                        localEntity = this.setNullEntityValuesWhenDuplicating(localEntity);
                    }
                    options.variables = { entity: localEntity };
                    return await this.superMutation(options);
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    const result = await this.saveSuper(entity);

                    //if no exceptions occured, close the modal
                    if (result !== true && this.tableIsEmbedded()) {
                        this.getDispatchers().dispatch(push(this.getMainLocation()));
                        this.getMountedHelperSlice()!.progressionTab.setInReduxState({ shouldReloadPilotProgressionHistory: true });
                    }
                },

                loadSuper: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    let entity = await this.loadSuper(id);
                    if (id === ADD) {
                        entity = { [TOTAL_TASKS]: 0, [TASKS]: 0, extras: 0, [ProteusConstants.VALID_FROM]: moment().startOf("date").toDate() };
                    }
                    this.getDispatchers().setInReduxState({ entity });
                    return entity;
                },

                setNullEntityValuesWhenDuplicating(entity: any) {
                    return {
                        ...entity,
                        id: null,
                        creationDate: null,
                        creationUser: null,
                        modificationDate: null,
                        modificationUser: null,
                        objectVersion: null
                    };
                },

                getMainLocation() {
                    return AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().locationForMain!.pathname;
                }
            }
        }).setEntityDescriptor(pilotProgressHistoryItemEntityDescriptor);

        pilotProgressHistoryItemEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof slicePilotProgressEditor> & EntityEditorPageProps> {

            getBreadcrumbSections() {
                let breadcrumb = super.getBreadcrumbSections();
                if (!this.props.dispatchers.tableIsEmbedded()) {
                    return breadcrumb;
                }

                breadcrumb = breadcrumb.filter((item: any) => item.key !== "table");
                breadcrumb.splice(1, 0, {
                    key: "mainLocation",
                    content: <><Icon name="chart bar outline" /><Link to={this.props.dispatchers.getMainLocation()}>{_msg(this.props.dispatchers.getMainLocation().substring(1))}</Link></>
                });
                return breadcrumb;
            }

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                let result = super.getPropsForFormSimple();
                result.entityDescriptor.doForFields([ProteusConstants.VALID_FROM, ProteusConstants.VALID_UNTIL, TASKS, TOTAL_TASKS], (fieldDescriptor: FieldDescriptor) => {
                    if (fieldDescriptor.name === ProteusConstants.VALID_FROM) {
                        fieldDescriptor.additionalFieldEditorProps = FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { allowClear: false, format: ProteusConstants.DATE_TIME_FORMAT });
                    } else if (fieldDescriptor.name === ProteusConstants.VALID_UNTIL) {
                        fieldDescriptor.additionalFieldEditorProps = FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT });
                    } else if (fieldDescriptor.name === TASKS || fieldDescriptor.name === TOTAL_TASKS) {
                        fieldDescriptor.type = CUSTOM_NUMBERS;
                    }
                });
                return result;
            }

            componentWillUnmount() {
                if (this.props.dispatchers.tableIsEmbedded()) {
                    this.props.dispatchers.dispatch(push(this.props.dispatchers.getMainLocation()));
                }
            }
        }
    }
}