import { fieldEditors } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { getEmployee_employeeService_employee } from "apollo-gen/getEmployee";
import { getEmployeeSnapshotOnDate_employeeService_employeeSnapshotOnDate } from "apollo-gen/getEmployeeSnapshotOnDate";
import moment from "moment";
import { HISTORY_FIELD_TYPE } from "pages/employeeEditor/customFieldRenderersEditors";
import React from "react";
import { Card, CardContent, CardGroup } from "semantic-ui-react";

type ResourceSummaryProps = {
    date: Date,
    employee: getEmployee_employeeService_employee | undefined | null,
    employeeSnapshot: getEmployeeSnapshotOnDate_employeeService_employeeSnapshotOnDate | undefined
};

export class ResourceSummary extends React.Component<ResourceSummaryProps> {

    renderBusinessCard() {
        return <Card><CardContent>
            {this.renderAddress()}
            {this.renderContact()}
        </CardContent></Card>
    }

    renderAddress() {
        const address = fieldEditors[HISTORY_FIELD_TYPE].retrieveCurrentHistoryItem(this.props.employee?.addressHistory, this.props.date)?.address;
        if (!address) {
            return <>{this.renderValueWithIcon('home', 'address', _msg('ResourceSummary.missingValue.label'))}</>;
        }
        return <>
            {this.renderValueWithIcon('home', 'street', (address.street || "") + " " + (address.houseNumber || "") + " " + (address.box || ""))}
            {this.renderValueWithIcon('', 'city', (address.city?.code || "") + " " + (address.city?.description || ""))}
        </>
    }

    renderContact() {
        const gsm = this.props.employee?.telecomList?.filter(t => t?.type?.code === "GSM" || t?.type?.code === "PGSM")?.[0]?.value || _msg('ResourceSummary.missingValue.label');
        const telephone = this.props.employee?.telecomList?.filter(t => t?.type?.code === "TEL" || t?.type?.code === "PTEL")?.[0]?.value || _msg('ResourceSummary.missingValue.label');
        const email = this.props.employee?.telecomList?.filter(t => t?.type?.code === "MAIL" || t?.type?.code === "PMAIL")?.[0]?.value || _msg('ResourceSummary.missingValue.label');
        const homepage = this.props.employee?.telecomList?.filter(t => t?.type?.code === "WEB")?.[0]?.value || _msg('ResourceSummary.missingValue.label');
        return <>
            {this.renderValueWithIcon('mobile alternate', 'GSM', gsm)}
            {this.renderValueWithIcon('phone', 'Tel', telephone)}
            {this.renderValueWithIcon('mail', 'Email', email)}
            {this.renderValueWithIcon('world', 'Homepage', homepage)}
        </>;
    }

    renderValueWithIcon(icon: string, field: string, value: string | null | undefined) {
        return <div>
            <i className={icon + " icon"}></i> <span data-testid={"ResourceSummary_" + field}>{value || ''}</span>
        </div>;
    }

    renderValue(label: string, value: string | null | undefined) {
        return <div>
            {label}: <b data-testid={"ResourceSummary_" + label} className="">{value || ''}</b>
        </div>;
    }

    renderAdditionalInfo() {
        if (!this.props.employeeSnapshot) {
            return;
        }
        const employeeSnapshot = this.props.employeeSnapshot;
        const department = fieldEditors[HISTORY_FIELD_TYPE].retrieveCurrentHistoryItem(this.props.employee?.departmentHistory, this.props.date)?.department;

        return <>
            <Card className="ResourceSummary_card"><CardContent className="flex-row" style={{ width: '100%' }}>
                <div className="ResourceSummary_additionalInfo">
                    {this.renderValue(_msg('ContractHistoryItem.employeeNumber.label'), employeeSnapshot.contractHistoryItem?.employeeNumber)}
                    {this.renderValue(_msg('Employee.departmentHistory.label'), department?.description)}
                    {this.renderValue(_msg('Employee.detail.categoryHistory.label'), employeeSnapshot.categoryHistoryItem?.category?.description)}
                    {this.renderValue(_msg('EmploymentBreak.label'), employeeSnapshot.locationHistoryItem?.location?.description)}
                    {this.renderValue(_msg('Employee.detail.hourRegimeHistory.label'), employeeSnapshot.hourRegimeHistoryItem?.hourRegime?.description)}
                </div>
                <div className="ResourceSummary_additionalInfo">
                    {this.renderValue(_msg('Employee.gender.label'), this.props.employee?.gender?.description)}
                    {this.renderValue(_msg('Employee.nativeLanguage.label'), this.props.employee?.nativeLanguage?.description)}
                    {this.renderValue(_msg('Employee.nationality.label'), this.props.employee?.nationality?.description)}
                    {this.renderValue(_msg('Employee.detail.dateOfBirth.label'), this.props.employee?.detail?.dateOfBirth ? moment(this.props.employee?.detail?.dateOfBirth).format('DD/MM/yyyy') : undefined)}
                    {this.renderValue(_msg('Employee.detail.birthplace.label'), this.props.employee?.detail?.birthplace?.description)}
                </div>
                <div className="ResourceSummary_additionalInfo">
                    {this.renderValue(_msg('Employee.detail.nationalRegisterNumber.label'), this.props.employee?.detail?.nationalRegisterNumber)}
                    {this.renderValue(_msg('Employee.detail.dimonaNumber.label'), this.props.employee?.detail?.dimonaNumber)}
                    {this.renderValue(_msg('Employee.detail.healthInsuranceNumber.label'), this.props.employee?.detail?.healthInsuranceNumber)}
                    {this.renderValue(_msg('Employee.detail.childAllowanceFund.label'), this.props.employee?.detail?.childAllowanceFund?.description)}
                    {this.renderValue(_msg('Employee.detail.childAllowanceFundNumber.label'), this.props.employee?.detail?.childAllowanceFundNumber)}
                </div>
            </CardContent></Card>
        </>
    }

    render() {
        return <CardGroup >
            {this.renderBusinessCard()}
            {this.renderAdditionalInfo()}
        </CardGroup>
    }
}