'use strict';

/*@ngInject*/
function Resource(DataSource) {

	var config = {
		baseUrl : '/restful/resourceService'
	};

	var ds = new DataSource(config);

	ds.config.getResource = ds.config.getResource || {};
	ds.config.getResource.method = 'POST';
	ds.config.getResource.url = '/getResource';
	if (!ds.getResource) {
		ds.registerOperation('getResource');
	}

	
	return ds;
}

module.exports = Resource;