import { apolloClient, ApolloContext, CatchedGraphQLError, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { getBalance_planningServiceFacadeBean_balance } from "apollo-gen/getBalance";
import { PLANNING_SERVICE_FACADE_BEAN_GET_BALANCE_INCLUDE_DEDUCTION, PLANNING_SERVICE_FACADE_BEAN_GET_LEAVE_DISTRIBUTION_BALANCE } from "graphql/queries";
import moment from "moment";
import { ProteusConstants } from "ProteusConstants";
import React from "react";
import { Form, Table } from "semantic-ui-react";
import { DeductionInfo } from "./DeductionInfo";
import { MetadataProviderHelper } from "utils/MetadataProviderHelper";
import { ProteusUtils } from "ProteusUtils";

const DEDUCTION_RESULT = "DEDUCTION_RESULT";

export interface BalanceType {
    code: string,
    description: string;
}

export const sliceBalanceCalculation = createSliceFoundation(class SliceBalanceCalculation {
    initialState = {
        balanceData: [] as getBalance_planningServiceFacadeBean_balance[],
        balanceHoursData: [] as getBalance_planningServiceFacadeBean_balance[],
        shouldLoadBalanceData: true as boolean,
        shoudlLoadCalculationData: true as boolean,
        balanceToDate: undefined as unknown as Date,
        isHoursBalanceType: false as boolean,
        includeBothCalculationTypes: false as boolean
    };

    reducers = {
        ...getBaseReducers<SliceBalanceCalculation>(this)
    };

    impures = {
        ...getBaseImpures<SliceBalanceCalculation>(this),

        getQueryContext(loadedBalance?: any, isHoursBalanceType?: boolean) {
            return {
                [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                    if (loadedBalance) {
                        // in case an exception occurs when getting leave distribution balance data, set the already loaded balance into state
                        isHoursBalanceType && this.getDispatchers().setInReduxState({ balanceHoursData: loadedBalance, shouldLoadBalanceData: false });
                        !isHoursBalanceType && this.getDispatchers().setInReduxState({ balanceData: loadedBalance, shouldLoadBalanceData: false });
                    }
                    return true;
                }
            };
        },

        async loadBalanceData(employeeId: number | undefined | null, balanceType: string, addDeductionInfo: boolean = true) {
            return (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_GET_BALANCE_INCLUDE_DEDUCTION,
                variables: {
                    person: employeeId,
                    toDate: this.getState().balanceToDate,
                    balanceType: balanceType,
                    includeLeaveDistribution: false,
                    includeDeductionInfo: addDeductionInfo
                },
                context: this.getQueryContext()
            })).data.planningServiceFacadeBean_balance;
        },

        async loadBalanceTabData(employeeId: number | undefined | null, balanceType: string, shouldLoadLeaveDistributionBalance: boolean, addDeductionInfo: boolean = true, context: string = ProteusConstants.SYSTEM) {
            if (!employeeId) {
                return;
            }

            const registrationType = balanceType?.replace(ProteusConstants.HOURS_BALANCE_SUFFIX, "");
            const useBalance = Boolean(MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!, registrationType, "", ProteusConstants.USE_BALANCE_COUNTER, context));
            const useHoursBalance = Boolean(MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!, registrationType, "", ProteusConstants.USE_HOURS_BALANCE_COUNTER, context));
            let isHoursBalanceType: boolean = balanceType?.endsWith(ProteusConstants.HOURS_BALANCE_CALCULATION);
            let balanceData = [];
            let balanceHoursData = [];
            if (!useBalance || isHoursBalanceType) {
                balanceData = [];
            } else {
                balanceData = await this.loadBalanceData(employeeId, registrationType, addDeductionInfo);
            }
            if (!useHoursBalance || !isHoursBalanceType) {
                balanceHoursData = [];
            } else {
                const type = isHoursBalanceType ? balanceType : balanceType + ProteusConstants.HOURS_BALANCE_SUFFIX;
                balanceHoursData = await this.loadBalanceData(employeeId, type, false);
                if (balanceHoursData.length > 0) {
                    isHoursBalanceType = true;
                }
            }

            this.getDispatchers().setInReduxState({ balanceData, balanceHoursData, shouldLoadBalanceData: false, isHoursBalanceType});

            const data = isHoursBalanceType ? balanceHoursData : balanceData;
            if (shouldLoadLeaveDistributionBalance && (data != null && data.length > 0)) {
                const leaveDistributionBalance = (await apolloClient.query({
                    query: PLANNING_SERVICE_FACADE_BEAN_GET_LEAVE_DISTRIBUTION_BALANCE,
                    variables: {
                        person: employeeId,
                        toDate: this.getState().balanceToDate,
                        balanceType
                    },
                    context: {
                        ...this.getQueryContext(isHoursBalanceType ? balanceHoursData : balanceData, isHoursBalanceType),
                        showSpinner: false
                    }
                })).data.planningServiceFacadeBean_leaveDistributionBalance;
                if (isHoursBalanceType) {
                    balanceHoursData = balanceHoursData.concat(leaveDistributionBalance);
                } else {
                    balanceData = balanceData.concat(leaveDistributionBalance);
                }
                this.getDispatchers().setInReduxState({ balanceData, balanceHoursData });
            }
        },
    };
});

type BalanceCalculationExtraProps = {
    employee: number | undefined | null;
    balanceType: BalanceType;
    addDeductionInfo?: boolean;
};

type BalanceCalculationProps = PropsFrom<typeof sliceBalanceCalculation> & BalanceCalculationExtraProps;

export class BalanceCalculation extends React.Component<BalanceCalculationProps> {
    componentWillUnmount(): void {
        this.props.dispatchers.setInReduxState({ balanceData: [], balanceHoursData: [] })
    }

    loadBalanceData = async () => {
        await this.props.dispatchers.loadBalanceTabData(
            this.props.employee,
            this.props.balanceType.code + (this.props.isHoursBalanceType && !this.props.balanceType.code.endsWith(ProteusConstants.HOURS_BALANCE_SUFFIX) ? ProteusConstants.HOURS_BALANCE_SUFFIX : ""),
            this.shouldLoadLeaveDistributionBalance(),
            this.props.addDeductionInfo);
    };

    protected renderBalance() {
        return <>
            {this.props.balanceHoursData.length > 0 ?
                this.renderBalanceTables(this.props.balanceHoursData, this.props.balanceType?.code?.endsWith(ProteusConstants.HOURS_BALANCE_CALCULATION) ? '' : ' - uren') : 
            <></>}
            {this.props.balanceData.length > 0 ?
                this.renderBalanceTables() : 
                <></>}
        </>;
    }

    protected renderBalanceTab() {
        return (
            <div className="BalanceCalculation_balanceTab flex">
                {this.renderBalance()}
            </div>
        );
    }

    protected onBalanceTabDateChange = async (date: any) => {
        this.props.dispatchers.setInReduxState({ balanceToDate: date.toISOString() });
        await this.loadBalanceData();
    };

    renderBalanceDateInput() {
        return (
            <Form>
                <Form.Group inline>
                    <Form.Field>
                        <label>
                            {_msg("RegistrationEditor.balanceTab.date.label")}
                        </label>
                    </Form.Field>
                    <Form.Field>
                        <DatePickerReactCalendar value={moment(this.props.balanceToDate)} format={ProteusConstants.DATE_TIME_FORMAT}
                            onChange={this.onBalanceTabDateChange} allowClear={false}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        );
    }

    protected renderBalanceTables(balanceData = this.props.balanceData, balanceTypeDescriptionSuffix = "") {
        if (balanceData === null || balanceData === undefined || balanceData.length === 0) {
            return;
        }
        const additionalRow = this.props.isHoursBalanceType ? 1 : 0;
        let tables = [];
        tables.push(this.getBalanceTable(this.props.balanceType.description + balanceTypeDescriptionSuffix, [...balanceData].slice(0, 4 + additionalRow)));
        // Check data to see if more than 4 rows are received (because for TimeCredit we expect only 4 rows)

        if (this.props.isHoursBalanceType) {
            { balanceData.length >= 4 + additionalRow && tables.push(this.getBalanceTable(_msg("RegistrationEditor.balanceTab.leaveDistribution.label"), [...balanceData].slice(5, 8))); }
            return tables;
        }

        // Next 4 rows: restitution balance
        { balanceData.length >= 8 + additionalRow && tables.push(this.getBalanceTable(_msg("RegistrationEditor.balanceTab.restitution.label"), [...balanceData].slice(4 + additionalRow, 8 + additionalRow))); }

        // Next 3 rows: deduction balance
        { balanceData.length >= 11 + additionalRow && tables.push(this.getBalanceTable(_msg("RegistrationEditor.balanceTab.deduction.label"), [...balanceData].slice(8 + additionalRow, 11 + additionalRow), this.getDeductionInfoButton([...balanceData].slice(8 + additionalRow, 11 + additionalRow)))); }

        // Next 3 rows: settlement balance
        { balanceData.length >= 14 + additionalRow && tables.push(this.getBalanceTable(_msg("RegistrationEditor.balanceTab.settlement.label"), [...balanceData].slice(11 + additionalRow, 14 + additionalRow))); }

        // Next row: totals 
        { balanceData.length >= 15 + additionalRow && tables.push(this.getBalanceTable(_msg("RegistrationEditor.balanceTab.closingBalance.label"), [...balanceData].slice(14 + additionalRow, 15 + additionalRow))); }

        // Next 3 rows: leave distribution balance
        { balanceData.length >= 18 + additionalRow && tables.push(this.getBalanceTable(_msg("RegistrationEditor.balanceTab.leaveDistribution.label"), [...balanceData].slice(15 + additionalRow, 18 + additionalRow))); }
        return tables;
    }

    getDeductionInfoButton(deductionItems: getBalance_planningServiceFacadeBean_balance[]) {
        // Only the balance item with DEDUCTION_RESULT will contain deduction registrations
        const items = deductionItems.filter(it => it.code == DEDUCTION_RESULT);
        if (items.length === 0) {
            return null;
        }
        let deductionRegistrations = items[0].deductionRegistrations;
        if (deductionRegistrations === null || deductionRegistrations.length === 0) {
            return null;
        }
        deductionRegistrations = [...deductionRegistrations].sort((a: any, b: any) => moment(a.fromDate).valueOf() - moment(b.fromDate).valueOf()) ;
        return <DeductionInfo id="DeductionInfo" resourceId={this.props.employee!} date={this.props.balanceToDate} deductionRegistrations={deductionRegistrations} />
    }

    protected getBalanceTable(firstCellHeaderContent: string, balanceItems: getBalance_planningServiceFacadeBean_balance[], additionalHeader?: any) {
        return (
            <div>
                <Table celled compact unstackable striped>
                    {balanceItems.length > 1 && <Table.Header>
                        {/* render header only if the table has more than one row */}
                        <Table.Row textAlign="center" verticalAlign="middle">
                            {this.renderBalanceTableHeader(firstCellHeaderContent, balanceItems, additionalHeader)}
                        </Table.Row>
                    </Table.Header>
                    }
                    <Table.Body>
                        {this.renderBalanceTableRow(balanceItems)}
                    </Table.Body>
                </Table>
            </div>
        );
    }

    protected renderBalanceTableHeader(firstCellHeaderContent: string, balanceItems: getBalance_planningServiceFacadeBean_balance[], additionalHeader?: any) {
        let header = [];
        header.push(
            <Table.HeaderCell width="8">
                <b>{firstCellHeaderContent}</b>
                {additionalHeader}
            </Table.HeaderCell>
        );
        if (balanceItems[0].counters) {
            for (let i = 0; i < balanceItems[0].counters.length; i++) {
                header.push(
                    <Table.HeaderCell width={balanceItems[0].counters.length === 4 ? "2" : undefined}>
                        <b>{balanceItems[0].counters[i]?.description}</b>
                    </Table.HeaderCell>
                );
            }
        }
        return header;
    }

    protected renderBalanceTableRow(balanceItems: getBalance_planningServiceFacadeBean_balance[]) {
        let rows = [];
        for (let i = 0; i < balanceItems.length; i++) {
            if (balanceItems[i].counters !== null && balanceItems[i].counters?.length !== 0) {
                rows.push(
                    <Table.Row verticalAlign="middle" textAlign="center">
                        {this.renderBalanceTableCell(balanceItems[i], i === balanceItems.length - 1)}
                    </Table.Row>
                );
            }
        }
        return rows;
    }

    protected renderBalanceTableCell(balance: getBalance_planningServiceFacadeBean_balance, isLastRow: boolean) {
        let cells = [];
        let totalRowValue = 0;
        if (balance.counters) {
            for (let i = 0; i < balance.counters.length; i++) {
                let balanceValue: number = balance.counters[i]?.value || 0;
                if (balance.counters[i]?.code !== ProteusConstants.EXTRA_COUNTER) {
                    totalRowValue += balanceValue;
                }
                cells.push(
                    <Table.Cell width={balance.counters.length === 1 ? "8" : undefined} textAlign="center" className={this.getStyledCellClassName(balance.counters[i]?.value || 0, isLastRow)}>
                        {Number.isInteger(balanceValue) ? balanceValue : balanceValue.toFixed(2)}
                    </Table.Cell>
                );
            }
        }
        cells.unshift(
            <Table.Cell textAlign="left" className={this.getStyledCellClassName(totalRowValue, isLastRow)}>
                {balance.description}
            </Table.Cell>
        );
        return cells;
    }

    /**
     * This method add styles to table cells, based on the cell value:
     * value = 0 => the cell will be purple.
     * value > 0 => the cell will be green.
     * value < 0 => the cell will be red.
     */
    protected getStyledCellClassName(value: number, isLastRow: boolean) {
        if (!isLastRow) {
            return "";
        } else if (value === 0) {
            return "RegistrationEditor_balanceTab_inStyleCell";
        } else if (value > 0) {
            return "RegistrationEditor_balanceTab_positiveCell";
        } else {
            return "RegistrationEditor_balanceTab_negativeCell";
        }
    }

    protected shouldLoadLeaveDistributionBalance() {
        return this.props.balanceType.code === ProteusConstants.LEAVE_TYPE_CODE || this.props.balanceType.code === ProteusConstants.LEAVE_HOURS_BALANCE_TYPE_CODE;
    }

    render() {
        return <div className="RegistrationEditor_balanceTab flex">
            {this.renderBalance()}
        </div>;
    }
}