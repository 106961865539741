import { apolloClient } from "@crispico/foundation-react/apolloClient";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { DoubleFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DoubleFieldEditor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { CodeEntity } from "apollo-gen/CodeEntity";
import { COMPANY_SERVICE_GET_COMPANIES_BY_CRITERIA, EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CRITERIA, EMPLOYEE_SERVICE_GET_PERSON_SNAPSHOTS_BY_CRITERIA, PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_STATUSES_BY_TYPE } from "graphql/queries";
import { Moment } from "moment";
import { InputProps } from "semantic-ui-react";
import React from "react";
import { Registration } from "./RegistrationEditor";
import { PersonAssociationFieldEditor } from "pages/realization/RealizationEntityDescriptor";
import { EmployeeCriteriaInput } from "apollo-gen/globalTypes";
import { getRealizationsByCriteria_educationServiceFacadeBean_realizations } from "apollo-gen/getRealizationsByCriteria";
import moment from "moment";
import { ProteusConstants } from "ProteusConstants";
import { DatePickerFieldEditor, DatePickerFieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { AssociationEditorProps, AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { SelectExt, SelectExtOption } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { ActionMeta } from "react-select";
import { FieldEditor, FieldEditorProps, ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { ScriptableUiHighlightWrapper, WithHW, ScriptableUiImpl, ScriptableUi } from "@famiprog-foundation/scriptable-ui";
import { BooleanFieldEditor, BooleanFieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldEditors/BooleanFieldEditor";
import { StringFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/StringFieldEditor";
import { DatePicker } from "antd";

export const remarksTableDescriptor = new EntityDescriptor({ name: "RegistrationEditorRemarks" })
    .addFieldDescriptor({ name: "value", type: FieldType.text })
    .addFieldDescriptor({ name: "creationUser", type: FieldType.string })
    .addFieldDescriptor({ name: "creationDate", type: FieldType.string })
    .addFieldDescriptor({ name: "modificationUser", type: FieldType.string })
    .addFieldDescriptor({ name: "modificationDate", type: FieldType.string })

export class CustomBooleanFieldEditor extends BooleanFieldEditor {

    constructor(props: BooleanFieldEditorProps) {
        super(props);

        this.scriptableUiImpl = ScriptableUi.extendImpl(this.scriptableUiImpl, original => ({
            setFieldValue: (value: any) => {
                original.setFieldValue(value);
                (this.props as any).onEntityChange();
            }
        }));
    }
}

export class CustomBooleanFieldDescriptor extends FieldDescriptor {

    constructor(props: any) {
        super();
        this.state = { onEntityChange: props }
    }

    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
        const newProps = { ...props, onEntityChange: this.state.onEntityChange };
        return React.createElement(CustomBooleanFieldEditor as any, newProps as FieldEditorProps);
    }
}

interface CustomDateFieldEditorProps extends FieldEditorProps {
    onDateChange: (date: Moment | null) => void | boolean;
    startOfDay?: boolean;
}

// TODO #35440  - replaces CustomDateFieldEditor in agenda because the editor does not open on iOS, modify after fix
export class CustomDateFieldWithDatePickerEditor extends FieldEditor<string, DatePickerFieldEditorProps> {

    constructor(props: DatePickerFieldEditorProps) {
        super(props);
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const value = this.getValue();
        const valueAsMoment = value ? moment(value) : undefined;
        return <DatePicker style={{ width: "100%" }} value={valueAsMoment} format={"DD/MM/YYYY HH:mm"} disabled={valueAsMoment && this.props.disabledDate ? this.props.disabledDate(valueAsMoment) : false}
            onChange={d => {
                if (!this.props.formikProps || !this.props.fieldDescriptor) {
                    return;
                }
                const valueBeforeChange = this.getValue();
                this.props.formikProps.setFieldValue(this.props.fieldDescriptor.getFieldName(), d);
                const allowChange = (this.props as CustomDateFieldEditorProps).onDateChange(moment(d));
                if (allowChange === false) {
                    this.props.formikProps.setFieldValue(this.props.fieldDescriptor.getFieldName(), valueBeforeChange);
                }
            }}
        />
    }
}

export class CustomDateFieldEditor extends DatePickerFieldEditor {

    constructor(props: DatePickerFieldEditorProps) {
        super(props);

        this.scriptableUiImpl = ScriptableUi.extendImpl(this.scriptableUiImpl, original => ({
            setFieldValue: (value: any) => {
                let newDate = value ? (this.props as CustomDateFieldEditorProps).startOfDay ? moment(value).startOf("date").toISOString() : value : null;
                original.setFieldValue(newDate);
                (this.props as CustomDateFieldEditorProps).onDateChange(moment(value));
            }
        }));
    }
}

interface CustomDoubleFieldEditorProps extends FieldEditorProps {
    onChangeValue: () => void;
}

export class CustomDoubleFieldEditor extends DoubleFieldEditor<number, CustomDoubleFieldEditorProps> {
    
    constructor(props: CustomDoubleFieldEditorProps) {
        super(props);

        this.scriptableUiImpl = ScriptableUi.extendImpl(this.scriptableUiImpl, original => ({
            setFieldValue: (value: any) => {
                this.props.onChangeValue();
                original.setFieldValue(Number(value));
            }
        }));
    }

    protected getInputProps(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper): InputProps | undefined {
        return { ...super.getInputProps(s, hw), step: "any", min: 0 };
    }
}

export class CustomStringFieldDescriptor extends FieldDescriptor {

    constructor(props: any) {
        super();
        this.state = { onEntityChange: props }
    }

    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
        const newProps = { ...props, onChange: this.state.onEntityChange };
        return React.createElement(CustomStringFieldEditor as any, newProps as FieldEditorProps);
    }
}

export class CustomStringFieldEditor extends StringFieldEditor {

    protected scriptableUiImpl: ScriptableUiImpl<ScriptableUiFieldEditor.Main> = {
        setFieldValue: value => {
            this.scriptableUiImpl.setFieldValue(value);
            (this.props as any).onChange();
        }
    }
}

interface MedicalCheckupFieldEditorProps extends FieldEditorProps {
    medicalCheckupTypes: CodeEntity[];
}

export class MedicalCheckupFieldEditor extends AssociationFieldEditor<MedicalCheckupFieldEditorProps> {

    onChange = (value: any, action: ActionMeta<any>, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        s.setFieldValue(hw, value ? value.value : null);
        if (this.props.onChange) {
            this.props.onChange(value ? value.value : null);
        }
    }

    getValue() {
        let value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values);
        let type = this.props.medicalCheckupTypes.find((element) => element.code === value);
        return type ? {
            entity: type,
            label: type!.description,
            value: type!.code!
        } : undefined;
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const options = this.props.medicalCheckupTypes.map((item: CodeEntity) => ({
            entity: item,
            label: item.description,
            value: item.code!
        }));

        return <SelectExt options={options} clearable={false} onChange={(data, action) => this.onChange(data, action, s, hw)}
            value={this.getValue()} noOptionsMessage={() => _msg("general.noResultsFound")}
            isMulti={false} closeMenuOnSelect />
    }
}

interface FlexibleAvailabilityFieldEditorProps extends FieldEditorProps {
    workperiodTypes: CodeEntity[];
}

export class FlexibleAvailabilityFieldEditor extends AssociationFieldEditor<FlexibleAvailabilityFieldEditorProps> {

    onChange = (value: any, action: ActionMeta<any>, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        s.setFieldValue(hw, value ? value.value : null);
        if (this.props.onChange) {
            this.props.onChange(value ? value.value : null);
        }
    }

    getValue() {
        let value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values);
        let type = this.props.workperiodTypes.find((element) => element.code === value);

        return type ? {
            entity: type,
            label: type!.description,
            value: type!.code!
        } : undefined;
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const options = this.props.workperiodTypes.map((item: CodeEntity) => ({
            entity: item,
            label: item.description,
            value: item.code!
        }));

        return <SelectExt options={options} clearable={false} onChange={(data, action) => this.onChange(data, action, s, hw)}
            value={this.getValue()} noOptionsMessage={() => _msg("general.noResultsFound")}
            isMulti={false} closeMenuOnSelect />
    }
}

interface PersonByCriteriaAssociationEditorProps {
    criteria: EmployeeCriteriaInput,
    date: string | Date
}

export class PersonByCriteriaAssociationFieldEditor extends PersonAssociationFieldEditor<PersonByCriteriaAssociationEditorProps>{
    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `employeeService_personSnapshotsByCriteria`;
        let query = EMPLOYEE_SERVICE_GET_PERSON_SNAPSHOTS_BY_CRITERIA;

        if (!searchQuery || (searchQuery && searchQuery.length < 2)) {
            this.setState({ entities: [] });
            return;
        }

        let personSnapshots = (await apolloClient.query({
            query: query,
            variables: {
                criteria: {
                    ...this.props.criteria,
                    searchText: searchQuery
                },
                date: this.props.date
            },
            context: { showSpinner: false }
        })).data[name];

        this.setState({ entities: personSnapshots });
    }

    protected getLabel(entity: any): string {
        if (entity !== undefined) {
            return (entity.name ? entity.name + " " : "") +
                (entity.firstName ? entity.firstName + " " : "") +
                (entity.company?.name ? " (" + entity.company.name + ")" : "");
        }
        return "";
    }
}

interface CompanyByCriteriaAssociationEditorProps extends AssociationEditorProps {
    criteria: {
        companyTypeCodes: string[],
        includeContactPersons: boolean
    }
}

export class CompanyByCriteriaAssociationFieldEditor extends AssociationFieldEditor<CompanyByCriteriaAssociationEditorProps>{
    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `companyService_companiesByCriteria`;
        let query = COMPANY_SERVICE_GET_COMPANIES_BY_CRITERIA;

        let companies = (await apolloClient.query({
            query: query,
            variables: {
                param: {
                    companyTypeCodes: this.props.criteria.companyTypeCodes,
                    includeContactPersons: this.props.criteria.includeContactPersons,
                    searchText: searchQuery
                }
            },
            context: { showSpinner: false }
        })).data[name];

        this.setState({ entities: companies });
    }
}

export class EmployeeByCriteriaAssociationFieldEditor extends PersonByCriteriaAssociationFieldEditor {
    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `employeeService_employeeSnapshotsByCriteria`;
        let query = EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CRITERIA;

        if (!searchQuery || (searchQuery && searchQuery.length < 2)) {
            this.setState({ entities: [] });
            return;
        }

        let employeeSnapshots = (await apolloClient.query({
            query: query,
            variables: {
                criteria: {
                    ...this.props.criteria,
                    searchText: searchQuery
                },
                date: this.props.date
            },
            context: { showSpinner: false }
        })).data[name];

        this.setState({ entities: employeeSnapshots });
    }

    protected getLabel(entity: any): string {
        if (entity !== undefined && entity.name !== undefined) {
            return entity?.name + " " + entity?.firstName +
                (entity?.contractHistoryItem?.employeeNumber !== null && entity?.contractHistoryItem?.employeeNumber !== undefined ?
                    " (" + entity?.contractHistoryItem?.employeeNumber + ")" : "");
        }
        return "";
    }
}

interface RealizationFieldEditorProps extends FieldEditorProps {
    realizations: getRealizationsByCriteria_educationServiceFacadeBean_realizations[];
}

export class RealizationFieldEditor extends AssociationFieldEditor<RealizationFieldEditorProps> {
    getValue() {
        return this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values)?.id || {
            entity: undefined,
            label: "(New)",
            value: 0
        };
    }

    formatDate(date: string | number) {
        return moment(new Date(date)).format(ProteusConstants.DATE_FORMAT).toString();
    }

    onChange = (data: any, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        const value = this.props.realizations.filter((item: getRealizationsByCriteria_educationServiceFacadeBean_realizations) => {
            if (data.value === 0) {
                return item.id === undefined || item.id === null;
            } else {
                return item.id === data.value;
            }
        })[0];
        s.setFieldValue(hw, value);
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const options = this.props.realizations.map((item: getRealizationsByCriteria_educationServiceFacadeBean_realizations) => ({
            // because the value must be of type string | number | boolean, it can not handle an "undefined" value
            // for the realization without an id (New realization), set the value to 0 
            entity: item,
            label: item.id ? _msg("realization.date") + ": " + this.formatDate(item.date) + " " +
                _msg("realization.dueDate") + ": " + (item.dueDate ? this.formatDate(item.dueDate) : "-") :
                ("(" + _msg("dto_crud.new") + ")"),
            value: item.id ? item.id : 0
        }));

        let selectedValue = this.getValue();
        selectedValue = options.find((option) => option.value === selectedValue);

        return <SelectExt options={options} clearable={false} onChange={(data, action) => this.onChange(data, s, hw)} isMulti={false}
            value={options.length > 0 ? selectedValue : undefined} closeMenuOnSelect
            noOptionsMessage={() => _msg("general.noResultsFound")} />
    }
}

interface RegistrationStatusAssociationFieldEditorProps extends FieldEditorProps {
    registrationType: CodeEntity;
}

export class RegistrationStatusAssociationFieldEditor extends AssociationFieldEditor<RegistrationStatusAssociationFieldEditorProps> {
    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `planningServiceFacadeBean_registrationStatuses`;
        let query = PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_STATUSES_BY_TYPE;

        if (!this.props.registrationType) {
            this.setState({ entities: [] });
            return;
        }

        let result = (await apolloClient.query({
            query: query,
            variables: {
                type: {
                    id: this.props.registrationType.id,
                    code: this.props.registrationType.code,
                    objectVersion: this.props.registrationType.objectVersion
                }
            },
            context: { showSpinner: false }
        })).data[name];

        this.setState({ entities: result });
    }
}

interface RelatedRegistrationFieldEditorProps extends FieldEditorProps {
    relatedRegistrations: Registration[];
    getCustomLabelForRegistration: (registration: Registration) => string;
    onRelatedRegistrationChange: (data: any, that: any) => void;
}

export class RelatedRegistrationsFieldEditor extends AssociationFieldEditor<RelatedRegistrationFieldEditorProps> {

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const options: SelectExtOption[] = this.props.relatedRegistrations.map((item: Registration) => ({
            label: this.props.getCustomLabelForRegistration(item),
            value: item.id!,
            entity: item,
        }));

        options.push({
            label: _msg("Registration.none.label"),
            value: "",
            entity: undefined,
        });

        let selectedValue = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values)?.id || "";
        selectedValue = options.find((option) => option.value === selectedValue);

        return <SelectExt value={selectedValue} options={options} clearable isMulti={false}
            onChange={async (data, action) => await this.props.onRelatedRegistrationChange(data, this)}
            noOptionsMessage={() => _msg("general.noResultsFound")} closeMenuOnSelect />
    }
}

interface WorkflowProcessFieldEditorProps extends FieldEditorProps {
    workflowProcesses: { name: string, description: string }[];
}

export class WorkflowProcessesFieldEditor extends AssociationFieldEditor<WorkflowProcessFieldEditorProps> {

    onChange = (data: any, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        let value = null;
        if (data && data.length > 0) {
            value = ""
            for (let i = 0; i < data.length; i++) {
                value += data[i].value + " ";
            }
        }
        s.setFieldValue(hw, value);
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    getValue() {
        let value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values) || "";
        if (value.length > 0) {
            value = value.split(" ");
            value.pop();
        } else {
            value = [];
        }
        for (let i = 0; i < value.length; i++) {
            let completeValue = this.props.workflowProcesses.find((element) => element.name === value[i]);
            if (!completeValue) {
                continue;
            }
            value[i] = {
                label: completeValue!.description,
                value: completeValue!.name,
                entity: completeValue
            }
        }
        return value;
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const options = this.props.workflowProcesses.map((item: { name: string, description: string }) => ({
            label: item.description,
            value: item.name,
            entity: item
        }));

        return <SelectExt options={options} clearable isMulti value={this.getValue()}
            onChange={(data, action) => this.onChange(data, s, hw)} noOptionsMessage={() => _msg("general.noResultsFound")} />
    }
}