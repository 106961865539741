'use strict'

/*@ngInject*/
function Resources(DataSource) {

	var config = {
		baseUrl : '/restful/resourcesServiceEndpoint',
	}

	var ds = new DataSource(config);

	ds.config.getResources = ds.config.getResources || {};
	ds.config.getResources.method = 'POST';
	ds.config.getResources.url = '/resources';
	if (!ds.getResources) {
		ds.registerOperation('getResources');
	}

	ds.config.getResourceSnapshots = ds.config.getResourceSnapshots || {};
	ds.config.getResourceSnapshots.method = 'POST';
	ds.config.getResourceSnapshots.url = '/resourceSnapshots';
	if (!ds.getResourceSnapshots) {
		ds.registerOperation('getResourceSnapshots');
	}

	ds.config.getGroups = ds.config.getGroups || {};
	ds.config.getGroups.method = 'GET';
	ds.config.getGroups.url = '/getGroupSnapshotByContext';
	if (!ds.getGroups) {
		ds.registerOperation('getGroups');
	}

	ds.config.getGroup = ds.config.getGroups || {};
	ds.config.getGroup.method = 'GET';
	ds.config.getGroup.url = '/getGroupsSnapshotByCode';
	if (!ds.getGroup) {
		ds.registerOperation('getGroup');
	}

	ds.config.getGroupResources = ds.config.getGroupResources || {};
	ds.config.getGroupResources.method = 'GET';
	ds.config.getGroupResources.url = '/getGroupResources';
	if (!ds.getGroupResources) {
		ds.registerOperation('getGroupResources');
	}

	ds.config.getGroupResourceSnapshots = ds.config.getGroupResourceSnapshots
			|| {};
	ds.config.getGroupResourceSnapshots.method = 'GET';
	ds.config.getGroupResourceSnapshots.url = '/getGroupResourceSnapshots';
	if (!ds.getGroupResourceSnapshots) {
		ds.registerOperation('getGroupResourceSnapshots');
	}

	ds.config.leaveGroups = ds.config.leaveGroups || {};
	ds.config.leaveGroups.method = 'GET';
	ds.config.leaveGroups.url = '/selectableLeaveGroups';
	if (!ds.leaveGroups) {
		ds.registerOperation('leaveGroups');
	}
	
	ds.config.getGroupSnapshotsOfMembers = ds.config.getGroupSnapshotsOfMembers || {};
	ds.config.getGroupSnapshotsOfMembers.method = 'GET';
	ds.config.getGroupSnapshotsOfMembers.url = '/getGroupSnapshotsOfMembers';
	if (!ds.getGroupSnapshotsOfMembers) {
		ds.registerOperation('getGroupSnapshotsOfMembers');
	}
	
	ds.config.getGroupRelations = ds.config.getGroupRelations || {};
	ds.config.getGroupRelations.method = 'GET';
	ds.config.getGroupRelations.url = '/groupRelations';
	if (!ds.getGroupRelations) {
		ds.registerOperation('getGroupRelations');
	}
	
	ds.config.getGroupSnapshotsByIds = ds.config.getGroupSnapshotsByIds || {};
	ds.config.getGroupSnapshotsByIds.method = 'GET';
	ds.config.getGroupSnapshotsByIds.url = '/groupSnapshotsByIds';
	if (!ds.getGroupSnapshotsByIds) {
		ds.registerOperation('getGroupSnapshotsByIds');
	}

	ds.config.getGroupResourceIds = ds.config.getGroupResourceIds || {};
	ds.config.getGroupResourceIds.method = 'GET';
	ds.config.getGroupResourceIds.url = '/getGroupResourceIds';
	if (!ds.getGroupResourceIds) {
		ds.registerOperation('getGroupResourceIds');
	}

	ds.config.getCompanies = ds.config.getCompanies || {};
	ds.config.getCompanies.method = 'POST';
	ds.config.getCompanies.url = '/companies';
	if (!ds.getCompanies) {
		ds.registerOperation('getCompanies');
	}

	return ds;
}

module.exports = Resources;