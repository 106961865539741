'use strict';

module.exports = {
	EMPLOYEE_EXPLORER : 'EMPLOYEE_EXPLORER',
	CONTRACT : 'CONTRACT',

	CATEGORY : 'category',
	TEAM : 'team',
	LOCATION : 'location',
	HOUR_REGIME : 'hourRegime',
	RESOURCE : 'resource'
}