import { apolloClient } from "@crispico/foundation-react";
import { FieldRendererProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { getGroupEmployeesByIds_employeeService_employees } from "apollo-gen/getGroupEmployeesByIds";
import { getGroupMembers_employeeService_employeeSnapshotsByGroup } from "apollo-gen/getGroupMembers";
import { HistoryTable, sliceHistoryTable } from "components/HistoryTable";
import { CellProps } from "fixed-data-table-2";
import { ROTATION_SYSTEM_SERVICE_GET_ROTATION_SYSTEMS, SEQUENCE_SERVICE_FACADE_BEAN_GET_GROUP_SEQUENCE_OVERVIEW } from "graphql/queries";
import _ from "lodash";
import moment, { Moment } from "moment";
import React from "react";
import { Accordion, Button, Form, Grid, Icon } from "semantic-ui-react";
import { groupResourcesDescriptor, MoveEmployeesTableRenderer, sequenceDefinitionHistoryDescriptor, SequenceTabTableRenderer } from "./customFieldRenderersEditors";
import { HistoryItem, RESOURCES } from "./GroupsManagement";
import { EMPLOYEE, EMPLOYEE_SNAPSHOT } from "./GroupSnapshotTab";
import { sliceGroupsManagementBase } from "./sliceGroupsManagementBase";
import { ResourceTable } from "./SuccessionTab";
// @ts-ignore
import omitDeep from "omit-deep-lodash";
import { PopupWithHelpTooltip } from "@crispico/foundation-react/components/semanticUiReactExt";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { ProteusConstants } from "ProteusConstants";
import { ShortcutRefForTest } from "@famiprog-foundation/tests-are-demo";

export const JUMP = "jump";
export const STARTING_POSITION = "startingPosition";
export const ROTATION_SYSTEM = "rotationSystem";
export const REFERENCE_DATE = "referenceDate";
export const GREEN_EMPLOYEE = "var(--green)";
export const RED_EMPLOYEE = "var(--red)";

export interface SequenceDefinitionHistory extends HistoryItem {
    referenceDate: string | Date
    jump: number,
    startingPosition: number,
    resources: number[],
    rotationSystem: RotationSystem
}

export interface RotationSystem {
    id: number,
    code: string,
    description: string,
    objectVersion: number
}

export const sliceSequenceTab = createSliceFoundation(class SliceSequenceTab {

    initialState = {
        rotationSystems: undefined as RotationSystem[] | undefined,
        loadedEmployees: undefined as unknown as getGroupEmployeesByIds_employeeService_employees[],
        groupMembers: undefined as unknown as getGroupMembers_employeeService_employeeSnapshotsByGroup[],
        firstSequenceEmployeeId: undefined as unknown as number,
        editEnabled: false as boolean,
        sequenceDate: '' as string | Date,
        sequenceDefinitionTableActive: true
    }

    nestedSlices = {
        sequenceDefinitionHistoryTable: sliceHistoryTable,
        sequenceDefinitionResourcesTable: sliceEntityTableLight
    }

    reducers = {
        ...getBaseReducers<SliceSequenceTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceSequenceTab>(this),

        async getRotationSystem() {
            if (this.getState().rotationSystems === undefined) {
                const rotationSystems = (await apolloClient.query({ query: ROTATION_SYSTEM_SERVICE_GET_ROTATION_SYSTEMS })).data.rotationSystemService_rotationSystems;
                this.getDispatchers().setInReduxState({ rotationSystems });
            }
        },

        async getGroupMembers(groupCode: string, fromDate: string | Date, toDate: string | Date) {
            const groupMembers = await sliceGroupsManagementBase.impures.getGroupMembers(groupCode, fromDate, toDate);
            this.getDispatchers().setInReduxState({ groupMembers });
        }
    }
})

type PropsNotFromState = {
    selectedGroup: any;
    groupIsPartOfSequenceContext: boolean;
    addOrUpdateSequenceDefinitionHistory: (historyItems: SequenceDefinitionHistory[]) => void;
    deleteSequenceDefinitionHistory: (remainingSequenceDefinition: SequenceDefinitionHistory[]) => void;
    addResourceToSequenceHistoryItem: (sequenceHistoryItem: SequenceDefinitionHistory, resource: number | null) => void;
    deleteResourceFromSequenceHistoryItem: (sequenceHistoryItem: SequenceDefinitionHistory, resourceIndex: number) => void;
    moveSequenceResourcesUpOrDown: (sequenceHistoryItem: SequenceDefinitionHistory, resourceId: number, moveUp: boolean) => void;
    addGroupMembersToSequence: (sequenceHistoryItem: SequenceDefinitionHistory, groupMembers: getGroupMembers_employeeService_employeeSnapshotsByGroup[], sequenceMembers: number[]) => number[] | undefined;
}

export class SequenceTab extends React.Component<PropsFrom<typeof sliceSequenceTab> & PropsNotFromState> {
    protected sequenceDefinitionHistoryTableRef = React.createRef<HistoryTable>();
    protected sequenceDefinitionResourcesTableRef = React.createRef<EntityTableLight>();

    getSequenceDefinitionHistoryTableRef() {
        return this.sequenceDefinitionHistoryTableRef;
    }

    async setSequenceDefinitionHistoryTable(sequenceDefinition: SequenceDefinitionHistory[]) {
        const selectedId = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!;
        const oldEntities = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities() || [];
        const entities = sequenceDefinition.sort((a: SequenceDefinitionHistory, b: SequenceDefinitionHistory) => moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf());
        // historyItems are sorted by validFrom value
        this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(entities);

        // selected history in table changed (row selection changed) or resources for the selected history item has been modified (added or removed a resource)
        if (_.isEqual(oldEntities[selectedId]?.resources, entities[selectedId]?.resources)) {
            this.onSequenceDefinitionHistoryChanged(selectedId);
        }

        let selectedEntityInTable = entities[selectedId];
        let prevSelectedEntityInTable = oldEntities[selectedId];

        // order of employees changed
        if (selectedEntityInTable !== undefined && !_.isEqual(selectedEntityInTable.resources, prevSelectedEntityInTable?.resources) &&
            selectedEntityInTable.resources?.length === prevSelectedEntityInTable?.resources?.length) {
            await this.getResourcesEmployees(selectedEntityInTable.resources, false);
        }
    }

    setSequenceResourcesTable(sequenceResources: getGroupEmployeesByIds_employeeService_employees[]) {
        let sequenceResourcesForTable: ResourceTable[] = [];
        for (let i = 0; i < sequenceResources.length; i++) {
            sequenceResourcesForTable.push({
                [EMPLOYEE]: {
                    name: sequenceResources[i].name,
                    firstName: sequenceResources[i].firstName,
                    id: sequenceResources[i].id,
                    employeeNumber: sequenceResources[i].detail?.contractHistory?.[0]?.employeeNumber
                }
            });
        }
        this.sequenceDefinitionResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(sequenceResourcesForTable);
    }

    async getResourcesEmployees(employeesIds: number[], loadEmployees: boolean) {
        let employeesData: getGroupEmployeesByIds_employeeService_employees[] = [];
        if (loadEmployees) {
            const employees: getGroupEmployeesByIds_employeeService_employees[] = await sliceGroupsManagementBase.impures.getResourcesEmployees(employeesIds);
            this.props.dispatchers.setInReduxState({ loadedEmployees: employees });
            employeesData = employees;
        } else {
            employeesData = this.props.loadedEmployees;
        }
        employeesData = [...employeesData].sort(function (a: getGroupEmployeesByIds_employeeService_employees, b: getGroupEmployeesByIds_employeeService_employees) {
            return employeesIds.indexOf(a.id!) - employeesIds.indexOf(b.id!);
        });
        this.setSequenceResourcesTable(employeesData);
    }

    async getSequenceOverview(groupCode: string, fromDate: string | Date, sequenceDefinition: any) {
        const selectedSequenceDefinitionId = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!;
        const selectedSequenceDefinition = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[selectedSequenceDefinitionId];
        if (selectedSequenceDefinition && selectedSequenceDefinition.resources.length === 0) { return; }
        let toDate = new Date(fromDate);
        toDate.setMonth(toDate.getMonth() + 1);
        const sequenceOverview = (await apolloClient.query({
            query: SEQUENCE_SERVICE_FACADE_BEAN_GET_GROUP_SEQUENCE_OVERVIEW,
            variables: {
                groupCode: groupCode,
                from: fromDate,
                to: toDate,
                maxNumberOfRosterRegistrations: 5,
                defaultSequenceDefinitions: [omitDeep(sequenceDefinition, ['__typename'])]
            }
        })).data.sequenceServiceFacadeBean_groupSequenceOverview;

        let firstSequenceEmployeeId = undefined;
        if (sequenceOverview?.employeesSequences) {
            for (let i = 0; i < sequenceOverview.employeesSequences.length; i++) {
                if (sequenceOverview.employeesSequences[i].firstSequenceNumber === 0) {
                    continue;
                }
                let sequenceDate = new Date(this.props.sequenceDate)
                if (sequenceDate && sequenceOverview.employeesSequences[i].sequencesItems[0]?.from &&
                    sequenceOverview.employeesSequences[i].sequencesItems[0]?.to) {
                    let from = new Date(sequenceOverview.employeesSequences[i].sequencesItems[0].from)
                    let to = new Date(sequenceOverview.employeesSequences[i].sequencesItems[0].to)
                    if (from <= sequenceDate && sequenceDate <= to) {
                        firstSequenceEmployeeId = sequenceOverview.employeesSequences[i].employee.id;
                    }
                }
                break;
            }
        }
        this.props.dispatchers.setInReduxState({ firstSequenceEmployeeId });
    }

    getDefaultValuesForFields = () => {
        return {
            jump: 0,
            startingPosition: 0,
            referenceDate: moment().startOf("date").toDate(),
            rotationSystem: this.props.rotationSystems === undefined ? undefined : this.props.rotationSystems[0]
        };
    }

    renderSequenceResourcesDescriptor(sequenceHistoryItem: SequenceDefinitionHistory) {
        fieldRenderers[EMPLOYEE_SNAPSHOT] = (props: FieldRendererProps) => {
            let newProps = {
                ...props,
                moveEmployeeUpOrDown: this.props.moveSequenceResourcesUpOrDown,
                historyItem: sequenceHistoryItem,
                editEnabled: this.props.editEnabled
            };
            return <MoveEmployeesTableRenderer {...newProps} table={this.sequenceDefinitionResourcesTableRef} />
        }
    }

    addAllGroupMembers() {
        const selectedId = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!;
        const selectedEntityInTable = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[selectedId];
        const members = this.props.addGroupMembersToSequence(selectedEntityInTable, this.props.groupMembers, selectedEntityInTable.resources);
        if (members) {
            this.addEmployees(members);
        }
    }

    async addEmployees(resourcesId: any[]) {
        let newEntities = [];
        const entities = [...(this.sequenceDefinitionResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities() || [])];
        const notInTable = resourcesId.filter(id => !entities.map(e => e[EMPLOYEE].id).includes(id));
        if (notInTable.length === 0) {
            return;
        }
        newEntities = this.props.loadedEmployees.filter(e => notInTable.includes(e.id));
        const notLoaded = notInTable.filter(id => !this.props.loadedEmployees?.map(e => e.id).includes(id));
        if (notLoaded.length > 0) {
            const employees: getGroupEmployeesByIds_employeeService_employees[] = await sliceGroupsManagementBase.impures.getResourcesEmployees(notLoaded);
            let loadedEmployees = this.props.loadedEmployees ? [...this.props.loadedEmployees].concat(employees) : employees;
            this.props.dispatchers.setInReduxState({ loadedEmployees });
            newEntities.push(...employees);
        }
        newEntities = newEntities.map(e => {
            return {
                [EMPLOYEE]: {
                    name: e.name,
                    firstName: e.firstName,
                    id: e.id,
                    employeeNumber: e.detail?.contractHistory?.[0]?.employeeNumber
                }
            }
        });
        entities.push(...newEntities);
        this.sequenceDefinitionResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(entities);
    }

    render() {
        fieldRenderers["RotationSystem"] = SequenceTabTableRenderer;
        const selectedId = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!;
        const selectedEntityInTable = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[selectedId];
        return (
            <>
                <ShortcutRefForTest objectToPublish={this} />
                <div className="GroupsManagement_flexGrow flex">
                    <Accordion className="GroupSnapshotTab_noPaddingTopRow" >
                        <Accordion.Title className="SequenceTab_sequenceDefinitionTitle" active={this.props.sequenceDefinitionTableActive} onClick={() => this.props.dispatchers.setInReduxState({ sequenceDefinitionTableActive: !this.props.sequenceDefinitionTableActive })} >
                            <div data-testid="SequenceTab_SequenceDefinitionsLabel">
                                <Icon name="dropdown" />
                                {_msg("SequenceDefinitions.label")}
                            </div>
                        </Accordion.Title>
                        <Accordion.Content className="SequenceTab_historyTable" active={this.props.sequenceDefinitionTableActive}  >
                            <HistoryTable {...this.props.sequenceDefinitionHistoryTable} dispatchers={this.props.dispatchers.sequenceDefinitionHistoryTable} ref={this.sequenceDefinitionHistoryTableRef}
                                onDelete={(remainingHistory: SequenceDefinitionHistory[]) => {
                                    this.props.deleteSequenceDefinitionHistory(remainingHistory);
                                    this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                                }}
                                onSave={(history: SequenceDefinitionHistory[]) => {
                                    this.props.addOrUpdateSequenceDefinitionHistory(history);
                                    this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                                    this.sequenceDefinitionResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                                }}
                                onAdd={sliceHistoryTable.reducers.onAdd} getDefaultValuesForFields={this.getDefaultValuesForFields}
                                entityDescriptor={sequenceDefinitionHistoryDescriptor} actions={{ showDeleteButton: false, showAddButton: true }}
                                formCustomizer={{ headerContent: _msg("GroupResourcesHistoryItem.addForm.label"), headerIcon: "history" }}
                                fieldsToBeCopied={[RESOURCES, ROTATION_SYSTEM, STARTING_POSITION, JUMP, REFERENCE_DATE]}
                                data-testid="SequenceTab_SequenceDefinitionTable" onSelectItem={(itemId: any) => this.onSelectedSequenceDefinitionHistoryChanged(itemId)}
                            />
                        </Accordion.Content>
                    </Accordion>
                    <Grid data-testid="SequenceTab_sequenceView" className="GroupSnapshotTab_staticGroup GroupsManagement_flexGrow">
                        <Grid.Row className="GroupSnapshotTab_noPaddingBottomRow">
                            <Grid.Column className={"SequenceTab_resourcesTable" + (this.props.editEnabled ? "" : " SequenceTab_disabled")}>
                                <div data-testid="SequenceTab_buttons" className="SequenceTab_buttons ui form">
                                    {this.props.groupIsPartOfSequenceContext &&
                                        <Button data-testid="SequenceTab_testSequenceButton" primary onClick={() => window.open("/#/Sequence/" + this.props.selectedGroup.code, "_blank")}>
                                            {_msg("SequenceTab.testSequence.label")}
                                        </Button>
                                    }
                                    <Button data-testid="SequenceTab_addAllGroupMembersButton" onClick={() => this.addAllGroupMembers()} disabled={selectedId === undefined || !this.props.editEnabled}>
                                        {_msg("SuccessionTab.addAllGroupMembers.label")}
                                    </Button>
                                    <Button data-testid="SequenceTab_enableEditButton" disabled={selectedId === undefined} onClick={async () => {
                                        if (this.props.editEnabled && this.props.sequenceDate) {
                                            // Edit was enabled and now the user disabled it.
                                            // Retrieve the group sequence and color in green the employee with first sequence on "validFrom" date.
                                            await this.getSequenceOverview(this.props.selectedGroup.code, this.props.sequenceDate, this.props.selectedGroup?.sequenceDefinition);
                                        }
                                        this.props.dispatchers.setInReduxState({ editEnabled: !this.props.editEnabled })
                                    }}>
                                        {this.props.editEnabled ? _msg("SequenceTab.applyChanges.label") : _msg("SequenceTab.enableEdit.label")}
                                    </Button>
                                    <Form className="SequenceTab_datePicker">
                                        <Form.Field disabled={selectedId === undefined || this.props.editEnabled}>
                                            <DatePickerReactCalendar format={ProteusConstants.DATE_TIME_FORMAT} data-testId="SequenceTab_dateInput" onChange={(date: Moment | null) => this.onSequenceDateChange(date)}
                                                value={this.props.sequenceDate ? moment(this.props.sequenceDate) : undefined} allowClear={false} />
                                        </Form.Field>
                                    </Form> 
                                    <PopupWithHelpTooltip dataTestId="SequenceTab_tooltip" tooltip={_msg("SequenceTab.tooltip.label")} />
                                </div>
                                <EntityTableLight {...this.props.sequenceDefinitionResourcesTable} dispatchers={this.props.dispatchers.sequenceDefinitionResourcesTable} ref={this.sequenceDefinitionResourcesTableRef}
                                    actions={{ doNotAddEntityToTable: true, showDeleteButton: false, disableAddButton: selectedId === undefined || !this.props.editEnabled, showEditButton: false }}
                                    entityDescriptor={groupResourcesDescriptor} renderCellCustomizer={this.renderCellCustomizer}
                                    onDelete={(remainingResources: getGroupMembers_employeeService_employeeSnapshotsByGroup, resourceToBeDeletedIndex: number) => {
                                        this.props.deleteResourceFromSequenceHistoryItem(selectedEntityInTable, resourceToBeDeletedIndex);
                                        this.sequenceDefinitionResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                                    }}
                                    onSave={(resources: ResourceTable[], addedResource: { rowIndex: number, values: { employee: getGroupMembers_employeeService_employeeSnapshotsByGroup } }) => {
                                        const selectedId = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!;
                                        const selectedEntityInTable = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[selectedId];
                                        this.props.addResourceToSequenceHistoryItem(selectedEntityInTable, addedResource.values?.employee?.id);
                                        this.addEmployees([addedResource.values?.employee?.id]);
                                    }}
                                    formCustomizer={{ headerContent: _msg("SequenceTab.sequenceMembers.label"), headerIcon: "user" }}
                                    data-testid="SequenceTab_SequenceTable"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {this.renderSequenceResourcesDescriptor(selectedEntityInTable)}
                </div>
            </>
        );
    }

    renderCellCustomizer = (props: CellProps) => {
        const rowObject = this.sequenceDefinitionResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[props.rowIndex!];
        if (this.props.groupMembers !== undefined) {
            const isNotGroupMember = this.props.groupMembers.filter((item: getGroupMembers_employeeService_employeeSnapshotsByGroup) =>
                item.id === rowObject.employee.id).length === 0;
            const isFirst = rowObject.employee.id === this.props.firstSequenceEmployeeId;
            if (isNotGroupMember) {
                props.style = { 'color': RED_EMPLOYEE };
            } else if (isFirst && !this.props.editEnabled) {
                props.style = { 'color': GREEN_EMPLOYEE };
            }
        }
    }

    selectedGroupChanged() {
        this.setSequenceDefinitionHistoryTable([...this.props.selectedGroup.sequenceDefinition.history]);
        this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
        this.setSequenceResourcesTable([]);
        this.props.dispatchers.setInReduxState({ editEnabled: false });
    }

    // selected history in table changed (row selection changed) or resources for the selected history item has been modified (added or removed a resource)
    async onSequenceDefinitionHistoryChanged(itemId: any) {
        let selectedEntityInTable = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[itemId];
        let resources = selectedEntityInTable?.resources;
        if (resources === undefined || resources.length === 0) {
            this.setSequenceResourcesTable([]);
        } else {
            await this.getResourcesEmployees(resources, true);
        }
    }

    async onSelectedSequenceDefinitionHistoryChanged(itemId: any) {
        await this.onSequenceDefinitionHistoryChanged(itemId);
        let selectedEntityInTable = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[itemId];
        if (itemId !== undefined) {
            this.props.dispatchers.setInReduxState({ sequenceDate: selectedEntityInTable.validFrom });
            await this.getSequenceOverview(this.props.selectedGroup.code, selectedEntityInTable?.validFrom, this.props.selectedGroup?.sequenceDefinition);
            await this.props.dispatchers.getGroupMembers(this.props.selectedGroup.code, selectedEntityInTable?.validFrom, selectedEntityInTable?.validUntil);
        } else {
            this.props.dispatchers.setInReduxState({ groupMembers: undefined });
        }
        this.props.dispatchers.setInReduxState({ editEnabled: false });
        if (this.props.loadedEmployees) {
            const loadedEmployees = [...this.props.loadedEmployees].filter(e => selectedEntityInTable.resources.includes(e.id));
            this.props.dispatchers.setInReduxState({ loadedEmployees });
        }
    }

    componentDidMount() {
        this.props.dispatchers.getRotationSystem();
        this.selectedGroupChanged();
    }

    onSequenceDateChange(date: Moment | null) {
        this.props.dispatchers.setInReduxState({ sequenceDate: date?.toISOString() });
    }

    async componentDidUpdate(prevProps: PropsFrom<typeof sliceSequenceTab> & PropsNotFromState) {
        // selected group changed
        if (this.props.selectedGroup.id !== prevProps.selectedGroup.id) {
            this.selectedGroupChanged();
        }

        // sequence definition history changed (added, edited or removed a history item)
        if (this.props.selectedGroup.sequenceDefinition.history !== prevProps.selectedGroup.sequenceDefinition.history) {
            this.setSequenceDefinitionHistoryTable([...this.props.selectedGroup.sequenceDefinition.history]);
        }

        let selectedId = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!;
        let selectedEntityInTable = this.sequenceDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[selectedId];

        let sequenceDate = this.props.sequenceDate;
        let selectedSequenceDateChanged: boolean;
        if (sequenceDate && prevProps.sequenceDate) {
            selectedSequenceDateChanged = !moment(sequenceDate).isSame(moment(prevProps.sequenceDate));
        } else {
            selectedSequenceDateChanged = sequenceDate !== prevProps.sequenceDate
        }

        if (selectedSequenceDateChanged && sequenceDate) {
            await this.getSequenceOverview(this.props.selectedGroup.code, sequenceDate, this.props.selectedGroup?.sequenceDefinition);
            await this.props.dispatchers.getGroupMembers(this.props.selectedGroup.code, sequenceDate, selectedEntityInTable?.validUntil);
        } else if (selectedSequenceDateChanged && !sequenceDate) {
            await this.props.dispatchers.getGroupMembers(this.props.selectedGroup.code, selectedEntityInTable?.validFrom, selectedEntityInTable?.validUntil);
            this.props.dispatchers.setInReduxState({ firstSequenceEmployeeId: undefined });
        }
    }
}