'use strict'

/*@ngInject*/
function CurriculumTreeController($scope, $rootScope, $filter, catalogsFilter, ObjectiveEditorModal, Curriculum, Confirm, ModalSpinner, CurriculumConstants) {

	var curriculum = $scope.curriculum = $scope.curriculum || {};
	
	curriculum.filterConfig = {
			properties: ['name'],
			elementFn: function(scope) {
				var catalogItemObjective = scope.catalogItemObjective;
				var trackModuleOrComponent = catalogItemObjective.track || catalogItemObjective.module || catalogItemObjective.component;
				if (trackModuleOrComponent) {
					return catalogsFilter(trackModuleOrComponent); // returns currentTrackVersion, currentModuleVersion or currentComponentVersion name
				} else {
					// the virtual nodes
					return {
						name: curriculum.getCatalogItemObjectiveLabel(scope)
					};
				}
			},
			placeholder: $filter('translate')('curriculum.search')
	};
	
	curriculum.getCatalogItemObjectiveLabel = function(scope) {
		var catalogItemObjective = scope.catalogItemObjective;
		if (catalogItemObjective.objectType == 'TrackObjective') {
			return catalogsFilter(catalogItemObjective.track, 'name'); // catalogItemObjective.track.currentTrackVersion.name;
		} else if(catalogItemObjective.objectType == 'ModuleObjective') {
			var moduleVersion = catalogItemObjective.currentModuleVersion != null && catalogItemObjective.module.currentModuleVersion.id != catalogItemObjective.currentModuleVersion.id ?
				catalogItemObjective.currentModuleVersion: catalogItemObjective.module.currentModuleVersion;
			return catalogsFilter(moduleVersion, 'name'); //catalogItemObjective.module.currentModuleVersion.name;
		} else if(catalogItemObjective.objectType == 'ComponentObjective') {
			var componentVersion = catalogItemObjective.currentComponentVersion != null && catalogItemObjective.component.currentComponentVersion.id != catalogItemObjective.currentComponentVersion.id ?
				catalogItemObjective.currentComponentVersion : catalogItemObjective.component.currentComponentVersion;
			return catalogsFilter(componentVersion, 'name'); //catalogItemObjective.component.currentComponentVersion.name;
		} else if(catalogItemObjective.objectType == 'objectiveNode') {
			return $filter('translate')('curriculum.objectives'); 
		} else if(catalogItemObjective.objectType == 'realizationsAndExemptionsNode') {
			return $filter('translate')('curriculum.realizationsAndExemptions'); 
		}
	};
	
	curriculum.select = function(scope) {
		curriculum.selection = scope;
		curriculum.selectedCatalogItemObjective = scope.catalogItemObjective;
		curriculum.onNewSelection(scope.catalogItemObjective);
	};
	
	curriculum.onObjectiveAdded = function(result) {		
		ModalSpinner.show('loading');			
		Curriculum.getCatalogItemObjective({}, {
			params: {
				employee: curriculum.resource.id,
				objective: result.data.id
			}
		}).then(function(result) {
			curriculum.addCatalogItemObjectiveToDataProvider(result.data);
			// refresh the tree in case we add an objective for a component that has been
			// realized (the componet should not appear under "Realisaties/vrijstellingen" anymore)
			curriculum.loadEducationCardData();
		}).finally(function() {
			ModalSpinner.hide();
		});
	};

	curriculum.onRealizationOrExemptionAdded = function(result) {		
		ModalSpinner.show('loading');

		var params = {
			employee: curriculum.resource.id
		}
		if (result.data.objectType == CurriculumConstants.REALIZATION) {
			params.realizationId = result.data.id;
		} else {
			params.exemptionId = result.data.id;
		}
		
		Curriculum.getComponentObjective({}, {
			params: params
		}).then(function(result) {
			curriculum.addCatalogItemObjectiveToDataProvider(result.data); 
		}).finally(function() {
			ModalSpinner.hide();
		});
	};
	
	function editCatalogItemObjective(scope) {
		var catalogItemObjective = scope.catalogItemObjective;
		
		ObjectiveEditorModal.open({
			objective: catalogItemObjective.objective,
			onSuccess: function(result) {
				catalogItemObjective.objective = result.data;
			}		
		});
	};

	function removeCatalogItemObjective(scope) {
		var catalogItemObjective = scope.catalogItemObjective;
		Confirm.open({
			title: 'clear',
			message: 'curriculum.objective.confirmRemove'
		}).result.then(function() {
			return Curriculum.removeObjective({}, {
				params: {
					id: catalogItemObjective.objective.id
				}
			});
		}).then(function(result) {
			var objectiveNode = $filter('filter')(curriculum.dataProvider.data, {objectType: "objectiveNode"}, true)[0]; 
			var index = objectiveNode.childObjectives.indexOf(catalogItemObjective);
			if (index >= 0) {
				objectiveNode.childObjectives.splice(index, 1);
			}

			curriculum.realizationsAndExemptions = [];
			curriculum.selectedCatalogItemObjective = null;

			// refresh the tree in case that the objective is realised and the realizations must be shown under "Realisaties/Vrijstellingen"
			curriculum.loadEducationCardData();
		});
	};
	
	curriculum.actions = [
			{
				index: 10,
				icon: 'fa fa-pencil fa-fw',
				label: 'edit',
				run: editCatalogItemObjective
			},
			{
				index: 20,
				icon: 'fa fa-trash fa-fw',
				label: 'clear',
				run: removeCatalogItemObjective
			}];
	
	curriculum.hasChildren = function(scope) {
		var catalogItemObjective = scope.catalogItemObjective;
		if (catalogItemObjective.objectType == 'ComponentObjective') {
			return false;
		}
		return true;
	};
	
	curriculum.shouldShowActions = function(scope) {
		var catalogItemObjective = scope.catalogItemObjective;
		if (!catalogItemObjective.objective) {
			return false;
		}
		return true;
	};
	
	curriculum.tree = {
		repeat: 'catalogItemObjective in curriculum.dataProvider.data',
		label: '{{curriculum.getCatalogItemObjectiveLabel(this)}}',
		select: 'curriculum.select(this)',
		active: 'curriculum.selectedCatalogItemObjective === catalogItemObjective',
		filter: 'curriculum.filterConfig',	
		expandable: true,
		shouldShowExpandButton: curriculum.hasChildren,		
		shouldShowActions: curriculum.shouldShowActions,
		action: {
			template: '<tree-add-menu row-scope="this" employee="curriculum.resource" on-objective-added="curriculum.onObjectiveAdded" ' +
					'on-realization-or-exemption-added="curriculum.onRealizationOrExemptionAdded"></tree-add-menu>'
		},
		children:  [          
		{
			repeat: 'catalogItemObjective in catalogItemObjective.childObjectives',
			label: '{{curriculum.getCatalogItemObjectiveLabel(this)}}',
			select: 'curriculum.select(this)',
			active: 'curriculum.selectedCatalogItemObjective === catalogItemObjective',
			filter: 'curriculum.filterConfig',
			actions: 'curriculum.actions', // see shouldShowActions, that is added above	
			action: {
				//catalogItemObjective.completed && catalogItemObjective.objective
				template: require('./component-status.html')
			},
			frontDecorator: {
				template: '<i ng-if="!!catalogItemObjective.track" class="fa fa-bolt fa-fw frontIcon"></i>' +
						  '<i ng-if="!!catalogItemObjective.module" class="fa fa-cogs fa-fw frontIcon"></i>' +
						  '<i ng-if="!!catalogItemObjective.component" class="fa fa-cog fa-fw frontIcon"></i>'
			},
			recurse: 'child-objectives',
			expandable: true,
			shouldShowExpandButton: curriculum.hasChildren,
		}]
	}
}

module.exports = CurriculumTreeController;