'use strict';

/**
 * A collection that supports simple operations.
 */
function Collection() {
	
	return {
		
		create: function(data) {
			var collection = {};
			
			collection.data = data;
			
			collection.add = function(element, index) {
				if (angular.isNumber(index)) {
					data.splice(index, 0, element);
				} else {
					data.push(element);
				}
			};
			
			collection.replace = function(element) {
				var index = collection.indexOf(element, collection.eqId);
				if (index >= 0) {
					collection.data.splice(index, 1, element);
				} else {
					collection.data.push(element);
				}
			};
			
			collection.remove = function(element) {
				var index = collection.indexOf(element);
				if (index >= 0) {
					collection.data.splice(index, 1);
				}
			};

			collection.removeByIndex = function(index) {
				if (index >= 0) {
					collection.data.splice(index, 1);
				}
			};
			
			collection.indexOf = function(element, eq) {
				var index = -1;
				eq = eq || collection.eq;
				for (var i = 0; i < collection.data.length; i++) {
					if (eq(element, collection.data[i])) {
						return i;
					}
				}
				return -1;
			};
			
			collection.contains = function(element, eq) {
				return collection.indexOf(element, eq) >= 0;
			};
			
			collection.eq = function(a, b) {
				return a === b;
			};
			
			collection.eqId = function(a, b) {
				return a.id === b.id;
			};
			
			return collection;
		}
		
	};
	
}

module.exports = Collection;