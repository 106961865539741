import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import React from "react";
import { Button, Segment, SemanticCOLORS } from "semantic-ui-react";

export interface File {
    code: string;
    content: string;
    id: number;
    name: string;
}

export class CustomStatusRenderer extends React.Component<FieldRendererProps> {

    getStatusDetails() {
        let color = 'white';
        let message = _msg("BackgroundTask.status." + this.props.value + ".label");
        switch (this.props.value) {
            case 'FINISHED':
                color = 'green';
                break;
            case 'FINISHED_WITH_ERRORS':
                color = 'red';
                break;
            case 'QUEUED':
                color = 'grey';
                break;
            case 'PROCESSING':
                color = 'yellow'
                break;
            default:
                color = 'black'
        }
        return { color, message };
    }

    render() {
        let { color, message } = this.getStatusDetails();
        return (
            <Segment fluid inverted color={color as SemanticCOLORS} className="CustomStatusRenderer" textAlign='center'>
                {message}
            </Segment>
        );
    }
}

export class CustomOutputFilesRenderer extends React.Component<FieldRendererProps> {
    base64ToArrayBuffer(base64Input: string) {
        var binaryString = window.atob(base64Input);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

    render() {
        return (
            <>
                {this.props.value.map((file: File) => (
                    <Button content={file.name} onClick={
                        () => {
                            var blob;
                            const viewableFormats = ["txt", "pdf"];
                            const nameArray = file.name.split(".");
                            const fileFormat = nameArray[nameArray.length - 1];
                            console.log(fileFormat)
                            if (fileFormat === "pdf") {
                                blob = new Blob([this.base64ToArrayBuffer(file.content)], { type: "application/pdf" });
                            } else {
                                blob = new Blob([this.base64ToArrayBuffer(file.content)]);
                            }
                            var currentFile = window.document.createElement('a');
                            currentFile.href = window.URL.createObjectURL(blob);
                            currentFile.download = file.name;
                            if (!viewableFormats.includes(fileFormat))
                                currentFile.click();
                            else {
                                window.open(currentFile.href, "Download", "width=900 height=600 top=100 left=100 status=no");
                            }
                        }
                    } />
                ))}
            </>
        );
    }
}