export default global.entitiesGen = {
  "AbstractDataSource": {
    "name": "AbstractDataSource",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "ReportDataSourceType",
        "kind": "OBJECT"
      }
    ]
  },
  "AbstractRecurrencePattern": {
    "name": "AbstractRecurrencePattern",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "definition",
        "type": "RecurrenceDefinition",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "AbstractRecurrenceRange": {
    "name": "AbstractRecurrenceRange",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "startDate",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "Account": {
    "name": "Account",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PASSWORD",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PERSON",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "Environment",
        "kind": "OBJECT"
      },
      {
        "name": "failedAttemptsNumber",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "isGeneratedPassword",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "lastPasswordInvalidDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "lastPasswordUpdateDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "locked",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "password",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "passwordHistory",
        "type": "PasswordHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "person",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "userName",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "AccountHistoryItem": {
    "name": "AccountHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_COUNTRY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_INSTITUTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NUMBER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USAGE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "country",
        "type": "Country",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "institution",
        "type": "FinancialInstitution",
        "kind": "OBJECT"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "number",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "AccountType",
        "kind": "OBJECT"
      },
      {
        "name": "usage",
        "type": "AccountUsage",
        "kind": "OBJECT"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "AccountType": {
    "name": "AccountType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "AccountUsage": {
    "name": "AccountUsage",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ActivityDefinition": {
    "name": "ActivityDefinition",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "actors",
        "type": "Actor",
        "kind": "LIST"
      },
      {
        "name": "connectorDefinitions",
        "type": "ConnectorDefinition",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataList",
        "type": "DataDefinition",
        "kind": "LIST"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "message",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "processDefinition",
        "type": "ProcessDefinition",
        "kind": "OBJECT"
      },
      {
        "name": "title",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "transitions",
        "type": "TransitionDefinition",
        "kind": "LIST"
      },
      {
        "name": "type",
        "type": "ActivityType",
        "kind": "OBJECT"
      }
    ]
  },
  "ActivityStatus": {
    "name": "ActivityStatus",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ActivityType": {
    "name": "ActivityType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Actor": {
    "name": "Actor",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "context",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Address": {
    "name": "Address",
    "fields": [
      {
        "name": "PROPERTY_ADRES_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_BOX",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CITY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_HOUSE_NUMBER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_STREET",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "box",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "city",
        "type": "City",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "houseNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "street",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "AddressType",
        "kind": "OBJECT"
      }
    ]
  },
  "AddressHistoryItem": {
    "name": "AddressHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_ADDRESS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "address",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "AddressType": {
    "name": "AddressType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "AssignmentRole": {
    "name": "AssignmentRole",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "BackgroundTask": {
    "name": "BackgroundTask",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VISIBILITY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VISIBILITY_GROUPS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "deleteWhenFinished",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "inputParameters",
        "type": "InputParameter",
        "kind": "LIST"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "notificationTypes",
        "type": "NotificationType",
        "kind": "LIST"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "outputFiles",
        "type": "OutputFile",
        "kind": "LIST"
      },
      {
        "name": "owner",
        "type": "Person",
        "kind": "OBJECT"
      },
      {
        "name": "processingEndDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "processingStartDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "storeOutputFile",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "targetClass",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "targetMethod",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "visibility",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "visibilityGroups",
        "type": "VisibilityGroup",
        "kind": "LIST"
      }
    ]
  },
  "BalanceCounterHistoryItem": {
    "name": "BalanceCounterHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_BALANCE_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_COUNTER_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PERSON",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALUE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_WORK_PERIOD_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "balanceType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "counterType",
        "type": "CounterType",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "person",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "remark",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "value",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "workPeriodType",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "BalanceType": {
    "name": "BalanceType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "counterType",
        "type": "CounterType",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Calculation": {
    "name": "Calculation",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PERSIST",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "persist",
        "type": "Boolean",
        "kind": "SCALAR"
      }
    ]
  },
  "CalendarDefinition": {
    "name": "CalendarDefinition",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "extendDefinitions",
        "type": "CalendarDefinition",
        "kind": "LIST"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "items",
        "type": "CalendarItem",
        "kind": "LIST"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "properties",
        "type": "CalendarProperty",
        "kind": "LIST"
      }
    ]
  },
  "CalendarItem": {
    "name": "CalendarItem",
    "fields": [
      {
        "name": "PROPERTY_ALL_DAY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_END_DATE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_RECURRENCE_DEFINITION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_RECURRING",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_START_DATE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_SUBJECT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "allDay",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "endDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "recurrenceDefinition",
        "type": "RecurrenceDefinition",
        "kind": "OBJECT"
      },
      {
        "name": "recurrenceDefinitionDto",
        "type": "RecurrenceDefinitionDto",
        "kind": "OBJECT"
      },
      {
        "name": "recurring",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "startDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "subject",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "CalendarProperty": {
    "name": "CalendarProperty",
    "fields": [
      {
        "name": "PROPERTY_CALENDAR_ITEM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALUE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "item",
        "type": "CalendarItem",
        "kind": "OBJECT"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "value",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "CalendarPropertyCt": {
    "name": "CalendarPropertyCt",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Call": {
    "name": "Call",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "direction",
        "type": "CallDirection",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "messages",
        "type": "Message",
        "kind": "LIST"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "CallStatus",
        "kind": "OBJECT"
      }
    ]
  },
  "CallDirection": {
    "name": "CallDirection",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CallStatus": {
    "name": "CallStatus",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Card": {
    "name": "Card",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NUMBER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "number",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "CardType",
        "kind": "OBJECT"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "CardType": {
    "name": "CardType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CatalogAttachment": {
    "name": "CatalogAttachment",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "classType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "content",
        "type": "Base64String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fileName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CatalogSnapshot": {
    "name": "CatalogSnapshot",
    "fields": [
      {
        "name": "PROPERTY_ACTIVE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "active",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Category": {
    "name": "Category",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CategoryHistoryItem": {
    "name": "CategoryHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_CATEGORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "category",
        "type": "Category",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "ChildAllowanceFund": {
    "name": "ChildAllowanceFund",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "City": {
    "name": "City",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_COUNTRY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_STRUCT_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "country",
        "type": "Country",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "structName",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "CivilStatus": {
    "name": "CivilStatus",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CivilStatusHistoryItem": {
    "name": "CivilStatusHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_CIVIL_STATUS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_OF_BIRTH_PARTNER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_FIRST_NAME_PARTNER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_INCOME_FORM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME_PARTNER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "civilStatus",
        "type": "CivilStatus",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dateOfBirthPartner",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "firstNamePartner",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "incomeForm",
        "type": "IncomeForm",
        "kind": "OBJECT"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "namePartner",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "Company": {
    "name": "Company",
    "fields": [
      {
        "name": "PROPERTY_ADDRESS_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ATTACHMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_BUSINESS_HOURS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EMPLOYERS_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EXTERN_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_INTERNET",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_LEGAL_FORM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PARENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_REMARKS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TELECOM_LIST",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TYPES",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VAT_NUMBER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "addressHistory",
        "type": "AddressHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "attachment",
        "type": "ResourceAttachment",
        "kind": "OBJECT"
      },
      {
        "name": "businessHours",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "employersCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "Environment",
        "kind": "OBJECT"
      },
      {
        "name": "externId",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "internet",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "legalForm",
        "type": "LegalForm",
        "kind": "OBJECT"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "parent",
        "type": "Company",
        "kind": "OBJECT"
      },
      {
        "name": "remarks",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "telecomList",
        "type": "Telecom",
        "kind": "LIST"
      },
      {
        "name": "types",
        "type": "CompanyType",
        "kind": "LIST"
      },
      {
        "name": "vatNumber",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "CompanyHistoryItem": {
    "name": "CompanyHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_COMAPNY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "company",
        "type": "Company",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "CompanyType": {
    "name": "CompanyType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CompensationLossRounding": {
    "name": "CompensationLossRounding",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Competence": {
    "name": "Competence",
    "fields": [
      {
        "name": "PROPERTY_CATEGORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CURRENT_COMPETENCE_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "category",
        "type": "CompetenceCategorySnapshot",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "currentCompetenceVersion",
        "type": "CompetenceVersion",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CompetenceAttachment": {
    "name": "CompetenceAttachment",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "classType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "content",
        "type": "Base64String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fileName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CompetenceCatalog": {
    "name": "CompetenceCatalog",
    "fields": [
      {
        "name": "PROPERTY_CATEGORIES",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_OMSCHRIJVING",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "categories",
        "type": "CompetenceCategory",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CompetenceCatalogSnapshot": {
    "name": "CompetenceCatalogSnapshot",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_OMSCHRIJVING",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CompetenceCategory": {
    "name": "CompetenceCategory",
    "fields": [
      {
        "name": "PROPERTY_CATEGORY_CHILDREN",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_COMPETENCES",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_OMSCHRIJVING",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PARENT_CATEGORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "catalog",
        "type": "CompetenceCatalogSnapshot",
        "kind": "OBJECT"
      },
      {
        "name": "categoryChildren",
        "type": "CompetenceCategory",
        "kind": "LIST"
      },
      {
        "name": "competences",
        "type": "Competence",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "parentCategory",
        "type": "CompetenceCategory",
        "kind": "OBJECT"
      }
    ]
  },
  "CompetenceCategorySnapshot": {
    "name": "CompetenceCategorySnapshot",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_OMSCHRIJVING",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "catalog",
        "type": "CompetenceCatalogSnapshot",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "parentCategory",
        "type": "CompetenceCategorySnapshot",
        "kind": "OBJECT"
      }
    ]
  },
  "CompetenceProfileCriterionVersion": {
    "name": "CompetenceProfileCriterionVersion",
    "fields": [
      {
        "name": "PROPERTY_COMPETENCE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PROFILE_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_REQUIRED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_WEIGHT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "competence",
        "type": "Competence",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "profileVersion",
        "type": "ProfileVersion",
        "kind": "OBJECT"
      },
      {
        "name": "required",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "weight",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CompetenceVersion": {
    "name": "CompetenceVersion",
    "fields": [
      {
        "name": "PROPERTY_ATTACHMENTS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_COMPETENCE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_LEVEL_DEFINITIONS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "active",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "attachments",
        "type": "CompetenceAttachment",
        "kind": "LIST"
      },
      {
        "name": "competence",
        "type": "Competence",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "levelDefinitions",
        "type": "LevelDefinition",
        "kind": "LIST"
      },
      {
        "name": "majorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "minorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "trainingComponentVersions",
        "type": "EducationComponentVersion",
        "kind": "LIST"
      }
    ]
  },
  "Component": {
    "name": "Component",
    "fields": [
      {
        "name": "PROPERTY_CATALOG",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CURRENT_COMPONENT_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EXTERNAL_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "catalog",
        "type": "CatalogSnapshot",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "currentComponentVersion",
        "type": "ComponentVersion",
        "kind": "OBJECT"
      },
      {
        "name": "externalId",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "ComponentType",
        "kind": "OBJECT"
      }
    ]
  },
  "ComponentType": {
    "name": "ComponentType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ComponentVersion": {
    "name": "ComponentVersion",
    "fields": [
      {
        "name": "PROPERTY_ACTIVE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ATTACHMENTS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_COMPONENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "active",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "attachments",
        "type": "CatalogAttachment",
        "kind": "LIST"
      },
      {
        "name": "component",
        "type": "Component",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "majorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "minorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CompositionGroup": {
    "name": "CompositionGroup",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CONTEXTS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_OWNER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "constraintDefinitions",
        "type": "ConstraintDefinition",
        "kind": "LIST"
      },
      {
        "name": "contexts",
        "type": "GroupContext",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "groups",
        "type": "Group",
        "kind": "LIST"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "owner",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "properties",
        "type": "GroupProperty",
        "kind": "LIST"
      },
      {
        "name": "securityDefinition",
        "type": "SecurityDefinition",
        "kind": "OBJECT"
      },
      {
        "name": "sequenceDefinition",
        "type": "SequenceDefinition",
        "kind": "OBJECT"
      },
      {
        "name": "successionDefinition",
        "type": "SuccessionDefinition",
        "kind": "OBJECT"
      }
    ]
  },
  "Connector": {
    "name": "Connector",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "className",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "parameters",
        "type": "ConnectorParameter",
        "kind": "LIST"
      },
      {
        "name": "type",
        "type": "ConnectorType",
        "kind": "OBJECT"
      }
    ]
  },
  "ConnectorDefinition": {
    "name": "ConnectorDefinition",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "connector",
        "type": "Connector",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "eventType",
        "type": "EventType",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "variables",
        "type": "ConnectorVariable",
        "kind": "LIST"
      }
    ]
  },
  "ConnectorParameter": {
    "name": "ConnectorParameter",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataTypeClass",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "methodName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ConnectorType": {
    "name": "ConnectorType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ConnectorVariable": {
    "name": "ConnectorVariable",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "expression",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "parameter",
        "type": "ConnectorParameter",
        "kind": "OBJECT"
      },
      {
        "name": "value",
        "type": "ObjectScalar",
        "kind": "LIST"
      }
    ]
  },
  "ConstraintDefinition": {
    "name": "ConstraintDefinition",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "history",
        "type": "ConstraintHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validForRegistrationTypes",
        "type": "String",
        "kind": "LIST"
      },
      {
        "name": "validOnRegistrationTypes",
        "type": "String",
        "kind": "LIST"
      }
    ]
  },
  "ConstraintGroup": {
    "name": "ConstraintGroup",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ConstraintGroupHistoryItem": {
    "name": "ConstraintGroupHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_CONSTRAINT_GROUP",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "constraintGroup",
        "type": "ConstraintGroup",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "ConstraintHistoryItem": {
    "name": "ConstraintHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "calendar",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "ContractHistoryItem": {
    "name": "ContractHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EMPLOYEE_NUMBER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EMPLOYMENT_BREAK",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_REASON_CONTRACT_END",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_STATUS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_SUSPENSION_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_WORK_ARRANGEMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "employeeNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "employmentBreak",
        "type": "EmploymentBreak",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "reasonContractEnd",
        "type": "ReasonContractEnd",
        "kind": "OBJECT"
      },
      {
        "name": "status",
        "type": "ContractStatus",
        "kind": "OBJECT"
      },
      {
        "name": "suspensionHistory",
        "type": "ContractSuspensionHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "type",
        "type": "ContractType",
        "kind": "OBJECT"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "workArrangement",
        "type": "WorkArrangement",
        "kind": "OBJECT"
      }
    ]
  },
  "ContractStatus": {
    "name": "ContractStatus",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ContractSuspensionHistoryItem": {
    "name": "ContractSuspensionHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "reasonSuspension",
        "type": "ReasonContractSuspension",
        "kind": "OBJECT"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "ContractType": {
    "name": "ContractType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CostCenter": {
    "name": "CostCenter",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CounterType": {
    "name": "CounterType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Country": {
    "name": "Country",
    "fields": [
      {
        "name": "PROPERTY_AUTOCODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_INTERNATIONAL_PHONE_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ISO3CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_WEB",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "autoCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "internationalPhoneCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "iso3Code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "web",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "DataDefinition": {
    "name": "DataDefinition",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataTypeClassName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "defaultValue",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "enumerationValues",
        "type": "EnumerationValue",
        "kind": "LIST"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "label",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "maxLength",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "processor",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "required",
        "type": "Boolean",
        "kind": "SCALAR"
      }
    ]
  },
  "DataType": {
    "name": "DataType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Day": {
    "name": "Day",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "DayOfWeekIndex": {
    "name": "DayOfWeekIndex",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Department": {
    "name": "Department",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "DepartmentHistoryItem": {
    "name": "DepartmentHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DEPARTMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "department",
        "type": "Department",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "DynamicGroup": {
    "name": "DynamicGroup",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CONTEXTS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_OWNER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_SQL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "constraintDefinitions",
        "type": "ConstraintDefinition",
        "kind": "LIST"
      },
      {
        "name": "contexts",
        "type": "GroupContext",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "owner",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "properties",
        "type": "GroupProperty",
        "kind": "LIST"
      },
      {
        "name": "securityDefinition",
        "type": "SecurityDefinition",
        "kind": "OBJECT"
      },
      {
        "name": "sequenceDefinition",
        "type": "SequenceDefinition",
        "kind": "OBJECT"
      },
      {
        "name": "sql",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "successionDefinition",
        "type": "SuccessionDefinition",
        "kind": "OBJECT"
      }
    ]
  },
  "EducationCardAttachment": {
    "name": "EducationCardAttachment",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "classType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "content",
        "type": "Base64String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fileName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "EducationComponentVersion": {
    "name": "EducationComponentVersion",
    "fields": [
      {
        "name": "PROPERTY_ACTIVE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ATTACHMENTS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_COMPONENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "active",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "attachments",
        "type": "CatalogAttachment",
        "kind": "LIST"
      },
      {
        "name": "competenceVersions",
        "type": "CompetenceVersion",
        "kind": "LIST"
      },
      {
        "name": "component",
        "type": "Component",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "majorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "minorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "trainerProfileVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Employee": {
    "name": "Employee",
    "fields": [
      {
        "name": "PROPERTY_ADDRESS_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ATTACHMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_COMPANY_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DEPARTMENT_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DETAIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EXTERN_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_FIRST_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_GENDER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_LICENSE_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NATIONALITY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NATIVE_LANGUAGE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_REMARKS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TELECOM_LIST",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TITLE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VOCATIVE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "addressHistory",
        "type": "AddressHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "attachment",
        "type": "ResourceAttachment",
        "kind": "OBJECT"
      },
      {
        "name": "companyHistory",
        "type": "CompanyHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "departmentHistory",
        "type": "DepartmentHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "detail",
        "type": "EmployeeDetail",
        "kind": "OBJECT"
      },
      {
        "name": "environment",
        "type": "Environment",
        "kind": "OBJECT"
      },
      {
        "name": "externId",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "firstName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "gender",
        "type": "Gender",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "licenseType",
        "type": "LicenseType",
        "kind": "OBJECT"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "nationality",
        "type": "Nationality",
        "kind": "OBJECT"
      },
      {
        "name": "nativeLanguage",
        "type": "Language",
        "kind": "OBJECT"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "remarks",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "telecomList",
        "type": "Telecom",
        "kind": "LIST"
      },
      {
        "name": "title",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "vocative",
        "type": "Vocative",
        "kind": "OBJECT"
      }
    ]
  },
  "EmployeeDetail": {
    "name": "EmployeeDetail",
    "fields": [
      {
        "name": "PROPERTY_ACCOUNT_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_BAREMA",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_BIRTHPLACE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CARDS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CATEGORY_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CHILD_ALLOWANCE_FUND",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CHILD_ALLOWANCE_FUND_NUMBER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CIVIL_STATUS_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CONSTRAINT_GROUP_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CONTRACT_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_OF_BIRTH",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_OF_DEATH",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DIMONA_NUMBER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_HEALTH_INSURANCE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_HEALTH_INSURANCE_NUMBER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_HOUR_REGIME_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_LOCATION_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NATIONAL_REGISTER_NUMBER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PERSON",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PILOT_NUMBER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_SHARE_REGISTER_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_START_DATE_CREDIT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TAX_STATUS_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TEAM_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TIME_REGISTRATION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "accountHistory",
        "type": "AccountHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "barema",
        "type": "Scale",
        "kind": "OBJECT"
      },
      {
        "name": "birthplace",
        "type": "City",
        "kind": "OBJECT"
      },
      {
        "name": "cards",
        "type": "Card",
        "kind": "LIST"
      },
      {
        "name": "categoryHistory",
        "type": "CategoryHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "childAllowanceFund",
        "type": "ChildAllowanceFund",
        "kind": "OBJECT"
      },
      {
        "name": "childAllowanceFundNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "civilStatusHistory",
        "type": "CivilStatusHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "constraintGroupHistory",
        "type": "ConstraintGroupHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "contractHistory",
        "type": "ContractHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dateOfBirth",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "dateOfDeath",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "dimonaNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "functionHistory",
        "type": "FunctionHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "healthInsurance",
        "type": "HealthInsurance",
        "kind": "OBJECT"
      },
      {
        "name": "healthInsuranceNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "hourRegimeHistory",
        "type": "HourRegimeHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "locationHistory",
        "type": "LocationHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "nationalRegisterNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "person",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "pilotNumber",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "scaleHistory",
        "type": "ScaleHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "shareRegisterHistory",
        "type": "ShareRegisterHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "startDateCredit",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "taxStatusHistory",
        "type": "TaxStatusHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "teamHistory",
        "type": "TeamHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "timeRegistration",
        "type": "Boolean",
        "kind": "SCALAR"
      }
    ]
  },
  "EmployeeFunction": {
    "name": "EmployeeFunction",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "ejobType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "EmploymentBreak": {
    "name": "EmploymentBreak",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "EnumerationValue": {
    "name": "EnumerationValue",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "label",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Environment": {
    "name": "Environment",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_COMPANY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "company",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Evaluation": {
    "name": "Evaluation",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "attachments",
        "type": "EvaluationAttachment",
        "kind": "LIST"
      },
      {
        "name": "averageScore",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "evaluationDefinitionVersion",
        "type": "EvaluationDefinitionVersion",
        "kind": "OBJECT"
      },
      {
        "name": "evaluator",
        "type": "PersonSnapshot",
        "kind": "OBJECT"
      },
      {
        "name": "finished",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "published",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "realization",
        "type": "Realization",
        "kind": "OBJECT"
      },
      {
        "name": "remark",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "resource",
        "type": "PersonSnapshot",
        "kind": "OBJECT"
      },
      {
        "name": "scores",
        "type": "Score",
        "kind": "LIST"
      },
      {
        "name": "targetDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "totalNorm",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "totalScore",
        "type": "Float",
        "kind": "SCALAR"
      }
    ]
  },
  "EvaluationAttachment": {
    "name": "EvaluationAttachment",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "classType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "content",
        "type": "Base64String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fileName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "EvaluationCriterionVersion": {
    "name": "EvaluationCriterionVersion",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "competenceVersion",
        "type": "CompetenceVersion",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "evaluationDefinitionVersion",
        "type": "EvaluationDefinitionVersion",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "normPercentage",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "ownScore",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "ratedEvaluations",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "required",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "totalScore",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "weight",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "EvaluationDefinition": {
    "name": "EvaluationDefinition",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "alsoSelfEvaluation",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "currentEvaluationDefinitionVersion",
        "type": "EvaluationDefinitionVersion",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "visibleToEmployees",
        "type": "Boolean",
        "kind": "SCALAR"
      }
    ]
  },
  "EvaluationDefinitionVersion": {
    "name": "EvaluationDefinitionVersion",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EVALUATION_CRITERION_VERSIONS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EVALUATION_DEFINITION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "evaluationCriterionVersions",
        "type": "EvaluationCriterionVersion",
        "kind": "LIST"
      },
      {
        "name": "evaluationDefinition",
        "type": "EvaluationDefinition",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "majorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "minorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "EventType": {
    "name": "EventType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "FinancialInstitution": {
    "name": "FinancialInstitution",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "FreeTimeChange": {
    "name": "FreeTimeChange",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "category",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "FunctionHistoryItem": {
    "name": "FunctionHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EMPLOYEE_FUNCTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "employeeFunction",
        "type": "EmployeeFunction",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "Gender": {
    "name": "Gender",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Group": {
    "name": "Group",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CONTEXTS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_OWNER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "contexts",
        "type": "GroupContext",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "owner",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "properties",
        "type": "GroupProperty",
        "kind": "LIST"
      }
    ]
  },
  "GroupContext": {
    "name": "GroupContext",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "owner",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "GroupProperty": {
    "name": "GroupProperty",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALUE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "group",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "value",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "GroupPropertyCt": {
    "name": "GroupPropertyCt",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "GroupRelation": {
    "name": "GroupRelation",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "groupRelationId",
        "type": "GroupRelationId",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "source",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "target",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "GroupRelationType",
        "kind": "OBJECT"
      }
    ]
  },
  "GroupRelationType": {
    "name": "GroupRelationType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "inLabel",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "outLabel",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "GroupResourcesHistoryItem": {
    "name": "GroupResourcesHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "resources",
        "type": "Int",
        "kind": "LIST"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "GroupRosterRelation": {
    "name": "GroupRosterRelation",
    "fields": [
      {
        "name": "PROPERTY_CATEGORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_HOURREGIME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_LEAVE_ON_HOLIDAY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_LOCATION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_REFERENCE_DATE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ROSTER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TEAM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "category",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "hourRegime",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "leaveOnHoliday",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "location",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "referenceDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "roster",
        "type": "Roster",
        "kind": "OBJECT"
      },
      {
        "name": "rosterRegistrations",
        "type": "RosterRegistration",
        "kind": "LIST"
      },
      {
        "name": "team",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "GroupType": {
    "name": "GroupType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "HalfDay": {
    "name": "HalfDay",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "HealthInsurance": {
    "name": "HealthInsurance",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "HourRegime": {
    "name": "HourRegime",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "HourRegimeHistoryItem": {
    "name": "HourRegimeHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_HOUR_REGIME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "hourRegime",
        "type": "HourRegime",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "IncomeForm": {
    "name": "IncomeForm",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "InputParameter": {
    "name": "InputParameter",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "label",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "value",
        "type": "ObjectScalar",
        "kind": "SCALAR"
      }
    ]
  },
  "Language": {
    "name": "Language",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "LayoutType": {
    "name": "LayoutType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "LegalForm": {
    "name": "LegalForm",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "LevelDefinition": {
    "name": "LevelDefinition",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "percentage",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "scale",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "LicenseType": {
    "name": "LicenseType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Location": {
    "name": "Location",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "address",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "LocationHistoryItem": {
    "name": "LocationHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_LOCATION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "location",
        "type": "Location",
        "kind": "OBJECT"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "MedicalCheckupType": {
    "name": "MedicalCheckupType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Message": {
    "name": "Message",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "call",
        "type": "Call",
        "kind": "OBJECT"
      },
      {
        "name": "checkpoint",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "confirmationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "content",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "errorCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "errorDescription",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "identifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "services",
        "type": "MessageService",
        "kind": "LIST"
      },
      {
        "name": "status",
        "type": "MessageStatus",
        "kind": "OBJECT"
      },
      {
        "name": "type",
        "type": "MessageType",
        "kind": "OBJECT"
      }
    ]
  },
  "MessageService": {
    "name": "MessageService",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "message",
        "type": "Message",
        "kind": "OBJECT"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "MessageStatus",
        "kind": "OBJECT"
      }
    ]
  },
  "MessageStatus": {
    "name": "MessageStatus",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "MessageType": {
    "name": "MessageType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "MetadataContext": {
    "name": "MetadataContext",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Module": {
    "name": "Module",
    "fields": [
      {
        "name": "PROPERTY_CATALOG",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CURRENT_MODULE_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "catalog",
        "type": "CatalogSnapshot",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "currentModuleVersion",
        "type": "ModuleVersion",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ModuleVersion": {
    "name": "ModuleVersion",
    "fields": [
      {
        "name": "PROPERTY_ACTIVE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ATTACHMENTS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_MODULE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ONDERDEEL_VERSIES",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "active",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "attachments",
        "type": "CatalogAttachment",
        "kind": "LIST"
      },
      {
        "name": "componentVersions",
        "type": "ComponentVersion",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "majorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "minorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "module",
        "type": "Module",
        "kind": "OBJECT"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Month": {
    "name": "Month",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Nationality": {
    "name": "Nationality",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "NotificationType": {
    "name": "NotificationType",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "notificationTypeCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ObjectiveStatus": {
    "name": "ObjectiveStatus",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "OutputFile": {
    "name": "OutputFile",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "content",
        "type": "Base64String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "OvertimeType": {
    "name": "OvertimeType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Parameter": {
    "name": "Parameter",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATA_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PARAMETER_VALUE_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataType",
        "type": "DataType",
        "kind": "OBJECT"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "parameterValueHistory",
        "type": "ParameterValueHistoryItem",
        "kind": "LIST"
      }
    ]
  },
  "ParameterValueHistoryItem": {
    "name": "ParameterValueHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALUE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "bytes",
        "type": "Base64String",
        "kind": "SCALAR"
      },
      {
        "name": "bytesAsString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "value",
        "type": "ObjectScalar",
        "kind": "SCALAR"
      }
    ]
  },
  "PasswordHistoryItem": {
    "name": "PasswordHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "password",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "Person": {
    "name": "Person",
    "fields": [
      {
        "name": "PROPERTY_ADDRESS_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ATTACHMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_COMPANY_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DEPARTMENT_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EXTERN_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_FIRST_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_GENDER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_LICENSE_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NATIONALITY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NATIVE_LANGUAGE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_REMARKS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TELECOM_LIST",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TITLE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VOCATIVE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "addressHistory",
        "type": "AddressHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "attachment",
        "type": "ResourceAttachment",
        "kind": "OBJECT"
      },
      {
        "name": "companyHistory",
        "type": "CompanyHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "departmentHistory",
        "type": "DepartmentHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "environment",
        "type": "Environment",
        "kind": "OBJECT"
      },
      {
        "name": "externId",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "firstName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "gender",
        "type": "Gender",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "licenseType",
        "type": "LicenseType",
        "kind": "OBJECT"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "nationality",
        "type": "Nationality",
        "kind": "OBJECT"
      },
      {
        "name": "nativeLanguage",
        "type": "Language",
        "kind": "OBJECT"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "remarks",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "telecomList",
        "type": "Telecom",
        "kind": "LIST"
      },
      {
        "name": "title",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "vocative",
        "type": "Vocative",
        "kind": "OBJECT"
      }
    ]
  },
  "PilotProgressCategory": {
    "name": "PilotProgressCategory",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "months",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "order",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "relationType",
        "type": "PilotProgressRelation",
        "kind": "OBJECT"
      },
      {
        "name": "tasks",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "totalTasks",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "PilotProgressCondition": {
    "name": "PilotProgressCondition",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "pilotProgressCategory",
        "type": "PilotProgressCategory",
        "kind": "OBJECT"
      },
      {
        "name": "pilotProgressCriterium",
        "type": "PilotProgressCriterium",
        "kind": "OBJECT"
      },
      {
        "name": "valueFrom",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "valueTo",
        "type": "Float",
        "kind": "SCALAR"
      }
    ]
  },
  "PilotProgressCriterium": {
    "name": "PilotProgressCriterium",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "PilotProgressHistoryItem": {
    "name": "PilotProgressHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "category",
        "type": "PilotProgressCategory",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "extras",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "generated",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "owner",
        "type": "Person",
        "kind": "OBJECT"
      },
      {
        "name": "tasks",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "totalTasks",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "PilotProgressRelation": {
    "name": "PilotProgressRelation",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "PremiumHistoryItem": {
    "name": "PremiumHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "record",
        "type": "PremiumRecord",
        "kind": "OBJECT"
      },
      {
        "name": "resource",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "taskCounter",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "PremiumLoaCategory": {
    "name": "PremiumLoaCategory",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "lengthFrom",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "lengthTo",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "PremiumParameter": {
    "name": "PremiumParameter",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "loaCategory",
        "type": "PremiumLoaCategory",
        "kind": "OBJECT"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "premiumParameterValueHistory",
        "type": "PremiumParameterHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "record",
        "type": "PremiumRecord",
        "kind": "OBJECT"
      },
      {
        "name": "route",
        "type": "PremiumRoute",
        "kind": "OBJECT"
      },
      {
        "name": "type",
        "type": "PremiumParameterType",
        "kind": "OBJECT"
      }
    ]
  },
  "PremiumParameterHistoryItem": {
    "name": "PremiumParameterHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "parameter",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "value",
        "type": "Float",
        "kind": "SCALAR"
      }
    ]
  },
  "PremiumParameterType": {
    "name": "PremiumParameterType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "PremiumRecord": {
    "name": "PremiumRecord",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "PremiumRoute": {
    "name": "PremiumRoute",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "PremiumSuspensionHistoryItem": {
    "name": "PremiumSuspensionHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "resource",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "ProcessDefinition": {
    "name": "ProcessDefinition",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataList",
        "type": "DataDefinition",
        "kind": "LIST"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "rootActivity",
        "type": "ActivityDefinition",
        "kind": "OBJECT"
      }
    ]
  },
  "Profile": {
    "name": "Profile",
    "fields": [
      {
        "name": "PROPERTY_CURRENT_PROFILE_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "currentProfileVersion",
        "type": "ProfileVersion",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ProfileVersion": {
    "name": "ProfileVersion",
    "fields": [
      {
        "name": "PROPERTY_COMPETENCE_PROFILE_CRITERION_VERSIONS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_COMPONENTS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PROFILE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "competenceProfileCriterionVersions",
        "type": "CompetenceProfileCriterionVersion",
        "kind": "LIST"
      },
      {
        "name": "components",
        "type": "Component",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "majorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "minorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "profile",
        "type": "Profile",
        "kind": "OBJECT"
      }
    ]
  },
  "Project": {
    "name": "Project",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Property": {
    "name": "Property",
    "fields": [
      {
        "name": "PROPERTY_CATEGORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "category",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "defaultValue",
        "type": "ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "isForType",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "userOverridePossible",
        "type": "Boolean",
        "kind": "SCALAR"
      }
    ]
  },
  "PropertyCategory": {
    "name": "PropertyCategory",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "PropertyValue": {
    "name": "PropertyValue",
    "fields": [
      {
        "name": "PROPERTY_CONTEXT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PROPERTY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_REGISTRATION_STATUS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_REGISTRATION_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "context",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "property",
        "type": "Property",
        "kind": "OBJECT"
      },
      {
        "name": "registrationStatus",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "registrationType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "value",
        "type": "ObjectScalar",
        "kind": "SCALAR"
      }
    ]
  },
  "Realization": {
    "name": "Realization",
    "fields": [
      {
        "name": "PROPERTY_ATTACHMENTS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_COMPONENT_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DUE_DATE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EXTERN_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_FINAL_REVIEWER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PERSON",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_REMARK",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_RESULT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "attachments",
        "type": "EducationCardAttachment",
        "kind": "LIST"
      },
      {
        "name": "componentVersion",
        "type": "ComponentVersion",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "dueDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "externId",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "finalReviewer",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "notFollowed",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "person",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "remark",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "result",
        "type": "RealizationResult",
        "kind": "OBJECT"
      }
    ]
  },
  "RealizationResult": {
    "name": "RealizationResult",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ReasonContractEnd": {
    "name": "ReasonContractEnd",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ReasonContractSuspension": {
    "name": "ReasonContractSuspension",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "export",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "exportCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "RecurrenceDefinition": {
    "name": "RecurrenceDefinition",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "item",
        "type": "CalendarItem",
        "kind": "OBJECT"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "pattern",
        "type": "AbstractRecurrencePattern",
        "kind": "OBJECT"
      },
      {
        "name": "range",
        "type": "AbstractRecurrenceRange",
        "kind": "OBJECT"
      }
    ]
  },
  "RecurrenceDefinitionDto": {
    "name": "RecurrenceDefinitionDto",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "pattern",
        "type": "RecurrencePatternDto",
        "kind": "OBJECT"
      },
      {
        "name": "range",
        "type": "RecurrenceRangeDto",
        "kind": "OBJECT"
      }
    ]
  },
  "RecurrencePatternDto": {
    "name": "RecurrencePatternDto",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "day",
        "type": "Day",
        "kind": "OBJECT"
      },
      {
        "name": "dayOfMonth",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dayOfYear",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "days",
        "type": "Day",
        "kind": "LIST"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "index",
        "type": "DayOfWeekIndex",
        "kind": "OBJECT"
      },
      {
        "name": "interval",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "month",
        "type": "Month",
        "kind": "OBJECT"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "startPoint",
        "type": "StartPointType",
        "kind": "OBJECT"
      }
    ]
  },
  "RecurrenceRangeDto": {
    "name": "RecurrenceRangeDto",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "endDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "occurrence",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "startDate",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "Regime": {
    "name": "Regime",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Registration": {
    "name": "Registration",
    "fields": [
      {
        "name": "PROPERTY_ATTACHMENTS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CALCULATION_RELATIONS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_COST_CENTERS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CYCLUS_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DETAIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EXTERN_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_FROM_DATE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_MELDING",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PERSONEN_MIDDELEN",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_REMARKS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_RESOURCE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_STATUS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TO_DATE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_WORK_ROSTER_ITEM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "attachments",
        "type": "RegistrationAttachment",
        "kind": "LIST"
      },
      {
        "name": "bedrijf",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "calculationRelations",
        "type": "RegistrationCalculationRelation",
        "kind": "LIST"
      },
      {
        "name": "costCenters",
        "type": "CostCenter",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "cyclusId",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "detail",
        "type": "RegistrationDetail",
        "kind": "INTERFACE"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "externId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "fromDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "melding",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "personenMiddelen",
        "type": "Int",
        "kind": "LIST"
      },
      {
        "name": "remarks",
        "type": "RegistrationRemark",
        "kind": "LIST"
      },
      {
        "name": "resource",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "RegistrationStatus",
        "kind": "OBJECT"
      },
      {
        "name": "toDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "RegistrationType",
        "kind": "OBJECT"
      },
      {
        "name": "workRosterItem",
        "type": "Boolean",
        "kind": "SCALAR"
      }
    ]
  },
  "RegistrationAttachment": {
    "name": "RegistrationAttachment",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "classType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "content",
        "type": "Base64String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fileName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "RegistrationCalculationRelation": {
    "name": "RegistrationCalculationRelation",
    "fields": [
      {
        "name": "PROPERTY_CALCULATION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "calculation",
        "type": "Calculation",
        "kind": "OBJECT"
      },
      {
        "name": "category",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "length",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "longConstant",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "result",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "shortConstant",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "year",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "RegistrationRelation": {
    "name": "RegistrationRelation",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PARENT_REGISTRATION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_REGISTRATION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "parentRegistration",
        "type": "Registration",
        "kind": "OBJECT"
      },
      {
        "name": "registration",
        "type": "Registration",
        "kind": "OBJECT"
      },
      {
        "name": "type",
        "type": "RegistrationRelationType",
        "kind": "OBJECT"
      }
    ]
  },
  "RegistrationRelationType": {
    "name": "RegistrationRelationType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "RegistrationRemark": {
    "name": "RegistrationRemark",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "value",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "RegistrationSnapshotExtra": {
    "name": "RegistrationSnapshotExtra",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "RegistrationStatus": {
    "name": "RegistrationStatus",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_OMGEVING",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "RegistrationType",
        "kind": "OBJECT"
      }
    ]
  },
  "RegistrationType": {
    "name": "RegistrationType",
    "fields": [
      {
        "name": "PROPERTY_CATEGORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "category",
        "type": "RegistrationTypeCategory",
        "kind": "OBJECT"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "statuses",
        "type": "RegistrationTypeStatusRelation",
        "kind": "LIST"
      }
    ]
  },
  "RegistrationTypeCategory": {
    "name": "RegistrationTypeCategory",
    "fields": [
      {
        "name": "PROPERTY_CATEGORY_CHILDREN",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PARENT_CATEGORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TYPES",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "categoryChildren",
        "type": "RegistrationTypeCategory",
        "kind": "LIST"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "parentCategory",
        "type": "RegistrationTypeCategory",
        "kind": "OBJECT"
      },
      {
        "name": "types",
        "type": "RegistrationType",
        "kind": "LIST"
      }
    ]
  },
  "RegistrationTypeShortcut": {
    "name": "RegistrationTypeShortcut",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_RESOURCE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "resource",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "RegistrationType",
        "kind": "OBJECT"
      }
    ]
  },
  "RegistrationTypeStatusRelation": {
    "name": "RegistrationTypeStatusRelation",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_STATUS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "RegistrationStatus",
        "kind": "OBJECT"
      },
      {
        "name": "type",
        "type": "RegistrationType",
        "kind": "OBJECT"
      }
    ]
  },
  "RelationType": {
    "name": "RelationType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ReportCategory": {
    "name": "ReportCategory",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ReportDataSourceType": {
    "name": "ReportDataSourceType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ReportDefinition": {
    "name": "ReportDefinition",
    "fields": [
      {
        "name": "PROPERTY_CATEGORIE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATA_SOURCE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAAM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_OMSCHRIJVING",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ONLINE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PARAMETERS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_RAPPORT_NAAM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ROOT_RAPPORT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_XMLS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "category",
        "type": "ReportCategory",
        "kind": "OBJECT"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataSource",
        "type": "AbstractDataSource",
        "kind": "OBJECT"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "online",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "orderIndex",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "parameters",
        "type": "ReportParameter",
        "kind": "LIST"
      },
      {
        "name": "reportName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "rootReport",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "xmls",
        "type": "ReportDefinitionXML",
        "kind": "LIST"
      }
    ]
  },
  "ReportDefinitionXML": {
    "name": "ReportDefinitionXML",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_JASPERRAPPORT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_JASPERRAPPORTBYTES",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_XML",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ReportParameter": {
    "name": "ReportParameter",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "completeName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "defaultValue",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "hasDefault",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "label",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "mandatory",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "prompting",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "securityRoleForEditingParameter",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "ResourceAttachment": {
    "name": "ResourceAttachment",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "classType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "content",
        "type": "Base64String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fileName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ResourceRosterRelation": {
    "name": "ResourceRosterRelation",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_LEAVE_ON_HOLIDAY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_REFERENCE_DATE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_RESOURCE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ROSTER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "leaveOnHoliday",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "referenceDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "resource",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "roster",
        "type": "Roster",
        "kind": "OBJECT"
      },
      {
        "name": "rosterRegistrations",
        "type": "RosterRegistration",
        "kind": "LIST"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "ResourceWorkRoster": {
    "name": "ResourceWorkRoster",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_OFFSET",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_STATUS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "offset",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "resource",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "RegistrationStatus",
        "kind": "OBJECT"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "Roster": {
    "name": "Roster",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "definition",
        "type": "Base64String",
        "kind": "SCALAR"
      },
      {
        "name": "definitionAsString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "RotationSystem": {
    "name": "RotationSystem",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "RoundingType": {
    "name": "RoundingType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "SalaryComponent": {
    "name": "SalaryComponent",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "values",
        "type": "SalaryComponentValue",
        "kind": "LIST"
      }
    ]
  },
  "SalaryComponentValue": {
    "name": "SalaryComponentValue",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "category",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "salaryComponent",
        "type": "SalaryComponent",
        "kind": "OBJECT"
      },
      {
        "name": "salaryPeriod",
        "type": "SalaryPeriod",
        "kind": "OBJECT"
      },
      {
        "name": "value",
        "type": "Float",
        "kind": "SCALAR"
      }
    ]
  },
  "SalaryPeriod": {
    "name": "SalaryPeriod",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "simulation",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "Scale": {
    "name": "Scale",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ScaleHistoryItem": {
    "name": "ScaleHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "extraPercentage",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "scalePercentage",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "Score": {
    "name": "Score",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EVALUATION_CRITERIUM_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PERCENTAGE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_SCALE_SCORE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "evaluationCriteriumVersion",
        "type": "EvaluationCriterionVersion",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "percentage",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "scaleScore",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "SecurityDefinition": {
    "name": "SecurityDefinition",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "history",
        "type": "SecurityHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "SecurityHistoryItem": {
    "name": "SecurityHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "profiles",
        "type": "SecurityProfile",
        "kind": "LIST"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "SecurityPermission": {
    "name": "SecurityPermission",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "SecurityProfile": {
    "name": "SecurityProfile",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "permissions",
        "type": "SecurityPermission",
        "kind": "LIST"
      }
    ]
  },
  "SequenceDefinition": {
    "name": "SequenceDefinition",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "history",
        "type": "SequenceHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "SequenceHistoryItem": {
    "name": "SequenceHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "jump",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "referenceDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "resources",
        "type": "Int",
        "kind": "LIST"
      },
      {
        "name": "rotationSystem",
        "type": "RotationSystem",
        "kind": "OBJECT"
      },
      {
        "name": "startingPosition",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "ShareRegisterHistoryItem": {
    "name": "ShareRegisterHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_AMOUNT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "amount",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "ShipCt": {
    "name": "ShipCt",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "ShipType": {
    "name": "ShipType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Spare": {
    "name": "Spare",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "orderIndex",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "StartPointType": {
    "name": "StartPointType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "StaticGroup": {
    "name": "StaticGroup",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CONTEXTS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_GROUP_RESOURCES_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_OWNER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "constraintDefinitions",
        "type": "ConstraintDefinition",
        "kind": "LIST"
      },
      {
        "name": "contexts",
        "type": "GroupContext",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "groupResourcesHistory",
        "type": "GroupResourcesHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "owner",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "properties",
        "type": "GroupProperty",
        "kind": "LIST"
      },
      {
        "name": "securityDefinition",
        "type": "SecurityDefinition",
        "kind": "OBJECT"
      },
      {
        "name": "sequenceDefinition",
        "type": "SequenceDefinition",
        "kind": "OBJECT"
      },
      {
        "name": "successionDefinition",
        "type": "SuccessionDefinition",
        "kind": "OBJECT"
      }
    ]
  },
  "SuccessionDefinition": {
    "name": "SuccessionDefinition",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_HISTORY",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "history",
        "type": "SuccessionHistoryItem",
        "kind": "LIST"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "SuccessionHistoryItem": {
    "name": "SuccessionHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "resources",
        "type": "Int",
        "kind": "LIST"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "TaxStatus": {
    "name": "TaxStatus",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "TaxStatusHistoryItem": {
    "name": "TaxStatusHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_AANTAL_ANDEREN",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_AANTAL_KINDEREN",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_AANTAL_MINDEREN_VALIDE_ANDEREN",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_AANTAL_MINDER_VALIDE_KINDEREN",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ECHTGENOOT_MINDER_VALIDE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ECHTGENOOT_TEN_LASTE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TAX_STATUS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_WERKNEMER_MINDER_VALIDE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "aantalAnderen",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "aantalKinderen",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "aantalMinderValideAnderen",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "aantalMinderValideKinderen",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "echtgenootMinderValide",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "echtgenootTenLaste",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "taxStatus",
        "type": "TaxStatus",
        "kind": "OBJECT"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "werknemerMinderValide",
        "type": "Boolean",
        "kind": "SCALAR"
      }
    ]
  },
  "Team": {
    "name": "Team",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "TeamHistoryItem": {
    "name": "TeamHistoryItem",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TEAM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_FROM",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALID_UNTIL",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "team",
        "type": "Team",
        "kind": "OBJECT"
      },
      {
        "name": "validFrom",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validUntil",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "Telecom": {
    "name": "Telecom",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TYPE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VALUE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "TelecomType",
        "kind": "OBJECT"
      },
      {
        "name": "value",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "TelecomType": {
    "name": "TelecomType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "TimeScheduleType": {
    "name": "TimeScheduleType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fromDateFriday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fromDateHoliday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fromDateMonday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fromDateSaturday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fromDateSunday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fromDateThursday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fromDateTuesday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fromDateWednesday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "ship",
        "type": "ShipCt",
        "kind": "OBJECT"
      },
      {
        "name": "spare",
        "type": "Spare",
        "kind": "OBJECT"
      },
      {
        "name": "toDateFriday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "toDateHoliday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "toDateMonday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "toDateSaturday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "toDateSunday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "toDateThursday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "toDateTuesday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "toDateWednesday",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "workPeriodType",
        "type": "WorkPeriodType",
        "kind": "OBJECT"
      }
    ]
  },
  "Track": {
    "name": "Track",
    "fields": [
      {
        "name": "PROPERTY_CATALOG",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CURRENT_TRACK_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "catalog",
        "type": "CatalogSnapshot",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "currentTrackVersion",
        "type": "TrackVersion",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "TrackVersion": {
    "name": "TrackVersion",
    "fields": [
      {
        "name": "PROPERTY_ACTIVE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ATTACHMENTS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_CHILDREN",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TRACK",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "active",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "attachments",
        "type": "CatalogAttachment",
        "kind": "LIST"
      },
      {
        "name": "children",
        "type": "TrackVersionChild",
        "kind": "LIST"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "majorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "minorVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "track",
        "type": "Track",
        "kind": "OBJECT"
      }
    ]
  },
  "TrackVersionChild": {
    "name": "TrackVersionChild",
    "fields": [
      {
        "name": "PROPERTY_COMPONENT_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_MODULE_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TRACK_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "componentVersion",
        "type": "ComponentVersion",
        "kind": "OBJECT"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "moduleVersion",
        "type": "ModuleVersion",
        "kind": "OBJECT"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "trackVersion",
        "type": "TrackVersion",
        "kind": "OBJECT"
      }
    ]
  },
  "TrainingSession": {
    "name": "TrainingSession",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ENVIRONMENT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_NAME",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_REMARK",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_STATUS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TIME_PERIODS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "archiveDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "environment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "owner",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "remark",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "TrainingSessionStatus",
        "kind": "OBJECT"
      },
      {
        "name": "timePeriods",
        "type": "TrainingTimePeriod",
        "kind": "LIST"
      }
    ]
  },
  "TrainingSessionStatus": {
    "name": "TrainingSessionStatus",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "TrainingTimePeriod": {
    "name": "TrainingTimePeriod",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_EXTRA_LOGISTICS_REGISTRATIONS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_FROM_DATE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_PARTICIPANT_REGISTRATIONS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_RELIEVE_REGISTRATIONS",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_SESSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TO_DATE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TRAINER_REGISTRATION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TRAINING_CENTER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_TRAINING_COMPONENT_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "extraLogisticsRegistrations",
        "type": "Registration",
        "kind": "LIST"
      },
      {
        "name": "fromDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "participantRegistrations",
        "type": "Registration",
        "kind": "LIST"
      },
      {
        "name": "relieveRegistrations",
        "type": "Registration",
        "kind": "LIST"
      },
      {
        "name": "resourcesToErrorPolicy",
        "type": "Map_Integer_ErrorMessagePolicyScalar",
        "kind": "SCALAR"
      },
      {
        "name": "session",
        "type": "TrainingSession",
        "kind": "OBJECT"
      },
      {
        "name": "toDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "trainer",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "trainerRegistration",
        "type": "Registration",
        "kind": "OBJECT"
      },
      {
        "name": "trainingCenter",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "trainingComponentVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "TransitionDefinition": {
    "name": "TransitionDefinition",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "condition",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "from",
        "type": "ActivityDefinition",
        "kind": "OBJECT"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "to",
        "type": "ActivityDefinition",
        "kind": "OBJECT"
      }
    ]
  },
  "UserValue": {
    "name": "UserValue",
    "fields": [
      {
        "name": "PROPERTY_CONTEXT",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_SYSTEM_PROPERTY_VALUE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "context",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "systemPropertyValue",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "user",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "value",
        "type": "ObjectScalar",
        "kind": "SCALAR"
      }
    ]
  },
  "VisibilityGroup": {
    "name": "VisibilityGroup",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_GROUP_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "groupCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Vocative": {
    "name": "Vocative",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "WorkArrangement": {
    "name": "WorkArrangement",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "WorkPeriodType": {
    "name": "WorkPeriodType",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "WorkRoster": {
    "name": "WorkRoster",
    "fields": [
      {
        "name": "PROPERTY_CODE",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DESCRIPTION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "definition",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "WorkRosterToTimeSchedule": {
    "name": "WorkRosterToTimeSchedule",
    "fields": [
      {
        "name": "PROPERTY_DATE_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_DATE_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_ID",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_CREATED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_USER_MODIFIED",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "PROPERTY_VERSION",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fromDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "id",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "modificationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "modificationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "roster",
        "type": "WorkRoster",
        "kind": "OBJECT"
      },
      {
        "name": "timeSchedule",
        "type": "TimeScheduleType",
        "kind": "LIST"
      },
      {
        "name": "toDate",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  }
}