import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { ModalExt, Severity } from '@crispico/foundation-react/components/ModalExt/ModalExt';

interface Props {
    when: boolean | undefined;
}
export const LeavePageModal = ({ when }: Props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const history = useHistory();

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <ModalExt
                severity={Severity.WARNING}
                open={modalVisible}
                header={_msg("warning.label")}
                content={_msg("unsavedDataWarning.label")}
                onClose={closeModal}
                actions={[
                    <Button key="cancel" onClick={closeModal}>{_msg("general.cancel")}</Button>,
                    <Button key="ok" primary onClick={handleConfirmNavigationClick}>{_msg("general.ok")}</Button>
                ]}
            />
        </>
    );
}