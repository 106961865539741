'use strict';

module.exports = {
	name: 'proteus.account',
	path: 'account.module.js',
	routes: {
		'/account/login': {
			templateUrl: 'account/login/login-page.html',
			controller: 'LoginPageCtrl',
			controllerAs: 'loginPage'
		},
		'/account/login-with-proteus': {
			templateUrl: 'account/login/login-with-proteus/login-with-proteus-page.html',
			controller: 'LoginWithProteusPageCtrl',
			controllerAs: 'loginWithProteusPage'
		}
	}
};