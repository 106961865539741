import { apolloClient, createSliceFoundation, EntityDescriptor, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { Component, ComponentVersion, RESPONSE_OK, TrainingCompetenceVersion } from "../Catalog";
import { Button, Form, Icon, Input, InputProps, Label, Menu, Modal, Segment, Tab, TabProps } from "semantic-ui-react";
import { NAME_VERSION_SEPARATOR, trainerCertificatesDescriptor, trainerCompetencesDescriptor } from "../customFieldRendererEditors";
import { CatalogItemEditor } from "./CatalogItemEditor";
import { SliceComponentItemEditor } from "./ComponentItemEditor";
import { CompetenceTreeRRC, CompetenceTree } from "pages/competences/CompetenceTree";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { CustomEditor } from "pages/groupsManagement/customFieldRenderersEditors";
import { Competence, CompetenceCategory, CompetenceVersion } from "pages/competences/Competences";
import _ from "lodash";
import React, { createRef } from "react";
import { OnSelectParams, PropsNotFromState, RenderItemParams, Tree, TreeReducers, TreeState } from "@crispico/foundation-react/components/TreeRRC/Tree";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { TRAINING_SERVICE_ENDPOINT_GET_PROFILE_VERSION } from "graphql/queries";
import { BasicEntity } from "components/uploadButton/RegistrationAndEducationFileUploadButton";
import { COMPONENT_VERSION_TREE_LABEL_CLASS } from "../CatalogTree";
import { FieldEditor, FieldEditorNotUsableStandAloneProps, ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { ProgressFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/ProgressFieldEditor";

const PROFILE_VERSION = "ProfileVersion";
const PROFILE = "Profile";
const WEIGHT = "weight";
const PROFILE_DATA = "ProfileData";
const REQUIRED = "required";
const CHILD = "child";

export interface CompetenceProfileCriterionVersion extends BasicEntity {
    currentCompetenceVersion: CompetenceVersion,
    category: CompetenceCategory,
    weight: number,
    required: boolean,
}

export interface Profile extends BasicEntity {
    objectType: string,
}

export interface ProfileVersion extends BasicEntity {
    components: Component[],
    competenceProfileCriterionVersions: CompetenceProfileCriterionVersion[],
    profile: Profile,
    objectType: string,
    name: string,
}

const sliceComponentItemEditor = createSliceFoundation(SliceComponentItemEditor, true);

export const sliceEducationComponentItemEditor = createSliceFoundation(class SliceEducationComponentItemEditor {
    initialState = {
        ...sliceComponentItemEditor.initialState,
        rootCompetences: undefined as unknown as CompetenceCategory[],
        openModalAddCompetences: false,
        trainingCompetenceVersions: undefined as TrainingCompetenceVersion[] | undefined,
        isCompetenceTreeModalOpen: false,
        selectedCertificate: undefined as unknown as Component,
        profileData: undefined as unknown as any,
        filteredProfileData: undefined as unknown as any,
        showProfileTree: false,
        searchExpressionProfileTree: "",
        profileVersion: undefined as unknown as ProfileVersion,
        defaultProfileVersion: undefined as unknown as ProfileVersion,
        addProfileVersionMode: false,
        selectedCompetenceToAdd: undefined as unknown as Competence,
        addedCompetences: [] as any[],
        removedCompetences: [] as any[],
    };

    nestedSlices = {
        ...sliceComponentItemEditor.nestedSlices,
        trainerCompetenceTable: sliceEntityTableLight,
        trainerCertificatesTable: sliceEntityTableLight
    };

    reducers = {
        ...sliceComponentItemEditor.reducers,
        ...getBaseReducers<SliceEducationComponentItemEditor>(this),

        update(state: StateFrom<SliceEducationComponentItemEditor>, p: { componentVersion: ComponentVersion, dirty: boolean; }) {
            state.component.currentComponentVersion = p.componentVersion;
            state.dirty = p.dirty;
        },

        setEducationComponent(state: StateFrom<SliceEducationComponentItemEditor>, p: {
            component: Component,
            competenceCategories: CompetenceCategory[];
        }) {
            state.dirty = false;
            const { component, competenceCategories } = p;
            state.rootCompetences = competenceCategories;
            this.setComponent(state, component);

            this.setCatalogItemVersion(state, state.component.currentComponentVersion);
        },

        onSaveComponent(state: StateFrom<SliceEducationComponentItemEditor>) {
            state.createNewMajorVersion = false;
            state.defaultProfileVersion = state.profileVersion;
            state.defaultComponent = state.component;
            state.addedCompetences = [];
            state.removedCompetences = [];
            state.dirty = false;
        }
    };

    impures = {
        ...getBaseImpures<SliceEducationComponentItemEditor>(this),

        async getProfileData() {
            const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
            const url = Utils.adjustUrlToServerContext(additionalUrlParam + `restful/profileService/getProfileData`);
            let response = await fetch(url as string);
            if (response.status == RESPONSE_OK) {
                const profileData = await response.json() as any;
                this.getDispatchers().setInReduxState({ profileData });
            }
        },

        async getTrainingCompetenceVersion() {
            if (!this.getState().component.currentComponentVersion.id) {
                this.getDispatchers().setInReduxState({ trainingCompetenceVersions: [] });
                return;
            }

            const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
            const url = Utils.adjustUrlToServerContext(additionalUrlParam + `restful/trainingServiceEndpoint/trainingCompetences/${this.getState().component.currentComponentVersion.id}`);
            let response = await fetch(url as string);
            if (response.status == RESPONSE_OK) {
                const trainingCompetenceVersions = await response.json() as TrainingCompetenceVersion[];
                this.getDispatchers().setInReduxState({ trainingCompetenceVersions });
            }
        }
    };
});

class ProfileVersionTreeState extends TreeState {
}

class ProfileVersionTreeReducers<S extends ProfileVersionTreeState = ProfileVersionTreeState> extends TreeReducers<S> {
    protected _getChildren(item: any): { localId: string, item: any; }[] {
        if (Array.isArray(item)) {
            return super._getChildren(item, {});
        }

        const children = item.profileVersions as Array<any>;

        if (children && children.length !== 0) {
            return (children.map((item, index) => ({ localId: "profileVersions" + Utils.defaultIdSeparator + index, item })));
        } else {
            return item.name;
        }
    }

    protected _hasChildren(item: any) {
        if (item.profileVersions && item.profileVersions.length > 0) {
            return true;
        }
        return false;
    }
}

type TreeProps = PropsNotFromState & RRCProps<ProfileVersionTreeState, ProfileVersionTreeReducers>;

class ProfileVersionTree extends Tree<TreeProps> {

    protected renderItem(params: RenderItemParams): React.ReactNode {
        const objectId = params.linearizedItem.itemId.split(Utils.defaultIdSeparator);
        const object = Utils.navigate(this.props.root, objectId);
        let version;

        if (object.objectType === PROFILE_DATA) {
            version = object.profile.currentProfileVersion;
        } else {
            version = object;
        }

        return <React.Fragment key={params.linearizedItem.itemId}>
            {`${version.name} `}
            <span className={COMPONENT_VERSION_TREE_LABEL_CLASS}>
                {` v${version?.majorVersion}.${version?.minorVersion}`}
            </span>
        </React.Fragment>;
    }
}

export const ProfileVersionTreeRRC = ReduxReusableComponents.connectRRC(ProfileVersionTreeState, ProfileVersionTreeReducers, ProfileVersionTree);

type TrainerCompetencesChildFieldEditorProps = FieldEditorNotUsableStandAloneProps & {
    rootCompetences: any;
};

export class TrainerCompetencesChildFieldEditor extends FieldEditor<any, TrainerCompetencesChildFieldEditorProps, { isCompetenceTreeModalOpen: boolean; }> {

    constructor(props: TrainerCompetencesChildFieldEditorProps) {
        super(props);
        this.state = {
            isCompetenceTreeModalOpen: false,
        };
    }

    componentDidMount(): void {
        if (!this.props.formikProps.values.weight) {
            this.props.formikProps.setFieldValue(WEIGHT, 0);
        }
        if (!this.props.formikProps.values.required) {
            this.props.formikProps.setFieldValue(REQUIRED, true);
        }
    }

    onSelectCompetence = (competence: Competence) => {
        this.props.formikProps.setFieldValue("selectedCompetence", competence);
    };

    setCompetence = () => {
        this.props.formikProps.setFieldValue("competence", this.props.formikProps.values.selectedCompetence);
        this.props.formikProps.setFieldValue("name", this.props.formikProps.values.selectedCompetence.currentCompetenceVersion.name);
        this.setState({ isCompetenceTreeModalOpen: false });
    };

    resetCompetence = () => {
        this.setState({ isCompetenceTreeModalOpen: false });
        this.props.formikProps.setFieldValue("selectedCompetence", undefined);
    };

    render = () => {
        return <>
            <div className="TrainerCompetencesChildRenderer_formContainer">
                <span className="flex-grow TrainerCompetencesChildRenderer_inputContainer">
                    <Form.Input className="w100" readOnly value={this.props.formikProps.values.name?.split(NAME_VERSION_SEPARATOR)[0]} />
                </span>
                <Button icon type="button" onClick={() => this.setState({ isCompetenceTreeModalOpen: true })}>
                    <Icon name='edit' color="blue" />
                </Button>
            </div>
            <ModalExt
                open={this.state.isCompetenceTreeModalOpen}
                size="large"
                onClose={() => this.resetCompetence()}
            >
                <Modal.Header>
                    {_msg("EducationComponentItemEditor.competences.label")}
                </Modal.Header>
                <Modal.Content scrolling>
                    <CompetenceTreeRRC
                        id="CompetenceTreeRRC"
                        searchExpression=""
                        root={this.props.rootCompetences}
                        active notActive hideActions
                        onSelectCompetenceTemp={this.onSelectCompetence}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.resetCompetence()}>
                        {_msg("Catalog.cancel.label")}
                    </Button>
                    <Button onClick={() => this.setCompetence()}>
                        {_msg("Catalog.ok.label")}
                    </Button>
                </Modal.Actions>
            </ModalExt>
        </>;
    };
}

export class WeightFieldEditor extends ProgressFieldEditor<FieldEditorProps> {

    protected getInputProps(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper): InputProps | undefined {
        return { type: "number", min: 0, max: 100 };
    }
}

type Props = PropsFrom<typeof sliceEducationComponentItemEditor> & {
    certificates: Component[],
};

export class EducationComponentItemEditor extends CatalogItemEditor<Props> {

    protected refEntityTableLightCompetences = createRef<EntityTableLight>();
    protected refEntityTableLightCertificates = createRef<EntityTableLight>();
    protected refCompetenceTree = createRef<CompetenceTree>();

    setProfileVersion(profileVersion: any) {
        this.props.dispatchers.setInReduxState({ profileVersion: profileVersion });
        if (this.props.defaultProfileVersion === undefined) {
            this.props.dispatchers.setInReduxState({ defaultProfileVersion: profileVersion });
        };
        this.setTrainerCompetenceTable(profileVersion?.competenceProfileCriterionVersions);
        this.setTrainerCertificatesTable(profileVersion?.components);
    }

    setTrainerCompetenceTable(competenceProfileCriterionVersions: any) {
        const entities = this.mapCompetences(competenceProfileCriterionVersions);
        this.refEntityTableLightCompetences.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(entities);
    }

    setTrainerCertificatesTable(components: Component[]) {
        const entities = this.mapCertificates(components);
        this.refEntityTableLightCertificates.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(entities);
    }

    async getProfileVersion(profileId: number) {
        const profileVersion = (await apolloClient.query({
            query: TRAINING_SERVICE_ENDPOINT_GET_PROFILE_VERSION,
            variables: {
                profileId: profileId
            },
            context: {
                showSpinner: false
            }
        })).data.trainingServiceEndpoint_profileVersion;

        this.setProfileVersion(profileVersion);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.component.id !== this.props.component.id) {
            this.props.dispatchers.setInReduxState({
                profileVersion: undefined,
                addProfileVersionMode: false,
                addedCompetences: [],
                removedCompetences: [],
                selectedCompetenceToAdd: undefined,
                defaultProfileVersion: undefined,
                showProfileTree: false,
                searchExpressionProfileTree: "",
                selectedCertificate: undefined
            });
            if (this.props.component.currentComponentVersion.trainerProfileVersion) {
                this.getProfileVersion(this.props.component.currentComponentVersion.trainerProfileVersion);
            } else {
                this.setProfileVersion(null);
            }
            this.props.dispatchers.getTrainingCompetenceVersion();
        }
        if (prevProps.component.currentComponentVersion.id !== this.props.component.currentComponentVersion.id) {
            this.props.dispatchers.getTrainingCompetenceVersion();
        }
        if (prevProps.component.currentComponentVersion.id !== this.props.component.currentComponentVersion.id && this.props.component.currentComponentVersion.trainerProfileVersion) {
            this.getProfileVersion(this.props.component.currentComponentVersion.trainerProfileVersion);
        }
        if (prevProps.profileVersion !== this.props.profileVersion ||
            prevProps.addedCompetences !== this.props.addedCompetences ||
            prevProps.removedCompetences !== this.props.removedCompetences) {
            this.update();
        }
        if (!_.isEqual(prevProps.trainingCompetenceVersions, this.props.trainingCompetenceVersions) && this.props.trainingCompetenceVersions) {
            this.refCompetenceTree.current?.setInitialExpandedIds(this.refCompetenceTree.current.getExpandedIdsRecursive(this.props.trainingCompetenceVersions[0].categories, ""));
        }

        let profileVersion = _.cloneDeep(this.props.profileVersion);
        if (this.refEntityTableLightCompetences.current && profileVersion && !_.isEqual(this.mapCompetences(profileVersion.competenceProfileCriterionVersions), this.refEntityTableLightCompetences.current.getEntityTableSimpleCustomizedRef().current?.getEntities())) {
            this.setTrainerCompetenceTable(profileVersion.competenceProfileCriterionVersions);
        }

        if (this.refEntityTableLightCertificates.current && profileVersion && !_.isEqual(this.mapCertificates(profileVersion.components), this.refEntityTableLightCertificates.current.getEntityTableSimpleCustomizedRef().current?.getEntities())) {
            this.setTrainerCertificatesTable(profileVersion.components);
        }

        super.componentDidUpdate(prevProps);
    }

    componentDidMount(): void {
        if (this.props.component.currentComponentVersion.trainerProfileVersion) {
            this.getProfileVersion(this.props.component.currentComponentVersion.trainerProfileVersion);
        } else {
            this.setProfileVersion(null);
        }
        this.props.dispatchers.getTrainingCompetenceVersion();
    }

    mapCompetences(competenceProfileCriterionVersions: any) {
        let childrenForTable = [];
        let children = competenceProfileCriterionVersions;
        for (let i = 0; i < children?.length; i++) {
            childrenForTable.push({
                name: children[i].competence.currentCompetenceVersion.name + NAME_VERSION_SEPARATOR +
                    `v${children[i].competence.currentCompetenceVersion.majorVersion}.${children[i].competence.currentCompetenceVersion.minorVersion}`,
                weight: children[i].weight,
                required: children[i].required
            });
        }
        return childrenForTable;
    }

    mapCertificates(components: Component[]) {
        let childrenForTable = [];
        let children = components;
        for (let i = 0; i < children?.length; i++) {
            childrenForTable.push({
                name: children[i].currentComponentVersion.name,
            });
        }
        return childrenForTable;
    }

    getEditorForTrainerCompetences() {
        const that = this;
        return new EntityDescriptor({ name: "TrainerCompetences" })
            .addFieldDescriptor({ name: CHILD, type: FieldType.string }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        rootCompetences: that.props.rootCompetences
                    };
                    return React.createElement(TrainerCompetencesChildFieldEditor as any, newProps as FieldEditorProps);
                }
            }())
            .addFieldDescriptor({ name: WEIGHT, type: FieldType.number }, new class extends FieldDescriptor {

                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    return React.createElement(WeightFieldEditor as any, props as FieldEditorProps);
                }
            })
            .addFieldDescriptor({ name: REQUIRED, type: FieldType.boolean });
    }

    getEditorForTrainerCertificates = () => {
        const that = this;
        return new EntityDescriptor({ name: "TrainerCertificates" })
            .addFieldDescriptor({ name: CHILD, type: FieldType.string }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    let newProps = { ...props, getCustomEditorContent: that.getTrainerCertificatesForm };
                    return super.renderFieldEditorInternal(CustomEditor, newProps);
                }
            });
    };

    getTrainerCertificatesForm = () => {
        return <div className="flex flex-grow">
            <Segment.Group>
                {this.props.certificates.map(certificate =>
                    <Segment className={_.isEqual(certificate, this.props.selectedCertificate) ? "selectedItem" : undefined}
                        onClick={() =>
                            this.props.dispatchers.setInReduxState({ selectedCertificate: certificate })}
                    >
                        {certificate.currentComponentVersion.name}
                    </Segment>)}
            </Segment.Group>
        </div>;
    };

    handleSelectCompetence = (competence: any) => {
        this.props.dispatchers.setInReduxState({ selectedCompetenceToAdd: competence });
    };

    addCompetenceToTree = (competence: any) => {
        if (!this.props.trainingCompetenceVersions) {
            return false;
        }

        let trainingCompetenceVersionsCopy: any = _.cloneDeep(this.props.trainingCompetenceVersions);
        if (trainingCompetenceVersionsCopy.length === 0) {
            trainingCompetenceVersionsCopy.push({ categories: [] });
        }
        let currentCategory: any = trainingCompetenceVersionsCopy[0].categories;
        let currentParent = competence.category;
        let levels = [];
        let i = 0, currentCompetences;

        levels.push(competence);
        levels.unshift(currentParent);
        while (currentParent.parentCategory) {
            currentParent = currentParent.parentCategory;
            levels.unshift(currentParent);
        }

        for (let level of levels) {
            if (i < levels.length - 1) {
                let foundIndex = -1;
                for (let index = 0; index < currentCategory.length; index++) {
                    if (currentCategory[index].name === level.name) {
                        foundIndex = index;
                    }
                }
                if (foundIndex !== -1) {
                    currentCompetences = currentCategory[foundIndex].competences;
                    currentCategory = currentCategory[foundIndex].categoryChildren;
                } else {
                    currentCategory.push({ ...level, categoryChildren: [], competences: [] });
                    currentCompetences = currentCategory[currentCategory.length - 1].competences;
                    currentCategory = currentCategory[currentCategory.length - 1].categoryChildren;
                }
            } else {
                let foundIndex = -1;
                for (let index = 0; index < currentCompetences.length; index++) {
                    if (currentCompetences[index].currentCompetenceVersion.name === level.currentCompetenceVersion.name) {
                        foundIndex = index;
                    }
                }

                if (foundIndex !== -1) {
                    return false; // competence is already present
                }

                currentCompetences.push(competence);
            }
            i++;
        }
        this.props.dispatchers.setInReduxState({ trainingCompetenceVersions: trainingCompetenceVersionsCopy });
        return true;
    };

    onRemoveCompetence = (competence: any) => {
        if (!this.props.trainingCompetenceVersions) {
            return false;
        }

        let trainingCompetenceVersionsCopy: any = _.cloneDeep(this.props.trainingCompetenceVersions);
        let currentCategory: any = trainingCompetenceVersionsCopy[0].categories;

        let levels = [];
        let currentParent = competence.category;
        let i = 0, currentCompetences;

        levels.push(competence);
        levels.unshift(currentParent);
        while (currentParent.parentCategory) {
            currentParent = currentParent.parentCategory;
            levels.unshift(currentParent);
        }

        let foundIndex;
        for (let level of levels) {
            if (i < levels.length - 1) {
                foundIndex = -1;
                for (let index = 0; index < currentCategory.length; index++) {
                    if (currentCategory[index].name === level.name) {
                        foundIndex = index;
                    }
                }
                currentCompetences = currentCategory[foundIndex].competences;
                currentCategory = currentCategory[foundIndex].categoryChildren;
            } else {
                foundIndex = -1;
                for (let index = 0; index < currentCompetences.length; index++) {
                    if (currentCompetences[index].currentCompetenceVersion.name === level.currentCompetenceVersion.name) {
                        foundIndex = index;
                    }
                }
                currentCompetences.splice(foundIndex, 1);
            }
            i++;
        }

        i = 0;
        currentCategory = trainingCompetenceVersionsCopy[0].categories;
        if (foundIndex !== -1) {
            for (let level of levels) {
                if (i < levels.length - 1) {
                    foundIndex = -1;
                    for (let index = 0; index < currentCategory.length; index++) {
                        if (currentCategory[index].name === level.name) {
                            foundIndex = index;
                        }
                    }
                    if (foundIndex === -1) {
                        break;
                    }
                    if (currentCategory[foundIndex].competences.length === 0 && currentCategory[foundIndex].categoryChildren.length === 0) {
                        currentCategory.splice(foundIndex, 1);
                        break;
                    }
                    currentCategory = currentCategory[foundIndex].categoryChildren;
                }
            }
        }
        trainingCompetenceVersionsCopy[0].categories = this.adjustCompetenceCategory(trainingCompetenceVersionsCopy[0].categories);
        this.props.dispatchers.setInReduxState({ trainingCompetenceVersions: trainingCompetenceVersionsCopy });
        const removedCompetences = _.cloneDeep(this.props.addedCompetences);
        removedCompetences.push(competence.id);
        this.props.dispatchers.setInReduxState({ removedCompetences: removedCompetences });
        let component = _.cloneDeep(this.props.component);
        component.currentComponentVersion.competenceVersions = component.currentComponentVersion.competenceVersions.filter((comp: any) =>
            comp.id !== competence.currentCompetenceVersion.id);

        this.props.dispatchers.setInReduxState({ component });
    };

    adjustCompetenceCategory(competenceCategories: CompetenceCategory[]) {
        const adjustedCompeteneCategories: CompetenceCategory[] = [];
        for (let competenceCategory of competenceCategories) {
            if (competenceCategory.categoryChildren.length > 0) {
                competenceCategory.categoryChildren = this.adjustCompetenceCategory(competenceCategory.categoryChildren);
            }
            if (competenceCategory.competences.length === 0 && competenceCategory.categoryChildren.length === 0) {
                continue;
            }
            adjustedCompeteneCategories.push(competenceCategory);
        }
        return adjustedCompeteneCategories;
    }

    getCompetencesTab() {
        const root = this.props.trainingCompetenceVersions?.length ? this.props.trainingCompetenceVersions[0].categories : [];
        return {
            menuItem: (
                <Menu.Item key='competences'>
                    {_msg("EducationComponentItemEditor.competences.label")}
                    <Label>{this.props.component?.currentComponentVersion?.competenceVersions?.length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane className="flex flex-container flex-grow">
                <div className="flex flex-grow flex-container Catalog">
                    <div>
                        <Button disabled={this.props.isOldVersionSelected} color="green" onClick={() => this.props.dispatchers.setInReduxState({ openModalAddCompetences: true })}>
                            <Icon name="plus" />
                            {_msg("EducationComponentItemEditor.competence.label")}
                        </Button>
                        <ModalExt open={this.props.openModalAddCompetences} onClose={() => {
                            this.props.dispatchers.setInReduxState({ openModalAddCompetences: false });
                        }}>
                            <Modal.Header>
                                {_msg("EducationComponentItemEditor.competences.label")}
                            </Modal.Header>
                            <Modal.Content scrolling>
                                <div className="flex flex-grow">
                                    <CompetenceTreeRRC
                                        id="CompetenceTreeRRC"
                                        searchExpression=""
                                        root={this.props.rootCompetences}
                                        active notActive hideActions
                                        onSelectCompetenceTemp={this.handleSelectCompetence}
                                    />
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => this.props.dispatchers.setInReduxState({ openModalAddCompetences: false, selectedCompetenceToAdd: undefined })}>{_msg("Catalog.cancel.label")}</Button>
                                <Button primary onClick={() => {
                                    const wasAdded = this.addCompetenceToTree(this.props.selectedCompetenceToAdd);
                                    if (wasAdded) {
                                        const addedCompetences = _.cloneDeep(this.props.addedCompetences);
                                        addedCompetences.push(this.props.selectedCompetenceToAdd.id);
                                        this.props.dispatchers.setInReduxState({ addedCompetences });
                                        let component = _.cloneDeep(this.props.component);
                                        component.currentComponentVersion.competenceVersions.push({
                                            ...this.props.selectedCompetenceToAdd.currentCompetenceVersion,
                                            competence: {
                                                ...this.props.selectedCompetenceToAdd,
                                                currentCompetenceVersion: undefined
                                            }
                                        });
                                        this.props.dispatchers.setInReduxState({ component });
                                    }
                                    this.props.dispatchers.setInReduxState({ openModalAddCompetences: false });
                                }}>{_msg("Catalog.ok.label")}</Button>
                            </Modal.Actions>
                        </ModalExt>
                    </div>
                    {root.length > 0 && <CompetenceTreeRRC
                        id="CompetenceTreeRRC2"
                        searchExpression=""
                        ref={this.refCompetenceTree}
                        root={root}
                        active notActive hideActions renderDeleteOnLeaves={!this.props.isOldVersionSelected}
                        onDeleteCompetence={this.onRemoveCompetence} />}
                </div>
            </Tab.Pane>,
        };
    }

    onAddCompetenceToProfile = (competences: any[], modifiedCompetence: any) => {
        if (!modifiedCompetence.values.competence) {
            return;
        }

        let profileVersion = _.cloneDeep(this.props.profileVersion);
        if (modifiedCompetence.rowIndex === -1) {
            profileVersion.competenceProfileCriterionVersions.push({
                ...modifiedCompetence.values,
                name: undefined
            });
        } else {
            profileVersion.competenceProfileCriterionVersions[modifiedCompetence.rowIndex] = {
                ...profileVersion.competenceProfileCriterionVersions[modifiedCompetence.rowIndex],
                ...modifiedCompetence.values,
                name: undefined
            };
        }

        this.setTrainerCompetenceTable(profileVersion.competenceProfileCriterionVersions);
        this.props.dispatchers.setInReduxState({ profileVersion });
        this.update(this.props.component.currentComponentVersion);
    };

    onDeleteCompetenceFromProfile(remainingEntities: any[], removedEntityIndex: number) {
        let profileVersion = _.cloneDeep(this.props.profileVersion);
        profileVersion.competenceProfileCriterionVersions.splice(removedEntityIndex, 1);

        this.setTrainerCompetenceTable(profileVersion.competenceProfileCriterionVersions);
        this.props.dispatchers.setInReduxState({ profileVersion });
        this.update(this.props.component.currentComponentVersion);
    }

    isProfileVersion = () => !!this.props.profileVersion;

    onAddTrainerCertificate = () => {
        let profileVersion = _.cloneDeep(this.props.profileVersion);
        profileVersion.components.push(this.props.selectedCertificate);
        this.setTrainerCertificatesTable(profileVersion.components);
        this.props.dispatchers.setInReduxState({ profileVersion, selectedCertificate: undefined });
        this.update();
    };

    onDeleteTrainerCertificate = (remainingEntities: any, removedEntityIndex: number) => {
        let names: any[] = [];
        remainingEntities.forEach((entity: any) => {
            names.push(entity.name);
        });
        let components = _.cloneDeep(this.props.profileVersion?.components).filter(
            (child: Component) => names.some(v => v.includes(child.currentComponentVersion.name))
        );
        let profileVersion = _.cloneDeep(this.props.profileVersion);
        profileVersion.components = components;
        this.props.dispatchers.setInReduxState({ profileVersion });
        this.setTrainerCertificatesTable(components);
        this.update();
    };

    getTrainerCertificatesTab() {
        return {
            menuItem: (
                <Menu.Item key='trainerCertificates'>
                    {_msg("EducationComponentItemEditor.trainerCertificates.label")}
                    <Label>{this.props.profileVersion?.components?.length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane className="flex flex-container flex-grow">
                <div className={`flex flex-grow flex-container Catalog ${this.props.isOldVersionSelected ? 'Catalog_disabled' : ''}`}>
                    <EntityTableLight
                        {...this.props.trainerCertificatesTable}
                        dispatchers={this.props.dispatchers.trainerCertificatesTable}
                        ref={this.refEntityTableLightCertificates}
                        // entitiesAsParams={this.props.profileVersion.competenceProfileCriterionVersions}
                        entityDescriptor={trainerCertificatesDescriptor}
                        actions={{
                            showAddButton: !this.props.isOldVersionSelected && this.isProfileVersion(),
                            showEditButton: false,
                            doNotAddEntityToTable: true,
                            saveFormDisabled: _.isUndefined(this.props.selectedCertificate)
                        }}
                        onSave={this.onAddTrainerCertificate}
                        onDelete={this.onDeleteTrainerCertificate}
                        formCustomizer={{ headerIcon: "plus", headerContent: _msg("Catalog.add.label"), customEntityDescriptorForEditor: this.getEditorForTrainerCertificates() }}
                        getConditionForShowingDelete={() => !this.props.isOldVersionSelected} />
                </div>
            </Tab.Pane>,
        };
    }

    getTrainerCompetencesTab() {
        return {
            menuItem: (
                <Menu.Item key='trainerCompetences'>
                    {_msg("EducationComponentItemEditor.trainerCompetences.label")}
                    <Label>{this.props.profileVersion?.competenceProfileCriterionVersions?.length}</Label>
                </Menu.Item>
            ),
            render: () => {
                const competence = this.refEntityTableLightCompetences.current?.getEntityFormLightRef().current?.props.refEditor?.current?.formik?.current?.values.competence;
                return <Tab.Pane className="flex flex-container flex-grow">
                    <div className={`flex flex-grow flex-container Catalog ${this.props.isOldVersionSelected ? 'Catalog_disabled' : ''}`}>
                        <EntityTableLight
                            {...this.props.trainerCompetenceTable}
                            dispatchers={this.props.dispatchers.trainerCompetenceTable}
                            entityDescriptor={trainerCompetencesDescriptor}
                            ref={this.refEntityTableLightCompetences}
                            actions={{
                                showAddButton: !this.props.isOldVersionSelected && this.isProfileVersion(),
                                showEditButton: !this.props.isOldVersionSelected,
                                doNotAddEntityToTable: true,
                                saveFormDisabled: _.isUndefined(competence)
                            }}
                            onSave={(entities: any, modifiedEntity) => this.onAddCompetenceToProfile(entities, modifiedEntity)}
                            onDelete={(remainingEntities, removedEntityIndex) => this.onDeleteCompetenceFromProfile(remainingEntities, removedEntityIndex)}
                            formCustomizer={{ headerIcon: "plus", headerContent: _msg("Catalog.add.label"), customEntityDescriptorForEditor: this.getEditorForTrainerCompetences() }}
                            getConditionForShowingDelete={() => !this.props.isOldVersionSelected} />
                    </div>
                </Tab.Pane>;
            },
        };
    }

    isDirty(componentVersion: ComponentVersion) {
        return !_.isEqual(componentVersion, this.props.defaultComponent.currentComponentVersion) ||
            (!_.isEqual(this.props.profileVersion, this.props.defaultProfileVersion) && !this.props.isOldVersionSelected) ||
            ((this.props.addedCompetences.length > 0 || this.props.removedCompetences.length > 0) && !this.props.isOldVersionSelected) ||
            this.props.createNewMajorVersion;
    }

    update(componentVersion?: ComponentVersion): void {
        let compVersion = componentVersion ? componentVersion : this.props.component.currentComponentVersion;
        const dirty = this.isDirty(compVersion);
        this.props.dispatchers.update({ componentVersion: compVersion, dirty });
        super.update(compVersion);
    }

    handleOnClickProfile = () => {
        if (!this.props.showProfileTree && !this.props.addProfileVersionMode) {
            this.props.dispatchers.getProfileData();
            this.props.dispatchers.setInReduxState({ showProfileTree: true });
        } else if (this.props.searchExpressionProfileTree === "") {
            this.props.dispatchers.setInReduxState({ showProfileTree: false });
        }
    };

    handleOnSelectItemProfileTree = (params: OnSelectParams) => {
        const profileData = this.props.filteredProfileData ? this.props.filteredProfileData : this.props.profileData;
        const object = Utils.navigate(profileData, params.itemId);
        if (object.objectType === PROFILE_DATA) {
            this.setProfileVersion(object.profile.currentProfileVersion);
        } else {
            this.setProfileVersion(object);
        }
        this.props.dispatchers.setInReduxState({ showProfileTree: false, searchExpressionProfileTree: "", filteredProfileData: undefined });
        this.update();
    };

    filterProfilesByKeyword = (searchExpression: string) => {
        return this.props.profileData.filter((profile: any) =>
            profile.profile.currentProfileVersion.name.toLowerCase().includes(searchExpression.toLowerCase())
        ).map((profile: any) => {
            const filteredVersions = profile.profileVersions?.filter((version: any) =>
                version.name.toLowerCase().includes(searchExpression.toLowerCase())
            );
            return {
                ...profile,
                profileVersions: filteredVersions
            };
        });
    };

    createNewProfileVersion = () => {
        return {
            components: [],
            competenceProfileCriterionVersions: [],
            name: "",
            objectType: PROFILE_VERSION,
            profile: {
                objectType: PROFILE
            }
        };
    };

    onChangeInputProfileVersion = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!this.props.addProfileVersionMode) {
            this.props.dispatchers.setInReduxState({ searchExpressionProfileTree: event.target.value });
            const filteredProfileData = this.filterProfilesByKeyword(event.target.value);
            this.props.dispatchers.setInReduxState({ filteredProfileData });
        } else {
            this.props.dispatchers.setInReduxState({ profileVersion: { ...this.props.profileVersion, name: event.target.value } });
        }
    };

    renderAdditionalContent = () => {
        return <>
            <div className="w100">
                {_msg("EducationComponentItemEditor.trainerProfile.label")}
                <div className="EducationComponentItemEditor_profileVersionContainer">
                    <Input className="EducationComponentItemEditor_profileVersionInput" icon
                        disabled={this.props.isOldVersionSelected}
                        onClick={this.handleOnClickProfile}
                        onChange={this.onChangeInputProfileVersion}
                        value={this.props.profileVersion ? this.props.profileVersion.name : this.props.searchExpressionProfileTree}
                    />
                    {this.props.profileVersion && !this.props.addProfileVersionMode && !this.props.isOldVersionSelected &&
                        <Button icon='x'
                            onClick={() => {
                                this.setProfileVersion(null);
                                this.update();
                            }}
                        />}
                    {!this.props.isOldVersionSelected && <Button icon={this.props.addProfileVersionMode ? 'arrow left' : 'plus'} color="green" onClick={() => {
                        if (this.props.addProfileVersionMode) {
                            this.setProfileVersion(null);
                        } else {
                            this.setProfileVersion(this.createNewProfileVersion());
                        }
                        this.props.dispatchers.setInReduxState({ addProfileVersionMode: !this.props.addProfileVersionMode });
                        this.update();
                    }} />}
                </div>
                {this.props.showProfileTree && this.props.profileData && !this.props.isOldVersionSelected &&
                    <ProfileVersionTreeRRC
                        id="ProfileVersionTree"
                        root={this.props.filteredProfileData ? this.props.filteredProfileData : this.props.profileData}
                        onSelectItem={this.handleOnSelectItemProfileTree}
                    />}
            </div>
        </>;
    };

    getPanes = () => [
        this.getGeneralTab(),
        this.getCompetencesTab(),
        this.getTrainerCompetencesTab(),
        this.getTrainerCertificatesTab(),
        this.getAttachmentsTab()
    ];

    onTabChange(event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
        super.onTabChange(event, data);
        this.forceUpdate();
    }
}