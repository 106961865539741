'use strict';

/*@ngInject*/
function resourcePickerPopup($compile) {
	return {
		scope : {
			isOpen : '=',
			owner : '=',
			date : '='
		},
		require : 'ngModel',
		link : function($scope, $element, $attrs, ngModelCtrl) {
			ngModelCtrl.$formatters.push(function(modelValue) {
				return modelValue;
			});

			ngModelCtrl.$render = function() {
				var value = '';
				if (ngModelCtrl.$viewValue) {
					if (angular.isObject(ngModelCtrl.$viewValue)) {
						value = ngModelCtrl.$viewValue.completeName;
					} else {
						value = ngModelCtrl.$viewValue;
					}
				}
				$element.val(value);
			}

			ngModelCtrl.$viewChangeListeners.push(function() {
				if (ngModelCtrl.$viewValue) {
					if (angular.isObject(ngModelCtrl.$viewValue)) {
						$scope.isOpen = false;
					}
				}
			});

			$scope.ngModelCtrl = ngModelCtrl;
			var tpl = '<resource-picker ng-if="isOpen" owner="owner" date="date" ng-model-ctrl="ngModelCtrl" class="dropdown-menu resource-picker"></resource-picker>';
			var picker = $compile(angular.element(tpl))($scope);

			$element.after(picker);
		}
	};
}

module.exports = resourcePickerPopup;