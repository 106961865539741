import { createSliceFoundation, EntityDescriptor, EntityEditorFormSimple, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FieldRendererProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { ScriptableContainer, Scriptable } from "@famiprog-foundation/scriptable-ui";
import { getEmployee_employeeService_employee, getEmployee_employeeService_employee_telecomList } from "apollo-gen/getEmployee";
import React from "react";
import { Form, Icon } from "semantic-ui-react";
import { HISTORY_FIELD_TYPE, MoveTelecomTableRenderer, telecomEditorEntityDescriptor, telecomTableEntityDescriptor } from "./customFieldRenderersEditors";
import { EMPLOYEE_EDITOR } from "./EmployeeEditorBase";

export const ADDRESS_HISTORY_ITEM = "AddressHistoryItem";
export const MOVE_UP_DOWN = "MOVE_UP_DOWN";

export const ADDRESS_SCRIPTABLE_UI_ID = "EmployeeEditor_AddressTab";

export const sliceAddressTab = createSliceFoundation(class SliceAddressTab {

    nestedSlices = {
        telecomTable: sliceEntityTableLight
    }

    reducers = {
        ...getBaseReducers<SliceAddressTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceAddressTab>(this)
    }
})

type PropsNotFromState = {
    employee: getEmployee_employeeService_employee | undefined | null;
    isEditable: boolean;
    onOpenTableLight: (fieldDescriptor: FieldDescriptor, entities: any[]) => void;
    renderAdditionalFieldsInForm: (additionalFields: JSX.Element) => JSX.Element;
    referenceDate: Date | number;
}

export class AddressTab extends TabbedPage<PropsFrom<typeof sliceAddressTab> & PropsNotFromState> {
    protected refEntityTableLight = React.createRef<EntityTableLight>();

    getEntityTableLightRef() {
        return this.refEntityTableLight;
    }

    protected getAddressEntityDescriptor() {
        return new EntityDescriptor({ name: EMPLOYEE_EDITOR }, false)
            .addFieldDescriptor({
                name: "employee.addressHistory",
                type: HISTORY_FIELD_TYPE,
                entityName: ADDRESS_HISTORY_ITEM,
                mandatoryFields: ["address.type", "address.city"],
                icon: "home",
                onOpenTableLight: this.props.onOpenTableLight,
                isDisabled: !this.props.isEditable,
                referenceDate: this.props.referenceDate
            });
    }

    renderTelecomDescriptor() {
        fieldRenderers[MOVE_UP_DOWN] = (props: FieldRendererProps) => {
            let newProps = { ...props, moveRowUpDown: this.moveRowUpDown };
            return <ScriptableContainer id={"TelecomUpDown" + props.entity?.id}>
                <Scriptable id={"arrowUpDown" + props.entity?.id} moveRowUpDown={this.moveRowUpDown}>{scriptable =>
                    <MoveTelecomTableRenderer {...newProps} moveRowUpDown={scriptable.proxy.moveRowUpDown} />}
                </Scriptable>
            </ScriptableContainer>;
        }
    }

    protected moveRowUpDown = (telecom: getEmployee_employeeService_employee_telecomList, moveUp: boolean) => {
        const entities = this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.getEntities();
        const telecoms = entities ? entities : [];
        let currentIndex = telecoms.indexOf(telecoms.filter((item: getEmployee_employeeService_employee_telecomList) => {
            if (telecom.id) {
                return item.id === telecom.id;
            } else {
                return item.type?.id === telecom.type?.id && item.value === telecom.value;
            }
        })[0])
        if (currentIndex > 0 && moveUp) {
            this.moveEntitiesInArray(currentIndex, telecom, -1);
            this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(currentIndex - 1);
        } else if (currentIndex < telecoms.length - 1 && !moveUp) {
            this.moveEntitiesInArray(currentIndex, telecom, 1);
            this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(currentIndex + 1);
        }
    }

    protected moveEntitiesInArray(currentIndex: number, telecom: getEmployee_employeeService_employee_telecomList, position: number) {
        const entities = this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.getEntities();
        let telecoms = entities ? [...entities] : [];
        telecoms.splice(currentIndex, 1);
        telecoms.splice(currentIndex + position, 0, telecom);
        this.refEntityTableLight.current?.open(telecoms);
    }

    componentDidMount() {
        this.refEntityTableLight.current?.open(this.props.employee?.telecomList || []);
    }

    render() {
        this.renderTelecomDescriptor();
        return (
            <div className={"AddressTab " + (this.props.isEditable ? "" : " AddressTab_disabled")}>
                <EntityEditorFormSimple scriptableUiId={ADDRESS_SCRIPTABLE_UI_ID} entityDescriptor={this.getAddressEntityDescriptor()}
                    hideButtonBar entity={{ employee: this.props.employee }} />
                {this.props.renderAdditionalFieldsInForm(
                    <>
                        <Form>
                            <Form.Field disabled={!this.props.isEditable}>
                                <label><Icon name="comment alternate" />{_msg("Telecom.label")}</label>
                            </Form.Field>
                        </Form>
                        <EntityTableLight {...this.props.telecomTable} dispatchers={this.props.dispatchers.telecomTable} ref={this.refEntityTableLight} entityDescriptor={telecomTableEntityDescriptor}
                            actions={{ showAddButton: this.props.isEditable, showEditButton: this.props.isEditable }}
                            formCustomizer={{ headerIcon: "comment alternate", headerContent: _msg("Telecom.label"), mandatoryFields: ["type", "value"], customEntityDescriptorForEditor: telecomEditorEntityDescriptor }}
                            useDefaultColumnsWidths={true}
                        />
                    </>
                )}
            </div>
        );
    }
}