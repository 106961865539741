'use strict';

/*@ngInject*/
function proteusGridSelection() {
	
	var GridSelection = function() {
		var selection = this;

		selection.selectRow = function(row, index, event) {
			selection.row = row;
			selection.index = index;
		}

		selection.on = {
			rowSelectionChanged: function($scope, callback) {
				var selectRow = selection.selectRow;
				selection.selectRow = function(row, index, event) {
					selectRow(row, index, event);
					callback(row, index, event);
				}
			}
		}
	}

	return {
		restrict: 'A',
		require: 'proteusGrid',
		link: function($scope, $elt, $attrs, grid) {
			grid.api.selection = new GridSelection();
		}
	}
};

module.exports = proteusGridSelection;