'use strict'

/*@ngInject*/
function WarningModal(FormModal) {

	return {

		'VALIDATION_WARNING': 'validationWarning',
		'IGNORE_VALIDATIONS': 'ignoreValidations',

		open: function(data) {
			FormModal.open({
				scope: {
					template: require('./warning-modal.html'),
					panelCls: 'panel-primary',
					icon: 'fa fa-question-circle',
					title: data.title || data.titleId,
					message: data.message,
					messageId: '<br>' + data.messageId,
					onYes: data.onYes,
					onClose: data.onClose 
				},
				actions: ['close', 'no', 'yes']
			});
		}
	}
};

module.exports = WarningModal;