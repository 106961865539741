import { Optional } from "@crispico/foundation-react";
import moment from "moment";
import { Icon, Popup } from "semantic-ui-react";
import { MetadataProviderHelper } from "utils/MetadataProviderHelper";
import { CustomItem } from "../mapper/GanttAdapter";
import { MetadataPropertyEnum } from "../mapper/PlanningGanttAdapter";
import { EmployeeSnapshot, RegistrationSnapshot } from "../PlanningGantt";
import { DateUtil } from 'utils/DateUtil';
import { Color } from "utils/ColorUtil";
import { ProteusConstants } from "ProteusConstants";
import { BaseItemRenderer } from "./BaseItemRenderer";

export const HIDE_DETAIL_COLOR = 0x808080;
const POPUP_DELAY_VALUE: number = 500;
const POPUP_TITLE_CLS: string = 'RegistrationItemRenderer_popupTitle';
const POPUP_DATE_COLOR: string = '#FF0000';
const POPUP_DATE_FORMAT: string = 'DD-MM-YYYY HH:mm';
const POPUP_BACKGROUND_COLOR: string = '#FFFFCE';
const WAITING_ICON_KEY: string = 'waiting-icon-key';
const WAITING_ICON_CLASS: string = 'RegistrationItemRenderer_waitingIcon';

export class RegistrationItemRenderer extends BaseItemRenderer {

    calculateColor() {
        if (this.isDetailHidden()) {
            return HIDE_DETAIL_COLOR;
        }
        const item: CustomItem = this.props.item as CustomItem;
        const registrationSnapshot: RegistrationSnapshot = item.node.source as RegistrationSnapshot;
        const backgroundColor: Optional<object> = MetadataProviderHelper.getPropertyValue(item.metadata, registrationSnapshot.type.code,
            registrationSnapshot.status.code, MetadataPropertyEnum.BACKGROUND_COLOR, ProteusConstants.PLANNING);
        return this.convertOptionalObjectToNumber(backgroundColor);
    }

    getGlowOnHoverClassName(): string {
        const glowOnHoverClassName = super.getGlowOnHoverClassName();
        const item: CustomItem = this.props.item as CustomItem;
        const registrationSnapshot: RegistrationSnapshot = item.node.source as RegistrationSnapshot;
        const glowOnHover: Optional<object> = MetadataProviderHelper.getPropertyValue(item.metadata, registrationSnapshot.type.code,
            registrationSnapshot.status.code, MetadataPropertyEnum.GLOW_ON_HOVER, ProteusConstants.PLANNING);
        return glowOnHover ? glowOnHoverClassName : '';
    }

    getLabel(registrationSnapshot: RegistrationSnapshot) {
        // If user can't see registration's details, it will show the hidden detail code (Absent/Afwezig)
        return DateUtil.getDifferenceWithUnitMeasure(new Date(registrationSnapshot.fromDate), new Date(registrationSnapshot.toDate), true) + ' ' + (this.isDetailHidden() ? _msg('Registration.hideDetail.code') : registrationSnapshot.type.code);
    }

    getTitle() {
        const item: CustomItem = this.props.item as CustomItem;
        const registrationSnapshot: RegistrationSnapshot = item.node.source as RegistrationSnapshot;
        if (moment(registrationSnapshot.fromDate).isSame(moment(registrationSnapshot.toDate))) {
            // If the registration has fromDate = toDate, it means that it is a milestone registration.
            // This registration will not have a label.
            return '';
        }
        const label = this.getLabel(registrationSnapshot);
        if (item.node.isWaitingRegistration) {
            return <span><span key={WAITING_ICON_KEY} style={{ padding: '1px' }}><Icon name={'exclamation triangle'} className={WAITING_ICON_CLASS}></Icon></span>{label}</span>;
        }
        return label;
    }

    renderPopupContent() {
        const item: CustomItem = this.props.item as CustomItem;
        const parentSource: EmployeeSnapshot = item.parent.source as EmployeeSnapshot;
        const source: RegistrationSnapshot = item.node.source as RegistrationSnapshot;
        return (<div>
            <div className={POPUP_TITLE_CLS}><b>{parentSource.completeName} ({parentSource.employeeNumber})</b></div>
            {!this.isDetailHidden() && <div>Type: <b>{source.type.name}</b></div>}
            <div>Status: <b>{source.status.description}</b></div>
            <div>Van: <b style={{ color: POPUP_DATE_COLOR }}>{moment(source.fromDate).format(POPUP_DATE_FORMAT)} </b></div>
            <div>Tot:  <b style={{ color: POPUP_DATE_COLOR }}>{moment(source.toDate).format(POPUP_DATE_FORMAT)}</b></div>
            <div>Duur: <b>{DateUtil.getDifferenceWithUnitMeasure(new Date(source.fromDate), new Date(source.toDate))}</b></div>
        </div>);
    }

    getStyle(): object {
        const item: CustomItem = this.props.item as CustomItem;
        const registrationSnapshot: RegistrationSnapshot = item.node.source as RegistrationSnapshot;
        let style: object = this.getBaseStyle(item, registrationSnapshot.type.code, registrationSnapshot.status.code, registrationSnapshot.fromDate, registrationSnapshot.toDate);
        const borderColor: Optional<object> = MetadataProviderHelper.getPropertyValue(item.metadata, registrationSnapshot.type.code,
            registrationSnapshot.status.code, MetadataPropertyEnum.BORDER_COLOR, ProteusConstants.PLANNING);
        style = {
            ...style,
            borderColor: Color(this.convertOptionalObjectToNumber(borderColor)).hex(),
        }
        return style;
    }

    getBackgroundGradient(): string {
        const item: CustomItem = this.props.item as CustomItem;
        const registrationSnapshot: RegistrationSnapshot = item.node.source as RegistrationSnapshot;
        return this.getBaseBackgroundGradient(item, registrationSnapshot.type.code, registrationSnapshot.status.code);
    }

    getClassName(): string {
        const registrationSnapshot: RegistrationSnapshot = (this.props.item as CustomItem).node.source as RegistrationSnapshot;
        const className = super.getClassName();
        if (moment(registrationSnapshot.fromDate).isSame(moment(registrationSnapshot.toDate))) {
            // If the registration has fromDate = toDate, it means that it is a milestone registration.
            // This registration will appear in gantt in the form of a diamond.
            return className + ' RegistrationItemRendeder_milestone';
        }
        return className;
    }

    isDetailHidden() {
        const item: CustomItem = this.props.item as CustomItem;
        const registrationSnapshot: RegistrationSnapshot = item.node.source as RegistrationSnapshot;
        const hideDetails = MetadataProviderHelper.getPropertyValue(item.metadata, registrationSnapshot.type.code, registrationSnapshot.status.code, MetadataPropertyEnum.HIDE_REGISTRATION_DETAIL, ProteusConstants.PLANNING);
        if (hideDetails && item.allowViewRegistrationDetails) {
            return false;
        }
        return hideDetails;
    }

    render() {
        let segment = super.render();
        return (<Popup basic mouseEnterDelay={POPUP_DELAY_VALUE} trigger={segment} style={{ backgroundColor: POPUP_BACKGROUND_COLOR }}>
            <Popup.Content>{this.renderPopupContent()}</Popup.Content>
        </Popup>);
    }
}