'use strict';

var  jquery = window.jQuery = window.$ = require('jquery'),
	angular = require('angular'),
 screenfull = require('screenfull'),
  FastClick = require('fastclick'),
		 Rx = require('rx-lite-angular');


if (window.isProteusReact) {
	// warning: copied from proteus-js2/main.js
	// in proteus-js2 / gulp we have to use relative path; otherwise it doesn't seem to pass
	// through the CSS processor (via browserify?). In webpack, we CAN use path that resolves
	// to node_modules. But webpack forces us to have a *quasi constant* arg for the "require()" function
	// (or variations, such as dynamic import). UPDATE: gulp also requires a constant argument.
	// That's why we CANNOT prefix the path w/ a var, e.g. "./node_modules"
	// for gulp and "./../../node_modules" or "" for react. And we CANNOT use "if" either. Because in
	// react mode, a static analysis of the code seems to be made. And even if a "require" is under an
	// "if" that will evaluate to false => webpack would try to import that module.
	require("bootswatch/paper/bootstrap.css");
	require('font-awesome/css/font-awesome.css');
	require('jquery-ui/themes/base/base.css');
}

require('../../assets/styles/jquery-ui.theme/jquery-ui.theme.css');
require('../../assets/styles/backbone/backbone.css');

require('../../assets/styles/style.css');
require('../../assets/styles/modal.css');
require('../../assets/styles/flex.css');
require('../../assets/styles/strike.css');

/**
 * Modules requiring 'ng' explicitly break the lazy-loading mechanism, because it forces angular 
 * to reinitialize its internal soup, overriding our decorators etc. Here we are removing 'ng' 
 * from a module's requires, to prevent $ocLazyLoad to force it to reinit. This has to be done
 * *before* loading $ocLazyLoad, since it also overwrites this method.
 */
var ngModuleFn = angular.module;
angular.module = function(name, requires, configFn) {
	var module = ngModuleFn.apply(angular, arguments);
	if (angular.isUndefined(requires)) {
		// retrieving the module
		// overwrite requires
		for (var i = module.requires.length - 1; i >= 0; i--) {
			if (module.requires[i] == 'ng') {
				module.requires.splice(i, 1);
			}
		}
	}
	return module;
};

let conditionalModules = [];
if (window.isProteusReact) {
	conditionalModules = [
		"proteus.account", "proteus.messaging", "proteus.tasks", "proteus.planning", "proteus.credit", "proteus.education", "proteus.react"
	];
}

/**
 * @ngdoc overview
 * @name proteus
 * @description
 * # proteus
 * 
 * Main module of the application.
 */
angular.module('proteus', [ ...conditionalModules,
	require('angular-route'),
	require('angular-translate'),
	require('angular-animate'),
	require('angular-sanitize'),
	require('angular-i18n/nl-be'),
	require('./app.constants'),
	'rx',
	require('../components/components.module'),
	require('oclazyload'),
])

.factory('authExpiredInterceptor', function($rootScope, $q, $injector, $location) {
	return {
		responseError: function(response) {
			// If we have an unauthorized request we redirect to the login page
			// Don't do this check on the account API to avoid infinite loop
			if (response.status == 401 && response.config.url !== undefined && response.config.url.indexOf("/user/login") == -1) {
				$rootScope.securityContext = null;
				$location.url('/account/login?expired');
			}
			return $q.reject(response);
		}
	};
})

.decorator('$exceptionHandler', function($delegate, $injector) {
	return function(exception, cause) {
		// using injector to avoid circular dependency: $rootScope <- $uibModal <- ErrorModal <- $exceptionHandler <- $rootScope
		var ErrorModal = $injector.get('ErrorModal');
		$delegate(exception, cause);
		ErrorModal.open({
			title: exception.message,
			message: exception.stack
		});
	};
})
.config(function($provide, $routeProvider, $qProvider, $httpProvider, $translateProvider, $compileProvider, $animateProvider, $locationProvider) {
	
	/**
	// Decorate $route with the '$routeProvider.when()' functionality. Any routes that need authentication should be registered with $route.whenWithAuth()
	// This will resolve the $securityContext before loading the route. */
	$provide.decorator('$route', function($delegate, $rootScope, $q, $location, Principal) {
		$delegate.whenWithAuth = function(path, route) {
			route.resolve = route.resolve || {};
			route.resolve.$securityContext = function() {
				var dfr = $q.defer();
				Principal.checkPermissions($rootScope.routePermissions['#' + $location.url()], 
						function(allowed) {
							if (allowed) {
								dfr.resolve($rootScope.securityContext);
							} else {
								dfr.reject();
								$location.url('/account/login');
							}
						});
				
				return dfr.promise;
			};
			$routeProvider.when(path, route);
			return $delegate; // builder pattern
		};
		
		$delegate.goToProteusHomePage = function() {
			if ($rootScope.securityContext == null) {
				// if the user is not logged in go to login page
				  $location.url('/account/login');
			  } else {
				  // if user is logged in we have a route for / to home.html (see app.js)
				  $location.path("");
			  }
		};
		
		return $delegate;
	});

	/**
	 * This is trouble either way
	 * 1) errorOnUnhandledRejections: we not not have catch clauses (error handlers) on modals, as a result when closing a modal, an error occurs (an empty error dialog is displayed)
	 * 2) !errorOnUnhandledRejections: sometimes errors can occur in our success callbacks, but they are swallowed (we have no indication that an error occurred)  
	 */ 
 	$qProvider.errorOnUnhandledRejections(false);
	
	$httpProvider.defaults.cache = false;
	if (!$httpProvider.defaults.headers.get) {
		$httpProvider.defaults.headers.get = {};
	}
	
	// disable IE ajax request caching
 	$httpProvider.defaults.headers.get['If-Modified-Since'] = '0';
	
	$httpProvider.interceptors.push('authExpiredInterceptor');
	
	$translateProvider.useSanitizeValueStrategy('escape');
	$translateProvider.translations('nl-be', angular.fromJson(require('./../../assets/locale/nl-be.json')));
	
	$translateProvider.preferredLanguage('nl-be');
	
	$compileProvider.debugInfoEnabled(true);
	
	// ng-animate will apply to the entire app unless we restrict it
	// this can cause some undesired side-effects 
	// (e.g. because we have backbone + angular views living in the same page, the animations will cause
	// vertical scrolling, which will cause confusion for angular-bootstrap -- the modal logic will add
	// an unwanted padding-right to compensate for the scrollbar)
	$animateProvider.classNameFilter(/ng-will-animate/);

	$locationProvider.hashPrefix('');
	
	// used by base-proteus-js
	window.App = window.App || {};

})
.run(function($rootScope, $route, $location, $document, $log, Principal, ModalSpinner) {
	// disabled because it has issues on react (didn't try really hard to fix), but mostly because the deprecation notice
	// saying that starting w/ 2015 this is not really needed, and actually not recommended: https://github.com/ftlabs/fastclick
	// FastClick(document.body);
	
	$route.whenWithAuth('/', {
		template: require('./home.html'),
		resolve: {
			'version': function() {
				return window.sessionStorage.projectVersion;
			}
		},
		resolveAs: 'home',
		// @ngInject annotation fails here
		controller: ['ModalSpinner', function(ModalSpinner) {
			ModalSpinner.hide();
		}]
	}).whenWithAuth(null, {}); // equivalent to $routeProvider.otherwise()
	
	$rootScope.routePermissions = $rootScope.routePermissions || {};
	$rootScope.$on('$locationChangeStart', function(event, current, previous) {
		
		$rootScope.navActions = [];
		
		if (window.App.requestLogout) {
			// e.g. after the user changes his password, perform a logout
			Principal.logout();
			delete window.App.requestLogout;
			$location.url('/account/login');
			return;
		}

		var index = current.indexOf('#');
		var currentPath = current.substr(index + 1);

		if (currentPath === '/carousel') {
			event.preventDefault();
			return;
		}

		if (currentPath.indexOf('/account') < 0) {
			if (currentPath != '/') {
				// show spinner for all the pages except home
				ModalSpinner.show();
			}
		}
		if (window.location.search.includes("TestsAreDemo")) {
			angular.element('.rootView')[0].style.padding = 0;
			if($route.routes[currentPath]) {
				ModalSpinner.hide();
				event.preventDefault();
				return;
			}
		}
		
	});
	
	// hide the backbone container for angular-managed routes
	$rootScope.$on('$locationChangeSuccess', function() {
		$rootScope.navActions = [];
		if ($route.current.$$route) {
			$('.container-backbone').hide();
			if ($route.current.$$route.controller != 'GwtContainerCtrl') {
				$('.root1').hide();
			}
		} else {
			$('.container-backbone').show();
			$('.root1').hide();
			// OnLocationStart angular puts a modal spinner
			// For the react app, if the current route is not loaded by angular this modal spinner is never removed
			// That's why we need to remove it here
			if (window.isProteusReact) {
				ModalSpinner.hide();
			}
		}
	});
	
	$rootScope.back = function() {
		if ($rootScope.previousRoute) {
			$location.url($rootScope.previousRoute);
		}
	};

	// disable backspace as back button functionality

	$document.on('keydown', function(e) {
		if (e.which === 8 && (e.target.nodeName !== "INPUT" && e.target.nodeName !== "TEXTAREA" && e.target.nodeName !== "SELECT")) {
			e.preventDefault();
		}
	});
	
	angular.Logger = function(p) {
		this.debug = function(message) {
			$log.debug('Proteus: ' + (p ? p : '') + ' > ' + message);
		};
	};
	
	// create the getter, it will be called from the gwt client
	window.getSecurityContext = function() {
		return $rootScope.securityContext;
	}
	
	new angular.Logger().debug('application started');
});

require('./app-lazy-config');