'use strict';

var css = require('./form-modal.css');

/**
 * Options object: {
 *  scope: {
 *  	model: Object,
 *  	title: String,
 *  	template: String,
 *  	modalBodyClass: String,
 *      subTitle: String
 *  },
 *  actions: Array,
 *  windowClass: String
 *  
 * }
 */
/*@ngInject*/
function FormModal($uibModal, Flags, $timeout) {
	
	return {
		open: function(options) {
			var instance = $uibModal.open({
				template: require('./form-modal.html'),
				windowClass: options.windowClass,
				controller: /*@ngInject*/ function($scope) {
					angular.extend($scope, options.scope);
					
					$scope.flags = new Flags('ok', 'cancel', 'yes', 'no', 'close');
					angular.forEach(options.actions, function(flag) {
						$scope.flags.add(flag);
					});
					
					$scope._isModelInvalid = function(model) {
						return $scope.isModelInvalid ? $scope.isModelInvalid(model) : false; 
					};
					
					$scope.ok = function(model) {
						if (!$scope._isModelInvalid(model)) {
							$scope.$close(model);
						}
					};

					$scope.yes = function(model) {
						if (!$scope._isModelInvalid(model)) {
							if ($scope.onYes) {
								$scope.onYes(model);
							}
							$scope.$close(model);
						}
					};

					$scope.close = function(model) {
						if ($scope.onClose) {
							$scope.onClose(model);
						}
						$scope.$dismiss('cancel');
					};

					if ($scope.dismissOnTimeout) {
						$timeout(function() {
							$scope.ok($scope.model);
						}, $scope.dismissOnTimeout);
					}

					instance.$scope = $scope;
				}
			});
			return instance;
		}
	};
}

module.exports = FormModal;