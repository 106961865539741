import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { DATA_TYPE_SERVICE_FIND_BY_CODE, PROPERTY_CATEGORY_SERVICE_FIND_BY_CODE } from "graphql/queries";
import { AssociationCodeEditor } from "pages/AssociationFieldEditors";
import React from "react";

const CATEGORY: string = "category";
const DATA_TYPE: string = "dataType";

export class PropertyEntityDescriptor extends EntityDescriptor {

    protected customize() {

        const propertyEntityDescriptor = this;

        const slicePropertyEditor = propertyEntityDescriptor.infoEditor.slice = createSliceFoundation(class SlicePropertyEditor extends SliceEntityEditorPage {

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                initialPropertyCategory: undefined as unknown as { id: number, code: string, description: string },
                initialDataType: undefined as unknown as { id: number, code: string, description: string }
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SlicePropertyEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SlicePropertyEditor>(this),

                async getInitialCategory(code: string) {
                    const initialPropertyCategory = (await apolloClient.query({
                        query: PROPERTY_CATEGORY_SERVICE_FIND_BY_CODE,
                        variables: {
                            code
                        }
                    })).data.propertyCategoryService_findByCode;
                    this.getDispatchers().setInReduxState({ initialPropertyCategory });
                },

                async getInitialDataType(code: string) {
                    const initialDataType = (await apolloClient.query({
                        query: DATA_TYPE_SERVICE_FIND_BY_CODE,
                        variables: {
                            code
                        }
                    })).data.dataTypeService_findByCode;
                    this.getDispatchers().setInReduxState({ initialDataType });
                },

                superLoad: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.superLoad(id);
                    if (entity !== undefined) {
                        if (entity.category !== undefined) {
                            await this.getInitialCategory(entity?.category);
                        }

                        if (entity.dataType !== undefined) {
                            await this.getInitialDataType(entity?.dataType);
                        }
                    }
                    return entity;
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    entity = this.convertFieldToCode(entity, CATEGORY);
                    entity = this.convertFieldToCode(entity, DATA_TYPE);
                    await this.saveSuper(entity);
                },

                convertFieldToCode(entity: any, field: string) {
                    if (entity[field] !== null && entity[field] !== undefined && entity[field].code !== undefined) {
                        entity = { ...entity, [field]: entity[field].code };
                    }
                    return entity;
                }
            }
        }).setEntityDescriptor(propertyEntityDescriptor);

        propertyEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof slicePropertyEditor> & EntityEditorPageProps> {

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "Property" }, false);

                // Add custom fields descriptors for category and data type. By default, we can see in the form only the code,
                // but we want to see a dropdown for each of these fields with the value from the database for the
                // entities: PropertyCategory and DataType.
                propertyEntityDescriptor.doForFields(null, (fieldDescriptor) => result.entityDescriptor.addFieldDescriptor(fieldDescriptor));
                result.entityDescriptor.removeFieldDescriptors(CATEGORY, DATA_TYPE);

                // category descriptor
                result.entityDescriptor.addFieldDescriptor({ name: CATEGORY, type: "PropertyCategory" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        return React.createElement(AssociationCodeEditor as any, props as FieldEditorProps);
                    }
                });

                // data type descriptor
                result.entityDescriptor.addFieldDescriptor({ name: DATA_TYPE, type: "DataType" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        return React.createElement(AssociationCodeEditor as any, props as FieldEditorProps);
                    }
                });

                result.entity = this.addInitialCodeValue(result.entity, CATEGORY, this.props.initialPropertyCategory);
                result.entity = this.addInitialCodeValue(result.entity, DATA_TYPE, this.props.initialDataType);
                return result;
            }

            protected addInitialCodeValue(entity: any, field: string, initialValue: { code: string }) {
                if (entity[field] !== undefined && entity[field] !== null && entity[field] === initialValue?.code) {
                    entity = { ...entity, [field]: initialValue };
                }
                return entity;
            }

            protected convertFromObjectToString(entity: any, fieldName: string, fieldNameInState: string) {
                if (entity[fieldName] && entity[fieldName].code !== undefined) {
                    this.props.dispatchers.setInReduxState({ [fieldNameInState]: entity[fieldName] });
                    entity = { ...entity, [fieldName]: entity[fieldName].code };
                }
                return entity;
            }

            protected getEntityValuesFromForm() {
                // because in the form the "category" and "dataType" fields are of type "object" and in the table they are string, a conversion
                // is needed when navigating from "edit properties" to "view properties" (from form to table)
                let values = super.getEntityValuesFromForm();
                values = this.convertFromObjectToString(values, CATEGORY, "initialPropertyCategory");
                values = this.convertFromObjectToString(values, DATA_TYPE, "initialDataType");
                return values;
            }
        }
    }
}