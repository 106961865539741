import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { getAdditionalDataForGroupOwner_employeeService_employeeSnapshot } from "apollo-gen/getAdditionalDataForGroupOwner";
import { getGroupContext_groupServiceFacadeBean_groupContext } from "apollo-gen/getGroupContext";
import { getGroupContexts_groupServiceFacadeBean_groupContexts } from "apollo-gen/getGroupContexts";
import { getGroupSnapshots_groupServiceFacadeBean_groupSnapshots } from "apollo-gen/getGroupSnapshots";
import { ProteusUtils } from "ProteusUtils";
import React from "react";
import { Button, Form, Grid, Input, Segment } from "semantic-ui-react";
import { groupsOfContextTableDescriptor, groupsOfContextEditorDescriptor } from "./customFieldRenderersEditors";
import { GroupOrContextEditor, sliceGroupOrContextEditor } from "./GroupOrContextEditor";
import { sliceGroupsManagementBase } from "./sliceGroupsManagementBase";

export interface GroupOption {
    key: number,
    text: string,
    value: string,
    type: string,
    contexts: getGroupContexts_groupServiceFacadeBean_groupContexts[]
}

export interface GroupTable {
    group: Group,
    hasParents: boolean,
    hasRelations: boolean
}

export interface Group {
    id: number,
    code: string,
    description: string,
    name: string,
    environment: string,
    contraints: boolean,
    relation: boolean,
    security: boolean,
    sequence: boolean,
    succession: boolean,
    owner: string,
    type: string | null | undefined,
    typeCode: string | null | undefined,
    contexts: getGroupContexts_groupServiceFacadeBean_groupContexts[]
}

export const sliceGroupContextTab = createSliceFoundation(class SliceGroupContextTab {

    initialState = {
        confirmations: undefined as unknown as { [key: string]: boolean },
        groupToBeDeletedFromCurrentContext: undefined as unknown as GroupTable
    }

    nestedSlices = {
        groupsManagementBase: sliceGroupsManagementBase,
        groupContextEditor: sliceGroupOrContextEditor,
        groupsOfContextTable: sliceEntityTableLight
    }

    reducers = {
        ...getBaseReducers<SliceGroupContextTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceGroupContextTab>(this)
    }
})

type PropsNotFromState = {
    currentContext: getGroupContext_groupServiceFacadeBean_groupContext & { groups: getGroupSnapshots_groupServiceFacadeBean_groupSnapshots[] };
    initialEmployeesOptions: { key: number, text: string, value: number }[];
    renderOnChangeForGroupOrContext: (fieldName: string, value: string | number | undefined) => void;
    removeGroupFromGroupContext: (groupToBeDeletedFromCurrentContext: any) => void;
    addExistingGroupToCurrentContext: (groupId: number) => void;
}

export class GroupContextTab extends React.Component<PropsFrom<typeof sliceGroupContextTab> & PropsNotFromState> {
    protected refEntityTableLight = React.createRef<EntityTableLight>();

    setGroupsTableForGroupContext(groups: getGroupSnapshots_groupServiceFacadeBean_groupSnapshots[], loggedInUser: getAdditionalDataForGroupOwner_employeeService_employeeSnapshot) {
        let groupsAsTableEntities: Group[] = [];
        for (let i = 0; i < groups.length; i++) {
            let group: any = {
                ...groups[i],
                owner: groups[i].owner === null ? _msg("GroupContextTab.system.label") : loggedInUser.firstName + " " + loggedInUser.name,
                type: groups[i].type?.description,
                typeCode: groups[i].type?.code
            };
            groupsAsTableEntities.push(group);
        }
        this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(groupsAsTableEntities.sort((a: any, b: any) => a.name > b.name ? 1 : -1));
    }

    filterGroupsOfSelectedContextTable(selectedContext: getGroupContext_groupServiceFacadeBean_groupContext & { groups: getGroupSnapshots_groupServiceFacadeBean_groupSnapshots[] },
        loggedInUser: getAdditionalDataForGroupOwner_employeeService_employeeSnapshot, searchedGroupInContextGroups: string) {
        if (searchedGroupInContextGroups === "") {
            this.setGroupsTableForGroupContext(selectedContext.groups, loggedInUser);
            return;
        }
        let filteredGroupsTableForContext = [];
        for (let i = 0; i < selectedContext.groups.length; i++) {
            if (selectedContext.groups[i].name?.toLowerCase().includes(searchedGroupInContextGroups.toLowerCase())) {
                filteredGroupsTableForContext.push(selectedContext.groups[i]);
            }
        }
        this.setGroupsTableForGroupContext(filteredGroupsTableForContext, loggedInUser);
    }

    render() {
        return (<>
            <GroupOrContextEditor {...this.props.groupContextEditor} dispatchers={this.props.dispatchers.groupContextEditor} selectedGroupOrContext={this.props.currentContext}
                initialEmployeesOptions={this.props.initialEmployeesOptions} onAccordionFormChange={this.props.renderOnChangeForGroupOrContext} />
            <ModalExt
                severity={Severity.INFO}
                open={this.props.confirmations?.confirmDeleteGroupFromCurrentContext !== undefined}
                header={_msg("GroupsManagement.deleteGroupConfirmation.header.label", this.props.groupToBeDeletedFromCurrentContext?.group?.name)}
                content={this.props.currentContext?.id !== undefined ?
                    _msg("GroupsManagement.deleteGroupWithContextConfirmation.content.label", this.props.groupToBeDeletedFromCurrentContext?.group?.name, this.props.currentContext?.name) :
                    (this.props.groupToBeDeletedFromCurrentContext?.hasRelations ? _msg("GroupsManagement.deleteGroupWithRelations.warning.label") :
                        this.props.groupToBeDeletedFromCurrentContext?.hasParents ? _msg("GroupsManagement.deleteGroupWithParents.warning.label") :
                            _msg("GroupsManagement.deleteGroupWithoutContextConfirmation.content.label", this.props.groupToBeDeletedFromCurrentContext?.group?.name))}
                onClose={this.onCancel}
                actions={[
                    <Button key="cancel" onClick={this.onCancel}>{_msg("general.cancel")}</Button>,
                    <Button key="ok" primary onClick={this.onConfirm}>{_msg("general.ok")}</Button>
                ]}
            />
            <div className="flex-grow flex">
                <Segment className="GroupContextTab_segment">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width="5">
                                <Form>
                                    <Form.Field>
                                        <Input icon="search" placeholder={_msg("searchGroup.label")}
                                            onChange={(e: any, data: any) => {
                                                this.filterGroupsOfSelectedContextTable(this.props.currentContext, ProteusUtils.getCurrentUser(), data.value);
                                            }}></Input>
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <EntityTableLight {...this.props.groupsOfContextTable} dispatchers={this.props.dispatchers.groupsOfContextTable} entityDescriptor={groupsOfContextTableDescriptor}
                    formCustomizer={{
                        customEntityDescriptorForEditor: groupsOfContextEditorDescriptor, headerContent: _msg("GroupOfContextEditor.addGroup.label", this.props.currentContext.name),
                        headerIcon: "group"
                    }} actions={{
                        showDeleteButton: false, showEditButton: false, showAddButton: this.props.currentContext.id !== undefined,
                        doNotRemoveEntityFromTable: true, doNotAddEntityToTable: true
                    }}
                    onDelete={async (remainingGroups: any, groupToBeDeletedIndex: number) => {
                        const group = this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[groupToBeDeletedIndex];
                        let parentGroups = [];
                        if (this.props.currentContext.id === undefined) {
                            // check if the group has parents only for Geen context (id === undefined)
                            parentGroups = await this.props.dispatchers.groupsManagementBase.getParentGroups(group.id);
                        }
                        this.props.dispatchers.setInReduxState({
                            groupToBeDeletedFromCurrentContext: {
                                group: group,
                                hasRelations: group.relation,
                                hasParents: parentGroups.length === 0 ? false : true
                            },
                            confirmations: { confirmDeleteGroupFromCurrentContext: true }
                        });
                    }}
                    onSave={(groups: GroupTable[], addedGroup: { values: { group: { id: number } } }) => {
                        this.props.addExistingGroupToCurrentContext(addedGroup.values.group.id);
                    }}
                    ref={this.refEntityTableLight}
                />
            </div>
        </>);
    }

    protected onCancel = () => {
        this.props.dispatchers.setInReduxState({ confirmations: undefined, groupToBeDeletedFromCurrentContext: undefined });
    }

    protected onConfirm = () => {
        this.props.dispatchers.setInReduxState({ confirmations: undefined });
        this.props.removeGroupFromGroupContext(this.props.groupToBeDeletedFromCurrentContext);
    }

    componentDidMount() {
        this.setGroupsTableForGroupContext(this.props.currentContext.groups, ProteusUtils.getCurrentUser());
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceGroupContextTab> & PropsNotFromState) {
        if (this.props.currentContext?.id !== prevProps.currentContext?.id || this.props.currentContext.groups.length !== prevProps.currentContext.groups.length) {
            this.setGroupsTableForGroupContext(this.props.currentContext.groups, ProteusUtils.getCurrentUser());
        }
    }
}