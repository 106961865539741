'use strict';

/*@ngInject*/
function uiGridCell(RGBAConverter) {
	return {
		link: function($scope, element) {
			var watches = ['row'];
			if ($scope.col.colDef.colorField) {
				watches.push($scope.col.colDef.colorField);
			}
			$scope.$watchGroup(watches, function() {
				var val = $scope.$eval($scope.col.colDef.colorField);
				if (val) {
					var noColor = false;
					if (angular.isFunction($scope.noColor)) {
						noColor = $scope.noColor($scope.row, $scope.rowRenderIndex);
					} else {
						noColor = $scope.noColor;
					}
					if (!noColor) {
						var style = RGBAConverter.apply(val);
						if (style) {
							element.css('background-color', style['background-color']);
							element.css('color', style.color)
							return;
						}
					}
				}
				element.css('background-color', 'transparent');
				element.css('color', 'inherit');
			});

			// disable mobile events
			element.off('touchstart');
			element.off('touchend');
		}
	};
}

module.exports = uiGridCell;