import { getPersons_groupServiceFacadeBean_persons } from 'apollo-gen/getPersons';
import { getResources_resourcesServiceEndpoint_resources_Employee } from 'apollo-gen/getResources';
import React from 'react';
import { Grid, Icon, SemanticWIDTHS } from 'semantic-ui-react';

export interface EmployeeInfo extends getResources_resourcesServiceEndpoint_resources_Employee {
    defaultLeaveGroup: string
}

export interface ResourcesInfoProps {
    resources: getPersons_groupServiceFacadeBean_persons[] | EmployeeInfo[] | null;
    embedded: boolean;
}

type ResourcesInfoState = {
    columns: SemanticWIDTHS
}

export class ResourcesInfo extends React.Component<ResourcesInfoProps, ResourcesInfoState> {

    constructor(props: ResourcesInfoProps) {
        super(props);
        this.state = { columns: 2 };
    }

    updateDimensions = () => {
        this.setState({ columns: window.innerWidth < 768 ? 1 : 2 });
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    renderCell(data: getPersons_groupServiceFacadeBean_persons | EmployeeInfo) {
        return (<Grid.Column>
            <div className='GroupInfo_person'>
                <Icon name='user' /> {data.name} {data.firstName}
            </div>
            {data.telecomList?.map(telecom =>
                telecom?.type?.code === "MAIL" ? <div><a href={"mailto: " + telecom.value}> <Icon name='mail' size='small' /> {telecom.value}</a></div> :
                telecom?.type?.code === "GSM" ? <div> <Icon name='phone' size='small' /> {telecom.value}</div> :
                telecom?.type?.code === "TEL" ? <div> <Icon name='home' size='small' /> {telecom.value}</div> : null)}
            {(data as EmployeeInfo).defaultLeaveGroup && <div> <Icon name='group' size='small' /> {(data as EmployeeInfo).defaultLeaveGroup}</div>}
        </Grid.Column>);
    }

    render() {
        const cells = this.props.resources?.map(info => this.renderCell(info))!;
        if (cells.length % 2 === 1) {
            cells.push(
                <Grid.Column>
                    <div className='GroupInfo_person' />
                </Grid.Column>
            );
        }
        return (
            <Grid className={'GroupInfo_grid ' + (this.props.embedded ? 'GroupInfo_gridEmbedded' : '')} columns={this.state.columns} children={cells} />
        );
    }
}
