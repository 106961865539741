'use strict';

/*@ngInject*/
function refreshButtonWithDropdown($interval, Common) {
		
	return {
		restrict: 'AE',
		scope: {
			action: '=',
			buttonType: '@'
		},
		controller: require('./refresh.controller.js'),
		template: require('./refresh-button-with-dropdown.html'),
		link: function($scope, element, attrs) {
					
			element.on('$destroy', function() {
				$scope.destroyTimer();
			});
			
			$scope.setRefreshInterval(); 
			
		}
	}
};

module.exports = refreshButtonWithDropdown;