import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { CodeEntity } from "apollo-gen/CodeEntity";
import { HOUR_REGIME_SERVICE_GET_HOUR_REGIME_BY_ID, LOCATION_SERVICE_GET_LOCATION_BY_ID, RESOURCES_SERVICE_FACADE_BEAN_GET_CATEGORY_BY_ID, TEAM_SERVICE_GET_TEAM_BY_ID } from "graphql/queries";
import _ from "lodash";
import { AssociationDescriptionAndIdEditor } from "pages/AssociationFieldEditors";
import { ProteusConstants } from "ProteusConstants";
import React from "react";

export class GroupRosterRelationEntityDescriptor extends EntityDescriptor {

    protected customize() {

        const groupRosterRelationEntityDescriptor = this;

        const sliceGroupRosterRelationEditor = groupRosterRelationEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceGroupRosterRelationEditor extends SliceEntityEditorPage {

            getSaveOperationName(): string {
                return `groupRosterRelationService_saveGroupRosterRelation`;
            }

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                initialCategory: undefined as unknown as CodeEntity,
                initialHourRegime: undefined as unknown as CodeEntity,
                initialLocation: undefined as unknown as CodeEntity,
                initialTeam: undefined as unknown as CodeEntity
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceGroupRosterRelationEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceGroupRosterRelationEditor>(this),

                async getInitialCategory(id: number) {
                    const initialCategory = (await apolloClient.query({
                        query: RESOURCES_SERVICE_FACADE_BEAN_GET_CATEGORY_BY_ID,
                        variables: {
                            id
                        }
                    })).data.resourcesServiceFacadeBean_category;
                    this.getDispatchers().setInReduxState({ initialCategory });
                },

                async getInitialTeam(id: number) {
                    const initialTeam = (await apolloClient.query({
                        query: TEAM_SERVICE_GET_TEAM_BY_ID,
                        variables: {
                            id
                        }
                    })).data.teamService_teamById;
                    this.getDispatchers().setInReduxState({ initialTeam });
                },

                async getInitialLocation(id: number) {
                    const initialLocation = (await apolloClient.query({
                        query: LOCATION_SERVICE_GET_LOCATION_BY_ID,
                        variables: {
                            id
                        }
                    })).data.locationService_locationById;
                    this.getDispatchers().setInReduxState({ initialLocation });
                },

                async getInitialHourRegime(id: number) {
                    const initialHourRegime = (await apolloClient.query({
                        query: HOUR_REGIME_SERVICE_GET_HOUR_REGIME_BY_ID,
                        variables: {
                            id
                        }
                    })).data.hourRegimeService_hourRegimeById;
                    this.getDispatchers().setInReduxState({ initialHourRegime });
                },

                superLoad: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.superLoad(id);
                    if (entity !== undefined) {
                        if (entity.category) {
                            await this.getInitialCategory(entity.category);
                        }
                        if (entity.team) {
                            await this.getInitialTeam(entity.team);
                        }
                        if (entity.location) {
                            await this.getInitialLocation(entity.location);
                        }
                        if (entity.hourRegime) {
                            await this.getInitialHourRegime(entity.hourRegime);
                        }
                    }
                    return entity;
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    entity = this.convertFieldToId(entity, [ProteusConstants.CATEGORY, ProteusConstants.TEAM, ProteusConstants.LOCATION, ProteusConstants.HOUR_REGIME]);
                    await this.saveSuper(entity);
                },

                convertFieldToId(entity: any, fields: string[]) {
                    for (let i = 0; i < fields.length; i++) {
                        if (entity[fields[i]] !== null && entity[fields[i]] !== undefined && entity[fields[i]].id !== undefined) {
                            entity = { ...entity, [fields[i]]: entity[fields[i]].id };
                        }
                    }
                    return entity;
                }
            }
        }).setEntityDescriptor(groupRosterRelationEntityDescriptor);

        groupRosterRelationEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceGroupRosterRelationEditor> & EntityEditorPageProps> {

            protected addCustomFieldDescriptors(entityDescriptor: EntityDescriptor, fields: { name: string, type: string }[]) {
                for (let i = 0; i < fields.length; i++) {
                    entityDescriptor.addFieldDescriptor({ name: fields[i].name, type: fields[i].type }, new class extends FieldDescriptor {
                        protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                            return React.createElement(AssociationDescriptionAndIdEditor as any, props as FieldEditorProps);
                        }
                    });
                }
                return entityDescriptor;
            }

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "GroupRosterRelation" }, false);
                groupRosterRelationEntityDescriptor.doForFields(null, (fieldDescriptor) => {
                    if (fieldDescriptor.type === "date") {
                        fieldDescriptor.additionalFieldEditorProps = FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT})
                    }
                    result.entityDescriptor.addFieldDescriptor(fieldDescriptor);
                });

                // Add custom fields descriptors for category, location, team and hourRegime. By default, we can see in the form only the id,
                // but we want to see a dropdown for each of these fields
                result.entityDescriptor = this.addCustomFieldDescriptors(result.entityDescriptor,
                    [{ name: ProteusConstants.CATEGORY, type: "Category" }, { name: ProteusConstants.TEAM, type: "Team" },
                    { name: ProteusConstants.LOCATION, type: "Location" }, { name: ProteusConstants.HOUR_REGIME, type: "HourRegime" }]);

                result.entity = this.addInitialValue(result.entity,
                    [{ fieldName: ProteusConstants.CATEGORY, initialValue: this.props.initialCategory }, { fieldName: ProteusConstants.LOCATION, initialValue: this.props.initialLocation },
                    { fieldName: ProteusConstants.TEAM, initialValue: this.props.initialTeam }, { fieldName: ProteusConstants.HOUR_REGIME, initialValue: this.props.initialHourRegime }]);
                return result;
            }

            protected addInitialValue(entity: any, fieldValues: { fieldName: string, initialValue: CodeEntity }[]) {
                for (let i = 0; i < fieldValues.length; i++) {
                    if (entity[fieldValues[i].fieldName] !== undefined && entity[fieldValues[i].fieldName] !== null && entity[fieldValues[i].fieldName] === fieldValues[i].initialValue?.id) {
                        entity = { ...entity, [fieldValues[i].fieldName]: fieldValues[i].initialValue };
                    }
                }
                return entity;
            }

            protected convertFromObjectToNumber(entity: any, objects: { fieldName: string, initialValueFieldName: string }[]) {
                for (let i = 0; i < objects.length; i++) {
                    if (entity[objects[i].fieldName] && entity[objects[i].fieldName].id !== undefined) {
                        this.props.dispatchers.setInReduxState({ [objects[i].initialValueFieldName]: entity[objects[i].fieldName] });
                        entity = { ...entity, [objects[i].fieldName]: entity[objects[i].fieldName].id };
                    }
                }
                return entity;
            }

            protected getEntityValuesFromForm() {
                // because in the form the "category", "team", "location" and "hourRegime" fields are of type "object" and in the table they are number, 
                // a conversion is needed when navigating from "edit properties" to "view properties" (from form to table)
                let values = super.getEntityValuesFromForm();
                values = this.convertFromObjectToNumber(values,
                    [{ fieldName: ProteusConstants.CATEGORY, initialValueFieldName: "initialCategory" }, { fieldName: ProteusConstants.TEAM, initialValueFieldName: "initialTeam" },
                    { fieldName: ProteusConstants.LOCATION, initialValueFieldName: "initialLocation" }, { fieldName: ProteusConstants.HOUR_REGIME, initialValueFieldName: "initialHourRegime" }]);
                return values;
            }
        }
    }
}