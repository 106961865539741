'use strict';

/*@ngInject*/
function resourceGrid($filter) {
	
	var dateFilter = $filter('date');
	
	return {
		template: require('./resource-grid.html'),
		scope: {
			group: '=',
			ids: '=',
			fromDate: '=',
			toDate: '=',
			registrationTypeCode: '=',
			showDefaultLeaveGroup: '=',
			fetch: '=',
			getLeaveGroups: '='
		},
		controller: /*@ngInject*/ function($scope, $q, Resources, DATE_TIME_FORMAT, ModalSpinner) {
			$scope.resources = {};
			
			var fetched = false;

			$scope.$watch('fetch', function(fetch) {
				if (fetch && !fetched) {
					fetched = true;

					if ($scope.group.id) {
						ModalSpinner.show('agenda.loading.resources');
						Resources.getGroupResources($scope.resources, {
							params: {
								groupCode: $scope.group.code,
								fromDate: dateFilter($scope.fromDate, DATE_TIME_FORMAT),
								toDate: dateFilter($scope.toDate, DATE_TIME_FORMAT)
							}
						}).finally(function() {
							ModalSpinner.hide();
						});
					} else {
						if (!$scope.ids || $scope.ids.length == 0) {
							return;
						}
						$scope.status = {
							showDefaultLeaveGroup: true
						}
						ModalSpinner.show('agenda.loading.resources');
						Resources.getResources({}, {
							data: {
								employeesIds: $scope.ids,
								fromDate: $scope.fromDate,
								toDate: $scope.toDate
							}
						}).then(function(resources) {
							var promises = [];
							if (!$scope.getLeaveGroups) {
								$scope.resources = resources;
								ModalSpinner.hide();
								return;
							}
							angular.forEach(resources.data, function(resource) {
								promises.push(Resources.leaveGroups({}, {
									params: {
										fromDate: dateFilter($scope.fromDate, DATE_TIME_FORMAT),
										endDate: dateFilter($scope.toDate, DATE_TIME_FORMAT),
										resource: resource.id,
										registrationTypeCode: $scope.registrationTypeCode
									}
								}));
							});

							$q.all(promises).then(function(results) {
								angular.forEach(results, function(result, idx) {
									var code = result.data.preselectedGroupSnapshotCode;
									angular.forEach(result.data.leaveGroupSnapshots, function(leaveGroup) {
										if (code === leaveGroup.code) {
											resources.data[idx].defaultLeaveGroup = leaveGroup;
										}
									});
								});
								$scope.resources = resources;
								ModalSpinner.hide();
							});
						});
					}
				}
			});
			
		}
	}

}

module.exports = resourceGrid;
