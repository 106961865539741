import { apolloClient } from "@crispico/foundation-react/apolloClient";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CONTRACT } from "graphql/queries";
import { WorkPeriodTypeAssociationFieldEditor } from "pages/reportDefinition/customizedFieldEditors";
import { ProteusConstants } from "ProteusConstants";
import React from "react";
import { Button, Icon, InputProps } from "semantic-ui-react";
import { CUSTOM_VALUE, EMPLOYEE, EMPLOYEE_SNAPSHOT, GROUP, GROUP_CONTEXTS, GROUP_PROPERTY_CT, GROUP_RESOURCES } from "./GroupSnapshotTab";
import { SequenceDefinitionHistory } from "./SequenceTab";
import { SuccessionDefinitionHistory } from "./SuccessionTab";
import { FieldEditorProps, fieldEditors, FieldRendererProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { NumberFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/NumberFieldEditor";
import { ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";

const CONTEXT = "context";
const CUSTOM_BOOLEAN = "customBoolean";
const GROUP_CONTEXT = "GroupContext";
const PROFILES = "profiles";
export const CUSTOM_NUMBERS = "CustomNumbers";

//local entity descriptors used by tables or forms
export const compositionGroupsDescriptor = new EntityDescriptor({ name: "CompositionGroups" })
    .addFieldDescriptor({ name: GROUP, type: "GroupSnapshot" })

export const constraintDefinitionDescriptor = new EntityDescriptor({ name: "ConstraintDefinitionCustom" })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
    .addFieldDescriptor({ name: "description", type: FieldType.string })

export const constraintDefinitionHistoryDescriptor = new EntityDescriptor({ name: "ConstraintDefinitionHistory" })
    .addFieldDescriptor({ name: ProteusConstants.VALID_FROM, type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT, allowClear: false }) })
    .addFieldDescriptor({ name: ProteusConstants.VALID_UNTIL, type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT }) })
    .addFieldDescriptor({ name: "description", type: FieldType.string });

export const groupsOfContextEditorDescriptor = new EntityDescriptor({ name: "GroupsOfContextEditor" })
    .addFieldDescriptor({ name: GROUP, type: "GroupSnapshot" })

export const groupsOfContextTableDescriptor = new EntityDescriptor({ name: "GroupsOfContext" })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
    .addFieldDescriptor({ name: "description", type: FieldType.string })
    .addFieldDescriptor({ name: "sequence", type: CUSTOM_BOOLEAN })
    .addFieldDescriptor({ name: "succession", type: CUSTOM_BOOLEAN })
    .addFieldDescriptor({ name: "contraints", type: CUSTOM_BOOLEAN })
    .addFieldDescriptor({ name: "security", type: CUSTOM_BOOLEAN })
    .addFieldDescriptor({ name: "relation", type: CUSTOM_BOOLEAN })
    .addFieldDescriptor({ name: "type", type: FieldType.string })
    .addFieldDescriptor({ name: "owner", type: FieldType.string })

export const groupContextsDescriptor = new EntityDescriptor({ name: GROUP_CONTEXTS })
    .addFieldDescriptor({ name: CONTEXT, type: GROUP_CONTEXT }, new class extends FieldDescriptor {
        protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
            return React.createElement(EditorClass as any, props as FieldEditorProps);
        }
    });

export const groupEmployeesDescriptor = new EntityDescriptor({ name: "GroupEmployees" })
    .addFieldDescriptor({ name: EMPLOYEE, type: FieldType.string })

export const groupRelationTableDescriptor = new EntityDescriptor({ name: "GroupRelationsTable" })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
    .addFieldDescriptor({ name: "description", type: FieldType.string })
    .addFieldDescriptor({ name: "relationType", type: FieldType.string })

export const groupResourcesDescriptor = new EntityDescriptor({ name: GROUP_RESOURCES })
    .addFieldDescriptor({ name: EMPLOYEE, type: EMPLOYEE_SNAPSHOT }, new class extends FieldDescriptor {
        protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
            const newProps = {
                ...props,
                includeAllWithValidContract: false,
                isClearable: false,
                queryLimit: -1,
            };
            return React.createElement(EmployeeSnapshotAssociationFieldEditor as any, newProps as FieldEditorProps);
        }
    });

export const groupResourcesHistoryDescriptor = new EntityDescriptor({ name: "GroupResourcesHistory" })
    .addFieldDescriptor({ name: ProteusConstants.VALID_FROM, type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT, allowClear: false }) })
    .addFieldDescriptor({ name: ProteusConstants.VALID_UNTIL, type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT }) })

export const securityDefinitionProfilesDescriptor = new EntityDescriptor({ name: "SecurityDefinitionProfiles" })
    .addFieldDescriptor({ name: PROFILES, type: "SecurityProfile" })

export const sequenceDefinitionHistoryDescriptor = new EntityDescriptor({ name: "SequenceDefinitionHistory" }, false)
    .addFieldDescriptor({ name: "rotationSystem", type: "RotationSystem" }, new class extends FieldDescriptor {
        protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
            const newProps = {
                ...props,
                isClearable: false,
            };
            return React.createElement(AssociationFieldEditor as any, newProps as FieldEditorProps);
        }
    })
    .addFieldDescriptor({ name: "jump", type: CUSTOM_NUMBERS })
    .addFieldDescriptor({ name: "startingPosition", type: CUSTOM_NUMBERS })
    .addFieldDescriptor({ name: "referenceDate", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT, allowClear: false }) })
    .addFieldDescriptor({ name: ProteusConstants.VALID_FROM, type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT, allowClear: false }) })
    .addFieldDescriptor({ name: ProteusConstants.VALID_UNTIL, type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT }) })

export const validForRegistrationTypeDescriptor = new EntityDescriptor({ name: "ConstraintDefinitionValidFor" })
    .addFieldDescriptor({ name: "registrationType", type: FieldType.string });

export const validOnRegistrationTypeDescriptor = new EntityDescriptor({ name: "ConstraintDefinitionValidOn" })
    .addFieldDescriptor({ name: "registrationType", type: "RegistrationType" }, new class extends FieldDescriptor {
        protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
            return React.createElement(WorkPeriodTypeAssociationFieldEditor as any, props as FieldEditorProps);
        }
    });

export class ConstraintTabTableRenderer extends React.Component<FieldRendererProps>{
    render() {
        if (this.props.fieldDescriptor.name === ProteusConstants.REGISTRATION_TYPE) {
            return this.props.entity?.registrationType;
        } else {
            return (this.props.entity?.code + (this.props.entity?.description === null ? "" : (" - " + this.props.entity?.description)));
        }
    }
}

interface CustomEditorProps extends FieldEditorProps {
    getCustomEditorContent: () => any;
}

export class CustomEditor extends React.Component<CustomEditorProps> {
    render() {
        return this.props.getCustomEditorContent();
    }
}

export class CustomNumberFieldEditor extends NumberFieldEditor<FieldEditorProps> {
    protected inputRef = React.createRef<any>();

    protected getInputProps(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper): InputProps | undefined {
        return {
            ref: this.inputRef,
            min: "0",
            onBlur: () => {
                if (this.inputRef?.current?.inputRef?.current?.value === "") {
                    s.setFieldValue(hw, 0);
                }
            },
            onKeyPress: (event: any) => {
                if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                }
            }
        }
    }
}

export class EmployeeSnapshotAssociationFieldEditor extends AssociationFieldEditor<FieldEditorProps & { includeAllWithValidContract?: boolean, allowSystemSelection: boolean }>{
    static defaultProps = {
        allowSystemSelection: false,
        queryLimit: 10,
        formikProps: undefined
    }

    //dummy option created to allow the user to select the "System" option (equivalent to null)
    protected dummyOption = [{ id: -1, name: _msg("GroupContextTab.system.label"), firstName: "" }];

    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `employeeService_employeeSnapshots`;
        let query = EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CONTRACT;

        let employees = []

        if (searchQuery && searchQuery.length > 2) {
            employees = (await apolloClient.query({
                query: query,
                variables: {
                    searchText: searchQuery,
                    fromDate: new Date(),
                    untilDate: new Date(),
                    includeAllWithValidContract: this.props.includeAllWithValidContract ? this.props.includeAllWithValidContract : false
                },
                context: { showSpinner: false }
            })).data[name];
            if (this.props.allowSystemSelection) {
                employees = this.dummyOption.concat(employees);
            }
        }

        if (!searchQuery || (searchQuery && searchQuery.length === 0)) {
            let defaultValue = this.getSelectedOption();
            if (defaultValue) {
                employees.push(defaultValue);
            }
        }

        this.setState({ entities: employees });
    }

    protected getLabel(entity: any): string {
        return entity.name + " " + entity.firstName + (entity.contractHistoryItem ? " (" + entity?.contractHistoryItem?.employeeNumber + ")" : "");
    }
}

export class GroupSnapshotTabTableRenderer extends React.Component<FieldRendererProps> {
    render = () => {
        if (this.props.fieldDescriptor.name === GROUP_PROPERTY_CT) {
            return <>{this.props.entity[GROUP_PROPERTY_CT]?.code}</>;
        } else if (this.props.fieldDescriptor.name === CONTEXT) {
            return <>{this.props.entity[CONTEXT]?.name}</>;
        } else if (this.props.fieldDescriptor.name === CUSTOM_VALUE) {
            return <>{this.props.entity[CUSTOM_VALUE]?.toString()}</>;
        } else if (this.props.fieldDescriptor.name === GROUP) {
            return <>{this.props.entity[GROUP]}</>;
        } else {
            return <>{this.props.entity[EMPLOYEE].name + " " + this.props.entity[EMPLOYEE].firstName +
                (this.props.entity[EMPLOYEE].employeeNumber !== undefined ? " (" + this.props.entity[EMPLOYEE].employeeNumber + ")" : "")}
            </>;
        }
    }
}

interface MoveEmployeesRenderedProps extends FieldRendererProps {
    moveEmployeeUpOrDown: (historyItem: any, resourceId: number, moveUp: boolean) => void;
    historyItem: SequenceDefinitionHistory | SuccessionDefinitionHistory;
    table: React.RefObject<EntityTableLight>;
    editEnabled: boolean
}

export class MoveEmployeesTableRenderer extends React.Component<MoveEmployeesRenderedProps> {

    selectEmployeeAfterRowMove(historyItem: SequenceDefinitionHistory | SuccessionDefinitionHistory, resourceId: number, moveUp: boolean, table: React.RefObject<EntityTableLight>) {
        let resourceIndex = historyItem.resources.indexOf(resourceId);
        if (resourceIndex > 0 && moveUp) {
            table.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(resourceIndex - 1);
        } else if (resourceIndex < historyItem.resources.length - 1 && !moveUp) {
            table.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(resourceIndex + 1);
        }
    }

    onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, moveUp: boolean) => {
        // We need to stop the propagation of to event to the table row.
        // When we click on arrow up/down, we need to switch the content of this row up or down
        // and select the upper/bottom row (that contains the same data). If the event gets to
        // table row, the onClick handler for table row selects the row that was clicked,
        // but we do not want this.
        event.stopPropagation();
        this.props.moveEmployeeUpOrDown(this.props.historyItem, this.props.entity.employee.id, moveUp);
        this.selectEmployeeAfterRowMove(this.props.historyItem, this.props.entity.employee.id, moveUp, this.props.table);
    }

    render = () => {
        const dataTestId = "SequenceTab_orderButtons" + this.props.entity.employee.id;
        return <div className="SequenceTab_moveEmployees">
            {this.props.entity.employee.name + " " + this.props.entity.employee.firstName +
                (this.props.entity.employee.employeeNumber !== undefined ? " (" + this.props.entity.employee.employeeNumber + ")" : "")}
            <div data-testid={dataTestId}>
                <Button data-testid={dataTestId + "_down"} disabled={!this.props.editEnabled} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.onClick(event, false)}
                    icon color="green" size="mini" floated="right">
                    <Icon name="arrow down" />
                </Button>
                <Button data-testid={dataTestId + "_up"} disabled={!this.props.editEnabled} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.onClick(event, true)}
                    icon color="green" size="mini" floated="right">
                    <Icon name="arrow up" />
                </Button>
            </div>
        </div>
    }
}

export class SecurityTabTableRenderer extends React.Component<FieldRendererProps> {
    render = () => {
        return <>{this.props.entity[PROFILES]?.description}</>;
    }
}

export class SequenceTabTableRenderer extends React.Component<FieldRendererProps> {
    render = () => {
        return <>{this.props.entity.rotationSystem?.description}</>;
    }
}

fieldEditors[CUSTOM_NUMBERS] = class extends React.Component<FieldEditorProps>{
    render = () => {
        return <CustomNumberFieldEditor formikProps={this.props.formikProps} fieldDescriptor={this.props.fieldDescriptor} />
    }
}

fieldRenderers[CUSTOM_BOOLEAN] = class extends React.Component<FieldRendererProps> {
    render = () => {
        if (this.props.value) {
            return <Icon color="green" className="GroupsManagement_checkedIcon" name="check"></Icon>;
        }
        return <></>;
    }
}

fieldRenderers[CUSTOM_NUMBERS] = class extends React.Component<FieldRendererProps> {
    render = () => {
        return <>{this.props.value?.toString()}</>;
    }
}