'use strict'

/*@ngInject*/
function RegistrationTypes(DataSource) {

	var config = {
		baseUrl : '/restful/planningServiceEndpoint/agenda/registrationTypes'
	}

	var ds = new DataSource(config);

	return ds;
};

module.exports = RegistrationTypes;