'use strict';

/*
Usage examples:
Curriculum.saveOrUpdateRealization({}, {
	data: result
});

Balance.remove({}, {
	params: {
		id: entity.id
	}
});

 */
/*@ngInject*/
function DataSource($http, $q, $injector, ErrorModal, ModalSpinner) {

	return function(config) {
		
		var ds = this;
		
		var API = $injector.get('APP_URL');
		if (!API) {
			API = '';
		}
		
		// initialize configuration
		ds.config = config;
		
		/**
		 * @param op
		 *            operation name (e.g. getAll, get, update, or custom
		 *            operation)
		 */
		function request(op, config) {
			
			// TODO event: onRequestStart
			
			var _config = {};
			angular.extend(_config, ds.config[op]);
			let data;
			if (_config.data) {
				data = _config.data;
			}
			if (config && config.data) {
				if (data) {
					angular.extend(data, config.data)
				} else {
					data = config.data;
				}
			}
			angular.extend(_config, config);
			if (data) {
				_config.data = data;
			}
			_config.withCredentials = true; // TODO param
			_config.url = API + ds.config.baseUrl + (_config.url ? _config.url : '');
			
			// replace params
			if (_config.params) {
				angular.forEach(_config.params, function(value, key) {
					var _key = ":" + key;
					if (_config.url.indexOf(_key) > 0) {
						 _config.url = _config.url.replace(_key, value);
						 delete _config.params[key];
					}
				});
			}
			
			return $http(_config).then(
				function(result) {
					
					// TODO event: onRequestEnd
					
					return result;
				}, 
				function(error) {
					if (!error.config.silent) {
						ErrorModal.open({
							titleId: error.data.errorCode? ErrorModal.VALIDATION_ERROR : ErrorModal.SERVER_ERROR,
							message: error.data.errorCode ? (error.data.errorCode + ' - ' + error.data.message) : error.data.message
						});
						ModalSpinner.hide();
					}

					// TODO event: onError
					
					return $q.reject(error);
				});
		}
		
		ds.registerOperations = function(ops, resultCallback, errorCallback) {
			angular.forEach(ops, function(op) {
				ds.registerOperation(op, resultCallback, errorCallback);
			});
		};
		
		ds.registerOperation = function(op, resultCallback, errorCallback) {
			ds[op] = function(dataObject, config) {
				return request(op, config).then(
					function(result) {
						if (dataObject) {
							var data = result.data;
							if (ds.config[op].paginated) {
								if (!angular.isArray(data)) {
									dataObject.totalItems = data[ds.config.totalItemsFieldName];
									data = data[ds.config.itemsFieldName];
								}
							}
							dataObject.data = data;
						}
						
						if (resultCallback) {
							resultCallback(result);
						}
						
						return result;
				},
				function(error) {
					if (errorCallback) {
						errorCallback(error);
					}
					
					return $q.reject(error);
				});
			};
		};
		
		function _registerOperation(op, method, url, data) {
			ds.config[op] = {
				method: method,
				url: url, 
				data: data
			};
			ds.registerOperation(op);
		}
		
		// register default operations
		_registerOperation('getAll', 'GET');
		ds.config.getAll.paginated = true;
		_registerOperation('get', 'GET', '/:id');
		_registerOperation('update', 'POST');
		_registerOperation('remove', 'DELETE', '/:id');
		
		// Pagination
		
		ds.paginationCallback = function(newPage, pageSize) {
			ds.paginationOptions[ds.config.paramPageSize] = pageSize;
			ds.paginationOptions[ds.config.paramFirstItemIndex] = (newPage - 1) * pageSize;
		};
		if (ds.config.pageSize) {
			ds.paginationOptions = {};
			ds.paginationCallback(1, ds.config.pageSize);
		}
		
	};
}

module.exports = DataSource;
