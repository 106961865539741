import { apolloClient, ApolloContext, CatchedGraphQLError, ConnectedPageInfo, createSliceFoundation, EntityDescriptor, EntityEditorFormSimple, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, Utils } from "@crispico/foundation-react";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { EmployeesTree, EmployeesTreeRaw } from "components/employeesTree/EmployeesTree";
import { WizardRRC, WizardStep } from "@crispico/foundation-react/components/Wizard/Wizard";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { Drawer } from "antd";
import { getEmployee_employeeService_employee } from "apollo-gen/getEmployee";
import { EmployeeInput, TelecomInput } from "apollo-gen/globalTypes";
import { COMPANY_SERVICE_GET_COMPANIES_BY_CRITERIA, EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOT_ON_DATE, RESOURCES_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_EMPLOYEE, TELECOM_TYPE_SERVICE_GET_TELECOM_TYPES_BY_CODE } from "graphql/queries";
import Interweave from "interweave";
import _ from "lodash";
import moment from "moment";
import { BALANCE_COUNTER_TAB_INDEX, EmployeeEditorBase, EmployeeEditorBaseProps, ModalOperations, SliceEmployeeEditorBase, sliceEmployeeEditorBaseOnlyForExtension } from "pages/employeeEditor/EmployeeEditorBase";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import React from "react";
import { createRef } from "react";
import { Accordion, Button, Container, Icon, Segment, Tab } from "semantic-ui-react";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { ContractEditor, sliceContractEditor } from "./ContractEditor";
import { ResourceSummary } from "./ResourceSummary";
import { getEmployeeSnapshotOnDate_employeeService_employeeSnapshotOnDate } from "apollo-gen/getEmployeeSnapshotOnDate";
import { ScriptableContainer, Scriptable } from "@famiprog-foundation/scriptable-ui";

enum ActiveDrawerPage {
    NO_ACTIVE_PAGE = 0,
    EMPLOYEE_WIZARD = 1
}

const EMPLOYEE_WIZARD_EDITOR = "EmployeeWizardEditor"

export class SliceCompanyResources extends SliceEmployeeEditorBase {

    initialState = {
        ...sliceEmployeeEditorBaseOnlyForExtension.initialState,
        accordionIsActive: false as boolean,
        activeDrawerPage: ActiveDrawerPage.NO_ACTIVE_PAGE as ActiveDrawerPage,
        employeeFromEditor: {
            employmentDate: new Date()
        } as any,
        employeeToAdd: {} as getEmployee_employeeService_employee,
        defaultCompany: {},
        employeeSnapshotForSummary: undefined as unknown as getEmployeeSnapshotOnDate_employeeService_employeeSnapshotOnDate
    };

    nestedSlices = {
        ...sliceEmployeeEditorBaseOnlyForExtension.nestedSlices,
        contractEditor: sliceContractEditor
    };

    reducers = {
        ...sliceEmployeeEditorBaseOnlyForExtension.reducers,
        ...getBaseReducers<SliceCompanyResources>(this)
    };

    impures = {
        ...sliceEmployeeEditorBaseOnlyForExtension.impures,
        ...getBaseImpures<SliceCompanyResources>(this),

        async saveNewEmployee(employee: EmployeeInput, errorPolicy: string) {
            return (await apolloClient.mutate({
                mutation: RESOURCES_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_EMPLOYEE,
                variables: {
                    resource: employee,
                    errorPolicy: {
                        policy: errorPolicy
                    }
                },
                context: {
                    [ApolloContext.ON_ERROR_HANDLER]: ProteusUtils.getApolloErrorHandlerForValidationException(ProteusConstants.EMPLOYEE_IGNORE_VALIDATION_ERRORS, (e: CatchedGraphQLError) => {
                        this.getDispatchers().setInReduxState({
                            showModal: {
                                open: true,
                                message: e.graphQLErrors?.[0]?.extensions?.ORIGINAL_EXCEPTION_MESSAGE,
                                operation: ModalOperations.CONFIRM_IGNORE_EXCEPTIONS_NEW_EMPLOYEE,
                                showNoButton: true,
                                newEmployee: undefined
                            }
                        });
                    })
                }
            })).data.resourcesServiceFacadeBean_saveOrUpdateEmployee;
        },

        async loadEmployeeSnapshot(employeeId: number, date: Date) {
            const employeeSnapshotForSummary = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOT_ON_DATE,
                variables: {
                    employeeId,
                    date
                },
                context: { showSpinner: false }
            })).data.employeeService_employeeSnapshotOnDate;
            this.getDispatchers().setInReduxState({ employeeSnapshotForSummary });
        }
    };
};

export const sliceCompanyResourcesOnlyForExtension = createSliceFoundation(class extends SliceCompanyResources { });

export type CompanyResourcesProps = EmployeeEditorBaseProps & PropsFrom<SliceCompanyResources>;

export class CompanyResources<P extends CompanyResourcesProps = CompanyResourcesProps> extends EmployeeEditorBase<P> {
    employeesTreeRef= React.createRef<EmployeesTreeRaw>()
    refGeneralInfoGroup = createRef<EntityEditorFormSimple>();
    refCompanyInfoGroup = createRef<EntityEditorFormSimple>();
    refPersonalInfoGroup = createRef<EntityEditorFormSimple>();
    refLegalInfoGroup = createRef<EntityEditorFormSimple>();
    refContractInfoGroup = createRef<EntityEditorFormSimple>();
    refAddressInfoGroup = createRef<EntityEditorFormSimple>();

    renderDetailsContent() {
        return this.props.employee ?
            <Segment className="CompanyResources_detailsPanel">
                {this.renderAccordion()}
                {this.renderTabs()}
            </Segment> :
            <Segment className="EmployeeEditor_noEmployee">
                <div>
                    <Interweave content={_msg("EmployeeEditor.noSelection.label")} />
                </div>
            </Segment>;
    }

    renderTabs() {
        return <Scriptable id='tabs' onTabChange={this.onTabPaneChange}>{scriptable =>
            <Tab className="CompanyResources_tabs flex GroupsManagement_tab" panes={this.renderTabPanes()} menu={{ secondary: true, pointing: true }}
                renderActiveOnly={false} activeIndex={this.props.activePageIndex} onTabChange={(e: any, data: any) => scriptable.proxy.onTabChange(data.panes[data.activeIndex].pane.key)} />}
        </Scriptable>;
    }

    async openResourceSummary() {
        if (this.props.employee?.id && (!this.props.employeeSnapshotForSummary || (this.props.employeeSnapshotForSummary && this.props.employee?.id !== this.props.employeeSnapshotForSummary.id))) {
            await this.props.dispatchers.loadEmployeeSnapshot(this.props.employee.id, this.getEmployeeReferenceDate());
        }
        this.props.dispatchers.setInReduxState({ accordionIsActive: !this.props.accordionIsActive });
    }

    renderAccordion() {
        return (
            <ScriptableContainer id="ResourceSummary">
                <Accordion>
                    <Scriptable id="accordion" onClick={async () => this.openResourceSummary()}>{scriptable =>
                        <Accordion.Title active={this.props.accordionIsActive}
                            onClick={async () => await scriptable.proxy.onClick()}>
                            <Icon name='dropdown' />
                            <b><span data-testId="CompanyResources_accordionTitle">{this.props.employee?.firstName + " " + this.props.employee?.name + (this.props.accordionIsActive && this.props.employeeSnapshotForSummary ? (" (" + (this.props.employeeSnapshotForSummary.contractHistoryItem?.employeeNumber || 'ontbreekt') + ")") : "")}</span></b>
                        </Accordion.Title>}
                    </Scriptable>
                    <Accordion.Content className="CompanyResources_resourceSummary" active={this.props.accordionIsActive}>
                        <ResourceSummary employeeSnapshot={this.props.employeeSnapshotForSummary} employee={this.props.employee} date={this.getEmployeeReferenceDate()} />
                    </Accordion.Content>
                </Accordion>
            </ScriptableContainer>
        );
    }

    /**
     * @returns @override
     */
    protected getEmployeeReferenceDate() {
        return moment(this.employeesTreeRef.current!.props.s.date).toDate();
    }

    onLeaveDrawerPage = () => {
        this.props.dispatchers.setInReduxState({ activeDrawerPage: ActiveDrawerPage.NO_ACTIVE_PAGE });
        this.clearEmployeeWizard();
    };

    getGeneralInfoStep = (): WizardStep => {
        return {
            icon: 'address card outline',
            render: () => this.renderStep(this.renderGeneralInfoGroupEditor),
            title: _msg("CompanyResource.addEmployee.employeeDetails.title.label"),
            description: _msg("CompanyResource.addEmployee.employeeDetails.general.description.label"),
            onNextClick: () => {
                const { employmentDate, employeeNumber, name, firstName, vocative, title, company } =
                    Object((this.refGeneralInfoGroup.current?.formik?.current as any)?.values);

                if (!name || name === "" || !company || !employmentDate || !employeeNumber) {
                    Utils.showGlobalAlert({ message: _msg("CompanyResource.generalMandatoryError.label"), severity: Severity.WARNING, title: _msg("general.warning") });
                    return false;
                }
                this.props.dispatchers.setInReduxState({
                    employeeFromEditor: {
                        ...this.props.employeeFromEditor,
                        employmentDate, employeeNumber, name, firstName, vocative, title, company
                    }
                });

                return true;
            }
        };
    };

    saveCompanyInfo = () => {
        const { department, detail } =
            Object((this.refCompanyInfoGroup.current?.formik?.current as any)?.values);

        this.props.dispatchers.setInReduxState({
            employeeFromEditor: {
                ...this.props.employeeFromEditor,
                department, detail: {
                    ...this.props.employeeFromEditor.detail,
                    ...detail
                }
            }
        });

        return true;
    };

    getCompanyInfoStep = (): WizardStep => {
        return {
            icon: 'address card outline',
            render: () => this.renderStep(this.renderCompanyInfoGroupEditor),
            title: _msg("CompanyResource.addEmployee.employeeDetails.title.label"),
            description: _msg("CompanyResource.addEmployee.employeeDetails.company.description.label"),
            onNextClick: this.saveCompanyInfo,
            onPreviousClick: this.saveCompanyInfo,
            nextDisabled: () => {
                return false;
            }
        };
    };

    savePersonalInfo = () => {
        const { gender, nativeLanguage, nationality, detail } =
            Object((this.refPersonalInfoGroup.current?.formik?.current as any)?.values);

        this.props.dispatchers.setInReduxState({
            employeeFromEditor: {
                ...this.props.employeeFromEditor,
                gender, nativeLanguage, nationality, detail: {
                    ...this.props.employeeFromEditor.detail,
                    ...detail
                }
            }
        });

        return true;
    };

    getPersonalInfoStep = (): WizardStep => {
        return {
            icon: 'address card outline',
            render: () => this.renderStep(this.renderPersonalInfoGroupEditor),
            title: _msg("CompanyResource.addEmployee.employeeDetails.title.label"),
            description: _msg("CompanyResource.addEmployee.employeeDetails.personal.description.label"),
            onPreviousClick: this.savePersonalInfo,
            onNextClick: this.savePersonalInfo
        };
    };

    saveLegalInfo = () => {
        const { detail } =
            Object((this.refLegalInfoGroup.current?.formik?.current as any)?.values);

        this.props.dispatchers.setInReduxState({
            employeeFromEditor: {
                ...this.props.employeeFromEditor, detail: {
                    ...this.props.employeeFromEditor.detail,
                    ...detail
                }
            }
        });

        return true;
    };

    getLegalInfoStep = (): WizardStep => {
        return {
            icon: 'address card outline',
            render: () => this.renderStep(this.renderLegalInfoGroupEditor),
            title: _msg("CompanyResource.addEmployee.employeeDetails.title.label"),
            description: _msg("CompanyResource.addEmployee.employeeDetails.legal.description.label"),
            onPreviousClick: this.saveLegalInfo,
            onNextClick: this.saveLegalInfo
        };
    };

    saveContractInfo = () => {
        const { type, status, workArrangement, employmentBreak, detail } =
            Object((this.refContractInfoGroup.current?.formik?.current as any)?.values);

        this.props.dispatchers.setInReduxState({
            employeeFromEditor: {
                ...this.props.employeeFromEditor, contract: {
                    ...this.props.employeeFromEditor.contract,
                    type, status, workArrangement, employmentBreak
                },
                detail: {
                    ...this.props.employeeFromEditor.detail,
                    ...detail
                }
            }
        });

        return true;

    };

    getContractStep = (): WizardStep => {
        return {
            icon: 'clipboard outline',
            render: () => this.renderStep(this.renderContractEditor),
            title: _msg("CompanyResource.addEmployee.employeeContract.title.label"),
            description: _msg("CompanyResource.addEmployee.employeeContract.description.label"),
            onPreviousClick: this.saveContractInfo,
            onNextClick: this.saveContractInfo
        };
    };

    saveAddressInfo = () => {
        const { addressType, street, number, postalBox, city, telecom } =
            Object((this.refAddressInfoGroup.current?.formik?.current as any)?.values);

        this.props.dispatchers.setInReduxState({
            employeeFromEditor: {
                ...this.props.employeeFromEditor,
                address: {
                    ...this.props.employeeFromEditor.address,
                    type: addressType, street, number, postalBox, city
                },
                telecom: {
                    ...this.props.employeeFromEditor.telecom,
                    ...telecom
                }
            }
        });

        return true;
    };

    getAddressStep = (): WizardStep => {
        return {
            icon: 'home',
            render: () => this.renderStep(this.renderAddressEditor),
            title: _msg("CompanyResource.addEmployee.employeeAddress.title.label"),
            description: _msg("CompanyResource.addEmployee.employeeAddress.description.label"),
            onPreviousClick: this.saveAddressInfo,
            onNextClick: this.saveAddressInfo
        };
    };

    getWizardSteps(): WizardStep[] {
        return [
            this.getGeneralInfoStep(),
            this.getCompanyInfoStep(),
            this.getPersonalInfoStep(),
            this.getLegalInfoStep(),
            this.getContractStep(),
            this.getAddressStep()
        ];
    }

    protected getDetailsGeneralGroupDescriptor() {
        let descriptor = new EntityDescriptor({ name: "EmployeeDetailsGeneralGroup" }, false)
            .addFieldDescriptor({ name: "employmentDate", type: FieldType.date })
            .addFieldDescriptor({ name: "employeeNumber", type: FieldType.string })
            .addFieldDescriptor({ name: "name", type: FieldType.string })
            .addFieldDescriptor({ name: "firstName", type: FieldType.string })
            .addFieldDescriptor({ name: "vocative", type: "Vocative" })
            .addFieldDescriptor({ name: "title", type: FieldType.string })
            .addFieldDescriptor({ name: "company", type: "Company" }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        value: {code: 'BRABO CVBA', id: 1}
                    }
                    return React.createElement(EditorClass as any, newProps as FieldEditorProps)
                }
            }());
        return descriptor;
    }

    renderGeneralInfoGroupEditor = () => {
        return <EntityEditorFormSimple scriptableUiId={EMPLOYEE_WIZARD_EDITOR} ref={this.refGeneralInfoGroup} entityDescriptor={this.getDetailsGeneralGroupDescriptor()} entity={{ ...this.props.employeeFromEditor }} hideButtonBar />;
    };

    protected getDetailsCompanyGroupDescriptor() {
        let descriptor = new EntityDescriptor({ name: "EmployeeDetailsCompanyGroup" }, false)
            .addFieldDescriptor({ name: "department", type: "Department", icon: "building" })
            .addFieldDescriptor({ name: "detail.category", type: "Category", icon: "clipboard" })
            .addFieldDescriptor({ name: "detail.team", type: "Team", icon: "users" })
            .addFieldDescriptor({ name: "detail.location", type: "Location", icon: "map marker alternate" })
            .addFieldDescriptor({ name: "detail.hourRegime", type: "HourRegime", icon: "clock" })
            .addFieldDescriptor({ name: "detail.constraintGroup", type: "ConstraintGroup", icon: "clock" });
        return descriptor;
    }

    renderCompanyInfoGroupEditor = () => {
        return <EntityEditorFormSimple scriptableUiId={EMPLOYEE_WIZARD_EDITOR} ref={this.refCompanyInfoGroup} entityDescriptor={this.getDetailsCompanyGroupDescriptor()} entity={{ ...this.props.employeeFromEditor }} hideButtonBar />;
    };

    protected getDetailsPersonalGroupDescriptor() {
        let descriptor = new EntityDescriptor({ name: "EmployeeDetailsPersonalGroup" }, false)
            .addFieldDescriptor({ name: "gender", type: "Gender" })
            .addFieldDescriptor({ name: "nativeLanguage", type: "Language" })
            .addFieldDescriptor({ name: "nationality", type: "Nationality" })
            .addFieldDescriptor({ name: "detail.dateOfBirth", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT}) })
            .addFieldDescriptor({ name: "detail.birthplace", type: "City" })
            .addFieldDescriptor({ name: "detail.timeRegistration", type: FieldType.boolean });
        return descriptor;
    }

    renderPersonalInfoGroupEditor = () => {
        return <EntityEditorFormSimple scriptableUiId={EMPLOYEE_WIZARD_EDITOR} ref={this.refPersonalInfoGroup} entityDescriptor={this.getDetailsPersonalGroupDescriptor()} entity={{ ...this.props.employeeFromEditor }} hideButtonBar />;
    };

    protected getDetailsLegalGroupDescriptor() {
        let descriptor = new EntityDescriptor({ name: "EmployeeDetailsLegalGroup" }, false)
            .addFieldDescriptor({ name: "detail.nationalRegisterNumber", type: FieldType.string })
            .addFieldDescriptor({ name: "detail.dimonaNumber", type: FieldType.string })
            .addFieldDescriptor({ name: "detail.healthInsuranceNumber", type: FieldType.string })
            .addFieldDescriptor({ name: "detail.childAllowanceFund", type: "ChildAllowanceFund" })
            .addFieldDescriptor({ name: "detail.childAllowanceFundNumber", type: FieldType.string })
            .addFieldDescriptor({ name: "detail.barema", type: "Scale" });
        return descriptor;
    }

    renderLegalInfoGroupEditor = () => {
        return <EntityEditorFormSimple scriptableUiId={EMPLOYEE_WIZARD_EDITOR} ref={this.refLegalInfoGroup} entityDescriptor={this.getDetailsLegalGroupDescriptor()} entity={{ ...this.props.employeeFromEditor }} hideButtonBar />;
    };

    onScaleHistoryModalOk = (employeeDetail: any) => {
        this.props.dispatchers.setInReduxState({
            employeeFromEditor: {
                ...this.props.employeeFromEditor,
                detail: {
                    ...this.props.employeeFromEditor.detail,
                    ...employeeDetail
                }
            }
        });
    };

    renderContractEditor = () => {
        const scaleHistory = this.props.employeeFromEditor?.detail?.scaleHistory || [];
        return <ContractEditor {...this.props.contractEditor} dispatchers={this.props.dispatchers.contractEditor} refContractInfoGroup={this.refContractInfoGroup}
            entity={{ ...this.props.employeeFromEditor.contract, detail: { ...this.props.employeeFromEditor.detail, scaleHistory: scaleHistory } }} startDate={moment(this.props.employeeFromEditor.employmentDate).startOf('day').toDate()}
            onModalOk={this.onScaleHistoryModalOk} />;
    };

    protected getAddressDescriptor() {
        let descriptor = new EntityDescriptor({ name: "EmployeeAddress" }, false)
            .addFieldDescriptor({ name: "addressType", type: "AddressType" })
            .addFieldDescriptor({ name: "street", type: FieldType.string })
            .addFieldDescriptor({ name: "number", type: FieldType.string })
            .addFieldDescriptor({ name: "postalBox", type: FieldType.string })
            .addFieldDescriptor({ name: "city", type: "City" })
            .addFieldDescriptor({ name: "telecom.gsm", type: FieldType.string })
            .addFieldDescriptor({ name: "telecom.telephone", type: FieldType.string });
        return descriptor;
    }

    renderAddressEditor = () => {
        return <EntityEditorFormSimple scriptableUiId={EMPLOYEE_WIZARD_EDITOR} ref={this.refAddressInfoGroup} entityDescriptor={this.getAddressDescriptor()}
            entity={{ ...this.props.employeeFromEditor.address, ...this.props.employeeFromEditor.telecom, addressType: this.props.employeeFromEditor.address?.type }} hideButtonBar />;
    };

    renderStep(renderGroup: () => any) {
        return <Container fluid className="EntityEditorPage_container flex-grow">
            <Segment padded className="CompanyResources_noMargin flex-grow EntityEditorPage_segment">
                {renderGroup()}
            </Segment>
        </Container>;
    }

    saveNewEmployee = async (errorPolicy: string) => {
        const { addressType, street, number, postalBox, city, telephone, gsm } =
            Object((this.refAddressInfoGroup.current?.formik?.current as any)?.values);

        let employmentDate = moment(this.props.employeeFromEditor.employmentDate).startOf('day').toDate();
        const telecom: TelecomInput[] = [];
        if (gsm || telephone) {
            const telecomTypeCodes = (await apolloClient.query({
                query: TELECOM_TYPE_SERVICE_GET_TELECOM_TYPES_BY_CODE,
                variables: { telecomTypeCodes: ['TEL', 'GSM'] }
            })).data.telecomTypeService_telecomTypeByCodes;
            const telephoneType = telecomTypeCodes.filter((type: any) => type.code === "TEL")[0];
            const gsmType = telecomTypeCodes.filter((type: any) => type.code === "GSM")[0]

            telephoneType && telephone && telecom.push({ type: telephoneType, value: telephone });
            gsmType && gsm && telecom.push({ type: gsmType, value: gsm });
        }

        let employee: EmployeeInput = {
            addressHistory: !_.isEmpty(this.props.employeeFromEditor.address) && ((addressType && !_.isEmpty(addressType))
                 || this.props.employeeFromEditor.address.street || this.props.employeeFromEditor.address.number  || this.props.employeeFromEditor.address.postalBox ||
                 this.props.employeeFromEditor.address.city)  ? [{
                address: {
                    type: addressType, street, houseNumber: number, box: postalBox, city
                },
                validFrom: employmentDate
            }] : [],
            companyHistory: [{
                company: this.props.employeeFromEditor.company,
                validFrom: employmentDate
            }],
            departmentHistory: this.props.employeeFromEditor.department ? [{
                department: this.props.employeeFromEditor.department,
                validFrom: employmentDate
            }] : [],
            detail: this.props.employeeFromEditor.detail ? {
                barema: this.props.employeeFromEditor.detail.barema,
                birthplace: this.props.employeeFromEditor.detail.birthplace,
                categoryHistory: this.props.employeeFromEditor.detail.category ? [{
                    category: this.props.employeeFromEditor.detail.category,
                    validFrom: employmentDate
                }] : [],
                childAllowanceFund: this.props.employeeFromEditor.detail.childAllowanceFund,
                childAllowanceFundNumber: this.props.employeeFromEditor.detail.childAllowanceFundNumber,
                contractHistory: !_.isEmpty(this.props.employeeFromEditor.contract) ? [{
                    ...this.props.employeeFromEditor.contract,
                    employeeNumber: this.props.employeeFromEditor.employeeNumber,
                    validFrom: employmentDate
                }] : [],
                dateOfBirth: this.props.employeeFromEditor.detail.dateOfBirth,
                dimonaNumber: this.props.employeeFromEditor.detail.dimonaNumber,
                healthInsuranceNumber: this.props.employeeFromEditor.detail.healthInsuranceNumber,
                hourRegimeHistory: this.props.employeeFromEditor.detail.hourRegime ? [{
                    hourRegime: this.props.employeeFromEditor.detail.hourRegime,
                    validFrom: employmentDate
                }] : [],
                locationHistory: this.props.employeeFromEditor.detail.location ? [{
                    location: this.props.employeeFromEditor.detail.location,
                    validFrom: employmentDate
                }] : [],
                nationalRegisterNumber: this.props.employeeFromEditor.detail.nationalRegisterNumber,
                teamHistory: this.props.employeeFromEditor.detail.team ? [{
                    team: this.props.employeeFromEditor.detail.team,
                    validFrom: employmentDate
                }] : [],
                timeRegistration: this.props.employeeFromEditor.timeRegistration,
                constraintGroupHistory: this.props.employeeFromEditor.detail.constraintGroup ? [{
                    constraintGroup: this.props.employeeFromEditor.detail.constraintGroup,
                    validFrom: employmentDate
                }] : [],
                scaleHistory: this.props.employeeFromEditor.detail.scaleHistory || []
            } : {},
            firstName: this.props.employeeFromEditor.firstName,
            gender: this.props.employeeFromEditor.gender,
            name: this.props.employeeFromEditor.name,
            nationality: this.props.employeeFromEditor.nationality,
            nativeLanguage: this.props.employeeFromEditor.nativeLanguage,
            objectType: "Employee",
            title: this.props.employeeFromEditor.title,
            vocative: this.props.employeeFromEditor.vocative,
            telecomList: telecom
        };

        const employeeId = await this.props.dispatchers.saveNewEmployee(employee, errorPolicy);
        this.onLeaveDrawerPage();
        return employeeId;
    };

    async onFinishClick(errorPolicy: string) {
        await this.saveAddressInfo();
        const employeeId = await this.saveNewEmployee(errorPolicy);
        await this.employeesTreeRef.current?.getEmployeeSnapshots();
        const newSelectionId = this.employeesTreeRef.current?.findNewlyAddedElementInTree(this.employeesTreeRef.current?.getTreeRoot(), employeeId, "");
        this.employeesTreeRef.current?.setSelectedId(newSelectionId);
        this.employeesTreeRef.current?.props.onSelectedIdChanged?.(newSelectionId);
        if (!newSelectionId) {
            await this.loadAdditionalEmployeeData(employeeId, true);
            await this.props.dispatchers.loadEmployeeSnapshot(employeeId, this.getEmployeeReferenceDate());
        }
    }

    protected renderAddEmployeeDrawer() {
        return <Drawer destroyOnClose placement="right" visible={true} className="CompanyResources_drawer flex-grow" width="80%"
            closeIcon={<Button icon="close" className="less-padding" circular />}
            onClose={() => this.onLeaveDrawerPage()}
        >
            {ProteusUtils.renderDrawerHeader(_msg("CompanyResources.newEmployee.label"))}
            <WizardRRC id="companyResourcesWizard"
                steps={this.getWizardSteps()}
                onFinishClick={() => this.onFinishClick(ProteusConstants.ENFORCE_ALL)}
            />
        </Drawer >;
    }

    clearEmployeeWizard() {
        this.props.dispatchers.setInReduxState({
            employeeFromEditor: {
                ...sliceCompanyResourcesOnlyForExtension.initialState.employeeFromEditor,
                company: this.props.defaultCompany
            }
        });
    }

    openAddEmployeeDrawer = () => {
        this.props.dispatchers.setInReduxState({ activeDrawerPage: ActiveDrawerPage.EMPLOYEE_WIZARD });
    };

    async saveAndReloadEmployee(errorPolicy: string) {
        await super.saveAndReloadEmployee(errorPolicy);
        if (this.props.employee?.id) {
            await this.props.dispatchers.loadEmployeeSnapshot(this.props.employee.id, this.getEmployeeReferenceDate());
        }
    }

    protected renderSaveSegment() {
        return (
            <Segment className="CompanyResources_actionsSegment">
                <div className="EmployeeEditor_saveSegment CompanyResources_saveSegment">
                    <span>
                        <Scriptable id="saveButton" onClick={async () => await this.saveAndReloadEmployee(ProteusConstants.ENFORCE_ALL)}>{scriptable =>
                            <Button primary onClick={async () => await scriptable.proxy.onClick()} disabled={!this.props.employee || !this.isEditable()}>
                                {_msg("general.save")}
                            </Button>}
                        </Scriptable>
                        {this.props.activePageIndex !== BALANCE_COUNTER_TAB_INDEX &&
                            <Button icon="refresh" color="green"
                                onClick={async () => {
                                    // if an employee is selected and refresh button is clicked => show modal to confirm refresh
                                    if (this.props.employee) {
                                        this.props.dispatchers.setInReduxState({
                                            showModal: {
                                                open: true,
                                                operation: ModalOperations.REFRESH,
                                                newEmployee: undefined,
                                                message: _msg("leavePage.message.label"),
                                                showNoButton: true
                                            }
                                        });
                                        return;
                                    }
                                    // otherwise reload tree
                                    await this.employeesTreeRef.current?.getEmployeeSnapshots();
                                }}
                            />}
                        <Button color="green" icon="add" onClick={this.openAddEmployeeDrawer} />
                    </span>
                </div>
                {this.renderAdditionalContentForSaveSegment()}
            </Segment>
        );
    }
    
    protected async handleModalRefresh() {
        await this.employeesTreeRef.current?.getEmployeeSnapshots();
        this.employeesTreeRef.current?.setSelectedId(undefined);
        this.employeesTreeRef.current?.props.onSelectedIdChanged?.(undefined);
     }

     protected async handleOnClickOkModal() {
        if (this.props.showModal?.operation === ModalOperations.CONFIRM_IGNORE_EXCEPTIONS_NEW_EMPLOYEE) {
            this.onFinishClick(ProteusConstants.IGNORE_WARNINGS);
        }
        super.handleOnClickOkModal();
    }

    render() {
        return (<ScriptableContainer id="CompanyResources">
            <div className="CompanyResources">
                {this.showModalInfoMessage()}
                {this.renderHistoryTableLight()}
                {this.renderSaveSegment()}
                <SplitPaneExt defaultSize="20%">
                    <EmployeesTree id="EmployeesTree_resources" ref={this.employeesTreeRef} onSelectedIdChanged={this.handleSelectedId} />
                    {this.renderDetailsContent()}
                </SplitPaneExt>
                {this.props.activeDrawerPage !== ActiveDrawerPage.NO_ACTIVE_PAGE && this.renderAddEmployeeDrawer()}
            </div>
        </ScriptableContainer>
        );
    }

    handleSelectedId = (selectedId?: string | undefined, dateChanged?: boolean) => {
        let employeeId = selectedId ? Utils.navigate(this.employeesTreeRef.current?.props.s.root, selectedId)?.id : undefined;
        if (dateChanged) {
            employeeId = this.props.employee?.id;
        }
        this.loadAdditionalEmployeeData(employeeId, true);
        if (this.props.accordionIsActive) {
            this.props.dispatchers.loadEmployeeSnapshot(employeeId, this.getEmployeeReferenceDate());
        }
        if (!selectedId && employeeId && dateChanged) {
            const newSelectionId = this.employeesTreeRef.current?.findNewlyAddedElementInTree(this.employeesTreeRef.current?.getTreeRoot(), employeeId, "");
            this.employeesTreeRef.current?.setSelectedId(newSelectionId);
        }
    };

    async componentDidMount() {
        super.componentDidMount();
        let companies = (await apolloClient.query({
            query: COMPANY_SERVICE_GET_COMPANIES_BY_CRITERIA,
            variables: {
                param: {
                    searchText: ProteusConstants.BRABO_COMPANY_CODE,
                    includeContactPersons: false,
                }
            },
            context: { showSpinner: false }
        })).data.companyService_companiesByCriteria;
        if (companies.length > 0) {
            this.props.dispatchers.setInReduxState({
                employeeFromEditor: {
                    ...this.props.employeeFromEditor,
                    company: companies[0]
                },
                defaultCompany: companies[0]
            })
        }
    }
}

export const infoCompanyResources = new ConnectedPageInfo(sliceCompanyResourcesOnlyForExtension, CompanyResources, "CompanyResources");
infoCompanyResources.routeProps = { permission: "RESOURCES_VIEW" };