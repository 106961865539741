'use strict';

angular.module('proteus.tasks', ['ui.grid', 'ui.grid.pagination', 'ui.grid.selection', 'ui.grid.resizeColumns'])

.run(function($templateCache) {
	$templateCache.put('tasks/background-tasks-page.html', require('./background-tasks-page.html'));
})

.factory('BackgroundTasks', require('./background-tasks.service'))

.controller('BackgroundTasksPageCtrl', require('./background-tasks-page.controller'));

module.exports = 'proteus.tasks';
