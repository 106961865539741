import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import _ from "lodash";
import React from "react";
import { AssociationNameAndIdEditor } from "pages/AssociationFieldEditors"
import { RESOURCES_SERVICE_FACADE_BEAN_GET_COMPANY_SNAPSHOT } from "graphql/queries";

const COMPANY: string = "company";

export class EnvironmentEntityDescriptor extends EntityDescriptor {

    toMiniString(entityWithMiniFields: any) {
        return entityWithMiniFields?.description;
    }

    protected customize() {

        const environmentEntityDescriptor = this;

        const sliceEnvironmentEditor = environmentEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceEnvironmentEditor extends SliceEntityEditorPage {

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                initialCompany: undefined as unknown as { id: number, name: string }
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceEnvironmentEditor>(this),
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceEnvironmentEditor>(this),

                async getInitialCompanyData(id: number) {
                    let initialCompany = (await apolloClient.query({
                        query: RESOURCES_SERVICE_FACADE_BEAN_GET_COMPANY_SNAPSHOT,
                        variables: {
                            id
                        }
                    })).data.resourcesServiceFacadeBean_companySnapshot;
                    this.getDispatchers().setInReduxState({ initialCompany });
                },

                superLoad: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.superLoad(id);
                    if (entity !== undefined && entity.company !== undefined) {
                        await this.getInitialCompanyData(entity.company);
                    }
                    return entity;
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    if (entity.company !== null && entity.company !== undefined && entity.company.id !== undefined) {
                        entity = { ...entity, company: entity.company.id };
                    }
                    await this.saveSuper(entity);
                },
            }
        }).setEntityDescriptor(environmentEntityDescriptor);

        environmentEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceEnvironmentEditor> & EntityEditorPageProps> {

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "Environment" }, false);

                // replace company descriptor with company picker descriptor
                environmentEntityDescriptor.doForFields(null, (fieldDescriptor) => result.entityDescriptor.addFieldDescriptor(fieldDescriptor));
                result.entityDescriptor.removeFieldDescriptors(COMPANY);
                result.entityDescriptor.addFieldDescriptor({ name: COMPANY, type: "Company" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        return React.createElement(AssociationNameAndIdEditor as any, props as FieldEditorProps);
                    }
                });
                if (result.entity.company !== undefined && result.entity.company !== null && result.entity.company === this.props.initialCompany?.id) {
                    result.entity = { ...result.entity, company: this.props.initialCompany };
                }
                return result;
            }

            protected getEntityValuesFromForm() {
                // because in the form the "company" field is of type "object" and in the table it is of type number (company id), a conversion
                // is needed when navigating from "edit properties" to "view properties" (from form to table)
                let values = super.getEntityValuesFromForm();
                if (values.company && values.company.id !== undefined) {
                    this.props.dispatchers.setInReduxState({ initialCompany: values.company });
                    values = { ...values, company: values.company.id };
                }
                return values;
            }
        }
    }
}
