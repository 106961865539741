'use strict';

/*@ngInject*/
function Curriculum(DataSource) {
	
	var config = {
			baseUrl : '/restful/educationService'
	};
	var ds = new DataSource(config);
	
	ds.config.getEducationCard = ds.config.getEducationCard || {};
	ds.config.getEducationCard.method = 'GET';
	ds.config.getEducationCard.url = '/getEducationCard';
	if (!ds.getEducationCard) {
		ds.registerOperation('getEducationCard');
	}
	
	ds.config.getTrackModuleOrComponentRealizations = ds.config.getTrackModuleOrComponentRealizations || {};
	ds.config.getTrackModuleOrComponentRealizations.method = 'GET';
	ds.config.getTrackModuleOrComponentRealizations.url = '/getTrackModuleOrComponentRealizations';
	if (!ds.getTrackModuleOrComponentRealizations) {
		ds.registerOperation('getTrackModuleOrComponentRealizations');
	}

	ds.config.getRealizationResults = ds.config.getRealizationResults || {};
	ds.config.getRealizationResults.method = 'GET';
	ds.config.getRealizationResults.url = '/getRealizationResults';
	if (!ds.getRealizationResults) {
		ds.registerOperation('getRealizationResults');
	}
	
	ds.config.belongsToCatalogItem = ds.config.belongsToCatalogItem || {};
	ds.config.belongsToCatalogItem.method = 'GET';
	ds.config.belongsToCatalogItem.url = '/belongsToCatalogItem';
	if (!ds.belongsToCatalogItem) {
		ds.registerOperation('belongsToCatalogItem');
	}	
	
	ds.config.getObjectiveStatuses = ds.config.getObjectiveStatuses || {};
	ds.config.getObjectiveStatuses.method = 'GET';
	ds.config.getObjectiveStatuses.url = '/getObjectiveStatuses';
	if (!ds.getObjectiveStatuses) {
		ds.registerOperation('getObjectiveStatuses');
	}
	
	ds.config.saveOrUpdateRealization = ds.config.saveOrUpdateRealization || {};
	ds.config.saveOrUpdateRealization.method = 'POST';
	ds.config.saveOrUpdateRealization.url = '/saveOrUpdateRealization';
	if (!ds.saveOrUpdateRealization) {
		ds.registerOperation('saveOrUpdateRealization');
	}

	ds.config.saveOrUpdateExemption = ds.config.saveOrUpdateExemption || {};
	ds.config.saveOrUpdateExemption.method = 'POST';
	ds.config.saveOrUpdateExemption.url = '/saveOrUpdateExemption';
	if (!ds.saveOrUpdateExemption) {
		ds.registerOperation('saveOrUpdateExemption');
	}
	
	ds.config.getStatusesForComponent = ds.config.getStatusesForComponent || {};
	ds.config.getStatusesForComponent.method = 'GET';
	ds.config.getStatusesForComponent.url = '/getStatusesForComponent';
	if (!ds.getStatusesForComponent) {
		ds.registerOperation('getStatusesForComponent');
	}
	
	ds.config.getCatalogItemObjective = ds.config.getCatalogItemObjective || {};
	ds.config.getCatalogItemObjective.method = 'GET';
	ds.config.getCatalogItemObjective.url = '/getCatalogItemObjective';
	if (!ds.getCatalogItemObjective) {
		ds.registerOperation('getCatalogItemObjective');
	}

	ds.config.getComponentObjective = ds.config.getComponentObjective || {};
	ds.config.getComponentObjective.method = 'GET';
	ds.config.getComponentObjective.url = '/getComponentObjective';
	if (!ds.getComponentObjective) {
		ds.registerOperation('getComponentObjective');
	}
	
	ds.config.saveOrUpdateObjective = ds.config.saveOrUpdateObjective || {};
	ds.config.saveOrUpdateObjective.method = 'POST';
	ds.config.saveOrUpdateObjective.url = '/saveOrUpdateObjective';
	if (!ds.saveOrUpdateObjective) {
		ds.registerOperation('saveOrUpdateObjective');
	}

	ds.config.removeRealization = ds.config.removeRealization || {};
	ds.config.removeRealization.method = 'DELETE';
	ds.config.removeRealization.url = '/removeRealization/:id';
	if (!ds.removeRealization) {
		ds.registerOperation('removeRealization');
	}
	
	ds.config.removeExemption = ds.config.removeExemption || {};
	ds.config.removeExemption.method = 'DELETE';
	ds.config.removeExemption.url = '/removeExemption/:id';
	if (!ds.removeExemption) {
		ds.registerOperation('removeExemption');
	}

	ds.config.removeObjective = ds.config.removeObjective || {};
	ds.config.removeObjective.method = 'DELETE';
	ds.config.removeObjective.url = '/removeObjective/:id';
	if (!ds.removeObjective) {
		ds.registerOperation('removeObjective');
	}

	return ds;
}

module.exports = Curriculum;