'use strict';

/*@ngInject*/
function LoginWithProteusPageCtrl($rootScope, $scope, $timeout, User, $location, ModalSpinner, $translate, $q) {
	$scope.user = {};
	
	$scope.alerts = [];
	$scope.isSwitchAccount = $location.search().switch;
	
	$scope.closeAlert = function(index) {
		$scope.alerts.splice(index, 1);
	};
	
	// used by base-proteus-js/account-administration for confirmation message
	$scope.confirmation = window.App;

	$scope.$watch('confirmation.infoMessage', function(msg) {
		if (!msg) {
			return;
		}
		$scope.alerts.push({
			type: 'success',
			msg: msg
		});
		// remove this too, so it won't show up the next time the login view is displayed
		delete window.App.infoMessage;
	});
	
	$timeout(function() {
		angular.element('[ng-model="username"]').focus();
	});

	function getLoginSuccessHandler() {
		return $q(function(resolve, reject) {
			window.App.loginSuccessHandler()
				.then(function(handler){
	        		resolve(handler);
	      		})
	      		.catch(reject)
		});
	}

	$scope.login = function(event) {
		event.preventDefault();
		ModalSpinner.show();
		
		// perform login
		var that = this;

		let params = $location.search();
		let isSwitch = params.switch;
		let data = 'username=' + $scope.username + '&password=' + encodeURIComponent($scope.password) + '&rememberMe=' + $scope.rememberMe;
		if (isSwitch) {
			data += '&switch=true';
		}
		
		User.login(null , {
			silent: true,
			data: data,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
			}
		}).then(function(result) {
			$scope.authenticationError = false;
			if (window.App.loginSuccessHandler) {
				getLoginSuccessHandler().then(function(handler) {
					that.redirectAfterLogin();
				});
			} else {
				ModalSpinner.hide();
			}
		}).catch(function(error) {
			ModalSpinner.hide();
		});
	}
	
	$scope.redirectAfterLogin = function() { 
		if ($rootScope.previousRoute && $rootScope.previousRoute != '/account/login') {
			// navigate to previous page
			$location.url($rootScope.previousRoute);
		} else {
			// go to home page
			$location.url("");
		}
	}
};

module.exports = LoginWithProteusPageCtrl;
