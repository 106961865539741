import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { GROUP_SERVICE_FACADE_BEAN_GET_GROUP, GROUP_SERVICE_FACADE_BEAN_SAVE_GROUP_PROPERTY } from "graphql/queries";
import _ from "lodash";
import { AssociationNameAndIdEditor } from "pages/AssociationFieldEditors";
import React from "react";

export class GroupPropertyEntityDescriptor extends EntityDescriptor {

    protected customize() {

        const groupPropertyEntityDescriptor = this;

        const sliceGroupPropertyEditor = groupPropertyEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceGroupPropertyEditor extends SliceEntityEditorPage {

            initQueries() {
                super.initQueries();
                this.saveMutation = GROUP_SERVICE_FACADE_BEAN_SAVE_GROUP_PROPERTY;
            }

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            getSaveOperationName(): string {
                return `groupPropertyService_saveGroupProperty`;
            }

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                initialGroup: undefined as unknown as { id: number, name: string }
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceGroupPropertyEditor>(this),
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceGroupPropertyEditor>(this),

                async getInitialGroup(id: number) {
                    let initialGroup = (await apolloClient.query({
                        query: GROUP_SERVICE_FACADE_BEAN_GET_GROUP,
                        variables: {
                            id
                        }
                    })).data.groupServiceFacadeBean_group;
                    this.getDispatchers().setInReduxState({ initialGroup });
                },

                superLoad: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.superLoad(id);
                    if (entity !== undefined && entity.group !== undefined) {
                        await this.getInitialGroup(entity.group);
                    }
                    return entity;
                },

                superMutation: sliceEntityEditorPageOnlyForExtension.impures.invokeSaveMutation,
                async invokeSaveMutation(options: any) {
                    let localEntity = this.getState().entity;
                    if (localEntity.group !== null && localEntity.group !== undefined && localEntity.group.id !== undefined) {
                        localEntity = { ...localEntity, group: localEntity.group.id };
                    }
                    options.variables = { entity: localEntity };
                    return await this.superMutation(options);
                }
            }
        }).setEntityDescriptor(groupPropertyEntityDescriptor);

        groupPropertyEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceGroupPropertyEditor> & EntityEditorPageProps> {

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "GroupProperty" }, false);

                // replace the group entity descriptor with a group picker entity descriptor
                groupPropertyEntityDescriptor.doForFields(null, (fieldDescriptor) => result.entityDescriptor.addFieldDescriptor(fieldDescriptor));
                result.entityDescriptor.removeFieldDescriptors("group");
                result.entityDescriptor.addFieldDescriptor({ name: "group", type: "GroupSnapshot" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        // innerEntityDescriptor has 'fields' field empty and function getGraphQlFieldsToRequest from
                        // AssociationFieldEditor wouldn't find any extra fields to query and options would be displayed
                        // as empty rows 
                        const newProps = {
                            ...props,
                        }
                        return React.createElement(AssociationNameAndIdEditor as any, newProps as FieldEditorProps);
                    }
                });
                if (result.entity.group !== undefined && result.entity.group !== null && result.entity.group === this.props.initialGroup?.id) {
                    result.entity = { ...result.entity, group: this.props.initialGroup };
                }
                return result;
            }

            protected getEntityValuesFromForm() {
                // because in the form the "group" field is of type "object" and in the table it is of type number (group id), a conversion
                // is needed when navigating from "edit properties" to "view properties" (from form to table)
                let values = super.getEntityValuesFromForm();
                if (values.group && values.group.id !== undefined) {
                    this.props.dispatchers.setInReduxState({ initialGroup: values.group });
                    values = { ...values, group: values.group.id };
                }
                return values;
            }
        }
    }
}