'use strict';

// THIS NEEDS TO BE REMOVED AS SOON AS WE REMOVE JQ_UI
var bootstrap = require('bootstrap');

var ui        = require('jquery-ui'),
	uiSpinner = require('jquery-ui/ui/widgets/spinner'),
	uiButton  = require('jquery-ui/ui/widgets/button'),
	uiKeyCode = require('jquery-ui/ui/keycode'),
	uiSAE     = require('jquery-ui/ui/safe-active-element');

/**
 * Wraps a JQuery UI Spinner widget.
 * TODO accept {min, max, step} as attrs
 */
function proteusSpinner() {

	return {
		restrict: 'A',
		require: 'ngModel',
		link: function($scope, elt, attrs, ngModel) {
			var min = 0, max = 100, step = 1;
			var adjustToMin = true;
			if (attrs.min) {
				min = attrs.min;
			}

			if (attrs.max) {
				max = attrs.max;
			}

			if (attrs.step) {
				step = attrs.step;
			}

			if (attrs.adjustToMin) {
				adjustToMin = eval(attrs.adjustToMin);
			}

			var spinner = elt.spinner({
				max: max,
				min: min,
				step: step,
				stop: function(event, ui) {
					var v = value();
					if (v != null && v < min) {
						value(min);
					}
					if (v > max) {
						value(max);
					}
					// update model
					ngModel.$setViewValue(value());
				}
			});
			
			function value(v) {
				if (angular.isDefined(v)) {
					spinner.spinner('value', v);
				} else {
					return spinner.spinner('value');
				}
			}
			
			// set the initial value
			ngModel.$render = function() {
				value(ngModel.$modelValue);
			};

			function parse(value) {
				return parseInt(value);
			}
			ngModel.$parsers.push(parse);

			ngModel.$validators.validCharacters = function(modelValue, viewValue) {
				return /^\d+$/.test(viewValue);
			};
		}
	};
	
}

module.exports = proteusSpinner;