import { createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, getBaseImpures, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import moment from "moment";
import { addResourceWorkRosterStatusFieldEditor } from "pages/employeeEditor/customFieldRenderersEditors";

export class ResourceWorkRosterEntityDescriptor extends EntityDescriptor {

    protected customize() {
        const resourceWorkRosterEntityDescriptor = this;
        addResourceWorkRosterStatusFieldEditor(this);

        const sliceResourceWorkRosterEditor = resourceWorkRosterEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceResourceWorkRosterEditor extends SliceEntityEditorPage {

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceResourceWorkRosterEditor>(this),

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    entity = {
                        ...entity,
                        validFrom: moment(entity.validFrom).startOf("day").toDate(),
                        validUntil: entity.validUntil ? moment(entity.validUntil).endOf("day").toDate() : entity.validUntil
                    }
                    await this.saveSuper(entity);
                },
            }
        }).setEntityDescriptor(resourceWorkRosterEntityDescriptor);;

        resourceWorkRosterEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceResourceWorkRosterEditor> & EntityEditorPageProps> {}
    }
}