import { apolloClient, ApolloContext, CatchedGraphQLError, createSliceFoundation, EntityDescriptor, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { TabbedPage, TabbedPageProps } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { fieldEditors } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { PasswordEditor } from "@crispico/foundation-react/pages/user/PasswordEditor";
import { getEmployee_employeeService_employee, getEmployee_employeeService_employee_detail_constraintGroupHistory, getEmployee_employeeService_employee_detail_contractHistory_suspensionHistory, getEmployee_employeeService_employee_detail_functionHistory } from "apollo-gen/getEmployee";
import { getResourceRosterRelations_workrosterServiceFacadeBean_resourceRosterRelations } from "apollo-gen/getResourceRosterRelations";
import { getResourceWorkRosters_resourceWorkRosterService_resourceWorkRosters } from "apollo-gen/getResourceWorkRosters";
import { HistoryTable, sliceHistoryTable } from "components/HistoryTable";
import { EMPLOYEE_SERVICE_GET_RESOURCE_BY_DATE, RESOURCES_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_EMPLOYEE, RESOURCE_WORK_ROSTER_SERVICE_GET_BY_RESOURCE_ID, WORKROSTER_SERVICE_FACADE_BEAN_GET_RESOURCE_ROSTER_RELATIONS } from "graphql/queries";
import Interweave from "interweave";
import lodash from "lodash";
import moment from "moment";
import { Registration } from "pages/registrationEditor/RegistrationEditor";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import React from 'react';
import { Button, Grid, Modal, Tab } from "semantic-ui-react";
import { AddressTab, ADDRESS_HISTORY_ITEM, sliceAddressTab } from "./AddressTab";
import { BalanceCounterTab, sliceBalanceCounterTab } from "./BalanceCounterTab";
import { BalanceTab, sliceBalanceTab } from "./BalanceTab";
import { CardTab, sliceCardTab } from "./CardTab";
import { ConstraintGroupTab, CONSTRAINT_GROUP_FIELD, CONSTRAINT_GROUP_HISTORY, CONSTRAINT_GROUP_HISTORY_FIELD, sliceConstraintGroupTab } from "./ConstraintGroupTab";
import { ContractTab, CONTRACT_HISTORY, SCALE_HISTORY_ITEM, sliceContractTab } from "./ContractTab";
import { CreditTab, sliceCreditTab } from "./CreditTab";
import { CurriculumTab, sliceCurriculumTab } from "./CurriculumTab";
import { accountEntityDescriptor, addressEntityDescriptor, categoryEntityDescriptor, civilStatusEntityDescriptor, companyEntityDescriptor, constraintGroupsEntityDescriptor, contractEntityDescriptor, departmentEntityDescriptor, functionEntityDescriptor, HISTORY_FIELD_TYPE, hourRegimeEntityDescriptor, locationEntityDescriptor, premiumHistoryEntityDescriptor, resourceRosterRelationsEntityDescriptor, resourceWorkRosterEntityDescriptor, scaleEntityDescriptor, shareRegisterEntityDescriptor, taxStatusEntityDescriptor, teamEntityDescriptor } from "./customFieldRenderersEditors";
import { ACCOUNT_HISTORY_ITEM, CHILDREN_IN_MY_RESPONSABILITY, CHILDREN_WITH_DISABILITIES, CIVIL_STATUS_HISTORY_ITEM, ExtraTab, OTHERS_IN_MY_RESPONSABILITY, OTHERS_WITH_DISABILITIES, PARTNER_IN_MY_RESPONSABILITY, PARTNER_WITH_DISABILITY, PERSON_WITH_DISABILITY, SHARE_REGISTER_HISTORY_ITEM, sliceExtraTab, TAX_STATUS_HISTORY } from "./ExtraTab";
import { EMPLOYEE_FUNCTION_FIELD, FunctionTab, FUNCTION_HISTORY, FUNCTION_HISTORY_FIELD, sliceFunctionTab } from "./FunctionTab";
import { CATEGORY_HISTORY_ITEM, COMPANY_HISTORY_ITEM, DEPARTMENT_HISTORY_ITEM, EntityEditorFormCustomized, HOUR_REGIME_HISTORY_ITEM, LOCATION_HISTORY_ITEM, PersonalDetailsTab, RESOURCE_ROSTER_RELATION, RESOURCE_WORK_ROSTER_HISTORY_ITEM, slicePersonalDetailsTab, TEAM_HISTORY_ITEM } from "./PersonalDetailsTab";
import { PremiumTab, slicePremiumTab } from "./PremiumTab";
import { PREMIUM_HISTORY, ProgressionTab, sliceProgressionTab } from "./ProgressionTab";

export const EMPLOYEE_EDITOR = "EmployeeEditor";
const PERSON = "Person";
export const PERSONAL_DETAILS_TAB_INDEX = 0;
export const EXTRA_TAB_INDEX = 1;
export const CARD_TAB_INDEX = 4;
export const CURRICULUM_TAB_INDEX = 5;
export const BALANCE_COUNTER_TAB_INDEX = 6;
export const BALANCE_TAB_INDEX = 7;
export const CREDIT_TAB_INDEX = 8;
export const FUNCTIONS_TAB_INDEX = 9;
export const CONSTRAINT_GROUPS_TAB_INDEX = 10;
export const PROGRESSION_TAB_INDEX = 11;
export const PREMIUM_TAB_INDEX = 12;

export enum ModalOperations {
    CONFIRM_IGNORE_EXCEPTIONS = "CONFIRM_IGNORE_EXCEPTIONS",
    CONFIRM_IGNORE_EXCEPTIONS_NEW_EMPLOYEE = "CONFIRM_IGNORE_EXCEPTIONS_NEW_EMPLOYEE",
    CHANGE_EMPLOYEE = "CHANGE_EMPLOYEE",
    INVALID_PASSWORD = "INVALID_PASSWORD",
    REFRESH = "REFRESH",
    FUNCTION_OR_CONSTRAINT_ALREADY_EXISTS = "FUNCTION_OR_CONSTRAINT_ALREADY_EXISTS"
};

export enum ButtonType {
    FUNCTION = "FUNCTION",
    CONSTRAINT_GROUPS = "CONSTRAINT_GROUPS"
}

interface Employee extends getEmployee_employeeService_employee {
    __typename: string;
}

export class SliceEmployeeEditorBase {

    initialState = {
        employee: undefined as unknown as Employee | undefined | null,
        employeeLoaded: undefined as unknown as getEmployee_employeeService_employee | undefined | null,
        showModal: { open: false as boolean, message: "" as string, operation: "" as string, showNoButton: true as boolean, newEmployee: undefined as unknown as number | undefined },
        openedTableLight: undefined as unknown as { fieldName: string, fieldType: string, mandatoryFields: string[]; },
        resourceRosterRelations: undefined as unknown as getResourceRosterRelations_workrosterServiceFacadeBean_resourceRosterRelations[],
        resourceRosterRelationsLoaded: undefined as unknown as getResourceRosterRelations_workrosterServiceFacadeBean_resourceRosterRelations[],
        resourceWorkRosters: undefined as unknown as getResourceWorkRosters_resourceWorkRosterService_resourceWorkRosters[],
        resourceWorkRostersLoaded: undefined as unknown as getResourceWorkRosters_resourceWorkRosterService_resourceWorkRosters[],
        activePageIndex: 0 as number,
    }

    nestedSlices = {
        addressTab: sliceAddressTab,
        balanceTab: sliceBalanceTab,
        balanceCounterTab: sliceBalanceCounterTab,
        cardTab: sliceCardTab,
        constraintGroupTab: sliceConstraintGroupTab,
        contractTab: sliceContractTab,
        creditTab: sliceCreditTab,
        curriculumTab: sliceCurriculumTab,
        extraTab: sliceExtraTab,
        functionTab: sliceFunctionTab,
        historyTable: sliceHistoryTable,
        personalDetailsTab: slicePersonalDetailsTab,
        premiumTab: slicePremiumTab,
        progressionTab: sliceProgressionTab
    }

    reducers = {
        ...getBaseReducers<SliceEmployeeEditorBase>(this)
    }

    impures = {
        ...getBaseImpures<SliceEmployeeEditorBase>(this),

        async loadResourceRosterRelations(resourceId: number | string | null | undefined) {
            let resourceRosterRelations = [];
            if (resourceId) {
                resourceRosterRelations = (await apolloClient.query({
                    query: WORKROSTER_SERVICE_FACADE_BEAN_GET_RESOURCE_ROSTER_RELATIONS,
                    variables: {
                        criteria: {
                            firstResult: 0,
                            maxResults: 0,
                            joins: [],
                            filters: [{
                                name: "resource",
                                value: resourceId
                            }]
                        }
                    }
                })).data.workrosterServiceFacadeBean_resourceRosterRelations;
            }
            this.getDispatchers().setInReduxState({ resourceRosterRelations, resourceRosterRelationsLoaded: resourceRosterRelations });
        },

        async loadResourceWorkRosters(resourceId: number | null | undefined) {
            let resourceWorkRosters = [];
            if (resourceId) {
                resourceWorkRosters = (await apolloClient.query({
                    query: RESOURCE_WORK_ROSTER_SERVICE_GET_BY_RESOURCE_ID,
                    variables: {
                        resourceId
                    }
                })).data.resourceWorkRosterService_resourceWorkRosters;
            }
            this.getDispatchers().setInReduxState({ resourceWorkRosters, resourceWorkRostersLoaded: resourceWorkRosters });
        },

        getEmployeeNumber() {
            let employeeNumber: string | number = "";
            if (this.getState().employee?.detail?.contractHistory) {
                const currentContract = fieldEditors[HISTORY_FIELD_TYPE].retrieveCurrentHistoryItem(this.getState().employee?.detail?.contractHistory)
                if (currentContract) {
                    employeeNumber = " (" + currentContract.employeeNumber + ")";
                }
            }
            return employeeNumber;
        },

        async saveEmployee(employee: getEmployee_employeeService_employee | null | undefined, resourceRosterRelations: getResourceRosterRelations_workrosterServiceFacadeBean_resourceRosterRelations[], resourceWorkRosters: getResourceWorkRosters_resourceWorkRosterService_resourceWorkRosters[], policy: string) {
            await apolloClient.mutate({
                mutation: RESOURCES_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_EMPLOYEE,
                variables: {
                    resource: employee,
                    resourceRosters: resourceRosterRelations || [],
                    resourceWorkRosters: resourceWorkRosters || [],
                    errorPolicy: { policy: policy }
                },
                context: {
                    [ApolloContext.ON_ERROR_HANDLER]: ProteusUtils.getApolloErrorHandlerForValidationException(ProteusConstants.EMPLOYEE_IGNORE_VALIDATION_ERRORS, (e: CatchedGraphQLError) => {
                        this.getDispatchers().setInReduxState({
                            showModal: {
                                open: true,
                                message: e.graphQLErrors?.[0]?.extensions?.ORIGINAL_EXCEPTION_MESSAGE,
                                operation: ModalOperations.CONFIRM_IGNORE_EXCEPTIONS,
                                showNoButton: true,
                                newEmployee: undefined
                            }
                        });
                    })
                }
            });
        }
    }
}

export const sliceEmployeeEditorBaseOnlyForExtension = createSliceFoundation(class extends SliceEmployeeEditorBase { });

export type EmployeeEditorBaseProps = PropsFrom<SliceEmployeeEditorBase> & TabbedPageProps & {
    getOperatingMode: (registration: Registration, context: string) => number;
    loadRegistrationJson: (registrationId: number) => Promise<any>;
};

export class EmployeeEditorBase<P extends EmployeeEditorBaseProps> extends TabbedPage<P> {

    protected refAddressTab = React.createRef<AddressTab>();
    protected refBalanceTab = React.createRef<BalanceTab>();
    protected refCardTab = React.createRef<CardTab>();
    protected refConstraintGroupsTab = React.createRef<ConstraintGroupTab>();
    protected refContractTab = React.createRef<ContractTab>();
    protected refCreditTab = React.createRef<CreditTab>();
    protected refCurriculumTab = React.createRef<CurriculumTab>();
    protected refFunctionsTab = React.createRef<FunctionTab>();
    protected refHistoryTable = React.createRef<HistoryTable>();
    protected refPasswordEditor = React.createRef<PasswordEditor>();
    protected refPersonalDetailsEditor = React.createRef<EntityEditorFormCustomized>();
    protected refPremiumTab = React.createRef<PremiumTab>();
    protected refProgressionTab = React.createRef<ProgressionTab>();
    protected refProgressionTabCustomized = React.createRef<EntityEditorFormCustomized>();

    constructor(props: any) {
        super(props);
        this.handleOnClickOkModal = this.handleOnClickOkModal.bind(this);
    }

    async loadEmployee(id: number | string | null | undefined, fromDate: Date | number) {
        let employee = undefined;
        if (id) {
            employee = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_RESOURCE_BY_DATE,
                variables: {
                    resourceId: id as number,
                    fromDate: new Date(fromDate),
                    untilDate: null
                }
            })).data.employeeService_resource;
            this.refAddressTab.current?.getEntityTableLightRef().current?.open(employee?.telecomList || []);
            this.refCardTab.current?.getEntityTableLightRef().current?.open(employee?.detail?.cards || []);
        }
        this.props.dispatchers.setInReduxState({ employee, employeeLoaded: employee });
    }

    ////////////////////////////////////
    // METHODS USED EMPLOYEE EDITOR - HISTORY TABLES
    ////////////////////////////////////

    protected getTableLightEntityDescriptor() {
        if (this.props.openedTableLight?.fieldType === COMPANY_HISTORY_ITEM) {
            return this.addHistoryFieldsToDescriptor(companyEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === CATEGORY_HISTORY_ITEM) {
            return this.addHistoryFieldsToDescriptor(categoryEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === DEPARTMENT_HISTORY_ITEM) {
            return this.addHistoryFieldsToDescriptor(departmentEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === TEAM_HISTORY_ITEM) {
            return this.addHistoryFieldsToDescriptor(teamEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === LOCATION_HISTORY_ITEM) {
            return this.addHistoryFieldsToDescriptor(locationEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === HOUR_REGIME_HISTORY_ITEM) {
            return this.addHistoryFieldsToDescriptor(hourRegimeEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === RESOURCE_ROSTER_RELATION) {
            return this.addHistoryFieldsToDescriptor(resourceRosterRelationsEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === ACCOUNT_HISTORY_ITEM) {
            return this.addHistoryFieldsToDescriptor(accountEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === CIVIL_STATUS_HISTORY_ITEM) {
            return this.addHistoryFieldsToDescriptor(civilStatusEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === TAX_STATUS_HISTORY) {
            return this.addHistoryFieldsToDescriptor(taxStatusEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === SHARE_REGISTER_HISTORY_ITEM) {
            return this.addHistoryFieldsToDescriptor(shareRegisterEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === ADDRESS_HISTORY_ITEM) {
            return this.addHistoryFieldsToDescriptor(addressEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === CONTRACT_HISTORY) {
            return this.addHistoryFieldsToDescriptor(contractEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === FUNCTION_HISTORY) {
            return this.adjustCustomHistoryDescriptor(functionEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === CONSTRAINT_GROUP_HISTORY) {
            return this.adjustCustomHistoryDescriptor(constraintGroupsEntityDescriptor)
        } else if (this.props.openedTableLight?.fieldType === PREMIUM_HISTORY) {
            return this.addHistoryFieldsToDescriptor(premiumHistoryEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === SCALE_HISTORY_ITEM) {
            return this.addHistoryFieldsToDescriptor(scaleEntityDescriptor);
        } else if (this.props.openedTableLight?.fieldType === RESOURCE_WORK_ROSTER_HISTORY_ITEM) {
            return this.addHistoryFieldsToDescriptor(resourceWorkRosterEntityDescriptor);
        }
        return new EntityDescriptor({ name: "dummy" })
            .addFieldDescriptor({ name: "validFrom", type: FieldType.date, propsForEditor: { hasTime: true, formatString: ProteusConstants.DATE_TIME_FORMAT, allowClear: false } })
            .addFieldDescriptor({ name: "validUntil", type: FieldType.date, propsForEditor: { hasTime: true, formatString: ProteusConstants.DATE_TIME_FORMAT } });
    }

    protected addHistoryFieldsToDescriptor = (descriptor: EntityDescriptor) => {
        descriptor.addFieldDescriptor({ name: "validFrom", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT, allowClear: false }) });
        descriptor.addFieldDescriptor({ name: "validUntil", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT }) });
        return descriptor;
    }

    protected adjustCustomHistoryDescriptor(descriptor: EntityDescriptor) {
        descriptor = this.addHistoryFieldsToDescriptor(descriptor);
        let keyField: string = this.props.openedTableLight?.fieldType === FUNCTION_HISTORY ? EMPLOYEE_FUNCTION_FIELD : CONSTRAINT_GROUP_FIELD;
        // For FunctionTab and ConstraintGroupTab descriptors, the dropdown from the form should be disabled
        // if there is at least one row available in the history table.
        descriptor.getField(keyField).enabled = this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.getEntities().length === 0 ? true : false;
        return descriptor;
    }

    /**
     * This method is a callback used to remove the fields from FunctionTab and ConstraintGroupTab.
     */
    protected onRemoveCustomHistoryField = (keyFieldId: number, keyField: string) => {
        let employee = this.getEmployeeValueFromForm();
        let fieldName = keyField === EMPLOYEE_FUNCTION_FIELD ? FUNCTION_HISTORY_FIELD : CONSTRAINT_GROUP_HISTORY_FIELD;
        let elements = (this.props.employee?.detail as any)?.[fieldName] || [];
        elements = (elements as any[]).filter((item: any) =>
            keyFieldId === -1 ? item !== null : keyFieldId === -2 ? item?.[keyField] !== null : item?.[keyField]?.id !== keyFieldId);
        this.props.dispatchers.setInReduxState({ employee: { ...employee, detail: { ...employee.detail, [fieldName]: elements } } });
    }

    protected onOpenTableLight = (fieldDescriptor: FieldDescriptor, entities: any[]) => {
        this.props.dispatchers.setInReduxState({
            openedTableLight: {
                fieldType: fieldDescriptor.entityName,
                fieldName: fieldDescriptor.name,
                mandatoryFields: fieldDescriptor.mandatoryFields
            }
        });
        this.refHistoryTable.current?.setOnOpen(fieldDescriptor?.onOpen);
        this.refHistoryTable.current?.open(entities);
        this.props.dispatchers.historyTable.setInReduxState({
            allowHoles: fieldDescriptor.entityName === FUNCTION_HISTORY
                || fieldDescriptor.entityName === CONTRACT_HISTORY
                || fieldDescriptor.entityName === CONSTRAINT_GROUP_HISTORY ? true : false,
            entities: entities
        });
    }

    protected onModalOk = (entities: any[]) => {
        let employee = this.getEmployeeValueFromForm();
        let employeeLoaded: any = lodash.cloneDeep(this.props.employeeLoaded);
        if (this.props.openedTableLight.fieldType === RESOURCE_ROSTER_RELATION) {
            // special case for resourceRosterRelation field, because it is not part of employee entity.
            this.props.dispatchers.setInReduxState({ employee, resourceRosterRelations: entities, openedTableLight: undefined });
            return;
        } else if (this.props.openedTableLight.fieldType === RESOURCE_WORK_ROSTER_HISTORY_ITEM) {
            // special case for resourceWorkRosters field, because it is not part of employee entity.
            this.props.dispatchers.setInReduxState({ employee, resourceWorkRosters: entities, openedTableLight: undefined });
            return;
        } else if (this.props.openedTableLight.fieldType === PREMIUM_HISTORY) {
            this.props.dispatchers.progressionTab.setInReduxState({ premiumHistory: entities, shouldSavePremiumHistory: true });
        } else if (this.props.openedTableLight.fieldType === FUNCTION_HISTORY || this.props.openedTableLight.fieldType === CONSTRAINT_GROUP_HISTORY) {
            // special case for functionHistory/constraintGroupHistory fields where the structure is different.
            let keyField: string = this.props.openedTableLight?.fieldType === FUNCTION_HISTORY ? EMPLOYEE_FUNCTION_FIELD : CONSTRAINT_GROUP_FIELD;
            let historyFieldName: string = this.props.openedTableLight?.fieldType === FUNCTION_HISTORY ? FUNCTION_HISTORY_FIELD : CONSTRAINT_GROUP_HISTORY_FIELD;
            let historyValues = (this.props.employee?.detail as any)?.[historyFieldName] || [];
            const openedFieldId = this.props.openedTableLight.fieldType === FUNCTION_HISTORY ? this.props.functionTab.openedEmployeeFunctionId : this.props.constraintGroupTab.openedConstraintGroupId;

            // show a warning message if a new function/constraint group is added, but the selected value in dropdown
            // already exists in employee's function history/constraint group history.
            const fieldAlreadyExists = entities.length > 0 && historyValues.filter((item: any) => item?.[keyField]?.id === entities[0][keyField].id).length > 0;
            if (openedFieldId === -1 && fieldAlreadyExists) {
                this.props.dispatchers.setInReduxState({
                    showModal: {
                        open: true,
                        operation: ModalOperations.FUNCTION_OR_CONSTRAINT_ALREADY_EXISTS,
                        newEmployee: undefined,
                        showNoButton: false,
                        message: this.props.openedTableLight.fieldType === FUNCTION_HISTORY ?
                            _msg("EmployeeEditor.functionAlreadyExists.label") :
                            _msg("EmployeeEditor.constraintGroupAlreadyExists.label")
                    }
                });
            }

            // onModalOk, filter all values existing in the employee.detail.functionHistory/employee.detail.constraintGroupHistory 
            // with employeeFunction/contraintGroup value equal with the value for which the history field was opened 
            // and replace them with "entities" property.
            historyValues = historyValues.filter((item: any) =>
                openedFieldId === -2 ? item?.[keyField] : item?.[keyField]?.id !== openedFieldId);
            historyValues = [...historyValues, ...entities];
            if (entities.length > 0 && openedFieldId === -1) {
                historyValues = historyValues.filter((item: any) => item !== null);
            }
            employee = { ...employee, detail: { ...employee.detail, [historyFieldName]: historyValues } };
        } else {
            // the general case used for all entities.
            // the history field can be found in employee directly (fieldName = companyHistory) or can be found in employee.detail (fieldName = detail.categoryHistory).
            let fieldNameAsArray = this.props.openedTableLight.fieldName.split(".").slice(1);
            if (fieldNameAsArray.length > 1) {
                const field: string = fieldNameAsArray[1];
                const employeeDetail = { ...employee.detail, [field]: entities };
                employee = { ...employee, detail: employeeDetail };
                //Sort entities on employeeLoaded too. Needed for dirty
                let employeeLoadedDetail = { ...employeeLoaded.detail, [field]: employeeLoaded.detail[field]?.sort((a: any, b: any) => moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf()) };
                employeeLoaded = { ...employeeLoaded, detail: employeeLoadedDetail };

            } else {
                const field: string = fieldNameAsArray[0];
                employee = { ...employee, [field]: entities };
                employeeLoaded = { ...employeeLoaded, [field]: employeeLoaded[field]?.sort((a: any, b: any) => moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf()) };

            }
        }
        this.props.dispatchers.setInReduxState({ employee, employeeLoaded, openedTableLight: undefined });
        this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
    }

    protected onModalCancel = () => {
        this.props.dispatchers.setInReduxState({ openedTableLight: undefined });
        this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
    }

    getDefaultValuesForHistoryFields = () => {
        if (this.props.openedTableLight?.fieldType === RESOURCE_ROSTER_RELATION || this.props.openedTableLight?.fieldType === RESOURCE_WORK_ROSTER_HISTORY_ITEM) {
            return { resource: this.props.employee?.id }
        } else if (this.props.openedTableLight?.fieldType === TAX_STATUS_HISTORY) {
            return {
                [PERSON_WITH_DISABILITY]: false,
                [PARTNER_IN_MY_RESPONSABILITY]: false,
                [PARTNER_WITH_DISABILITY]: false,
                [CHILDREN_IN_MY_RESPONSABILITY]: 0,
                [CHILDREN_WITH_DISABILITIES]: 0,
                [OTHERS_IN_MY_RESPONSABILITY]: 0,
                [OTHERS_WITH_DISABILITIES]: 0
            };
        } else if (this.props.openedTableLight?.fieldType === SHARE_REGISTER_HISTORY_ITEM) {
            return { amount: 0 };
        } else if ((this.props.openedTableLight?.fieldType === FUNCTION_HISTORY || this.props.openedTableLight?.fieldType === CONSTRAINT_GROUP_HISTORY) &&
            this.refHistoryTable.current && this.refHistoryTable.current.getEntityTableSimpleCustomizedRef().current &&
            this.refHistoryTable.current.getEntityTableSimpleCustomizedRef().current!.getEntities().length > 0) {
            // For functionHistory/constraintGroupHistory field, if at least one function/constraintGroup is completed for a table,
            // set that function/constraintGroup as default each time the editor (for that corresponding table) opens.
            let keyField: string = this.props.openedTableLight?.fieldType === FUNCTION_HISTORY ? EMPLOYEE_FUNCTION_FIELD : CONSTRAINT_GROUP_FIELD;
            return { [keyField]: this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[0][keyField] };
        } else if (this.props.openedTableLight?.fieldType === PREMIUM_HISTORY) {
            return { taskCounter: 0 };
        }
        return {};
    }

    /**
     * When a suspension contract is added/updated/removed, propagate the changes into contract history table.
     * onModalOk, all the changes to history table will be retrieved, including the ones we propagated. 
     */
    protected updateContractHistoryTable = (suspensionHistory: getEmployee_employeeService_employee_detail_contractHistory_suspensionHistory[]) => {
        const selectedContractIndex = this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!;
        const entities = this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.getEntities();
        let allContracts = entities ? [...entities] : [];
        let currentContract = allContracts.splice(selectedContractIndex, 1)[0];
        currentContract = { ...currentContract, suspensionHistory };
        allContracts.push(currentContract);
        this.refHistoryTable.current?.open(allContracts);
    }

    /**
     * This method is used to render additional content in EntityTableLight modal, beside the history table (example: ContractTab - Suspensions table)
     */
    protected additionalModalContent = () => {
        if (this.props.openedTableLight?.fieldType === CONTRACT_HISTORY) {
            return this.refContractTab.current?.getContractModalAdditionalContent() || <></>;
        }

        if (this.props.openedTableLight?.fieldType === PREMIUM_HISTORY) {
            return this.refProgressionTab.current?.getPremiumModalAdditionalContent() || <></>;
        }

        return <></>
    }

    protected renderHistoryTableLight() {
        const descriptor = this.getTableLightEntityDescriptor();
        return <HistoryTable actions={{ showAddButton: this.isEditable(), showEditButton: this.isEditable() }} ref={this.refHistoryTable}
            getDefaultValuesForFields={this.getDefaultValuesForHistoryFields} formCustomizer={{
                showInModal: true, headerIcon: descriptor.icon || "file", headerContent: _msg(this.props.openedTableLight?.fieldType + ".label"),
                mandatoryFields: this.props.openedTableLight?.mandatoryFields, modalClassName: this.isEditable() ? "" : "EmployeeEditorTab_disabledModal"
            }} {...this.props.historyTable} onAdd={sliceHistoryTable.reducers.onAdd}
            onDelete={() => {
                this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                if (this.props.openedTableLight?.fieldType === CONTRACT_HISTORY) {
                    this.refContractTab.current?.getHistoryTableRef().current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                }
            }}
            onSave={() => {
                this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                if (this.props.openedTableLight?.fieldType === CONTRACT_HISTORY) {
                    this.refContractTab.current?.getHistoryTableRef().current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                }
            }}
            dispatchers={this.props.dispatchers.historyTable} entityDescriptor={descriptor}
            modalCustomizer={{ onModalOk: this.onModalOk, onModalCancel: this.onModalCancel, additionalModalContent: this.additionalModalContent }}
            onSelectItem={(itemId: any) => this.onHistoryTabelSelectItem(itemId)}
        />
    }

    ////////////////////////////////////
    // METHODS USED BY EMPLOYEE EDITOR - TAB PANES
    ////////////////////////////////////

    protected renderTabPanes() {
        let tabPanes: { menuItem: string, pane: { key: number, content: JSX.Element } }[] = [];
        if (this.props.employee?.__typename === PERSON) {
            tabPanes.push({
                menuItem: _msg("EmployeeEditor.address.label"),
                pane: {
                    key: 2,
                    content: this.renderTabContent(this.renderAddressTab())
                }
            });
            return tabPanes;
        }

        // Personal details tab
        tabPanes.push({
            menuItem: _msg("EmployeeEditor.personalDetails.label"),
            pane: {
                key: PERSONAL_DETAILS_TAB_INDEX,
                content: this.renderTabContent(this.renderPersonalDetailsTab())
            }
        });

        // Extra tab
        tabPanes.push({
            menuItem: _msg("EmployeeEditor.extra.label"),
            pane: {
                key: EXTRA_TAB_INDEX,
                content: this.renderTabContent(this.renderExtraTab())
            }
        });

        // Address tab
        tabPanes.push({
            menuItem: _msg("EmployeeEditor.address.label"),
            pane: {
                key: 2,
                content: this.renderTabContent(this.renderAddressTab())
            }
        });

        // Contract tab
        tabPanes.push({
            menuItem: _msg("EmployeeEditor.contract.label"),
            pane: {
                key: 3,
                content: this.renderTabContent(this.renderContractTab())
            }
        });

        // Card tab
        tabPanes.push({
            menuItem: _msg("EmployeeEditor.card.label"),
            pane: {
                key: CARD_TAB_INDEX,
                content: this.renderTabContent(this.renderCardTab())
            }
        });

        // Curriculum tab
        tabPanes.push({
            menuItem: _msg("EmployeeEditor.curriculum.label"),
            pane: {
                key: CURRICULUM_TAB_INDEX,
                content: this.renderTabContent(this.renderCurriculumTab(), this.refCurriculumTab.current?.renderSaveBarAdditionalContent())
            }
        });

        const addBalanceCounterTab = AppMetaTempGlobals.appMetaInstance.hasPermission(ProteusConstants.BALANCES_VIEW_IN_EMPLOYEE_EDITOR, false) ||
            AppMetaTempGlobals.appMetaInstance.hasPermission(ProteusConstants.BALANCES_VIEW, false);

        // Balance Counter tab
        addBalanceCounterTab && tabPanes.push({
            menuItem: _msg("BalanceCounterHistoryItem.label"),
            pane: {
                key: BALANCE_COUNTER_TAB_INDEX,
                content: this.renderTabContent(this.renderBalanceCounterTab())
            }
        });

        tabPanes.push({
            menuItem: _msg("RegistrationEditor.balance.label"),
            pane: {
                key: BALANCE_TAB_INDEX,
                content: this.renderTabContent(this.renderBalanceTab())
            }
        })

        // Credit tab
        tabPanes.push({
            menuItem: _msg("Credit.label"),
            pane: {
                key: CREDIT_TAB_INDEX,
                content: this.renderTabContent(this.renderCreditTab(), this.refCreditTab.current?.renderSaveBarAdditionalContent(), "EmployeeEditor_saveSegmentCredit")
            }
        });

        // Function tab
        tabPanes.push({
            menuItem: _msg("EmployeeEditor.functions.label"),
            pane: {
                key: FUNCTIONS_TAB_INDEX,
                content: this.renderTabContent(this.renderFunctionTab(), this.renderAddFunctionsOrConstraintsButton(ButtonType.FUNCTION))
            }
        });

        // Constraint groups tab
        tabPanes.push({
            menuItem: _msg("EmployeeEditor.constraintGroups.label"),
            pane: {
                key: CONSTRAINT_GROUPS_TAB_INDEX,
                content: this.renderTabContent(this.renderConstraintGroupsTab(), this.renderAddFunctionsOrConstraintsButton(ButtonType.CONSTRAINT_GROUPS))
            }
        });

        // Progression tab
        tabPanes.push({
            menuItem: _msg("EmployeeEditor.progression.label"),
            pane: {
                key: PROGRESSION_TAB_INDEX,
                content: this.renderTabContent(this.renderProgressionTab())
            }
        });

        // Premiums tab
        if (this.isAuxiliarTabVisibleForEmployee()) {
            tabPanes.push({
                menuItem: _msg("EmployeeEditor.premiums.label"),
                pane: {
                    key: PREMIUM_TAB_INDEX,
                    content: this.renderTabContent(this.renderPremiumsTab(), this.refPremiumTab.current?.renderSaveBarAdditionalContent())
                }
            });
        }

        return tabPanes;
    }

    protected isAuxiliarTabVisibleForEmployee() {
        if (this.props.employee) {
            let currentValue = fieldEditors[HISTORY_FIELD_TYPE].retrieveCurrentHistoryItem(this.props.employee.detail?.categoryHistory, this.getEmployeeReferenceDate());
            if (currentValue && currentValue.category.code === ProteusConstants.PILOTS_CODE) {
                return true;
            }
        }
        return false;
    }

    protected shouldLoadLeaveDistributionBalance() {
        return this.props.balanceTab.selectedBalance.code === ProteusConstants.LEAVE_TYPE_CODE;
    }

    protected onTabPaneChange = async (activeTabKey: any) => {
        if (activeTabKey === PREMIUM_TAB_INDEX && !this.props.premiumTab.premiumData) {
            this.props.dispatchers.premiumTab.loadPremiumData(this.props.employee?.id, new Date(this.props.premiumTab.premiumDatePanel.fromDate), new Date(this.props.premiumTab.premiumDatePanel.untilDate));
        } else if (activeTabKey === PROGRESSION_TAB_INDEX && !this.props.progressionTab.premiumHistory) {
            this.props.dispatchers.progressionTab.loadProgressionData(this.props.employee?.id);
        } else if (activeTabKey === CREDIT_TAB_INDEX && !this.props.creditTab.creditData) {
            this.props.dispatchers.creditTab.loadCreditData(this.props.employee?.id, this.props.creditTab.date);
        } else if (activeTabKey === EXTRA_TAB_INDEX && !this.props.extraTab.initialAccount) {
            this.props.dispatchers.extraTab.loadAccount(this.props.employee?.id);
        } else if (activeTabKey === CURRICULUM_TAB_INDEX) {
            if (this.refCurriculumTab.current?.getRegistrationTypeCategoryTreeRef()?.props.registrationTypeTree.length === 0) {
                await this.refCurriculumTab.current?.getRegistrationTypeCategoryTreeRef()?.loadRegistrationTypeTree(true, true, true, true, [], ProteusConstants.PLANNING_CURRICULUM, false);
            }
            if (this.refCurriculumTab.current?.getEntityTableLightRef().current?.getEntityTableSimpleCustomizedRef().current?.getEntities().length === 0) {
                if (this.refCurriculumTab.current?.props.appliedRegistrationTypes) {
                    this.refCurriculumTab.current?.getRegistrationSnapshots(this.props.employee?.id, this.refCurriculumTab.current?.props.appliedRegistrationTypes || []);
                }
            }
        } else if (activeTabKey === BALANCE_TAB_INDEX && !this.props.balanceTab.balanceCalculation.balanceData.length && this.props.employee?.id) {
            this.props.dispatchers.balanceTab.balanceCalculation.loadBalanceTabData(
                this.props.employee?.id!,
                this.props.balanceTab.selectedBalance?.code!,
                this.refBalanceTab.current?.shouldLoadLeaveDistributionBalance(this.props.balanceTab.selectedBalance?.code!)!
            );
        }

        this.props.dispatchers.setInReduxState({ activePageIndex: activeTabKey });
    }

    protected renderPersonalDetailsTab() {
        return this.props.employee && <PersonalDetailsTab {...this.props.personalDetailsTab} dispatchers={this.props.dispatchers.personalDetailsTab} employeeEditor={this}
            refPersonalDetailsEditor={this.refPersonalDetailsEditor} employee={this.props.employee} onOpenTableLight={this.onOpenTableLight} isEditable={this.isEditable()}
            resourceRosterRelations={this.props.resourceRosterRelations} resourceWorkRosters={this.props.resourceWorkRosters} referenceDate={this.getEmployeeReferenceDate()} />
    }

    protected renderExtraTab() {
        return this.props.employee && <ExtraTab {...this.props.extraTab} dispatchers={this.props.dispatchers.extraTab}
            employee={this.props.employee} onOpenTableLight={this.onOpenTableLight}
            refPasswordEditor={this.refPasswordEditor} isEditable={this.isEditable()}
            renderAdditionalFieldsInForm={this.renderAdditionalFieldsInForm} referenceDate={this.getEmployeeReferenceDate()} />
    }

    protected renderAddressTab() {
        return this.props.employee && <AddressTab {...this.props.addressTab} dispatchers={this.props.dispatchers.addressTab}
            employee={this.props.employee} onOpenTableLight={this.onOpenTableLight} ref={this.refAddressTab}
            isEditable={this.isEditable()} renderAdditionalFieldsInForm={this.renderAdditionalFieldsInForm} referenceDate={this.getEmployeeReferenceDate()} />
    }

    protected renderContractTab() {
        return this.props.employee && <ContractTab {...this.props.contractTab} dispatchers={this.props.dispatchers.contractTab}
            employee={this.props.employee} onOpenTableLight={this.onOpenTableLight} addHistoryFieldsToDescriptor={this.addHistoryFieldsToDescriptor}
            isEditable={this.isEditable()} selectedContractIndex={this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()} ref={this.refContractTab}
            updateContractHistoryTable={this.updateContractHistoryTable} referenceDate={this.getEmployeeReferenceDate()} />
    }

    protected renderCardTab() {
        return this.props.employee && <CardTab {...this.props.cardTab} dispatchers={this.props.dispatchers.cardTab} ref={this.refCardTab}
            employee={this.props.employee} onOpenTableLight={this.onOpenTableLight}
            isEditable={this.isEditable()} renderAdditionalFieldsInForm={this.renderAdditionalFieldsInForm}
            cardPaneActive={this.props.activePageIndex === CARD_TAB_INDEX} />
    }

    protected renderCurriculumTab() {
        return this.props.employee && <CurriculumTab {...this.props.curriculumTab} dispatchers={this.props.dispatchers.curriculumTab}
            employee={this.props.employee} ref={this.refCurriculumTab} renderAdditionalFieldsInForm={this.renderAdditionalFieldsInForm}
            getOperatingMode={this.props.getOperatingMode} loadRegistrationJson={this.props.loadRegistrationJson} isEditable={this.isEditable()} />
    }

    protected renderBalanceCounterTab() {
        return <BalanceCounterTab {...this.props.balanceCounterTab} dispatchers={this.props.dispatchers.balanceCounterTab}
            employee={this.props.employee} isEditable={this.isEditable()} balancePaneActive={this.props.activePageIndex === BALANCE_COUNTER_TAB_INDEX} />
    }

    protected renderBalanceTab() {
        return this.props.employee && <BalanceTab {...this.props.balanceTab} dispatchers={this.props.dispatchers.balanceTab} employee={this.props.employee}
            ref={this.refBalanceTab}
        />
    }

    protected renderCreditTab() {
        return this.props.employee && <CreditTab {...this.props.creditTab} dispatchers={this.props.dispatchers.creditTab}
            employee={this.props.employee} ref={this.refCreditTab} />
    }

    protected renderFunctionTab() {
        return this.props.employee && <FunctionTab {...this.props.functionTab} dispatchers={this.props.dispatchers.functionTab}
            employee={this.props.employee} isEditable={this.isEditable()} ref={this.refFunctionsTab}
            onOpenTableLight={this.onOpenTableLight} onRemoveCustomHistoryField={this.onRemoveCustomHistoryField} referenceDate={this.getEmployeeReferenceDate()} />
    }

    protected renderConstraintGroupsTab() {
        return this.props.employee && <ConstraintGroupTab {...this.props.constraintGroupTab} dispatchers={this.props.dispatchers.constraintGroupTab}
            employee={this.props.employee} isEditable={this.isEditable()} onOpenTableLight={this.onOpenTableLight}
            onRemoveCustomHistoryField={this.onRemoveCustomHistoryField} ref={this.refConstraintGroupsTab} referenceDate={this.getEmployeeReferenceDate()} />
    }

    protected renderPremiumsTab() {
        return <PremiumTab {...this.props.premiumTab} dispatchers={this.props.dispatchers.premiumTab} employee={this.props.employee}
            ref={this.refPremiumTab} referenceDate={this.getEmployeeReferenceDate()} />
    }

    protected renderProgressionTab() {
        return this.props.employee && <ProgressionTab {...this.props.progressionTab} dispatchers={this.props.dispatchers.progressionTab}
            employee={this.props.employee} isEditable={this.isEditable()} onOpenTableLight={this.onOpenTableLight} ref={this.refProgressionTab}
            refProgressionTab={this.refProgressionTabCustomized} setFormValuesInState={this.setFormValuesInState} referenceDate={this.getEmployeeReferenceDate()} />
    }

    /**
    * Meant to be overridden
    */
    protected renderTabContent(content: any, additionalContentForSaveSegment?: JSX.Element, additionalClassName?: string) {
        return content;
    }

    protected renderAdditionalContentForSaveSegment() {
        if (this.props.activePageIndex === CURRICULUM_TAB_INDEX) {
            return this.refCurriculumTab.current?.renderSaveBarAdditionalContent();
        } else if (this.props.activePageIndex === CREDIT_TAB_INDEX) {
            return this.refCreditTab.current?.renderSaveBarAdditionalContent();
        } else if (this.props.activePageIndex === FUNCTIONS_TAB_INDEX) {
            return this.renderAddFunctionsOrConstraintsButton(ButtonType.FUNCTION);
        } else if (this.props.activePageIndex === CONSTRAINT_GROUPS_TAB_INDEX) {
            return this.renderAddFunctionsOrConstraintsButton(ButtonType.CONSTRAINT_GROUPS);
        } else if (this.props.activePageIndex === PREMIUM_TAB_INDEX) {
            return this.refPremiumTab.current?.renderSaveBarAdditionalContent();
        } else if (this.props.activePageIndex === BALANCE_TAB_INDEX) {
            return this.refBalanceTab.current?.renderSaveBarAdditionalContent();
        }
        return null;
    }

    protected renderTabs() {
        return <Tab panes={this.renderTabPanes()} menu={{ secondary: true, pointing: true }} renderActiveOnly={false} activeIndex={this.props.activePageIndex}
            className={"EmployeeEditor_tab" + (!this.isEditable() ? " EmployeeEditorTab_disabled" : "")} onTabChange={(e: any, data: any) => this.onTabPaneChange(data.panes[data.activeIndex].pane.key)} />
    }

    ////////////////////////////////////
    // METHODS USED BY EMPLOYEE EDITOR - GENERAL USE
    ////////////////////////////////////

    protected getEmployeeValueFromForm() {
        let detail = this.refPersonalDetailsEditor.current?.formikContext?.values.employee.detail;
        let employeesFunctions = [...detail.functionHistory];
        let constraintGroups = [...detail.constraintGroupHistory];
        // Filter null value that may be present if user pressed "Add function" and not completed the history table.
        employeesFunctions = employeesFunctions.filter((item: getEmployee_employeeService_employee_detail_functionHistory | null) => item !== null);
        constraintGroups = constraintGroups.filter((item: getEmployee_employeeService_employee_detail_constraintGroupHistory | null) => item !== null);
        const pilotNumber = this.getProgressionValuesFromForm()?.employee?.detail?.pilotNumber;
        let employee = {
            ...this.refPersonalDetailsEditor.current?.formikContext?.values.employee,
            telecomList: this.refAddressTab.current?.getEntityTableLightRef().current?.getEntityTableSimpleCustomizedRef().current?.getEntities(),
            detail: {
                ...detail,
                cards: this.refCardTab.current?.getEntityTableLightRef().current?.getEntityTableSimpleCustomizedRef().current?.getEntities(),
                functionHistory: employeesFunctions,
                constraintGroupHistory: constraintGroups,
                pilotNumber: pilotNumber ? parseInt(pilotNumber) : pilotNumber
            }
        };
        return employee;
    }

    protected getResourceRosterRelationValuesFromForm() {
        return this.refPersonalDetailsEditor.current?.formikContext?.values.rosterRelations;
    }

    protected getResourceWorkRostersValuesFromForm() {
        return this.refPersonalDetailsEditor.current?.formikContext?.values.resourceWorkRosters;
    }

    protected getProgressionValuesFromForm() {
        return this.refProgressionTabCustomized.current?.formikContext?.values;
    }

    protected renderAddFunctionsOrConstraintsButton(type: ButtonType) {
        let historyFieldName = type === ButtonType.FUNCTION ? FUNCTION_HISTORY_FIELD : CONSTRAINT_GROUP_HISTORY_FIELD;
        return <>
            <div key="div1" className="FunctionsTab_barDivider" />
            <Button icon="plus" disabled={!this.isEditable() || !this.props.employee} color="green" onClick={() => {
                let employee = this.getEmployeeValueFromForm();
                let values = [...employee.detail[historyFieldName]];
                // Add a new empty field for functions/constraintGroups only if one is not already present.
                const newFieldExists = values.filter((item: any) => item == null).length > 0;
                if (!newFieldExists) {
                    values.push(null);
                    this.props.dispatchers.setInReduxState({ employee: { ...employee, detail: { ...employee.detail, [historyFieldName]: values } } });
                }
                const fieldDescriptor = type === ButtonType.FUNCTION ? this.refFunctionsTab.current?.getFunctionsEntityDescriptor().getField("employee.detail.functionHistory") :
                    this.refConstraintGroupsTab.current?.getConstraintGroupsEntityDescriptor().getField("employee.detail.constraintGroupHistory");
                const openFunction = type === ButtonType.FUNCTION ? this.refFunctionsTab.current?.onOpenCustomHistoryTable :
                    this.refConstraintGroupsTab.current?.onOpenCustomHistoryTable;
                openFunction?.(fieldDescriptor!, [], -1);
            }}>
            </Button>
        </>
    }

    protected setFormValuesInState = () => {
        this.props.dispatchers.setInReduxState({ employee: this.getEmployeeValueFromForm() });
    }

    async saveAndReloadEmployee(errorPolicy: string) {
        if (this.props.employee?.__typename === PERSON) {
            Utils.showGlobalAlert({ message: _msg("EmployeeEditorBase.savePerson.label"), title: _msg("general.error"), severity: Severity.INFO });
            return;
        }
        const employee = this.getEmployeeValueFromForm();
        if (!employee) {
            return;
        }

        const passwordEditor: PasswordEditor = this.refPasswordEditor.current!;
        if (this.props.extraTab.editPasswordEnabled) {
            await passwordEditor.submitForm();
            if (passwordEditor.hasErrors()) {
                this.props.dispatchers.setInReduxState({
                    showModal: {
                        open: true,
                        message: this.props.extraTab.account?.id ? _msg("EmployeeEditor.invalidPassword.label") : _msg("EmployeeEditor.invalidNewPassword.label"),
                        operation: ModalOperations.INVALID_PASSWORD,
                        showNoButton: false,
                        newEmployee: undefined
                    }
                });
                return;
            }
        }

        if (this.props.extraTab.shouldRemoveAccount) {
            await this.props.dispatchers.extraTab.removeAccount();
        }
        if (this.props.extraTab.shouldSaveAccount || this.props.extraTab.editPasswordEnabled) {
            await this.props.dispatchers.extraTab.saveAndReloadAccount(this.refPasswordEditor.current);
        }
        await this.props.dispatchers.saveEmployee(employee, this.getResourceRosterRelationValuesFromForm(), this.getResourceWorkRostersValuesFromForm(), errorPolicy);
        this.loadEmployee(employee.id, this.getEmployeeReferenceDate());
        this.props.dispatchers.loadResourceRosterRelations(employee.id);
        this.props.dispatchers.loadResourceWorkRosters(employee.id);
        if (this.props.progressionTab.shouldSavePremiumHistory) {
            this.props.dispatchers.progressionTab.savePremiumHistory(this.props.employee?.id);
        }

        if (this.props.progressionTab.shouldSavePremiumSuspensionHistory) {
            this.props.dispatchers.progressionTab.savePremiumSuspensionHistory(this.props.employee?.id);
        }
    }

    /**
     * Meant to be overridden
     */
    protected isEditable() {
        return true;
    }

    /**
     * Meant to be overridden
     */
    protected getEmployeeReferenceDate(): Date | number {
        return new Date();
    }

    /**
     * Meant to be overridden
     */
    protected getDateFromPlanning(): Date {
        return new Date();
    }

    protected async handleModalConfirmIgnoreException() {
        await this.saveAndReloadEmployee(ProteusConstants.IGNORE_WARNINGS);
    }

    protected async handleModalRefresh() {
        // This set is necessary because some changes to employee (like 'name') does not automatically trigger
        // changes in employee state property. When the employee is reloaded from the server, if
        // it is the same as it was before in the state, it does not trigger any re-render, so the form will not reload
        this.props.dispatchers.setInReduxState({ employee: this.getEmployeeValueFromForm() });
        await this.loadAdditionalEmployeeData(this.props.employee?.id, true);
    }

    protected async handleOnChangeEmployee() {
        await this.loadAdditionalEmployeeData(this.props.showModal?.newEmployee, true);
    }

    protected async handleOnClickOkModal() {
        this.props.dispatchers.setInReduxState({ showModal: { ...this.props.showModal, open: false } });
        if (this.props.showModal?.operation === ModalOperations.CONFIRM_IGNORE_EXCEPTIONS) {
            this.handleModalConfirmIgnoreException();
        } else if (this.props.showModal?.operation === ModalOperations.CHANGE_EMPLOYEE) {
            this.handleOnChangeEmployee();
        } else if (this.props.showModal?.operation === ModalOperations.REFRESH) {
            this.handleModalRefresh();
        }
        this.props.dispatchers.setInReduxState({ showModal: undefined });
    }

    protected showModalInfoMessage() {
        return (
            <ModalExt onClose={() => this.props.dispatchers.setInReduxState({ showModal: undefined })}
                open={this.props.showModal?.open === true} size='small' severity={Severity.WARNING}>
                <Modal.Header>
                    {_msg("warning.label")}
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Interweave content={this.props.showModal?.message} />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    {this.props.showModal?.showNoButton === true &&
                        <Button key="cancel"
                            onClick={() => {
                                this.props.dispatchers.setInReduxState({ showModal: undefined });
                            }}>
                            {_msg("general.cancel")}
                        </Button>
                    }
                    <Button primary key="ok" onClick={this.handleOnClickOkModal}>
                        {_msg("general.ok")}
                    </Button>
                </Modal.Actions>
            </ModalExt>
        );
    }

    /**
     * This method should be used when additional fields are rendered in a tab, besides the ones from the EntityFormSimple.
     * It adds necessary css classes to the fields, so that all the fields from the form will look the same in web/mobile version.
     */
    protected renderAdditionalFieldsInForm = (additionalFields: JSX.Element) => {
        return (
            <Grid className="EntityEditorPage_grid" stackable columns='equal'>
                <Grid.Row className="EntityEditorPage_grid_row" verticalAlign="middle">
                    <Grid.Column className="EntityEditorPage_grid_row_column">
                        {additionalFields}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    async loadAdditionalEmployeeData(employeeId: number | undefined | null, loadEmployee: boolean) {
        if (loadEmployee) {
            this.loadEmployee(employeeId, this.getEmployeeReferenceDate());
        }
        this.props.dispatchers.loadResourceRosterRelations(employeeId);
        this.props.dispatchers.loadResourceWorkRosters(employeeId);
        this.props.dispatchers.extraTab.loadAccount(this.props.activePageIndex === EXTRA_TAB_INDEX ? employeeId : undefined);
        this.props.dispatchers.premiumTab.loadPremiumData(this.props.activePageIndex === PREMIUM_TAB_INDEX && this.isAuxiliarTabVisibleForEmployee() ? employeeId : undefined, new Date(this.props.premiumTab.premiumDatePanel.fromDate), new Date(this.props.premiumTab.premiumDatePanel.untilDate));
        this.props.dispatchers.progressionTab.loadProgressionData(this.props.activePageIndex === PROGRESSION_TAB_INDEX ? employeeId : undefined);
        this.props.dispatchers.creditTab.loadCreditData(this.props.activePageIndex === CREDIT_TAB_INDEX ? employeeId : undefined, this.props.creditTab.date);
        this.refCurriculumTab.current?.getRegistrationSnapshots(this.props.activePageIndex === CURRICULUM_TAB_INDEX ? employeeId : undefined, this.refCurriculumTab.current?.props.appliedRegistrationTypes!);
        this.props.dispatchers.balanceTab.balanceCalculation.loadBalanceTabData(
            this.props.activePageIndex === BALANCE_TAB_INDEX ? employeeId : undefined,
            this.props.balanceTab.selectedBalance?.code!,
            this.refBalanceTab.current?.shouldLoadLeaveDistributionBalance(this.props.balanceTab.defaultBalance?.code!)!
        );
    }

    public isDirty(): boolean | undefined {
        if (this.props.employee) {
            const employeeDirty = !lodash.isEqual(this.getEmployeeValueFromForm(), this.props.employeeLoaded)
            const resourceRosterRelationsDirty = !lodash.isEqual(this.getResourceRosterRelationValuesFromForm(), this.props.resourceRosterRelationsLoaded);
            const resourceWorkRostersDirty = !lodash.isEqual(this.getResourceWorkRostersValuesFromForm(), this.props.resourceWorkRostersLoaded);
            const extraTabAccountDirty = !lodash.isEqual(this.props.extraTab.account, this.props.extraTab.initialAccount);
            const premiumScaleDirty = !lodash.isEqual(this.props.progressionTab.premiumHistory, this.props.progressionTab.premiumHistoryLoaded);
            return employeeDirty || resourceRosterRelationsDirty || extraTabAccountDirty || premiumScaleDirty || resourceWorkRostersDirty;
        }
        return false;
    }

    onHistoryTabelSelectItem(itemId: any) {
        const selectedContract = this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[itemId];
        this.refContractTab.current?.getHistoryTableRef().current?.open(selectedContract?.suspensionHistory || []);
        this.props.dispatchers.contractTab.contractSuspensionTable.setInReduxState({ entities: selectedContract?.suspensionHistory || [] });
    }

    render() {
        return (
            <div className="PlanningPage_employeeExplorerDrawer flex">
                {this.renderTabs()}
                {this.renderHistoryTableLight()}
                {this.showModalInfoMessage()}
            </div>
        );
    }

    async componentDidMount() {
        await this.props.dispatchers.balanceTab.getRegistrationTypesWithUseBalanceCounter();
        this.props.dispatchers.balanceTab.balanceCalculation.setInReduxState({ balanceToDate: moment(this.getDateFromPlanning()).endOf('year').toDate() });
    }

    componentWillUnmount() {
        this.props.dispatchers.setInReduxState({
            activePageIndex: 0,
            resourceRosterRelations: undefined,
            resourceWorkRosters: undefined,
            employee: undefined
        });
        this.props.dispatchers.curriculumTab.setInReduxState({ firstLoad: true });
    }
}