'use strict';

var timePeriodEditor = {
	template: require('./time-period-editor.html'),
	bindings: {
		entity: '<'
	},
	controllerAs: 'timePeriodEditor',
	controller: /*@ngInject*/ function($scope, $filter, Education, DATE_TIME_FORMAT) {
		var timePeriodEditor = this;

		timePeriodEditor.catalogs = {
						visible: ['COMPONENT'],
						editable: false,
						autofocusFilter: true
					}

		timePeriodEditor.onResourceChange = function(resource) {
			timePeriodEditor.entity.timePeriod.trainer = resource;
			if (resource && (resource.objectType == 'EmployeeSnapshot' || resource.objectType == 'ContactPersonSnapshot')) {
				timePeriodEditor.entity.timePeriod.trainingCenter = resource.company;
			}
			if (resource == null) {
				timePeriodEditor.entity.timePeriod.trainerSocialHoursBalance = null;
			}
		}

		timePeriodEditor.onCompanyChange = function(company) {
			timePeriodEditor.entity.timePeriod.trainingCenter = company;
			if (company && company.objectType == 'Company' && timePeriodEditor.entity.timePeriod.trainer && company != timePeriodEditor.entity.timePeriod.trainer.company) {
				timePeriodEditor.entity.timePeriod.trainer = null;
			}
		}

		timePeriodEditor.onComponentVersionChange = function(catalog) {
			// For "Certificates" component type, the default selected trainer should be the logged in user.
			if (catalog.element.type.code == 'CERT' && !timePeriodEditor.entity.timePeriod.trainer && !timePeriodEditor.entity.timePeriod.trainingCenter) {
				timePeriodEditor.onResourceChange(window.getSecurityContext().resource);
			}
		}

		var that = this;
		timePeriodEditor.$onInit = function() {
			if (timePeriodEditor.entity.timePeriod) {
				if (!timePeriodEditor.entity.timePeriod.trainingComponentVersion && timePeriodEditor.catalogItemObjective) {
					if (timePeriodEditor.catalogItemObjective.component) {
						timePeriodEditor.entity.timePeriod.trainingComponentVersion = timePeriodEditor.catalogItemObjective.component.currentComponentVersion;
					} else if (timePeriodEditor.catalogItemObjective.objective && timePeriodEditor.catalogItemObjective.objective.track) {
						timePeriodEditor.entity.timePeriod.trainingComponentVersion = timePeriodEditor.catalogItemObjective.objective.track.currentTrackVersion;
					} else if (timePeriodEditor.catalogItemObjective.objective && timePeriodEditor.catalogItemObjective.objective.module) {
						timePeriodEditor.entity.timePeriod.trainingComponentVersion = timePeriodEditor.catalogItemObjective.objective.module.currentModuleVersion;
					}
				}
				timePeriodEditor.catalogsType = ['COMPONENT'];

				if (!timePeriodEditor.entity.timePeriod.fromDate) {
					var currentDate = new Date();
					currentDate.setHours(0,0,0,0);
					timePeriodEditor.entity.timePeriod.fromDate = currentDate;
				} else {
					timePeriodEditor.entity.timePeriod.fromDate = new Date(timePeriodEditor.entity.timePeriod.fromDate);
				}

				if (!timePeriodEditor.entity.timePeriod.toDate) {
					timePeriodEditor.entity.timePeriod.toDate = new Date(timePeriodEditor.entity.timePeriod.fromDate);
					timePeriodEditor.entity.timePeriod.toDate.setHours(23,59,0,0);
				} else {
					timePeriodEditor.entity.timePeriod.toDate = new Date(timePeriodEditor.entity.timePeriod.toDate);
				}

				if (timePeriodEditor.entity.timePeriod.trainer) {
					timePeriodEditor.onResourceChange(timePeriodEditor.entity.timePeriod.trainer);
				}

				if (timePeriodEditor.entity.timePeriod.trainerRegistration && timePeriodEditor.entity.timePeriod.trainerRegistration.detail) {
					timePeriodEditor.entity.timePeriod.trainerSocialHoursBalance = timePeriodEditor.entity.timePeriod.trainerRegistration.detail.socialHoursBalance;
				}
			}

			timePeriodEditor.currentDate = new Date();
			timePeriodEditor.currentDate.setHours(0,0,0,0);
			
			if (timePeriodEditor.entity.newEntity) {
				return;
			}

			timePeriodEditor.entity.realization = {};
			timePeriodEditor.entity.realization.editRealizations = false;

			// model in entity: {timePeriod : {realization (for children), participantRegistration: {detail: {realization (for the current participant)}}}
			if (timePeriodEditor.entity.editAllRealizations) {
				// initialize realization when editing all the realizations
				let timePeriodDate = new Date(timePeriodEditor.entity.timePeriod.fromDate);
				timePeriodDate.setHours(0,0,0,0);
				timePeriodEditor.entity.realization = angular.extend({}, timePeriodEditor.entity.timePeriod.realization);
				timePeriodEditor.entity.realization.date = timePeriodEditor.entity.realization.date ? timePeriodEditor.entity.realization.date : timePeriodDate;
			}

			if (!timePeriodEditor.entity.editAllRealizations && timePeriodEditor.entity.participantRegistration && timePeriodEditor.entity.participantRegistration.detail && timePeriodEditor.entity.participantRegistration.detail.realization) {
				// initialize realization for the current participant
				timePeriodEditor.entity.realization = angular.extend({}, timePeriodEditor.entity.participantRegistration.detail.realization);
			}

			if (!timePeriodEditor.entity.editAllRealizations) {
				var realizations = {
					data: timePeriodEditor.entity.realizations,
					labelFn: timePeriodEditor.getRealizationAsString,
					style: {
						"dropdown-menu-left": true
					}
				};
				var realization = timePeriodEditor.entity.realization;

				// no result => select default status
				if (!realizations.selection && !realization) {
					realization = $filter('filter')(realizations.data, {componentVersion: null}, true)[0];
				}

				realizations.selection = realization;
				realizations.onSelect = onSelectRealization;

				timePeriodEditor.realizations = realizations;
			}

			if (timePeriodEditor.entity.participantRegistration && !timePeriodEditor.entity.editAllRealizations) {
				timePeriodEditor.entity.realization.date = timePeriodEditor.entity.participantRegistration.detail.realization ? timePeriodEditor.entity.participantRegistration.detail.realization.date : null;
				timePeriodEditor.entity.realization.dueDate = timePeriodEditor.entity.participantRegistration.detail.realization ? timePeriodEditor.entity.participantRegistration.detail.realization.dueDate : null;
			}

			var realizationStatuses = {
				data: timePeriodEditor.entity.realizationStatuses ? timePeriodEditor.entity.realizationStatuses : [],
				labelField: 'description',
				style: {
					"dropdown-menu-left": true
				}
			};
			var realizationResult;
			if (timePeriodEditor.entity.realization) {
				realizationResult = timePeriodEditor.entity.realization.result;
			}

			// no result => select default status
			if (!realizationStatuses.selection && !realizationResult) {
				realizationResult = $filter('filter')(realizationStatuses.data, {code: null}, true)[0];
			}

			realizationStatuses.selection = realizationResult;
			realizationStatuses.onSelect = onSelectRealizationResult;

			timePeriodEditor.realizationStatuses = realizationStatuses;
		};

		function onSelectRealizationResult(data) {
			var status = data.code ? data : null;
			timePeriodEditor.entity.realization.result = status;
		}

		function onSelectRealization(data) {
			if (!data.componentVersion) {
				let realizationDate = new Date(timePeriodEditor.entity.timePeriod.fromDate);
				realizationDate.setHours(0,0,0,0);
				data.date = realizationDate;
			}

			if (timePeriodEditor.realizationStatuses && timePeriodEditor.realizationStatuses.data) {
				if (data.result) {
					timePeriodEditor.realizationStatuses.selection = data.result;
				} else {
					timePeriodEditor.realizationStatuses.selection = $filter('filter')(timePeriodEditor.realizationStatuses.data, {code: null}, true)[0];
				}
			}
			timePeriodEditor.entity.realization = data;
			timePeriodEditor.entity.realization.date = timePeriodEditor.entity.realization.date ? new Date(timePeriodEditor.entity.realization.date) : null;
			timePeriodEditor.entity.realization.dueDate = timePeriodEditor.entity.realization.dueDate ? new Date(timePeriodEditor.entity.realization.dueDate) : null;
		}

		timePeriodEditor.getRealizationAsString = function(realization) {
			if (!realization.componentVersion) {
				return '(Nieuw)';
			}

			var realizationAsString = 'Datum: ';
			var realizationDate = $filter('date')(realization.date, DATE_TIME_FORMAT);
			var realizationDueDate = realization.dueDate ? $filter('date')(realization.dueDate, DATE_TIME_FORMAT): '-';
			return realizationAsString + realizationDate + ' Vervaldatum: ' + realizationDueDate;
		}

		timePeriodEditor.onChangeDueDate = function(date) {
			if (date) {
				date.setHours(23,59,0,0);
			}
			timePeriodEditor.entity.realization.dueDate = date;
		}

		timePeriodEditor.editRealizations = function() {
			timePeriodEditor.entity.realization.editRealizations = true;
		}
	}
};

module.exports = timePeriodEditor;