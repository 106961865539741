import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldEditorProps, fieldEditors } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { isEmpty } from "lodash";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { ADD, CrudEditorPageRenderHeaderParams, EntityEditorPage, EntityEditorPageProps, SaveParams, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { apolloClient, ColumnDefinition, EntityTablePage, EntityTablePageProps } from "@crispico/foundation-react";
import { SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, Utils } from "@crispico/foundation-react";
import React from "react";
import { Checkbox, Dropdown, Form, Grid, Icon, Input, Menu, Segment } from "semantic-ui-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { push } from "connected-react-router";
import { FileUploadButton, sliceFileUploadButton } from "@crispico/foundation-react/components/fileUploadButton/FileUploadButton";
import { UploadFile } from "antd/lib/upload/interface";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { ProteusConstants } from "ProteusConstants";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { BALANCE_SERVICE_FACADE_BEAN_FIND_ALL_BALANCE_TYPES, BALANCE_COUNTER_HISTORY_ITEM_DAO_IMPL_FIND_COUNTER_TYPES, BALANCE_COUNTER_HISTORY_ITEM_DAO_IMPL_FIND_COUNTER_TYPES_BY_BALANCE, BALANCE_COUNTER_HISTORY_ITEM_SERVICE_GET_SEQUENCE_VALUES, BALANCE_COUNTER_HISTORY_ITEM_SERVICE_LOAD_AVAILABLE_YEARS_FOR_PERSON, BALANCE_COUNTER_HISTORY_ITEM_SERVICE_SAVE_BALANCE_COUNTER_HISTORY_ITEM, BALANCE_COUNTER_HISTORY_ITEM_SERVICE_UPLOAD_BALANCE_COUNTERS, EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CONTRACT, PERSON_SERVICE_FIND_BY_ID, PLANNING_SERVICE_ENDPOINT_GET_REGISTRATION_TYPES_BY_LAYOUT, BALANCE_COUNTER_HISTORY_ITEM_DAO_IMPL_FIND_BALANCE_TYPES } from "graphql/queries";
import { PopupWithHelpTooltip } from "@crispico/foundation-react/components/semanticUiReactExt";
import { Link } from "react-router-dom";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { FieldEditor, FieldEditorNotUsableStandAloneProps } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";

const URL_SEPARATOR = ".";

/**
 * This component is used in 2 modes:
 * 1. Stand-alone CRUD screen (embeddedMode = false)
 * 2. Embedded CRUD screen in EmployeeEditor - Planning/CompanyResources (embeddedMode = true)
 * The stand-alone mode should be removed in the future, after the client confirms that the embedded mode is OK for the users.
 */
export class BalanceCounterHistoryItemEntityDescriptor extends EntityDescriptor {

    getGraphQlFieldsToRequest() {
        return "person validFrom validUntil remark value workPeriodType balanceType " +
            "counterType { id code description creationDate creationUser modificationDate modificationUser objectVersion } " +
            "id objectVersion creationDate creationUser modificationDate modificationUser "
    }

    toMiniString(entityWithMiniFields: any) {
        if (entityWithMiniFields["employeeName"]) {
            return entityWithMiniFields["employeeName"]?.name + " " + entityWithMiniFields["employeeName"]?.firstName;
        } else {
            return this.getLabel();
        }
    }

    protected customize() {
        const balanceCounterHistoryItemEntityDescriptor = this;
        balanceCounterHistoryItemEntityDescriptor.addFieldDescriptor({ name: "sequence", type: FieldType.number, filterable: false, clientOnly: true })

        interface BasicFields {
            id: number,
            code: string,
            description: string,
            objectVersion?: number
        }

        interface Entity {
            id: number,
            person: number,
            remark: string,
            sequence?: number,
            validFrom: Date,
            validUntil: Date,
            value: number,
            workPeriodType: string,
            balanceType: string,
            counterType: BasicFields
        }

        interface Options {
            key: string | number,
            text: string,
            value: string | number,
            code?: string,
            id?: number,
            objectversion?: number
        }

        interface EmployeeSnapshot {
            objectType: string,
            contractHistoryItem: {
                objectType: string,
                status: {
                    objectType: string,
                    code: string
                }
            }
        }

        interface StateEmployee {
            id: number | undefined,
            text: string | undefined,
            contractCode: string | undefined
        }

        const sliceBalanceCounterTable = balanceCounterHistoryItemEntityDescriptor.infoTable.slice = createSliceFoundation(class SliceBalanceCounterTable extends SliceEntityTablePage {

            nestedSlices = {
                ...sliceEntityTablePageOnlyForExtension.nestedSlices,
                fileUploadButton: sliceFileUploadButton
            }

            initialState = {
                ...sliceEntityTablePageOnlyForExtension.initialState,
                employeesOptions: [] as Options[],
                balanceTypesOptions: [] as Options[],
                counterTypesOptions: [] as Options[],
                workPeriodType: [] as { code: string, name: string }[],
                yearsOptions: [] as Options[],
                balanceTypeCode: {} as BasicFields,
                counterTypeCode: {} as BasicFields,
                fromDate: undefined as unknown as number | string,
                toDate: undefined as unknown as number | string,
                employee: { id: undefined, text: "", contractCode: "" } as StateEmployee,
                sequence: {} as { [key: string]: number },
                uploadYear: (new Date().getFullYear() + 1) as number | string,
                counterTypes: [] as BasicFields[]
            }

            reducers = {
                ...sliceEntityTablePageOnlyForExtension.reducers,
                ...getBaseReducers<SliceBalanceCounterTable>(this)
            }

            impures = {
                ...sliceEntityTablePageOnlyForExtension.impures,
                ...getBaseImpures<SliceBalanceCounterTable>(this),

                /**
                * Filter by person, balanceType, counterType and fromDate
                */
                adjustFilterBeforeLoad(filter: Filter): Filter {
                    if (this.getState().employee?.id === undefined) {
                        filter.filters?.push({ field: ProteusConstants.PERSON, operator: "equals", value: "-1" });
                    } else {
                        filter.filters?.push({ field: ProteusConstants.PERSON, operator: "equals", value: this.getState().employee?.id ? String(this.getState().employee.id) : "" });
                    }
                    if (this.getState().balanceTypeCode?.description) {
                        filter.filters?.push({ field: "balanceType", operator: "equals", value: this.getState().balanceTypeCode.code });
                    }
                    if (this.getState().counterTypeCode?.description) {
                        filter.filters?.push({ field: "counterType.description", operator: "equals", value: this.getState().counterTypeCode.description });
                    }
                    if (this.getState().fromDate) {
                        filter.filters?.push({ field: ProteusConstants.VALID_FROM, operator: "greaterThanOrEqualTo", value: (Number(this.getState().fromDate) - 1) + "-12-31T22:00:00Z" });
                    }
                    if (this.getState().toDate) {
                        filter.filters?.push({ field: ProteusConstants.VALID_FROM, operator: "lessThanOrEqualTo", value: this.getState().toDate + "-12-31T21:59:00Z" });
                    }
                    return filter;
                },

                /**
                * Sort by validFrom desc, balanceType asc and counterType acs
                * Don't allow sort by sequence (custom column, not part of the entity)
                */
                adjustSortsBeforeLoad(sorts: Sort[]): Sort[] {
                    return sorts.concat(Object.assign([{ field: ProteusConstants.VALID_FROM, direction: "DESC" }, { field: ProteusConstants.BALANCE_TYPE, direction: "ASC" },
                    { field: ProteusConstants.COUNTER_TYPE, direction: "ASC" }])).filter(item => item.field !== ProteusConstants.SEQUENCE && item.field !== ProteusConstants.ID);
                },

                async getSequenceValues() {
                    return (await apolloClient.query({
                        query: BALANCE_COUNTER_HISTORY_ITEM_SERVICE_GET_SEQUENCE_VALUES
                    })).data.balanceCounterHistoryItemService_sequenceValues;
                },

                async searchEmployee(text: string, fromDate: Date, untilDate: Date) {
                    const result = (await apolloClient.query({
                        query: EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CONTRACT,
                        variables: {
                            searchText: text,
                            fromDate: fromDate,
                            untilDate: untilDate,
                            includeAllWithValidContract: true
                        }
                    })).data.employeeService_employeeSnapshots;
                    if (result === undefined || result === null) {
                        return;
                    }
                    this.getDispatchers().setInReduxState({
                        employeesOptions: result.map((item: { id: number; name: string; firstName: string; contractHistoryItem: { employeeNumber: string, status: { code: string; }; }; }) => ({
                            key: item?.id as number,
                            text: ((item?.name) as string).concat(" ", item?.firstName as string, (item?.contractHistoryItem?.employeeNumber ? " (" + item?.contractHistoryItem?.employeeNumber + ")" : "") as string),
                            value: item?.id as number,
                            code: item?.contractHistoryItem?.status?.code as string
                        }))
                    });
                },

                async getCounterTypes(balanceTypeCode?: string) {
                    let counterTypes: BasicFields[] = [];

                    const balanceTypes = (await apolloClient.query({
                        query: BALANCE_COUNTER_HISTORY_ITEM_DAO_IMPL_FIND_BALANCE_TYPES,
                    })).data.balanceCounterHistoryItemDaoImpl_findBalanceTypes.map((balanceType: BasicFields) => balanceType.code);
                    /* For types not in BalanceType table, only INITIAL counter is available. Leave type will have restitution counter too. Becuase we have only these 2 options, we don't
                        need to make a request for them */
                    if (balanceTypeCode && !balanceTypes.includes(balanceTypeCode)) {
                        const initial = this.getState().counterTypes.find(ct => ct.code === "AANVANG");
                        initial && counterTypes.push(initial);
                        if (balanceTypeCode === ProteusConstants.LEAVE_TYPE_CODE) {
                            const restitution = this.getState().counterTypes.find(ct => ct.code === "TERUGGAVE");
                            restitution && counterTypes.push(restitution);
                        } else if (balanceTypeCode === ProteusConstants.LEAVE_HOURS_BALANCE_TYPE_CODE) {
                            const extraHours = this.getState().counterTypes.find(ct => ct.code === "EXTRA_HOURS");
                            extraHours && counterTypes.push(extraHours);
                        }
                    } else if (balanceTypeCode) {
                        counterTypes = (await apolloClient.query({
                            query: BALANCE_COUNTER_HISTORY_ITEM_DAO_IMPL_FIND_COUNTER_TYPES_BY_BALANCE,
                            variables: {
                                balanceTypeCode: balanceTypeCode
                            }
                        })).data.balanceCounterHistoryItemDaoImpl_findCounterTypes;
                    } else {
                        counterTypes = this.getState().counterTypes;
                    }
                    this.getDispatchers().setInReduxState({
                        counterTypesOptions: counterTypes.map((item: BasicFields) => ({
                            key: item.code as string,
                            text: item.description as string,
                            value: item.description as string,
                            id: item.id as number
                        }))
                    })
                },

                async getBalanceTypes() {
                    const balanceTypes = (await apolloClient.query({ query: BALANCE_SERVICE_FACADE_BEAN_FIND_ALL_BALANCE_TYPES })).data.balanceServiceFacadeBean_allBalanceTypes;
                    this.getDispatchers().setInReduxState({
                        balanceTypesOptions: balanceTypes.map((item: BasicFields) => ({
                            key: item.code as string,
                            text: item.description as string,
                            value: item.description as string,
                            id: item.id
                        }))
                    });
                },

                async getWorkPeriodType() {
                    // because workPeriodType does not depend on any other selectable parameter, get it only once.
                    if (this.getState().workPeriodType && this.getState().workPeriodType.length > 0) {
                        return;
                    }

                    const workPeriodType = (await apolloClient.query({ query: PLANNING_SERVICE_ENDPOINT_GET_REGISTRATION_TYPES_BY_LAYOUT, context: { showSpinner: false } })).data.planningServiceEndpoint_registrationTypes;
                    this.getDispatchers().setInReduxState({ workPeriodType });
                },

                async getAllCounterTypes() {
                    const counterTypes: BasicFields[] = (await apolloClient.query({ query: BALANCE_COUNTER_HISTORY_ITEM_DAO_IMPL_FIND_COUNTER_TYPES })).data.balanceCounterHistoryItemDaoImpl_findCounterTypes;
                    this.getDispatchers().setInReduxState({ counterTypes });
                }
            }
        }).setEntityDescriptor(balanceCounterHistoryItemEntityDescriptor);

        balanceCounterHistoryItemEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<PropsFrom<typeof sliceBalanceCounterTable> & EntityTablePageProps & { employee?: any }> {
            /**
            * For each entity, add sequence column and convert workPeriodType from code (as it is in DB) to name (as it should be shown in table)
            */
            adjustEntities(entities: Entity[]): Entity[] {
                entities = super.adjustEntities(entities);
                for (let i = 0; i < entities.length; i++) {
                    // Replace balanceType code with its description for table
                    const balanceType = this.props.balanceTypesOptions.filter((balanceType: any) => balanceType.key === entities[i].balanceType)[0];
                    if (balanceType) {
                        entities[i].balanceType = balanceType.text;
                    }
                    if (entities[i].balanceType === ProteusConstants.LEAVE_DISTRIBUTION || entities[i].balanceType === ProteusConstants.BRAXO_LEAVE_HOURS_DISTRIBUTION) {
                        if (entities[i].counterType.code === ProteusConstants.WETTELIJK) {
                            if (this.props.employee?.contractCode === ProteusConstants.ARBEIDER) {
                                entities[i] = { ...entities[i], sequence: this.props.sequence[ProteusConstants.VERLOF_WET2] };
                            } else {
                                entities[i] = { ...entities[i], sequence: this.props.sequence[ProteusConstants.VERLOF_WET1] };
                            }
                        } else {
                            for (let key in this.props.sequence) {
                                if (key === entities[i].counterType.code) {
                                    entities[i] = { ...entities[i], sequence: this.props.sequence[key] };
                                }
                            }
                        }
                    }

                    for (let j = 0; j < this.props.workPeriodType.length; j++) {
                        if (entities[i].workPeriodType === this.props.workPeriodType[j].code) {
                            entities[i] = { ...entities[i], workPeriodType: this.props.workPeriodType[j].name };
                            break;
                        }
                    }
                }
                return entities;
            }

            /**
            * For every counterType of "Verlof distributie", we save the code and the value in sequence array
            * We do this only for first load. For every employee selected we will look into sequence array and add the sequence column accordingly
            */
            async loadEntities(p: { startIndex: number, pageSize: number, countMode?: boolean }) {
                // Should not load anything if there is no employee
                if (!this.props.employee?.id) {
                    this.props.dispatchers.setInReduxState({ loaded: 0, totalCount: 0 });
                    return;
                }
                if (isEmpty(this.props.sequence)) {
                    const sequenceValue = await this.props.dispatchers.getSequenceValues();
                    this.props.dispatchers.setInReduxState({ sequence: sequenceValue });
                }
                await this.props.dispatchers.getWorkPeriodType();
                super.loadEntities(p);
            }

            renderTopHeader() {
                return (
                    <Segment className="BalanceCounterHistoryItem_inputsSegment">
                        <Grid columns={this.props.embeddedMode ? 4 : 5} doubling>
                            <Grid.Row>
                                {!this.props.embeddedMode &&
                                    <Grid.Column >
                                        <Form>
                                            <Form.Field>
                                                {_msg(ProteusConstants.PERSON + ".label")}
                                                <Dropdown selection search clearable selectOnNavigation={false} options={this.props.employeesOptions} value={this.props.employee?.id || ""}
                                                    placeholder={_msg(ProteusConstants.PERSON + ".name.label")} noResultsMessage={_msg("general.noResultsFound")} onSearchChange={(e, data) => {
                                                        this.props.dispatchers.searchEmployee(data.searchQuery as unknown as string, new Date(), new Date());
                                                    }}
                                                    onChange={async (e: any, data: any) => {
                                                        let employeeId: string | undefined;
                                                        let yearsOptions = [];
                                                        if (String(data.value).length >= 1) {
                                                            employeeId = data.value
                                                            const availableYears = (await apolloClient.query({
                                                                query: BALANCE_COUNTER_HISTORY_ITEM_SERVICE_LOAD_AVAILABLE_YEARS_FOR_PERSON,
                                                                variables: {
                                                                    person: employeeId
                                                                }
                                                            })).data.balanceCounterHistoryItemService_loadAvailableYearsForPerson;
                                                            yearsOptions = availableYears.map((item: string | number) => ({
                                                                key: item as number,
                                                                text: item as string,
                                                                value: item as number
                                                            }));
                                                        } else {
                                                            employeeId = undefined;
                                                        }
                                                        let selected = data.options.filter((item: { key: string; }) => item.key === employeeId)[0];
                                                        let employee = {
                                                            id: selected?.key,
                                                            text: selected?.text,
                                                            contractCode: selected?.code
                                                        }
                                                        this.props.dispatchers.setInReduxState({ employee, yearsOptions: yearsOptions, fromDate: "", toDate: "" });
                                                        this.refresh();
                                                    }} />
                                            </Form.Field>
                                        </Form>
                                    </Grid.Column>
                                }
                                <Grid.Column>
                                    <Form>
                                        <Form.Field>
                                            {_msg(ProteusConstants.BALANCE_TYPE + ".label")}
                                            <Dropdown selection search clearable selectOnNavigation={false} placeholder={_msg(ProteusConstants.BALANCE_TYPE + ".label")} options={this.props.balanceTypesOptions}
                                                value={this.props.balanceTypeCode.description} noResultsMessage={_msg("general.noResultsFound")}
                                                onChange={(e: any, data: any) => {
                                                    const selectedBalance = data.options.filter((item: { value: string; }) => item.value === data.value)[0];
                                                    this.props.dispatchers.setInReduxState({ balanceTypeCode: { description: data.value, id: selectedBalance?.id, code: selectedBalance?.key } });
                                                    if (data.value.length > 0) {
                                                        this.props.dispatchers.getCounterTypes(data.options.filter((item: { value: string }) => item.value === data.value)[0]?.key);
                                                        if (this.props.counterTypeCode?.description !== "") {
                                                            this.props.dispatchers.setInReduxState({ counterTypeCode: { description: "", id: 0, code: "" } });
                                                        }
                                                    } else {
                                                        this.props.dispatchers.getBalanceTypes();
                                                        this.props.dispatchers.getCounterTypes();
                                                    }
                                                    this.refresh();
                                                }} />
                                        </Form.Field>
                                    </Form>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form>
                                        <Form.Field>
                                            {_msg(ProteusConstants.COUNTER_TYPE + ".label")}
                                            <Dropdown selection search clearable selectOnNavigation={false} placeholder={_msg(ProteusConstants.COUNTER_TYPE + ".label")} options={this.props.counterTypesOptions}
                                                value={this.props.counterTypeCode.description} noResultsMessage={_msg("general.noResultsFound")}
                                                onChange={async (e: any, data: any) => {
                                                    const selectedCounter = data.options.filter((item: { value: string; }) => item.value === data.value)[0];
                                                    this.props.dispatchers.setInReduxState({ counterTypeCode: { description: data.value, id: selectedCounter?.id, code: selectedCounter?.key } });
                                                    this.refresh();
                                                }} />
                                        </Form.Field>
                                    </Form>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form>
                                        <Form.Field>
                                            {_msg(ProteusConstants.FROM_DATE + ".label")}
                                            <Dropdown selection search clearable selectOnNavigation={false} placeholder={_msg(ProteusConstants.FROM_DATE + ".label")}
                                                options={this.filterYearsOptions(ProteusConstants.FROM_DATE, this.props.toDate)}
                                                value={this.props.fromDate} noResultsMessage={_msg("general.noResultsFound")}
                                                onChange={async (e: any, { value }: any) => {
                                                    this.props.dispatchers.setInReduxState({ fromDate: value });
                                                    this.refresh();
                                                }} />
                                        </Form.Field>
                                    </Form>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form>
                                        <Form.Field>
                                            {_msg(ProteusConstants.TO_DATE + ".label")}
                                            <Dropdown selection search clearable selectOnNavigation={false} placeholder={_msg(ProteusConstants.TO_DATE + ".label")}
                                                options={this.filterYearsOptions(ProteusConstants.TO_DATE, this.props.fromDate)}
                                                value={this.props.toDate} noResultsMessage={_msg("general.noResultsFound")}
                                                onChange={async (e: any, { value }: any) => {
                                                    this.props.dispatchers.setInReduxState({ toDate: value });
                                                    this.refresh();
                                                }} />
                                        </Form.Field>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                );
            }

            protected filterYearsOptions(field: string, referenceDate: string | number | null | undefined) {
                if (referenceDate === null || referenceDate === undefined || referenceDate === "") {
                    return this.props.yearsOptions;
                } else {
                    return this.props.yearsOptions.filter((item: Options) => field === ProteusConstants.FROM_DATE ? item.key <= referenceDate : item.key >= referenceDate);
                }
            }

            protected onUploadFiles = async (files: UploadFile[]) => {
                const token = (await apolloClient.query({
                    query: BALANCE_COUNTER_HISTORY_ITEM_SERVICE_UPLOAD_BALANCE_COUNTERS,
                    variables: {
                        year: this.props.uploadYear
                    }
                })).data.balanceCounterHistoryItemService_uploadBalanceCounters;
                const fd = new FormData();
                fd.append('entitiesFile', files[0].originFileObj!);
                const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
                const url = Utils.adjustUrlToServerContext(additionalUrlParam + 'upload/upload-file?token=' + token);
                const response = await fetch(url!, { method: 'POST', body: fd });
                if (response.status === 200) {
                    return _msg("FileUploadButton.uploadSuccessfully");
                }
                return _msg("FileUploadButton.uploadError");
            }

            renderPane(entityDescriptor: EntityDescriptor, columns: Array<ColumnDefinition>) {
                return <>
                    {this.renderTopHeader()}
                    {super.renderPane(entityDescriptor, columns)}
                </>
            }

            protected renderCompactBarMenuModal() {
                let result = super.renderCompactBarMenuModal();
                let allMenuChildren = this.searchAndAdjustAddButton(result);
                let customImport = this.getCustomImportButton();
                allMenuChildren.push(customImport);
                result = { ...result, props: { ...result.props, children: allMenuChildren } };
                return result;
            }

            /**
             * The ADD button should be enabled ONLY when a person is selected.
             */
            protected searchAndAdjustAddButton(result: JSX.Element) {
                let allMenuChildren = [...result.props.children];
                const { entityDescriptor } = this.props.dispatchers.getSlice();
                let balanceTypeCounterTypeData = this.encodeBalanceTypeCounterTypeData();
                for (let i = 0; i < allMenuChildren.length; i++) {
                    if (Array.isArray(allMenuChildren[i])) {
                        for (let j = 0; j < allMenuChildren[i].length; j++) {
                            if (allMenuChildren[i][j].key === "add") {
                                allMenuChildren[i][j] = this.adjustAddButton(allMenuChildren[i][j], entityDescriptor, balanceTypeCounterTypeData);
                            }
                        }
                    } else if (allMenuChildren[i].key === "add") {
                        allMenuChildren[i] = this.adjustAddButton(allMenuChildren[i], entityDescriptor, balanceTypeCounterTypeData);
                    }
                }
                return allMenuChildren;
            }

            protected adjustAddButton(addButton: any, entityDescriptor: EntityDescriptor, balanceTypeCounterTypeData: { balanceTypeUrl: string; counterTypeUrl: string }) {
                return {
                    ...addButton,
                    props: {
                        ...addButton.props,
                        to: entityDescriptor.getEntityEditorUrl(ADD + URL_SEPARATOR + this.props.employee?.id + URL_SEPARATOR + balanceTypeCounterTypeData.balanceTypeUrl + URL_SEPARATOR + balanceTypeCounterTypeData.counterTypeUrl),
                        className: (this.props.employee?.id === "-1" || this.props.employee?.id === undefined) ? "BalanceCounterHistoryItem_disableAdd" : ""
                    }
                };
            }

            /**
             * When ADD button is pressed, the selected filters are sent to form by url.
             */
            protected encodeBalanceTypeCounterTypeData() {
                // Because the description of balance/counter can contain "/" and this cause problems for the editor, replace the "/" character with "+".
                let balanceDescription = this.props.balanceTypeCode?.description?.split("/")?.join("+");
                let counterDescription = this.props.counterTypeCode?.description?.split("/")?.join("+");
                const balanceTypeUrl = encodeURIComponent(balanceDescription + URL_SEPARATOR + this.props.balanceTypeCode?.id + URL_SEPARATOR + this.props.balanceTypeCode?.code);
                const counterTypeUrl = encodeURIComponent(counterDescription + URL_SEPARATOR + this.props.counterTypeCode?.id + URL_SEPARATOR + this.props.counterTypeCode?.code);
                return { balanceTypeUrl, counterTypeUrl };
            }

            protected getCustomImportButton() {
                return (
                    <Menu.Item className="BalanceCounterHistoryItem_uploadMenuItem">
                        <div className="FileImporter_menu_div">
                            {_msg("dto_crud.import")}
                            <div>
                                <Input type="number" size="small" value={this.props.uploadYear}
                                    onChange={(e: any, { value }: any) => this.props.dispatchers.setInReduxState({ uploadYear: value })}></Input>
                                <FileUploadButton {...this.props.fileUploadButton} dispatchers={this.props.dispatchers.fileUploadButton} onUploadFiles={this.onUploadFiles}
                                    accept=".xls, .xlsx" multiple={false} useUploadAsMenuItem={true}>
                                </FileUploadButton>
                            </div>
                        </div>
                    </Menu.Item>
                );
            }

            protected getTabbedPageCssClasses() {
                return super.getTabbedPageCssClasses() + " BalanceCounterHistoryItemTable";
            }

            protected async onEmployeeChange() {
                let employee = undefined;
                let yearsOptions = [];
                if (this.props.employee?.id && this.props.employee.id != -1) {
                    const availableYears = (await apolloClient.query({
                        query: BALANCE_COUNTER_HISTORY_ITEM_SERVICE_LOAD_AVAILABLE_YEARS_FOR_PERSON,
                        variables: {
                            person: this.props.employee?.id
                        },
                        context: { showSpinner: false }
                    })).data.balanceCounterHistoryItemService_loadAvailableYearsForPerson;
                    yearsOptions = availableYears.map((item: string | number) => ({
                        key: item as number,
                        text: item as string,
                        value: item as number
                    }));
                    employee = this.props.employee;
                }
                this.props.dispatchers.setInReduxState({ employee, yearsOptions: yearsOptions, fromDate: "", toDate: "" });
                await this.refresh();
            }

            renderTableDimmer() {
                const dimmer = super.renderTableDimmer();
                if (!this.props.embeddedMode) {
                    return dimmer;
                }
                return <></>;
            }

            async componentDidMount() {
                //because I want to keep the balanceTypeOptions and counterTypeOptions when I navigate from form back to table.
                if (!this.props.balanceTypeCode.description && !this.props.counterTypeCode.description) {
                    await this.props.dispatchers.getBalanceTypes();
                    await this.props.dispatchers.getCounterTypes();
                }
                if (this.props.embeddedMode) {
                    await this.onEmployeeChange();
                }
                this.props.dispatchers.getAllCounterTypes();
            }

            async componentDidUpdate(prevProps: any) {
                super.componentDidUpdate(prevProps);
                if (this.props.embeddedMode && this.props.employee?.id !== prevProps.employee?.id) {
                    await this.onEmployeeChange();
                }
            }

            componentWillUnmount() {
                super.componentWillUnmount();
                this.props.dispatchers.setInReduxState({
                    yearsOptions: [],
                    balanceTypeCode: {} as BasicFields,
                    counterTypeCode: {} as BasicFields,
                    fromDate: undefined,
                    toDate: undefined
                });
            }
        }

        const sliceBalanceCounterEditor = balanceCounterHistoryItemEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceBalanceCounterEditor extends SliceEntityEditorPage {

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            getSaveOperationName(): string {
                return `balanceCounterHistoryItemService_saveBalanceCounterHistoryItem`;
            }

            initQueries() {
                super.initQueries();
                this.saveMutation = BALANCE_COUNTER_HISTORY_ITEM_SERVICE_SAVE_BALANCE_COUNTER_HISTORY_ITEM;
            }

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                balanceTypesOptions: [] as Options[],
                counterTypesOptions: [] as Options[],
                workPeriodTypeOptions: [] as Options[],
                overridePrevAndNextCounters: true as boolean,
                counterTypes: [] as BasicFields[]
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceBalanceCounterEditor>(this),
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceBalanceCounterEditor>(this),

                /**
                * Because the save function (server-side) has additional logic, the default "save-service-function" has beed overridden. 
                * Adapt options.variables for the overridden save function
                */
                superMutation: sliceEntityEditorPageOnlyForExtension.impures.invokeSaveMutation,
                async invokeSaveMutation(options: any) {
                    let localEntity = {};
                    for (let key in this.getState().entity) {
                        if ((key === ProteusConstants.BALANCE_TYPE && isEmpty(this.getState().entity[key])) || (key === ProteusConstants.COUNTER_TYPE && isEmpty(this.getState().entity[key])) || key === "employeeName") {
                            continue;
                        }
                        localEntity = { ...localEntity, [key]: this.getState().entity[key] };
                    }
                    if (this.getState().entity.value === undefined || this.getState().entity.value === null || this.getState().entity.value === "") {
                        localEntity = { ...localEntity, value: 0};
                    }

                    if (this.getState().duplication) {
                        localEntity = this.setNullEntityValuesWhenDuplicating(localEntity);
                    }
                    localEntity = {...localEntity, balanceType : this.getState().entity[ProteusConstants.BALANCE_TYPE]?.code }
                    options.variables = { entity: localEntity, overridePrevAndNextCounters: this.getState().overridePrevAndNextCounters };
                    return await this.superMutation(options);
                },

                /**
                * Load the entity and read its fields values from the url
                * If is a new entity (ADD), set default values. Else, load the employeeName (used in toMiniString() function)
                */
                loadSuper: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    if (id.length > 3 && id.substring(0, 3) === ADD) {
                        await this.loadSuper(ADD);
                        await this.setDefaultValuesForEditor(id);
                    } else {
                        let entity = await this.loadSuper(id);
                        entity = { ...entity, employeeName: (await apolloClient.query({ query: PERSON_SERVICE_FIND_BY_ID, variables: { id: entity.person } })).data.personService_findById };
                        const balanceType = this.getState().balanceTypesOptions.find(bt => bt.key === entity.balanceType);
                        entity = { ...entity, balanceType: { id : balanceType?.id, code: balanceType?.key, description: balanceType?.text, objectVersion: balanceType?.objectversion }};
                        this.getDispatchers().setInReduxState({ entity });
                        await this.getCounterTypes(entity.balanceType.code);
                    }
                },

                setNullEntityValuesWhenDuplicating(entity: any) {
                    return {
                        ...entity,
                        id: null,
                        creationDate: null,
                        creationUser: null,
                        modificationDate: null,
                        modificationUser: null,
                        objectVersion: null
                    };
                },

                async setDefaultValuesForEditor(url: string) {
                    const urlProps = decodeURIComponent(url).split(URL_SEPARATOR);
                    // Because the description of balance/counter can contain "/" and this cause problems for the editor, the "/" was replaced with "+".
                    // Revert the change when the values are set to entity.
                    let balanceDescription = urlProps[2]?.split("+")?.join("/");
                    let counterDescription = urlProps[5]?.split("+")?.join("/");
                    let entity = {
                        person: urlProps[1], validFrom: new Date("01/01/" + new Date().getFullYear() + " 00:00"),
                        validUntil: new Date("12/31/" + new Date().getFullYear() + " 23:59"),
                        balanceType: urlProps[3] !== "undefined" ? { description: balanceDescription, id: urlProps[3], objectVersion: 0, code: urlProps[4] } : {},
                        counterType: urlProps[6] !== "undefined" ? { description: counterDescription, id: urlProps[6], objectVersion: 0, code: urlProps[7] } : {}
                    };
                    if (urlProps[4] !== "undefined") {
                        await this.getCounterTypes(urlProps[4]);
                    } else {
                        await this.getCounterTypes();
                    }
                    this.getDispatchers().setInReduxState({ entity });
                },

                async getCounterTypes(balanceTypeCode?: string) {
                    let counterTypes: BasicFields[] = [];
                    if (this.getState().counterTypes.length === 0) {
                        await this.getAllCounterTypes();
                    }
                    const balanceTypes = (await apolloClient.query({
                        query: BALANCE_COUNTER_HISTORY_ITEM_DAO_IMPL_FIND_BALANCE_TYPES,
                    })).data.balanceCounterHistoryItemDaoImpl_findBalanceTypes.map((balanceType: BasicFields) => balanceType.code);

                    if (balanceTypeCode && !balanceTypes.includes(balanceTypeCode)) {
                        const initial = this.getState().counterTypes.find(ct => ct.code === "AANVANG");
                        initial && counterTypes.push(initial);
                        if (balanceTypeCode === ProteusConstants.LEAVE_TYPE_CODE) {
                            const restitution = this.getState().counterTypes.find(ct => ct.code === "TERUGGAVE");
                            restitution && counterTypes.push(restitution);
                        } else if (balanceTypeCode === ProteusConstants.LEAVE_HOURS_BALANCE_TYPE_CODE) {
                            const extraHours = this.getState().counterTypes.find(ct => ct.code === "EXTRA_HOURS");
                            extraHours && counterTypes.push(extraHours);
                        }
                    } else if (balanceTypeCode) {
                        counterTypes = (await apolloClient.query({
                            query: BALANCE_COUNTER_HISTORY_ITEM_DAO_IMPL_FIND_COUNTER_TYPES_BY_BALANCE,
                            variables: {
                                balanceTypeCode: balanceTypeCode
                            }
                        })).data.balanceCounterHistoryItemDaoImpl_findCounterTypes;
                    } else {
                        counterTypes = this.getState().counterTypes;
                    }
                    this.getDispatchers().setInReduxState({
                        counterTypesOptions: counterTypes.map((item: BasicFields) => ({
                            key: item.code as string,
                            text: item.description as string,
                            value: item.description as string,
                            id: item.id as number,
                            objectversion: item.objectVersion as number
                        }))
                    })
                },

                async getBalanceTypes() {
                    const balanceTypes = (await apolloClient.query({ query: BALANCE_SERVICE_FACADE_BEAN_FIND_ALL_BALANCE_TYPES })).data.balanceServiceFacadeBean_allBalanceTypes;
                    this.getDispatchers().setInReduxState({
                        balanceTypesOptions: balanceTypes.map((item: BasicFields) => ({
                            key: item.code as string,
                            text: item.description as string,
                            value: item.description as string,
                            id: item.id,
                            objectversion: item.objectVersion as number
                        }))
                    });
                },

                async getWorkPeriodType() {
                    const workPeriodType = (await apolloClient.query({ query: PLANNING_SERVICE_ENDPOINT_GET_REGISTRATION_TYPES_BY_LAYOUT })).data.planningServiceEndpoint_registrationTypes;
                    this.getDispatchers().setInReduxState({
                        workPeriodTypeOptions: workPeriodType.map((item: { name: string, code: string }) => ({
                            key: item.code as string,
                            text: item.name as string,
                            value: item.code as string
                        }))
                    });
                },

                async getAllCounterTypes() {
                    const counterTypes: BasicFields[] = (await apolloClient.query({ query: BALANCE_COUNTER_HISTORY_ITEM_DAO_IMPL_FIND_COUNTER_TYPES })).data.balanceCounterHistoryItemDaoImpl_findCounterTypes;
                    this.getDispatchers().setInReduxState({ counterTypes });
                },

                tableIsEmbedded() {
                    if (balanceCounterHistoryItemEntityDescriptor.infoTable.routeProps?.routeEntityName === AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().locationForMainRouteEntityName) {
                        return false;
                    }
                    return true;
                },

                getMainLocation() {
                    return AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().locationForMain!.pathname;
                }
            }

        }).setEntityDescriptor(balanceCounterHistoryItemEntityDescriptor);

        balanceCounterHistoryItemEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceBalanceCounterEditor> & EntityEditorPageProps> {

            getBreadcrumbSections() {
                let breadcrumb = super.getBreadcrumbSections();
                if (!this.props.dispatchers.tableIsEmbedded()) {
                    return breadcrumb;
                }

                breadcrumb = breadcrumb.filter((item: any) => item.key !== "table");
                breadcrumb.splice(1, 0, { key: 'mainLocation', content: <><Icon name="chart bar outline" /><Link to={this.props.dispatchers.getMainLocation()}>{_msg(this.props.dispatchers.getMainLocation().substring(1))}</Link></> },)
                return breadcrumb;
            }

            renderForm() {
                return (
                    <>
                        {super.renderForm()}
                        <Form>
                            <Grid className="EntityEditorPage_grid" stackable>
                                <Grid.Row className="EntityEditorPage_grid_row">
                                    <Grid.Column className="column EntityEditorPage_grid_row_column">
                                        <Form.Field className="BalanceCounterHistoryItem_overrideField">
                                            <div>
                                                <label>{_msg("BalanceCounterHistoryItem.override.label")}</label>
                                                <PopupWithHelpTooltip tooltip={_msg("BalanceCounterHistoryItem.overrideTooltip.label")} />
                                            </div>
                                            <Checkbox checked={this.props.overridePrevAndNextCounters}
                                                onChange={() => this.props.dispatchers.setInReduxState({ overridePrevAndNextCounters: !this.props.overridePrevAndNextCounters })}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </>
                );
            }

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "BalanceCounterHistoryItem" })
                    .addFieldDescriptor({ name: ProteusConstants.VALID_FROM, type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT, allowClear: false}) })
                    .addFieldDescriptor({ name: ProteusConstants.VALID_UNTIL, type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT}) })
                    .addFieldDescriptor({ name: ProteusConstants.REMARK, type: FieldType.text })
                    .addFieldDescriptor({ name: ProteusConstants.BALANCE_TYPE, type: "CustomField" })
                    .addFieldDescriptor({ name: ProteusConstants.VALUE, type: "CustomField" })
                    .addFieldDescriptor({ name: ProteusConstants.WORK_PERIOD_TYPE, type: "CustomField" })
                    .addFieldDescriptor({ name: ProteusConstants.COUNTER_TYPE, type: "CustomField" })

                const that = this;
                fieldEditors["CustomField"] = class extends FieldEditor<any, FieldEditorNotUsableStandAloneProps>{
                    render = () => {
                        if (this.props.fieldDescriptor.name === ProteusConstants.BALANCE_TYPE) {
                            return (
                                <Dropdown selection search clearable selectOnNavigation={false} options={that.props.balanceTypesOptions} value={that.props.entity?.balanceType?.description}
                                    className="BalanceCounterHistoryItemEditor_customDropdown" onChange={(e: any, data: any) => {
                                        const selectedBalance = data.options.filter((item: { value: string; }) => item.value === data.value)[0];
                                        let entity = this.props.formikProps.values;
                                        if (selectedBalance) {
                                            entity = {
                                                ...entity,
                                                balanceType: { description: data.value, id: selectedBalance.id, objectVersion: selectedBalance.objectversion, code: selectedBalance.key },
                                                counterType: {}
                                            };
                                            that.props.dispatchers.getCounterTypes(selectedBalance.key);
                                        } else {
                                            entity = { ...entity, balanceType: {} };
                                            that.props.dispatchers.getBalanceTypes();
                                            that.props.dispatchers.getCounterTypes();
                                        }
                                        that.props.dispatchers.setInReduxState({ entity });
                                    }} noResultsMessage={_msg("general.noResultsFound")} />)
                        } else if (this.props.fieldDescriptor.name === ProteusConstants.COUNTER_TYPE) {
                            return (
                                <Dropdown selection search clearable selectOnNavigation={false} options={that.props.counterTypesOptions} value={that.props.entity?.counterType?.description}
                                    className="BalanceCounterHistoryItemEditor_customDropdown" onChange={(e: any, data: any) => {
                                        const selectedCounter = data.options.filter((item: { value: string; }) => item.value === data.value)[0];
                                        let entity = this.props.formikProps.values;
                                        if (selectedCounter) {
                                            entity = {
                                                ...entity,
                                                counterType: { description: data.value, id: selectedCounter.id, objectVersion: selectedCounter.objectversion, code: selectedCounter.key }
                                            };
                                        } else {
                                            entity = { ...entity, counterType: {} };
                                        }
                                        that.props.dispatchers.setInReduxState({ entity });
                                    }} noResultsMessage={_msg("general.noResultsFound")} />)
                        } else if (this.props.fieldDescriptor.name === ProteusConstants.WORK_PERIOD_TYPE) {
                            return (
                                <Dropdown selection search clearable selectOnNavigation={false} options={that.props.workPeriodTypeOptions} value={that.props.entity?.workPeriodType}
                                    className="BalanceCounterHistoryItemEditor_customDropdown" onChange={(e: any, data: any) => {
                                        let entity = { ...this.props.formikProps.values };
                                        if (data.value) {
                                            entity = { ...entity, workPeriodType: data.value };
                                        } else {
                                            entity = { ...entity, workPeriodType: null };
                                        }
                                        that.props.dispatchers.setInReduxState({ entity });
                                    }} noResultsMessage={_msg("general.noResultsFound")} />)
                        } else {
                            return <Input type='number' value={this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values) || "0"} min="0"
                                onChange={(e: any, data: any) => {
                                    if (Number(data.value) >= 0) {
                                        this.props.formikProps.setFieldValue(ProteusConstants.VALUE, data.value);
                                    }
                                }}
                            />
                        }
                    }
                }
                return result;
            }

            componentDidMount() {
                this.props.dispatchers.getBalanceTypes();
                this.props.dispatchers.getWorkPeriodType();
            }

            componentWillUnmount() {
                if (this.props.dispatchers.tableIsEmbedded()) {
                    this.props.dispatchers.dispatch(push(this.props.dispatchers.getMainLocation()));
                }
            }
        }
    }
}
