'use strict'

/*@ngInject*/
function ErrorModal(FormModal) {
	
	return {
		
		'ERROR': 'error',
		'SERVER_ERROR': 'serverError',
		'VALIDATION_ERROR': 'validationError',
		
		open: function(data) {
			FormModal.open({
				scope: {
					template: require('./error-modal.html'),
					panelCls: 'panel-danger',
					icon: 'fa fa-exclamation-circle',
					title: data.title || data.titleId,
					message: data.message,
					messageId: data.messageId
				},
				actions: ['close']
			});
		}
	}
};

module.exports = ErrorModal;