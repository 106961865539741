import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import _ from "lodash";
import React from "react";
import { PersonAssociationFieldEditor } from "pages/realization/RealizationEntityDescriptor";
import { ProteusConstants } from "ProteusConstants";
import { EMPLOYEE_SERVICE_GET_EMPLOYEE_BY_ID_WITH_CONTRACT, RESOURCES_SERVICE_FACADE_BEAN_GET_COMPANY_SNAPSHOT_BY_CODE } from "graphql/queries";
import { Person } from "pages/employeeDetail/EmployeeDetailEntityDescriptor";
import { AssociationNameAndCodeEditor } from "pages/AssociationFieldEditors";

const BEDRIJF: string = "bedrijf";

export class RegistrationEntityDescriptor extends EntityDescriptor {

    protected customize() {

        const registrationEntityDescriptor = this;
        registrationEntityDescriptor.removeFieldDescriptors("detail");

        const sliceRegistrationEditor = registrationEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceRegistrationEditor extends SliceEntityEditorPage {

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                initialCompany: undefined as unknown as { code: string, name: string },
                initialResource: undefined as unknown as Person
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceRegistrationEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceRegistrationEditor>(this),

                async getInitialCompanyData(code: string) {
                    let initialCompany = (await apolloClient.query({
                        query: RESOURCES_SERVICE_FACADE_BEAN_GET_COMPANY_SNAPSHOT_BY_CODE,
                        variables: {
                            code
                        }
                    })).data.resourcesServiceFacadeBean_companySnapshot;
                    this.getDispatchers().setInReduxState({ initialCompany });
                },

                async getInitialResourceData(id: number) {
                    let initialResource = (await apolloClient.query({
                        query: EMPLOYEE_SERVICE_GET_EMPLOYEE_BY_ID_WITH_CONTRACT,
                        variables: {
                            employeeId: id
                        }
                    })).data.employeeService_employee;

                    initialResource = {
                        ...initialResource,
                        contractHistoryItem: {
                            employeeNumber: initialResource?.detail?.contractHistory[0]?.employeeNumber
                        }
                    };
                    delete initialResource?.detail;
                    this.getDispatchers().setInReduxState({ initialResource });
                },

                superLoad: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.superLoad(id);
                    if (entity !== undefined) {
                        if (entity[BEDRIJF] !== undefined) {
                            await this.getInitialCompanyData(entity[BEDRIJF]);
                        }
                        if (entity[ProteusConstants.RESOURCE] !== undefined) {
                            await this.getInitialResourceData(entity[ProteusConstants.RESOURCE]);
                        }
                    }
                    return entity;
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    entity = this.convertFromObjectToIdOrCode(entity, BEDRIJF, ProteusConstants.CODE);
                    entity = this.convertFromObjectToIdOrCode(entity, ProteusConstants.RESOURCE, ProteusConstants.ID);
                    await this.saveSuper(entity);
                },

                convertFromObjectToIdOrCode(entity: any, field: string, property: string) {
                    if (entity[field] !== null && entity[field] !== undefined && entity[field][property] !== undefined) {
                        entity = { ...entity, [field]: entity[field][property] };
                    }
                    return entity;
                }
            }
        }).setEntityDescriptor(registrationEntityDescriptor);

        registrationEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceRegistrationEditor> & EntityEditorPageProps> {

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "Registration" }, false);
                registrationEntityDescriptor.doForFields(null, (fieldDescriptor) => result.entityDescriptor.addFieldDescriptor(fieldDescriptor));
                result.entityDescriptor.removeFieldDescriptors(BEDRIJF, ProteusConstants.RESOURCE);
                // replace resource descriptor with resource picker descriptor
                result.entityDescriptor.addFieldDescriptor({ name: ProteusConstants.RESOURCE, type: "EmployeeSnapshot" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        const newProps = {
                            ...props,
                            includeAllWithValidContract: true,
                            isClearable: false,
                            queryLimit: -1,
                        };
                        return React.createElement(PersonAssociationFieldEditor as any, newProps as FieldEditorProps);
                    }
                });

                // replace company descriptor with company picker descriptor
                result.entityDescriptor.addFieldDescriptor({ name: BEDRIJF, type: "Company" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        return React.createElement(AssociationNameAndCodeEditor as any, props as FieldEditorProps);
                    }
                });

                // initialize the pickers
                result.entity = this.addInitialValues(result.entity, BEDRIJF, this.props.initialCompany, ProteusConstants.CODE);
                result.entity = this.addInitialValues(result.entity, ProteusConstants.RESOURCE, this.props.initialResource, ProteusConstants.ID);
                return result;
            }

            protected addInitialValues(entity: any, field: string, initialValue: any, property: string) {
                if (entity[field] !== undefined && entity[field] !== null && entity[field][property] === undefined &&
                    initialValue !== undefined && initialValue !== null && entity[field] === initialValue[property]) {
                    entity = { ...entity, [field]: initialValue };
                }
                return entity;
            }

            protected convertFromObjectToStringOrNumber(entity: any, fieldName: string, fieldNameInState: string, propertyToBeShown: string) {
                if (entity[fieldName] && entity[fieldName][propertyToBeShown] !== undefined) {
                    this.props.dispatchers.setInReduxState({ [fieldNameInState]: entity[fieldName] });
                    entity = { ...entity, [fieldName]: entity[fieldName][propertyToBeShown] };
                }
                return entity;
            }

            protected getEntityValuesFromForm() {
                // because in the form the "company", and "resource" fields are of type "object" and in the table they are string or number, 
                // a conversion is needed when navigating from "edit properties" to "view properties" (from form to table)
                let values = super.getEntityValuesFromForm();
                values = this.convertFromObjectToStringOrNumber(values, BEDRIJF, "initialCompany", "code");
                values = this.convertFromObjectToStringOrNumber(values, ProteusConstants.RESOURCE, "initialResource", "id");
                return values;
            }
        }
    }
}