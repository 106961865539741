'use strict';

var css = require('./drawer.css');

/*@ngInject*/
function drawer(Principal, $rootScope, $location) {
	
	$rootScope.getNavRoute = function(path) {
		if ($rootScope.navRoutes) {
			for (var i = 0; i < $rootScope.navRoutes.length; i++) {
				var navRoute = $rootScope.navRoutes[i];
				if (navRoute.route === path) {
					return navRoute;
				}
				if (navRoute.subRoutes) {
					for (var j = 0; j < navRoute.subRoutes.length; j++) {
						var subRoute = navRoute.subRoutes[j];
						if (subRoute.route === path) {
							return subRoute;
						}
					}
				}
			}
		}
		return null;
	};
	
	return {
		restrict: 'AE',
		template: require('./drawer.html'),
		link: function(scope, elt, attrs) {
			var drawer = scope.drawer = {};

			drawer.open = false;

			drawer.toggle = function() {
				drawer.open = !drawer.open;
			};
			
			drawer.logout = Principal.logout;

			drawer.locationChange = function() {
				var path = '#' + $location.path();
				drawer.route = $rootScope.getNavRoute(path);
				drawer.open = false;
				
				
				if ($rootScope.navRoutes) {
					for (var i = 0; i < $rootScope.navRoutes.length; i++) {
						var navRoute = $rootScope.navRoutes[i];
						navRoute.collapsed = true;
					}
				}
			};

			scope.$on('$locationChangeStart', drawer.locationChange);
			$rootScope.$watchCollection('navRoutes', drawer.locationChange);
			// $watchCollection does not detect sub-routes changes =>
			// we listen to a custom event for this case
			$rootScope.$on('navRoutesChanged', drawer.locationChange);
			
			var isExpanded = false;
			var MENU_MIN_WIDTH = 1175;	

			angular.element(document).ready(function () {
				if (angular.element(window).width() < MENU_MIN_WIDTH) {					
					colapseMenu();
					isExpanded = false;
               	} else {
					expandMenu();
					isExpanded = true;
               	}
			});


			angular.element(window).on('resize', function() {              	
               	if (angular.element(window).width() < MENU_MIN_WIDTH && isExpanded) {
					colapseMenu();
					isExpanded = false;
               	} 

               	if (angular.element(window).width() >= MENU_MIN_WIDTH && !isExpanded) {
					expandMenu();
					isExpanded = true;
               	}               	
			});

			function colapseMenu() {
				angular.element('.collapsed-expanded').addClass('navbar-hidden');
				angular.element('.collapsed-navbar ').removeClass('navbar-hidden');
			}

			function expandMenu() {
				angular.element('.collapsed-expanded').removeClass('navbar-hidden');
				angular.element('.collapsed-navbar ').addClass('navbar-hidden');
			}
			
		}
	};
}

module.exports = drawer;