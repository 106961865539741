import { SelectExt } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { FieldEditor, ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { getAvailableSpares_employeePlanningServiceImpl_availableSpares } from "apollo-gen/getAvailableSpares";
import Interweave from "interweave";
import React from "react";
import { Option } from "react-select/src/filters";

interface SpareFieldEditorProps extends FieldEditorProps {
    spares: getAvailableSpares_employeePlanningServiceImpl_availableSpares[];
    onChange?: (entity: any) => void;
    onFocus?: () => void
}

export class SpareFieldEditor extends FieldEditor<getAvailableSpares_employeePlanningServiceImpl_availableSpares, SpareFieldEditorProps> {

    onChange = (data: any, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        const value = this.props.spares.filter((item: getAvailableSpares_employeePlanningServiceImpl_availableSpares) => {
            if (data.value === 0) {
                return item.id === undefined || item.id === null;
            } else {
                return item.id === data.value;
            }
        })[0];
        s.setFieldValue(hw, value);
        this.props.onChange?.(value);
    }

    getLabel(item: getAvailableSpares_employeePlanningServiceImpl_availableSpares) {
        if (!item.shift) {
            return _msg("ShipRosterEditor.shipRoster.noShiftSpare.label", item.fullName, item.status);
        }
        if (!item.ship) {
            return _msg("ShipRosterEditor.shipRoster.noShipSpare.label", item.fullName, item.shift, item.status);
        }
        return _msg("ShipRosterEditor.shipRoster.spareLabel.label", item.fullName, item.shift, item.ship, item.status)
    }

    onInput(option: Option, rawInput: string) {
        // Label contains html tags. They have to be removed so the filtering to be correct 
        const content = option.label.toLowerCase().replace(/<\/?[^>]+(>|$)/g, "");
        return content.includes(rawInput.toLowerCase().trim());
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const options = this.props.spares.map((item: getAvailableSpares_employeePlanningServiceImpl_availableSpares) => ({
            entity: item,
            label: this.getLabel(item),
            value: item.id ? item.id : 0
        }));

        let selectedValue = this.getValue() || {
            entity: undefined,
            label: "",
            value: 0
        };
        selectedValue = options.find((option) => option.value === selectedValue);

        return <SelectExt options={options} clearable={false} onChange={(data, action) => this.onChange(data, s, hw)} isMulti={false} filterOption={(option: Option, rawInput: string) => this.onInput(option, rawInput)}
            value={options.length > 0 ? selectedValue : undefined} closeMenuOnSelect formatOptionLabel={(option: Option) => <Interweave content={option.label} />}
            noOptionsMessage={() => _msg("general.noResultsFound")} onFocus={this.props.onFocus} />
    }

}