'use strict'

function Flags() {
	return function(arr) {

		var _ = 0, pow = 1, map = {};

		if (!Array.isArray(arr)) {
			arr = arguments;
		}
		
		for (var i = 0; i < arr.length; i++) {
			map[arr[i]] = pow;
			pow *= 2;
		}

		this.get = function() {
			return _;
		}

		this.set = function(flag) {
			_ = map[flag];
		}
		
		this.reset = function() {
			_ = 0;
		}

		this.is = function(flag) {
			return (_ & map[flag]) == map[flag];
		}

		this.add = function(flag) {
			_ |= map[flag];
		}

		this.remove = function(flag) {
			_ ^= map[flag];
		}

	}
}

module.exports = Flags;