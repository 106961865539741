'use strict';

module.exports = {

	template: require('./catalogs-dropdown.html'),
	bindings: {
		catalogs: '<',
		catalogItemLabel: '@',
		disabledInput: '<',
		hideActions: '<'
	},
	controllerAs: 'catalogsDropdown',
	controller: /*@ngInject*/ function($scope, rx) {
		var catalogsDropdown = this;

		catalogsDropdown.dropdownOpen = false;
		$scope.$watch('parentDropdownOpen', function(newValue, oldValue) {
			catalogsDropdown.dropdownOpen = $scope.parentDropdownOpen;
		});
		
		catalogsDropdown.$onInit = function() {
			catalogsDropdown.catalogs.elementSubject = catalogsDropdown.catalogs.elementSubject || new rx.Subject();
			catalogsDropdown.catalogs.elementSubject.subscribe(function(element) {
				if (element) {
					catalogsDropdown.dropdownOpen = false;
				}
			});
		};
	}
};