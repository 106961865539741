'use strict';

/**
 * @ngdoc overview
 * @name proteus-react
 * @description
 * # proteus-react
 * 
 * This module provides a bridge between the main angular app and the react app, via the `App` global variable.
 */
angular.module('proteus.react', ['ui.grid', 'ui.grid.grouping', 'ui.grid.autoResize', 'proteus.education'])
.directive('onInit', require('./on-init.directive'))
.run(/*@ngInject*/ function($rootScope, $compile, $route, $timeout, ModalSpinner, APP_URL, Principal) {
	
	// allow react to access the modal spinner
	window.App.ModalSpinner = ModalSpinner;
	
	window.App.logout = function(logoutHandler) {
		return Principal.logout();
	}
})

module.exports = 'proteus.react';
