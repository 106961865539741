'use strict';

angular.module('proteus.resource-picker', [])

.constant('ResourcePickerConstants', require('./resource-picker.constants'))
.directive('resourcePicker',         require('./resource-picker.directive'))
.directive('resourcePickerPopup',    require('./resource-picker-popup.directive'))
.directive('resourcePickerTypeahead',    require('./resource-picker-typeahead.directive'))
.controller('ResourceTypeaheadCtrl', require('./resource-typeahead.controller'))
.factory('Resources',                require('./resources.service'))
.factory('Resource',                 require('./resource.service'));

module.exports = 'proteus.resource-picker';