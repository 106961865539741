'use strict';

/*@ngInject*/
function CompetencesTrainingComponentsCtrl($scope, Education, Collection, FormModal) {
	var trainingComponents = $scope.competences.trainingComponents = this;
	$scope.competences.addedComponents = [];
	$scope.competences.removedComponents = [];

	$scope.$watch('competences.version', function(version) {
		if (version.id) {
			Education.getComponentsForCompetence(null, {
				params: {
					competenceVersion: version.id
				}
			}).then(function(result) {
				version.trainingComponentVersions = result.data;
				trainingComponents.collection = Collection.create(version.trainingComponentVersions);
				trainingComponents.visibleCollection = Collection.create([]);
				var map = {};
				for (var i in trainingComponents.collection.data) {
					var componentVersion = trainingComponents.collection.data[i];
					if (!map[componentVersion.component.id]) {
						map[componentVersion.component.id] = componentVersion;
						trainingComponents.visibleCollection.add(componentVersion);
					}
				}
			});
		} else {
			version.trainingComponentVersions = [];
			trainingComponents.collection = Collection.create(version.trainingComponentVersions);
			trainingComponents.visibleCollection = Collection.create([]);
		}
	});

	trainingComponents.disableSave = $scope.competences.disableSave;

	trainingComponents.addComponent = function(event) {
		trainingComponents.catalogs = {
			visible: ['COMPONENT'],
			type: 'OPL',
			filter: {
				show: true,
				hideActive: true
			},
			hideSelectHeader: true,
			multipleSelection: 'true'
		};
		var modal = FormModal.open({
			windowClass: 'modal-full',
			scope: {
				template: require('../catalogs/catalogs-groups.html'),
				title: 'add',
				catalogs: trainingComponents.catalogs
			},
			actions: ['ok', 'cancel', 'close']
		}).result.then(function() {
			for (var i in trainingComponents.catalogs.selectedComponents) {
				var component = trainingComponents.catalogs.selectedComponents[i];
				var contains = trainingComponents.collection.contains(component.currentComponentVersion, function(o1, o2) {
					if (o1.id == o2.id) {
						return true;
					}
					return false;
				});
				if (!contains) {
					var componentVersion = component.currentComponentVersion;
					componentVersion.component = Object.assign({}, component);
					delete componentVersion.component.currentComponentVersion;

					trainingComponents.collection.add(componentVersion);
					trainingComponents.visibleCollection.add(componentVersion);

					// search to see if the component was just removed
					var removedIndex = $scope.competences.removedComponents.indexOf(component.id);
					if (removedIndex >= 0) {
						$scope.competences.removedComponents.splice(removedIndex);
					} else {
						$scope.competences.addedComponents[$scope.competences.addedComponents.length] = component.id;
					}

					if ($scope.competences.addedComponents.length == 0 && $scope.competences.removedComponents.length == 0) {
						// disable save button
						trainingComponents.disableSave(true);
					} else {
						// enable save button
						trainingComponents.disableSave(false);
					}

					$scope.competences.createNewMajorVersion = true;
				}
			}
		}).catch(function(reason) {
			// the form window has been closed (cancel button)
		});
	}

	function removeComponent(scope) {
		// remove the all the component versions from the references
		var toRemove = [];
		for (var i in trainingComponents.collection.data) {
			var componentVersion = trainingComponents.collection.data[i];
			if (componentVersion.component.id == scope.component.component.id) {
				toRemove[toRemove.length] = componentVersion;
			}
		}
		for (var i in toRemove) {
			trainingComponents.collection.remove(toRemove[i]);
		}

		// remove the clicked component from the visible list
		trainingComponents.visibleCollection.remove(scope.component);

		var addedIndex = $scope.competences.addedComponents.indexOf(scope.component.component.id);
		// search to see if is a new added components; remove from added list, remove from collection and visible list, but don't add to the removed list
		if (addedIndex >= 0) {
			$scope.competences.addedComponents.splice(addedIndex, 1);
		} else {
			$scope.competences.removedComponents[$scope.competences.removedComponents.length] = scope.component.component.id;
		}

		if ($scope.competences.addedComponents.length == 0 && $scope.competences.removedComponents.length == 0) {
			trainingComponents.disableSave(true);
		} else {
			trainingComponents.disableSave(false);
		}

		// add/remove component => create new major version for the competence and for the removed component
		$scope.competences.createNewMajorVersion = true;
	}

	// tree action
	trainingComponents.actions = {
		icon: 'fa fa-trash fa-fw',
		run: removeComponent 	
	};

	// tree configuration
	trainingComponents.tree = {
		repeat: 'component in trainingComponents.visibleCollection.data track by component.id ',
		label: '{{component.name}} <span class="text-muted">v{{component.version}}</span>',
		action: 'trainingComponents.actions'
	};
}

module.exports = CompetencesTrainingComponentsCtrl;