import gql from "graphql-tag";

/************************************************************************************
 * FRAGMENTS
 ************************************************************************************/
export const BASIC_ENTITY = gql`
    fragment BasicEntity on BasicEntity {
        id objectVersion creationDate creationUser modificationDate modificationUser
    }
`;

export const CODE_ENTITY = gql`
    fragment CodeEntity on CodeEntity {
        id code description objectVersion creationDate creationUser modificationDate modificationUser
    }
`;

export const ADDRESS = gql`
    fragment Address on Address {
        box
        city {
            country { autoCode internationalPhoneCode iso3Code web ...CodeEntity }
            structName
            ...CodeEntity
        } 
        creationDate creationUser houseNumber id modificationDate modificationUser objectVersion street
        type { ...CodeEntity } 
    }
    ${CODE_ENTITY}
`;

export const CALENDAR_ITEM = gql`
    fragment CalendarItem on CalendarItem {
        ...BasicEntity, allDay, description, endDate, recurring, startDate, subject, 
        recurrenceDefinitionDto {
            ...BasicEntity,
            pattern {
                ...BasicEntity, day { ...CodeEntity }, dayOfMonth, dayOfYear, days { ...CodeEntity }, 
                index { ...CodeEntity }, interval, month { ...CodeEntity }, objectType, startPoint { ...CodeEntity }
            }, 
            range { endDate, startDate, occurrence, objectType, ...BasicEntity }
        }
    }
    ${CODE_ENTITY}
    ${BASIC_ENTITY}
`;

export const CALENDAR_DEFINITION = gql`
    fragment CalendarDefinition on CalendarDefinition {
        ...CodeEntity, 
        extendDefinitions {...CodeEntity, items {...CalendarItem}, properties {...BasicEntity, code, item {...BasicEntity, allDay, description, startDate, endDate, subject, recurring}, value}}, 
        items {...CalendarItem} 
        properties {...BasicEntity, code, item {...BasicEntity, allDay, description, startDate, endDate, subject, recurring}, value}
    }
    ${CODE_ENTITY}
    ${BASIC_ENTITY}
    ${CALENDAR_ITEM}
`;

export const PARENT_COMPETENCE_CATEGORY = gql`
    fragment CompetenceCategory on CompetenceCategory {
        ...BasicEntity, name, description,
        catalog {
            ...BasicEntity
            environment, description, name, 
        }
    }
    ${BASIC_ENTITY}
`;

export const CATALOG_ATTACHMENT = gql`
    fragment CatalogAttachment on CatalogAttachment {
        name ...BasicEntity classType
    }
    ${BASIC_ENTITY}
`;

export const CATALOG_ITEM_VERSION = gql`
    fragment CatalogItemVersion on CatalogItemVersion {
        name active description majorVersion minorVersion
    }
`;

export const TRAINING_COMPONENT_VERSION = gql`
    fragment TrainingComponentVersion on EducationComponentVersion {
        ...BasicEntity,
        objectType
        trainerProfileVersion
        attachments {
            ...CatalogAttachment
        }
        ...CatalogItemVersion
        component { ...BasicEntity externalId objectType type { ...BasicEntity description code }}
    }
    ${CATALOG_ATTACHMENT}
    ${CATALOG_ITEM_VERSION}
`;

export const COMPETENCE_VERSION = gql`
    fragment CompetenceVersion on CompetenceVersion {
        name, description, majorVersion, minorVersion, active
        levelDefinitions {
            description, percentage, scale, id
        }
        trainingComponentVersions {
            ...TrainingComponentVersion
        }
        attachments {
            ...BasicEntity, fileName, name, classType
        }
        ...BasicEntity
    }
    ${TRAINING_COMPONENT_VERSION}
`

export const COMPETENCE_CATEGORY = gql`
    fragment Competence on CompetenceCategory {
        ...BasicEntity
        name description
        catalog {
            ...BasicEntity
            environment, description, name, 
        }
        competences {
            ...BasicEntity,
            currentCompetenceVersion {
                ...CompetenceVersion
            },
            category {
                ...BasicEntity, name, description,
                catalog {
                    ...BasicEntity, name, description
                }
                parentCategory {
                    name, description, ...BasicEntity,
                    parentCategory {
                        name, description, ...BasicEntity,
                        parentCategory {
                            name, description, ...BasicEntity,
                            parentCategory {
                                name, description, ...BasicEntity,
                                parentCategory {
                                    name, description, ...BasicEntity,
                                    parentCategory {
                                        name, description, ...BasicEntity,
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        parentCategory {
            ...CompetenceCategory
            parentCategory {
                ...CompetenceCategory
                parentCategory {
                    ...CompetenceCategory
                    parentCategory {
                        ...CompetenceCategory
                        parentCategory {
                            ...CompetenceCategory
                            parentCategory {
                                ...CompetenceCategory
                            }
                        }
                    }
                }
            }
        }
    }
    ${PARENT_COMPETENCE_CATEGORY}
    ${BASIC_ENTITY}
    ${TRAINING_COMPONENT_VERSION}
    ${COMPETENCE_VERSION}
`;

export const CATALOG_SNAPSHOT = gql`
    fragment CatalogSnapshot on CatalogSnapshot {
        active name id description environment ...BasicEntity
    }
    ${BASIC_ENTITY}
`;

export const COMPONENT_VERSION = gql`
    fragment ComponentVersion on ComponentVersion {
        ...BasicEntity
        ...CatalogItemVersion
        attachments { ...CatalogAttachment ...BasicEntity}
        component { type { ...CodeEntity } objectType ...BasicEntity}
        objectType
    }
    ${BASIC_ENTITY}
    ${CATALOG_ITEM_VERSION}
    ${CATALOG_ATTACHMENT}
    ${CODE_ENTITY}
`;

export const COMPONENT = gql`
    fragment Component on Component {
        catalog { ...CatalogSnapshot }
        ...BasicEntity objectType
        currentComponentVersion {
            ...ComponentVersion
        }
        type { ...CodeEntity }
    }
    ${BASIC_ENTITY}
    ${CODE_ENTITY}
    ${CATALOG_SNAPSHOT}
    ${CATALOG_ITEM_VERSION}
    ${COMPONENT_VERSION}
`;

export const EVALUATION_CRITERION_VERSION = gql`
    fragment EvaluationCriterionVersion on EvaluationCriterionVersion {
        ...BasicEntity competenceVersion {...CompetenceVersion} ownScore totalScore normPercentage weight required
    }
    ${BASIC_ENTITY}
    ${COMPETENCE_VERSION}
`;

export const MODULE_VERSION = gql`
    fragment ModuleVersion on ModuleVersion {
        ...CatalogItemVersion
        attachments { ...CatalogAttachment }
        ...BasicEntity objectType
        componentVersions {
            ...CatalogItemVersion
            attachments { ...CatalogAttachment }
            component {
                type { description code }
                objectType
            }
            ...BasicEntity objectType
        }
        attachments { ...CatalogAttachment }
    }
    ${CATALOG_ITEM_VERSION}
    ${CATALOG_ATTACHMENT}
    ${BASIC_ENTITY}
`

export const TRACK_VERSION = gql`
    fragment TrackVersion on TrackVersion {
        ...CatalogItemVersion
        attachments { ...CatalogAttachment }
        children {
            componentVersion {
                ...CatalogItemVersion
                attachments {...CatalogAttachment }
                component {
                    type { description code }
                    objectType
                }
                ...BasicEntity objectType
            }
            moduleVersion {
                ...CatalogItemVersion
                attachments { ...CatalogAttachment }
                componentVersions {
                    ...CatalogItemVersion
                    attachments { ...CatalogAttachment }
                    ...BasicEntity objectType
                }
                ...BasicEntity objectType
            }
        }
        ...BasicEntity objectType
    }
    ${CATALOG_ITEM_VERSION}
    ${CATALOG_ATTACHMENT}
    ${BASIC_ENTITY}
`

// GroupWithDefinitions is not an entity, not exposed by GraphQL. Because of this, a constant was created instead of fragment
export const GROUP_WITH_DEFINITION = "...CodeEntity, constraintDefinitions {...BasicEntity, description, name, validForRegistrationTypes, validOnRegistrationTypes, history {...BasicEntity, calendar, validFrom, validUntil}}," +
    " contexts {...CodeEntity, environment, name, owner}, environment, name, owner, properties {...BasicEntity, code, group, value}, " +
    " securityDefinition {...BasicEntity, history {...BasicEntity, validFrom, validUntil, profiles {...CodeEntity, permissions {...CodeEntity}}}}, " +
    " sequenceDefinition {...BasicEntity, history {...BasicEntity, jump, referenceDate, resources, startingPosition, validFrom, validUntil, rotationSystem {...CodeEntity}}}, " +
    " successionDefinition {...BasicEntity, history {...BasicEntity, resources, validFrom, validUntil}} ";

export const HISTORICAL_ENTITY = gql`
    fragment HistoricalEntity on HistoricalEntity {
      creationDate creationUser id modificationDate modificationUser objectVersion validFrom validUntil
}
`;

export const EMPLOYEE = gql`
    fragment Employee on Employee {
      companyHistory { company{ businessHours code creationDate creationUser employersCode id internet  legalForm { ...CodeEntity }
      modificationDate modificationUser name objectType objectVersion parent { code creationDate creationUser id objectType objectVersion } types { environment ...CodeEntity } vatNumber } ...HistoricalEntity }
      creationDate creationUser
      departmentHistory { creationDate creationUser department { ...CodeEntity } id modificationDate modificationUser objectVersion validFrom validUntil }
      firstName gender { ...CodeEntity } id licenseType { ...CodeEntity } modificationDate modificationUser name
      nationality { ...CodeEntity } nativeLanguage { ...CodeEntity } objectType objectVersion title vocative { ...CodeEntity }
      environment { company ...CodeEntity } externId remarks attachment { id content name fileName creationDate modificationDate creationUser modificationUser objectVersion }
      addressHistory { address { ...Address } ...HistoricalEntity }
      telecomList { value type { ...CodeEntity } id creationDate modificationDate creationUser modificationUser objectVersion }
    }
    ${ADDRESS}
    ${CODE_ENTITY}
    ${HISTORICAL_ENTITY}
`;

export const EMPLOYEE_DETAIL = gql`
    fragment EmployeeDetail on EmployeeDetail {
        accountHistory { country{autoCode internationalPhoneCode iso3Code web ...CodeEntity } institution { ...CodeEntity }
        number type { ...CodeEntity } usage { ...CodeEntity } ...HistoricalEntity } barema { ...CodeEntity }
        birthplace { country { autoCode internationalPhoneCode iso3Code web ...CodeEntity } structName ...CodeEntity }
        cards { code creationDate creationUser id modificationDate modificationUser number objectVersion
        type { ...CodeEntity } validUntil } categoryHistory { category { environment ...CodeEntity } ...HistoricalEntity }
        childAllowanceFund { ...CodeEntity } childAllowanceFundNumber
        civilStatusHistory { civilStatus { ...CodeEntity } dateOfBirthPartner firstNamePartner incomeForm { ...CodeEntity }
        namePartner ...HistoricalEntity } constraintGroupHistory { constraintGroup { environment ...CodeEntity } ...HistoricalEntity }
        contractHistory { employeeNumber employmentBreak { ...CodeEntity } reasonContractEnd {...CodeEntity }
        status { ...CodeEntity } suspensionHistory { reasonSuspension { export exportCode ...CodeEntity } ...HistoricalEntity }
        type { ...CodeEntity } workArrangement { ...CodeEntity } ...HistoricalEntity } creationDate creationUser dateOfBirth
        dateOfDeath dimonaNumber functionHistory { employeeFunction { environment ...CodeEntity } ...HistoricalEntity }
        healthInsurance { ...CodeEntity } healthInsuranceNumber
        hourRegimeHistory { hourRegime { environment objectType ...CodeEntity } ...HistoricalEntity }  id
        locationHistory { location { address { ...Address } environment objectType ...CodeEntity } ...HistoricalEntity }
        modificationDate modificationUser nationalRegisterNumber objectVersion person pilotNumber
        shareRegisterHistory { amount name ...HistoricalEntity } startDateCredit
        taxStatusHistory { aantalAnderen aantalKinderen aantalMinderValideAnderen aantalMinderValideKinderen
        echtgenootMinderValide echtgenootTenLaste taxStatus { ...CodeEntity } werknemerMinderValide ...HistoricalEntity }
        teamHistory { team { environment objectType ...CodeEntity } ...HistoricalEntity } timeRegistration
        scaleHistory { scalePercentage extraPercentage ...HistoricalEntity }
    }
    ${ADDRESS}
    ${CODE_ENTITY}
    ${HISTORICAL_ENTITY}
`;

export const EMPLOYEE_SNAPSHOT = gql`
    fragment EmployeeSnapshot on EmployeeSnapshot {
        id name firstName contractHistoryItem { employeeNumber } locationHistoryItem { location { description } } categoryHistoryItem { category {description } }
        hourRegimeHistoryItem { hourRegime {description} }
    }
`;

export const INBOX_ITEM = gql`
    fragment InboxItem on InboxItem {
        id message person processInstance title date parameters { name defaultValue label processor required dataTypeClassName } processVariables
    }
`;

export const NODE = gql`
    fragment Node on Node {
        source
        data { parent { data { isWaitingRegistration source } source } isWaitingRegistration source }
        children { source data { isWaitingRegistration source } }
        parent { source data { isWaitingRegistration source } }
    }
`;

export const PROPERTY = gql`
    fragment Property on Property {
        category, code, creationDate, creationUser, dataType, defaultValue, description, environment, id, isForType, 
        modificationDate, modificationUser, objectVersion, userOverridePossible
    }
`;

export const REGISTRATION_STATUS = gql`
    fragment RegistrationStatus on RegistrationStatus {
        environment, code, creationDate, creationUser, description, id, modificationDate, modificationUser, objectVersion
    }
`;

export const REGISTRATION_TYPE = gql`
    fragment RegistrationType on RegistrationType {
        code, environment, creationDate, creationUser, description, id, modificationDate, modificationUser, name, objectVersion
    }
`;

export const REGISTRATION_SNAPSHOT = gql`
    fragment RegistrationSnapshot on RegistrationSnapshot {
        cyclusId environment externId fromDate id objectType resource status { ...RegistrationStatus } toDate
        type { ...RegistrationType } workRosterItem
    }
    ${REGISTRATION_TYPE}
    ${REGISTRATION_STATUS}
`;

export const REGISTRATION_TYPE_CATEGORY_FRAGMENT = gql`
    fragment RegistrationTypeCategoryFragment on RegistrationTypeCategory {
        ...CodeEntity name environment
        types { ...CodeEntity name environment category { ...CodeEntity, name } 
        statuses { id objectVersion creationDate creationUser modificationDate modificationUser
        type { ...CodeEntity } status { ...CodeEntity environment } } }
    }
    ${CODE_ENTITY}
`;

export const REGISTRATION_TYPE_CATEGORY = gql`
    fragment RegistrationTypeCategory on RegistrationTypeCategory {
        ...RegistrationTypeCategoryFragment
        categoryChildren {
            ...RegistrationTypeCategoryFragment
            categoryChildren {
                ...RegistrationTypeCategoryFragment 
                categoryChildren {
                    ...RegistrationTypeCategoryFragment
                    categoryChildren {
                        ...RegistrationTypeCategoryFragment
                        categoryChildren {
                            ...RegistrationTypeCategoryFragment
                        },
                        parentCategory {
                            ...RegistrationTypeCategoryFragment
                        }
                    },
                    parentCategory {
                        ...RegistrationTypeCategoryFragment
                    }
                },
                parentCategory {
                    ...RegistrationTypeCategoryFragment
                }
            },
            parentCategory {
                ...RegistrationTypeCategoryFragment
            }
        },
        parentCategory {
            ...RegistrationTypeCategoryFragment
        }
    }
    ${REGISTRATION_TYPE_CATEGORY_FRAGMENT}
`;

export const RESOURCE = gql`
    fragment Resource on Resource {
        ...BasicEntity telecomList { ...BasicEntity value type { ...CodeEntity } } addressHistory { address { ...Address } ...HistoricalEntity }
        environment { company ...CodeEntity } externId remarks attachment { id content name fileName creationDate modificationDate creationUser modificationUser objectVersion }
        ... on Person {
            name firstName title nativeLanguage { ...CodeEntity } gender { ...CodeEntity } vocative { ...CodeEntity } licenseType { ...CodeEntity } nationality { ...CodeEntity }
            departmentHistory { creationDate creationUser department { ...CodeEntity } id modificationDate modificationUser objectVersion validFrom validUntil }
            companyHistory { company { businessHours code creationDate creationUser employersCode id internet  legalForm { ...CodeEntity }
            modificationDate modificationUser name objectType objectVersion parent { code creationDate creationUser id objectType objectVersion } types { environment ...CodeEntity } vatNumber } ...HistoricalEntity }
        }
        ... on Employee {
           ...Employee detail {...EmployeeDetail}
        }
    }
    ${EMPLOYEE}
    ${BASIC_ENTITY}
    ${EMPLOYEE_DETAIL}
    ${CODE_ENTITY}
`;

export const RESOURCE_SNAPSHOT = gql`
    fragment ResourceSnapshot on ResourceSnapshot {
        id environment externId 
        ... on EmployeeSnapshot {
           ...EmployeeSnapshot
        }
        ... on PersonSnapshot {
            name firstName title objectType
            company { ...BasicEntity externId remarks environment { company ...CodeEntity } telecomList { ...BasicEntity value type { ...CodeEntity } } 
            addressHistory { address { ...Address } ...HistoricalEntity } code name }
        }
    }
    ${EMPLOYEE_SNAPSHOT}
    ${BASIC_ENTITY}
    ${ADDRESS}
    ${CODE_ENTITY}
    ${HISTORICAL_ENTITY}
`;

export const REGISTRATION_DETAIL = gql`
    fragment RegistrationDetail on RegistrationDetail {
        dummyField
        ... on ConstraintGroupDetail {
            groupCode
        }
        ... on LeaveDetail {
            groupCode groupingToDate groupingFromDate transferred
        }
        ... on DelegationDetail {
            delegate { ...ResourceSnapshot } workflows
        }
        ... on ExchangeDetail {
            secondDate applicant secondRegistrationId
            secondParty { ...ResourceSnapshot }
            exchange {fromDate toDate workPeriodType { ...RegistrationType } substitute { ...ResourceSnapshot } substituted { ...ResourceSnapshot } }
            compensation { fromDate toDate workPeriodType { ...RegistrationType } substitute { ...ResourceSnapshot } substituted { ...ResourceSnapshot } }
        }
        ... on IllnessDetail {
            fromDateCertificate toDateCertificate creditCorrection relapseOf { ...RegistrationSnapshot } relapseRegistrations { ...RegistrationSnapshot }
        }
        ... on WorkAccidentDetail {
            fromDateCertificate toDateCertificate creditCorrection relapseOf { ...RegistrationSnapshot } relapseRegistrations { ...RegistrationSnapshot }
            accidentOnCommute
        }
        ... on OperationalDetail {
            choicePilot superPilot babySuperPilot doublePilot seaCanal regime direction annulation delay info call assignmentCode
            from { code name } to { code name } ship { code name loa breadth } agent { code name } role { code description }
        }
        ... on SequenceExchangeDetail {
            secondParty { id } applicant
        }
        ... on TrainingDetail {
            socialHoursBalance
            company { ...BasicEntity name }
            trainer { ...ResourceSnapshot name firstName }
            realization { id objectType date dueDate person }
            componentVersion {  ...CatalogItemVersion, ...BasicEntity objectType }
        }
        ... on InstructorDetail {
            socialHoursBalance componentVersion { ...CatalogItemVersion, ...BasicEntity objectType }
        }
        ... on WorkPeriodDetail {
            minFromDate maxFromDate minToDate maxToDate restPeriod category team location hourRegime
        }
        ... on MedicalCheckupDetail {
            typeCode
        }
        ... on ShipRosterDetail {
            ship { ...CodeEntity } spare { ...CodeEntity } categoryCode
        }
        ... on OvertimeDetail {
            type { ...CodeEntity } overtimeReason
        }
    }
    ${BASIC_ENTITY}
    ${CODE_ENTITY}
    ${CATALOG_ITEM_VERSION}
    ${REGISTRATION_TYPE}
    ${REGISTRATION_SNAPSHOT}
    ${RESOURCE_SNAPSHOT}
`;

export const AGENDA_DAY_ITEM = gql`
    fragment AgendaDayItem on AgendaDayItem {
        start, end, backgroundColor, transparencyPercent, workRosterType, status, ship, spare, category, agendaRegistrations {prefix, isWaitingRegistration, metadata, 
        registration { ...BasicEntity, environment, fromDate, toDate, resource, status { ...RegistrationStatus }, type { ...RegistrationType }, detail { ...RegistrationDetail }, calculationRelations { result, calculation { id, code }}  remarks { ...BasicEntity value} }},
        constraints {groupName, constraintName, used, available, employeesWithLeaveIds, availableEmployeesIds, minimumAvailable, minimum} 
    }
    ${BASIC_ENTITY}
    ${REGISTRATION_DETAIL}
    ${REGISTRATION_STATUS}
    ${REGISTRATION_TYPE}
`;

export const BALANCE_ITEM = gql`
    fragment BalanceItem on BalanceItem {
        code, description, counters {code, description, value} deductionRegistrations {fromDate, toDate, type {...RegistrationType}, calculationRelations {...BasicEntity, result, year, calculation {...CodeEntity}, category, shortConstant, longConstant, length }}
    }
    ${REGISTRATION_TYPE}
    ${CODE_ENTITY}
    ${BASIC_ENTITY}
`;
/************************************************************************************
 * QUERIES
 ************************************************************************************/
export const ACCOUNT_SERVICE_SAVE_OR_UPDATE_ACCOUNT_WITH_PASSWORD_CONFIRMATION = gql`
    mutation saveOrUpdateAccount($confirmationNewPassword: String, $account: AccountInput) {
        accountService_saveOrUpdateAccountWithPasswordConfirmation(confirmationNewPassword: $confirmationNewPassword, account: $account) {
            id, password
        }
    }
`;

export const BALANCE_COUNTER_HISTORY_ITEM_DAO_IMPL_FIND_BALANCE_TYPES = gql`
    query getBalanceTypes {
        balanceCounterHistoryItemDaoImpl_findBalanceTypes {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const BALANCE_COUNTER_HISTORY_ITEM_DAO_IMPL_FIND_COUNTER_TYPES = gql`
    query getCounterTypes {
        balanceCounterHistoryItemDaoImpl_findCounterTypes {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const BALANCE_COUNTER_HISTORY_ITEM_DAO_IMPL_FIND_COUNTER_TYPES_BY_BALANCE = gql`
    query getCounterTypesByBalance ($balanceTypeCode: String) {
        balanceCounterHistoryItemDaoImpl_findCounterTypes(balanceTypeCode: $balanceTypeCode) {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const BALANCE_COUNTER_HISTORY_ITEM_SERVICE_GET_SEQUENCE_VALUES = gql`
    query getSequenceValues{
        balanceCounterHistoryItemService_sequenceValues
    }
`;

export const BALANCE_COUNTER_HISTORY_ITEM_SERVICE_LOAD_AVAILABLE_YEARS_FOR_PERSON = gql`
    query getAvailableYears ($person: Int) {
        balanceCounterHistoryItemService_loadAvailableYearsForPerson(person: $person)
    }
`;

export const BALANCE_COUNTER_HISTORY_ITEM_SERVICE_SAVE_BALANCE_COUNTER_HISTORY_ITEM = gql`
    mutation saveOrUpdateBalanceCounter ($entity: BalanceCounterHistoryItemInput, $overridePrevAndNextCounters: Boolean) {
        balanceCounterHistoryItemService_saveBalanceCounterHistoryItem(entity: $entity, overridePrevAndNextCounters: $overridePrevAndNextCounters) {
            person, ...BasicEntity, remark, validFrom, validUntil, value, workPeriodType, balanceType,
            counterType { ...CodeEntity }
        }   
    }
    ${BASIC_ENTITY}
    ${CODE_ENTITY}
`;

export const BALANCE_COUNTER_HISTORY_ITEM_SERVICE_UPLOAD_BALANCE_COUNTERS = gql`
    query uploadBalanceCounters ($year: String) {
        balanceCounterHistoryItemService_uploadBalanceCounters(taskType: "PROCESS_BALANCE_COUNTERS_FILE", year: $year) 
    }
`;

export const BALANCE_SERVICE_FACADE_BEAN_GET_CREDIT_BALANCE = gql`
    query getCreditBalance($automaticallyAdjustUntilDate: Boolean!, $includeEarningDates: Boolean!, $untilDate: Date, $employeeId: Int, $includeStatistics: Boolean!) {
        balanceServiceFacadeBean_creditBalance(automaticallyAdjustUntilDate: $automaticallyAdjustUntilDate, includeEarningDates: $includeEarningDates, untilDate: $untilDate, employeeId: $employeeId, includeStatistics: $includeStatistics) {
            summary { adjustedUntilDate, finalBalance, legalMinimumDate, counters { id, validFrom, validUntil, value, counterType { ...CodeEntity } } }
        }
    }
    ${CODE_ENTITY}
`;

export const BALANCE_SERVICE_FACADE_BEAN_FIND_ALL_BALANCE_TYPES = gql`
    query getAllBalanceTypes {
        balanceServiceFacadeBean_allBalanceTypes {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const BALANCE_SERVICE_FACADE_BEAN_FIND_BALANCE_TYPES_FOR_REGISTRATIONS = gql`
    query getBalanceTypesFromRegistrationType {
        balanceServiceFacadeBean_balanceTypesFromRegistrationType {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const BALANCE_SERVICE_FACADE_BEAN_FIND_BALANCE_TYPES_FOR_REGISTRATIONS_SEARCH = gql`
    query getBalanceTypesFromRegistrationTypeWithSearch($searchedRegistrationType: String) {
        balanceServiceFacadeBean_balanceTypesFromRegistrationTypeWithSearch(searchedRegistrationType: $searchedRegistrationType) {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const CALENDAR_DEFINITION_SERVICE_GET_CALENDAR_DEFINITION = gql`
    query getCalendarDefinition($id: Int) {
        calendarDefinitionService_calendarDefinition(id: $id) {
            ...CalendarDefinition
        }
    }
    ${CALENDAR_DEFINITION}
`;

export const CALENDAR_DEFINITION_SERVICE_GET_CALENDAR_DEFINITIONS = gql`
    query getCalendarDefinitions($ids: [Int]) {
        calendarDefinitionService_calendarDefinitions(ids: $ids) {
            ...CalendarDefinition
        }
    }
    ${CALENDAR_DEFINITION}
`;

export const CALENDAR_DEFINITION_SERVICE_REMOVE_CALENDAR_DEFINITION = gql`
    mutation removeCalendarDefinition($id: Int) {
        calendarDefinitionService_removeCalendarDefinition(id: $id)
    }
`;

export const CALENDAR_DEFINITION_SERVICE_SAVE_CALENDAR_DEFINITION = gql`
    mutation saveOrUpdateCalendarDefinition($entity: CalendarDefinitionInput) {
        calendarDefinitionService_saveCalendarDefinition(calendarDefinition: $entity) {
            id
        }
    }
`;

export const CALENDAR_DEFINITION_SERVICE_SAVE_CALENDAR_DEFINITIONS = gql`
    mutation saveOrUpdateCalendarDefinitions($calendarDefinitions: [CalendarDefinitionInput]) {
        calendarDefinitionService_saveCalendarDefinitions(calendarDefinitions: $calendarDefinitions) {
            id, code
        }
    }
`;

export const CALENDAR_PROPERTY_CT_SERVICE_GET_CALENDAR_PROPERTIES_CT = gql`
    query getCalendarPropertiesCt {
        calendarPropertyCtService_calendarPropertiesCt {
            id, code
        }
    }
`;

export const CALENDAR_SERVICE_GET_CALENDAR_EVENTS = gql`
    query getCalendarEvents ($fromDate: Date, $tillDate: Date, $calendarDefinitionCode: String) {
        calendarService_calendarEvents(fromDate: $fromDate, tillDate: $tillDate, calendarDefinitionCode: $calendarDefinitionCode) {
            fromDate tillDate properties { code value }
        }
    }
`;

export const CALENDAR_SERVICE_FIND_BY_STRING = gql`
    query calendarDefinitionFindByString ($params: FindByStringParamsInput) {
        calendarDefinitionService_findByString(params: $params) {
            ...CalendarDefinition
        }
    }
    ${CALENDAR_DEFINITION}
`;

export const COMMON_SERVICE_ENDPOINT_RUN_IN_BACKGROUND = gql`
    mutation runInBackground ($backgroundTask: BackgroundTaskInput) {
        commonServiceEndpoint_runInBackground(backgroundTask: $backgroundTask)
    }
`;

export const COMMON_SERVICE_FACADE_BEAN_COMMUNICATION_WITH_APICS_IS_RUNNING = gql`
    query communicationWithApicsIsRunning {
        commonServiceFacadeBean_communicationWithApicsIsRunning
    }
`;

export const COMMON_SERVICE_FACADE_BEAN_FIND_CALLS = gql`
    query findCalls ($date: Date) {
        commonServiceFacadeBean_findCalls(date: $date) {
            ...BasicEntity, date, direction {...CodeEntity}, status {...CodeEntity}, 
            messages {...BasicEntity, call {...BasicEntity }, checkpoint, confirmationDate, content, date, errorCode, errorDescription, identifier, status {...CodeEntity}, type, 
                services {...BasicEntity, code, message {...BasicEntity}, status {...CodeEntity}}}
        }
    }
    ${BASIC_ENTITY}
    ${CODE_ENTITY}
`;

export const COMMON_SERVICE_FACADE_BEAN_GET_PARAMETER_VALUE = gql`
    query getParameterValue($name: String, $date: Date) {
        commonServiceFacadeBean_parameterValue(name: $name, date: $date)
    }
`;

export const COMMON_SERVICE_FACADE_BEAN_START_COMMUNICATION_WITH_APICS = gql`
    mutation startCommunicationWithApics {
        commonServiceFacadeBean_startCommunicationWithApics
    }
`;

export const COMMON_SERVICE_FACADE_BEAN_STOP_COMMUNICATION_WITH_APICS = gql`
    mutation stopCommunicationWithApics {
        commonServiceFacadeBean_stopCommunicationWithApics
    }
`;

export const COMPANY_SERVICE_GET_COMPANIES_BY_CRITERIA = gql`
    query getCompaniesByCriteria ($param: GetResourcesParameterInput) {
        companyService_companiesByCriteria (param: $param) {
            ...BasicEntity, code, name, objectType
        }
    }
    ${BASIC_ENTITY}
`;

export const COMPANY_SERVICE_SAVE_COMPANY = gql`
    mutation saveCompany ($entity: CompanyInput) {
        companyService_saveCompany (entity: $entity) {
            id
        }
    }
`;


export const COMPENSATION_LOSS_ROUNDING_SERVICE_GET_COMPENSATION_LOSS_ROUNDINGS = gql`
    query getCompensationLossRoundings {
        compensationLossRoundingService_compensationLossRoundings {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const COMPONENT_SERVICE_FIND_ALL_COMPONENTS_ORDER_BY_TYPE_AND_NAME = gql`
    query findAllComponentsOrderByTypeAndName ($searchText: String) {
        componentService_findAllComponentsOrderByTypeAndName (searchText: $searchText) {
            id, currentComponentVersion { name, majorVersion, minorVersion }
        }
    }
`;

export const COMPONENT_VERSION_SERVICE_GET_EVALUATIONS_WITH_REALIZATIONS = gql`
    query getEvaluations ($catalogId: Int, $searchedComponent: String, $person: Int) {
        componentVersionService_evaluationsWithRealizations (catalogId: $catalogId, searchedComponent: $searchedComponent, person: $person) {
            name, id, minorVersion, majorVersion
        }
    }
`;

export const COMPOSITION_GROUP_SERVICE_GET_COMPOSITION_GROUP = gql`
    query getCompositionGroup ($id: Int) {
        compositionGroupService_compositionGroup(id: $id) {
            ${GROUP_WITH_DEFINITION} groups {...CodeEntity, environment, name, owner, contexts {...CodeEntity, environment, name, owner}, properties {...BasicEntity, code, group, value}}
        }
    }
    ${CODE_ENTITY}
    ${BASIC_ENTITY}
`;

export const COMPOSITION_GROUP_SERVICE_SAVE_OR_UPDATE_COMPOSITION_GROUP = gql`
    mutation saveOrUpdateCompositionGroup ($group: CompositionGroupInput, $relations: [GroupRelationInput]) {
        compositionGroupService_saveOrUpdateCompositionGroup(group: $group, relations: $relations)
    }
`;

export const COMMUNICATION_TEST_PROCESS_REGISTRATIONS = gql`
    mutation processRegistrationsFromVirtualEjobMessage($message: String) {
        communicationTest_processRegistrationsFromVirtualEjobMessage(message: $message)
	}
`;

export const COMMUNICATION_TEST_PROCESS_SERVICE_LIST = gql`
    mutation processServiceListFromVirtualApicsCall($serviceList: String) {
        communicationTest_processServiceListFromVirtualApicsCall(serviceList: $serviceList)
	}
`;

export const CONSTRAINT_GROUPS_SERVICE_GET_GROUPS_OVERVIEW = gql`
    query getConstraintGroupsOverview ($fromDate: Date, $registrationType: String, $searchUserText: String, $teamId: Int, $selectableGroupCodes: [String], $userId: Int, $categoryId: Int) {
        constraintGroupsService_constraintGroupsOverview (fromDate: $fromDate, registrationType: $registrationType, searchUserText: $searchUserText, teamId: $teamId, selectableGroupCodes: $selectableGroupCodes, userId: $userId, categoryId: $categoryId) {
            categoryName, groupsConstraints {status, group {groupCode, groupName, isMemberOfGroup, isSelectable}}, registrationTypes, teamName, userName, workPeriods {backgroundColor, fromDate, status, toDate, transparencyPercent, workRosterType}
        }
    }
`;

export const DATA_TYPE_SERVICE_FIND_BY_CODE = gql`
    query getDataTypeByCode($code: String) {
        dataTypeService_findByCode(code: $code) {
            id, code, description
        }
    }
`;

export const DAY_OF_WEEK_INDEX_SERVICE_GET_DAY_OF_WEEK_INDEXES = gql`
    query getDayOfWeekIndexes {
        dayOfWeekIndexService_dayOfWeekIndexes {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const DAY_SERVICE_GET_DAYS = gql`
    query getDays {
        dayService_days {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const DYNAMIC_GROUP_SERVICE_GET_DYNAMIC_GROUP = gql`
    query getDynamicGroup ($id: Int) {
        dynamicGroupService_dynamicGroup(id: $id) {
            ${GROUP_WITH_DEFINITION} sql
        }
    }
    ${CODE_ENTITY}
    ${BASIC_ENTITY}
`;

export const DYNAMIC_GROUP_SERVICE_SAVE_OR_UPDATE_DYNAMIC_GROUP = gql`
    mutation saveOrUpdateDynamicGroup ($group: DynamicGroupInput, $relations: [GroupRelationInput]) {
        dynamicGroupService_saveOrUpdateDynamicGroup(group: $group, relations: $relations)
    }
`;

export const EDUCATION_SERVICE_FACADE_BEAN_GET_REALIZATIONS_BY_CRITERIA = gql`
    query getRealizationsByCriteria ($criteria: RealizationCriteriaInput) {
        educationServiceFacadeBean_realizations (criteria: $criteria) {
            ...BasicEntity, date, dueDate, person, componentVersion {id, name, description, objectType, objectVersion}, objectType
        }
    }
    ${BASIC_ENTITY}
`;

export const EMPLOYEE_AGENDA_SERVICE_GET_AGENDA_DATA_FOR_EMPLOYEE = gql`
    query getDataForEmployee ($employeeId: Int, $fromDate: Date, $toDate: Date, $constraintGroupCode: String, $registrationTypeCode: String, $useOtherAgendaContext: Boolean!, $onlyWaitingRegistrations: Boolean!, $leaveGroupSnapshotsByTimePeriod: Map_String_Collection_GroupSnapshotScalar) {
        employeeAgendaServiceImpl_dataForEmployee (employeeId: $employeeId, fromDate: $fromDate, toDate: $toDate, constraintGroupCode: $constraintGroupCode, registrationTypeCode: $registrationTypeCode, useOtherAgendaContext: $useOtherAgendaContext, onlyWaitingRegistrations: $onlyWaitingRegistrations, leaveGroupSnapshotsByTimePeriod: $leaveGroupSnapshotsByTimePeriod) {
            ...AgendaDayItem
        }
    }
    ${AGENDA_DAY_ITEM}
`;

export const EMPLOYEE_SERVICE_GET_PLANNING_DATA = gql`
    query getPlanningData($fromDate: Date, $untilDate: Date, $resourceFilter: String, $registrationTypesAndStatus: [String], $groupCode: String) {
        employeeService_employeePlanningData(fromDate: $fromDate, untilDate: $untilDate, resourceFilter: $resourceFilter,
            registrationTypesAndStatus: $registrationTypesAndStatus, groupCode: $groupCode) {
            ...Node
            parent {
                ...Node
                parent {
                    ...Node
                    parent {
                        ...Node
                        parent {
                            ...Node
                        } 
                        children {
                            ...Node
                        }
                    }
                    children {
                        ...Node
                        parent {
                            ...Node
                        }
                        children {
                            ...Node
                        }
                    }
                }
                children {
                    ...Node
                    parent {
                        ...Node
                        parent {
                            ...Node
                        }  
                        children {
                            ...Node
                        }
                    }
                    children {
                        ...Node
                        parent {
                          ...Node
                        }
                        children {
                          ...Node
                        }
                    }
                }
            }
            children {
                ...Node
                parent {
                    ...Node
                    parent {
                        ...Node
                        parent {
                            ...Node
                        } 
                        children {
                            ...Node
                        }
                    }
                    children {
                        ...Node
                        parent {
                            ...Node
                        }
                        children {
                            ...Node
                        }
                    }
                }
                children {
                    ...Node
                    parent {
                        ...Node
                        parent {
                            ...Node
                        }
                        children {
                            ...Node
                        }
                    }
                    children {
                        ...Node
                        parent {
                            ...Node
                        }
                        children {
                            ...Node
                        }
                    }
                }
            }
        }
    }
    ${NODE}
`;

export const EMPLOYEE_PLANNING_SERVICE_GET_AVAILABLE_SPARES = gql`
    query getAvailableSpares($shiftCode: String, $category: String, $fromDate: Date, $toDate: Date, $shiftsFromDate: Date, $shiftsToDate: Date) {
        employeePlanningServiceImpl_availableSpares(shiftCode: $shiftCode, category: $category, fromDate: $fromDate, toDate: $toDate, shiftsFromDate: $shiftsFromDate, shiftsToDate: $shiftsToDate) {
            id fullName shift status ship absenceRemovalRequestId
        }
    }
`;

export const EMPLOYEE_PLANNING_SERVICE_REPLACE_EMPLOYEE_ON_SHIFT = gql`
    mutation replaceEmployeeOnShift($newReplacementRegistration: RegistrationInput, $oldReplacementRegistrationId: Int, $absenceRemovalRequestRegistrationId: Int, $type: String, $category: String, $ship: ShipCtInput, $context: RegistrationServiceContextInput, $initialEmployee: Int) {
        employeePlanningServiceImpl_replaceEmployeeOnShift(newReplacementRegistration: $newReplacementRegistration, oldReplacementRegistrationId: $oldReplacementRegistrationId, absenceRemovalRequestRegistrationId: $absenceRemovalRequestRegistrationId, type: $type, category: $category, ship: $ship, context: $context, initialEmployee: $initialEmployee)
    }
`;

export const EMPLOYEE_PLANNING_SERVICE_UPDATE_REGISTRATION_STATUS = gql`
    mutation updateRegistrationStatus($registrationId: Int, $statusCode: String) {
        employeePlanningServiceImpl_updateRegistrationStatus(registrationId: $registrationId, statusCode: $statusCode)
    }
`;

export const EMPLOYEE_SERVICE_GET_EMPLOYEES = gql`
    query getGroupEmployeesByIds($employeesIds: [Int]) {
        employeeService_employees(employeesIds: $employeesIds, getLastContractOnlyIfValid: true) {
            id, name, firstName, detail { contractHistory { employeeNumber } }
        }
    }
`;

export const EMPLOYEE_SERVICE_GET_EMPLOYEE_BY_ID_WITH_CONTRACT = gql`
    query getEmployeeByIdWithContract ($employeeId: Int) {
        employeeService_employee(employeeId: $employeeId, getLastContractOnlyIfValid: true) {
            id, name, firstName, detail {contractHistory {employeeNumber}}
        }
    }
`;

export const EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CRITERIA = gql`
    query getEmployeeSnapshotsByCriteria($criteria: EmployeeCriteriaInput, $date: Date) {
        employeeService_employeeSnapshotsByCriteria(criteria: $criteria, date: $date) {
            id, firstName, name, contractHistoryItem { employeeNumber } objectType
        }
    }
`;

export const EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS = gql`
    query getResourceSnapshots ($searchText: String, $fromDate: Date, $untilDate: Date) {
        employeeService_employeeSnapshots(searchText: $searchText, fromDate: $fromDate, untilDate: $untilDate) {
            id firstName name contractHistoryItem {employeeNumber}
        }
    } 
`;

export const EMPLOYEE_SERVICE_GET_ACCOUNT_BY_PERSON = gql`
    query getAccountByPerson ($personId: Int!){
        employeeService_accountByPerson(personId:$personId) {
            creationDate creationUser environment { company ...CodeEntity }
            failedAttemptsNumber id isGeneratedPassword lastPasswordInvalidDate lastPasswordUpdateDate locked
            modificationDate modificationUser objectVersion password
            passwordHistory { password ...HistoricalEntity } person userName
        }
    }
    ${CODE_ENTITY}
    ${HISTORICAL_ENTITY}
`;

export const EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOT_ON_DATE = gql`
    query getEmployeeSnapshotOnDate ($employeeId: Int, $date: Date) {
        employeeService_employeeSnapshotOnDate(employeeId: $employeeId, date: $date) {
            ...ResourceSnapshot
        }
    }
    ${RESOURCE_SNAPSHOT}
`;

export const EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CONTRACT = gql`
    query getResourceSnapshot ($searchText: String, $fromDate: Date, $untilDate: Date, $includeAllWithValidContract: Boolean) {
        employeeService_employeeSnapshots(searchText: $searchText, fromDate: $fromDate, untilDate: $untilDate, includeAllWithValidContract: $includeAllWithValidContract) {
            id, firstName, name, contractHistoryItem { status { code }, employeeNumber }
        }
    }
`;

export const EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_GROUP = gql`
    query getGroupMembers($fromDate: Date, $untilDate: Date, $groupCode: String) {
        employeeService_employeeSnapshotsByGroup(fromDate: $fromDate, untilDate: $untilDate, groupCode: $groupCode) {
            id, name, firstName, categoryHistoryItem { category { description } }, teamHistoryItem { team { description } }, 
            locationHistoryItem { location { description } }, hourRegimeHistoryItem { hourRegime { description } }
        }
    }
`;

export const EMPLOYEE_SERVICE_GET_GROUP_EMPLOYEES_BY_SQL = gql`
    query getGroupEmployeesBySql($fromDate: Date, $toDate: Date, $sql: String) {
        employeeService_groupEmployees(fromDate: $fromDate, toDate: $toDate, properties: null, sql: $sql) {
            name, firstName
        }
    }
`;

export const EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT = gql`
    query getGroupSnapshotsByContext($owner: Int!, $groupContextCode: String) {
        employeeService_groupSnapshotsByContext(owner: $owner, groupContextCode: $groupContextCode) {
            id, code, name
        }
    }
`;

export const EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT_FOR_PLANNING = gql`
    query getGroupSnapshotsByContextForPlanning($owner: Int!, $context: String, $permissions: [String]) {
        employeeService_groupSnapshotsByContextForPlanning(owner: $owner, context: $context, permissions: $permissions) {
            id, code, name, planningForShips
        }
    }
`;

export const EMPLOYEE_SERVICE_GET_SHIPS_PLANNING_GROUPS = gql`
    query getShipsPlanningGroups($groups: [Int]) {
        employeeService_shipsPlanningGroups(groups: $groups)
    }
`;

export const EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_OF_MEMBERS_FOR_PLANNING = gql`
    query getGroupSnapshotsOfMemberForPlanning ($owner: Int!, $member: Int!, $fromDate: Date, $untilDate: Date, $useExcludeFromSearch: Boolean!) {
        employeeService_groupSnapshotsOfMemberForPlanning(owner:$owner, member:$member, fromDate: $fromDate, untilDate: $untilDate, useExcludeFromSearch: $useExcludeFromSearch) {
            id code name planningForShips
        }
    } 
`;

export const EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_OF_MEMBER = gql`
    query getGroupSnapshotsOfMember ($owner: Int!, $member: Int!, $groupContextCode: String, $fromDate: Date, $untilDate: Date, $useExcludeFromSearch: Boolean!) {
        employeeService_groupSnapshotsOfMember(owner:$owner, member:$member, groupContextCode: $groupContextCode, fromDate: $fromDate, untilDate: $untilDate, useExcludeFromSearch: $useExcludeFromSearch) {
            code, name, description, environment, id, owner, type { code, description }, relation, security, sequence, succession, contraints
            contexts { ...CodeEntity, name, environment, owner }
        }
    } 
    ${CODE_ENTITY}
`;

export const EMPLOYEE_SERVICE_GET_INITIALLY_SELECTED_TYPES_AND_STATUSES_FROM_METADATA = gql`
    query getInitiallySelectedRegistrationTypesAndStatusFromMetadata($metadataContext: String) {
        employeeService_initiallySelectedRegistrationTypesAndStatusFromMetadata(metadataContext:$metadataContext)
    }
`;

export const EMPLOYEE_SERVICE_GET_PERSON_SNAPSHOTS_BY_CRITERIA = gql`
    query getPersonSnapshotsByCriteria($criteria: PersonCriteriaInput, $date: Date) {
        employeeService_personSnapshotsByCriteria(criteria: $criteria, date: $date) {
            id, firstName, name, objectType, company {id, code, name, objectType, objectVersion}
        }
    }
`;

export const EMPLOYEE_SERVICE_GET_REGISTRATION_SNAPSHOTS_CURRICULUM = gql`
    query getRegistrationSnapshotsForCurriculum( $employeeId: Int, $fromDate: Date, $toDate: Date, $registrationTypesAndStatus: [String]) {
        employeeService_registrationSnapshotsForCurriculum(employeeId: $employeeId, fromDate: $fromDate, toDate: $toDate, registrationTypesAndStatus: $registrationTypesAndStatus) {
            a { id resource externId cyclusId environment fromDate toDate workRosterItem
                status { environment ...CodeEntity } type { name environment ...CodeEntity } }
            b { id creationUser creationDate objectVersion modificationDate modificationUser }
        }
    }
    ${CODE_ENTITY}
`;

export const EMPLOYEE_SERVICE_GET_REGISTRATION_TYPE_TREE = gql`
    query getRegistrationTypeTree($getStatuses: Boolean!, $filterVisibleStatuses: Boolean!, $metadataContext: String) {
        employeeService_registrationTypeTree(getStatuses:$getStatuses, filterVisibleStatuses: $filterVisibleStatuses, metadataContext: $metadataContext) {
            ...RegistrationTypeCategory
        }
    }
    ${REGISTRATION_TYPE_CATEGORY}
`;

export const EMPLOYEE_SERVICE_GET_RESOURCE_BY_DATE = gql`
    query getResourceWithDetailByDate($resourceId: Int!, $fromDate: Date, $untilDate: Date) {
        employeeService_resource(resourceId: $resourceId, fromDate: $fromDate, untilDate: $untilDate) {
            ...Resource
        }
    }
    ${RESOURCE}
`;

export const EMPLOYEE_SERVICE_GET_RESOURCE_ITEM = gql`
    query getResourceItemById ($resourceId: Int) {
        employeeService_resourceItem(resourceId: $resourceId) {
            id, name
        }
    }
`;

export const EMPLOYEE_SERVICE_GET_RESOURCE_ITEMS = gql`
    query getResourceItems ($searchedText: String) {
        employeeService_resourceItems (searchedText: $searchedText) {
            id, name
        }
    }
`;

export const EMPLOYEE_SERVICE_GET_SNAPSHOT = gql`
    query getSnapshot($employeeId: Int, $fromDate: Date, $untilDate: Date) {
        employeeService_employeeSnapshotBetween(employeeId: $employeeId, fromDate: $fromDate, untilDate: $untilDate) {
            ...EmployeeSnapshot
        }
    }
    ${EMPLOYEE_SNAPSHOT}
`;

export const EMPLOYEE_SERVICE_REMOVE_PICTURE = gql`
    mutation removePicture($resourceId: Int!) {
        employeeService_removePicture(resourceId: $resourceId)
    }
`;

export const EVALUATIONS_FILL_IN_TEST = gql`
    mutation populateDB {
        evaluationsFillInTest_populateDB
    }
`;

export const FREE_TIME_OVERVIEW_CALCULATOR_CALL_EMPLOYEE = gql`
    mutation callEmployee ($employeeId: Int, $category: String) {
        freeTimeOverviewCalculator_callEmployee(employeeId: $employeeId, date: null, category: $category)
    }
`;

export const FREE_TIME_OVERVIEW_CALCULATOR_CLEAR_CACHE = gql`
    query clearCache {
        freeTimeOverviewCalculator_clearCache
    }
`;

export const FREE_TIME_OVERVIEW_CALCULATOR_GET_CURRENT_FREE_TIME_STATUS_FOR_CURRENT_USER = gql`
    query getCurrentUser {
        freeTimeOverviewCalculator_currentFreeTimeStatusForCurrentUser{
            fromDate, toDate, status,
            unavailableForFreeTaskRegistration{
                fromDate, toDate, environment, bedrijf, cyclusId, externId, melding, resource, workRosterItem, ...BasicEntity, 
                status{ ...RegistrationStatus, type { ...RegistrationType, category { code } } },
                type{ ...RegistrationType, statuses{ ...BasicEntity, status{ ...RegistrationStatus } } } 
            },
            unavailableRegistation{
                fromDate, toDate, type { name }
            }
        }
    }
    ${BASIC_ENTITY}
    ${REGISTRATION_STATUS}
    ${REGISTRATION_TYPE}
`;

export const FREE_TIME_OVERVIEW_CALCULATOR_GET_DATE_UNTIL_NEXT_CHANGE = gql`
    query getDateUntilNextChange {
        freeTimeOverviewCalculator_dateUntilNextChange 
    }
`;

export const FREE_TIME_OVERVIEW_CALCULATOR_GET_CACHE = gql`
    query getCache {
        freeTimeOverviewCalculator_cache {
            freeTimeChanges {id category} changedRosterIds freeTimeItems, rosterRelations, nextRestChange, lastRequestDateBoatmen,
            lastRequestDatePilots, lastUnavailableBoatmen, lastUnavailablePilots, lastUnavailableForFreeTimeTaskBoatmen,
            lastUnavailableForFreeTimeTaskPilots
        }
    }
`;

export const FREE_TIME_OVERVIEW_CALCULATOR_GET_FREE_TIME_OVERVIEW = gql`
    query getFreeTimeOverview ($category: String, $clearCacheForCategory: Boolean) {
        freeTimeOverviewCalculator_freeTimeOverview(date: null, category: $category, clearCacheForCategory: $clearCacheForCategory) {
            employeeId, sequence, employee, category, team, internalTelephone, internalGsm, pilotNumber, municipality, callCount, status, freeTimeSuperGroup
            unavailablePeriods { fromDate, toDate, object },
            absences { fromDate, toDate, type { name } }, 
            exchange { fromDate, toDate, type { name } }
        }
    }
`;

export const FREE_TIME_OVERVIEW_CALCULATOR_SET_EMPLOYEES_AS_FIRST = gql`
    mutation setEmployeesAsFirst($changes: [FreeTimeSetFirstEmployeeParameterInput]){
        freeTimeOverviewCalculator_setEmployeesAsFirst(changes: $changes)
    }
`;

export const GROUP_PROPERTY_CT_SERVICE_GET_GROUP_PROPERTIES_CT = gql`
    query getGroupPropertiesConstants {
        groupPropertyCtService_groupPropertiesCt {
            ...CodeEntity, dataType
        }   
    }
    ${CODE_ENTITY}
`;

export const GROUP_SERVICE_FACADE_BEAN_ADD_CONTEXT_TO_GROUPS = gql`
    mutation addContextToGroups($groupsId: [Int], $contextCode: String){
        groupServiceFacadeBean_addContextToGroups(groupsId: $groupsId, contextCode: $contextCode)
    }
`;

export const GROUP_SERVICE_FACADE_BEAN_FIND_GROUPS = gql`
    query getGroups {
        groupServiceFacadeBean_findGroups {
            id, code, name
        }
    }
`;

export const GROUP_SERVICE_FACADE_BEAN_GET_GROUP = gql`
    query getGroupByGroupId ($id: Int) {
        groupServiceFacadeBean_group(id: $id) {
            ...CodeEntity, environment, contexts {...CodeEntity, environment, name, owner}, name, owner, properties {...BasicEntity, code, group, value}
        }
    }
    ${CODE_ENTITY}
    ${BASIC_ENTITY}
`;

export const GROUP_SERVICE_FACADE_BEAN_GET_GROUP_SNAPSHOT_BY_CODE = gql`
    query getGroupSnapshotByCode ($code: String) {
        groupServiceFacadeBean_groupSnapshot (code: $code) {
            id, code, name
        }
    }
`;

export const GROUP_SERVICE_FACADE_BEAN_GET_GROUPS = gql`
    query getGroupsByGroupsIds($groupIds: [Int]) {
        groupServiceFacadeBean_groups(groupIds: $groupIds) {
            id, code, name, description
        }
    }
`;

export const GROUP_SERVICE_FACADE_BEAN_GET_GROUP_CONTEXT = gql`
    query getGroupContext ($code: String) {
        groupServiceFacadeBean_groupContext(code: $code) {
            ...CodeEntity, name, environment, owner
        }
    }
    ${CODE_ENTITY}
`;

export const GROUP_SERVICE_FACADE_BEAN_GET_GROUP_CONTEXTS = gql`
    query getGroupContexts ($owner: Int) {
        groupServiceFacadeBean_groupContexts(owner: $owner) {
            ...CodeEntity, name, environment, owner
        }
    }
    ${CODE_ENTITY}
`;

export const GROUP_SERVICE_FACADE_BEAN_GET_GROUP_RELATION_TYPES = gql`
    query getGroupRelationTypes {
        groupServiceFacadeBean_groupRelationTypes {
            ...CodeEntity, inLabel, outLabel
        }
    }
    ${CODE_ENTITY}
`;

export const GROUP_SERVICE_FACADE_BEAN_GET_GROUP_SNAPSHOTS = gql`
    query getGroupSnapshots ($owner: Int) {
        groupServiceFacadeBean_groupSnapshots(owner: $owner) {
            code, name, description, environment, id, owner, type { code, description }, relation, security, sequence, succession, contraints
            contexts { ...CodeEntity, name, environment, owner }
        }
    }
    ${CODE_ENTITY}
`;

export const GROUP_SERVICE_FACADE_BEAN_GET_PARENT_GROUPS = gql`
    query getParentGroups ($child: Int) {
        groupServiceFacadeBean_parentGroups(child: $child) {
            id
        }
    }
`;

export const GROUP_SERVICE_FACADE_BEAN_GET_PERSONS = gql`
query getPersons ($groupCode: String, $fromDate: Date, $toDate: Date) {
    groupServiceFacadeBean_persons(groupCode: $groupCode, fromDate: $fromDate, toDate: $toDate) {
        name firstName telecomList {type {code} value}
    }
}
`;

export const GROUP_SERVICE_FACADE_BEAN_REMOVE_CONTEXT_FROM_GROUPS = gql`
    mutation removeContextFromGroups($groupsId: [Int], $contextId: Int){
        groupServiceFacadeBean_removeContextFromGroups(groupsId: $groupsId, contextId: $contextId)
    }
`;

export const GROUP_SERVICE_FACADE_BEAN_REMOVE_GROUP = gql`
    mutation removeGroup ($id: Int) {
        groupServiceFacadeBean_removeGroup(id: $id)
    }
`;

export const GROUP_SERVICE_FACADE_BEAN_REMOVE_GROUPS = gql`
    mutation removeGroups ($ids: [Int]) {
        groupServiceFacadeBean_removeGroups(ids: $ids)
    }
`;

export const GROUP_SERVICE_FACADE_BEAN_REMOVE_GROUP_CONTEXT = gql`
    mutation removeGroupContext ($id: Int) {
        groupServiceFacadeBean_removeGroupContext(id: $id)
    }
`;

export const GROUP_SERVICE_FACADE_BEAN_SAVE_GROUP_PROPERTY = gql`
    mutation saveGroupProperty($entity: GroupPropertyInput) {
        groupPropertyService_saveGroupProperty(entity: $entity) {
            id
        }
    }
`;

export const GROUP_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_GROUP_CONTEXT = gql`
    mutation saveOrUpdateGroupContext ($groupContext: GroupContextInput) {
        groupServiceFacadeBean_saveOrUpdateGroupContext(groupContext: $groupContext)
    }
`;

export const GROUP_SNAPSHOT_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT = gql`
    query getSearchedGroupSnapshotsByContext ($owner: Int!, $groupContextCode: String, $searchedGroupSnapshot: String) {
        groupSnapshotService_groupSnapshotsByContext (owner: $owner, groupContextCode: $groupContextCode, searchedGroupSnapshot: $searchedGroupSnapshot) {
            id, code, name
        }
    }
`;

export const HALF_DAY_SERVICE_GET_HALF_DAYS = gql`
    query getHalfDays {
        halfDayService_halfDays {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const HALF_DAY_SERVICE_GET_HALF_DAY_BY_CODE = gql`
    query getHalfDayByCode ($code: String) {
        halfDayService_halfDayByCode (code: $code) {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const HOUR_REGIME_SERVICE_GET_HOUR_REGIME_BY_ID = gql`
    query getHourRegimeById ($id: Int) {
        hourRegimeService_hourRegimeById (id: $id) {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const LOCATION_SERVICE_GET_LOCATION_BY_ID = gql`
    query getLocationById ($id: Int) {
        locationService_locationById (id: $id) {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const METADATA_CONTEXT_SERVICE_FACADE_BEAN_SAVE_METADATA_CONTEXT = gql`
    mutation saveOrUpdateMetadataContext($context: MetadataContextInput) {
        metadataContextService_saveMetadataContext(context: $context){
            id
        }
    }
`;

export const MONTHS_SERVICE_GET_MONTHS = gql`
    query getMonths {
        monthService_months {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const PARAMETER_MANAGER_SERVICE_FACADE_BEAN_GET_DATA_TYPES = gql`
    query getDataTypes{
        parameterManagerServiceFacadeBean_dataTypes{
            id, code, description, objectVersion
        }
    }
`;

export const PARAMETER_SERVICE_SAVE_PARAMETER = gql`
    mutation saveOrUpdateParameter ($entity: ParameterInput) {
        parameterService_saveParameter(entity: $entity){
            id
        }
    }
`;

export const PERSON_SERVICE_FIND_BY_ID = gql`
    query findPersonById($id: Int) {
        personService_findById(id: $id) {
            id, name, firstName
        }
    }
`;

export const PERSON_SERVICE_FIND_BY_IDS = gql`
    query findPersonByIds($ids: [Int]) {
        personService_findByIds(ids: $ids) {
            id, name, firstName
        }
    }
`;

export const PILOT_PROGRESS_HISTORY_ITEM_SERVICE_SAVE_PILOT_PROGRESS_HISTORY_ITEM = gql`
    mutation savePilotProgressHistoryItem($entity: PilotProgressHistoryItemInput) {
        pilotProgressHistoryItemService_savePilotProgressHistoryItem(entity: $entity) {
            id
        }
    }
`;

export const PLANNING_SERVICE_ENDPOINT_GET_REGISTRATION_TYPES_BY_LAYOUT = gql`
    query workPeriodTypes {
        planningServiceEndpoint_registrationTypes(layoutTypeCode: "WERK_PERIODE") {
            id, code, name
        }
    }
`;

export const PLANNING_SERVICE_ENDPOINT_GET_REGISTRATION_TYPES_WITH_CUSTOM_PROPERTY = gql`
    query registrationTypes($property: String, $value: String, $searchedRegistrationType: String) {
        planningServiceEndpoint_registrationTypesWithProperty(property: $property, value: $value, searchedRegistrationType: $searchedRegistrationType) {
            id, code, name, description
        }
    }
`;

export const PLANNING_SERVICE_ENDPOINT_IS_CONSTRAINED_REGISTRATION_TYPE = gql`
    query constrainedRegistrationType($parameter: IsContrainedRegistrationTypeInput) {
        planningServiceEndpoint_constrainedRegistrationType(parameter: $parameter)
    }
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_BALANCE = gql`
    query getBalance($person: Int, $toDate: Date, $balanceType: String, $includeLeaveDistribution: Boolean!) {
        planningServiceFacadeBean_balance(person: $person, toDate: $toDate, balanceType: $balanceType, includeLeaveDistribution: $includeLeaveDistribution) {
            ...BalanceItem
        }
    }
    ${BALANCE_ITEM}
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_BALANCE_INCLUDE_DEDUCTION = gql`
    query getBalanceWithDeduction($person: Int, $toDate: Date, $balanceType: String, $includeLeaveDistribution: Boolean!, $includeDeductionInfo: Boolean!) {
        planningServiceFacadeBean_balance(person: $person, toDate: $toDate, balanceType: $balanceType, includeLeaveDistribution: $includeLeaveDistribution, includeDeductionInfo: $includeDeductionInfo) {
            ...BalanceItem
        }
    }
    ${BALANCE_ITEM}
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_LAYOUT_TYPES = gql`
    query getLayoutTypes {
        planningServiceFacadeBean_layoutTypes {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_LEAVE_DISTRIBUTION_BALANCE = gql`
    query getLeaveDistributionBalance($person: Int, $toDate: Date, $balanceType: String) {
        planningServiceFacadeBean_leaveDistributionBalance(person: $person, toDate: $toDate, balanceType: $balanceType) {
            code, description, counters {code, description, value}
        }
    }
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_LEAVE_DISTRIBUTION_CALCULATION_RELATIONS = gql`
    query getLeaveDistributionCalculationRelation($registrationAsJson: String) {
        planningServiceFacadeBean_leaveDistributionCalculationRelationsWithJsonRegistration(registrationAsJson: $registrationAsJson) {
            ...BasicEntity, result, year, calculation {...CodeEntity, persist}
        }
    }
    ${BASIC_ENTITY}
    ${CODE_ENTITY}
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_OCCUPATION_OVERVIEW = gql`
    query getOccupationOverview($date: Date, $registrationTypeCode: String, $calculateLoa: Boolean, $skipVelofExchanged: Boolean, $resourceFilter: [Int], $dayOccuppation: Boolean!, $groupCode: String) {
        planningServiceFacadeBean_occupationOverview(date: $date, registrationTypeCode: $registrationTypeCode, calculateLoa: $calculateLoa, skipVelofExchanged: $skipVelofExchanged, resourceFilter: $resourceFilter, dayOccuppation: $dayOccuppation, groupCode: $groupCode) {
            ...Node
        }
    }
    ${NODE}
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_CONSTRAINT_COUNTER_OVERVIEW = gql`
    query getConstraintCounterOverview($date: Date, $registrationTypeCode: String, $calculateLoa: Boolean, $skipVelofExchanged: Boolean, $resourceFilter: [Int], $dayOccuppation: Boolean!, $groupCode: String) {
        planningServiceFacadeBean_constraintCounterOverview(date: $date, registrationTypeCode: $registrationTypeCode, calculateLoa: $calculateLoa, skipVelofExchanged: $skipVelofExchanged, resourceFilter: $resourceFilter, dayOccuppation: $dayOccuppation, groupCode: $groupCode) {
            counterTitle
            items {
                registrationFromDate registrationToDate registrationType employeeName group status
            }
        }
    }
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_PILOT_PREMIUM_OVERVIEW = gql`
    query getPilotPremiumOverview($fromDate: Date, $resource: Int, $toDate: Date) {
        planningServiceFacadeBean_pilotPremiumOverview(fromDate: $fromDate, resource: $resource, toDate: $toDate) {
            pilotingTaskItems { agent { code, name }, annulation, babySuperPilot, choicePilot, employeeNumber, endDate,
                experienceScale, from { code, name }, loaScale, newExperienceScale, newLoaScale, newTaskCounter, premium { babySuperPilot, piloting, premium, regime, superPilot }, 
                regime, registrationType { id, code, description, name }, role { code, description }, route, seaCanal, 
                ship { breadth, code, loa, name }, startDate, superPilot, taskCounter, to { code, name }},
            resource
        }
    }
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_PILOT_PROGRESSION_HISTORY = gql`
    query getPilotProgressionHistory($pilot: Int) {
        planningServiceFacadeBean_pilotProgressionHistory(pilot: $pilot) {
            ...BasicEntity, category { ...CodeEntity, months, order, tasks, totalTasks, relationType { ...CodeEntity }},
            extras, generated, owner { id }, tasks, totalTasks, validFrom, validUntil
        }
    }
    ${BASIC_ENTITY}
    ${CODE_ENTITY}
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_PREMIUM_HISTORY = gql`
    query getPremiumHistory($resource: Int) {
        planningServiceFacadeBean_premiumHistory(resource: $resource) {
            ...BasicEntity, resource, taskCounter, validFrom, validUntil, record { ...CodeEntity }
        }
    }
    ${BASIC_ENTITY}
    ${CODE_ENTITY}
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_STATUSES_BY_TYPE = gql`
    query getRegistrationStatusesByType ($type: RegistrationTypeInput) {
        planningServiceFacadeBean_registrationStatuses(type: $type) {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const PLANNING_SERVICE_FACADE_BEAN_MATCHING_ROUNDING_TYPE = gql`
    query matchingRoundingType ($actualToDate: Date, $resource: Int, $actualFromDate: Date) {
        planningServiceFacadeBean_matchingRoundingType (actualToDate: $actualToDate, resource: $resource, actualFromDate: $actualFromDate) {
            halfDay, roundingType { ...CodeEntity }
        }
    }
    ${CODE_ENTITY}
`;


export const PLANNING_SERVICE_FACADE_BEAN_GET_SELF_SERVICE_REGISTRATION_TYPES = gql`
    query getSelfServiceRegistrationTypes ($context: String) {
        planningServiceFacadeBean_selfServiceRegistrationTypes (context: $context) {
            ...CodeEntity, name 
        }
    }
    ${CODE_ENTITY}
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_MEDICAL_CHECKUP_TYPES = gql`
    query getMedicalCheckupTypes {
        planningServiceFacadeBean_medicalCheckupTypes {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;


export const PLANNING_SERVICE_FACADE_BEAN_GET_METADATA_CONTEXT_BY_ID = gql`
    query getContext($id: Int) {
        planningServiceFacadeBean_metadataContext(id: $id) {
            id, name
        }
    }
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION = gql`
    query getRegistration_planningService($id: Int) {
        planningServiceFacadeBean_registration(id: $id) {
            resource fromDate toDate
        }
    }
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_JSON = gql`
    query getRegistrationAsJson($id: Int) {
        planningServiceFacadeBean_registrationAsJson(id: $id) 
    }
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_SNAPSHOTS = gql`
    query getRegistrationSnapshots ($criteria: RegistrationCriteriaInput) {
        planningServiceFacadeBean_registrationSnapshots(criteria: $criteria) {
            ...RegistrationSnapshot
        } 
    }
    ${REGISTRATION_SNAPSHOT}
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_STATUS_BY_CODE = gql`
    query getRegistrationStatus($code: String) {
        planningServiceFacadeBean_registrationStatus(code: $code) {
            ...CodeEntity, environment, type {id, code}
        }
    }
    ${CODE_ENTITY}
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_BY_CODE = gql`
    query getRegistrationType($code: String) {
        planningServiceFacadeBean_registrationType(code: $code) {
            id, code, description, name
        }
    }
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_SHORTCUTS = gql`
    query getRegistrationTypeShortcuts($resource: Int) {
        planningServiceFacadeBean_registrationTypeShortcuts(resource: $resource) {
            ...BasicEntity, resource, type {...RegistrationType}
        }
    }
    ${BASIC_ENTITY}
    ${REGISTRATION_TYPE}
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_ROUNDING_VALUE = gql`
    query getRoundingValue($resource: Int, $fromDate: Date, $toDate: Date, $roundingType: RoundingTypeInput, $halfDayRounding: String) {
        planningServiceFacadeBean_roundingValue(resource: $resource, fromDate: $fromDate, toDate: $toDate, roundingType: $roundingType, halfDayRounding: $halfDayRounding) {
            fromDate, toDate, rosterHasHalfDay
        }
    }
`;

export const PLANNING_SERVICE_FACADE_BEAN_GET_WAITING_BALANCE = gql`
    query getWaitingBalance($resource: Int, $toDate: Date, $registrationTypeCode: String) {
        planningServiceFacadeBean_waitingBalance(resource: $resource, toDate: $toDate, registrationTypeCode: $registrationTypeCode) {
            code description value
        }
    }
`;

export const PLANNING_SERVICE_FACADE_BEAN_REMOVE_REGISTRATION_BY_ID = gql`
    mutation removeRegistrationById($registration: Int, $context: RegistrationServiceContextInput) {
        planningServiceFacadeBean_removeRegistrationById(registration: $registration, context: $context) {
            removedItems { id }
        }
    }
`;

export const PLANNING_SERVICE_FACADE_BEAN_REMOVE_REGISTRATIONS_BY_ID = gql`
    mutation removeRegistrationsById($registrations: [Int], $context: RegistrationServiceContextInput) {
        planningServiceFacadeBean_removeRegistrationsById(registrations: $registrations, context: $context) {
            removedItems { id }
        }
    }
`;

export const PLANNING_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_PREMIUM_HISTORY = gql`
    mutation saveOrUpdatePremiumHistory($resource: Int, $premiumHistory: [PremiumHistoryItemInput]) {
        planningServiceFacadeBean_saveOrUpdatePremiumHistory(resource: $resource, premiumHistory: $premiumHistory) {
            ...BasicEntity, resource, taskCounter, validFrom, validUntil, record { ...CodeEntity }
        }
    }
    ${BASIC_ENTITY}
    ${CODE_ENTITY}
`;

export const PLANNING_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_REGISTRATION = gql`
    mutation saveRegistration($registrationAsJson: String, $context: RegistrationServiceContextInput) {
        planningServiceFacadeBean_saveOrUpdateRegistration(registrationAsJson: $registrationAsJson, context: $context){
            createdItems { ...RegistrationSnapshot } initialCreatedItem { ...RegistrationSnapshot }
            registrationIdToWaitingRegistrationMap
        }
    }
    ${REGISTRATION_SNAPSHOT}
`;

export const PLANNING_SERVICE_FACADE_BEAN_SWITCH_REGISTRATION = gql`
    mutation switchRegistration($registration: RegistrationInput, $supporter: Int, $switchDate: Date) {
        planningServiceFacadeBean_switchRegistration(registration: $registration, supporter: $supporter, switchDate: $switchDate) {
            removedItems { id }
        }
    }
`;

export const PLANNING_SERVICE_SAVE_OR_UPDATE_REGISTRATION = gql`
    mutation saveOrUpdateRegistration($parameter: SaveOrUpdateRegistrationParameterInput) {
        planningService_saveOrUpdateRegistration(parameter: $parameter)
    }
`;

export const PREMIUM_PARAMETER_SERVICE_SAVE_PREMIUM_PARAMETER = gql`
    mutation saveOrUpdatePremiumParameter ($entity: PremiumParameterInput) {
        premiumParameterService_savePremiumParameter(entity: $entity) {
            id
        }
    }
`;

export const PREMIUM_SUSPENSION_SERVICE_GET_PREMIUM_SUSPENSION_HISTORY = gql`
    query getPremiumSuspensionHistory ($resource: Int) {
        premiumSuspensionHistoryItemService_findAllByResource(resource: $resource) {
            validFrom, validUntil, ...BasicEntity
        }
    }
    ${BASIC_ENTITY}
`;

export const PREMIUM_SUSPENSION_SERVICE_SAVE_OR_UPDATE_PREMIUM_SUSPENSION_HISTORY = gql`
    mutation saveOrUpdatePremiumSuspensionHistory($resource: Int, $premiumSuspensionHistory: [PremiumSuspensionHistoryItemInput]) {
        premiumSuspensionHistoryItemService_saveOrUpdatePremiumSuspensionHistory(resource: $resource, premiumSuspensionHistory: $premiumSuspensionHistory) {
            ...BasicEntity, resource, validFrom, validUntil
        }
    }
    ${BASIC_ENTITY}
`;

export const PROPERTY_CATEGORY_SERVICE_FIND_BY_CODE = gql`
    query getPropertyCategoryByCode($code: String) {
        propertyCategoryService_findByCode(code: $code) {
            id, code, description
        }
    }
`;

export const REGISTRATION_METADATA_SERVICE_ENDPOINT_BEAN_GET_METADATA_PROPERTY_VALUE_FOR_TYPES = gql`
    query getMetadataPropertyValueForTypes($registrationTypes: [String], $context: String, $propertyNames: String) {
        registrationMetadataServiceEndpoint_metadataPropertyValueForTypes(registrationTypes: $registrationTypes, context: $context, propertyNames: $propertyNames)
    }
`;

export const REGISTRATION_METADATA_SERVICE_FACADE_BEAN_GET_METADATA_CONTEXTS_BY_ENVIRONMENT = gql`
    query getMetadataContext($environment: Int) {
        registrationMetadataServiceFacadeBean_metadataContexts(environment: $environment) {
            ...CodeEntity, name, environment
        }
    }
    ${CODE_ENTITY}
`;

export const REGISTRATION_METADATA_SERVICE_FACADE_BEAN_GET_METADATA_PROVIDER = gql`
    query getMetadataProvider {
        registrationMetadataServiceFacadeBean_metadataProvider {
           allChaches properties typePropertyCodes
        }
    }
`;

export const REGISTRATION_METADATA_SERVICE_FACADE_BEAN_GET_PROPERTIES_VALUES = gql`
    query getPropertiesValues($criteria: PropertyCriteriaInput) {
        registrationMetadataServiceFacadeBean_propertiesValues(criteria: $criteria) {
            contextValue{...BasicEntity, context, environment, value, registrationType, registrationStatus, property{...Property}},
            systemValue{...BasicEntity, context, environment, value, registrationType, registrationStatus, property{...Property}},
            userValue{...BasicEntity, context, value, user, systemPropertyValue}
        }
    }
    ${BASIC_ENTITY}
    ${PROPERTY}
`;

export const REGISTRATION_METADATA_SERVICE_FACADE_BEAN_REMOVE_METADATA_CONTEXT = gql`
    mutation deleteMetadataContext($context: MetadataContextInput) {
        registrationMetadataServiceFacadeBean_removeMetadataContext(context: $context)
    }
`;

export const REGISTRATION_METADATA_SERVICE_FACADE_BEAN_REMOVE_TYPE_STATUS_RELATION_AND_PROPERTIES = gql`
    mutation removeTypeStatusRelation($systemValues: [PropertyValueInput], $relation: RegistrationTypeStatusRelationInput) {
        registrationMetadataServiceFacadeBean_removeTypeStatusRelationAndProperties(systemValues: $systemValues, relation: $relation)
    }
`;

export const REGISTRATION_METADATA_SERVICE_FACADE_BEAN_SAVE_PROPERTY_ENTITIES_WITH_PROPERTY_VALUE = gql`
    mutation SaveOrUpdatePropertyEntitiesForSystemOrContext($entities: [PropertyValueInput]) {
        registrationMetadataServiceFacadeBean_saveOrUpdatePropertyEntitiesWithPropertyValue(entities: $entities)
    }
`;

export const REGISTRATION_METADATA_SERVICE_FACADE_BEAN_SAVE_PROPERTY_ENTITIES_WITH_USER_VALUE = gql`
    mutation SaveOrUpdatePropertyEntitiesForUser($entities: [UserValueInput]) {
        registrationMetadataServiceFacadeBean_saveOrUpdatePropertyEntitiesWithUserValue(entities: $entities)
    }
`;

export const REGISTRATION_METADATA_SERVICE_FACADE_BEAN_SAVE_TYPE_STATUS_RELATION = gql`
    mutation saveTypeStatusRelation($relation: RegistrationTypeStatusRelationInput) {
        registrationMetadataServiceFacadeBean_saveTypeStatusRelationAndCreateSystemValues(relation: $relation)
    }
`;

export const REGISTRATION_SERVICE_FACADE_BEAN_GET_TRAINING_RELATIONS = gql`
    query getTrainingRelations($registration: Int) {
        registrationServiceFacadeBean_trainingRelations(registration: $registration) {
            parentRegistration {...BasicEntity, resource}, registration {...BasicEntity, resource}, participantName
        }
    }
    ${BASIC_ENTITY}
`;

export const REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_STATUSES = gql`
    query getRegistrationStatuses {
        registrationServiceFacadeBean_registrationStatuses {
            ...CodeEntity, environment, type {id}
        }
    }
    ${CODE_ENTITY}
`;

export const REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_STATUS_RELATION = gql`
    query getRegistrationStatusRelation($type: RegistrationTypeInput) {
        registrationServiceFacadeBean_registrationTypeStatusRelations(type: $type) {
            id, status { id, code, description }
        }
    }
`;

export const REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPES = gql`
    query getRegistrationTypes {
        registrationServiceFacadeBean_registrationTypes {
            ...CodeEntity, name, environment
        }
    }
    ${CODE_ENTITY}
`;

export const REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPES_BY_LAYOUT_AND_CONTEXT = gql`
    query getRegistrationTypesByContext($layoutType: String, $context: String) {
        registrationServiceFacadeBean_registrationTypesByContext(layoutType: $layoutType, context: $context) {
            id, code, name
        }
    }
`;

export const REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_TREE_FOR_PLANNING = gql`
    query getRegistrationTypeTreeForPlanning($getStatuses: Boolean!, $filterVisibleStatuses: Boolean!, $metadataContext: String) {
        registrationServiceFacadeBean_registrationTypeTreeForPlanning(getStatuses:$getStatuses, filterVisibleStatuses: $filterVisibleStatuses, metadataContext: $metadataContext) {
            a {...RegistrationTypeCategory} b {...RegistrationType}
        }
    }
    ${REGISTRATION_TYPE}
    ${REGISTRATION_TYPE_CATEGORY}
`;

export const REGISTRATION_SERVICE_FACADE_BEAN_MAP_REGISTRATIONS_BY_ID = gql`
    mutation mapRegistrationsByIds($registrationIds: [Int], $context: RegistrationServiceContextInput) {
        registrationServiceFacadeBean_mapRegistrationsByIds(registrationIds: $registrationIds, context: $context) {
            updatedItems { id }
        }
    }
`;

export const REGISTRATION_SERVICE_FACADE_BEAN_MERGE_REGISTRATIONS_BY_ID = gql`
    mutation mergeRegistrationsByIds($registrationIds: [Int], $context: RegistrationServiceContextInput) {
        registrationServiceFacadeBean_mergeRegistrationsByIds(registrationIds: $registrationIds, context: $context) {
            updatedItems { id }
        }
    }
`;

export const REGISTRATION_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_REGISTRATION_TYPE = gql`
    mutation saveOrUpdateRegistrationType($type: RegistrationTypeInput) {
        registrationServiceFacadeBean_saveOrUpdateRegistrationType(type: $type){
            id, code, name
        }
    }
`;

export const REGISTRATION_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_REGISTRATION_TYPE_CATEGORY = gql`
    mutation saveOrUpdateRegistrationTypeCategory($category: RegistrationTypeCategoryInput) {
        registrationServiceFacadeBean_saveOrUpdateRegistrationTypeCategory(category: $category){
            id, code, name
        }
    }
`;

export const REGISTRATION_SERVICE_FACADE_BEAN_REMOVE_REGISTRATION_TYPE = gql`
    mutation deleteRegistrationType($registrationType: RegistrationTypeInput) {
        registrationServiceFacadeBean_removeRegistrationType(registrationType: $registrationType)
    }
`;

export const REGISTRATION_SERVICE_FACADE_BEAN_REMOVE_REGISTRATION_TYPE_CATEGORY = gql`
    mutation deleteRegistrationTypeCategory($registrationTypeCategory: RegistrationTypeCategoryInput) {
        registrationServiceFacadeBean_removeRegistrationTypeCategory(registrationTypeCategory: $registrationTypeCategory)
    }
`;

export const REGISTRATION_TYPE_SHORTCUT_SERVICE_ADD_SHORTCUT = gql`
    mutation addShortcut($shortcut: RegistrationTypeShortcutInput) {
        registrationTypeShortcutService_saveShortcut(shortcut: $shortcut) {
            id
        }
    }
`;

export const REGISTRATION_TYPE_SHORTCUT_SERVICE_DELETE_BY_ID = gql`
    mutation deleteById($id: Int) {
        registrationTypeShortcutService_deleteById(id: $id)
    }
`;

export const REGISTRATION_TYPE_STATUS_RELATION_SERVICE_GET_STATUSES_FOR_TYPE = gql`
    query getStatusesForTypes($typeCodes: [String]) {
        registrationTypeStatusRelationService_statusesForTypes(typeCodes: $typeCodes) {
            id code description objectVersion
        }
    }
`;

export const REPORT_DEFINITION_ADMIN_SERVICE_SAVE = gql`
    mutation createOrUpdateReportDefinition ($entity: ReportDefinitionInput) {
        reportDefinitionAdminService_save(reportDefinition: $entity){
            id
        }
    }
`;

export const REPORT_DEFINITION_SERVICE_GET_RESOURCE_SNAPSHOTS = gql`
    query getResourcesSnapshots ($param: GetResourcesParameterInput) {
        reportDefinitionService_resourceSnapshots(param: $param) {
            id, name, firstName, contractHistoryItem { employeeNumber }
        }
    }
`;

export const REPORTING_SERVICE_FACADE_BEAN_GET_REPORT_DEFINITION = gql`
    query getReportDefinitionByCode ($code: String) {
        reportingServiceFacadeBean_reportDefinition (code: $code) {
            id, code, parameters { completeName, id, prompting, type, defaultValue, label, securityRoleForEditingParameter }
        }
    }
`;

export const REPORTING_SERVICE_FACADE_BEAN_GET_REPORT_DEFINITION_BY_ID = gql`
    query getReportDefinitionById ($id: Int) {
        reportingServiceFacadeBean_reportDefinitionById(id: $id) {
            parameters { completeName, id, prompting, type, defaultValue, label, securityRoleForEditingParameter, mandatory }
        }
    }
`;

export const RESOURCE_WORK_ROSTER_SERVICE_GET_BY_RESOURCE_ID = gql`
    query getResourceWorkRosters ($resourceId: Int) {
        resourceWorkRosterService_resourceWorkRosters (resourceId: $resourceId) {
            ...HistoricalEntity offset resource status {...RegistrationStatus}
        }
    }
    ${HISTORICAL_ENTITY}
    ${REGISTRATION_STATUS}
`;

export const RESOURCES_SERVICE_ENDPOINT_GET_CATEGORIES = gql`
    query category {
        resourcesServiceEndpoint_categories {
            id, description
        }
    }
`;

export const RESOURCES_SERVICE_ENDPOINT_GET_GROUP_SNAPSHOTS_BY_IDS = gql`
    query getGroupSnapshotsByIds ($owner: Int, $groupIds: String) {
        resourcesServiceEndpoint_groupSnapshotsByIds (owner: $owner, groupIds: $groupIds) {
            id code name
        }
    }
`;

export const RESOURCES_SERVICE_ENDPOINT_GET_TEAMS = gql`
    query team {
        resourcesServiceEndpoint_teams {
            id, description
        }
    }
`;

export const RESOURCES_SERVICE_ENDPOINT_GET_RESOURCES = gql`
    query getResources ($param: GetResourcesParameterInput) {
        resourcesServiceEndpoint_resources (param: $param) {
            ...Resource
        }
    }
    ${RESOURCE}
`;

export const RESOURCES_SERVICE_ENDPOINT_GET_SELECTABLE_LEAVE_GROUPS_DATA = gql`
    query getSelectableLeaveGroupsDataEndpoint ($resource: Int, $fromDateString: String, $toDateString: String, $registrationTypeCode: String) {
        resourcesServiceEndpoint_selectableLeaveGroupsData (resource: $resource, fromDateString: $fromDateString, toDateString: $toDateString, registrationTypeCode: $registrationTypeCode) {
            allowNull, disableDropDown, nonOverlappingGroups, preselectedGroupSnapshotCode, splitDate, leaveGroupSnapshotsForReact {id, code, name}, leaveGroupSnapshotsByTimePeriod
        }
    }
`;

export const RESOURCES_SERVICE_FACADE_BEAN_GET_CONSTRAINED_REGISTRATION_TYPES = gql`
    query getConstrainedRegistrationTypes ($resource: Int, $fromDate: Date, $toDate: Date, $registrationTypeCodes: [String]) {
        resourcesServiceFacadeBean_constrainedRegistrationTypes (resource: $resource, fromDate: $fromDate, toDate: $toDate, registrationTypeCodes: $registrationTypeCodes)
    }
`;

export const RESOURCES_SERVICE_FACADE_BEAN_GET_CATEGORY_BY_ID = gql`
    query getCategoryById ($id: Int) {
        resourcesServiceFacadeBean_category (id: $id) {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const RESOURCES_SERVICE_FACADE_BEAN_GET_COMPANY_SNAPSHOT = gql`
    query getCompanySnapshot($id: Int) {
        resourcesServiceFacadeBean_companySnapshot(id: $id) {
            id, name
        }
    }
`;

export const RESOURCES_SERVICE_FACADE_BEAN_GET_COMPANY_SNAPSHOT_BY_CODE = gql`
    query getCompanySnapshotBycode($code: String) {
        resourcesServiceFacadeBean_companySnapshot(code: $code) {
            id, name, code
        }
    }
`;

export const RESOURCES_SERVICE_FACADE_BEAN_GET_ENVIRONMENT_BY_ID = gql`
    query getEnvironment($id: Int){
        resourcesServiceFacadeBean_environment(id: $id){
            id, description
        }
    }
`;

export const RESOURCES_SERVICE_FACADE_BEAN_GET_GROUP_RELATIONS = gql`
    query getGroupRelations ($relationType: String, $groupIds: [Int]) {
        resourcesServiceFacadeBean_groupRelations(relationType: $relationType, groupIds: $groupIds) {
            ...BasicEntity, source, target, type {...CodeEntity, inLabel, outLabel}
        }
    }
    ${CODE_ENTITY}
    ${BASIC_ENTITY}
`;

export const RESOURCES_SERVICE_FACADE_BEAN_GET_GROUP_RELATIONS_OR_NULL = gql`
    query getGroupRelationsOrNull ($relationType: String, $groupIds: [Int]) {
        resourcesServiceFacadeBean_groupRelationsOrNull(relationType: $relationType, groupIds: $groupIds) {
            ...BasicEntity, source, target, type {...CodeEntity, inLabel, outLabel}
        }
    }
    ${CODE_ENTITY}
    ${BASIC_ENTITY}
`;

export const RESOURCES_SERVICE_FACADE_BEAN_GET_GROUP_SNAPSHOTS_OF_MEMBER_FOR_AGENDA = gql`
    query getGroupSnapshotsOfMemberForAgenda ($owner: Int, $date: String, $groupContextCode: String, $member: Int) {
        resourcesServiceFacadeBean_groupSnapshotsOfMemberForAgenda (owner: $owner, date: $date, groupContextCode: $groupContextCode, member: $member) {
            id
        }
    }
`;

export const RESOURCES_SERVICE_FACADE_BEAN_GET_SELECTABLE_LEAVE_GROUPS_DATA = gql`
    query getSelectableLeaveGroupsData ($resource: Int, $fromDate: Date, $toDate: Date, $registrationTypeCode: String) {
        resourcesServiceFacadeBean_selectableLeaveGroupsData (resource: $resource, fromDate: $fromDate, toDate: $toDate, registrationTypeCode: $registrationTypeCode) {
            allowNull, disableDropDown, nonOverlappingGroups, preselectedGroupSnapshotCode, splitDate, leaveGroupSnapshotsForReact {id, code, name}
        }
    }
`;

export const RESOURCES_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_EMPLOYEE = gql`
    mutation saveOrUpdateEmployee($resource: EmployeeInput, $resourceRosters: [ResourceRosterRelationInput], $resourceWorkRosters: [ResourceWorkRosterInput], $errorPolicy: ErrorMessagePolicyInput) {
        resourcesServiceFacadeBean_saveOrUpdateEmployee(resource: $resource, resourceRosters: $resourceRosters, resourceWorkRosters: $resourceWorkRosters, errorPolicy: $errorPolicy) 
    }
`;

export const ROTATION_SYSTEM_SERVICE_GET_ROTATION_SYSTEMS = gql`
    query getRotationSystems {
        rotationSystemService_rotationSystems {
            id, code, description, objectVersion
        }
    }
`;

export const ROUNDING_TYPE_SERVICE_GET_ROUNDING_TYPE_BY_CODE = gql`
    query getRoundingTypeByCode ($code: String) {
        roundingTypeService_roundingTypeByCode (code: $code) {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const ROUNDING_TYPE_SERVICE_GET_ROUNDING_TYPES = gql`
    query getRoundingTypes {
        roundingTypeService_roundingTypes {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const ROSTER_SERVICE_FIND_ROSTER_BY_ID = gql `
    query findRosterById($id: Int) {
        rosterService_findRosterById(id: $id) {
            ...BasicEntity code description definitionAsString
        }
    }${BASIC_ENTITY}
`;

export const SALARY_COMPONENT_SERVICE_SAVE_SALARY_COMPONENT = gql `
    mutation saveSalaryComponent($entity: SalaryComponentInput, $addedValues: [SalaryComponentValueInput] , $deletedValues: [Int]) {
        salaryComponentService_saveSalaryComponent(entity: $entity, addedValues: $addedValues, deletedValues: $deletedValues) {
            ...CodeEntity, values { id category value salaryComponent {...CodeEntity} salaryPeriod { id validFrom, validUntil, description, simulation ...BasicEntity } ...BasicEntity }
        }
    }
    ${BASIC_ENTITY}
    ${CODE_ENTITY}
`;

export const SECURITY_PROFILE_SERVICE_SAVE_SECURITY_PROFILE = gql`
    mutation saveOrUpdateSecurityProfile($entity: SecurityProfileInput) {
        securityProfileService_saveSecurityProfile(entity: $entity){
            id
        }
    }
`;

export const SECURITY_SERVICE_FACADE_BEAN_GET_SECURITY_PERMISSIONS_FOR_RESOURCE = gql`
    query getSecurityPermissionsForResource($id: Int) {
        securityServiceFacadeBean_securityPermissionsForResource(id: $id) {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const SECURITY_SERVICE_FACADE_BEAN_GET_SECURITY_PROFILES_FOR_PERSON = gql`
    query getPermissions($person: Int) {
        securityServiceFacadeBean_securityProfilesForPerson(person: $person) {
            description, permissions { code, description }
        }
    }
`;

export const SECURITY_SERVICE_FACADE_BEAN_REMOVE_ACCOUNT = gql`
    mutation removeAccount($account: AccountInput) {
        securityServiceFacadeBean_removeAccount(account: $account)
    }
`;

export const SEQUENCE_SERVICE_FACADE_BEAN_GET_GROUP_SEQUENCE_OVERVIEW = gql`
    query getGroupSequenceOverview ($groupCode: String, $from: Date, $to: Date, $maxNumberOfRosterRegistrations: Int!, $defaultSequenceDefinitions: [SequenceDefinitionInput]) {
        sequenceServiceFacadeBean_groupSequenceOverview(groupCode: $groupCode, from: $from, to: $to, maxNumberOfRosterRegistrations: $maxNumberOfRosterRegistrations, defaultSequenceDefinitions: $defaultSequenceDefinitions) {
            group {id, code, name},
            workPeriods {fromDate, toDate, object},
            employeesSequences { employee {id, name, firstName}, firstSequenceNumber, sequencesItems {from, to, sequenceNumber, workPeriodType {id, code, name}}}
        }
    }
`;

export const START_POINT_TYPE_SERVICE_GET_START_POINT_TYPES = gql`
    query getStartPointTypes {
        startPointTypeService_startPointTypes {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const STATIC_GROUP_SERVICE_GET_STATIC_GROUP = gql`
    query getStaticGroup ($id: Int) {
        staticGroupService_staticGroup(id: $id) {
            ${GROUP_WITH_DEFINITION} groupResourcesHistory {...BasicEntity, resources, validFrom, validUntil}
        }
    }
    ${CODE_ENTITY}
    ${BASIC_ENTITY}
`;

export const STATIC_GROUP_SERVICE_SAVE_OR_UPDATE_STATIC_GROUP = gql`
    mutation saveOrUpdateStaticGroup ($group: StaticGroupInput, $relations: [GroupRelationInput]) {
        staticGroupService_saveOrUpdateStaticGroup(group: $group, relations: $relations)
    }
`;

export const TEAM_SERVICE_GET_TEAM_BY_ID = gql`
    query getTeamById ($id: Int) {
        teamService_teamById (id: $id) {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const TELECOM_TYPE_SERVICE_GET_TELECOM_TYPES_BY_CODE = gql`
    query getTelecomTypeByCodes ($telecomTypeCodes: [String]) {
        telecomTypeService_telecomTypeByCodes (telecomTypeCodes: $telecomTypeCodes) {
            ...CodeEntity
        }
    }
    ${CODE_ENTITY}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_CATALOG_SNAPSHOT = gql`
    query catalogSnapshot {
        trainingServiceEndpoint_catalogSnapshots {
            ...CatalogSnapshot
        }
    }
    ${CATALOG_SNAPSHOT}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE = gql`
    query competence($competenceId: Int) {
        trainingServiceEndpoint_competence(competenceId: $competenceId) {
            ...BasicEntity,
            currentCompetenceVersion {
                ...CompetenceVersion
            },
            category {
                ...BasicEntity, name, description,
                catalog {
                    ...BasicEntity, name, description
                }
            }
        }
    }
    ${BASIC_ENTITY}
    ${TRAINING_COMPONENT_VERSION}
    ${COMPETENCE_VERSION}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE_CATEGORIES = gql`
    query getCompetenceCategories($catalogId: Int) {
        trainingServiceEndpoint_competenceCategories(catalogId: $catalogId) {
            ...Competence
            categoryChildren {
                ...Competence
                categoryChildren {
                    ...Competence
                    categoryChildren {
                        ...Competence
                        categoryChildren {
                            ...Competence
                            categoryChildren {
                                ...Competence
                            }
                        }
                    }
                }
            }
        }
    }
    ${COMPETENCE_CATEGORY}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE_VERSION = gql`
    query getCompetenceVersion($versionId: Int) {
        trainingServiceEndpoint_competenceVersion(versionId: $versionId) {
            ...BasicEntity,
            name, description, majorVersion, minorVersion, active
            levelDefinitions {
                description, percentage, scale, id
            }
            trainingComponentVersions {
                ...TrainingComponentVersion
            }
            competence {
                ...BasicEntity,
                currentCompetenceVersion {
                    ...CompetenceVersion
                },
                category {
                    ...BasicEntity, name, description,
                    catalog {
                        ...BasicEntity, name, description
                    }
                }
            }
            attachments {
                ...BasicEntity, fileName, name, classType
            }
        }
    }
    ${BASIC_ENTITY}
    ${TRAINING_COMPONENT_VERSION}
    ${COMPETENCE_VERSION}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE_VERSIONS = gql`
    query getCompetenceVersions($competenceId: Int) {
        trainingServiceEndpoint_competenceVersions(competenceId: $competenceId) {
            ...BasicEntity,
            name, description, majorVersion, minorVersion, active
            levelDefinitions {
                description, percentage, scale, id
            }
            trainingComponentVersions {
                ...TrainingComponentVersion
            }
            competence {
                ...BasicEntity,
                currentCompetenceVersion {
                    ...CompetenceVersion
                },
                category {
                    ...BasicEntity, name, description,
                    catalog {
                        ...BasicEntity, name, description
                    }
                }
            }
            attachments {
                ...BasicEntity, fileName, name, classType
            }
        }
    }
    ${BASIC_ENTITY}
    ${TRAINING_COMPONENT_VERSION}
    ${COMPETENCE_VERSION}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_COMPONENTS = gql`
    query components($catalogId: Int) {
        trainingServiceEndpoint_components(catalogId: $catalogId) {
            ...Component
        }
    }
    ${COMPONENT}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_COMPONENT_VERSIONS = gql`
    query componentVersions($componentId: Int) {
        trainingServiceEndpoint_componentVersions(componentId: $componentId) {
            ...ComponentVersion
        }
    }
    ${COMPONENT_VERSION}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_EVALUATIONS_TO_FILL_IN = gql`
    query evaluationsToFillIn($evaluatorId: Int) {
        trainingServiceEndpoint_evaluationsToFillIn(evaluatorId: $evaluatorId) {
            ...BasicEntity
            attachments { ...BasicEntity name fileName classType }
            date targetDate description remark finished published
            averageScore
            totalScore
            totalNorm
            environment
            evaluationDefinitionVersion { ...BasicEntity majorVersion minorVersion evaluationCriterionVersions { ...EvaluationCriterionVersion } }
            resource { name firstName id objectType }
            evaluator { name firstName id objectType }
            realization { ...BasicEntity objectType person componentVersion { ...BasicEntity majorVersion minorVersion name objectType } }
            scores { ...BasicEntity percentage scaleScore evaluationCriteriumVersion { ...EvaluationCriterionVersion } }
        }
    }
    ${BASIC_ENTITY}
    ${EVALUATION_CRITERION_VERSION}
`;

export const COMPONENT_TYPE_SERVICE_FIND_ALL = gql`
    query componentTypes($params: FindByFilterParamsInput) {
        componentTypeService_findByFilter(params: $params) {
            results { ...CodeEntity }
        }
    }
    ${CODE_ENTITY}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_MODULE = gql`
    query module($id: Int) {
        trainingServiceEndpoint_module(id: $id) {
            ...BasicEntity objectType
            catalog { ...CatalogSnapshot }
            currentModuleVersion {
                ...ModuleVersion
            }
        }
    }
    ${MODULE_VERSION}
    ${BASIC_ENTITY}
    ${CATALOG_SNAPSHOT}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_MODULES = gql`
    query modules($catalogId: Int) {
        trainingServiceEndpoint_modules(catalogId: $catalogId) {
            catalog { ...CatalogSnapshot }
            ...BasicEntity objectType
            currentModuleVersion {
                ...ModuleVersion
            }
        }
    }
    ${MODULE_VERSION}
    ${BASIC_ENTITY}
    ${CATALOG_SNAPSHOT}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_MODULE_VERSIONS = gql`
    query moduleVersions($moduleId: Int) {
        trainingServiceEndpoint_moduleVersions(moduleId: $moduleId) {
            ...ModuleVersion
        }
    }
    ${MODULE_VERSION}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_PROFILE_VERSION = gql`
    query profileVersion($profileId: Int) {
        trainingServiceEndpoint_profileVersion(profileId: $profileId) {
            ...BasicEntity
            competenceProfileCriterionVersions {
                ...BasicEntity
                competence {
                    ...BasicEntity,
                    currentCompetenceVersion {
                        name, description, majorVersion, minorVersion, active
                        levelDefinitions {
                            description, percentage, scale, id
                        }
                        attachments {
                            ...BasicEntity, fileName, name, classType
                        }
                        ...BasicEntity
                    },
                    category {
                        ...BasicEntity, name, description,
                        catalog {
                            ...BasicEntity, name, description
                        }
                    }
                }
                weight
                required
            }
            components {
                ...Component
            }
            objectType
            profile {
                ...BasicEntity
                objectType
            }
            name
        }
    }
    ${BASIC_ENTITY}
    ${CATALOG_ITEM_VERSION}
    ${COMPONENT}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_REALIZATIONS = gql`
    query realizations($personId: Int, $componentVersionId: Int) {
        trainingServiceEndpoint_realizations(personId: $personId, componentVersionId: $componentVersionId) {
            id, date, dueDate
        }
    }
`;

export const TRAINING_SERVICE_ENDPOINT_GET_TRACK = gql`
    query track($id: Int) {
        trainingServiceEndpoint_track(id: $id) {
            catalog { ...CatalogSnapshot }
            ...BasicEntity objectType
            currentTrackVersion {
                ...TrackVersion
            }
        }
    }
    ${TRACK_VERSION}
    ${BASIC_ENTITY}
    ${CATALOG_SNAPSHOT}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_TRACKS = gql`
    query tracks($catalogId: Int) {
        trainingServiceEndpoint_tracks(catalogId: $catalogId) {
            catalog { ...CatalogSnapshot }
            ...BasicEntity objectType
            currentTrackVersion {
                ...TrackVersion
            }
        }
    }
    ${TRACK_VERSION}
    ${BASIC_ENTITY}
    ${CATALOG_SNAPSHOT}
`;

export const TRAINING_SERVICE_ENDPOINT_GET_TRACK_VERSIONS = gql`
    query trackVersions($trackId: Int) {
        trainingServiceEndpoint_trackVersions(trackId: $trackId) {
            ...TrackVersion
        }
    }
    ${TRACK_VERSION}
`;

export const TRAINING_SERVICE_ENDPOINT_REMOVE_COMPETENCE = gql`
    mutation removeCompetence($competenceId: Int) {
        trainingServiceEndpoint_removeCompetence(id: $competenceId)
    }
`;

export const TRAINING_SERVICE_ENDPOINT_REMOVE_COMPETENCE_CATEGORY = gql`
    mutation removeCompetenceCategory($categoryId: Int) {
        trainingServiceEndpoint_removeCompetenceCategory(id: $categoryId)
    }
`;

export const TRAINING_SERVICE_ENDPOINT_REMOVE_COMPETENCE_VERSION = gql`
    mutation removeCompetenceVersion($competenceVersionId: Int) {
        trainingServiceEndpoint_removeCompetenceVersion(id: $competenceVersionId)
    }
`;

export const TRAINING_SERVICE_ENDPOINT_REMOVE_COMPONENT = gql`
    mutation removeComponent($id: Int) {
        trainingServiceEndpoint_removeComponent(id: $id)
    }
`;

export const TRAINING_SERVICE_ENDPOINT_REMOVE_COMPONENT_VERSION = gql`
    mutation removeComponentVersion($id: Int) {
        trainingServiceEndpoint_removeComponentVersion(id: $id)
    }
`;

export const TRAINING_SERVICE_ENDPOINT_REMOVE_MODULE = gql`
    mutation removeModule($id: Int) {
        trainingServiceEndpoint_removeModule(id: $id) 
    }
`;

export const TRAINING_SERVICE_ENDPOINT_REMOVE_MODULE_VERSION = gql`
    mutation removeModuleVersion($id: Int) {
        trainingServiceEndpoint_removeModuleVersion(id: $id) 
    }
`;

export const TRAINING_SERVICE_ENDPOINT_REMOVE_TRACK = gql`
    mutation removeTrack($id: Int) {
        trainingServiceEndpoint_removeTrack(id: $id) 
    }
`;

export const TRAINING_SERVICE_ENDPOINT_REMOVE_TRACK_VERSION = gql`
    mutation removeTrackVersion($id: Int) {
        trainingServiceEndpoint_removeTrackVersion(id: $id) 
    }
`;

export const TRAINING_SERVICE_ENDPOINT_SAVE_COMPETENCE = gql`
    mutation saveCompetence($param: FullCompetenceInput) {
        trainingServiceEndpoint_saveCompetence(param: $param) {            
            ...BasicEntity,
            currentCompetenceVersion {
                ...CompetenceVersion
            },
            category {
                ...BasicEntity, name, description,
                catalog {
                    ...BasicEntity, name, description
                }
            }
        }
    }
    ${BASIC_ENTITY}
    ${TRAINING_COMPONENT_VERSION}
    ${COMPETENCE_VERSION}
`;

export const TRAINING_SERVICE_ENDPOINT_SAVE_COMPETENCE_CATEGORY = gql`
    mutation saveCompetenceCategory($competenceCategory: CompetenceCategoryInput) {
        trainingServiceEndpoint_saveCompetenceCategory(competenceCategory: $competenceCategory) {
            ...Competence
            categoryChildren {
                ...Competence
            }
        }
    }
    ${COMPETENCE_CATEGORY}
`;

export const TRAINING_SERVICE_ENDPOINT_SAVE_COMPETENCE_VERSION = gql`
    mutation saveCompetenceVersion($competenceVersion: CompetenceVersionInput) {
        trainingServiceEndpoint_saveCompetenceVersion(competenceVersion: $competenceVersion)
    }
`;

export const TRAINING_SERVICE_ENDPOINT_SAVE_COMPONENT = gql`
    mutation saveComponent($component: ComponentInput, $createNewMajorVersion: Boolean) {
        trainingServiceEndpoint_saveComponent(component: $component, createNewMajorVersion: $createNewMajorVersion) {
            catalog { ...CatalogSnapshot }
            ...BasicEntity objectType
            currentComponentVersion {
                ...BasicEntity
                ...CatalogItemVersion
                attachments { ...CatalogAttachment ...BasicEntity}
                component { type { ...BasicEntity description code } objectType }
                objectType
            }
            type { ...BasicEntity description code }
        }
    }
    ${CATALOG_ITEM_VERSION}
    ${CATALOG_ATTACHMENT}
    ${BASIC_ENTITY}
    ${CATALOG_SNAPSHOT}
`;

export const TRAINING_SERVICE_ENDPOINT_SAVE_COMPONENT_VERSION = gql`
    mutation saveComponentVersion($componentVersion: ComponentVersionInput) {
        trainingServiceEndpoint_saveComponentVersion(componentVersion: $componentVersion) { id }
    }
`;

export const TRAINING_SERVICE_ENDPOINT_SAVE_MODULE = gql`
    mutation saveModule($module: ModuleInput, $createNewMajorVersion: Boolean) {
        trainingServiceEndpoint_saveModule(module: $module, createNewMajorVersion: $createNewMajorVersion) {
            catalog { ...CatalogSnapshot }
            ...BasicEntity objectType
            currentModuleVersion { ...CatalogItemVersion attachments { ...CatalogAttachment } ...BasicEntity objectType
                componentVersions {
                    ...CatalogItemVersion attachments { ...CatalogAttachment } component { type { description code } objectType } ...BasicEntity objectType
                }
                attachments { ...CatalogAttachment }
            }
        }
    }
    ${CATALOG_ITEM_VERSION}
    ${CATALOG_ATTACHMENT}
    ${BASIC_ENTITY}
    ${CATALOG_SNAPSHOT}
`;

export const TRAINING_SERVICE_ENDPOINT_SAVE_MODULE_VERSION = gql`
    mutation saveModuleVersion($moduleVersion: ModuleVersionInput) {
        trainingServiceEndpoint_saveModuleVersion(moduleVersion: $moduleVersion) { 
            ...ModuleVersion
         }
    }
    ${MODULE_VERSION}
`;

export const TRAINING_SERVICE_ENDPOINT_SAVE_TRACK = gql`
    mutation saveTrack($track: TrackInput, $createNewMajorVersion: Boolean) {
        trainingServiceEndpoint_saveTrack(track: $track, createNewMajorVersion: $createNewMajorVersion) {
            catalog { ...CatalogSnapshot }
            ...BasicEntity objectType
            currentTrackVersion { ...CatalogItemVersion attachments { ...CatalogAttachment }
                children {
                    componentVersion {
                        ...CatalogItemVersion attachments { ...CatalogAttachment } component { type { description code } objectType } ...BasicEntity objectType
                    }
                    moduleVersion {
                        ...CatalogItemVersion attachments { ...CatalogAttachment } componentVersions { ...CatalogItemVersion attachments { ...CatalogAttachment } ...BasicEntity objectType} ...BasicEntity objectType
                    }
                }
                ...BasicEntity
            }
        }
    }
    ${CATALOG_ITEM_VERSION}
    ${CATALOG_ATTACHMENT}
    ${BASIC_ENTITY}
    ${CATALOG_SNAPSHOT}
`;

export const TRAINING_SERVICE_ENDPOINT_SAVE_TRACK_VERSION = gql`
    mutation saveTrackVersion($trackVersion: TrackVersionInput) {
        trainingServiceEndpoint_saveTrackVersion(trackVersion: $trackVersion) {
            ...TrackVersion
         }
    }
    ${TRACK_VERSION}
`;

export const TRAINING_SERVICE_ENDPOINT_UPDATE_EVALUATION = gql`
    mutation updateEvaluation($evaluation: EvaluationInput) {
        trainingServiceEndpoint_updateEvaluation(evaluation: $evaluation)
    }
`;

export const WORKFLOW_SERVICE_FACADE_BEAN_GET_WORKFLOW_PROCESSES = gql`
    query getWorkflowProcesses {
        workflowServiceFacadeBean_workflowProcesses {
            name, description
        }
    }
`;

export const WORKFLOW_SERVICE_FACADE_BEAN_GET_INBOX_ITEMS = gql`
    query getInboxItems($person: Int, $fromDate: Date, $toDate: Date) {
        workflowServiceFacadeBean_inboxItems(person: $person, fromDate: $fromDate, toDate: $toDate) {
            ...InboxItem
        }
    }
    ${INBOX_ITEM}
`;

export const WORKFLOW_SERVICE_FACADE_BEAN_GET_INBOX_ITEMS_ALL_ACTION = gql`
    query getInboxItemsAllAction($person: Int, $fromDate: Date, $toDate: Date) {
        workflowServiceFacadeBean_inboxItemsAllAction(person: $person, fromDate: $fromDate, toDate: $toDate) {
            ...InboxItem
        }
    }
    ${INBOX_ITEM}
`;

export const WORKFLOW_SERVICE_FACADE_BEAN_PROCESS_ITEMS = gql`
    mutation processItems($items: [WorkflowItemInput]) {
        workflowServiceFacadeBean_processItems(items: $items) 
    }
`;

export const WORKROSTER_SERVICE_FACADE_BEAN_GENERATE_ROSTER_REGISTRATIONS = gql`
    mutation generateRosterForGroup ($minFromDate: Date, $validFrom: Date, $groupCode: String) {
        workrosterServiceFacadeBean_generateRosterRegistrations(minFromDate: $minFromDate, validFrom: $validFrom, groupCode: $groupCode) 
    }
`;

export const WORKROSTER_SERVICE_FACADE_BEAN_GENERATE_ROSTER_REGISTRATIONS_FOR_EMPLOYEE = gql`
    mutation generateRosterForEmployee ($minFromDate: [Date], $validFrom: Date, $employeeId: Int) {
        workrosterServiceFacadeBean_generateRosterRegistrationsForEmployee(minFromDate: $minFromDate, validFrom: $validFrom, validUntil: null, employeeId: $employeeId) 
    }
`;

export const WORKROSTER_SERVICE_FACADE_BEAN_GET_RESOURCE_ROSTER_RELATIONS = gql`
    query getResourceRosterRelations ($criteria: EntityCriteriaInput) {
        workrosterServiceFacadeBean_resourceRosterRelations (criteria: $criteria) {
            ...BasicEntity, leaveOnHoliday, referenceDate, resource, validFrom, validUntil, roster { ...BasicEntity, code, description }
        }
    }
    ${BASIC_ENTITY}
`;

// export const WORKFLOW_TEST = gql`
//     mutation populateDB{
//         workflowTest_populateDB
//     }
// `;

export const WORKFLOW_TEST_ADD_NEW_MESSAGE = gql`
    mutation addNewMessage ($title: String, $message: String, $type: VariableInstanceType ) {
        workflowTest_addNewMessage (title: $title, message: $message, type: $type) 
    }
`;

export const WORKFLOW_TEST_REMOVE_MESSAGES = gql`
    mutation removeMessages{
        workflowTest_removeMessages
    }
`;