import { apolloClient } from "@crispico/foundation-react";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { FieldEditor, ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { FieldEditorProps, fieldEditors } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { BALANCE_SERVICE_FACADE_BEAN_FIND_BALANCE_TYPES_FOR_REGISTRATIONS_SEARCH, COMPONENT_SERVICE_FIND_ALL_COMPONENTS_ORDER_BY_TYPE_AND_NAME, COMPONENT_VERSION_SERVICE_GET_EVALUATIONS_WITH_REALIZATIONS, GROUP_SNAPSHOT_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT, PLANNING_SERVICE_ENDPOINT_GET_REGISTRATION_TYPES_WITH_CUSTOM_PROPERTY, REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPES_BY_LAYOUT_AND_CONTEXT, REPORT_DEFINITION_SERVICE_GET_RESOURCE_SNAPSHOTS, TRAINING_SERVICE_ENDPOINT_GET_REALIZATIONS } from "graphql/queries";
import _ from "lodash";
import moment from "moment";
import { ProteusConstants } from "ProteusConstants";
import React from "react";

export class WorkPeriodTypeAssociationFieldEditor extends AssociationFieldEditor<FieldEditorProps> {
    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `registrationServiceFacadeBean_registrationTypesByContext`;
        let query = REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPES_BY_LAYOUT_AND_CONTEXT;
        const context = (this.props as any).context;
        let registrationTypes = (await apolloClient.query({
            query: query,
            variables: {
                layoutType: ProteusConstants.WERK_PERIODE,
                context: context ? context : ProteusConstants.SYSTEM
            },
            context: { showSpinner: false }
        })).data[name];

        this.setState({ entities: registrationTypes });
    }
}

export class BalanceTypeWithPropertyAssociationFieldEditor extends AssociationFieldEditor<FieldEditorProps> {
    protected getLabel(entity: any): string {
        return entity.description;
    }

    protected changeSelectedValue(option: any, s?: WithHW<ScriptableUiFieldEditor.Main>, hw?: ScriptableUiHighlightWrapper) {
        if (s && hw && this.props.formikProps) {
            s.setFieldValue(hw, option);
        } else if (this.props.formikProps) {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, option);
        }
        this.props.formikProps.setFieldValue(ProteusConstants.BALANCE_TYPE_NAME, option?.description);
    }

    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `balanceServiceFacadeBean_balanceTypesFromRegistrationTypeWithSearch`;
        let query = BALANCE_SERVICE_FACADE_BEAN_FIND_BALANCE_TYPES_FOR_REGISTRATIONS_SEARCH;

        let registrationTypes = (await apolloClient.query({
            query: query,
            variables: {
                searchedRegistrationType: searchQuery
            },
            context: { showSpinner: false }
        })).data[name];
        this.setState({ entities: registrationTypes });
    }
}

export class RegistrationTypeWithPropertyAssociationFieldEditor extends AssociationFieldEditor<FieldEditorProps> {
    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `planningServiceEndpoint_registrationTypesWithProperty`;
        let query = PLANNING_SERVICE_ENDPOINT_GET_REGISTRATION_TYPES_WITH_CUSTOM_PROPERTY;

        let registrationTypesWithProperty = (await apolloClient.query({
            query: query,
            variables: {
                property: ProteusConstants.CONSTRAINED,
                value: "<boolean>true</boolean>",
                searchedRegistrationType: searchQuery
            },
            context: { showSpinner: false }
        })).data[name];

        this.setState({ entities: registrationTypesWithProperty });
    }
}

export class ResourceSnapshotAssociationFieldEditor extends AssociationFieldEditor<FieldEditorProps>{
    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `reportDefinitionService_resourceSnapshots`;
        let query = REPORT_DEFINITION_SERVICE_GET_RESOURCE_SNAPSHOTS;

        if (searchQuery && searchQuery.length > 2) {
            const param: { [k: string]: any } = {};
            param.fromDate = new Date();
            param.includeAllWithValidContract = true;
            param.groupMembershipFromDate = param.fromDate;
            param.limitResultsToCategoryOfEmployee = -1;
            param.searchText = searchQuery;
            param.includeContactPersons = false;

            let resources = (await apolloClient.query({
                query: query,
                variables: { param: param },
                context: { showSpinner: false }
            })).data[name];

            this.setState({ entities: resources });
            return;
        }

        this.setState({ entities: [] });
    }

    protected getLabel(entity: any): string {
        return entity.name + " " + entity.firstName + (entity.contractHistoryItem ? " (" + entity?.contractHistoryItem?.employeeNumber + ")" : "");
    }
}

export interface GroupSnapshotAssociationEditorProps extends FieldEditorProps {
    additionalProps: { owner: number, groupContextCode: string };
}

export class GroupSnapshotByContextAssociationFieldEditor extends AssociationFieldEditor<GroupSnapshotAssociationEditorProps>{
    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `groupSnapshotService_groupSnapshotsByContext`;
        let query = GROUP_SNAPSHOT_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT;

        let snapshots = (await apolloClient.query({
            query: query,
            variables: {
                owner: this.props.additionalProps.owner,
                groupContextCode: this.props.additionalProps.groupContextCode,
                searchedGroupSnapshot: searchQuery
            },
            context: { showSpinner: false }
        })).data[name];

        this.setState({ entities: snapshots });
    }
}

interface EvaluationsWithRealizationsAssociationEditorProps extends FieldEditorProps {
    additionalProps: { personId: number };
}

export class EvaluationsWithRealizationsAssociationFieldEditor extends AssociationFieldEditor<EvaluationsWithRealizationsAssociationEditorProps>{
    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `componentVersionService_evaluationsWithRealizations`;
        let query = COMPONENT_VERSION_SERVICE_GET_EVALUATIONS_WITH_REALIZATIONS;

        let evaluationsWithRealizations = (await apolloClient.query({
            query: query,
            variables: {
                catalogId: 1,
                searchedComponent: searchQuery,
                person: this.props.additionalProps.personId
            },
            context: { showSpinner: false }
        })).data[name];

        this.setState({ entities: evaluationsWithRealizations });
    }
}

interface RealizationAssociationEditorProps extends FieldEditorProps {
    additionalProps: { personId: number, componentVersionId: number };
}

export class RealizationAssociationFieldEditor extends AssociationFieldEditor<RealizationAssociationEditorProps>{
    // we can not search realizations by searchQuery because the returned result is
    // processed and displayed in dropdown as Datum: date Verveldatum: dueDate
    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `trainingServiceEndpoint_realizations`;
        let query = TRAINING_SERVICE_ENDPOINT_GET_REALIZATIONS;

        let realizations = (await apolloClient.query({
            query: query, variables: {
                personId: this.props.additionalProps.personId,
                componentVersionId: this.props.additionalProps.componentVersionId
            },
            context: { showSpinner: false }
        })).data[name]
        
        this.setState({ entities: realizations });
    }
}

fieldEditors["MonthYearPicker"] = class extends FieldEditor<string, FieldEditorProps>{
    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        return <DatePickerReactCalendar view="year" format="MM-YYYY" value={moment(this.getValue(), "MM-YYYY")} allowClear={false}
            onChange={(data: any) => {
                // Set date to second day of the current month. We do this because when we want to get the previous date
                // and the current date is the 31st of the month, then previous month might not have 31 days and 
                // it will set again the date as the first day of the current month. 
                const newDate = new Date(data);
                newDate.setDate(2);
                s.setFieldValue(hw, newDate.getMonth() + 1 + "-"  + newDate.getFullYear());
            }} />
    }
}

export class ComponentVersionAssociationFieldEditor extends AssociationFieldEditor<FieldEditorProps> {

    /**
     * The _.debounce() method is used to create a debounced function which delays the given func until after the stated wait
     * time in milliseconds have passed since the last time this debounced function was called.
     * In this case, if the seached text is introduced really fast, but the query takes some time to finish,
     * then we want to perform the query only when we finished typing.
     */
    debouncePerformQuery = _.debounce(async function (that, searchQuery?: string) {
        let name = `componentService_findAllComponentsOrderByTypeAndName`;
        let query = COMPONENT_SERVICE_FIND_ALL_COMPONENTS_ORDER_BY_TYPE_AND_NAME;

        let components = (await apolloClient.query({
            query: query,
            variables: {
                searchText: searchQuery,
            },
            context: { showSpinner: false }
        })).data[name];

        that.setState({ entities: components });
    }, 250);

    protected async performQuery(searchQuery?: string, operationName?: string) {
        this.debouncePerformQuery(this, searchQuery);
    }
}