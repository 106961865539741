'use strict';
	
var css = require('./curriculum.css');

var curriculumPage = {
		template: require('./curriculum-page.html'),
		controllerAs: 'curriculum',
		controller: /*@ngInject*/ function ($scope, $rootScope, $log, ModalSpinner, Curriculum, Catalog, Collection, Principal, $filter, TreeIterator, BackgroundTasks) {
			
			$log.debug('initializing CurriculumPageCtrl');
			ModalSpinner.hide();
			
			var curriculum = this;
			curriculum.date = new Date();
			curriculum.reportButtonEnabled = false;
			
			curriculum.loadEducationCardData = function() {		
				if (curriculum.resource && angular.isObject(curriculum.resource)) {
					ModalSpinner.show('curriculum.loadingCurriculum');
					Curriculum.getEducationCard({}, {
						params: {
							employee: curriculum.resource.id
						}
					}).then(function(response) {
						curriculum.dataProvider = createDataProvider(response.data);
					}).finally(function() {
						ModalSpinner.hide();
					});
					curriculum.reportButtonEnabled = true;
				} else {
					curriculum.dataProvider = null;
					curriculum.reportButtonEnabled = false;
				}

				delete curriculum.selection;
				$scope.masterDetail.goToMaster();

				// update disabled state on the navbar actions
				for (var i = 0; i < $rootScope.navActions.length; i++) {
					$rootScope.navActions[i].disabled = !curriculum.reportButtonEnabled;
				}
			};

			curriculum.runReport = function() {		
				var backgroundTask = {	
						name: 'Curriculum_rapport',		
						type: 'REPORT_GENERATION',
						inputParameters: [
						                  { name: 'RapportGebruiker', value: $rootScope.securityContext.resource.name + ' ' + $rootScope.securityContext.resource.firstName },
						                  { name: 'reportCode', value: 'CurriculumOverview' },
						                  { name: 'EmployeeId', value: curriculum.resource.id },
						]				
				};

				BackgroundTasks.runInBackground({}, {
					data: backgroundTask
				}).then(function() {
					curriculum.alert = {
							visible: true,
							type: 'success',
							title: 'Generatie begonnen',
							message: $filter('translate')('curriculum.report.generate')	
					};
				}, function(error) {
					curriculum.alert = {
							visible: true,
							type: 'danger',
							title: 'Error',
							message: $filter('translate')('curriculum.report.error')	
					};
				});
			};

			curriculum.closeAlert = function() {
				curriculum.alert.visible = false;
			};

			curriculum.addCatalogItemObjectiveToDataProvider = function(catalogItemObjective) {
				if (catalogItemObjective.objective) {
					var objectiveNode = $filter('filter')(curriculum.dataProvider.data, {objectType: "objectiveNode"}, true)[0];
					objectiveNode.childObjectives.push(catalogItemObjective);
				} else {
					var realizationsAndExemptionsNode = $filter('filter')(curriculum.dataProvider.data, {objectType: "realizationsAndExemptionsNode"}, true)[0];
					realizationsAndExemptionsNode.childObjectives.push(catalogItemObjective);
				}
			};		
			
			curriculum.removeCatalogItemObjectiveFromDataProvider = function(catalogItemObjective) {
				if (catalogItemObjective.objective) {
					throw 'not implemented';
				} else {
					var realizationsAndExemptionsNode = $filter('filter')(curriculum.dataProvider.data, {objectType: "realizationsAndExemptionsNode"}, true)[0];
					var index = realizationsAndExemptionsNode.childObjectives.indexOf(catalogItemObjective);
					if (index >= 0) {
						realizationsAndExemptionsNode.childObjectives.splice(index, 1);
					}
				}
			}
			
			function getChildrenForCatalogItemObjectiveNode(node) {
				if (node == curriculum.dataProvider) {
					return curriculum.dataProvider.data;
				} else if (node.objectType == "objectiveNode" || node.objectType == "realizationsAndExemptionsNode") {
					return node.childObjectives;
				} else if (node.objectType == "TrackObjective") {
					return node.childObjectives; // catalogItemObjectives attribute on java side is renamed to childObjectives
				} else if (node.objectType == "ModuleObjective") {
					return node.childObjectives; // componentObjectives attribute on java side is renamed to childObjectives
				} else if (node.objectType == "ComponentObjective") {
					return null;
				}
			}
			
			function createDataProvider(educationCard) {
				var objectivesNode/*:CatalogItemObjective*/ = {
						objectType: 'objectiveNode',
						objective: null,
						completed: false,
						childObjectives: educationCard.catalogItemObjectives
				};
				
				var realizationsAndExemptions = educationCard.otherRealizations.concat(educationCard.otherExemptions);
				
				var realizationsAndExemptionsNode/*:CatalogItemObjective*/ = {
						objectType: 'realizationsAndExemptionsNode',
						objective: null,
						completed: false,
						childObjectives: realizationsAndExemptions
				};
				
				var dataProvider/*<CatalogItemObjective>*/ = Collection.create([objectivesNode, realizationsAndExemptionsNode]);
				curriculum.realizationsAndExemptions = [];
				return dataProvider;
			};
			
			curriculum.onNewSelection = function(catalogItemObjective) {
				if (!angular.isObject(catalogItemObjective)) {
					return;
				}
				curriculum.selection = catalogItemObjective;
				ModalSpinner.show('curriculum.loadingRealizationsAndExemptions');
				var componentParam = angular.isObject(catalogItemObjective.component) ? catalogItemObjective.component.id : null;
				var moduleParam = angular.isObject(catalogItemObjective.module) ? catalogItemObjective.module.id : null;
				Curriculum.getTrackModuleOrComponentRealizations({}, {
					params: {
						// only one of track, module or compenent will be not null
						employee: curriculum.resource.id,
						track: angular.isObject(catalogItemObjective.track) ? catalogItemObjective.track.id : null ,
						module: moduleParam,
						component: componentParam
					}
				}).then(function(response) {
					curriculum.realizationsAndExemptions = [];
					if (response.data.realizations) {
						curriculum.realizationsAndExemptions = curriculum.realizationsAndExemptions.concat(response.data.realizations);
					}
					if (response.data.exemptions) {
						curriculum.realizationsAndExemptions = curriculum.realizationsAndExemptions.concat(response.data.exemptions);	
					}
					curriculum.realizationsAndExemptions.sort(curriculum.compareRealizations);

					curriculum.reviewersMap = response.data.reviewersMap;
					curriculum.userToPersonMap = response.data.userToPersonMap;
					$scope.masterDetail.goToDetail();
				}).finally(function() {
					ModalSpinner.hide();
				});
			};

			curriculum.compareRealizations = function(r1, r2) {
				var v1 = r1.componentVersion.version;
				var v2 = r2.componentVersion.version;

				var versionCompare = v2.localeCompare(v1);
				if (versionCompare != 0) {
					return versionCompare;
				}

				if (!r1.date) {
					return -1;
				} else if (!r2.date) {
					return 1;
				}

				return new Date(r2.date) - new Date(r1.date);
			}

			curriculum.onRealizationAdded = function(realizationOrExemption) {			
				// check if the realization belongs to an CatalogItemObjective from the tree.
				// If not it means that we need to add a new CatalogItemObjective in the realizations/exemptions without objectives section from the tree				
				// we will have a new ComponentObjective created client side
				
				if (getComponentCatalogItemObjectiveFor(realizationOrExemption) == null) {	
					// we can't use realizationOrExemption.componentVersion.component because it does not have a currentComponentVersion (is json ignored)
					ModalSpinner.show('curriculum.loadComponent');
					Catalog.getComponent({}, {
						params: {
							id: realizationOrExemption.componentVersion.component.id
						}
					}).then(function(result) {
						curriculum.addCatalogItemObjectiveToDataProvider({
								objectType: "ComponentObjective",
								component: result.data
						});
					}).finally(function() {
						ModalSpinner.hide();
					});

				}
				
				curriculum.updateFlags(realizationOrExemption);
			};
			
			curriculum.onRealizationRemoved = function(realizationOrExemption) {
				// get the catalogItemObjective it belongs. If catalogItemObjective.objective == null (realizations/exemptions without objectives section), if there are no other realizations, exemptions remove it
				
				var catalogItemObjective = getComponentCatalogItemObjectiveFor(realizationOrExemption);				
				// if there are no other realization/exemptions we must remove the node
				Curriculum.getTrackModuleOrComponentRealizations({}, {
					params: {
						// only one of track, module or compenent will be not null
						employee: curriculum.resource.id,
						track: null,
						module: null,
						component: angular.isObject(catalogItemObjective.component) ? catalogItemObjective.component.id : null
					}
				}).then(function(response) {
					var realizationData = response.data;
					if (realizationData.realizations.length == 0 && realizationData.exemptions.length == 0) {
						curriculum.removeCatalogItemObjectiveFromDataProvider(catalogItemObjective);
						$scope.masterDetail.goToMaster();
					}
				});
				
				curriculum.updateFlags(realizationOrExemption);
			}
			
			curriculum.updateFlags = function(realization) {
				// we load the catalog item objectives where this realization might belong.
				// after, we recalculate the completed flag
				ModalSpinner.show('loading');	
				Curriculum.getStatusesForComponent({}, {
					params: {
						employee: curriculum.resource.id,
						component: realization.componentVersion.component.id
					}
				}).then(function(result) {
					var componentStatuses = result.data;
					updateStatusesForComponent(realization.componentVersion.component.id, componentStatuses);
					propagateStatusUpwards();
				}).catch(angular.noop).finally(function() {
					ModalSpinner.hide();
				});
			};
			
			function updateStatusesForComponent(componentId, componentStatuses) {
								
				// we skip  realizations and exemptions without objective node
				var objectiveNode = $filter('filter')(curriculum.dataProvider.data, {objectType: "objectiveNode"}, true)[0]; 
				
				for (var it = TreeIterator.create(objectiveNode, getChildrenForCatalogItemObjectiveNode); it.hasNext();) {
					var existingCatalogItemObjective = it.next();					
										
					if (existingCatalogItemObjective.component && existingCatalogItemObjective.component.id == componentId) {
						existingCatalogItemObjective.completed = componentStatuses.completed;
						existingCatalogItemObjective.expired = componentStatuses.expired;
						existingCatalogItemObjective.lastRealizationStatus = componentStatuses.realizationResult;
					}
				}

				var realizationsAndExemptionsNode = $filter('filter')(curriculum.dataProvider.data, {objectType: "realizationsAndExemptionsNode"}, true)[0];
				for (var it = TreeIterator.create(realizationsAndExemptionsNode, getChildrenForCatalogItemObjectiveNode); it.hasNext();) {
					var realizationOrExemption = it.next();			
					if (realizationOrExemption.component && realizationOrExemption.component.id == componentId) {
						realizationOrExemption.expired = componentStatuses.expired;
						realizationOrExemption.lastRealizationStatus = componentStatuses.realizationResult;
					}
				}
			}
			
			function propagateStatusUpwards() {
				var catalogItemObjectives = [];
				// reverse this
				for (var it = TreeIterator.create(curriculum.dataProvider, getChildrenForCatalogItemObjectiveNode); it.hasNext();) {
					catalogItemObjectives.push(it.next());
				}
				catalogItemObjectives.reverse();
				
				// we iterate from terminal nodes towards the roots
				for (var i = 0; i < catalogItemObjectives.length; i++) {
					var node = catalogItemObjectives[i];
					var children = getChildrenForCatalogItemObjectiveNode(node);					
					if (children) {
						var completed = true;
						var expired = false;
						//lastRealizationStatus: empty (children have different statuses) or the children status
						var childrenHaveSameStatus = true;
						var lastRealizationStatus = null;
						for (var j = 0; j < children.length; j++) {
							var catalogItemObjective = children[j];
							completed = completed && catalogItemObjective.completed;
							expired = expired || catalogItemObjective.expired;
							
							if (!lastRealizationStatus) {
								lastRealizationStatus = catalogItemObjective.lastRealizationStatus;
							} else if (childrenHaveSameStatus && lastRealizationStatus != catalogItemObjective.lastRealizationStatus) {
								childrenHaveSameStatus = false;
							}
						}
						if (!childrenHaveSameStatus) {
							lastRealizationStatus = -1;
						}
						node.lastRealizationStatus = lastRealizationStatus;
						node.completed = completed;
						node.expired = expired;
					}
				}
			}
			
			function getComponentCatalogItemObjectiveFor(realizationOrExemption) {				
				var theCatalogItemObjective = null;
				for (var it = TreeIterator.create(curriculum.dataProvider, getChildrenForCatalogItemObjectiveNode); it.hasNext();) {
					var catalogItemObjective = it.next();
					if (!catalogItemObjective.component) {
						continue;
					}
					if (catalogItemObjective.component.id == realizationOrExemption.componentVersion.component.id) {
						theCatalogItemObjective = catalogItemObjective;
						break;
					}					
				}
				return theCatalogItemObjective;
			}

			$rootScope.navActions = [
									{
										index: 10,
										icon: 'fa-print',
										run: curriculum.runReport,
										disabled: true
									},
									{
										index: 20,
										icon: 'fa-refresh',
										run: curriculum.loadEducationCardData,
										disabled: false
									}
			];
			
			curriculum.$onInit = function() {
				
				curriculum.resourcePickerDisabled = false;
				
				var hasCurriculumView = false;
				var hasCurriculumViewAll = false;
				
				Principal.hasPermissions("EDUCATION_CURRICULUM_VIEW").then(function(result) {
					hasCurriculumView = result;
					
					return Principal.hasPermissions("EDUCATION_CURRICULUM_VIEW_ALL");
				}).then(function(result) {
					hasCurriculumViewAll = result;
					
					if (hasCurriculumView && !hasCurriculumViewAll) {
						curriculum.resource = $rootScope.securityContext.resource;
						curriculum.resourcePickerDisabled = true;
					}
				});

				Curriculum.getRealizationResults({}, {}).then(function(result) {
					curriculum.statusMap = {};
					for (var i in result.data) {
						var realizationResult = result.data[i];
						curriculum.statusMap[realizationResult.code] = realizationResult;
					}
				});
			}
		}
	};

module.exports = curriculumPage;