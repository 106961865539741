'use strict';

var css = require('./wizard.css');

angular.module('proteus.wizard', [])

.directive('proteusWizard', require('./wizard.directive'))
.directive('proteusWizardPane', require('./wizard-pane.directive'))
.directive('proteusWizardPaneTitle', require('./wizard-pane-title.directive'))

.directive('proteusWizardActionPrev', function() {
	return proteusWizardActionDir(-1);
})

.directive('proteusWizardActionNext', function() {
	return proteusWizardActionDir(1);
})

.directive('proteusWizardActionSave', function() {
	return proteusWizardActionDir(0);
});

function proteusWizardActionDir(inc) {

	return {
		restrict: 'C',
		scope: true,
		controller: /*@ngInject*/ function($scope, $element, $attrs) {
			var wizard = $scope.wizard;
			$scope.$watchGroup(['wizard.selected', 'wizard.steps.length'], function() {
				$scope.step = wizard.steps[wizard.selected + inc];
			});
		}
	}
}

module.exports = 'proteus.wizard';