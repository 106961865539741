import React from "react";
import Measure from "react-measure";
import { Button, Icon, Ref } from "semantic-ui-react";

export enum ArrowScrollDirection {
  HORIZONTAL = "Horizontal",
  VERTICAL = "Vertical"
}

type ArrowScrollContainerProps = {
  direction: ArrowScrollDirection,
  scrollAmount: number,
  content: any,
  style?: any
}

type ArrowScrollContainerState = {
  showArrows: boolean
}

export const initialState: ArrowScrollContainerState = {
  showArrows: false
}

export class ArrowScrollContainer extends React.Component<ArrowScrollContainerProps, ArrowScrollContainerState> {

  scrollContainerRef = React.createRef<HTMLDivElement>();

  static defaultProps = {
    scrollAmount: 50
  }

  constructor(props: ArrowScrollContainerProps) {
    super(props);
    this.state = { ...initialState };
    this.onResize = this.onResize.bind(this);
    this.onClickFirstArrow = this.onClickFirstArrow.bind(this);
    this.onClickSecondArrow = this.onClickSecondArrow.bind(this);
  }

  onResize() {
    const container = this.scrollContainerRef.current;
    if (!container || !container.parentElement) {
      return;
    }
    let showArrows = false;
    if (this.props.direction === ArrowScrollDirection.HORIZONTAL) {
      showArrows = container.scrollWidth > container.parentElement?.clientWidth;
    } else {
      showArrows = container.scrollHeight > container.parentElement?.clientHeight;
    }
    this.setState({ showArrows });
  }

  componentDidMount(): void {
    this.onResize();
  }

  onClickFirstArrow() {
    const container = this.scrollContainerRef.current;
    if (!container) {
      return;
    }
    if (this.props.direction === ArrowScrollDirection.HORIZONTAL) {
      container.scrollLeft -= this.props.scrollAmount;
    } else {
      container.scrollTop -= this.props.scrollAmount;
    }
  };

  onClickSecondArrow() {
    const container = this.scrollContainerRef.current;
    if (!container) {
      return;
    }

    if (this.props.direction === ArrowScrollDirection.HORIZONTAL) {
      container.scrollLeft += this.props.scrollAmount;
    } else {
      container.scrollTop += this.props.scrollAmount;
    }
  };

  getStyle() {
    return this.props.style;
  }

  render() {
    return <Measure bounds scroll onResize={this.onResize}>{({ measureRef }) => (
      <Ref innerRef={measureRef}>
        <div style={this.getStyle()} className={`ArrowScrollContainer_parent${this.props.direction}`} >
          {this.state.showArrows && <Button className="ArrowScrollContainer_arrowBtn" icon basic onClick={this.onClickFirstArrow} style={{ marginRight: '0px!important' }}>
            <Icon name={this.props.direction === ArrowScrollDirection.HORIZONTAL ? "angle double left" : "angle double up"} />
          </Button>}
          <div style={{ flex: 1, overflow: 'hidden' }} ref={this.scrollContainerRef}>
            <div className={`ArrowScrollContainer_content${this.props.direction}`}>
              <Measure bounds scroll onResize={this.onResize}>{({ measureRef }) => (
                <Ref innerRef={measureRef}><>
                  {this.props.content}
                </>
                </Ref>)}
              </Measure>
            </div>
          </div>
          {this.state.showArrows && <Button className="ArrowScrollContainer_arrowBtn" icon basic onClick={this.onClickSecondArrow}>
            <Icon name={this.props.direction === ArrowScrollDirection.HORIZONTAL ? "angle double right" : "angle double down"} />
          </Button>}
        </div>
      </Ref>)}
    </Measure>;
  }
}