import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { EMPLOYEE_SERVICE_GET_EMPLOYEE_BY_ID_WITH_CONTRACT } from "graphql/queries";
import _ from "lodash";
import { PersonAssociationFieldEditor } from "pages/realization/RealizationEntityDescriptor";
import React from "react";

export interface Person {
    name: string,
    firstName: string,
    id: number,
    contractHistoryItem?: {
        employeeNumber: number
    },
    locationHistoryItem? : {
        location: {
            description: string
        }
    }
}

const PERSON: string = "person";

export class EmployeeDetailEntityDescriptor extends EntityDescriptor {

    protected customize() {

        const employeeDetailEntityDescriptor = this;

        const sliceEmployeeDetailEditor = employeeDetailEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceEmployeeDetailEditor extends SliceEntityEditorPage {

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                initialEmployee: undefined as unknown as Person
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceEmployeeDetailEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceEmployeeDetailEditor>(this),

                async getInitialEmployeeData(id: number) {
                    let initialEmployee = (await apolloClient.query({
                        query: EMPLOYEE_SERVICE_GET_EMPLOYEE_BY_ID_WITH_CONTRACT,
                        variables: {
                            employeeId: id
                        }
                    })).data.employeeService_employee;
                    initialEmployee = { ...initialEmployee, contractHistoryItem: { employeeNumber: initialEmployee?.detail?.contractHistory[0]?.employeeNumber } };
                    delete initialEmployee?.detail;
                    this.getDispatchers().setInReduxState({ initialEmployee });
                },

                superLoad: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.superLoad(id);
                    if (entity !== undefined) {
                        if (entity.person !== null && entity.person !== undefined) {
                            await this.getInitialEmployeeData(entity.person);
                        }
                    }
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    if (entity.person !== null && entity.person !== undefined && entity.person.id !== undefined) {
                        entity = { ...entity, person: entity.person.id };
                    }
                    await this.saveSuper(entity);
                }
            }
        }).setEntityDescriptor(employeeDetailEntityDescriptor);

        employeeDetailEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceEmployeeDetailEditor> & EntityEditorPageProps> {

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "EmployeeDetail" }, false);
                employeeDetailEntityDescriptor.doForFields(null, (fieldDescriptor) => result.entityDescriptor.addFieldDescriptor(fieldDescriptor));
                result.entityDescriptor.removeFieldDescriptors(PERSON);
                result.entityDescriptor.addFieldDescriptor({ name: PERSON, type: "EmployeeSnapshot" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        const newProps = {
                            ...props,
                            includeAllWithValidContract: false,
                            isClearable: false,
                            queryLimit: -1,
                        };
                        return React.createElement(PersonAssociationFieldEditor as any, newProps as FieldEditorProps);
                    }
                });
                if (result.entity.person !== undefined && result.entity.person !== null && result.entity.person.id === undefined &&
                    result.entity.person === this.props.initialEmployee?.id) {
                    result.entity = { ...result.entity, person: this.props.initialEmployee };
                }
                return result;
            }

            protected getEntityValuesFromForm() {
                // because in the form the "person" field is of type "object" and in the table it is of type number (person id), a conversion
                // is needed when navigating from "edit properties" to "view properties" (from form to table)
                let values = super.getEntityValuesFromForm();
                if (values.person && values.person.id !== undefined) {
                    this.props.dispatchers.setInReduxState({ initialEmployee: values.person });
                    values = { ...values, person: values.person.id };
                }
                return values;
            }
        }
    }
}