'use strict';

module.exports = {
	name: 'proteus.education',
	path: 'education.module.js',
	authRoutes: {
		'/education/catalogs': {
			templateUrl: '/catalogs/catalogs.html',
			controller: 'CatalogsCtrl',
			controllerAs: 'catalogs'
		},
		'/education/competences': {
			templateUrl: '/competences/competences.html',
			controller: 'CompetencesCtrl',
			controllerAs: 'competences'
		},
		'/education/evaluationRequest': {
			templateUrl: '/evaluation/request/requests.html',
			controller: 'EvaluationRequestsCtrl',
			controllerAs: 'requests'
		},
		'/education/evaluationFillIn': {
			templateUrl: '/evaluation/fill-in/evaluations-fill-in.html',
			controller: 'EvaluationsFillInCtrl',
			controllerAs: 'evaluations'
		},
		'/education/curriculum': {
			template: '<curriculum-page></curriculum-page>'
		},
		'/education/groupRegistrations': {
			templateUrl: '/group-registrations/group-registrations.html',
			controller: 'GroupRegistrationsCtrl',
			controllerAs: 'groupRegistrations'
		}
	}
};