'use strict';

module.exports = function() {
	return {
		link : function($scope, element) {
			$scope.$watch('row', function(newValue, oldValue) {
				var backgroundColor = null;
				var date = new Date($scope.row.entity.start);
				if ($scope.grid.options.calendarEvents[date.getDate()]) {
					backgroundColor = '#F79B2C';
				} else {
					var day = date.getDay();
					if (day == 0 || day == 6) {
						backgroundColor = '#FFDD00';
					}
				}
				if (backgroundColor) {
					$scope.noColor = false;
					$scope.eventColor = {
						backgroundColor : backgroundColor
					}
				} else {
					$scope.noColor = true;
					$scope.eventColor = null;
				}
			});
		}
	}
};