import { FieldRendererProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { getGroupEmployeesByIds_employeeService_employees } from "apollo-gen/getGroupEmployeesByIds";
import { getGroupMembers_employeeService_employeeSnapshotsByGroup } from "apollo-gen/getGroupMembers";
import { HistoryTable, sliceHistoryTable } from "components/HistoryTable";
import { CellProps } from "fixed-data-table-2";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Button, Grid } from "semantic-ui-react";
import { groupResourcesDescriptor, groupResourcesHistoryDescriptor, MoveEmployeesTableRenderer } from "./customFieldRenderersEditors";
import { HistoryItem, RESOURCES } from "./GroupsManagement";
import { EMPLOYEE, EMPLOYEE_SNAPSHOT } from "./GroupSnapshotTab";
import { sliceGroupsManagementBase } from "./sliceGroupsManagementBase";

export interface ResourceTable {
    [EMPLOYEE]: {
        id: number | null,
        name: string | null,
        firstName: string | null,
        employeeNumber: string | null | undefined
    }
}

export interface SuccessionDefinitionHistory extends HistoryItem {
    resources: number[]
}

export const sliceSuccessionTab = createSliceFoundation(class SliceSuccessionTab {

    initialState = {
        groupMembers: undefined as unknown as getGroupMembers_employeeService_employeeSnapshotsByGroup[],
        loadedEmployees: undefined as unknown as getGroupEmployeesByIds_employeeService_employees[]
    }

    nestedSlices = {
        successionDefinitionHistoryTable: sliceHistoryTable,
        successionDefinitionResourcesTable: sliceEntityTableLight
    }

    reducers = {
        ...getBaseReducers<SliceSuccessionTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceSuccessionTab>(this),

        async getGroupMembers(groupCode: string, fromDate: string | Date, toDate: string | Date) {
            const groupMembers = await sliceGroupsManagementBase.impures.getGroupMembers(groupCode, fromDate, toDate);
            this.getDispatchers().setInReduxState({ groupMembers });
        }
    }
})

type PropsNotFromState = {
    selectedGroup: any;
    deleteSuccessionDefinitionHistory: (remainingSuccessionDefinition: SuccessionDefinitionHistory[]) => void;
    addOrUpdateSuccessionDefinitionHistory: (successionDefinitionHistory: SuccessionDefinitionHistory[]) => void;
    deleteResourceFromSuccessionHistoryItem: (successionHistoryItem: SuccessionDefinitionHistory, resource: ResourceTable) => void;
    addResourceToSuccessionHistoryItem: (successionHistoryItem: SuccessionDefinitionHistory, addedResource: ResourceTable) => void;
    addGroupMembersToSuccession: (successionHistoryItem: SuccessionDefinitionHistory, groupMembers: getGroupMembers_employeeService_employeeSnapshotsByGroup[], successionMembers: number[]) => void;
    moveSuccessionResourcesUpOrDown: (successionHistoryItem: SuccessionDefinitionHistory, resourceId: number, moveUp: boolean) => void;
}

export class SuccessionTab extends React.Component<PropsFrom<typeof sliceSuccessionTab> & PropsNotFromState> {
    protected successionDefinitionHistoryTableRef = React.createRef<HistoryTable>();
    protected successionDefinitionResourcesTableRef = React.createRef<EntityTableLight>();

    async setSuccessionDefinitionHistoryTable(successionDefinitionHistory: SuccessionDefinitionHistory[]) {
        const selectedId = this.successionDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!;
        const oldEntities = this.successionDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()!;
        const entities = successionDefinitionHistory.sort((a: SuccessionDefinitionHistory, b: SuccessionDefinitionHistory) => moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf());
        this.successionDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(entities);

        if (!_.isEqual(oldEntities[selectedId]?.resources, entities[selectedId]?.resources)) {
            this.onSuccessionDefinitionHistoryChange(selectedId);
        }

        let oldSelectedEntityInTable = oldEntities[selectedId];
        let selectedEntityInTable = entities[selectedId];
        if (selectedEntityInTable !== undefined && selectedEntityInTable.resources !== undefined && !_.isEqual(oldEntities[selectedId].resources, entities[selectedId].resources) &&
            selectedEntityInTable.resources.length === oldSelectedEntityInTable?.resources?.length) {
            // order of employees changed
            await this.getResourcesEmployees(selectedEntityInTable.resources, false);
        }
    }

    setSuccessionResourcesTable(successionResources: getGroupEmployeesByIds_employeeService_employees[]) {
        let successionResourcesForTable: ResourceTable[] = [];
        for (let i = 0; i < successionResources.length; i++) {
            successionResourcesForTable.push({
                [EMPLOYEE]: {
                    name: successionResources[i].name,
                    firstName: successionResources[i].firstName,
                    id: successionResources[i].id,
                    employeeNumber: successionResources[i].detail?.contractHistory?.[0]?.employeeNumber
                }
            });
        }
        this.successionDefinitionResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(successionResourcesForTable);
    }

    async getResourcesEmployees(employeesIds: number[], loadEmployees: boolean) {
        let employeesData: getGroupEmployeesByIds_employeeService_employees[] = []
        if (loadEmployees) {
            const employees: getGroupEmployeesByIds_employeeService_employees[] = await sliceGroupsManagementBase.impures.getResourcesEmployees(employeesIds);
            this.props.dispatchers.setInReduxState({ loadedEmployees: employees });
            employeesData = employees;
        } else {
            employeesData = this.props.loadedEmployees;
        }
        employeesData = [...employeesData].sort(function (a: getGroupEmployeesByIds_employeeService_employees, b: getGroupEmployeesByIds_employeeService_employees) {
            return employeesIds.indexOf(a.id!) - employeesIds.indexOf(b.id!);
        });
        this.setSuccessionResourcesTable(employeesData);
    }

    renderSuccessionResourcesDescriptor(successionHistoryItem: SuccessionDefinitionHistory) {
        fieldRenderers[EMPLOYEE_SNAPSHOT] = (props: FieldRendererProps) => {
            let newProps = {
                ...props,
                moveEmployeeUpOrDown: this.props.moveSuccessionResourcesUpOrDown,
                historyItem: successionHistoryItem,
                editEnabled: true
            };
            return <MoveEmployeesTableRenderer {...newProps} table={this.successionDefinitionResourcesTableRef} />
        }
    }

    moveResourceUpOrDown = (successionHistoryItem: SuccessionDefinitionHistory, resourceId: number, moveUp: boolean) => {
        this.props.moveSuccessionResourcesUpOrDown(successionHistoryItem, resourceId, moveUp);
        this.successionDefinitionResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(this.getResourceNewPosition(successionHistoryItem, resourceId, moveUp));
    }

    getResourceNewPosition(successionHistoryItem: SuccessionDefinitionHistory, resourceId: number, moveUp: boolean) {
        let resources = [...successionHistoryItem.resources];
        let resourceIndex = resources.indexOf(resourceId);
        if (resourceIndex > 0 && moveUp) {
            return resourceIndex - 1;
        } else if (resourceIndex < resources.length - 1 && !moveUp) {
            return resourceIndex + 1;
        }
        return resourceIndex;
    }

    render() {
        const selectedId = this.successionDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!;
        const selectedEntityInTable = this.successionDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[selectedId];
        return (
            <>
                <Grid className="GroupSnapshotTab_staticGroup GroupsManagement_flexGrow">
                    <Grid.Row columns={2} className="GroupSnapshotTab_noPaddingBottomRow">
                        <Grid.Column className="SuccessionTab_table SuccessionTab_topMargin">
                            <HistoryTable {...this.props.successionDefinitionHistoryTable} dispatchers={this.props.dispatchers.successionDefinitionHistoryTable}
                                onDelete={(remainingSuccessionDefinition: SuccessionDefinitionHistory[]) => {
                                    this.props.deleteSuccessionDefinitionHistory(remainingSuccessionDefinition);
                                    this.successionDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                                    this.successionDefinitionResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                                }} onSave={(successionDefinitionHistory: SuccessionDefinitionHistory[]) => {
                                    this.props.addOrUpdateSuccessionDefinitionHistory(successionDefinitionHistory);
                                    this.successionDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                                    this.successionDefinitionResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                                }}
                                onAdd={sliceHistoryTable.reducers.onAdd} actions={{ showDeleteButton: false, showAddButton: true }}
                                formCustomizer={{ headerContent: _msg("GroupResourcesHistoryItem.addForm.label"), headerIcon: "history" }}
                                entityDescriptor={groupResourcesHistoryDescriptor} fieldsToBeCopied={[RESOURCES]}
                                ref={this.successionDefinitionHistoryTableRef} onSelectItem={(itemId: any) => this.onSuccessionDefinitionHistorySelectionChange(itemId)}
                            />
                        </Grid.Column>
                        <Grid.Column className="SuccessionTab_table">
                            <Button floated="right" onClick={() => {
                                if (this.props.groupMembers !== undefined && this.props.groupMembers.length !== 0) {
                                    this.props.addGroupMembersToSuccession(selectedEntityInTable, this.props.groupMembers, selectedEntityInTable.resources);
                                }
                            }} disabled={selectedId === undefined}>
                                {_msg("SuccessionTab.addAllGroupMembers.label")}
                            </Button>
                            <EntityTableLight {...this.props.successionDefinitionResourcesTable} dispatchers={this.props.dispatchers.successionDefinitionResourcesTable}
                                entityDescriptor={groupResourcesDescriptor}
                                actions={{ showDeleteButton: false, disableAddButton: selectedId === undefined, showEditButton: false, doNotAddEntityToTable: true }}
                                formCustomizer={{ headerContent: _msg("SuccessionTab.successionMembers.label"), headerIcon: "user" }}
                                onDelete={(remainingSuccessionResources: ResourceTable[], successionResourceToBeDeletedIndex: number) => {
                                    this.props.deleteResourceFromSuccessionHistoryItem(selectedEntityInTable,
                                        this.successionDefinitionResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[successionResourceToBeDeletedIndex]);
                                }}
                                onSave={(resources: ResourceTable[], addedResource: { rowIndex: number, values: ResourceTable }) => {
                                    this.props.addResourceToSuccessionHistoryItem(selectedEntityInTable, addedResource.values)
                                }}
                                renderCellCustomizer={this.renderCellCustomizer} ref={this.successionDefinitionResourcesTableRef} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {this.renderSuccessionResourcesDescriptor(selectedEntityInTable)}
            </>);
    }

    renderCellCustomizer = (props: CellProps) => {
        const rowObject = this.successionDefinitionResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[props.rowIndex!];
        const employeeIsNotGroupMember: boolean = this.props.groupMembers?.filter((item: getGroupMembers_employeeService_employeeSnapshotsByGroup) =>
            item.id === rowObject.employee.id).length === 0;
        if (this.props.groupMembers !== undefined && employeeIsNotGroupMember) {
            props.style = { 'color': '#FF0000' };
        }
    }

    selectedGroupChanged() {
        this.setSuccessionDefinitionHistoryTable([...this.props.selectedGroup.successionDefinition.history]);
        this.successionDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
        this.setSuccessionResourcesTable([]);
    }

    async onSuccessionDefinitionHistoryChange(itemId: any) {
        const entities = [...this.props.selectedGroup.successionDefinition.history].sort((a: SuccessionDefinitionHistory, b: SuccessionDefinitionHistory) => moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf());
        let selectedEntityInTable = entities[itemId];
        let resources = selectedEntityInTable?.resources;
        if (resources === undefined || resources.length === 0) {
            this.setSuccessionResourcesTable([]);
        } else {
            await this.getResourcesEmployees(resources, true);
        }
    }

    async onSuccessionDefinitionHistorySelectionChange(itemId: any) {
        await this.onSuccessionDefinitionHistoryChange(itemId);
        let selectedEntityInTable = this.successionDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[itemId];
        if (itemId !== undefined) {
            await this.props.dispatchers.getGroupMembers(this.props.selectedGroup.code, selectedEntityInTable?.validFrom, selectedEntityInTable?.validUntil);
        } else {
            this.props.dispatchers.setInReduxState({ groupMembers: undefined });
        }
    }

    componentDidMount() {
        this.selectedGroupChanged();
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceSuccessionTab> & PropsNotFromState) {
        if (this.props.selectedGroup.id !== prevProps.selectedGroup.id) {
            this.selectedGroupChanged();
        }

        if (this.props.selectedGroup.successionDefinition.history !== prevProps.selectedGroup.successionDefinition.history) {
            this.setSuccessionDefinitionHistoryTable([...this.props.selectedGroup.successionDefinition.history]);
        }
    }
}