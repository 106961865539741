import { addEntityDescriptor, EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FoundationEntityDescriptors } from "@crispico/foundation-react/FoundationEntityDescriptors";
import { AccountEntityDescriptor } from "pages/account/AccountEntityDescriptor";
import { BackgroundTaskEntityDescriptor } from "pages/BackgroundTask/BackgroundTaskEntityDescriptor";
import { BalanceCounterHistoryItemEntityDescriptor } from "pages/balanceCounterHistoryItem/BalanceCounterHistoryItemEntityDescriptor";
import { CalendarDefinitionEntityDescriptor } from "pages/calendarDefinition/CalendarDefinitionEntityDescriptor";
import { CompanyEntityDescriptor } from "pages/company/CompanyEntityDescriptor";
import { CountryEntityDescriptor } from "pages/country/CountryEntityDescriptor";
import { EmployeeDetailEntityDescriptor } from "pages/employeeDetail/EmployeeDetailEntityDescriptor";
import { EnvironmentEntityDescriptor } from "pages/environment/EnvironmentEntityDescriptor";
import { GroupContextEntityDescriptor } from "pages/groupContext/GroupContextEntityDescriptor";
import { GroupPropertyEntityDescriptor } from "pages/groupProperty/GroupPropertyEntityDescriptor";
import { GroupRosterRelationEntityDescriptor } from "pages/groupRosterRelation/GroupRosterRelationEntityDescriptor";
import { MetadataContextEntityDescriptor } from "pages/metadataContext/MetadataContextEntityDescriptor";
import { ParameterEntityDescriptor } from "pages/parameter/ParameterEntityDescriptor";
import { PilotProgressHistoryItemEntityDescriptor } from "pages/pilotProgressHistoryItem/PilotProgressHistoryItemEntityDescriptor";
import { PropertyEntityDescriptor } from "pages/property/PropertyEntityDescriptor";
import { PropertyValueEntityDescriptor } from "pages/propertyValue/PropertyValueEntityDescriptor";
import { RealizationEntityDescriptor } from "pages/realization/RealizationEntityDescriptor";
import { RegistrationEntityDescriptor } from "pages/registration/RegistrationEntityDescriptor";
import { ReportDefinitionAdminEntityDescriptor } from "pages/reportDefinition/ReportDefinitionAdminEntityDescriptor";
import { ReportDefinitionEntityDescriptor } from "pages/reportDefinition/ReportDefinitionEntityDescriptor";
import { ResourceRosterRelationEntityDescriptor } from "pages/resourceRosterRelation/ResourceRosterRelationEntityDescriptor";
import { RosterEntityDescriptor } from "pages/roster/RosterEntityDescriptor";
import { SecurityProfileEntityDescriptor } from "pages/securityProfile/SecurityProfileEntityDescriptor";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { PremiumParameterEntityDescriptor } from "pages/premiumParameter/PremiumParameterEntityDescriptor";
import { SalaryComponentEntityDescriptor } from "pages/statistics/SalaryComponentEntityDescriptor";
import { SalaryPeriodEntityDescriptor } from "pages/statistics/SalaryPeriodEntityDescriptor";
import { SalaryComponentValueEntityDescriptor } from "pages/statistics/SalaryComponentValueEntityDescriptor";
import { ResourceWorkRosterEntityDescriptor } from "pages/resourceWorkRoster/ResourceWorkRosterEntityDescriptor";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { ProteusConstants } from "ProteusConstants";

export let accountEntityDescriptor: EntityDescriptor;
export let accountHistoryItemEntityDescriptor: EntityDescriptor;
export let addressHistoryItemEntityDescriptor: EntityDescriptor;
export let backgroundTaskEntityDescriptor: EntityDescriptor;
export let balanceCounterHistoryItemEntityDescriptor: EntityDescriptor;
export let calendarDefinitionEntityDescriptor: EntityDescriptor;
export let cardEntityDescriptor: EntityDescriptor;
export let civilStatusHistoryItemEntityDescriptor: EntityDescriptor;
export let contractHistoryItemEntityDescriptor: EntityDescriptor;
export let contractSuspensionHistoryItemEntityDescriptor: EntityDescriptor;
export let groupRosterRelationEntityDescriptor: EntityDescriptor;
export let parameterEntityDescriptor: EntityDescriptor;
export let pilotProgressHistoryItemEntityDescriptor: EntityDescriptor;
export let premiumParameterEntityDescriptor: EntityDescriptor;
export let reportDefinitionEntityDescriptor: EntityDescriptor;
export let reportDefinitionAdminEntityDescriptor: EntityDescriptor;
export let resourceWorkRosterEntityDescriptor: EntityDescriptor;
export let salaryComponentEntityDescriptor: EntityDescriptor;
export let salaryComponentValueEntityDescriptor: EntityDescriptor;
export let securityPermissionEntityDescriptor: EntityDescriptor;
export let securityProfileEntityDescriptor: EntityDescriptor;
export let shareRegisterHistoryItemEntityDescriptor: EntityDescriptor;
export let taxStatusHistoryItemEntityDescriptor: EntityDescriptor;
export let telecomEntityDescriptor: EntityDescriptor;

const CODE_ENTITY = ["description", "code", "objectVersion", "creationDate", "creationUser", "modificationDate", "modificationUser"];

export class AppEntityDescriptors extends FoundationEntityDescriptors {

    init() {
        super.init();
        // please keep sorted alphabetically
        this.addEntityDescriptorWithCustomMiniFields({ name: "AbstractDataSource", miniFields: ["name", "objectVersion"], icon: "file", fieldToReturn: "name" });
        accountEntityDescriptor = addEntityDescriptor(new AccountEntityDescriptor({ name: "Account", icon: "laptop", miniFields: ["userName"], hasDuplicateButton: false }));
        accountHistoryItemEntityDescriptor = addEntityDescriptor(new EntityDescriptor({ name: "AccountHistoryItem", icon: "chart line" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "AccountType", miniFields: [...CODE_ENTITY], fieldToReturn: "description", icon: "edit" });
        this.addEntityDescriptorWithCustomMiniFields({ name: "AccountUsage", miniFields: [...CODE_ENTITY], fieldToReturn: "description", icon: "file alternate" });
        addEntityDescriptor(new class extends EntityDescriptor {
            toMiniString(entityWithMiniFields: any) {
                return entityWithMiniFields?.street + " no: " + entityWithMiniFields?.houseNumber;
            }
        }({ name: "Address", miniFields: ["street", "houseNumber", "objectVersion", "id", "city", "type"], icon: "home" }));
        addressHistoryItemEntityDescriptor = addEntityDescriptor(new class extends EntityDescriptor {
            toMiniString(entityWithMiniFields: any) {
                return entityWithMiniFields?.address?.street + ": " + entityWithMiniFields?.address?.houseNumber;
            }
        }({ name: "AddressHistoryItem", miniFields: ["address", "id", "objectVersion", "validFrom", "validUntil"], icon: "map" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "AddressType", miniFields: [...CODE_ENTITY], icon: "address book", fieldToReturn: "description" });
        this.addEntityDescriptorWithCustomMiniFields({ name: "AssignmentRole", miniFields: ["code", "description"], icon: "file alternate outline", fieldToReturn: "description" });
        backgroundTaskEntityDescriptor = addEntityDescriptor(new BackgroundTaskEntityDescriptor({ name: 'BackgroundTask', icon: 'tasks', hasDuplicateButton: false, defaultSort: { field: "processingStartDate", direction: "DESC" } }))
            .removeFieldDescriptors("targetMethod", "targetClass", "visibility", "deleteWhenFinished", "storeOutputFile", "type");
        addEntityDescriptor(new EntityDescriptor({name: "BackgroundTaskOutputFiles", miniFields: ["name", "content", "code"]}));
        balanceCounterHistoryItemEntityDescriptor = addEntityDescriptor(new BalanceCounterHistoryItemEntityDescriptor({ name: "BalanceCounterHistoryItem", icon: "balance" })
            .isInDefaultColumnConfig(false, "person"));
        addEntityDescriptor(new EntityDescriptor({ name: "BalanceType", icon: "balance" }));
        addEntityDescriptor(new EntityDescriptor({ name: "BalanceTypeForReport", icon: "balance", idFields: ['code'] }));
        addEntityDescriptor(new EntityDescriptor({ name: "Calculation", icon: "calculator" }));
        calendarDefinitionEntityDescriptor = addEntityDescriptor(new CalendarDefinitionEntityDescriptor({
            name: "CalendarDefinition", icon: "calendar alternate outline",
            miniFields: ["id", "code", "description", "objectVersion", "items {id, objectVersion, subject, description, startDate, endDate, recurring, allDay}",
                "properties {id, code, value, item {id, allDay, description, startDate, endDate, subject, recurring}}"]
        }));
        addEntityDescriptor(new class extends EntityDescriptor {
            toMiniString(entityWithMiniFields: any) {
                return entityWithMiniFields?.subject + (entityWithMiniFields?.recurrenceDefinition?.id ? " recDef: " + entityWithMiniFields?.recurrenceDefinition?.id : "");
            }
        }({ name: "CalendarItem", miniFields: ["subject"], icon: "calendar alternate" })
            .removeFieldDescriptors("recurrenceDefinition"));
        addEntityDescriptor(new EntityDescriptor({ name: "CalendarProperty", icon: "calendar alternate outline" }));
        addEntityDescriptor(new EntityDescriptor({ name: "CalendarPropertyCt", icon: "calendar alternate outline", miniFields: ["code"] }));
        cardEntityDescriptor = addEntityDescriptor(new EntityDescriptor({ name: "Card", icon: "credit card outline" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "CardType", icon: "credit card", miniFields: [...CODE_ENTITY], fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "CatalogAttachment", icon: "attach" })
            .isInDefaultColumnConfig(false, "content"));
        addEntityDescriptor(new EntityDescriptor({ name: "CatalogSnapshot", icon: "file" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "Category", icon: "clipboard", miniFields: ["environment", ...CODE_ENTITY], fieldToReturn: "description" })
            .removeFieldDescriptors("objectType");
        addEntityDescriptor(new EntityDescriptor({ name: "CategoryHistoryItem", icon: "clipboard outline" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "ChildAllowanceFund", icon: "child", miniFields: [...CODE_ENTITY], fieldToReturn: "description" });
        addEntityDescriptor(new class extends EntityDescriptor {
            toMiniString(entityWithMiniFields: any) {
                return entityWithMiniFields?.code + " " + entityWithMiniFields?.description + (entityWithMiniFields?.country?.description !== undefined ? " (" +
                    entityWithMiniFields?.country?.description + ")" : "");
            }
        }({ name: "City", miniFields: [`country {id iso3Code web autoCode internationalPhoneCode ${CODE_ENTITY.join(" ")}}`, "structName", ...CODE_ENTITY], icon: "map" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "CivilStatus", icon: "file", miniFields: [...CODE_ENTITY], fieldToReturn: "description" });
        civilStatusHistoryItemEntityDescriptor = addEntityDescriptor(new EntityDescriptor({ name: "CivilStatusHistoryItem", icon: "history" }));
        addEntityDescriptor(new CompanyEntityDescriptor({
            name: "Company", icon: "suitcase", miniFields: ["name", "code", "objectVersion", "objectType", "businessHours", "creationDate",
                "creationUser", "employersCode", "internet", `legalForm { id ${CODE_ENTITY.join(" ")} }`, "modificationDate", "modificationUser", "parent { code creationDate creationUser id objectType objectVersion }",
                `types { environment id ${CODE_ENTITY.join(" ")}}`, "vatNumber"]
        }))
            .isInDefaultColumnConfig(false, "objectType").removeFieldDescriptors("attachment");
        addEntityDescriptor(new EntityDescriptor({ name: "CompanyHistoryItem", icon: "suitcase" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "CompanyType", icon: "briefcase", miniFields: ["code", "description", "objectVersion"], fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "CompensationLossType", icon: "clipboard outline" }));
        addEntityDescriptor(new class extends EntityDescriptor {
            toMiniString(entityWithMiniFields: any): string {
                return entityWithMiniFields["currentComponentVersion"]?.["name"] + " " + "v" + entityWithMiniFields["currentComponentVersion"]?.["majorVersion"] +
                    "." + entityWithMiniFields["currentComponentVersion"]?.["minorVersion"];
            }
        }({
            name: "Component",
            miniFields: ["id", "currentComponentVersion {name, majorVersion, minorVersion}"],
            icon: "file",
            showInUI: false
        }));
        addEntityDescriptor(new EntityDescriptor({ name: "ComponentType", icon: "edit outline" }));
        addEntityDescriptor(new class extends EntityDescriptor {
            toMiniString(entityWithMiniFields: any): string {
                return entityWithMiniFields["name"] + " " + "v" + entityWithMiniFields["majorVersion"] +
                    "." + entityWithMiniFields["minorVersion"];
            }
        }({ name: "ComponentVersion", miniFields: ["name", "minorVersion", "majorVersion", "objectVersion", "objectType", "component {id, objectVersion, objectType, type { id, code } }"], icon: "file" })
            .isInDefaultColumnConfig(false, "component", "objectType"));
        addEntityDescriptor(new EntityDescriptor({ name: "ConstraintDefinition", icon: "file", miniFields: ["name"] }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "ConstraintGroup", icon: "group", miniFields: ["environment", ...CODE_ENTITY], fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "ConstraintGroupHistoryItem", icon: "user" }));
        addEntityDescriptor(new EntityDescriptor({ name: "ConstraintHistoryItem", icon: "file" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "ContractStatus", icon: "file", miniFields: [...CODE_ENTITY], fieldToReturn: "description" })
            .isInDefaultColumnConfig(false, "objectType");
        this.addEntityDescriptorWithCustomMiniFields({ name: "ContractType", icon: "file", miniFields: [...CODE_ENTITY], fieldToReturn: "description" });
        contractHistoryItemEntityDescriptor = addEntityDescriptor(new EntityDescriptor({ name: "ContractHistoryItem", icon: "file alternate outline" }))
            .removeFieldDescriptors("objectType");
        contractSuspensionHistoryItemEntityDescriptor = addEntityDescriptor(new EntityDescriptor({ name: "ContractSuspensionHistoryItem", icon: "calendar minus outline" }));
        addEntityDescriptor(new EntityDescriptor({ name: "CostCenter", icon: "money bill alternate" }));
        addEntityDescriptor(new EntityDescriptor({ name: "CounterType", icon: "balance" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "DataType", icon: "file", miniFields: ["description", "code", "objectVersion"], fieldToReturn: "description" });
        addEntityDescriptor(new CountryEntityDescriptor({ name: "Country", icon: "world", miniFields: ["id", "iso3Code", "web", "autoCode", "internationalPhoneCode", ...CODE_ENTITY] }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "Department", icon: "building", miniFields: [...CODE_ENTITY], fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "DepartmentHistoryItem", icon: "building outline" }));
        addEntityDescriptor(new EntityDescriptor({ name: "EducationCardAttachment", icon: "attach" })
            .isInDefaultColumnConfig(false, "content"));
        addEntityDescriptor(new EmployeeDetailEntityDescriptor({ name: "EmployeeDetail", icon: "id card" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "EmployeeFunction", icon: "briefcase", miniFields: ["description", "ejobType", ...CODE_ENTITY], fieldToReturn: "description" });
        addEntityDescriptor(new class extends EntityDescriptor {
            toMiniString(entityWithMiniFields: any): string {
                return entityWithMiniFields?.name + " " + entityWithMiniFields?.firstName + (entityWithMiniFields?.contractHistoryItem?.employeeNumber !== undefined ?
                    " (" + entityWithMiniFields?.contractHistoryItem?.employeeNumber + ")" : "");
            }
        }({ name: "EmployeeSnapshot", icon: "id card", miniFields: ["name", "firstName", "contractHistoryItem {employeeNumber}"], showInUI: false }))
        this.addEntityDescriptorWithCustomMiniFields({ name: "EmploymentBreak", icon: "pause", miniFields: [...CODE_ENTITY], fieldToReturn: "description" });
        addEntityDescriptor(new EnvironmentEntityDescriptor({ name: "Environment", icon: "suitcase", miniFields: ["description", "objectVersion"] }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "FinancialInstitution", icon: "building outline", miniFields: [...CODE_ENTITY], fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "FunctionHistoryItem", icon: "briefcase" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "Gender", icon: "venus mars", miniFields: ["id", ...CODE_ENTITY], fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "Group", icon: "group", miniFields: ["name"], showInUI: false }));
        addEntityDescriptor(new GroupContextEntityDescriptor({ name: "GroupContext", icon: "group", miniFields: ["name"] }));
        addEntityDescriptor(new GroupPropertyEntityDescriptor({ name: "GroupProperty", icon: "group" }));
        addEntityDescriptor(new EntityDescriptor({ name: "GroupPropertyCt", icon: "group", miniFields: ["code"] }));
        addEntityDescriptor(new EntityDescriptor({ name: "GroupRelationType", icon: "group" }));
        addEntityDescriptor(new EntityDescriptor({ name: "GroupResourcesHistoryItem", icon: "group" }));
        addEntityDescriptor(new GroupRosterRelationEntityDescriptor({ name: "GroupRosterRelation", icon: "sync alternate" }));
        addEntityDescriptor(new EntityDescriptor({ name: "GroupSnapshot", icon: "group", miniFields: ["name"], showInUI: false }))
            .addFieldDescriptor({name: "name", type: FieldType.string});
        addEntityDescriptor(new EntityDescriptor({ name: "GroupType", icon: "group" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "HalfDay", icon: "file", miniFields: ["id", "code", "description", "objectVersion"], fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "HealthInsurance", icon: "hospital" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "HourRegime", icon: "clock", miniFields: ["id", "environment", "objectType", ...CODE_ENTITY], fieldToReturn: "description" })
            .isInDefaultColumnConfig(false, "objectType");
        addEntityDescriptor(new EntityDescriptor({ name: "HourRegimeHistoryItem", icon: "clock outline" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "IncomeForm", icon: "money bill alternate", miniFields: ["id", ...CODE_ENTITY], fieldToReturn: "description" });
        this.addEntityDescriptorWithCustomMiniFields({ name: "Language", icon: "language", miniFields: ["id", ...CODE_ENTITY], fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "LayoutType", icon: "file text" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "LegalForm", icon: "clipboard", miniFields: ["id", "code", "description", "objectVersion"], fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "LicenseType", icon: "drivers license" }));
        this.addEntityDescriptorWithCustomMiniFields({
            name: "Location", miniFields: ["id", "objectVersion", ...CODE_ENTITY,
                `address { box
                           city {
                                country { autoCode internationalPhoneCode iso3Code web ${CODE_ENTITY.join(" ")} }
                                structName
                                ${CODE_ENTITY.join(" ")}
                            } 
                           creationDate creationUser houseNumber id modificationDate modificationUser objectVersion street
                           type { ${CODE_ENTITY.join(" ")} } }`], fieldToReturn: "description", icon: "map marker alternate"
        })
            .isInDefaultColumnConfig(false, "objectType");
        addEntityDescriptor(new EntityDescriptor({ name: "LocationHistoryItem", icon: "map signs" }));
        addEntityDescriptor(new MetadataContextEntityDescriptor({ name: "MetadataContext", icon: "file", miniFields: ["name"] })
            .isInDefaultColumnConfig(true, "code", "description", "environment", "name"));
        this.addEntityDescriptorWithCustomMiniFields({ name: "Nationality", icon: "globe", miniFields: ["id", ...CODE_ENTITY], fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "ObjectiveStatus", icon: "calendar alternate" }));
        addEntityDescriptor(new EntityDescriptor({ name: "OutputFile", icon: "file alternate", miniFields: ["name", "content", "code"]}));
        this.addEntityDescriptorWithCustomMiniFields({ name: "OvertimeType", icon: "clipboard", miniFields: ["id", "code", "description", "objectVersion"], fieldToReturn: "description" }).isInDefaultColumnConfig(false, "objectType");
        parameterEntityDescriptor = addEntityDescriptor(new ParameterEntityDescriptor({ name: "Parameter", icon: "wrench", miniFields: ["code"] }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "ParameterValueHistoryItem", icon: "cogs", miniFields: ["value", "validFrom", "validUntil", "id", "objectVersion"], fieldToReturn: "id" })
            .isInDefaultColumnConfig(false, "bytes");
        addEntityDescriptor(new EntityDescriptor({ name: "PasswordHistoryItem", icon: "star" }));
        addEntityDescriptor(new class extends EntityDescriptor {
            toMiniString(entityWithMiniFields: any) {
                return entityWithMiniFields?.name + " " + entityWithMiniFields?.firstName;
            }
        }({ name: "Person", icon: "user", miniFields: ["name", "firstName", "objectVersion", "objectType"] })
            .isInDefaultColumnConfig(false, "objectType").removeFieldDescriptors("attachment"));
        this.addEntityDescriptorWithCustomMiniFields({ name: "PilotProgressCategory", icon: "tasks", miniFields: ["description", "objectVersion"], fieldToReturn: "description" })
            .removeFieldDescriptors("months");
        addEntityDescriptor(new EntityDescriptor({ name: "PilotProgressCondition", icon: "tasks" }));
        addEntityDescriptor(new EntityDescriptor({ name: "PilotProgressCriterium", icon: "tasks" }));
        pilotProgressHistoryItemEntityDescriptor = addEntityDescriptor(new PilotProgressHistoryItemEntityDescriptor({ name: "PilotProgressHistoryItem", icon: "tasks" })
            .removeFieldDescriptors("extras")).isInDefaultColumnConfig(false, "validUntil");
        addEntityDescriptor(new EntityDescriptor({ name: "PilotProgressRelation", icon: "tasks" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "PremiumRecord", icon: "money bill alternate", miniFields: [...CODE_ENTITY],  fieldToReturn: "description" });
        this.addEntityDescriptorWithCustomMiniFields({ name: "PremiumLoaCategory", icon: "money bill alternate", miniFields: [...CODE_ENTITY, "lengthFrom", "lengthTo"],  fieldToReturn: "description" });
        this.addEntityDescriptorWithCustomMiniFields({ name: "PremiumRoute", icon: "money bill alternate", miniFields: [...CODE_ENTITY],  fieldToReturn: "description" });
        this.addEntityDescriptorWithCustomMiniFields({ name: "PremiumParameterType", icon: "money bill alternate", miniFields: [...CODE_ENTITY],  fieldToReturn: "description" });
        premiumParameterEntityDescriptor = addEntityDescriptor(new PremiumParameterEntityDescriptor({ name: "PremiumParameter", icon: "money bill alternate", miniFields: ["code"] }));
        addEntityDescriptor(new EntityDescriptor({ name: "Project", icon: "file alternate" }));
        addEntityDescriptor(new PropertyEntityDescriptor({ name: "Property", icon: "paint brush" }));
        addEntityDescriptor(new EntityDescriptor({ name: "PropertyCategory", icon: "paint brush", miniFields: ["description", "objectVersion", "code"] }));
        addEntityDescriptor(new PropertyValueEntityDescriptor({ name: "PropertyValue", icon: "paint brush" })
            .isInDefaultColumnConfig(true, "environment", "property", "context", "registrationType", "registrationStatus", "value"));
        addEntityDescriptor(new RealizationEntityDescriptor({ name: "Realization", miniFields: ["date", "dueDate"], icon: "file" }));
        addEntityDescriptor(new EntityDescriptor({ name: "RealizationResult", icon: "graduation" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "ReasonContractEnd", icon: "calendar times", miniFields: [...CODE_ENTITY], fieldToReturn: "description" });
        this.addEntityDescriptorWithCustomMiniFields({ name: "ReasonContractSuspension", icon: "calendar minus outline", miniFields: ["export", "exportCode", ...CODE_ENTITY], fieldToReturn: "description" });
        this.addEntityDescriptorWithCustomMiniFields({ name: "Regime", miniFields: ["code", "description"], icon: "file alternate outline", fieldToReturn: "description" });
        addEntityDescriptor(new RegistrationEntityDescriptor({ name: "Registration", icon: "file alternate" }));
        addEntityDescriptor(new EntityDescriptor({ name: "RegistrationAttachment", icon: "attach" })
            .isInDefaultColumnConfig(false, "content"));
        addEntityDescriptor(new EntityDescriptor({ name: "RegistrationRemark", icon: "tag", miniFields: ["value"] }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "RegistrationStatus", miniFields: ["description", "objectVersion", "code"], icon: "print", fieldToReturn: "description" })
            .isInDefaultColumnConfig(false, "type");
        this.addEntityDescriptorWithCustomMiniFields({ name: "RegistrationType", miniFields: ["description", "objectVersion", "name", "code"], icon: "file alternate outline", fieldToReturn: "name" });
        addEntityDescriptor(new EntityDescriptor({ name: "RegistrationTypeCategory", icon: "file alternate outline", miniFields: ["name"] }));
        addEntityDescriptor(new EntityDescriptor({ name: "RegistrationTypeStatusRelation", icon: "file text" }));
        addEntityDescriptor(new EntityDescriptor({ name: "RelationType", icon: "sticky note" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "ReportCategory", miniFields: ["description", "objectVersion", "code"], icon: "file", fieldToReturn: "description" })
            .isInDefaultColumnConfig(true, "code", "description");
        addEntityDescriptor(new EntityDescriptor({ name: "ReportDataSourceType", icon: "file" })
            .isInDefaultColumnConfig(true, "code", "description"));
        reportDefinitionEntityDescriptor = addEntityDescriptor(new ReportDefinitionEntityDescriptor({ name: "ReportDefinition", icon: "file", miniFields: ["name"], hasDuplicateButton: false }));
        reportDefinitionEntityDescriptor.doForFields(["name", "category", "id", "orderIndex", "code", "dataSource","online", "rootReport", "reportName", "description"], f => {
            f.isInDefaultColumnConfigForEditor = false;
        }).isInDefaultColumnConfig(false, "category", "id", "orderIndex", "code", "dataSource","online", "rootReport", "reportName", "description");
        reportDefinitionAdminEntityDescriptor = addEntityDescriptor(new ReportDefinitionAdminEntityDescriptor({ name: "ReportDefinitionAdmin", icon: "file", miniFields: ["name"], hasDuplicateButton: false }));
        addEntityDescriptor(new EntityDescriptor({ name: "ReportDefinitionXML", icon: "file excel", miniFields: ["name"] }));
        addEntityDescriptor(new EntityDescriptor({ name: "Resource", icon: "file", showInUI: false })
            .removeFieldDescriptors("attachment"));
        resourceWorkRosterEntityDescriptor = addEntityDescriptor(new ResourceWorkRosterEntityDescriptor({ name: "ResourceWorkRoster", icon: "file"}));
        addEntityDescriptor(new ResourceRosterRelationEntityDescriptor({ name: "ResourceRosterRelation", icon: "sync alternate" }));
        addEntityDescriptor(new RosterEntityDescriptor({ name: "Roster", icon: "file", miniFields: ["code", "description", "objectVersion"] }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "RotationSystem", miniFields: ["id", "code", "description", "objectVersion"], icon: "repeat", fieldToReturn: "description" });
        this.addEntityDescriptorWithCustomMiniFields({ name: "RoundingType", icon: "calendar alternate", miniFields: ["id", "code", "description", "objectVersion"], fieldToReturn: "description" });
        salaryComponentEntityDescriptor = addEntityDescriptor(new SalaryComponentEntityDescriptor({ name: "SalaryComponent", icon: "file" }));
        salaryComponentValueEntityDescriptor = addEntityDescriptor(new SalaryComponentValueEntityDescriptor({ name: "SalaryComponentValue", icon: "file" }));
        addEntityDescriptor(new SalaryPeriodEntityDescriptor({ name: "SalaryPeriod", icon: "file" }));
        addEntityDescriptor(new EntityDescriptor({ name: "SecurityHistoryItem", icon: "lock" }));
        addEntityDescriptor(new EntityDescriptor({ name: "SequenceHistoryItem", icon: "file" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "Scale", miniFields: [...CODE_ENTITY], icon: "user secret", fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "SecurityDefinition", icon: "file" }));
        securityPermissionEntityDescriptor = this.addEntityDescriptorWithCustomMiniFields({ name: "SecurityPermission", miniFields: ["code", "description", "objectVersion"], icon: "lock", fieldToReturn: "code" });
        securityProfileEntityDescriptor = addEntityDescriptor(new SecurityProfileEntityDescriptor({ name: "SecurityProfile", icon: "lock", miniFields: ["id", "code", "description", "objectVersion"] }));
        addEntityDescriptor(new EntityDescriptor({ name: "SequenceDefinition", icon: "file" }));
        shareRegisterHistoryItemEntityDescriptor = this.addEntityDescriptorWithCustomMiniFields({ name: "ShareRegisterHistoryItem", icon: "external share", miniFields: ["code", "description", "objectVersion"], fieldToReturn: "description" });
        this.addEntityDescriptorWithCustomMiniFields({ name: "ShipCt", icon: "ship", miniFields: ["id", "code", "description", "objectVersion"], fieldToReturn: "description" });
        this.addEntityDescriptorWithCustomMiniFields({ name: "ShipType", icon: "ship", miniFields: ["description", "objectVersion"], fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "Spare", icon: "sync" }));
        addEntityDescriptor(new EntityDescriptor({ name: "SuccessionDefinition", icon: "file" }));
        addEntityDescriptor(new EntityDescriptor({ name: "SuccessionHistoryItem", icon: "file" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "TaxStatus", icon: "money bill alternate outline", miniFields: [...CODE_ENTITY], fieldToReturn: "description" });
        taxStatusHistoryItemEntityDescriptor = addEntityDescriptor(new EntityDescriptor({ name: "TaxStatusHistoryItem", icon: "money bill alternate" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "Team", icon: "users", miniFields: ["environment", "objectType", ...CODE_ENTITY], fieldToReturn: "description" })
            .isInDefaultColumnConfig(false, "objectType");
        addEntityDescriptor(new EntityDescriptor({ name: "TeamHistoryItem", icon: "user circle" }));
        telecomEntityDescriptor = this.addEntityDescriptorWithCustomMiniFields({ name: "Telecom", icon: "comment alternate", miniFields: ["value", "id", "code", "description", "objectVersion", "type"], fieldToReturn: "description" });
        this.addEntityDescriptorWithCustomMiniFields({ name: "TelecomType", miniFields: [...CODE_ENTITY], icon: "comments", fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "TimeScheduleType", icon: "time" }));
        addEntityDescriptor(new EntityDescriptor({ name: "UserValue", icon: "paint brush" }));
        this.addEntityDescriptorWithCustomMiniFields({ name: "Vocative", miniFields: ["id", ...CODE_ENTITY], icon: "volume up", fieldToReturn: "description" });
        this.addEntityDescriptorWithCustomMiniFields({ name: "WorkArrangement", miniFields: [...CODE_ENTITY], icon: "suitcase", fieldToReturn: "description" });
        addEntityDescriptor(new EntityDescriptor({ name: "WorkPeriodType", icon: "file" }));
        addEntityDescriptor(new EntityDescriptor({ name: "WorkRoster", icon: "file" }));
        addEntityDescriptor(new EntityDescriptor({ name: "WorkRosterToTimeSchedule", icon: "file" })
            .addFieldDescriptor({ name: "fromDate", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT}) })
            .addFieldDescriptor({ name: "toDate", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT}) })
        );
    }

    addEntityDescriptorWithCustomMiniFields(entity: { name: string, miniFields: string[], icon: SemanticICONS, fieldToReturn: string }) {
        return addEntityDescriptor(new class extends EntityDescriptor {
            toMiniString(entityWithMiniFields: any) {
                return entityWithMiniFields?.[entity.fieldToReturn];
            }
        }({ name: entity.name, miniFields: entity.miniFields, icon: entity.icon }));
    }
}