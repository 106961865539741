import { createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldEditorProps, FieldRendererProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import _ from "lodash";
import React, { ReactNode } from "react";
import { CategoryAssociationFieldEditor } from "./SalaryComponentEntityDescriptor";


export class SalaryComponentValueEntityDescriptor extends EntityDescriptor {

    getGraphQlFieldsToRequest() {
        return `id objectVersion modificationDate modificationUser creationDate creationUser category value
            salaryComponent { id code description objectVersion modificationDate modificationUser creationDate creationUser }
            salaryPeriod { id validFrom, validUntil, description, simulation id objectVersion modificationDate modificationUser creationDate creationUser }`;
    }

    protected customize() {
        const salaryComponentValueEntityDescriptor = this;

        this.addFieldDescriptor(new class extends FieldDescriptor {
            name = "category"
            type = "Category"
            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                const newProps = {
                    ...props,
                    innerEntityDescriptor: entityDescriptors['Category']
                }
                return React.createElement(CategoryAssociationFieldEditor as any, newProps as FieldEditorProps)
            }

            protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
                const renderClass = fieldRenderers[FieldType.string];
                const categoryCode = typeof props.entity.category === 'object' ? props.entity.category.code : props.entity.category;
                const newProps = {
                    ...props,
                    entity: {
                        ...props.entity,
                        category: categoryCode
                    },
                    value: categoryCode
                };
                return super.renderFieldInternal(renderClass, newProps, entity);
            }

        });

        const sliceSalaryComponentValueEditor = salaryComponentValueEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceSalaryComponentValueEditor extends SliceEntityEditorPage {

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState
            }

            nestedSlices = {
                ...sliceEntityEditorPageOnlyForExtension.nestedSlices
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceSalaryComponentValueEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceSalaryComponentValueEditor>(this)
            }

        }).setEntityDescriptor(salaryComponentValueEntityDescriptor);

        salaryComponentValueEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceSalaryComponentValueEditor> & EntityEditorPageProps> {
            protected async onSaveInternal() {
                const categoryCode = typeof this.props.entity.category === 'object' ? this.props.entity.category.code : this.props.entity.category;
                this.props.dispatchers.setInReduxState({ entity: { ...this.props.entity, category: categoryCode } });
                await super.onSaveInternal();
            }
        }

    }
}