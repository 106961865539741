'use strict';

/*@ngInject*/
function Confirm(FormModal) {

	return {
		open : function(confirm) {
			return FormModal.open({
				scope: {
					template : require('./confirm.html'),
					icon: confirm.icon || 'fa fa-question-circle',
					title: confirm.title,
					message: confirm.message,
					messageId: confirm.messageId,
					panelCls: confirm.panelCls,
					model: true,
					dismissOnTimeout: confirm.dismissOnTimeout
				},
				actions: confirm.actions || ['ok', 'cancel']
			});
		}
	};
}

module.exports = Confirm;