import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { RESOURCES_SERVICE_FACADE_BEAN_GET_ENVIRONMENT_BY_ID } from "graphql/queries";
import _ from "lodash";
import { AssociationDescriptionAndIdEditor } from "pages/AssociationFieldEditors";
import React from "react";

const ENVIRONMENT: string = "environment";

export class MetadataContextEntityDescriptor extends EntityDescriptor {

    protected customize() {

        const metadataContextEntityDescriptor = this;

        const sliceMetadataContextEditor = metadataContextEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceMetadataContextEditor extends SliceEntityEditorPage {

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                initialEnvironment: undefined as unknown as { id: number, description: string }
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceMetadataContextEditor>(this),
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceMetadataContextEditor>(this),

                async getInitialEnvironment(id: number) {
                    let initialEnvironment = (await apolloClient.query({
                        query: RESOURCES_SERVICE_FACADE_BEAN_GET_ENVIRONMENT_BY_ID,
                        variables: {
                            id
                        }
                    })).data.resourcesServiceFacadeBean_environment;
                    this.getDispatchers().setInReduxState({ initialEnvironment });
                },

                superLoad: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.superLoad(id);
                    if (entity !== undefined && entity.environment !== undefined) {
                        await this.getInitialEnvironment(entity?.environment);
                    }
                    return entity;
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    if (entity.environment !== null && entity.environment !== undefined && entity.environment.id !== undefined) {
                        entity = { ...entity, environment: entity.environment.id };
                    }
                    await this.saveSuper(entity);
                },
            }
        }).setEntityDescriptor(metadataContextEntityDescriptor);

        metadataContextEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceMetadataContextEditor> & EntityEditorPageProps> {

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const that = this;
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "MetadataContext" }, false);

                // Custom field descriptor for environment field.
                metadataContextEntityDescriptor.doForFields(null, (fieldDescriptor) => result.entityDescriptor.addFieldDescriptor(fieldDescriptor));
                result.entityDescriptor.removeFieldDescriptors(ENVIRONMENT);
                result.entityDescriptor.addFieldDescriptor({ name: ENVIRONMENT, type: "Environment" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        const newProps = { ...props, onChange: async () => { await that.onApply(); } };
                        return React.createElement(AssociationDescriptionAndIdEditor as any, newProps as FieldEditorProps);
                    }
                });
                if (result.entity.environment !== undefined && result.entity.environment !== null && result.entity.environment === this.props.initialEnvironment?.id) {
                    result.entity = { ...result.entity, environment: this.props.initialEnvironment };
                }
                return result;
            }

            protected getEntityValuesFromForm() {
                // because in the form the "environment" field is of type "object" and in the table it is of type number (wnvironment id), a conversion
                // is needed when navigating from "edit properties" to "view properties" (from form to table)
                let values = super.getEntityValuesFromForm();
                if (values.environment && values.environment.id !== undefined) {
                    this.props.dispatchers.setInReduxState({ initialEnvironment: values.environment });
                    values = { ...values, environment: values.environment.id };
                }
                return values;
            }
        }
    }
}