'use strict';

function companyPickerTypeahead() {

	return {
		restrict: 'E',
		template: require('./company-picker-typeahead.html'),
		scope: {
			company: '=?',
			onCompanyChange: '<',
			disabled: '=?', // should be used as x-disabled
			required: '=?', // should be used as x-required,
			companyTypes: '=?'
		},
		controller: /*@ngInject*/ function($scope, Resources) {
			var companyPickerCtrl = $scope.companyPickerCtrl; // this

			 $scope.$watch('company', function(newCompany, oldCompany) {
			 	if (newCompany != oldCompany && $scope.onCompanyChange && (!angular.isString($scope.company) || (angular.isString($scope.company) && $scope.company == ''))) {
					$scope.onCompanyChange($scope.company);
				}
			 });

			companyPickerCtrl.clearCompany = function() {
				if ($scope.disabled) {
					return;
				}
				$scope.company = null;
			};

			companyPickerCtrl.getCompanies = function(searchText) {
				var config = {
					data: {
						searchText: searchText,
						companyTypeCodes: $scope.companyTypes
					}
				};
				return Resources.getCompanies({}, config).then(function(result) {
					return result.data;
				});
			};
		},
		controllerAs: 'companyPickerCtrl'
	};
}

module.exports = companyPickerTypeahead;