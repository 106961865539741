import { apolloClient, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, Utils } from "@crispico/foundation-react";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { getCreditBalance_balanceServiceFacadeBean_creditBalance, getCreditBalance_balanceServiceFacadeBean_creditBalance_summary_counters } from "apollo-gen/getCreditBalance";
import { getEmployee_employeeService_employee } from "apollo-gen/getEmployee";
import { BALANCE_SERVICE_FACADE_BEAN_GET_CREDIT_BALANCE, COMMON_SERVICE_ENDPOINT_RUN_IN_BACKGROUND } from "graphql/queries";
import moment from "moment";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import React, { Component } from "react";
import { Button, Checkbox, CheckboxProps, Dimmer, Form, Loader, Table, TableRow } from "semantic-ui-react";
import { REPORT_USER } from "./PremiumTab";

const CREDIT_REPORT_CODE = "CreditBalanceReport";
const CREDIT_REPORT_NAME = "Krediet rapport";

export const sliceCreditTab = createSliceFoundation(class SliceCreditTab {

    initialState = {
        creditData: undefined as unknown as getCreditBalance_balanceServiceFacadeBean_creditBalance,
        includeStatistics: false as boolean,
        includeEarningDates: false as boolean,
        date: moment().startOf("day").toDate() as Date,
        loading: false as boolean
    }

    reducers = {
        ...getBaseReducers<SliceCreditTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceCreditTab>(this),

        async loadCreditData(employeeId: number | undefined | null, untilDate: Date) {
            let creditData: getCreditBalance_balanceServiceFacadeBean_creditBalance | undefined = undefined;

            if (employeeId) {
                creditData = (await apolloClient.query({
                    query: BALANCE_SERVICE_FACADE_BEAN_GET_CREDIT_BALANCE,
                    variables: {
                        automaticallyAdjustUntilDate: true,
                        includeEarningDates: false,
                        includeStatistics: false,
                        untilDate: untilDate,
                        employeeId: employeeId
                    }
                })).data.balanceServiceFacadeBean_creditBalance;
                if (creditData?.summary?.counters) {
                    let counters = creditData.summary.counters.sort((a: getCreditBalance_balanceServiceFacadeBean_creditBalance_summary_counters | null, b: getCreditBalance_balanceServiceFacadeBean_creditBalance_summary_counters | null) =>
                        moment(b?.validFrom).valueOf() - moment(a?.validFrom).valueOf());
                    creditData = { ...creditData, summary: { ...creditData.summary, counters } };
                }
            }
            this.getDispatchers().setInReduxState({ creditData });
        },

        async runReport(employeeId: number | null | undefined, includeEarningDates: boolean, includeStatistics: boolean) {
            if (!employeeId) {
                return;
            }

            const runVariable: { [k: string]: any } = {
                name: CREDIT_REPORT_NAME,
                type: ProteusConstants.REPORT_GENERATION,
                inputParameters: [
                    { name: REPORT_USER, value: ProteusUtils.getUserCompleteName() },
                    { name: "reportCode", value: CREDIT_REPORT_CODE },
                    { name: "Employee.persoon", value: employeeId },
                    { name: "Berekeningsdatum", value: moment(new Date(this.getState().date)).format(ProteusConstants.DATE_FORMAT) },
                    { name: "includeEarningDates", value: includeEarningDates },
                    { name: "includeStatistics", value: includeStatistics }
                ]
            };

            await apolloClient.mutate({
                mutation: COMMON_SERVICE_ENDPOINT_RUN_IN_BACKGROUND,
                variables: {
                    backgroundTask: runVariable
                }
            });
            Utils.showGlobalAlert({ message: _msg("ReportDefinition.runMessage", CREDIT_REPORT_NAME), severity: Severity.INFO });
        }
    }
});

type PropsNotFromState = {
    employee: getEmployee_employeeService_employee | undefined | null;
}

export class CreditTab extends TabbedPage<PropsFrom<typeof sliceCreditTab> & PropsNotFromState> {

    protected renderCountersTableBody() {
        if (!this.props.creditData.summary?.counters) {
            return;
        }

        const rows = [];
        for (let i = 0; i < this.props.creditData.summary?.counters?.length; i++) {
            rows.push(
                <TableRow key={i} verticalAlign="middle">
                    <Table.Cell key={0}>{moment(new Date(this.props.creditData.summary.counters[i]?.validFrom)).format(ProteusConstants.DATE_TIME_FORMAT)}</Table.Cell>
                    <Table.Cell key={1}>{this.props.creditData.summary.counters[i]?.counterType?.description || ""}</Table.Cell>
                    <Table.Cell key={2}>{this.props.creditData.summary.counters[i]?.value || ""}</Table.Cell>
                </TableRow>
            );
        }
        return rows;
    }

    resetCheckboxes() {
        this.props.dispatchers.setInReduxState({ includeEarningDates: false, includeStatistics: false});
    }

    renderSaveBarAdditionalContent = () => {
        return (
            this.props.employee ?
                <>
                    <div key="div1" className="FunctionsTab_barDivider" />
                    <Form style={{ float: 'left' }}>
                        <Form.Field>
                            <DatePickerReactCalendar className="CreditTab_datePicker" format="DD/MM/YYYY" allowClear={false} value={moment(this.props.date)}
                                onChange={async (date) => {
                                    this.props.dispatchers.setInReduxState({ loading: true });
                                    await this.props.dispatchers.loadCreditData(this.props.employee?.id, date!.toDate());
                                    this.props.dispatchers.setInReduxState({ date: date!.toDate() });
                                    this.props.dispatchers.setInReduxState({ loading: false });
                                }} />
                        </Form.Field>
                    </Form>
                    <div className="CreditTab_checkbox">
                        <Checkbox checked={this.props.dispatchers.getState().includeEarningDates} label={_msg("EmployeeEditor.credit.showEarningDates.label")}  onChange={() => {
                                 this.props.dispatchers.setInReduxState({ includeEarningDates: !this.props.includeEarningDates });
                        }}/>
                    </div>
                    <div className="CreditTab_checkbox">
                        <Checkbox checked={this.props.dispatchers.getState().includeStatistics} label={_msg("EmployeeEditor.credit.showStatistics.label")} onChange={() => {
                                 this.props.dispatchers.setInReduxState({ includeStatistics: !this.props.includeStatistics });
			}}/>
                    </div>
                    <div>
                        <Button disabled={!this.props.employee} primary
                            onClick={async () => {
                                await this.props.dispatchers.runReport(this.props.employee?.id, this.props.dispatchers.getState().includeEarningDates, this.props.dispatchers.getState().includeStatistics);
                            }}>{_msg("Sequences.report.label")}
                        </Button>
                    </div>
                </> : undefined
        );
    }

    render() {
        if (!this.props.creditData) {
            return <></>;
        }

        return (
            <>
                <Dimmer inverted active={this.props.loading} page={true}><Loader size='large'>{_msg("general.loading")}</Loader></Dimmer>
                <div className="CreditTab_summaryTable">
                    <Table celled unstackable compact selectable striped>
                        <Table.Body>
                            <Table.Row key={0} verticalAlign="middle">
                                <Table.Cell key={0} width={8}>{_msg("EmployeeEditor.balance.latestBalance.label")}</Table.Cell>
                                <Table.Cell key={1} width={8}>{this.props.creditData.summary?.finalBalance || ""}</Table.Cell>
                            </Table.Row>
                            <Table.Row key={1} verticalAlign="middle">
                                <Table.Cell key={0} width={8}>{_msg("EmployeeEditor.balance.legalMinimumDate.label")}</Table.Cell>
                                <Table.Cell key={1} width={8}>{this.props.creditData.summary?.legalMinimumDate ? moment(new Date(this.props.creditData.summary.legalMinimumDate)).format(ProteusConstants.DATE_TIME_FORMAT) : ""}</Table.Cell>
                            </Table.Row>
                            <Table.Row key={2} verticalAlign="middle">
                                <Table.Cell key={0} width={8}>{_msg("EmployeeEditor.balance.adjustedCalculationDate.label")}</Table.Cell>
                                <Table.Cell key={1} width={8}>{this.props.creditData.summary?.adjustedUntilDate ? moment(new Date(this.props.creditData.summary.adjustedUntilDate)).format(ProteusConstants.DATE_TIME_FORMAT) : ""}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
                <div className="CreditTab_countersTable">
                    <Table celled unstackable compact selectable striped>
                        <Table.Header>
                            <Table.Row verticalAlign="middle">
                                <Table.HeaderCell key={0} width="5">{_msg("fromDate.label")}</Table.HeaderCell>
                                <Table.HeaderCell key={1} width="6">{_msg("counter.label")}</Table.HeaderCell>
                                <Table.HeaderCell key={2} width="5">{_msg("value.label")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.renderCountersTableBody()}
                        </Table.Body>
                    </Table>
                </div>
            </>
        );
    }

    componentWillUnmount() {
        this.props.dispatchers.setInReduxState({ date: moment().startOf("day").toDate() as Date, creditData: undefined, includeStatistics: false, includeEarningDates: false });
    }
}