'use strict';

function proteusDatePicker() {
	
	return {
		restrict: 'E',
		scope: {
			date: '=',
			required: '=',
			options: '=',
			format: '@?'
		},
		template: require('./date-picker.html'),
		controllerAs: 'picker',
		link: function($scope) {
			$scope.format = $scope.format || 'dd/MM/yy';
		}
	};
	
}

module.exports = proteusDatePicker;