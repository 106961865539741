import { EntityDescriptor, EntityTablePage, EntityTablePageProps, PropsFrom } from "@crispico/foundation-react";
import { fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { CustomOutputFilesRenderer, CustomStatusRenderer } from "./customFieldRenderersEditors";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { ProteusUtils } from "ProteusUtils";
import { ProteusConstants } from "ProteusConstants";
import { FilterOperators } from "@crispico/foundation-gwt-js";

fieldRenderers["BackgroundTaskStatus"] = CustomStatusRenderer;
fieldRenderers["OutputFile"] = CustomOutputFilesRenderer;

export class BackgroundTaskEntityDescriptor extends EntityDescriptor {

    protected customize() {
        const backgroundTaskEntityDescriptor = this;
        backgroundTaskEntityDescriptor.addFieldDescriptor({ name: "status",  type: "BackgroundTaskStatus"});
        backgroundTaskEntityDescriptor.addFieldDescriptor({ name: "outputFiles", type: "OutputFile"  });

        backgroundTaskEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<PropsFrom<typeof backgroundTaskEntityDescriptor.infoTable.slice> & EntityTablePageProps> {
            componentDidMount() {
                const { entityDescriptor } = this.props.dispatchers.getSlice();
                entityDescriptor.addFieldDescriptor({ name: "owner", type: "Person", filterable: ProteusUtils.checkPermission(ProteusConstants.BACKGROUND_TASKS_VIEW_ALL_PERMISSION) });
                const user = ProteusUtils.getCurrentUser();
                entityDescriptor.defaultFilter = Filter.createForClient("owner", FilterOperators.forEntityManyToOne.equals, user.id + '|/|' + user.name + ' ' + user.firstName + '|/|Person');
            }

            onDoubleClickItem() {
            }
        }
    }
}