'use strict';

function proteusWizard() {

	var Wizard = function() {

		this.steps = [];
		this.selected = 1;

		var count = 0;
		
		this.addStep = function() {
			var index = ++count;
			var step = {
				index: index
			};
			this.steps[index] = step;
			return step;
		};

		this.prev = function() {
			this.goTo(this.steps[this.selected - 1]);
		};

		this.goTo = function(step) {
			if (!step || step.disabled) {
				return;
			}
			this.selected = step.index;
			step.onShow();
		};

		this.goToIndex = function(idx) {
			var step = this.steps[idx];
			this.goTo(step);
		};
		
		this.clickAction = function(action) {
			if (action.invalid) {
				return;
			}
			action.onClick();
		}

		return this;
	};

	return {
		restrict: 'AE',
		transclude: true,
		template: require('./wizard.html'),
		controller: /*@ngInject*/ function($scope, $element, $attrs, ErrorModal) {
			// initialize wizard
			$scope.wizard = new Wizard();
			$scope.wizard.beforeNext = $attrs.beforeNext;
			$scope.wizard.save = !$attrs.onSave ? angular.noop : function() {
				if ($scope.wizard.invalidForSave || $scope.wizard.invalid) {
					return;
				}
				$scope.$eval($attrs.onSave);
			};
			
			if ($attrs.actions) {
				$scope.wizard.actions = $scope.$eval($attrs.actions);
			}

			if ($attrs.invalidErrorMessage) {
				$scope.wizard.invalidErrorMessage = $attrs.invalidErrorMessage;
			}

			$scope.wizard.next = function() {
				$scope.$eval($attrs.beforeNext);
				if (this.isFormInvalid()) {
					return;
				}
				var step, idx = this.selected;
				do {
					idx++;
					step = this.steps[idx];
				} while (step.optional);
				this.goTo(step);
			};

			var steps = $element.find('.steps-container');
			
			this.addStep = function(scope, transcludeFn) {
				// add it to this step's scope
				scope.step = $scope.wizard.addStep();

				// transclude step title into wizard navigation
				transcludeFn(scope, function(step) {
					steps.append(step);
				}, null, steps);
			};

			$scope.wizard.isFormInvalid = function() {
				if ($scope.wizard.invalid) {
					if ($scope.wizard.invalidErrorMessage) {
						ErrorModal.open({
							titleId: ErrorModal.VALIDATION_ERROR,
							message: $scope.wizard.invalidErrorMessage
						});
					}
					return true;
				}
			};
		}
	};
	
}

module.exports = proteusWizard;