import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import _ from "lodash";
import { createSliceFoundation } from "@crispico/foundation-react/reduxHelpers";
import { SliceEntityEditorPage } from "@crispico/foundation-react/entity_crud/EntityEditorPage";

export class CountryEntityDescriptor extends EntityDescriptor {

    toMiniString(entityWithMiniFields: any) {
        return entityWithMiniFields?.description;
    }

    protected customize() {

        const countryEntityDescriptor = this;
        countryEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceBalanceCounterEditor extends SliceEntityEditorPage {

            getSaveOperationName(): string {
                return `countryService_saveCountry`;
            }

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }
        }).setEntityDescriptor(countryEntityDescriptor);
    }
}