import { ItemRenderer } from '@crispico/react-timeline-10000';
import { ConstraintItemRenderer } from '../itemRenderer/ConstraintItemRenderer';
import { RegistrationItemRenderer } from '../itemRenderer/RegistrationItemRenderer';
import { ShipWorkPeriodItemRenderer } from '../itemRenderer/ShipWorkPeriodItemRenderer';
import { CustomItem, RendererType } from '../mapper/GanttAdapter';

export class CustomItemRenderer extends ItemRenderer {

    render() {
        if ((this.props.item as CustomItem).type === RendererType.REGISTRATION) {
            return <RegistrationItemRenderer {...this.props} />;
        } else if ((this.props.item as CustomItem).type === RendererType.SHIP_WORK_PERIOD) {
            return <ShipWorkPeriodItemRenderer {...this.props} />
        } else {
            return <ConstraintItemRenderer {...this.props} />
        }
    }

}