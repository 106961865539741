import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import _ from "lodash";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { CrudEditorPageRenderHeaderParams, EntityEditorPage, EntityEditorPageProps, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { apolloClient, ColumnDefinition, EntityTablePage, EntityTablePageProps, ITableActionParamForRun, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import React from "react";
import { Button, Form, Menu, MenuItemProps, Message, Radio, Segment } from "semantic-ui-react";
import { ProteusUtils } from "ProteusUtils";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { OverrideableElement, TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { NavLink } from "react-router-dom";
import { ProteusConstants } from "ProteusConstants";
import { BalanceTypeWithPropertyAssociationFieldEditor, ComponentVersionAssociationFieldEditor, EvaluationsWithRealizationsAssociationFieldEditor, GroupSnapshotByContextAssociationFieldEditor } from "./customizedFieldEditors";
import { RealizationAssociationFieldEditor, RegistrationTypeWithPropertyAssociationFieldEditor, ResourceSnapshotAssociationFieldEditor, WorkPeriodTypeAssociationFieldEditor } from "./customizedFieldEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { COMMON_SERVICE_ENDPOINT_RUN_IN_BACKGROUND, EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT, REPORTING_SERVICE_FACADE_BEAN_GET_REPORT_DEFINITION_BY_ID, RESOURCES_SERVICE_ENDPOINT_GET_CATEGORIES, RESOURCES_SERVICE_ENDPOINT_GET_TEAMS } from "graphql/queries";
import { Person } from "pages/employeeDetail/EmployeeDetailEntityDescriptor";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { DropdownFieldEditor } from '@crispico/foundation-react/entity_crud/fieldEditors/DropdownFieldEditor';
import { IAction } from "@crispico/react-timeline-10000/types/components/ContextMenu/IAction";

export const BOOLEAN = "java.lang.Boolean";
export const DATE = "java.util.Date";
export const INTEGER = "java.lang.Integer";
export const STRING = "java.lang.String";
export const GROUP_PICKER = "groupPicker_";
export const COMPONENT_PICKER = "componentPicker";
const REGIME_PICKER = "regimePicker";
const ROLE_PICKER = "rolePicker";
const EVALUATION_RESULT = "EvaluationResultOverview";
const OCCUPATION_REPORT = "OccupationOverviewReport";
const EMPLOYEE_PARAMETER = "employeeId";
const USER_PARAMETER = "user";
const CATEGORY_PARAMETER = "categoryId";
const TEAM_PARAMETER = "teamId";
const GROUP_PARAMETER = "groupCode";
const CONSTRAINT_GROUPS_REPORT = "ConstraintGroupsReport";
const SEARCH_TEXT = 'searchText';
const COMPONENT_IDS = "componentIds";
const EDUCATION_REALIZATION_TYPE = 'EducationRealization';

const months = [
    { from: 1 },
    { from: 2 },
    { from: 3 },
    { from: 4 },
    { from: 5 },
    { from: 6 },
    { from: 7 },
    { from: 8 },
    { from: 9 },
    { from: 10 },
    { from: 11 },
    { from: 12 }
];

interface AdditionalFields {
    completeName: string,
    id: number,
    prompting: boolean,
    type: string
    defaultValue: string,
    label: string,
    securityRoleForEditingParameter: string,
    mandatory: boolean
}

interface DefaultValues {
    id: number,
    type: string,
    code?: string,
    name?: string,
    description?: string
}

interface ComponentVersion {
    name: string,
    id: number,
    minorVersion: number,
    majorVersion: number
}

interface DisabledFields {
    disableEmployeePicker: boolean,
    disableGroupPicker: boolean,
    disableComponentPicker: boolean,
    disableRealizationPicker: boolean,
    disableTeamPicker: boolean,
    disableCategoryPicker: boolean
}

export class ReportDefinitionEntityDescriptor extends EntityDescriptor {

    getGraphQlFieldsToRequest() {
        return "id name orderIndex code category{ code description } description "
    }

    protected customize() {
        const reportDefinitionEntityDescriptor = this;

        const sliceReportDefinitionTable = reportDefinitionEntityDescriptor.infoTable.slice = createSliceFoundation(class SliceReportDefinitionTable extends SliceEntityTablePage {

            initialState = {
                ...sliceEntityTablePageOnlyForExtension.initialState,
                category: ProteusConstants.REPORTS as string
            }

            reducers = {
                ...sliceEntityTablePageOnlyForExtension.reducers,
                ...getBaseReducers<SliceReportDefinitionTable>(this)
            }

            impures = {
                ...sliceEntityTablePageOnlyForExtension.impures,
                ...getBaseImpures<SliceReportDefinitionTable>(this),

                /**
                * Filter reports by category: if "reports" category is selected, keep only the reports without category or with category !== payroll_file
                */
                adjustFilterBeforeLoad(filter: Filter): Filter {
                    if (this.getState().category === ProteusConstants.REPORTS) {
                        filter.filters?.push({ field: ProteusConstants.CATEGORY, operator: "isEmpty", value: "" });
                        filter.filters?.push({ field: ProteusConstants.CATEGORY_ID, operator: "notEquals", value: ProteusConstants.PAYROLL_FILE_ID });
                        filter.operator = "or";
                    } else {
                        filter.filters?.push({ field: ProteusConstants.CATEGORY_ID, operator: "equals", value: ProteusConstants.PAYROLL_FILE_ID });
                    }
                    return filter;
                },

                adjustSortsBeforeLoad(sorts: Sort[]): Sort[] {
                    return sorts.concat(Object.assign([{ field: "orderIndex", direction: "ASC" }])).filter(item => item.field !== ProteusConstants.ID);
                }
            }
        }).setEntityDescriptor(reportDefinitionEntityDescriptor);

        reportDefinitionEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<PropsFrom<typeof sliceReportDefinitionTable> & EntityTablePageProps> {

            static defaultProps = {
                showDtoCrudButtons: false,
                showImportButton: false,
                showExportButton: false,
                hideActionsBar: true
            }

            adjustEntities(entities: any[]): any[] {
                return entities.filter((entity) => ProteusUtils.checkPermission("EXECUTE_" + entity.code) === true && entity.orderIndex !== 0);
            }

            /**
            * Overriden method from EntityTablePage to keep only the "edit" button from tables's rows & cells
            */
            protected provideActionsForRow(actionParam: ITableActionParamForRun): IAction[] {
                const { entityDescriptor } = this.props.dispatchers.getSlice();
                return [
                    {
                        icon: "play",
                        label: _msg("general.run"),
                        run: (param) => {
                            this.goToEditor(entityDescriptor.getEntityEditorUrl(param.selection[0].id));
                        }
                    }
                ];
            }
            
            protected provideActionsForCell(actionParam: ITableActionParamForRun): IAction[] {
                return [];
            }

            protected handleChange = (e: any, { value }: any) => {
                this.props.dispatchers.setInReduxState({ category: value });
                this.refresh();
            }

            renderPane(entityDescriptor: EntityDescriptor, columns: Array<ColumnDefinition>) {
                return <>
                    <Segment className="less-margin-top-bottom EntityTablePage_bar">
                        <Form>
                            <Form.Group inline className="ReportDefinition_filter">
                                <Form.Field>
                                    <Radio
                                        label={_msg("popup.reports")} name='radioGroup' value={ProteusConstants.REPORTS}
                                        checked={this.props.category === ProteusConstants.REPORTS} onChange={this.handleChange} />
                                </Form.Field>
                                <Form.Field>
                                    <Radio
                                        label={_msg('popup.wages')} name='radioGroup' value={ProteusConstants.WAGES}
                                        checked={this.props.category === ProteusConstants.WAGES} onChange={this.handleChange} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Segment>
                    {super.renderPane(entityDescriptor, columns)}
                </>
            }

            renderSubheader() {
                return _msg("ReportDefinition.table.subheader.label");
            }
        }

        const sliceReportDefinitionEditor = reportDefinitionEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceReportDefinitionEditor extends SliceEntityEditorPage {

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                additionalFields: [] as AdditionalFields[],
                showRunMessage: false as boolean,
                defaultValues: [] as DefaultValues[],
                selectedEmployee: undefined as unknown as Person,
                selectedComponent: undefined as unknown as ComponentVersion,
                disabledFields: {
                    disableEmployeePicker: false,
                    disableGroupPicker: false,
                    disableComponentPicker: false,
                    disableRealizationPicker: false,
                    disableTeamPicker: false,
                    disableCategoryPicker: false
                } as DisabledFields
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceReportDefinitionEditor>(this),
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceReportDefinitionEditor>(this),

                /**
                * For each page, load ReportDefinition to generate the screen
                * Load defaultValues to populate the comboBoxes for fieldType "categorie", "ploeg" or "groupPicker_"
                */
                async getReportDefinition(id: number) {
                    const defaultValues = [];
                    const reportDefinition = (await apolloClient.query({
                        query: REPORTING_SERVICE_FACADE_BEAN_GET_REPORT_DEFINITION_BY_ID,
                        variables: { id: id }
                    })).data["reportingServiceFacadeBean_reportDefinitionById"];
                    for (let i = 0; i < reportDefinition.parameters.length; i++) {
                        if (reportDefinition.parameters[i].prompting === true) {
                            if (reportDefinition.parameters[i].type === ProteusConstants.CATEGORIE) {
                                const defaultCategory = (await apolloClient.query({ query: RESOURCES_SERVICE_ENDPOINT_GET_CATEGORIES })).data["resourcesServiceEndpoint_categories"][0];
                                defaultValues.push({ type: ProteusConstants.CATEGORIE, id: defaultCategory?.id, description: defaultCategory?.description });
                            } else if (reportDefinition.parameters[i].type === ProteusConstants.PLOEG) {
                                const defaultTeam = (await apolloClient.query({ query: RESOURCES_SERVICE_ENDPOINT_GET_TEAMS })).data["resourcesServiceEndpoint_teams"][0];
                                defaultValues.push({ type: ProteusConstants.PLOEG, id: defaultTeam?.id, description: defaultTeam?.description });
                            } else if (reportDefinition.parameters[i].type.slice(0, 12) === GROUP_PICKER) {
                                const groupCode = reportDefinition.parameters[i].type.substring(12);
                                if (groupCode !== ProteusConstants.GROUP_CODE_EMPLOYEE_EXPLORER && groupCode !== ProteusConstants.GROUP_CODE_ASSIGNMENT_REPORT) {
                                    const defaultGroup = (await apolloClient.query({
                                        query: EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT,
                                        variables: {
                                            owner: ProteusUtils.getCurrentUser().id,
                                            groupContextCode: groupCode
                                        }
                                    })).data["employeeService_groupSnapshotsByContext"][0];
                                    defaultValues.push({ type: GROUP_PICKER, id: defaultGroup?.id, name: defaultGroup?.name, code: defaultGroup?.code });
                                }
                            }
                        }
                    }
                    this.getDispatchers().setInReduxState({ additionalFields: reportDefinition.parameters, defaultValues });
                },

                /**
                * Create backgroundTask and run the report
                * For each field we need to select the needed value (id/ code/ ...) from the entity
                */
                async runReport(entity: any) {
                    const currentUser = ProteusUtils.getUserCompleteName();
                    const runVariable: { [k: string]: any } = {};
                    const inputParameters = [];
                    const parameters = this.getState().additionalFields;
                    const valueAsId = [ProteusConstants.PLOEG, ProteusConstants.CATEGORIE, ProteusConstants.REALIZATION];
                    const valueAsCode = [ROLE_PICKER, REGIME_PICKER];
                    runVariable.name = entity.name;
                    if (entity.category?.code === ProteusConstants.PAYROLL_FILE_CATEGORY_CODE) {
                        runVariable.type = ProteusConstants.PAYROLL_GENERATION;
                    } else {
                        runVariable.type = ProteusConstants.REPORT_GENERATION;
                        inputParameters.push({ name: "RapportGebruiker", value: currentUser });
                    }
                    inputParameters.push({ name: "reportCode", value: entity.code });
                    const validationError = this.validateParameters(parameters, entity);
                    if (validationError) {
                        Utils.showGlobalAlert({ message: validationError, severity: Severity.INFO });
                        return true;
                    }
                    for (let i = 0; i < parameters.length; i++) {
                        if (parameters[i].prompting === true) {
                            let value = entity[parameters[i].completeName];
                            if (valueAsId.includes(parameters[i].type)) {
                                value = entity[parameters[i].completeName]?.id;
                            } else if (valueAsCode.includes(parameters[i].type) || parameters[i].type.slice(0, 12) === GROUP_PICKER) {
                                value = entity[parameters[i].completeName]?.code;
                            } else if (parameters[i].type === ProteusConstants.WORK_PERIOD_TYPE || parameters[i].type === ProteusConstants.REGISTRATION_TYPE) {
                                value = "";
                                for (let j = 0; j < entity[parameters[i].completeName]?.length; j++) {
                                    value += entity[parameters[i].completeName][j].code + ",";
                                }
                            } else if (parameters[i].type === ProteusConstants.PERSOON) {
                                if (parameters[i].securityRoleForEditingParameter === null || ProteusUtils.checkPermission(parameters[i].securityRoleForEditingParameter)) {
                                    value = entity[parameters[i].completeName]?.id;
                                } else {
                                    value = ProteusUtils.getCurrentUser().id;
                                }
                            } else if (parameters[i].type === DATE) {
                                const date = new Date(entity[parameters[i].completeName]);
                                value = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
                                if (parameters[i].defaultValue === "end_current_month") {
                                    value += " 23:59";
                                }
                            } else if (parameters[i].type === ProteusConstants.CATALOG) {
                                value = entity[parameters[i].completeName]?.id;
                            } else if (parameters[i].type === ProteusConstants.BALANCE_TYPE_CODE) {
                                value = entity[parameters[i].completeName]?.code;
                            } else if (parameters[i].type === COMPONENT_PICKER) {
                                value = entity[parameters[i].completeName]?.map((component: { id: number; }) => component.id);
                            }
                            inputParameters.push({ name: parameters[i].completeName, value: value, label: parameters[i].label });
                        }
                    }
                    runVariable.inputParameters = inputParameters;
                    await apolloClient.mutate({ mutation: COMMON_SERVICE_ENDPOINT_RUN_IN_BACKGROUND, variables: { backgroundTask: runVariable } });
                },

                validateParameters(parameters: AdditionalFields[], entity: any) {
                    // In reports with employee AND group as parameters, one of them should always be selected.
                    const employeeAndGroupParams = parameters.filter(param => param.completeName === EMPLOYEE_PARAMETER || param.completeName === GROUP_PARAMETER);
                    if (employeeAndGroupParams.length === 2) {
                        let employeeValue = entity[EMPLOYEE_PARAMETER];
                        let groupValue = entity[GROUP_PARAMETER];
                        if (!employeeValue && !groupValue) {
                            return _msg('ReportDefinition.error.mandatoryEmployeeOrGroup');
                        }
                    }

                    const employeeOrCategoryAndTeamParams = parameters.filter(param => param.completeName === USER_PARAMETER || param.completeName === CATEGORY_PARAMETER || param.completeName == TEAM_PARAMETER);
                    if (employeeOrCategoryAndTeamParams.length === 3) {
                        let employeeValue = entity[USER_PARAMETER];
                        let categorieValue = entity[CATEGORY_PARAMETER];
                        let teamValue = entity[TEAM_PARAMETER];
                        if (!employeeValue && (!categorieValue || !teamValue)) {
                            return _msg('ReportDefinition.error.mandatoryEmployeeOrCategoryAndTeam');
                        }
                    }

                    // opleiding realisatie (education realization) report
                    if (entity.code === EDUCATION_REALIZATION_TYPE) {
                        let componentIds = entity[COMPONENT_IDS];
                        let searchText = entity[SEARCH_TEXT];
                        if (!componentIds && !searchText) {
                            return _msg('ReportDefinition.error.mandatoryForEducationRealization');
                        }
                    }

                    // Other parameters will use the `mandatory` property.
                    const mandatoryParams = parameters.filter(param => param.mandatory);
                    for (const mandatoryParam of mandatoryParams) {
                        if (mandatoryParam.prompting && (entity[mandatoryParam.completeName] === undefined || entity[mandatoryParam.completeName] === null)) {
                            return _msg('ReportDefinition.error.mandatoryParameter', mandatoryParam.label);
                        };
                    }
                    return null;
                }
            }
        }).setEntityDescriptor(reportDefinitionEntityDescriptor);

        reportDefinitionEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceReportDefinitionEditor> & EntityEditorPageProps> {
            propsForFormCache: CrudFormInEditorProps | undefined;

            // When some fields become enabled, getPropsForFormSimple should be called again because the fieldDescriptor's enabled value won't change
            forceGetPropsForFormSimple: boolean = false;

            constructor(props: Readonly<PropsFrom<typeof sliceReportDefinitionEditor>>) {
                super(props);
                this.onRun = this.onRun.bind(this);
            }

            /**
            * Hide "columnConfig" button from the editor and show a message for 5 seconds after the "run" button has been clicked
            */
            renderHeader(params: CrudEditorPageRenderHeaderParams) {
                return <>{this.props.showRunMessage ? <Message success>{_msg("ReportDefinition.runMessage", this.props.entity?.name)}</Message> : null}
                    {super.renderHeader({ columnConfig: false })}
                </>
            }

            protected async onRun() {
                const entityFromForm = this.getEntityValuesFromForm();
                const error = await this.props.dispatchers.runReport(entityFromForm);
                if (error) {
                    return;
                }
                let disabledFields = this.resetReportForm(entityFromForm);
                this.props.dispatchers.setInReduxState({ showRunMessage: true, entity: entityFromForm, disabledFields });
                setTimeout(() => { this.props.dispatchers.setInReduxState({ showRunMessage: false }); }, 3000);
            }

            protected async onRevert() {
                await super.onRevert();
                const disabledFields = this.resetReportForm(null);
                this.props.dispatchers.setInReduxState({ disabledFields, selectedEmployee: undefined, selectedComponent: undefined });
            }

            protected resetReportForm(entityFromForm: any) {
                const disableComponentPicker = entityFromForm === null || !this.props.selectedEmployee ? true : false;
                const disableRealizationPicker = disableComponentPicker || !this.props.selectedComponent ? true : false;
                let disabledFields = { disableEmployeePicker: false, disableGroupPicker: false, disableTeamPicker: false, disableCategoryPicker: false, disableRealizationPicker, disableComponentPicker };
                if (entityFromForm) {
                    const personParameter = this.props.additionalFields.filter((item: AdditionalFields) => item.type === ProteusConstants.PERSOON)[0];
                    const groupParameter = this.props.additionalFields.filter((item: AdditionalFields) => item.type.slice(0, 12) === GROUP_PICKER)[0];
                    if (personParameter && entityFromForm[personParameter.completeName] !== null && entityFromForm[personParameter.completeName] !== undefined) {
                        disabledFields.disableGroupPicker = true;
                    } else if (groupParameter && entityFromForm[groupParameter.completeName] !== null && entityFromForm[groupParameter.completeName] !== undefined) {
                        disabledFields.disableEmployeePicker = true;
                    }
                }
                return disabledFields;
            }

            protected isClearableTeamAndCategory() {
                const propsForForm = this.getPropsForFormSimple();
                return propsForForm.entity.code === CONSTRAINT_GROUPS_REPORT;
            }

            /**
            * Set showRunMessage to false when going back to table
            * Otherwise, it will still keep the variable on true when going to another editor page from table in less than 5 seconds
            */
            protected onCancel() {
                this.props.dispatchers.setInReduxState({ showRunMessage: false });
                super.onCancel();
            }

            /**
            * Add a "run" button in place of "save" button
            */
            protected preRenderButtons(params: CrudEditorPageRenderHeaderParams): Array<OverrideableElement> {
                const buttons = super.preRenderButtons(params);
                for (let i = 0; i < buttons.length; i++) {
                    if ((buttons[i] as any)?.props?.key === "save") {
                        buttons[i] = { elementType: Button, props: { key: "run", content: _msg("general.run"), primary: true, onClick: this.onRun } };
                        break;
                    }
                }
                return buttons;
            }

            /**
            * Create custom editors by overriding Form's entityDescriptor and set the default values for the fields
            * The fildDescriptors are added in accordance with report's parameters
            */
            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const that = this;
                // this method should be called only once when the editor is opened, but actually is called multiple
                // times because of Utils.hideSpinner().
                // when the editor is opened we cache the value, then we re-use it
                if (that.propsForFormCache && !this.forceGetPropsForFormSimple) {
                    return that.propsForFormCache;
                }
                this.forceGetPropsForFormSimple = false;
                const parameters = this.props.additionalFields;
                const result = super.getPropsForFormSimple();
                if (parameters.length === 0) {
                    return result;
                }
                result.entityDescriptor = new EntityDescriptor({ name: "ReportDefinition" }, false);
                for (let i = 0; i < parameters.length; i++) {
                    if (parameters[i].prompting === true) {
                        if (parameters[i].type === DATE) {
                            if (!this.props.entity[parameters[i].completeName]) {
                                let value = new Date();
                                const defaultValue = parameters[i].defaultValue;
                                let dateString, year, month, day, hour, minute, second;
                                if (defaultValue && (dateString = defaultValue.trim()).length == 19 && !isNaN(year = parseInt(dateString.substring(0, 2)))
                                    && !isNaN(month = parseInt(dateString.substring(3, 5))) && !isNaN(day = parseInt(dateString.substring(6, 10)))
                                    && !isNaN(hour = parseInt(dateString.substring(11, 13)))
                                    && !isNaN(minute = parseInt(dateString.substring(14, 16)))
                                    && !isNaN(second = parseInt(dateString.substring(17, 19)))) {
                                    value = new Date(year, month, day, hour, minute, second);
                                } else if (defaultValue && defaultValue.toLowerCase() == "start_current_month") {
                                    value = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                                } else if (defaultValue && defaultValue.toLowerCase() == "end_current_month") {
                                    value = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
                                }
                                result.entity = { ...result.entity, [parameters[i].completeName]: value };
                            }
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { allowClear: false }) });
                        } else if (parameters[i].type === INTEGER) {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: FieldType.number });
                            if (!this.props.entity[parameters[i].completeName]) {
                                if (parameters[i].defaultValue === "current") {
                                    result.entity = { ...result.entity, [parameters[i].completeName]: new Date().getFullYear() };
                                } else if (parseInt(parameters[i].defaultValue)) {
                                    result.entity = { ...result.entity, [parameters[i].completeName]: parameters[i].defaultValue };
                                }
                            }
                        } else if (parameters[i].type === STRING) {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: FieldType.string });
                        } else if (parameters[i].type === BOOLEAN) {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: FieldType.boolean });
                            if (!this.props.entity[parameters[i].completeName]) {
                                result.entity = { ...result.entity, [parameters[i].completeName]: false };
                            }
                        } else if (parameters[i].type === "month") {
                            // we want to be able to see the dropdown over the header of the editor or other elements;
                            // w/o using css, we can use menuPortalTarget property from react-select to ensure that the dropdown is rendered
                            // outside the container with overflow, and it won't be clipped by any overflow styles
                            result.entityDescriptor.addFieldDescriptor({
                                name: parameters[i].completeName, type: FieldType.dropdown,
                                additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DropdownFieldEditor, { menuPortalTarget: document.body}),
                                fieldDescriptorSettings: { fieldIntervals: months }
                            });
                            if (!this.props.entity[parameters[i].completeName]) {
                                result.entity = { ...result.entity, [parameters[i].completeName]: new Date().getMonth() + 1 };
                            }
                        } else if (parameters[i].type === "month-year") {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: "MonthYearPicker" });
                            if (!this.props.entity[parameters[i].completeName]) {
                                const date = new Date();
                                // Set date to second day of the current month. We do this because when we want to get the previous date
                                // and the current date is the 31st of the month, then previous month might not have 31 days and 
                                // it will set again the date as the first day of the current month. 
                                date.setDate(2);
                                if (parameters[i].defaultValue === "previous") {
                                    if ((date.getMonth() - 1) < 0) {
                                        date.setMonth(date.getMonth() + 12);
                                        date.setFullYear(date.getFullYear() - 1);
                                    }
                                    date.setMonth(date.getMonth() - 1);
                                }
                                result.entity = { ...result.entity, [parameters[i].completeName]: (date.getMonth() + 1 + "-" + date.getFullYear()) };
                            }
                        } else if (parameters[i].type === ProteusConstants.PLOEG) {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: "Team", enabled: !this.props.disabledFields.disableTeamPicker }, new class extends FieldDescriptor {
                                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                                    const newProps = {
                                        ...props,
                                        isClearable: that.isClearableTeamAndCategory(),
                                        onChange: (team: any) => {
                                            if (result.entity.code === CONSTRAINT_GROUPS_REPORT) {
                                                const entity = { ...that.getEntityValuesFromForm(), teamId: team }
                                                const disableField = team || entity.categoryId ? true : false;
                                                that.props.dispatchers.setInReduxState({
                                                    entity: entity,
                                                    disabledFields: {
                                                        ...that.props.disabledFields,
                                                        disableEmployeePicker: disableField
                                                    }
                                                });
                                                that.forceGetPropsForFormSimple = that.props.disabledFields.disableEmployeePicker !== disableField;
                                            }
                                        }
                                    }
                                    return React.createElement(AssociationFieldEditor as any, newProps as FieldEditorProps);
                                }
                            }());
                            if (this.props.entity[parameters[i].completeName] === undefined && result.entity.code !== CONSTRAINT_GROUPS_REPORT) {
                                const defaultValue = that.props.defaultValues.filter((item) => item.type === ProteusConstants.PLOEG)[0];
                                result.entity = { ...result.entity, [parameters[i].completeName]: { id: defaultValue.id, description: defaultValue.description } };
                            }
                        } else if (parameters[i].type === ProteusConstants.CATEGORIE) {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: "Category", enabled: !this.props.disabledFields.disableCategoryPicker }, new class extends FieldDescriptor {
                                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                                    const newProps = {
                                        ...props,
                                        isClearable: that.isClearableTeamAndCategory(),
                                        onChange: (category: any) => {
                                            if (result.entity.code === CONSTRAINT_GROUPS_REPORT) {
                                                const entity = { ...that.getEntityValuesFromForm(), categoryId: category }
                                                const disableField = category || entity.teamId ? true : false;
                                                that.props.dispatchers.setInReduxState({
                                                    entity: entity,
                                                    disabledFields: {
                                                        ...that.props.disabledFields,
                                                        disableEmployeePicker: disableField
                                                    }
                                                });
                                                that.forceGetPropsForFormSimple = that.props.disabledFields.disableEmployeePicker !== disableField;
                                            }
                                        }
                                    }
                                    return React.createElement(AssociationFieldEditor as any, newProps as FieldEditorProps);
                                }
                            }());
                            if (this.props.entity[parameters[i].completeName] === undefined && result.entity.code !== CONSTRAINT_GROUPS_REPORT) {
                                const defaultValue = that.props.defaultValues.filter((item) => item.type === ProteusConstants.CATEGORIE)[0];
                                result.entity = { ...result.entity, [parameters[i].completeName]: { id: defaultValue.id, description: defaultValue.description } };
                            }
                        } else if (parameters[i].type === ROLE_PICKER) {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: "AssignmentRole" }, new class extends FieldDescriptor {
                                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                                    const newProps = { ...props, placeholder: _msg("ReportDefinition.all.label") }
                                    return React.createElement(EditorClass as any, newProps as FieldEditorProps);
                                }
                            }());
                        } else if (parameters[i].type === REGIME_PICKER) {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: "Regime" }, new class extends FieldDescriptor {
                                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                                    const newProps = { ...props, placeholder: _msg("ReportDefinition.all.label") }
                                    return React.createElement(EditorClass as any, newProps as FieldEditorProps);
                                }
                            }());
                        } else if (parameters[i].type === ProteusConstants.WORK_PERIOD_TYPE) {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: "RegistrationType" }, new class extends FieldDescriptor {
                                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                                    let context = result.entity.code === OCCUPATION_REPORT ? ProteusConstants.OCCUPATION : ProteusConstants.SYSTEM;
                                    const newProps = {
                                        ...props,
                                        isMulti: true,
                                        context
                                    }
                                    return React.createElement(WorkPeriodTypeAssociationFieldEditor as any, newProps as FieldEditorProps);
                                }
                            }());
                        } else if (parameters[i].type === ProteusConstants.REGISTRATION_TYPE) {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: "RegistrationType" }, new class extends FieldDescriptor {
                                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                                    const newProps = {
                                        ...props,
                                        placeholder: _msg("general.emptySelection"),
                                        isMulti: true
                                    }
                                    return React.createElement(RegistrationTypeWithPropertyAssociationFieldEditor as any, newProps as FieldEditorProps);
                                }
                            }());
                        } else if (parameters[i].type === ProteusConstants.PERSOON) {
                            const isEnabled = result.entity.code === CONSTRAINT_GROUPS_REPORT ? !result.entity.categoryId && !result.entity.teamId : !this.props.disabledFields.disableCategoryPicker;
                            if (parameters[i].securityRoleForEditingParameter === null || ProteusUtils.checkPermission(parameters[i].securityRoleForEditingParameter)) {
                                result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: "EmployeeSnapshot", enabled: isEnabled },
                                    new class extends FieldDescriptor {
                                        protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                                            const newProps = {
                                                ...props,
                                                onChange: (employee: Person) => {
                                                    if (result.entity.code === EVALUATION_RESULT) {
                                                        // because realization field must listen to employee, set in redux state the selected employee only for EVALUATION_RESULT screen
                                                        const entity = { ...that.getEntityValuesFromForm(), realization: null, catalog: null, employeeId: employee }
                                                        that.props.dispatchers.setInReduxState({
                                                            selectedEmployee: employee,
                                                            entity: entity,
                                                            selectedComponent: undefined,
                                                            disabledFields: {
                                                                ...that.props.disabledFields,
                                                                disableComponentPicker: !employee,
                                                                disableRealizationPicker: true
                                                            }
                                                        });
                                                        that.forceGetPropsForFormSimple = that.props.disabledFields.disableComponentPicker !== !employee;
                                                    }
                                                    if (result.entity.code === CONSTRAINT_GROUPS_REPORT) {
                                                        const disableField = employee ? true : false;
                                                        const entity = { ...that.getEntityValuesFromForm(), categoryId: null, teamId: null, user: employee }
                                                        that.props.dispatchers.setInReduxState({
                                                            selectedEmployee: employee,
                                                            entity: entity,
                                                            disabledFields: {
                                                                ...that.props.disabledFields,
                                                                disableCategoryPicker: disableField,
                                                                disableTeamPicker: disableField
                                                            }
                                                        });
                                                        that.forceGetPropsForFormSimple = true;
                                                    }
                                                    const groupParameter = that.props.additionalFields.filter((item: AdditionalFields) => item.type.slice(0, 12) === GROUP_PICKER)[0];
                                                    result.entityDescriptor.doForFields(null, (field: FieldDescriptor) => {
                                                        if (groupParameter && field.name === groupParameter.completeName) {
                                                            field.enabled = employee === null ? true : false;
                                                        }
                                                    })
                                                }
                                            }
                                            return React.createElement(ResourceSnapshotAssociationFieldEditor as any, newProps as FieldEditorProps);
                                        }
                                    });
                            } else {
                                result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: FieldType.string, enabled: false });
                                result.entity = { ...result.entity, [parameters[i].completeName]: ProteusUtils.getUserCompleteName() };
                            }
                        } else if (parameters[i].type === ProteusConstants.REALIZATION) {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: "Realization", enabled: !this.props.disabledFields.disableRealizationPicker }, new class extends FieldDescriptor {
                                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                                    const newProps = {
                                        ...props,
                                        additionalProps: {
                                            personId: that.props.selectedEmployee?.id,
                                            componentVersionId: that.props.selectedComponent?.id
                                        },
                                        queryLimit: -1,
                                    }
                                    return React.createElement(RealizationAssociationFieldEditor as any, newProps as FieldEditorProps);
                                }
                            }());
                        } else if (parameters[i].type === ProteusConstants.CATALOG) {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: "ComponentVersion", enabled: !this.props.disabledFields.disableComponentPicker }, new class extends FieldDescriptor {
                                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                                    const newProps = {
                                        ...props,
                                        additionalProps: { personId: that.props.selectedEmployee?.id },
                                        onChange: (componentVersion: ComponentVersion) => {
                                            const entity = { ...that.getEntityValuesFromForm(), realization: null, catalog: componentVersion };
                                            that.props.dispatchers.setInReduxState({
                                                selectedComponent: componentVersion,
                                                entity: entity,
                                                disabledFields: {
                                                    ...that.props.disabledFields,
                                                    disableRealizationPicker: !componentVersion
                                                }
                                            });
                                            that.forceGetPropsForFormSimple = that.props.disabledFields.disableRealizationPicker !== !componentVersion;
                                        }
                                    }
                                    return React.createElement(EvaluationsWithRealizationsAssociationFieldEditor as any, newProps as FieldEditorProps);
                                }
                            }());
                        } else if (parameters[i].type === ProteusConstants.BALANCE_TYPE_CODE) {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: "BalanceTypeForReport" }, new class extends FieldDescriptor {
                                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                                    const newProps = { ...props, placeholder: _msg("general.emptySelection") }
                                    return React.createElement(BalanceTypeWithPropertyAssociationFieldEditor as any, newProps as FieldEditorProps);
                                }
                            }());
                        } else if (parameters[i].type === COMPONENT_PICKER) {
                            result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: "Component" }, new class extends FieldDescriptor {
                                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                                    const newProps = { ...props, placeholder: _msg("general.emptySelection"), isClearable: false, isMulti: true };
                                    return React.createElement(ComponentVersionAssociationFieldEditor as any, newProps as FieldEditorProps);
                                }
                            }());
                        } else {
                            if (parameters[i].type.slice(0, 12) === GROUP_PICKER) {
                                if (parameters[i].securityRoleForEditingParameter === null || ProteusUtils.checkPermission(parameters[i].securityRoleForEditingParameter)) {
                                    const groupCode = parameters[i].type.substring(12);
                                    result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: "Group", enabled: !this.props.disabledFields.disableGroupPicker },
                                        new class extends FieldDescriptor {
                                            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                                                const newProps = {
                                                    ...props,
                                                    additionalProps: {
                                                        owner: ProteusUtils.getCurrentUser().id,
                                                        groupContextCode: groupCode
                                                    },
                                                    onChange: (data: any) => {
                                                        const personParameter = that.props.additionalFields.filter((item: AdditionalFields) => item.type === ProteusConstants.PERSOON)[0];
                                                        result.entityDescriptor.doForFields(null, (field: FieldDescriptor) => {
                                                            if (personParameter && field.name === personParameter.completeName) {
                                                                field.enabled = data === null ? true : false;
                                                            }
                                                        })
                                                    },
                                                    isClearable: (groupCode !== ProteusConstants.GROUP_CODE_EMPLOYEE_EXPLORER &&
                                                        groupCode !== ProteusConstants.GROUP_CODE_ASSIGNMENT_REPORT) ? false : true,
                                                    placeholder: _msg("general.selectGroup"),
                                                }
                                                return React.createElement(GroupSnapshotByContextAssociationFieldEditor as any, newProps as FieldEditorProps);
                                            }
                                        }());
                                    if (groupCode !== ProteusConstants.GROUP_CODE_EMPLOYEE_EXPLORER && groupCode !== ProteusConstants.GROUP_CODE_ASSIGNMENT_REPORT && groupCode !== ProteusConstants.EXPIRED_COMPONENTS_REPORT &&
                                        !this.props.entity[parameters[i].completeName]) {
                                        const defaultValue = that.props.defaultValues.filter((item) => item.type.slice(0, 12) === GROUP_PICKER)[0];
                                        result.entity = { ...result.entity, [parameters[i].completeName]: { id: defaultValue.id, code: defaultValue.code, name: defaultValue.name } };
                                    }
                                } else {
                                    result.entityDescriptor.addFieldDescriptor({ name: parameters[i].completeName, type: FieldType.string, enabled: false });
                                }
                            }
                        }
                        // After changes from #34446, column config is initialized earlier and columnsVisibleMap is no longer undefined at the moment the form is rendered. 
                        // Because of this, all the added fieldDescriptors have to stay in columnsVisibleMap
                        if (result.columnsVisibleMap) {
                            result.columnsVisibleMap[parameters[i].completeName] = true;
                        }
                    }
                }
                that.propsForFormCache = result;
                return result;
            }

            // because this is a "very customized" descriptor, going from "view property" to "edit property" does not work properly and
            // because the only field from "view property" is "name", this feature is not needed and will be disabled for this screen
            protected getExtraTabPanes(): (TabRouterPane | null)[] {
                return [];
            }

            // for this particular screen, the "View property" message is not suitable. The message has been overridden
            protected getMainMenuItemProps(): string | MenuItemProps {
                let result = super.getMainMenuItemProps();
                if (typeof result !== "string") {
                    result = { ...result, content: _msg("ReportDefinition.generateReport.label") };
                }
                return result;
            }

            async componentDidMount() {
                await this.props.dispatchers.getReportDefinition(this.props.match?.params.id);
                this.props.dispatchers.setInReduxState({
                    showRunMessage: false,
                    selectedEmployee: undefined,
                    selectedComponent: undefined,
                    disabledFields: {
                        disableEmployeePicker: false,
                        disableGroupPicker: false,
                        disableRealizationPicker: true,
                        disableComponentPicker: true,
                        disableTeamPicker: false,
                        disableCategoryPicker: false
                    }
                });
            }
        }
    }
}