'use strict';

/**
 * This controller is used in two different contexts: 
 * either for the catalogs master-detail view (selecting an item 
 * will display its details), or for the track/module composition
 * add form (selecting an item will add it to the track/module's 
 * composition).
 * 
 * This controller is responsible for populating the catalog groups.
 */
/*@ngInject*/
function CatalogsGroupsCtrl($scope, Education, Collection, Confirm, ModalSpinner, Flags, catalogsFilter, translateFilter, $filter, rx) {
	var catalogs = $scope.catalogs = $scope.catalogs || {};	
	catalogs.elementSubject = catalogs.elementSubject || new rx.Subject();
	var hideActions = $scope.hideActions;
	
	var catalogsCatalogs = {
		template: require('./catalog-form.html'),
		spinner: catalogs.typeahead ? '' : 'education.catalogs.loading',
		create: function() {
			return {
				name: '',
				description: '',
				environment: 'BRABO',
				active: false
			};
		},
		get: Education.getCatalogs,
		save: Education.saveCatalog,
		onSelect: function(catalog) {
			catalogs.catalog = catalog;
			catalogs.refreshGroups();
		},
		style: {
			"dropdown-menu-right": true
		}
	};
	if (catalogs.catalogs) {
		catalogs.catalogs = angular.extend(catalogs.catalogs, catalogsCatalogs);
	} else {
		catalogs.catalogs = catalogsCatalogs;
	}
	
	catalogs.refreshGroups = function() {
		angular.forEach(catalogs.groups, function(group) {
			delete group.data;
		});
		
		if (catalogs.group) {
			catalogs.load(catalogs.group);
		}
		catalogs.unselect();
	};

	catalogs.refreshElement = function(scope) {
		catalogs.group.get.call(Education, null, {
			params: {
				id: scope.element.id
			}
		}).then(function onRefreshElement(result) {
			var elements = catalogs.group.types ? catalogs.group.data[catalogs.group.type.id] : catalogs.group.data;
			if (result.data) {
				var element = result.data;
				Collection.create(elements).replace(element);
				catalogs.selectElt(element, null);
			} else {
				// the element does not exist anymore, probably the last version was just deleted
				Collection.create(elements).remove(scope.element);
				if (catalogs.selection && catalogs.selection.element.id === scope.element.id) {
					catalogs.unselect();
				}
			}
		});
	};

	catalogs.removeComponent = function(scope) {
		Confirm.open({
			title: 'clear',
			message: 'education.catalogs.component.remove.confirm'
		}).result.then(function() {
			ModalSpinner.show('education.catalogs.component.remove.progress');	
			if (scope.element.currentComponentVersion) {
				Education.removeComponent({}, {
					params: {
						id: scope.element.id
					}
				}).then(function(result) {
					catalogs.refreshElement(scope.$parent);
				}).finally(function() {
					ModalSpinner.hide();
				});
			} else if (scope.element.currentModuleVersion) {
				Education.removeModule({}, {
					params: {
						id: scope.element.id
					}
				}).then(function(result) {
					catalogs.refreshElement(scope.$parent);
				}).finally(function() {
					ModalSpinner.hide();
				});
			} else if (scope.element.currentTrackVersion) {
				Education.removeTrack({}, {
					params: {
						id: scope.element.id
					}
				}).then(function(result) {
					catalogs.refreshElement(scope.$parent);
				}).finally(function() {
					ModalSpinner.hide();
				});
			}

		});
	};

	catalogs.copyComponent = function($event, scope) {
		$event.stopPropagation();
		catalogs.addScope = scope;

		// copy element and remove unnecessary fields from the component and version
		var element = JSON.parse(JSON.stringify(scope.element));
		cleanElement(element);

		var currentVersion;
		if (element.currentComponentVersion) {
			currentVersion = element.currentComponentVersion;
			// useful for training component, for Competences tab
			element.currentComponentVersion.isCopy = true;
			element.currentComponentVersion.originalElementId = scope.element.currentComponentVersion.id;
		} else if (element.currentModuleVersion) {
			currentVersion = element.currentModuleVersion;
		} else if (element.currentTrackVersion) {
			currentVersion = element.currentTrackVersion;
		}
		cleanElement(currentVersion);
		// always set active to true
		currentVersion.active = true;

		// delete previous selected element and open the detail for the copy
		delete catalogs.selection;
		catalogs.selectElt(element, null);
		scope.masterDetail.goToDetail();
	};

	function cleanElement(element) {
		delete element.id;
		delete element.majorVersion;
		delete element.minorVersion;
		delete element.objectVersion;
		delete element.creationDate;
		delete element.creationUser;
		delete element.modificationDate;
		delete element.modificationUser;
		delete element.externalId;
		delete element.version;
		delete element.name;
		delete element.description;
	}

	catalogs.actions = {
		versions: {
			index: 10,
			icon: 'fa fa-trash fa-fw',
			run: function(scope) {
				Confirm.open({
					title: 'clear',
					message: 'education.catalogs.version.remove.confirm'
				}).result.then(function() {
					ModalSpinner.show('education.catalogs.version.remove.progress');
					
					let i = 0;
					let selectedVersion = scope.element.version;
					let versionId;
					for (i in scope.versions.data) {
						if (selectedVersion === scope.versions.data[i].version) {
							versionId = scope.versions.data[i].id;
							break;
						}
					}
					scope.group.versions.remove.call(Education, null, {
						params: {
							id: versionId
						}
					}).then(function(result) {
						catalogs.refreshElement(scope.$parent);
						showVersions(scope.$parent);
					}).finally(function() {
						ModalSpinner.hide();
					});
				});
			}
		},
		removeComponent: {
			template: require('./catalog-remove-component.html')
		}
	};
	
	function showVersions(scope) {
		ModalSpinner.show('education.catalogs.actions.versions.loading');
		// after a delete, don't clear the versions; this way we avoid flickering!
		var versions = scope.versions = scope.versions || {};
		scope.group.versions.get.call(Education, versions, {
			params: {
				id: scope.element.id
			}
		}).finally(function() {
			ModalSpinner.hide();
		});
	}

	function hideVersions(scope) {
		delete scope.versions;
	}

	catalogs.showHideVersions = function(scope) {
		if (scope) {
			scope.collapsed ? hideVersions(scope) : showVersions(scope);
		}
	};

	// multiple selection: e.g. from competences screen / select training components
	var active = 'catalogs.element.id === element.id && !catalogs.versionIsSelected';
	if (catalogs.multipleSelection) {
		catalogs.selectedComponents = [];
		active = 'catalogs.selectedComponents.indexOf(element) >= 0';
	}
	if (!catalogs.selectedComponentForDropdown) {
		catalogs.tree = {
			repeat: 'element in group.data track by element.id',
			label: '{{element | catalogs:"name"}}',
			detail: 'v{{element | catalogs:"version"}}',
			select: 'catalogs.select(this)',
			active: active,
			filter: 'catalogs.filter',
			expandable: !catalogs.editable ? 0 : 'catalogs.showHideVersions(this)',
			action: (hideActions || catalogs.ignoreActions) ? "" : catalogs.actions.removeComponent,
			children: !catalogs.editable ? [] : [
			{
				repeat: 'element in versions.data | orderBy:"element.majorVersion":true track by element.id',
				detail: 'v{{element.version}}',
				select: 'catalogs.select(this)',
				active: 'element.id === catalogs.elementSelectedVersion.id && catalogs.versionIsSelected',
				label: '{{element.name}}',
				action: (hideActions || catalogs.ignoreActions) ? "" : 'catalogs.actions.versions',
				filter: 'catalogs.filter'
			}]
		};
	} else  {
		catalogs.tree = {
			repeat: 'element in group.versions.data | orderBy:"element.majorVersion":true track by element.id',
			detail: 'v{{element.version}}',
			select: 'catalogs.select(this)',
			label: '{{element.name}}',
			filter: 'catalogs.filter',
			active: 'element.id === catalogs.elementSelectedVersion.id && catalogs.versionIsSelected'
		};
	}

	catalogs.trees = {
		tracksOrModules: angular.copy(catalogs.tree),
		components: catalogs.selectedComponentForDropdown ? catalogs.tree : angular.merge({}, catalogs.tree, {
			repeat: 'element in group.data[group.type.id] track by element.id'
		})
	};

	catalogs.groups = [
	{
		type: 'tracks',
		attachmentsType: 'TRACK',
		icon: 'fa fa-bolt fa-fw',
		getAll: Education.getTracks,
		get: Education.getTrack,
		versions: {
			get: Education.getTrackVersions,
			remove: Education.removeTrackVersion
		},
		create: function createTrack() {
			return {
				objectType: 'Track',
				currentTrackVersion: {
					name: '',
					description: '',
					active: true,
					attachments: [],
					children: []
					
				}
			};
		}
	},
	{
		type: 'modules',
		attachmentsType: 'MODULE',
		icon: 'fa fa-cogs fa-fw', 
		getAll: Education.getModules,
		get: Education.getModule,
		versions: {
			get: Education.getModuleVersions,
			remove: Education.removeModuleVersion
		},
		create: function createModule() {
			return {
				objectType: 'Module',
				currentModuleVersion: {
					name: '',
					description: '',
					active: true,
					attachments: [],
					componentVersions: []
				}
			};
		}
	}, 
	{
		type: 'components',
		attachmentsType: 'COMPONENT',
		icon: 'fa fa-cog fa-fw', 
		getAll: function(group, params) {
			// group components by type
			return Education.getComponents(null, params).then(function(result) {
				var data = {},
					types = [],
					visible = [],
					map = {};
				angular.forEach(result.data, function(component) {
					if (group.selectedComponentForDropdown && group.selectedComponentForDropdown.component.id != component.id) {
						return;
					}
					var type = component.type;
					if (!data[type.id]) {
						// new component type
						types.push(type);
						visible.push(type.code);
						map[type.code] = type;
						data[type.id] = [];
					}
					data[type.id].push(component);
				});
				group.data = data;
				group.types = types;
				group.flags = new Flags(visible);
				var type;
				angular.forEach(group.visible || visible, function(v) {
					if (catalogs.type) {
						if (catalogs.type == v) {
							type = map[v];
							group.flags.add(v);
						}
					} else {
						if (catalogs.excludeTypes && catalogs.excludeTypes.length > 0) {
							for (var i= 0; i < catalogs.excludeTypes.length; i++) {
								if (catalogs.excludeTypes[i] == v) {
									return;
								}
							}
						}
						 if (!type) {
							type = map[v];
						}
						group.flags.add(v);
					}
				});
				catalogs.loadType(type);
			});
		},
		get: Education.getComponent,
		versions: {
			get: Education.getComponentVersions,
			remove: Education.removeComponentVersion
		},
		components: true,
		create: function createComponent() {
			return {
				objectType: 'Component',
				type: this.type,
				currentComponentVersion: {
					name: '',
					description: '',
					active: true,
					attachments: [],
					objectType: (function(code) {
						switch (code) {
							case 'EVA' : return 'EvaluationComponentVersion';
							case 'OPL' : return 'EducationComponentVersion';
							default: return 'ComponentVersion';
						}
					})(this.type.code)
				}
			};
		},
		selectedComponentForDropdown: catalogs.selectedComponentForDropdown
	}];

	catalogs.flags = new Flags('TRACK', 'MODULE', 'COMPONENT');
	catalogs.visible = catalogs.visible || [];
	angular.forEach(catalogs.visible, function(visible) {
		if (angular.isString(visible)) {
			catalogs.flags.add(visible);
		} else {
			catalogs.flags.add(visible.type);
			angular.forEach(catalogs.groups, function(group) {
				if (group.type == visible.type) {
					group.visible = visible.visible;
				}
			});
		}
	});

	catalogs.initGroupTab = function(scope, group) {
		group.expandTabScope = scope;
		if (catalogs.visible.length == 1) {
			checkIfOnlyOneGroupIsVisibleAndExpandIt(group, 'COMPONENT', 'components') &&
			checkIfOnlyOneGroupIsVisibleAndExpandIt(group, 'MODULE', 'modules') &&
			checkIfOnlyOneGroupIsVisibleAndExpandIt(group, 'TRACK', 'tracks');
		}
	};

	function checkIfOnlyOneGroupIsVisibleAndExpandIt(group, visible, type) {
		return catalogs.flags.is(visible) && group.type == type && catalogs.openGroupByType(type);
	}

	/**
	 * Expands or collapses the tab.
	 * Type can be one of: tracks, modules, components
	 */
	catalogs.openGroupByType = function(type) {
		var group = $filter('filter')(catalogs.groups, {type: type}, true)[0];
		group.expandTabScope.isOpen = !group.expandTabScope.isOpen;
		if (group.expandTabScope.isOpen) {
			catalogs.load(group);
		}
		return true;
	};
	
	/**
	 * Expand handler for accordion. Load data for a group.
	 */
	catalogs.load = function(group) {
		// reset filters
		catalogs.typeahead || catalogs.filter.reset();

		catalogs.unselect();
		catalogs.group = group;
		var id = 'education.catalogs.new.' + group.type;
		var label = translateFilter(id);
		if (label == id) {
			label = '';
		}
		catalogs.newLabel = label;
		if (group.selectedComponentForDropdown) {
			var versions = group.versions = group.versions || {};
			if (!group.selectedComponentForDropdown.component) {
				Education.getComponentByComponentVersionId(null, {
					params: {
						componentVersionId: group.selectedComponentForDropdown.id
					}
				}).then(function(result) {
					group.versions.get.call(Education, versions, {
						params: {
							id: result.data.id
						}
					});
				});
			} else {
				group.versions.get.call(Education, versions, {
					params: {
						id: group.selectedComponentForDropdown.component.id
					}
				}).finally(function() {
					ModalSpinner.hide();
				});
			}
			return;
		}
		if (!group.data && catalogs.catalog) {
			var ms = catalogs.loadSpin.start('education.catalogs.loading.group');
			group.getAll.call(Education, group, {
				params: {
					id: catalogs.catalog.id
				}
			}).finally(function() {
				catalogs.loadSpin.end(ms);
			});
		}
	};

	catalogs.loadType = function(type) {
		// reset filters
		catalogs.typeahead || catalogs.filter.reset();
		
		catalogs.newLabel = translateFilter('education.catalogs.new.' + type.code);
		catalogs.unselect();
		catalogs.group.type = type;
	};

	catalogs.loadSpin = catalogs.loadSpin || {
		start: function(ms) {
			return ModalSpinner.show(ms);
		}, 
		end: function(ms) {
			ModalSpinner.hide(ms);
		}
	};
	
	var selectFn = catalogs.select;	
	/**
	 * Selection handler in accordion.
	 */
	catalogs.select = function(scope) {
		catalogs.selection = scope;
		catalogs.group = scope.group;
		// if an older version is selected,
		// the user should be allowed to change only basic fields: name, description etc.
		catalogs.olderVersionSelected = scope.element.objectType.indexOf('Version') != -1;
		catalogs.selectElt(scope.element, scope.version);
		
		selectFn && selectFn(scope);
	};
	
	catalogs.selectElt = function(element, version) {
		// use a copy of the element to discard form edits when changing selection
		if (catalogs.multipleSelection) {
			var index = catalogs.selectedComponents.indexOf(element);
			if (index >= 0) {
				catalogs.selectedComponents.splice(index, 1);
			} else {
				catalogs.selectedComponents[catalogs.selectedComponents.length] = element;
			}
		}

		catalogs.element = angular.copy(element);
		catalogs.elementCurrentVersion = catalogsFilter(catalogs.element);
		
		catalogs.elementSubject.onNext(catalogs.element);
		
		if (version) {
			if (version.id == catalogs.elementCurrentVersion.id) {
				catalogs.elementSelectedVersion = catalogs.elementCurrentVersion;
			} else {
				catalogs.elementSelectedVersion = version;
			}
			catalogs.versionIsSelected = true;
		} else {
			catalogs.elementSelectedVersion = catalogs.elementCurrentVersion;
			catalogs.versionIsSelected = false;
		}
		
		catalogs.editorIsDisabled = catalogs.elementSelectedVersion.id != catalogs.elementCurrentVersion.id; 
	};
	
	catalogs.filter = catalogs.filter || {};
	catalogs.filter.properties = ['name'];
	catalogs.filter.initLocals = true;
	catalogs.filter.placeholder = $filter('translate')('education.catalogs.search');
	catalogs.filter.elementFn = function(scope) {
		return catalogsFilter(scope.element);
	};
	
	var unselectFn = catalogs.unselect;

	catalogs.unselect = function() {
		// we "unselect" a component if is an existent component or if it is a
		// new component (add button clicked, not yet saved)
		if (!catalogs.selection && !catalogs.element) {
			return;
		}

		unselectFn && unselectFn();

		delete catalogs.addScope;
		delete catalogs.selection;
		delete catalogs.element;
		delete catalogs.elementCurrentVersion;
		delete catalogs.elementSelectedVersion;
		delete catalogs.composition;
	};

	catalogs.refreshGroups();
}

module.exports = CatalogsGroupsCtrl;