'use strict';

/*@ngInject*/
function GwtContainerCtrl(ModalSpinner, $scope, $rootScope, navActions) {

	ModalSpinner.hide();
	
	$('[ng-view]').hide();	// hide angular view
	$('.root1').show();		// show gwt view
	
	$rootScope.navActions = navActions;
	
	var t = window.setInterval(function() {
		if ($('.dispatchers-container .fp-table-body').length) {
			$('.dispatchers-container .fp-table-body')[0].addEventListener('touchmove', function(e) {
				e.stopPropagation();
			});
			window.clearInterval(t);
		}
	}, 1000);
	
}

module.exports = GwtContainerCtrl;