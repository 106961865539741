'use strict';

/*@ngInject*/
function hasPermissions(Principal) {
	
	return {
		restrict: 'A',
		link: function($scope, elt, attrs) {
			// we need to watch the hasPermissions attribute in case it is used in ngRepeat
			// because DOM elements are reused in ngRepeat when the collection elements change

			// NOTE: we cannot use an isolated scope to watch the change, because we want to change the element's scope
			// for the hasPermissions flag
			
			var scope = $scope.$new(true);
			scope.attrs = attrs;

			scope.$watch('attrs.hasPermissions', function() {
				Principal.checkPermissions(attrs.hasPermissions, function(result) {
					if ($scope.hasPermissions !== result) {
						$scope.hasPermissions = result;
						// event going up => let the parent know if it needs to hide
						$scope.$emit('permissionsChanged');
					}
				});
			});
		}
	}
};

module.exports = hasPermissions;