'use strict';

var split = require('split.js'), 
	css = require('./master-detail.css');

if (split.default) {
	// this is for react; split is the module and the default export is in the field "default";
	// I don't understand why we have the issue only here; I expected this to be a general behavior
	// of all "requires" in React
	split = split.default;
}

function proteusMasterDetail() {
	
	return {
		restrict: 'E',
		template: require('./master-detail.html'),
		transclude: {
			master: 'master',
			detail: 'detail'
		},
		scope: {
			minSize: '=?', // This value is used for the split.js call. Attention 450 is hardcoded as min-width in master-detail.css. It would be nice to use this value there too.  
			masterSizePercent: '=?',
			detailSizePercent: '=?',
			addMinWidthMdClass: '=?'
		}, 
		bindToController: true,
		controllerAs: 'masterDetail',
		controller: /*@ngInject*/ function($scope) {
			var masterDetail = this;

			masterDetail.goToDetail = function() {
				masterDetail.visible = 'detail';
			};

			masterDetail.goToMaster = function() {
				masterDetail.visible = 'master';
			};

			masterDetail.goToMaster();
			
			if (!masterDetail.minSize) {
				masterDetail.minSize = 200;
			}
			if (!masterDetail.masterSizePercent) {
				masterDetail.masterSizePercent = 33;
			}
			if (!masterDetail.detailSizePercent) {
				masterDetail.detailSizePercent = 67;
			}

			if (!masterDetail.addMinWidthMdClass) {
				masterDetail.addMinWidthMdClass = false;
			}
			
			// the isolated scope was added later. We publish the controller in parent scope for backwards compatibility
			$scope.$parent.masterDetail = masterDetail;
		},
		link: function($scope, elt, attrs) {
			$scope.masterDetail.noSelection = attrs.noSelection || 'noSelection'; // we could move no-selection into the scope
			
			$scope.$eval(attrs.onInit);

			var minSize = $scope.masterDetail.minSize;
			var sizes = [$scope.masterDetail.masterSizePercent, $scope.masterDetail.detailSizePercent];	

			split(['.master', '.detail'], {
				sizes: sizes,
				minSize: minSize,
				elementStyle: function (dimension, elementSize, gutterSize) {
					var style = 'calc(' + elementSize + '% - ' + gutterSize + 'px)';
					return { 
						'flex-basis': style,
						'-webkit-flex-basis': style,
						'-moz-flex-basis': style
					};
				},
				gutterStyle: function (dimension, gutterSize) {
					var style = gutterSize + 'px';
					return {
						'width': style,
						'flex-basis': style,
						'-webkit-flex-basis': style,
						'-moz-flex-basis': style
					};
			    }
			});
		}
	};
	
}

module.exports = proteusMasterDetail;