'use strict';

/*@ngInject*/
function TrainingCompetencesCtrl($scope, Education, Collection, FormModal) {
	var catalogs = $scope.catalogs;
	var trainingCompetences = catalogs.trainingCompetences = this;
	trainingCompetences.disableSave = $scope.catalogs.disableSave;
	$scope.catalogs.addedCompetences = [];
	$scope.catalogs.removedCompetences = [];

	$scope.$watch('catalogs.elementSelectedVersion', function(element) {
		trainingCompetences.visibleCollection = Collection.create([]);
		if (!element.id && !element.isCopy) {
			element.competenceVersions = [];
		}

		trainingCompetences.collection = Collection.create(element.competenceVersions ? element.competenceVersions : []);
		var map = {};
		for (var i in trainingCompetences.collection.data) {
			var competenceVersion = trainingCompetences.collection.data[i];
			delete competenceVersion.levelDefinitions;

			if (!map[competenceVersion.competence.id]) {
				map[competenceVersion.competence.id] = competenceVersion;
				trainingCompetences.visibleCollection.add(competenceVersion);
			}
		}

		if (element.id || element.isCopy) {
			var elementId = element.isCopy ? element.originalElementId : element.id;
			Education.getTrainingCompetences(null, {
				params: {
					trainingComponentVersion: elementId
				}
			}).then(function(result) {
				trainingCompetences.data = result.data;
				if (!result.data) {
					trainingCompetences.data = [];
				}
			});
		} else {
			trainingCompetences.data = [];
		}
	});

	trainingCompetences.actions = {
		icon: 'fa fa-trash fa-fw',
		run: removeCompetence 	
	};

	trainingCompetences.tree = {
		repeat: 'catalog in trainingCompetences.data track by catalog.id ',
		label: '{{catalog.name}}',
		expandable: true,
		children: [
			{
				repeat: 'category in catalog.categories track by category.id',
				label: '{{category.name}}',
				icon: "'fa fa-folder-open fa-fw'",
				expandable: true,
				children: [
					{
						repeat: 'competence in category.competences track by competence.id',
						label: '{{competence.currentCompetenceVersion.name}} <span class="text-muted">v{{competence.currentCompetenceVersion.version}}</span>',
						icon: "'fa fa-file fa-fw text-muted'",
						action: 'trainingCompetences.actions'
					},
					{
						repeat: 'category in category.categoryChildren track by category.id',
						label: '{{category.name}}',
						icon: "'fa fa-folder-open fa-fw'",
						recurse: 'training-competence-categories',
						expandable: true
					}
				]
			}
		]
	};

	function removeCompetence(scope) {
		// remove competence from tree
		var catalogsToRemove = [];
		for (var i in trainingCompetences.data) {
			var catalog = trainingCompetences.data[i];
			iterateTreeForRemove(catalog.categories, scope.competence);
			if (catalog.categories.length == 0) {
				catalogsToRemove[catalogsToRemove.length] = catalog;
			}
		}

		for (var i in catalogsToRemove) {
			var index = trainingCompetences.data.indexOf(catalogsToRemove[i]);
			if (index >= 0) {
				trainingCompetences.data.splice(index, 1);
			}
		}

		// remove competence from component's list
		var toRemove = [];
		for (var i in trainingCompetences.collection.data) {
			var competenceVersion = trainingCompetences.collection.data[i];
			if (competenceVersion.competence.id == scope.competence.id) {
				toRemove[toRemove.length] = competenceVersion;
			}
		}
		for (var i in toRemove) {
			trainingCompetences.collection.remove(toRemove[i]);
			trainingCompetences.visibleCollection.remove(toRemove[i]);
		}

		var addedIndex = $scope.catalogs.addedCompetences.indexOf(scope.competence.id);
		// search to see if is a new added component; remove from added list, remove from collection and visible list, but don't add to the removed list
		if (addedIndex >= 0) {
			$scope.catalogs.addedCompetences.splice(addedIndex, 1);
		} else {
			$scope.catalogs.removedCompetences[$scope.catalogs.removedCompetences.length] = scope.competence.id;
		}

		if ($scope.catalogs.addedCompetences.length == 0 && $scope.catalogs.removedCompetences.length == 0) {
			trainingCompetences.disableSave(true);
		} else {
			trainingCompetences.disableSave(false);
		}

		// create new major version
		$scope.catalogs.createNewMajorVersion = true;
	}

	function iterateTreeForRemove(categories, trainingCompetence) {
		var categoriesToRemove = [];
		for (var i in categories) {
			var category = categories[i];
			var competences = category.competences;
			var index = competences.indexOf(trainingCompetence);
			if (index >= 0) {
				competences.splice(index, 1);
			}

			iterateTreeForRemove(category.categoryChildren, trainingCompetence);
			if (competences.length == 0 && category.categoryChildren.length == 0) {
				// remove category;
				categoriesToRemove[categoriesToRemove.length] = category;
			}
		}

		for (var i in categoriesToRemove) {
			var index = categories.indexOf(categoriesToRemove[i]);
			if (index >= 0) {
				categories.splice(index, 1);
			}
		}
	}

	trainingCompetences.addCompetence = function(event) {
		if (trainingCompetences.isAddDisabled()) {
			return;
		}

		var competences = {
			multipleSelection: true
		};

		var modal = FormModal.open({
			windowClass: 'modal-full',
			scope: {
				template: require('../competences/competences-tree.html'),
				title: 'add',
				competences: competences
			},
			actions: ['ok', 'cancel', 'close'],
		});
		modal.result.then(function() {
			for (var i in competences.selectedCompetences) {
				var competence = competences.selectedCompetences[i];
				var index = trainingCompetences.collection.indexOf(competence, function(o1, o2) {
					if (o2.competence.id == o1.id) {
						return true;
					}
					return false;
				});

				if (index < 0) {
					// update tree
					trainingCompetences.createNewEntryInTree(competence);

					// update component's list
					var removedIndex = $scope.catalogs.removedCompetences.indexOf(competence.id);

					delete competence.currentCompetenceVersion.levelDefinitions;
					var currentComp = competence.currentCompetenceVersion;
					trainingCompetences.collection.add(currentComp);

					currentComp.competence = Object.assign({}, competence);
					delete currentComp.competence.category;
					delete currentComp.competence.currentCompetenceVersion;

					trainingCompetences.visibleCollection.add(currentComp);

					// search to see if the competence was just removed
					var removedIndex = $scope.catalogs.removedCompetences.indexOf(competence.id);
					if (removedIndex >= 0) {
						$scope.catalogs.removedCompetences.splice(removedIndex);
					} else {
						$scope.catalogs.addedCompetences[$scope.catalogs.addedCompetences.length] = competence.id;
					}

					if ($scope.catalogs.addedCompetences.length == 0 && $scope.catalogs.removedCompetences.length == 0) {
						// disable save button
						trainingCompetences.disableSave(true);
					} else {
						// enable save button
						trainingCompetences.disableSave(false);
					}

					// create new major version
					$scope.catalogs.createNewMajorVersion = true;
				}
			}
		}).catch(function(reason) {
			// the form window has been closed (cancel button)
		});
	}

	trainingCompetences.createNewEntryInTree = function(competence) {
		var catalog = competence.category.catalog;
		var treeCatalog;
		for (var i in trainingCompetences.data) {
			if (catalog.id == trainingCompetences.data[i].id) {
				treeCatalog = trainingCompetences.data[i];
			}
		}

		// add new catalog if the tree doesn't contain the catalog
		if (!treeCatalog) {
			treeCatalog = {
				id: catalog.id,
				name: catalog.name,
				categories: []
			}
			trainingCompetences.data[trainingCompetences.data.length] = treeCatalog;
		}

		competence.category.competence = competence;
		if (competence.category.parentCategory) {
			iterateTreeForCreate(competence.category, competence.category.parentCategory, treeCatalog.categories, competence);
		} else {
			addNewCategoryWithCompetence(competence.category, treeCatalog.categories, competence);
		}
	}

	trainingCompetences.isAddDisabled = function(competence) {
		return catalogs.olderVersionSelected;
	}

	function iterateTreeForCreate(previousCategory, category, treeCategories, competence) {
		if (!category && !previousCategory) {
			return treeCategories;
		}
		if (category && !category.parentCategory) {
			var treeCategory;
			for (var i in treeCategories) {
				if (treeCategories[i].id == category.id) {
					treeCategory = treeCategories[i];
				}
			}

			if (!treeCategory) {
				// new category
				treeCategory = {
					id: category.id,
					name: category.name,
					categoryChildren: [],
					competences: []
				}
				treeCategories[treeCategories.length] = treeCategory;
			}

			treeCategories = treeCategory.categoryChildren;
		}

		if (!category) {
			return treeCategories;
		}
		treeCategories = iterateTreeForCreate(category, category.parentCategory, treeCategories, competence);

		// go up
		var previousTreeCategory = addNewCategoryWithCompetence(previousCategory, treeCategories, competence);
		return previousTreeCategory.categoryChildren;	
	}

	function addNewCategoryWithCompetence(category, treeCategories, competence) {
		var treeCategory;
		for (var i in treeCategories) {
			if (category.id == treeCategories[i].id) {
				treeCategory = treeCategories[i];
			}
		}
		if (!treeCategory) {
			treeCategory = {
				id: category.id,
				name: category.name,
				categoryChildren: [],
				competences: []
			}
			treeCategories[treeCategories.length] = treeCategory;
		}

		if (category.competence == competence) {
			var competences = treeCategory.competences;
			var treeCompetence;
			for (var i in competences) {
				if (competences[i].id == competence.id) {
					treeCompetence = competences[i];
				}
			}
			if (!treeCompetence) {
				treeCompetence = competence;
				treeCategory.competences[treeCategory.competences.length] = treeCompetence;
			}
		}
		return treeCategory;
	}
}

module.exports = TrainingCompetencesCtrl;