import { apolloClient, EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react";
import { FieldEditorProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { HistoryTable, sliceHistoryTable } from "components/HistoryTable";
import { RegistrationTypeCategoryTree, RegistrationTypeCategoryTreeRaw } from "components/registrationTypeCategoryTree/RegistrationTypeCategoryTree";
import { REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPES } from "graphql/queries";
import _ from "lodash";
import moment from "moment";
import { CalendarDefinition, CalendarDefinitionEditor, sliceCalendarDefinition } from "pages/calendarDefinition/CalendarDefinitionEditor";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import React from "react";
import { Grid } from "semantic-ui-react";
import { MetadataProviderHelper } from "utils/MetadataProviderHelper";
import { constraintDefinitionDescriptor, constraintDefinitionHistoryDescriptor, ConstraintTabTableRenderer, CustomEditor, validForRegistrationTypeDescriptor, validOnRegistrationTypeDescriptor } from "./customFieldRenderersEditors";
import { HistoryItem } from "./GroupsManagement";

export const VALID_ON_REGISTRATION_TYPES = "validOnRegistrationTypes";
export const VALID_FOR_REGISTRATION_TYPES = "validForRegistrationTypes";
export const CALENDAR = "calendar";

export interface ConstraintDefinition {
    id: number,
    name: string,
    description: string,
    validForRegistrationTypes: string[],
    validOnRegistrationTypes: string[],
    history: ConstraintDefinitionHistory[]
}

export interface ConstraintDefinitionHistory extends HistoryItem {
    calendar: CalendarDefinition
}

export interface ConstraintDefinitionEditor {
    rowIndex: number,
    values: ConstraintDefinition
}

export interface RegistrationType {
    id: number,
    name: string,
    code: string
}

export interface RegistrationTypeTable {
    registrationType: string
}

export const sliceConstraintsTab = createSliceFoundation(class SliceConstraintsTab {

    initialState = {
        // work period types are actually registration types
        workPeriodTypes: [] as RegistrationType[],
        // registration types contains all registration types that are not work period types
        registrationTypes: [] as RegistrationType[],
        selectedConstraintDefinitionHistory: undefined as number | undefined,
        selectedConstraintIndex: undefined as number | undefined
    }

    nestedSlices = {
        constraintDefinitionTable: sliceEntityTableLight,
        // validForRegistrationTypes - are the types of registrations for which the constraint is valid, i.e. the registrations that are counted in counter. 
        // Ex: if validForRegistrationTypes=[VC, TK] and the group contains 3 employees, and in the same day the first employee has a registration of type VC, 
        // the second has a registration of type OUV and the third of type TK, then the counter will be 2 for that day, because only the TC and TK are counted. 
        // validForRegistrationTypes is valid only for the MAXIMUM property, the MINIMUM property uses registration that has BESCHIKBAAR = true in metadata for calculations
        validForRegistrationTypesTable: sliceEntityTableLight,
        // validOnRegistrationTypes - are the types of shifts for which the constraints are calculated. Ex: if we have an employee who has 2 types of shifts: DAG and NACHT 
        // and we add to validOnRegistrationTypes only the DAG shift, when we want to see the conters for that employee, we will see only the counters for DAG shift
        validOnRegistrationTypesTable: sliceEntityTableLight,
        constraintDefinitionHistoryTable: sliceHistoryTable,
        calendarDefinitionEditor: sliceCalendarDefinition
    }

    reducers = {
        ...getBaseReducers<SliceConstraintsTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceConstraintsTab>(this),

        async getRegistrationTypes() {
            if (this.getState().registrationTypes.length === 0) {
                let registrations = (await apolloClient.query({
                    query: REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPES
                })).data.registrationServiceFacadeBean_registrationTypes;
                // We have to separate work period types from the rest of registration types:
                // * work period types => validOnRegistrationTypes table
                // * registration types => validForRegistrationTypes table
                registrations = this.separateWorkingPeriods(registrations);
                this.getDispatchers().setInReduxState({ registrationTypes: registrations });
            }
        },

        separateWorkingPeriods(registrations: RegistrationType[]) {
            const workPeriodTypes: RegistrationType[] = [];
            const registrationTypes: RegistrationType[] = [];
            for (let i = 0; i < registrations.length; i++) {
                // Work period types can be distinguished from other registration types using LAYOUT_TYPE_CODE metadata property.
                // LAYOUT_TYPE_CODE should be WERK_PERIODE.
                const layoutTypeCode = MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!,
                    registrations[i].code, undefined, ProteusConstants.LAYOUT_TYPE_CODE, ProteusConstants.SYSTEM);
                if (layoutTypeCode?.toString() === ProteusConstants.WERK_PERIODE) {
                    workPeriodTypes.push(registrations[i]);
                    continue;
                }
                registrationTypes.push(registrations[i]);
            }
            this.getDispatchers().setInReduxState({ workPeriodTypes });
            return registrations;
        },

        convertFromCodeToName(codes: string[], allRegistrationTypes: RegistrationType[]) {
            let names: string[] = [];
            for (let i = 0; i < codes.length; i++) {
                names.push(allRegistrationTypes.filter((item: RegistrationType) => item.code === codes[i])[0].name);
            }
            return names;
        },

        convertFromNameToCode(names: string[], allRegistrationTypes: RegistrationType[]) {
            let codes: string[] = [];
            for (let i = 0; i < names.length; i++) {
                codes.push(allRegistrationTypes.filter((item: RegistrationType) => item.name === names[i])[0].code);
            }
            return codes;
        },

        adaptRegistrationTypesForTable(codes: string[], allRegistrationTypes: RegistrationType[]) {
            const registrationTypes = this.convertFromCodeToName(codes, allRegistrationTypes);
            let registrationTypesTable: RegistrationTypeTable[] = [];
            for (let i = 0; i < registrationTypes.length; i++) {
                registrationTypesTable.push({ registrationType: registrationTypes[i] });
            }
            return registrationTypesTable.sort((a: RegistrationTypeTable, b: RegistrationTypeTable) =>
                a.registrationType > b.registrationType ? 1 : -1);
        }
    }
})

type PropsNotFromState = {
    selectedGroup: any;
    addOrUpdateConstraintDefinition: (constraintDefinition: ConstraintDefinitionEditor) => void;
    deleteConstraintDefinition: (remainingConstraints: ConstraintDefinition[]) => void;
    addValidOnRegistrationType: (currentConstraintIndex: number, registrationType: RegistrationType) => void;
    deleteValidOnRegistrationType: (currentConstraintIndex: number, remainingRegistrationTypes: string[]) => void;
    addValidForRegistrationType: (currentConstraintIndex: number, registrationType: string[]) => void;
    deleteValidForRegistrationType: (currentConstraintIndex: number, remainingRegistrationTypes: string[]) => void;
    addOrUpdateConstraintDefinitionHistory: (currentConstraintIndex: number, constraintDefinitionHistory: ConstraintDefinitionHistory[], addedConstraint: ConstraintDefinitionHistory) => void;
    deleteConstraintDefinitionHistory: (currentConstraintIndex: number, remainingHistory: ConstraintDefinitionHistory[]) => void;
}

export class ConstraintsTab extends React.Component<PropsFrom<typeof sliceConstraintsTab> & PropsNotFromState> {
    registrationTypeCategoryTreeRef = React.createRef<RegistrationTypeCategoryTreeRaw>();
    calendarDefinitionEditorRef = React.createRef<CalendarDefinitionEditor>();
    constraintDefinitionHistoryTableRef = React.createRef<HistoryTable>();
    constraintDefinitionTableRef = React.createRef<EntityTableLight>();
    validForRegistrationTypesTableRef = React.createRef<EntityTableLight>();
    validOnRegistrationTypesTableRef = React.createRef<EntityTableLight>();

    setConstraintDefinitionTable(constraintDefinitions: ConstraintDefinition[]) {
        this.constraintDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(constraintDefinitions);
    }

    setValidOnRegistrationTypesTable(validOnRegistrationTypes: string[]) {
        this.validOnRegistrationTypesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(this.props.dispatchers.adaptRegistrationTypesForTable(validOnRegistrationTypes, this.props.workPeriodTypes));
    }

    setValidForRegistrationTypesTable(validForRegistrationTypes: string[]) {
        this.validForRegistrationTypesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(this.props.dispatchers.adaptRegistrationTypesForTable(validForRegistrationTypes, this.props.registrationTypes));
    }

    setConstraintDefinitionHistoryTable(constraintDefinitionHistory: ConstraintDefinitionHistory[]) {
        let constraintDefinitionHistoryTable = [];
        for (let i = 0; i < constraintDefinitionHistory.length; i++) {
            constraintDefinitionHistoryTable.push({ ...constraintDefinitionHistory[i], description: constraintDefinitionHistory[i].calendar.description });
        }
        this.constraintDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(constraintDefinitionHistoryTable.sort((a: ConstraintDefinitionHistory, b: ConstraintDefinitionHistory) =>
            moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf()));
    }

    getValidForEditorDescriptor() {
        const that = this;
        let descriptor = new EntityDescriptor({ name: "ValidForRegistrationTypes" })
            .addFieldDescriptor({ name: VALID_FOR_REGISTRATION_TYPES, type: FieldType.string }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    let newProps = { ...props, getCustomEditorContent: that.getRegistrationTypeTree };
                    return super.renderFieldEditorInternal(CustomEditor, newProps);
                }
            }())
        return descriptor;
    }

    getRegistrationTypeTree = () => {
        return (
            <div className="ConstraintTab_formTree">
                <RegistrationTypeCategoryTree id="RegistrationTypeCategoryTree" ref={this.registrationTypeCategoryTreeRef} hasStatuses={false}
                    initiallySelectedTypes={this.props.selectedGroup?.constraintDefinitions[this.constraintDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!]?.validForRegistrationTypes}
                    hasCheckboxes={true} filterWorkPeriodTypes={true}
                />
            </div>
        );
    }

    getSelectedConstraintHistoryIndexInGroup(selectedConstraintIndex: number, selectedHistoryIndexInTable: number) {
        if (selectedConstraintIndex === undefined || selectedHistoryIndexInTable === undefined) {
            return undefined;
        }
        // because in the table, history items are ordered by "validFrom" field, the right item index must be retrieved from the selected group object
        const selectedHistoryItemInTable = this.constraintDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[selectedHistoryIndexInTable];
        return this.props.selectedGroup.constraintDefinitions[selectedConstraintIndex].history.findIndex((item: any) =>
            item.validFrom === selectedHistoryItemInTable?.validFrom);
    }

    clearLastSelectedConstraintDefinition() {
        this.constraintDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
        this.constraintDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
        this.validForRegistrationTypesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
        this.validOnRegistrationTypesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
        this.constraintDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
        this.props.dispatchers.setInReduxState({ selectedConstraintIndex: undefined });
    }

    render() {
        const selectedConstraintIndex = this.props.selectedConstraintIndex!;
        const selectedConstraintHistoryIndexInTable = this.props.selectedConstraintDefinitionHistory!;
        const selectedConstraintHistoryIndexInGroup = this.getSelectedConstraintHistoryIndexInGroup(selectedConstraintIndex, selectedConstraintHistoryIndexInTable);
        fieldRenderers["RegistrationType"] = ConstraintTabTableRenderer;
        fieldRenderers["CalendarDefinition"] = ConstraintTabTableRenderer;
        return (
            <div className="GroupsManagement_flexGrow flex">
                <Grid>
                    <Grid.Row columns={3} className="GroupSnapshotTab_noPaddingTopRow">
                        <Grid.Column className="GroupSnapshotTab_contextPropertiesTables">
                            <EntityTableLight {...this.props.constraintDefinitionTable} dispatchers={this.props.dispatchers.constraintDefinitionTable} ref={this.constraintDefinitionTableRef}
                                entityDescriptor={constraintDefinitionDescriptor} fieldsToBeCopied={[VALID_FOR_REGISTRATION_TYPES, VALID_ON_REGISTRATION_TYPES]}
                                onSave={(constraints: ConstraintDefinition[], editedConstraint: ConstraintDefinitionEditor) => {
                                    this.props.addOrUpdateConstraintDefinition(editedConstraint);
                                    if (editedConstraint.rowIndex == -1) {
                                        this.clearLastSelectedConstraintDefinition();
                                    }
                                }}
                                onDelete={(remainingConstraints: ConstraintDefinition[]) => {
                                    this.props.deleteConstraintDefinition(remainingConstraints);
                                    this.clearLastSelectedConstraintDefinition();
                                }}
                                actions={{ showDeleteButton: false, doNotAddEntityToTable: true }}
                                formCustomizer={{ headerContent: _msg("ConstraintDefinition.label"), headerIcon: "file alternate outline" }}
                                onSelectItem={(item: any) => {
                                    this.onConstraintDefinitionSelectionChange(item);
                                    this.props.dispatchers.setInReduxState({ selectedConstraintIndex: item });
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column className="GroupSnapshotTab_contextPropertiesTables">
                            <EntityTableLight {...this.props.validForRegistrationTypesTable} dispatchers={this.props.dispatchers.validForRegistrationTypesTable} ref={this.validForRegistrationTypesTableRef}
                                entityDescriptor={validForRegistrationTypeDescriptor}
                                onSave={() => {
                                    this.registrationTypeCategoryTreeRef.current?.props.s.selectedRegistrationTypes &&
                                        this.props.addValidForRegistrationType(selectedConstraintIndex, this.registrationTypeCategoryTreeRef.current?.props.s.selectedRegistrationTypes);
                                }}
                                onDelete={(remainingRegistrationTypes: RegistrationTypeTable[]) => {
                                    const codes = this.props.dispatchers.convertFromNameToCode(remainingRegistrationTypes.map((item: RegistrationTypeTable) =>
                                        item.registrationType), this.props.registrationTypes);
                                    this.props.deleteValidForRegistrationType(selectedConstraintIndex, codes);
                                }}
                                formCustomizer={{
                                    headerContent: _msg("RegistrationType.label"), modalClassName: "ConstraintTab_modal", headerIcon: "file alternate outline",
                                    customEntityDescriptorForEditor: this.getValidForEditorDescriptor()
                                }}
                                actions={{ showDeleteButton: false, disableAddButton: selectedConstraintIndex === undefined, showEditButton: false, doNotAddEntityToTable: true }}
                            />
                        </Grid.Column>
                        <Grid.Column className="GroupSnapshotTab_contextPropertiesTables">
                            <EntityTableLight {...this.props.validOnRegistrationTypesTable} dispatchers={this.props.dispatchers.validOnRegistrationTypesTable} ref={this.validOnRegistrationTypesTableRef}
                                entityDescriptor={validOnRegistrationTypeDescriptor}
                                onSave={(registrationTypes: RegistrationTypeTable[], addedRegistrationTypes: { rowIndex: number, values: { registrationType: RegistrationType; }; }) => {
                                    this.props.addValidOnRegistrationType(selectedConstraintIndex, addedRegistrationTypes.values.registrationType);
                                }}
                                onDelete={(remainingRegistrationTypes: RegistrationTypeTable[]) => {
                                    const codes = this.props.dispatchers.convertFromNameToCode(remainingRegistrationTypes.map((item: RegistrationTypeTable) =>
                                        item.registrationType), this.props.workPeriodTypes);
                                    this.props.deleteValidOnRegistrationType(selectedConstraintIndex, codes);
                                }}
                                formCustomizer={{ headerContent: _msg("ConstraintDefinitionValidOn.workperiodType.label"), headerIcon: "file alternate outline" }}
                                actions={{ showDeleteButton: false, disableAddButton: selectedConstraintIndex === undefined, showEditButton: false, doNotAddEntityToTable: true }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3} className="GroupSnapshotTab_noPaddingBottomRow">
                        <Grid.Column className="GroupSnapshotTab_contextPropertiesTables">
                            <HistoryTable {...this.props.constraintDefinitionHistoryTable} dispatchers={this.props.dispatchers.constraintDefinitionHistoryTable} ref={this.constraintDefinitionHistoryTableRef}
                                entityDescriptor={constraintDefinitionHistoryDescriptor} fieldsToBeCopied={[CALENDAR]}
                                onSave={(constraintDefinitionHistory: ConstraintDefinitionHistory[], addedConstraint: ConstraintDefinitionHistory) => {
                                    this.props.addOrUpdateConstraintDefinitionHistory(selectedConstraintIndex, constraintDefinitionHistory, addedConstraint);
                                }}
                                onDelete={(remainingHistory: ConstraintDefinitionHistory[]) => {
                                    this.props.deleteConstraintDefinitionHistory(selectedConstraintIndex, remainingHistory);
                                    this.constraintDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                                    this.props.dispatchers.setInReduxState({ selectedConstraintDefinitionHistory: undefined })
                                }}
                                onAdd={sliceHistoryTable.reducers.onAdd}
                                actions={{ disableAddButton: selectedConstraintIndex === undefined, showDeleteButton: false }}
                                formCustomizer={{ headerContent: _msg("GroupResourcesHistoryItem.addForm.label"), headerIcon: "history" }}
                                onSelectItem={(itemId) => this.props.dispatchers.setInReduxState({ selectedConstraintDefinitionHistory: itemId })}
                            />
                        </Grid.Column>
                        <CalendarDefinitionEditor {...this.props.calendarDefinitionEditor} dispatchers={this.props.dispatchers.calendarDefinitionEditor} ref={this.calendarDefinitionEditorRef}
                            calendar={this.props.selectedGroup.constraintDefinitions[selectedConstraintIndex]?.history[selectedConstraintHistoryIndexInGroup]?.calendar}
                            disableAddButton={selectedConstraintHistoryIndexInTable === undefined}
                            defaultStartDateValue={this.props.selectedGroup.constraintDefinitions[selectedConstraintIndex]?.history[selectedConstraintHistoryIndexInGroup]?.validFrom} />
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    constraintDefinitionChanged(prevProps: PropsFrom<typeof sliceConstraintsTab> & PropsNotFromState) {
        const constraints = this.props.selectedGroup.constraintDefinitions;
        if (prevProps.selectedGroup.constraintDefinitions.length !== constraints.length) {
            return true;
        }
        for (let i = 0; i < constraints.length; i++) {
            if (constraints[i].name !== this.constraintDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[i]?.name ||
                constraints[i].description !== this.constraintDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[i]?.description) {
                return true;
            }
        }
        return false;
    }

    onConstraintDefinitionSelectionChange(id: any) {
        const selectedConstraint = this.props.selectedGroup.constraintDefinitions[id];
        if (id === undefined) {
            this.clearConstraintTabTables();
        } else {
            this.setValidOnRegistrationTypesTable(selectedConstraint.validOnRegistrationTypes);
            this.setValidForRegistrationTypesTable(selectedConstraint.validForRegistrationTypes);
            this.setConstraintDefinitionHistoryTable([...selectedConstraint.history])
        }
    }

    clearConstraintTabTables() {
        this.setValidOnRegistrationTypesTable([]);
        this.setValidForRegistrationTypesTable([]);
        this.setConstraintDefinitionHistoryTable([]);
        this.calendarDefinitionEditorRef.current?.setCalendarDefinitionExtendTable([]);
    }

    componentDidMount() {
        this.props.dispatchers.getRegistrationTypes();
        this.setConstraintDefinitionTable(this.props.selectedGroup.constraintDefinitions);
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceConstraintsTab> & PropsNotFromState) {
        if (this.constraintDefinitionChanged(prevProps)) {
            this.setConstraintDefinitionTable(this.props.selectedGroup.constraintDefinitions);
            this.constraintDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
        }

        const selectedConstraintIndex = this.constraintDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!;
        const selectedConstraint = this.props.selectedGroup.constraintDefinitions[selectedConstraintIndex];

        if (selectedConstraintIndex !== undefined && selectedConstraintIndex !== null) {
            // if a validOnRegistrationTypes has been added or removed, reset the data in tables
            if (selectedConstraint.validOnRegistrationTypes.length !== prevProps.selectedGroup.constraintDefinitions[selectedConstraintIndex].validOnRegistrationTypes.length) {
                this.setConstraintDefinitionTable(this.props.selectedGroup.constraintDefinitions);
                this.setValidOnRegistrationTypesTable(selectedConstraint.validOnRegistrationTypes);
            }

            // if a validForRegistrationTypes has been added or removed, reset the data in tables
            if (!_.isEqual(selectedConstraint?.validForRegistrationTypes, prevProps.selectedGroup.constraintDefinitions[selectedConstraintIndex]?.validForRegistrationTypes)) {
                this.setConstraintDefinitionTable(this.props.selectedGroup.constraintDefinitions);
                this.setValidForRegistrationTypesTable(selectedConstraint?.validForRegistrationTypes);
            }

            // if a costraintHistoryItem has been added, removed of edited, reset the data in table
            if (!_.isEqual(selectedConstraint?.history, prevProps.selectedGroup.constraintDefinitions[selectedConstraintIndex]?.history)) {
                this.setConstraintDefinitionTable(this.props.selectedGroup.constraintDefinitions);
                this.setConstraintDefinitionHistoryTable([...selectedConstraint.history]);
            }
        }
    }

    componentWillUnmount() {
        this.constraintDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
        this.constraintDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
        this.setConstraintDefinitionTable([]);
        this.clearConstraintTabTables();
        this.props.dispatchers.setInReduxState({ selectedConstraintIndex: undefined, selectedConstraintDefinitionHistory: undefined });
    }
}