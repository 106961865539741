import { createSliceFoundation, EntityDescriptor, FieldDescriptor, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { Component, Module, Track, TrackVersion } from "../Catalog";
import { Label, Menu, Tab } from "semantic-ui-react";
import { CatalogItemEditor, SliceCatalogItemEditor } from "./CatalogItemEditor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { CatalogTree, sliceCatalogTree } from "../CatalogTree";
import { childVersionEntityDescriptor } from "../customFieldRendererEditors";
import { CustomEditor } from "pages/groupsManagement/customFieldRenderersEditors";
import _ from "lodash";
import React from "react";

const CHILD_VERSION = "ChildVersion";
const CHILD = "child";

const sliceCatalogItemEditor = createSliceFoundation(SliceCatalogItemEditor, true);

export const sliceTrackItemEditor = createSliceFoundation(class SliceTrackItemEditor extends SliceCatalogItemEditor {
    initialState = {
        ...sliceCatalogItemEditor.initialState,
        track: {} as Track,
        // we need a default track, which is the one that the editor was initialized with to be able to check if the editor is "dirty"
        defaultTrack: {} as Track,
        selectedModuleToAdd: undefined as unknown as Module,
        selectedComponentToAdd: undefined as unknown as Component,
        children: [] as any
    };

    nestedSlices = {
        ...sliceCatalogItemEditor.nestedSlices,
        table: sliceEntityTableLight,
        catalogTree: sliceCatalogTree
    };

    reducers = {
        ...sliceCatalogItemEditor.reducers,
        ...getBaseReducers<SliceTrackItemEditor>(this),

        update(state: StateFrom<SliceTrackItemEditor>, p: { trackVersion: TrackVersion, dirty: boolean; }) {
            state.track.currentTrackVersion = p.trackVersion;
            state.dirty = p.dirty;
        }
    };

});

type Props = PropsFrom<typeof sliceTrackItemEditor>;

export class TrackItemEditor extends CatalogItemEditor<Props> {
    protected refEntityTableLight = React.createRef<EntityTableLight>();

    setTrack(track: Track) {
        this.props.dispatchers.setInReduxState({
            track,
            defaultTrack: track,
            dirty: false
        });
        this.setTable(track.currentTrackVersion.children);
        this.props.dispatchers.setCatalogItemVersion(track.currentTrackVersion);
    }

    setTable(children: any) {
        let childrenForTable = [];
        for (let i = 0; i < children.length; i++) {
            if (children[i].moduleVersion) {
                childrenForTable.push({
                    version: `v${children[i].moduleVersion.majorVersion}.${children[i].moduleVersion.minorVersion}`,
                    name: children[i].moduleVersion.name,
                    type: _msg("Catalog.moduleType.label")
                });
            } else if (children[i].componentVersion) {
                childrenForTable.push({
                    version: `v${children[i].componentVersion.majorVersion}.${children[i].componentVersion.minorVersion}`,
                    name: children[i].componentVersion.name,
                    type: _msg("Catalog.componentType.label"),
                    componentType: children[i].componentVersion.component.type.description
                });
            }
        }
        this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(childrenForTable.sort((a: any, b: any) => a.name > b.name ? 1 : -1));
    }

    getEditorForChildVersion() {
        const that = this;
        return new EntityDescriptor({ name: CHILD_VERSION })
            .addFieldDescriptor({ name: CHILD, type: FieldType.string }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    let newProps = { ...props, getCustomEditorContent: that.getCatalogTree };
                    return super.renderFieldEditorInternal(CustomEditor, newProps);
                }
            });
    }

    update(trackVersion: TrackVersion) {
        const dirty = this.isDirty(trackVersion);
        this.props.dispatchers.update({ trackVersion, dirty });
        super.update(trackVersion);
    }

    isDirty(trackVersion: TrackVersion) {
        return !_.isEqual(trackVersion, this.props.defaultTrack.currentTrackVersion) || this.props.createNewMajorVersion;
    }

    onTrackSelect = (track: Track) => {
    };

    onModuleSelect = (module: Module) => {
        this.props.dispatchers.setInReduxState({ selectedModuleToAdd: module, selectedComponentToAdd: undefined });
    };

    onComponentSelect = (component: Component) => {
        this.props.dispatchers.setInReduxState({ selectedComponentToAdd: component, selectedModuleToAdd: undefined });
    };

    getCatalogTree = () => {
        return (
            <div className="ConstraintTab_formTree">
                <CatalogTree {...this.props.catalogTree} dispatchers={this.props.dispatchers.catalogTree} onTrackSelect={this.onTrackSelect} onModuleSelect={this.onModuleSelect}
                    onComponentSelect={this.onComponentSelect} renderButtons={false} renderTracks={false} renderExpands={false} isLoadComponentsUsingGraphQL={true} refreshTreeOnOpen={true}
                />
            </div>
        );
    };

    getComponentsTab() {
        return {
            menuItem: (
                <Menu.Item key='components'>
                    {_msg("Catalog.compositionTab.label")}<Label>{this.props.track?.currentTrackVersion?.children.length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane className="flex flex-container flex-grow">
                <div className={`flex flex-grow flex-container Catalog ${this.props.isOldVersionSelected ? 'Catalog_disabled' : ''}`}>
                    <EntityTableLight {...this.props.table} dispatchers={this.props.dispatchers.table} entityDescriptor={childVersionEntityDescriptor} ref={this.refEntityTableLight}
                        actions={{ showAddButton: !this.props.isOldVersionSelected, showEditButton: false, doNotAddEntityToTable: true }}
                        onSave={() => {
                            if (this.props.track.currentTrackVersion.children.filter(child => {
                                return (child.componentVersion && child.componentVersion.name === this.props.selectedComponentToAdd?.currentComponentVersion.name) ||
                                    (child.moduleVersion && child.moduleVersion.name === this.props.selectedModuleToAdd?.currentModuleVersion.name);
                            }).length !== 0) {
                                return;
                            }
                            let childVersion = { componentVersion: this.props.selectedComponentToAdd?.currentComponentVersion, moduleVersion: this.props.selectedModuleToAdd?.currentModuleVersion };
                            let trackNew = _.cloneDeep(this.props.track);
                            trackNew.currentTrackVersion.children.push(childVersion);
                            this.props.dispatchers.setInReduxState({ track: trackNew });
                            this.setTable(trackNew.currentTrackVersion.children);
                            this.update(trackNew.currentTrackVersion);
                        }}
                        onDelete={(remainingEntities, removedEntityIndex) => {
                            let names: string[] = [];
                            remainingEntities.forEach((entity: any) => {
                                names.push(entity.name);
                            });
                            let trackNew = _.cloneDeep(this.props.track);

                            trackNew.currentTrackVersion.children = trackNew.currentTrackVersion.children.filter(
                                child => ((child.componentVersion && names.some(v => v.includes(child.componentVersion!.name!)))
                                    || (child.moduleVersion && names.some(v => v.includes(child.moduleVersion!.name!))))
                            );
                            this.props.dispatchers.setInReduxState({ track: trackNew });
                            this.setTable(trackNew.currentTrackVersion.children);
                            this.update(trackNew.currentTrackVersion);
                        }}
                        formCustomizer={{ headerIcon: "plus", headerContent: _msg("Catalog.add.label"), customEntityDescriptorForEditor: this.getEditorForChildVersion() }}
                        getConditionForShowingDelete={() => !this.props.isOldVersionSelected}
                    />
                </div>
            </Tab.Pane>,
        };
    }

    getPanes = () => {
        return [
            this.getGeneralTab(),
            this.getComponentsTab(),
            this.getAttachmentsTab()
        ];
    };

    componentDidMount() {
        if (this.props.track) {
            this.setTrack(this.props.track);
        }
    }

    componentDidUpdate() {
        if (this.refEntityTableLight.current && this.props.track.currentTrackVersion.children.length > 0 && this.refEntityTableLight.current.getEntityTableSimpleCustomizedRef().current?.getEntities().length === 0) {
            this.setTable(this.props.track.currentTrackVersion.children);
        }
    }
}

