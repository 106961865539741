import { Timeline } from '@crispico/react-timeline-10000';

export default class TimelineExt extends Timeline {

    constructor(props) {
        super(props);
    }

    renderMenuButton() {
        return null;
    }
}

