'use strict';

/*@ngInject*/
function proteusAttachments(Education, Upload, ModalSpinner) {
	
	return {
		restrict: 'E',
		scope: {
			attachments: '=',
			type: '@',
			limit: '@',
			onChangeCallback: '&'
		},
		template: require('./attachments.html'),
		controller: /*@ngInject*/ function($scope) {
			var endpoint;
			switch ($scope.type) {
			case 'CATALOG':
				endpoint = Education.getCatalogAttachment;
				break;
			case 'COMPETENCE': 
				endpoint = Education.getCompetenceAttachment;
				break;
			case 'EVALUATION':
				endpoint = Education.getEvaluationAttachment;
				break;
			case 'EDUCATION':
				endpoint = Education.getEducationCardAttachment; 
			default:
				break;
			}
			
			$scope.add = function(file) {
				if (!file) {
					// the add button is used multiple times
					// it will call this function with null before choosing another file
					return;
				}
				ModalSpinner.show();
				
				 				
				
				Upload.upload({
					url: '/proteus/attachment/upload?type=' + $scope.type,
					data: {
						file: file
					}
				}).then(function(result) {
					endpoint(null, {
						params: {
							id: result.data
						}
					}).then(function(result) {
						$scope.attachments.push(result.data);
					}).finally(function() {
						ModalSpinner.hide();
					})
					$scope.onChangeCallback();
				}, function(error) {
					// not on finally, because we still need to get the attachment
					ModalSpinner.hide();
				});
			}
			
			$scope.remove = function(file) {
				var found, idx = 0;
				angular.forEach($scope.attachments, function(f) {
					if (file === f) {
						found = idx;
					}
					idx++;
				});
				$scope.attachments.splice(found, 1);
				$scope.onChangeCallback();
			}
			
			$scope.$watch('attachments', function() {
				$scope.options = {
					data: $scope.attachments,
					columnDefs: 
					[
						{
							name: 'name',
							displayName: 'education.attachments.name',
							cellTemplateUrl: '/education/attachments/link.html'
						},
						{
							name: 'actions',
							width: '32px',
							headerTemplateUrl: '/education/attachments/add.html',
							cellTemplateUrl: '/education/attachments/remove.html'
						}
					]
				}
			});
		}
	}
}

module.exports = proteusAttachments;