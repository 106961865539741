'use strict';

module.exports = {
	name: 'proteus.planning',
	path: 'planning.module.js',
	authRoutes: {
		'/agenda': {
			templateUrl: 'planning/agenda-page.html',
			controller: 'AgendaPageCtrl',
			controllerAs: 'agenda',
			resolve: {
				useOtherAgendaContext: function() {
					return false;
				},
				onlyWaitingRegistrations: function() {
					return false;
				}
			}
		},
		'/other-agenda': {
			templateUrl: 'planning/agenda-page.html',
			controller: 'AgendaPageCtrl',
			controllerAs: 'agenda',
			resolve: {
				useOtherAgendaContext: function() {
					return true;
				},
				onlyWaitingRegistrations: function() {
					return false;
				}
			}
		},
		'/waiting-agenda': {
			templateUrl: 'planning/agenda-page.html',
			controller: 'AgendaPageCtrl',
			controllerAs: 'agenda',
			resolve: {
				useOtherAgendaContext: function() {
					return false;
				},
				onlyWaitingRegistrations: function() {
					return true;
				}
			}
		},
		'/planning': {
			template: '',
			controller: 'GwtContainerCtrl',
			resolve: {
				navActions: function() {
					return [];
				}
			}
		},
		'/occupancy': {
			template: '',
			controller: 'GwtContainerCtrl',
			resolve: {
				navActions: function() {
					return [];
				}
			}
		},
		'/hr-tasks': {
			template: '<balance-upload-page></balance-upload-page>'
		},
		'/free-time': {
			template: '',
			controller: 'GwtContainerCtrl',
			resolve: {
				navActions: function() {
					return [];
				}
			}
		},
		'/dispatchers': {
			template: '',
			controller: 'GwtContainerCtrl',
			resolve: {
				navActions: function() {
					return [{
						index: 10,
						id: 'filter-dispatchers-screen-btn',
						icon: 'fa-filter',
						run: function() {
							var navbar = document.getElementsByClassName('navbar-proteus')[0];
							navbar.dispatchEvent(new Event('filter-dispatchers-screen'));
						},
						template: '<div uib-dropdown dropdown-append-to-body="false" auto-close="outsideClick">' +
									  '<button uib-dropdown-toggle id="filter-dispatchers-screen-btn" class="btn btn-default btn-action" ng-click="action.run()">' +
									  	'<span class="fa fa-fw fa-filter"></span>' + 
									  '</button>' +
									  '<ul uib-dropdown-menu id="filter-dispatchers-screen-dropdown" class="dropdown-in-navbar dropdown-menu dropdown-menu-right"></ul>' + 
								  '</div>'
					}, {
						index: 20,
						id: 'refresh-dispatchers-screen-btn',
						icon: 'fa-user',
						run: function() {
							var navbar = document.getElementsByClassName('navbar-proteus')[0];
							navbar.dispatchEvent(new Event('refresh-dispatchers-screen'));
						},
						template: '<proteus-ui-refresh id="refresh-dispatchers-screen-btn" action="action"></proteus-ui-refresh>'
					}];
				}
			}
		},
		'/:entity/browse': {
			template: '',
			controller: 'GwtContainerCtrl',
			resolve: {
				navActions: function() {
					return [{
						index: 10,
						id: 'import-balances-screen-btn',
						icon: 'fa-upload',
						template: '<div uib-dropdown dropdown-append-to-body="false" auto-close="outsideClick">' +
									  '<button uib-dropdown-toggle id="upload-balance-screen-btn" class="btn btn-default btn-action" ng-click="action.run()">' +
									  	'<span class="fa fa-fw fa-upload"></span>' + 
									  '</button>' +
									  '<ul uib-dropdown-menu id="upload-balance-screen-dropdown" class="dropdown-in-navbar dropdown-menu dropdown-menu-right">'+
									  '	<balance-upload-page />' +
									  '</ul>' + 
								  '</div>'
					}];
				}
			}
		},
		'/:entity/edit/:id': {
			template: '',
			controller: 'GwtContainerCtrl',
			resolve: {
				navActions: function() {
					return [];
				}
			}
		},
		'/:entity/add': {
			template: '',
			controller: 'GwtContainerCtrl',
			resolve: {
				navActions: function() {
					return [];
				}
			}
		}
	}
};