import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { ConnectedPageInfo, createSliceFoundation, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import React from "react";
import { Button, Dimmer, Loader, Segment } from "semantic-ui-react";

export const sliceMeetRoger = createSliceFoundation(class SliceMeetRoger { })
export class MeetRoger extends TabbedPage<PropsFrom<typeof sliceMeetRoger>, { loading: boolean }> {

    constructor(props: PropsFrom<typeof sliceMeetRoger>) {
        super(props);
        this.state = { loading: false };
    }

    renderMain() {
        return (
            <>
                <Dimmer inverted active={this.state.loading}><Loader size='large'>{_msg("general.loading")}</Loader></Dimmer>
                <Segment className="MeetRoger">
                    <Button primary onClick={async () => {
                        this.setState({ loading: true });
                        const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
                        const url = Utils.adjustUrlToServerContext(additionalUrlParam + "attachment/download?type=MEET_ROGER");
                        await fetch(url!, { method: "GET" }).then((response) => response.blob())
                            .then((blob) => {
                                const url = window.URL.createObjectURL(new Blob([blob]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", "MeetRoger.xlsx");
                                document.body.appendChild(link);
                                link.click();
                                link.parentNode?.removeChild(link);
                                this.setState({ loading: false });
                            })
                    }}>{_msg("MeetRoger.generateFile.label")}
                    </Button>
                </Segment>
            </>
        );
    }
}

export const infoMeetRoger = new ConnectedPageInfo(sliceMeetRoger, MeetRoger, "MeetRoger");
infoMeetRoger.routeProps = { permission: "MEET_ROGER_VIEW" };