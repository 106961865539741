import { FieldEditor, FieldEditorNotUsableStandAloneProps } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { fieldEditors } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { getEmployeeWithDetailByDate_employeeService_employee_detail_scaleHistory } from "apollo-gen/getEmployeeWithDetailByDate";
import { HistoryItem } from "pages/groupsManagement/GroupsManagement";
import { Button, Form, Icon } from "semantic-ui-react";

export const SCALE_HISTORY_FIELD = "scaleHistoryItem";

fieldEditors[SCALE_HISTORY_FIELD] = class extends FieldEditor<any, FieldEditorNotUsableStandAloneProps> {

    protected static retrieveCurrentHistoryItem(historyItems: HistoryItem[], referenceDate?: Date) {
        const date = referenceDate ? new Date(referenceDate) : new Date();
        let currentItemIndex = -1;
        let currentItemValidUntil = Number.MAX_SAFE_INTEGER;
        for (let i = 0; i < historyItems.length; i++) {
            if ((!historyItems[i].validUntil && new Date(historyItems[i].validFrom!).getTime() <= date.getTime())
                || (historyItems[i].validUntil && new Date(historyItems[i].validFrom!).getTime() <= date.getTime() && new Date(historyItems[i].validUntil!).getTime() >= date.getTime())) {
                currentItemIndex = i;
                break;
            } else if (new Date(historyItems[i].validUntil!).getTime() > currentItemValidUntil) {
                currentItemValidUntil = new Date(historyItems[i].validUntil!).getTime();
                currentItemIndex = i;
            }
        }
        if (currentItemIndex !== -1) {
            return historyItems[currentItemIndex];
        } else {
            return undefined;
        }
    }

    protected getScaleHistoryDisplayedValue(currentHistoryValue: getEmployeeWithDetailByDate_employeeService_employee_detail_scaleHistory) {
        return _msg("ScaleHistoryItem.scalePercentage.label") + ": " + currentHistoryValue.scalePercentage +
            (currentHistoryValue.extraPercentage ? ", " + _msg("ScaleHistoryItem.extraPercentage.label") + ": " + currentHistoryValue.extraPercentage : "");
    }

    render = () => {
        let value = this.getValue();
        // for history fields, "value" is actually an array of history values. From this array, we must retrieve the most recent value to be displayed in the dropdown
        let currentHistoryValue = value ? fieldEditors[SCALE_HISTORY_FIELD].retrieveCurrentHistoryItem(value, this.props.fieldDescriptor.referenceDate) : null;
        let displayedValue = "";
        if (currentHistoryValue) {
            displayedValue = this.getScaleHistoryDisplayedValue(currentHistoryValue);
        }

        return (
            <Form.Field className="EmployeeEditor_historyFields">
                <Form.Input value={displayedValue} readOnly className={this.props.fieldDescriptor.isDisabled ? "disabled" : ""} />
                <Button icon type="button" onClick={() => this.props.fieldDescriptor.onOpenTableLight(this.props.fieldDescriptor, value)}><Icon name="edit" color="blue" /></Button>
            </Form.Field>
        );
    }
}