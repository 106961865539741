'use strict';

var css = require('./filter.css');

/**
 * A filter implementation that delegates to the angular filter.
 * 
 * @see https://docs.angularjs.org/api/ng/filter/filter
 * 
 * The implementation works in the following way:
 * 1) allows scopes for each tree row to be registered
 * 2) uses elementFn to get the actual data displayed in the row
 * 3) when it applies the filtering, updates the filter_hide flag in the scope of the row (to indicate if a row must be hidden)
 * 4) it is the responsibility of the client widget (i.e. the tree) to use filter_hide flag to show/hide rows as appropriate
 * 
 *  The reason for which it creates filter_hide flag instead of calling a function each time is performance-wise: during digest the function could be called multiple times
 *  (because digest loop uses dirty-checking and any modification of watched data would trigger again the digest loop; if the function to get visibility is expensive it would degrade performance
 *  because would be called on each digest loop even if the filter value would not be changed) 
 *  
 *  <div proteus-filter="proteusFilter" x-autofocus="true"></div>
 *  The proteusFilter object can look like this:
 *  {
 *		properties: ['name'],
 *		elementFn: function(scope) {
 *			// return the data for this row
 *		}
 *	}
 * Also the tree must be configured with a reference to the proteusFilter so it can register the scopes.
 */
 /*@ngInject*/
function proteusFilter(Filter) {
	
	return {
		restrict: 'A',
		template: require('./filter.html'),
		scope: {
			filter: '=proteusFilter',
			property: '@',
			type: '@',
			labels: '=',
			autofocus: '='
		},
		controller: /*@ngInject*/ function($scope) {
			Filter.filter($scope.filter);
			
			if ($scope.filter && $scope.filter.initLocals) {
				$scope.locals = {};			
			}
			$scope.property = $scope.property || 'name';

			$scope.onBooleanChange = function() {
				// in case the user clicks twice on one checkbox, and not on the other, then false != undefined
				// so we use !! to convert to boolean values 
				var truthy = !!$scope.locals.truthy, falsy = !!$scope.locals.falsy;
				if (truthy == falsy) {
					// both checked or un-checked => notify the filter service that this property does not matter
					$scope.filter.onChange($scope.property);
				} else {
					// only one is checked => notify the filter to match elements with this property value
					$scope.filter.onChange($scope.property, truthy);
				}
			};

			var reset = $scope.filter.reset;
			$scope.filter.reset = function() {
				reset.call($scope.filter);
				$scope.locals = {};
			};
		}
	};
	
}

module.exports = proteusFilter;