'use strict';

module.exports = {
	
	'Properties': {
		'ROUNDING_TYPE_CODE': 'ROUNDING_TYPE_CODE',
		'DEFAULT_STATUS_CODE': 'DEFAULT_STATUS_CODE'
	},	
	
	'MetadataContexts': {
		'SYSTEM': 'SYSTEM'
	},
		
	'CONTEXT': 'AGENDA',
	'OTHER_CONTEXT': 'OTHER_AGENDA',
	'ENVIRONMENT': 'BRABO',
	'ERROR_POLICY_ENFORCE_ALL': 'ENFORCE_ALL',
	'VERLOFKALENDER': 'VERLOFKALENDER',
	
	// Registration type codes
	
	'CODE_VC': 'VC',
	'CODE_TVL': 'TVL',
	'CODE_WISSEL': 'WISSEL',
	'CODE_BEURTWISSEL': 'BEURTWISSEL',
	'CODE_TK': 'TK',
	'CODE_OUV': 'OUV',
	'CODE_GEEN_VRIJETIJD': 'GEEN_VRIJETIJD',

	// Calculation

	'INITIAL_CALCULATION_PREFIX': 'AANTAL_',

	// Metadata properties
	
	'LAYOUT_TYPE_CODE': 'LAYOUT_TYPE_CODE',
	'BACKGROUND_COLOR': 'BACKGROUND_COLOR',
	'TRANSPARENCY_PERCENT': 'TRANSPARENCY_PERCENT',
	'CREATE_ROLE': 'CREATE_ROLE',
	'READ_ROLE': 'READ_ROLE',
	'UPDATE_ROLE': 'UPDATE_ROLE',
	'DELETE_ROLE': 'DELETE_ROLE',
	'ALLOW_MAP_TO_WORKPERIOD': 'ALLOW_MAP_TO_WORKPERIOD',
	'MAP_TO_WORKPERIOD': 'MAP_TO_WORKPERIOD',
	'CONSTRAINED': 'CONSTRAINED',
	'MILESTONE' : 'MILESTONE',
	'HIDE_REGISTRATION_DETAIL' : 'HIDE_REGISTRATION_DETAIL',
	
	// Layout type codes
	
	'LAYOUT_TYPE_VERLOF': 'VERLOF',
	'LAYOUT_TYPE_WISSEL': 'WISSEL',
	'LAYOUT_TYPE_BEURTWISSEL': 'BEURTWISSEL',
	'LAYOUT_TYPE_TIJDSKREDIET': 'TIJDSKREDIET',
	'LAYOUT_TYPE_GEEN_VRIJETIJD': '',
	'LAYOUT_TYPE_MEDICAL_CHECKUP': 'MEDISCH_ONDERZOEK',
	'LAYOUT_TYPE_FLEXIBLE_AVAILABILITY': 'FLEX_BESCHIKBAARHEID',
	
	// Registration detail object types
	
	'DETAIL_CONSTRAINT_GROUP': 'ConstraintGroupDetail',
	'DETAIL_LEAVE': 'LeaveDetail',
	'DETAIL_EXCHANGE': 'ExchangeDetail',
	'DETAIL_SEQUENCE_EXCHANGE': 'SequenceExchangeDetail',
	'DETAIL_MEDICAL_CHECKUP': 'MedicalCheckupDetail',
	'DETAIL_TRAINING': 'TrainingDetail',
	'DETAIL_INSTRUCTOR': 'InstructorDetail',

	// Half day settings
	
	'HALF_DAY_FULL': 'FULL_DAY',
	'HALF_DAY_AM': 'HALF_DAY_AM',
	'HALF_DAY_PM': 'HALF_DAY_PM',

	// Security roles
	'WAITING_AGENDA': 'WAITING_AGENDA_VIEW',

	// Rounding types
	'ROUNDING_TYPE_WP': 'WP',
	'ROUNDING_TYPE_DAG': 'DAG',
	
	//Hide details
	'HIDE_DETAIL_CODE': 'AFWEZIG',

	// Flexible availability rounding types
	'FLEXIBLE_EARLY': 'EARLY',
	'FLEXIBLE_LATE': 'LATE',
	'FLEXIBLE_ALL_DAY': 'ALL_DAY',

	prefix: function(code) {
		switch (code) {
			case 'HALF_DAY_AM':
			return '(VM) ';
			case 'HALF_DAY_PM':
			return '(NM) ';
		}
		return '';
	},
	
	indexOfRegistration: function(registration, list) {
		for (var i = 0; i < list.length; i++) {
			var id = list[i].registration.id;
			if (registration.id === id) {
				return i;
			}
		}
		return -1;
	},

	'MODE_MONTH': new function() {
		
		this.id = 'month';
		
		/**
         * Get the start date for a registration, rounded to the current day, i.e. the same day at 00:00.
         */
		this.getStartDate = function(date) {
			date = new Date(date);
			// convert to Date
			return new Date(date.getFullYear(),date.getMonth(),date.getDate());
		}
		
		/**
         * Get the end date for a registration; rounded to the current day, i.e. the same day at 23:59.
         */
		this.getEndDate = function(date) {
			date = new Date(date);
			// convert to Date
			return new Date(date.getFullYear(),date.getMonth(),date.getDate(),23,59);
		}
		
		/**
         * Get the first date, rounded to the current month, i.e. the first day of the current month.
         */
		this.getFirstDate = function(date) {
			date = new Date(date);
			// convert to Date
			date.setDate(1);
			date.setHours(0, 0, 0, 0);
			return date;
		}
		
		/**
         * Get the last date, rounded to the current month, i.e. the last day of the current month.
         */
		this.getLastDate = function(date) {
			date = new Date(date);
			// convert to Date
			
			var DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
			
			function getDaysInMonth(year, month) {
				return ((month === 1) && (year % 4 === 0) && ((year % 100 !== 0) || (year % 400 === 0))) ? 29 : DAYS_IN_MONTH[month];
			}
			
			date.setDate(getDaysInMonth(date.getFullYear(), date.getMonth()));
			date.setHours(23, 59, 0, 0);
			return date;
		}
		
		this.getIndex = function(date, registrations) {
			date = new Date(date);
			// convert to Date

			if (registrations != undefined && registrations != null) {
				for (let i = 0; i < registrations.length; i++) {
					if (new Date(registrations[i].start) <= date && new Date(registrations[i].end) >= date) {
						return i;
					}
				}
			}
			return date.getDate() - 1;
		}
		
		/**
         * Registrations for the full month.
         */
		this.getEntries = function(registrations) {
			for (var i = 0; i < registrations.length; i++) {
				var agendaRegistrations = registrations[i].agendaRegistrations;
				for (var j = agendaRegistrations.length - 1; j >= 0; j--) {
					var registration = agendaRegistrations[j].registration;
					var startIdx = this.getIndex(registration.fromDate, registrations);
					var endIdx = this.getIndex(registration.toDate, registrations);
					var l = endIdx - startIdx;
					if (l > 0 && i && i == endIdx) {
						// last day => check if continuation of previous work period
						if (i == this.getIndex(this.getWorkPeriodEnd(registrations[i-1], registrations[i], registration), registrations)) {
							// remove this registration
							agendaRegistrations.splice(j, 1);
						}
					}
				}
			}
			return registrations;
		}

		this.getWorkPeriodEnd = function(entry, today, registration) {
			if (entry.workRosterType) {
				return entry.end;
			}
			if (!today.workRosterType && registration.type.code === 'WISSEL' && registration.detail) {
				if (registration.detail.applicant) {
					return registration.detail.compensation.toDate;
				} else {
					return registration.detail.exchange.toDate;
				}
			}
			return NaN;
		}
	
	},
	
	'MODE_DAY': new function() {
		
		this.id = 'day';
		
		/**
         * Get the start date for a registration; in this case simply return the date.
         */
		this.getStartDate = function(date) {
			return new Date(date);
			// convert to Date
		}
		
		/**
         * Get the end date for a registration; in this case simply return the date.
         */
		this.getEndDate = function(date) {
			return new Date(date);
			// convert to date
		}
		
		/**
         * Get the first date, rounded to the current day, i.e. the same day at 00:00.
         */
		this.getFirstDate = function(date) {
			date = new Date(date);
			// convert to Date
			date.setHours(0, 0, 0, 0);
			return date;
		}
		
		/**
         * Get the last date, rounded to the current day, i.e. the same day at 23:59.
         */
		this.getLastDate = function(date) {
			date = new Date(date);
			// convert to Date
			date.setHours(23, 59, 0, 0);
			return date;
		}
		
		this.getIndex = function(date) {
			date = new Date(date);
			// convert to Date
			var index = 2 * date.getHours();
			var minutes = date.getMinutes();
			if (minutes <= 29) {
				return index;
			} else {
				return index + 1;
			}
		}
		
		/**
         * Create half-hour intervals for the day, and hightlight each interval with the corresponding registration.
         */
		this.getEntries = function(registrations, date) {
			if (registrations.length == 48) {
				return registrations;
			}
			var registration = registrations[0];
			
			// CAlLBACK: create entry if there is a registration for the start-end interval
			function createEntry(start, end) {
				var entry = {
					start: start,
					end: end,
					agendaRegistrations: []
				}
				if (registration.start <= entry.start && entry.end <= registration.end) {
					entry.workRosterType = registration.workRosterType;
					entry.constraint = registration.constraint;
					entry.backgroundColor = registration.backgroundColor;
					entry.transparencyPercent = registration.transparencyPercent;
				}
				angular.forEach(registration.agendaRegistrations, function(agendaRegistration) {
					if (agendaRegistration.registration.fromDate <= entry.start && entry.end <= agendaRegistration.registration.toDate) {
						entry.agendaRegistrations.push(angular.copy(agendaRegistration));
					}
				});
				return entry;
			}
			
			// create half-hour intervals for the day
			var entries = [];
			for (var i = 0; i < 24; i++) {
				
				// first half
				
				var start = new Date(date);
				start.setHours(i, 0, 0, 0);
				// XX:00
				var end = new Date(date);
				end.setHours(i, 29, 0, 0);
				// XX:29
				
				entries.push(createEntry(start.getTime(), end.getTime()));
				
				// second half
				
				start = new Date(date);
				start.setHours(i, 30, 0, 0);
				// XX:30
				var end = new Date(date);
				end.setHours(i, 59, 0, 0);
				// XX:59
				
				entries.push(createEntry(start.getTime(), end.getTime()));
			}
			
			return entries;
		}
	
	},

		'MODE_OPEN_COMPARTMENTS': new function() {

		this.id = 'open_compartments';

		/**
         * Get the start date for a registration, rounded to the current day, i.e. the same day at 00:00.
         */
		this.getStartDate = function(date) {
			date = new Date(date);
			// convert to Date
			return new Date(date.getFullYear(),date.getMonth(),date.getDate());
		}

		/**
         * Get the end date for a registration; rounded to the current day, i.e. the same day at 23:59.
         */
		this.getEndDate = function(date) {
			date = new Date(date);
			// convert to Date
			return new Date(date.getFullYear(),date.getMonth(),date.getDate(),23,59);
		}

		/**
         * Get the first date, rounded to the current month, i.e. the first day of the current month.
         */
		this.getFirstDate = function(date) {
			date = new Date(date);
			// convert to Date
			date.setDate(1);
			date.setHours(0, 0, 0, 0);
			return date;
		}

		/**
         * Get the last date, rounded to the current month, i.e. the last day of the current month.
         */
		this.getLastDate = function(date) {
			date = new Date(date);
			// convert to Date

			var DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

			function getDaysInMonth(year, month) {
				return ((month === 1) && (year % 4 === 0) && ((year % 100 !== 0) || (year % 400 === 0))) ? 29 : DAYS_IN_MONTH[month];
			}

			date.setDate(getDaysInMonth(date.getFullYear(), date.getMonth()));
			date.setHours(23, 59, 0, 0);
			return date;
		}

		this.getIndex = function(date) {
			date = new Date(date);
			// convert to Date
			return date.getDate() - 1;
		}

	},
};