'use strict';

var css = require('./group-registrations.css');

/*@ngInject*/
function GroupRegistrationsCtrl($scope, Flags, Education, ModalSpinner, Confirm, $rootScope, Curriculum, ErrorModal, $filter) {
	var groupRegistrations = this;
	ModalSpinner.hide();
	groupRegistrations.flags = new Flags('edit', 'add');

	// wizard custom actions: save as effective
	var that = groupRegistrations;
	groupRegistrations.actions = [
		{
			step: 2,
			onClick: function() {
				that.timePeriods.saveSession(true);
			},
			icons: ["fa-save", "fa-check-circle save-effective-icon"],
			title: $filter('translate')('education.groupRegistrations.saveAsEffective'),
			invalid: true
		}
	];

	groupRegistrations.setSaveAsEffectiveInvalid = function(invalid) {
		groupRegistrations.actions[0].invalid = invalid;
	}

	groupRegistrations.invalidWizardForSave = true;
	groupRegistrations.onChange = function() {
		groupRegistrations.invalidWizardForSave = false;
	}

	// get realization statuses
	Curriculum.getRealizationResults({}, {}).then(function(result) {
		groupRegistrations.realizationStatuses = result.data;
		// add null status
		groupRegistrations.realizationStatuses.splice( 0, 0, {code: null, description: "(Geen)"} );
	});

	// get training session statuses (default - simulatie)
	Education.getTrainingSessionStatuses().then(function(result) {
		groupRegistrations.trainingSessionStatuses = result;
	});

	groupRegistrations.refresh = function(selectNew) {
		var lastSelectedSession = groupRegistrations.session;
		delete groupRegistrations.data;
		delete groupRegistrations.session;

		groupRegistrations.masterDetail && groupRegistrations.masterDetail.goToMaster();
		if (groupRegistrations.skipSpinner) {
			delete groupRegistrations.skipSpinner;
		} else {
			ModalSpinner.show();
		}

		// load sessions - filter by owner and archived status
		Education.getTrainingSessions(groupRegistrations, {
			data: {
				owner: groupRegistrations.filters.owner ? groupRegistrations.filters.owner.id : null,
				archived: groupRegistrations.filters.archived
			}
		}).then(function() {
			if (groupRegistrations.initiallySelectedId) {
				// select the last selected session
				angular.forEach(groupRegistrations.data, function(session) {
					if (session.id == groupRegistrations.initiallySelectedId) {
						var sessionToSelect = selectNew ? session : lastSelectedSession;
						sessionToSelect.selectOrCreateNewSession = lastSelectedSession.selectOrCreateNewSession;
						groupRegistrations.selectSession(sessionToSelect);
					}
				});
			} else {
				if (groupRegistrations.newSession && groupRegistrations.flags.is('add')) {
					// new session (not saved yet): add the session to the
					// beginning of the list, then select it 
					groupRegistrations.data.unshift(groupRegistrations.newSession);
					groupRegistrations.selectSession(groupRegistrations.data[0]);
					delete groupRegistrations.newSession;
					groupRegistrations.masterDetail.goToDetail();
				}
			}

			// if the selection is lost, return to first page from wizard and go to master
			if (!groupRegistrations.session) {
				if ($scope.wizard.selected != 1) {
					groupRegistrations.skipSpinner = true;
					ModalSpinner.hide();
					$scope.wizard.goToIndex(1);
					groupRegistrations.masterDetail.goToMaster();
				}
			}
		}).finally(function() {
			ModalSpinner.hide();
		});
	};

	// initialize filters
	groupRegistrations.filters = {
		prevOwner: $rootScope.securityContext.resource,
		owner: $rootScope.securityContext.resource
	};

	groupRegistrations.filterSessionsByName = function(session) {
		return !groupRegistrations.filterSessions || session.name.toLowerCase().includes(groupRegistrations.filterSessions.toLowerCase())
	}

	groupRegistrations.refresh();

	groupRegistrations.backToFirstStep = function() {
		if (groupRegistrations.session) {
			if (groupRegistrations.session.id) {
				groupRegistrations.selectSession(groupRegistrations.session);
			} else {
				// new session (not yet saved): we will use this value on refresh
				groupRegistrations.newSession = groupRegistrations.session;
			}
		}
		groupRegistrations.refresh();
	};

	groupRegistrations.onSelectOwner = function(resource) {
		if (resource) {
			if (!groupRegistrations.filters.prevOwner || resource.id != groupRegistrations.filters.prevOwner.id) {
				groupRegistrations.filters.prevOwner = resource;
			}
		} else {
			groupRegistrations.filters.prevOwner = null;
		}
		groupRegistrations.refresh();
	};

	groupRegistrations.addSession = function(session) {
		groupRegistrations.flags.add('add');
		session = session || {
			environment: 'BRABO',
			name: $filter('translate')('education.groupRegistrations.new'),
			owner: $rootScope.securityContext.resource,
			ownerSnapshot: $rootScope.securityContext.resource,
			status: groupRegistrations.trainingSessionStatuses.data['SIM']
		};
		session.selectOrCreateNewSession = true;
		groupRegistrations.data.unshift(session);
		groupRegistrations.selectSession(session);
		groupRegistrations.invalidWizardForSave = false;
	};

	groupRegistrations.selectSession = function(session) {
		groupRegistrations.flags.add('edit');
		groupRegistrations.session = session;
		if (groupRegistrations.initiallySelectedId != session.id) {
			groupRegistrations.invalidWizardForSave = true;
		}

		groupRegistrations.initiallySelectedId = session.id;
		groupRegistrations.session.visible = ['COMPONENT'];

		groupRegistrations.session.archiveDate = groupRegistrations.session.archiveDate ? new Date(groupRegistrations.session.archiveDate) : groupRegistrations.session.archiveDate;
		groupRegistrations.session.fromDate = groupRegistrations.session.fromDate ? new Date(groupRegistrations.session.fromDate) : null;
		groupRegistrations.session.toDate = groupRegistrations.session.toDate ? new Date(groupRegistrations.session.toDate) : null;
		if (groupRegistrations.session.owner && !groupRegistrations.session.owner.objectType && groupRegistrations.session.ownerSnapshot) {
			groupRegistrations.session.owner = groupRegistrations.session.ownerSnapshot;
		}
		groupRegistrations.masterDetail.goToDetail();
	};

	groupRegistrations.selectSessionOnClick = function(session) {
		if (groupRegistrations.flags.is('add')) {
			// remove the new session (not saved yet)
			groupRegistrations.flags.remove('add');
			groupRegistrations.refresh();
		}
		session.selectOrCreateNewSession = true;
		if (groupRegistrations.session && ((groupRegistrations.session.ownerSnapshot || session.ownerSnapshot) && (!groupRegistrations.session.ownerSnapshot || !session.ownerSnapshot || groupRegistrations.session.ownerSnapshot.id != session.ownerSnapshot.id))) {
			session.ownerIsChanged = true;
		}
		groupRegistrations.selectSession(session);
	}

	groupRegistrations.unselectSession = function(session) {
		if (!groupRegistrations.session) {
			// nothing to unselect
			return;
		}
		if (groupRegistrations.session.id == session.id) {
			groupRegistrations.flags.remove('edit');
			delete groupRegistrations.initiallySelectedId;
			groupRegistrations.session = null;
		}
	};
 
 	groupRegistrations.onSave = function() {
		if (groupRegistrations.flags.is('add')) {
			groupRegistrations.flags.remove('add');
		}
	}

 	groupRegistrations.doRemoveSession = function(session) {
		groupRegistrations.unselectSession(session);
		if (!session.id) {
			// new session => just remove it from list
			groupRegistrations.flags.remove('add');
			groupRegistrations.data.shift();
		} else {
			Education.removeTrainingSession({}, {
				params: {
					id: session.id
				}
			}).then(function() {
				groupRegistrations.refresh();
			});
		}
		groupRegistrations.masterDetail.goToMaster();
	};

 	groupRegistrations.removeSession = function(session) {
 		if (!session.id) {
			groupRegistrations.doRemoveSession(session);
		} else {
			var message;
			if (session.status.code == 'SIM') {
				message = 'education.groupRegistrations.remove.simulation.message';
			} else {
				message = 'education.groupRegistrations.remove.effective.message';
			}

			Confirm.open({
				title: 'education.groupRegistrations.remove.title',
				message: message
			}).result.then(function() {
				groupRegistrations.doRemoveSession(session);
			});
		}
 	}

 	groupRegistrations.archiveSession = function(session) {
 		var message;
		if (session.status.code == 'SIM') {
			ErrorModal.open({
				titleId: ErrorModal.ERROR,
				message:  $filter('translate')('education.groupRegistrations.archive.simulation.message')
			});
		} else {
			Confirm.open({
				title: 'education.groupRegistrations.archive.title',
				message: 'education.groupRegistrations.archive.effective.message'
			}).result.then(function() {
				if (session.status.code == 'SIM') {
					return;
				}
				Education.archiveTrainingSession(null, {
					params: {
						id: session.id
					}
				}).then(function() {
					groupRegistrations.refresh(true);
				});
				groupRegistrations.masterDetail.goToMaster();
			});
		}
	}

 	groupRegistrations.changeStatus = function() {
 		groupRegistrations.session.newStatus = groupRegistrations.timePeriods.session.newStatus = 'DEF';
 	}

	groupRegistrations.isValid = function(groupRegistrationsScope) {
		// check first panel form
		if (groupRegistrationsScope.wizard.selected == 1) {
			var isFormValid = document.form.checkValidity();
			groupRegistrationsScope.wizard.invalid = !isFormValid;
			groupRegistrationsScope.wizard.invalidErrorMessage = "Vul alstublieft alle verplichte velden.";
		}
	}

	groupRegistrations.onResourceChange = function() {
		if (groupRegistrations.session && groupRegistrations.session.ownerIsChanged) {
			delete groupRegistrations.session.ownerIsChanged;
			return;
		}
		groupRegistrations.onChange();
	}
}

module.exports = GroupRegistrationsCtrl;