'use strict';

/*@ngInject*/
function InboxItemPageCtrl($scope, $routeParams, $location, InboxItems, ModalSpinner, Confirm) {
	  
	var ctrl = this;
	
	$scope.date = new Date();

	$scope.refresh = function() {
		ModalSpinner.show();
		InboxItems.get(ctrl, {
			params: { id: $routeParams.id }
		}).then(angular.noop, function(error) {
			$location.path('/inboxItems');
		}).finally(function() {
			ModalSpinner.hide();
		});
	};
	
	$scope.process = function(item, noConfirm) {
		ModalSpinner.show();
		InboxItems.process(ctrl, {
			data: {
				activityInstanceId: ctrl.data.id,
				processInstanceId: ctrl.data.processInstance,
				person: ctrl.data.person,
				parameters: [{ key: item.name, value: item.defaultValue }]
			}
		}).then(function() {
			$location.path('/inboxItems');
		}, function(result) {
			var errorCode = result.data.errorCode;
			var message = result.data.message;
			var actions = ['close'];
			if (errorCode.search("WORKFLOW-5") > -1) {
				message = 'inboxItems.removeIfError';
				actions = ['yes', 'no'];
			}
			if (!noConfirm) {
				Confirm.open({
					icon: 'fa fa-exclamation-circle',
					panelCls: 'panel-danger',
					title: 'error',
					message: message,
					actions: actions
				}).result.then(function() {
					// try again with value null to remove the item completely
					$scope.process({ name: item.name, value: null }, true);
				});
			}
		}).finally(function() {
			ModalSpinner.hide();
		});
	};

	$scope.refresh();

}

module.exports = InboxItemPageCtrl;