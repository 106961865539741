'use strict';

/**
 * @ngdoc service
 * @name proteus.filter:replaceLineBreaks
 * @description # replaceLineBreaks
 * 
 * Filter of the application.
 */
function replaceLineBreaks() {

	return function(str) {
		if (str) {
			str = str.replace(/(?:\r\n|\r|\n)/g, '<p/>');
		}
		return str;
	}
};

module.exports = replaceLineBreaks;