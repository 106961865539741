'use strict';

/*@ngInject*/
function CompositionCtrl($scope, Collection, FormModal) {
	let catalogs = $scope.catalogs;
	var composition = $scope.catalogs.composition = this;
	composition.disableSave = $scope.catalogs.disableSave;
	
	$scope.$watch('catalogs.elementSelectedVersion', function(element) {
		if (!element) {
			composition.collection = Collection.create([]);
		} else {
			composition.collection = Collection.create(element.children || element.componentVersions);
		}
		
		composition.options.data = composition.collection.data;
	});
	
	composition.options = {
		columnDefs: [
			{
				name: 'name',
				displayName: 'education.catalogs.composition.name',
				labelFn: function(child) {
					if (child.name) {
						return child.name;
					} else if (angular.isObject(child.componentVersion)) {
						return child.componentVersion.name;
					} else if (angular.isObject(child.moduleVersion)) {
						return child.moduleVersion.name;
					}
				}, 
				detailFn: function(child) {
					var version = child.version;
					if (angular.isObject(child.componentVersion)) {
						version = child.componentVersion.version;
					} else if (angular.isObject(child.moduleVersion)) {
						version = child.moduleVersion.version;
					}
					return version ? 'v' + version : '';
				}
			},
			{
				name: 'sort',
				width: '100px',
				displayName: 'education.catalogs.composition.sort',
				labelFn: function(child) {
					if (child.component || child.componentVersion) {
						return 'education.catalogs.composition.sort.component';
					}
					return 'education.catalogs.composition.sort.module';
				}
			},
			{
				name: 'type',
				width: '100px',
				displayName: 'education.catalogs.composition.type',
				labelFn: function(child) {
					if (angular.isObject(child.component)) {
						return child.component.type.description;
					} else if (angular.isObject(child.componentVersion)) {
						var component = child.componentVersion.component;
						if (angular.isObject(component)) {
							return component.type.description;
						}
					}
				}
			},
			{
				name: 'actions',
				width: '32px',
				headerTemplateUrl: '/catalogs/composition.add.html',
				cellTemplateUrl: '/catalogs/composition.remove.html'
			}
		]
	};

	composition.isDisabled = function() {
		return catalogs.olderVersionSelected;
	}

	composition.add = function(c) {
		if (composition.isDisabled()) {
			return;
		}

		// open popup
		var catalogs = {
			// pass selected catalog
			catalog: $scope.catalogs.catalog,
			visible: ($scope.catalogs.group.type == 'modules' ? ['COMPONENT'] : ['MODULE', 'COMPONENT'])
		};
		var modal = FormModal.open({
			windowClass: 'modal-full',
			scope: {
				template: require('./catalogs-groups.html'),
				title: 'add',
				catalogs: catalogs,
				hideActions: true
			},
			actions: ['ok', 'cancel', 'close']
		});
		modal.result.then(function() {
			var child = null;
			if (catalogs.group.type == 'modules') {
				child = {
					moduleVersion: catalogs.element.currentModuleVersion
				};
				for (var i in child.moduleVersion.componentVersions) {
					var componentVersion = child.moduleVersion.componentVersions[i];
					for (var j in componentVersion.competenceVersions) {
						delete componentVersion.competenceVersions[j].levelDefinitions;
					}
				}
			} else {
				if ($scope.catalogs.group.type == 'modules') {
					child = catalogs.element.currentComponentVersion;
					child.component = catalogs.element;
					delete child.component.currentComponentVersion;

					for (var i in child.competenceVersions) {
						delete child.competenceVersions[i].levelDefinitions;
					}
				} else {
					child = {
						componentVersion: catalogs.element.currentComponentVersion
					};
					child.componentVersion.component = catalogs.element;
					for (var i in child.componentVersion.competenceVersions) {
						delete child.componentVersion.competenceVersions[i].levelDefinitions;
					}
					delete child.componentVersion.component.currentComponentVersion;
				}
			}
			composition.collection.add(child);
			composition.disableSave(false);

			$scope.catalogs.createNewMajorVersion = true;
		});
	};
	
	composition.remove = function(child) {
		if (composition.isDisabled()) {
			return;
		}
		composition.collection.remove(child);
		composition.disableSave(false);
		$scope.catalogs.createNewMajorVersion = true;
	};
}
	
module.exports = CompositionCtrl;