'use strict';

/*@ngInject*/
function agendaGridRegistration(AgendaConstants) {
	
	return {
		restrict: 'A',
		link: function($scope, elt, attrs) {
			
			var contextButton = elt.find('.context-button');
			var registrationCode = elt.find('.registration-code');
			var waitingRegistrationIcon = elt.find('.is-waiting-registration');
			
			var agenda = $scope.grid.appScope.agenda;
			
			function registrationChanged() {
				// use the `$parent` scope associated to the grid row,
				// so the other columns will also have access to the agendaRegistration and graphic properties
				var agendaRegistration = $scope.row.entity[$scope.col.colDef.name];
				
				// show/hide the button when the registration changes
				var showContextBtn, showRegistrationCode, first, notSaved = false;
				
				if (agendaRegistration) {
					if (agendaRegistration.first) {
						// first day
						showContextBtn = true;
						showRegistrationCode = true;
						first = true;
						if (agendaRegistration.placeholder) {
							notSaved = true;
						}
					}
					
					// show contextButton is the registration overlaps month boundary
					var rowDate = new Date($scope.row.entity.start);
					if (agendaRegistration.registration.fromDate < agenda.date && rowDate.getDay() == 1) {
						showContextBtn = true;
						showRegistrationCode = true;
					}
					
					// hide context button if the registration type does not have at least one of CREATE, UPDATE or READ roles
					if(!agendaRegistration.metadata[AgendaConstants.CREATE_ROLE] && !agendaRegistration.metadata[AgendaConstants.UPDATE_ROLE] && 
						!agendaRegistration.metadata[AgendaConstants.READ_ROLE]) {
						showContextBtn = false;
					}

					if (!agenda.editable) {
						showContextBtn = false;
					}
				}
				
				showContextBtn ? contextButton.show() : contextButton.hide();

				if (agendaRegistration != undefined && agendaRegistration != null && agendaRegistration.isWaitingRegistration === true) {
					waitingRegistrationIcon.show();
				} else {
					if (waitingRegistrationIcon != undefined && waitingRegistrationIcon != null && waitingRegistrationIcon.length != 0) {
						waitingRegistrationIcon.hide();
					}
				}

				if (showRegistrationCode) {
					var name = agendaRegistration.metadata[AgendaConstants.HIDE_REGISTRATION_DETAIL] ? AgendaConstants.HIDE_DETAIL_CODE : agendaRegistration.registration.type.name;
					registrationCode.text(AgendaConstants.prefix(agendaRegistration.prefix) + name);
					if (showContextBtn) {
						contextButton.find('.context-button-content').append(registrationCode);
					} else {
						elt.append(registrationCode);
					}
					registrationCode.show();
				} else {
					registrationCode.text('');
					registrationCode.hide();
				}

				first ? elt.parent().addClass('first') : elt.parent().removeClass('first');
				notSaved ? elt.addClass('not-saved') : elt.removeClass('not-saved');
			}
			
			// $watch() if SOME properties of the registration change
			// we could use $watchCollection(), but we don't need to know about all registration properties
			var name = 'row.entity.' + $scope.col.colDef.name;
			$scope.$watchGroup(
				[name + '.registration.id',
				 name + '.registration.fromDate', 
				 name + '.registration.toDate', 
				 name + '.first',
				 name + '.placeholder',
				 name + '.isWaitingRegistration',
				 name + '.metadata'], registrationChanged);
		}
	}
};

module.exports = agendaGridRegistration;