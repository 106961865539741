'use strict'

var css = require('./filter-box.css');

function filterBox() {
	
	return {
		restrict: 'E',
		template: require('./filter-box.html'),
		scope:true,
		bindToController: {
			text: '='
		},
		controllerAs: 'filterBoxCtrl',
		controller: /*@ngInject*/ function($scope) {
			var filterBoxCtrl = this;

			filterBoxCtrl.clearText = function() {
				filterBoxCtrl.text = '';
			}
		}
	}
};

module.exports = filterBox;