'use strict';

/*@ngInject*/
function CreditPageCtrl($scope, $rootScope, $log, $filter, ModalSpinner, BackgroundTasks, Balance, Flags, Principal) {
	
	$log.debug('initializing credit controller');
	var credit = this;
	credit.currentDate = new Date();
	credit.untilDate = new Date();
	credit.includeEarningDates = false;
	credit.includeStatistics = false;
	
	credit.visibleTab = new Flags('calculation', 'history');
	credit.buttonsEnabled = false;
	
	ModalSpinner.hide();
	
	credit.summaryGridOptions = {
			hideHeader: true,
			columnDefs: [
	             { 
	            	 name: 'summaryName', 		 					
	            	 field: 'name', 	
	            	 displayName:'name' 
	             },
	             { 
	            	 name: 'summaryValue',	
	            	 field: 'value', 	
	            	 displayName:'value' 
	             }
			]	
	};
	
	credit.historySettingsGridOptions = {
					columnDefs: [
			             { 
			            	 name: '_fromDate', 		 					
			            	 displayName:'credit.balanceCounterHistoryItem.fromDate', 
			            	 cellTemplateUrl: '/credit/validFrom.html',
			            	 field: 'validFrom',
			            	 sortable: true
			             },
			             { 
			            	 name: '_parameterName',	
			            	 field: 'counterType.description', 	
			            	 displayName:'credit.balanceCounterHistoryItem.parameterName',
			            	 sortable: true
			             },
			             { 
			            	 name: '_value', 			
			            	 field: 'value', 					
			            	 displayName:'credit.balanceCounterHistoryItem.value',
			            	 sortable: true
			             }
					],
					onRegisterApi: function(gridApi) {
						gridApi.core.sort(this.columnDefs[0], 'DESC');
					}	
			};
	
	credit.alert = {
			visible: false,
			type: 'info',
			title: '',
			message: ''			
	};
	
	credit.closeAlert = function() {
		credit.alert.visible = false;
	};
	
	credit.calculate = function() {
		
		ModalSpinner.show('credit.calculating');
		Balance.getCreditBalanceSummary({}, {
			params: {
				employeeId: credit.resource.id,
				untilDate: $filter('date')(credit.untilDate, 'dd/MM/yyyy'),
				includeEarningDates: false, // parameter used only by the jrxml report
				includeStatistics: false, // parameter used only by the jrxml report
				automaticallyAdjustUntilDate: true
			}
		}).then(function(result) {
			credit.creditCalculation = result.data;
			credit.historySettingsGridOptions.data = credit.creditCalculation.counters;
			
			credit.summaryGridOptions.data = [
			                                  { name: $filter('translate')('credit.summary.finalBalance'), 		value: "" + credit.creditCalculation.finalBalance },
			                                  { name: $filter('translate')('credit.summary.legalMinimumDate'), 	value: $filter('date')(credit.creditCalculation.legalMinimumDate, 'dd/MM/yyyy HH:mm') },
			                                  { name: $filter('translate')('credit.summary.adjustedUntilDate'), value: $filter('date')(credit.creditCalculation.adjustedUntilDate, 'dd/MM/yyyy HH:mm') },
			                                  ];

			credit.visibleTab.set('calculation');
			$scope.masterDetail.goToDetail();
		}).finally(function() {
			ModalSpinner.hide();
		});
	};
	
	credit.runReport = function() {		
		var backgroundTask = {	
				name: 'Krediet rapport',		
				type: 'REPORT_GENERATION',
				inputParameters: [
				                  { name: 'RapportGebruiker', value: $rootScope.securityContext.resource.name + ' ' + $rootScope.securityContext.resource.firstName },
				                  { name: 'reportCode', value: 'CreditBalanceReport' },
				                  { name: 'Employee.persoon', value: credit.resource.id },
				                  { name: 'Berekeningsdatum', value: $filter('date')(credit.untilDate, 'dd/MM/yyyy') },
				                  { name: 'includeEarningDates', value: credit.includeEarningDates },
				                  { name: 'includeStatistics', value: credit.includeStatistics },
				]				
		};
				
		BackgroundTasks.runInBackground({}, {
			data: backgroundTask
		}).then(function() {
			credit.alert = {
					visible: true,
					type: 'success',
					title: 'Generatie begonnen',
					message: $filter('translate')('credit.report.generate')	
			};
		}, function(error) {
			credit.alert = {
					visible: true,
					type: 'danger',
					title: 'Error',
					message: $filter('translate')('credit.report.error')	
			};
		});
	};
	
	credit.showHistoryItems = function() {
		credit.visibleTab.set('history');
		$scope.masterDetail.goToDetail();
	};
	
	credit.onResourceChanged = function() {
		if (!credit.resource || !angular.isObject(credit.resource)) {
			credit.visibleTab.reset();
			credit.buttonsEnabled = false;
		} else {
			credit.buttonsEnabled = true;
		}
		
		// update disabled state on the navbar actions
		for (var i = 0; i < $rootScope.navActions.length; i++) {
			$rootScope.navActions[i].disabled = !credit.buttonsEnabled;
		}
	};
	
	$scope.$watch('credit.resource', credit.onResourceChanged);
	
	credit.editAction = {
    	 	index: 30,
 			icon: 'fa-pencil-square-o',
 			template: '<button class="btn btn-default btn-action" ng-click="action.run()" ng-disabled="action.disabled" ng-show="{{action.hasEditAllCounters}}">' + 
 						'<i class="fa fa-fw" ng-class="action.icon"></i>' +
 					  '</button>',
 			run: credit.showHistoryItems,
 			disabled: false,
 			hasEditAllCounters: false
    };
	
	Principal.hasPermissions("CREDIT_EDIT_ALL_COUNTERS").then(function(result) {
		credit.editAction.hasEditAllCounters = result;
	});
	
	$rootScope.navActions = [
         {
        	 	index: 10,
     			icon: 'fa-calculator',
     			run: credit.calculate,
     			disabled: true
         },
         {
        	 	index: 20,
     			icon: 'fa-print',
     			run: credit.runReport,
     			disabled: true
         },
         credit.editAction
	];
	
	$log.debug('credit initialized');

}

module.exports = CreditPageCtrl;