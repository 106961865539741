'use strict';

/*@ngInject*/
function navbarHideIfChildrenHidden($rootScope) {
	// Cases: (P - it has permission; !P - it doesn't have permission)
	// 1. Parent: it doesn't require permission or P; children - P => show top menu item + submenu items
	// 2. Parent: it doesn't require permission or !P; children - !P => hide top menu item + submenu items
	// 3. Parent: P; children: !P => show top menu; hide submenu items
	// 4: Parent: !P; children: P => show top menu item + submenu items

	return {
		restrict: 'A',
		link: function($scope, elt, attrs) {
			$scope.$on('permissionsChanged', function(event, args) {
				if (event.targetScope.hasPermissions && $scope.hasPermissions) {
					// at least one child is visible => show this element
					elt.scope().hideElement = false;
					elt.scope().navRoute.atLeastOneVisibleChild = true;
				} else {
					// this child is hidden, but others may be visible => check
					doHideIfChildrenHidden();
				}
			});

			function doHideIfChildrenHidden() {
				// show the element if it doesn't require permissions or if it has permissions;
				var eltScope = elt.scope();
				var showElt = false;
				if (eltScope && eltScope.navRoute.permissions && eltScope.hasPermissions) {
					showElt = true;
				}

				// top menu: a top menu item is a 'li' and the submenu items are lis (children of the top menu item).
				// drawer menu: a top menu item is an anchor element and the submenu items are anchor elements (children of the next sibling of the top menu item).
				var lis = elt.find('li');
				if (elt[0].tagName == 'A') {
					lis = $(elt[0].nextElementSibling).find('a');
				}

				var atLeastOneVisibleChild = false;
				for (var i = 0; i < lis.length; i++) {
					var scope = angular.element(lis[i]).scope();
					if (scope && scope.hasPermissions) {
						// found one visible child
						atLeastOneVisibleChild = true;
						showElt = true;
						break;
					}
				}
				
				if (showElt) {
					eltScope.hideElement = !showElt;
					eltScope.navRoute.atLeastOneVisibleChild = atLeastOneVisibleChild;
					return;
				}
				eltScope.hideElement = true;
			}
		}
	}

};

module.exports = navbarHideIfChildrenHidden;