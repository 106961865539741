export enum DatePartEnum {
    FULL_YEAR = "fullYear",
    MONTH = "month",
    DATE = "date",
    HOURS = "hours",
    MINUTES = "minutes",
    SECONDS = "seconds",
    MILLISECONDS = "milliseconds"
}
var moment = require("moment");

export const MILISECONDS_IN_MINUTE: number = 1000 * 60;
export const MILISECONDS_IN_HOUR: number = MILISECONDS_IN_MINUTE * 60;
export const MILISECONDS_IN_DAY: number = MILISECONDS_IN_HOUR * 24;
export const DIFF_DAYS: number = 2;
export const DIFF_HOURS: number = 3;
export const DIFF_MINUTES: number = 4;
export const DIFF_SECONDS: number = 5;
export const DIFF_MILLISECONDS: number = 6;

export class DateUtil {
    static getDifferenceWithUnitMeasure(van: Date, tot: Date, cut: boolean = false): string {
        // No end
        if (van == null || tot == null) {
            return '~';
        }

        // Round seconds to previous minute
        van = DateUtil.roundDownTillMinutes(van);
        if (cut) {
            // Round seconds to previous minute
            tot = DateUtil.roundDownTillMinutes(tot);
        } else {
            // Round seconds to next minunte
            tot = DateUtil.roundUpTillMinutes(tot);
        }

        // If 'until' date ends with 29 and 'from' date equals 0 or 30 then add 1 minute to 'until' date 
        // If 'until' date ends with 59 and 'from' date equals 0 or 30 then add 1 minute to 'until' date 
        if ((tot.getMinutes() == 29 && (van.getMinutes() == 0 || van.getMinutes() == 30)) ||
            (tot.getMinutes() == 59 && (van.getMinutes() == 0 || van.getMinutes() == 30))) {
            tot = DateUtil.dateAdd("minutes", 1, tot);
        }

        var difference: number = tot.getTime() - van.getTime();
        var days: bigint = BigInt(difference) / BigInt(MILISECONDS_IN_DAY);
        var hours: bigint = (BigInt(difference) - (days * BigInt(MILISECONDS_IN_DAY))) / BigInt(MILISECONDS_IN_HOUR);
        var mins: bigint = (BigInt(difference) - (days * BigInt(MILISECONDS_IN_DAY)) - (hours * BigInt(MILISECONDS_IN_HOUR))) / BigInt(MILISECONDS_IN_MINUTE);

        return (days > 0 ? days.toString() + "d" : "") +
            (hours > 0 ? hours.toString() + "u" : "") +
            (mins > 0 ? mins.toString() + "m" : "");
    }

    static roundDownTillMinutes(date: Date): Date {
        if (date == null) {
            date = new Date();
        }
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes());
    }

    static roundUpTillMinutes(date: Date): Date {
        if (date == null) {
            date = new Date();
        }
        if (date.getSeconds() > 0) {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() + 1);
        }
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes());
    }

    static dateAdd(datepart: string, number: number = 0, date: Date): Date {
        if (date == null) {
            date = new Date();
        }
        var returnDate: Date = new Date(date.getTime());
        switch (datepart.toLowerCase()) {
            case DatePartEnum.FULL_YEAR:
                returnDate.setFullYear(returnDate.getFullYear() + number);
                break;
            case DatePartEnum.MONTH:
                returnDate.setMonth(returnDate.getMonth() + number);
                break;
            case DatePartEnum.DATE:
                returnDate.setDate(returnDate.getDate() + number);
                break;
            case DatePartEnum.HOURS:
                returnDate.setHours(returnDate.getHours() + number);
                break;
            case DatePartEnum.MINUTES:
                returnDate.setMinutes(returnDate.getMinutes() + number);
                break;
            case DatePartEnum.SECONDS:
                returnDate.setSeconds(returnDate.getSeconds() + number);
                break;
            case DatePartEnum.MILLISECONDS:
                returnDate.setMilliseconds(returnDate.getMilliseconds() + number);
                break;
            default:
                /* Unknown date part, do nothing. */
                break;
        }
        return returnDate;
    }
}