'use strict';

var css = require('./catalogs.css');

/*@ngInject*/
function CatalogsCtrl(Education, Collection, catalogsFilter, ModalSpinner, $rootScope, rx) {
	var catalogs = this;
	catalogs.elementSubject = catalogs.elementSubject || new rx.Subject();
	
	catalogs.visible = ['TRACK', 'MODULE', 'COMPONENT'];
	catalogs.editable = true;
	
	catalogs.add = function($event, scope) {
		$event.stopPropagation();
		catalogs.addScope = scope;
		
		var element = scope.group.create();
		element.catalog = catalogs.catalog;
		
		delete catalogs.selection;
		catalogs.selectElt(element, null);
		scope.masterDetail.goToDetail();
	};

	catalogs.select = function(scope) {
		catalogs.active = 0;
		if (scope.masterDetail) {
			scope.masterDetail.goToDetail();
		}
		catalogs.createNewMajorVersion = false;
		catalogs.disableSave(true);
	};
	
	catalogs.editorIsDisabled = false;
	catalogs.saveIsDisabled = true;

	catalogs.change = function () {
		catalogs.disableSave(false);
	}

	catalogs.disableSave = function(disabled) {
		catalogs.saveIsDisabled = disabled;
	}

	catalogs.save = function() {
		var endpoint, config = {
			params: {
				id: catalogs.element.id,
				createNewMajorVersion: catalogs.elementSelectedVersion.forceVersionUpdate || catalogs.createNewMajorVersion
			},
			data: catalogs.element
		};
		var callback = angular.noop;

		if (catalogsFilter.asTrack(catalogs.element)) {
			endpoint = Education.saveTrack;
		} else if (catalogsFilter.asModule(catalogs.element)) {
			endpoint = Education.saveModule;
			callback = function clearTracks() {
				delete catalogs.groups[0].data;
			};
		} else if (catalogsFilter.asComponent(catalogs.element)) {
			if (catalogs.element.currentComponentVersion.objectType == 'EducationComponentVersion') {
				endpoint = Education.saveEducationComponent;
				config.data = {
					component: catalogs.element,
					profileVersion: catalogs.trainer.save ? catalogs.trainer.data : null,
					createNewMajorVersion: catalogs.elementSelectedVersion.forceVersionUpdate || catalogs.createNewMajorVersion,
					addedCompetences: catalogs.addedCompetences,
					removedCompetences: catalogs.removedCompetences
				};
			} else {
				endpoint = Education.saveComponent;
			}
			callback = function clearModules() {
				delete catalogs.groups[1].data;
			};
		}

		// save older version
		if (endpoint != undefined || endpoint != null) {
			ModalSpinner.show();
			endpoint.call(Education, null, config).then(function(result) {
				// update element and selection
				var element = result.data;
				var elements = catalogs.group.types ? catalogs.group.data[catalogs.group.type.id] : catalogs.group.data;
				Collection.create(elements).replace(element);
				catalogs.selectElt(element);
	
				// update versions if expanded
				catalogs.showHideVersions(catalogs.selection);
	
				// extra callback depending on type of element
				callback();
				catalogs.disableSave(true);
	
				catalogs.addedCompetences = [];
				catalogs.removedCompetences = [];
			}).finally(function() {
				ModalSpinner.hide();
				catalogs.createNewMajorVersion = false;
			});
			return;
		}

		delete config.params.createNewMajorVersion;
		if (catalogs.element.objectType == 'EducationComponentVersion'
				|| catalogs.element.objectType == 'ComponentVersion'
				|| catalogs.element.objectType == 'EvaluationComponentVersion' ) {
			endpoint = Education.saveComponentVersion;
		} else if (catalogs.element.objectType == 'ModuleVersion') {
			endpoint = Education.saveModuleVersion;
		} else if (catalogs.element.objectType == 'TrackVersion') {
			endpoint = Education.saveTrackVersion;
		}
		ModalSpinner.show();
		endpoint.call(Education, null, config).then(function(result) {
			catalogs.disableSave(true);
			catalogs.refreshGroups();
		}).finally(function() {
			ModalSpinner.hide();
		});
	};

	catalogs.isSaveDisabled = function() {
		return catalogs.editorIsDisabled || catalogs.saveIsDisabled;
	}

	catalogs.unselect = function() {
		if (catalogs.selection) {
			catalogs.goToMaster(catalogs.selection);
		}
		if (catalogs.addScope) {
			catalogs.goToMaster(catalogs.addScope)
		}
	};

	catalogs.goToMaster = function(scope) {
		scope.masterDetail.goToMaster();
	}
}

module.exports = CatalogsCtrl;