export const TimebarFormat = {
    majorLabels: {
        minute: {
            short: 'mm', //01
            long: 'HH:mm' //12:01
        },
        hour: {
            short: 'HH', //13
            long: 'HH:mm' //13:00
        },
        day: {
            short: 'Do', //1st
            long: 'ddd, LL' //Sun, July 3, 2018
        },
        month: {
            short: 'MMM', //Jan
            long: 'MMMM, YYYY' //January 2018
        },
        year: {
            short: 'YYYY', //2018
            long: 'YYYY' //2018
        }
    },
    minorLabels: {
        minute: {
            short: 'mm', //01
            long: 'HH:mm' //12:01
        },
        hour: {
            short: 'HH', //13
            long: 'HH' //13:00
        },
        day: {
            short: 'DD',  //01
            long: 'DD' // 01
        },
        month: {
            short: 'MM', //02
            long: 'MMM' //Jan
        },
        year: {
            short: 'YYYY', //2018
            long: 'YYYY' //2018
        }
    }
};
