'use strict';

function uiGridGrouping() {
	return {
		require: '^uiGrid',
		compile: function() {
			return {
				post: function($scope, $elm, $attrs, uiGridCtrl) {
					$scope.$watch(function() {
						return uiGridCtrl.grid.rowHeaderColumns;
					}, function(newValue, oldValue) {
						angular.forEach(newValue, function(rowHeaderColumn) {
							rowHeaderColumn.renderContainer = 'body';
						});
					});
				}
			}
		}
	}
}

module.exports = uiGridGrouping;