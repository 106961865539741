import { Item } from '@crispico/react-timeline-10000';
import { loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider } from 'apollo-gen/loadMetadataProvider';
import React from 'react';
import { Segment, Node } from '../PlanningGantt';

export enum RendererType {
    CONSTRAINT,
    REGISTRATION,
    SHIP_WORK_PERIOD
}

export interface BaseMapper {
    base: Function
}

export interface GanttMap<G extends BaseMapper, I extends BaseMapper, R extends BaseMapper> {
    groups: G,
    items: I,
    rowLayers: R
}

export interface Group {
    id: number,
    source: object,
    title: string,
    icon: string,
    children: Node[]
}

export interface CustomItem extends Item {
    start: Date,
    end: Date,
    label: string | number,
    row: number,
    key: number,
    style: object,
    type: RendererType,
    node: any,
    parent: any,
    isWaitingRegistration: boolean,
    metadata: loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider,
    allowViewRegistrationDetails: boolean
}

export interface Layer {
    start: Date,
    end: Date,
    style: object,
    rowNumber: number,
    key: number,
    zIndex: number
}

export interface Marker {
    start: Date,
    showInTimelineBody: boolean,
    style: object
}

export class Visitor {
    mapper: GanttAdapter;
    groupId: number;
    displayStartDate: Date;
    displayEndDate: Date;
    rowLayerId: number;
    simpleTreeStructure: boolean;

    constructor(mapper: GanttAdapter, displayStartDate: Date, displayEndDate: Date, simpleTreeStructure: boolean = false) {
        this.mapper = mapper;
        this.displayEndDate = new Date(displayEndDate);
        this.displayStartDate = new Date(displayStartDate);
        this.simpleTreeStructure = simpleTreeStructure;
        this.groupId = -1;
        this.rowLayerId = -1;
    }

    visit(node: Node, parent: Node | null, groups = [] as Group[], items = [] as Item[], rowLayers = [] as Layer[], groupLayers = [], indent = 0) {
        // process
        this.collectGroup(node, groups, indent);

        // iterate data
        if (this.getChildren(node).length === 0) {
            parent = node;
        }
        this.getData(node).map(object => {
            this.collectItem(object, parent, items);
        });

        let _groupLayers: [] = [];
        this.getData(node).map(object => {
            this.collectRowLayer(object, _groupLayers, rowLayers, node);
        });

        if (node.source && (node.source as { objectType: string })?.objectType === 'EmployeeSnapshot' && _groupLayers.length === 0) {
            groupLayers.map(groupLayer => {
                rowLayers.push({ ...groupLayer as any, rowNumber: this.groupId });
            });
        }

        // iterate children
        this.getChildren(node).map(child => {
            this.visit(child, node, groups, items, rowLayers, groupLayers.concat(_groupLayers), indent + 1);
        });
    }

    collectGroup(node: Node, groups: Group[], indent: number) {
        let source = this.getSource(node);
        if (source) {
            let mapped = this.mapper.mapGroup(source);
            let children = this.getChildren(node);
            if (mapped) {
                mapped.id = ++this.groupId;
                mapped.source = source;
                mapped.children = children;
                mapped.indent = indent;
                groups.push(mapped);
            }
        }
    }

    collectItem(node: Segment, parent: Node | null,  items: Item[]) {
        let source = this.getSource(node);
        if (source) {
            let mapped = this.mapper.mapItem(source, this.groupId);
            if (mapped) {
                mapped.row = this.groupId;
                mapped.node = { ...node, parent };
                mapped.parent = parent;

                items.push(mapped);
            }
        }
    }

    collectRowLayer(node: Segment, groupLayers: Layer[], rowLayers: Layer[], parent: Node) {
        let source = this.getSource(node);
        if (source) {
            let mapped = this.mapper.mapRowLayer(source);
            if (mapped) {
                mapped.rowNumber = this.groupId;
                mapped.source = source;
                mapped.key = ++this.rowLayerId;

                // We show the RosterRegistrations as row layers and the RosterRegistrations stay in the HourRegime row,
                // but this row is hidden in the simple tree structure.
                if (!this.simpleTreeStructure || (parent.source && (parent.source as { objectType: string })?.objectType === 'EmployeeSnapshot')) {
                    rowLayers.push(mapped);
                }
                // However, we always add the RosterRegistration in groupLayers because we need to copy the workperiod as
                // row layer for each employee that is a child of the HourRegime row.
                groupLayers.push(mapped);
            }
        }
    }

    getSource(node: Node | Segment): {} {
        return node.source || {};
    }

    getParent(node: Node | Segment): {} {
        return node.parent || {};
    }

    getChildren(node: Node): Node[] {
        return node.children || [];
    }

    getData(node: Node): Segment[] {
        return node.data || [];
    }

}

export class GanttAdapter {
    objectType: string;
    protected maps: GanttMap<BaseMapper, BaseMapper, BaseMapper>;

    constructor(maps: GanttMap<BaseMapper, BaseMapper, BaseMapper>, objectType = 'objectType') {
        this.maps = maps;
        this.objectType = objectType;
    }

    mapGroup(o: object) {
        return this.map(o, this.maps.groups);
    }

    mapItem(o: object, rowIndex: number) {
        return this.map(o, this.maps.items, rowIndex);
    }

    mapRowLayer(o: object) {
        return this.map(o, this.maps.rowLayers);
    }

    map(o: object, maps: BaseMapper, rowIndex?: number) {
        let fn = this.get(o, maps);
        if (!fn) {
            return null;
        }
        let baseFn = maps.base;
        if (baseFn) {
            return { ...baseFn(o), ...fn(o, rowIndex) };
        }
        return fn(o, rowIndex);
    }

    get(o: object, maps: any) {
        return maps[this.getObjectType(o)];
    }

    getObjectType(o: any) {
        return o[this.objectType] || o;
    }

}