'use strict';

/*@ngInject*/
function agendaGridAction() {

	return {
		template: require('./agenda-grid-action.html'),
		scope: true,
		link: function($scope, elt, attrs) {
			if (!$scope.action) {
				$scope.action = $scope.$eval(attrs.agendaGridAction);
			}
		}
	}
};

module.exports = agendaGridAction;