'use strict';

var objectiveEditor = {
	template: require('./objective-editor.html'),
	bindings: {
		objective: '<',
		catalogs: '<',
		initialEmployee: '<'		
	},
	controllerAs: 'objectiveEditor',
	controller: /*@ngInject*/ function(catalogsFilter, Curriculum, Resource, $filter, $scope) {
		
		var objectiveEditor = this;
		
		objectiveEditor.onEmployeeChange = function(employee) {
			if (employee) {
				objectiveEditor.objective.person = employee.id;
			}
		};
		
		objectiveEditor.$onInit = function() {
			if (objectiveEditor.objective) {
				objectiveEditor.objectiveComponent = objectiveEditor.objective.component || objectiveEditor.objective.module || objectiveEditor.objective.track;
			}
			
			objectiveEditor.objectiveTypes = ['TRACK', 'MODULE', 'COMPONENT'];
			objectiveEditor.currentDate = new Date();
			
			objectiveEditor.statusDropdownConfig = {
					data: [],
					selection: objectiveEditor.objective.status,
					labelField: 'description',
					onSelect: function(data) {						
						objectiveEditor.objective.status = data;
					},
					style: {
						"dropdown-menu-left": true
					}
			};
			
			Curriculum.getObjectiveStatuses({}, {}).then(function(result) {
				objectiveEditor.statusDropdownConfig.data = result.data;
				
				if (!objectiveEditor.objective.status) {
					objectiveEditor.statusDropdownConfig.selection = objectiveEditor.objective.status = $filter('filter')(result.data, {code: "GELDIG"}, true)[0]; 				
				}
			});
			
			if (objectiveEditor.objective.person) {
				Resource.getResource({}, { 
					data: {
						ids: [objectiveEditor.objective.person]
					} 
				}).then(function(result) {
					objectiveEditor.initialEmployee = result.data;
				});
			}
			if (objectiveEditor.initialEmployee) {
				objectiveEditor.objective.person = objectiveEditor.initialEmployee.id;
			}
		};
		
		objectiveEditor.select = function() {
			if(!objectiveEditor.objectiveComponent) {
				return;
			}

			objectiveEditor.objective.track = null;
			objectiveEditor.objective.module = null;
			objectiveEditor.objective.component = null;
			if (objectiveEditor.objectiveComponent.objectType == 'Track') {
				objectiveEditor.objective.track = objectiveEditor.objectiveComponent;
			} else if (objectiveEditor.objectiveComponent.objectType == 'Module') {
				objectiveEditor.objective.module = objectiveEditor.objectiveComponent;
			} else if (objectiveEditor.objectiveComponent.objectType == 'Component') {
				objectiveEditor.objective.component = objectiveEditor.objectiveComponent;
				if (objectiveEditor.objective.component.currentComponentVersion) {
					var componentVersion  = objectiveEditor.objective.component.currentComponentVersion;
					for (var i in componentVersion.competenceVersions) {
						delete componentVersion.competenceVersions[i].levelDefinitions;
					}
				}
			}
		};
	}
};

module.exports = objectiveEditor;