'use strict';

function RGBAConverter() {
	
	return {
		apply: function(data) {
			var bgColor = data['backgroundColor'] || data['BACKGROUND_COLOR'];
			var hideDetails = data['HIDE_REGISTRATION_DETAIL'];
			if (hideDetails) {
				// HIDE_REGISTRATION_DETAIL metadata property hides the details of a registration. Other color will be displayed for it.
				bgColor = '#808080';
			}
			if (bgColor) {
				// #hex -> (r,g,b,a)
				var hex = parseInt(bgColor.substring(1), 16);
				var r = (hex & 0xff0000) >> 16;
				var g = (hex & 0x00ff00) >> 8;
				var b = hex & 0x0000ff;
				
				var a = 1;
				
				var opacity = data['transparencyPercent'] || data['TRANSPARENCY_PERCENT'];
				if (opacity) {
					a = 1 - opacity / 100;
				}
				
				return {
					'background-color': 'rgba(' + [r, g, b, a].join() + ')',
					color: getGreyscale(r, g, b) < 128 ? '#fff' : '#000'
				}
			}
			return null ;
		}
	}
}

function getGreyscale(r, g, b) {
	return r * 0.299 + g * 0.587 + b * 0.114;
}

module.exports = RGBAConverter;