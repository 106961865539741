import { apolloClient } from "@crispico/foundation-react/apolloClient";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CONTRACT } from "graphql/queries";
import { ProteusConstants } from "ProteusConstants";
import React from "react";
import { Accordion, Form, Grid, Icon } from "semantic-ui-react";
import { CODE, DESCRIPTION, GROUP_CONTEXT, LABEL, NAME, OWNER, TYPE } from "./GroupsManagement";
import { sliceGroupsManagementBase } from "./sliceGroupsManagementBase";

export const sliceGroupOrContextEditor = createSliceFoundation(class SliceGroupOrContextEditor {
    initialState = {
        accordionIsOpen: false as boolean,
        employeesOptions: [] as { key: number, text: string, value: number }[]
    }

    nestedSlices = {
        groupsManagementBase: sliceGroupsManagementBase
    }

    reducers = {
        ...getBaseReducers<SliceGroupOrContextEditor>(this),
    }

    impures = {
        ...getBaseImpures<SliceGroupOrContextEditor>(this),

        async searchEmployee(text: string, fromDate: Date, untilDate: Date) {
            let employeesOptions = [];
            if (text.length > 2) {
                const result = (await apolloClient.query({
                    query: EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CONTRACT,
                    variables: {
                        searchText: text,
                        fromDate: fromDate,
                        untilDate: untilDate,
                        includeAllWithValidContract: true
                    },
                    context: { showSpinner: false }
                })).data.employeeService_employeeSnapshots;
                if (result === undefined || result === null) {
                    return;
                }
                employeesOptions = result.map((item: { id: number; name: string; firstName: string; contractHistoryItem: { employeeNumber: string, status: { code: string; }; }; }) => ({
                    key: item?.id as number,
                    text: ((item?.name) as string).concat(" ", item?.firstName as string, (item?.contractHistoryItem?.employeeNumber ?
                        " (" + item?.contractHistoryItem?.employeeNumber + ")" : "") as string),
                    value: item?.id as number,
                }))
            } else {
                employeesOptions = [];
            }
            this.getDispatchers().setInReduxState({ employeesOptions });
        }
    }
})

type PropsNotFromState = {
    selectedGroupOrContext: any;
    initialEmployeesOptions: { key: number, text: string, value: number }[];
    onAccordionFormChange: (fieldName: string, value: string | number | undefined) => void;
}

export class GroupOrContextEditor extends React.Component<PropsFrom<typeof sliceGroupOrContextEditor> & PropsNotFromState> {

    protected dummyOption = [{ key: -1, text: _msg("GroupContextTab.system.label"), value: -1 }];

    protected renderFormInputInAccordion(fieldName: string, groupOrContext: any) {
        return <Grid.Column >
            <Form.Input placeholder={_msg(fieldName + LABEL)} label={_msg(fieldName + LABEL)} value={groupOrContext?.[fieldName] || ""}
                onChange={(e: any, data: any) => this.props.onAccordionFormChange(fieldName, data.value)} disabled={groupOrContext?.id === undefined}>
            </Form.Input>
        </Grid.Column>
    }

    render() {
        return (
            <Accordion fluid>
                <Accordion.Title active={this.props.accordionIsOpen} index={0} onClick={() =>
                    this.props.dispatchers.setInReduxState({ accordionIsOpen: !this.props.accordionIsOpen })}>
                    <Icon name='dropdown' />{this.props.selectedGroupOrContext?.name}
                </Accordion.Title>
                <Accordion.Content active={this.props.accordionIsOpen}>
                    <Form>
                        {/* for contexts, the row will have 3 inputs (code, name, owner). For groups the row will have 4 (code, name, owner, type) */}
                        <Grid doubling columns={this.props.selectedGroupOrContext?.__typename === GROUP_CONTEXT ? 3 : 4}>
                            <Grid.Row className="GroupOrContextEditor_accordion_form">
                                {this.renderFormInputInAccordion(CODE, this.props.selectedGroupOrContext)}
                                {this.renderFormInputInAccordion(NAME, this.props.selectedGroupOrContext)}
                                <Grid.Column>
                                    <Form.Dropdown placeholder={_msg(OWNER + LABEL)} label={_msg(OWNER + LABEL)} selection search
                                        onChange={(e: any, data: any) => this.props.onAccordionFormChange(OWNER, data.value)} noResultsMessage={_msg("general.noResultsFound")}
                                        onSearchChange={(e, data) => {
                                            this.props.dispatchers.searchEmployee(data.searchQuery as unknown as string, new Date(), new Date());
                                        }} value={this.props.selectedGroupOrContext?.owner || this.dummyOption[0].value} disabled={this.props.selectedGroupOrContext?.id === undefined}
                                        options={this.dummyOption.concat(this.props.employeesOptions.length === 0 ? this.props.initialEmployeesOptions : this.props.employeesOptions)} />
                                </Grid.Column >
                                {this.props.selectedGroupOrContext?.__typename !== GROUP_CONTEXT &&
                                    <Grid.Column>
                                        <Form.Input placeholder={_msg(TYPE + LABEL)} label={_msg(TYPE + LABEL)} readOnly className="GroupOrContextEditor_type_input"
                                            value={this.props.selectedGroupOrContext?.__typename === ProteusConstants.STATIC_GROUP ? _msg("GroupsManagement.staticGroup.label") :
                                                (this.props.selectedGroupOrContext?.__typename === ProteusConstants.DYNAMIC_GROUP ? _msg("GroupsManagement.dynamicGroup.label") :
                                                    (this.props.selectedGroupOrContext?.__typename === ProteusConstants.COMPOSITION_GROUP ? _msg("GroupsManagement.compositionGroup.label") : ""))} />
                                    </Grid.Column>}
                            </Grid.Row>
                        </Grid>
                        <Grid columns={1}>
                            <Grid.Row className="GroupOrContextEditor_accordion_form">
                                <Grid.Column>
                                    <Form.TextArea placeholder={_msg(DESCRIPTION + LABEL)} label={_msg(DESCRIPTION + LABEL)} rows={1}
                                        value={this.props.selectedGroupOrContext?.description || ""} disabled={this.props.selectedGroupOrContext?.id === undefined}
                                        onChange={(e: any, data: any) => this.props.onAccordionFormChange(DESCRIPTION, data.value)} >
                                    </Form.TextArea>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Accordion.Content>
            </Accordion >
        );
    }
}