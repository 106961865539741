'use strict';


/** 
 * Iterates a tree in breadth-first mode. This means we iterate the tree on levels (all elements on first level, then all elements on second level and so on);
 * 
 * Theoretically, we can have Depth-first iteration: [pre order (root-left-right), in order (left-root-right), post order (left-right-root)] or Breadth-first iteration.
 * 
 * @returns
 * @author Florin Buzatu
 */
/*@ngInject*/
function TreeIterator() {
	
	return {
		
		create: function(root, getChildren) {		
			var iterator = {};			
			var stack = [];
			var children = getChildren(root);
			if (children != null) {
				Array.prototype.push.apply(stack, children); // push all
			}
			
			var index = 0;
			
			iterator.hasNext = function() {
				return index < stack.length;
			};
			
			iterator.next = function() {				
				var element = stack[index];	
				index++;
				var children = getChildren(element);
				if (children != null) {
					Array.prototype.push.apply(stack, children); // push all
				}
				return element;
			};
			
			return iterator;
		}	
	
	};
}

module.exports = TreeIterator;