'use strict';

var css = require('./grid.css');

angular.module('proteus.grid', [])

.directive('proteusGrid',          require('./grid.directive'))
.directive('proteusGridCol',       require('./grid-col.directive'))
.directive('proteusGridSelection', require('./grid-selection.directive'))
.directive('proteusGridGrouping',  require('./grid-grouping.directive'))

module.exports = 'proteus.grid';