import { apolloClient, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, Utils } from "@crispico/foundation-react";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { getEmployee_employeeService_employee } from "apollo-gen/getEmployee";
import { getPilotPremiumOverview_planningServiceFacadeBean_pilotPremiumOverview, getPilotPremiumOverview_planningServiceFacadeBean_pilotPremiumOverview_pilotingTaskItems } from "apollo-gen/getPilotPremiumOverview";
import { COMMON_SERVICE_ENDPOINT_RUN_IN_BACKGROUND, PLANNING_SERVICE_FACADE_BEAN_GET_PILOT_PREMIUM_OVERVIEW } from "graphql/queries";
import moment from "moment";
import { DateNavigationPanel, sliceDateNavigation } from "pages/DateNavigationPanel/DateNavigationPanel";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import { Button, Modal, Table } from "semantic-ui-react";

const PREMIUM_REPORT_CODE = "PilotPremium";
const PREMIUM_REPORT_NAME = "Premies (new)";
export const REPORT_USER = "RapportGebruiker";

export const slicePremiumTab = createSliceFoundation(class SlicePremiumTab {

    initialState = {
        premiumData: undefined as unknown as getPilotPremiumOverview_planningServiceFacadeBean_pilotPremiumOverview,
        openModal: false as boolean
    }

    nestedSlices = {
        premiumDatePanel: sliceDateNavigation
    }

    reducers = {
        ...getBaseReducers<SlicePremiumTab>(this)
    }

    impures = {
        ...getBaseImpures<SlicePremiumTab>(this),

        async loadPremiumData(resource: number | undefined | null, fromDate: Date, toDate: Date) {
            let premiumData = undefined;
            if (resource) {
                premiumData = (await apolloClient.query({
                    query: PLANNING_SERVICE_FACADE_BEAN_GET_PILOT_PREMIUM_OVERVIEW,
                    variables: { resource, fromDate, toDate }
                })).data.planningServiceFacadeBean_pilotPremiumOverview;
            }
            this.getDispatchers().setInReduxState({ premiumData });
        },

        async runReport(employeeId: number | null | undefined, fromDate: Date, toDate: Date) {
            if (!employeeId) {
                return;
            }

            const currentUser = ProteusUtils.getUserCompleteName();
            const runVariable: { [k: string]: any } = {
                name: PREMIUM_REPORT_NAME,
                type: ProteusConstants.REPORT_GENERATION,
                inputParameters: [
                    { name: REPORT_USER, value: currentUser },
                    { name: "reportCode", value: PREMIUM_REPORT_CODE },
                    { name: "EmployeeId", value: employeeId },
                    { name: "FromDate", value: this.adjustReportDate(fromDate, false) },
                    { name: "ToDate", value: this.adjustReportDate(toDate, true) }
                ]
            };

            await apolloClient.mutate({
                mutation: COMMON_SERVICE_ENDPOINT_RUN_IN_BACKGROUND,
                variables: {
                    backgroundTask: runVariable
                }
            });
            Utils.showGlobalAlert({ message: _msg("ReportDefinition.runMessage", PREMIUM_REPORT_NAME), severity: Severity.INFO });
        },

        adjustReportDate(date: Date, showTime: boolean) {
            let value = moment(date).format(Utils.dateFormat);
            if (showTime) {
                value += " 23:59";
            }
            return value;
        }
    }
})

type PropsNotFromState = {
    employee: getEmployee_employeeService_employee | undefined | null;
}

export class PremiumTab extends TabbedPage<PropsFrom<typeof slicePremiumTab> & PropsNotFromState> {

    protected renderTable() {
        return (
            <Table celled unstackable compact selectable striped onClick={() => this.props.dispatchers.setInReduxState({ openModal: true })}>
                <Table.Header>
                    <Table.Row className="PremiumTab_tableHeader">
                        {this.renderTableHeader()}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.renderTableBody()}
                </Table.Body>
            </Table>
        );
    }

    protected renderTableBody() {
        if (!this.props.premiumData || !this.props.premiumData.pilotingTaskItems) {
            return;
        }

        let rows = [];
        for (let i = 0; i < this.props.premiumData.pilotingTaskItems.length; i++) {
            rows.push(<Table.Row key={i}>{this.getTableRowValue(this.props.premiumData.pilotingTaskItems[i])}</Table.Row>);
        }
        return rows;
    }

    protected getTableRowValue(pilotingTaskItems: getPilotPremiumOverview_planningServiceFacadeBean_pilotPremiumOverview_pilotingTaskItems | null) {
        if (!pilotingTaskItems) {
            return;
        }

        return [
            <>
                <Table.Cell verticalAlign="middle" textAlign='center' key={0}>{pilotingTaskItems.registrationType?.name || ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={1}>{moment(new Date(pilotingTaskItems.startDate)).format(ProteusConstants.DATE_TIME_FORMAT)}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={2}>{moment(new Date(pilotingTaskItems.endDate)).format(ProteusConstants.DATE_TIME_FORMAT)}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={3}>{pilotingTaskItems.ship?.name || ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={4}>{pilotingTaskItems.from?.name || ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={5}>{pilotingTaskItems.to?.name || ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={6}>{pilotingTaskItems.ship?.loa || ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={7}>{pilotingTaskItems.role?.description || ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={8}>{pilotingTaskItems.regime || ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={9}>{pilotingTaskItems.annulation ? "Ja" : ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={10}>{pilotingTaskItems.seaCanal ? "Ja" : ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={11}>{pilotingTaskItems.choicePilot ? "Ja" : ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={12}>{pilotingTaskItems.superPilot ? "Ja" : ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={13}>{pilotingTaskItems.babySuperPilot ? "Ja" : ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={14}>{pilotingTaskItems.newLoaScale || ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={15}>{pilotingTaskItems.newExperienceScale || ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={16}>{pilotingTaskItems.newTaskCounter !== 0 ? pilotingTaskItems.newTaskCounter : ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={17}>{pilotingTaskItems.route || ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={18}>{pilotingTaskItems.premium?.premium || ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={19}>{pilotingTaskItems.premium?.superPilot || ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center' key={20}>{pilotingTaskItems.premium?.babySuperPilot || ""}</Table.Cell>
            </>
        ];
    }

    protected renderTableHeader() {
        if (!this.props.premiumData) {
            return;
        }

        return [
            <>
                <Table.HeaderCell textAlign='center' key={0}>{_msg("EmployeeEditor.premiums.taskType.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={1}>{_msg("EmployeeEditor.premiums.start.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={2}>{_msg("EmployeeEditor.premiums.end.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={3}>{_msg("EmployeeEditor.premiums.ship.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={4}>{_msg("EmployeeEditor.premiums.from.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={5}>{_msg("EmployeeEditor.premiums.to.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={6}>{_msg("EmployeeEditor.premiums.loa.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={7}>{_msg("EmployeeEditor.premiums.role.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={8}>{_msg("EmployeeEditor.premiums.regime.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={9}>{_msg("EmployeeEditor.premiums.annulation.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={10}>{_msg("EmployeeEditor.premiums.seaCanal.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={11}>{_msg("EmployeeEditor.premiums.chiocePilot.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={12}>{_msg("EmployeeEditor.premiums.superPilot.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={13}>{_msg("EmployeeEditor.premiums.babySuperPilot.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={14}>{_msg("EmployeeEditor.premiums.shipCategory.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={15}>{_msg("EmployeeEditor.premiums.experienceScale.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={16}>{_msg("EmployeeEditor.premiums.taskCounter.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={17}>{_msg("EmployeeEditor.premiums.route.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={18}>{_msg("EmployeeEditor.premiums.premium.piloting.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={19}>{_msg("EmployeeEditor.premiums.premium.superPilot.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={20}>{_msg("EmployeeEditor.premiums.premium.babySuperPilot.label")}</Table.HeaderCell>
            </>
        ];
    }

    renderSaveBarAdditionalContent = () => {
        return <>
            <div key="div1" className="FunctionsTab_barDivider" />
            <div>
                <Button disabled={!this.props.employee} primary
                    onClick={() => {
                        this.props.dispatchers.runReport(this.props.employee?.id, new Date(this.props.premiumDatePanel.fromDate), new Date(this.props.premiumDatePanel.untilDate));
                    }}>{_msg("Sequences.report.label")}</Button>
            </div>
            <DateNavigationPanel {...this.props.premiumDatePanel} dispatchers={this.props.dispatchers.premiumDatePanel} onChangePeriod={this.onPremiumDateNavigationChange} />
        </>
    }


    protected onPremiumDateNavigationChange = async (e: any) => {
        await this.props.dispatchers.loadPremiumData(this.props.employee?.id, e.fromDate, e.untilDate);
    }

    protected closeModal = () => {
        this.props.dispatchers.setInReduxState({ openModal: false });
    }

    render() {
        // on double click on premium table => open a modal containing only this table,
        // for
        return (
            <div className="PremiumTab">
                {this.renderTable()}
                <ModalExt open={this.props.openModal} size="small" className="PremiumTab_modal"
                     closeIcon={true} onClose={this.closeModal}>
                     <Modal.Header>{_msg("EmployeeEditor.premiums.label")}</Modal.Header>
                     <Modal.Content>
                        {this.renderTable()}
                     </Modal.Content>
                 </ModalExt>
            </div>
        );
    }

    componentDidMount() {
        this.props.dispatchers.premiumDatePanel.setInReduxState({
            fromDate: moment().startOf('month').valueOf(),
            untilDate: moment().endOf('month').valueOf(),
            periodDropdownValue: 1
        });
    }

    componentWillUnmount() {
        this.props.dispatchers.setInReduxState({ premiumData: undefined });
    }
}