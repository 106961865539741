'use strict';

/*@ngInject*/
function Agenda(DataSource) {

	var config = {
		baseUrl : '/restful/planningServiceEndpoint'
	};

	var ds = new DataSource(config);

	ds.config.getAll = ds.config.getAll || {};

	ds.config.getAll.method = 'POST';
	ds.config.getAll.url = '/agenda';

	if (!ds.getAll) {
		ds.registerOperation('getAll');
	}

	ds.config.get = ds.config.get || {};

	ds.config.get.url = '/registration/:id';

	if (!ds.get) {
		ds.registerOperation('get');
	}

	ds.config.save = ds.config.save || {};

	ds.config.save.method = 'POST';
	ds.config.save.url = '/registration';

	if (!ds.save) {
		ds.registerOperation('save');
	}

	ds.config.split = ds.config.split || {};

	ds.config.split.method = 'POST';
	ds.config.split.url = '/registration/split/:id/:errorPolicy';

	if (!ds.split) {
		ds.registerOperation('split');
	}

	ds.config.remove = ds.config.remove || {};

	ds.config.remove.method = 'POST';
	ds.config.remove.url = '/registration/remove/:id';

	if (!ds.remove) {
		ds.registerOperation('remove');
	}

	ds.config.getMatchingRoundingType = ds.config.getMatchingRoundingType || {};

	ds.config.getMatchingRoundingType.method = 'POST';
	ds.config.getMatchingRoundingType.url = '/matchingRoundingType';

	if (!ds.getMatchingRoundingType) {
		ds.registerOperation('getMatchingRoundingType');
	}

	ds.config.getWaitingBalance = ds.config.getWaitingBalance || {};

	ds.config.getWaitingBalance.method = 'POST';
	ds.config.getWaitingBalance.url = '/waitingBalance';

	if (!ds.getWaitingBalance) {
		ds.registerOperation('getWaitingBalance');
	}

	ds.config.getBalance = ds.config.getBalance || {};

	ds.config.getBalance.method = 'POST';
	ds.config.getBalance.url = '/balance';

	if (!ds.getBalance) {
		ds.registerOperation('getBalance');
	}

	ds.config.getRoundingValue = ds.config.getRoundingValue || {};

	ds.config.getRoundingValue.method = 'POST';
	ds.config.getRoundingValue.url = '/roundingValue';

	if (!ds.getRoundingValue) {
		ds.registerOperation('getRoundingValue');
	}

	ds.config.getMedicalCheckupTypes = ds.config.getMedicalCheckupTypes || {};

	ds.config.getMedicalCheckupTypes.method = 'POST';
	ds.config.getMedicalCheckupTypes.url = '/medicalCheckupTypes';

	if (!ds.getMedicalCheckupTypes) {
		ds.registerOperation('getMedicalCheckupTypes');
	}

	ds.config.getWorkPeriod = ds.config.getWorkPeriod || {};

	ds.config.getWorkPeriod.method = 'POST';
	ds.config.getWorkPeriod.url = '/workPeriod';

	if (!ds.getWorkPeriod) {
		ds.registerOperation('getWorkPeriod');
	}

	ds.config.isConstrainedRegistrationType = ds.config.isConstrainedRegistrationType || {};

	ds.config.isConstrainedRegistrationType.method = 'POST';
	ds.config.isConstrainedRegistrationType.url = '/agenda/constrainedRegistrationType';

	if (!ds.isConstrainedRegistrationType) {
		ds.registerOperation('isConstrainedRegistrationType');
	}

	ds.config.getGroupByCode = ds.config.getGroupByCode || {};

	ds.config.getGroupByCode.method = 'POST';
	ds.config.getGroupByCode.url = '/getGroupByCode/:groupCode';

	if (!ds.getGroupByCode) {
		ds.registerOperation('getGroupByCode');
	}
	
	ds.config.switchRegistration = ds.config.switchRegistration || {};
	ds.config.switchRegistration.method = 'POST';
	ds.config.switchRegistration.url = '/registration/switchRegistration';
	if (!ds.switchRegistration) {
		ds.registerOperation('switchRegistration');
	}

	ds.config.getRegistrationTypesWithProperty = ds.config.getRegistrationTypesWithProperty || {};
	ds.config.getRegistrationTypesWithProperty.method = 'POST';
	ds.config.getRegistrationTypesWithProperty.url = '/registrationType/metadata/:property/:value/:type';
	if (!ds.getRegistrationTypesWithProperty) {
		ds.registerOperation('getRegistrationTypesWithProperty');
	}

	return ds;
}

module.exports = Agenda;