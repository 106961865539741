'use strict'

/*@ngInject*/
function InboxItemsPageCtrl($scope, $rootScope, $q, uiGridConstants, InboxItems, Confirm, ErrorModal, ModalSpinner) {
	
	var ctrl = this;
		
	// grid options
	$scope.actionButtonsType = "btn btn-primary btn-action";
	$scope.gridOptions = {
		enableHorizontalScrollbar: 0,
		gridMenuShowHideColumns: false,
		
		flatEntityAccess: true,
		
		// pagination
		paginationPageSizes: [],
		paginationPageSize: 20,
		
		// selection
		multiSelect: true,
		enableFullRowSelection: false,
		selectionRowHeaderWidth: 50,
		isRowSelectable: function(row) {
			return row.grid.appScope.canRemove(row.entity);
		},
		
		onRegisterApi: function(gridApi) {
			$scope.gridApi = gridApi;
		}
	}
	
	$scope.sortCol = {
		name: 'date',
		field: 'date',
		headerCellFilter: 'translate',
		// using date to use sorting
		enableHiding: false,
		minWidth: 120,
		cellTemplate: require('./inbox-item.html')
	};
	
	$scope.gridOptions.columnDefs = [
	
	// title column
	
	{
		name: 'title',
		field: 'title',
		enableHiding: false,
		displayName: 'inboxItems.cols.title',
		headerCellFilter: 'translate',
		minWidth: 100,
		cellTemplate: '<div class="ui-grid-cell-contents word-wrap" title="TOOLTIP">{{row.entity.title}}</div>'
	}, 
	
	// date column
	
	$scope.sortCol,

	// view column: button to open detail
	
	{
		name: 'view',
		enableHiding: false,
		enableSorting: false,
		enableColumnMenu: false,
		displayName: '',
		width: 60,
		cellTemplate: '<div class="ui-grid-cell-contents text-center" title="TOOLTIP">' + 
			'<a class="btn btn-default" ng-href="#/inboxItem/{{row.entity.id}}">' +
				'<span class="fa fa-search">' + 
					'<sup class="badge-warn">' +
						'<div class="badge" ng-show="grid.appScope.hasActions(row.entity)">!</div>' +
					'</sup>' +
				'</span>' +
			'</a>' + 
		'</div>'
	
	},

	// remove column
	
	{
		name: 'remove',
		enableHiding: false,
		enableSorting: false,
		enableColumnMenu: false,
		displayName: '',
		width: 60,
		cellTemplate: '<div class="ui-grid-cell-contents text-center" title="TOOLTIP">' + 
		'<button class="btn btn-primary" ng-click="grid.appScope.remove([row.entity])" ng-disabled="!grid.appScope.canRemove(row.entity)">' + 
		'<i class="fa fa-trash"></i>' + 
		'</button>' + 
		'</div>'
	}]
	
	$scope.hasActions = function(item) {
		for (var i = 0; i < item.parameters.length; i++) {
			var parameter = item.parameters[i];
			if (parameter.name !== 'Verwijder') {
				return true;
			}
		}
		return false;
	}

	/**
	 * Check if the item can be removed (e.g. for exchange workflow, the message cannot be removed).
	 */
	$scope.canRemove = function(item) {
		for (var i = 0; i < item.parameters.length; i++) {
			var parameter = item.parameters[i];
			if (parameter.name === 'Verwijder') {
				return true;
			}
		}
		return false;
	}
	
	/**
	 * Remove action.
	 */
	$scope.remove = function(items) {
		// ask for confirmation
		Confirm.open({
			title: 'clear',
			message: 'inboxItems.remove.confirm'
		}).result.then(function() {
			ModalSpinner.show();
			
			// avoid multiple refresh
			var promises = [];
			for (var i = 0; i < items.length; i++) {
				promises.push($scope.process(items[i], {
					key: 'Verwijder',
					value: null 
				}));
			}
			
			$q.all(promises).then(angular.noop, function(error) {
				ErrorModal.open({
					titleId: ErrorModal.SERVER_ERROR,
					message: error.data.message
				});
			}).finally(function() {
				$scope.refresh();
			});
		});
	}
	
	$scope.process = function(item, parameter) {
		return InboxItems.process({}, {
			silent: true,
			data: {
				activityInstanceId: item.id,
				processInstanceId: item.processInstance,
				person: item.person,
				parameters: [parameter]
			}
		});
	}
	
	/**
	 * Refresh action.
	 */
	$scope.refresh = function() {
		ModalSpinner.show();
		InboxItems.getAll($scope.gridOptions).finally(function() {
			if ($scope.gridOptions.data.length === 0) {
				$scope.gridApi.grid.selection.selectAll = false;
			}

			var navRoute = $rootScope.getNavRoute('#/inboxItems');
			if (navRoute) {
				navRoute.badge = $scope.gridOptions.data.length;
			}

			// The inbox items are order first, by action, and then, by date.
			// To check if we have inbox items with action, it is enough to 
			// check if the first item requires an action. 
			if ($scope.gridOptions.data.length > 0) {
				var firstInboxItem = $scope.gridOptions.data[0];
				if ($scope.hasActions(firstInboxItem)) {
					navRoute.parentRoute.navRouteClass = "proteus-badge-danger";
				} else {
					navRoute.parentRoute.navRouteClass = null;
				}
			}

			ModalSpinner.hide();
		});
	}
	
	// initial refresh
	$scope.refresh();
	
	$rootScope.navActions = [
	
	// Remove selected items action.
	
	{
		index: 10,
		icon: 'fa-trash',
		run: function() {
			var selection = $scope.gridApi.selection.getSelectedRows();
			if (selection.length == 0) {
				// empty selection
				ErrorModal.open({
					titleId: ErrorModal.ERROR,
					messageId: 'inboxItems.remove.emptySelection'
				});
				return;
			}
			$scope.remove(selection);
		}
	},

	// Refresh.

	{
		index: 20,
		icon: 'fa-refresh',
		run: $scope.refresh,
		template: '<proteus-ui-refresh action="action"></proteus-ui-refresh>'
	}];

	$scope.mqChanged = function(mq, visible) {
		for (var i = 0; i < $scope.gridOptions.columnDefs.length; i++) {
			// hide on xs, show on sm+
			$scope.gridOptions.columnDefs[i].visible = !visible;
		}
		// always visible
		$scope.sortCol.visible = true;
		
		if (visible) {
			$scope.sortCol.width = '*';
			$scope.sortCol.displayName = 'inboxItems.title';
		} else {
			$scope.sortCol.width = 120;
			$scope.sortCol.displayName = 'inboxItems.cols.date';
		}
		
		if ($scope.gridApi) {
			$scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
		}
	}

};

module.exports = InboxItemsPageCtrl;
