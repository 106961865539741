'use strict';

/*@ngInject*/
function EvaluationsListCtrl($scope, ModalSpinner, Education, FormModal, Confirm, ErrorModal, $filter) {
	let data;

	// selectedEvaluationRequest is the evaluation request from the first page of the wizard.
	let selectedEvaluationRequest = $scope.requests.evaluationRequest;
	// evaluationRequest is the "cached" evaluation request that keeps the changes when we navigate in the wizard
	let evaluationRequest = $scope.requests.evaluations ? $scope.requests.evaluations.evaluationRequest : null;
	if (evaluationRequest && !selectedEvaluationRequest.selectOrCreateNewEvaluationRequest && ((evaluationRequest.id && $scope.requests.evaluationRequest.id) || (evaluationRequest.id == $scope.requests.evaluationRequest.id))) {
		data = $scope.requests.evaluations && $scope.requests.evaluations.gridOptions ? $scope.requests.evaluations.gridOptions.data : null;
	}
	delete $scope.requests.evaluationRequest.selectOrCreateNewEvaluationRequest;

	let evaluations = $scope.requests.evaluations = this;

	// wizard invalid for save means that are no changes
	evaluations.setWizardInvalidForSave = function(invalidWizardForSave) {
		$scope.wizard.invalidForSave = invalidWizardForSave;
	}

	evaluations.gridOptions = {
		columnDefs: [{
			name: 'resource',
			field: 'resource.completeName',
			cellClass: function(row) {
				let result = '';
				if (!row.entity.resource || !row.entity.evaluator) {
					result += 'danger text-danger';
				}
				if (row.grouping) {
					angular.forEach(row.children, function(evaluation) {
						if (!evaluation.resource || !evaluation.evaluator) {
							result += 'danger text-danger';
						}
					});
				}
				return result;
			},
			headerTemplateUrl: '/evaluation/request/evaluations-actions-header.html'
		}, {
			name: 'published',
			displayIcon: 'fa fa-upload',
			width: '30px',
			emptyForGrouping: true,
			headerTooltip: 'Gepubliceerd',
			cellTemplate: '<input type="checkbox" class="evaluation-request-checkbox" ng-checked="::row.entity.published" disabled>'
		}, {
			name: 'started',
			displayIcon: 'fa fa-play',
			width: '30px',
			emptyForGrouping: true,
			headerTooltip: 'Begonnen',
			cellTemplate: '<input type="checkbox" class="evaluation-request-checkbox" ng-checked="::row.entity.scores.length > 0" disabled>'
		}, {
			name: 'finished',
			displayIcon: 'fa fa-flag',
			width: '35px',
			emptyForGrouping: true,
			headerTooltip: 'Afgewerkt',
			cellTemplate: '	<a class="btn btn-xs btn-primary" ng-disabled="!row.entity.finished" ng-click="grid.appScope.evaluations.getEvaluationResult(row.entity)"><i class="fa fa-fw fa-clipboard"></i></a>'
		}, {
			name: 'targetDate',
			field: 'targetDate',
			emptyForGrouping: true,
			headerTemplateUrl: '/evaluation/request/evaluations-date-header.html',
			cellTemplate: '<proteus-date-picker date="row.entity.targetDate" options="{onChange: options.targetDateOptions.onChange, published: row.entity.published}" ng-class="grid.appScope.evaluations.evaluationRequest.archiveDate ? \'evaluation-disabled disabled\' : \'\'"></proteus-date-picker>'
		}, {
			name: 'actions',
			headerTemplateUrl: '/evaluation/request/evaluations-actions-header.html',
			cellTemplate: require('./evaluations-actions.html')
		}],

		emptyLabel: 'education.evaluationRequest.noResource',

		onRegisterApi: function (gridApi) {
			evaluations.gridApi = gridApi;
			gridApi.grouping.setGrouping({
				grouping: [{
					name: 'resource',
					criteria: 'evaluator',
					field: 'evaluator.completeName'
				}]
			});
			gridApi.grouping.expandCollapseAll();
		},
		targetDateOptions: {
			onChange: function(date) {
				evaluations.evaluationRequest.targetDateChanges = true;
				evaluations.checkValid();
			}
		}
	};

	evaluations.checkFinishedAll = function() {
		// disable notify button if the request is archived
		if (evaluations.evaluationRequest.archiveDate || !evaluations.gridOptions || !evaluations.gridOptions.data || evaluations.gridOptions.data.length == 0) {
			$scope.requests.setNotifyInvalid(true);
			return;
		}

		for (let i = 0; i < evaluations.gridOptions.data.length; i++) {
			let evaluation = evaluations.gridOptions.data[i];
			if (!evaluation.finished && evaluation.published && evaluation.id && evaluation.evaluator && evaluation.resource) {
				$scope.requests.setNotifyInvalid(false);
				return;
			}
 		}
 		
		$scope.requests.setNotifyInvalid(true);
	}

	evaluations.checkValid = function() {
		if (evaluations.evaluationRequest.archiveDate) {
			// disable save buttons if the request is archived
			$scope.wizard.invalidForSave = true;
			evaluations.setWizardInvalidForSave(true);
			$scope.requests.setPublishInvalid(true);
			return;
		}

		angular.forEach(evaluations.gridOptions.data, function(evaluation) {
			if (!evaluation.resource || !evaluation.evaluator) {
				$scope.wizard.invalidForSave = true;
			}
		});

		let incompleteData = false;
		let unsavedEvaluations = false;
		let evalutionsToPublish = false;
		for (let i = 0; i < evaluations.gridOptions.data.length; i++) {
			let evaluation = evaluations.gridOptions.data[i];
			if (!evaluation.resource || !evaluation.evaluator) {
				incompleteData = true;
				break;
			} else {
				if (!evaluation.id) {
					unsavedEvaluations = true;
				} else if (evaluation.published == false) {
					evalutionsToPublish = true;
				}
			}
		}
		let invalidSave = true, invalidPublish = true;
		if (incompleteData) {
			invalidSave = true;
			invalidPublish = true;
		} else if (unsavedEvaluations) {
			invalidSave = false;
			invalidPublish = false;
		} else if (evalutionsToPublish) {
			invalidPublish = false;
		}
		if (!incompleteData && (evaluations.evaluationRequest.targetDateChanges || !$scope.requests.invalidWizardForSave || evaluations.evaluationRequest.evaluationsToRemove.length > 0)) {
			invalidSave = false;
		}
		evaluations.setWizardInvalidForSave(invalidSave);
		$scope.requests.setPublishInvalid(invalidPublish);
	};

	evaluations.refresh = function(evaluationRequestId, selectNew) {
		if (data && !selectNew) {
			evaluations.gridOptions.data = data;
			evaluations.evaluationRequest = evaluationRequest;
			ModalSpinner.hide();
			evaluations.checkValid();
			return;
		}

		ModalSpinner.show('education.evaluationRequest.loading');

		let id = evaluationRequestId ? evaluationRequestId : $scope.requests.evaluationRequest.id;
		Education.getEvaluations(null, {
			params: {
				id: id
			}
		}).then(function(result) {
			evaluations.evaluationRequest = Object.assign({}, result.data);
			if (!evaluations.evaluationRequest.evaluationsToRemove) {
				evaluations.evaluationRequest.evaluationsToRemove = [];
			}
			evaluations.findEvaluatorsToNotify(result.data.evaluations);
			evaluations.gridOptions.data = result.data.evaluations;

			for (let i in result.data.evaluations) {
				let targetDate = result.data.evaluations[i].targetDate;
				result.data.evaluations[i].targetDate = targetDate ? new Date(targetDate) : null;
			}

			evaluations.checkValid();
			evaluations.checkFinishedAll();
			setGroupingAndExpand();
		}).finally(function() {
			ModalSpinner.hide();
		});
	}

	if (!$scope.requests.evaluationRequest.id && !data) {
		evaluations.gridOptions.data = [];

		evaluations.evaluationRequest = $scope.requests.evaluationRequest;
		evaluations.evaluationRequest.evaluations = [];
		evaluations.evaluationRequest.evaluationsToRemove = [];

		evaluations.checkValid();
		evaluations.checkFinishedAll();
	} else {
		evaluations.refresh();
	}

	function setGroupingAndExpand() {
		evaluations.gridApi.grouping.setGrouping({
			grouping: [{
				name: 'resource',
				criteria: 'evaluator',
				field: 'evaluator.completeName'
			}]
		});
		evaluations.gridApi.grouping.expandCollapseAll();
	}

	evaluations.saveEvaluations = function(publish) {
		ModalSpinner.show();

		var evaluationRequest = angular.copy($scope.requests.evaluationRequest);
		delete evaluationRequest.numberOfEvaluations;
		delete evaluationRequest.numberOfFinishedEvaluations;
		delete evaluationRequest.numberOfStartedEvaluations;
		if (publish) {
			evaluationRequest.publishDate = new Date();
			evaluationRequest.publishNewEvaluations = true;
		}
		evaluationRequest.evaluations = evaluations.gridOptions.data;

		let componentVersion = evaluationRequest.componentVersion;

		Education.saveEvaluations(null, {
			data: {
				evaluationRequest: evaluationRequest,
				componentVersion: componentVersion
			}
		}).then(function(result) {
			// go back to evaluation requests, select saved request
			$scope.requests.initiallySelectedId = result.data;
			evaluations.setWizardInvalidForSave(true);
			$scope.requests.invalidWizardForSave = true;
			evaluations.evaluationRequest.targetDateChanges = false;

			$scope.requests.onSave();
			$scope.requests.refresh(true);
			evaluations.refresh(result.data, true);
		}).finally(function() {
			ModalSpinner.hide();
		});
	};

	$scope.$watch('evaluations.targetDate', function(targetDate) {
		if (!targetDate) {
			return;
		}
		angular.forEach(evaluations.gridOptions.data, function(evaluation) {
			if (!evaluation.targetDate) {
				evaluation.targetDate = new Date(targetDate);
				evaluations.evaluationRequest.targetDateChanges = true;
			}
		});
		evaluations.checkValid();
	});
	
	///////////////////////
	// click handlers
	///////////////////////

	evaluations.changeGrouping = function(grid) {
		if (grid.api.grouping.config.resource.criteria == 'evaluator') {
			grid.api.grouping.setGrouping({
				grouping: [{
					name: 'resource',
					criteria: 'resource',
					field: 'resource.completeName'
				}]
			});
			grid.options.columnDefs[0].field = 'evaluator.completeName';
		} else {
			grid.api.grouping.setGrouping({
				grouping: [{
					name: 'resource',
					criteria: 'evaluator',
					field: 'evaluator.completeName'
				}]
			});
			grid.options.columnDefs[0].field = 'resource.completeName';
		}
		grid.api.grouping.expandCollapseAll();
	};

	evaluations.addEvaluatedForEvaluation = function(grid, evaluation) {
		openResourcePicker(function(resource) {
			evaluations.removeIncompleteEvaluation(grid, 'resource', evaluation.evaluator, 'evaluator');
			createNewEvaluation(grid, evaluation.evaluator, resource);
			evaluations.checkValid();
			evaluations.checkFinishedAll();
		}, 'education.evaluationRequest.evaluated');
	};

	evaluations.addEvaluatorForEvaluation = function(grid, evaluation) {
		openResourcePicker(function(evaluator) {
			evaluations.removeIncompleteEvaluation(grid, 'evaluator', evaluation.resource, 'resource');
			createNewEvaluation(grid, evaluator, evaluation.resource);
			evaluations.checkValid();
			evaluations.checkFinishedAll();
		}, 'education.evaluationRequest.evaluator');
	};

	evaluations.addEvaluator = function(grid) {
		openResourcePicker(function(evaluator, evaluated) {
			createNewEvaluation(grid, evaluator, evaluated);
			evaluations.checkValid();
			evaluations.checkFinishedAll();
		}, 'education.evaluationRequest.evaluator', 'education.evaluationRequest.evaluated');
	};

	evaluations.addEvaluatorToAll = function(grid) {
		openResourcePicker(function(evaluator) {
			angular.forEach(grid.rows, function(row) {
				if (row.grouping) {
					evaluations.removeIncompleteEvaluation(grid, 'evaluator');
					createNewEvaluation(grid, evaluator, row.entity.resource);
				}
			});
			evaluations.checkValid();
			evaluations.checkFinishedAll();
		}, 'education.evaluationRequest.evaluator');
	};

	evaluations.addEvaluated = function(grid) {
		openResourcePicker(function(evaluated, evaluator) {
			createNewEvaluation(grid, evaluator, evaluated);
			evaluations.checkValid();
			evaluations.checkFinishedAll();
		}, 'education.evaluationRequest.evaluated', 'education.evaluationRequest.evaluator');
	};

	evaluations.addEvaluatedToAll = function(grid) {
		openResourcePicker(function(resource) {
			angular.forEach(grid.rows, function(row) {
				if (row.grouping) {
					evaluations.removeIncompleteEvaluation(grid, 'resource');
					createNewEvaluation(grid, row.entity.evaluator, resource);
				}
			});
			evaluations.checkValid();
			evaluations.checkFinishedAll();
		}, 'education.evaluationRequest.evaluated');
	};

	evaluations.removeIncompleteEvaluation = function(grid, emptyField, evaluationResource, evaluationResourceName) {
		for (var i = grid.options.data.length; i--;) {
			var data = grid.options.data[i];
			if (evaluationResource && data[evaluationResourceName] && data[evaluationResourceName].id != evaluationResource.id) {
				continue;
			}
			if (!data[emptyField]) {
				 grid.options.data.splice(i, 1);
			}
		}
	}

	function openResourcePicker(callback, label, otherLabel) {
		FormModal.open({
			scope: {
				model: {},
				resourceLbl: label,
				template: require('./resource-picker-popup.html'),
				title: 'add',
				isModelInvalid: function(model) {
					return ((!model.resource || model.resource.objectType != 'EmployeeSnapshot'));
				},
				allowOverflowContainer: true
			},
			windowClass: 'modal-absolute-content',
			actions: ['ok', 'cancel', 'close']
		}).result.then(function(model) {
			callback(model.resource, model.other);
		});
	}

	function createNewEvaluation(grid, evaluator, resource) {
		var evaluation = new Object({
			environment: "BRABO"
		});
		evaluation.evaluator = evaluator;
		evaluation.resource = resource;
		evaluation.targetDate = evaluations.targetDate ? new Date(evaluations.targetDate) : null;

		grid.options.data.push(evaluation);
		evaluations.checkValid();
		evaluations.checkFinishedAll();
	}

	// Disable remove button if the evaluation request is archived or if the evaluation is finished.
	evaluations.disableRemoveButton = function(grid, row) {
		return grid.appScope.evaluations.evaluationRequest.archiveDate || 
			(row.parentRow ? row.entity.finished : checkIfParentHasFinishedChild(row.children));
	};

	// Disable the parent remove button if it has at least one finished child.
	function checkIfParentHasFinishedChild(children) {
		for (let i = 0; i < children.length; i++) {
			if (children[i].finished) {
				return true;
			}
		}
		return false;
	}

	evaluations.removeEvaluation = function(grid, row) {
		if (!row.grouping) {
			removeEvaluation(grid, row.entity, row.parentRow.children.length);
		} else {
			angular.forEach(row.children, function(child) {
				removeEvaluation(grid, child);
			});
		}

		evaluations.checkValid();
		evaluations.checkFinishedAll();
	};

	function removeEvaluation(grid, evaluation, childrenSize) {
		let idx = -1;
		for (let i = 0; i < grid.options.data.length; i++) {
			if (evaluation === grid.options.data[i]) {
				idx = i;
			}
		}
		if (childrenSize == 1) {
			// if we remove the only child, the parent must remain with "Geen" as child
			if (grid.api.grouping.config.resource.criteria == 'evaluator') {
				createNewEvaluation(grid, evaluation.evaluator, null);
			} else {
				createNewEvaluation(grid, null, evaluation.resource);
			}
		}

		if (evaluation.id) {
			evaluations.evaluationRequest.evaluationsToRemove[evaluations.evaluationRequest.evaluationsToRemove.length] = evaluation.id;
		}
		grid.options.data.splice(idx, 1);

		evaluations.checkValid();
		evaluations.checkFinishedAll();
	}

	evaluations.isTargetDateExceeded = function() {
		if (evaluations.evaluatorsToNotify) {
			return evaluations.evaluatorsToNotify.length > 0;
		}
		return false;
	}

	evaluations.findEvaluatorsToNotify = function(evaluationsData) {
		let evaluators = [];
		let k = 0;
		let currentDate = new Date();
		// get evaluators that exceeded the target date for evaluations that have been published, but not finished
		for (let i in evaluationsData) {
			if (evaluationsData[i].evaluator) {
				if (!evaluationsData[i].finished && evaluationsData[i].published) {
					evaluators[k++] = evaluationsData[i].evaluator.id;
				}
			}
		}
		if (evaluators.length > 0) {
			evaluations.evaluatorsToNotify = new Set(evaluators);
		}
	}

	evaluations.notifyEvaluatorsForPublishedAndNotFinishedEvaluations = function() {
		if (!evaluations.evaluatorsToNotify) {
			return;
		}

		let config = {
			data: {
				evaluatorIds: Array.from(evaluations.evaluatorsToNotify)
			}
		}
		let modal = Confirm.open({
			icon: 'fa',
			title: 'education.evaluationRequest.notifyEvaluators',
			message: 'education.evaluationRequest.notifyEvaluatorsMessage',
			actions: ['ok'],
			dismissOnTimeout: 7000
		});

		Education.notifyEvaluatorsExcedeedDate({}, config)
		.then(function(result) {
		}, function(error) {
			ErrorModal.open({
				titleId: ErrorModal.SERVER_ERROR,
				message: error.data && error.data.message ? error.data.message :  $filter('translate')('education.evaluationRequest.notifyEvaluatorsFailed')
			});
		});
	}

	let smallScreen = $(document).width() < 768;
	evaluations.smallScreen = smallScreen;
	let cellWidth = smallScreen ? 50 : 100;

	// initialise grid options
	evaluations.evaluationResult = {};
	evaluations.remarks = [];
	let gridOptions = {
		enableColumnMenus: false,
		columnDefs: [{
				name: 'name',
				field: 'name',
				displayName: $filter('translate')('curriculum.realization.competention'),
				headerCellClass: 'evaluation-result-title',
				enableSorting: false,
				cellClass: function(grid, row, col) {
					let cellClass = "evaluation-result-cell-full ";
					if (row.entity.name == 'Gewogen percentage') {
						cellClass += 'weighted-percentage';
					} else {
						cellClass += 'evaluation-cell ';
					}
					return cellClass;
				},
				cellTooltip: function(row, col) {
					return row.entity.name;
				},
				width: smallScreen ? 100 : 150
			}, {
				name: 'norm',
				field: 'norm',
				displayName: smallScreen ? 'N' : $filter('translate')('curriculum.realization.norm'),
				enableSorting: false,
				headerCellClass: 'evaluation-result-title',
				cellClass: function(grid, row, col) {
					let cellClass = "evaluation-result-cell-full align-score ";
					if (row.entity.name == 'Gewogen percentage') {
						cellClass += 'weighted-percentage';
					} else {
						cellClass += 'evaluation-cell ';
					}
					return cellClass;
				},
				headerTooltip: function(col) {
					return $filter('translate')('curriculum.realization.norm');
				},
				width: cellWidth
			}, {
				name: 'weight',
				field: 'weight',
				displayName: smallScreen ? 'G' : $filter('translate')('curriculum.realization.weight'),
				enableSorting: false,
				headerCellClass: 'evaluation-result-title',
				cellClass: function(grid, row, col) {
					let cellClass = "evaluation-result-cell-full align-score ";
					if (row.entity.name == 'Gewogen percentage') {
						cellClass += 'weighted-percentage';
					} else {
						cellClass += 'evaluation-cell ';
					}
					return cellClass;
				},
				headerTooltip: function(col) {
					return $filter('translate')('curriculum.realization.weight');
				},
				width: cellWidth
			}, {
				name: 'average',
				cellTemplate: '<div class="ui-grid-cell-contents align-score">' +
								'<div ng-if="row.entity.averageItemScore != \'NaN\'"> {{row.entity.averageItemScore}}</div>' +
								'<div ng-if="row.entity.averageItemScore == \'NaN\'"> - </div></div>',
				displayName: smallScreen ? 'Gm' : $filter('translate')('curriculum.realization.average'),
				enableSorting: false,
				headerCellClass: 'evaluation-result-title',
				cellClass: function(grid, row, col) {
					let cellClass = 'evaluation-result-cell-full ';

					if (row.entity.name == 'Gewogen percentage') {
						cellClass += 'total-score-color ';
					} else {
						cellClass += 'average-cell ';
					}

					if (row.entity.averageItemScore >= row.entity.norm) {
						if (row.entity.name == 'Gewogen percentage') {
							cellClass += 'positive-total-score';
						} else {
							cellClass += 'positive-score';
						}
					} else if (row.entity.averageItemScore < row.entity.norm) {
						if (row.entity.name == 'Gewogen percentage') {
							cellClass += 'negative-total-score';
						} else {
							cellClass += 'negative-score';
						}
					}
					return cellClass;
				},
				headerTooltip: function(col) {
					return $filter('translate')('curriculum.realization.average');
				},
				width: cellWidth
			}]
		};

	evaluations.getEvaluationResult = function(entity) {
		if (!entity || !entity.realization || !entity.realization.id || !entity.finished) {// return if new realization
			return;
		}

		evaluations.evaluationResult.gridOptions = angular.copy(gridOptions);

		Education.getEvaluationOverview(null, {
			params: {
				realizationId: entity.realization.id
			}
		}).then(function(result) {
			if (result.data.evaluationItems != null && result.data.evaluationItems.length > 0) {
				let numberOfEvaluations = result.data.evaluationItems[0].scores.length;

				// pin existing columns; only if there are evaluations
				if (numberOfEvaluations > 0) {
					for (let i in evaluations.evaluationResult.gridOptions.columnDefs) {
						let column = evaluations.evaluationResult.gridOptions.columnDefs[i] ;
						column.enablePinning = true;
						column.pinnedLeft = true;			
					}
				}

				// create a new column for each evaluation
				for (let i = 0; i < numberOfEvaluations; i++) {
					let evaluator = result.data.evaluationItems[0].scores[i].resource;
					let columnDef = {
						name: 'score' + i,
						cellTemplate: '<div class="ui-grid-cell-contents align-score">' +
								'<div ng-if="row.entity.scores[col.colDef.index].score != \'NaN\'"> {{row.entity.scores[col.colDef.index].score}}</div>' +
								'<div ng-if="row.entity.scores[col.colDef.index].score == \'NaN\'"> - </div></div>',
						displayName: smallScreen ? 'S(' + (i + 1) + ')' : (evaluator ? evaluator.firstName + " " + evaluator.name : ''),
						headerCellClass: 'evaluation-result-title',
						enableSorting: false,
						cellClass: function(grid, row, col) {
							var cellClass = 'evaluation-result-cell-full ';

							if (row.entity.name == 'Gewogen percentage') {
								cellClass += 'weighted-percentage ';
							} else {
								cellClass += 'evaluation-cell ';
							}

							if (row.entity.scores[col.colDef.index].score >= row.entity.norm) {
								cellClass += 'positive-score';
							} else if (row.entity.scores[col.colDef.index].score < row.entity.norm) {
								cellClass += 'negative-score';
							}
							return cellClass;
						},
						headerTooltip: function(col) {
							var index = col.colDef.index;
							var evaluationScore = col.grid.options.data[0].scores[index];
							return evaluationScore && evaluationScore.resource ? evaluationScore.resource.firstName + " " + evaluationScore.resource.name : "";
						},
						index: i,
						width: smallScreen ? 55 : 100
					};
					evaluations.evaluationResult.gridOptions.columnDefs[evaluations.evaluationResult.gridOptions.columnDefs.length] = columnDef;
				}
				
				let title = $filter('translate')('education.evaluationRequest.evaluationResult') + ": ";
				title += result.data.employee.completeName;
				let subTitle = result.data.componentVersion.name + " v" + result.data.componentVersion.version;
				let modal = FormModal.open({
					scope: {
						template: require('./evaluation-result.html'),
						title: title,
						subTitle: subTitle,
						model: {
							gridOptions: evaluations.evaluationResult.gridOptions,
							remarks: result.data.remarks
						},
						smallScreen: evaluations.smallScreen,
						getResourceForLegend: evaluations.getResourceForLegend
					},
					actions: ['close']
				});
			}
			evaluations.evaluationResult.gridOptions.data = result.data.evaluationItems;
		});
	};

	evaluations.getResourceForLegend = function(evaluationScore, index) {
		if (!evaluationScore) {
			return;
		}
		let name = evaluationScore.resource.name;
		let firstName = evaluationScore.resource.firstName;

		return "S(" + (index + 1) + ") - " + firstName + " " + name; 
	}
}

module.exports = EvaluationsListCtrl;