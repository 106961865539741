'use strict';

/*@ngInject*/
function LoginPageCtrl($rootScope, $scope, $timeout, User, $location, $window, ModalSpinner, $translate, Common) {
	$scope.user = {};
	
	$scope.alerts = [];
	
	$scope.closeAlert = function(index) {
		$scope.alerts.splice(index, 1);
	};

	Common.isProdValue({}, {
		data: {
			date: new Date()
		}
	}).then(function(result) {
		$scope.isProd = result.data;
	});

	// used by base-proteus-js/account-administration for confirmation message
	$scope.confirmation = window.App;

	$scope.$watch('confirmation.infoMessage', function(msg) {
		if (!msg) {
			return;
		}
		$scope.alerts.push({
			type: 'success',
			msg: msg
		});
		// remove this too, so it won't show up the next time the login view is displayed
		delete window.App.infoMessage;
	});
	
	ModalSpinner.hide();
	
	// show the "expired" error message
	
	if ($location.$$search.expired) {
		$translate('login.expired').then(function(msg) {
			$scope.alerts.push({
				type: 'danger',
				msg: msg
			});
		});
	}
	
	if (User.error) {
		showError($scope, User.error);
		delete User.error;
	}
	
	checkLogin($rootScope, $location);
	
	if (!User.loginRequested && !User.logoutRequested) {
		// try auto-login
		ModalSpinner.show();
		User.login().then(function() {
			checkLogin($rootScope, $location);
		}).finally(function() {
			ModalSpinner.hide();
		});
	}
	
	User.logoutRequested = false;

	$scope.login = function(location) {
		$location.url(location);	
	}
	
	$scope.loginWithAzure = function() {
		$window.location.href = '/proteus/account/login-with-azure';
	}
};

function showError($scope, error) {
	// show login error
	var idx = -1;
	
	var dataMsg = angular.isString(error.data);
	var message = dataMsg ? error.data : error.data.message; // error.data.message when using spring
	idx = message.lastIndexOf("Exception:");

	var s = message;
	if (idx >= 0) {
		s = message.substring(idx + 10);
		s = dataMsg ? s.substring(0, s.indexOf("</pre>")) : s;
	}

	$scope.alerts.push({
		type: 'danger',
		msg: s
	});
}

function checkLogin($rootScope, $location) {
	if ($rootScope.securityContext) {
		// if user already logged in
		$location.url('/');
	}
}

module.exports = LoginPageCtrl;