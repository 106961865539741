'use strict';

/*@ngInject*/
function q($delegate) {
	
	$delegate.all = function(promises, allSettled) {
		var deferred = $delegate.defer(), 
		counter = 0, 
		results = angular.isArray(promises) ? [] : {};
		
		angular.forEach(promises, function(promise, key) {
			counter++;
			$delegate.when(promise).then(function(value) {
				if (results.hasOwnProperty(key))
					return;
				results[key] = value;
				if (!(--counter)) {
					deferred.resolve(results);
				}
			}, function(reason) {
				if (results.hasOwnProperty(key)) {
					return;
				}
				// changed here; if allSettled, keep the reason in the reasons array/map
				if (allSettled) {
					results[key] = reason;
					if (!(--counter)) {
						deferred.resolve(results);
					}
				} else {
					deferred.reject(reason);
				}
			});
		});
		
		if (counter === 0) {
			deferred.resolve(results);
		}
		
		return deferred.promise;
	};
	
	return $delegate;

}

module.exports = q;
