'use strict';

/*@ngInject*/
function onInit() {
	
	return {
		restrict: 'A',
		link: function($scope, elt, attrs) {
			window.angularInitHandler();
		}
	}
};

module.exports = onInit;