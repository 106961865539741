'use strict';

/*@ngInject*/
function BalanceTableCtrl($scope) {

	$scope.prefix = 'agenda.balance.';

	$scope.mqChanged = function(xs, visible) {
		$scope.xs = visible;
		console.log($scope.xs);
	}

}

module.exports = BalanceTableCtrl;