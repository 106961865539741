export const Color = require('color');

export enum ColorsDecimal {
    GREEN = 65280,
    ORANGE = 16753920,
    RED = 16711680,
    YELLOW = 16776960,
    BLUE = 350445,
    NULL = 0
}

export class ColorUtil {

    static getForeground(color: number): number {
        return (Color(color).grayscale().color[0] < 128 ? 0xFFFFFF : 0x000000);
    }
}