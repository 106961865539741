import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldEditorProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { createSliceFoundation, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import { CellProps } from "fixed-data-table-2";
import _ from "lodash";
import { ConstraintTabTableRenderer, CustomEditor } from "pages/groupsManagement/customFieldRenderersEditors";
import React from "react";
import { Button, Grid } from "semantic-ui-react";
import { CalendarItemEditor, RecurrenceDefinitionDto, sliceCalendarItemEditor } from "./CalendarItemEditor";
import { calendarItemDescriptor, CalendarItemTableRenderer, extendDefinitionsDescriptor } from "./customFieldRenderersEditors";

export interface CalendarDefinition {
    id?: number,
    code: string,
    description?: string,
    items: CalendarItem[],
    properties: CalendarProperty[],
    extendDefinitions: CalendarDefinition[]
}

export interface CalendarItem {
    id?: number,
    allDay?: boolean,
    description?: string,
    endDate?: string | Date,
    startDate?: string | Date,
    subject?: string,
    recurring?: boolean,
    isNew?: boolean,
    recurrenceDefinitionDto?: null | RecurrenceDefinitionDto | undefined
}

export interface CalendarProperty {
    id?: number,
    code: string,
    value: string,
    item: CalendarItem
}

export interface CalendarDefinitionForEditor {
    rowIndex: number,
    values: {
        calendarDefinition: CalendarDefinition
    }
}

export const sliceCalendarDefinition = createSliceFoundation(class SliceCalendarDefinition {

    initialState = {
        calendarDefinition: undefined as unknown as CalendarDefinition
    }

    nestedSlices = {
        calendarDefinitionExtendedTable: sliceEntityTableLight,
        calendarItemsTable: sliceEntityTableLight,
        calendarItemEditor: sliceCalendarItemEditor
    }

    reducers = {
        ...getBaseReducers<SliceCalendarDefinition>(this)
    }
})

type PropsNotFromState = {
    calendar: CalendarDefinition;
    disableAddButton?: boolean;
    defaultStartDateValue: Date | string
}

export class CalendarDefinitionEditor extends React.Component<PropsFrom<typeof sliceCalendarDefinition> & PropsNotFromState> {
    protected calendarItemsTableRef = React.createRef<EntityTableLight>();
    protected calendarDefinitionExtendedTableRef = React.createRef<EntityTableLight>();

    setExtendCalendarDefinitions(calendarDefinition: CalendarDefinition, extendDefinitions: CalendarDefinition[]) {
        this.props.dispatchers.setInReduxState({
            calendarDefinition: {
                ...calendarDefinition,
                extendDefinitions: extendDefinitions
            }
        })
        this.setCalendarDefinitionExtendTable(extendDefinitions);
        let extendCalendarItems: CalendarItem[] = [];
        for (let i = 0; i < extendDefinitions.length; i++) {
            for (let j = 0; j < extendDefinitions[i].items.length; j++) {
                extendCalendarItems.push(extendDefinitions[i].items[j]);
            }
        }
        this.setCalendarItemsTable(calendarDefinition?.items, extendCalendarItems);
    }

    setCalendarDefinitionExtendTable(calendarDefinitionExtend: CalendarDefinition[]) {
        let calendarDefinitionExtendTable: CalendarDefinition[] = [];
        if (calendarDefinitionExtend !== undefined) {
            calendarDefinitionExtendTable = [...calendarDefinitionExtend].sort((a: CalendarDefinition, b: CalendarDefinition) => a.code > b.code ? 1 : -1);
        }
        // extend calendar definitions are sorted ascending by code
        this.calendarDefinitionExtendedTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(calendarDefinitionExtendTable);
    }

    setCalendarItemsTable(ownCalendarItems: CalendarItem[], extendCalendarItems: CalendarItem[]) {
        let calendarItems: (CalendarItem & { isOwn: boolean })[] = [];
        for (let i = 0; i < ownCalendarItems?.length; i++) {
            calendarItems.push({ ...ownCalendarItems[i], isOwn: true });
        }
        for (let i = 0; i < extendCalendarItems?.length; i++) {
            calendarItems.push({ ...extendCalendarItems[i], isOwn: false });
        }
        // calendar items are sorted ascending by subject
        this.calendarItemsTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(calendarItems.sort((a: CalendarItem, b: CalendarItem) =>
            a.subject! > b.subject! ? 1 : -1
        ));
    }

    addExtendCalendarDefinition(extendCalendar: CalendarDefinition) {
        if (extendCalendar === null || extendCalendar === undefined) {
            Utils.showGlobalAlert({ message: _msg("CalendarDefinition.calendar.mandatory.label"), severity: Severity.INFO });
        }
        let calendarAlreadyExists = this.props.calendarDefinition.extendDefinitions.filter((item: CalendarDefinition) => item.id === extendCalendar.id).length > 0;
        if (!calendarAlreadyExists) {
            let extendCalendars = [...this.props.calendarDefinition.extendDefinitions];
            extendCalendars.push(extendCalendar);
            this.setExtendCalendarDefinitions(this.props.calendarDefinition, extendCalendars);
        }
    }

    getCalendarItemForm = () => {
        const selectedCalendarItem = this.calendarItemsTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[this.calendarItemsTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!];
        let currentCalendarItem: { id: number | undefined, subject: string | undefined, isOwnItem: boolean | undefined } = { id: undefined, subject: undefined, isOwnItem: undefined };
        if (this.calendarItemsTableRef.current?.getEntityFormLightRef().current?.props.s.rowIndex !== -1) {
            currentCalendarItem.isOwnItem = selectedCalendarItem.isOwn;
            currentCalendarItem.id = selectedCalendarItem.id;
            currentCalendarItem.subject = selectedCalendarItem.subject;
        }
        return <CalendarItemEditor {...this.props.calendarItemEditor} dispatchers={this.props.dispatchers.calendarItemEditor} calendar={this.props.calendarDefinition}
            currentCalendarItem={currentCalendarItem} defaultStartDateValue={this.props.defaultStartDateValue}/>
    }

    getCalendarItemEditorDescriptor() {
        const that = this;
        let descriptor = new EntityDescriptor({ name: "CustomCalendarItem" })
            .addFieldDescriptor({ name: "calendarItem", type: FieldType.string }, new class extends FieldDescriptor {
                getLabel() {
                    return "";
                }

                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    let newProps = { ...props, getCustomEditorContent: that.getCalendarItemForm };
                    return super.renderFieldEditorInternal(CustomEditor, newProps);
                }
            });
        return descriptor;
    }

    getConditionForShowingDelete = (entity: any): boolean => {
        if (entity?.isOwn) {
            return true;
        }
        return false;
    }

    validateCalendarItemBeforeAdd(calendarItem: CalendarItem, items: CalendarItem[]): boolean {
        let message = "";
        if (calendarItem.subject === null || calendarItem.subject === undefined || calendarItem.subject.trim().length === 0) {
            message = _msg("CalendarItemEditor.subject.mandatory.unique.label");
        } else {
            let calendarSubjectExists = items.filter((item: CalendarItem) => item.subject === calendarItem.subject).length > 0;
            if (calendarSubjectExists) {
                message = _msg("CalendarItemEditor.subject.mandatory.unique.label");
            }
        }
        if (calendarItem.startDate === null || calendarItem.startDate === undefined || calendarItem.endDate === null || calendarItem.endDate === undefined) {
            message = _msg("CalendarItemEditor.date.mandatory.label");
        }
        if (message !== "") {
            Utils.showGlobalAlert({ message: message, severity: Severity.INFO });
            return false;
        }
        return true;
    }

    addOrUpdateCalendarItem(calendarDefinition: CalendarDefinition, selectedItem: CalendarItem) {
        let editedItem: CalendarItem = { ...calendarDefinition.items.filter((item: CalendarItem) => item.isNew !== undefined)[0] };
        let itemIndex: number = 0;
        let items: CalendarItem[] = [...this.props.calendarDefinition.items];
        let properties: CalendarProperty[] = [...this.props.calendarItemEditor.calendarDefinition.properties];
        if (!editedItem.isNew) {
            // find the edited item and remove it from items, edit it and add it back
            itemIndex = items.findIndex((item: CalendarItem) => {
                if (item.id !== undefined) {
                    return item.id === selectedItem.id;
                } else {
                    return item.subject === selectedItem.subject;
                }
            });
            items.splice(itemIndex, 1);
            properties = this.updatePropertyItemAfterItemIsUpdated(properties, editedItem);
        }
        if (this.validateCalendarItemBeforeAdd(editedItem, items)) {
            delete editedItem.isNew;
            items.splice(itemIndex, 0, editedItem);
            this.props.dispatchers.setInReduxState({ calendarDefinition: { ...this.props.calendarDefinition, items, properties: properties } });
        }
    }

    updatePropertyItemAfterItemIsUpdated(properties: CalendarProperty[], editedItem: CalendarItem) {
        let newProperties = [...properties];
        let itemProperties = properties.filter((prop: CalendarProperty) => prop.item.subject === editedItem.subject);
        for (let i = 0; i < itemProperties.length; i++) {
            let index = newProperties.findIndex((prop: CalendarProperty) => prop.code === itemProperties[i].code && prop.item.subject === itemProperties[i].item.subject);
            newProperties.splice(index, 1);
            newProperties.push({ ...itemProperties[i], item: editedItem });
        }
        return newProperties;
    }

    removeCalendarItem(calendarIndex: number) {
        const calendar = this.calendarItemsTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[calendarIndex];
        let items = [...this.props.calendarDefinition.items].filter((item: CalendarItem) => {
            if (calendar.id !== undefined) {
                return item.id !== calendar.id;
            } else {
                return item.subject !== calendar.subject;
            }
        });
        this.props.dispatchers.setInReduxState({ calendarDefinition: { ...this.props.calendarDefinition, items } });
    }

    getCalendarCodeDescriptionForCalendarItem(calendarItem: CalendarItem & { isOwn: boolean }) {
        let code: string = "";
        let description: string | undefined = "";
        if (this.props.calendarDefinition === undefined) {
            return "";
        }
        
        if (this.calendarItemsTableRef.current?.getEntityFormLightRef().current?.props.s.rowIndex === -1 || (calendarItem !== undefined && calendarItem.isOwn === true)) {
            code = this.props.calendarDefinition.code;
            description = this.props.calendarDefinition.description;
        } else {
            // if the item is not owned by the current calendar, search the "parent" calendar in extendDefinitions
            const calendar = this.props.calendarDefinition.extendDefinitions.filter((item: CalendarDefinition) => {
                return item.items.filter((item: CalendarItem) => item.id === calendarItem?.id).length !== 0
            })[0];
            code = calendar?.code;
            description = calendar?.description;
        }
        return this.computeCodeDescriptionForCalendar(code, description);
    }

    computeCodeDescriptionForCalendar(code: string, description: string | null | undefined) {
        return code + ((description === null || description === undefined || description === "") ? "" : (" - " + description));
    }

    renderCellCustomizer = (props: CellProps) => {
        // color CalendarItems that belong to extended calendars, not to current CalendarDefinition 
        const rowObject = this.calendarItemsTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[props.rowIndex!];
        if (!rowObject.isOwn) {
            props.style = { 'color': '#ff6734' };
        }
    }

    render() {
        const selectedCalendarItem = this.calendarItemsTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[this.calendarItemsTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!];
        fieldRenderers["CalendarItem"] = CalendarItemTableRenderer;
        fieldRenderers["CalendarDefinition"] = ConstraintTabTableRenderer;
        return (
            <>
                <Grid.Column className="GroupSnapshotTab_contextPropertiesTables">
                    <EntityTableLight {...this.props.calendarDefinitionExtendedTable} dispatchers={this.props.dispatchers.calendarDefinitionExtendedTable} ref={this.calendarDefinitionExtendedTableRef}
                        entityDescriptor={extendDefinitionsDescriptor}
                        actions={{ disableAddButton: this.props.disableAddButton, showDeleteButton: false, showEditButton: false, doNotAddEntityToTable: true }}
                        onSave={(extendCalendars: CalendarDefinition[], addedExtendCalendar: CalendarDefinitionForEditor) => {
                            this.addExtendCalendarDefinition(addedExtendCalendar.values.calendarDefinition);
                        }}
                        onDelete={(remainingExtendCalendars: CalendarDefinition[], removedEntityIndex: number) => {
                            const extendedCalendar = [...this.props.calendarDefinition.extendDefinitions];
                            extendedCalendar.splice(removedEntityIndex, 1);
                            this.setExtendCalendarDefinitions(this.props.calendarDefinition, extendedCalendar);
                        }}
                        formCustomizer={{
                            headerContent: _msg("ExtendDefinitions.addCalendarDefinition.label", this.computeCodeDescriptionForCalendar(this.props.calendarDefinition?.code, this.props.calendarDefinition?.description)),
                            headerIcon: "calendar alternate outline"
                        }}
                    />
                </Grid.Column>
                <Grid.Column className="GroupSnapshotTab_contextPropertiesTables">
                    <EntityTableLight {...this.props.calendarItemsTable} ref={this.calendarItemsTableRef} dispatchers={this.props.dispatchers.calendarItemsTable}
                        actions={{
                            disableAddButton: this.props.disableAddButton,
                            doNotAddEntityToTable: true,
                            showDeleteButton: false,
                            saveFormDisabled: this.calendarItemsTableRef.current?.getEntityFormLightRef().current?.props.s.rowIndex !== -1 && selectedCalendarItem?.isOwn === false
                        }}
                        entityDescriptor={calendarItemDescriptor} getConditionForShowingDelete={this.getConditionForShowingDelete} renderCellCustomizer={this.renderCellCustomizer}
                        formCustomizer={{
                            headerContent: _msg("CalendarItems.addCalendarItem.label", this.getCalendarCodeDescriptionForCalendarItem(selectedCalendarItem)),
                            customEntityDescriptorForEditor: this.getCalendarItemEditorDescriptor(),
                            headerIcon: "calendar alternate outline"
                        }}
                        onSave={(items: CalendarItem[], addedItem: { rowIndex: number }) => {
                            this.addOrUpdateCalendarItem(this.props.calendarItemEditor.calendarDefinition, selectedCalendarItem);
                        }}
                        onDelete={(remainingItems: CalendarItem[], deletedCalendarIndex: number) => {
                            this.removeCalendarItem(deletedCalendarIndex);
                        }}
                    />
                </Grid.Column>
            </>
        );
    }

    clearTablesContent() {
        this.setCalendarDefinitionExtendTable([]);
        this.setCalendarItemsTable([], []);
    }

    calendarDefinitionChanged() {
        this.props.dispatchers.setInReduxState({ calendarDefinition: this.props.calendar });
        if (this.props.calendar === undefined || Object.keys(this.props.calendar).length === 0) {
            this.clearTablesContent();
        } else {
            this.setExtendCalendarDefinitions(this.props.calendar, this.props.calendar.extendDefinitions);
        }
    }

    componentDidMount() {
        this.calendarDefinitionChanged();
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceCalendarDefinition> & PropsNotFromState) {
        if (!_.isEqual(this.props.calendar, prevProps.calendar)) {
            this.calendarDefinitionChanged();
        }
    }

    componentWillUnmount() {
        this.clearTablesContent();
    }
}