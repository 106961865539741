'use strict';

function textarea() {
	
	return {
		require: 'ngModel', // Array = multiple requires, ? = optional, ^ = check parent elements
		restrict: 'E', // E = Element, A = Attribute, C = Class, M = Comment
		
		link: function($scope, $elt, $attrs, ngModelCtrl) {
			if ('autogrow' in $attrs) {
				//angular will trim by default, unless you add the ng-trim attribute
				$attrs.ngTrim = 'false';

				// whenever the text changes, the component will change its scroll height
				// we resize the component to its scroll height, to show all the text
				$elt.scroll(function() {
					$elt.height($elt.prop('scrollHeight'));
				});

				// wait for the component to be displayed to set its initial height
				window.requestAnimationFrame(function() {
					$elt.scroll();
				});
			}
		}
	};
}

module.exports = textarea;
