'use strict';

angular.module('proteus.credit', ['ui.grid', 'proteus.tasks'])

.run(function($templateCache) {
	$templateCache.put('credit-page.html', require('./credit-page.html'));
})

.factory('Balance',                   require('./balance.service'))

.controller('CreditPageCtrl', 		  require('./credit-page.controller'))

.directive('balanceCountersTable',   require('./balance-counters-editor/balance-counters-table.directive'))
.directive('balanceCounterEditor',   require('./balance-counters-editor/balance-counter-editor.directive'));

module.exports = 'proteus.credit';