import { apolloClient, ApolloContext, apolloGlobalErrorHandler, CatchedGraphQLError, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, EntityTablePage, EntityTablePageProps, ENT_DELETE, getBaseImpures, getBaseReducers, GraphQLErrorExtensions, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, sliceEntityTablePageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import gql from "graphql-tag";
import lodash from "lodash";
import _ from "lodash";
import moment from "moment";


export class SalaryPeriodEntityDescriptor extends EntityDescriptor {

    getGraphQlFieldsToRequest() {
        return "id objectVersion modificationDate modificationUser creationDate creationUser validFrom validUntil description simulation";
    }

    protected customize() {

        const salaryPeriodEntityDescriptor = this;

        const sliceSalaryPeriodEditor = salaryPeriodEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceSalaryPeriodEditor extends SliceEntityEditorPage {

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
            }

            nestedSlices = {
                ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
                entityTableLight: sliceEntityTableLight
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceSalaryPeriodEditor>(this),
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceSalaryPeriodEditor>(this),
            }

        }).setEntityDescriptor(salaryPeriodEntityDescriptor);

        salaryPeriodEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceSalaryPeriodEditor> & EntityEditorPageProps> {
            protected async onSave() {
                await this.onApply();
                if (moment(this.props.entity.validFrom).toDate().getTime() > moment(this.props.entity.validUntil).toDate().getTime()) {
                    Utils.showGlobalAlert({ message: _msg("SalaryPeriod.validation.label"), severity: Severity.ERROR });
                    return;
                }
                await super.onSave();
            }
        }

        salaryPeriodEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<PropsFrom<typeof sliceEntityTablePageOnlyForExtension> & EntityTablePageProps> {
            async deleteEntity(entityDescriptorName: string, id: number, location: any) {
                const { entityDescriptor } = this.props.dispatchers.getSlice();
                const permission = Utils.pipeJoin([ENT_DELETE, entityDescriptor.name]);
                if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission, true)) {
                    return;
                }
                const removeOperationName = `${lodash.lowerFirst(entityDescriptorName)}Service_deleteById`;
                const removeMutation = gql(`mutation deleteEntity($id: ${this.props.dispatchers.getSlice().getGraphQlIdType()}){${removeOperationName}(id: $id)}`);
                await apolloClient.mutate({
                    mutation: removeMutation, variables: { id: id }, context: {
                        [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                            apolloGlobalErrorHandler(e);
                            return true;
                        }
                    }
                })
        
                this.refresh();
            }
        }
    }
}