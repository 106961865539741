import { Utils } from '@crispico/foundation-react';
import moment from 'moment';
import React from 'react';
import { Accordion, Grid, Icon, Label } from 'semantic-ui-react';
import { Constraints, DataForEmployee } from './AgendaTypes';
import { EmployeeInfo, ResourcesInfo } from './ResourcesInfo';

export interface WorkperiodInfoProps {
    data: DataForEmployee;
    employees: EmployeeInfo[],
    getEmployees: (employeesIds: number[], fromDate: Date, toDate: Date) => void,
    onClose: () => void
}

export class WorkperiodInfo extends React.Component<WorkperiodInfoProps> {

    componentWillUnmount(): void {
        this.props.onClose();
    }

    renderEmptyEmployee() {
        return (
            <div className='WorkperiodInfo_noEmployee'>
                <Icon className='WorkperiodInfo_noEmployeeIcon' name='close' floated='left' />
                {_msg('WorkperiodInfo.noEmployee.label')}
            </div>
        );
    }

    renderEmployeeGrid(employees: EmployeeInfo[]) {
        return <ResourcesInfo resources={employees} embedded />
    }

    renderConstraintContent(constraint: Constraints, active: any) {
        const content = [];
        if (active && constraint.available != 0) {
            const limit = constraint.used - constraint.available;
            content.push(this.renderContent(constraint.employeesWithLeaveIds, limit, constraint.used, constraint.available, _msg('WorkperiodInfo.notAvailable.label')));
        }
        if (active && constraint.minimum != 0) {
            const limit = constraint.minimum - constraint.minimumAvailable;
            content.push(this.renderContent(constraint.availableEmployeesIds, limit, constraint.minimumAvailable, constraint.minimum, _msg('WorkperiodInfo.available.label')));
        }
        return (
            <Accordion.Content active={active}>
                {content}
            </Accordion.Content>
        );
    }

    renderContent(employeeIds: any, limit: number, current: number, total: number, message: string) {
        const employees = this.props.employees.filter(e => employeeIds.includes(e.id!));
        let className = this.getTitleLabelClassName(limit);
        return (<div className='WorkperiodInfo_content'>
            <span className='WorkperiodInfo_title'>{message} <Label className={className} horizontal>{`${current}/${total}`}</Label></span>
            {employees.length > 0 ? this.renderEmployeeGrid(employees) : this.renderEmptyEmployee()}
        </div>);
    }

    getTitleLabelClassName(limit: number) {
        let className = 'WorkperiodInfo_titleLabel ';
            if (limit < 0) {
                return className + 'WorkperiodInfo_free';
            } else if (limit === 0) {
                return className + 'WorkperiodInfo_full';
            } else {
                return className + 'WorkperiodInfo_overrun';
            }
    }

    getConstraintPanelTitle(constraint: Constraints) {
        const title = constraint.groupName + ' - ' + constraint.constraintName;
        const labels = [];
        let employeeIds: number[] = [];
        if (constraint.available != 0) {
            employeeIds = employeeIds.concat(constraint.employeesWithLeaveIds);
            let className = this.getTitleLabelClassName(constraint.used - constraint.available);
            labels.push(<Label className={className} horizontal>{`MAX: ${constraint.used}/${constraint.available}`}</Label>)
        }
        if (constraint.minimum != 0) {
            employeeIds = employeeIds.concat(constraint.availableEmployeesIds);
            let className = this.getTitleLabelClassName(constraint.minimum - constraint.minimumAvailable);
            labels.push(<Label className={className} horizontal>{`MIN: ${constraint.minimumAvailable}/${constraint.minimum}`}</Label>)
        }
        const loadedEmployees = this.props.employees.map(e => e.id!);
        employeeIds = employeeIds.filter(id => !loadedEmployees.includes(id));
        return {
            content: <span className='WorkperiodInfo_title'>{title} {labels}</span>,
            icon: "angle down",
            onClick: () => employeeIds.length > 0 && this.props.getEmployees(employeeIds, new Date(this.props.data.start), new Date(this.props.data.end))
        }
    }

    getWorkperiodModalPanels() {
        const data = this.props.data;
        return data.constraints.map(constraint => {
            return { key: constraint.groupName, title: this.getConstraintPanelTitle(constraint), content: (i: any, active: any) => this.renderConstraintContent(constraint, active.active) }
        });
    }

    render() {
        const data = this.props.data;
        const startDate = data.spare ? moment(data.start).startOf('day') : moment(data.start);
        const endDate = data.spare ? moment(data.end).endOf('day') : moment(data.end);
        return (
            <>
                <Grid className='WorkperiodInfo_grid' columns={2}>
                    <Grid.Column>
                        <Grid.Row>
                            <Icon name='calendar outline' />
                            {startDate.format(Utils.dateFormat)}
                        </Grid.Row>
                        {(data.ship || data.spare) && <Grid.Row>
                            {data.ship && <><Icon name='ship'/>
                               {_msg("WorkperiodInfo.ship.label") + " " + data.ship}
                            </>}
                            {data.spare && <><Icon name='sync'/>
                               {_msg("WorkperiodInfo.spare.label") + " " + data.spare}
                            </>} 
                        </Grid.Row>}
                        {data.status && <Grid.Row>
                            <Icon name='file outline' />
                            {_msg("status.label") + " " + data.status}
                        </Grid.Row>}
                    </Grid.Column>
                    <Grid.Column>
                        <Grid.Row>
                            <Icon name='clock outline' />
                            {startDate.format(Utils.timeFormat)} - {endDate.format(Utils.timeFormat)}
                        </Grid.Row>
                        {data.category && <Grid.Row>
                            <Icon name='clipboard' />
                            {_msg("WorkperiodInfo.category.label") + " " + data.category}
                        </Grid.Row>}
                    </Grid.Column>
                </Grid>
                {data.constraints && <Accordion className='WorkperiodInfo_constraints' fluid panels={this.getWorkperiodModalPanels()} styled></Accordion>}
            </>
        );
    }
}
