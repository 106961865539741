'use strict';

var css = require('./competences.css');

/*@ngInject*/
function CompetencesCtrl($rootScope, Education, Confirm, Collection, FormModal, ModalSpinner) {
	var competences = this;
	
	competences.editable = true;

	competences.editorIsDisabled = false;
	competences.saveIsDisabled = true;

	competences.disableSave = function(disabled) {
		competences.saveIsDisabled = disabled;
	}
	
	competences.select = function(scope) {
		scope.masterDetail.goToDetail();
		competences.category = scope.category;
		competences.createNewMajorVersion = false;
		competences.disableSave(true);
	};

	competences.isSaveDisabled = function () {
		return competences.editorIsDisabled || competences.saveIsDisabled;
	}
	
	competences.save = function() {
		ModalSpinner.show();
		Education.saveCompetence(null, {
			data: {
				competence: competences.competence,
				createNewMajorVersion: competences.createNewMajorVersion,
				addedComponents: competences.addedComponents,
				removedComponents: competences.removedComponents
			},
			params: {
				id: competences.competence.id,
			}
		}).then(function(result) {
			var competence = result.data;
			Collection.create(competences.category.competences).replace(competence);
			competences.selectElt(competence);
			showVersions(competences.selection, competence);
			competences.createNewMajorVersion = false;
			competences.disableSave(true);

			competences.addedComponents = [];
			competences.removedComponents = [];
		}).finally(function() {
			ModalSpinner.hide();
		});
	}
	
	competences.unselect = function() {
		if (competences.selection) {
			competences.selection.masterDetail.goToMaster();
		}
		delete competences.selection;
		delete competences.competence;
	};

	competences.actions = {
		categories: [
		{
			index: 10,
			icon: 'fa fa-plus fa-fw',
			label: 'education.competences.category.add.category',
			run: addCategory
		},
		{
			index: 20,
			icon: 'fa fa-plus fa-fw',
			label: 'education.competences.category.add.item',
			run: addItem
		},
		{
			index: 30,
			icon: 'fa fa-pencil fa-fw',
			label: 'edit',
			run: editCategory
		},
		{
			index: 40,
			icon: 'fa fa-trash fa-fw',
			label: 'clear',
			run: removeCategory
		}],
		versions: {
			index: 10,
			icon: 'fa fa-trash fa-fw',
			run: removeCompetenceVersion
		},		
		competences: {
			index: 10,
			icon: 'fa fa-trash fa-fw',
			run: removeCompetence 	
			} 	
		};

	function showVersions(scope, competence) {
		competence = competence || scope.competence;
		ModalSpinner.show('education.catalogs.actions.versions.loading');
		// after a delete, don't clear the versions; this way we avoid flickering!
		var versions = scope.versions = scope.versions || {};
		Education.getCompetenceVersions(versions, {
			params: {
				id: competence.id
			}
		}).finally(function() {
			ModalSpinner.hide();
		});
	}

	function hideVersions(scope) {
		delete scope.$parent.versions;
	}

	competences.showHideVersions = function(scope) {
		scope.collapsed ? hideVersions(scope) : showVersions(scope);
	};

	function editCategory(scope) {
		openCategoryForm(scope.category);
	}

	function addCategory(scope) {
		var child = {
			name: '',
			description: '',
			catalog: competences.dictionaries.selection,
			parentCategory: scope.category,
			objectType: 'CompetenceCategory'
		};
		openCategoryForm(child);
	}

	function addItem(scope) {
		var category = scope.category;
		var competence = {
			category: category,
			objectType: 'Competence',
			currentCompetenceVersion: {
				name: '',
				description: '',
				attachments: [],
				levelDefinitions: []
			}
		};
		
		delete competences.selection;
		competences.select({
			masterDetail: scope.masterDetail,
			category: category,
			competence: competence
		});
	}

	function removeCategory(scope) {
		Confirm.open({
			title: 'clear',
			message: 'education.competences.category.remove.confirm'
		}).result.then(function() {
			ModalSpinner.show('education.competences.category.removing');
			Education.removeCompetenceCategory(null, {
				params: {
					id: scope.category.id
				}
			}).then(function() {
				competences.refresh();
			}, function(error) {
				// only if error, otherwise keep the spinner while the competences tree refreshes
				ModalSpinner.hide();
			});
		});
	}

	function removeCompetence(scope) {
		Confirm.open({
			title: 'clear',
			message: 'education.competences.remove.confirm'
		}).result.then(function() {
			ModalSpinner.show('education.competences.remove.progress');
			
			Education.removeCompetence(null, {
				params: {
					id: scope.competence.id 
				}
			}).then(function(result) {
				refreshElement(scope);
			}).finally(function() {
				ModalSpinner.hide();
			});
			
		});
	}
	
	function removeCompetenceVersion(scope) {
		Confirm.open({
			title: 'clear',
			message: 'education.competences.version.remove.confirm'
		}).result.then(function() {
			ModalSpinner.show('education.competences.version.remove.progress');
			Education.removeCompetenceVersion(null, {
				params: {
					id: scope.version.id
				}
			}).then(function(result) {
				refreshElement(scope);
				showVersions(scope.$parent);
			}).finally(function() {
				ModalSpinner.hide();
			});
		});
	}

	function openCategoryForm(category, add) {
		category = angular.copy(category);
		FormModal.open({
			scope: {
				template: require('./competence-category-form.html'),
				title: add ? 'add' : 'edit',
				model: category,
				parentCategory: function(value) {
					if (arguments.length) {
						if (!value) {
							category.parentCategory = null;
						}
					} else {
						return category.parentCategory ? category.parentCategory.name : '';
					}
				},
				competences: {
					data: competences.data,
					category: category
				},
				allowOverflowContainer: true
			},
			actions: ['ok', 'cancel', 'close']
		}).result.then(function(result) {
			ModalSpinner.show('education.competences.category.saving');
			Education.saveCompetenceCategory(null, {
				data: result,
				params: {
					id: result.id
				}
			}).then(function() {
				competences.refresh();
			}, function(error) {
				// only if error, otherwise keep the spinner while the competencies tree refreshes
				ModalSpinner.hide();
			});
		});
	}

	function refreshElement(scope) {
		Education.getCompetence(null, {
			params: {
				id: scope.competence.id
			}
		}).then(function(result) {
			var competence = result.data,
				category = scope.category,
				list = Collection.create(category.competences);
			if (competence) {
				list.replace(competence);
				competences.selectElt(competence);
			} else {
				// the competence does not exist anymore, probably the last version was removed
				list.remove(scope.competence);
				competences.unselect();
			}
		});
	}
}

module.exports = CompetencesCtrl;