import { apolloClient, ApolloContext, apolloGlobalErrorHandler, CatchedGraphQLError } from "@crispico/foundation-react/apolloClient";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityEditorFormSimple } from "@crispico/foundation-react/entity_crud/EntityEditorFormSimple";
import { EntityTableSimpleRRC, EntityTableSimpleProps, EntityTableSimple } from "@crispico/foundation-react/entity_crud/EntityTableSimple";
import { FieldEditorProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { getCompositionGroup_compositionGroupService_compositionGroup_groups, getCompositionGroup_compositionGroupService_compositionGroup_groups_properties } from "apollo-gen/getCompositionGroup";
import { getGroupEmployeesByIds_employeeService_employees } from "apollo-gen/getGroupEmployeesByIds";
import { getGroupEmployeesBySql_employeeService_groupEmployees } from "apollo-gen/getGroupEmployeesBySql";
import { getGroupMembers_employeeService_employeeSnapshotsByGroup } from "apollo-gen/getGroupMembers";
import { getGroupPropertiesConstants_groupPropertyCtService_groupPropertiesCt } from "apollo-gen/getGroupPropertiesConstants";
import { getGroupSnapshots_groupServiceFacadeBean_groupSnapshots_contexts } from "apollo-gen/getGroupSnapshots";
import { getStaticGroup_staticGroupService_staticGroup_groupResourcesHistory } from "apollo-gen/getStaticGroup";
import { HistoryTable, sliceHistoryTable } from "components/HistoryTable";
import { EMPLOYEE_SERVICE_GET_EMPLOYEES, EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_GROUP, EMPLOYEE_SERVICE_GET_GROUP_EMPLOYEES_BY_SQL, GROUP_PROPERTY_CT_SERVICE_GET_GROUP_PROPERTIES_CT } from "graphql/queries";
import _, { uniqueId } from "lodash";
import moment from "moment";
import { ProteusConstants } from "ProteusConstants";
import React from "react";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { compositionGroupsDescriptor, groupContextsDescriptor, groupEmployeesDescriptor, groupResourcesDescriptor, groupResourcesHistoryDescriptor, GroupSnapshotTabTableRenderer } from "./customFieldRenderersEditors";
import { GroupOrContextEditor, sliceGroupOrContextEditor } from "./GroupOrContextEditor";
import { GROUP_CONTEXT, GROUP_SNAPSHOT, HistoryItem, RESOURCES } from "./GroupsManagement";
import { sliceGroupsManagementBase } from "./sliceGroupsManagementBase";
import { ResourceTable } from "./SuccessionTab";

export const CUSTOM_VALUE = "customValueByFieldType";
export const GROUP_PROPERTY_CT = "groupPropertyCt"
export const EMPLOYEE = "employee";
export const GROUP = "group";

// descriptors name
export const GROUP_PROPERTIES = "GroupProperties";
export const GROUP_PROPERTY = "GroupPropertyCt";
export const GROUP_CONTEXTS = "GroupContexts";
export const GROUP_RESOURCES = "GroupResources";
export const EMPLOYEE_SNAPSHOT = "EmployeeSnapshot";
export const CUSTOM_STRING_FIELD = "CustomStringField";

export interface GroupPropertyEditor {
    rowIndex: number,
    values: {
        [CUSTOM_VALUE]: string,
        [GROUP_PROPERTY_CT]: getCompositionGroup_compositionGroupService_compositionGroup_groups_properties
    }
}

export interface ResourcesHistoryItem extends HistoryItem {
    resources: number[]
}

export interface BasicFields {
    id: number,
    name: string
}

export const sliceGroupSnapshotTab = createSliceFoundation(class SliceGroupSnapshotTab {

    initialState = {
        groupPropertiesConstants: undefined as unknown as getGroupPropertiesConstants_groupPropertyCtService_groupPropertiesCt[],
        sqlQueryDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0) as Date,
        membersQueryDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0) as Date,
        sqlQueryInfos: undefined as unknown as { employeesNumber: number, queryDuration: number },
        membersQueryInfos: undefined as unknown as { employeesNumber: number, queryDuration: number },
        sideBarIsVisible: false as boolean,
        employeeSnapshotsOptions: [] as { key: number, text: string, value: number }[],
        selectedGroupResourcesHistory: undefined as number | undefined
    }

    nestedSlices = {
        groupEditor: sliceGroupOrContextEditor,
        propertiesTable: sliceEntityTableLight,
        contextsTable: sliceEntityTableLight,
        groupsManagementBase: sliceGroupsManagementBase,
        compositionGroupsTable: sliceEntityTableLight,
        groupResourcesHistoryTable: sliceHistoryTable,
        groupResourcesTable: sliceEntityTableLight
    }

    reducers = {
        ...getBaseReducers<SliceGroupSnapshotTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceGroupSnapshotTab>(this),

        async getGroupPropertiesConstants() {
            const groupPropertiesConstants = (await apolloClient.query({
                query: GROUP_PROPERTY_CT_SERVICE_GET_GROUP_PROPERTIES_CT
            })).data.groupPropertyCtService_groupPropertiesCt;
            this.getDispatchers().setInReduxState({ groupPropertiesConstants });
        }
    }
})

type PropsNotFromState = {
    selectedGroupAndContext: { selectedGroup: any, selectedContext: any };
    initialEmployeesOptions: { key: number, text: string, value: number }[];
    renderOnChangeForGroupOrContext: (fieldName: string, value: string | number | undefined) => void;
    addContextToCurrentGroup: (contextToBeAdded: BasicFields) => void;
    deleteContextFromCurrentGroup: (contextToBeDeleted: BasicFields) => void;
    addOrUpdateGroupProperty: (editedProperty: GroupPropertyEditor) => void;
    deleteGroupProperty: (propertyToBeDeleted: getCompositionGroup_compositionGroupService_compositionGroup_groups_properties) => void;
    addGroupsToCompositionGroup: (groupToBeAdded: BasicFields) => void;
    deleteGroupFromCompositionGroup: (groupToBeDeleted: { id: number, group: string }) => void;
    deleteResourceHistory: (remainingResourcesHistory: ResourcesHistoryItem[]) => void;
    addOrUpdateResourcesHistory: (resourcesHistory: ResourcesHistoryItem[]) => void;
    deleteResourceFromResourceHistoryItem: (resourceHistoryItem: ResourcesHistoryItem, resource: ResourceTable) => void;
    addResourceToResourceHistoryItem: (resources: ResourcesHistoryItem, addedResource: ResourceTable) => void;
}

export class GroupSnapshotTab extends React.Component<PropsFrom<typeof sliceGroupSnapshotTab> & PropsNotFromState> {
    refFormSimple = React.createRef<EntityEditorFormSimple>();

    protected propertiesTableRef = React.createRef<EntityTableLight>();
    protected contextsTableRef = React.createRef<EntityTableLight>();
    protected compositionGroupsTableRef = React.createRef<EntityTableLight>();
    protected groupResourcesTableRef = React.createRef<EntityTableLight>();
    protected groupResourcesHistoryTableRef = React.createRef<HistoryTable>();

    protected groupEmployeesTableRef = React.createRef<EntityTableSimple<EntityTableSimpleProps>>();
    protected groupMembersTableRef = React.createRef<EntityTableSimple<EntityTableSimpleProps>>();

    setContextsTable(contexts: getGroupSnapshots_groupServiceFacadeBean_groupSnapshots_contexts[]) {
        let contextsForTable = [];
        for (let i = 0; i < contexts.length; i++) {
            contextsForTable.push({ context: { name: contexts[i].name, id: contexts[i].id } });
        }
        this.contextsTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(contextsForTable.sort((a: any, b: any) => a.context.name > b.context.name ? 1 : -1));
    }

    setPropertiesTable(properties: getCompositionGroup_compositionGroupService_compositionGroup_groups_properties[]) {
        let propertiesForTable = [];
        for (let i = 0; i < properties.length; i++) {
            // the id is needed because if a property is added (not saved) and then edited, it does not have an id and the form will not show the property correctly in dropdown
            // this id is added only for this purpose, it is not sent to server
            let property = { ...properties[i], id: properties[i].id !== undefined ? properties[i].id : this.props.groupPropertiesConstants.filter((item: any) => item.code === properties[i].code)[0].id };
            propertiesForTable.push({
                [GROUP_PROPERTY_CT]: property,
                [CUSTOM_VALUE]: (property.value?.toLowerCase().trim() === "true" ? true : property.value?.toLowerCase().trim() === "false" ? false : property.value)
            });
        }
        this.propertiesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(propertiesForTable.sort((a: any, b: any) => a[GROUP_PROPERTY_CT].code > b[GROUP_PROPERTY_CT].code ? 1 : -1));
    }

    // groupEmployees are the employees received by running the sql
    setGroupEmployeesTable(employees: getGroupEmployeesBySql_employeeService_groupEmployees[]) {
        let employeesForTable = [];
        for (let i = 0; i < employees.length; i++) {
            employeesForTable.push({ [EMPLOYEE]: employees[i].name + " " + employees[i].firstName });
        }
        this.groupEmployeesTableRef.current?.setEntities(employeesForTable.sort((a: any, b: any) => a[EMPLOYEE] > b[EMPLOYEE] ? 1 : -1));
    }

    setCompositionGroupsTable(groups: getCompositionGroup_compositionGroupService_compositionGroup_groups[]) {
        let groupsForTable = [];
        for (let i = 0; i < groups.length; i++) {
            groupsForTable.push({ [GROUP]: groups[i].name, id: groups[i].id });
        }
        this.compositionGroupsTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(groupsForTable.sort((a: any, b: any) => a[GROUP] > b[GROUP] ? 1 : -1));
    }

    // groupMembers are the employees received from the "get members" sidebar
    setGroupMembersTable(groupMembers: getGroupMembers_employeeService_employeeSnapshotsByGroup[]) {
        let groupMembersForTable = [];
        for (let i = 0; i < groupMembers.length; i++) {
            groupMembersForTable.push({ [EMPLOYEE]: groupMembers[i].name + " " + groupMembers[i].firstName });
        }
        this.groupMembersTableRef.current?.setEntities(groupMembersForTable.sort((a: any, b: any) => a[EMPLOYEE] > b[EMPLOYEE] ? 1 : -1));
    }

    async setGroupResourcesHistoryTable(groupResourcesHistory: getStaticGroup_staticGroupService_staticGroup_groupResourcesHistory[]) {
        let selectedId = this.groupResourcesHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!;
        const oldEntities = this.groupResourcesHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities();
        const newEntities = groupResourcesHistory.sort((a: any, b: any) => moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf());
        this.groupResourcesHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(newEntities);
        if (!_.isEqual(oldEntities, newEntities)) {
            await this.onGroupResourcesHistoryTableChange(newEntities[selectedId]?.resources, selectedId);
        }
    }

    setGroupResourcesTable(groupResources: getGroupEmployeesByIds_employeeService_employees[], itemId?: any) {
        let groupResourcesForTable: ResourceTable[] = [];
        if (itemId !== undefined) {
            for (let i = 0; i < groupResources.length; i++) {
                groupResourcesForTable.push({
                    [EMPLOYEE]: {
                        name: groupResources[i].name,
                        firstName: groupResources[i].firstName,
                        id: groupResources[i].id,
                        employeeNumber: groupResources[i].detail?.contractHistory?.[0]?.employeeNumber
                    }
                });
            }
        }
        this.groupResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(groupResourcesForTable.sort((a: any, b: any) => a[EMPLOYEE].name > b[EMPLOYEE].name ? 1 : -1));
    }

    async getGroupEmployeesBySql(sql: string) {
        const sqlStartTime = new Date().getTime();
        const groupEmployees = (await apolloClient.query({
            query: EMPLOYEE_SERVICE_GET_GROUP_EMPLOYEES_BY_SQL,
            variables: {
                fromDate: this.props.sqlQueryDate,
                toDate: this.props.sqlQueryDate,
                sql: sql
            },
            context: {
                [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                    apolloGlobalErrorHandler(e);
                }
            },
        })).data.employeeService_groupEmployees;
        this.props.dispatchers.setInReduxState({ sqlQueryInfos: { employeesNumber: groupEmployees.length, queryDuration: new Date().getTime() - sqlStartTime } });
        this.setGroupEmployeesTable(groupEmployees);
    }

    async getGroupMembers(groupCode: string) {
        const sqlStartTime = new Date().getTime();
        const groupMembers = (await apolloClient.query({
            query: EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_GROUP,
            variables: {
                fromDate: this.props.membersQueryDate,
                untilDate: this.props.membersQueryDate,
                groupCode: groupCode
            }
        })).data.employeeService_employeeSnapshotsByGroup;
        this.props.dispatchers.setInReduxState({ membersQueryInfos: { employeesNumber: groupMembers.length, queryDuration: new Date().getTime() - sqlStartTime } });
        this.setGroupMembersTable(groupMembers);
    }

    async getGroupResourcesEmployees(employeesIds: number[], itemId: any) {
        const employees = (await apolloClient.query({
            query: EMPLOYEE_SERVICE_GET_EMPLOYEES,
            variables: {
                employeesIds: employeesIds
            }
        })).data.employeeService_employees;
        this.setGroupResourcesTable(employees, itemId);
    }

    getGroupPropertyDescriptorForEditor() {
        const entityDescriptor = this.propertiesTableRef.current?.props.formCustomizer?.customEntityDescriptorForEditor;
        if (entityDescriptor) {
            return entityDescriptor;
        }
        let that = this;
        let groupPropertiesDescriptor = new EntityDescriptor({ name: GROUP_PROPERTIES })
            .addFieldDescriptor({ name: GROUP_PROPERTY_CT, type: GROUP_PROPERTY }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    let newProps = {
                        ...props, onChange: (data: any) => {
                            that.addCustomValueFieldType(groupPropertiesDescriptor, data);
                            props.formikProps?.setFieldValue(CUSTOM_VALUE, that.getDefaultValueForValueField(data));
                        }
                    };
                    if (!props.formikProps?.values[GROUP_PROPERTY_CT]) {
                        that.addCustomValueFieldType(groupPropertiesDescriptor, undefined);
                    }
                    return React.createElement(EditorClass as any, newProps as FieldEditorProps);
                }
            }())
        return groupPropertiesDescriptor;
    }

    getGroupPropertyDescriptorForTable() {
        return new EntityDescriptor({ name: GROUP_PROPERTIES })
            .addFieldDescriptor({ name: GROUP_PROPERTY_CT, type: GROUP_PROPERTY })
            .addFieldDescriptor({ name: CUSTOM_VALUE, type: CUSTOM_STRING_FIELD })
    }

    onItemCustomEdit(entity: any, rowIndex: number) {
        const entityDescriptor = this.propertiesTableRef.current?.props.formCustomizer?.customEntityDescriptorForEditor;
        entityDescriptor && this.addCustomValueFieldType(entityDescriptor, entity[GROUP_PROPERTY_CT]);
        this.propertiesTableRef.current?.getEntityFormLightRef().current?.open(entity, rowIndex);
    }

    addCustomValueFieldType(groupPropertiesDescriptor: EntityDescriptor, groupProperty?: getCompositionGroup_compositionGroupService_compositionGroup_groups_properties) {
        let groupPropertyCt = this.props.groupPropertiesConstants?.filter((item: any) => item.code === groupProperty?.code)[0];
        if (groupPropertyCt?.dataType === ProteusConstants.BOOLEAN) {
            groupPropertiesDescriptor.addFieldDescriptor({ name: CUSTOM_VALUE, type: FieldType.boolean });
        } else if (groupPropertyCt?.dataType === ProteusConstants.INTEGER) {
            groupPropertiesDescriptor.addFieldDescriptor({ name: CUSTOM_VALUE, type: FieldType.number });
        } else {
            groupPropertiesDescriptor.addFieldDescriptor({ name: CUSTOM_VALUE, type: CUSTOM_STRING_FIELD });
        }
    }

    getDefaultValueForValueField(groupProperty: getCompositionGroup_compositionGroupService_compositionGroup_groups_properties) {
        let groupPropertyCt = this.props.groupPropertiesConstants?.filter((item: any) => item.code === groupProperty?.code)[0];
        if (groupPropertyCt?.dataType === ProteusConstants.BOOLEAN) {
            return false;
        } else if (groupPropertyCt?.dataType === ProteusConstants.INTEGER) {
            return "0";
        } else {
            return "";
        }
    }

    getSqlQueryInfos() {
        if (this.props.sqlQueryInfos !== undefined) {
            return _msg("GroupSnapshotTab.employeesNumber.label") + " " + this.props.sqlQueryInfos.employeesNumber + " " +
                _msg("GroupSnapshotTab.queryDuration.label") + " " + this.props.sqlQueryInfos.queryDuration;
        }
    }

    renderDynamicGroup() {
        let selectedGroup = this.props.selectedGroupAndContext?.selectedGroup?.group;
        return (<Grid className="GroupSnapshotTab_dynamicGroup GroupsManagement_flexGrow">
            {/* for a dynamic group, when the sidebar is visible, the row will have 3 columns. Otherwise, it will have 2 */}
            <Grid.Row columns={this.props.sideBarIsVisible ? 3 : 2}>
                <Grid.Column>
                    <Form>
                        <Form.TextArea placeholder={_msg("GroupSnapshotTab.query.label")}
                            value={selectedGroup?.sql || ""} onChange={(e, data) => this.props.renderOnChangeForGroupOrContext("sql", data.value)} />
                    </Form>
                </Grid.Column>
                <Grid.Column className="GroupSnapshotTab_dynamicGroupTable">
                    <Segment>
                        <Grid>
                            <Grid.Row>
                                {_msg("GroupSnapshotTab.membersOn.label")}
                                <Form>
                                    <Form.Field>
                                        <DatePickerReactCalendar format={ProteusConstants.DATE_FORMAT} allowClear={false} value={moment(this.props.sqlQueryDate)}
                                            onChange={(date: any) => {
                                                this.props.dispatchers.setInReduxState({ sqlQueryDate: date.toISOString() });
                                            }} />
                                    </Form.Field>
                                </Form>
                                {!this.props.sideBarIsVisible && this.getSqlQueryInfos()}
                                <Button className="GroupSnapshotTab_dynamicGroupSql" disabled={selectedGroup?.sql === null || selectedGroup?.sql === ""}
                                    onClick={() => { this.getGroupEmployeesBySql(selectedGroup.sql) }}>{_msg("GroupSnapshotTab.query.label")}
                                </Button>
                                <Button onClick={() => this.props.dispatchers.setInReduxState({ sideBarIsVisible: !this.props.sideBarIsVisible })}>
                                    {this.props.sideBarIsVisible ? _msg("GroupSnapshotTab.hideGroupMembersButton.label") : _msg("GroupSnapshotTab.showGroupMembersButton.label")}
                                </Button>
                            </Grid.Row>
                            {this.props.sideBarIsVisible && <Grid.Row>{this.getSqlQueryInfos()}</Grid.Row>}
                        </Grid>
                    </Segment>
                    {/* Not all components are migrated to RRC so the id from EntityTableSimple can be conflicting if other table is rendered in the same page. */}
                    <EntityTableSimpleRRC id='entityTableSimple3' ref={this.groupEmployeesTableRef} entityDescriptor={groupEmployeesDescriptor} />
                </Grid.Column >
                {this.props.sideBarIsVisible && <Grid.Column className="GroupSnapshotTab_dynamicGroupTable">
                    {this.renderMembersSidebar()}
                </Grid.Column>}
            </Grid.Row>
        </Grid>);
    }

    renderCompositionGroup() {
        return (
            <Grid className="GroupSnapshotTab_staticGroup GroupsManagement_flexGrow flex-grow">
                {/* for a composition group, when the sidebar is visible, the row will have 2 columns. Otherwise, it will have 1 */}
                <Grid.Row columns={this.props.sideBarIsVisible ? 2 : 1} className="GroupSnapshotTab_noPaddingBottomRow">
                    <Grid.Column className="SuccessionTab_table">
                        <Button onClick={() => this.props.dispatchers.setInReduxState({ sideBarIsVisible: !this.props.sideBarIsVisible })} floated="right" >
                            {this.props.sideBarIsVisible ? _msg("GroupSnapshotTab.hideGroupMembersButton.label") : _msg("GroupSnapshotTab.showGroupMembersButton.label")}
                        </Button>
                        <EntityTableLight ref={this.compositionGroupsTableRef} {...this.props.compositionGroupsTable} dispatchers={this.props.dispatchers.compositionGroupsTable}
                            actions={{ showDeleteButton: false, showEditButton: false, doNotAddEntityToTable: true }}
                            entityDescriptor={compositionGroupsDescriptor} formCustomizer={{ headerIcon: "group", headerContent: _msg("GroupSnapshotTab.addGroupToComposition.label") }}
                            onDelete={(remainingGroups: any, groupToBeDeletedIndex: number) => {
                                this.props.deleteGroupFromCompositionGroup(this.compositionGroupsTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[groupToBeDeletedIndex]);
                            }}
                            onSave={(groups: any, group: any) => this.props.addGroupsToCompositionGroup(group.values.group)} />
                    </Grid.Column>
                    {this.props.sideBarIsVisible && <Grid.Column className="GroupSnapshotTab_staticSidebar GroupSnapshotTab_staticGroupHistory flex-grow flex">
                        {this.renderMembersSidebar()}
                    </Grid.Column>}
                </Grid.Row>
            </Grid>
        );
    }

    renderStaticGroup() {
        let selectedId = this.props.selectedGroupResourcesHistory!;
        return (
            <Grid className="GroupSnapshotTab_staticGroup GroupsManagement_flexGrow flex-grow">
                {/* for a static group, when the sidebar is visible, the row will have 3 columns. Otherwise, it will have 2 */}
                <Grid.Row columns={this.props.sideBarIsVisible ? 3 : 2} className="GroupSnapshotTab_noPaddingBottomRow">
                    <Grid.Column className="GroupSnapshotTab_block GroupSnapshotTab_historyTable">
                        <HistoryTable ref={this.groupResourcesHistoryTableRef} {...this.props.groupResourcesHistoryTable} dispatchers={this.props.dispatchers.groupResourcesHistoryTable}
                            onDelete={(remainingGroupResources: any) => {
                                this.props.deleteResourceHistory(remainingGroupResources);
                                this.groupResourcesHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                                this.groupResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                                this.props.dispatchers.setInReduxState({ selectedGroupResourcesHistory: undefined });
                            }} onSave={(resourcesHistory: any) => {
                                this.props.addOrUpdateResourcesHistory(resourcesHistory);
                                this.groupResourcesHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                                this.groupResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                                this.props.dispatchers.setInReduxState({ selectedGroupResourcesHistory: undefined });
                            }}
                            onAdd={sliceHistoryTable.reducers.onAdd} actions={{ showDeleteButton: false, showAddButton: true }}
                            entityDescriptor={groupResourcesHistoryDescriptor} fieldsToBeCopied={[RESOURCES]}
                            formCustomizer={{ headerContent: _msg("GroupResourcesHistoryItem.addForm.label"), headerIcon: "history" }}
                            onSelectItem={(itemId: any) => {
                                const resources = this.groupResourcesHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[itemId!]?.resources;
                                this.onGroupResourcesHistoryTableSelectedChange(resources, itemId);
                                this.props.dispatchers.setInReduxState({ selectedGroupResourcesHistory: itemId });
                            }} />
                    </Grid.Column>
                    <Grid.Column className="SuccessionTab_table">
                        <Button floated="right" onClick={() => this.props.dispatchers.setInReduxState({ sideBarIsVisible: !this.props.sideBarIsVisible })}>
                            {this.props.sideBarIsVisible ? _msg("GroupSnapshotTab.hideGroupMembersButton.label") : _msg("GroupSnapshotTab.showGroupMembersButton.label")}
                        </Button>
                        <EntityTableLight ref={this.groupResourcesTableRef} {...this.props.groupResourcesTable} dispatchers={this.props.dispatchers.groupResourcesTable}
                            actions={{ showDeleteButton: false, disableAddButton: selectedId === undefined, showEditButton: false, doNotAddEntityToTable: true }}
                            entityDescriptor={groupResourcesDescriptor} formCustomizer={{ headerContent: _msg("GroupResources.groupMembers.label"), headerIcon: "user" }}
                            onDelete={(remainingGroupResources: any, groupResourceToBeDeletedIndex: number) => {
                                this.props.deleteResourceFromResourceHistoryItem(this.groupResourcesHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[selectedId!],
                                    this.groupResourcesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[groupResourceToBeDeletedIndex])
                            }}
                            onSave={(resources: any, addedResource: any) => {
                                this.props.addResourceToResourceHistoryItem(this.groupResourcesHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[selectedId!], addedResource.values);
                                this.setGroupResourcesHistoryTable([...this.props.selectedGroupAndContext.selectedGroup.group.groupResourcesHistory])
                            }}
                        />
                    </Grid.Column>
                    {this.props.sideBarIsVisible && <Grid.Column className="GroupSnapshotTab_staticSidebar GroupSnapshotTab_staticGroupHistory flex-grow flex">
                        {this.renderMembersSidebar()}
                    </Grid.Column>}
                </Grid.Row>
            </Grid>
        )
    }

    getMembersQueryInfos() {
        if (this.props.membersQueryInfos !== undefined) {
            return (_msg("GroupSnapshotTab.employeesNumber.label") + " " + this.props.membersQueryInfos.employeesNumber + " " +
                _msg("GroupSnapshotTab.queryDuration.label") + " " + this.props.membersQueryInfos.queryDuration);
        }
        return "";
    }

    renderMembersSidebar() {
        return (<>
            <Segment>
                <Grid>
                    <Grid.Row>
                        {_msg("GroupSnapshotTab.membersOn.label")}
                        <Form>
                            <Form.Field>
                                <DatePickerReactCalendar format={ProteusConstants.DATE_FORMAT} allowClear={false} value={moment(this.props.membersQueryDate)}
                                    onChange={(date: any) => {
                                        this.props.dispatchers.setInReduxState({ membersQueryDate: date.toISOString() });
                                    }} />
                            </Form.Field>
                        </Form>
                        {this.props.selectedGroupAndContext.selectedGroup.group.__typename === ProteusConstants.COMPOSITION_GROUP && this.getMembersQueryInfos()}
                        <Button className="GroupSnapshotTab_dynamicGroupSql"
                            onClick={() => { this.getGroupMembers(this.props.selectedGroupAndContext.selectedGroup.group.code) }}>
                            {_msg("GroupSnapshotTab.members.label")}
                        </Button>
                    </Grid.Row>
                    {this.props.selectedGroupAndContext.selectedGroup.group.__typename !== ProteusConstants.COMPOSITION_GROUP && <Grid.Row>
                        {this.getMembersQueryInfos()}
                    </Grid.Row>}
                </Grid>
            </Segment>
            {/* Not all components are migrated to RRC so the id from EntityTableSimple can be conflicting if other table is rendered in the same page. */}
            <EntityTableSimpleRRC id={uniqueId("entityTableSimple4-")} ref={this.groupMembersTableRef} entityDescriptor={groupEmployeesDescriptor} />
        </>)
    }

    selectedGroupChanged() {
        const selectedGroup = this.props.selectedGroupAndContext.selectedGroup.group;
        this.setContextsTable(selectedGroup.contexts);
        this.setPropertiesTable(selectedGroup.properties);
        if (selectedGroup.__typename === ProteusConstants.COMPOSITION_GROUP) {
            this.setCompositionGroupsTable(selectedGroup.groups);
        } else if (selectedGroup.__typename === ProteusConstants.STATIC_GROUP) {
            this.setGroupResourcesHistoryTable([...selectedGroup.groupResourcesHistory]);
        }
    }

    render() {
        const selectedGroup = this.props.selectedGroupAndContext?.selectedGroup?.group;
        fieldRenderers[GROUP_PROPERTY] = GroupSnapshotTabTableRenderer;
        fieldRenderers[GROUP_CONTEXT] = GroupSnapshotTabTableRenderer;
        fieldRenderers[EMPLOYEE_SNAPSHOT] = GroupSnapshotTabTableRenderer;
        fieldRenderers[CUSTOM_STRING_FIELD] = GroupSnapshotTabTableRenderer;
        fieldRenderers[GROUP_SNAPSHOT] = GroupSnapshotTabTableRenderer;
        return (<>
            <GroupOrContextEditor {...this.props.groupEditor} dispatchers={this.props.dispatchers.groupEditor} initialEmployeesOptions={this.props.initialEmployeesOptions}
                onAccordionFormChange={this.props.renderOnChangeForGroupOrContext} selectedGroupOrContext={selectedGroup} />
            <div className="GroupsManagement_flexGrow flex flex-grow">
                <Grid>
                    <Grid.Row columns={2} className="GroupSnapshotTab_noPaddingTopRow">
                        <Grid.Column className="GroupSnapshotTab_contextPropertiesTables">
                            <EntityTableLight {...this.props.propertiesTable} ref={this.propertiesTableRef} dispatchers={this.props.dispatchers.propertiesTable}
                                formCustomizer={{ headerIcon: "edit", headerContent: _msg(GROUP_PROPERTIES), customEntityDescriptorForEditor: this.getGroupPropertyDescriptorForEditor(), onItemCustomEdit: (entity: any, rowIndex: number) => this.onItemCustomEdit(entity, rowIndex) }}
                                entityDescriptor={this.getGroupPropertyDescriptorForTable()} actions={{ showDeleteButton: false, doNotAddEntityToTable: true }}
                                onSave={(properties: any, editedProperty: any) => {
                                    this.props.addOrUpdateGroupProperty(editedProperty);
                                }}
                                onDelete={(remainingProperties, propertyToBeDeletedIndex: number) => {
                                    this.props.deleteGroupProperty(this.propertiesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[propertyToBeDeletedIndex][GROUP_PROPERTY_CT]);
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column className="GroupSnapshotTab_contextPropertiesTables">
                            <EntityTableLight ref={this.contextsTableRef} {...this.props.contextsTable} dispatchers={this.props.dispatchers.contextsTable} entityDescriptor={groupContextsDescriptor}
                                actions={{ showEditButton: false, showDeleteButton: false, doNotAddEntityToTable: true }}
                                formCustomizer={{ headerIcon: "setting", headerContent: _msg("GroupSnapshotTab.addContextToGroup.label", selectedGroup?.name) }}
                                onSave={(contexts: any, contextToBeAdded: any) => this.props.addContextToCurrentGroup(contextToBeAdded.values.context)}
                                onDelete={(remainingContexts: any, contextToBeDeletedIndex: number) => {
                                    this.props.deleteContextFromCurrentGroup(this.contextsTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[contextToBeDeletedIndex].context);
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {selectedGroup?.__typename === ProteusConstants.DYNAMIC_GROUP && this.renderDynamicGroup()}
                {selectedGroup?.__typename === ProteusConstants.COMPOSITION_GROUP && this.renderCompositionGroup()}
                {selectedGroup?.__typename === ProteusConstants.STATIC_GROUP && this.renderStaticGroup()}
            </div>
        </>);
    }

    clearTablesContent() {
        this.setGroupEmployeesTable([]);
        this.setGroupMembersTable([]);
        this.setGroupResourcesTable([]);
        this.groupResourcesHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
    }

    async onGroupResourcesHistoryTableSelectedChange(resources: any, itemId: any) {
        await this.onGroupResourcesHistoryTableChange(resources, itemId);
    }

    async onGroupResourcesHistoryTableChange(resources: any, itemId: any) {
        if (resources === undefined || resources.length === 0) {
            this.setGroupResourcesTable([]);
        } else {
            await this.getGroupResourcesEmployees(resources, itemId);
        }
    }

    componentDidMount() {
        this.props.dispatchers.getGroupPropertiesConstants();
        this.selectedGroupChanged();
    }

    async componentDidUpdate(prevProps: PropsFrom<typeof sliceGroupSnapshotTab> & PropsNotFromState) {
        const selectedGroup = this.props.selectedGroupAndContext.selectedGroup.group;
        const prevSelectedGroup = prevProps.selectedGroupAndContext.selectedGroup.group;
        if (selectedGroup.id !== prevSelectedGroup.id) {
            this.selectedGroupChanged();
            this.clearTablesContent();
            this.props.dispatchers.setInReduxState({ sqlQueryInfos: undefined, membersQueryInfos: undefined, sideBarIsVisible: false,  membersQueryDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0) });
        } else {
            if (selectedGroup.contexts !== prevSelectedGroup.contexts) {
                this.setContextsTable(selectedGroup.contexts);
            }

            if (selectedGroup.properties !== prevSelectedGroup.properties) {
                this.setPropertiesTable(selectedGroup.properties);
            }

            if (selectedGroup.__typename === ProteusConstants.COMPOSITION_GROUP &&
                selectedGroup.groups !== prevSelectedGroup.groups) {
                this.setCompositionGroupsTable(selectedGroup.groups);
            }

            if (selectedGroup.__typename === ProteusConstants.STATIC_GROUP &&
                selectedGroup.groupResourcesHistory !== prevSelectedGroup.groupResourcesHistory) {
                this.setGroupResourcesHistoryTable([...selectedGroup.groupResourcesHistory]);
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatchers.setInReduxState({ sqlQueryInfos: undefined, membersQueryInfos: undefined });
        this.clearTablesContent();
    }
}