import { apolloClient, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react";
import { EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS } from "graphql/queries";
import React from "react";
import { Button, Dropdown, Form, Segment } from "semantic-ui-react";
import { getResourceSnapshots_employeeService_employeeSnapshots } from "apollo-gen/getResourceSnapshots";
import { sliceDateNavigation } from "pages/DateNavigationPanel/DateNavigationPanel";
import moment from "moment";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import { fieldEditors } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import _ from "lodash";
import { BALANCE_COUNTER_TAB_INDEX, EmployeeEditorBase, EmployeeEditorBaseProps, ModalOperations, SliceEmployeeEditorBase, sliceEmployeeEditorBaseOnlyForExtension } from "./EmployeeEditorBase";
import { HISTORY_FIELD_TYPE } from "./customFieldRenderersEditors";
import Interweave from "interweave";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { PLANNING_EMPLOYEE_EDITOR_UPDATE, PLANNING_EMPLOYEE_EDITOR_VIEW, PLANNING_OTHER_EMPLOYEE_EDITOR_UPDATE, PLANNING_OTHER_EMPLOYEE_EDITOR_VIEW } from "pages/Planning/PlanningPage";

export interface EmployeesOptions {
    key: number,
    text: string,
    value: number,
    employeeNumber: number | string
};

export class SliceEmployeeEditor extends SliceEmployeeEditorBase {

    initialState = {
        ...sliceEmployeeEditorBaseOnlyForExtension.initialState,
        employeesOptions: [] as EmployeesOptions[]
    }

    nestedSlices = {
        ...sliceEmployeeEditorBaseOnlyForExtension.nestedSlices
    }

    reducers = {
        ...sliceEmployeeEditorBaseOnlyForExtension.reducers,
        ...getBaseReducers<SliceEmployeeEditor>(this)
    }

    impures = {
        ...sliceEmployeeEditorBaseOnlyForExtension.impures,
        ...getBaseImpures<SliceEmployeeEditor>(this),

        async searchEmployee(text: string, fromDate: number, untilDate: number) {
            if (text.length <= 2) {
                return;
            }

            let employees = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS,
                variables: {
                    searchText: text,
                    fromDate: fromDate,
                    untilDate: untilDate
                },
                context: { showSpinner: false }
            })).data.employeeService_employeeSnapshots;

            if (!employees) {
                return;
            }

            // If the selected employee can not be find between the options, add it to the options.
            if (this.getState().employee && employees.filter((item: any) => item.id === this.getState().employee?.id).length === 0) {
                const selectedEmployee = this.getState().employee;
                employees.unshift({
                    id: selectedEmployee?.id,
                    name: selectedEmployee?.name,
                    firstName: selectedEmployee?.firstName,
                    contractHistoryItem: {
                        employeeNumber: fieldEditors[HISTORY_FIELD_TYPE].retrieveCurrentHistoryItem(selectedEmployee?.detail?.contractHistory)?.employeeNumber
                    }
                });
            }

            const allowOwnEmployeeDetailView = AppMetaTempGlobals.appMetaInstance.hasPermission(PLANNING_EMPLOYEE_EDITOR_VIEW);
            const allowOwnEmployeeDetailUpdate = AppMetaTempGlobals.appMetaInstance.hasPermission(PLANNING_EMPLOYEE_EDITOR_UPDATE);
            if (!allowOwnEmployeeDetailView && !allowOwnEmployeeDetailUpdate) {
                employees = employees.filter((item: any) => item.id !== ProteusUtils.getCurrentUser().id);
            }

            this.getDispatchers().setInReduxState({
                employeesOptions: employees.filter((employee: getResourceSnapshots_employeeService_employeeSnapshots, index: number, array: getResourceSnapshots_employeeService_employeeSnapshots[]) =>
                    array.findIndex((employee2: getResourceSnapshots_employeeService_employeeSnapshots) => (employee2.id === employee.id)) === index).map((item: getResourceSnapshots_employeeService_employeeSnapshots) => ({
                        key: item.id as number,
                        text: item.name + " " + item.firstName + (item.contractHistoryItem && item.contractHistoryItem.employeeNumber ? " (" + item.contractHistoryItem.employeeNumber + ")" : ""),
                        value: item.id as number
                    }))
            });
        },

        getEmployeeDrawerTitle() {
            let title = _msg("employee.label") + ": ";
            if (this.getState().employee) {
                title += this.getState().employee?.name + " " + this.getState().employee?.firstName + this.getEmployeeNumber();
            } else {
                title += _msg("general.emptySelection").toLowerCase();
            }
            return title;
        }
    }
};

export const sliceEmployeeEditorOnlyForExtension = createSliceFoundation(class extends SliceEmployeeEditor { });

export type EmployeeEditorProps = EmployeeEditorBaseProps & PropsFrom<SliceEmployeeEditor> & {
    selectedEmployeeIdFromPlanning: number | string | undefined;
    employeeOptionsFromPlanning: EmployeesOptions[];
    datePanel: StateFrom<typeof sliceDateNavigation>;
}

export class EmployeeEditor<P extends EmployeeEditorProps = EmployeeEditorProps> extends EmployeeEditorBase<P> {

    /**
     * @override
     */
    protected renderTabContent(content: any, additionalContentForSaveSegment?: JSX.Element, additionalClassName?: string) {
        return (
            <>
                {this.renderSaveSegment(additionalContentForSaveSegment, additionalClassName)}
                {content ? content :
                    <Segment className="EmployeeEditor_noEmployee">
                        <div>
                            <Interweave content={_msg("EmployeeEditor.noSelection.label")} />
                        </div>
                    </Segment>}
            </>
        );
    }

    /**
     * @override
     */
    protected isEditable() {
        const currentUserId = ProteusUtils.getCurrentUser().id;
        const allowOtherEmployeeDetailUpdate = AppMetaTempGlobals.appMetaInstance.hasPermission(PLANNING_OTHER_EMPLOYEE_EDITOR_UPDATE);
        if (allowOtherEmployeeDetailUpdate && this.props.employee?.id !== currentUserId) {
            return true;
        }
        const allowOwnEmployeeDetailUpdate = AppMetaTempGlobals.appMetaInstance.hasPermission(PLANNING_EMPLOYEE_EDITOR_UPDATE);
        if (allowOwnEmployeeDetailUpdate && this.props.employee?.id === currentUserId) {
            return true;
        }
        return false;
    }

    /**
     * @override
     * This method returns the reference date for the employee (the date that is sent to the server to retrieve the employee information).
     * If the current date in between the "fromDate" and the "untilDate" selected in the Gantt, the current date is returned.
     * Otherwise, the "fromDate" value selected in the Gantt is used.
     */
    protected getEmployeeReferenceDate() {
        let currentDate = new Date();
        if (currentDate.getTime() >= new Date(this.props.datePanel.fromDate).getTime() && currentDate.getTime() <= new Date(this.props.datePanel.untilDate).getTime()) {
            return moment(currentDate).startOf("day").toDate();
        }
        return this.props.datePanel.fromDate;
    }

    protected getDateFromPlanning(): Date {
        return new Date(this.props.datePanel.fromDate);
    }

    protected renderSaveButtonLabel() {
        return <span className="EmployeeEditor_saveLabel">{_msg("general.save")}</span>
    }

    async onRefresh() {
        if (this.isDirty()) {
            this.props.dispatchers.setInReduxState({
                showModal: {
                    open: true,
                    operation: ModalOperations.REFRESH,
                    newEmployee: undefined,
                    message: _msg("leavePage.message.label"),
                    showNoButton: true
                }
            });
        } else {
            this.props.dispatchers.setInReduxState({ employee: this.getEmployeeValueFromForm() });
            await this.loadAdditionalEmployeeData(this.props.employee?.id, true);
        }
    }

    async onEmployeeChange(e: any, data: any) {
        if (this.isDirty()) {
            this.props.dispatchers.setInReduxState({
                showModal: {
                    open: true,
                    operation: ModalOperations.CHANGE_EMPLOYEE,
                    newEmployee: data.value,
                    message: _msg("leavePage.message.label"),
                    showNoButton: true
                }
            });
        } else {
            await this.refBalanceTab.current?.resetBalanceData();
            this.refCreditTab.current?.resetCheckboxes();
            await this.loadAdditionalEmployeeData(data.value, true);
        }
    }

    protected renderSaveSegment(additionalContentForSaveSegment?: JSX.Element, additionalClassName?: string) {
        const allowOtherEmployeeDetailView = AppMetaTempGlobals.appMetaInstance.hasPermission(PLANNING_OTHER_EMPLOYEE_EDITOR_VIEW);
        const allowOtherEmployeeDetailUpdate = AppMetaTempGlobals.appMetaInstance.hasPermission(PLANNING_OTHER_EMPLOYEE_EDITOR_UPDATE);
        return (
            <Segment style={{zIndex: 100}}>
                <div className={"EmployeeEditor_saveSegment " + (additionalClassName ? additionalClassName : "")}>
                    <Button className="EmployeeEditor_saveBtn" icon="save" content={this.renderSaveButtonLabel()} primary onClick={async () => await this.saveAndReloadEmployee(ProteusConstants.ENFORCE_ALL)} disabled={!this.props.employee || !this.isEditable()}>
                    </Button>
                    {this.props.activePageIndex !== BALANCE_COUNTER_TAB_INDEX &&
                        <Button disabled={!this.props.employee} icon="refresh" color="green"
                            onClick={() => this.onRefresh()}
                        />}
                    {(allowOtherEmployeeDetailView || allowOtherEmployeeDetailUpdate) && <Form>
                        <Form.Field>
                            <Dropdown fluid selection search={_.identity} clearable selectOnNavigation={false} noResultsMessage={_msg("general.noResultsFound")}
                                options={this.props.employeesOptions} value={this.props.employee?.id || ""} placeholder={_msg("searchEmployee.label")}
                                onChange={async (e: any, data: any) => this.onEmployeeChange(e, data)}
                                onSearchChange={(e: any) => {
                                    this.props.dispatchers.searchEmployee(e.target.value as string, this.props.datePanel.fromDate, this.props.datePanel.untilDate);
                                }}
                            />
                        </Form.Field>
                    </Form>}
                </div>
                {this.renderAdditionalContentForSaveSegment()}
            </Segment>
        );
    }

    async componentDidMount() {
        super.componentDidMount();
        this.loadEmployee(this.props.selectedEmployeeIdFromPlanning, this.getEmployeeReferenceDate());
        if (this.props.selectedEmployeeIdFromPlanning) {
            this.loadAdditionalEmployeeData(Number(this.props.selectedEmployeeIdFromPlanning), false);
        }
        // if an employee is already selected in Planning, it must be selected when the employeeEditor drawer opens.
        // the dropdown from employeeEditor must have the options already initialized so the employee can be shown as selected. 
        this.props.dispatchers.setInReduxState({ employeesOptions: this.props.employeeOptionsFromPlanning });
    }
}