'use strict';

/*@ngInject*/
function proteusGridGrouping(orderByFilter, Uid) {
	
	var GridGrouping = function(grid) {
		var grouping = this;

		grouping.setGrouping = function(config) {
			grouping.config = {};
			angular.forEach(config.grouping, function(col) {
				grouping.config[col.name] = col;
			});
			grouping.expanded = {};
			grid.api.core.computeRows(grid.options.data);
		}

		grouping.expandCollapseRow = function(row) {
			grouping.expanded[row.uid] = !grouping.expanded[row.uid];
			grid.zebra();
		}

		grouping.expandCollapseAll = function() {
			grouping.expanded[''] = !grouping.expanded[''];
			angular.forEach(grid.rows, function(row) {
				if (row.grouping) {
					grouping.expanded[row.uid] = grouping.expanded[''];
				}
			});
			grid.zebra();
		}

		grouping.dataChanged = function(data) {
			var groups = {}, groupsArray = [];
			var rows = [];
						
			angular.forEach(data, function(entity) {
				var key = '';
				angular.forEach(grouping.config, function(config) {
					try {
						var fields = [];
						if (config.field) {
							fields[0] = config.field;
						} else if (config.fields) {
							fields = config.fields;
						}
						for (var i = 0; i < fields.length; i++) {
							let tokens = fields[i].split('.');
							let j = 0;
							let u = entity;
							while (tokens[j]) {
								u = u[tokens[j++]];
							}
							if (angular.isDate(u)) {
								key += u;
							} else if (angular.isObject(u)) {
								key += u.id;
							} else {
								key += u;
							}
						}
					} catch(err) {
						// nothing to do
					}
				});
				
				if (!groups[key]) {
					groups[key] = {
						key: key,
						entity: entity,
						children: []
					};
					groupsArray.push(groups[key]);
				}
				groups[key].children.push(entity);
			});

			// order to make sure that the groups do not move after removing a child
			groupsArray = orderByFilter(groupsArray, 'key');

			angular.forEach(groupsArray, function(group) {
				var parentRow = {
					entity: group.entity,
					uid: group.key,
					grouping: true,
					children: group.children
				}

				// expand new row if all rows are expanded
				if (grouping.expanded[''] && !grouping.expanded[parentRow.uid]) {
					grouping.expandCollapseRow(parentRow);
				}

				rows.push(parentRow);

				angular.forEach(group.children, function(entity) {
					rows.push({
						entity: entity,
						uid: Uid.next(),
						parentRow: parentRow
					});
				});
			});

			grid.rows = rows;

			return true;
		}

		grouping.columnDefsChanged = function(columnDefs) {
			angular.forEach(columnDefs, function(columnDef) {
				var config = grouping.config[columnDef.name];
				if (config) {
					columnDef.grouping = config;
				} else {
					delete columnDef.grouping;
				}
			});
		}
	}

	return {
		restrict: 'A',
		require: 'proteusGrid',
		link: function($scope, $elt, $attrs, grid) {
			grid.api.grouping = new GridGrouping(grid);
		}
	}
};

module.exports = proteusGridGrouping;