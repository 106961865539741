'use strict';

/*@ngInject*/
function catalogs() {
	
	function asTrack(element) {
		return element.currentTrackVersion;
	}
	
	function asModule(element) {
		return element.currentModuleVersion;
	}
	
	function asComponent(element) {
		return element.currentComponentVersion;
	}
	
	function f(element, field) {
		var rslt = {};
		
		if (element) {
			rslt = asTrack(element) || asModule(element) || asComponent(element) || element;
		}
		
		if (field) {
			return rslt[field];
		}
		
		return rslt;
	}
	
	f.asTrack = asTrack;
	f.asModule = asModule;
	f.asComponent = asComponent;
	
	return f;
}

module.exports = catalogs;