import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorFormSimple, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { getEmployee_employeeService_employee } from "apollo-gen/getEmployee";
import { getPilotProgressionHistory_planningServiceFacadeBean_pilotProgressionHistory } from "apollo-gen/getPilotProgressionHistory";
import { getPremiumHistory_planningServiceFacadeBean_premiumHistory } from "apollo-gen/getPremiumHistory";
import { pilotProgressHistoryItemEntityDescriptor } from "AppEntityDescriptors";
import { PLANNING_SERVICE_FACADE_BEAN_GET_PILOT_PROGRESSION_HISTORY, PLANNING_SERVICE_FACADE_BEAN_GET_PREMIUM_HISTORY, PLANNING_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_PREMIUM_HISTORY, PREMIUM_SUSPENSION_SERVICE_GET_PREMIUM_SUSPENSION_HISTORY, PREMIUM_SUSPENSION_SERVICE_SAVE_OR_UPDATE_PREMIUM_SUSPENSION_HISTORY } from "graphql/queries";
import moment from "moment";
import { CUSTOM_NUMBERS } from "pages/groupsManagement/customFieldRenderersEditors";
import { ProteusConstants } from "ProteusConstants";
import React from "react";
import { Button, Header, Icon, Modal, Tab, Table } from "semantic-ui-react";
import { HISTORY_FIELD_TYPE } from "./customFieldRenderersEditors";
import { EMPLOYEE_EDITOR } from "./EmployeeEditorBase";
import { HistoryTable, sliceHistoryTable } from "components/HistoryTable";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import _ from "lodash";

const VIEW_TAB_INDEX = 0;
const EDIT_TAB_INDEX = 1;
export const PREMIUM_HISTORY = "PremiumHistory";
export const PREMIUM_SUSPENSION_HISTORY = "PremiumSuspensionHistory";
export const PILOT_PROGRESSION_FIELD_TYPE = "pilotProgressionFieldType";

export const PROGRESSION_SCRIPTABLE_UI_ID = "EmployeeEditor_ProgressionTab";

export const premiumSuspensionHistoryItemEntityDescriptor = new EntityDescriptor({ name: "PremiumSuspensionHistory", icon: "file alternate" });

export const sliceProgressionTab = createSliceFoundation(class SliceProgressionTab {

    initialState = {
        premiumHistory: undefined as unknown as getPremiumHistory_planningServiceFacadeBean_premiumHistory[],
        premiumSuspensionHistory: undefined as unknown as any[],
        pilotProgressionHistory: undefined as unknown as getPilotProgressionHistory_planningServiceFacadeBean_pilotProgressionHistory[],
        premiumHistoryLoaded: undefined as unknown as getPremiumHistory_planningServiceFacadeBean_premiumHistory[],
        pilotProgressionHistoryLoaded: undefined as unknown as getPilotProgressionHistory_planningServiceFacadeBean_pilotProgressionHistory[],
        shouldSavePremiumHistory: false as boolean,
        shouldSavePremiumSuspensionHistory: false as boolean,
        modalOpen: false as boolean,
        shouldReloadPilotProgressionHistory: false as boolean
    }

    nestedSlices = {
        pilotProgressHistoryDb: pilotProgressHistoryItemEntityDescriptor.infoTable.slice,
        premiumSuspensionTable: sliceHistoryTable
    }

    reducers = {
        ...getBaseReducers<SliceProgressionTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceProgressionTab>(this),

        async loadProgressionData(resource: number | undefined | null) {
            let premiumHistory = undefined;
            if (resource) {
                premiumHistory = (await apolloClient.query({
                    query: PLANNING_SERVICE_FACADE_BEAN_GET_PREMIUM_HISTORY,
                    variables: { resource }
                })).data.planningServiceFacadeBean_premiumHistory;
                this.loadPilotProgressionHistory(resource);
                this.loadPremiumSuspensionHistory(resource);
            }
            this.getDispatchers().setInReduxState({ premiumHistory, premiumHistoryLoaded: premiumHistory, shouldSavePremiumHistory: false });
        },

        async loadPremiumSuspensionHistory(resource: number | undefined | null) {
            if (!resource) {
                return;
            }
            let premiumSuspensionHistory = (await apolloClient.query({
                    query: PREMIUM_SUSPENSION_SERVICE_GET_PREMIUM_SUSPENSION_HISTORY,
                    variables: { resource }
                })).data.premiumSuspensionHistoryItemService_findAllByResource;
            this.getDispatchers().setInReduxState({ premiumSuspensionHistory, shouldSavePremiumSuspensionHistory: false });
        },

        async loadPilotProgressionHistory(resource: number | undefined | null) {
            let pilotProgressionHistory = (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_GET_PILOT_PROGRESSION_HISTORY,
                variables: { pilot: resource }
            })).data.planningServiceFacadeBean_pilotProgressionHistory;
            pilotProgressionHistory = pilotProgressionHistory.sort((a: getPilotProgressionHistory_planningServiceFacadeBean_pilotProgressionHistory, b: getPilotProgressionHistory_planningServiceFacadeBean_pilotProgressionHistory) =>
                moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf());
            this.getDispatchers().setInReduxState({ pilotProgressionHistory, pilotProgressionHistoryLoaded: pilotProgressionHistory, shouldReloadPilotProgressionHistory: false });
        },

        async savePremiumHistory(resource: number | undefined | null) {
            const premiumHistory = (await apolloClient.mutate({
                mutation: PLANNING_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_PREMIUM_HISTORY,
                variables: {
                    resource: resource,
                    premiumHistory: this.getState().premiumHistory
                }
            })).data.planningServiceFacadeBean_saveOrUpdatePremiumHistory;
            this.getDispatchers().setInReduxState({ premiumHistory, premiumHistoryLoaded: premiumHistory, shouldSavePremiumHistory: false });
        },

        async savePremiumSuspensionHistory(resource: number | undefined | null) {
            const premiumSuspensionHistory = (await apolloClient.mutate({
                mutation: PREMIUM_SUSPENSION_SERVICE_SAVE_OR_UPDATE_PREMIUM_SUSPENSION_HISTORY,
                variables: {
                    resource: resource,
                    premiumSuspensionHistory: this.getState().premiumSuspensionHistory
                }
            })).data.premiumSuspensionHistoryItemService_saveOrUpdatePremiumSuspensionHistory;
            this.getDispatchers().setInReduxState({ premiumSuspensionHistory, shouldSavePremiumSuspensionHistory: false });
        }
    }
});

type PropsNotFromState = {
    employee: getEmployee_employeeService_employee | undefined | null;
    isEditable: boolean;
    refProgressionTab: React.RefObject<EntityEditorFormSimple>;
    onOpenTableLight: (fieldDescriptor: FieldDescriptor, entities: any[]) => void;
    setFormValuesInState: () => void;
    referenceDate: Date | number;
}

export class ProgressionTab extends TabbedPage<PropsFrom<typeof sliceProgressionTab> & PropsNotFromState> {

    protected refPremiumSuspensionHistoryTable = React.createRef<HistoryTable>();

    constructor(props: any) {
        super(props);
        this.onPremiumHistoryTableOpen = this.onPremiumHistoryTableOpen.bind(this);
    }

    protected getProgressionEntityDescriptor() {
        return new EntityDescriptor({ name: EMPLOYEE_EDITOR }, false)
            .addFieldDescriptor({ name: "employee.detail.pilotNumber", type: CUSTOM_NUMBERS, enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "pilotProgressionHistory", type: PILOT_PROGRESSION_FIELD_TYPE, icon: "anchor", isDisabled: !this.props.isEditable, onModalOpenClose: this.onModalOpenClose, referenceDate: this.props.referenceDate })
            .addFieldDescriptor({ name: "premiumHistory", type: HISTORY_FIELD_TYPE, entityName: PREMIUM_HISTORY, mandatoryFields: ["record"], icon: "trophy", onOpen: this.onPremiumHistoryTableOpen, onOpenTableLight: this.props.onOpenTableLight, isDisabled: !this.props.isEditable, referenceDate: this.props.referenceDate });
    }

    onPremiumHistoryTableOpen() {
         this.setTableEntity(this.props.premiumSuspensionHistory);
    }

    protected onModalOpenClose = (open: boolean) => {
        this.props.dispatchers.setInReduxState({ modalOpen: open });
    }

    setTableEntity(data: any[]) {
        let entities = data;
        if (!data) {
            entities = [];
        }
        this.refPremiumSuspensionHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(data);
    }

    protected update(premiumSuspensionHistory: any[]) {
        this.props.dispatchers.setInReduxState({ premiumSuspensionHistory, shouldSavePremiumSuspensionHistory: true });
    }

    public getPremiumModalAdditionalContent() {
        return (
            <>
                <div><b><Icon name="calendar minus outline"></Icon>{_msg("PremiumSuspensionHistory.label")}</b></div>
                <HistoryTable ref={this.refPremiumSuspensionHistoryTable}
                    formCustomizer={{ headerIcon: "calendar minus outline", headerContent: _msg("PremiumSuspensionHistory.label") }}
                    {...this.props.premiumSuspensionTable} onAdd={sliceHistoryTable.reducers.onAdd}
                    dispatchers={this.props.dispatchers.premiumSuspensionTable}
                    onSave={(suspensionHistory: any[]) => {
                        this.update(suspensionHistory);
                    }}
                    onDelete={(suspensionHistory: any[]) => {
                        this.update(suspensionHistory);
                    }}
                    allowHoles={true} entityDescriptor={this.addHistoryFieldsToDescriptor(premiumSuspensionHistoryItemEntityDescriptor)}
                />
            </>
        );
    }

    protected addHistoryFieldsToDescriptor = (descriptor: EntityDescriptor) => {
        descriptor.addFieldDescriptor({ name: "validFrom", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT, allowClear: false}) });
        descriptor.addFieldDescriptor({ name: "validUntil", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT}) });
        return descriptor;
    }

    protected renderModalPanes() {
        return [
            {
                menuItem: _msg("entityCrud.editor.viewer"),
                key: VIEW_TAB_INDEX,
                render: this.renderViewPane
            },
            {
                menuItem: _msg("entityCrud.editor.form"),
                key: EDIT_TAB_INDEX,
                render: this.renderEditPane
            }];
    }

    protected renderViewPane = () => {
        return (
            <div className="ProgressionTab_table">
                <Table structured celled unstackable compact selectable striped>
                    <Table.Header>
                        {this.renderTableHeader()}
                    </Table.Header>
                    <Table.Body>
                        {this.renderTableBody()}
                    </Table.Body>
                </Table>
            </div>
        );
    }

    protected renderTableHeader() {
        if (!this.props.pilotProgressionHistory) {
            return;
        }

        return [
            <Table.Row key={0}>
                <Table.HeaderCell textAlign='center' key={0} rowSpan={2}>{_msg("PilotProgressionHistory.category.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={1} colSpan={2}>{_msg("PilotProgressionHistory.goal.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={2} rowSpan={2}>{_msg("PilotProgressionHistory.totalTasks.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={3} rowSpan={2}>{_msg("PilotProgressionHistory.categoryTasks.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={4} rowSpan={2}>{_msg("validFrom.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={5} rowSpan={2}>{_msg("validUntil.label")}</Table.HeaderCell>
            </Table.Row>,
            <Table.Row key={1}>
                <Table.HeaderCell textAlign='center' key={0}>{_msg("PilotProgressionHistory.totalTasks.label")}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' key={1}>{_msg("PilotProgressionHistory.categoryTasks.label")}</Table.HeaderCell>
            </Table.Row>
        ];
    }

    protected renderTableBody() {
        const progression = this.props.pilotProgressionHistory;
        if (!progression) {
            return;
        }

        let rows = [];
        for (let i = 0; i < progression.length; i++) {
            rows.push(<Table.Row key={i}>
                <Table.Cell verticalAlign="middle" textAlign='center'>{progression[i].category?.description || ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center'>{progression[i].category?.totalTasks || 0}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center'>{progression[i].category?.tasks || 0}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center'>{progression[i].totalTasks || 0}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center'>{progression[i].tasks || 0}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center'>{progression[i].validFrom ? moment(new Date(progression[i].validFrom)).format(ProteusConstants.DATE_TIME_FORMAT) : ""}</Table.Cell>
                <Table.Cell verticalAlign="middle" textAlign='center'>{progression[i].validUntil ? moment(new Date(progression[i].validUntil)).format(ProteusConstants.DATE_TIME_FORMAT) : ""}</Table.Cell>
            </Table.Row>);
        }
        return rows;
    }

    protected renderEditPane = () => {
        const ed = pilotProgressHistoryItemEntityDescriptor;
        return (
            <div className={"ProgressionTab_embededTable" + (this.props.isEditable ? "" : " ProgressionTab_embededTableDisabled")}>
                <ed.infoTable.wrappedComponentClass {...this.props.pilotProgressHistoryDb} dispatchers={this.props.dispatchers.pilotProgressHistoryDb}
                    embeddedMode employee={this.props.employee} />
            </div>
        );
    }

    protected async reloadPilotProgressHistory() {
        this.props.setFormValuesInState();
        await this.props.dispatchers.loadPilotProgressionHistory(this.props.employee?.id);
    }

    protected renderPilotProgressionHistoryModal() {
        return (
            <Modal open={this.props.modalOpen} size="large" closeIcon closeOnDimmerClick={false} onClose={() => this.onModalOpenClose(false)}>
                <Header icon="anchor" content={_msg("EmployeeEditor.pilotProgressionHistory.label")} />
                <Modal.Content className="EntityTableLight_modal ProgressionTab_modal">
                    <Tab menu={{ secondary: true, pointing: true }} panes={this.renderModalPanes()}
                        onTabChange={async (e: any, data: any) => {
                            if (data.activeIndex === VIEW_TAB_INDEX && this.props.shouldReloadPilotProgressionHistory) {
                                await this.reloadPilotProgressHistory();
                            }
                        }}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={async () => {
                        this.onModalOpenClose(false);
                        if (this.props.shouldReloadPilotProgressionHistory) {
                            await this.reloadPilotProgressHistory();
                        }
                    }}>{_msg("general.ok")} </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    render() {
        return (
            <div className="ProgressionTab">
                {this.renderPilotProgressionHistoryModal()}
                <EntityEditorFormSimple scriptableUiId={PROGRESSION_SCRIPTABLE_UI_ID} entityDescriptor={this.getProgressionEntityDescriptor()} hideButtonBar ref={this.props.refProgressionTab}
                    entity={{ employee: this.props.employee, premiumHistory: this.props.premiumHistory || [], pilotProgressionHistory: this.props.pilotProgressionHistory || [] }} />
            </div>
        );
    }

    componentWillUnmount() {
        this.props.dispatchers.setInReduxState({ shouldSavePremiumHistory: false, premiumHistory: undefined, premiumSuspensionHistory: undefined, shouldSavePremiumSuspensionHistory: false });
    }
}