'use strict';

var treeAddMenu = {
	template: require('./tree-add-menu.html'),
	bindings: {
		rowScope: '<',
		employee: '<',
		onObjectiveAdded: '=',
		onRealizationOrExemptionAdded: '='
	},
	controller:/*@ngInject*/function($rootScope, ObjectiveEditorModal, RealizationEditorModal, Principal) {
		var $ctrl = this;
		
		$ctrl.addObjective = function(event) {
			event.stopPropagation();
			ObjectiveEditorModal.open({
				objective: {},
				employee: $ctrl.employee,
				onObjectiveAdded: $ctrl.onObjectiveAdded
			});
		};

		$ctrl.addRealization = function(event) {
			event.stopPropagation();
			// always initialise final reviewer with the current user
			var finalReviewer = $rootScope.securityContext.resource;
			var entity = {
				finalReviewer: finalReviewer.id
			};
			var reviewersMap = {};
			reviewersMap[finalReviewer.id] = finalReviewer;

			RealizationEditorModal.open({
				entity: entity,
				finalReviewer: finalReviewer,
				reviewersMap: reviewersMap,
				employee: $ctrl.employee,
				onRealizationOrExemptionAdded: $ctrl.onRealizationOrExemptionAdded
			});
		};
		$ctrl.hasEditPermission = false;
		
		$ctrl.$onInit = function() {
			Principal.checkPermissions("EDUCATION_CURRICULUM_EDIT_ALL", function(hasEditPermission){
				$ctrl.hasEditPermission = hasEditPermission;
			});
		}
	}
};

module.exports = treeAddMenu;