'use strict'

function goToMasterButton() {
	
	return {
		restrict: 'E',
		template: require('./go-to-master.html')
	}
};

module.exports = goToMasterButton;

