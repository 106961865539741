import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { EMPLOYEE_SERVICE_GET_EMPLOYEE_BY_ID_WITH_CONTRACT, EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CONTRACT, EMPLOYEE_SERVICE_GET_RESOURCE_ITEM, EMPLOYEE_SERVICE_GET_RESOURCE_ITEMS } from "graphql/queries";
import _ from "lodash";
import { Person } from "pages/employeeDetail/EmployeeDetailEntityDescriptor";
import React from "react";

const PERSON: string = "person";
const FINAL_REVIEWER: string = "finalReviewer";

export class RealizationEntityDescriptor extends EntityDescriptor {

    toMiniString(entityWithMiniFields: any): string {
        let date = entityWithMiniFields["date"] !== null ? new Date(entityWithMiniFields["date"]).toLocaleString() : " -";
        let dueDate = entityWithMiniFields["dueDate"] !== null ? new Date(entityWithMiniFields["dueDate"]).toLocaleString() : "-";
        return _msg("realization.date") + ": " + date + " " + _msg("realization.dueDate") + ": " + dueDate;
    }

    protected customize() {

        const realizationEntityDescriptor = this;

        const sliceRealizationEditor = realizationEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceRealizationEditor extends SliceEntityEditorPage {

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                initialEmployee: undefined as unknown as Person,
                initialReviewer: undefined as unknown as Person
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceRealizationEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceRealizationEditor>(this),

                async getInitialEmployeeData(id: number) {
                    let initialEmployee = (await apolloClient.query({
                        query: EMPLOYEE_SERVICE_GET_EMPLOYEE_BY_ID_WITH_CONTRACT,
                        variables: {
                            employeeId: id
                        }
                    })).data.employeeService_employee;
                    initialEmployee = { ...initialEmployee, contractHistoryItem: { employeeNumber: initialEmployee?.detail?.contractHistory[0]?.employeeNumber } };
                    delete initialEmployee?.detail;
                    this.getDispatchers().setInReduxState({ initialEmployee });
                },

                async getInitialReviewer(id: number) {
                    let initialReviewer = (await apolloClient.query({
                        query: EMPLOYEE_SERVICE_GET_RESOURCE_ITEM,
                        variables: {
                            resourceId: id
                        }
                    })).data.employeeService_resourceItem;
                    this.getDispatchers().setInReduxState({ initialReviewer });
                },

                superLoad: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.superLoad(id);
                    if (entity !== undefined) {
                        if (entity.person !== null && entity.person !== undefined) {
                            await this.getInitialEmployeeData(entity.person);
                        }
                        if (entity.finalReviewer !== null && entity.finalReviewer !== undefined) {
                            await this.getInitialReviewer(entity.finalReviewer);
                        }
                    }
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    entity = this.convertFromObjectToId(entity, PERSON);
                    entity = this.convertFromObjectToId(entity, FINAL_REVIEWER);
                    await this.saveSuper(entity);
                },

                convertFromObjectToId(entity: any, field: string) {
                    if (entity[field] !== null && entity[field] !== undefined && entity[field].id !== undefined) {
                        entity = { ...entity, [field]: entity[field].id };
                    }
                    return entity;
                }
            }
        }).setEntityDescriptor(realizationEntityDescriptor);

        realizationEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceRealizationEditor> & EntityEditorPageProps> {

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "Realization" }, false);
                realizationEntityDescriptor.doForFields(null, (fieldDescriptor) => result.entityDescriptor.addFieldDescriptor(fieldDescriptor));
                result.entityDescriptor.removeFieldDescriptors(PERSON, FINAL_REVIEWER);
                result.entityDescriptor.addFieldDescriptor({ name: PERSON, type: "EmployeeSnapshot" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        const newProps = {
                            ...props,
                            includeAllWithValidContract: true,
                            isClearable: false,
                            queryLimit: -1,
                        };
                        return React.createElement(PersonAssociationFieldEditor as any, newProps as FieldEditorProps);
                    }
                });
                result.entityDescriptor.addFieldDescriptor({ name: FINAL_REVIEWER, type: "Company" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        return React.createElement(ResourceItemAssociationFieldEditor as any, props as FieldEditorProps);
                    }
                });
                result.entity = this.addInitialValues(result.entity, PERSON, this.props.initialEmployee);
                result.entity = this.addInitialValues(result.entity, FINAL_REVIEWER, this.props.initialReviewer);
                return result;
            }

            protected addInitialValues(entity: any, field: string, initialValue: Person) {
                if (entity[field] !== undefined && entity[field] !== null && entity[field].id === undefined && entity[field] === initialValue?.id) {
                    entity = { ...entity, [field]: initialValue };
                }
                return entity;
            }

            protected convertFromObjectToNumber(entity: any, fieldName: string, fieldNameInState: string) {
                if (entity[fieldName] && entity[fieldName].id !== undefined) {
                    this.props.dispatchers.setInReduxState({ [fieldNameInState]: entity[fieldName] });
                    entity = { ...entity, [fieldName]: entity[fieldName].id };
                }
                return entity;
            }

            protected getEntityValuesFromForm() {
                // because in the form the "person" and "finalReviewer" fields are of type "object" and in the table they are number, a conversion
                // is needed when navigating from "edit properties" to "view properties" (from form to table)
                let values = super.getEntityValuesFromForm();
                values = this.convertFromObjectToNumber(values, PERSON, "initialEmployee");
                values = this.convertFromObjectToNumber(values, FINAL_REVIEWER, "initialReviewer");
                return values;
            }
        }
    }
}

export class ResourceItemAssociationFieldEditor extends AssociationFieldEditor<FieldEditorProps>{
    
    protected getLabel(entity: any): string {
        if (entity !== undefined && entity.name !== undefined) {
            return entity?.name + " - " + entity?.id;
        }
        return "";
    }

    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `employeeService_resourceItems`;
        let query = EMPLOYEE_SERVICE_GET_RESOURCE_ITEMS;

        if (!searchQuery || (searchQuery && searchQuery.length < 3)) {
            this.setState({ entities: [] });
            return;
        }
        
        let employees = (await apolloClient.query({
            query: query,
            variables: {
                searchedText: searchQuery
            }
        })).data[name];

        this.setState({ entities: employees });
    }
}

interface PersonAssociationEditorProps {
    includeAllWithValidContract: boolean,
    clearable: boolean,
    hidePersonId?: boolean
}

export class PersonAssociationFieldEditor<P> extends AssociationFieldEditor<P & PersonAssociationEditorProps>{

    protected async performQuery(searchQuery?: string, operationName?: string) {
        let name = `employeeService_employeeSnapshots`;
        let query = EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CONTRACT;

        if ((searchQuery && searchQuery.length < 2) || !searchQuery) {
            this.setState({ entities: [] });
            return;
        }

        let result = (await apolloClient.query({
            query: query, variables: {
                fromDate: new Date(),
                includeAllWithValidContract: this.props.includeAllWithValidContract,
                untilDate: new Date(),
                searchText: searchQuery
            }
        })).data[name];

        this.setState({ entities: result });
    }

    protected getLabel(entity: any): string {
        if (entity !== undefined && entity.name !== undefined) {
            return entity?.name + " " + entity?.firstName + (this.props.hidePersonId !== true ? " - " + entity?.id : "") +
                (entity?.contractHistoryItem?.employeeNumber !== null && entity?.contractHistoryItem?.employeeNumber !== undefined ?
                    " (" + entity?.contractHistoryItem?.employeeNumber + ")" : "");
        }
        return "";
    }
}