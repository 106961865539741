import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { CrudEditorPageRenderHeaderParams, EntityEditorPage, EntityEditorPageProps, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import React from "react";
import { Segment } from "semantic-ui-react";
import { push } from "connected-react-router";
import { FileUploadButton, sliceFileUploadButton } from "@crispico/foundation-react/components/fileUploadButton/FileUploadButton";
import { UploadFile } from "antd/lib/upload/interface";
import { ProteusUtils } from "ProteusUtils";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { ProteusConstants } from "ProteusConstants";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { REPORT_DEFINITION_ADMIN_SERVICE_SAVE } from "graphql/queries";

export class ReportDefinitionAdminEntityDescriptor extends EntityDescriptor {

    getGraphQlFieldsToRequest() {
        return "id name description orderIndex code creationDate creationUser modificationDate modificationUser category { code id description objectVersion} " +
            "objectVersion online reportName rootReport dataSource { name id creationDate creationUser objectVersion } xmls { name id }"
    }

    protected customize() {
        const reportDefinitionAdminEntityDescriptor = this;
        reportDefinitionAdminEntityDescriptor.addFieldDescriptor({ name: "id", type: FieldType.number })
        reportDefinitionAdminEntityDescriptor.addFieldDescriptor({ name: "name", type: FieldType.string })
        reportDefinitionAdminEntityDescriptor.isInDefaultColumnConfig(false, "id");

        reportDefinitionAdminEntityDescriptor.infoTable.slice = createSliceFoundation(class SliceReportDefinitionAdminTable extends SliceEntityTablePage {

            impures = {
                ...sliceEntityTablePageOnlyForExtension.impures,
                ...getBaseImpures<SliceReportDefinitionAdminTable>(this),
        
                /**
                * Filter reports by category: keep only the reports without category or with category !== payroll_file
                */
                adjustFilterBeforeLoad(filter: Filter): Filter {
                    filter.filters?.push({
                        field: undefined,
                        filters: [
                            {
                                field: ProteusConstants.CATEGORY,
                                operator: "isEmpty",
                                value: ""
                            },
                            {
                                field: ProteusConstants.CATEGORY_ID,
                                operator: "notEquals",
                                value: ProteusConstants.PAYROLL_FILE_ID
                            }
                        ],
                        operator: "or"
                    });
                    filter.operator = "and";
                    return filter;
                },
        
                adjustSortsBeforeLoad(sorts: Sort[]): Sort[] {
                    return sorts.concat(Object.assign([{ field: "name", direction: "ASC" }])).filter(item => item.field !== ProteusConstants.ID);
                }
            }
        }).setEntityDescriptor(reportDefinitionAdminEntityDescriptor);

        const sliceReportDefinitionAdminEditor = reportDefinitionAdminEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceReportDefinitionAdminEditor extends SliceEntityEditorPage {
            
            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }
        
            initQueries() {
                super.initQueries();
                this.saveMutation = REPORT_DEFINITION_ADMIN_SERVICE_SAVE;
            }
        
            nestedSlices = {
                ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
                fileUploadButton: sliceFileUploadButton
            }
        
            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                uploadedXMLS: [] as any
            }
        
            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceReportDefinitionAdminEditor>(this),
            }
        
            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceReportDefinitionAdminEditor>(this),
        
                /**
                * Because the save function (server-side) has additional logic, the default "save-service-function" has beed overridden. 
                * Adapt options.variables for the overridden save function
                */   
                superMutation: sliceEntityEditorPageOnlyForExtension.impures.invokeSaveMutation,
                async invokeSaveMutation(options: any) {
                    let localEntity = this.getState().entity;
                    for (let key of ["code", "name"]) {
                        if (this.getState().entity[key] === "") {
                            localEntity = { ...localEntity, [key]: null };
                        }
                    }
                    localEntity = { ...localEntity, xmls: this.getState().uploadedXMLS };
                    options.variables = { entity: localEntity };
                    return await this.superMutation(options);
                },
        
                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    const result = await this.saveSuper(entity);
        
                    //if no exceptions occured, go back to table
                    if (result !== true) {
                        this.getDispatchers().dispatch(push(reportDefinitionAdminEntityDescriptor.getEntityTableUrl()));
                    }
                },
        
                loadSuper: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.loadSuper(id);
                    this.getDispatchers().setInReduxState({ uploadedXMLS: entity?.xmls });
                }
            }
        }).setEntityDescriptor(reportDefinitionAdminEntityDescriptor);
    
        reportDefinitionAdminEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceReportDefinitionAdminEditor> & EntityEditorPageProps> {
            
            protected onUploadFiles = async (files: UploadFile[]) => {
                const fd = new FormData();
                fd.append('entitiesFile', files[files.length - 1].originFileObj!);
                const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
                const url = Utils.adjustUrlToServerContext(additionalUrlParam + 'uploadXML/upload-file');
                const response = await fetch(url!, { method: 'POST', body: fd, headers: { "PROTEUS_PARAM_AUTH": ProteusUtils.getUserName() + ":null" } });
                if (response.status === 200) {
                    const id = await response.text();
                    let uploadedXMLS = this.props.uploadedXMLS ? this.props.uploadedXMLS : [];
                    uploadedXMLS = uploadedXMLS.concat([{ name: (files[files.length - 1].originFileObj! as any).name.split(".")[0], id: Number(id) }]);
                    this.props.dispatchers.setInReduxState({ uploadedXMLS });
                    return _msg("FileUploadButton.uploadSuccessfully");
                }
                return _msg("FileUploadButton.uploadError");
            }
        
            /**
            * When reverting the changes, update uplodedXMLS array with entity's xmls
            */
            protected async onRevert() {
                super.onRevert();
                this.props.dispatchers.setInReduxState({ uploadedXMLS: this.props.entity.xmls });
            }
        
            /**
            * Hide "columnConfig" button from the editor
            */
            renderHeader(params: CrudEditorPageRenderHeaderParams) {
                return super.renderHeader({ columnConfig: false });
            }
        
            /**
            * Add a segment for the file upload button and uploaded files at the buttom of the form
            */
            renderForm() {
                let fileList = [];
                if (this.props.uploadedXMLS !== undefined) {
                    fileList = this.props.uploadedXMLS.map((item: { name: string, id: number }) => ({ name: item.name, uid: String(item.id), type: "select", size: 100 }));
                }
                return (
                    <div>{super.renderForm()}
                        <Segment>
                            <FileUploadButton {...this.props.fileUploadButton} dispatchers={this.props.dispatchers.fileUploadButton} fileList={fileList}
                                showUploadList={true} onUploadFiles={this.onUploadFiles} accept=".jrxml" multiple={false}
                                onRemove={(file) => this.props.dispatchers.setInReduxState({ uploadedXMLS: this.props.uploadedXMLS.filter((item: { name: string; }) => item.name !== file.name) })}>
                            </FileUploadButton>
                        </Segment></div>);
            }
        
            /**
            * Create custom editor by overriding Form's entityDescriptor. By using another descriptor, we can have different fields shown in form/table
            */
            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "ReportDefinition" }, false);
                result.entityDescriptor.addFieldDescriptor({ name: "name", type: FieldType.string });
                result.entityDescriptor.addFieldDescriptor({ name: "code", type: FieldType.string });
                result.entityDescriptor.addFieldDescriptor({ name: "dataSource", type: "AbstractDataSource" });
                result.entityDescriptor.addFieldDescriptor({ name: "category", type: "ReportCategory" });
                result.entityDescriptor.addFieldDescriptor({ name: "orderIndex", type: FieldType.number });
                result.entityDescriptor.addFieldDescriptor({ name: "description", type: FieldType.text });

                // After changes from #34446, column config is initialized earlier and columnsVisibleMap is no longer undefined at the moment the form is rendered. 
                // Because of this, all the added fieldDescriptors have to stay in columnsVisibleMap
                if (result.columnsVisibleMap) {
                    result.columnsVisibleMap["name"] = true;
                    result.columnsVisibleMap["code"] = true;
                    result.columnsVisibleMap["dataSource"] = true;
                    result.columnsVisibleMap["category"] = true;
                    result.columnsVisibleMap["orderIndex"] = true;
                    result.columnsVisibleMap["description"] = true;
                }
                return result;
            }
            
            // because this is a customized descriptor, going from "view property" to "edit property" does not work properly and
            // because the only field from "view property" is "name", this feature is not needed and will be disabled for this screen
            protected getExtraTabPanes(): (TabRouterPane | null)[] {
                return [];
            }
        }
    }
}