'use strict';

/*@ngInject*/
function ConstraintGroups(DataSource) {

	var config = {
		baseUrl : '/restful/constraintGroupsServiceEndpoint'
	};

	var ds = new DataSource(config);

	ds.config.getConstraintGroups = ds.config.getConstraintGroups || {};

	ds.config.getConstraintGroups.method = 'POST';
	ds.config.getConstraintGroups.url = '/constraintGroups';

	if (!ds.getConstraintGroups) {
		ds.registerOperation('getConstraintGroups');
	}

	return ds;
}

module.exports = ConstraintGroups;