import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { apolloClient, EntityDescriptor, Utils } from "@crispico/foundation-react";
import { fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { CodeEntity } from "apollo-gen/CodeEntity";
import { CALENDAR_PROPERTY_CT_SERVICE_GET_CALENDAR_PROPERTIES_CT, DAY_SERVICE_GET_DAYS, DAY_OF_WEEK_INDEX_SERVICE_GET_DAY_OF_WEEK_INDEXES, MONTHS_SERVICE_GET_MONTHS, START_POINT_TYPE_SERVICE_GET_START_POINT_TYPES } from "graphql/queries";
import _ from "lodash";
import moment, { Moment } from "moment";
import { ProteusConstants } from "ProteusConstants";
import React from "react";
import { Button, Checkbox, Dropdown, Form, Grid, Input, Radio, Tab } from "semantic-ui-react";
import { CalendarDefinition, CalendarItem, CalendarProperty } from "./CalendarDefinitionEditor";
import { calendarPropertyDescriptor, CalendarPropertyTableRenderer } from "./customFieldRenderersEditors";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";

export const ALL_DAY = "allDay";
export const DAY = "day";
export const DAYS = "days"
export const DAY_OF_MONTH = "dayOfMonth";
export const DAY_OF_YEAR = "dayOfYear";
export const DESCRIPTION = "description";
export const END_DATE = "endDate";
export const INDEX = "index";
export const INTERVAL = "interval";
export const MONTH = "month";
export const OCCURRENCE = "occurrence";
export const START_DATE = "startDate";
export const START_POINT = "startPoint";
export const SUBJECT = "subject";

export interface CalendarPropertyTable {
    id?: number,
    value: string,
    item: CalendarItem,
    property: Property
}

export interface Property {
    id: number,
    code: string
}

export interface RecurrenceDefinitionDto {
    range?: RecurrenceRangeDto,
    pattern?: RecurrencePatternDto
}

export interface RecurrenceRangeDto {
    startDate?: Date | string,
    endDate?: Date | string,
    occurrence?: number,
    objectType?: RecurrenceRangeEnum
}

export interface RecurrencePatternDto {
    interval?: number,
    day?: CodeEntity,
    index?: CodeEntity,
    dayOfMonth?: number,
    startPoint?: CodeEntity,
    days?: CodeEntity[],
    month?: CodeEntity,
    dayOfYear?: number,
    objectType?: RecurrencePatternEnum
}

enum RecurrencePatternEnum {
    DAILY = "DAILY",
    EVERY_WEEKDAY = "EVERY_WEEKDAY",
    RELATIVE_WEEKLY = "RELATIVE_WEEKLY",
    RELATIVE_MONTHLY = "RELATIVE_MONTHLY",
    RELATIVE_START_MONTHLY = "RELATIVE_START_MONTHLY",
    ABSOLUTE_YEARLY = "ABSOLUTE_YEARLY",
    RELATIVE_YEARLY = "RELATIVE_YEARLY",
    RELATIVE_START_YEARLY = "RELATIVE_START_YEARLY"
}

enum RecurrenceRangeEnum {
    UNLIMITED = "UNLIMITED",
    NUMBERED = "NUMBERED",
    END_DATE = "END_DATE"
}

export const sliceCalendarItemEditor = createSliceFoundation(class SliceCalendarItemEditor {

    initialState = {
        calendarDefinition: undefined as unknown as CalendarDefinition,
        calendarPropertiesCt: undefined as unknown as CodeEntity[],
        days: undefined as unknown as CodeEntity[],
        dayOfWeekIndexes: undefined as unknown as CodeEntity[],
        startPointTypes: undefined as unknown as CodeEntity[],
        months: undefined as unknown as CodeEntity[]
    }

    nestedSlices = {
        calendarPropertiesTable: sliceEntityTableLight
    }

    reducers = {
        ...getBaseReducers<SliceCalendarItemEditor>(this)
    }

    impures = {
        ...getBaseImpures<SliceCalendarItemEditor>(this),

        async getConstantData() {
            if (this.getState().calendarPropertiesCt === undefined) {
                const calendarPropertiesCt = (await apolloClient.query({ query: CALENDAR_PROPERTY_CT_SERVICE_GET_CALENDAR_PROPERTIES_CT })).data.calendarPropertyCtService_calendarPropertiesCt;
                const days = (await apolloClient.query({ query: DAY_SERVICE_GET_DAYS })).data.dayService_days;
                const dayOfWeekIndexes = (await apolloClient.query({ query: DAY_OF_WEEK_INDEX_SERVICE_GET_DAY_OF_WEEK_INDEXES })).data.dayOfWeekIndexService_dayOfWeekIndexes;
                const startPointTypes = (await apolloClient.query({ query: START_POINT_TYPE_SERVICE_GET_START_POINT_TYPES })).data.startPointTypeService_startPointTypes;
                const months = (await apolloClient.query({ query: MONTHS_SERVICE_GET_MONTHS })).data.monthService_months;
                this.getDispatchers().setInReduxState({ calendarPropertiesCt, days, dayOfWeekIndexes, startPointTypes, months });
            }
        }
    }
})

type PropsNotFromState = {
    calendar: CalendarDefinition;
    /**
     * isOwnItem is: 
     * 1) undefined when a new form is opened (a new item is added)
     * 2) true when an item is edited (i.e. the item belongs to the current calendar)
     * 3) false when an item is in "view" mode (i.e. the item belongs to extend calendars and can not be edited)
     */
    currentCalendarItem: { id: number | undefined, subject: string | undefined, isOwnItem: boolean | undefined };
    defaultStartDateValue: Date | string
}

export class CalendarItemEditor extends React.Component<PropsFrom<typeof sliceCalendarItemEditor> & PropsNotFromState> {
    protected entityTableLightRef = React.createRef<EntityTableLight>();

    setCalendarPropertiesTable(calendarProperties: CalendarProperty[]) {
        let calendarPropertiesTable: CalendarPropertyTable[] = [];
        for (let i = 0; i < calendarProperties.length; i++) {
            calendarPropertiesTable.push({
                ...calendarProperties[i],
                property: {
                    code: calendarProperties[i].code,
                    id: this.props.calendarPropertiesCt.filter((item: CodeEntity) => item.code === calendarProperties[i].code)[0].id!
                }
            });
        }
        // calendar properties are sorted ascending by code
        this.entityTableLightRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([...calendarPropertiesTable].sort((a: CalendarPropertyTable, b: CalendarPropertyTable) =>
            a.property.code > b.property.code ? 1 : -1
        ));
    }

    ////////////////////////////////////
    // METHODS USED BY SUBJECT TAB (FIRST TAB)
    ////////////////////////////////////

    changeSubjectTabFieldsValue(currentCalendarItem: CalendarItem | undefined, fieldName: string, fieldValue: string | boolean | undefined | null | Moment | Date) {
        if (currentCalendarItem === undefined) {
            return;
        }

        let items = [...this.props.calendarDefinition.items];
        let properties = [...this.props.calendarDefinition.properties];
        let currentItemIndex = this.getCurrentItemIndex();
        let item = items.splice(currentItemIndex, 1)[0];
        if (fieldName === SUBJECT) {
            // because the property object contains the calendar item and because there might be more unsaved calendar items and properties,
            // when the calendar subject is changed, it must be updated inside the corresponding property too, to not lose the reference between them
            properties = this.updatePropertyItemAfterSubjectIsUpdated(item, fieldValue!.toString());
        }
        item = { ...item, [fieldName]: fieldValue };
        if (fieldName === START_DATE) {
            item = this.updateRecurrenceDefinitionStartDate(item);

            if (item[ALL_DAY]) {
                item = this.updateItemEndDate(item);
            }
        }
        if (fieldName === ALL_DAY && fieldValue === true) {
            item = this.updateItemEndDate(item);
            item = this.updateRecurrenceDefinitionStartDate(item);
        }
        items.splice(currentItemIndex, 0, item);
        let newCalendar = { ...this.props.calendarDefinition, items, properties };
        if (fieldName === SUBJECT) {
            this.getPropertiesForCurrentCalendarItem(newCalendar);
        }
        this.props.dispatchers.setInReduxState({ calendarDefinition: newCalendar });
    }

    updateRecurrenceDefinitionStartDate(item: CalendarItem) {
        if (item.recurrenceDefinitionDto !== null) {
            item = { ...item, recurrenceDefinitionDto: { ...item.recurrenceDefinitionDto, range: { ...item.recurrenceDefinitionDto?.range, startDate: item.startDate } } };
        }
        return item;
    }

    updateItemEndDate(item: CalendarItem) {
        return { ...item, endDate: new Date(moment(item.startDate).endOf("date").toDate()) };
    }

    updatePropertyItemAfterSubjectIsUpdated(item: CalendarItem, fieldValue: string) {
        let newProperties = [...this.props.calendarDefinition.properties];
        let itemProperties = this.props.calendarDefinition.properties.filter((prop: CalendarProperty) => prop.item.subject === item.subject);
        for (let i = 0; i < itemProperties.length; i++) {
            let index = newProperties.findIndex((prop: CalendarProperty) => prop.code === itemProperties[i].code && prop.item.subject === itemProperties[i].item.subject);
            newProperties.splice(index, 1);
            newProperties.push({ ...itemProperties[i], item: { ...itemProperties[i].item, subject: fieldValue } });
        }
        return newProperties;
    }

    getTime(currentCalendarItem: CalendarItem | undefined, fieldName: String) {
        if (currentCalendarItem === undefined || (this.props.currentCalendarItem?.isOwnItem === undefined &&
            ((fieldName === START_DATE && currentCalendarItem.startDate === undefined) ||
                (fieldName === END_DATE && currentCalendarItem.endDate === undefined)))) {
            return undefined;
        }
        if (fieldName === START_DATE) {
            return moment(currentCalendarItem.startDate);
        } else {
            return moment(currentCalendarItem.endDate);
        }
    }

    getAllDayCheckboxState(currentCalendarItem: CalendarItem | undefined) {
        if (currentCalendarItem === undefined || (this.props.currentCalendarItem?.isOwnItem === undefined && currentCalendarItem.allDay === undefined)) {
            return false;
        }
        return currentCalendarItem.allDay;
    }

    disableEndDate(current: Moment, currentCalendarItem: CalendarItem | undefined): boolean {
        // Can not select days before startDate
        return current && current < moment(currentCalendarItem?.startDate);
    }

    disableStartDate(current: Moment, currentCalendarItem: CalendarItem | undefined): boolean {
        // Can not select days after endDate
        if (currentCalendarItem && currentCalendarItem.endDate) {
            return current && current > moment(currentCalendarItem?.endDate);
        }
        return false;
    }

    renderSubjectTab() {
        const currentCalendarItem = this.getCurrentCalendarItem();
        return <Tab.Pane>
            <fieldset disabled={this.props.currentCalendarItem.isOwnItem === false} className="CalendarItemEditor_disabledFieldset">
                <Grid doubling>
                    <Grid.Row columns={2}>
                        <Grid.Column width="12">
                            <Form.Input label={_msg("CalendarItemEditor.subject.label")} placeholder={_msg("CalendarItemEditor.subject.label")}
                                value={currentCalendarItem?.subject || ""}
                                onChange={(e, data) => this.changeSubjectTabFieldsValue(currentCalendarItem, SUBJECT, data.value)} />
                            <Form.TextArea label={_msg("CalendarItemEditor.description.label")} placeholder={_msg("CalendarItemEditor.description.label")}
                                value={currentCalendarItem?.description || ""} rows={4}
                                onChange={(e, data) => this.changeSubjectTabFieldsValue(currentCalendarItem, DESCRIPTION, String(data.value))} />
                        </Grid.Column>
                        <Grid.Column width="4">
                            <Form.Field className="CalendarItemEditor_dateField">
                                <label>{_msg("CalendarItemEditor.startDate.label")}</label>
                                <DatePickerReactCalendar placeholder={_msg("CalendarItemEditor.startDate.label")} format={ProteusConstants.DATE_TIME_FORMAT}
                                    value={this.getTime(currentCalendarItem, START_DATE)}
                                    onChange={(date) => this.changeSubjectTabFieldsValue(currentCalendarItem, START_DATE, new Date(date!.toDate()))}
                                    allowClear={false} disabledDate={(current: Moment) => this.disableStartDate(current, currentCalendarItem)}
                                />
                            </Form.Field>
                            <Form.Field disabled={this.getAllDayCheckboxState(currentCalendarItem)} className="CalendarItemEditor_dateField">
                                <label>{_msg("CalendarItemEditor.endDate.label")}</label>
                                <DatePickerReactCalendar placeholder={_msg("CalendarItemEditor.endDate.label")} format={ProteusConstants.DATE_TIME_FORMAT}
                                    value={this.getTime(currentCalendarItem, END_DATE)}
                                    onChange={(date) => this.changeSubjectTabFieldsValue(currentCalendarItem, END_DATE, new Date(date!.toDate()))}
                                    allowClear={false} disabledDate={(current: Moment) => this.disableEndDate(current, currentCalendarItem)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{_msg("CalendarItemEditor.allDay.label")}</label>
                                <Checkbox checked={this.getAllDayCheckboxState(currentCalendarItem)}
                                    onChange={(e, data) => this.changeSubjectTabFieldsValue(currentCalendarItem, ALL_DAY, data.checked)} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </fieldset>
        </Tab.Pane>
    }

    ////////////////////////////////////
    // METHODS USED BY PATTERN TAB (SECOND TAB)
    ////////////////////////////////////

    renderPatternTab() {
        const currentCalendarItem = this.getCurrentCalendarItem();
        const pattern = currentCalendarItem?.recurrenceDefinitionDto?.pattern;
        return <Tab.Pane>
            <fieldset disabled={this.props.currentCalendarItem.isOwnItem === false} className="CalendarItemEditor_disabledFieldset">
                <Grid doubling className="CalendarItemEditor_patternTab">
                    <Grid.Row columns={2}>
                        <Grid.Column width="3">
                            <div>
                                <Form.Field>
                                    <Radio label={_msg("CalendarItemEditor.none.label")} checked={currentCalendarItem?.recurrenceDefinitionDto === null}
                                        onChange={() => this.onPatternChange(null)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Radio label={_msg("CalendarItemEditor.daily.label")}
                                        checked={pattern?.objectType === RecurrencePatternEnum.DAILY || pattern?.objectType === RecurrencePatternEnum.EVERY_WEEKDAY}
                                        onChange={() => this.onPatternChange(RecurrencePatternEnum.DAILY)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Radio label={_msg("CalendarItemEditor.weekly.label")}
                                        checked={pattern?.objectType === RecurrencePatternEnum.RELATIVE_WEEKLY}
                                        onChange={() => this.onPatternChange(RecurrencePatternEnum.RELATIVE_WEEKLY)} />
                                </Form.Field>
                                <Form.Field>
                                    <Radio label={_msg("CalendarItemEditor.monthly.label")}
                                        checked={pattern?.objectType === RecurrencePatternEnum.RELATIVE_MONTHLY || pattern?.objectType === RecurrencePatternEnum.RELATIVE_START_MONTHLY}
                                        onChange={() => this.onPatternChange(RecurrencePatternEnum.RELATIVE_MONTHLY)} />
                                </Form.Field>
                                <Form.Field>
                                    <Radio label={_msg("CalendarItemEditor.yearly.label")}
                                        checked={pattern?.objectType === RecurrencePatternEnum.ABSOLUTE_YEARLY || pattern?.objectType === RecurrencePatternEnum.RELATIVE_YEARLY ||
                                            pattern?.objectType === RecurrencePatternEnum.RELATIVE_START_YEARLY}
                                        onChange={() => this.onPatternChange(RecurrencePatternEnum.ABSOLUTE_YEARLY)} />
                                </Form.Field>
                            </div>
                        </Grid.Column>
                        <div key="div1" className="EntityTablePage_barDivider CalendarItemEditor_barDivider" />
                        <Grid.Column width="12">
                            {(pattern?.objectType === RecurrencePatternEnum.DAILY || pattern?.objectType === RecurrencePatternEnum.EVERY_WEEKDAY) && this.renderDailyForm(pattern)}
                            {pattern?.objectType === RecurrencePatternEnum.RELATIVE_WEEKLY && this.renderWeeklyForm(pattern)}
                            {(pattern?.objectType === RecurrencePatternEnum.RELATIVE_MONTHLY || pattern?.objectType === RecurrencePatternEnum.RELATIVE_START_MONTHLY) && this.renderMonthlyForm(pattern)}
                            {(pattern?.objectType === RecurrencePatternEnum.ABSOLUTE_YEARLY || pattern?.objectType === RecurrencePatternEnum.RELATIVE_YEARLY ||
                                pattern?.objectType === RecurrencePatternEnum.RELATIVE_START_YEARLY) && this.renderYearlyForm(pattern)}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </fieldset>
        </Tab.Pane>
    }

    onPatternChange(objectType: RecurrencePatternEnum | null) {
        const currentCalendarItem = this.getCurrentCalendarItem()!;
        let pattern: RecurrencePatternDto | null = {};
        let range: RecurrenceRangeDto = { ...currentCalendarItem.recurrenceDefinitionDto?.range };
        if (currentCalendarItem.recurrenceDefinitionDto === null) {
            range = {
                objectType: RecurrenceRangeEnum.UNLIMITED,
                startDate: currentCalendarItem.startDate
            };
        }
        if (objectType === null) {
            pattern = null;
        } else if (objectType === RecurrencePatternEnum.DAILY) {
            pattern = {
                interval: 1
            };
        } else if (objectType === RecurrencePatternEnum.RELATIVE_WEEKLY) {
            pattern = {
                days: [],
                interval: 1
            };
        } else if (objectType === RecurrencePatternEnum.RELATIVE_MONTHLY) {
            pattern = {
                interval: 1,
                day: this.props.days[0],
                index: this.props.dayOfWeekIndexes[0]
            };
        } else if (objectType === RecurrencePatternEnum.RELATIVE_START_MONTHLY) {
            pattern = {
                interval: 1,
                dayOfMonth: 1,
                startPoint: this.props.startPointTypes[0]
            };
        } else if (objectType === RecurrencePatternEnum.ABSOLUTE_YEARLY) {
            pattern = {
                interval: 1,
                dayOfMonth: 1,
                month: this.props.months[0]
            };
        } else if (objectType === RecurrencePatternEnum.RELATIVE_YEARLY) {
            pattern = {
                interval: 1,
                index: this.props.dayOfWeekIndexes[0],
                day: this.props.days[0],
                month: this.props.months[0]
            };
        } else if (objectType === RecurrencePatternEnum.RELATIVE_START_YEARLY) {
            pattern = {
                dayOfYear: 1,
                startPoint: this.props.startPointTypes[0],
                interval: 1
            }
        }
        let recurrenceDefinitionDto: RecurrenceDefinitionDto | null | undefined = null;
        if (pattern !== null && objectType !== null) {
            pattern = { ...pattern, objectType }
            recurrenceDefinitionDto = { ...currentCalendarItem.recurrenceDefinitionDto, pattern, range };
        }
        this.updateCurrentItemRecurrenceDefinitionDto(currentCalendarItem, recurrenceDefinitionDto);
    }

    updateCurrentItemRecurrenceDefinitionDto(currentCalendarItem: CalendarItem, recurrenceDefinitionDto: RecurrenceDefinitionDto | null) {
        let recurring = currentCalendarItem.recurring;
        if (recurring === false && recurrenceDefinitionDto !== null) {
            recurring = true;
        } else if (recurring === true && recurrenceDefinitionDto === null) {
            recurring = false;
        }
        let items = [...this.props.calendarDefinition.items];
        let currentItemIndex = this.getCurrentItemIndex();
        let item = items.splice(currentItemIndex, 1)[0];
        item = { ...item, recurrenceDefinitionDto, recurring };
        items.splice(currentItemIndex, 0, item);
        this.props.dispatchers.setInReduxState({ calendarDefinition: { ...this.props.calendarDefinition, items } });
    }

    updatePatternValues(fieldName: string, fieldValue: any) {
        const currentCalendarItem = this.getCurrentCalendarItem();
        if (currentCalendarItem !== undefined) {
            const recurrenceDefinitionDto = {
                ...currentCalendarItem.recurrenceDefinitionDto,
                pattern: {
                    ...currentCalendarItem.recurrenceDefinitionDto?.pattern,
                    [fieldName]: fieldValue
                }
            };
            this.updateCurrentItemRecurrenceDefinitionDto(currentCalendarItem, recurrenceDefinitionDto);
        }
    }

    onPatternFieldNumberChange(value: string, fieldName: string) {
        this.updatePatternValues(fieldName, value.toString().trim().length === 0 || value.toString() === "0" ? 1 : Number(value));
    }

    onDropdownItemChange(value: any, entity: CodeEntity[], fieldName: string) {
        const values = this.getValuesFromDropdown(value, entity);
        this.updatePatternValues(fieldName, values);
    }

    getOptionsFromEntity(entity: CodeEntity[]) {
        if (entity === undefined) {
            return [];
        }
        return entity.map((item: CodeEntity) => ({
            key: item.id as number,
            text: item.description as string,
            value: item.id as number
        }));
    }

    getValuesForDropdown(entity: CodeEntity[] | CodeEntity | undefined, multiple?: boolean) {
        if (entity === undefined || entity === null) {
            return multiple ? [] : "";
        } else if (Array.isArray(entity)) {
            return entity.map((item: CodeEntity) => item.id!);
        } else {
            return entity.id!;
        }
    }

    getValuesFromDropdown(values: any, entity: CodeEntity[]) {
        if (values === undefined) {
            return [];
        } else if (Array.isArray(values)) {
            return entity.filter((item: CodeEntity) => item.id !== null && values.includes(item.id));
        } else {
            return entity.filter((item: CodeEntity) => item.id === values)[0];
        }
    }

    renderNumberInput(disabled: boolean, value: any, fieldName: string, maxFieldValue?: string) {
        return <Input type="number" disabled={disabled} value={value || ""} min="1" max={maxFieldValue}
            onChange={(e) => {
                let value = e.target.value;
                if (maxFieldValue !== undefined && Number(value) > Number(maxFieldValue)) {
                    value = maxFieldValue;
                }
                this.onPatternFieldNumberChange(value, fieldName);
            }
            } className="CalendarItemEditor_numericInput" />
    }

    renderDropdown(entity: CodeEntity[], multiple: boolean, fieldName: string, fieldValue: any, disabled: boolean) {
        return <Dropdown fluid floating selectOnBlur={false} scrolling wrapSelection search={true} options={this.getOptionsFromEntity(entity)}
            selection multiple={multiple} value={this.getValuesForDropdown(fieldValue, multiple)} className="CalendarItemEditor_dropdownInput"
            onChange={(e, data) => this.onDropdownItemChange(data.value, entity, fieldName)} disabled={disabled} noResultsMessage={_msg("general.noResultsFound")} />
    }

    renderDailyForm(pattern: RecurrencePatternDto) {
        return (
            <div className="CalendarItemEditor_dailyForm">
                <Form.Group>
                    <Form.Field>
                        <Radio label={_msg("CalendarItemEditor.each.label")} checked={pattern?.objectType === RecurrencePatternEnum.DAILY}
                            onChange={() => this.onPatternChange(RecurrencePatternEnum.DAILY)} />
                    </Form.Field>
                    <Form.Field>
                        {this.renderNumberInput(pattern?.objectType !== RecurrencePatternEnum.DAILY, pattern?.interval, INTERVAL)}
                    </Form.Field>
                    <label>
                        {_msg("CalendarItemEditor.days.label").toLowerCase()}
                    </label>
                </Form.Group>
                <Form.Field>
                    <Radio label={_msg("CalendarItemEditor.everyWeekday.label")} checked={pattern?.objectType === RecurrencePatternEnum.EVERY_WEEKDAY}
                        onChange={() => this.onPatternChange(RecurrencePatternEnum.EVERY_WEEKDAY)} />
                </Form.Field>
            </div>
        );
    }

    renderWeeklyForm(pattern: RecurrencePatternDto) {
        return (
            <div className="CalendarItemEditor_dailyForm CalendarItemEditor_weeklyForm">
                <Form.Group>
                    <label>
                        {_msg("CalendarItemEditor.returnEvery.label")}
                    </label>
                    <Form.Field>
                        {this.renderNumberInput(false, pattern?.interval, INTERVAL)}
                    </Form.Field>
                    <label>
                        {_msg("CalendarItemEditor.weeks.label").toLowerCase()}
                    </label>
                </Form.Group>
                <Form.Field>
                    {this.renderDropdown(this.props.days, true, DAYS, pattern?.days, false)}
                </Form.Field>
            </div>
        );
    }

    renderMonthlyForm(pattern: RecurrencePatternDto) {
        return (
            <>
                <Grid.Column>
                    <div className="CalendarItemEditor_dailyForm">
                        <Form.Group>
                            <Form.Field>
                                <Radio label={_msg("CalendarItemEditor.the.label")} checked={pattern?.objectType === RecurrencePatternEnum.RELATIVE_MONTHLY}
                                    onChange={() => this.onPatternChange(RecurrencePatternEnum.RELATIVE_MONTHLY)} />
                            </Form.Field>
                            <Form.Field>
                                {this.renderDropdown(this.props.dayOfWeekIndexes, false, INDEX, pattern?.index, pattern?.objectType !== RecurrencePatternEnum.RELATIVE_MONTHLY)}
                            </Form.Field>
                            <Form.Field>
                                {this.renderDropdown(this.props.days, false, DAY, pattern?.day, pattern?.objectType !== RecurrencePatternEnum.RELATIVE_MONTHLY)}
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field>
                                <Radio label={_msg("CalendarItemEditor.day.label")} checked={pattern?.objectType === RecurrencePatternEnum.RELATIVE_START_MONTHLY}
                                    onChange={() => this.onPatternChange(RecurrencePatternEnum.RELATIVE_START_MONTHLY)} />
                            </Form.Field>
                            <Form.Field>
                                {this.renderNumberInput(pattern?.objectType !== RecurrencePatternEnum.RELATIVE_START_MONTHLY, pattern?.dayOfMonth || "", DAY_OF_MONTH, "31")}
                            </Form.Field>
                            <Form.Field>
                                {this.renderDropdown(this.props.startPointTypes, false, START_POINT, pattern?.startPoint, pattern?.objectType !== RecurrencePatternEnum.RELATIVE_START_MONTHLY)}
                            </Form.Field>
                        </Form.Group>
                    </div>
                </Grid.Column>
                <div key="div1" className="EntityTablePage_barDivider CalendarItemEditor_barDivider" />
                <Grid.Column className="CalendarItemEditor_dailyForm CalendarItemEditor_monthlyForm">
                    <Form.Group>
                        <label>
                            {_msg("CalendarItemEditor.each.label").toLowerCase()}
                        </label>
                        <Form.Field>
                            {this.renderNumberInput(false, pattern?.interval, INTERVAL)}
                        </Form.Field>
                        <label>
                            {_msg("CalendarItemEditor.months.label").toLowerCase()}
                        </label>
                    </Form.Group>
                </Grid.Column>
            </>
        );
    }

    renderYearlyForm(pattern: RecurrencePatternDto) {
        return (
            <>
                <Grid.Column>
                    <div className="CalendarItemEditor_dailyForm">
                        <Form.Group>
                            <Form.Field>
                                <Radio label={_msg("CalendarItemEditor.each.label")} checked={pattern?.objectType === RecurrencePatternEnum.ABSOLUTE_YEARLY}
                                    onChange={() => this.onPatternChange(RecurrencePatternEnum.ABSOLUTE_YEARLY)} />
                            </Form.Field>
                            <Form.Field>
                                {this.renderDropdown(this.props.months, false, MONTH, pattern?.objectType !== RecurrencePatternEnum.ABSOLUTE_YEARLY ? "" : pattern?.month,
                                    pattern?.objectType !== RecurrencePatternEnum.ABSOLUTE_YEARLY)}
                            </Form.Field>
                            <Form.Field>
                                {this.renderNumberInput(pattern?.objectType !== RecurrencePatternEnum.ABSOLUTE_YEARLY, pattern?.dayOfMonth || "", DAY_OF_MONTH, "31")}
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field>
                                <Radio label={_msg("CalendarItemEditor.the.label")} checked={pattern?.objectType === RecurrencePatternEnum.RELATIVE_YEARLY}
                                    onChange={() => this.onPatternChange(RecurrencePatternEnum.RELATIVE_YEARLY)} />
                            </Form.Field>
                            <Form.Field>
                                {this.renderDropdown(this.props.dayOfWeekIndexes, false, INDEX, pattern?.index, pattern?.objectType !== RecurrencePatternEnum.RELATIVE_YEARLY)}
                            </Form.Field>
                            <Form.Field>
                                {this.renderDropdown(this.props.days, false, DAY, pattern?.day, pattern?.objectType !== RecurrencePatternEnum.RELATIVE_YEARLY)}
                            </Form.Field>
                            <label>
                                {_msg("CalendarItemEditor.of.label").toLowerCase()}
                            </label>
                            <Form.Field>
                                {this.renderDropdown(this.props.months, false, MONTH, pattern?.objectType !== RecurrencePatternEnum.RELATIVE_YEARLY ? "" : pattern?.month,
                                    pattern?.objectType !== RecurrencePatternEnum.RELATIVE_YEARLY)}
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field>
                                <Radio label={_msg("CalendarItemEditor.day.label")} checked={pattern?.objectType === RecurrencePatternEnum.RELATIVE_START_YEARLY}
                                    onChange={() => this.onPatternChange(RecurrencePatternEnum.RELATIVE_START_YEARLY)} />
                            </Form.Field>
                            <Form.Field>
                                {this.renderNumberInput(pattern?.objectType !== RecurrencePatternEnum.RELATIVE_START_YEARLY, pattern?.dayOfYear || "", DAY_OF_YEAR, "366")}
                            </Form.Field>
                            <Form.Field>
                                {this.renderDropdown(this.props.startPointTypes, false, START_POINT, pattern?.startPoint, pattern?.objectType !== RecurrencePatternEnum.RELATIVE_START_YEARLY)}
                            </Form.Field>
                        </Form.Group>
                    </div>
                </Grid.Column>
                <div key="div1" className="EntityTablePage_barDivider CalendarItemEditor_barDivider" />
                <Grid.Column className="CalendarItemEditor_dailyForm CalendarItemEditor_monthlyForm">
                    <Form.Group>
                        <label>
                            {_msg("CalendarItemEditor.each.label").toLowerCase()}
                        </label>
                        <Form.Field>
                            {this.renderNumberInput(false, pattern?.interval, INTERVAL)}
                        </Form.Field>
                        <label>
                            {_msg("CalendarItemEditor.years.label").toLowerCase()}
                        </label>
                    </Form.Group>
                </Grid.Column>
            </>
        );
    }

    ////////////////////////////////////
    // METHODS USED BY RANGE TAB (THIRD TAB)
    ////////////////////////////////////

    onRangeChange(objectType: RecurrenceRangeEnum) {
        const currentCalendarItem = this.getCurrentCalendarItem()!;
        let range: RecurrenceRangeDto = { startDate: currentCalendarItem.startDate };
        if (objectType === RecurrenceRangeEnum.NUMBERED) {
            range.occurrence = 1;
        } else if (objectType === RecurrenceRangeEnum.END_DATE) {
            range.endDate = new Date(moment(range.startDate).endOf("date").toDate());
        }
        range = { ...range, objectType };
        const recurrenceDefinitionDto = { ...currentCalendarItem.recurrenceDefinitionDto, range };
        this.updateCurrentItemRecurrenceDefinitionDto(currentCalendarItem, recurrenceDefinitionDto);
    }

    updateRangeValues(fieldName: string, fieldValue: any) {
        const currentCalendarItem = this.getCurrentCalendarItem();
        if (currentCalendarItem !== undefined) {
            const recurrenceDefinitionDto = {
                ...currentCalendarItem.recurrenceDefinitionDto,
                range: {
                    ...currentCalendarItem.recurrenceDefinitionDto?.range,
                    [fieldName]: fieldValue
                }
            };
            this.updateCurrentItemRecurrenceDefinitionDto(currentCalendarItem, recurrenceDefinitionDto);
        }
    }

    renderRangeTab() {
        const currentCalendarItem = this.getCurrentCalendarItem();
        const range = currentCalendarItem?.recurrenceDefinitionDto?.range;
        return <Tab.Pane>
            <fieldset disabled={this.props.currentCalendarItem.isOwnItem === false || currentCalendarItem?.recurrenceDefinitionDto === null}
                className="CalendarItemEditor_disabledFieldset">
                <Grid doubling className="CalendarItemEditor_rangeTab">
                    <Grid.Row columns={2}>
                        <Grid.Column width="5">
                            <div className="CalendarItemEditor_dailyForm">
                                <Form.Group>
                                    <label>
                                        {_msg("CalendarItemEditor.begin.label")}
                                    </label>
                                    <Form.Field disabled>
                                        <DatePickerReactCalendar value={range?.startDate === undefined ? undefined : moment(range?.startDate)}
                                            format={ProteusConstants.DATE_TIME_FORMAT} />
                                    </Form.Field>
                                </Form.Group>
                            </div>
                        </Grid.Column>
                        <div key="div1" className="EntityTablePage_barDivider CalendarItemEditor_barDivider" />
                        <Grid.Column>
                            <div className="CalendarItemEditor_dailyForm">
                                <Form.Field>
                                    <Radio label={_msg("CalendarItemEditor.noEndDate.label")} checked={range?.objectType === RecurrenceRangeEnum.UNLIMITED}
                                        onChange={() => this.onRangeChange(RecurrenceRangeEnum.UNLIMITED)} />
                                </Form.Field>
                                <Form.Group>
                                    <Form.Field>
                                        <Radio label={_msg("CalendarItemEditor.endsAfter.label")} checked={range?.objectType === RecurrenceRangeEnum.NUMBERED}
                                            onChange={() => this.onRangeChange(RecurrenceRangeEnum.NUMBERED)} />
                                    </Form.Field>
                                    <Form.Field>
                                        <Input type="number" disabled={range?.objectType !== RecurrenceRangeEnum.NUMBERED} value={range?.occurrence || ""} min="1"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                this.updateRangeValues(OCCURRENCE, value.toString().trim().length === 0 || value.toString() === "0" ? 1 : Number(value))
                                            }}
                                            className="CalendarItemEditor_numericInput" />
                                    </Form.Field>
                                    <label>
                                        {_msg("CalendarItemEditor.copies.label").toLowerCase()}
                                    </label>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field>
                                        <Radio label={_msg("CalendarItemEditor.endsWith.label")} checked={range?.objectType === RecurrenceRangeEnum.END_DATE}
                                            onChange={() => this.onRangeChange(RecurrenceRangeEnum.END_DATE)} />
                                    </Form.Field>
                                    <Form.Field disabled={range?.objectType !== RecurrenceRangeEnum.END_DATE}>
                                        <DatePickerReactCalendar value={range?.objectType !== RecurrenceRangeEnum.END_DATE ? undefined : moment(range?.endDate)}
                                            format={ProteusConstants.DATE_TIME_FORMAT} allowClear={false}
                                            onChange={(date) => this.updateRangeValues(END_DATE, new Date(date!.toDate()))} />
                                    </Form.Field>
                                </Form.Group>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </fieldset>
        </Tab.Pane>
    }

    ////////////////////////////////////
    // METHODS USED BY PROPERTIES TABLE
    ////////////////////////////////////

    addOrUpdateProperty(property: { rowIndex: number, values: CalendarPropertyTable }) {
        if (property.values.property === undefined || property.values.property === null || property.values.value === undefined || property.values.value.trim().length === 0) {
            Utils.showGlobalAlert({ message: _msg("GroupSnapshotTab.messages.propertyCanNotBeAdded"), severity: Severity.INFO });
            return;
        }

        let properties = [...this.props.calendarDefinition.properties];
        let newProperty: CalendarProperty | undefined = undefined;
        if (property.rowIndex === -1) {
            // add new property to item
            const propertyAlreadyExists = this.props.calendarDefinition.properties.filter((prop: CalendarProperty) =>
                prop.code === property.values.property.code && ((this.props.currentCalendarItem.id !== undefined && prop.item.id === this.props.currentCalendarItem.id) ||
                    prop.item.subject === this.getCurrentCalendarItem()?.subject
                )).length > 0;
            if (!propertyAlreadyExists) {
                let currentItem = { ...this.getCurrentCalendarItem()! };
                delete currentItem.isNew;
                newProperty = { code: property.values.property.code, value: property.values.value.trim(), item: currentItem };
            } else {
                Utils.showGlobalAlert({ message: _msg('GroupSnapshotTab.messages.propertyAlreadyExists'), severity: Severity.INFO });
                return;
            }
        } else {
            // edit property
            let propertyIndex = properties.findIndex((prop: CalendarProperty) => prop.code === property.values.property.code && prop.item.id === property.values.item.id);
            if (propertyIndex === -1) {
                let currentItem = { ...this.getCurrentCalendarItem()! };
                delete currentItem.isNew;
                newProperty = { code: property.values.property.code, value: property.values.value.trim(), item: currentItem };
            } else {
                // CZ: I need tableIndex too because properties are sorted by code in calendarPropertiesTable, so the index can be different from the one from calendarDefinition properties
                let tableIndex = this.entityTableLightRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities().findIndex((prop: CalendarPropertyTable) => prop.property.code === property.values.property.code && prop.item.id === property.values.item.id);
                if (tableIndex !== property.rowIndex) {
                    // CZ: Edit of a existing property (same code) started from other property's form
                    Utils.showGlobalAlert({ message: _msg('GroupSnapshotTab.messages.propertyAlreadyExists'), severity: Severity.INFO });
                    return;
                }
                newProperty = properties.filter((prop: CalendarProperty) => prop.code === property.values.property.code && prop.item.id === property.values.item.id)[0];
                newProperty = { ...newProperty, value: property.values.value.trim() };
                properties.splice(propertyIndex, 1);
            }
        }
        if (newProperty !== undefined) {
            properties.push(newProperty);
        }
        const newCalendar = { ...this.props.calendarDefinition, properties: properties };
        this.props.dispatchers.setInReduxState({ calendarDefinition: newCalendar });
        this.getPropertiesForCurrentCalendarItem(newCalendar);
    }

    removeProperty(propertyTable: CalendarPropertyTable) {
        let properties = this.props.calendarDefinition.properties.filter((item: CalendarProperty) =>
            item.code !== propertyTable.property.code || item.item.subject !== propertyTable.item.subject);
        this.props.dispatchers.setInReduxState({ calendarDefinition: { ...this.props.calendarDefinition, properties } });
    }

    getPropertiesForCurrentCalendarItem(calendar: CalendarDefinition) {
        let properties: CalendarProperty[] = [];
        if (this.props.currentCalendarItem.isOwnItem || this.props.currentCalendarItem.isOwnItem === undefined) {
            properties = calendar.properties.filter((property: CalendarProperty) => {
                if (this.props.currentCalendarItem.id !== undefined) {
                    return this.props.currentCalendarItem.id === property.item.id;
                } else {
                    // isNew is set when the calendar item form is opened only for the current calendar item (see addNewFlagToCalendarDefinitionItem method)
                    // when the form is closed, isNew flag is deleted
                    return property.item.subject === calendar.items.filter((item: CalendarItem) => item.isNew !== undefined)[0]?.subject && property.item.id === undefined;
                }
            })
        } else {
            // search the corresponding properties in extendDefinitions
            let extendedCalendar = calendar.extendDefinitions.filter((cal: CalendarDefinition) => {
                return cal.items.filter((item: CalendarItem) => item.id === this.props.currentCalendarItem.id).length > 0;
            })[0];
            properties = extendedCalendar.properties.filter((property: CalendarProperty) => property.item.id === this.props.currentCalendarItem.id);
        }
        this.setCalendarPropertiesTable(properties);
    }

    ////////////////////////////////////
    // METHODS USED BY CALENDAR ITEM EDITOR (GENERAL)
    ////////////////////////////////////

    getCalendarFormTabPanes() {
        return (
            [
                { menuItem: _msg("CalendarItemEditor.subject.label"), render: () => this.renderSubjectTab() },
                { menuItem: _msg("CalendarItemEditor.recurrence.label"), render: () => this.renderPatternTab() },
                { menuItem: _msg("CalendarItemEditor.rangeOfRecurrence.label"), render: () => this.renderRangeTab() }
            ]
        );
    }

    getCurrentCalendarItem() {
        if (this.props.calendarDefinition === undefined) {
            return undefined;
        }

        let allCalendarItems = this.props.calendarDefinition?.items ? [...this.props.calendarDefinition?.items] : [];
        if (this.props.currentCalendarItem.isOwnItem === false) {
            for (let i = 0; i < this.props.calendarDefinition.extendDefinitions.length; i++) {
                for (let j = 0; j < this.props.calendarDefinition.extendDefinitions[i].items.length; j++) {
                    allCalendarItems.push(this.props.calendarDefinition.extendDefinitions[i].items[j]);
                }
            }
        }
        return allCalendarItems.filter((item: CalendarItem) => {
            if (this.props.currentCalendarItem.isOwnItem === undefined || this.props.currentCalendarItem.isOwnItem === true) {
                // isNew is set when the calendar item form is opened only for the current calendar item (see addNewFlagToCalendarDefinitionItem method)
                // when the form is closed, isNew flag is deleted
                return item.isNew !== undefined;
            } else {
                return item.id === this.props.currentCalendarItem.id;
            }
        })[0];
    }

    /**
     * This method is used only for own items (i.e. the items that belong directly to the current calendar, not to extend calendars)
     */
    getCurrentItemIndex() {
        return this.props.calendarDefinition.items.findIndex((item: CalendarItem) => item.isNew !== undefined);
    }

    getConditionForShowingDelete(): boolean {
        if (this.props.currentCalendarItem.isOwnItem) {
            return true;
        }
        return false;
    }

    addNewFlagToCalendarDefinitionItem(items: CalendarItem[]) {
        let item = {};
        // when opening an empty form (i.e. add a new calendar item), we need to create the object that will be edited
        // add a dummy property isNew that will be deleted later to make a difference between the current item and other possible items with id null (unsaved items)
        if (this.props.currentCalendarItem.isOwnItem === undefined) {
            item = { isNew: true, recurring: false, recurrenceDefinitionDto: null, startDate: this.props.defaultStartDateValue };
            items.push(item);
        } else {
            let currentCalendarIndex = items.findIndex((item: CalendarItem) => {
                if (this.props.currentCalendarItem.id !== undefined) {
                    return item.id === this.props.currentCalendarItem.id;
                } else {
                    return item.subject === this.props.currentCalendarItem.subject;
                }
            });
            item = items.splice(currentCalendarIndex, 1)[0];
            item = { ...item, isNew: false };
            items.splice(currentCalendarIndex, 1, item);
        }
        return items;
    }

    calendarDefinitionChanged() {
        this.props.dispatchers.setInReduxState({ calendarDefinition: this.props.calendar });
        let newCalendar = { ...this.props.calendar };
        if (this.props.currentCalendarItem.isOwnItem !== false) {
            newCalendar = { ...newCalendar, items: this.addNewFlagToCalendarDefinitionItem([...this.props.calendar.items]) };
            this.props.dispatchers.setInReduxState({ calendarDefinition: newCalendar });
        }
        if (this.props.currentCalendarItem.isOwnItem !== undefined) {
            this.getPropertiesForCurrentCalendarItem(newCalendar);
        }
    }

    clearTablesContent() {
        this.setCalendarPropertiesTable([]);
    }

    render() {
        fieldRenderers["CalendarPropertyCt"] = CalendarPropertyTableRenderer;
        return (
            <>
                <Tab className="CalendarItemEditor_tab" panes={this.getCalendarFormTabPanes()} />
                <fieldset className="GroupSnapshotTab_contextPropertiesTables CalendarItemEditor_disabledFieldset" disabled={this.props.currentCalendarItem.isOwnItem === false}>
                    <EntityTableLight {...this.props.calendarPropertiesTable} ref={this.entityTableLightRef} dispatchers={this.props.dispatchers.calendarPropertiesTable}
                        entityDescriptor={calendarPropertyDescriptor} actions={{ showDeleteButton: false, doNotAddEntityToTable: true }}
                        onSave={(properties: CalendarProperty[], addedProperty: { rowIndex: number, values: CalendarPropertyTable }) => {
                            this.addOrUpdateProperty(addedProperty);
                        }}
                        onDelete={(properties: CalendarDefinition[], removedPropertyIndex: number) => {
                            this.removeProperty(this.entityTableLightRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[removedPropertyIndex]);
                        }}
                        formCustomizer={{ headerContent: _msg("CalendarItemEditor.property.label"), headerIcon: "edit" }}
                    />
                </fieldset>
            </>
        );
    }

    async componentDidMount() {
        await this.props.dispatchers.getConstantData();
        this.calendarDefinitionChanged();
    }

    componentWillUnmount() {
        this.clearTablesContent();
        this.props.dispatchers.setInReduxState({ calendarDefinition: undefined });
    }
}