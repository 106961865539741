import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { PLANNING_SERVICE_FACADE_BEAN_GET_METADATA_CONTEXT_BY_ID, PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_STATUS_BY_CODE, PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_BY_CODE, RESOURCES_SERVICE_FACADE_BEAN_GET_ENVIRONMENT_BY_ID } from "graphql/queries";
import _ from "lodash";
import { AssociationDescriptionAndIdEditor, AssociationDescriptionEditor, AssociationNameAndIdEditor, AssociationNameEditor } from "pages/AssociationFieldEditors";
import React from "react";

const ENVIRONMENT:string = "environment";
const CONTEXT:string = "context";
const STATUS:string = "registrationStatus";
const TYPE:string = "registrationType";

export class PropertyValueEntityDescriptor extends EntityDescriptor {

    protected customize() {

        const propertyValueEntityDescriptor = this;

        const slicePropertyValueEditor = propertyValueEntityDescriptor.infoEditor.slice = createSliceFoundation(class SlicePropertyValueEditor extends SliceEntityEditorPage {

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                initialEnvironment: undefined as unknown as { id: number, description: string },
                initialContext: undefined as unknown as { id: number, name: string },
                initialStatus: undefined as unknown as {id: number, code: string, description: string},
                initialType: undefined as unknown as {id: number, code: string, description: string, name: string}
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SlicePropertyValueEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SlicePropertyValueEditor>(this),

                async getInitialEnvironment(id: number) {
                    const initialEnvironment = (await apolloClient.query({
                        query: RESOURCES_SERVICE_FACADE_BEAN_GET_ENVIRONMENT_BY_ID,
                        variables: {
                            id
                        }
                    })).data.resourcesServiceFacadeBean_environment;
                    this.getDispatchers().setInReduxState({ initialEnvironment });
                },

                async getInitialContext(id: number) {
                    const initialContext = (await apolloClient.query({
                        query: PLANNING_SERVICE_FACADE_BEAN_GET_METADATA_CONTEXT_BY_ID,
                        variables: {
                            id
                        }
                    })).data.planningServiceFacadeBean_metadataContext;
                    this.getDispatchers().setInReduxState({ initialContext });
                },

                async getInitialStatus(code: string) {
                    const initialStatus = (await apolloClient.query({
                        query: PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_STATUS_BY_CODE,
                        variables: {
                            code
                        }
                    })).data.planningServiceFacadeBean_registrationStatus;
                    this.getDispatchers().setInReduxState({ initialStatus });
                },

                async getInitialType(code: string) {
                    const initialType = (await apolloClient.query({
                        query: PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_BY_CODE,
                        variables: {
                            code
                        }
                    })).data.planningServiceFacadeBean_registrationType;
                    this.getDispatchers().setInReduxState({ initialType });
                },

                superLoad: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.superLoad(id);
                    if (entity !== undefined) {
                        if (entity.environment !== undefined) {
                            await this.getInitialEnvironment(entity?.environment);
                        }
                        if (entity.context !== undefined) {
                            await this.getInitialContext(entity?.context);
                        }
                        if (entity.registrationStatus !== undefined) {
                            await this.getInitialStatus(entity?.registrationStatus);
                        }
                        if (entity.registrationType !== undefined) {
                            await this.getInitialType(entity?.registrationType);
                        }
                    }
                    return entity;
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    entity = this.convertFieldToId(entity, ENVIRONMENT);
                    entity = this.convertFieldToId(entity, CONTEXT);
                    entity = this.convertFieldToCode(entity, STATUS);
                    entity = this.convertFieldToCode(entity, TYPE);
                    await this.saveSuper(entity);
                },

                convertFieldToId(entity: any, field: string) {
                    if (entity[field] !== null && entity[field] !== undefined && entity[field].id !== undefined) {
                        entity = { ...entity, [field]: entity[field].id };
                    }
                    return entity;
                },

                convertFieldToCode(entity: any, field: string) {
                    if (entity[field] !== null && entity[field] !== undefined && entity[field].code !== undefined) {
                        entity = { ...entity, [field]: entity[field].code };
                    }
                    return entity;
                }
            }
        }).setEntityDescriptor(propertyValueEntityDescriptor);

        propertyValueEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof slicePropertyValueEditor> & EntityEditorPageProps> {

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "PropertyValue" }, false);

                // Add custom fields descriptors for environment and context. By default, we can see in the form only the id,
                // but we want to see a dropdown for each of these fields with the value from the database for the
                // entities: Environment and MetadataContext.
                propertyValueEntityDescriptor.doForFields(null, (fieldDescriptor) => result.entityDescriptor.addFieldDescriptor(fieldDescriptor));
                result.entityDescriptor.removeFieldDescriptors(ENVIRONMENT, CONTEXT, STATUS, TYPE);

                // environment descriptor
                result.entityDescriptor.addFieldDescriptor({ name: ENVIRONMENT, type: "Environment" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        return React.createElement(AssociationDescriptionAndIdEditor as any, props as FieldEditorProps);
                    }
                });

                // context descriptor
                result.entityDescriptor.addFieldDescriptor({ name: CONTEXT, type: "MetadataContext" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        return React.createElement(AssociationNameAndIdEditor as any, props as FieldEditorProps);
                    }
                });

                // registration status descriptor
                result.entityDescriptor.addFieldDescriptor({ name: STATUS, type: "RegistrationStatus" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        return React.createElement(AssociationDescriptionEditor as any, props as FieldEditorProps);
                    }
                });

                 // registration type descriptor
                 result.entityDescriptor.addFieldDescriptor({ name: TYPE, type: "RegistrationType" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        return React.createElement(AssociationNameEditor as any, props as FieldEditorProps);
                    }
                });

                result.entity = this.addInitialCodeValue(result.entity, STATUS, this.props.initialStatus);
                result.entity = this.addInitialCodeValue(result.entity, TYPE, this.props.initialType);
                result.entity = this.addInitialValue(result.entity, ENVIRONMENT, this.props.initialEnvironment);
                result.entity = this.addInitialValue(result.entity, CONTEXT, this.props.initialContext);
                return result;
            }

            protected addInitialValue(entity: any, field: string, initialValue: { id: number, name: string } | { id: number, description: string }) {
                if (entity[field] !== undefined && entity[field] !== null && entity[field] === initialValue?.id) {
                    entity = { ...entity, [field]: initialValue };
                }
                return entity;
            }

            protected addInitialCodeValue(entity: any, field: string, initialValue: { code : string}) {
                if (entity[field] !== undefined && entity[field] !== null && entity[field] === initialValue?.code) {
                    entity = { ...entity, [field]: initialValue };
                }
                return entity;
            }

            protected convertFromObjectToStringOrNumber(entity: any, fieldName: string, fieldNameInState: string, propertyToBeShown: string) {
                if (entity[fieldName] && entity[fieldName][propertyToBeShown] !== undefined) {
                    this.props.dispatchers.setInReduxState({ [fieldNameInState]: entity[fieldName] });
                    entity = { ...entity, [fieldName]: entity[fieldName][propertyToBeShown] };
                }
                return entity;
            }

            protected getEntityValuesFromForm() {
                // because in the form the "status", "type", "environment" and "context" fields are of type "object" and in the table they are string or number, 
                // a conversion is needed when navigating from "edit properties" to "view properties" (from form to table)
                let values = super.getEntityValuesFromForm();
                values = this.convertFromObjectToStringOrNumber(values, STATUS, "initialStatus", "code");
                values = this.convertFromObjectToStringOrNumber(values, TYPE, "initialType", "code");
                values = this.convertFromObjectToStringOrNumber(values, ENVIRONMENT, "initialEnvironment", "id");
                values = this.convertFromObjectToStringOrNumber(values, CONTEXT, "initialContext", "id");
                return values;
            }
        }
    }
}