import React from "react";
import { Form, Dropdown, Button } from "semantic-ui-react";
import { createSliceFoundation, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react";
import moment, { Moment } from 'moment';
import { ProteusConstants } from "ProteusConstants";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";

// need for DatePicker to start with Monday
moment.updateLocale("en", {
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4
    }
});

export enum NavigationConstants { YEAR, MONTH, WEEK, WORKINGWEEK, DAY, PERIOD }

export const sliceDateNavigation = createSliceFoundation(class SliceDateNavigation {

    initialState = {
        fromDate: moment().startOf('month').valueOf(),
        untilDate: moment().endOf('month').seconds(0).milliseconds(0).valueOf(),
        periodDropdownValue: 1
    }

    reducers = {
        ...getBaseReducers<SliceDateNavigation>(this),

        updatePeriod(state: StateFrom<SliceDateNavigation>, value: number) {
            state.periodDropdownValue = value;
        }
    }
})

type PropsNotFromState = {
    onChangePeriod: (values: any) => void;
    showNowButton?: boolean;
}

export class DateNavigationPanel extends React.Component<PropsFrom<typeof sliceDateNavigation> & PropsNotFromState> {

    protected navigationPeriods = [{ key: 1, value: NavigationConstants.DAY, text: _msg("DateNavigationPanel.day.label") },
    { key: 2, value: NavigationConstants.WEEK, text: _msg("DateNavigationPanel.week.label") },
    { key: 3, value: NavigationConstants.WORKINGWEEK, text: _msg("DateNavigationPanel.workingWeek.label") },
    { key: 4, value: NavigationConstants.MONTH, text: _msg("DateNavigationPanel.month.label") },
    { key: 5, value: NavigationConstants.YEAR, text: _msg("DateNavigationPanel.year.label") },
    { key: 6, value: NavigationConstants.PERIOD, text: _msg("DateNavigationPanel.period.label") }];

    updateDates(periodDropdownValue: number, fromDate: any, untilDate: any, triggerOnChange: boolean = true) {
        let newFromDate = undefined;
        let newUntilDate = undefined;
        let newUntilDateAsMoment = undefined;
        switch (periodDropdownValue) {
            case NavigationConstants.YEAR: {
                newFromDate = moment(fromDate).startOf('year').valueOf();
                newUntilDateAsMoment = moment(fromDate).endOf('year');
                break;
            }
            case NavigationConstants.MONTH: {
                newFromDate = moment(fromDate).startOf('month').valueOf();
                newUntilDateAsMoment = moment(fromDate).endOf('month');
                break;
            }
            case NavigationConstants.WEEK: {
                newFromDate = moment(fromDate).startOf('date').valueOf();
                newUntilDateAsMoment = moment(fromDate).add(6, 'days').endOf('date');
                break;
            }
            case NavigationConstants.WORKINGWEEK: {
                newFromDate = moment(fromDate).startOf('week').startOf('date').valueOf();
                newUntilDateAsMoment = moment(fromDate).add(4, 'days').endOf('date');
                break;
            }
            case NavigationConstants.DAY: {
                newFromDate = moment(fromDate).startOf('day').valueOf();
                newUntilDateAsMoment = moment(fromDate).endOf('day');
                break;
            }
            case NavigationConstants.PERIOD: {
                newFromDate = moment(fromDate).startOf('day').valueOf();
                newUntilDateAsMoment = moment(untilDate).endOf('day');
                break;
            }
        }
    
        newUntilDate = newUntilDateAsMoment ? newUntilDateAsMoment.seconds(0).milliseconds(0).valueOf() : undefined;
        this.props.dispatchers.setInReduxState({ fromDate: newFromDate, untilDate: newUntilDate });
        triggerOnChange && this.props.onChangePeriod({ fromDate: newFromDate, untilDate: newUntilDate });
    }

    protected onDropdownClick(value: number) {
        this.props.dispatchers.setInReduxState({ periodDropdownValue: value });
        this.updateDates(value, this.props.fromDate, this.props.untilDate);
    }

    protected onPrevClick() {
        let fromDate = undefined;
        if (this.props.periodDropdownValue === NavigationConstants.WEEK || this.props.periodDropdownValue === NavigationConstants.WORKINGWEEK) {
            fromDate = moment(this.props.fromDate).subtract(7, 'days').valueOf();
        } else {
            fromDate = moment(this.props.fromDate).subtract(1, 'days').valueOf();
        }
        this.props.dispatchers.setInReduxState({ fromDate });
        this.updateDates(this.props.periodDropdownValue, fromDate, this.props.untilDate);
    }

    protected onNextClick() {
        let fromDate = undefined;
        if (this.props.periodDropdownValue === NavigationConstants.WORKINGWEEK) {
            fromDate = moment(this.props.untilDate).add(3, 'days').startOf('date').valueOf();
        } else {
            fromDate = moment(this.props.untilDate).add(1, 'days').startOf('date').valueOf();
        }
        this.props.dispatchers.setInReduxState({ fromDate });
        this.updateDates(this.props.periodDropdownValue, fromDate, this.props.untilDate);
    }

    protected onDatePickerChange(dateAsNumber: number) {
        this.props.dispatchers.setInReduxState({ fromDate: dateAsNumber });
        this.updateDates(this.props.periodDropdownValue, dateAsNumber, this.props.untilDate);
    }

    protected onUntilDatePickerClick(dateAsNumber: number) {
        this.props.dispatchers.setInReduxState({ untilDate: dateAsNumber });
        this.updateDates(this.props.periodDropdownValue, this.props.fromDate, dateAsNumber);
    }

    protected onNowClick() {
        this.updateDates(this.props.periodDropdownValue, moment().valueOf(), moment().valueOf());
    }

    render() {
        const props = this.props;
        return (
            <Form data-testid="DateNavigationPanel_comboGroup" className="DateNavigationPanel_comboGroup">
                {props.showNowButton && <Form.Field className="DateNavigationPanel_buttonDiv">
                    <Button className="DateNavigationPanel_nowButton" onClick={() => this.onNowClick()}>{_msg("DateNavigationPanel.now")}</Button>
                </Form.Field>}
                <Form.Field id="DateNavigationPanel_periodDropdown" >
                    <div className="DateNavigationPanel_label">{_msg("general.show")}</div>
                    <div className="DateNavigationPanel_dropdown">
                        <Dropdown
                            onChange={(e, data) => this.onDropdownClick(data.value as number)}
                            placeholder='Dates' search value={props.periodDropdownValue} selection options={this.navigationPeriods}
                        />
                    </div>
                </Form.Field>
                <Form.Field>
                    <div className="DateNavigationPanel_label">{_msg("fromDate.label")}</div>
                    <div className="DateNavigationPanel_date">
                        <DatePickerReactCalendar value={moment(props.fromDate) as any} format={ProteusConstants.DATE_FORMAT}
                            onChange={d => this.onDatePickerChange(d?.valueOf() as number)} allowClear={false}
                            disabledDate={(current: Moment) => {
                                if (props.periodDropdownValue === NavigationConstants.PERIOD) {
                                    return current && current > moment(new Date(props.untilDate));
                                }
                                return false;
                            }}
                        />
                    </div>
                </Form.Field>
                <Form.Field disabled={props.periodDropdownValue !== NavigationConstants.PERIOD} >
                    <div className="DateNavigationPanel_label">{_msg("toDate.label")}</div>
                    <div className="DateNavigationPanel_date">
                        <DatePickerReactCalendar value={moment(props.untilDate) as any} format={ProteusConstants.DATE_FORMAT}
                            onChange={d => this.onUntilDatePickerClick(d?.valueOf() as number)} allowClear={false}
                            disabledDate={(current: Moment) => current && current < moment(new Date(props.fromDate))}
                        />
                    </div>
                </Form.Field>
                <Form.Field className="DateNavigationPanel_buttonDiv">
                    <Button data-testid="DateNavigationPanel_buttonPrevious" disabled={props.periodDropdownValue === NavigationConstants.PERIOD} onClick={() => this.onPrevClick()} icon={'arrow left'} />
                    <Button data-testid="DateNavigationPanel_buttonNext" disabled={props.periodDropdownValue === NavigationConstants.PERIOD} onClick={() => this.onNextClick()} icon={'arrow right'} />
                </Form.Field>
            </Form>
        );
    }
}