export class ProteusConstants {
    static ADMIN_CATEGORY_CODE = "ADMIN";
    static AGENDA: string = "AGENDA";
    static ALLOW_MAP_TO_WORKPERIOD: string = "ALLOW_MAP_TO_WORKPERIOD";
    static ALLOW_RESOURCE_CHANGE_IN_REG_EDITOR: string = "ALLOW_RESOURCE_CHANGE_IN_REG_EDITOR";
    static ARBEIDER: string = "01";
    static ARBEIDSONGEVAL: string = "AO";
    static BACKGROUND_COLOR: string = "BACKGROUND_COLOR";
    static BACKGROUND_TASKS_VIEW_ALL_PERMISSION = "BACKGROUND_TASKS_VIEW_ALL";
    static BALANCE_TYPE: string = "balanceType";
    static BALANCE_TYPE_CODE: string = "balanceTypeCode";
    static BALANCE_TYPE_NAME: string = "balanceTypeName";
    static BALANCES_VIEW: string = "BALANCES_VIEW";
    static BALANCES_VIEW_IN_EMPLOYEE_EDITOR: string = "BALANCES_VIEW_IN_EMPLOYEE_EDITOR";
    static BEDIENDE: string = "02";
    static BESCHIKBAAR: string = "BESCHIKBAAR";
    static BESCHIKBAAR_ONBESCHIKBAAR: string = "FT_OKNOK";
    static BRABO: string = "BRABO";
    static BRABO_COMPANY_CODE: string = "BRABO CVBA";
    static BOOLEAN: string = "BOOLEAN";
    static BOOTMAN: string = "BTM";
    static BOOTMAN_CATEGORY: string = "Bootman";
    static BRAXO_LEAVE_HOURS_DISTRIBUTION: string = "Braxo Verlof uren distributie";
    static BYTE_ARRAY: string = "BYTE_ARRAY";
    static CAPTAINS_CATEGORY_CODE: string = "STEW";
    static CATALOG: string = "catalog";
    static CATEGORIE: string = "categorie";
    static CATEGORY: string = "category";
    static CATEGORY_ID: string = "category.id";
    static CERTIFICATE: string = "CERT";
    static CODE: string = "code";
    static CODE_WAITING: string = "AHW";
    static COLOR: string = "COLOR";
    static COMPENSATION_LOSS_CODE: string = "INL_COMP";
    static COMPOSITION_GROUP: string = "CompositionGroup";
    static COMPOSITION_GROUP_CODE: string  = "C";
    static CONSTRAINED: string = "CONSTRAINED";
    static CONSTRAINT_DEFINITION: string = "constraintDefinition";
    static CONSTRAINT_DEFINITIONS: string = "constraintDefinitions";
    static CONSTRAINT_GROUP_DETAIL:string = "ConstraintGroupDetail";
    static CONTEXT_FREE_TIME_SCREEN: string = "FREE_TIME_SCREEN";
    static CONTRACT: string = "CONTRACT";
    static CONTRACT_HISTORY = 'ContractHistory';
    static COUNTER_TYPE: string = "counterType";
    static CREATE_ROLE: string = "CREATE_ROLE";
    static DAG_24H: string = "DAG_24H";
    static DATE_FORMAT: string = "DD/MM/YYYY";
    static DATE_TIME_FORMAT: string = "DD/MM/YYYY HH:mm";
    static DEFAULT_STATUS_CODE: string = "DEFAULT_STATUS_CODE";
    static DELETE_ROLE: string = "DELETE_ROLE";
    static DETAIL_EXCHANGE: string = 'ExchangeDetail';
    static DETAIL_LEAVE: string = 'LeaveDetail';
    static DEKSMAN_CATEGORY_CODE: string = "DEK";
    static DYNAMIC_GROUP: string = "DynamicGroup";
    static DYNAMIC_GROUP_CODE: string = "D";
    static DATE: string = "DATE";
    static DOUBLE: string = "DOUBLE";
    static EARLY_SHIFT: string = "VROEGE";
    static EFFECTIEF: string = "EFF";
    static EJOB_METADATA_TYPE: string = "EJOB_METADATA_TYPE";
    static EJOB_SHIP_ATTENDANCE: string = "EJOB_SHIP_ATTENDANCE";
    static EJOB_TYPE: string = "EJOB_TYPE";
    static EMPLOYEE_IGNORE_VALIDATION_ERRORS: string = "EMPLOYEE_IGNORE_VALIDATION_ERRORS";
    static ENFORCE_ALL: string = "ENFORCE_ALL";
    static EXCHANGE_REGISTRATION_TYPE: string = "WISSEL";
    static EXPIRED_COMPONENTS_REPORT: string = "EXPIRED_COMPONENTS_REPORT_CONTEXT";
    static EXTRA_COUNTER: string = "EXTRA";
    static FREE_TIME: string = "FREE_TIME";
    static FREE_TIME_DISPATCHERS_CALL: string = "FREE_TIME_DISPATCHERS_CALL";
    static FREE_TIME_MAKE_EMPLOYEE_UNAVAILABLE: string = "FREE_TIME_MAKE_EMPLOYEE_UNAVAILABLE";
    static FREE_TIME_ORDER_CHANGE: string = "FREE_TIME_ORDER_CHANGE";
    static FREE_TIME_SUPER_GROUP: string = "freeTimeSuperGroup";
    static FROM_DATE: string = "fromDate";
    static GROUP_CODE_ASSIGNMENT_REPORT: string = "ASSIGNMENT_REPORT";
    static GROUP_CODE_EMPLOYEE_EXPLORER: string = "EMPLOYEE_EXPLORER";
    static GROUP_RESOURCES_HISTORY: string = "groupResourcesHistory";
    static HALF_DAY_FULL: string = 'FULL_DAY';
    static HALF_DAY_AM: string = 'HALF_DAY_AM';
    static HALF_DAY_PM: string = 'HALF_DAY_PM';
    static HALF_DAY_ROUNDING_PROPERTY: string = "HALF_DAY_ROUNDING";
    static HOUR_REGIME: string = "hourRegime";
    static HOURS_BALANCE_CALCULATION: string = "HOURS";
    static HOURS_BALANCE_SUFFIX: string = "_HOURS";
    static ID: string = "id";
    static IGNORE_WARNINGS: string = "IGNORE_WARNINGS";
    static INITIAL_CALCULATION_PREFIX: string = "AANTAL_";
    static INSTRUCTOR_REGISTRATION_TYPE: string = "LESG";
    static INTEGER: string = "INTEGER";
    static LATE_SHIFT: string = "LATE";
    static LAYOUT_TYPE_CODE: string = "LAYOUT_TYPE_CODE";
    static LEAVE: string = "VERLOF";
    static LEAVE_DISTRIBUTION: string = "Verlof distributie";
    static LEAVE_HOURS_BALANCE_TYPE_CODE: string = "VC_HOURS";
    static LEAVE_TYPE_CODE: string = "VC";
    static LOCATION: string = "location";
    static PILOTS_CODE: string = "LDS";
    static MAP_TO_WORKPERIOD: string = "MAP_TO_WORKPERIOD";
    static MEDICAL_CHECKUP_DETAIL: string = "MedicalCheckupDetail";
    static METADATA_PROPERTY_TYPE_CATEGORY: string = "TYPE";
    static METADATA_PROPERTY_EJOB_CATEGORY: string = "EJOB";
    static EJOB_METADATA_TYPE_CODE: string = "TYPE";
    static MILESTONE: string = "MILESTONE";
    static NOT_WORKING_STATUS_CODE: string = "NW";
    static OCCUPATION: string = "OCCUPATION";
    static ONBESCHIKBAAR: string = "ONBESCHIKBAAR";
    static ONBESCHIKBAAR_VOOR_VRIJETIJDSTAAK: string = "GEEN_VRIJETIJD";
    static OVERRIDE_ZERO_LEAVE_BALANCE_LOGIC: string = "OVERRIDE_ZERO_LEAVE_BALANCE_LOGIC";
    static OVERTIME_LEAVE: string = "OVC";
    static PARENTAL_LEAVE: string = "PARENTAL_LEAVE";
    static PARENTAL_LEAVE_TYPE_CODE: string = "OUV";
    static PAYROLL_GENERATION: string = "PAYROLL_FILE_GENERATION";
    static PAYROLL_FILE_CATEGORY_CODE: string = "PF";
    static PAYROLL_FILE_ID: string = "3";
    static PERSON: string = "person";
    static PERSOON: string = "persoon";
    static PILOTS: string = "Loods";
    static PLANNING: string = "PLANNING";
    static PLANNING_ALLOW_HALF_DAY_REGISTRATIONS: string = "PLANNING_ALLOW_HALF_DAY_REGISTRATIONS";
    static PLANNING_CURRICULUM: string = "PLANNING_CURRICULUM";
    static PLANNING_EDIT_DATES_IN_DETAIL: string = "PLANNING_EDIT_DATES_IN_DETAIL";
    static PLANNING_IGNORE_VALIDATION_ERRORS: string = "PLANNING_IGNORE_VALIDATION_ERRORS";
    static PLANNING_REGISTRATION_EDITOR_VIEW_ALL_TABS: string = "PLANNING_REGISTRATION_EDITOR_VIEW_ALL_TABS";
    static PLANNING_VIEW_DETAIL_PREFIX: string = "PLANNING_VIEW_";
    static PLANNING_VIEW_DETAIL_SUFFIX: string = "_DETAIL";
    static PLOEG: string = "ploeg";
    static POLICY_ENFORCE_ALL: string = "ENFORCE_ALL";
    static PRIMARY_PLANNING_TABS: string = "PRIMARY_PLANNING_TABS";
    static PRIVE_ONGEVAL_MET_VERZEKERING: string = "POMV";
    static PRIVE_ONGEVAL_ZONDER_VERZEKERING: string = "POGV";
    static READ_ROLE: string = "READ_ROLE";
    static REALIZATION: string = "realization";
    static REGISTRATION_TYPE: string = "registrationType";
    static REMARK: string = "remark";
    static REMOVED_STATUS_CODE: string = "VERWIJDERD";
    static REPORTS: string = "reports";
    static REPORT_GENERATION: string = "REPORT_GENERATION";
    static RESOURCE: string = "resource";
    static EJOB_SHIP_REQUIRED_CODE: string = "SHIP_REQUIRED";
    static SPARE_REGISTRATION_TYPE: string = "RES";
    static REPLACEMENT_EARLY: string = "REPLACEMENT_VROEGE";
    static REPLACEMENT_LATE: string = "REPLACEMENT_LATE";
    static RESOURCES_EDIT_EMPLOYEES: string = "RESOURCES_EDIT_EMPLOYEES";
    static RESTITUTION_LEAVE_TYPE_CODE: string = "TVL";
    static ROUNDING_TYPE_CODE: string = "ROUNDING_TYPE_CODE";
    static ROUNDING_TYPE_DAG: string = "DAG";
    static SAILORS_CATEGORY_CODE: string = "SCHIP";
    static SECONDARY_PLANNING_DROPDOWN_GROUPS: string = "SECONDARY_PLANNING_DROPDOWN_GROUPS";
    static SECURITY: string = "SECURITY";
    static SECURITY_DEFINITION: string = "securityDefinition";
    static SEQUENCE: string = "sequence";
    static SEQUENCE_CONTEXT_CODE: string = "BEURT";
    static SEQUENCE_DEFINITION: string = "sequenceDefinition";
    static SEQUENCE_EXCHANGE_DETAIL: string = "SequenceExchangeDetail";
    static SEQUENCE_EXCHANGE_REGISTRATION_TYPE: string = "BEURTWISSEL";
    static SEQUENCE_REPORT_NAME: string = "Beurt";
    static SEQUENCE_REPORT_CODE: string = "SequenceReport";
    static STATIC_GROUP: string = "StaticGroup";
    static STATIC_GROUP_CODE: string = "S";
    static STRING: string = "STRING";
    static SUCCESSION_DEFINITION = "successionDefinition";
    static SYSTEM: string = "SYSTEM";
    static TABLE_PAGE_ALLOW_ASSOCIATED_LINKS: string = "TABLE_PAGE_ALLOW_ASSOCIATED_LINKS";
    static TEAM: string = "team";
    static TECHNICAL_CATEGORY_CODE = "TECHX";
    static TIME_CREDIT: string = "TIJDSKREDIET";
    static TIME_CREDIT_TYPE_CODE: string = "TK";
    static TO_DATE: string = "toDate";
    static TRAINERS_EXPLORER_CONTEXT: string = "TRAINERS_EXPLORER";
    static TRANSPARENCY_PERCENT: string = "TRANSPARENCY_PERCENT";
    static UPDATE_ROLE: string = "UPDATE_ROLE";
    static USE_BALANCE_COUNTER = "USE_BALANCE_COUNTER";
    static USE_HOURS_BALANCE_COUNTER = "USE_HOURS_BALANCE_COUNTER";
    static VALIDATION_EXCEPTION: string = "ValidationException";
    static VALID_FROM: string = "validFrom";
    static VALID_UNTIL: string = "validUntil";
    static VALUE: string = "value";
    static VERLOF_WET: string = "VERLOF_WET";
    static VERLOF_WET1: string = "VERLOF_WET1";
    static VERLOF_WET2: string = "VERLOF_WET2";
    static WAGES: string = "wages";
    static WAITING_AGENDA_VIEW: string = "WAITING_AGENDA_VIEW";
    static WETTELIJK: string = "WETTELIJK";
    static WERK_PERIODE: string = "WERK_PERIODE";
    static WORK_PERIOD_ROUNDING_TYPE: string = "WP";
    static WORK_PERIOD_TYPE: string = "workPeriodType";
    static ZIEKTE: string = "ZK";
} 