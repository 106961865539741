import { apolloClient, Utils } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { RenderItemParams } from "@crispico/foundation-react/components/TreeRRC/Tree";
import { TreeTable, TreeTableState } from "@crispico/foundation-react/components/treeTable/TreeTable";
import { ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { findPersonById_personService_findById } from "apollo-gen/findPersonById";
import { EmployeesTree, EmployeesTreeRaw } from "components/employeesTree/EmployeesTree";
import { EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_OF_MEMBER, PERSON_SERVICE_FIND_BY_IDS } from "graphql/queries";
import { filteringTree, GROUP_CONTEXT, GroupContextTreeReducers, groupsContextsTreeRootType, sliceGroupsManagement } from "pages/groupsManagement/GroupsManagement";
import { UsersRoles } from "pages/usersRoles/UsersRoles";
import React, { createRef } from "react";
import { Form, Grid, Icon, Input, Segment } from "semantic-ui-react";

export class UserGroupsTreeTable extends TreeTable {
    protected renderItem = (params: RenderItemParams) => {
        const objectId = params.linearizedItem.itemId.split(Utils.defaultIdSeparator);
        const object = Utils.navigate(this.props.root, objectId);
        if (object.__typename === GROUP_CONTEXT) {
            return <React.Fragment key={object.id}><Icon size="big" name="setting" />{object.name}</React.Fragment>;
        } else {
            return <React.Fragment key={object.id}>
                <Icon name="group" size="large" className="GroupsManagement_groupsHeader"></Icon>{object.name}
            </React.Fragment>
        }
    };

}

interface groupsContextsTreeRootTypeWithOwnerAsEntity extends groupsContextsTreeRootType {
    ownerFullName?: string;
}

export const UserGroupsTreeTableRRC = ReduxReusableComponents.connectRRC(TreeTableState, GroupContextTreeReducers, UserGroupsTreeTable);
export const sliceUsersGroups = createSliceFoundation(class SliceUsersGroups {

    initialState = {
        groupsContextsTreeRoot: [] as groupsContextsTreeRootTypeWithOwnerAsEntity[],
        filteredGroupsContextsTreeRoot: [] as groupsContextsTreeRootTypeWithOwnerAsEntity[],
        searchedGroupOrContext: "" as string,
        root: [] as any,
        expandCollapseItemId: undefined as unknown as string,
    };

    reducers = {
        ...getBaseReducers<SliceUsersGroups>(this),
        ...sliceGroupsManagement.reducers
    }

    impures = {
        ...getBaseImpures<SliceUsersGroups>(this),
    };
});

export class UsersGroups extends TabbedPage<PropsFrom<typeof sliceUsersGroups>> {
    employeesTreeRef = createRef<EmployeesTreeRaw>();
    prevEmployeesTreeRef = {} as any;
    protected groupsContextTreeRef = React.createRef<UserGroupsTreeTable>();
    protected prevGroupsContextTreeRef = {} as any;

    protected columns = [{ name: "name", width: 3, label: _msg("name.label") }, { name: "description", width: 4, label: _msg("description.label") }, { name: "ownerFullName", width: 1, label: _msg("owner.label") }];

    protected getTitle() {
        return { icon: "unlock alternate", title: _msg("UsersGroups") };
    }

    protected getTabbedPageCssClasses() {
        return super.getTabbedPageCssClasses() + " container_fullHeight";
    }

    async loadGroupsOfMemberForTree(expandTree: boolean, groupsContextTreeRef: React.RefObject<UserGroupsTreeTable>, employee: any) {
        let groupsContextsTreeRoot = [];
        const groupsRaw = (await apolloClient.query({
            query: EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_OF_MEMBER,
            variables: {
                owner: -1,
                groupContextCode: null,
                member: employee.id,
                fromDate: new Date(),
                untilDate: new Date(),
                useExcludeFromSearch: false
            }
        })).data.employeeService_groupSnapshotsOfMember;

        // find all owner ids, the result is saved as Set to be sure that are only once
        let owners = new Set<number>();
        for (let i = 0; i < groupsRaw.length; i++) {
            if (groupsRaw[i].owner !== null && groupsRaw[i].owner !== 0 && groupsRaw[i].owner !== -1) {
                owners.add(groupsRaw[i].owner)
            }
        }

        // get the Person entity for each ownerId
        const ownersAsEntity: findPersonById_personService_findById[] = (await apolloClient.query({
            query: PERSON_SERVICE_FIND_BY_IDS,
            variables: {
                ids: [...owners]
            }
        })).data.personService_findByIds;

        // there are groups without context, they should apear in the tree as well
        let emptyContext = { name: _msg("GroupsManagement.noContext.label"), groups: [], __typename: GROUP_CONTEXT } as any;

        // format the raw format of groups, adding the owner as fullName
        for (let i = 0; i < groupsRaw.length; i++) {
            let ownerFullName;
            if (groupsRaw[i].owner !== null && groupsRaw[i].owner !== 0 && groupsRaw[i].owner !== -1) {
                const ownerAsEntity = ownersAsEntity.find(owner => owner.id === groupsRaw[i].owner);
                ownerFullName = ownerAsEntity?.name + " " + ownerAsEntity?.firstName;
            } else {
                ownerFullName = _msg("GroupContextTab.system.label");
            }
            groupsRaw[i] = { ...groupsRaw[i], ownerFullName }
            for (let j = 0; j < groupsRaw[i].contexts.length; j++) {
                const alreadyExisting = groupsContextsTreeRoot.find(item => item.code === groupsRaw[i].contexts[j].code)
                if (alreadyExisting !== undefined) {
                    alreadyExisting.groups.push(groupsRaw[i])
                } else {
                    let entry = { ...groupsRaw[i].contexts[j], groups: [groupsRaw[i]] }
                    groupsContextsTreeRoot.push(entry);
                }
            }
            if (groupsRaw[i].contexts.length === 0) {
                emptyContext.groups.push(groupsRaw[i]);
            }
        }
        
        // add empty context (Geen context) to tree
        groupsContextsTreeRoot.push(emptyContext);

        // sort contexts and groups from each context by name 
        groupsContextsTreeRoot.sort((a: any, b: any) => a.name < b.name ? -1 : 1);
        for (let i = 0; i < groupsContextsTreeRoot.length; i++) {
            groupsContextsTreeRoot[i].groups = groupsContextsTreeRoot[i].groups.sort((a: any, b: any) => a.name < b.name ? -1 : 1);
        }

        // expand and filter the tree
        if (expandTree) {
            this.expandGroupsContextsTree(groupsContextsTreeRoot, groupsContextTreeRef);
        }
        this.props.dispatchers.setInReduxState({ groupsContextsTreeRoot });
        this.props.dispatchers.setGroupsContextsTreeRoot();
    }

    expandGroupsContextsTree(groupsContextsTreeRoot: any, groupsContextTreeRef: React.RefObject<UserGroupsTreeTable>) {
        let expandedIds = {};
        for (let i = 0; i < groupsContextsTreeRoot.length; i++) {
            expandedIds = { ...expandedIds, [i]: true };
        }
        groupsContextTreeRef.current?.props.r.setInReduxState({ expandedIds });
        groupsContextTreeRef.current?.props.r.linearize(this.props.root, {});
    }

    filterGroupsContextsTree() {
        if (this.props.searchedGroupOrContext === "") {
            this.props.dispatchers.setGroupsContextsTreeRoot();
            return;
        }
        let filteredGroupsContextsTreeRoot = filteringTree(this.props.groupsContextsTreeRoot, this.props.searchedGroupOrContext);
        this.props.dispatchers.setInReduxState({ filteredGroupsContextsTreeRoot });
        this.props.dispatchers.setGroupsContextsTreeRoot();
    }

    handleSelectedId = (selectedId: string | undefined) => {
        if (selectedId) {
            const employee = Utils.navigate(this.employeesTreeRef.current?.getTreeRoot(), selectedId);
            if (employee.id) {
                this.loadGroupsOfMemberForTree(true, this.groupsContextTreeRef, employee);
            }
        }
    };

    protected renderMain() {
        return (
            <div className="UsersRoles">
                <Grid columns={2}>
                    <Grid.Row key="employeeRow">
                        <EmployeesTree
                            id="employeesTree_groups"
                            ref={this.employeesTreeRef}
                            onSelectedIdChanged={this.handleSelectedId}
                        />
                        <Grid.Column style={{ height: "90%" }} key="empl">
                            <Grid.Row>
                                <Segment>
                                    <Form>
                                        <Form.Field>
                                            <Input value={this.props.searchedGroupOrContext} onChange={(e, data) => {
                                                this.props.dispatchers.setInReduxState({ searchedGroupOrContext: data.value as string });
                                                this.filterGroupsContextsTree();
                                            }} placeholder={_msg("searchPermission.label")} icon="search" />
                                        </Form.Field>
                                    </Form>
                                </Segment>
                            </Grid.Row>
                            <Grid.Row className="UsersRoles_tree">
                                <UserGroupsTreeTableRRC id="userGroupsTree" ref={this.groupsContextTreeRef} root={this.props.root} columns={this.columns}
                                    renderColumn={(object: any, columnName: string) => <React.Fragment key={object[columnName]}>{object[columnName]}</React.Fragment>}
                                    verticalAlign="middle"
                                />
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    componentDidMount() {
        if (this.props.groupsContextsTreeRoot === undefined) {
            this.props.dispatchers.setGroupsContextsTreeRoot();
        }
        UsersRoles.getPrevRefSnapshot(this);
    }

    async componentDidUpdate(prevProps: any) {
        if (this.employeesTreeRef.current?.props.s.selectedId !== this.prevEmployeesTreeRef.selectedId) {
            UsersRoles.getPrevRefSnapshot(this);
        }

        //group, date or searched employee changed => clear its permissions because no employee will be selected
        const changed = UsersRoles.groupOrDateOrSearchedEmployeeChanged(this);
        if (changed) {
            this.props.dispatchers.setInReduxState({ groupsContextsTreeRoot: [], searchedGroupOrContext: "" });
            this.props.dispatchers.setGroupsContextsTreeRoot();
            UsersRoles.getPrevRefSnapshot(this);
        }
    }

}

export const infoUsersGroups = new ConnectedPageInfo(sliceUsersGroups, UsersGroups, "UsersGroups");
infoUsersGroups.routeProps = { permission: "USER_GROUPS_VIEW" };