'use strict';

function excludeInterval() {
	return {
		require : 'ngModel',
		link : function(scope, element, attrs, modelCtrl) {

			modelCtrl.$parsers.push(function(inputValue) {

				var transformedInput = inputValue;
				
				if (inputValue >= 0 && inputValue <= 10) {
					transformedInput = 11;
				}
				if (inputValue < -1) {
					transformedInput = -1;
				}
				
				if (transformedInput != inputValue) {
					modelCtrl.$setViewValue(transformedInput);
					modelCtrl.$render();
				}

				return transformedInput;
			});
		}
	};
};

module.exports = excludeInterval;