'use strict';

/*@ngInject*/
function Education(DataSource) {
	
	var config = {
		baseUrl: '/restful/trainingServiceEndpoint',
		
		////////////////////
		// Catalogs
		////////////////////
		
		getCatalogs: {
			method: 'GET',
			url: '/catalogSnapshot'
		},
		saveCatalog: {
			method: 'PUT',
			url: '/catalogSnapshot/:id'
		},
		getTracks: {
			method: 'GET',
			url: '/catalog/:id/track'
		},
		getTrack: {
			method: 'GET',
			url: '/track/:id'
		},
		getTrackVersions: {
			method: 'GET',
			url: '/track/:id/versions'
		},
		saveTrack: {
			method: 'PUT',
			url: '/track/:id/:createNewMajorVersion'
		},
		saveTrackVersion: {
			method: 'PUT',
			url: '/trackVersion/:id'
		},
		removeTrack: {
			method: 'DELETE',
			url: '/track/:id'
		},
		removeTrackVersion: {
			method: 'DELETE',
			url: '/trackVersion/:id'
		},
		getModules: {
			method: 'GET',
			url: '/catalog/:id/module'
		},
		getModule: {
			method: 'GET',
			url: '/module/:id'
		},
		getModuleVersions: {
			method: 'GET',
			url: '/module/:id/versions'
		},
		saveModule: {
			method: 'PUT',
			url: '/module/:id/:createNewMajorVersion'
		},
		saveModuleVersion: {
			method: 'PUT',
			url: '/saveModuleVersion/:id'
		},
		removeModule: {
			method: 'DELETE',
			url: '/module/:id'
		},
		removeModuleVersion: {
			method: 'DELETE',
			url: '/moduleVersion/:id'
		},
		getComponents: {
			method: 'GET',
			url: '/catalog/:id/component'
		},
		getComponent: {
			method: 'GET',
			url: '/component/:id'
		},
		getComponentByComponentVersionId: {
			method: 'GET',
			url: '/componentByVersion/:componentVersionId'
		},
		getComponentVersions: {
			method: 'GET',
			url: '/component/:id/versions'
		},
		saveComponent: {
			method: 'PUT',
			url: '/component/:id/:createNewMajorVersion'
		},
		saveComponentVersion: {
			method: 'PUT',
			url: '/componentVersion/:id'
		},
		removeComponent: {
			method: 'DELETE',
			url: '/component/:id'
		},
		removeComponentVersion: {
			method: 'DELETE',
			url: '/componentVersion/:id'
		},
		getCatalogAttachment: {
			method: 'GET',
			url: '/catalogAttachment/:id'
		},
		getProfileVersion: {
			method: 'GET',
			url: '/profileVersion/:id'
		},
		saveEducationComponent: {
			method: 'PUT',
			url: '/educationComponent/:id'
		},
		
		getTrainingCompetences: {
			method: 'GET',
			url: '/trainingCompetences/:trainingComponentVersion'
		},

		////////////////////
		// Competences
		////////////////////

		getDictionaries: {
			method: 'GET',
			url: '/dictionarySnapshot'
		},
		saveDictionary: {
			method: 'PUT',
			url: '/dictionarySnapshot/:id'
		},
		getCompetences: {
			method: 'GET',
			url: '/competenceCategories/:id'
		},
		getCompetenceVersions: {
			method: 'GET',
			url: '/competence/:id/versions'
		},
		getCompetence: {
			method: 'GET',
			url: '/competence/:id/'
		},
		saveCompetence: {
			method: 'PUT',
			url: '/competence/:id/'
		},
		removeCompetence: {
			method: 'DELETE',
			url: '/competence/:id'
		},
		removeCompetenceVersion: {
			method: 'DELETE',
			url: '/competenceVersion/:id'
		},
		saveCompetenceCategory: {
			method: 'PUT',
			url: '/competenceCategory/:id'
		},
		removeCompetenceCategory: {
			method: 'DELETE',
			url: '/competenceCategory/:id'
		},
		getCompetenceAttachment: {
			method: 'GET',
			url: '/competenceAttachment/:id'
		},
		getComponentsForCompetence: {
			method: 'GET',
			url: '/competences/trainingComponents/:competenceVersion'
		}
	};
	
	var ds = new DataSource(config);
	
	ds.registerOperations([
		'getCatalogs', 'saveCatalog',
		'getTracks', 'getTrack', 'getTrackVersions', 'saveTrack', 'saveTrackVersion', 'removeTrack', 'removeTrackVersion',
		'getModules', 'getModule', 'getModuleVersions', 'saveModule', 'saveModuleVersion', 'removeModule', 'removeModuleVersion',
		'getComponents', 'getComponent', 'getComponentVersions', 'saveComponent', 'removeComponent', 'removeComponentVersion',
		'getCatalogAttachment', 'saveComponentVersion',
		'getProfileVersion', 'saveEducationComponent',
		
		'getDictionaries', 'saveDictionary',
		'getCompetences', 'getCompetenceVersions', 'getCompetence',
		'saveCompetence', 'removeCompetence', 'removeCompetenceVersion',
		'saveCompetenceCategory', 'removeCompetenceCategory',
		'getCompetenceAttachment', 'getComponentsForCompetence'
	]);
	
	ds.config.getEvaluationComponents = {
		method: 'GET',
		url: '/catalog/:id/evaluation'
	};
	ds.config.getEvaluationRequests = {
		method: 'POST',
		url: '/evaluationRequestSnapshot'
	};
	ds.config.deleteEvaluationRequest = {
		method: 'DELETE',
		url: '/evaluationRequestSnapshot/:id'
	};
	ds.config.getEvaluations = {
		method: 'GET',
		url: '/evaluationRequest/:id'
	};
	ds.config.saveEvaluations = {
		method: 'POST',
		url: '/evaluationRequest'
	};
	ds.config.getEvaluationsToFillIn = {
		method: 'GET',
		url: '/evaluationToFillIn/:id'
	};
	ds.config.saveEvaluationToFillIn = {
		method: 'PUT',
		url: '/evaluationToFillIn/:evaluatorId/:evaluationId'
	};
	ds.config.getEvaluationAttachment = {
		method: 'GET',
		url: '/evaluationAttachment/:id'
	};
	
	ds.config.getEducationCardAttachment = {
			method: 'GET',
			url: '/educationCardAttachment/:id'	
	}
	ds.config.notifyEvaluatorsExcedeedDate = {
		method: 'POST',
		url: '/notifyEvaluatorsExcedeedDate'	
	}
	ds.config.archiveEvaluationRequest = {
		method: 'PUT',
		url: '/evaluationRequestSnapshot/:evaluationRequestId/archive'
	};

	ds.config.getTrainingSessions = {
		method: 'POST',
		url: '/trainingSessionSnapshots'
	}

	ds.config.getTrainingSessionStatuses = {
		method: 'GET',
		url: '/trainingSessionStatuses'
	}

	ds.config.getTrainingTimePeriods = {
		method: 'GET',
		url: '/trainingSession/:id'
	}

	ds.config.saveSession = {
		method: 'POST',
		url: '/session'
	}

	ds.config.removeTrainingSession = {
		method: 'DELETE',
		url: '/session/:id'
	}

	ds.config.getLatestRealization = {
		method: 'POST',
		url: '/groupRegistrations/realization/:id/:componentVersionId'
	}

	ds.config.getLatestRealizations = {
		method: 'POST',
		url: '/groupRegistrations/latestRealizations'
	}

	ds.config.archiveTrainingSession = {
		method: 'PUT',
		url: '/session/:id/archive'
	}

	ds.config.getRealizationsByCriteria = {
		method: 'POST',
		url: '/groupRegistrations/realizations'
	}

	ds.config.getEvaluationOverview = {
		method: 'GET',
		url: '/evaluationOverview/:realizationId/'
	}

	ds.registerOperation('getEvaluationComponents');
	ds.registerOperation('getEvaluationRequests');
	ds.registerOperation('deleteEvaluationRequest');
	ds.registerOperation('getEvaluations');
	ds.registerOperation('saveEvaluations');
	ds.registerOperation('getEvaluationsToFillIn');
	ds.registerOperation('saveEvaluationToFillIn');
	ds.registerOperation('getEvaluationAttachment');
	ds.registerOperation('getEducationCardAttachment');
	ds.registerOperation('notifyEvaluatorsExcedeedDate');
	ds.registerOperation('archiveEvaluationRequest');
	ds.registerOperation('getTrainingSessions');
	ds.registerOperation('getTrainingSessionStatuses');
	ds.registerOperation('getTrainingTimePeriods');
	ds.registerOperation('saveSession');
	ds.registerOperation('removeTrainingSession');
	ds.registerOperation('getLatestRealization');
	ds.registerOperation('getLatestRealizations');
	ds.registerOperation('archiveTrainingSession');
	ds.registerOperation('getRealizationsByCriteria');
	ds.registerOperation('getEvaluationOverview');
	ds.registerOperation('getTrainingCompetences');
	ds.registerOperation('getComponentByComponentVersionId');
	return ds;
}

module.exports = Education;