'use strict'

/*@ngInject*/
function LeaveGroupCtrl($rootScope, $scope, $filter, Resources, DATE_TIME_FORMAT, ModalSpinner, Agenda, AgendaConstants) {
	
	var agenda = $scope.agenda;
	var mode = agenda ? agenda.mode : null;
	var agendaRegistration = $scope.agendaRegistration;

	var dateFilter = $filter('date');

	function refresh() {
		var params = getLeaveGroupsParams();
		if (params) {
			ModalSpinner.show('agenda.loading.groups');
			Resources.leaveGroups(null, {
				params: params
			}).then(function(result) {
				let leaveGroupsData = {
										allowNull: result.data.allowNull,
										disableDropDown: result.data.disableDropDown,
										nonOverlappingGroups: result.data.nonOverlappingGroups,
										preselectedGroupSnapshotCode: result.data.preselectedGroupSnapshotCode,
										splitDate: result.data.splitDate
					}
				if (agendaRegistration) {
					agendaRegistration.leaveGroupsData = leaveGroupsData;
				}
				if ($scope.leaveGroupsOptions) {
					var oldPreselectedGroup = $scope.leaveGroupsOptions.preselectedGroupSnapshotCode;
				}
				$scope.leaveGroupsOptions = result.data;
				if (mode === AgendaConstants.MODE_OPEN_COMPARTMENTS) {
					$scope.leaveGroupsOptions.preselectedGroupSnapshotCode = oldPreselectedGroup;
				}
				if ($scope.leaveGroupsOptions.allowNull) {
					$scope.leaveGroupsOptions.leaveGroupSnapshots.push({
						code: null,
						name: '(Geen beperkingen groep)'
					});
				}
				if (agenda) {
					// we need the group snapshots in open vakken screen
					agenda.leaveGroupSnapshots = $scope.leaveGroupsOptions.leaveGroupSnapshots;
					agenda.leaveGroupSnapshotsByTimePeriod = $scope.leaveGroupsOptions.leaveGroupSnapshotsByTimePeriod;
				}
				preselectGroup();

				if (mode === AgendaConstants.MODE_OPEN_COMPARTMENTS) {
					agenda.getConstraintGroups();
				} else {
					// get registration types with constraint definition
					if (!agenda.registrationType) {
						return;
					}

					var config = {
						data: {
							fromDate: agenda.mode.getFirstDate(agenda.date),
							toDate: agenda.mode.getLastDate(agenda.date),
							registrationTypeCode: agenda.registrationType.code,
							user: agenda.resource.id,
							leaveGroups: agenda.leaveGroupSnapshots
						}
					}			
					Agenda.isConstrainedRegistrationType({}, config).then(function(result) {
						agenda.isConstrainedRegistrationType = result.data;
					});
				}
			});
		}
	}
	
	$scope.refreshLeaveGroups = refresh; 
	
	if (agenda) {
		// in the agenda, we let the RegistrationTypeCtrl ask for the selectable leave groups
		agenda.getLeaveGroups = refresh;
	} else {
		if (!agenda && $scope.status.agenda) {
			mode = $scope.status.agenda.mode;
		}
		refresh();
	}

	$scope.leaveGroupChanged = function() {
		// leave group is set => we can try a refresh
		if (agenda) {
			agenda.leaveGroupSet = true;
			agenda.refresh();
		}
	}
	
	function getLeaveGroupsParams() {
		if (agenda) {
			if (agenda.date) {
				return {
					resource: $rootScope.securityContext.resource.id,
					fromDate: dateFilter(agenda.mode.getFirstDate(agenda.date), DATE_TIME_FORMAT),
					endDate: dateFilter(agenda.mode.getLastDate(agenda.date), DATE_TIME_FORMAT),
					registrationTypeCode: agenda.registrationType ? agenda.registrationType.code : null
				}
			}
		} else if ($scope.agendaRegistration.registration) {
			return {
				resource: $rootScope.securityContext.resource.id,
				fromDate: dateFilter(new Date($scope.agendaRegistration.registration.fromDate), DATE_TIME_FORMAT),
				endDate: dateFilter(new Date($scope.agendaRegistration.registration.toDate), DATE_TIME_FORMAT),
				registrationTypeCode: $scope.code
			}
		}
		return null;
	}
	
	function preselectGroup() {
		var code = $scope.leaveGroupsOptions.preselectedGroupSnapshotCode;
		var existingRegistration = false;
		// get preselected code from registration
		if ($scope.agendaRegistration) {
			if ($scope.agendaRegistration.registration.id) {
				existingRegistration = true;
				code = $scope.agendaRegistration.registration.detail.groupCode;
			}
		}
		
		var rslt = null;
		angular.forEach($scope.leaveGroupsOptions.leaveGroupSnapshots, function(group) {
			if (code === group.code) {
				rslt = group;
			}
		});
		
		if (rslt) {
			doSelectGroup(rslt);
		} else if (code && existingRegistration) {
			// group is not in the leaveGroupOptions => request it from server
			Resources.getGroup(null, {
				params: {
					code: code
				}
			}).then(function(result) {
				var group = result.data;
				// since this group is not in the leaveGroupsOptions => add it to the list
				$scope.leaveGroupsOptions.leaveGroupSnapshots.splice(0, 0, group);
				doSelectGroup(group);
			});
		} else {
			$scope.leaveGroupsOptions.allowNull = true;
			rslt = {
				code: null,
				name: '(Geen beperkingen groep)'
			}
			if ($scope.leaveGroupsOptions.disableDropDown && $scope.leaveGroupsOptions.leaveGroupSnapshots.length > 0) {
				$scope.leaveGroupsOptions.disableDropDown = false;
			}
			$scope.leaveGroupsOptions.leaveGroupSnapshots.push(rslt);
			doSelectGroup(rslt);
		}
	}

	function doSelectGroup(group) {
		if (agenda) {
			agenda.leaveGroup = group;
		} else {
			$scope.status.leaveGroup = group;
		}

		$scope.leaveGroupChanged();

		if ($scope.agendaRegistration) {
			ModalSpinner.hide();
		}
	}
};

module.exports = LeaveGroupCtrl;