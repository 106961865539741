import { apolloClient, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { ModalExt, ModalExtOpen, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { OnSelectParams, PropsNotFromState, RenderItemParams, Tree, TreeReducers, TreeState } from "@crispico/foundation-react/components/TreeRRC/Tree";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { COMPONENT_TYPE_SERVICE_FIND_ALL, TRAINING_SERVICE_ENDPOINT_GET_CATALOG_SNAPSHOT, TRAINING_SERVICE_ENDPOINT_GET_COMPONENTS, TRAINING_SERVICE_ENDPOINT_GET_COMPONENT_VERSIONS, TRAINING_SERVICE_ENDPOINT_GET_MODULE, TRAINING_SERVICE_ENDPOINT_GET_MODULES, TRAINING_SERVICE_ENDPOINT_GET_MODULE_VERSIONS, TRAINING_SERVICE_ENDPOINT_GET_TRACK, TRAINING_SERVICE_ENDPOINT_GET_TRACKS, TRAINING_SERVICE_ENDPOINT_GET_TRACK_VERSIONS, TRAINING_SERVICE_ENDPOINT_REMOVE_COMPONENT, TRAINING_SERVICE_ENDPOINT_REMOVE_COMPONENT_VERSION, TRAINING_SERVICE_ENDPOINT_REMOVE_MODULE, TRAINING_SERVICE_ENDPOINT_REMOVE_MODULE_VERSION, TRAINING_SERVICE_ENDPOINT_REMOVE_TRACK, TRAINING_SERVICE_ENDPOINT_REMOVE_TRACK_VERSION } from "graphql/queries";
import _, { add } from "lodash";
import React from "react";
import { Button, Checkbox, Form, Icon, Input, Menu, Modal, Segment } from "semantic-ui-react";
import { CatalogSnapshot, Component, ComponentType, Module, RESPONSE_OK, Track } from "./Catalog";
import { ArrowScrollContainer, ArrowScrollDirection } from "components/scrollableContainer/ArrowScrollContainer";

export const DEFAULT_CATALOG = 1;
const NOT_LOADED = "NOT_LOADED";
export const TRACK = "Track";
export const MODULE = "Module";
export const COMPONENT = "Component";
export const TRACK_VERSION = "TrackVersion";
export const MODULE_VERSION = "ModuleVersion";
export const COMPONENT_VERSION = "ComponentVersion";
export const EDUCATION_COMPONENT_VERSION = "EducationComponentVersion";
export const EVALUATION_COMPONENT_VERSION = "EvaluationComponentVersion";
export const COMPONENT_VERSION_TREE_LABEL_CLASS = "Catalog_treeLabel_span text-muted";

export enum TreeType {
    NONE = "NONE",
    TRACKS = "TRACKS",
    MODULES = "MODULES",
    COMPONENTS = "COMPONENTS"
}

class EducationTreeState extends TreeState {
    confirmDelete: boolean = false;
}
class EducationTreeReducers<S extends EducationTreeState = EducationTreeState> extends TreeReducers<S> {
    protected _getChildren(item: any): { localId: string, item: any; }[] {
        if (Array.isArray(item)) {
            return super._getChildren(item, {});
        }

        const children = item.versions as Array<any>;
        if (item.objectType === TRACK_VERSION) { }
        if (children[0]?.objectType === NOT_LOADED) {
            return [];
        }
        if (children && children.length !== 0) {
            return (children.map((item, index) => ({ localId: "versions" + Utils.defaultIdSeparator + index, item })));
        } else {
            return item.name;
        }
    }

    protected _hasChildren(item: any) {
        if (item.versions && item.versions.length > 0) {
            return true;
        }
        return false;
    }
}

type TreeProps = PropsNotFromState & {
    renderButtons?: boolean,
    openMenuContext?: Function,
} & RRCProps<EducationTreeState, EducationTreeReducers>;

class EducationTree extends Tree<TreeProps> {
    protected renderItemAux(params: RenderItemParams): React.ReactNode {
        const objectId = params.linearizedItem.itemId.split(Utils.defaultIdSeparator);
        const object = Utils.navigate(this.props.root, objectId);
        if (object.objectType === "Root") {
            return <React.Fragment key={params.linearizedItem.itemId}>{object.name}</React.Fragment>;
        } else if (object.objectType === TRACK) {
            return <React.Fragment key={params.linearizedItem.itemId}>
                {object.currentTrackVersion.name}
                <span className={COMPONENT_VERSION_TREE_LABEL_CLASS}>{` v${object.currentTrackVersion?.majorVersion}.${object.currentTrackVersion?.minorVersion}`}</span>
            </React.Fragment>;
        } else if (object.objectType === MODULE) {
            return <React.Fragment key={params.linearizedItem.itemId}>
                {object.currentModuleVersion.name}
                <span className={COMPONENT_VERSION_TREE_LABEL_CLASS}>{` v${object.currentModuleVersion?.majorVersion}.${object.currentModuleVersion?.minorVersion}`}</span>
            </React.Fragment>;
        } else if (object.objectType === COMPONENT) {
            return <React.Fragment key={params.linearizedItem.itemId}>
                {`${object.currentComponentVersion.name} `}
                <span className={COMPONENT_VERSION_TREE_LABEL_CLASS}>{` v${object.currentComponentVersion?.majorVersion}.${object.currentComponentVersion?.minorVersion}`}</span>
            </React.Fragment>;
        } else {
            return <React.Fragment key={params.linearizedItem.itemId}>
                {`${object.name} `}
                <span className={COMPONENT_VERSION_TREE_LABEL_CLASS}>{` v${object?.majorVersion}.${object?.minorVersion}`}</span>
            </React.Fragment>;
        }
    }

    protected renderItem(params: RenderItemParams): React.ReactNode {
        const treeElementId = params.linearizedItem.itemId.split(Utils.defaultIdSeparator);
        let treeElement = Utils.navigate(this.props.root, treeElementId);
        if (treeElement.objectType === NOT_LOADED) return null;

        return <>
            {this.renderItemAux(params)}
            {this.props.renderButtons &&
                <Button primary icon="bars" size="mini" className="Catalog_tree_buttons"
                    onClick={(event) => {
                        event.stopPropagation();
                        this.props.openMenuContext!([event.clientX, event.clientY], treeElement);
                    }}
                />}
        </>;
    }
}

export const EducationTreeRRC = ReduxReusableComponents.connectRRC(EducationTreeState, EducationTreeReducers, EducationTree);

export const sliceCatalogTree = createSliceFoundation(class SliceCatalogTree {

    initialState = {
        rootTracks: [] as any,
        rootModules: [] as any,
        rootComponents: [] as any,
        filteredRoot: undefined as any,
        catalogSnapshot: {} as CatalogSnapshot,
        components: [] as any,
        componentTypes: [] as ComponentType[],
        treeTypeOpen: TreeType.NONE,
        loadedTrees: [] as string[],
        selectedComponent: undefined as string | undefined,
        activeCheck: false,
        notActiveCheck: false,
        searchExpression: "",
        treeElement: undefined as any,
        confirmDeleteModalOpen: false,
        renderExpands: true,
        openContextMenuModal: false as ModalExtOpen
    };

    nestedSlices = {
    };

    reducers = {
        ...getBaseReducers<SliceCatalogTree>(this),

        addTracks(state: StateFrom<SliceCatalogTree>, p: { tracks: Track[], addVersions?: boolean; }) {
            state.rootTracks = p.tracks;
            state.rootTracks.forEach(function (item: Track & { versions: any; }, index: number) {
                item.versions = p.addVersions ? [{ name: "", objectType: NOT_LOADED }] : [];
            });
            if (!state.loadedTrees.includes(TreeType.TRACKS)) {
                state.loadedTrees = [...state.loadedTrees, TreeType.TRACKS];
            }
            this.filterRoot(state, state.searchExpression);
        },

        addModules(state: StateFrom<SliceCatalogTree>, p: { modules: Module[], addVersions?: boolean; }) {
            state.rootModules = p.modules;
            state.rootModules.forEach(function (item: Module & { versions: any; }, index: number) {
                item.versions = p.addVersions ? [{ name: "", objectType: NOT_LOADED }] : [];
            });
            if (!state.loadedTrees.includes(TreeType.MODULES)) {
                state.loadedTrees = [...state.loadedTrees, TreeType.MODULES];
            }
            this.filterRoot(state, state.searchExpression);
        },

        addComponents(state: StateFrom<SliceCatalogTree>, p: { components: Component[], addVersions?: boolean; }) {
            let componentsCategorized: any = {};
            p.components.forEach(component => {
                if (!componentsCategorized[component.type.description]) {
                    componentsCategorized[component.type.description] = [];
                }
                componentsCategorized[component.type.description].push({ ...component, versions: p.addVersions ? [{ name: "", objectType: NOT_LOADED }] : [] });
            });
            state.componentTypes.forEach((componentType: ComponentType) => {
                if (!componentsCategorized[componentType.description]) {
                    componentsCategorized[componentType.description] = [];
                }
            });
            state.components = componentsCategorized;
            state.rootComponents = Object.values(state.components)[0];
            state.selectedComponent = Object.keys(state.components)[0];
            if (!state.loadedTrees.includes(TreeType.COMPONENTS)) {
                state.loadedTrees = [...state.loadedTrees, TreeType.COMPONENTS];
            }
            this.filterRoot(state, state.searchExpression);
        },

        addToModules(state: StateFrom<SliceCatalogTree>, module: Module) {
            let moduleToAdd = { ...module, versions: [{ name: "", objectType: NOT_LOADED }] };
            state.rootModules.push(moduleToAdd);
        },

        addToTracks(state: StateFrom<SliceCatalogTree>, track: Track) {
            let trackToAdd = { ...track, versions: [{ name: "", objectType: NOT_LOADED }] };
            state.rootTracks.push(trackToAdd);
        },

        addToComponents(state: StateFrom<SliceCatalogTree>, component: Component) {
            let componentToAdd = { ...component, versions: [{ name: "", objectType: NOT_LOADED }] };
            state.rootComponents.push(componentToAdd);
            if (state.selectedComponent) {
                state.components[state.selectedComponent].push(componentToAdd);
            }
        },

        updateTrack(state: StateFrom<SliceCatalogTree>, track: Track) {
            this.updateItem(state, state.rootTracks, track);
        },

        updateTrackChild(state: StateFrom<SliceCatalogTree>, p: { child: Track, parent: Track }) {
            this.setVersion(state, p.child, p.parent.id, state.filteredRoot);
            this.setVersion(state, p.child, p.parent.id, state.rootTracks);
        },

        updateModule(state: StateFrom<SliceCatalogTree>, module: Module) {
            this.updateItem(state, state.rootModules, module);
        },

        updateModuleChild(state: StateFrom<SliceCatalogTree>, p: { child: Module, parent: Module }) {
            this.setVersion(state, p.child, p.parent.id, state.filteredRoot);
            this.setVersion(state, p.child, p.parent.id, state.rootModules);
        },

        updateItem(state: StateFrom<SliceCatalogTree>, root: any, item: any) {
            const indexOfItem = root.indexOf(root.filter((elem: any) => elem.id === item.id)[0]);
            if (root[indexOfItem].versions[0].objectType !== NOT_LOADED) {
                let versionCopy;
                if (item.objectType === TRACK) {
                    versionCopy = { ..._.cloneDeep(root[indexOfItem].currentTrackVersion) };
                } else if (item.objectType === MODULE) {
                    versionCopy = { ..._.cloneDeep(root[indexOfItem].currentModuleVersion) };
                } else {
                    versionCopy = { ..._.cloneDeep(root[indexOfItem].currentComponentVersion) };
                }
                delete versionCopy.versions;
                root[indexOfItem].versions.unshift(versionCopy);
            }
            root[indexOfItem] = { ...root[indexOfItem], ...item };
        },

        updateComponent(state: StateFrom<SliceCatalogTree>, component: Component) {
            this.updateItem(state, state.rootComponents, component);
            this.updateItem(state, state.filteredRoot, component);
        },

        setVersion(state: StateFrom<SliceCatalogTree>, version: any, parentId: any, root: any) {
            const rootCopy = _.cloneDeep(root);
            const indexOfItem = rootCopy.indexOf(rootCopy.filter((elem: any) => elem.id === parentId)[0]);
            root[indexOfItem].versions = rootCopy[indexOfItem].versions.map((elem: any) => {
                if (elem.id === version.id) {
                    return version;
                }
                return elem;
            });
        },

        updateComponentChild(state: StateFrom<SliceCatalogTree>, p: { child: Component, parent: Component }) {
            this.setVersion(state, p.child, p.parent.id, state.filteredRoot);
            this.setVersion(state, p.child, p.parent.id, state.rootComponents);
            this.setVersion(state, p.child, p.parent.id, state.components[state.selectedComponent!]);
        },

        filterRoot(state: StateFrom<SliceCatalogTree>, searchExpression: string) {
            state.searchExpression = searchExpression;
            let root = state.treeTypeOpen === TreeType.TRACKS ? state.rootTracks : state.treeTypeOpen === TreeType.MODULES ? state.rootModules : state.rootComponents;
            let filteredRoot = [];
            for (let element of root) {

                if ((element.currentTrackVersion && element.currentTrackVersion.name.toLowerCase().includes(state.searchExpression.toLowerCase())) ||
                    (element.currentModuleVersion && element.currentModuleVersion.name.toLowerCase().includes(state.searchExpression.toLowerCase())) ||
                    (element.currentComponentVersion && element.currentComponentVersion.name.toLowerCase().includes(state.searchExpression.toLowerCase()))) {
                    let filteredChildren = [];
                    if (element.versions) {
                        for (let child of element.versions) {
                            if (child.name.toLowerCase().includes(state.searchExpression.toLowerCase())) {
                                if ((!state.activeCheck && !state.notActiveCheck) || // both unchecked
                                    (state.activeCheck && state.notActiveCheck) || // both checked
                                    (state.activeCheck && (child?.active)) || // active check
                                    (state.notActiveCheck && (child?.active === false)) // not active check
                                )
                                    filteredChildren.push(child);
                            }
                        }
                    }
                    let newElement = _.cloneDeep(element);
                    if (filteredChildren.length === 0) {
                        filteredChildren = [{ name: "", objectType: NOT_LOADED }];
                    }
                    if (state.renderExpands === false) {
                        filteredChildren = [];
                    }
                    newElement.versions = filteredChildren;
                    if ((!state.activeCheck && !state.notActiveCheck) || // both unchecked
                        (state.activeCheck && state.notActiveCheck) || // both checked
                        (state.activeCheck && (element.currentTrackVersion?.active || element.currentModuleVersion?.active || element.currentComponentVersion?.active)) || // active check
                        (state.notActiveCheck && (element.currentTrackVersion?.active === false || element.currentModuleVersion?.active === false || element.currentComponentVersion?.active === false)) // not active check
                    )
                        filteredRoot.push(newElement);
                }
            }
            state.filteredRoot = filteredRoot;
        },

        resetFilters(state: StateFrom<SliceCatalogTree>) {
            state.activeCheck = true;
            state.notActiveCheck = false;
            state.searchExpression = "";
            state.filteredRoot = undefined;
        }
    };

    impures = {
        ...getBaseImpures<SliceCatalogTree>(this),

        async loadComponentsTypes() {
            let params: any = {
                filter: {
                    operator: "and",
                    filters: []
                },
                aggregateFunctions: null,
                pageSize: -1,
                sorts: [],
                startIndex: 0,
                countMode: true

            };
            let componentTypes = (await apolloClient.query({
                query: COMPONENT_TYPE_SERVICE_FIND_ALL,
                variables: {
                    params: params
                }
            })).data.componentTypeService_findByFilter.results;
            this.getDispatchers().setInReduxState({ componentTypes });
        },

        async loadCatalogSnapshot() {
            let catalogSnapshot: CatalogSnapshot = (await apolloClient.query({
                query: TRAINING_SERVICE_ENDPOINT_GET_CATALOG_SNAPSHOT
            })).data.trainingServiceEndpoint_catalogSnapshots[0];
            this.getDispatchers().setInReduxState({ catalogSnapshot: catalogSnapshot });
        },

        async loadTrack(id: number) {
            let track: Track = (await apolloClient.query({
                query: TRAINING_SERVICE_ENDPOINT_GET_TRACK,
                variables: {
                    id: id
                }
            }
            )).data.trainingServiceEndpoint_track;
            return track;
        },

        async loadModule(id: number) {
            let module: Module = (await apolloClient.query({
                query: TRAINING_SERVICE_ENDPOINT_GET_MODULE,
                variables: {
                    id: id
                }
            })).data.trainingServiceEndpoint_module;
            return module;
        },

        async loadComponent(id: number) {
            let component: any = (await apolloClient.query({
                query: TRAINING_SERVICE_ENDPOINT_GET_COMPONENTS,
                variables: {
                    catalogId: id
                }
            })).data.trainingServiceEndpoint_components;
            return component;
        },

        async loadComponentVersions(objectId: string[]) {
            let rootCopy: any;
            if (this.getState().filteredRoot) {
                rootCopy = _.cloneDeep(this.getState().filteredRoot);
            } else {
                rootCopy = _.cloneDeep(this.getState().rootTracks);
            }

            let component = Utils.navigate(rootCopy, objectId);
            if (component.versions[0]?.objectType !== NOT_LOADED) {
                return;
            }

            const isBasicComponentVersion = component.currentComponentVersion.objectType === COMPONENT_VERSION;

            let componentVersions;

            if (isBasicComponentVersion) {
                componentVersions = (await apolloClient.query({
                    query: TRAINING_SERVICE_ENDPOINT_GET_COMPONENT_VERSIONS,
                    variables: {
                        componentId: component.id
                    }
                })).data.trainingServiceEndpoint_componentVersions;
            } else {
                const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
                const url = Utils.adjustUrlToServerContext(additionalUrlParam + `restful/trainingServiceEndpoint/component/${component.id}/versions`);
                const response = await fetch(url as string);
                if (response.ok) {
                    componentVersions = await response.json();
                }
            }

            component.versions = componentVersions.reverse();
            if (this.getState().filteredRoot) {
                this.getDispatchers().setInReduxState({ filteredRoot: rootCopy });
                let rootComponentsCopy = _.cloneDeep(this.getState().rootComponents);
                rootComponentsCopy = rootComponentsCopy.map((componentCopy: Component) => {
                    if (component.id === componentCopy.id)
                        return component;
                    else return componentCopy;
                });
                this.getDispatchers().setInReduxState({ rootComponents: rootComponentsCopy });
                this.getDispatchers().filterRoot(this.getState().searchExpression);
            } else {
                this.getDispatchers().setInReduxState({ rootComponents: rootCopy });
            }
        },

        async loadTrackVersions(objectId: string[]) {
            let rootCopy: any;
            if (this.getState().filteredRoot) {
                rootCopy = _.cloneDeep(this.getState().filteredRoot);
            } else {
                rootCopy = _.cloneDeep(this.getState().rootTracks);
            }

            let track = Utils.navigate(rootCopy, objectId);
            if (track.versions[0]?.objectType !== NOT_LOADED) {
                return;
            }

            let trackVersions = (await apolloClient.query({
                query: TRAINING_SERVICE_ENDPOINT_GET_TRACK_VERSIONS,
                variables: {
                    trackId: track.id
                }
            })).data.trainingServiceEndpoint_trackVersions;
            if (trackVersions.length > 0)
                track.versions = trackVersions.reverse();
            if (this.getState().filteredRoot) {
                this.getDispatchers().setInReduxState({ filteredRoot: rootCopy });
                let rootTracksCopy = _.cloneDeep(this.getState().rootTracks);
                rootTracksCopy = rootTracksCopy.map((trackCopy: Track) => {
                    if (track.id === trackCopy.id)
                        return track;
                    else return trackCopy;
                });
                this.getDispatchers().setInReduxState({ rootTracks: rootTracksCopy });
                this.getDispatchers().filterRoot(this.getState().searchExpression);
            } else {
                this.getDispatchers().setInReduxState({ rootTracks: rootCopy });
            }
        },

        async loadModuleVersions(objectId: string[]) {
            let rootCopy: any;
            if (this.getState().filteredRoot) {
                rootCopy = _.cloneDeep(this.getState().filteredRoot);
            } else {
                rootCopy = _.cloneDeep(this.getState().rootModules);
            }
            let module = Utils.navigate(rootCopy, objectId);
            if (module.versions[0]?.objectType !== NOT_LOADED) {
                return;
            }

            let moduleVersions = (await apolloClient.query({
                query: TRAINING_SERVICE_ENDPOINT_GET_MODULE_VERSIONS,
                variables: {
                    moduleId: module.id
                }
            })).data.trainingServiceEndpoint_moduleVersions;
            if (moduleVersions.length > 0)
                module.versions = moduleVersions.reverse();
            if (this.getState().filteredRoot) {
                this.getDispatchers().setInReduxState({ filteredRoot: rootCopy });
                let rootModulesCopy = _.cloneDeep(this.getState().rootModules);
                rootModulesCopy = rootModulesCopy.map((moduleCopy: Module) => {
                    if (module.id === moduleCopy.id)
                        return module;
                    else return moduleCopy;
                });
                this.getDispatchers().setInReduxState({ rootModules: rootModulesCopy });
                this.getDispatchers().filterRoot(this.getState().searchExpression);
            } else {
                this.getDispatchers().setInReduxState({ rootModules: rootCopy });
            }
        },

        async loadTracks(id: number, renderExpands?: boolean) {
            let tracks: any = (await apolloClient.query({
                query: TRAINING_SERVICE_ENDPOINT_GET_TRACKS,
                variables: {
                    catalogId: id
                }
            })).data.trainingServiceEndpoint_tracks;
            this.getDispatchers().addTracks({ tracks, addVersions: renderExpands });
        },

        async loadModules(id: number, renderExpand?: boolean) {
            let modules = (await apolloClient.query({
                query: TRAINING_SERVICE_ENDPOINT_GET_MODULES,
                variables: {
                    catalogId: id
                }
            })).data.trainingServiceEndpoint_modules;
            this.getDispatchers().addModules({ modules, addVersions: renderExpand });
        },

        async loadComponents(id: number, renderExpands?: boolean, loadGraphQL?: boolean) {
            const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
            const url = Utils.adjustUrlToServerContext(additionalUrlParam + `restful/trainingServiceEndpoint/catalog/${id}/component`);
            let components: any[] = [];
            if (!loadGraphQL) {
                let response = await fetch(url as string);
                if (response.status == RESPONSE_OK) {
                    components = await response.json() as Component[];
                }
            } else {
                components = (await apolloClient.query({
                    query: TRAINING_SERVICE_ENDPOINT_GET_COMPONENTS,
                    variables: {
                        catalogId: id
                    }
                })).data.trainingServiceEndpoint_components;
            }
            this.getDispatchers().addComponents({ components, addVersions: renderExpands });
        },

        async removeTrack(id: number) {
            let root = _.cloneDeep(this.getState().rootTracks);
            await apolloClient.mutate({
                mutation: TRAINING_SERVICE_ENDPOINT_REMOVE_TRACK,
                variables: {
                    id: id
                }
            });
            root = root.filter((elem: any) => elem.id !== id);
            this.getDispatchers().setInReduxState({ rootTracks: root });
        },

        async removeTrackVersion(id: number) {
            let root = _.cloneDeep(this.getState().rootTracks);
            await apolloClient.mutate({
                mutation: TRAINING_SERVICE_ENDPOINT_REMOVE_TRACK_VERSION,
                variables: {
                    id: id
                }
            });
            root = root.map((elem: any) => {
                elem.versions = elem.versions.filter((child: any) => child.id !== id);
                return elem;
            });
            this.getDispatchers().setInReduxState({ rootTracks: root });
        },

        async removeModule(id: number) {
            let root = _.cloneDeep(this.getState().rootModules);

            await apolloClient.mutate({
                mutation: TRAINING_SERVICE_ENDPOINT_REMOVE_MODULE,
                variables: {
                    id: id
                }
            });
            root = root.filter((elem: any) => elem.id !== id);
            this.getDispatchers().setInReduxState({ rootModules: root });
        },

        async removeModuleVersion(id: number) {
            let root = _.cloneDeep(this.getState().rootModules);
            await apolloClient.mutate({
                mutation: TRAINING_SERVICE_ENDPOINT_REMOVE_MODULE_VERSION,
                variables: {
                    id: id
                }
            });
            root = root.map((elem: any) => {
                elem.versions = elem.versions.filter((child: any) => child.id !== id);
                return elem;
            });
            this.getDispatchers().setInReduxState({ rootModules: root });
        },

        async removeComponent(id: number) {
            let root = _.cloneDeep(this.getState().rootComponents);
            await apolloClient.mutate({
                mutation: TRAINING_SERVICE_ENDPOINT_REMOVE_COMPONENT,
                variables: {
                    id: id
                }
            });
            root = root.filter((elem: any) => elem.id !== id);
            this.getDispatchers().setInReduxState({ rootComponents: root });
        },

        async removeComponentVersion(id: number) {
            let root = _.cloneDeep(this.getState().rootComponents);
            await apolloClient.mutate({
                mutation: TRAINING_SERVICE_ENDPOINT_REMOVE_COMPONENT_VERSION,
                variables: {
                    id: id
                }
            });
            root = root.map((elem: any) => {
                elem.versions = elem.versions.filter((child: any) => child.id !== id);
                return elem;
            });
            this.getDispatchers().setInReduxState({ rootComponents: root });
        },
    };
});

type Props = PropsFrom<typeof sliceCatalogTree> & {
    onModuleSelect: Function,
    onTrackSelect: Function,
    onComponentSelect: Function,
    renderButtons?: boolean,
    renderFilters?: boolean,
    renderExpands?: boolean,
    renderTracks?: boolean,
    renderModules?: boolean,
    renderComponents?: boolean,
    isLoadComponentsUsingGraphQL?: boolean,
    defaultSegmentOpen?: "tracks" | "modules" | "components";
    refreshTreeOnOpen?: boolean;
};
export class CatalogTree extends React.Component<Props> {

    static defaultProps = {
        renderFilters: true,
        renderButtons: true,
        renderTracks: true,
        renderModules: true,
        renderComponents: true
    };

    tracksTreeRef = React.createRef<EducationTree>();
    modulesTreeRef = React.createRef<EducationTree>();
    componentsTreeRef = React.createRef<EducationTree>();


    handleExpandComponent = (params: OnSelectParams) => {
        const objectId = params.itemId.split(Utils.defaultIdSeparator);
        this.props.dispatchers.loadComponentVersions(objectId);
    };

    handleExpandTrack = (params: OnSelectParams) => {
        const objectId = params.itemId.split(Utils.defaultIdSeparator);
        this.props.dispatchers.loadTrackVersions(objectId);
    };

    handleExpandModule = (params: OnSelectParams) => {
        const objectId = params.itemId.split(Utils.defaultIdSeparator);
        this.props.dispatchers.loadModuleVersions(objectId);
    };

    handleSelectTrack = (params: OnSelectParams) => {
        const objectId = params.itemId.split(Utils.defaultIdSeparator);
        const root = this.props.filteredRoot ? this.props.filteredRoot : this.props.rootTracks;


        let track;
        if (objectId.length === 1) {
            track = Utils.navigate(root, objectId);
        } else if ((objectId.length === 3)) {
            track = _.cloneDeep(Utils.navigate(root, objectId.slice(0, 1)));
            track.currentTrackVersion = Object.assign(track.currentTrackVersion, Utils.navigate(root, objectId));
        }

        this.props.onTrackSelect(track);
    };

    handleSelectModule = (params: OnSelectParams) => {
        const objectId = params.itemId.split(Utils.defaultIdSeparator);
        const root = this.props.filteredRoot ? this.props.filteredRoot : this.props.rootModules;

        let module;
        if (objectId.length === 1) {
            module = Utils.navigate(root, objectId);
        } else if ((objectId.length === 3)) {
            module = _.cloneDeep(Utils.navigate(root, objectId.slice(0, 1)));
            module.currentModuleVersion = Object.assign(module.currentModuleVersion, Utils.navigate(root, objectId));
        }

        this.props.onModuleSelect(module);
    };

    handleSelectComponent = (params: OnSelectParams) => {
        const objectId = params.itemId.split(Utils.defaultIdSeparator);
        const root = this.props.filteredRoot ? this.props.filteredRoot : this.props.rootComponents;

        let component;
        if (objectId.length === 1) {
            component = Utils.navigate(root, objectId);
        } else if ((objectId.length === 3)) {
            component = _.cloneDeep(Utils.navigate(root, objectId.slice(0, 1)));
            component.currentComponentVersion = Object.assign(component.currentComponentVersion, Utils.navigate(root, objectId));
        }

        this.props.onComponentSelect(component);
    };

    renderMenuContext = () => {
        return <ModalExt open={this.props.openContextMenuModal}
            onClick={() => this.props.dispatchers.setInReduxState({ openContextMenuModal: false })}
            onClose={() => this.props.dispatchers.setInReduxState({ openContextMenuModal: false })}
            className="Competences_modal"
        >
            <Menu vertical>
                {!this.props.treeElement?.objectType?.includes("Version") && <Menu.Item
                    name="copy"
                    content={_msg("Catalog.copy.label")}
                    icon="copy"
                    onClick={() => this.handleCopyElement(this.props.treeElement)} />}
                <Menu.Item
                    name="delete"
                    content={_msg("CatalogTree.delete.label")}
                    icon="delete"
                    onClick={() => this.handleDeleteElement(this.props.treeElement)}
                />
            </Menu>
        </ModalExt>;
    };

    openMenuContext = (open: ModalExtOpen, treeElement: any) => {
        this.props.dispatchers.setInReduxState({ openContextMenuModal: open, treeElement });
    };

    renderComponentTypes() {
        let componentTypes: JSX.Element[] = [];
        Object.keys(this.props.components).forEach((key: any) =>
            componentTypes.push(<Segment style={{ whiteSpace: 'nowrap'}} className={this.props.selectedComponent === key ? "selectedItem" : ""} key={key}
                onClick={() => {
                    this.props.dispatchers.setInReduxState({ rootComponents: this.props.components[key] });
                    this.componentsTreeRef.current?.props.r.setInReduxState({ selectedId: undefined, expandedIds: {} });

                    this.props.dispatchers.setInReduxState({ filteredRoot: undefined });
                    this.props.dispatchers.setInReduxState({ searchExpression: "" });
                    this.props.onComponentSelect(null);
                    this.props.dispatchers.setInReduxState({ selectedComponent: key });
                }
                }> {`${key}`}</Segment >)
        );
        return componentTypes;
    }

    createEmptyTrack() {
        this.tracksTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
        return {
            objectType: 'Track',
            catalog: {},
            currentTrackVersion: {
                name: '',
                description: '',
                active: true,
                attachments: [],
                children: [],
                majorVersion: 1,
                minorVersion: 0,
                objectType: 'TrackVersion'
            }
        };
    }

    openTracksTree = (keepOpenIfAlreadyOpen?: boolean) => {
        let treeTypeOpen = this.props.treeTypeOpen === TreeType.TRACKS ? TreeType.NONE : TreeType.TRACKS;
        if (keepOpenIfAlreadyOpen) {
            treeTypeOpen = TreeType.TRACKS;
        }
        this.props.onTrackSelect(null);
        if (this.props.refreshTreeOnOpen || !this.props.loadedTrees.includes(TreeType.TRACKS)) {
            this.props.dispatchers.loadTracks(DEFAULT_CATALOG, this.props.renderExpands);
        }
        this.props.dispatchers.setInReduxState({ treeTypeOpen });
        this.filterRoot(this.props.searchExpression);
    };

    renderTracksTree() {
        return <>
            <Segment className="CatalogTree_treeSegment" onClick={() => this.openTracksTree()}>
                <div>
                    <Icon name="lightning" />
                    {_msg("Catalog.track.label")}
                </div>
                {this.props.treeTypeOpen === TreeType.TRACKS && this.props.renderButtons &&
                    <div onClick={(e) => {
                        let newTrack = this.createEmptyTrack();
                        newTrack.catalog = this.props.catalogSnapshot;
                        this.props.onTrackSelect(newTrack);
                        e.stopPropagation();
                    }}>
                        <Icon name="plus" />
                        {_msg("Catalog.trackType.label")}
                    </div>}
            </Segment>
            {this.props.treeTypeOpen === TreeType.TRACKS &&
                <div className="flex-grow">
                    <EducationTreeRRC id="TrackTree" root={!this.props.filteredRoot ? this.props.rootTracks : this.props.filteredRoot} onSelectItem={this.handleSelectTrack}
                        ref={this.tracksTreeRef} onExpandCollapseItem={this.handleExpandTrack}
                        renderButtons={this.props.renderButtons} openMenuContext={this.openMenuContext} />
                </div>}
        </>;
    }

    createEmptyModule() {
        this.modulesTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
        return {
            catalog: {},
            objectType: 'Module',
            currentModuleVersion: {
                name: '',
                description: '',
                active: true,
                attachments: [],
                componentVersions: [],
                majorVersion: 1,
                minorVersion: 0,
                objectType: 'ModuleVersion'
            }
        };
    }

    openModulesTree = (keepOpenIfAlreadyOpen?: boolean) => {
        let treeTypeOpen = this.props.treeTypeOpen === TreeType.MODULES ? TreeType.NONE : TreeType.MODULES;
        if (keepOpenIfAlreadyOpen) {
            treeTypeOpen = TreeType.MODULES;
        }
        this.props.onModuleSelect(null);
        if (this.props.refreshTreeOnOpen || !this.props.loadedTrees.includes(TreeType.MODULES)) {
            this.props.dispatchers.loadModules(DEFAULT_CATALOG, this.props.renderExpands);
        }
        this.props.dispatchers.setInReduxState({ treeTypeOpen });
        this.filterRoot(this.props.searchExpression);
    };

    renderModulesTree() {
        return <>
            <Segment className="CatalogTree_treeSegment" onClick={() => this.openModulesTree()}>
                <div>
                    <Icon name="settings" />
                    {_msg("Catalog.modules.label")}
                </div>
                {this.props.treeTypeOpen === TreeType.MODULES && this.props.renderButtons &&
                    <div onClick={(e) => {
                        let newModule = this.createEmptyModule();
                        newModule.catalog = this.props.catalogSnapshot;
                        this.props.onModuleSelect(newModule);
                        e.stopPropagation();
                    }}>
                        <Icon name="plus" />
                        {_msg("Catalog.moduleType.label")}
                    </div>}
            </Segment>
            {this.props.treeTypeOpen === TreeType.MODULES &&
                <div className="flex-grow">
                    <EducationTreeRRC id="ModulesTree" root={!this.props.filteredRoot ? this.props.rootModules : this.props.filteredRoot} onSelectItem={this.handleSelectModule}
                        ref={this.modulesTreeRef} onExpandCollapseItem={this.handleExpandModule}
                        renderButtons={this.props.renderButtons} openMenuContext={this.openMenuContext} />
                </div>}
        </>;
    }

    createEmptyComponent(selectedComponent: string) {
        this.componentsTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
        let type;
        this.props.componentTypes.forEach((componentType: ComponentType) => { if (selectedComponent === componentType.description) type = componentType; });
        let objectType = selectedComponent === "Opleidingen" ? EDUCATION_COMPONENT_VERSION : selectedComponent === "Evaluaties" ? EVALUATION_COMPONENT_VERSION : COMPONENT_VERSION;
        return {
            catalog: {},
            objectType: COMPONENT,
            type: type,
            currentComponentVersion: {
                name: '',
                description: '',
                component: {
                    type: type,
                    objectType: COMPONENT
                },
                active: true,
                attachments: [],
                competenceVersions: [],
                objectType: objectType,
                majorVersion: 1,
                minorVersion: 0,
            }
        };
    }

    openComponentsTree = (keepOpenIfAlreadyOpen?: boolean) => {
        let treeTypeOpen = this.props.treeTypeOpen === TreeType.COMPONENTS ? TreeType.NONE : TreeType.COMPONENTS;
        if (keepOpenIfAlreadyOpen) {
            treeTypeOpen = TreeType.COMPONENTS;
        }
        this.props.dispatchers.setInReduxState({ treeTypeOpen });
        if (this.props.refreshTreeOnOpen || !this.props.loadedTrees.includes(TreeType.COMPONENTS)) {
            this.props.dispatchers.loadComponents(DEFAULT_CATALOG, this.props.renderExpands, this.props.isLoadComponentsUsingGraphQL);
        }
        this.filterRoot(this.props.searchExpression);
        this.props.onComponentSelect(null);
    };

    renderComponentsTree() {
        return <>
            <Segment className="CatalogTree_treeSegment" onClick={() => this.openComponentsTree()}>
                <div>
                    <Icon name="setting" />
                    {_msg("Catalog.components.label")}
                </div>
                {this.props.treeTypeOpen === TreeType.COMPONENTS && this.props.renderButtons &&
                    <div onClick={(e) => {
                        let newComponent = this.createEmptyComponent(this.props.selectedComponent!);
                        newComponent.catalog = this.props.catalogSnapshot;
                        this.props.onComponentSelect(newComponent);
                        e.stopPropagation();
                    }}>
                        <Icon name="plus" />
                        {this.props.selectedComponent}
                    </div>}
            </Segment>
            {this.props.treeTypeOpen === TreeType.COMPONENTS && this.props.loadedTrees.includes(TreeType.COMPONENTS) &&
                <Segment.Group horizontal className="Catalog_componentTypesContainer">
                    <ArrowScrollContainer content={this.renderComponentTypes()} direction={ArrowScrollDirection.HORIZONTAL}/>
                </Segment.Group>}
            {this.props.treeTypeOpen === TreeType.COMPONENTS &&
                <div className="flex flex-grow">
                    <EducationTreeRRC id="ComponentsTree" root={!this.props.filteredRoot ? this.props.rootComponents : this.props.filteredRoot} onExpandCollapseItem={this.handleExpandComponent}
                        onSelectItem={this.handleSelectComponent} ref={this.componentsTreeRef} renderButtons={this.props.renderButtons} openMenuContext={this.openMenuContext} />
                </div>}
        </>;
    }

    resetCurrentVersion(version: any) {
        return {
            active: true,
            attachments: version.attachments,
            competenceVersions: version.competenceVersions,
            trainerProfileVersion: version.trainerProfileVersion,
            objectType: version.objectType,
            name: "",
            evaluationDefinitionVersion: version.evaluationDefinitionVersion,
            majorVersion: 1,
            minorVersion: 0
        };
    }

    resetModuleCurrentVersion(version: any) {
        let currentVersion = this.resetCurrentVersion(version);
        (currentVersion as any).componentVersions = version.componentVersions;
        return currentVersion;
    }

    resetTrackCurrentVersion(version: any) {
        let currentVersion = this.resetCurrentVersion(version);
        (currentVersion as any).children = version.children;
        return currentVersion;
    }

    copyElement(element: any) {
        let newElement: any = {};
        newElement.catalog = _.cloneDeep(element.catalog);
        if (element.currentTrackVersion) {
            newElement.objectType = TRACK;
            newElement.currentTrackVersion = this.resetTrackCurrentVersion(_.cloneDeep(element.currentTrackVersion));
        }
        if (element.currentModuleVersion) {
            newElement.objectType = MODULE;
            newElement.currentModuleVersion = this.resetModuleCurrentVersion(_.cloneDeep(element.currentModuleVersion));
        };
        if (element.currentComponentVersion) {
            newElement.objectType = COMPONENT;
            newElement.currentComponentVersion = this.resetCurrentVersion(_.cloneDeep(element.currentComponentVersion));
            newElement.type = element.type;
        }
        return newElement;
    }

    handleCopyElement = (treeElement: any) => {
        setTimeout(() => {
            let copiedElement = this.copyElement(treeElement);
            let root = this.props.filteredRoot;
            if (treeElement.objectType === TRACK) {
                this.props.onTrackSelect(copiedElement);
                this.tracksTreeRef.current?.props.r.selectItem(root ? `${root.length - 1}` : `${this.props.rootTracks.length - 1}`);
            }
            if (treeElement.objectType === MODULE) { this.props.onModuleSelect(copiedElement); }
            if (treeElement.objectType === COMPONENT) { this.props.onComponentSelect(copiedElement); }
        });
    };

    handleDeleteElement = (treeElement: any) => {
        this.props.dispatchers.setInReduxState({ treeElement: treeElement, confirmDeleteModalOpen: true });
    };

    deselectId = () => {
        this.tracksTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
        this.modulesTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
        this.componentsTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
    };

    deleteElement = (treeElement: any) => {
        if (treeElement.objectType === TRACK) { this.props.dispatchers.removeTrack(treeElement.id); }
        if (treeElement.objectType === TRACK_VERSION) { this.props.dispatchers.removeTrackVersion(treeElement.id); }
        if (treeElement.objectType === MODULE) { this.props.dispatchers.removeModule(treeElement.id); }
        if (treeElement.objectType === MODULE_VERSION) { this.props.dispatchers.removeModuleVersion(treeElement.id); }
        if (treeElement.objectType === COMPONENT) { this.props.dispatchers.removeComponent(treeElement.id); }
        if (treeElement.objectType.includes(COMPONENT_VERSION)) { this.props.dispatchers.removeComponentVersion(treeElement.id); }
        setTimeout(() => {
            this.props.onComponentSelect(null);
            this.deselectId();
        });
    };

    typeSearchExpression(text: string) {
        this.filterRoot(text);
        this.resetExpandedIds();
    }

    resetExpandedIds() {
        this.tracksTreeRef.current?.props.r.setInReduxState({ expandedIds: {} });
        this.modulesTreeRef.current?.props.r.setInReduxState({ expandedIds: {} });
        this.componentsTreeRef.current?.props.r.setInReduxState({ expandedIds: {} });
    }

    filterRoot(searchExpression: string) {
        this.props.onTrackSelect(null);
        this.props.onModuleSelect(null);
        this.props.onComponentSelect(null);
        this.deselectId();
        this.props.dispatchers.filterRoot(searchExpression);
    }

    render(): React.ReactNode {
        return <div className="flex flex-grow">
            <div className="flex-container flex-grow Catalog_catalogTree" >
                {this.props.renderFilters &&
                    <Form>
                        <div className="flex CatalogTree_filtersContainer">
                            <span className="flex-grow CatalogTree_filterInputContainer">
                                <Input className="w100" placeholder={_msg('CatalogTree.input.label')}
                                    value={this.props.searchExpression}
                                    onChange={(e) => this.typeSearchExpression(e.target.value)}
                                />
                            </span>
                            <span className="flex-row">
                                <span className="CatalogTree_checkboxContainer CatalogTree_checkboxContainerExtraPadding">
                                    <Checkbox label={_msg('CatalogTree.active.label')} checked={this.props.activeCheck} onChange={() => {
                                        this.props.dispatchers.setInReduxState({ activeCheck: !this.props.activeCheck });
                                        this.filterRoot(this.props.searchExpression);
                                        this.resetExpandedIds();
                                    }} />
                                </span>
                                <span className="CatalogTree_checkboxContainer">
                                    <Checkbox label={_msg('CatalogTree.notActive.label')} checked={this.props.notActiveCheck} onChange={() => {
                                        this.props.dispatchers.setInReduxState({ notActiveCheck: !this.props.notActiveCheck });
                                        this.filterRoot(this.props.searchExpression);
                                        this.resetExpandedIds();
                                    }} />
                                </span>
                            </span>
                        </div>
                    </Form>
                }
                <div className="Catalog_noMargin flex flex-grow Catalog_catalogTree">
                    {this.props.renderTracks && this.renderTracksTree()}
                    {this.props.renderModules && this.renderModulesTree()}
                    {this.props.renderComponents && this.renderComponentsTree()}
                </div>
                <ModalExt size="mini" severity={Severity.CONFIRMATION}
                    open={this.props.confirmDeleteModalOpen} closeIcon={true}
                    onClose={() => this.props.dispatchers.setInReduxState({ confirmDeleteModalOpen: false })}
                >
                    <Modal.Header>
                        {_msg("CatalogTree.delete.label")}
                    </Modal.Header>

                    <Modal.Content>
                        {this.props.treeElement?.objectType.includes("Version") ?
                            _msg("Catalog.deleteVersionMessage.label") :
                            _msg("Catalog.deleteMessage.label")}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => {
                            this.props.dispatchers.setInReduxState({ confirmDeleteModalOpen: false });
                            this.deleteElement(this.props.treeElement);
                        }
                        }>
                            {_msg("CatalogTree.yes.label")}
                        </Button>
                        <Button onClick={() => this.props.dispatchers.setInReduxState({ confirmDeleteModalOpen: false })}>
                            {_msg("CatalogTree.no.label")}
                        </Button>
                    </Modal.Actions>
                </ModalExt>
                {this.renderMenuContext()}
            </div>
        </div>;
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!_.isEqual(this.props.rootTracks, prevProps.rootTracks) ||
            !_.isEqual(this.props.rootModules, prevProps.rootModules) ||
            !_.isEqual(this.props.rootComponents, prevProps.rootComponents)) {
            this.filterRoot(this.props.searchExpression);
        }
    }

    componentDidMount(): void {
        this.props.dispatchers.loadCatalogSnapshot();
        this.props.dispatchers.loadComponentsTypes();
        this.props.dispatchers.setInReduxState({ activeCheck: true, renderExpands: this.props.renderExpands });
        if (this.props.defaultSegmentOpen) {
            if (this.props.defaultSegmentOpen === "components") {
                this.openComponentsTree(true);
            } else if (this.props.defaultSegmentOpen === "modules") {
                this.openModulesTree(true);
            } else if (this.props.defaultSegmentOpen === "tracks") {
                this.openTracksTree(true);
            }
        }
    }
}