'use strict';

var css = require('./requests.css');

/*@ngInject*/
function EvaluationRequestsCtrl($scope, Flags, Education, ModalSpinner, Confirm, $rootScope, $filter) {
	var requests = this;
	
	requests.flags = new Flags('edit', 'add');

	requests.invalidWizardForSave = true;
	requests.onChange = function() {
		requests.invalidWizardForSave = false;
	}

	// wizard custom actions: notify evaluators and publish evaluations
	var that = requests;
	requests.actions = [
		{
			step: 2,
			onClick: function() {
				that.evaluations.notifyEvaluatorsForPublishedAndNotFinishedEvaluations();
			},
			icons: ["fa-fw fa-bell"],
			invalid: false,
			title: $filter('translate')('education.evaluationRequest.notifyEvaluators')
		}, {
			step: 2,
			onClick: function() {
				that.evaluations.saveEvaluations(true);
				this.invalid = true;
				requests.invalidWizardForSave = true;
			},
			icons: ["fa-fw fa-upload"],
			invalid: true,
			title: $filter('translate')('education.evaluationRequest.saveAndPublish')
		}
	];

	requests.setNotifyInvalid = function(invalid) {
		requests.actions[0].invalid = invalid;
	}

	requests.setPublishInvalid = function(invalid) {
		requests.actions[1].invalid = invalid;
	}

	requests.refresh = function(selectNew) {
		var lastSelectedEvaluationRequest = requests.evaluationRequest;
		delete requests.data;
		delete requests.evaluationRequest;

		requests.masterDetail && requests.masterDetail.goToMaster();

		ModalSpinner.show('education.evaluationRequest.loading.all');
		
		Education.getEvaluationRequests(requests, {
			data: {
				ownerId: requests.filters.owner ? requests.filters.owner.id : null,
				archived: requests.filters.archived
			}
		}).then(function() {
			if (requests.initiallySelectedId) {
				// select the last selected session
				angular.forEach(requests.data, function(evaluationRequest) {
					if (evaluationRequest.id == requests.initiallySelectedId) {
						var evaluationRequestToSelect = selectNew ? evaluationRequest : lastSelectedEvaluationRequest;
						requests.selectEvaluationRequest(evaluationRequestToSelect);
					}
				});
			} else {
				if (requests.newEvaluationRequest && requests.flags.is('add')) {
					// new request (not saved yet): add the request to the
					// beggining of the list, then select it 
					requests.data.unshift(requests.newEvaluationRequest);
					requests.selectEvaluationRequest(requests.data[0]);
					delete requests.newEvaluationRequest;
					requests.masterDetail.goToDetail();
				}
			}
		}).finally(function() {
			ModalSpinner.hide();
		});
	};

	// server-side
	requests.filters = {
		prevOwner: $rootScope.securityContext.resource,
		owner: $rootScope.securityContext.resource
	};

	// client-side
	// requests.filter = {
	// 	elementName: 'evaluationRequest'
	// };

	requests.refresh();

	requests.backToFirstStep = function() {
		if (requests.evaluationRequest) {
			if (requests.evaluationRequest.id) {
				requests.selectEvaluationRequest(requests.evaluationRequest);
			} else {
				// new request (not yet saved): we will use this value on refresh
				requests.newEvaluationRequest = requests.evaluationRequest;
			}
		}
		requests.refresh();
	};

	requests.componentVersionFn = function(value) {
		if (arguments.length) {
			if (!value) {
				requests.evaluationRequest.componentVersion = null;
			} 
		} else {
			return requests.evaluationRequest &&
				requests.evaluationRequest.componentVersion &&
				requests.evaluationRequest.componentVersion.name;
		}
	};

	requests.catalogs = {
		visible: ['COMPONENT'],
		select: function(scope) {
			if (scope.element.id !== (requests.component ? requests.component.id : 0)) {
				requests.component = scope.element;
				requests.evaluationRequest.componentVersion = scope.element.currentComponentVersion;
				$scope.parentDropdownOpen = false;
			}
		}
	};

	requests.disableOwnerDropdown = $rootScope.securityContext.permissions.filter(item => item.code === 'EDUCATION_VIEW_ALL_EVALUATION_REQUESTS_ROLE').length === 0;

	/////////////////////
	// click handlers
	/////////////////////

	requests.selectEvaluationRequestOnClick = function(evaluationRequest) {
		if (requests.flags.is('add')) {
			// remove the new request (not saved yet)
			requests.flags.remove('add');
			delete requests.evaluationRequest;
			requests.refresh(true);
		}
		evaluationRequest.selectOrCreateNewEvaluationRequest = true;
		requests.selectEvaluationRequest(evaluationRequest);
	}

	requests.selectEvaluationRequest = function(evaluationRequest) {
		requests.flags.add('edit');

		if (!evaluationRequest.id || !requests.initiallySelectedId || evaluationRequest.id != requests.initiallySelectedId) {
			requests.invalidWizardForSave = true;
		}

		requests.initiallySelectedId = evaluationRequest.id;
		requests.evaluationRequest = evaluationRequest;
		requests.evaluationRequest.visible = ['COMPONENT'];
		requests.evaluationRequest.publishDate = requests.evaluationRequest.publishDate ? new Date(requests.evaluationRequest.publishDate) : requests.evaluationRequest.publishDate;
		requests.evaluationRequest.archiveDate = requests.evaluationRequest.archiveDate ? new Date(requests.evaluationRequest.archiveDate) : requests.evaluationRequest.archiveDate;
		requests.evaluationRequest.requestDate = requests.evaluationRequest.requestDate ? new Date(requests.evaluationRequest.requestDate) : requests.evaluationRequest.requestDate;
		requests.masterDetail.goToDetail();
	};

	requests.unselectEvaluationRequest = function(evaluationRequest) {
		if (!requests.evaluationRequest) {
			// nothing to unselect
			return;
		}
		if (requests.evaluationRequest.id == evaluationRequest.id) {
			requests.flags.remove('edit');
			delete requests.initiallySelectedId;
			requests.evaluationRequest = null;
		}
	};

	requests.addEvaluationRequest = function(evaluationRequest) {
		requests.flags.add('add');
		evaluationRequest = evaluationRequest || {
			environment: 'BRABO',
			name: 'Nieuw verzoek',
			owner: $rootScope.securityContext.resource,
			requestDate: new Date()
		};
		requests.data.unshift(evaluationRequest);
		evaluationRequest.selectOrCreateNewEvaluationRequest = true;
		requests.selectEvaluationRequest(evaluationRequest);
		requests.invalidWizardForSave = false;
	};

	requests.copyEvaluationRequest = function(evaluationRequest, index, $event) {
		var owner = evaluationRequest.owner || $rootScope.securityContext.resource;
		requests.addEvaluationRequest({
			environment: evaluationRequest.environment,
			name: evaluationRequest.name,
			description: evaluationRequest.description,
			remark: evaluationRequest.remark,
			owner: owner,
			requestDate: new Date()
		});
		$event.stopPropagation();
	};

	requests.isValid = function(requestsScope) {
		// skip form validation if the request is archived
		if (!document || !document.form || this.evaluationRequest.archiveDate) {
			requestsScope.wizard.invalid = false;
			return true;
		}

		let isFormValid = document.form.checkValidity();
		// We have required=true for the owner field, but is not enough. We can type "jo" and the form will be considered 
		// valid, but we have to make sure that we have selected an employee.
		if (this.evaluationRequest.owner == null || this.evaluationRequest.owner.id == null) {
			isFormValid = false;
		}
		requestsScope.wizard.invalid = !isFormValid;
		return isFormValid;
	}

	requests.removeEvaluationRequest = function(evaluationRequest) {
		if (!evaluationRequest.id) {
			requests.doRemoveEvaluationRequest(evaluationRequest);
		} else {
			var message = 'education.evaluationRequest.remove.message';
			if (evaluationRequest.publishDate) {
				message = 'education.evaluationRequest.remove.messageArchive';
			}

			Confirm.open({
				title: 'education.evaluationRequest.remove.title',
				message: message
			}).result.then(function() {
				if (evaluationRequest.publishDate) {
					requests.archiveEvaluationRequest(evaluationRequest);
				} else {
					requests.doRemoveEvaluationRequest(evaluationRequest);
				}
			});
		}
	};

	requests.archiveEvaluationRequest = function(evaluationRequest) {
		Education.archiveEvaluationRequest(null, {
			params: {
				evaluationRequestId: evaluationRequest.id
			}
		}).then(function() {
			requests.refresh();
		});
		requests.masterDetail.goToMaster();
	}

	requests.doRemoveEvaluationRequest = function(evaluationRequest) {
		requests.unselectEvaluationRequest(evaluationRequest);
		if (!evaluationRequest.id) {
			// new request => just remove it from list
			requests.flags.remove('add');
			requests.data.shift();
		} else {
			Education.deleteEvaluationRequest({}, {
				params: {
					id: evaluationRequest.id
				}
			}).then(function() {
				requests.refresh();
			});
		}
		requests.masterDetail.goToMaster();
	};

	requests.onSelectOwner = function(resource) {
		if (!resource || !requests.filters.prevOwner || resource.id != requests.filters.prevOwner.id) {
			requests.filters.prevOwner = resource;
			requests.refresh();
		}
	};

	requests.onSave = function() {
		if (requests.flags.is('add')) {
			requests.flags.remove('add');
		}
	}
	
	requests.onEvaluationRequestOwnerChange = function(resource) {
		if (requests.evaluationRequest && (requests.evaluationRequest.owner = resource) && 
			(requests.oldEvaluationRequest && requests.oldEvaluationRequest.id != requests.evaluationRequest.id)) {
			requests.oldEvaluationRequest = requests.evaluationRequest;
			return;
		}
		requests.oldEvaluationRequest = requests.evaluationRequest;
		requests.onChange();
	};
}

module.exports = EvaluationRequestsCtrl;