'use strict';

angular.module('proteus.education', ['proteus.components', 'ui.grid', 'ui.grid.selection', 'ui.grid', 'ui.grid.pinning', 'proteus.tasks', require('ng-file-upload')])
.run(function($templateCache) {
	$templateCache.put('/catalogs/catalogs.html', require('./catalogs/catalogs.html'));
	$templateCache.put('/competences/competences.html', require('./competences/competences.html'));
	$templateCache.put('/evaluation/request/requests.html', require('./evaluation/request/requests.html'));
	$templateCache.put('/evaluation/fill-in/evaluations-fill-in.html', require('./evaluation/fill-in/evaluations-fill-in.html'));

	$templateCache.put('/catalogs/catalogs-groups.html', require('./catalogs/catalogs-groups.html'));
	$templateCache.put('/catalogs/catalogs-group.html', require('./catalogs/catalogs-group.html'));
	$templateCache.put('/catalogs/training-competences.html', require('./catalogs/training-competences.html'));
	$templateCache.put('/competences/competences-tree.html', require('./competences/competences-tree.html'));
	
	$templateCache.put('/evaluation/request/request-form.html', require('./evaluation/request/request-form.html'));
	$templateCache.put('/evaluation/request/evaluations-actions-header.html', require('./evaluation/request/evaluations-actions-header.html'));
	$templateCache.put('/evaluation/request/evaluations-date-header.html', require('./evaluation/request/evaluations-date-header.html'));
	$templateCache.put('/evaluation/fill-in/score.html', require('./evaluation/fill-in/score.html'));
	
	$templateCache.put('/curriculum/curriculum-tree.html', require('./curriculum/curriculum-tree.html'));
	$templateCache.put('/profiles/profiles-typeahead.html', require('./profiles/profiles-typeahead.html'));

	$templateCache.put('/group-registrations/group-registrations.html', require('./group-registrations/group-registrations.html'));
	$templateCache.put('/group-registrations/sessions-form.html', require('./group-registrations/sessions-form.html'));
	$templateCache.put('/group-registrations/training-time-periods-trainer.html', require('./group-registrations/training-time-periods-trainer.html'));
	$templateCache.put('/group-registrations/training-time-periods-actions-header.html', require('./group-registrations/training-time-periods-actions-header.html'));
	$templateCache.put('/group-registrations/time-period-editor.html', require('./group-registrations/time-period-editor.html'));
})

.factory('Education', 				require('./education.service'))
.factory('Profile', 				require('./profiles/profile.service'))
.factory('Curriculum', 				require('./curriculum/curriculum.service'))
.factory('Catalog', 				require('./curriculum/catalog.service'))
.factory('RealizationEditorModal', 	require('./curriculum/realization-editor-modal.service.js'))
.factory('ObjectiveEditorModal', 	require('./curriculum/objective-editor-modal.service.js'))

.filter('catalogs', require('./catalogs/catalogs.filter'))
.filter('scale', require('./competences/scale.filter'))

.controller('CatalogsCtrl', 		require('./catalogs/catalogs.controller'))
.controller('CatalogsGroupsCtrl', 	require('./catalogs/catalogs-groups.controller'))
.controller('CompositionCtrl', 		require('./catalogs/composition.controller'))
.controller('TrainerProfileCtrl', 	require('./catalogs/trainer-profile.controller'))
.controller('EvaluationCtrl',		require('./catalogs/evaluation.controller'))
.controller('TrainingCompetencesCtrl', 	require('./catalogs/training-competences.controller'))
.component('catalogsTypeahead',     require('./catalogs/catalogs-typeahead.component'))
.component('catalogsDropdown',       require('./catalogs/catalogs-dropdown.component'))

.controller('CompetencesCtrl', 					require('./competences/competences.controller'))
.controller('CompetencesTreeCtrl', 				require('./competences/competences-tree.controller'))
.controller('CompetencesCategoriesTreeCtrl', 	require('./competences/competences-categories-tree.controller'))
.controller('DefinitionsCtrl', 					require('./competences/definitions.controller'))
.controller('CompetencesTrainingComponentsCtrl',	require('./competences/competences-training-components.controller'))
.component('competencesTypeahead',              require('./competences/competences-typeahead.component'))

.controller('EvaluationRequestsCtrl', 		require('./evaluation/request/requests.controller'))
.controller('EvaluationComponentsListCtrl', require('./evaluation/request/evaluation-components.controller'))
.controller('EvaluationsListCtrl', 			require('./evaluation/request/evaluations.controller'))
.controller('EvaluationsFillInCtrl', 		require('./evaluation/fill-in/evaluations-fill-in.controller'))
.controller('EvaluationCriteriaCtrl', 		require('./evaluation/fill-in/evaluation-criteria.controller'))

.controller('CurriculumTreeCtrl', 		  	require('./curriculum/curriculum-tree.controller'))
.component('curriculumPage', 				require('./curriculum/curriculum-page.component'))
.component('realizationTable', 				require('./curriculum/realizations-table.component'))
.component('realizationEditor', 			require('./curriculum/realization-editor.component'))
.component('objectiveEditor', 			    require('./curriculum/objective-editor.component'))
.component('treeAddMenu', 					require('./curriculum/tree-add-menu.component'))
.component('profilesTree', 					require('./profiles/profiles-tree.component'))
.component('profilesTypeahead',             require('./profiles/profiles-typeahead.component'))

.controller('GroupRegistrationsCtrl',		require('./group-registrations/group-registrations.controller'))
.controller('TrainingTimePeriodsListCtrl',	require('./group-registrations/training-time-periods.controller'))
.component('timePeriodEditor', 				require('./group-registrations/time-period-editor.component'))

.directive('proteusAttachments', require('./attachments/attachments.directive'))

.constant('CurriculumConstants', require('./curriculum/curriculum.constants'))
.constant('AgendaConstants',  require('../planning/agenda-page.constants'));

module.exports = 'proteus.education';