import { ADD, apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { PasswordEditor } from "@crispico/foundation-react/pages/user/PasswordEditor";
import { ACCOUNT_SERVICE_SAVE_OR_UPDATE_ACCOUNT_WITH_PASSWORD_CONFIRMATION, EMPLOYEE_SERVICE_GET_EMPLOYEE_BY_ID_WITH_CONTRACT } from "graphql/queries";
import _ from "lodash";
import { Person } from "pages/employeeDetail/EmployeeDetailEntityDescriptor";
import { PersonAssociationFieldEditor } from "pages/realization/RealizationEntityDescriptor";
import React from "react";
import { Button, Icon } from "semantic-ui-react";

const PERSON: string = "person";
const PASSWORD: string = "password";

export class AccountEntityDescriptor extends EntityDescriptor {

    protected customize() {

        const accountEntityDescriptor = this;
        // Hide password field in table and editor. In table it should not be visible, and in editor it is replaced with PasswordEditor component
        accountEntityDescriptor.isInDefaultColumnConfig(false, PASSWORD);

        const sliceAccountEditor = accountEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceAccountEditor extends SliceEntityEditorPage {

            getSaveOperationName(): string {
                return 'accountService_saveOrUpdateAccountWithPasswordConfirmation';
            }

            initQueries() {
                super.initQueries();
                this.saveMutation = ACCOUNT_SERVICE_SAVE_OR_UPDATE_ACCOUNT_WITH_PASSWORD_CONFIRMATION;
            }

            // Overriden method from SliceEntityEditorPage to show an user-friendly message in case of validation exception
            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                initialEmployee: undefined as unknown as Person,
                editPasswordEnabled: false as boolean,
                hashPassword: undefined as unknown as string
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceAccountEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceAccountEditor>(this),

                // Initial employee data is needed to show the current employee in dropdown as selected
                async getInitialEmployeeData(id: number) {
                    let initialEmployee = (await apolloClient.query({
                        query: EMPLOYEE_SERVICE_GET_EMPLOYEE_BY_ID_WITH_CONTRACT,
                        variables: {
                            employeeId: id
                        }
                    })).data.employeeService_employee;
                    initialEmployee = { ...initialEmployee, contractHistoryItem: { employeeNumber: initialEmployee?.detail?.contractHistory[0]?.employeeNumber } };
                    delete initialEmployee?.detail;
                    this.getDispatchers().setInReduxState({ initialEmployee });
                },

                superLoad: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.superLoad(id);
                    if (entity !== undefined) {
                        if (entity.person !== null && entity.person !== undefined) {
                            await this.getInitialEmployeeData(entity.person);
                        }
                    }
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    entity = this.modifyAccountBeforeSave({ ...entity });
                    return await this.saveSuper(entity);
                },

                superMutation: sliceEntityEditorPageOnlyForExtension.impures.invokeSaveMutation,
                async invokeSaveMutation(options: any) {
                    const confirmationNewPassword = this.getState().entity.confirmPassword;
                    options.variables = { account: this.modifyAccountBeforeSave({ ...this.getState().entity }), confirmationNewPassword };
                    let entity = await this.superMutation(options);
                    this.getDispatchers().setInReduxState({ hashPassword: entity.data[this.getSlice().getSaveOperationName()].password });
                    return entity;
                },

                modifyAccountBeforeSave(entity: any) {
                    if (entity.person !== null && entity.person !== undefined && entity.person.id !== undefined) {
                        entity = { ...entity, person: entity.person.id };
                    }
                    delete entity.confirmPassword;
                    return entity;
                }
            }
        }).setEntityDescriptor(accountEntityDescriptor);

        accountEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceAccountEditor> & EntityEditorPageProps> {

            protected refPasswordEditor = React.createRef<PasswordEditor>();

            protected onMatchChanged(match: any) {
                super.onMatchChanged(match);
                this.resetPasswordEditorState();
            }

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "Account" }, false);
                accountEntityDescriptor.doForFields(null, (fieldDescriptor) => {
                    if (fieldDescriptor.enabled === true) {
                        return result.entityDescriptor.addFieldDescriptor(fieldDescriptor);
                    }
                });
                result.entityDescriptor.removeFieldDescriptors(PASSWORD);
                result.entityDescriptor.addFieldDescriptor({ name: PERSON, type: "EmployeeSnapshot" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        const newProps = {
                            ...props,
                            includeAllWithValidContract: false,
                            isClearable: false,
                            queryLimit: -1,
                        };
                        return React.createElement(PersonAssociationFieldEditor as any, newProps as FieldEditorProps);
                    }
                });
                if (result.entity.person !== undefined && result.entity.person !== null && result.entity.person.id === undefined &&
                    result.entity.person === this.props.initialEmployee?.id) {
                    result.entity = { ...result.entity, person: this.props.initialEmployee };
                }
                return result;
            }

            protected getEntityValuesFromForm() {
                // because in the form the "person" field is of type "object" and in the table it is of type number (person id), a conversion
                // is needed when navigating from "edit properties" to "view properties" (from form to table)
                let values = super.getEntityValuesFromForm();
                if (values.person && values.person.id !== undefined) {
                    this.props.dispatchers.setInReduxState({ initialEmployee: values.person });
                    values = { ...values, person: values.person.id };
                }
                return values;
            }

            editPasswordChangeHandler = () => {
                // Can not deactivate the password editor on new user creation. Setting the password is required
                if (this.props.match?.params.id == ADD) {
                    return;
                }
                this.props.dispatchers.setInReduxState({ editPasswordEnabled: !this.props.editPasswordEnabled });
            }

            protected resetPasswordEditorState() {
                let editPasswordEnabled: boolean = false;
                if (this.props.match?.params.id == ADD) {
                    // When creating a new user editing the password is required
                    editPasswordEnabled = true;
                }
                this.props.dispatchers.setInReduxState({ editPasswordEnabled });
            }

            protected renderForm() {
                return <div>
                    {super.renderForm()}
                    <>
                        <Button className="UserEditorPage_additionalField" icon labelPosition='left' onClick={this.editPasswordChangeHandler}
                            active={this.props.editPasswordEnabled}>
                            <Icon name={this.props.editPasswordEnabled ? "check square outline" : "square outline"} />
                            {_msg("UserEditorPage.edit.password")}
                        </Button>
                        <div className={this.props.editPasswordEnabled ? "UserEditorPage_passwordEditor_enabled" : "UserEditorPage_passwordEditor_disabled"}>
                            <PasswordEditor conditionsForPassword={{ PASSWORD_MIN_LENGTH: 8 }} className="UserEditorPage_additionalField"
                                hasInputForCurrentPassword={false} ref={this.refPasswordEditor} />
                        </div>
                    </>
                </div>;
            }

            protected async onSave() {
                if (this.props.editPasswordEnabled) {
                    const passwordEditor: PasswordEditor = this.refPasswordEditor.current!;
                    // If we don't call this some validation errors could be skipped 
                    await passwordEditor.submitForm();
                    // Invalid new password
                    if (passwordEditor.hasErrors()) {
                        return;
                    }
                    // Apply changes before adding password (from form) to entity
                    // Otherwise, this.props.entity is empty and entity will only contain password and confirmPassword
                    await super.onApply();
                    this.props.dispatchers.setInReduxState({
                        entity: {
                            ...this.props.entity,
                            password: passwordEditor.formikContext.values.newPassword,
                            confirmPassword: passwordEditor.formikContext.values.newPasswordConfirmation
                        }
                    });
                }
                await super.onSave();
                let entityAfterSave = { ...this.props.entity, objectVersion: this.props.entity?.objectVersion !== undefined ? this.props.entity.objectVersion + 1 : 1 };
                if (this.props.editPasswordEnabled) {
                    entityAfterSave = { ...entityAfterSave, password: this.props.hashPassword };
                    delete entityAfterSave.confirmPassword;
                    // Reset new password and its editors after each succesfull save
                    this.resetPasswordEditorState();
                    this.refPasswordEditor.current!.formikContext.resetForm();
                }
                this.props.dispatchers.setInReduxState({ entity: entityAfterSave, hashPassword: undefined });
            }
        }
    }
}