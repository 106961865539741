'use strict';

module.exports = {
	name: 'proteus.credit',
	path: 'credit.module.js',
	authRoutes: {
		'/credit': {
			templateUrl: 'credit-page.html',
			controller: 'CreditPageCtrl',
			controllerAs: 'credit'
		}
	}
};