'use strict';

/*@ngInject*/
function Principal($rootScope, $location, User, ModalSpinner, $q, $window, Common) {

	return {

		/**
		 * Perform logout if the user is logged in; otherwise, execute the
		 * callback directly.
		 */
		logout : function() {
			User.loginRequested = false;
			User.logoutRequested = true;
			if ($rootScope.securityContext && $rootScope.securityContext.userName) {
				// logout
				$rootScope.securityContext = null;
				return Common.getLogoutParameter().then(function(result) {
					if (result.data.isLoginWithAzure) {
						$window.location.href = result.data.logoutUri;
					}
					User.logout().finally(function() {
						$location.url('/account/login');
					});
				});
				
				//return 
			} else {
				return {
					then : function(callback) {
						callback();
					}
				};
			}
		},
		
		hasPermissions: function(permission) {
			var deferred = $q.defer();
			
			this.checkPermissions(permission, function(hasRole) {
				deferred.resolve(hasRole);
			});
			return deferred.promise;
		},

		/**
		 * @param permissions
		 *            Comma-separated list of permissions.
		 * @param callback
		 *            Invoked every time the securityContext changes.
		 */
		checkPermissions : function(permissions, callback) {
			permissions = permissions ? permissions.split(',') : [];

			function doCheckPermissions() {
				if (!permissions || permissions.length == 0) {
					return true;
				}

				if (!$rootScope.securityContext.permissions) {
					return false;
				}

				var count = 0;
				for (var i = 0; i < permissions.length; i++) {
					var permissionCode = permissions[i];
					if (!permissionCode) {
						count++;
						continue;
					}
					for (var j = 0; j < $rootScope.securityContext.permissions.length; j++) {
						if ($rootScope.securityContext.permissions[j].code == permissionCode) {
							count++;
						}
					}
				}
				return count == permissions.length;
			}

			if ($rootScope.securityContext) {
				callback(doCheckPermissions());
			} else {
				var deregister = $rootScope.$watch('securityContext', function(newValue, oldValue) {
					if (newValue === oldValue) {
						return;
					}
					callback(doCheckPermissions());
					deregister();
				});

				// get security context from server
				if (!User.loginRequested) {
					ModalSpinner.show();
					User.loginRequested = true;
					User.login();
				}
			}
		}
	};
}

module.exports = Principal;