import { createSliceFoundation, EntityDescriptor, EntityEditorFormSimple, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { getEmployee_employeeService_employee } from "apollo-gen/getEmployee";
import React from "react";
import { CUSTOM_HISTORY_FIELD } from "./customFieldRenderersEditors";
import { EMPLOYEE_EDITOR } from "./EmployeeEditorBase";

export const CONSTRAINT_GROUP_HISTORY = "ConstraintGroupHistory";
export const CONSTRAINT_GROUP_HISTORY_FIELD = "constraintGroupHistory";
export const CONSTRAINT_GROUP_FIELD = "constraintGroup";

export const CONSTRAINT_GROUP_SCRIPTABLE_UI_ID = "EmployeeEditor_ConstraintGroupTab";

export const sliceConstraintGroupTab = createSliceFoundation(class SliceConstraintGroupTab {

    initialState = {
        openedConstraintGroupId: undefined as unknown as number
    }

    reducers = {
        ...getBaseReducers<SliceConstraintGroupTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceConstraintGroupTab>(this)
    }
})

type PropsNotFromState = {
    employee: getEmployee_employeeService_employee | undefined | null;
    isEditable: boolean;
    onOpenTableLight: (fieldDescriptor: FieldDescriptor, entities: any[]) => void;
    onRemoveCustomHistoryField: (openedConstraintGroupId: number, keyField: string) => void;
    referenceDate: Date | number;
}

export class ConstraintGroupTab extends TabbedPage<PropsFrom<typeof sliceConstraintGroupTab> & PropsNotFromState> {

    public onOpenCustomHistoryTable = (fieldDescriptor: FieldDescriptor, entities: any[], openedConstraintGroupId: number) => {
        this.props.onOpenTableLight(fieldDescriptor, entities);
        this.props.dispatchers.setInReduxState({ openedConstraintGroupId: openedConstraintGroupId });
    }

    public getConstraintGroupsEntityDescriptor() {
        return new EntityDescriptor({ name: EMPLOYEE_EDITOR }, false)
            .addFieldDescriptor({
                name: "employee.detail.constraintGroupHistory",
                type: CUSTOM_HISTORY_FIELD,
                entityName: CONSTRAINT_GROUP_HISTORY,
                mandatoryFields: ["constraintGroup"],
                icon: "group",
                onOpenCustomHistoryTable: this.onOpenCustomHistoryTable,
                onRemoveCustomHistoryField: this.props.onRemoveCustomHistoryField,
                isDisabled: !this.props.isEditable,
                referenceDate: this.props.referenceDate
            });
    }

    render() {
        const displayConstraints = this.props.employee?.detail?.constraintGroupHistory
            && this.props.employee.detail.constraintGroupHistory.length > 0 ? true : false;
        return (
            <div className={displayConstraints ? "" : " ConstraintGroupTab_hide"}>
                <EntityEditorFormSimple scriptableUiId={CONSTRAINT_GROUP_SCRIPTABLE_UI_ID} entityDescriptor={this.getConstraintGroupsEntityDescriptor()}
                    hideButtonBar entity={{ employee: this.props.employee }} />
            </div>
        );
    }
}