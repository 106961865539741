'use strict';

/**
 * Can be used to display realizations and exemptions as the two classes have near identical structure.
 */
var realizationTable = {
		template: require('./realizations-table.html'),
		bindings: {
			  catalogItemObjective: '<',	
			  realizations: '<',			  
			  employee: '<',
			  reviewersMap: '<',
			  userToPersonMap: '<',
			  onRealizationAdded: '=', // i.e. realization or exemption added
			  onRealizationRemoved: '=', // i.e. realization or exemption removed
			  statusMap: '<'
		},
		controllerAs: 'realizationTable',
		controller: /*@ngInject*/function($scope, $rootScope, ModalSpinner, FormModal, Curriculum, RealizationEditorModal, Confirm, $filter, DATE_FORMAT) {
			
			var realizationTable = this;
			realizationTable.dateFormat = DATE_FORMAT;
			realizationTable.hideObjectiveFields = false;
			
			realizationTable.$onInit = function() {
				initObjectiveStatusVisibility();
			};
			
			realizationTable.gridOptions = {
					columnDefs: [
								{
									name: '_componentVersion',
									displayName: 'curriculum.realization.componentVersion',
					            	cellTemplate: '<div>{{row.entity.componentVersion.name}} <span class="text-muted">v{{row.entity.componentVersion.version}}</span></div>'
					             },
					             {
					            	 name: '_status',
					            	 displayName: 'curriculum.realization.status',
					            	// field: 'result.description' //exemptionStatus
					            	 labelFn: function(entity) {
					            		 if (entity.objectType == 'Realization') {
					            			 return entity.result ? entity.result.description : '';
					            		 } else if (entity.objectType == 'Exemption') {
					            			 return $filter('translate')('curriculum.exemptionStatus');
					            		 }
					            	 }
					             },
					             {
					            	 name: '_date',
					            	 displayName: 'curriculum.realization.date',
					            	 cellTemplateUrl: '/education/curriculum/date.html'
					             },
					             {
					            	 name: '_finalReviewer',
					            	 displayName: 'curriculum.realization.finalReviewer', //'Eindbeoordeler',
					            	 labelFn: function(entity) {
					            		 if (entity.objectType == 'Realization') {
						            		 if (entity.finalReviewer) {
						            			 var reviewer/*:PersonSnapshot*/ = realizationTable.reviewersMap[entity.finalReviewer];
						            			 if (reviewer) {
						            				 // after changing selection, this labelFn is called the first time with the previous realization, so is possible
						            				 // that we do not have the reviewers.
						            				 return reviewer.firstName + ' ' + reviewer.name;
						            			 }
						            		 }
					            		 }
					            		 return "";
					 				}
					             },
					             {
					            	 name: '_expirationDate',
					            	 displayName: 'curriculum.realization.expirationDate', //'Vervaldatum',
					            	 cellTemplateUrl: '/education/curriculum/dueDate.html'
					             },
					             { 
					            	 name: '_extraColumn',
					            	 cellTemplateUrl: '/education/curriculum/removeCell.html',
					            	 headerTemplateUrl: '/education/curriculum/addRealizationAndExemptionItemHeader.html',
			             		 }
					             ],
					date: realizationTable.realizations,
					onRegisterApi: function(gridApi) {
						gridApi.selection.on.rowSelectionChanged(null, function(row, index, event) {
							var element = event.target;
							if (element == undefined || element.classList.contains('fa-times') || element.classList.contains('remove-realization-btn')) {
								return;
							}

							realizationTable.editItem(row.entity);
						});
					}
			};


			realizationTable.addRealization = function() {
				realizationTable.editItem({
					objectType: 'Realization',
					person: realizationTable.employee.id
				});
			};
			
			realizationTable.addExemption = function() {
				realizationTable.editItem({
					objectType: 'Exemption',
					person: realizationTable.employee.id
				});
			};
			
			realizationTable.editItem = function(entity) {
				// always initialise final reviewer with the current user
				if (!entity.id && !entity.finalReviewer) {
					entity.finalReviewer = $rootScope.securityContext.resource.id;
					realizationTable.reviewersMap[entity.finalReviewer] = $rootScope.securityContext.resource;
				}

				RealizationEditorModal.open({
					entity: entity, 
					finalReviewer: realizationTable.reviewersMap[entity.finalReviewer], 
					employee: realizationTable.employee,
					catalogItemObjective: realizationTable.catalogItemObjective,
					reviewersMap: realizationTable.reviewersMap,
					disabled: true
				}).then(function(result) {
					// a realization or exemption has been added. We update the current displayed table of realizations/exemptions
					var savedRealization = result.data;
					var existingRealization = null;
					for (var i = 0; i < realizationTable.realizations.length; i++) {
						if (realizationTable.realizations[i].id == result.data.id) {
							existingRealization = realizationTable.realizations[i];
							break;
						}
					}
					
					if (existingRealization) {
						//angular.merge(existingRealization, savedRealization); // does not update attachments (after removing an attachment)
						angular.extend(existingRealization, savedRealization);
						return existingRealization;
					} else {							
						var realizationOrExemption = result.data;
						var catalogItemObjective = realizationTable.catalogItemObjective;
						
						// TODO: I think this check should be simpler. I.e. a check catalogItemObjective.component = realization.componentVersion.component
						// but what if it's a catalogItemObjective for module or track? then we can check the children (with the iterator)
						return Curriculum.belongsToCatalogItem({}, {
							params: {
								realization: realizationOrExemption.objectType == "Realization" ? realizationOrExemption.id : null,
								exemption: realizationOrExemption.objectType == "Exemption" ? realizationOrExemption.id : null,
								track: catalogItemObjective.track ? catalogItemObjective.track.id : null,
								module: catalogItemObjective.module ? catalogItemObjective.module.id : null,
								component: catalogItemObjective.component ? catalogItemObjective.component.id : null,
							}								
						}).then(function(result) {
							if (result.data === true) {
								realizationTable.realizations.push(realizationOrExemption);
							}
							return realizationOrExemption;
						});							
					}
				}).then(function(realization) {
					// update the catalog item objective (completed status might have change)
					// and then recalculated all completed statuses					
					if (realizationTable.onRealizationAdded) {
						realizationTable.onRealizationAdded(realization);
					}
					realizationTable.realizations.sort(realizationTable.compareRealizations);
				});
			};

			realizationTable.removeItem = function(entity) {
				Confirm.open({
					title: 'clear',
					message: 'curriculum.realization.confirmRemove'
				}).result.then(function() {
					if (entity.objectType == 'Realization') {
						ModalSpinner.show('curriculum.realization.remove');
						Curriculum.removeRealization({}, {
							params: {
								id: entity.id
							}
						}).then(function(r) {
							var index = realizationTable.realizations.indexOf(entity);
							realizationTable.realizations.splice(index, 1);
							
							if (realizationTable.onRealizationRemoved) {
								realizationTable.onRealizationRemoved(entity);
							}
						}).finally(function() {
							ModalSpinner.hide();
						});
					} else if (entity.objectType == 'Exemption') {
						ModalSpinner.show('curriculum.exemption.remove');
						Curriculum.removeExemption({}, {
							params: {
								id: entity.id
							}
						}).then(function(r) {
							var index = realizationTable.realizations.indexOf(entity);
							realizationTable.realizations.splice(index, 1);
							
							if (realizationTable.onRealizationRemoved) {
								realizationTable.onRealizationRemoved(entity);
							}
							realizationTable.realizations.sort(realizationTable.compareRealizations);
						}).finally(function() {
							ModalSpinner.hide();
						});
					}
				});
			};
			
			function initObjectiveStatusVisibility() {
				var objective = realizationTable.catalogItemObjective ? realizationTable.catalogItemObjective.objective : null;				
				realizationTable.hideObjectiveFields = objective == null;
			}
			
			realizationTable.$onChanges = function(changesObj) {
				realizationTable.gridOptions.data = realizationTable.realizations;
				
				if (changesObj.catalogItemObjective) {
					initObjectiveStatusVisibility();
				}
			};

			realizationTable.compareRealizations = function(r1, r2) {
				var v1 = r1.componentVersion.version;
				var v2 = r2.componentVersion.version;

				var versionCompare = v2.localeCompare(v1);
				if (versionCompare != 0) {
					return versionCompare;
				}

				if (!r1.date) {
					return -1;
				} else if (!r2.date) {
					return 1;
				}

				return new Date(r2.date) - new Date(r1.date);
			}
		}
	};

module.exports = realizationTable;