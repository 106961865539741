import { Utils } from "@crispico/foundation-react";
import { Expanded, LinearizedItem, Props, RenderItemParams, Tree, TreeReducers, TreeState } from "@crispico/foundation-react/components/TreeRRC/Tree";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import _ from "lodash";
import React from "react";
import { Button, Icon, Segment } from "semantic-ui-react";

class CompetenceTreeState extends TreeState {
    minimizeTree = false as boolean;
    categoryToBeHidden = "" as string;
    active = true as boolean;
    notActive = true as boolean;
    selectedComponentId = "" as string;
}

type CompetenceProps = Props & {
    onSelectCompetence?: Function,
    onSelectCompetenceTemp?: (competence?: any) => void,
    onSelectVersion?: Function,
    onSelectCategory?: Function,
    activateDeleteModal?: Function,
    openContextMenuModal?: Function,
    searchExpression: string,
    // minimizeTree is used to show just competence categories from tree
    minimizeTree?: boolean,
    // hideActions disable buttons with options from elements of tree
    hideActions?: boolean,
    // categoryToBeHidden should contain an id of a competence category that we don't want to be displayed 
    categoryToBeHidden?: string,
    active?: boolean,
    notActive?: boolean,
    selectedComponentId?: string;
    renderDeleteOnLeaves?: boolean;
    onDeleteCompetence?: (competence?: any) => void;
} & RRCProps<CompetenceTreeState, CompetenceTreeReducers>;

interface ComponentDetails {
    name: string,
    version?: string;
}

class CompetenceTreeReducers<S extends CompetenceTreeState = CompetenceTreeState> extends TreeReducers<S> {
    /**
     * If the item is array, the children will be represented by his elements (this is the case for root of
     * the tree). Otherwise, we try to find children based on specific fields from item (currentCompetenceVersion,
     * competences, categoryChildren etc.).
     */
    protected _getChildren(item: any): { localId: string, item: any; }[] {
        if (Array.isArray(item)) {
            return item.map((value: any, index: any) => ({
                localId: value.id + "",
                item: value
            }));
        }
        let children = [];
        if (item.currentCompetenceVersion) {
            if (item.versions !== undefined) {
                children = item.versions.map((value: any, index: any) => ({
                    localId: value.id + "",
                    item: value
                }));
            }
            return children;
        }
        if (item.competences.length === 0) {
            let items = item.categoryChildren;
            if (this.s.minimizeTree) {
                items = items.filter((value: any, index: any) => value.id !== Number(this.s.categoryToBeHidden));
            }
            return items.map((value: any, index: any) => ({ localId: value.id + "", item: value }));
        }
        if (!this.s.minimizeTree) {
            children = item.competences.map((value: any, index: any) => ({ localId: value.id + "", item: value }));
        }
        if (item.categoryChildren && item.categoryChildren.length !== 0) {
            let items = item.categoryChildren;
            if (this.s.minimizeTree) {
                items = items.filter((value: any, index: any) => value.id !== Number(this.s.categoryToBeHidden));
            }
            let categories = items.map((value: any, index: any) => ({ localId: value.id + "", item: value }));
            children = children.concat(categories);
        }
        return children;
    }

    /**
     * If the tree is minimized (has not competences displayed), categories without categories as children 
     * will be leaves. Otherwise, versions of competences and categories without children are leaves. 
     */
    protected _hasChildren(item: any) {
        if ((!this.s.minimizeTree && !item.currentCompetenceVersion && item.attachments) ||
            (item.competences && item.competences.length === 0 && item.categoryChildren &&
                item.categoryChildren.length === 0) ||
            (this.s.minimizeTree && item.categoryChildren && item.categoryChildren.length === 0 &&
                item.competences && item.competences.length !== 0)) {
            return false;
        }
        return true;
    }

    /**
     * Default method returns false. This will check if a competence contains string from searchExpression
     */
    protected _matchesSearchExpression(currentItem: any): boolean {
        if (this.s.searchExpression !== "") {
            if (currentItem.currentCompetenceVersion &&
                currentItem.currentCompetenceVersion.name.toLowerCase().includes(this.s.searchExpression.toLowerCase())) {
                return true;
            }
        }
        return false;
    }
}

/*
* This tree receive a root which is an array of competence categories. A category has two fields
* where we can find his children: categoryChildren which is also an array of competence categories and
* competences which is an array of competences. A competence can have versions as children (versions are
* not loaded by default in the current tree). To load versions for a competence we need to give a function
* to 'onExpandCollapseItem' for loading them externally and add into tree (see 'loadVersions' from Competences).
*/
export class CompetenceTree extends Tree<CompetenceProps> {
    getExpandedIdsRecursive(root: any, parentId: string) {
        let expandedIds = {} as { [key: string]: boolean; };
        for (let i = 0; i < root.length; i++) {
            let currentId = !parentId ? root[i].id : parentId + "|/|" + root[i].id;
            expandedIds[currentId] = true;
            if (root[i].categoryChildren) {
                expandedIds = { ...expandedIds, ...this.getExpandedIdsRecursive(root[i].categoryChildren, currentId) };
            }
        }
        return expandedIds;
    }

    componentDidMount(): void {
        this.props.r.setInReduxState({
            minimizeTree: this.props.minimizeTree,
            categoryToBeHidden: this.props.categoryToBeHidden,
            active: this.props.active !== undefined ? this.props.active : this.props.s.active,
            notActive: this.props.notActive !== undefined ? this.props.notActive : this.props.s.notActive,
            selectedComponentId: this.props.selectedComponentId ? this.props.selectedComponentId : ""
        });
        this.setInitialExpandedIds(this.getExpandedIdsRecursive(this.props.root, ""));
        this.props.r.linearize(this.props.root, {});
    }

    componentDidUpdate(prevProps: Readonly<CompetenceProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.active !== this.props.active) {
            this.props.r.setInReduxState({ active: this.props.active });
        }
        if (prevProps.notActive !== this.props.notActive) {
            this.props.r.setInReduxState({ notActive: this.props.notActive });
        }
        if (prevProps.searchExpression !== this.props.searchExpression) {
            this.props.r.modifySearchExpression(this.props.root, this.props.searchExpression, {});
        }
        if (prevProps.selectedComponentId !== this.props.selectedComponentId) {
            this.props.r.setInReduxState({ selectedComponentId: this.props.selectedComponentId });
        }
    }

    protected renderCompetence(competence: any, parentId: string[], componentDetails: ComponentDetails, message: string, renderDetails?: Function) {
        return <>
            <div onClick={() => {
                if (renderDetails) {
                    renderDetails(competence.id, parentId);
                };
                if (this.props.onSelectCompetenceTemp) {
                    this.props.onSelectCompetenceTemp(competence);
                };
            }} key={componentDetails.name}>
                <Icon name="file" />
                {componentDetails.name} <span className="CompetenceTree_version">v{componentDetails.version}</span>
            </div>
            {!this.props.hideActions ? <Button primary icon="bars" size="mini" className="CompetenceTree_button"
                onClick={this.props.activateDeleteModal ? ((buttonPosition) => {
                    buttonPosition.stopPropagation();
                    this.props.openContextMenuModal!([buttonPosition.clientX, buttonPosition.clientY], true, competence, parentId, message);
                }) : undefined} /> : undefined}
            {this.props.renderDeleteOnLeaves && <Button color="red" icon="delete" size="mini" className="CompetenceTree_button" onClick={() => {
                if (this.props.onDeleteCompetence) {
                    this.props.onDeleteCompetence(competence);
                }
            }} />}

        </>;
    }

    /*
    * This method was overrided to find element into tree based on his structure described above and
    * render items based on their type.
    */
    protected renderItem = (params: RenderItemParams) => {
        const objectId = params.linearizedItem.itemId.split(Utils.defaultIdSeparator);
        let object = this.props.root;
        let firstIteration = true;
        let isCompetence = false;
        let isVersion = false;
        for (let field of objectId) {
            if (firstIteration) {
                firstIteration = false;
                for (let child of object) {
                    if (child.id === Number(field)) {
                        object = child;
                        break;
                    }
                }
            } else {
                let found = false;
                if (object.competences) {
                    for (let competence of object.competences) {
                        if (competence.id === Number(field)) {
                            object = competence;
                            isCompetence = true;
                            found = true;
                            break;
                        }
                    }
                    if (found) {
                        continue;
                    }
                }
                if (object.categoryChildren) {
                    for (let category of object.categoryChildren) {
                        if (category.id === Number(field)) {
                            object = category;
                            found = true;
                            break;
                        }
                    }
                    if (found) {
                        continue;
                    }
                }
                if (object.versions === undefined) {
                    return;
                } else {
                    for (let version of object.versions) {
                        if (version.id === Number(field)) {
                            object = version;
                            found = true;
                            isCompetence = false;
                            isVersion = true;
                            break;
                        }
                    }
                }
            }
        }
        let componentDetails: ComponentDetails = isCompetence
            ? {
                name: object.currentCompetenceVersion.name,
                version: object.currentCompetenceVersion.majorVersion + "." + object.currentCompetenceVersion.minorVersion
            }
            : isVersion
                ? {
                    name: object.name,
                    version: object.majorVersion + "." + object.minorVersion
                }
                : {
                    name: object.name,
                    version: undefined
                };
        if (isCompetence) {
            if ((this.props.s.active && object.currentCompetenceVersion.active) ||
                (this.props.s.notActive && !object.currentCompetenceVersion.active) ||
                (this.props.s.active === false && this.props.s.notActive === false)) {
                return this.renderCompetence(object, objectId, componentDetails,
                    _msg("Competences.delete.message.competence"), this.props.onSelectCompetence);
            }
            return undefined;
        } else if (isVersion) {
            if ((this.props.s.active && object.active && object.competence.currentCompetenceVersion.active) ||
                (this.props.s.notActive && !object.active && !object.competence.currentCompetenceVersion.active) ||
                (this.props.s.active === false && this.props.s.notActive === false) ||
                (this.props.s.active && this.props.s.notActive)) {
                return this.renderCompetence(object, objectId, componentDetails,
                    _msg("Competences.delete.message.competenceVersion"), this.props.onSelectVersion);
            }
            return undefined;
        }
        return <>
            <div onClick={() => this.props.onSelectCategory ? this.props.onSelectCategory(componentDetails.name, objectId, object) : undefined}>
                <Icon name="folder open" />
                {componentDetails.name}
            </div>
            {!this.props.hideActions ?
                (!this.props.s.minimizeTree ?
                    <Button size="mini" primary icon="bars" className="CompetenceTree_button"
                        onClick={this.props.openContextMenuModal !== undefined ? ((buttonPosition) => {
                            buttonPosition.stopPropagation();
                            this.props.openContextMenuModal!([buttonPosition.clientX, buttonPosition.clientY], false,
                                object, objectId, _msg("Competences.delete.message.competenceCategory"));
                        }) : undefined
                        }
                    />
                    : null)
                : null}
        </>;
    };

    /**
     * If one of active/notActive filter is enabled, children[1] wouldn't contain anything for
     * competences that doesn't meet requirements (normally it would contain competence name, version and
     * options button)
     */
    protected renderItemWrapperInternal(props: any, ...children: any) {
        if (!children[1]) {
            return <></>;
        }
        return React.createElement(Segment, props, ...children);
    }

    /*
    * It was necessary to extract from rendered item his onClick function to extend it to the
    * entire row of this item
    */
    protected renderItemWrapper(linearizedItem: LinearizedItem, itemWrapperProps: any) {
        const props = this.props;
        const icon = this.getChildrenIcon(linearizedItem);

        let item = this.renderItem({ props, linearizedItem });

        return this.renderItemWrapperInternal({
            ...itemWrapperProps,
            className: this.props.s.selectedComponentId === linearizedItem.itemId ? "selectedItem" : "",
            onClick: () => {
                item?.props.children[0].props["onClick"]();
                this.props.r.setInReduxState({ selectedComponentId: linearizedItem.itemId });
            }
        },
            icon && <Icon data-testid="Tree_expandCollapseIcon" link size="large" name={icon} id="expandCollapseIcon"
                onClick={async (event: any) => {
                    event.stopPropagation();
                    if (linearizedItem.expanded === Expanded.COLLAPSED) {
                        const params = { itemId: linearizedItem.itemId, prevent: false };
                        props.onExpandCollapseItem?.call(null, params);
                        if (params.prevent) {
                            return;
                        }
                    }
                    props.r.expandCollapse(this.getRoot(), linearizedItem.itemId, linearizedItem.expanded === Expanded.COLLAPSED, {});
                }}
            />, item);
    }
}

export const CompetenceTreeRRC = ReduxReusableComponents.connectRRC(CompetenceTreeState, CompetenceTreeReducers, CompetenceTree);