'use strict';

/**
 *
 * This editor can be used to display a realization or an exemption.
 * 
 *	data:  { 
 *		objective,  
 *		employee, 
 * 		onObjectiveAdded(result)
 * }
 */
/*@ngInject*/
function ObjectiveEditorModal(FormModal, Curriculum) {
	
	return {

		open: function(data) {
			var objective = angular.copy(data.objective);					
			
			FormModal.open({
				scope: {
					catalogs: {
						visible: ['TRACK', 'MODULE', 'COMPONENT'],
						editable: false,
						autofocusFilter: true, // TODO not working	
					},
					allowOverflowContainer: true,
					employee: data.employee,
					model: objective,
					title: 'curriculum.objective.editorTitle',
					template: '<objective-editor objective="model" catalogs="catalogs" initial-employee="employee"></objective-editor>',
					modalBodyClass: 'overflowVisible'
				},
				actions: ['ok', 'cancel', 'close']
			}).result.then(function(result) {
				if (result.module && result.module.currentModuleVersion) {
					delete result.module.currentModuleVersion ;
				}

				return Curriculum.saveOrUpdateObjective({}, {
					data: result
				});
			}).then(function(result) {
				if (data.onObjectiveAdded) {
					data.onObjectiveAdded(result);
				}
			});
		}
	};
}

module.exports = ObjectiveEditorModal;