import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";

export class AssociationCodeEditor extends AssociationFieldEditor<FieldEditorProps>{

    protected getLabel(entity: any): string {
        if (entity !== undefined && entity.code !== undefined) {
            return entity?.code;
        }
        return super.getLabel(entity);
    }
}

export class AssociationDescriptionEditor extends AssociationFieldEditor<FieldEditorProps>{

    protected getLabel(entity: any): string {
        if (entity !== undefined && entity.description !== undefined) {
            return entity?.description;
        }
        return super.getLabel(entity);
    }
}

export class AssociationDescriptionAndIdEditor extends AssociationFieldEditor<FieldEditorProps>{

    protected getLabel(entity: any): string {
        if (entity !== undefined && entity.description !== undefined) {
            return entity?.description + " - " + entity?.id;
        }
        return super.getLabel(entity);
    }
}

export class AssociationNameEditor extends AssociationFieldEditor<FieldEditorProps>{

    protected getLabel(entity: any): string {
        if (entity !== undefined && entity.name !== undefined) {
            return entity?.name;
        }
        return super.getLabel(entity);
    }
}

export class AssociationNameAndCodeEditor extends AssociationFieldEditor<FieldEditorProps>{

    protected getLabel(entity: any): string {
        if (entity !== undefined && entity.name !== undefined) {
            return entity?.name + " - " + entity?.code;
        }
        return super.getLabel(entity);
    }
}

export class AssociationNameAndIdEditor extends AssociationFieldEditor<FieldEditorProps>{

    protected getLabel(entity: any): string {
        if (entity !== undefined && entity.name !== undefined) {
            return entity?.name + " - " + entity?.id;
        }
        return super.getLabel(entity);
    }
}