'use strict';

/*@ngInject*/
function TrainerProfileCtrl($scope, Education, Collection, FormModal) {
	var catalogs = $scope.catalogs;
	var trainer = $scope.catalogs.trainer = this;
	var criteria = trainer.criteria = {};
	var certificates = trainer.certificates = {};
	
	var EMPTY_PROFILE_VERSION = {
			objectType: "ProfileVersion",
			profile: {
				objectType: "Profile",
			},
			competenceProfileCriterionVersions: [],
			components: []
		};
	
	// The profile typeahead ui component binds to catalogs.trainerProfileVersion. 
	// So when this modifies we update our model. When we load the model, we must set this value. 
	
	if (catalogs.elementCurrentVersion.trainerProfileVersion) {
		initializeTrainerProfileVersion(catalogs.elementCurrentVersion.trainerProfileVersion);
	} else {	
		trainer.trainerProfileVersion = angular.copy(EMPTY_PROFILE_VERSION);
	}
	
	$scope.$watch("catalogs.trainer.trainerProfileVersion", function(newValue, oldValue){
		// when UI changes, we update the model
		if (catalogs.elementCurrentVersion && catalogs.elementCurrentVersion.objectType == 'EducationComponentVersion') {
			// catalogs.elementCurrentVersion is same as catalogs.element.currentComponentVersion (see catalogs.selectElt function)
			catalogs.elementCurrentVersion.trainerProfileVersion = catalogs.trainer.trainerProfileVersion && catalogs.trainer.trainerProfileVersion.id ? catalogs.trainer.trainerProfileVersion.id : null;
		}
	});
	
	$scope.$watchGroup(['catalogs.elementCurrentVersion', 'catalogs.elementCurrentVersion.trainerProfileVersion'], function(newValues, oldValues, scope) {
		// load data only if catalogs.elementCurrentVersion and catalogs.elementCurrentVersion.trainerProfileVersion changed their previous values		
		if (newValues[0] == oldValues[0] && angular.equals(newValues[1], oldValues[1])) {
			// for trainerProfileVersion we might have two copies of EMPTY_PROFILE_VERSION
			return;
		}
		
		var element = newValues[0];
		if (element && element.trainerProfileVersion) {
			initializeTrainerProfileVersion(element.trainerProfileVersion);
		} else {		
			trainer.addNewProfile(false);
		}
	});
	
	function initializeTrainerProfileVersion(profileVersionId) {
		Education.getProfileVersion(trainer, {
			params: {
				id: profileVersionId //element.trainerProfileVersion
			}
		}).then(function() {
			trainer.trainerProfileVersion = trainer.data;
			trainer.save = true;
			
			criteria.collection = Collection.create(trainer.data.competenceProfileCriterionVersions);
			criteria.options.data = criteria.collection.data;
			
			certificates.collection = Collection.create(trainer.data.components);
			certificates.options.data = certificates.collection.data;
		});
	}
	
	trainer.addNewProfile = function(shouldSave) {
		trainer.save = shouldSave;
		trainer.trainerProfileVersion = trainer.data = angular.copy(EMPTY_PROFILE_VERSION);
		
		criteria.collection = Collection.create(trainer.data.competenceProfileCriterionVersions);
		criteria.options.data = criteria.collection.data;
		
		certificates.collection = Collection.create(trainer.data.components);
		certificates.options.data = certificates.collection.data;
	};
	
	trainer.updateProfileName = function(name) {
		trainer.trainerProfileVersion.name = name;
	};

	trainer.isDisabled = function() {
		return catalogs.olderVersionSelected;
	}
	
	criteria.options = {
		columnDefs: [
			{
				name: 'name',
				displayName: 'education.catalogs.trainer.criteria.name',
				field: 'competence.currentCompetenceVersion.name'
			},
			{
				name: 'weight',
				displayName: 'education.catalogs.trainer.criteria.weight',
				field: 'weight'
			},
			{
				name: 'required',
				displayName: 'education.catalogs.trainer.criteria.required',
				field: 'required'
			},
			{
				name: 'actions',
				width: '32px',
				headerTemplateUrl: '/catalogs/trainer.criteria.add.html',
				cellTemplateUrl: '/catalogs/trainer.criteria.remove.html'
			}
		],
		onRegisterApi: function(gridApi) {
			gridApi.selection.on.rowSelectionChanged(null, function(row) {
				criteria.edit(row.entity);
			});
		}
	};
	
	criteria.add = function() {
		if (trainer.isDisabled()) {
			return;
		}
		criteria.edit({
			weight: 0,
			required: false
		}, function(child) {
			criteria.collection.add(child);
			trainer.save = true;
		});
	};
	
	criteria.edit = function(child, callback) {
		if (trainer.isDisabled()) {
			return;
		}
		var instance = FormModal.open({
			scope: {
				model: angular.copy(child),
				title: child.competence ? child.competence.currentCompetenceVersion.name : 'education.competences.add',
				template: require('./trainer-profile-criterion-form.html'),
				isModelInvalid: function(model) {
					return !model.competence;
				},
				allowOverflowContainer: true
			},
			actions: ['ok', 'cancel', 'close']
		});
		instance.result.then(function(result) {
			angular.merge(child, result);
			callback && callback(child);
			catalogs.disableSave(false);
		}).catch(function(reason) {
			// the form window has been closed (cancel button)
		});
	};
	
	criteria.remove = function(child) {
		if (trainer.isDisabled()) {
			return;
		}
		criteria.collection.remove(child);
		catalogs.disableSave(false);
	};
	
	certificates.options = {
		columnDefs: [
			{
				name: 'name',
				displayName: 'education.catalogs.trainer.criteria.name',
				field: 'currentComponentVersion.name'
			},
			{
				name: 'actions',
				width: '32px',
				headerTemplateUrl: '/catalogs/trainer.certificates.add.html',
				cellTemplateUrl: '/catalogs/trainer.certificates.remove.html'
			}
		]
	};
	
	certificates.add = function() {
		if (trainer.isDisabled()) {
			return;
		}
		// open popup
		var disableSave = $scope.catalogs.disableSave;
		var catalogs = {
			// pass selected catalog
			catalog: $scope.catalogs.catalog,
			visible: ['COMPONENT'],
			type: 'CERT'
		};
		var modal = FormModal.open({
			windowClass: 'modal-full',
			scope: {
				template: require('./catalogs-groups.html'),
				title: 'add',
				catalogs: catalogs
			},
			actions: ['ok', 'cancel', 'close']
		});
		modal.rendered.then(function() {
			// remove tracks and modules
			catalogs.groups.splice(0, 2);
		});
		modal.result.then(function() {
			var component = catalogs.element;
			delete component['@id'];
			delete component.currentComponentVersion.component;
			certificates.collection.add(component);
			disableSave(false);
			trainer.save = true;
		});
	};
	
	certificates.remove = function(child) {
		if (trainer.isDisabled()) {
			return;
		}
		certificates.collection.remove(child);
		catalogs.disableSave(false);
	};
}

module.exports = TrainerProfileCtrl;