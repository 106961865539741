import { createSliceFoundation, EntityDescriptor, FieldDescriptor, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import _ from "lodash";
import { CustomEditor } from "pages/groupsManagement/customFieldRenderersEditors";
import React from "react";
import { createRef } from "react";
import { Label, Menu, Tab } from "semantic-ui-react";
import { Component, Module, ModuleVersion, Track } from "../Catalog";
import { CatalogTree, sliceCatalogTree } from "../CatalogTree";
import { childVersionEntityDescriptor } from "../customFieldRendererEditors";
import { CatalogItemEditor, SliceCatalogItemEditor } from "./CatalogItemEditor";

const CHILD_VERSION = "ChildVersion";
const CHILD = "child";

const sliceCatalogItemEditor = createSliceFoundation(SliceCatalogItemEditor, true);

export const sliceModuleItemEditor = createSliceFoundation(class SliceModuleItemEditor extends SliceCatalogItemEditor {
    initialState = {
        ...sliceCatalogItemEditor.initialState,
        module: {} as Module,
        // we need a default module, which is the one that the editor was initialized with to be able to check if the editor is "dirty"
        defaultModule: {} as Module,
        selectedComponentToAdd: undefined as unknown as Component,
    };

    nestedSlices = {
        ...sliceCatalogItemEditor.nestedSlices,
        table: sliceEntityTableLight,
        catalogTree: sliceCatalogTree
    };

    reducers = {
        ...sliceCatalogItemEditor.reducers,
        ...getBaseReducers<SliceModuleItemEditor>(this),

        update(state: StateFrom<SliceModuleItemEditor>, p: { moduleVersion: ModuleVersion, dirty: boolean; }) {
            state.module.currentModuleVersion = p.moduleVersion;
            state.dirty = p.dirty;
        }
    };

});

type Props = PropsFrom<typeof sliceModuleItemEditor>;

export class ModuleItemEditor extends CatalogItemEditor<Props> {
    protected refEntityTableLight = React.createRef<EntityTableLight>();

    setModule(module: Module) {
        this.props.dispatchers.setInReduxState({
            module,
            defaultModule: module,
            dirty: false
        });
        this.setTable(module.currentModuleVersion.componentVersions);
        this.props.dispatchers.setCatalogItemVersion(module.currentModuleVersion);
    }

    setTable(children: any) {
        let childrenForTable = [];
        for (let i = 0; i < children.length; i++) {
            childrenForTable.push({
                version: ` v${children[i].majorVersion}.${children[i].minorVersion}`,
                name: children[i].name,
                type: _msg("Catalog.componentType.label"),
                componentType: children[i].component.type.description
            });
        }
        this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(childrenForTable.sort((a: any, b: any) => a.name > b.name ? 1 : -1));
    }

    getEditorForChildVersion() {
        const that = this;
        return new EntityDescriptor({ name: CHILD_VERSION })
            .addFieldDescriptor({ name: CHILD, type: FieldType.string }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    let newProps = { ...props, getCustomEditorContent: that.getCatalogTree };
                    return super.renderFieldEditorInternal(CustomEditor, newProps);
                }
            });
    }

    onTrackSelect = (track: Track) => {
    };

    onModuleSelect = (module: Module) => {
    };

    onComponentSelect = (component: Component) => {
        this.props.dispatchers.setInReduxState({ selectedComponentToAdd: component });
    };

    getCatalogTree = () => {
        const refCatalogTree = createRef<CatalogTree>();

        return (
            <div className="ConstraintTab_formTree">
                <CatalogTree
                    {...this.props.catalogTree}
                    dispatchers={this.props.dispatchers.catalogTree}
                    ref={refCatalogTree}
                    onTrackSelect={this.onTrackSelect}
                    onModuleSelect={this.onModuleSelect}
                    onComponentSelect={this.onComponentSelect}
                    renderButtons={false}
                    renderTracks={false}
                    renderExpands={false}
                    renderModules={false}
                    isLoadComponentsUsingGraphQL={true}
                    defaultSegmentOpen="components"
                    refreshTreeOnOpen={true}
                />
            </div>
        );
    };
    update(moduleVersion: ModuleVersion) {
        const dirty = this.isDirty(moduleVersion);
        this.props.dispatchers.update({ moduleVersion, dirty });
        super.update(moduleVersion);
    }

    isDirty(moduleVersion: ModuleVersion) {
        return !_.isEqual(moduleVersion, this.props.defaultModule.currentModuleVersion) || this.props.createNewMajorVersion;
    }

    getComponentsTab() {
        return {
            menuItem: (
                <Menu.Item key='components'>
                    {_msg("Catalog.compositionTab.label")}<Label>{this.props.module?.currentModuleVersion?.componentVersions.length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane className="flex flex-container flex-grow">
                <div className={`flex flex-grow flex-container Catalog ${this.props.isOldVersionSelected ? 'Catalog_disabled' : ''}`}>
                    <EntityTableLight {...this.props.table} dispatchers={this.props.dispatchers.table} entityDescriptor={childVersionEntityDescriptor} ref={this.refEntityTableLight}
                        actions={{ showAddButton: !this.props.isOldVersionSelected, showEditButton: false, doNotAddEntityToTable: true }}
                        onSave={() => {
                            if (this.props.module.currentModuleVersion.componentVersions.filter(componentVersion => componentVersion.name === this.props.selectedComponentToAdd?.currentComponentVersion.name).length !== 0) {
                                return;
                            }
                            let componentVersion = this.props.selectedComponentToAdd?.currentComponentVersion;
                            let moduleNew = _.cloneDeep(this.props.module);
                            moduleNew.currentModuleVersion.componentVersions.push(componentVersion);
                            this.props.dispatchers.setInReduxState({ module: moduleNew });
                            this.setTable(moduleNew.currentModuleVersion.componentVersions);
                            this.update(moduleNew.currentModuleVersion);
                        }}
                        onDelete={(remainingEntities, removedEntityIndex) => {
                            let names: any[] = [];
                            remainingEntities.forEach((entity: any) => {
                                names.push(entity.name);
                            });
                            let moduleNew = _.cloneDeep(this.props.module);
                            moduleNew.currentModuleVersion.componentVersions = moduleNew.currentModuleVersion.componentVersions.filter(
                                child => names.some(v => v.includes(child.name))
                            );
                            this.props.dispatchers.setInReduxState({ module: moduleNew });
                            this.setTable(moduleNew.currentModuleVersion.componentVersions);
                            this.update(moduleNew.currentModuleVersion);
                        }}
                        formCustomizer={{ headerIcon: "plus", headerContent: _msg("Catalog.add.label"), customEntityDescriptorForEditor: this.getEditorForChildVersion() }}
                        getConditionForShowingDelete={() => !this.props.isOldVersionSelected}
                    />
                </div>
            </Tab.Pane>,
        };
    }

    getPanes = () => {
        return [
            this.getGeneralTab(),
            this.getComponentsTab(),
            this.getAttachmentsTab()
        ];
    };

    componentDidMount() {
        if (this.props.module) {
            this.setModule(this.props.module);
        }
    }

    componentDidUpdate() {
        if (this.refEntityTableLight.current && this.props.module.currentModuleVersion.componentVersions.length > 0 && this.refEntityTableLight.current.getEntityTableSimpleCustomizedRef().current?.getEntities().length === 0) {
            this.setTable(this.props.module.currentModuleVersion.componentVersions);
        }
    }
}