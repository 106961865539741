'use strict';

/*@ngInject*/
function CompetencesTreeCtrl($scope, Education, Confirm, ModalSpinner, FormModal, $filter) {
	var competences = $scope.competences = $scope.competences || {};
	
	ModalSpinner.show();
	
	competences.refresh = function() {
		ModalSpinner.show();
		Education.getCompetences(competences, {
			params: {
				id: competences.dictionaries.selection.id
			}
		}).finally(function() {
			ModalSpinner.hide();
		});
	};

	competences.dictionaries = {
		template: require('./dictionary-form.html'),
		create: function() {
			return {
				name: '',
				description: '',
				environment: 'BRABO'
			};
		},
		spinner: true,
		get: Education.getDictionaries,
		save: Education.saveDictionary,
		onSelect: function() {
			competences.refresh();
		},
		style: {
			"dropdown-menu-right": true
		}
	};
	
	/**
	 * We keep a reference to original competences.select in selectFn.
	 * Bellow we replace competences.select definition (we 'override' it)
	 */
	var selectFn = competences.select;
	
	/**
	 * Selection handler in competences list.
	 */
	competences.select = function(scope) {
		competences.selection = scope;

		competences.selectElt(scope.competence, scope.version);

		selectFn && selectFn(scope);
	};
	
	competences.selectElt = function(competence, version) {
		if (competences.multipleSelection) {
			// used for selecting multiple competences from another page (e.g. Catalogs - Training Component)
			var index = competences.selectedCompetences.indexOf(competence);
			if (index >= 0) {
				competences.selectedCompetences.splice(index, 1);
			} else {
				competences.selectedCompetences[competences.selectedCompetences.length] = competence;
			}
		}

		// use a copy of the element to discard form edits when changing selection
		competences.competence = angular.copy(competence);
		//competences.competence.currentCompetenceVersion = competences.version = angular.copy(version ? version : competence.currentCompetenceVersion);

		if (version) {
			if (version.id == competences.competence.currentCompetenceVersion.id) {
				competences.version = competences.competence.currentCompetenceVersion;
			} else {
				competences.version = version;
			}
			competences.versionIsSelected = true;
		} else {
			competences.version = competences.competence.currentCompetenceVersion;
			competences.versionIsSelected = false;
		}

		competences.editorIsDisabled = competences.version.id != competences.competence.currentCompetenceVersion.id;
	};

	competences.filter = competences.filter || {};
	competences.filter.properties = ['name'];
	competences.filter.parent = 'category';
	competences.filter.elementFn = function(scope) {
		return scope.competence.currentCompetenceVersion;
	};
	competences.filter.placeholder = $filter('translate')('education.competences.search');

	var active = 'competences.competence.id === competence.id && !competences.versionIsSelected';
	if (competences.multipleSelection) {
		// used for selecting multiple competences from another page (e.g. Catalogs - Training Component)
		competences.selectedCompetences = [];
		active = 'competences.selectedCompetences.indexOf(competence) >= 0';
	}
	competences.tree = {
		repeat: 'category in competences.data track by category.id',
		label: '{{category.name}}',
		icon: "'fa fa-folder-open fa-fw'",
		actions: !competences.editable ? 0 : 'competences.actions.categories',
		expandable: true,
		children: [
		{
			repeat: 'competence in category.competences track by competence.id',
			label: '{{competence.currentCompetenceVersion.name}}',
			detail: 'v{{competence.currentCompetenceVersion.version}}',
			icon: "'fa fa-file fa-fw text-muted'",
			filter: 'competences.filter',
			select: 'competences.select(this)',
			//active: 'competences.competence.id === competence.id',
			active: active,
			action: 'competences.actions.competences',
			expandable: !competences.editable ? 0 : 'competences.showHideVersions(this)',
			children: !competences.editable ? [] : [
			{
				repeat: 'version in versions.data | orderBy:"version.majorVersion":true track by version.id',
				detail: 'v{{version.version}}',
				label: '{{version.name}}',
				icon: "'fa fa-fw'",
				select: 'competences.select(this)',
				active: 'version.id === competences.version.id && competences.versionIsSelected',
				action: 'competences.actions.versions'
			}]
		},
		{
			repeat: 'category in category.categoryChildren track by category.id',
			label: '{{category.name}}',
			icon: "'fa fa-folder-open fa-fw'",
			recurse: 'competence-categories',
			expandable: true,
			actions: !competences.editable ? 0 : 'competences.actions.categories'
		}]
	};
}

module.exports = CompetencesTreeCtrl;