'use strict';

/*@ngInject*/
function ResourceTypeaheadCtrl($scope, Resources, ResourcePickerConstants, DATE_TIME_FORMAT) {
	
	$scope.getResources = function(searchText, date, limitResultsToCategoryOfEmployee) {
		date = date || new Date();		
		limitResultsToCategoryOfEmployee = limitResultsToCategoryOfEmployee || -1;
		
		var config = {
			data: {
				searchText: searchText,
				fromDate: date,
				groupMembershipFromDate: date,
				groupCode: ResourcePickerConstants.CONTRACT,
				limitResultsToCategoryOfEmployee: limitResultsToCategoryOfEmployee
			}
		}
		return Resources.getResourceSnapshots({}, config).then(function(result) {
			return result.data;
		});
	}
}

module.exports = ResourceTypeaheadCtrl;
