import { apolloClient, Utils } from '@crispico/foundation-react';
import { DatePickerReactCalendar } from '@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar';
import { Reducers, ReduxReusableComponents, RRCProps, State } from '@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents';
import { Drawer } from 'antd';
import { getResourceSnapshots, getResourceSnapshotsVariables, getResourceSnapshots_employeeService_employeeSnapshots } from 'apollo-gen/getResourceSnapshots';
import { RegistrationInput } from 'apollo-gen/globalTypes';
import { EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS, PLANNING_SERVICE_FACADE_BEAN_SWITCH_REGISTRATION } from 'graphql/queries';
import _ from 'lodash';
import moment from 'moment';
import { Registration } from 'pages/registrationEditor/RegistrationEditor';
import { ProteusUtils } from 'ProteusUtils';
import React from 'react';
import { Button, Dropdown, Form, Icon, Input, Segment } from 'semantic-ui-react';

export class SwitchRegistrationEditorState extends State {
    open: boolean = false;
    employee: any = undefined;
    registration: Registration | undefined;
    employeesOptions: any[] = [];
    selectedEmployee: any | undefined = {};
    switchDate: Date | undefined = undefined;
}

export class SwitchRegistrationEditorReducers<S extends SwitchRegistrationEditorState = SwitchRegistrationEditorState> extends Reducers<S> {

    closeExchangeEditor() {
        this.s.open = false;
        this.s.employee = undefined;
        this.s.registration = undefined;
        this.s.selectedEmployee = undefined;
        this.s.switchDate = undefined;
        this.s.employeesOptions = [];
    }

    selectEmployee(selectedEmployee: any) {
        this.s.selectedEmployee = selectedEmployee;
    }

    selectSwitchDate(switchDate: Date | undefined) {
        this.s.switchDate = switchDate;
    }

    setEmployeeOptions(employeeOptions: any) {
        this.s.employeesOptions = employeeOptions;
    }

    open(registration: Registration) {
        this.s.open = true;
        this.s.registration = registration;
    }
}

type SwitchRegistrationEditorProps = RRCProps<SwitchRegistrationEditorState, SwitchRegistrationEditorReducers> & {
    refresh: () => void
};

export class SwitchRegistrationEditorRaw extends React.Component<SwitchRegistrationEditorProps> {

    async loadEmployees(text: string) {
        if (text.length < 2) { return; }
        const employees = (await apolloClient.query<getResourceSnapshots, getResourceSnapshotsVariables>({
            query: EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS,
            variables: {
                searchText: text,
                fromDate: new Date(),
                untilDate: new Date()
            },
            context: { showSpinner: false }
        })).data.employeeService_employeeSnapshots

        this.props.r.setEmployeeOptions(employees.filter((employee: getResourceSnapshots_employeeService_employeeSnapshots, index: number, array: getResourceSnapshots_employeeService_employeeSnapshots[]) =>
            array.findIndex((employee2: getResourceSnapshots_employeeService_employeeSnapshots) => (employee2.id === employee.id)) === index).map(item => ({
                key: item.id as number,
                text: item.name + " " + item.firstName + (item.contractHistoryItem ? " (" + item.contractHistoryItem.employeeNumber + ")" : ""),
                value: item.id as number,
                filteredEmployee: ((item?.firstName) as string).concat(" ", item?.name as string),
                name: item.name,
                firstName: item.firstName,
                employeeNumber: item.contractHistoryItem?.employeeNumber
            }))
        )
    }

    async switchRegistration() {
        const registration: RegistrationInput = {
            fromDate: moment(this.props.s.registration?.fromDate).valueOf(),
            toDate: moment(this.props.s.registration?.toDate).valueOf(),
            resource: (this.props.s.registration as any)?.resource,
            type: this.props.s.registration?.type,
            status: this.props.s.registration?.status,
            id: this.props.s.registration?.id
        }
        await apolloClient.mutate({
            mutation: PLANNING_SERVICE_FACADE_BEAN_SWITCH_REGISTRATION,
            variables: {
                registration: registration,
                supporter: this.props.s.selectedEmployee.value,
                switchDate: moment(this.props.s.switchDate).valueOf()
            }
        });
        this.props.r.closeExchangeEditor();
        this.props.refresh?.();
    }

    renderButtonBar() {
        return <Segment className="buttonBar">
            <Button primary onClick={async () => this.switchRegistration()}> {_msg("general.ok")}</Button>
            <Button onClick={() => this.props.r.closeExchangeEditor()} >{_msg("general.cancel")}</Button>
        </Segment>
    }

    render() {
        return (
            <Drawer className='AgendaDrawer' destroyOnClose placement="right" visible={this.props.s.open} closeIcon={<Button icon="close" className="less-padding" circular />} onClose={() => this.props.r.closeExchangeEditor()}>
                {ProteusUtils.renderDrawerHeader(<><Icon name='exchange' />{_msg("PlanningGantt.contextMenu.switch.validation.label")}</>, 'RegistrationEditor_drawerTitle')}
                <div className='SwitchRegistrationEditorContent'>
                    {this.renderButtonBar()}
                    <Form>
                        <Form.Field>
                            {_msg('general.date')}
                            <Input disabled={true}>{moment(this.props.s.registration?.fromDate).format(Utils.dateFormat)}</Input>
                        </Form.Field>
                        <Form.Field>
                            {_msg("RegistrationEditor.registration.supporter.label")}
                            <Dropdown fluid selection search clearable selectOnNavigation={false} noResultsMessage={_msg("general.noResultsFound")}
                                options={this.props.s.employeesOptions}
                                placeholder={_msg("searchEmployee.label")}
                                onChange={async (e: any, data: any) => {
                                    const selectedEmployee = data.options.filter((item: { key: number, text: string }) => item.key === data.value)[0];
                                    this.props.r.selectEmployee(selectedEmployee);
                                }}
                                onSearchChange={(e: any) => {
                                    this.loadEmployees(e.target.value as unknown as string);
                                }}
                            />
                        </Form.Field>
                        <Form.Field>
                            {_msg("RegistrationEditor.registration.detail.secondDate.label")}
                            <DatePickerReactCalendar format={Utils.dateFormat} allowClear onChange={date => this.props.r.selectSwitchDate(date?.toDate())}></DatePickerReactCalendar>
                        </Form.Field>
                    </Form>
                </div>
            </Drawer>
        );
    }
}

export const SwitchRegistrationEditor = ReduxReusableComponents.connectRRC(SwitchRegistrationEditorState, SwitchRegistrationEditorReducers, SwitchRegistrationEditorRaw);