import { createSliceFoundation, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react";
import { Component, ComponentVersion } from "../Catalog";
import { CatalogItemEditor, SliceCatalogItemEditor } from "./CatalogItemEditor";
import _ from "lodash";

const sliceCatalogItemEditor = createSliceFoundation(SliceCatalogItemEditor, true);

export class SliceComponentItemEditor extends SliceCatalogItemEditor {
    initialState = {
        ...sliceCatalogItemEditor.initialState,
        component: {} as Component,
        // we need a default component, which is the one that the editor was initialized with to be able to check if the editor is "dirty"
        defaultComponent: {} as Component
    };

    nestedSlices = {
        ...sliceCatalogItemEditor.nestedSlices,
    };

    reducers = {
        ...sliceCatalogItemEditor.reducers,
        ...getBaseReducers<SliceComponentItemEditor>(this),

        setComponent(state: StateFrom<SliceComponentItemEditor>, component: Component) {
            state.component = component;
            state.defaultComponent = component;
            state.dirty = false;
            this.setCatalogItemVersion(state, component.currentComponentVersion);
        },

        onSaveComponent(state: StateFrom<SliceComponentItemEditor>) {
            state.createNewMajorVersion = false;
            state.defaultComponent = state.component;
            state.dirty = false;
        },

        update(state: StateFrom<SliceComponentItemEditor>, p: { componentVersion: ComponentVersion, dirty: boolean; }) {
            state.component.currentComponentVersion = p.componentVersion;
            state.dirty = p.dirty;
        },
    };
}

export const sliceComponentItemEditor = createSliceFoundation(SliceComponentItemEditor);

type Props = PropsFrom<typeof sliceComponentItemEditor>;
export class ComponentItemEditor extends CatalogItemEditor<Props> {
    isDirty(componentVersion: ComponentVersion) {
        return !_.isEqual(componentVersion, this.props.defaultComponent.currentComponentVersion) || this.props.createNewMajorVersion;
    }

    update(componentVersion?: ComponentVersion): void {
        let compVersion = componentVersion ? componentVersion : this.props.component.currentComponentVersion;
        const dirty = this.isDirty(compVersion);
        this.props.dispatchers.update({ componentVersion: compVersion, dirty });
        super.update(compVersion);
    }

    getPanesComponent = () => [
        this.getGeneralTab(),
        this.getAttachmentsTab()
    ];
}