'use strict';

/*@ngInject*/
function EvalationCriteriaCtrl($scope, Collection, FormModal, Principal) {
	var criteria = $scope.evaluations.criteria = this;
	
	criteria.scores = Collection.create($scope.evaluations.evaluation.scores);
	criteria.competences = Collection.create($scope.evaluations.evaluation.evaluationDefinitionVersion.evaluationCriterionVersions);
	
	Principal.hasPermissions('EDUCATION_VIEW_EVALUATION_COMPETENCE_DETAILS').then(function(result) {
		criteria.showPercentage = result;
		criteria.options.columnDefs[1].visible = result;
	});

	criteria.options = {
		data: criteria.competences.data,
		columnDefs: [
			{
				name: 'name',
				displayName: 'education.evaluationFillIn.criteria.name',
				field: 'competenceVersion.name'
			},
			{
				name: 'weight',
				displayName: 'education.evaluationFillIn.criteria.weight',
				field: 'weight',
				visible: criteria.showWeight
			},
			{
				name: 'required',
				displayName: 'education.catalogs.evaluation.required',
				field: 'required',
				cellTemplate: '<i class="fa fa-check" ng-if="row.entity.required && row.entity.required==true"></i>'
			},
			{
				name: 'score',
				displayName: 'education.evaluationFillIn.criteria.score',
				cellTemplateUrl: '/evaluation/fill-in/score.html'
			}
		],
		onRegisterApi: function(gridApi) {
			gridApi.selection.on.rowSelectionChanged(null, function(row) {
				var competence = row.entity;
				var score = criteria.getScore(competence);
				// custom score; no scale
				// if the existing score has a scale, then it is not custom
				var custom = {
					evaluationCriteriumVersion: competence,
					scale: 0,
					percentage: score ? (score.scaleScore ? 0 : score.percentage) : 0
				}
				var none = {
					evaluationCriteriumVersion: competence
				}
				var selection = criteria.getDefinition(competence, score);
				if (!selection) {
					selection = criteria.isFilled(competence) ? custom : none;
				}
				FormModal.open({
					scope: {
						title: competence.competenceVersion.name,
						template: require('./competence.html'),
						competence: competence.competenceVersion,
						score: criteria.getScore(competence),
						model: {
							selection: selection,
							custom: custom,
							none: none,
							showPercentage: criteria.showPercentage
						}
					},
					actions: ['ok', 'cancel', 'close']
				}).result.then(function(result) {
					if (result.selection.scale == -1) {
						// remove score if null
						var index = criteria.getIndexOfScore(competence);
						criteria.scores.data.splice(index, 1);
						return;
					}
						
					// set or update the score
					if (!score) {
						// create a new score
						score = {
							evaluationCriteriumVersion: competence
						}
						criteria.scores.add(score);
					}
					score.scaleScore = result.selection.scale;
					score.percentage = result.selection.percentage;
				});
			});
		}
	}
	
	criteria.getIndexOfScore = function(competence) {
		return $scope.evaluations.getIndexOfScore(criteria.scores, competence);
	}
	
	criteria.getScore = function(competence) {
		return $scope.evaluations.getScore(criteria.scores, competence);
	}
	
	criteria.isFilled = function(competence) {
		return $scope.evaluations.isFilled(criteria.scores, competence);
	}
	
	criteria.getDefinition = function(competence, score) {
		if (score) { 
			var definitions = Collection.create(competence.competenceVersion.levelDefinitions);
			var index = definitions.indexOf(score.scaleScore, function(scale, definition) {
				return definition.scale === scale;
			});
			if (index >= 0) {
				return definitions.data[index];
			}
		}
	}
}

module.exports = EvalationCriteriaCtrl;