'use strict';

/**
 * Initialize routes with module lazy-loading. Each config must return an Object with the following properties:
 * {
 * 	name: {String} the name of the module,
 * 	path: {String} the path of the module,
 * 	routes: {Object.<String, Object>} routes that should be registered
 * 	authRoutes: {Object.<String, Object>} routes that require auth
 * 	navRoutes: {Array.<Object>} routes that will be added to the navigation bar,
 * 	onAuth: {Function([dependencies])} a callback that will be called on authentication
 * }
 *
 * The dependencies of the route will be lazy-loaded when the route is accessed.
 */

var configs = [
	require('./account/account'),
	require('./messaging/messaging'),
	require('./tasks/tasks'),
	require('./planning/planning'),
	require('./credit/credit'),
	require('./education/education'),
	require('./menu')
];

angular.module('proteus')

/**
 * Configure the lazy-loader with the list of modules.
 * For each module, register its routes.
 */
.config(function($ocLazyLoadProvider, $routeProvider, $qProvider) {
	var modules = [],
		$q = $qProvider.$get(),
        rr = new RouteResolver($q);

	angular.forEach(configs, function(config) {

		// collect lazy modules
		
		modules.push({
			name: config.name,
			files: [config.path]
		});

		// register routes

		angular.forEach(config.routes, function(route, name) {
			rr.configRoute(route, config);
			$routeProvider.when(name, route);
		});
		
	});

	if (window.isProteusReact) {
		// if in react, the modules were already imported; so no need for lazy import
		return;
	}
	
	// register lazy modules
	$ocLazyLoadProvider.config({
		modules: modules,
		debug: false
	});
})

/**
 * For each module, register its auth routes.
 */
.run(function($route, $q, $rootScope, $ocLazyLoad, $location, Principal) {
	var rr = new RouteResolver($q);

	angular.forEach(configs, function(config) {

		// register auth routes

		angular.forEach(config.authRoutes, function(route, name) {
            rr.configRoute(route, config);
			$route.whenWithAuth(name, route);
		});

		// register nav routes
		
		function recurse(route) {
			if (route.route) {
				$rootScope.routePermissions[route.route] = route.permissions;
			}

			// iterate sub-routes
			angular.forEach(route.subRoutes, function(subRoute) {
				subRoute.parentRoute = route;
				recurse(subRoute);
			});
		}

		if (config.navRoutes) {
			$rootScope.navRoutes = $rootScope.navRoutes || [];
			$rootScope.routePermissions = $rootScope.routePermissions || {};
			$rootScope.navRoutes.push.apply($rootScope.navRoutes, config.navRoutes);

			angular.forEach(config.navRoutes, recurse);
		}

		if (config.routePermissions) {
			$rootScope.routePermissions = $rootScope.routePermissions || {};
			angular.extend($rootScope.routePermissions, config.routePermissions);
		}

		// register onAuth callback
		
		if (config.onAuth) {
			Principal.checkPermissions('', function() {
				var injector = angular.injector(['ng', 'proteus.constants']);
				injector.invoke(config.onAuth, config, {
					routeResolver: rr,
					$rootScope: $rootScope, // pass the $rootScope here, otherwise the injector will create a new scope
					$route: $route,
					$location: $location,
					$ocLazyLoad: $ocLazyLoad
				});
			});
		}
	});
});

/**
 * Configure the route so the module will be lazy-loaded on access.
 * Additionally, make sure that angular will load the template only *after* the module is loaded.
 * We do this by removing 'templateUrl', and instead settting 'template' to return a promise 
 * that will be resolved on module load.
 */
function RouteResolver($q) {
	this.configRoute = function(route, config) {
        
		// lazy-load the module on route access
		route.resolve = route.resolve || {};

		route.resolve.deps = /*@ngInject*/ function($ocLazyLoad, $templateCache) {
			const callback = () => route.templateResolve && route.templateResolve($templateCache);
			
			if (window.isProteusReact) {
				// if react, we can "resolve" right away. Almost. Using a "setTimeout()"
				// no need to wait for anything else
				setTimeout(callback);
				return;
			} 

			var module = $ocLazyLoad.load(config.name);
			// the template will be resolved only after the module is loaded
			module.then(callback);
			return module;
		};

		// replace url with a function that returns the template from the cache
		// the template *must* be added to the cache by the lazy module
		if (angular.isString(route.templateUrl)) {
			var templateUrl = route.templateUrl;

			// this function will return a promise that will be resolved when the module is loaded
			route.template = function() {
				var deferred = $q.defer();

				// replace the previous templateResolve
				// otherwise the same promise will be used, and a promise can only be resolved *once*
				// so the view won't be loaded the second time the route is accessed
				route.templateResolve = function(templateCache) {
					deferred.resolve(templateCache.get(templateUrl));
				};

				return deferred.promise;
			};

			// don't let angular request the module, it won't be available
			// and we don't want an error here
			delete route.templateUrl;
		}
	};
	return this;
}