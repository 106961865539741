'use strict';

function uibTabPane() {
	
	return {
		restrict: 'C',
		controller: /*@ngInject*/ function($scope) {
			this.tab = $scope.tab.tab;
		}
	}
	
}

module.exports = uibTabPane;