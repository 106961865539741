import { Optional } from "@crispico/foundation-react";
import { ItemRenderer } from "@crispico/react-timeline-10000";
import { ColorsDecimal, ColorUtil, Color } from "utils/ColorUtil";
import { CustomItem } from "../mapper/GanttAdapter";
import { ConstraintSegmentItem } from "../PlanningGantt";

const MAX: string = 'MAX';

export class ConstraintItemRenderer extends ItemRenderer {

    getTitle(): string | JSX.Element {
        const counterAsObject: Optional<object> = (this.props.item as CustomItem).node.source;
        if (counterAsObject == null) {
            return '';
        }
        const counter: ConstraintSegmentItem = counterAsObject as ConstraintSegmentItem;
        let label: string = counter.actualValue + '';
        if (counter.counterType === MAX && counter.usedWithNullOrPrivateConstraintGroup) {
            label += '*';
        }
        return label;
    }

    calculateColor(): ColorsDecimal {
        const counterAsObject: Optional<object> = (this.props.item as CustomItem).node.source;
        if (counterAsObject == null) {
            return 0;
        }
        const counter: ConstraintSegmentItem = counterAsObject as ConstraintSegmentItem;
        let diff: number = 0;
        if (counter.counterType === MAX) {
            diff = counter.constraintValue - counter.actualValue;
        } else {
            diff = counter.actualValue - counter.constraintValue;
        }
        if (diff === 0) {
            return ColorsDecimal.ORANGE;
        } else if (diff < 0) {
            return ColorsDecimal.RED;
        }

        return ColorsDecimal.GREEN;
    }

    getBackgroundGradient(): string {
        return Color(this.calculateColor()).hex();
    }

    getTextColor(): string {
        return Color(ColorUtil.getForeground(this.calculateColor())).hex();
    }

    getHeight(): string | number {
        // we set `margin: 0` on its parent and i want the item to use the entire height 
        return (this.props.height as number) + 10;
    }

    getStyle(): object {
        let style: object = super.getStyle();
        style = {
            ...style,
            borderColor: Color(this.calculateColor()).lightness(25).hex()
        }
        return style;
    }
}