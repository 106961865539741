'use strict';

/**
 * RealizationEditor.catalogs used for the catalogs dropdown (actually only components dropdown) is configured in multiple places:
 * 1) in realization-editor-modal.service were catalogs is initialized
 * 2) here were we add catalogs.select and catalogs.catalogs
 * 3) in catalogs-groups.controller were catalogs object received additional properties
 */
var realizationEditor = {
	template: require('./realization-editor.html'),
	bindings: {
		entity: '<', /*:Realization or Exemption*/
		employee: '<', 
		finalReviewer: '=', /*:PersonSnapshot*/
		catalogs: '<',
		catalogItemObjective: '<',
		reviewersMap: '<',
		disabled: '<'
	},
	controllerAs: 'realizationEditor',
	controller: /*@ngInject*/ function(Curriculum, CurriculumConstants, $filter, $scope, Education, uiGridConstants) {

		var realizationEditor = this;

		realizationEditor.remarks = [];

		realizationEditor.onEmployeeChange = function(employee) {
			if (employee) {
				realizationEditor.entity.person = employee.id;
			}
		};

		// custom width for each column based on screen width 
		var smallScreen = $(document).width() < 768;
		realizationEditor.smallScreen = smallScreen;
		var cellWidth = smallScreen ? 50 : 100;

		// initialise grid options
		realizationEditor.gridOptions = {
			enableColumnMenus: false,
			columnDefs: [{
					name: 'name',
					field: 'name',
					displayName: $filter('translate')('curriculum.realization.competention'),
					headerCellClass: 'evaluation-result-title',
					enableSorting: false,
					cellClass: function(grid, row, col) {
						var cellClass = "evaluation-result-cell-full ";
						if (row.entity.name == 'Gewogen percentage') {
							cellClass += 'weighted-percentage';
						} else {
							cellClass += 'evaluation-cell ';
						}
						return cellClass;
					},
					  cellTooltip: function(row, col) {
					    return row.entity.name;
					  },
					width: smallScreen ? 100 : 150
				}, {
					name: 'norm',
					field: 'norm',
					displayName: smallScreen ? 'N' : $filter('translate')('curriculum.realization.norm'),
					enableSorting: false,
					headerCellClass: 'evaluation-result-title',
					cellClass: function(grid, row, col) {
						var cellClass = "evaluation-result-cell-full align-score ";
						if (row.entity.name == 'Gewogen percentage') {
							cellClass += 'weighted-percentage';
						} else {
							cellClass += 'evaluation-cell ';
						}
						return cellClass;
					},
					headerTooltip: function(col) {
						return $filter('translate')('curriculum.realization.norm');
					},
					width: cellWidth
				}, {
					name: 'weight',
					field: 'weight',
					displayName: smallScreen ? 'G' : $filter('translate')('curriculum.realization.weight'),
					enableSorting: false,
					headerCellClass: 'evaluation-result-title',
					cellClass: function(grid, row, col) {
						var cellClass = "evaluation-result-cell-full align-score ";
						if (row.entity.name == 'Gewogen percentage') {
							cellClass += 'weighted-percentage';
						} else {
							cellClass += 'evaluation-cell ';
						}
						return cellClass;
					},
					headerTooltip: function(col) {
						return $filter('translate')('curriculum.realization.weight');
					},
					width: cellWidth
				}, {
					name: 'average',
					cellTemplate: '<div class="ui-grid-cell-contents align-score">' +
									'<div ng-if="row.entity.averageItemScore != \'NaN\'"> {{row.entity.averageItemScore}}</div>' +
									'<div ng-if="row.entity.averageItemScore == \'NaN\'"> - </div></div>',
					displayName: smallScreen ? 'Gm' : $filter('translate')('curriculum.realization.average'),
					enableSorting: false,
					headerCellClass: 'evaluation-result-title',
					cellClass: function(grid, row, col) {
						var cellClass = 'evaluation-result-cell-full ';

						if (row.entity.name == 'Gewogen percentage') {
							cellClass += 'total-score-color ';
						} else {
							cellClass += 'average-cell ';
						}

						if (row.entity.averageItemScore >= row.entity.norm) {
							if (row.entity.name == 'Gewogen percentage') {
								cellClass += 'positive-total-score';
							} else {
								cellClass += 'positive-score';
							}
						} else if (row.entity.averageItemScore < row.entity.norm) {
							if (row.entity.name == 'Gewogen percentage') {
								cellClass += 'negative-total-score';
							} else {
								cellClass += 'negative-score';
							}
						}
						return cellClass;
					},
					headerTooltip: function(col) {
						return $filter('translate')('curriculum.realization.average');
					},
					width: cellWidth
				}]
		};
		
		var that = this;
		realizationEditor.$onInit = function() {
			if (realizationEditor.entity) {
				if (!realizationEditor.entity.componentVersion && realizationEditor.catalogItemObjective) {
					if (realizationEditor.catalogItemObjective.component) {
						if (realizationEditor.catalogItemObjective.currentComponentVersion && realizationEditor.catalogItemObjective.component.currentComponentVersion != realizationEditor.catalogItemObjective.currentComponentVersion) {
							realizationEditor.entity.componentVersion = realizationEditor.catalogItemObjective.currentComponentVersion;
						} else {
							realizationEditor.entity.componentVersion = realizationEditor.catalogItemObjective.component.currentComponentVersion;
						}
					} else if (realizationEditor.catalogItemObjective.objective && realizationEditor.catalogItemObjective.objective.track) {
						realizationEditor.entity.componentVersion = realizationEditor.catalogItemObjective.objective.track.currentTrackVersion;
					} else if (realizationEditor.catalogItemObjective.objective && realizationEditor.catalogItemObjective.objective.module) {
						if (realizationEditor.catalogItemObjective.currentModuleVersion && realizationEditor.catalogItemObjective.module.currentModuleVersion != realizationEditor.catalogItemObjective.currentModuleVersion) {
							realizationEditor.entity.componentVersion = realizationEditor.catalogItemObjective.currentModuleVersion;
						} else {
							realizationEditor.entity.componentVersion = realizationEditor.catalogItemObjective.module.currentModuleVersion;
						}
					}

					realizationEditor.entity.selectedComponentVersion = realizationEditor.entity.componentVersion;
				}
				realizationEditor.disabledComponentVersion = realizationEditor.entity.componentVersion ? true : false;

				// load evaluation result
				$scope.getEvaluationResult();

				if (!realizationEditor.entity.date) {
					// initialize date with current date
					var currentDate = new Date();
					currentDate.setHours(0,0,0,0);
					realizationEditor.entity.date = currentDate;
				} else {
					realizationEditor.entity.date = new Date(realizationEditor.entity.date);
				}

				if (realizationEditor.entity.dueDate) {
					realizationEditor.entity.dueDate = new Date(realizationEditor.entity.dueDate);
				}

				if (!realizationEditor.entity.objectType) {
					realizationEditor.entity.objectType = CurriculumConstants.REALIZATION;
				}
				realizationEditor.catalogTypes = ['COMPONENT'];
				if (!realizationEditor.entity.selectedComponentVersion) {
					realizationEditor.excludeTypes = ['EVA'];
				}

				if (realizationEditor.employee) {
					realizationEditor.onEmployeeChange(realizationEditor.employee);
				}
				realizationEditor.isExempted = realizationEditor.entity.objectType == CurriculumConstants.EXEMPTION;
			}
			realizationEditor.currentDate = new Date();
			
			realizationEditor.resultDropdownConfig = {
					data: [],
					selection: realizationEditor.entity.result,
					labelField: 'description',
					onSelect: function(data) {
						 realizationEditor.entity.result = data;
					},
					style: {
						"dropdown-menu-left": true
					}
				};
			
			
			Curriculum.getRealizationResults({}, {}).then(function(result) {
				realizationEditor.resultDropdownConfig.data = result.data;
				// add null status; set as default
				realizationEditor.resultDropdownConfig.data.splice( 0, 0, {code: null, description: "(Geen)"} );
				if (!realizationEditor.resultDropdownConfig.selection) {
					realizationEditor.entity.result = $filter('filter')(realizationEditor.resultDropdownConfig.data, {code: null}, true)[0];
					realizationEditor.resultDropdownConfig.selection = realizationEditor.entity.result; 
				}
			});
		};
		
		$scope.$watch('realizationEditor.finalReviewer', function(newValue, oldValue) {
			if (newValue != oldValue) {
				if (newValue && newValue.objectType == "EmployeeSnapshot") {
					realizationEditor.entity.finalReviewer = newValue.id;
					if (!realizationEditor.reviewersMap) {
						realizationEditor.reviewersMap = {};
					}
					realizationEditor.reviewersMap[newValue.id] = newValue;
				} else if (!newValue) {
					realizationEditor.entity.finalReviewer = null;
				}
			}
		});

		$scope.onObjectTypeChange = function() {
			var objectType = CurriculumConstants.REALIZATION;
			if (realizationEditor.isExempted) {
				objectType = CurriculumConstants.EXEMPTION;
			}
			realizationEditor.entity.objectType = objectType;
		}

		$scope.getEvaluationResult = function() {
			if (!realizationEditor.entity.id) {
				// return if new realization
				return;
			}

			Education.getEvaluationOverview(null, {
				params: {
					realizationId: realizationEditor.entity.id
				}
			}).then(function(result) {
				if (result.data.evaluationItems != null && result.data.evaluationItems.length > 0) {
					var numberOfEvaluations = result.data.evaluationItems[0].scores.length;
					
					// pin existing columns; only if there are evaluations
					if (numberOfEvaluations > 0) {
						for (var i in realizationEditor.gridOptions.columnDefs) {
							var column = realizationEditor.gridOptions.columnDefs[i] ;
							column.enablePinning = true;
							column.pinnedLeft = true;			
						}
					}

					// create a new column for each evaluation
					for (var i = 0; i < numberOfEvaluations; i++) {
						var evaluator = result.data.evaluationItems[0].scores[i].resource;
						var columnDef = {
							name: 'score' + i,
							cellTemplate: '<div class="ui-grid-cell-contents align-score">' +
									'<div ng-if="row.entity.scores[col.colDef.index].score != \'NaN\'"> {{row.entity.scores[col.colDef.index].score}}</div>' +
									'<div ng-if="row.entity.scores[col.colDef.index].score == \'NaN\'"> - </div></div>',
							displayName: smallScreen ? 'S(' + (i + 1) + ')' : evaluator.firstName + " " + evaluator.name,
							headerCellClass: 'evaluation-result-title',
							enableSorting: false,
							cellClass: function(grid, row, col) {
								var cellClass = 'evaluation-result-cell-full ';

								if (row.entity.name == 'Gewogen percentage') {
									cellClass += 'weighted-percentage ';
								} else {
									cellClass += 'evaluation-cell ';
								}

								if (row.entity.scores[col.colDef.index].score >= row.entity.norm) {
									cellClass += 'positive-score';
								} else if (row.entity.scores[col.colDef.index].score < row.entity.norm) {
									cellClass += 'negative-score';
								}
								return cellClass;
							},
							headerTooltip: function(col) {
								var index = col.colDef.index;
								var evaluationScore = col.grid.options.data[0].scores[index];
								return evaluationScore.resource.firstName + " " + evaluationScore.resource.name;
							},
							index: i,
							width: smallScreen ? 55 : 100
						};
						realizationEditor.gridOptions.columnDefs[realizationEditor.gridOptions.columnDefs.length] = columnDef;
					}
				}
				realizationEditor.gridOptions.data = result.data.evaluationItems;

				if (result.data.remarks != null && result.data.remarks.length > 0) {
					realizationEditor.remarks = result.data.remarks;
				}
			});
		};

		realizationEditor.getResourceForLegend = function(evaluationScore, index) {
			if (!evaluationScore) {
				return;
			}
			var name = evaluationScore.resource.name;
			var firstName = evaluationScore.resource.firstName;

			return "S(" + (index + 1) + ") - " + firstName + " " + name; 
		}
	}

};

module.exports = realizationEditor;