import { apolloClient, EntityDescriptor, Utils } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { TreeTable, TreeTableReducers, TreeTableState } from "@crispico/foundation-react/components/treeTable/TreeTable";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { SECURITY_SERVICE_FACADE_BEAN_GET_SECURITY_PROFILES_FOR_PERSON } from "graphql/queries";
import React, { createRef } from "react";
import { Form, Grid, Icon, Input, Segment } from "semantic-ui-react";
import { EmployeesTree, EmployeesTreeRaw } from "../../components/employeesTree/EmployeesTree";
import { RenderItemParams } from "@crispico/foundation-react/components/TreeRRC/Tree";

interface Permission {
    name: string,
    permissions: {
        code: string,
        description: string
    }[]
}

class PermissionsTreeTableState extends TreeTableState { }
class PermissionsTreeTableReducers<S extends PermissionsTreeTableState = PermissionsTreeTableState> extends TreeTableReducers<S> {
    protected _getChildren(item: any): { localId: string, item: any; }[] {
        if (Array.isArray(item)) {
            return super._getChildren(item, {});
        }

        const permissions = item.permissions as Array<any>;
        if (permissions && permissions.length !== 0) {
            return (permissions.map((item, index) => ({ localId: "permissions" + Utils.defaultIdSeparator + index, item })));
        } else {
            return item.code;
        }
    }

    protected _hasChildren(item: any) {
        if (item.code) {
            return false;
        }
        return true;
    }
}

class PermissionsTreeTable extends TreeTable {
    protected renderItem = (params: RenderItemParams) => {
        const objectId = params.linearizedItem.itemId.split(Utils.defaultIdSeparator);
        const object = Utils.navigate(this.props.root, objectId);
        if (object.code) {
            return <React.Fragment key={object.code}>{object.code}</React.Fragment>;
        }
        return <b key="unlock" className="UsersRoles_treeTableHeader"><Icon name="unlock alternate" size="large"></Icon>{object.name}</b>;
    };
}

export const PermissionTreeTableRRC = ReduxReusableComponents.connectRRC(TreeTableState, PermissionsTreeTableReducers, PermissionsTreeTable);

export const sliceUsersRoles = createSliceFoundation(class SliceUsersRoles {

    initialState = {
        permissions: [] as Permission[],
        filteredPermissions: [] as Permission[],
        searchedPermission: "" as string,
        treeTableRoot: [] as any,
    };

    reducers = {
        ...getBaseReducers<SliceUsersRoles>(this),

        setTreeTableRoot(state: StateFrom<SliceUsersRoles>) {
            if (state.permissions.length === 0) {
                state.treeTableRoot = [];
            } else if (state.searchedPermission === "") {
                state.treeTableRoot = state.permissions;
            } else {
                state.treeTableRoot = state.filteredPermissions;
            }
        },

    }

    impures = {
        ...getBaseImpures<SliceUsersRoles>(this),
    };
});

export class UsersRoles extends TabbedPage<PropsFrom<typeof sliceUsersRoles>> {
    employeesTreeRef = createRef<EmployeesTreeRaw>();
    prevEmployeesTreeRef = {} as any;
    protected treeTableRef = createRef<PermissionsTreeTable>();

    protected tableDescriptor = new EntityDescriptor({ name: "UsersRoles" })
        .addFieldDescriptor({ name: "code", type: FieldType.string })
        .addFieldDescriptor({ name: "description", type: FieldType.string });

    protected columns = [{ name: "code", width: 2, label: _msg("code.label") }, { name: "description", width: 4, label: _msg("description.label") }];

    protected getTitle() {
        return { icon: "unlock alternate", title: _msg("UsersRoles.label") };
    }

    protected getTabbedPageCssClasses() {
        return super.getTabbedPageCssClasses() + " container_fullHeight";
    }

    async getPermissions(employee: any) {
        const permissions = (await apolloClient.query({
            query: SECURITY_SERVICE_FACADE_BEAN_GET_SECURITY_PROFILES_FOR_PERSON,
            variables: {
                person: employee.id
            }
        })).data.securityServiceFacadeBean_securityProfilesForPerson;
        this.getPermissionsAsRootOfTreeTable(permissions);
    }

    sortAndExpandPermissions(permissions: Permission[]) {
        let expandedIds = {};
        permissions = permissions.sort((a: any, b: any) => a.name < b.name ? -1 : 1);
        for (let i = 0; i < permissions.length; i++) {
            expandedIds = { ...expandedIds, [i]: true };
            permissions[i].permissions = permissions[i].permissions.sort((a: any, b: any) => a.code < b.code ? -1 : 1);
        }
        this.treeTableRef.current?.props.r.setInReduxState({ expandedIds });
    }

    /**
    * Adapt the permissions object for treeTable
    */
    getPermissionsAsRootOfTreeTable(permissions: any) {
        let permissionsAsRoot: Permission[] = [];
        for (let i = 0; i < permissions.length; i++) {
            const permission = { name: permissions[i].description, permissions: permissions[i].permissions };
            permissionsAsRoot.push(permission);
        }
        this.sortAndExpandPermissions(permissionsAsRoot);
        this.props.dispatchers.setInReduxState({ permissions: permissionsAsRoot });
        this.filterPermissions();
    }

    filterPermissions() {
        if (this.props.dispatchers.getState().searchedPermission === "") {
            this.props.dispatchers.setTreeTableRoot();
            return;
        }
        let filteredPermissions: Permission[] = [];
        for (let i = 0; i < this.props.dispatchers.getState().permissions.length; i++) {
            let permission = { name: this.props.dispatchers.getState().permissions[i].name, permissions: [] as any };
            for (let j = 0; j < this.props.dispatchers.getState().permissions[i].permissions.length; j++) {
                if (this.props.dispatchers.getState().permissions[i].permissions[j].code.toLowerCase().includes(this.props.dispatchers.getState().searchedPermission.toLowerCase())) {
                    permission.permissions.push(this.props.dispatchers.getState().permissions[i].permissions[j]);
                }
            }
            if (permission.permissions.length !== 0) {
                filteredPermissions.push(permission);
            }
        }
        this.props.dispatchers.setInReduxState({ filteredPermissions: filteredPermissions });
        this.props.dispatchers.setTreeTableRoot();
    }

    handleSelectedId = (selectedId: string | undefined) => {
        if (selectedId) {
            const employee = Utils.navigate(this.employeesTreeRef.current?.getTreeRoot(), selectedId);
            if (employee.id) {
                this.getPermissions(employee);
            }
        }
    };

    protected renderMain() {
        return (
            <div className="UsersRoles">
                <Grid columns={2}>
                    <Grid.Row>
                        <EmployeesTree
                            id="employeesTree_roles"
                            ref={this.employeesTreeRef}
                            onSelectedIdChanged={this.handleSelectedId}
                        />
                        <Grid.Column style={{ height: "100%" }}>
                            <Grid.Row>
                                <Segment>
                                    <Form>
                                        <Form.Field>
                                            <Input value={this.props.searchedPermission} onChange={(e, data) => {
                                                this.props.dispatchers.setInReduxState({ searchedPermission: data.value as string });
                                                this.filterPermissions();
                                            }} placeholder={_msg("searchPermission.label")} icon="search" />
                                        </Form.Field>
                                    </Form>
                                </Segment>
                            </Grid.Row>
                            <Grid.Row className="UsersRoles_tree">
                                <PermissionTreeTableRRC id="treeTable" ref={this.treeTableRef} root={this.props.treeTableRoot} columns={this.columns}
                                    renderColumn={(object: any, columnName: string) => <React.Fragment key={object[columnName]}>{object[columnName]}</React.Fragment>} verticalAlign="middle"
                                />
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    static getPrevRefSnapshot(screen: { employeesTreeRef: React.RefObject<EmployeesTreeRaw>, prevEmployeesTreeRef: any }) {
        const stateChild = screen.employeesTreeRef.current?.props.s;
        screen.prevEmployeesTreeRef = {
            date: stateChild?.date,
            groupCode: stateChild?.groupCode,
            selectedEmployeeId: stateChild?.selectedEmployeeId,
            treeRef: screen.employeesTreeRef.current?.treeRef,
            selectedId: stateChild?.selectedId
        };
    }

    componentDidMount() {
        if (this.props.treeTableRoot === undefined) {
            this.props.dispatchers.setTreeTableRoot();
        }
        UsersRoles.getPrevRefSnapshot(this);
    }

    static groupOrDateOrSearchedEmployeeChanged(screen: { employeesTreeRef: React.RefObject<EmployeesTreeRaw>, prevEmployeesTreeRef: any }): boolean {
        return ((screen.employeesTreeRef.current?.props.s.groupCode !== screen.prevEmployeesTreeRef?.groupCode && screen.prevEmployeesTreeRef?.groupCode !== undefined && screen.prevEmployeesTreeRef?.groupCode !== "") ||
            (screen.prevEmployeesTreeRef?.date !== undefined && screen.employeesTreeRef.current?.props.s.date !== screen.prevEmployeesTreeRef?.date) ||
            (screen.employeesTreeRef.current?.props.s.selectedEmployeeId !== screen.prevEmployeesTreeRef?.selectedEmployeeId))
    }

    async componentDidUpdate(prevProps: any) {
        if (this.employeesTreeRef.current?.props.s.selectedId !== this.prevEmployeesTreeRef.selectedId) {
            UsersRoles.getPrevRefSnapshot(this);
        }

        //group, date or searched employee changed => clear its permissions because no employee will be selected
        const changed = UsersRoles.groupOrDateOrSearchedEmployeeChanged(this);
        if (changed) {
            this.props.dispatchers.setInReduxState({ permissions: [], searchedPermission: "" });
            this.props.dispatchers.setTreeTableRoot();
            UsersRoles.getPrevRefSnapshot(this);
        }
    }
}

export const infoUsersRoles = new ConnectedPageInfo(sliceUsersRoles, UsersRoles, "UsersRoles");
infoUsersRoles.routeProps = { permission: "USER_ROLES_VIEW" };