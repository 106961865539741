'use strict';

/*@ngInject*/
function RegistrationDetailCtrl($scope, $filter, ModalSpinner, FormModal, AgendaConstants, Agenda, RegistrationMetadata, Resources, Principal, DATE_FORMAT, DATE_TIME_FORMAT, ErrorModal) {
	
	$scope.model.scope = $scope;
	
	var agendaRegistration = $scope.agendaRegistration;

	if ($scope.agendaRegistration.registration.detail && $scope.agendaRegistration.registration.detail.secondDate) {
		$scope.agendaRegistration.registration.detail.secondDate = new Date($scope.agendaRegistration.registration.detail.secondDate);
	}

	var options = $scope.options;	
	var registration = agendaRegistration.registration;
	$scope.numberOfShifts = "";

 	var roundingType = agendaRegistration.metadata[AgendaConstants.Properties.ROUNDING_TYPE_CODE]; 
 //	if (!roundingType) {
 		$scope.dialogDateFormat = DATE_TIME_FORMAT;
// 	} else {
//		$scope.dialogDateFormat = DATE_FORMAT;
// 	}

	if (roundingType === AgendaConstants.ROUNDING_TYPE_DAG) {
		var config = {
				data: {
					resource: registration.resource,
					fromDate: AgendaConstants.MODE_DAY.getFirstDate(new Date(registration.fromDate)),
					toDate: AgendaConstants.MODE_DAY.getLastDate(registration.toDate),
					roundingType: roundingType
				}
			}
		Agenda.getRoundingValue({}, config).then(function(result) {
			agendaRegistration.registration.fromDate = new Date(result.data.fromDate);
			agendaRegistration.registration.toDate = new Date(result.data.toDate);
		}, function(error) {
			ErrorModal.open({
				titleId: ErrorModal.VALIDATION_ERROR,
				message: error.data.message
			});
		});
	}
	
	$scope.code = registration.type.code;
	var milestone = agendaRegistration.metadata[AgendaConstants.MILESTONE];
	$scope.status = {
		minDate: new Date(registration.fromDate),
		maxDate: milestone ? null : new Date(registration.toDate),
		remarks: angular.copy(registration.remarks),
		updateMode: options.updateMode,
		editable: agendaRegistration.metadata[AgendaConstants.UPDATE_ROLE],
		splitable: agendaRegistration.metadata[AgendaConstants.ALLOW_MAP_TO_WORKPERIOD] && agendaRegistration.registration.id,
		milestone: milestone,
		leave: false,         // leave registrations => will display leave group select if true
		exchange: false,      // exchange registrations => display secondParty + secondDate inputs
		showDatePicker: false, // sequence exchange => hide secondDate input$,
		resource: registration.resource,
		roundingType: roundingType,
		agenda: options.agenda,
		halfDayRounding: agendaRegistration.halfDay,
		existingRegistration: agendaRegistration.registration.id
	};
	$scope.status.computeNumberOfShifts = function(fromDate, toDate, agendaDays) {
		if (fromDate == null || toDate == null) {
			return 0;
		}
		var shifts = {};
		var startIndex;
		var startTime = new Date(fromDate).getTime();
		if ($scope.options.agenda.mode == AgendaConstants.MODE_OPEN_COMPARTMENTS) {
			for (var i = 0; i < agendaDays.length; i++) {
				var agendaDayStartTime = new Date(agendaDays[i].start).getTime();
				var agendaNextDayStartTime;
				if (i < agendaDays.length - 1) {
					agendaNextDayStartTime = new Date(agendaDays[i+1].start).getTime();
				}
				if ((agendaNextDayStartTime != undefined && agendaDayStartTime <= startTime && startTime < agendaNextDayStartTime)
					|| (agendaNextDayStartTime ==  undefined && agendaDayStartTime <= startTime)) {
					startIndex = i;
					break;
				}
			}
		} else {
			startIndex = fromDate.getDate() - 1;
		}
		for (var i = startIndex; i < agendaDays.length; i++) {
			var agendaDay = agendaDays[i];
			if (new Date(agendaDay.start) > toDate) {
				break;
			}
			if (agendaDay.workRosterType != null) {
				var numberOfShifts = 0;
				if (shifts[agendaDay.workRosterType] != undefined) {
					numberOfShifts = shifts[agendaDay.workRosterType];
				}
				if (new Date(agendaDay.start) <= toDate) {
					numberOfShifts += 1;
				}	
				shifts[agendaDay.workRosterType] = numberOfShifts;
			}
		}
		var first = true;
		$scope.numberOfShifts = "";
		for (var key in shifts) {
			if (!first) {
				$scope.numberOfShifts += "- ";
			}
			$scope.numberOfShifts += shifts[key] + " " + key + " ";
			first = false;
		}
	}
	$scope.status.computeNumberOfShifts($scope.status.minDate, $scope.status.maxDate, $scope.$parent.options.agenda.gridOptions.data);
	$scope.flexibleRoundingOptions = null;
	switch (options.agenda.registrationTypesMetadata[registration.type.code][AgendaConstants.LAYOUT_TYPE_CODE]) {
	case AgendaConstants.LAYOUT_TYPE_FLEXIBLE_AVAILABILITY:
		$scope.flexibleRoundingOptions = [
			{
				setting: AgendaConstants.FLEXIBLE_ALL_DAY,
				label: 'agenda.flexibleAvailability.allDay'
			}, {
				setting: AgendaConstants.FLEXIBLE_EARLY,
				label: 'agenda.flexibleAvailability.early'
			}, {
				setting: AgendaConstants.FLEXIBLE_LATE,
				label: 'agenda.flexibleAvailability.late'
			}];
		const startHour = (new Date(agendaRegistration.registration.fromDate)).getHours();
		const endHour = (new Date(agendaRegistration.registration.toDate)).getHours();
		$scope.flexibleRounding = null;
		if (startHour === 0) {
            if (endHour === 11) {
                $scope.flexibleRounding = $scope.flexibleRoundingOptions[1];
            } else {
                $scope.flexibleRounding = $scope.flexibleRoundingOptions[0];
            }
        } else if (startHour == 12) {
            $scope.flexibleRounding = $scope.flexibleRoundingOptions[2];
        }
		break;
	case AgendaConstants.LAYOUT_TYPE_VERLOF:
		if (!registration.detail) {
			// creating the registration => create the detail
			registration.detail = {
				objectType: AgendaConstants.DETAIL_LEAVE,
				groupCode: options.agenda.leaveGroup.code
			};
		}
		$scope.status.leave = true;
		$scope.leaveGroupsOptions = {
			preselectedGroupSnapshotCode: registration.detail.groupCode
		};
		break;
	case AgendaConstants.LAYOUT_TYPE_WISSEL:
		if (!registration.detail) {
			// creating the registration => create the detail
			registration.detail = {
				objectType: AgendaConstants.DETAIL_EXCHANGE,
				applicant: true
			};
		}
		$scope.status.exchange = true;
		$scope.status.showDatePicker = true;
		
		options.waitForExchangeRelations = true;
		
		// find the employees that I can exchange with in 1-2-3 easy steps!
		// 1. get the groups where I am currently a member
		Resources.getGroupSnapshotsOfMembers({}, {
			params: {
				member: registration.resource,
				groupContextCode: 'VERLOF',
				date: $filter('date')(new Date(), 'dd/MM/yyyy HH:mm:ss')
			}
		}).then(function(result) {
			var groupIds = [];
			angular.forEach(result.data, function(group) {
				groupIds.push(group.id);
			});
			
			// if I am not a member of any group in the VERLOF context, then I can exchange with anyone
			if (groupIds == null || groupIds.length == 0) {
				options.exchangeGroups = [];
				options.waitForExchangeRelations = false;
				return;
			}

			// 2. get the 'exchange' relations for my groups
			Resources.getGroupRelations({}, {
				params: {
					groupIds: groupIds.join(','),
					relationType: 'WISSEL'
				}
			}).then(function(result) {
				var exchangeGroupIds = [];
				angular.forEach(result.data, function(groupRelation) {
					exchangeGroupIds.push(groupIds.indexOf(groupRelation.source) < 0 ? groupRelation.source : groupRelation.target);
				});
				
				// 3. get the codes for the exchange groups
				
				Resources.getGroupSnapshotsByIds({}, {
					params: {
						groupIds: exchangeGroupIds.join(',')
					}
				}).then(function(result) {
					var exchangeGroups = [];
					angular.forEach(result.data, function(group) {
						exchangeGroups.push(group.code);
					});
					options.exchangeGroups = exchangeGroups;
					options.waitForExchangeRelations = false;
				});
			});
		});
		
		break;
	case AgendaConstants.LAYOUT_TYPE_BEURTWISSEL:
		if (!registration.detail) {
			// creating the registration => create the detail
			registration.detail = {
				objectType: AgendaConstants.DETAIL_SEQUENCE_EXCHANGE,
				applicant: true
			};
		}
		$scope.status.exchange = true;
		break;
	case AgendaConstants.LAYOUT_TYPE_MEDICAL_CHECKUP:
		var medicalCheckupTypes = [];
		Agenda.getMedicalCheckupTypes().then(function(result) {
			medicalCheckupTypes = result.data;
		}).finally(function() {
			if (medicalCheckupTypes.length == 0) {
				medicalCheckupTypes[0] = {typeCode: null, description: '(Geen)'};
			}
			if (!registration.detail) {	
				// creating the registration => create the detail
				$scope.status.selectedMedicalCheckupType = medicalCheckupTypes[0];
				registration.detail = {
					objectType: AgendaConstants.DETAIL_MEDICAL_CHECKUP,
					typeCode: $scope.status.selectedMedicalCheckupType.code,
					applicant: true
				};
			} else {
				if (registration.detail.typeCode == null) {
					registration.detail.description = '(Geen)';
				}
				for (var i in medicalCheckupTypes) {
					if (medicalCheckupTypes[i].code === registration.detail.typeCode) {
						$scope.status.selectedMedicalCheckupType = medicalCheckupTypes[i];
						break;
					}
				}
			}
			
			$scope.status.medicalCheckupTypes = medicalCheckupTypes;
		});
		$scope.status.medical_chekcup = true;
		break;
	}

	// special case for CONSTRAINED registration types
	if (options.agenda.registrationTypesMetadata[registration.type.code][AgendaConstants.CONSTRAINED]) {
		if (!registration.detail) {
			registration.detail = {
				objectType: AgendaConstants.DETAIL_CONSTRAINT_GROUP,
				groupCode: options.agenda.leaveGroup.code
			};
		}
		$scope.status.leave = true;
		$scope.leaveGroupsOptions = {
			preselectedGroupSnapshotCode: registration.detail.groupCode
		};
	}
	
	if (registration.type.code == AgendaConstants.CODE_VC || registration.type.code == AgendaConstants.CODE_TVL) {
		Principal.hasPermissions('PLANNING_ALLOW_HALF_DAY_REGISTRATIONS').then(function(result) {
			if (!result) {
				return;
			}
			options.halfDayOptions = [{
				setting: AgendaConstants.HALF_DAY_FULL,
				label: 'agenda.halfDay.full'
			}, {
				setting: AgendaConstants.HALF_DAY_AM,
				label: 'agenda.halfDay.am',
				prefix: 'VM'
			}, {
				setting: AgendaConstants.HALF_DAY_PM,
				label: 'agenda.halfDay.pm',
				prefix: 'NM'
			}];

			if (!registration.id) {
				agendaRegistration.halfDay = options.halfDayOptions[0];
			} else {
				var prefix = agendaRegistration.prefix;
				if (prefix) {
					var found = false;
					angular.forEach(options.halfDayOptions, function(option) {
						if (option.setting == prefix) {
							agendaRegistration.halfDay = option;
							found = true;
						}
					});
					if (!found) {
						agendaRegistration.halfDay = {
							label: 'none'
						};
						options.halfDayOptions.push(agendaRegistration.halfDay);
					}
				}
			}
			$scope.$watch('agendaRegistration.halfDay', function(newValue, oldValue) {
				var config = {
					data: {
						resource: agendaRegistration.registration.resource,
						fromDate: agendaRegistration.registration.fromDate,
						toDate: agendaRegistration.registration.toDate,
						roundingType: roundingType,
						halfDayRounding: newValue ? newValue.setting : undefined
					}
				}
				$scope.status.halfDayRounding = newValue ? newValue.setting : undefined;

				Agenda.getRoundingValue({}, config).then(function(result) {
					agendaRegistration.registration.fromDate = new Date(result.data.fromDate);
					agendaRegistration.registration.toDate = new Date(result.data.toDate);
				}, function(error) {
					ErrorModal.open({
						titleId: ErrorModal.VALIDATION_ERROR,
						message: error.data.message
					});
				}).finally(function() {
				//	agenda.refresh();
				});
			});
		});
	}


	$scope.agendaRegistration = agendaRegistration;
	$scope.agendaRegistration.split = $scope.agendaRegistration.metadata[AgendaConstants.MAP_TO_WORKPERIOD];
	
	$scope.isEditable = function() {
		return !$scope.status.updateMode || $scope.status.editable;
	};

	$scope.isConstraintGroupDropdownEditable = function() {
		return this.leaveGroupsOptions.disableDropDown || !this.isEditable() || agendaRegistration.registration.id
	}

	/**
	 * Open a read-only view of the agenda for the second party.
	 */
	$scope.selectSecondDate = function(secondParty) {
		var parentScope = $scope;
		var modal = FormModal.open({
			windowClass: 'modal-full modal-no-padding',
			scope: {
				template: require('./../agenda-grid/agenda-grid.html'),
				title: 'agenda.selectCompensation',
				agenda: {
					date: options.agenda.date,
					mode: AgendaConstants.MODE_MONTH,
					resource: secondParty,
					useOtherAgendaContext: true,
					constraints: {},

					selectable: true,
					selectColumn: {
						name: 'select',
						headerCellClass: 'hide',
						cellClass: 'ui-grid-cell-no-border',
						minWidth: 100,
						cellTemplate: require('./agenda-grid-select.html')
					},
					selectDate: function(date) {
						// keep selected date
						parentScope.agendaRegistration.registration.detail.secondDate = new Date(date);
						modal.close();
						ModalSpinner.hide();
					}
				}
			},
			actions: ['close']
		});
	};
	$scope.addRemark = function(reg) {
		if (typeof(reg.remarks) == 'undefined' || reg.remarks == null) {
			reg.remarks = [];
		}
		reg.remarks.push({value: ""});
	};
	$scope.removeRemark = function(remarkList, index) {
		remarkList = remarkList.splice(index, 1);
	};
	$scope.updateMedicalCheckupType = function() {
		registration.detail.typeCode = this.status.selectedMedicalCheckupType.code;
	}
	// milestone registrations have startDate = toDate; this method is called from the registration-detail-date directive
	$scope.setToDate = function(date) {
		$scope.agendaRegistration.registration.toDate = date;
	}
	$scope.isMaxDate = function() {
		if ($scope.status.milestone) {
			return false;
		}
		return true;
	}
	$scope.onClickMilestoneBtn = function() {
		$scope.agendaRegistration.registration.toDate = $scope.agendaRegistration.registration.fromDate;
	}

	$scope.onFlexibleRoundingChange = function(flexibleRounding) {
		const fromDate = new Date(agendaRegistration.registration.fromDate);
		const toDate = new Date(agendaRegistration.registration.toDate);
		let fromDateTime;
		let toDateTime;
		if (flexibleRounding.setting === AgendaConstants.FLEXIBLE_ALL_DAY) {
			fromDateTime = { hours: 0, minutes: 0};
			toDateTime = { hours: 23, minutes: 59};
		} else if (flexibleRounding.setting === AgendaConstants.FLEXIBLE_EARLY) {
			fromDateTime = { hours: 0, minutes: 0};
			toDateTime = { hours: 11, minutes: 59};
		} else if (flexibleRounding.setting === AgendaConstants.FLEXIBLE_LATE) {
			fromDateTime = { hours: 12, minutes: 0};
			toDateTime = { hours: 23, minutes: 59};
		}
		if (fromDateTime && toDateTime)
		agendaRegistration.registration.fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), fromDateTime.hours, fromDateTime.minutes, 0, 0);
		agendaRegistration.registration.toDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), toDateTime.hours, toDateTime.minutes, 0, 0);
	}
}

module.exports = RegistrationDetailCtrl;
