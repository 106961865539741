'use strict'

/*@ngInject*/
function BackgroundTasksPageCtrl($scope, $rootScope, $routeParams, $q, uiGridConstants, Confirm, ErrorModal, BackgroundTasks, ModalSpinner, PROTEUS_URL, Principal) {
	
	var ctrl = this;
	
	$scope.date = new Date();
	
	$scope.gridOptions = {
		enableHorizontalScrollbar: 0,
		gridMenuShowHideColumns: false,
		
		// external pagination
		useExternalPagination: true,
		paginationPageSize: 20,
		paginationPageSizes: [],
		onRegisterApi: function(gridApi) {
			$scope.gridApi = gridApi;
			gridApi.pagination.on.paginationChanged($scope, function(newPage, pageSize) {
				BackgroundTasks.paginationCallback(newPage, pageSize);
				$scope.refresh();
			});
		},
		
		// selection
		multiSelect: true,
		enableFullRowSelection: false,
		selectionRowHeaderWidth: 50
	}
	
	$scope.sortCol = {
		name: 'backgroundTasks.cols.start',
		headerCellFilter: 'translate',
		field: 'processingStartDate',
		enableHiding: false,
		minWidth: 120,
		cellTemplate: require('./background-task.html')
	};

	let ownerCol = 	{
		name: 'owner',
		field: 'owner',
		cellFilter: 'date',
		displayName: 'backgroundTasks.cols.owner',
		headerCellFilter: 'translate',
		minWidth: 120
	};

	$scope.gridOptions.columnDefs = [
	
	// name column
	
	{
		name: 'name',
		enableHiding: false,
		displayName: 'backgroundTasks.cols.name',
		headerCellFilter: 'translate',
		cellTemplate: '<div class="ui-grid-cell-contents word-wrap" title="TOOLTIP">{{row.entity.name}}</div>'
	}, 
	
	// attachments column
	
	{
		name: 'outputFiles',
		enableHiding: false,
		displayName: 'backgroundTasks.cols.files',
		headerCellFilter: 'translate',
		minWidth: 240,
		cellTemplate: '<div class="ui-grid-cell-contents" title="TOOLTIP">' + 
		'<div ng-repeat="item in row.entity.outputFiles">' + 
		'<a href="" ng-click="grid.appScope.openFile(item.id)">{{item.name}}</a>' + 
		'</div>' + 
		'</div>'
	}, 
	
	// status column
	
	{
		name: 'status',
		enableHiding: false,
		displayName: 'backgroundTasks.cols.status',
		headerCellFilter: 'translate',
		minWidth: 80,
		cellTemplate: '<div class="ui-grid-cell-contents clearfix" title="TOOLTIP">' + 
		'<span class="label" ng-class="grid.appScope.getStatusClass(row.entity.status)">{{row.entity.status | translate}}</span>' + 
		'</div>'
	}, 
	
	// start column
	
	$scope.sortCol, 
	
	// end column
	
	{
		name: 'processingEndDate',
		field: 'processingEndDate',
		cellFilter: 'date',
		enableHiding: false,
		displayName: 'backgroundTasks.cols.end',
		headerCellFilter: 'translate',
		minWidth: 120
	},

	// remove column
	
	{
		name: 'remove',
		enableHiding: false,
		enableSorting: false,
		enableColumnMenu: false,
		displayName: '',
		width: 60,
		cellTemplate: '<div class="ui-grid-cell-contents text-center" title="TOOLTIP">' + 
		'<div class="btn btn-primary" ng-click="grid.appScope.remove([row.entity])">' + 
		'<i class="fa fa-trash"></i>' + 
		'</div>' + 
		'</div>'
	}]
	
	$scope.getStatusClass = function(status) {
		switch (status) {
		case 'PROCESSING':
			return 'label-warning';
		case 'FINISHED':
			return 'label-success';
		case 'FINISHED_WITH_ERRORS':
			return 'label-danger';
		}
		return 'label-default';
	}
	
	$scope.openFile = function(id) {
		let url = PROTEUS_URL + '/attachment/download?type=BACKGROUND_TASK_OUTPUT_FILE&id=' + id;
		if(process.env.NODE_ENV === "development") {
			url = "http://localhost:8080" + url;
		}
		window.open(url, '_blank', "location=yes,toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=900,height=600");
	}
	
	$scope.remove = function(items) {
		Confirm.open({
			title: 'clear',
			message: 'backgroundTasks.remove.confirm'
		}).result.then(function() {
			ModalSpinner.show();
			
			var promises = [];
			for (var i = 0; i < items.length; i++) {
				promises.push(BackgroundTasks.remove($scope.gridOptions, {
					params: {
						id: items[i].id
					}
				}));
			}
			
			$q.all(promises).then(angular.noop, function(error) {
				ErrorModal.open({
					titleId: ErrorModal.SERVER_ERROR,
					message: error.data.message
				});
			}).finally(function() {
				$scope.refresh();
			})
		});
	}
	
	$scope.onResourceChange = function(resource) {
		$scope.refresh();
		if (resource === null) {
			$scope.showOwnerColumn(true);
		} else {
			$scope.showOwnerColumn(false);
		}
	}

	$scope.refresh = function() {
		ModalSpinner.show();
		BackgroundTasks.getAll($scope.gridOptions, {
			data: {
				requestorId: $scope.resource ? $scope.resource.id : null
			}}).finally(function() {
			if ($scope.gridOptions.data.length === 0) {
				$scope.gridApi.grid.selection.selectAll = false;
			}
			$scope.gridApi.grid.sortColumn($scope.gridApi.grid.getColumn($scope.sortCol.name), 'desc');

			var navRoute = $rootScope.getNavRoute('#/backgroundTasks');
			if (navRoute) {
				navRoute.badge = $scope.gridOptions.totalItems;
			}

			ModalSpinner.hide();
		});
	}
	
	$rootScope.navActions = [
	
	// Remove selected items action.
	
	{
		index: 10,
		icon: 'fa-trash',
		run: function() {
			var selection = $scope.gridApi.selection.getSelectedRows();
			if (selection.length == 0) {
				// empty selection
				ErrorModal.open({
					titleId: ErrorModal.ERROR,
					messageId: 'backgroundTasks.remove.emptySelection'
				});
				return;
			}
			$scope.remove(selection);
		}
	},

	// Refresh.

	{
		index: 20,
		icon: 'fa-refresh',
		run: $scope.refresh,
		template: '<proteus-ui-refresh action="action"></proteus-ui-refresh>'
	}];

	$scope.mqChanged = function(mq, visible) {
		for (var i = 0; i < $scope.gridOptions.columnDefs.length; i++) {
			// hide on xs, show on sm+
			$scope.gridOptions.columnDefs[i].visible = !visible;
		}
		// always visible
		$scope.sortCol.visible = true;
		
		if (visible) {
			$scope.sortCol.minWidth = 0;
			$scope.sortCol.displayName = 'backgroundTasks.title';
		} else {
			$scope.sortCol.minWidth = 120;
			$scope.sortCol.displayName = 'backgroundTasks.cols.start';
		}
		
		if ($scope.gridApi) {
			$scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
		}
	}

	$scope.showOwnerColumn = function(show) {
    	var ownerColumn = $scope.gridApi.grid.columns[6];
    	if (ownerColumn.field !== 'owner') {
    		if (!show) {
				return;
			}

    		$scope.gridOptions.columnDefs.splice(5, 0, ownerCol);
    		return;
    	}
 
    	if (ownerColumn.visible) {
    		if (show) {
    			return;
    			}
    	  	ownerColumn.hideColumn();
		} else {
    		if (!show) {
    			return;
    		} 
     	 	ownerColumn.showColumn();
    	}
    	$scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
	};

	$scope.date = new Date();
	var hasBackgroundTasksView = false;
	var hasBackgroundTasksViewAll = false;

	Principal.hasPermissions("BACKGROUND_TASKS_VIEW").then(function(result) {
		hasBackgroundTasksView = result;
		return Principal.hasPermissions("BACKGROUND_TASKS_VIEW_ALL");
	}).then(function(result) {
		hasBackgroundTasksViewAll = result;
		$scope.resource = $rootScope.securityContext.resource;
		if (hasBackgroundTasksView && !hasBackgroundTasksViewAll) {
			$scope.resourcePickerDisabled = true;
		} else {
			$scope.resourcePickerDisabled = false;
		}
		$scope.refresh();
	});
}

module.exports = BackgroundTasksPageCtrl;
