'use strict';

var css = require('./modal-spinner.css');

/*@ngInject*/
function ModalSpinner($uibModal) {
	var instance = null;
	var messages = {};
	
	function doHide() {
		if (instance) {
			instance.opened.then(function() {
				instance.dismiss();
				instance = null;
				messages = {};
			});
		}
	}
	
	return {
		show: function(message) {
			// display message
			message && (messages[message] = message);
			
			if (instance) {
				// already on
				return;
			}

			instance = $uibModal.open({
				animation: false,
				windowClass: 'modal-spinner-window',
				template: require('./modal-spinner.html'),
				controller: /*@ngInject*/ function($scope) {
					$scope.messages = messages;
				},
				backdrop: 'static',
				keyboard: false,
				spinner: true
			});
			instance.result.then(angular.noop, angular.noop);

			return message;
		},
		
		hide: function(message) {
			if (message) {
				delete messages[message];
				if (Object.keys(messages).length == 0) {
					doHide();
				}
			} else {
				doHide();
			}
		}
	};
}

module.exports = ModalSpinner;