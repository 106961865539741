'use strict';

module.exports = {

	template: require('../../../components/typeahead/typeahead.html'),
	bindings: {
		// inputs
		isVersionMode: '@version',
		required: '@'
	},
	require: {
		ngModelCtrl: 'ngModel' // is available only after $onInit
	},
	controllerAs: 'competences',
	controller: /*@ngInject*/ function($scope, Typeahead, $filter) {
		var competences = this;

		// initialize filter, it will be extended by CompetencesTreeCtrl
		var addon = {
			icon: 'fa fa-refresh fa-spin'
		};
		competences.filter = {
			addon: addon,
			show: true,
			placeholder: $filter('translate')('education.competences.search')
		};

		var typeahead = competences.typeahead = $scope.typeahead = 
			new Typeahead($scope, competences.filter, getName);
		typeahead.dropdownTpl = '/competences/competences-tree.html';

		// link to internal input
		competences.$onInit = function() {
			// add a formatter to display the name in the filter input
			typeahead.filter.required = this.required;
			competences.ngModelCtrl.$formatters.push(function(value) {
				typeahead.ngModelCtrl = competences.ngModelCtrl;
				typeahead.onInit();
			});
		};

		competences.select = function() {
			typeahead.select(isVersionMode() ? competences.version : competences.competence);
		};

		/**
		 * Unselect function. Invalidate the filter input.
		 */
		competences.unselect = function() {
			typeahead.unselect();
		};

		function isVersionMode() {
			return angular.isDefined(competences.isVersionMode);
		}

		function getName() {
			var model = competences.ngModelCtrl.$modelValue;
			var version = model ? model.currentCompetenceVersion : null;

			var competenceVersion = (version || model || {});
			var name = competenceVersion.name;
			if (name) {
				name = name + " v" + competenceVersion.version;
			}
			return name;
		}
	}
};