import { apolloClient, ApolloContext, apolloGlobalErrorHandler, CatchedGraphQLError, ConnectedPageInfo, createSliceFoundation, EntityDescriptor, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, TestUtils, Utils } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import React from "react";
import { Button, Label, Segment } from "semantic-ui-react";
import moment, { Moment } from "moment";
import { FREE_TIME_OVERVIEW_CALCULATOR_GET_CURRENT_FREE_TIME_STATUS_FOR_CURRENT_USER, PLANNING_SERVICE_SAVE_OR_UPDATE_REGISTRATION } from "graphql/queries";
import { ProteusConstants } from "ProteusConstants";
import { getCurrentUser_freeTimeOverviewCalculator_currentFreeTimeStatusForCurrentUser } from "apollo-gen/getCurrentUser";
import { EntityFormLight, EntityFormLightRaw } from "@crispico/foundation-react/entity_crud/light_crud/EntityFormLight";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";

const DATE_FORMAT = "DD/MM/YYYY HH:mm";

const periodSelectionDescriptor = new EntityDescriptor({ name: "PeriodSelection" })
    .addFieldDescriptor({ name: "fromDate", type: FieldType.date, enabled: false, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT}) })
    .addFieldDescriptor({ name: "toDate", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT, allowClear: false, disabledDate: (current: Moment) => current < moment().startOf('day')}) })

export const sliceFreeTime = createSliceFoundation(class SliceFreeTime {

    initialState = {
        currentUser: {} as getCurrentUser_freeTimeOverviewCalculator_currentFreeTimeStatusForCurrentUser
    }

    reducers = {
        ...getBaseReducers<SliceFreeTime>(this),
    }

    impures = {
        ...getBaseImpures<SliceFreeTime>(this),

        async getCurrentUser() {
            let user = (await apolloClient.query({ query: FREE_TIME_OVERVIEW_CALCULATOR_GET_CURRENT_FREE_TIME_STATUS_FOR_CURRENT_USER })).data.freeTimeOverviewCalculator_currentFreeTimeStatusForCurrentUser;
            this.getDispatchers().setInReduxState({ currentUser: user });
        },

        async saveOrUpdateRegistration(parameter: {}) {
            await apolloClient.mutate({
                mutation: PLANNING_SERVICE_SAVE_OR_UPDATE_REGISTRATION,
                variables: {
                    parameter: parameter
                },
                context: {
                    [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                        apolloGlobalErrorHandler(e);
                        return true;
                    }
                }
            });
            await this.getCurrentUser();
        }
    }
})

type Props = PropsFrom<typeof sliceFreeTime>
export class FreeTime<T extends Props = Props> extends TabbedPage<T> {

    protected entityFormLightRef = React.createRef<EntityFormLightRaw>();

    protected getTitle() {
        return { icon: "clock", title: _msg("FreeTimeScreen.label") };
    }

    createOrUpdateRegistration = async (startDate: Date | null, endDate: Date | null) => {
        let parameter: { [k: string]: any } = {};
        let registration: { [k: string]: any } = {};

        if (this.props.currentUser.unavailableForFreeTaskRegistration !== null) {
            registration = this.props.currentUser.unavailableForFreeTaskRegistration;
            registration = { ...registration, fromDate: new Date(this.props.currentUser.unavailableForFreeTaskRegistration.fromDate) };
        } else {
            registration.fromDate = startDate;
            registration.environment = ProteusConstants.BRABO;
            if (startDate === endDate) {
                parameter.typeCode = ProteusConstants.BESCHIKBAAR_ONBESCHIKBAAR;
                parameter.statusCode = this.props.currentUser.status === ProteusConstants.BESCHIKBAAR ? ProteusConstants.ONBESCHIKBAAR : ProteusConstants.BESCHIKBAAR;
            } else {
                parameter.typeCode = ProteusConstants.ONBESCHIKBAAR_VOOR_VRIJETIJDSTAAK;
                parameter.statusCode = ProteusConstants.EFFECTIEF;
            }
        }
        registration = { ...registration, toDate: endDate };
        parameter.registration = registration;
        parameter.errorPolicy = { policy: ProteusConstants.POLICY_ENFORCE_ALL };
        parameter.metadataContext = ProteusConstants.CONTEXT_FREE_TIME_SCREEN;
        parameter.split = false;
        parameter.useDefaultMetadataRounding = false;
        await this.props.dispatchers.saveOrUpdateRegistration(parameter);
    }

    async createMilestoneRegistration() {
        let now = new Date();
        await this.createOrUpdateRegistration(now, now);
    }

    async componentDidMount() {
        if (!TestUtils.storybookMode) {
            await this.props.dispatchers.getCurrentUser();
        }
    }

    protected openForm = () => {
        this.entityFormLightRef.current!.open({ fromDate: new Date() }, 0);
    }

    renderMain() {
        return (
            <Segment className="flex-container FreeTime" compact>
                <EntityFormLight id="FreeTime_entityFormLight" ref={this.entityFormLightRef} headerContent={_msg("FreeTimeScreen.selectInterval")} headerIcon={"calendar alternate outline"}
                    onSubmit={async (param) => {
                        let fromDate = new Date(param.values.fromDate);
                        // set the seconds to 0 only to compare the fromDate and toDate
                        fromDate.setSeconds(0, 0);
                        let toDate = undefined;
                        if (param.values.toDate) {
                            toDate = new Date(param.values.toDate);
                            toDate.setSeconds(0, 0);
                        }
                        if (!toDate) {
                            Utils.showGlobalAlert({ message: _msg("validationMessage.mandatory.label", _msg("freetime.label"), _msg("toDate.label").toLowerCase()), severity: Severity.INFO });
                        } else if (fromDate.getTime() >= toDate.getTime()) {
                            Utils.showGlobalAlert({ message: _msg("FreeTimeScreen.fromDateAfterToDate"), severity: Severity.INFO });
                        } else {
                            // do not send the fromDate and toDate with seconds set to 0, but the original dates from the form
                            await this.createOrUpdateRegistration(new Date(param.values.fromDate), new Date(param.values.toDate));
                        }
                    }}
                    entityDescriptor={periodSelectionDescriptor} />
                {this.props.currentUser.status === ProteusConstants.BESCHIKBAAR &&
                    <>
                        <Button className="FreeTime_available" onClick={async () => await this.createMilestoneRegistration()}>{_msg("FreeTimeScreen.becomeUnavailableIndefinitely")}</Button>
                        <Button className="FreeTime_available" onClick={() => { this.openForm() }}>{_msg("FreeTimeScreen.becomeUnavailableInterval")}</Button>
                    </>}
                {this.props.currentUser.status === ProteusConstants.ONBESCHIKBAAR &&
                    <>
                        <Button className="FreeTime_unavailable" onClick={async () => await this.createMilestoneRegistration()}>{!this.props.currentUser.unavailableForFreeTaskRegistration?.toDate ?
                            _msg("FreeTimeScreen.unavailableFrom", moment(new Date(this.props.currentUser.fromDate)).format(DATE_FORMAT)) :
                            _msg("FreeTimeScreen.unavailableFromTo", moment(new Date(this.props.currentUser.fromDate)).format(DATE_FORMAT),
                                moment(new Date(this.props.currentUser.unavailableForFreeTaskRegistration.toDate)).format(DATE_FORMAT))}</Button>
                    </>}
            </Segment>
        );
    }
}

export const infoFreeTime = new ConnectedPageInfo(sliceFreeTime, FreeTime, "FreeTime",);
infoFreeTime.routeProps = { permission: "FREE_TIME_SCREEN_VIEW" };