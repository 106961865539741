import { EntityDescriptor } from "@crispico/foundation-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { fieldEditors, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import _ from "lodash";
import { Button, Form, Grid, Header, Icon, Input, Segment } from "semantic-ui-react";
import { CompetenceTreeRRC } from "./CompetenceTree";
import { FieldEditor, FieldEditorNotUsableStandAloneProps } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";

export class CompetenceCategoryDescriptor extends EntityDescriptor {

    protected customize() {
        let competenceCategoryDescriptor = this;
        competenceCategoryDescriptor.addFieldDescriptor({ name: "parent", type: "DropdownParent" });
        fieldRenderers["DropdownParent"] = undefined;
        fieldEditors["DropdownParent"] = class extends FieldEditor<any, FieldEditorNotUsableStandAloneProps, {
            isOpen: boolean,
            parentName: string,
            parentNameSelected: string,
            newParentComponent: any,
            parentId: string[]
        }>{
            constructor(props: FieldEditorNotUsableStandAloneProps | Readonly<FieldEditorNotUsableStandAloneProps>) {
                super(props);

                this.state = {
                    isOpen: false,
                    newParentComponent: undefined,
                    parentId: props.formikProps.values.competenceCategory.competenceCategoryParentId,
                    parentName: props.formikProps.values.competenceCategory.parentName,
                    parentNameSelected: props.formikProps.values.competenceCategory.parentName,
                };
                this.changeParentName = this.changeParentName.bind(this);
            }

            changeParentName(parentName: string, parentId: string[], newParentComponent: any) {
                this.setState({
                    parentName: parentName,
                    newParentComponent: newParentComponent,
                    parentId: parentId
                });
            }

            changeParentNameSelected() {
                if (this.state.parentId.length !== 0) {
                    this.setState({ parentNameSelected: this.state.parentName });
                    this.props.formikProps.values.changeParentId(this.state.parentId, this.state.newParentComponent);
                }
                this.setState({ isOpen: false });
            }

            render() {
                if (_.isEmpty(this.props.formikProps.values)) {
                    return <></>;
                }
                return <>
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column className="CompetenceCategoryDescriptor_input">
                                    <Input readOnly
                                        value={this.state.parentNameSelected}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    {/* We need to set button type because, being in a form, default is submit and
                                    when we would try to click on it, the page will be rerendered */}
                                    <Button type="button" icon="edit" primary className="CompetenceCategoryDescriptor_button"
                                        onClick={() => this.setState({ isOpen: !this.state.isOpen })} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {this.state.isOpen ? <ModalExt open={this.state.isOpen}
                            onClose={() => this.setState({ isOpen: false })} competenceCategoryDescriptorModal={"true"} >
                            <Header icon='sitemap' content={_msg("CompetenceCategoryEditor.parent.label")} />
                            <Segment className="CompetenceCategoryDescriptor_header">
                                <Button primary onClick={() => this.changeParentNameSelected()}>
                                    <Icon name="save" />
                                    {_msg("general.save")}
                                </Button>
                                <Button onClick={() => this.changeParentName(_msg("CompetenceCategoryEditor.root.label"), [""], null)}>
                                    <Icon name="delete" />
                                    {_msg("CompetenceCategoryEditor.root.label")}
                                </Button>
                            </Segment>
                            <CompetenceTreeRRC
                                id="CompetenceCategoriesTreeRRC" root={this.props.formikProps.values.treeRoot}
                                searchExpression="" onSelectCategory={this.changeParentName}
                                categoryToBeHidden={this.props.formikProps.values.categoryToBeHidden}
                                minimizeTree selectedComponentId={this.state.parentId.join("|/|")}
                            />
                        </ModalExt> : null}
                        <Form.Input label={_msg("CompetenceCategoryEditor.name.label")}
                            defaultValue={this.props.formikProps.values.competenceCategory.name}
                            onChange={(e, data) => { this.props.formikProps.values.changeEditorState(data.value, "editorCompetenceCategoryName") }}
                        />
                        <Form.Input label={_msg("CompetenceCategoryEditor.description.label")}
                            defaultValue={this.props.formikProps.values.competenceCategory.description}
                            onChange={(e, data) => this.props.formikProps.values.changeEditorState(data.value, "editorCompetenceCategoryDescription")}
                        />
                    </Form>
                </>
            }
        }
    }
}