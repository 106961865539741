'use strict'

/*@ngInject*/
function Common(DataSource) {
	
	var config = {
		baseUrl: '/restful/commonServiceEndpoint',
	}
	
	var ds = new DataSource(config);

	ds.config.getParameterValue = ds.config.getParameterValue || {};

	ds.config.getParameterValue.method = 'POST';
	ds.config.getParameterValue.url = '/getParameterValue';

	if (!ds.getParameterValue) {
		ds.registerOperation('getParameterValue');
	}

	ds.config.getCalendarEvents = ds.config.getCalendarEvents || {};

	ds.config.getCalendarEvents.method = 'POST';
	ds.config.getCalendarEvents.url = '/getCalendarEvents';

	if (!ds.getCalendarEvents) {
		ds.registerOperation('getCalendarEvents');
	}
	
	ds.config.getLogoutParameter = ds.config.getLogoutParameter || {};

	ds.config.getLogoutParameter.method = 'POST';
	ds.config.getLogoutParameter.url = '/logoutParameters';

	if (!ds.getLogoutParameter) {
		ds.registerOperation('getLogoutParameter');
	}

	ds.config.isProdValue = ds.config.isProdValue || {};

	ds.config.isProdValue.method = 'POST';
	ds.config.isProdValue.url = '/isProdValue';

	if (!ds.isProdValue) {
		ds.registerOperation('isProdValue');
	}

	return ds;
};

module.exports = Common;