'use strict';

/*@ngInject*/
function Catalog(DataSource) {
	
	var config = {
			baseUrl : '/restful/catalogService'
	};
	var ds = new DataSource(config);
	
	ds.config.getComponent = ds.config.getComponent || {};
	ds.config.getComponent.method = 'GET';
	ds.config.getComponent.url = '/getComponent';
	if (!ds.getObjectiveStatuses) {
		ds.registerOperation('getComponent');
	}

	return ds;
}

module.exports = Catalog;