import { apolloClient, ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { Drawer } from "antd";
import { getPersons_groupServiceFacadeBean_persons } from "apollo-gen/getPersons";
import { BalanceCalculation, BalanceType, sliceBalanceCalculation } from "components/balanceCalculation/BalanceCalculation";
import { CALENDAR_SERVICE_GET_CALENDAR_EVENTS, CONSTRAINT_GROUPS_SERVICE_GET_GROUPS_OVERVIEW, EMPLOYEE_AGENDA_SERVICE_GET_AGENDA_DATA_FOR_EMPLOYEE, GROUP_SERVICE_FACADE_BEAN_GET_PERSONS, PLANNING_SERVICE_ENDPOINT_IS_CONSTRAINED_REGISTRATION_TYPE, PLANNING_SERVICE_FACADE_BEAN_GET_SELF_SERVICE_REGISTRATION_TYPES, REGISTRATION_METADATA_SERVICE_ENDPOINT_BEAN_GET_METADATA_PROPERTY_VALUE_FOR_TYPES, RESOURCES_SERVICE_ENDPOINT_GET_SELECTABLE_LEAVE_GROUPS_DATA, RESOURCES_SERVICE_FACADE_BEAN_GET_CONSTRAINED_REGISTRATION_TYPES } from "graphql/queries";
import _ from "lodash";
import moment, { Moment } from "moment";
import { CalendarEvent } from "pages/planningPage/planningGantt/PlanningGantt";
import { ExchangeDetail, Registration } from "pages/registrationEditor/RegistrationEditor";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import { Button, Dropdown, Form, Icon, Modal } from "semantic-ui-react";
import { AgendaGrid, sliceAgendaGrid } from "./AgendaGrid";
import { AgendaRegistrations, Balance, BalanceData, BalanceItem, BasicFields, ConstraintGroup, ConstraintGroupData, ConstraintGroupsOverview, DataForEmployee, GroupSnapshot, RegistrationTypeOption } from "./AgendaTypes";
import { AGENDA_MODE, CONSTRAINED, METADATA_PROPERTIES, LEAVE_CALENDAR, INITIAL, LEAVE, _RESULT, RESULT, WHITE, BLACK } from "./AgendaUtils";
import { ResourcesInfo } from "./ResourcesInfo";
import { UiApiHelper } from "@famiprog-foundation/tests-are-demo/dist-lib/lib/uiApi/UiApiHelper";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { ScriptableContainer, Scriptable } from "@famiprog-foundation/scriptable-ui";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { DatePicker } from "antd";

export const sliceAgenda = createSliceFoundation(class SliceAgenda {

    initialState = {
        /**
         * List of registration types that the user is allowed to create in agenda (based on user roles and metadata)
         */
        registrationTypesOptions: [] as RegistrationTypeOption[],
        /**
         * List of registration types that the user is allowed to create in agenda (based on user roles and metadata)
         */
        constrainedRegistrationTypesOptions: [] as RegistrationTypeOption[],
        /**
         * The current selected registration type in the dropdown; if you create a new registration, then it will use
         * this type. 
         */
        selectedRegistrationType: undefined as RegistrationTypeOption | undefined,
        /**
         * Map with metadata for the registration types used/loaded.
         */
        registrationTypesMetadata: {} as { [key: string]: { [key: string]: any } },
        /**
         * Date for which to load agenda data.
         */
        agendaDate: new Date().toISOString() as string,
        /**
         * Contains constraint data. Loaded only for constrained registration types.
         */
        constraintGroupData: undefined as ConstraintGroupData | undefined,
        /**
         * The current selected constrained group in the dropdown. Info like day occupancy or group membership varies depending on it.
         */
        selectedGroup: undefined as GroupSnapshot | undefined,
        groupDropdownDisabled: false as boolean,
        /**
         * Id of the resource logged in
         */
        resource: null as number | null,
        mode: AGENDA_MODE.MODE_MONTH as AGENDA_MODE,
        /**
         * Constraint groups dropdown rendered if selected registration type is constratined (metadata property CONSTRAINED)
         */
        showConstraints: true,
        /**
         * Employee's balance
         */
        daysBalance: null as BalanceData | null,
        hoursBalance: null as BalanceData | null,
        emptyInitialBalance: false,
        useOtherAgendaContext: false,
        onlyWaitingRegistrations: false,
        balanceTableDrawerOpen: false,
        agendaData: null as DataForEmployee[] | null,
        constraintGroupsData: null as ConstraintGroupsOverview | null,
        constraintGroupModalOpen: false,
        isConstrainedRegistrationForUser: false,
        contraintGroupsOpenCompartments: null as ConstraintGroup[] | null,
        /**
         * Name and Telecom information about the employees of the selected group
         */
        groupInfo: null as getPersons_groupServiceFacadeBean_persons[] | null,
        /**
         * Map with the days that are national holidays.
         */
        calendarEvents: null as { [key: string]: boolean } | null,
    }

    nestedSlices = {
        agendaGrid: sliceAgendaGrid,
        balanceCalculation: sliceBalanceCalculation
    }

    reducers = {
        ...getBaseReducers<SliceAgenda>(this),

        /**
         * An empty group is added in two cases:
         * 1. there is no preselected group for the user
         * 2. the user is allowed to create a registration without a group
         */
        addEmptyGroup(state: StateFrom<SliceAgenda>) {
            const emptyGroup = {
                code: null,
                name: _msg("Agenda.constraintGroups.placeholder"),
                key: _msg("Agenda.constraintGroups.placeholder"),
                text: _msg("Agenda.constraintGroups.placeholder"),
                value: _msg("Agenda.constraintGroups.placeholder")
            } as GroupSnapshot;
            state.constraintGroupData?.leaveGroupSnapshotsForReact?.push(emptyGroup);
            return emptyGroup;
        }
    }

    impures = {
        ...getBaseImpures<SliceAgenda>(this),

        getDefaultRegistrationType() {
            let defaultValue: RegistrationTypeOption;
            for (const option of this.getState().registrationTypesOptions) {
                if (option.code === ProteusConstants.LEAVE_TYPE_CODE) {
                    defaultValue = option;
                    break;
                }
                defaultValue = option;
            }
            this.registrationTypeChanged(defaultValue!.code);
        },

        async getRegistrationTypes() {
            const registrationTypes = (await apolloClient.query({ query: PLANNING_SERVICE_FACADE_BEAN_GET_SELF_SERVICE_REGISTRATION_TYPES, variables: { context: ProteusConstants.AGENDA } })).data.planningServiceFacadeBean_selfServiceRegistrationTypes;
            this.getDispatchers().setInReduxState({
                registrationTypesOptions: registrationTypes.map((item: BasicFields) => ({
                    ...item,
                    key: item.code as string,
                    text: item.name as string,
                    value: item.code as string,
                    description: undefined
                }))
            });
            await this.getRegistrationTypeMetadata();
            if (registrationTypes.length === 0) {
                Utils.showGlobalAlert({ message: _msg('Agenda.loading.noRegistrationTypes'), severity: Severity.ERROR });
                return;
            }
            this.getDefaultRegistrationType();
        },

        async registrationTypeChanged(selectedRegistrationType: string) {
            const newType = this.getState().registrationTypesOptions.filter(option => option.code === selectedRegistrationType)[0];
            if (!newType) {
                return;
            }
            this.getDispatchers().setInReduxState({ selectedRegistrationType: newType });
            if (this.getState().registrationTypesMetadata[newType.code][CONSTRAINED]) {
                await this.getConstraintGroups(this.getState().mode !== AGENDA_MODE.MODE_OPEN_COMPARTMENTS);
            }
            await this.refresh(true, false, false, this.getState().mode !== AGENDA_MODE.MODE_OPEN_COMPARTMENTS);
        },

        async getRegistrationTypeMetadata() {
            const result = (await apolloClient.query({
                query: REGISTRATION_METADATA_SERVICE_ENDPOINT_BEAN_GET_METADATA_PROPERTY_VALUE_FOR_TYPES,
                variables: {
                    registrationTypes: this.getState().registrationTypesOptions.map(type => type.code),
                    context: ProteusConstants.AGENDA,
                    propertyNames: METADATA_PROPERTIES
                }
            })).data.registrationMetadataServiceEndpoint_metadataPropertyValueForTypes;
            this.getDispatchers().setInReduxState({
                registrationTypesMetadata: result
            });
        },

        getConstraintGroupsParams() {
            if (this.getState().agendaDate) {
                return {
                    resource: ProteusUtils.getCurrentUser().id,
                    fromDateString: moment(this.getState().agendaDate).startOf("month").format(Utils.dateTimeFormat),
                    toDateString: moment(this.getState().agendaDate).endOf("month").format(Utils.dateTimeFormat),
                    registrationTypeCode: this.getState().selectedRegistrationType?.code
                }
            }
            return null;
        },

        async getConstraintGroups(loadConstrainedRegistrationTypes: boolean = true) {
            const params = this.getConstraintGroupsParams();
            if (!params) { return; }
            const constraintData: ConstraintGroupData = (await apolloClient.query({
                query: RESOURCES_SERVICE_ENDPOINT_GET_SELECTABLE_LEAVE_GROUPS_DATA,
                variables: params
            })).data.resourcesServiceEndpoint_selectableLeaveGroupsData;

            if (!constraintData) {
                return;
            }
            this.isConstrainedRegistrationTypeForUser(constraintData.leaveGroupSnapshotsForReact);

            constraintData.leaveGroupSnapshotsForReact = constraintData.leaveGroupSnapshotsForReact?.map(group => {
                return {
                    ...group,
                    key: group.code,
                    text: group.name,
                    value: group.code
                } as GroupSnapshot;
            });

            if (this.getState().mode === AGENDA_MODE.MODE_OPEN_COMPARTMENTS) {
                constraintData.preselectedGroupSnapshotCode = this.getState().constraintGroupData?.preselectedGroupSnapshotCode;
            }
            this.getDispatchers().setInReduxState({ constraintGroupData: constraintData });
            if (constraintData.allowNull) {
                this.getDispatchers().addEmptyGroup();
            }
            this.preselectGroup();

            if (this.getState().mode === AGENDA_MODE.MODE_OPEN_COMPARTMENTS) {
                this.getConstraintGroupsOpenCompartmentsMode();
                loadConstrainedRegistrationTypes && this.loadConstrainedRegistrationTypes();
            }
        },

        async isConstrainedRegistrationTypeForUser(leaveGroups: any) {
            leaveGroups?.forEach((group: { __typename: any; }) => delete group.__typename);
            const result = (await apolloClient.query({
                query: PLANNING_SERVICE_ENDPOINT_IS_CONSTRAINED_REGISTRATION_TYPE,
                variables: {
                    parameter: {
                        user: ProteusUtils.getCurrentUser().id!,
                        fromDate: moment(this.getState().agendaDate).startOf("month"),
                        toDate: moment(this.getState().agendaDate).endOf("month"),
                        registrationTypeCode: this.getState().selectedRegistrationType?.code,
                        leaveGroups
                    }
                }
            })).data.planningServiceEndpoint_constrainedRegistrationType;
            this.getDispatchers().setInReduxState({ isConstrainedRegistrationForUser: result });
        },

        async getConstraintGroupsOpenCompartmentsMode() {
            let groupCodes: string[] = [];
            this.getState().constraintGroupData?.leaveGroupSnapshotsForReact?.forEach(group => {
                if (group.code != null) {
                    groupCodes.push(group.code);
                }
            });

            if (groupCodes.length == 0) {
                return;
            }

            const overview: ConstraintGroupsOverview = (await apolloClient.query({
                query: CONSTRAINT_GROUPS_SERVICE_GET_GROUPS_OVERVIEW,
                variables: {
                    userId: this.getState().resource,
                    fromDate: moment(this.getState().agendaDate).startOf("month"),
                    selectableGroupCodes: groupCodes,
                    registrationType: this.getState().selectedRegistrationType?.code
                }
            })).data.constraintGroupsService_constraintGroupsOverview;

            const constraintGroupsData = this.processConstraintGroupsData(overview);
            this.getDispatchers().setInReduxState({ contraintGroupsOpenCompartments: constraintGroupsData });
        },

        loadConstrainedRegistrationTypes() {
            // While waiting for the constrainedRegistrationTypes, make current selected registrationType the only option in dropdown
            this.getDispatchers().setInReduxState({ constrainedRegistrationTypesOptions: [this.getState().selectedRegistrationType!] })

            // Load constrained registrationTypes in background
            apolloClient.query({
                query: RESOURCES_SERVICE_FACADE_BEAN_GET_CONSTRAINED_REGISTRATION_TYPES,
                variables: {
                    resource: ProteusUtils.getCurrentUser().id,
                    fromDate: moment(this.getState().agendaDate).startOf("month").toDate(),
                    toDate: moment(this.getState().agendaDate).endOf("month").toDate(),
                    registrationTypeCodes: this.getState().registrationTypesOptions.map(type => type.code)
                },
                context: { showSpinner: false }
            }).then((result: any) => {
                const registrationTypes = result.data.resourcesServiceFacadeBean_constrainedRegistrationTypes;
                const newRegistrationTypesOptions = this.getState().registrationTypesOptions.filter(type => registrationTypes.includes(type.code));
                this.getDispatchers().setInReduxState({ constrainedRegistrationTypesOptions: newRegistrationTypesOptions });
            });
        },

        processConstraintGroupsData(overview: ConstraintGroupsOverview) {
            let constraintGroupsData: ConstraintGroup[] = [];
            for (let i = 0; i < overview.workPeriods.length; i++) {
                let groupStatuses = [];
                const workPeriod = overview.workPeriods[i];
                for (let j = 0; j < overview.groupsConstraints.length; j++) {
                    const constraintGroupStatus = overview.groupsConstraints[j];
                    const status = {
                        group: constraintGroupStatus.group,
                        status: constraintGroupStatus.status[i]
                    };
                    groupStatuses.push(status);
                }
                constraintGroupsData.push({
                    start: workPeriod.fromDate,
                    workPeriod: workPeriod,
                    groupStatuses: groupStatuses,
                    workRosterType: workPeriod.workRosterType,
                    agendaRegistrations: []
                });
            }
            return constraintGroupsData;
        },

        groupChanged(groupCode: string) {
            const group = this.getState().constraintGroupData?.leaveGroupSnapshotsForReact?.find(group => groupCode === group.code);
            this.getDispatchers().setInReduxState({ selectedGroup: group });
            this.refresh(false, false, false);
        },

        preselectGroup() {
            let preselectedGroupCode = this.getState().constraintGroupData?.preselectedGroupSnapshotCode;
            let preselectedGroup = this.getState().constraintGroupData?.leaveGroupSnapshotsForReact?.find(group => preselectedGroupCode === group.code);

            if (!preselectedGroup) {
                this.getDispatchers().setInReduxState({
                    constraintGroupData: {
                        ...this.getState().constraintGroupData,
                        allowNull: true
                    }
                });
                preselectedGroup = this.getDispatchers().addEmptyGroup();
                if (this.getState().groupDropdownDisabled && this.getState().constraintGroupData!.leaveGroupSnapshotsForReact!.length > 0) {
                    this.getDispatchers().setInReduxState({ groupDropdownDisabled: false });
                }
            }
            this.doSelectGroup(preselectedGroup);
        },

        doSelectGroup(group: GroupSnapshot) {
            this.getDispatchers().setInReduxState({ selectedGroup: group });
        },

        async refresh(refreshBalance: boolean, refreshCalendarEvents: boolean, refreshMetadata: boolean, loadConstrainedRegistrationTypes: boolean = true) {
            if (!this.getState().resource || !this.getState().selectedGroup || !this.getState().selectedRegistrationType) {
                return;
            }

            refreshMetadata && await this.getRegistrationTypeMetadata();

            if (this.getState().mode === AGENDA_MODE.MODE_MONTH &&
                this.getState().selectedRegistrationType &&
                this.getState().registrationTypesMetadata[this.getState().selectedRegistrationType!.code][CONSTRAINED] && this.getState().selectedGroup) {
                this.getDispatchers().setInReduxState({ showConstraints: true });
            } else {
                this.getDispatchers().setInReduxState({ showConstraints: false });
            }

            if (this.getState().mode === AGENDA_MODE.MODE_OPEN_COMPARTMENTS) {
                this.getConstraintGroupsOpenCompartmentsMode();
                loadConstrainedRegistrationTypes && this.loadConstrainedRegistrationTypes();
            } else {
                this.refreshAgenda();
                refreshCalendarEvents && this.getCalendarEvents(LEAVE_CALENDAR, moment(this.getState().agendaDate).endOf("month"), moment(this.getState().agendaDate).startOf("month"));
            }

            refreshBalance && this.getBalance();
        },

        async getCalendarEvents(calendarDefinitionCode: string, tillDate: Date | Moment, fromDate: Date | Moment) {
            const events: CalendarEvent[] = (await apolloClient.query({
                query: CALENDAR_SERVICE_GET_CALENDAR_EVENTS,
                variables: {
                    calendarDefinitionCode: calendarDefinitionCode,
                    tillDate: tillDate,
                    fromDate: fromDate,
                }
            })).data.calendarService_calendarEvents;

            const calendarEvents = {} as { [key: string]: boolean };
            events.forEach(calendarEvent => {
                const date = new Date(calendarEvent.fromDate);
                calendarEvents[date.getDate()] = true;
            });
            this.getDispatchers().setInReduxState({ calendarEvents });
        },

        async refreshAgenda() {
            const selectedRegistrationTypeCode = this.getState().selectedRegistrationType!.code;
            //TODO
            // const waitingAgendaView = AppMetaTempGlobals.appMetaInstance.hasPermission(ProteusConstants.WAITING_AGENDA_VIEW, false);
            // if (waitingAgendaView && [ProteusConstants.LEAVE_TYPE_CODE, ProteusConstants.PARENTAL_LEAVE_TYPE_CODE, ProteusConstants.TIME_CREDIT_TYPE_CODE].includes(selectedRegistrationTypeCode)) {
            //     let waitingDays = "";
            //     try {
            //         const waitingBalance: Counter[] = (await apolloClient.query({
            //             query: PLANNING_SERVICE_FACADE_BEAN_GET_WAITING_BALANCE,
            //             variables: {
            //                 resource: this.getState().resource,
            //                 toDate: moment(this.getState().agendaDate).endOf("year"),
            //                 registrationTypeCode: selectedRegistrationTypeCode ? selectedRegistrationTypeCode : null
            //             }
            //         })).data.planningServiceFacadeBean_waitingBalance;
            //         let balanceString = "U hebt nog:";
            //         for (const counter of waitingBalance) {
            //             if (counter.value != 0) {
            //                 balanceString += " " + counter.value + " " + counter.description + ",";
            //             }
            //         }
            //         if (balanceString.endsWith(",")) {
            //             balanceString = balanceString.slice(0, -1);
            //         } else {
            //             balanceString += " 0";
            //         }
            //         waitingDays = balanceString;
            //     } catch (e: any) {
            //         waitingDays = "Er is een fout opgetreden bij het ophalen van het saldo";
            //     }
            // }

            const result: DataForEmployee[] = (await apolloClient.query({
                query: EMPLOYEE_AGENDA_SERVICE_GET_AGENDA_DATA_FOR_EMPLOYEE,
                variables: {
                    employeeId: this.getState().resource,
                    useOtherAgendaContext: this.getState().useOtherAgendaContext,
                    toDate: moment(this.getState().agendaDate).endOf("month"),
                    fromDate: moment(this.getState().agendaDate).startOf("month"),
                    registrationTypeCode: selectedRegistrationTypeCode ? selectedRegistrationTypeCode : null,
                    constraintGroupCode: this.getState().selectedGroup?.code ? this.getState().selectedGroup!.code : null,
                    onlyWaitingRegistrations: this.getState().onlyWaitingRegistrations,
                    leaveGroupSnapshotsByTimePeriod: this.getState().constraintGroupData?.leaveGroupSnapshotsByTimePeriod
                }
            })).data.employeeAgendaServiceImpl_dataForEmployee;
            let agendaData = this.getEntries(result);
            agendaData = this.adjustData(agendaData);
            this.getDispatchers().setInReduxState({ agendaData });
        },

        adjustData(agendaData: DataForEmployee[]) {
            for (let i = 0; i < agendaData.length; i++) {
                let orderedRegistrations: any[] = [];
                let registrations = [...agendaData[i].agendaRegistrations];
                if (registrations.length === 0 || i === 0) {
                    continue;
                }
                const prevRegistrationsType = agendaData[i - 1].agendaRegistrations.map(r => r?.registration?.type?.code);
                for (let registration of registrations) {
                    const index = prevRegistrationsType.indexOf(registration.registration.type?.code!);
                    if (index !== -1) {
                        orderedRegistrations[index] = registration;
                        registrations = registrations.filter(r => !_.isEqual(r, registration));
                    }
                }
                registrations.forEach(r => {
                    let filled = false;
                    for (let i = 0; i < orderedRegistrations.length; i++) {
                        if (!orderedRegistrations[i]) {
                            orderedRegistrations[i] = r;
                            filled = true;
                        }
                    }
                    if (!filled) {
                        orderedRegistrations.push(r);
                    }
                });
                agendaData[i].agendaRegistrations = orderedRegistrations;
            }
            return agendaData;
        },

        getEntries(registrations: DataForEmployee[]) {
            for (var i = 0; i < registrations.length; i++) {
                var agendaRegistrations = registrations[i].agendaRegistrations;
                for (var j = agendaRegistrations.length - 1; j >= 0; j--) {
                    var registration = agendaRegistrations[j].registration;
                    var startIdx = this.getIndex(registration.fromDate, registrations);
                    var endIdx = this.getIndex(registration.toDate, registrations);
                    var l = endIdx - startIdx;
                    if (l > 0 && i && i == endIdx) {
                        // last day => check if continuation of previous work period
                        if (i == this.getIndex(this.getWorkPeriodEnd(registrations[i - 1], registrations[i], registration), registrations)) {
                            // remove this registration
                            agendaRegistrations.splice(j, 1);
                        }
                    }
                }
            }
            return registrations;
        },

        getIndex(date: any, registrations?: any) {
            date = new Date(date);
            // convert to Date
            if (registrations != undefined && registrations != null) {
                for (let i = 0; i < registrations.length; i++) {
                    if (new Date(registrations[i].start) <= date && new Date(registrations[i].end) >= date) {
                        return i;
                    }
                }
            }
            return date.getDate() - 1;
        },

        getWorkPeriodEnd(entry: DataForEmployee, today: DataForEmployee, registration: Registration) {
            if (entry.workRosterType) {
                return entry.end;
            }
            if (!today.workRosterType && registration.type!.code === ProteusConstants.EXCHANGE_REGISTRATION_TYPE && registration.detail) {
                const detail = registration.detail as ExchangeDetail;
                if (detail.applicant) {
                    return detail.compensation.toDate;
                } else {
                    return detail.exchange.toDate;
                }
            }
            return NaN;
        },

        indexOfRegistration(registration: Registration, list: AgendaRegistrations[]) {
            for (var i = 0; i < list.length; i++) {
                var id = list[i].registration.id;
                if (registration.id === id) {
                    return i;
                }
            }
            return -1;
        },

        async getBalance() {
            if (!this.getState().selectedRegistrationType) {
                return;
            }

            const code = this.getState().selectedRegistrationType?.code!;
            this.getDispatchers().balanceCalculation.setInReduxState({ balanceToDate: moment(this.getState().agendaDate).endOf('year').toDate() });
            const categoryHistoryItem = ProteusUtils.getCurrentUser().categoryHistoryItem;
            let balanceType = code;
            if (categoryHistoryItem && (categoryHistoryItem.category.code === ProteusConstants.SAILORS_CATEGORY_CODE ||
                categoryHistoryItem.category.code === ProteusConstants.CAPTAINS_CATEGORY_CODE || categoryHistoryItem.category.code === ProteusConstants.DEKSMAN_CATEGORY_CODE || 
                categoryHistoryItem.category.code === ProteusConstants.ADMIN_CATEGORY_CODE || categoryHistoryItem.category.code === ProteusConstants.TECHNICAL_CATEGORY_CODE)) {
                balanceType += ProteusConstants.HOURS_BALANCE_SUFFIX;
            }
            await this.getDispatchers().balanceCalculation.loadBalanceTabData(this.getState().resource, code == ProteusConstants.OVERTIME_LEAVE ? ProteusConstants.LEAVE_HOURS_BALANCE_TYPE_CODE : balanceType, false, false);
            const daysBalance = this.processBalanceItems(this.getState().balanceCalculation.balanceData as BalanceItem[]);
            const hoursBalance = this.processBalanceItems(this.getState().balanceCalculation.balanceHoursData as BalanceItem[]);
            this.getDispatchers().setInReduxState({ daysBalance, hoursBalance });
        },

        processBalanceItems(balanceItems: BalanceItem[]) {
            let agendaBalance = {} as BalanceData;
            let balances = [] as Balance[];
            let emptyInitialBalance = false;
            let map = {} as { [key: string]: Balance };
            for (let i = 0; i < balanceItems.length; i++) {
                const balanceItem = balanceItems[i];
                let idx = balanceItem.code.lastIndexOf('_');
                idx < 0 && (idx = 0);
                const prefix = balanceItem.code.substring(0, idx);
                const suffix = balanceItem.code.substring(idx);

                if (suffix == INITIAL && prefix == LEAVE) {
                    emptyInitialBalance = true;
                    for (var j = 0; j < balanceItem.counters.length; j++) {
                        if (balanceItem.counters[j].value != 0) {
                            emptyInitialBalance = false;
                            break;
                        }
                    }
                    if (emptyInitialBalance) {
                        break;
                    }
                }

                let balance: Balance = map[prefix];
                if (!balance) {
                    map[prefix] = balance = {
                        prefix: prefix,
                        items: [],
                        headers: []
                    };
                    for (let j = 0; j < balanceItem.counters.length; j++) {
                        const counter = balanceItem.counters[j];
                        // none of the counters will have codes, so just stop now
                        if (!counter.code) {
                            break;
                        }
                        balance.headers.push(counter.description);
                    }
                    balances.push(balance);
                }
                balance.items.push(balanceItem);

                if (suffix == RESULT || suffix == _RESULT) {
                    let sum = 0;
                    for (let j = 0; j < balanceItem.counters.length; j++) {
                        sum += balanceItem.counters[j].value;
                    }
                    if (!prefix || this.getState().selectedRegistrationType?.code != ProteusConstants.LEAVE_TYPE_CODE) {
                        agendaBalance.result = sum;
                    } else {
                        agendaBalance[prefix.toLowerCase()] = sum;
                        if (isNaN(agendaBalance.shifts) && balance.headers.length) {
                            agendaBalance.shifts = sum;
                        }
                    }
                }
            }

            agendaBalance.items = balances;
            return agendaBalance;
        },

        async showBalance() {
            this.getDispatchers().setInReduxState({ balanceTableDrawerOpen: true });
        },

        async showConstraintGroup() {
            const selectedGroup = this.getState().mode === AGENDA_MODE.MODE_MONTH ? this.getState().selectedGroup : this.getState().agendaGrid.openCompartmentsSelectedGroup;
            if (!selectedGroup?.code) {
                return;
            }
            const groupInfo: getPersons_groupServiceFacadeBean_persons[] = (await apolloClient.query({
                query: GROUP_SERVICE_FACADE_BEAN_GET_PERSONS,
                variables: {
                    toDate: moment(this.getState().agendaDate).endOf("month"),
                    fromDate: moment(this.getState().agendaDate).startOf("month"),
                    groupCode: selectedGroup?.code,
                }
            })).data.groupServiceFacadeBean_persons;
            this.getDispatchers().setInReduxState({ constraintGroupModalOpen: true, groupInfo });
        }
    }
});

type UiApiAgenda = Agenda["apiImplementation"];

export const uiApiAgenda = UiApiHelper.INSTANCE.create<UiApiAgenda>("Agenda");

type Props = PropsFrom<typeof sliceAgenda>;
export class Agenda<T extends Props = Props> extends TabbedPage<T> /* implements UiApiAgenda */ {

    protected api = uiApiAgenda();

    protected apiImplementation = {
        onChangeRegistrationType: (selectedRegistrationType: string): void => {
            this.props.dispatchers.registrationTypeChanged(selectedRegistrationType)
        },
        onRefresh: () => this.props.dispatchers.refresh(true, true, true),
    }

    constructor(props: T) {
        super(props);
        props.dispatchers.setInReduxState({ resource: ProteusUtils.getCurrentUser().id })
        props.dispatchers.getRegistrationTypes();
        props.dispatchers.getCalendarEvents(LEAVE_CALENDAR, moment(this.props.agendaDate).endOf("month"), moment(props.agendaDate).startOf("month"));
    }

    async onDateChange(date: Date | undefined) {
        if (!date) { return; }
        if (this.props.agendaGrid.monthPlayButtonPressedStart && date.getMonth() < this.props.agendaGrid.monthPlayButtonPressedStart) {
            this.props.dispatchers.agendaGrid.resetNewRegistrationData();
        }
        this.props.dispatchers.setInReduxState({ agendaDate: date.toISOString() });
        if (this.props.selectedRegistrationType?.code && this.props.registrationTypesMetadata[this.props.selectedRegistrationType.code][CONSTRAINED]) {
            await this.props.dispatchers.getConstraintGroups();
        }
        this.props.dispatchers.refreshAgenda();
        this.props.dispatchers.getCalendarEvents(LEAVE_CALENDAR, moment(this.props.agendaDate).endOf("month"), moment(this.props.agendaDate).startOf("month"));
    }

    getAgendaStyle() {
        if (!this.props.selectedRegistrationType || !this.props.registrationTypesMetadata[this.props.selectedRegistrationType.code]) {
            return;
        }
        const color = this.props.registrationTypesMetadata[this.props.selectedRegistrationType!.code][ProteusConstants.BACKGROUND_COLOR];
        if (color === BLACK) {
            return { backgroundColor: color, boxShadow: '1px 1px 4px rgba(255, 255, 255, .4)' }
        }
        return { backgroundColor: color, boxShadow: '1px 1px 4px rgba(0, 0, 0, .4)' }
    }

    getRegistrationTypeColorClassName() {
        if (!this.props.selectedRegistrationType || !this.props.registrationTypesMetadata[this.props.selectedRegistrationType.code]) {
            return '';
        }
        const color = this.props.registrationTypesMetadata[this.props.selectedRegistrationType!.code][ProteusConstants.BACKGROUND_COLOR];
        const contrastingColorAsHex = Utils.convertColorToHex(Utils.getContrastingForegroundColor(Utils.convertColorFromHex(color)));
        return contrastingColorAsHex.toLowerCase() === WHITE ? 'Agenda_typesDropdownWhite' : 'Agenda_typesDropdownBlack';
    }

    hasBalance() {
        const daysBalance = this.props.daysBalance;
        const hoursBalance = this.props.hoursBalance;
        return (daysBalance && daysBalance.items.length > 0 && !this.props.emptyInitialBalance) || (hoursBalance && hoursBalance.items.length > 0) || this.props.selectedRegistrationType?.code == ProteusConstants.OVERTIME_LEAVE;
    }

    renderBalanceBadge() {
        const categoryCode = ProteusUtils.getCurrentUser().categoryHistoryItem?.category?.code;
        if (ProteusConstants.BOOTMAN !== categoryCode || ProteusUtils.checkPermission('ALLOW_NON_ZERO_BALANCE')) {
            return;
        }

        let color = '';
        if (!!this.props.daysBalance && this.props.daysBalance.items?.length > 0) {
            const balance = this.props.daysBalance;
            color = this.getBadgeColor(balance);
            if (balance?.shifts == 0 && balance?.settlement > 0) {
                color = 'Agenda_balanceBadgeWarn';
            }
        }
        if ((!!this.props.hoursBalance && this.props.hoursBalance.items?.length > 0) && (color === '' || color === 'Agenda_balanceBadgeOk')) {
            const balance = this.props.hoursBalance;
            color = this.getBadgeColor(balance);
        }

        return <Icon circular size="small" name={color === 'Agenda_balanceBadgeOk' ? 'check' : 'exclamation'} className={"Agenda_balanceBadge " + color} />
    }

    protected getBadgeColor(balance: BalanceData | null) {
        return balance?.result === 0 || balance?.leave === 0  ? 'Agenda_balanceBadgeOk' : 'Agenda_balanceBadgeError';
    }

    protected renderButtonsSegment() {
        const agendaStyle = this.getAgendaStyle();
        const registrationTypeColorClassName = this.getRegistrationTypeColorClassName();
        const typesDropdownClassName = 'Agenda_typesDropdown ' + registrationTypeColorClassName;
        const registrationTypesOptions = this.props.mode === AGENDA_MODE.MODE_MONTH ? this.props.registrationTypesOptions : this.props.constrainedRegistrationTypesOptions;
        return (
            <div className="Agenda_buttonsSegment" style={agendaStyle}>
                <Dropdown data-testid={this.api.testids.onChangeRegistrationType} search={false} className={typesDropdownClassName} item button selection options={registrationTypesOptions} value={this.props.selectedRegistrationType?.code} onChange={(e: any, data: any) => this.api.dispatcher.onChangeRegistrationType(data.value) } style={agendaStyle} />
                <Button data-testid={this.api.testids.onRefresh} primary size="big" className={registrationTypeColorClassName} icon='refresh' style={agendaStyle} onClick={() => this.api.dispatcher.onRefresh()} floated='right' />
                {this.hasBalance() &&
                    <Button floated='right' className={registrationTypeColorClassName} primary size="big" icon={
                        <Icon.Group>
                            <Icon name='balance' />
                            {this.renderBalanceBadge()}
                        </Icon.Group>} style={agendaStyle} onClick={() => this.props.dispatchers.showBalance()}>
                    </Button>}
                {this.renderBalanceTable()}
            </div>);
    }

    protected renderTableHeaderSegment() {
        const selectedRegistrationMetadata = this.props.selectedRegistrationType ? this.props.registrationTypesMetadata[this.props.selectedRegistrationType.code] : undefined;
        return (<div className="Agenda_tableHeaderSegment">
            <Form className="Agenda_datePicker">
                {/* TODO #35440  - replaced because the focus causes issues on iOS, modify after fix */}
                <DatePicker value={moment(this.props.agendaDate)} format={"MM/YYYY"} onChange={d =>this.onDateChange(d?.toDate())} picker="month"/>
                {/* <DatePickerFieldEditor format="MM/YYYY" scriptableUiId="datePicker" value={moment(this.props.agendaDate).toISOString()} view='year' onChangeValue={(date: string) => this.onDateChange(new Date(date))} /> */}
            </Form>
            {this.props.showConstraints && <div className="Agenda_constraintGroups">
                <Button.Group className="Agenda_groupButtons">
                    <Dropdown className="Agenda_groupDropdown" basic disabled={this.props.groupDropdownDisabled || this.props.constraintGroupData?.disableDropDown} button options={this.props.constraintGroupData?.leaveGroupSnapshotsForReact} value={this.props.selectedGroup?.value} onChange={(e: any, data: any) => this.props.dispatchers.groupChanged(data.value)} />
                    <Button className="Agenda_groupsButton" disabled={!this.props.selectedGroup?.code} primary icon="group" onClick={() => this.props.dispatchers.showConstraintGroup()} />
                </Button.Group>
                {!this.props.constraintGroupData?.disableDropDown && selectedRegistrationMetadata && selectedRegistrationMetadata[CONSTRAINED] && this.props.isConstrainedRegistrationForUser && <Button className="Agenda_openCompartmentsButton" primary icon="grid layout" onClick={() => {
                    this.props.dispatchers.setInReduxState({ mode: AGENDA_MODE.MODE_OPEN_COMPARTMENTS });
                    this.props.dispatchers.refresh(true, false, false);
                }} />}
            </div>}
        </div>);
    }

    protected renderTableHeaderSegmentOpenCompartments() {
        return (<div className="Agenda_tableHeaderSegment">
            <Button primary icon='arrow left' onClick={() => {
                this.props.dispatchers.setInReduxState({ mode: AGENDA_MODE.MODE_MONTH });
                this.props.dispatchers.refresh(true, false, false);
            }} />
            <Button primary icon='angle left' onClick={() => this.onDateChange(moment(this.props.agendaDate).subtract(1, 'month').toDate())}></Button>
            <Form className="Agenda_datePicker">
                <DatePickerReactCalendar format="MM/YYYY" value={moment(this.props.agendaDate)} view='year' onChange={(date: Moment | null) => date === null ? null : this.onDateChange(date.toDate())} />
            </Form>
            <Button primary icon='angle right' onClick={() => this.onDateChange(moment(this.props.agendaDate).add(1, 'month').toDate())} />
        </div>);
    }

    getBalanceTableHeaderContent(prefix: string) {
        if (prefix === LEAVE) {
            return _msg("RegistrationEditor.balanceTab.workperiod.label");
        } else if (prefix) {
            return _msg(`RegistrationEditor.balanceTab.${prefix.toLowerCase()}.label`);
        } else {
            return _msg("RegistrationEditor.balanceTab.closingBalance.label");
        }
    }

    renderBalanceTable() {
        if (!(this.props.hoursBalance || this.props.daysBalance) || !this.props.selectedRegistrationType) {
            return;
        }
        const balanceType: BalanceType = {
            code: this.props.selectedRegistrationType.code,
            description: this.props.selectedRegistrationType.name!
        }
        return (
            <Drawer className="AgendaDrawer" placement="right" visible={this.props.balanceTableDrawerOpen} closeIcon={<Button icon="close" className="less-padding" circular />} onClose={() => this.props.dispatchers.setInReduxState({ balanceTableDrawerOpen: false })}>
                {ProteusUtils.renderDrawerHeader(<><Icon name='balance' />{this.props.selectedRegistrationType?.text}</>)}
                <div className="Agenda_balanceTable">
                    <BalanceCalculation
                        {...this.props.balanceCalculation}
                        dispatchers={this.props.dispatchers.balanceCalculation}
                        employee={this.props.resource}
                        balanceType={balanceType}
                    />
                </div>
            </Drawer>
        );
    }

    onConstraintGroupModalClose() {
        this.props.dispatchers.setInReduxState({ constraintGroupModalOpen: false, groupInfo: undefined });
        this.props.dispatchers.agendaGrid.setInReduxState({ openCompartmentsSelectedGroup: undefined });
    }

    renderConstraintGroupModal() {
        if (!this.props.selectedGroup) {
            return;
        }
        return (
            <ModalExt className="Agenda_modal" addNiceLookingOffsets closeIcon centered={false} open={this.props.constraintGroupModalOpen} onClose={() => this.onConstraintGroupModalClose()}>
                <Modal.Header className="Agenda_modalHeader">
                    <Icon name='group' />
                    {_msg('Agenda.groupInfo.header', this.props.mode === AGENDA_MODE.MODE_MONTH ? this.props.selectedGroup?.name : this.props.agendaGrid.openCompartmentsSelectedGroup?.name)}
                </Modal.Header>
                <Modal.Content className="Agenda_groupResourcesModalContent">
                    <ResourcesInfo resources={this.props.groupInfo} embedded={false} />
                </Modal.Content>
            </ModalExt>
        );
    }

    protected renderMain() {
        const metadata = this.props.selectedRegistrationType && this.props.registrationTypesMetadata[this.props.selectedRegistrationType.code];
        return (
            <div className="AgendaPage">
                <this.api.Listener implementation={this.apiImplementation} />
                <div className="Agenda_contents">
                    {this.renderButtonsSegment()}
                    {this.props.mode === AGENDA_MODE.MODE_MONTH ? this.renderTableHeaderSegment() : this.renderTableHeaderSegmentOpenCompartments()}
                    {this.renderConstraintGroupModal()}
                    <AgendaGrid {...this.props.agendaGrid} dispatchers={this.props.dispatchers.agendaGrid} mode={this.props.mode} agendaDate={new Date(this.props.agendaDate)}
                        calendarEvents={this.props.calendarEvents} agendaData={this.props.agendaData} selectedRegistrationType={this.props.selectedRegistrationType}
                        selectedRegistrationTypeMetadata={metadata} resource={this.props.resource} selectedGroup={this.props.selectedGroup} refresh={() => {
                            this.props.dispatchers.refreshAgenda();
                            this.props.dispatchers.getBalance();
                        }}
                        currentMonth={new Date(this.props.agendaDate).getMonth()}
                        contraintGroupsOpenCompartments={this.props.contraintGroupsOpenCompartments}
                        showConstraintGroup={() => this.props.dispatchers.showConstraintGroup()} />
                </div>
            </div>
        );
    }
}

export const agenda = new ConnectedPageInfo(sliceAgenda, Agenda, "AgendaNew");
agenda.routeProps = { permission: "AGENDA_WEB_VIEW" };