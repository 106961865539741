import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import React from "react";

export const NAME_VERSION_SEPARATOR = '#';

export const childVersionEntityDescriptor = new EntityDescriptor({ name: "ChildVersion" })
    .addFieldDescriptor({ name: "name", type: FieldType.string }, new class extends FieldDescriptor {
        protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any) {
            return <div>{entity.name} <span className="text-muted">{entity.version}</span></div>;
        }
    }())
    .addFieldDescriptor({ name: "type", type: FieldType.string })
    .addFieldDescriptor({ name: "componentType", type: FieldType.string });

export class NameVersionRenderer extends React.Component<FieldRendererProps> {
    render(): React.ReactNode {
        const [name, version] = this.props.value.split(NAME_VERSION_SEPARATOR)
        return <React.Fragment>
            {name}
            <span className="CatalogItem_version text-muted">
                {version}
            </span>
        </React.Fragment>
    }
}

export const trainerCompetencesDescriptor = new EntityDescriptor({ name: "TrainerCompetences" })
    .addFieldDescriptor({ name: "name" }, new class extends FieldDescriptor {
        protected renderFieldInternal(EditorClass: any, props: FieldRendererProps) {
            return React.createElement(NameVersionRenderer as any, props as FieldRendererProps)
        }
    })
    .addFieldDescriptor({ name: "weight", type: FieldType.number })
    .addFieldDescriptor({ name: "required", type: FieldType.boolean });

export const trainerCertificatesDescriptor = new EntityDescriptor({ name: "TrainerCertificates" })
    .addFieldDescriptor({ name: "name", type: FieldType.string });

export const evaluationCriteriaDescriptor = new EntityDescriptor({ name: "EvaluationCriteria" })
    .addFieldDescriptor({ name: "name" }, new class extends FieldDescriptor {
        protected renderFieldInternal(EditorClass: any, props: FieldRendererProps) {
            return React.createElement(NameVersionRenderer as any, props as FieldRendererProps)
        }
    })
    .addFieldDescriptor({ name: "normPercentage", type: FieldType.number })
    .addFieldDescriptor({ name: "weight", type: FieldType.number })
    .addFieldDescriptor({ name: "required", type: FieldType.boolean })
    .addFieldDescriptor({ name: "ownScore", type: FieldType.boolean });