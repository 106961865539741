'use strict';

/*@ngInject*/
function balanceCountersTable() {

	return {
		restrict: 'E',
		template: require('./balance-counters-table.html'),
		scope: {
			balanceTypeCode: '@',
			balanceType: '<', // balanceTypeCode or balanceType are required
			resource: '@'
		},
		link: function($scope, elt, attrs) {
			
		},
		controller: /*@ngInject*/ function($scope, $q, Balance, ModalSpinner, FormModal, ErrorModal, Confirm) {
			var balanceCounterTable = $scope.balanceCounterTable;
			
			balanceCounterTable.gridOptions = {
					columnDefs: [
			             { 
			            	 name: '_fromDate', 		 					
			            	 displayName:'credit.balanceCounterHistoryItem.fromDate', 
			            	 cellTemplateUrl: '/credit/balance-counters-editor/validFrom.html',
			            	 field: 'validFrom',
			            	 sortable: true
			             },
			             { 
			            	 name: '_parameterName',	
			            	 field: 'counterType.description', 	
			            	 displayName:'credit.balanceCounterHistoryItem.parameterName',
			            	 sortable: true 
			             },
			             { 
			            	 name: '_value', 			
			            	 field: 'value', 					
			            	 displayName:'credit.balanceCounterHistoryItem.value',
			            	 sortable: true
			             },
			             { 
			            	 name: '_extraColumn',
			            	 headerTemplateUrl: '/credit/balance-counters-editor/addItemHeader.html',
			            	 cellTemplateUrl: '/credit/balance-counters-editor/editRemoveCell.html'
			             }
					],
					onRegisterApi: function(gridApi) {
						gridApi.core.sort(this.columnDefs[0], 'DESC');
					}
			};
			
			balanceCounterTable.addItem = function() {
				var newItem = {
						balanceType: $scope.balanceType.code,
						//counterType: balanceCounterTable.balanceCounterHistoryItems[0], // lets prepopulate it with some value
						person: $scope.resource,
						value: 0
				};
				balanceCounterTable.editItem(newItem);
			};
			
			balanceCounterTable.editItem = function(entity) {
				
				var instance = FormModal.open({
					scope: {
						model: entity,
						title: 'credit.balanceCounterHistoryItem.editorTitle',
						template: '<balance-counter-editor item="model"></balance-counter-editor>',
					},
					actions: ['ok', 'cancel', 'close']
				});
				instance.result.then(function(result) {
					Balance.saveOrUpdate({}, { 
						//silent: true,
						data: result 
					}).then(function(result){						 						
						var mutations = result.data;
						if (mutations.initialCreatedItem) {
							balanceCounterTable.balanceCounterHistoryItems.unshift(mutations.initialCreatedItem);
						}
						for (var i = 0; i < mutations.updatedItems.length; i++) {
							var itemResult = mutations.updatedItems[i];
							for (var j = 0; j < balanceCounterTable.balanceCounterHistoryItems.length; j++) {
								var dataSourceCounter = balanceCounterTable.balanceCounterHistoryItems[j];
								if (itemResult.id == dataSourceCounter.id) {
									angular.merge(dataSourceCounter, itemResult);
								}
							}
						}
						
					});
				});
			};
			
			balanceCounterTable.removeItem = function(entity) {
				
				var instance = Confirm.open({
					title: 'clear',
					message: 'credit.remove.confirm'
				});
				instance.result.then(function() {
					Balance.remove({}, {
						params: {
							id: entity.id
						}
					}).then(function(result) {
						var index = balanceCounterTable.balanceCounterHistoryItems.indexOf(entity);
					    balanceCounterTable.balanceCounterHistoryItems.splice(index, 1);
					});
				});
			};
			
			ModalSpinner.show('loading');			
			
			
			if (angular.isUndefined($scope.balanceType) && angular.isUndefined($scope.balanceTypeCode)) {
				throw "balanceType or balanceTypeCode must be defined";
			}
			
			var deferred = $q.defer();
			if (!angular.isUndefined($scope.balanceType)) {
				deferred.resolve($scope.balanceType);
			} else {			
				deferred.resolve(
					Balance.getBalanceTypeByCode({}, {
						params: { code: $scope.balanceTypeCode } 
					})
				);
			}
			
			var promise = deferred.promise;
			promise.then(function(result) {
				$scope.balanceType = result.data;
				
				return Balance.getByCriteria({}, {
					silent: true,
					data: { 
						filters: [ { name: 'balanceType', value: $scope.balanceType.code }, { name: 'person', value: $scope.resource }, { name: 'validFrom', sortOrder: 'DESC' }]
					}
				});
			}).then(function(result) {
				balanceCounterTable.balanceCounterHistoryItems = result.data;
			}).catch(function(reason) {
				ErrorModal.open({
					titleId: ErrorModal.ERROR,
					message: reason.data.message
				});
			}).finally(function() {
				balanceCounterTable.gridOptions.data = balanceCounterTable.balanceCounterHistoryItems;
				ModalSpinner.hide();
			});
		},
		controllerAs: 'balanceCounterTable'
	};
}

module.exports = balanceCountersTable;