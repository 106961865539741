'use strict';

/*@ngInject*/
function proteusGridCol($compile) {

	return {
		restrict: 'C',
		link: function($scope, $elt) {
			if ($scope.col && $scope.col.cellTemplate) {
				if ($scope.grid.api.grouping && $scope.row.grouping && $scope.col.emptyForGrouping) {
					return;
				}
				var compileFn = $compile($scope.col.cellTemplate);
				var cell = compileFn($scope);
				$elt.empty().append(cell);
			}
		}
	}
};

module.exports = proteusGridCol;