import { createSliceFoundation, EntityDescriptor, EntityEditorFormSimple, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { getEmployee_employeeService_employee } from "apollo-gen/getEmployee";
import React from "react";
import { CUSTOM_HISTORY_FIELD } from "./customFieldRenderersEditors";
import { EMPLOYEE_EDITOR } from "./EmployeeEditorBase";

export const FUNCTION_HISTORY = "FunctionHistory";
export const FUNCTION_HISTORY_FIELD = "functionHistory";
export const FUNCTION_SCRIPTABLE_UI_ID = "EmployeeEditor_FunctionTab";
export const EMPLOYEE_FUNCTION_FIELD = "employeeFunction";

export const sliceFunctionTab = createSliceFoundation(class SliceFunctionTab {

    initialState = {
        openedEmployeeFunctionId: undefined as unknown as number
    }

    reducers = {
        ...getBaseReducers<SliceFunctionTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceFunctionTab>(this)
    }
})

type PropsNotFromState = {
    employee: getEmployee_employeeService_employee | undefined | null;
    isEditable: boolean;
    onOpenTableLight: (fieldDescriptor: FieldDescriptor, entities: any[]) => void;
    onRemoveCustomHistoryField: (employeeFunctionId: number, keyField: string) => void;
    referenceDate: Date | number;
}

export class FunctionTab extends TabbedPage<PropsFrom<typeof sliceFunctionTab> & PropsNotFromState> {

    public onOpenCustomHistoryTable = (fieldDescriptor: FieldDescriptor, entities: any[], employeeFunctionId: number) => {
        this.props.onOpenTableLight(fieldDescriptor, entities);
        this.props.dispatchers.setInReduxState({ openedEmployeeFunctionId: employeeFunctionId });
    }

    public getFunctionsEntityDescriptor() {
        return new EntityDescriptor({ name: EMPLOYEE_EDITOR }, false)
            .addFieldDescriptor({
                name: "employee.detail.functionHistory",
                type: CUSTOM_HISTORY_FIELD,
                entityName: FUNCTION_HISTORY,
                mandatoryFields: ["employeeFunction"],
                icon: "briefcase",
                onOpenCustomHistoryTable: this.onOpenCustomHistoryTable,
                onRemoveCustomHistoryField: this.props.onRemoveCustomHistoryField,
                isDisabled: !this.props.isEditable,
                referenceDate: this.props.referenceDate
            });
    }

    render() {
        const displayFunctions = this.props.employee?.detail?.functionHistory
            && this.props.employee.detail.functionHistory.length > 0 ? true : false;
        return (
            <div className={displayFunctions ? "" : " FunctionTab_hide"}>
                <EntityEditorFormSimple scriptableUiId={FUNCTION_SCRIPTABLE_UI_ID} entityDescriptor={this.getFunctionsEntityDescriptor()}
                    hideButtonBar entity={{ employee: this.props.employee }} />
            </div>
        );
    }
}