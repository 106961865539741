'use strict';

/*@ngInject*/
function resourcePicker(ModalSpinner, DATE_TIME_FORMAT, $filter) {
	return {
		template : require('./resource-picker.html'),
		scope : {
			group : '=',
			owner : '=',
			date : '=',
			ngModelCtrl : '='
		},
		require : [ 'resourcePicker', '?^ngModel' ],
		link : function($scope, $element, $attrs, ctrls) {
			var ctrl = ctrls[0], ngModelCtrl = ctrls[1];
			if (ngModelCtrl) {
				$scope.ngModelCtrl = ngModelCtrl;
				ctrl.init();
			}
		},
		controller : /*@ngInject*/ function($scope, Resources, ResourcePickerConstants) {
			var ctrl = this;

			ctrl.init = function() {
				// add deserialization info to model
				$scope.ngModelCtrl.$parsers.push(function(viewValue) {
					if (angular.isObject(viewValue)) {
						viewValue.objectType = 'EmployeeSnapshot';
					}
					return viewValue;
				});
			}

			if ($scope.ngModelCtrl) {
				ctrl.init();
			}

			var treeConfig = {
				core : {
					multiple : false,
					expand_selected_onload : true,
					themes : {
						'variant' : 'large',
						'responsive' : true,
						'dots' : false
					}
				},
				types : {
					category : {
						icon : 'fa fa-tag'
					},
					team : {
						icon : 'fa fa-users'
					},
					location : {
						icon : 'fa fa-globe'
					},
					hourRegime : {
						icon : 'fa fa-calendar'
					},
					resource : {
						icon : 'fa fa-user'
					}
				},
				plugins : [ 'types' ]
			}

			/**
			 * Handler for tree selection => set model and render.
			 */
			$scope.nodeSelected = function(node, selected, event) {
				if ($scope.ngModelCtrl) {
					var resource = selected.node.original.resource;
					$scope.ngModelCtrl.$setViewValue(resource);
					$scope.ngModelCtrl.$render();
				}
			}

			/**
			 * Called on click on group header.
			 */
			$scope.getResources = function(group) {
				if (!group.treeConfig) {
					group.treeConfig = {
						version : 1
					};
					angular.copy(treeConfig, group.treeConfig);
				}
				if (!group.treeData) {
					var processData = function(result) {
						group.treeData = [];
						var map = {};

						var defaultText = {
							category : '(Geen categorie)',
							team : '(Geen ploeg)',
							location : '(Geen tewerkstellingsplaats)',
							hourRegime : '(Geen rooster)'
						}

						var push = function(data, type, parent) {
							var id = 0;
							if (data) {
								data = data[type];
								id = data.id;
							}
							var id = (parent ? parent + ':' : '') + type + '_'
									+ id;
							if (!map[id]) {
								group.treeData.push({
									id : id,
									parent : parent ? parent : '#',
									state : {
										opened : $scope.single,
										disabled : true
									},
									type : type,
									text : data ? data.description
											: defaultText[type]
								});
								map[id] = true;
							}
							return id;
						}

						angular.forEach(result.data, function(resource) {
							var category = push(resource.categoryHistoryItem, ResourcePickerConstants.CATEGORY);
							var team = push(resource.teamHistoryItem, ResourcePickerConstants.TEAM, category);
							var location = push(resource.locationHistoryItem, ResourcePickerConstants.LOCATION, team);
							var hourRegime = push(resource.hourRegimeHistoryItem, ResourcePickerConstants.HOUR_REGIME, location);

							// finally, the employee
							group.treeData.push({
								id : hourRegime + ':' + ResourcePickerConstants.RESOURCE + '_' + resource.id,
								parent : hourRegime,
								type : ResourcePickerConstants.RESOURCE,
								state : {
									opened : $scope.single
								},
								resource : resource,
								text : resource.completeName
							});
						});

						// trigger tree refresh
						group.treeConfig.version++;
					}

					Resources.getGroupResourceSnapshots(
							{},
							{
								params : {
									groupCode : group.code,
									fromDate : $filter('date')($scope.date, DATE_TIME_FORMAT)
								}
							}).then(processData);
				}
			};

			function checkIfSingle() {
				if ($scope.picker.data.length == 1) {
					$scope.single = true;
					$scope.getResources($scope.picker.data[0]);
				}
			}

			$scope.picker = {};

			if ($scope.group) {
				$scope.picker.data = [ $scope.group ];
				checkIfSingle();
			} else {
				// get groups data
				Resources
						.getGroups(
								$scope.picker,
								{
									params : {
										owner : $scope.owner,
										groupContextCode : ResourcePickerConstants.EMPLOYEE_EXPLORER
									}
								}).then(function(result) {
							checkIfSingle();
						});
			}
		}
	};
}

module.exports = resourcePicker;
