'use strict';

// Menu reorganization according to task #21975.
// Indexes 40 and 50 are used for reports and payroll.
// Deprecated. Use menu from React.

module.exports = {
	name: 'proteus.menu',
	navRoutes: [{
			index: 0, 
			icon: 'fa fa-users',
			label: 'index.nav.personnel',
			subRoutes :[
				{
					route: '#/BalanceCounterHistoryItem/browse',
					label: 'index.nav.balanceCounters',
					permissions: 'BALANCES_VIEW',
					index: 10
				}, {
					route: '#/credit',
					label: 'index.nav.credit',
					permissions: 'CREDIT_VIEW',
					index: 20
				}
			]
		}, {
			index: 10,
			route: '#/planning',
			label: 'index.nav.planning',
			icon: 'fa fa-calendar',
			permissions: 'PLANNING_VIEW'
		}, {
			index: 20,
			icon: 'fa fa-graduation-cap',
			label: 'index.nav.education',
			subRoutes: [
				{
					index: 10,
					route: '#/education/catalogs',
					label: 'index.nav.education.catalogs',
					permissions: 'EDUCATION_VIEW_CATALOG'
				}, {
					index: 20,
					route: '#/education/competences',
					label: 'index.nav.education.competences',
					permissions: 'EDUCATION_VIEW_DICTIONARY'
				}, {
					index: 30,
					route: '#/education/evaluationRequest',
					label: 'index.nav.education.evaluationRequest',
					permissions: 'EDUCATION_VIEW_EVALUATION_REQUEST'
				}, {
					index: 40,
					route: '#/education/curriculum',
					label: 'index.nav.curriculum',
					permissions: 'EDUCATION_CURRICULUM_VIEW'
				}, {
					index: 50,
					route: '#/education/groupRegistrations',
					label: 'index.nav.trainingSessions',
					permissions: 'EDUCATION_VIEW_TRAINING_SESSIONS'
				}
			]
		}, {
			index: 30,
			icon: 'fa fa-user',
			label: 'index.nav.employee',
			subRoutes: [
				{
					index: 10,
					route: '#/inboxItems',
					label: 'index.nav.inboxItems',
					permissions: 'MESSAGES_VIEW'
				}, {
					index: 20,
					route: '#/agenda',
					label: 'index.nav.agenda',
					permissions: 'AGENDA_VIEW'
				}, {
					index: 30,
					route: '#/other-agenda',
					label: 'index.nav.otherAgenda',
					permissions: 'OTHER_AGENDA_VIEW'
				}, {
					index: 40,
					route: '#/waiting-agenda',
					label: 'index.nav.waitingAgenda',
					permissions: 'WAITING_AGENDA_VIEW'
				}, {
					index: 50,
					route: '#/education/evaluationFillIn',
					label: 'index.nav.education.evaluationFillIn',
					permissions: 'EDUCATION_VIEW_EVALUATION_COMPLETION'
				}, {
					index: 60,
					route: '#/backgroundTasks',
					label: 'index.nav.backgroundTasks',
					permissions: 'BACKGROUND_TASKS_VIEW'
				}
			]
		}, {
			index: 60,
			label: 'index.nav.freeTime',
			icon: 'fa fa-clock-o',
			subRoutes: [
				{
					route: '#/free-time',
					label: 'index.nav.freeTimeScreen',
					permissions: 'FREE_TIME_SCREEN_VIEW',
					index: 10
				}, {
					route: '#/dispatchers',
					label: 'index.nav.freeTimeDispatchers',
					permissions: 'FREE_TIME_DISPATCHERS_SCREEN_VIEW',
					index: 20
				}
			]
		}, {
			index: 100,
			route: '#/occupancy',
			label: 'index.nav.occupancy',
			icon: 'fa fa-th-list',
			permissions: 'OPEN_VAKKEN_SCREEN_VIEW'
		}]
};