import { createSliceFoundation, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react";
import { EntityTableLight, PropsNotFromState, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import _ from "lodash";
import moment from "moment";

export const sliceHistoryTable = createSliceFoundation(class SliceHistoryTable {

    nestedSlices = {
        ...sliceEntityTableLight.nestedSlices
    }

    initialState = {
        ...sliceEntityTableLight.initialState,
        allowHoles: false,
        isHistoryTable: true,
        entities: [] as any[],
       	onOpen: undefined as any
    }

    reducers = {
        ...sliceEntityTableLight.reducers,
        ...getBaseReducers<SliceHistoryTable>(this),

        openFormLight(state: StateFrom<SliceHistoryTable>, params: { entity: any, rowIndex: number }) {
            if (!params.entity) {
                params.entity = {};
                params.entity.validFrom = moment().startOf("date").toDate();
            }
        },

        onAdd(firstTableRow: any, getDefaultValuesForFields?: () => { [key: string]: any }) {
            let entity = getDefaultValuesForFields ? getDefaultValuesForFields() : {};
            if (firstTableRow === undefined || firstTableRow.validUntil === null || firstTableRow.validUntil === undefined) {
                entity.validFrom = moment().startOf("date").toDate();
            } else {
                entity.validFrom = moment(firstTableRow.validUntil).add(1, 'minutes').toDate()
            }
            return entity;
        }
    }
})

export class HistoryTable extends EntityTableLight<PropsFrom<typeof sliceHistoryTable> & PropsNotFromState> {

    setOnOpen(onOpen: () => void) {
       this.props.dispatchers.setInReduxState({onOpen})
    }

    open(this: any, entities: any) {
        entities = [...entities];
        super.open(entities);
        this.sort(entities);
        this.props.onOpen?.();
    }

    updateEntity(entity: any, index: number) {
        const entities = _.cloneDeep(this.entityTableSimpleCustomizedRef.current?.getEntities()!);
        const element = entities[index];
        element.validUntil = moment(entity.validFrom).subtract(1, 'minutes').toDate();
        return this.entityTableSimpleCustomizedRef.current?.updateEntity(element, index);
    }

    onAddOrUpdate(entity: any, rowIndex: number) {
        let newEntity = {} as any;
        let entities = [...(this.entityTableSimpleCustomizedRef.current ? this.entityTableSimpleCustomizedRef.current.getEntities() : [])];
        //add button is pressed
        if (rowIndex === -1) {
            //search if an entity with the same validFrom exists in entities
            //you may click on "add" but actually edit the entity (choose the same validFrom and add another validUntil)
            //in this case, you want the entity to be completed, not override it with params.entity {validFrom, validUntil}
            let entityIndex = entities.findIndex((item: any) => moment(item.validFrom).isSame(entity.validFrom));
            if (entityIndex !== -1) {
                newEntity = { ...entities[entityIndex!], validFrom: entity.validFrom, validUntil: entity.validUntil };
            } else {
                newEntity = { ...entity };
            }

            //no value was selected for validFrom
            if (!entity.validFrom) {
                newEntity.validFrom = moment().startOf("date").toDate();
            } else {
                newEntity.validFrom = moment(entity.validFrom).toDate();
            }

            // the add button was pressed but an existing fromDate value was selected and validUntil was modified
            // in this case, modify the validUntil field of the current row and the validFrom field of the above row in table (if it exists)
            const isSameDate = entities.findIndex((item: any) => moment(item.validFrom).isSame(newEntity.validFrom));
            if (isSameDate !== -1) {
                // If a row with the same "valid from" already exists, show an info message and do not add it to the table.
                // validationMessage is part of sliceEntityTableLight (which is the 'parent' of this slice). 
                // The info modal is rendered in the EntityTableLight component because the history table is just a simple slice.
                this.props.dispatchers.setInReduxState({ validationMessage: _msg("HistoryTable.validFromAlreadyExists.label") });
            } else {
                //add a new entry in table
                entities = this.entityTableSimpleCustomizedRef.current ? this.entityTableSimpleCustomizedRef.current.getEntities() : [];
                for (let i = 0; i < entities.length; i++) {
                    // when you add a new entity with a later validFrom date
                    // e.g. newEntity.validFrom: 10.09.2020 > currentEntity.validFrom: 01.09.2020 
                    if (moment(newEntity.validFrom).isAfter(moment(entities[i]?.validFrom))) {
                        entities = this.updateEntity(newEntity, i)!;
                        if (i > 0) {
                            newEntity.validUntil = moment(entities[i - 1].validFrom).subtract(1, 'minutes').toDate();
                        }
                        break;
                    }
                    // when you add a new entity with a earlier validFrom date 
                    // e.g. newEntity.validFrom: 01.09.2020 > currentEntity.validFrom: 10.09.2020 
                    if (entities && i === entities.length - 1) {
                        newEntity.validUntil = moment(entities[i].validFrom).subtract(1, 'minutes').toDate();
                    }
                }
                entities = [...entities];
                entities.push(newEntity);
                this.entityTableSimpleCustomizedRef.current?.setEntities(entities);
            }
        } else {
            if (this.props.allowHoles) {
                const entities = super.onAddOrUpdate(entity, rowIndex)!;
                this.sort(entities);
                return;
            }
            // validFrom was modified by user, validUntil should be automatically changed
            if (!moment(entity.validFrom).isSame(moment(entities[rowIndex]?.validFrom)) && entities[rowIndex + 1]) {
                entities = this.updateEntity(entity, rowIndex + 1)!;
            }
            // validUntil was modified by user, validFrom should be automatically changed
            if (!moment(entity.validUntil).isSame(moment(entities[rowIndex]?.validUntil)) && entities[rowIndex - 1]) {
                const element = _.cloneDeep(entities[rowIndex - 1]);
                element.validFrom = moment(entity.validUntil).add(1, 'minutes').toDate();
                entities[rowIndex - 1] = element;
            }
            entities = [...entities];
            entities[rowIndex] = entity;
            this.entityTableSimpleCustomizedRef.current?.setEntities(entities);
        }
        this.sort(entities);
        return entities;
    }

    sort(entities: any[]) {
        entities = [...entities];
        entities.sort((a: any, b: any) => moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf());
        this.entityTableSimpleCustomizedRef.current?.setEntities(entities);
    }

    onDeleteClick() {
        let entities = [...(this.entityTableSimpleCustomizedRef.current ? this.entityTableSimpleCustomizedRef.current.getEntities() : [])];
        const selected = this.entityTableSimpleCustomizedRef.current?.getSelected()!;
        if (selected && selected !== 0 && entities[selected + 1] && !this.props.allowHoles) {
            const element = _.cloneDeep(entities[selected + 1]);
            element.validUntil = entities[selected].validUntil;
            entities[selected + 1] = element
        }
        entities.splice(selected, 1);
        this.sort(entities);
        return entities;
    }

    componentDidUpdate(prevProps: any): void {
        if (!prevProps.modalOpen && this.props.modalOpen) {
            this.open(this.props.entities);
            this.setEqualColumnsWidthListener();
        }
    }
}