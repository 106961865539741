import { apolloClient } from "@crispico/foundation-react/apolloClient"
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage"
import { ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers"
import React from "react"
import { Button, Container, Dropdown, Form, Message, Radio, Segment } from "semantic-ui-react"
import moment from "moment";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt"
import { EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CONTRACT, GROUP_SERVICE_FACADE_BEAN_FIND_GROUPS, WORKROSTER_SERVICE_FACADE_BEAN_GENERATE_ROSTER_REGISTRATIONS, WORKROSTER_SERVICE_FACADE_BEAN_GENERATE_ROSTER_REGISTRATIONS_FOR_EMPLOYEE } from "graphql/queries"
import { ProteusConstants } from "ProteusConstants"
import { Utils } from "@crispico/foundation-react"
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar"

interface Entity {
    minFromDate: Date,
    groupCode: string | undefined,
    employeeId: number | undefined
}

const MODE1 = "MODE1"; //Generate from the last work periods
const MODE2 = "MODE2"; //Overwrite from date

export const sliceWorkPeriodsGeneration = createSliceFoundation(class SliceWorkPeriodsGeneration {
    initialState = {
        entity: { minFromDate: new Date(new Date().getFullYear(), 0, 1, 0, 0, 0), groupCode: undefined, employeeId: undefined } as Entity,
        groupsOptions: [] as { key: number, text: string, value: string }[],
        employeesOptions: [] as { key: number, text: string, value: number }[],
        mode: MODE1 as string,
        showMessage: false as boolean
    }

    reducers = {
        ...getBaseReducers<SliceWorkPeriodsGeneration>(this)
    }

    impures = {
        ...getBaseImpures<SliceWorkPeriodsGeneration>(this),

        async getGroups() {
            const groups = (await apolloClient.query({ query: GROUP_SERVICE_FACADE_BEAN_FIND_GROUPS })).data.groupServiceFacadeBean_findGroups;
            this.getDispatchers().setInReduxState({
                groupsOptions: groups?.map((item: any) => ({
                    key: item?.id as number,
                    text: item?.name as string,
                    value: item?.code as string
                }))
            });
        },

        async searchEmployee(text: string, fromDate: Date, untilDate: Date) {
            const result = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_CONTRACT,
                variables: {
                    searchText: text,
                    fromDate: fromDate,
                    untilDate: untilDate,
                    includeAllWithValidContract: true
                },
                context: { showSpinner: false }
            })).data.employeeService_employeeSnapshots;
            this.getDispatchers().setInReduxState({
                employeesOptions: result?.map((item: { id: number; name: string; firstName: string; contractHistoryItem: { employeeNumber: string }; }) => ({
                    key: item?.id as number,
                    text: ((item?.name) as string)
                        .concat(" ", item?.firstName as string, (item?.contractHistoryItem?.employeeNumber ? " (" + item?.contractHistoryItem?.employeeNumber + ")" : "") as string),
                    value: item?.id as number
                }))
            });
        },

        async generateRosterRegistrations() {
            let validFrom = null;
            if (this.getState().mode === MODE2) {
                validFrom = new Date(this.getState().entity.minFromDate);
            }
            //generate roster registrations for group
            if (this.getState().entity.groupCode !== undefined) {
                await apolloClient.mutate({
                    mutation: WORKROSTER_SERVICE_FACADE_BEAN_GENERATE_ROSTER_REGISTRATIONS,
                    variables: {
                        minFromDate: new Date(this.getState().entity.minFromDate),
                        validFrom: validFrom,
                        groupCode: this.getState().entity.groupCode
                    }
                });
            } else if (this.getState().entity.employeeId !== undefined) {
                //generate roster registrations for employee
                await apolloClient.mutate({
                    mutation: WORKROSTER_SERVICE_FACADE_BEAN_GENERATE_ROSTER_REGISTRATIONS_FOR_EMPLOYEE,
                    variables: {
                        minFromDate: new Date(this.getState().entity.minFromDate),
                        validFrom: validFrom,
                        employeeId: this.getState().entity.employeeId
                    }
                });
            }
        }
    }
})

export class SliceWorkPeriodsGeneration extends TabbedPage<PropsFrom<typeof sliceWorkPeriodsGeneration>> {

    protected getTitle() {
        return { icon: "retweet", title: _msg("WorkPeriodGeneration.generateWorkPeriod") };
    }

    protected handleChange = (e: any, { value }: any) => {
        this.props.dispatchers.setInReduxState({ mode: value });
    }

    protected renderMain() {
        return (
            <Container className="WorkPeriodsGeneration_container"><Segment>
                <Segment className="WorkPeriodsGeneration_generateBtnSegment">
                    <Button primary disabled={this.props.entity.employeeId === undefined && this.props.entity.groupCode === undefined}
                        onClick={() => {
                            this.props.dispatchers.generateRosterRegistrations();
                            this.props.dispatchers.setInReduxState({ showMessage: true });
                            setTimeout(() => { this.props.dispatchers.setInReduxState({ showMessage: false }); }, 3000);
                        }}>
                        {this.props.entity.employeeId === undefined && this.props.entity.groupCode === undefined ?
                            _msg("WorkPeriodGeneration.generateWorkPeriod") : (this.props.entity.employeeId !== undefined ?
                                _msg("WorkPeriodGeneration.generateWorkPeriodForEmployee") : _msg("WorkPeriodGeneration.generateWorkPeriodForGroup"))
                        }
                    </Button>
                    <Button color='blue' size='mini' circular icon='question'
                        onClick={() => Utils.showGlobalAlert({ title: _msg("WorkPeriodGeneration.extraInfo.header.label"), message: _msg("WorkPeriodGeneration.extraInfo.content.label"), severity: Severity.INFO })} />
                </Segment>
                {this.props.showMessage ? <Message success>{_msg("WorkPeriodGeneration.start.message")}</Message> : null}
                <Form>
                    <Form.Field>
                        <label>{_msg("WorkPeriodGeneration.group.label")}</label>
                        <Dropdown selection search clearable selectOnNavigation={false} placeholder={_msg("WorkPeriodGeneration.group.label")}
                            options={this.props.groupsOptions} value={this.props.entity.groupCode} disabled={this.props.entity.employeeId !== undefined}
                            onChange={(e: any, data: any) => {
                                this.props.dispatchers.setInReduxState({ entity: { ...this.props.entity, groupCode: data.value.length > 0 ? data.value : undefined } });
                            }} noResultsMessage={_msg("general.noResultsFound")} />
                    </Form.Field>
                    <Form.Field>
                        <label>{_msg("WorkPeriodGeneration.employee.label")}</label>
                        <Dropdown selection search clearable selectOnNavigation={false} placeholder={_msg("WorkPeriodGeneration.employee.label")}
                            options={this.props.employeesOptions} value={this.props.entity.employeeId} disabled={this.props.entity.groupCode !== undefined}
                            onChange={(e: any, data: any) => {
                                this.props.dispatchers.setInReduxState({ entity: { ...this.props.entity, employeeId: String(data.value).length > 0 ? data.value : undefined } });
                            }}
                            onSearchChange={(e, data) => {
                                this.props.dispatchers.searchEmployee(data.searchQuery as unknown as string, new Date(), new Date());
                            }} noResultsMessage={_msg("general.noResultsFound")} />
                    </Form.Field>
                    <Form.Field><label>{_msg("WorkPeriodGeneration.mode.label")}</label>
                        <Form.Group inline>
                            <Form.Field>
                                <Radio label={_msg("WorkPeriodGeneration.mode1.label")} name='radioGroup' checked={this.props.mode === MODE1} value={MODE1} onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field>
                                <Radio label={_msg('WorkPeriodGeneration.mode2.label')} name='radioGroup' checked={this.props.mode === MODE2} value={MODE2} onChange={this.handleChange} />
                            </Form.Field>
                        </Form.Group>
                    </Form.Field>
                    <Form.Field>
                        <label>{_msg("WorkPeriodGeneration.minFromDate.label")}</label>
                        <DatePickerReactCalendar format={ProteusConstants.DATE_FORMAT} allowClear={false} placeholder={_msg("WorkPeriodGeneration.minFromDate.label")}
                            value={moment(this.props.entity.minFromDate)} onChange={(date: any) => {
                                this.props.dispatchers.setInReduxState({ entity: { ...this.props.entity, minFromDate: date.toISOString() } });
                            }} />
                    </Form.Field>
                </Form>
                <Message warning>{this.props.mode === MODE1 ? _msg("WorkPeriodGeneration.mode1.warningMessage") : _msg("WorkPeriodGeneration.mode2.warningMessage")}</Message>
            </Segment></Container>
        )
    }

    componentDidMount() {
        this.props.dispatchers.getGroups();
    }
}

export const infoWorkPeriodGeneration = new ConnectedPageInfo(sliceWorkPeriodsGeneration, SliceWorkPeriodsGeneration, "WorkPeriodGeneration");
infoWorkPeriodGeneration.routeProps = { permission: "WORK_PERIOD_GENERATION_VIEW" };