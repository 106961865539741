import { apolloClient, ApolloContext, apolloGetExtensionFromError, CatchedGraphQLError, createSliceFoundation, EntityDescriptor, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { CrudHeader } from "@crispico/foundation-react/entity_crud/CrudHeader";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import StringFieldRenderer, { StringFieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderers/StringFieldRenderer";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { ShortcutRefForTest } from "@famiprog-foundation/tests-are-demo";
import { Drawer } from "antd";
import { WorkflowItemInput } from "apollo-gen/globalTypes";
import { WORKFLOW_SERVICE_FACADE_BEAN_PROCESS_ITEMS } from "graphql/queries";
import Interweave from "interweave";
import _, { uniqueId } from "lodash";
import { DateNavigationPanel, sliceDateNavigation } from "pages/DateNavigationPanel/DateNavigationPanel";
import { PLANNING_WEB_VIEW, REGISTRATION_ID } from "pages/Planning/PlanningPage";
import { ProteusGraphQLErrorExtensions } from "pages/registrationEditor/RegistrationEditor";
import { ProteusUtils } from "ProteusUtils";
import React, { ReactNode } from "react";
import { Button, Container, Icon, Modal, Segment } from "semantic-ui-react";
import { RefreshButtonRRC } from "@crispico/foundation-react/components/RefreshButton/RefreshButton";

export const DELETE_ACTION = "Verwijder";
export const MESSAGES_REFRESH_RATE = 60;

export interface DataDefinition {
    name: string,
    defaultValue: string,
    label: string,
    processor: boolean,
    required: boolean,
    dataTypeClassName: string
}

export interface Message {
    id: number,
    message: string,
    person: number,
    title: string,
    date: Date,
    processInstance: number,
    parameters: DataDefinition[],
    processVariables: { [key: string]: any }
}

export interface EmployeeCriteria {
    ids: number[],
    dateCriteria: { fromDate: any, toDate: any },
    avoidLoadingEmployeeDetails: boolean
}

export const sliceMessagesTable = createSliceFoundation(class SliceMessagesTable {
    initialState = {
        refreshRate: MESSAGES_REFRESH_RATE as number,
        messageViewOpen: false,
        selectedMessage: undefined as undefined | Message,
        processItemModalOpen: false,
        processItemDataDefinition: undefined as undefined | DataDefinition
    }

    nestedSlices = {
        tableSimple: sliceEntityTableLight,
        messagesDatePanel: sliceDateNavigation,
    }

    reducers = {
        ...getBaseReducers<SliceMessagesTable>(this),

        closeMessageView(state: StateFrom<SliceMessagesTable>) {
            state.processItemDataDefinition = undefined;
            state.messageViewOpen = false;
            state.selectedMessage = undefined;
        }
    }

    impures = {
        ...getBaseImpures<SliceMessagesTable>(this)
    }
});

type PropsNotFromState = {
    goToRegistration: any,
    loadMessages: () => void,
    messages: Message[]
}

export class MessagesTable extends React.Component<PropsFrom<typeof sliceMessagesTable> & PropsNotFromState> {
    protected refEntityTableLight = React.createRef<EntityTableLight>();

    setMessages(messages: any[]) {
        this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(messages);
    }

    async processInboxItemForData(dataDefinition: DataDefinition, inboxItem: Message, noConfirm: boolean = false) {
        let workflowItem: WorkflowItemInput = {
            activityInstanceId: inboxItem.id,
            person: inboxItem.person,
            processInstanceId: inboxItem.processInstance,
            parameters: [{
                key: dataDefinition.name,
                value: dataDefinition.defaultValue
            }]
        };

        if (noConfirm) {
            workflowItem.parameters![0]!.value = null;
        }

        await apolloClient.mutate({
            mutation: WORKFLOW_SERVICE_FACADE_BEAN_PROCESS_ITEMS,
            variables: {
                items: [workflowItem]
            },
            context: {
                [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                    const originalCauseErrorCode = apolloGetExtensionFromError(e, ProteusGraphQLErrorExtensions.ORIGINAL_CAUSE_ERROR_CODE);
                    // For workflow error code 500 and 501, the request was already covered or the registration was deleted => the message can be deleted
                    if (originalCauseErrorCode?.includes('WORKFLOW-5')) {
                        !noConfirm && this.props.dispatchers.setInReduxState({ processItemModalOpen: true, processItemDataDefinition: dataDefinition });
                        noConfirm && this.props.dispatchers.setInReduxState({ processItemModalOpen: false, processItemDataDefinition: undefined });
                    } else {
                        const originalCauseMessage = apolloGetExtensionFromError(e, ProteusGraphQLErrorExtensions.ORIGINAL_CAUSE_MESSAGE);
                        Utils.showGlobalAlert({ message: originalCauseMessage, severity: Severity.ERROR, title: _msg("error.globalErrorMessage.server.title") });
                    }
                }
            }
        }).then(() => {
            this.props.loadMessages();
            this.props.dispatchers.closeMessageView();
        });
    }

    componentDidMount() {
        this.props.dispatchers.tableSimple.columnConfigDropdown.updateColumnSize({ name: "date", width: 100 });
        this.setMessages(this.props.messages);
    }
    
    componentDidUpdate(prevProps: any) {
        if (!_.isEqual(prevProps.messages, this.props.messages)) {
            this.setMessages(this.props.messages);
        }
    }

    onItemCustomEdit(entity: any, rowIndex: number) {
        this.props.dispatchers.setInReduxState({
            selectedMessage: entity,
            messageViewOpen: true
        })
    }

    renderHeader(): ReactNode {
        return (
            <>
                <CrudHeader content={{component: <Segment className="EntityEditorPage_header_content" data-testid="MessagesTable_messageViewHeader">
                    <div className="flex-container-row flex-center gap5">
                        <Icon size="big" name="mail outline" className="EntityCrudHeader_white" />
                        <h2 className="EntityCrudHeader_white no-margin">{this.props.selectedMessage?.title}</h2>
                    </div>
                </Segment >, className: 'CrudHeader_editorHeight'}
                    }
                />
            </>
        );
    }

    protected renderProcessItemModalInfoMessage() {
        return (
            <ModalExt onClose={() => this.props.dispatchers.setInReduxState({ processItemModalOpen: false })}
                open={this.props.processItemModalOpen === true} size='small'>
                <Modal.Header>{_msg("alert.errorTitle")}</Modal.Header>
                <Modal.Content>{_msg("MessagesTable.processItemModal.message")}</Modal.Content>
                <Modal.Actions>
                    <Button key={1} onClick={() => { this.props.dispatchers.setInReduxState({ processItemModalOpen: false }) }} content={_msg("general.cancel")} />
                    <Button key={2} primary content={_msg("general.ok")} onClick={() => {
                        const message = this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!];
                        this.processInboxItemForData(this.props.processItemDataDefinition!, message, true);
                        this.props.dispatchers.setInReduxState({ processItemModalOpen: false, processItemDataDefinition: undefined })
                    }} />
                </Modal.Actions>
            </ModalExt>
        );
    }

    protected getGoToButton() {
        if (ProteusUtils.checkPermission(PLANNING_WEB_VIEW) && this.props.selectedMessage?.processVariables[REGISTRATION_ID]) {
            return <Button data-testid="MessageTable_GoTo" onClick={() => this.props.goToRegistration(this.props.selectedMessage?.processVariables[REGISTRATION_ID])}>{_msg("MessagesTable.goTo.label")}</Button>
        }
    }

    protected onDelete(removedPropertyIndex: number) {
        const messageToDelete: Message = this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[removedPropertyIndex];
        const canBeDeleted = messageToDelete.parameters.some(param => param.name === DELETE_ACTION);
        if (canBeDeleted) {
            const dataDefinition = messageToDelete.parameters.find(param => param.name === DELETE_ACTION);
            this.processInboxItemForData(dataDefinition!, messageToDelete);
            return;
        }
        Utils.showGlobalAlert({ title: _msg("general.error"), message: _msg("MessagesTable.deleteMessage.notAllowed"), severity: Severity.INFO });
    }

    protected renderActionButtons() {
        return (
            <>
                {this.props.selectedMessage?.parameters.map(param => {
                    if (param.processor) {
                        return <Button data-testid={"MessageTable_" + param.label} onClick={() => this.processInboxItemForData(param, this.props.selectedMessage!)}>{param.label}</Button>;
                    }
                })}
                {this.getGoToButton()}
            </>
        );
    }

    renderMessageView() {
        return (
            <>
                {this.renderHeader()}
                <Container className="MessagesTable_container flex-grow" fluid>
                    <Segment className="MessagesTable_segment" data-testid="MessagesTable_messageView">
                        <Segment className="MessagesTable_messageViewActionSegment" data-testid="MessagesTable_actionButtons">
                            {this.renderActionButtons()}
                        </Segment>
                        <Container data-testid="MessagesTable_messageContent"><Interweave content={this.props.selectedMessage?.message}/></Container>
                    </Segment>
                </Container>
            </>
        );
    }

    render() {
        return <Segment className="wh100 flex">
            <Segment data-testid="MessagesTable_header" className="MessagesTable_header">
                <RefreshButtonRRC id={uniqueId("refreshButton")} automaticRefresh={true} defaultRefreshRate={15}
                    refresh={() => this.props.loadMessages()} refreshRate={this.props.refreshRate} 
                    onChange={(params: { refreshRate?: number; }) => 
                    { this.props.dispatchers.setInReduxState({ refreshRate: params.refreshRate }) }} />
                <DateNavigationPanel {...this.props.messagesDatePanel} dispatchers={this.props.dispatchers.messagesDatePanel} onChangePeriod={() => this.props.loadMessages()} />
            </Segment>
            <ShortcutRefForTest objectToPublish={this} className={"ShortcutRefForTest"} />
            <EntityTableLight {...this.props.tableSimple} dispatchers={this.props.dispatchers.tableSimple} data-testid="MessagesTable" ref={this.refEntityTableLight}
                entityDescriptor={inboxItemDescriptor} actions={{ showDeleteButton: true, showAddButton: false, showEditButton: true, doNotRemoveEntityFromTable: true }}
                formCustomizer={{ onItemCustomEdit: (entity: any, rowIndex: number) => this.onItemCustomEdit(entity, rowIndex) }}
                onDelete={(messages: Message[], removedPropertyIndex: number) => this.onDelete(removedPropertyIndex)} useDefaultColumnsWidths
            />
            <Drawer className="MessagesTable_messageViewDrawer" visible={this.props.messageViewOpen} width="80%" closeIcon={<Button icon="close" className="less-padding" circular />}
                onClose={() => this.props.dispatchers.setInReduxState({ messageViewOpen: false })} destroyOnClose>
                {this.renderMessageView()}
            </Drawer>
            {this.renderProcessItemModalInfoMessage()}
        </Segment>;
    }

    tadCloseMessagesView() {
        this.props.dispatchers.closeMessageView();
    }

}

export const inboxItemDescriptor = new EntityDescriptor({ name: "InboxItem" })
    .addFieldDescriptor({ name: "date", type: FieldType.date })
    .addFieldDescriptor({ name: "title", type: FieldType.string }, new class extends FieldDescriptor {

        protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): React.ReactNode {
            return React.createElement(MessageTitleFieldRenderer, props)
        }
    })

export class MessageTitleFieldRenderer extends StringFieldRenderer<StringFieldRendererProps> {
    protected getContent() {
        const { props } = this;
        const showIcon = props.entity.parameters.every((param: DataDefinition) => param.name !== DELETE_ACTION);
        return <div data-testid="StringFieldRenderer" className="StringFieldRenderer" style={props.fieldDescriptor.getFieldColors(props.value)}
            data-tooltip={this.props.showTooltip && props.fieldDescriptor.getLabel()} data-position="top center">
            {showIcon && <Icon data-testid="MessagesTable_actionMessageIcon" name="exclamation circle" color="red" />}
            {this.getText()}
        </div>
    }
}