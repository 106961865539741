import { apolloClient, ApolloContext, apolloGlobalErrorHandler, AppMeta, CatchedGraphQLError, EntityDescriptor, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityFormLight, EntityFormLightRaw } from "@crispico/foundation-react/entity_crud/light_crud/EntityFormLight";
import { ConnectedPageInfo, createSliceFoundation, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { CodeEntity } from "apollo-gen/CodeEntity";
import { getMetadataContext_registrationMetadataServiceFacadeBean_metadataContexts } from "apollo-gen/getMetadataContext";
import { getPropertiesValues_registrationMetadataServiceFacadeBean_propertiesValues } from "apollo-gen/getPropertiesValues";
import { getRegistrationStatuses_registrationServiceFacadeBean_registrationStatuses } from "apollo-gen/getRegistrationStatuses";
import { RegistrationType } from "apollo-gen/RegistrationType";
import { RegistrationTypeCategory_types_category } from "apollo-gen/RegistrationTypeCategory";
import { RegistrationTypeStatusRelation } from "apollo-gen/RegistrationTypeStatusRelation";
import { InitializationsForClient } from "app";
import { LeavePageModal } from "components/LeavePageModal";
import { RegistrationTypeCategoryTree, RegistrationTypeCategoryTreeRaw } from "components/registrationTypeCategoryTree/RegistrationTypeCategoryTree";
import { COMPENSATION_LOSS_ROUNDING_SERVICE_GET_COMPENSATION_LOSS_ROUNDINGS, METADATA_CONTEXT_SERVICE_FACADE_BEAN_SAVE_METADATA_CONTEXT, PLANNING_SERVICE_FACADE_BEAN_GET_LAYOUT_TYPES, REGISTRATION_METADATA_SERVICE_FACADE_BEAN_GET_METADATA_CONTEXTS_BY_ENVIRONMENT, REGISTRATION_METADATA_SERVICE_FACADE_BEAN_GET_PROPERTIES_VALUES, REGISTRATION_METADATA_SERVICE_FACADE_BEAN_REMOVE_METADATA_CONTEXT, REGISTRATION_METADATA_SERVICE_FACADE_BEAN_REMOVE_TYPE_STATUS_RELATION_AND_PROPERTIES, REGISTRATION_METADATA_SERVICE_FACADE_BEAN_SAVE_PROPERTY_ENTITIES_WITH_PROPERTY_VALUE, REGISTRATION_METADATA_SERVICE_FACADE_BEAN_SAVE_PROPERTY_ENTITIES_WITH_USER_VALUE, REGISTRATION_METADATA_SERVICE_FACADE_BEAN_SAVE_TYPE_STATUS_RELATION, REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_STATUSES, REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_STATUS_RELATION, REGISTRATION_SERVICE_FACADE_BEAN_REMOVE_REGISTRATION_TYPE, REGISTRATION_SERVICE_FACADE_BEAN_REMOVE_REGISTRATION_TYPE_CATEGORY, REGISTRATION_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_REGISTRATION_TYPE, REGISTRATION_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_REGISTRATION_TYPE_CATEGORY, ROUNDING_TYPE_SERVICE_GET_ROUNDING_TYPES } from "graphql/queries";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import React from "react";
import { SketchPicker } from "react-color";
import { Accordion, Button, Checkbox, Container, Dropdown, Form, Grid, Icon, Input, Segment, SemanticICONS, Tab, Table } from "semantic-ui-react";
import { ValueField } from 'app';

export const SYSTEM_PROPERTIES = 0x100;
export const SYSTEM_CONTEXT_PROPERTIES = 0x110;
export const SYSTEM_CONTEXT_USER_PROPERTIES = 0x111;
export const REGISTRATION_TYPE = "RegistrationType";
export const REGISTRATION_TYPE_CATEGORY = "RegistrationTypeCategory";
export const METADATA_CONTEXT = "MetadataContext";

export const sliceRegistrationMetadata = createSliceFoundation(class SliceRegistrationMetadata {

    initialState = {
        selectedRegistrationTypeOrCategory: undefined as unknown as RegistrationType & { __typename: string } | RegistrationTypeCategory_types_category & { __typename: string },
        isAdministrator: false as boolean,
        confirmDeleteRegistration: false as boolean,
        confirmDeleteMetadata: false as boolean,
        confirmDeleteStatus: false as boolean,
        confirmChangingRegistration: false as boolean,
        editorHearderContent: { text: "" as string, icon: "file" as SemanticICONS },
        metadataContexts: [] as getMetadataContext_registrationMetadataServiceFacadeBean_metadataContexts[],
        openedContextForm: false as boolean,
        currentMetadataContext: {} as getMetadataContext_registrationMetadataServiceFacadeBean_metadataContexts,
        typeProperties: [] as getPropertiesValues_registrationMetadataServiceFacadeBean_propertiesValues[],
        loadedTypeStatusProperties: [] as getPropertiesValues_registrationMetadataServiceFacadeBean_propertiesValues[],
        typeStatusProperties: [] as getPropertiesValues_registrationMetadataServiceFacadeBean_propertiesValues[],
        searchedTypeStatusProperty: "" as string,
        propertiesToBeSaved: [] as any,
        registrationStatuses: [] as getRegistrationStatuses_registrationServiceFacadeBean_registrationStatuses[],
        registrationTypeStatusRelation: [] as RegistrationTypeStatusRelation[],
        displayedColorPicker: [] as string[],
        selectedStatusForNewRelation: undefined as unknown as getRegistrationStatuses_registrationServiceFacadeBean_registrationStatuses,
        columnToBeDeleted: undefined as unknown as getRegistrationStatuses_registrationServiceFacadeBean_registrationStatuses,
        confirmLeavePage: false as boolean,
        newOperationToBeConfirmed: undefined as unknown as any,
        expandCollapseItemId: undefined as unknown as string,
        layoutTypes: [] as CodeEntity[],
        roundingTypes: [] as CodeEntity[],
        compensationLossRoundings: [] as CodeEntity[],
        currentSelectedItem: "" as string,
        ejobMetadataTypeOptions: [] as { key: string, text: string, value: string }[],
        ejobShipAttendanceOptions: [] as { key: string, text: string, value: string }[]
    };

    reducers = {
        ...getBaseReducers<SliceRegistrationMetadata>(this),

        /**
        * Open formLight with custom message and icon
        */
        openFormLight(state: StateFrom<SliceRegistrationMetadata>, entity: any) {
            let icon: SemanticICONS = "file alternate outline";
            let message = "";
            if ((entity.__typename === REGISTRATION_TYPE || entity.__typename === REGISTRATION_TYPE_CATEGORY) && entity.id !== undefined) {
                message = _msg("entityCrud.editor.subheader", _msg(entity.__typename + ".label"));
                if (entity.__typename === REGISTRATION_TYPE) {
                    icon = "sticky note";
                }
            } else if (entity.__typename === REGISTRATION_TYPE) {
                message = _msg("entityCrud.editor.subheader.add", _msg("RegistrationType.label"));
                icon = "sticky note";
            } else if (entity.__typename === REGISTRATION_TYPE_CATEGORY) {
                message = _msg("entityCrud.editor.subheader.add", _msg("RegistrationTypeCategory.label"));
            } else if (entity.__typename === METADATA_CONTEXT) {
                message = _msg("entityCrud.editor.subheader.add", _msg("MetadataContext.label"));
            }
            state.editorHearderContent = { text: message, icon };
        }
    }
});

export class RegistrationMetadata extends TabbedPage<PropsFrom<typeof sliceRegistrationMetadata>> {

    protected entityFormLightRef = React.createRef<EntityFormLightRaw>();
    protected registrationTypeCategoryTreeRef = React.createRef<RegistrationTypeCategoryTreeRaw>();
    protected prevRegistrationTypeCategoryTreeRef = {} as any;

    constructor(props: any) {
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

        async loadMetadataContexts(reloadScreen: boolean, currentContextId?: number) {
            let metadataContexts = (await apolloClient.query({
                query: REGISTRATION_METADATA_SERVICE_FACADE_BEAN_GET_METADATA_CONTEXTS_BY_ENVIRONMENT,
                variables: {
                    environment: ProteusUtils.getEnvironmentId()
                },
                context: {
                    showSpinner: reloadScreen
                }
            })).data.registrationMetadataServiceFacadeBean_metadataContexts;
            metadataContexts = metadataContexts.sort((a: any, b: any) => a.name > b.name ? 1 : -1);
            const systemContext = metadataContexts.filter((item: any) => item.code === ProteusConstants.SYSTEM);
            metadataContexts = systemContext?.concat(metadataContexts.filter((item: any) => item.code !== ProteusConstants.SYSTEM));
            if (currentContextId) {
                this.props.dispatchers.setInReduxState({ currentMetadataContext: metadataContexts.filter((item: any) => item.id === currentContextId)[0] });
            } else if (metadataContexts.length > 0) {
                this.props.dispatchers.setInReduxState({ currentMetadataContext: metadataContexts[1] });
            }
            this.props.dispatchers.setInReduxState({ metadataContexts: metadataContexts });
        }

        /**
        * Load propertyValues for selected context and registration
        * Because there are two tables and because the second table can be filtered, the properties are kept in two variables, one for each table
        */
        async loadPropertiesValues(reloadScreen: boolean) {
            const criteria = {
                contextCode: this.props.currentMetadataContext.code,
                environment: ProteusUtils.getEnvironmentId(),
                registrationTypes: [this.props.selectedRegistrationTypeOrCategory.code],
                typesToLoad: this.getPropertyTypesToLoad(),
                categories: [],
                propertyCodes: [],
                registrationStatuses: []
            };
            const propertiesValues = (await apolloClient.query({
                query: REGISTRATION_METADATA_SERVICE_FACADE_BEAN_GET_PROPERTIES_VALUES,
                variables: {
                    criteria: criteria
                },
                context: {
                    showSpinner: reloadScreen
                }
            })).data.registrationMetadataServiceFacadeBean_propertiesValues;
            const typeProperties = propertiesValues.filter((item: any) => item.systemValue.property.isForType === true)
                .sort((a: any, b: any) => a.systemValue.property.description > b.systemValue.property.description ? 1 : -1);
            const loadedTypeStatusProperties = propertiesValues.filter((item: any) => item.systemValue.property.isForType === false);
            this.props.dispatchers.setInReduxState({ typeProperties, loadedTypeStatusProperties });
            this.setTypeStatusProperties(this.props.searchedTypeStatusProperty);
        }

        /**
        * If needed (value !== ""), filter typeStatusProperties
        */
        setTypeStatusProperties(value: string) {
            if (value !== "") {
                let filteredTypeStatusProperties = this.props.loadedTypeStatusProperties.filter((item: any) => item.systemValue.property.description.toLowerCase().includes(value.toLowerCase()));
                this.props.dispatchers.setInReduxState({ typeStatusProperties: filteredTypeStatusProperties });
            } else {
                this.props.dispatchers.setInReduxState({ typeStatusProperties: this.props.loadedTypeStatusProperties });
            }
            this.registrationTypeCategoryTreeRef.current?.props.r.setTreeRoot();
        }

        getMutationContext() {
            return ({
                [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                    apolloGlobalErrorHandler(e);
                    return true;
                },
            })
        }

        async saveOrUpdateRegistrationTypeCategory(registrationTypeCategory: any) {
            if (registrationTypeCategory.__typename === REGISTRATION_TYPE_CATEGORY) {
                await apolloClient.mutate({
                    mutation: REGISTRATION_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_REGISTRATION_TYPE_CATEGORY,
                    variables: {
                        category: registrationTypeCategory
                    },
                    context: this.getMutationContext()
                });
            } else if (registrationTypeCategory.__typename === REGISTRATION_TYPE) {
                await apolloClient.mutate({
                    mutation: REGISTRATION_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_REGISTRATION_TYPE,
                    variables: {
                        type: registrationTypeCategory
                    },
                    context: this.getMutationContext()
                });
            }
            this.setEmptyTreeOnAddOrDelete();
            await this.registrationTypeCategoryTreeRef.current?.loadRegistrationTypeTree(false, false, false, false);

            //if the registration is updated, update selectedRegistration also
            if (registrationTypeCategory.id !== undefined) {
                this.props.dispatchers.setInReduxState({ selectedRegistrationTypeOrCategory: { ...registrationTypeCategory, objectVersion: registrationTypeCategory.objectVersion + 1 } });
                await this.loadPropertiesValues(false);
            }
            let index = this.findNewlyAddedElementInTree(this.registrationTypeCategoryTreeRef.current?.props.s.root, registrationTypeCategory, "");
            this.registrationTypeCategoryTreeRef.current?.setTreeSelectedId(index);
            this.props.dispatchers.setInReduxState({ currentSelectedItem: index });
            document.getElementsByName(index!)[0]?.scrollIntoView(false);
        }

        findNewlyAddedElementInTree(root: any, registrationTypeCategory: any, indexInTree: string): string | undefined {
            let index = "" as string | undefined;
            for (let i = 0; i < root.length; i++) {
                if (registrationTypeCategory.__typename === REGISTRATION_TYPE_CATEGORY && root[i].code === registrationTypeCategory.code && root[i].__typename === registrationTypeCategory.__typename) {
                    return indexInTree + i;
                } else if (registrationTypeCategory.__typename === REGISTRATION_TYPE && root[i].types.length > 0) {
                    for (let j = 0; j < root[i].types.length; j++) {
                        if (root[i].types[j].code === registrationTypeCategory.code) {
                            return indexInTree + i + Utils.defaultIdSeparator + "types" + Utils.defaultIdSeparator + j;
                        }
                    }
                } else {
                    if (root[i].categoryChildren.length > 0) {
                        index = this.findNewlyAddedElementInTree(root[i].categoryChildren, registrationTypeCategory, indexInTree + i + Utils.defaultIdSeparator + "categoryChildren" + Utils.defaultIdSeparator);
                        if (index !== undefined) {
                            return index;
                        }
                    }
                }
            }
            return undefined;
        }

        setEmptyTreeOnAddOrDelete() {
            this.registrationTypeCategoryTreeRef.current?.registrationTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
            this.registrationTypeCategoryTreeRef.current?.props.r.setInReduxState({ registrationTypeTree: [] });
            this.registrationTypeCategoryTreeRef.current?.props.r.setTreeRoot();
        }

        async deleteRegistrationTypeCategory(registrationTypeCategory: any) {
            if (registrationTypeCategory.__typename === REGISTRATION_TYPE_CATEGORY) {
                await apolloClient.mutate({
                    mutation: REGISTRATION_SERVICE_FACADE_BEAN_REMOVE_REGISTRATION_TYPE_CATEGORY,
                    variables: {
                        registrationTypeCategory: registrationTypeCategory
                    },
                    context: this.getMutationContext()
                });
            } else if (registrationTypeCategory.__typename === REGISTRATION_TYPE) {
                await apolloClient.mutate({
                    mutation: REGISTRATION_SERVICE_FACADE_BEAN_REMOVE_REGISTRATION_TYPE,
                    variables: {
                        registrationType: registrationTypeCategory
                    }
                });
            }
            this.setEmptyTreeOnAddOrDelete();
            await this.registrationTypeCategoryTreeRef.current?.loadRegistrationTypeTree(false, false, false, false);
        }

        async saveMetadataContext(metadataContext: any) {
            const entity = (await apolloClient.mutate({
                mutation: METADATA_CONTEXT_SERVICE_FACADE_BEAN_SAVE_METADATA_CONTEXT,
                variables: {
                    context: metadataContext
                }
            })).data.metadataContextService_saveMetadataContext;
            await this.loadMetadataContexts(true, entity.id);
        }

        async deleteMetadataContext(metadataContext: any) {
            await apolloClient.mutate({
                mutation: REGISTRATION_METADATA_SERVICE_FACADE_BEAN_REMOVE_METADATA_CONTEXT,
                variables: {
                    context: metadataContext
                }
            });
            await apolloClient.mutate({
                mutation: REGISTRATION_METADATA_SERVICE_FACADE_BEAN_REMOVE_METADATA_CONTEXT,
                variables: { context: metadataContext }
            });
            await this.loadMetadataContexts(true);
        }

        async savePropertiesValues() {
            if (this.getPropertyTypesToLoad() === SYSTEM_CONTEXT_PROPERTIES || this.getPropertyTypesToLoad() === SYSTEM_PROPERTIES) {
                await apolloClient.mutate({
                    mutation: REGISTRATION_METADATA_SERVICE_FACADE_BEAN_SAVE_PROPERTY_ENTITIES_WITH_PROPERTY_VALUE,
                    variables: {
                        entities: this.props.propertiesToBeSaved
                    }
                });
            } else {
                await apolloClient.mutate({
                    mutation: REGISTRATION_METADATA_SERVICE_FACADE_BEAN_SAVE_PROPERTY_ENTITIES_WITH_USER_VALUE,
                    variables: {
                        entities: this.props.propertiesToBeSaved
                    }
                });
            }
            await this.loadPropertiesValues(false);
            this.props.dispatchers.setInReduxState({ propertiesToBeSaved: [] });
        }

        /**
        * registrationStatuses are used in dropDown (SYSTEM context)
        */
        async getRegistrationStatuses() {
            const registrationStatuses = (await apolloClient.query({ query: REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_STATUSES })).data.registrationServiceFacadeBean_registrationStatuses;
            this.props.dispatchers.setInReduxState({ registrationStatuses });
        }

        async getLayoutTypes() {
            const layoutTypes = (await apolloClient.query({ query: PLANNING_SERVICE_FACADE_BEAN_GET_LAYOUT_TYPES })).data.planningServiceFacadeBean_layoutTypes;
            this.props.dispatchers.setInReduxState({ layoutTypes });
        }

        async getRoundingTypes() {
            const roundingTypes = (await apolloClient.query({ query: ROUNDING_TYPE_SERVICE_GET_ROUNDING_TYPES })).data.roundingTypeService_roundingTypes;
            this.props.dispatchers.setInReduxState({ roundingTypes });
        }

        async getCompensationLossRoundings() {
            const compensationLossRoundings = (await apolloClient.query({ query: COMPENSATION_LOSS_ROUNDING_SERVICE_GET_COMPENSATION_LOSS_ROUNDINGS })).data.compensationLossRoundingService_compensationLossRoundings;
            this.props.dispatchers.setInReduxState({ compensationLossRoundings });
        }

        async saveTypeStatusRelation() {
            const relation = { type: this.props.selectedRegistrationTypeOrCategory, status: this.props.selectedStatusForNewRelation };
            await apolloClient.mutate({
                mutation: REGISTRATION_METADATA_SERVICE_FACADE_BEAN_SAVE_TYPE_STATUS_RELATION,
                variables: {
                    relation
                }
            });
            await this.getRegistrationTypeStatusRelation();
            await this.loadPropertiesValues(false);
        }

        async deleteTypeStatusRelation() {
            let systemValues = this.props.typeStatusProperties.filter((item: any) =>
                item.systemValue.registrationStatus === this.props.columnToBeDeleted.code).map((item: any) => item.systemValue);

            await apolloClient.mutate({
                mutation: REGISTRATION_METADATA_SERVICE_FACADE_BEAN_REMOVE_TYPE_STATUS_RELATION_AND_PROPERTIES,
                variables: {
                    systemValues: systemValues,
                    relation: {
                        status: this.props.columnToBeDeleted,
                        type: this.props.selectedRegistrationTypeOrCategory,
                        id: this.props.registrationTypeStatusRelation.filter((item: any) => item.status.id === this.props.columnToBeDeleted.id)[0].id
                    }
                }
            });
            await this.getRegistrationTypeStatusRelation();
            await this.loadPropertiesValues(false);
            this.props.dispatchers.setInReduxState({ columnToBeDeleted: undefined });
        }

        async getRegistrationTypeStatusRelation() {
            const registrationTypeStatusRelation = (await apolloClient.query({
                query: REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_STATUS_RELATION,
                variables: {
                    type: {
                        id: this.props.selectedRegistrationTypeOrCategory.id,
                        objectVersion: this.props.selectedRegistrationTypeOrCategory.objectVersion
                    }
                }
            })).data.registrationServiceFacadeBean_registrationTypeStatusRelations;
            this.props.dispatchers.setInReduxState({
                registrationTypeStatusRelation: [...registrationTypeStatusRelation].sort((a: any, b: any) =>
                    a.status.code > b.status.code ? 1 : -1)
            });
        }

        async refresh() {
            await this.registrationTypeCategoryTreeRef.current?.loadRegistrationTypeTree(false, false, false, false);
            await this.loadMetadataContexts(false);
            if (this.props.selectedRegistrationTypeOrCategory && this.props.selectedRegistrationTypeOrCategory.id && this.props.currentMetadataContext.id) {
                await this.getRegistrationTypeStatusRelation();
                await this.loadPropertiesValues(false);
            }
        }

        getPropertyTypesToLoad() {
            if (this.props.currentMetadataContext.code === ProteusConstants.SYSTEM) {
                return SYSTEM_PROPERTIES;
            } else if (this.props.isAdministrator) {
                return SYSTEM_CONTEXT_PROPERTIES;
            } else {
                return SYSTEM_CONTEXT_USER_PROPERTIES;
            }
        }

    protected typeCategoryAndContextDescriptor = new EntityDescriptor({ name: "TypeCategoryAndContext" })
        .addFieldDescriptor({ name: "code", type: FieldType.string })
        .addFieldDescriptor({ name: "name", type: FieldType.string })
        .addFieldDescriptor({ name: "description", type: FieldType.string })

    protected getTabbedPageCssClasses() {
        return super.getTabbedPageCssClasses() + " container_fullHeight";
    }

    protected onDeleteCancel = () => {
        this.props.dispatchers.setInReduxState({ confirmDeleteRegistration: false });
    }

    protected onDeleteConfirm = () => {
        this.props.dispatchers.setInReduxState({ confirmDeleteRegistration: false });
        this.deleteRegistrationTypeCategory(this.props.selectedRegistrationTypeOrCategory);
        this.registrationTypeCategoryTreeRef.current?.setTreeSelectedId(undefined);
        this.props.dispatchers.setInReduxState({ currentSelectedItem: undefined });
    }

    handleDelete(object: any) {
        const confirmDeleteRegistration = true && (!this.props.selectedRegistrationTypeOrCategory || (object.id === this.props.selectedRegistrationTypeOrCategory?.id && !this.props.confirmLeavePage));
        this.props.dispatchers.setInReduxState({ confirmDeleteRegistration });
    }

    handleEdit(object: any) {
        if (this.props.propertiesToBeSaved.length > 0) {
            this.props.dispatchers.setInReduxState({ confirmLeavePage: true, newOperationToBeConfirmed: { newObjectToBeEdited: object } })
        } else {
            this.openFormLight(object);
        }
    }

    renderDeleteModal() {
        return <ModalExt severity={Severity.INFO} open={this.props.confirmDeleteRegistration}
            header={_msg("registration.deleteConfirmation", this.props.selectedRegistrationTypeOrCategory?.name)}
            content={_msg("dto_crud.deleteConfirmation")} onClose={this.onDeleteCancel}
            actions={[
                <Button key="cancel" onClick={this.onDeleteCancel}>{_msg("general.cancel")}</Button>,
                <Button key="ok" primary onClick={this.onDeleteConfirm}>{_msg("general.ok")}</Button>
            ]}
        />;
    }

    protected openFormLight = (object: any) => {
        this.props.dispatchers.openFormLight(object);
        this.entityFormLightRef.current!.open(object, 0);
    }

    /**
    * Show system context pane only if administrator checkbox is checked 
    */
    renderTabPanes() {
        let tabPanes = [];
        let metadataContexts = this.props.metadataContexts;
        if (!this.props.isAdministrator) {
            metadataContexts = metadataContexts.filter((item: any) => item.code !== ProteusConstants.SYSTEM);
        }
        for (let i = 0; i < metadataContexts.length; i++) {
            tabPanes.push({ menuItem: metadataContexts[i].name, render: () => this.renderTabsContent(metadataContexts[i]) })
        }
        return tabPanes;
    }

    renderTabsContent(context: any) {
        const disabled = !this.props.isAdministrator || this.props.currentMetadataContext.code === ProteusConstants.SYSTEM;
        return <Tab.Pane active={this.props.selectedRegistrationTypeOrCategory?.__typename === REGISTRATION_TYPE} className="flex-grow">
            <Accordion fluid>
                <Accordion.Title
                    active={this.props.openedContextForm}
                    index={0}
                    onClick={() => this.props.dispatchers.setInReduxState({ openedContextForm: !this.props.openedContextForm })}>
                    <Icon name='dropdown' />
                    {context.name}
                </Accordion.Title>
                <Accordion.Content active={this.props.openedContextForm}>
                    <Form>
                        <Grid className="RegistrationMetadata_accordionGridRow" stackable>
                            <Grid.Row columns={4}>
                                <Grid.Column >
                                    <Form.Input placeholder={_msg("code.label")} label={_msg("code.label")} value={this.props.currentMetadataContext.code || ""}
                                        onChange={(e: any, data: any) => {
                                            this.props.dispatchers.setInReduxState({ currentMetadataContext: { ...this.props.currentMetadataContext, code: data.value } })
                                        }} disabled={disabled}>
                                    </Form.Input>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input placeholder={_msg("name.label")} label={_msg("name.label")} value={this.props.currentMetadataContext.name || ""}
                                        onChange={(e: any, data: any) => {
                                            this.props.dispatchers.setInReduxState({ currentMetadataContext: { ...this.props.currentMetadataContext, name: data.value } })
                                        }} disabled={disabled}>
                                    </Form.Input >
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input placeholder={_msg("description.label")} label={_msg("description.label")} value={this.props.currentMetadataContext.description || ""}
                                        onChange={(e: any, data: any) => {
                                            this.props.dispatchers.setInReduxState({ currentMetadataContext: { ...this.props.currentMetadataContext, description: data.value } })
                                        }} disabled={disabled}>
                                    </Form.Input>
                                </Grid.Column>
                                <Grid.Column>
                                    <Grid.Row>
                                        <Button primary icon disabled={disabled} onClick={() => {
                                            this.saveMetadataContext(this.props.currentMetadataContext);
                                        }}>
                                            <Icon name="save" />{_msg("dto_crud.save")}
                                        </Button>
                                        <Button onClick={() => { this.props.dispatchers.setInReduxState({ confirmDeleteMetadata: true }); }}
                                            icon negative disabled={disabled}>
                                            <Icon name="delete" />{_msg("dto_crud.delete")}</Button>
                                        <ModalExt
                                            severity={Severity.INFO}
                                            open={this.props.confirmDeleteMetadata}
                                            header={_msg("MetadataContext.deleteConfirmation.header.label", this.props.currentMetadataContext.name)}
                                            content={_msg("MetadataContext.deleteConfirmation.content.label")}
                                            onClose={this.onDeleteMetadataCancel}
                                            actions={[
                                                <Button key="cancel" onClick={this.onDeleteMetadataCancel}>{_msg("general.cancel")}</Button>,
                                                <Button key="ok" primary onClick={this.onDeleteMetadataConfirm}>{_msg("general.ok")}</Button>
                                            ]}
                                        />
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Accordion.Content>
            </Accordion>
            {this.renderTypeTable()}
            {this.renderTypeStatusSegment()}
            {this.renderTypeStatusTable()}
        </Tab.Pane>
    }

    protected onDeleteMetadataCancel = () => {
        this.props.dispatchers.setInReduxState({ confirmDeleteMetadata: false });
    }

    protected onDeleteMetadataConfirm = () => {
        this.props.dispatchers.setInReduxState({ confirmDeleteMetadata: false });
        this.deleteMetadataContext(this.props.currentMetadataContext);
    }

    renderTypeStatusSegment() {
        let registrationStatusesOptions = this.props.registrationStatuses.map((item: any) => ({
            key: item.id as string,
            text: item.description as string,
            value: item.id as string
        })).filter((item: any) => !(this.props.registrationTypeStatusRelation.map((item2: any) => (item2.status.description)).includes(item.text)));

        return <Segment>
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <Form>
                            <Form.Field>
                                <Input icon="search" placeholder={_msg("searchProperty.label")} value={this.props.searchedTypeStatusProperty}
                                    onChange={(e: any, data: any) => {
                                        this.props.dispatchers.setInReduxState({ searchedTypeStatusProperty: data.value });
                                        this.setTypeStatusProperties(data.value);
                                    }}></Input>
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                    <Grid.Column className="RegistrationMetadata_typeStatusSegment">
                        {this.props.currentMetadataContext.code === ProteusConstants.SYSTEM && <><Dropdown placeholder={_msg("general.selectStatus")} selection search clearable options={registrationStatusesOptions} onChange={(e, data) => {
                            if (data.value !== "") {
                                this.props.dispatchers.setInReduxState({ selectedStatusForNewRelation: this.props.registrationStatuses.filter((item: any) => item.id === data.value)[0] });
                            } else {
                                this.props.dispatchers.setInReduxState({ selectedStatusForNewRelation: undefined });
                            }
                        }} noResultsMessage={_msg("general.noResultsFound")} />
                            <Button color="green" icon className="RegistrationMetadata_addStatusRelation" disabled={this.props.selectedStatusForNewRelation === undefined} value={this.props.selectedStatusForNewRelation} onClick={async () => {
                                await this.saveTypeStatusRelation();
                                this.props.dispatchers.setInReduxState({ selectedStatusForNewRelation: undefined });
                            }}><Icon name="add" /></Button></>}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    }

    renderTypeTable() {
        return (<Table celled structured compact unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                    <Table.HeaderCell width={7} textAlign="center">{_msg("name.label")}</Table.HeaderCell>
                    <Table.HeaderCell width={7} textAlign="center">{_msg("value.label")}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {this.renderTypeTableRows()}
            </Table.Body>
        </Table>)
    }

    renderTypeTableRows() {
        let rows = [];
        let categories = [] as any[];
        for (let i = 0; i < this.props.typeProperties.length; i++) {
            if (!categories.includes(this.props.typeProperties[i].systemValue?.property?.category)) {
                categories.push(this.props.typeProperties[i].systemValue?.property?.category)
            }
        }
        categories = categories.sort((a: string, b: string) => a < b ? 1 : -1);
        for (let i = 0; i < categories.length; i++) {
            const categoryProperties = this.props.typeProperties.filter(p => p.systemValue?.property?.category === categories[i]);
            const rowsNumber = categoryProperties.length;
            for (let j = 0; j < categoryProperties.length; j++) {
                rows.push(<Table.Row key={j}>
                    {j === 0 && <Table.Cell textAlign="center" rowSpan={rowsNumber} verticalAlign="middle">
                        {categories[i] === ProteusConstants.METADATA_PROPERTY_TYPE_CATEGORY ? _msg("type.label") : categories[i] === ProteusConstants.METADATA_PROPERTY_EJOB_CATEGORY ? _msg("ejob.label") : ''}
                    </Table.Cell>}
                    <Table.Cell verticalAlign="middle">{categoryProperties[j].systemValue?.property?.description}</Table.Cell>
                    <Table.Cell warning={this.entityHasBeenModified(categoryProperties[j])} textAlign="center" verticalAlign="middle" className="RegistrationMetadata_tableCell">
                        {this.renderTableCell(categoryProperties[j])}
                    </Table.Cell>
                </Table.Row>)
            }
        }
        return rows;
    }

    renderTypeStatusTable() {
        let headerCell = [];
        for (let i = 0; i < this.props.registrationTypeStatusRelation.length; i++) {
            headerCell.push(<Table.HeaderCell width={2} key={i + 2} textAlign="center" verticalAlign="middle">{this.props.registrationTypeStatusRelation[i].status?.description}
                {this.props.currentMetadataContext.code === ProteusConstants.SYSTEM && <><Button negative icon="delete" className="RegistrationMetadata_removeStatusRelation" onClick={(e) => {
                    this.props.dispatchers.setInReduxState({
                        columnToBeDeleted: this.props.registrationStatuses.filter((item: any) => String(item.id) === String(e.currentTarget.value))[0], confirmDeleteStatus: true
                    });
                }} value={this.props.registrationTypeStatusRelation[i].status?.id} />
                    <ModalExt
                        severity={Severity.INFO}
                        open={this.props.confirmDeleteStatus && this.props.registrationTypeStatusRelation[i].status?.id === this.props.columnToBeDeleted.id}
                        header={_msg("RegistrationStatus.deleteConfirmation.header.label", this.props.registrationTypeStatusRelation[i].status?.description)}
                        content={_msg("RegistrationStatus.deleteConfirmation.content.label")}
                        onClose={this.onDeleteStatusCancel}
                        actions={[
                            <Button key="cancel" onClick={this.onDeleteStatusCancel}>{_msg("general.cancel")}</Button>,
                            <Button key="ok" primary onClick={this.onDeleteStatusConfirm}>{_msg("general.ok")}</Button>
                        ]}
                    />
                </>}
            </Table.HeaderCell>)
        }
        return (<Table celled structured compact unstackable>
            <Table.Header>
                <Table.Row verticalAlign="middle">
                    <Table.HeaderCell colSpan={this.props.registrationTypeStatusRelation.length + 2} textAlign="center" verticalAlign="middle">
                        {_msg("RegistrationMetadata.statuses.label")}
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row verticalAlign="middle">
                    <Table.HeaderCell key={0} width={1}></Table.HeaderCell>
                    <Table.HeaderCell key={1} width={3} textAlign="center">{_msg("name.label")}</Table.HeaderCell>
                    {headerCell}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {this.renderTypeStatusTableRows()}
            </Table.Body>
        </Table>)
    }

    protected onDeleteStatusCancel = () => {
        this.props.dispatchers.setInReduxState({ confirmDeleteStatus: false, columnToBeDeleted: undefined });
    }

    protected onDeleteStatusConfirm = () => {
        this.deleteTypeStatusRelation();
        this.props.dispatchers.setInReduxState({ confirmDeleteStatus: false });
    }

    renderTypeStatusTableRows() {
        let categories = [] as any[];
        let rows = [];
        for (let i = 0; i < this.props.typeStatusProperties.length; i++) {
            if (!categories.includes(this.props.typeStatusProperties[i].systemValue?.property?.category)) {
                categories.push(this.props.typeStatusProperties[i].systemValue?.property?.category)
            }
        }
        //find all categories and sort them
        categories = categories.sort((a: string, b: string) => a > b ? 1 : -1);
        for (let i = 0; i < categories.length; i++) {
            //group properties by category and sort the properties by description and registrationStatus
            let propertiesFromCategory = this.props.typeStatusProperties.filter((item: any) => item.systemValue.property.category === categories[i]);
            propertiesFromCategory = propertiesFromCategory.sort((a: any, b: any) => a.systemValue.property.description > b.systemValue.property.description ?
                1 : (a.systemValue.property.description < b.systemValue.property.description ? -1 : (a.systemValue.registrationStatus > b.systemValue.registrationStatus ? 1 : -1)));
            if (this.props.registrationTypeStatusRelation.length > 0) {
                //propertiesFromCategory length = number of different properties * number of columns in table (registrationTypeStatusRelation length)
                for (let j = 0; j < propertiesFromCategory.length; j = j + this.props.registrationTypeStatusRelation.length) {
                    let currentProperty = [];
                    for (let k = 0; k < this.props.registrationTypeStatusRelation.length; k++) {
                        currentProperty.push(propertiesFromCategory[j + k])
                    }
                    rows.push(<Table.Row key={i + "" + j} verticalAlign="middle">
                        {j === 0 && <Table.Cell textAlign="center" verticalAlign="middle" rowSpan={propertiesFromCategory.length / this.props.registrationTypeStatusRelation.length}>
                            {categories[i].toLowerCase().charAt(0).toUpperCase() + categories[i].toLowerCase().slice(1)}</Table.Cell>}
                        {this.renderTypeStatusTableRow(currentProperty)}
                    </Table.Row>)
                }
            }
        }
        return rows
    }

    renderTypeStatusTableRow(property: any) {
        let row = [];
        row.push(<Table.Cell key={0} verticalAlign="middle">{property[0].systemValue.property.description}</Table.Cell>)
        for (let i = 0; i < property.length; i++) {
            row.push(<Table.Cell warning={this.entityHasBeenModified(property[i])} key={i + 1} textAlign="center" verticalAlign="middle" className="RegistrationMetadata_tableCell">
                {this.renderTableCell(property[i])}
            </Table.Cell>)
        }
        return row;
    }

    // Get options for layoutTypeCode, defaultStatusCode and roundingTypes properties. Convert an entity of type CodeEntity to an object of type DropdownItemProps
    protected getOptionsForCustomDropdown(entity: CodeEntity[]) {
        return entity.map((item: CodeEntity) => ({
            key: item.code as string,
            text: item.description as string,
            value: item.code as string
        }));
    }

    renderTableCell(property: any) {
        if (property !== undefined) {
            const tableProperties = property.systemValue.registrationStatus === null ? this.props.typeProperties : this.props.typeStatusProperties;
            let disabled: boolean = !this.props.isAdministrator || (this.getPropertyTypesToLoad() === SYSTEM_CONTEXT_USER_PROPERTIES &&
                property.systemValue.property.userOverridePossible === false);
            let propertyValue = this.getPropertyValue(property);

            //check if the property has beed edited. If it was, show the edited value
            const editedProperty = this.props.propertiesToBeSaved.filter((item: any) => (item.id !== null && item.id !== undefined && item.id === propertyValue.id) ||
                ((item.id === null || item.id === undefined) && item.property !== null && item.property !== undefined && item.property.id === propertyValue.property.id &&
                    ((item.registrationStatus === propertyValue.registrationStatus && item.registrationStatus !== null) || (item.registrationStatus === null))) ||
                (item.systemPropertyValue === propertyValue.systemPropertyValue && item.systemPropertyValue !== null && item.systemPropertyValue !== undefined));

            if (editedProperty.length > 0) {
                propertyValue = editedProperty[0];
            }
            // for layoutType and defaultRegistrationStatus field, use a dropdown instead of a string input
            if (property.systemValue.property.code === ProteusConstants.LAYOUT_TYPE_CODE) {
                return this.renderCustomDropdown(disabled, propertyValue, editedProperty, tableProperties, this.getOptionsForCustomDropdown(this.props.layoutTypes));
            } else if (property.systemValue.property.code === ProteusConstants.DEFAULT_STATUS_CODE) {
                return this.renderCustomDropdown(disabled, propertyValue, editedProperty, tableProperties, this.getOptionsForCustomDropdown(this.props.registrationStatuses));
            } else if (property.systemValue.property.code === ProteusConstants.ROUNDING_TYPE_CODE) {
                return this.renderCustomDropdown(disabled, propertyValue, editedProperty, tableProperties, this.getOptionsForCustomDropdown(this.props.roundingTypes));
            } else if (property.systemValue.property.code === ProteusConstants.COMPENSATION_LOSS_CODE) {
                return this.renderCustomDropdown(disabled, propertyValue, editedProperty, tableProperties, this.getOptionsForCustomDropdown(this.props.compensationLossRoundings));
            } else if (this.getSystemPropertiesCode().includes(property.systemValue.property.code)) {
                return this.renderCustomDropdown(disabled, propertyValue, editedProperty, tableProperties, this.getOptionsForSystemProperties());
            } else if (property.systemValue.property.code === ProteusConstants.EJOB_METADATA_TYPE_CODE) {
                return this.renderCustomDropdown(disabled, propertyValue, editedProperty, tableProperties, this.props.ejobMetadataTypeOptions);
            } else if (property.systemValue.property.code === ProteusConstants.EJOB_SHIP_REQUIRED_CODE) {
                return this.renderCustomDropdown(disabled, propertyValue, editedProperty, tableProperties, this.props.ejobShipAttendanceOptions);
            } else if (property.systemValue.property.dataType === ProteusConstants.BOOLEAN) {
                return <Checkbox disabled={disabled} checked={propertyValue.value} onChange={() =>
                    this.modifyPropertyValue(propertyValue, !propertyValue.value, editedProperty, tableProperties)
                }></Checkbox>
            } else if (property.systemValue.property.dataType === ProteusConstants.STRING) {
                return <Input fluid disabled={disabled} value={propertyValue.value || ""} onChange={(e: any, data: any) => {
                    this.modifyPropertyValue(propertyValue, data.value, editedProperty, tableProperties)
                }}></Input>
            } else if (property.systemValue.property.dataType === ProteusConstants.INTEGER && property.systemValue.property.code.toUpperCase().endsWith(ProteusConstants.COLOR)) {
                return this.colorPickerRenderer(propertyValue, editedProperty, tableProperties, disabled);
            } else if (property.systemValue.property.dataType === ProteusConstants.INTEGER || property.systemValue.property.dataType === ProteusConstants.DOUBLE) {
                let step = property.systemValue.property.dataType === ProteusConstants.INTEGER ? 1 : 0.1;
                return <Input fluid disabled={disabled} type="number" step={step} value={propertyValue.value || 0} onChange={(e: any, data: any) => {
                    this.modifyPropertyValue(propertyValue, Number(data.value), editedProperty, tableProperties);
                }}></Input>
            } else if (property.systemValue.property.dataType === ProteusConstants.COLOR) {
                return <Checkbox disabled={disabled} />
            }
        }
        return "";
    }

    protected getSystemPropertiesCode() {
        return [ProteusConstants.READ_ROLE, ProteusConstants.CREATE_ROLE, ProteusConstants.UPDATE_ROLE, ProteusConstants.DELETE_ROLE];
    }

    protected getOptionsForSystemProperties() {
        return [
            { key: "DIRECT", text: "DIRECT", value: "DIRECT" },
            { key: "NOT_ALLOWED", text: "NOT_ALLOWED", value: "NOT_ALLOWED" },
            { key: "VIA_WF", text: "VIA_WF", value: "VIA_WF" }
        ];
    }

    // Dropdown used for layoutTypeCode, defaultStatusCode, roundingTypeCode and system properties
    protected renderCustomDropdown(disabled: boolean, propertyValue: any, editedProperty: any, tableProperties: any, options: { key: string, text: string, value: string }[]) {
        return <Dropdown fluid floating selectOnBlur={false} scrolling wrapSelection search={true} options={options}
            selection value={propertyValue.value} disabled={disabled} noResultsMessage={_msg("general.noResultsFound")} clearable={true}
            onChange={(e, data) => this.modifyPropertyValue(propertyValue, data.value, editedProperty, tableProperties)} />
    }

    /**
    * Check if the property has beed edited (is in propertyToBeSaved array)
    * There are more cases because the propertyToBeSaved can be of type propertiesValues_systemValue or propertiesValues_userValues
    */
    entityHasBeenModified(property: any) {
        if (!property) {
            // the property can be undefined in case a new status has been added and the table rerenders before its values are loaded.
            return false;
        }

        return this.props.propertiesToBeSaved.filter((item: any) => (item.id === this.getPropertyValue(property).id && item.id) ||
            (item.systemPropertyValue === this.getPropertyValue(property).systemPropertyValue && item.systemPropertyValue) ||
            (!item.id && item.property && item.property.id === this.getPropertyValue(property).property.id &&
                item.registrationStatus === this.getPropertyValue(property).registrationStatus)).length > 0;
    }

    modifyPropertyValue(propertyValue: any, newValue: any, editedProperty: any, tableProperties: any) {
        let newProperty = { ...propertyValue, value: newValue };
        //if the property has been edited, check if the new value is equal to initial value
        //if the values are equal, it means that the current cell should not be shown as modified, so delete the property from the propertiesToBeSaved array
        //if the values are not equal, add the property to propertiesToBeSaved array
        if (editedProperty.length > 0) {
            //filteredEditedProperties are all the properties that are edited, excepting the newProperty
            let filteredEditedProperties = this.props.propertiesToBeSaved.filter((item: any) => ((item.id !== null && item.id !== propertyValue.id) ||
                (item.id === null && item.property !== null && (item.property.id !== propertyValue.property.id || (item.registrationStatus !== null &&
                    item.registrationStatus !== propertyValue.registrationStatus)))) || (item.systemPropertyValue !== null && item.systemPropertyValue !== undefined &&
                        item.systemPropertyValue !== propertyValue.systemPropertyValue));

            let initialProperty = tableProperties.filter((item: any) => ((newProperty.property !== null && newProperty.property !== undefined &&
                item.systemValue.property.id === newProperty.property.id && (item.systemValue.registrationStatus === null || (item.systemValue.registrationStatus !== null &&
                    item.systemValue.registrationStatus === newProperty.registrationStatus))) || item.systemValue.id === newProperty.systemPropertyValue))[0];

            if (this.getPropertyValue(initialProperty).value !== newProperty.value) {
                filteredEditedProperties.push(newProperty);
            }
            this.props.dispatchers.setInReduxState({ propertiesToBeSaved: filteredEditedProperties });
        } else {
            this.props.dispatchers.setInReduxState({ propertiesToBeSaved: this.props.propertiesToBeSaved.concat([newProperty]) });
        }
    }

    colorPickerRenderer(propertyValue: any, editedProperty: any, tableProperties: any, disabled: boolean) {
        let propertyToBePushed = this.getPropertyTypesToLoad() === SYSTEM_CONTEXT_PROPERTIES || this.getPropertyTypesToLoad() === SYSTEM_PROPERTIES ?
            propertyValue.property.code + "_" + propertyValue.registrationStatus : propertyValue.systemPropertyValue;
        return (<div>
            <div className="ColorPicker_swatch RegistrationMetadata_colorPicker" onClick={() => {
                if (disabled) {
                    return;
                }
                let currentColorPicker = this.props.displayedColorPicker.filter((item: any) => item === propertyToBePushed);
                let filteredColorPicker = this.props.displayedColorPicker.filter((item: any) => item !== propertyToBePushed);
                if (currentColorPicker.length === 0) {
                    filteredColorPicker.push(propertyToBePushed);
                }
                this.props.dispatchers.setInReduxState({ displayedColorPicker: filteredColorPicker });
            }}>
                <div className="ColorPicker_color" style={{ backgroundColor: Utils.convertColorToHex(propertyValue.value) }} />
            </div>
            {this.props.displayedColorPicker.filter((item: any) => item === propertyToBePushed).length > 0 ?
                <div className="ColorPicker_popover">
                    <div className="ColorPicker_cover" onClick={() => {
                        this.props.dispatchers.setInReduxState({ displayedColorPicker: this.props.displayedColorPicker.filter((item: any) => item !== propertyToBePushed) })
                    }} />
                    <SketchPicker disableAlpha color={Utils.convertColorToHex(propertyValue.value)} onChange={(color) => {
                        this.modifyPropertyValue(propertyValue, Utils.convertColorFromHex(color.hex), editedProperty, tableProperties)
                    }} />
                </div> : null}
        </div>)
    }

    getPropertyValue(property: any) {
        if (this.getPropertyTypesToLoad() === SYSTEM_CONTEXT_USER_PROPERTIES) {
            if (property.userValue) {
                return property.userValue;
            } else {
                return {
                    systemPropertyValue: property.systemValue.id,
                    context: this.props.currentMetadataContext.id,
                    value: property.contextValue ? property.contextValue.value : property.systemValue.value,
                    objectVersion: 0,
                    user: ProteusUtils.getCurrentUser().id
                }
            }
        } else if (this.getPropertyTypesToLoad() === SYSTEM_CONTEXT_PROPERTIES) {
            if (property.contextValue) {
                return property.contextValue;
            } else {
                return { ...property.systemValue, context: this.props.currentMetadataContext.id, id: null, objectVersion: null, creationDate: null, creationUser: null };
            }
        }
        return property.systemValue;
    }

    protected getTitle() {
        return { icon: "paint brush", title: _msg("RegistrationMetadata") };
    }

    protected changeContextTab(newTab: any) {
        if (this.props.isAdministrator) {
            this.props.dispatchers.setInReduxState({ currentMetadataContext: this.props.metadataContexts[newTab.activeIndex! as number] })
        } else {
            this.props.dispatchers.setInReduxState({ currentMetadataContext: this.props.metadataContexts[Number(newTab.activeIndex!) + 1] })
        }
    }

    /**
    * This method is used by Tree to prevent changing the current selected item in tree if it is modified
    */
    protected onSelectItem = (item: any) => {
        if (this.props.propertiesToBeSaved.length > 0) {
            this.props.dispatchers.setInReduxState({ confirmLeavePage: true, newOperationToBeConfirmed: { newSelectedElementInTree: item.itemId } });
            item.prevent = true;
        } else {
            this.props.dispatchers.setInReduxState({ currentSelectedItem: item.itemId });
        }
    }

    /**
    * Because on the server side this fields are checked for null value, this function converts it to null if the value is ""
    * The value can be empty ("") if the field has been completed and deleted
    */
    protected convertFromEmptyToNull(itemToBeSaved: any) {
        for (let key of ["code", "name", "description"]) {
            if (itemToBeSaved[key] === "") {
                itemToBeSaved[key] = null;
            }
        }
        return itemToBeSaved;
    }

    protected onTreeSearchChange = (data: string) => {
        if (this.props.propertiesToBeSaved.length === 0) {
            this.registrationTypeCategoryTreeRef.current?.props.r.filterTree(this.registrationTypeCategoryTreeRef.current?.props.s.registrationTypeTree, data);
            this.registrationTypeCategoryTreeRef.current?.setTreeSelectedId(undefined);
            this.props.dispatchers.setInReduxState({ currentSelectedItem: undefined });
        } else {
            this.props.dispatchers.setInReduxState({ confirmLeavePage: true, newOperationToBeConfirmed: { newSearchedRegistrationType: data } });
        }
    }

    protected onTreeCollapse = () => {
        if (this.props.propertiesToBeSaved.length > 0) {
            this.props.dispatchers.setInReduxState({ newOperationToBeConfirmed: { treeWillBeCollapsed: true }, confirmLeavePage: true });
        } else {
            this.registrationTypeCategoryTreeRef.current?.registrationTreeRef.current?.props.r.collapseAll(
                this.registrationTypeCategoryTreeRef.current?.registrationTreeRef.current?.props.root, {}
            );
        }
    }

    /**
    * This method is used by Tree to prevent expanding/collapsing elements in tree if the current selected element is modified
    */
    protected onExpandCollapseItem = (item: any) => {
        if (this.props.propertiesToBeSaved.length !== 0) {
            this.props.dispatchers.setInReduxState({ expandCollapseItemId: item.itemId, confirmLeavePage: true });
            item.prevent = true;
        }
    }

    protected onLeavePageCancel = () => {
        if (this.props.confirmDeleteRegistration) {
            this.props.dispatchers.setInReduxState({ confirmDeleteRegistration: false });
        }
        this.props.dispatchers.setInReduxState({ confirmLeavePage: false, newOperationToBeConfirmed: undefined, expandCollapseItemId: undefined });
    }

    protected onLeavePageConfirm = async () => {
        //"leave page" confirmation was called by changing the selected metadata context tab
        if (this.props.newOperationToBeConfirmed?.newSelectedTab !== undefined) {
            this.changeContextTab(this.props.newOperationToBeConfirmed?.newSelectedTab);
        } else if (this.props.newOperationToBeConfirmed?.newSelectedElementInTree !== undefined) {
            if (this.props.expandCollapseItemId !== undefined) {
                const isExpand = this.props.expandCollapseItemId in this.registrationTypeCategoryTreeRef.current!.registrationTreeRef.current!.props.s.expandedIds;
                this.registrationTypeCategoryTreeRef.current?.registrationTreeRef.current?.props.r.expandCollapse(
                    this.registrationTypeCategoryTreeRef.current?.registrationTreeRef.current?.props.root,
                    this.props.expandCollapseItemId,
                    !isExpand, {});
            } else {
                //"leave page" confirmation was called by changing the selected element in tree
                this.registrationTypeCategoryTreeRef.current?.setTreeSelectedId(this.props.newOperationToBeConfirmed.newSelectedElementInTree);
                this.props.dispatchers.setInReduxState({ currentSelectedItem: this.props.newOperationToBeConfirmed.newSelectedElementInTree });

            }
        } else if (this.props.newOperationToBeConfirmed?.newObjectToBeEdited !== undefined) {
            //"leave page" confirmation was called by trying to edit an element in tree
            this.openFormLight(this.props.newOperationToBeConfirmed.newObjectToBeEdited);
        } else if (this.props.newOperationToBeConfirmed?.treeWillBeCollapsed) {
            //"leave page" confirmation was called by clicking on "collapse all" button
            this.registrationTypeCategoryTreeRef.current?.registrationTreeRef.current?.props.r.collapseAll(
                this.registrationTypeCategoryTreeRef.current?.registrationTreeRef.current?.props.root, {}
            );
        } else if (this.registrationTypeCategoryTreeRef.current?.props.s.searchedRegistrationType !== this.props.newOperationToBeConfirmed?.newSearchedRegistrationType &&
            this.props.newOperationToBeConfirmed?.newSearchedRegistrationType !== undefined) {
            //"leave page" confirmation was called by searching an element in tree
            this.registrationTypeCategoryTreeRef.current?.props.r.filterTree(this.registrationTypeCategoryTreeRef.current?.props.s.registrationTypeTree, this.props.newOperationToBeConfirmed.newSearchedRegistrationType);
            this.registrationTypeCategoryTreeRef.current?.setTreeSelectedId(undefined);
            this.props.dispatchers.setInReduxState({ currentSelectedItem: undefined });
            this.registrationTypeCategoryTreeRef.current?.props.r.setInReduxState({ searchedRegistrationType: this.props.newOperationToBeConfirmed.newSearchedRegistrationType });
        } else if (this.props.newOperationToBeConfirmed?.newContextToBeAdded !== undefined) {
            //"leave page" confirmation was called by trying to add a new context
            this.openFormLight(this.props.newOperationToBeConfirmed.newContextToBeAdded);
        } else if (this.props.newOperationToBeConfirmed?.refreshPage) {
            //"leave page" confirmation was called by trying to refresh the page using the refresh button
            this.props.dispatchers.setInReduxState({ confirmLeavePage: false });
            await this.refresh();
        } else {
            //"leave page" confirmation was called by changing the "administrator" checkbox value
            this.props.dispatchers.setInReduxState({ isAdministrator: !this.props.isAdministrator });
        }
        this.props.dispatchers.setInReduxState({ newOperationToBeConfirmed: undefined, confirmLeavePage: false, propertiesToBeSaved: [], displayedColorPicker: [] });
    };

    toggleAdministrator() {
        if (this.props.propertiesToBeSaved.length > 0) {
            this.props.dispatchers.setInReduxState({ confirmLeavePage: true });
        } else {
            this.props.dispatchers.setInReduxState({ isAdministrator: !this.props.isAdministrator, propertiesToBeSaved: [], displayedColorPicker: [] });
            if (!this.props.selectedRegistrationTypeOrCategory) { return; }
            if (!this.props.isAdministrator) {
                const systemContext = this.props.metadataContexts.filter((item: any) => item.code === ProteusConstants.SYSTEM)[0];
                this.props.dispatchers.setInReduxState({ currentMetadataContext: systemContext });
            } else {
                this.props.dispatchers.setInReduxState({ currentMetadataContext: this.props.metadataContexts[1] });
            }
        }
    }

    protected renderMain() {
        return (<div className="RegistrationMetadata">
            <LeavePageModal when={this.props.propertiesToBeSaved.length > 0} />
            <ModalExt
                severity={Severity.WARNING}
                open={this.props.confirmLeavePage}
                content={_msg("unsavedDataWarning.label")}
                header={_msg("warning.label")}
                onClose={this.onLeavePageCancel}
                actions={[
                    <Button key="cancel" onClick={this.onLeavePageCancel}>{_msg("general.cancel")}</Button>,
                    <Button key="ok" primary onClick={this.onLeavePageConfirm}>{_msg("general.ok")}</Button>
                ]}
            />
            <EntityFormLight id="RegistrationMetadata_entityFormLight" ref={this.entityFormLightRef} onSubmit={(p: any) => {
                let itemToBeSaved = { ...p.values };
                if (itemToBeSaved.__typename === METADATA_CONTEXT) {
                    this.saveMetadataContext(this.convertFromEmptyToNull({ ...itemToBeSaved }));
                } else {
                    if (itemToBeSaved.__typename === REGISTRATION_TYPE && itemToBeSaved.id === undefined) {
                        itemToBeSaved = { ...itemToBeSaved, category: { ...itemToBeSaved.category, types: itemToBeSaved.category.types.concat(itemToBeSaved) }, __typename: REGISTRATION_TYPE };
                    } else if (itemToBeSaved.__typename === REGISTRATION_TYPE_CATEGORY && itemToBeSaved.id === undefined) {
                        if (itemToBeSaved.parentCategory !== undefined) {
                            itemToBeSaved = { ...itemToBeSaved, parentCategory: { ...itemToBeSaved.parentCategory, categoryChildren: itemToBeSaved.parentCategory.categoryChildren?.concat(itemToBeSaved) }, __typename: REGISTRATION_TYPE_CATEGORY };
                        } else {
                            itemToBeSaved = { ...itemToBeSaved, __typename: REGISTRATION_TYPE_CATEGORY };
                        }
                    }
                    this.saveOrUpdateRegistrationTypeCategory(this.convertFromEmptyToNull({ ...itemToBeSaved }));
                }
            }}
                entityDescriptor={this.typeCategoryAndContextDescriptor} saveDisabled={!this.props.isAdministrator}
                headerContent={this.props.editorHearderContent.text}
                headerIcon={this.props.editorHearderContent.icon}
            />
            <Segment>
                <Button disabled={!this.props.isAdministrator || this.props.selectedRegistrationTypeOrCategory?.__typename === REGISTRATION_TYPE || this.registrationTypeCategoryTreeRef.current?.props.s.searchedRegistrationType !== ""}
                    icon color="green" onClick={() => {
                        let newCategory = {
                            name: "Nieuwe category", __typename: REGISTRATION_TYPE_CATEGORY,
                            parentCategory: this.props.selectedRegistrationTypeOrCategory?.id !== undefined ? this.props.selectedRegistrationTypeOrCategory : undefined, environment: ProteusConstants.BRABO
                        };
                        this.openFormLight(newCategory);
                    }}><Icon name="add" /> {_msg("newCategory.label")}</Button>
                <Button disabled={!this.props.isAdministrator || this.registrationTypeCategoryTreeRef.current?.registrationTreeRef.current?.props.s.selectedId === undefined || this.props.selectedRegistrationTypeOrCategory?.__typename === REGISTRATION_TYPE
                    || this.registrationTypeCategoryTreeRef.current?.props.s.searchedRegistrationType !== ""}
                    icon color="green" onClick={() => {
                        let newType = { name: "Nieuwe type", __typename: REGISTRATION_TYPE, category: this.props.selectedRegistrationTypeOrCategory, environment: ProteusConstants.BRABO };
                        this.openFormLight(newType);
                    }}><Icon name="add" /> {_msg("newType.label")}</Button>
                <Button disabled={!this.props.isAdministrator || this.registrationTypeCategoryTreeRef.current?.registrationTreeRef.current?.props.s.selectedId === undefined || this.props.selectedRegistrationTypeOrCategory?.__typename !== REGISTRATION_TYPE}
                    icon color="green" onClick={() => {
                        let newContext = { code: "CONTEXT", name: "context", environment: ProteusUtils.getEnvironmentId(), __typename: METADATA_CONTEXT };
                        if (this.props.propertiesToBeSaved.length === 0) {
                            this.openFormLight(newContext);
                        } else {
                            this.props.dispatchers.setInReduxState({ newOperationToBeConfirmed: { newContextToBeAdded: newContext }, confirmLeavePage: true });
                        }
                    }}><Icon name="add" /> {_msg("newContext.label")}</Button>
                <Button primary disabled={this.props.propertiesToBeSaved.length === 0} onClick={() => {
                    this.savePropertiesValues();
                }}>{_msg("general.save")}</Button>
                <Button icon="refresh" color="green" onClick={async () => {
                    if (this.props.propertiesToBeSaved.length === 0) {
                        await this.refresh();
                    } else {
                        this.props.dispatchers.setInReduxState({ newOperationToBeConfirmed: { refreshPage: true }, confirmLeavePage: true });
                    }
                }} />
                <Checkbox label={_msg("administrator.label")} checked={this.props.isAdministrator}
                    onChange={() => this.toggleAdministrator()}>
                </Checkbox>
            </Segment>
            <Container fluid>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width="5" className="RegistrationMetadata_treeColumn">
                            <RegistrationTypeCategoryTree id="RegistrationTypeCategoryTree" ref={this.registrationTypeCategoryTreeRef} onSearchChange={this.onTreeSearchChange}
                                onTreeCollapse={this.onTreeCollapse} onSelectItem={this.onSelectItem} hasStatuses={false} hasCheckboxes={false}
                                onExpandCollapseItem={this.onExpandCollapseItem}
                                handleDelete={this.props.isAdministrator ? this.handleDelete : undefined}
                                handleEdit={this.handleEdit}
                            />
                        </Grid.Column>
                        <Grid.Column width="11" style={{display: "flex"}}>
                            {this.props.selectedRegistrationTypeOrCategory !== undefined && this.props.selectedRegistrationTypeOrCategory?.__typename === REGISTRATION_TYPE &&
                                <>
                                    <div className="RegistrationMetadata_registrationCode">{this.props.selectedRegistrationTypeOrCategory.name! + " (" + this.props.selectedRegistrationTypeOrCategory.code + ")"}</div>
                                    <Tab className="RegistrationMetadata_tab flex-grow" panes={this.renderTabPanes()}
                                        onTabChange={(e: any, data) => {
                                            if (this.props.propertiesToBeSaved.length > 0) {
                                                this.props.dispatchers.setInReduxState({ confirmLeavePage: true, newOperationToBeConfirmed: { newSelectedTab: data } });
                                            } else {
                                                this.changeContextTab(data);
                                            }
                                        }} activeIndex={this.props.metadataContexts.length > 0 ? (this.props.isAdministrator ?
                                            this.props.metadataContexts.map((item: any) => item.id).indexOf(this.props.currentMetadataContext.id) :
                                            this.props.metadataContexts.map((item: any) => item.id).indexOf(this.props.currentMetadataContext.id) - 1) : 0} />
                                </>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            {this.renderDeleteModal()}
        </div>);
    }

    getPrevRefSnapshot() {
        this.prevRegistrationTypeCategoryTreeRef = {
            selectedId: this.props.currentSelectedItem,
        };

    }

    getEjobValuesFromSettings() {
        const { enumSettings } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
        const ejobMetadataTypeSettings = enumSettings.forProperties.filter((p: any) => p.name === ProteusConstants.EJOB_METADATA_TYPE)[0];
        const ejobShipAttendanceSettings = enumSettings.forProperties.filter((p: any) => p.name === ProteusConstants.EJOB_SHIP_ATTENDANCE)[0];
        const metadataTypeOptions = ejobMetadataTypeSettings?.values.map((value: ValueField) => ({
            key: value.value,
            text: value.value,
            value: value.value
        }));
        const shipAttendanceOptions = ejobShipAttendanceSettings?.values.map((value: ValueField) => ({
            key: value.value,
            text: value.value,
            value: value.value
        }));
        this.props.dispatchers.setInReduxState({ ejobMetadataTypeOptions: metadataTypeOptions, ejobShipAttendanceOptions: shipAttendanceOptions })
    }

    componentDidMount() {
        this.getPrevRefSnapshot();
        this.loadMetadataContexts(false);
        this.getRegistrationStatuses();
        this.getLayoutTypes();
        this.getRoundingTypes();
        this.getCompensationLossRoundings();
        this.getEjobValuesFromSettings();
    }

    setRegistration(registration: RegistrationType & { __typename: string; } | RegistrationTypeCategory_types_category & { __typename: string; }) {
        this.props.dispatchers.setInReduxState({ selectedRegistrationTypeOrCategory: registration, propertiesToBeSaved: [] });
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.currentSelectedItem !== this.prevRegistrationTypeCategoryTreeRef.selectedId) {
            if (this.props.currentSelectedItem) {
                const selectedId = this.props.currentSelectedItem.split(Utils.defaultIdSeparator);
                if (selectedId) {
                    const registration = Utils.navigate(this.registrationTypeCategoryTreeRef.current?.registrationTreeRef.current?.props.root, selectedId);
                    this.setRegistration(registration);
                }
            } else {
                this.props.dispatchers.setInReduxState({ selectedRegistrationTypeOrCategory: undefined, propertiesToBeSaved: [], displayedColorPicker: [] });
            }
        }
        if ((this.props.isAdministrator !== prevProps.isAdministrator && this.props.selectedRegistrationTypeOrCategory?.__typename === REGISTRATION_TYPE) ||
            (this.props.selectedRegistrationTypeOrCategory?.__typename === REGISTRATION_TYPE && this.props.selectedRegistrationTypeOrCategory?.id !== prevProps.selectedRegistrationTypeOrCategory?.id) ||
            (prevProps.currentMetadataContext.id && this.props.currentMetadataContext.id !== prevProps.currentMetadataContext.id)) {
            this.props.dispatchers.setInReduxState({ selectedStatusForNewRelation: undefined, propertiesToBeSaved: [], displayedColorPicker: [], searchedTypeStatusProperty: '' });
            this.loadPropertiesValues(true);
        }

        if (this.props.selectedRegistrationTypeOrCategory?.__typename === REGISTRATION_TYPE && this.props.selectedRegistrationTypeOrCategory?.id !== prevProps.selectedRegistrationTypeOrCategory?.id) {
            this.getRegistrationTypeStatusRelation();
        }
        this.getPrevRefSnapshot();
    }
}

export const infoRegistrationMetadata = new ConnectedPageInfo(sliceRegistrationMetadata, RegistrationMetadata, "RegistrationMetadata");
infoRegistrationMetadata.routeProps = { permission: "METADATA_VIEW" };