'use strict';

/**
 *
 * This editor can be used to display a realization or an exemption.
 * It returns a promise!
 * 
 *	data:  { 
 *		entity, 
 *		finalReviewer, 
 *		employee, 
 * }
 */
/*@ngInject*/
function RealizationEditorModal(FormModal, Curriculum, CurriculumConstants, $q) {
	
	return {

		open: function(data) {
			var deferred = $q.defer();
			
			var entity = angular.copy(data.entity);
			var isNewEntity = !entity.id;						
			var finalReviewer = data.finalReviewer;
			var title = 'curriculum.realization.editorTitle';
			
			FormModal.open({
				scope: {
					employee: data.employee,
					finalReviewer: finalReviewer,
					catalogItemObjective: data.catalogItemObjective,
					catalogs: {
						visible: ['COMPONENT'],
						editable: false,
						autofocusFilter: true, // TODO not working	
					},
					reviewersMap: data.reviewersMap,
					model: entity,
					title: title,
					disabled: data.disabled,
					template: '<realization-editor entity="model" x-disabled="disabled" catalogs="catalogs" employee="employee" final-reviewer="finalReviewer" reviewers-map="reviewersMap" catalog-item-objective="catalogItemObjective"></realization-editor>',
					modalBodyClass: 'realization-editor-max-width',
					panelCls: 'realization-editor-panel',
					isModelInvalid: function(model) {
						// Component and realization date are mandatory fields 
						return angular.isUndefined(model.date) || model.date === null
								|| angular.isUndefined(model.componentVersion) || model.componentVersion === null;
					}
				},
				actions: ['ok', 'cancel', 'close']
			}).result.then(function(result) {
				if (CurriculumConstants.REALIZATION == result.objectType) {
					if (result.result && !result.result.code) {
						result.result = null;
					}
					if (result.componentVersion) {
						var componentVersion  = result.componentVersion;
						for (var i in componentVersion.competenceVersions) {
							delete componentVersion.competenceVersions[i].levelDefinitions;
						}
					}
					return Curriculum.saveOrUpdateRealization({}, {
						data: result
					});
				} else if (CurriculumConstants.EXEMPTION == result.objectType) {
					return Curriculum.saveOrUpdateExemption({}, {
						data: result
					});
				}
			}).then(function(result) {
				if (data.onRealizationOrExemptionAdded) {
					data.onRealizationOrExemptionAdded(result);
				}
				deferred.resolve(result);
			}).catch(function(reason) {
				deferred.reject(reason);
			});
			
			return deferred.promise;
		}
	};
}

module.exports = RealizationEditorModal;