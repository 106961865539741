import { apolloClient, ApolloContext, apolloGlobalErrorHandler, CatchedGraphQLError, ConnectedPageInfo, createSliceFoundation, EntityDescriptor, EntityTableSimpleProps, EntityTableSimple, EntityTableSimpleReducers, EntityTableSimpleState, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, TestUtils } from "@crispico/foundation-react";
import React from "react";
import { Button, Dimmer, Form, Header, Icon, Input, Loader, Popup, Segment } from "semantic-ui-react";
import { FilterPopup, sliceFilterPopup } from "pages/freeTimeDispatchers/FilterPopup";
import { CellProps } from "fixed-data-table-2";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FieldRendererProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { ProteusUtils } from "ProteusUtils";
import moment from "moment";
import { FreeTimeSetFirstEmployeeParameterInput, FreeTimeStatusEnum } from "apollo-gen/globalTypes";
import { ProteusConstants } from "ProteusConstants";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import _, { partial, uniqueId } from "lodash";
import { FREE_TIME_OVERVIEW_CALCULATOR_CALL_EMPLOYEE, FREE_TIME_OVERVIEW_CALCULATOR_CLEAR_CACHE, FREE_TIME_OVERVIEW_CALCULATOR_GET_CACHE, FREE_TIME_OVERVIEW_CALCULATOR_GET_FREE_TIME_OVERVIEW, FREE_TIME_OVERVIEW_CALCULATOR_SET_EMPLOYEES_AS_FIRST, PLANNING_SERVICE_SAVE_OR_UPDATE_REGISTRATION } from "graphql/queries";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

export class EntityTableCustomizedRaw extends EntityTableSimple<EntityTableSimpleProps> {

    renderCell(props: CellProps) {
        const rowObject = this.getEntity(props.rowIndex!);
        if (rowObject.status === FreeTimeStatusEnum.FREE) {
            props.style = { 'color': '#439a46' };
        } else if (rowObject.status === FreeTimeStatusEnum.ABSENT) {
            props.style = { 'color': '#ff9800' };
        } else if (rowObject.status === FreeTimeStatusEnum.UNAVAILABLE) {
            props.style = { 'color': '#e51c23' };
        }
        return super.renderCell(props);
    }

    getHeight(): number {
        return this.state.measuredHeight ? this.state.measuredHeight : 0;
    }
}

export const EntityTableCustomized = ReduxReusableComponents.connectRRC(EntityTableSimpleState, EntityTableSimpleReducers, EntityTableCustomizedRaw);

interface Root {
    status: string
    sequence: number,
    employee: string,
    employeeId: number,
    category: string,
    team: string,
    freeTimeSuperGroup: number,
    internalTelephone: string,
    internalGsm: string,
    pilotNumber: number,
    municipality: string,
    fromDate: string,
    toDate: string,
    object: string,
    callCount: number,
    unavailablePeriods: { fromDate: string, toDate: string, object: string }[],
    absences: { fromDate: string, toDate: string, type: { name: string } }[],
    exchange: { fromDate: string, toDate: string, type: { name: string } } | null
}

export const sliceDispatchers = createSliceFoundation(class SliceDispatchers {

    initialState = {
        originalRoot: [] as Root[],
        timer: 60 as number,
        loading: false,
        firstEmployees: [] as FreeTimeSetFirstEmployeeParameterInput[]
    }

    nestedSlices = {
        filterPopup: sliceFilterPopup
    }

    reducers = {
        ...getBaseReducers<SliceDispatchers>(this),

        setCallCounter(state: StateFrom<SliceDispatchers>, employee: Root) {
            for (let i = 0; i < state.originalRoot.length; i++) {
                if (state.originalRoot[i].sequence === employee.sequence && state.originalRoot[i].team === employee.team && state.originalRoot[i].category === employee.category) {
                    state.originalRoot[i].callCount++;
                    break;
                }
            }
        },

        /**
         * Adds employees selected to be first in its supergroup. If other employee is selected for the same supergroup,
         * the old one will be removed from the list of employees to be changed.
         */
        addFirstEmployee(state: StateFrom<SliceDispatchers>, firstEmployee: FreeTimeSetFirstEmployeeParameterInput) {
            const alreadyExists = state.firstEmployees.filter(e => _.isEqual(e, firstEmployee)).length > 0;
            if (alreadyExists) {
                state.firstEmployees = state.firstEmployees.filter(e => !_.isEqual(e, firstEmployee));
                return;
            }
            const sameFreeTimeSuperGroupChangeExists = state.firstEmployees.filter(e => e.freeTimeSuperGroupId == firstEmployee.freeTimeSuperGroupId).length > 0;
            if (sameFreeTimeSuperGroupChangeExists) {
                state.firstEmployees = state.firstEmployees.filter(e => e.freeTimeSuperGroupId != firstEmployee.freeTimeSuperGroupId);
            }
            state.firstEmployees.push(firstEmployee);
        }
    }

    impures = {
        ...getBaseImpures<SliceDispatchers>(this),

        getFreeTimePeriod(employee: Root) {
            if (employee.unavailablePeriods.length > 0) {
                return employee.unavailablePeriods[0];
            } else if (employee.absences.length > 0) {
                return { fromDate: employee.absences[0].fromDate, toDate: employee.absences[0].toDate, object: employee.absences[0].type.name };
            } else if (employee.exchange !== null) {
                return { fromDate: employee.exchange.fromDate, toDate: employee.exchange.toDate, object: employee.exchange.type.name };
            }
            return null;
        },

        async getFreeTimeOverviewCalculator(category: string, clearCacheForCategory?: boolean) {
            return (await apolloClient.query({
                query: FREE_TIME_OVERVIEW_CALCULATOR_GET_FREE_TIME_OVERVIEW,
                variables: {
                    category: category,
                    clearCacheForCategory: clearCacheForCategory
                },
                context: {
                    showSpinner: false
                }
            })).data.freeTimeOverviewCalculator_freeTimeOverview;
        },

        async makeEmployeeUnavailable(resource: number) {
            const now = new Date();
            let parameter = {
                registration: {
                    fromDate: now,
                    toDate: now,
                    environment: ProteusConstants.BRABO,
                    resource
                },
                typeCode: ProteusConstants.BESCHIKBAAR_ONBESCHIKBAAR,
                statusCode: ProteusConstants.ONBESCHIKBAAR,
                errorPolicy: { policy: ProteusConstants.POLICY_ENFORCE_ALL },
                metadataContext: ProteusConstants.CONTEXT_FREE_TIME_SCREEN,
                split: false,
                useDefaultMetadataRounding: false
            }

            await apolloClient.mutate({
                mutation: PLANNING_SERVICE_SAVE_OR_UPDATE_REGISTRATION,
                variables: {
                    parameter: parameter
                },
                context: {
                    [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                        apolloGlobalErrorHandler(e);
                        return true;
                    }
                }
            });

            // refresh employees
            this.generateEmployees(ProteusConstants.PILOTS);
        },

        async generateCategory(category: string, joinEmployees: Root[], clearCacheForCategory?: boolean) {
            const employees = await this.getFreeTimeOverviewCalculator(category, clearCacheForCategory);
            const result: Root[] = [];
            for (let i = 0; i < employees.length; i++) {
                const employee = { ...employees[i] };
                const freeTimePeriod = this.getFreeTimePeriod(employee);
                if (freeTimePeriod?.fromDate) {
                    employee.fromDate = moment(new Date(freeTimePeriod.fromDate)).format(ProteusConstants.DATE_TIME_FORMAT);
                }
                if (freeTimePeriod?.toDate) {
                    employee.toDate = moment(new Date(freeTimePeriod.toDate)).format(ProteusConstants.DATE_TIME_FORMAT);
                }
                employee.object = freeTimePeriod?.object;
                employee.pilotNumber = employee.pilotNumber === null ? 0 : employee.pilotNumber;
                result.push(employee);
            }
            this.getDispatchers().setInReduxState({ originalRoot: [...joinEmployees, ...result] });
            return result;
        },

        async generateEmployees(filterCategory: string, clearCacheForCategory?: boolean, onlySelectedCategory?: boolean) {
            if (filterCategory == ProteusConstants.PILOTS) {
                this.getDispatchers().setInReduxState({ loading: true });
                const pilots = await this.generateCategory(ProteusConstants.PILOTS_CODE, [], clearCacheForCategory);
                this.getDispatchers().setInReduxState({ loading: false });
                !onlySelectedCategory && await this.generateCategory(ProteusConstants.BOOTMAN, pilots, clearCacheForCategory);
            } else {
                this.getDispatchers().setInReduxState({ loading: true });
                const boatmen = await this.generateCategory(ProteusConstants.BOOTMAN, [], clearCacheForCategory);
                this.getDispatchers().setInReduxState({ loading: false });
                !onlySelectedCategory && await this.generateCategory(ProteusConstants.PILOTS_CODE, boatmen, clearCacheForCategory);
            }
        },

        async callEmployee(employeeId: number, category: String) {
            await apolloClient.mutate({
                mutation: FREE_TIME_OVERVIEW_CALCULATOR_CALL_EMPLOYEE,
                variables: {
                    employeeId: employeeId,
                    category: category
                }
            });
        },

        async applyChanges() {
            await apolloClient.mutate({
                mutation: FREE_TIME_OVERVIEW_CALCULATOR_SET_EMPLOYEES_AS_FIRST,
                variables: {
                    changes: this.getState().firstEmployees
                }
            });
            this.getDispatchers().setInReduxState({ firstEmployees: [] });
            this.generateEmployees(this.getState().filterPopup.category, true, true);
        },
    
        async clearCache() {
            await apolloClient.query({
                query: FREE_TIME_OVERVIEW_CALCULATOR_CLEAR_CACHE,
                context: {
                    showSpinner: false
                }
            });
        },

        async loadCache() {
            await apolloClient.query({
                query: FREE_TIME_OVERVIEW_CALCULATOR_GET_CACHE,
                context: {
                    showSpinner: false
                }
            });
        }
    }
})

type PropsNotFromState = {
    /**
     * In this mode the refresh is disabled, category filter is disabled, additional columns are hidden
     * and call/make unavailable buttons are not shown. It also allows to add setFirstButton to employees.
     */
    rosterMode?: boolean,
    setFirstButton?: boolean,
    recalculateButton?: boolean,
}

export class Dispatchers extends TabbedPage<PropsFrom<typeof sliceDispatchers> & PropsNotFromState>{
    protected columns = [{ name: "sequence", width: 80 }, { name: "employee", width: 305 }, { name: "category", width: 120 }, { name: "team", width: 150 }];
    protected additionalColumns = [{ name: "internalTelephone", width: 120 }, { name: "internalGsm", width: 120 }, { name: "pilotNumber", width: 80 }, { name: "municipality", width: 220 },
    { name: "fromDate", width: 180 }, { name: "toDate", width: 180 }, { name: "object", width: 220 }];
    countdown: NodeJS.Timeout | undefined;
    entityDescriptor: any;
    employeeFieldRenderer: string = '';
    static i = 0;
    protected entityTableSimpleCustomizedRef = React.createRef<EntityTableCustomizedRaw>();

    setEntity() {
        const entities = this.getFilteredEmployees();
        this.entityTableSimpleCustomizedRef.current?.setEntities(entities);
    }

    getFilteredEmployees() {
        let partialState = this.props.originalRoot.filter((item: any) => (item.category === this.props.filterPopup.category));
        if (this.props.filterPopup.availableChecked && !this.props.filterPopup.unavailableChecked) {
            partialState = partialState.filter((item: any) => (item.status !== FreeTimeStatusEnum.UNAVAILABLE));
        } else if (!this.props.filterPopup.availableChecked && this.props.filterPopup.unavailableChecked) {
            partialState = partialState.filter((item: any) => (item.status === FreeTimeStatusEnum.UNAVAILABLE));
        } else if (!this.props.filterPopup.availableChecked && !this.props.filterPopup.unavailableChecked) {
            partialState = partialState.filter((item: any) => (item.status !== FreeTimeStatusEnum.ABSENT && item.status !== FreeTimeStatusEnum.FREE && item.status !== FreeTimeStatusEnum.UNAVAILABLE));
        }
        return partialState;
    }

    createEntityDescriptor = () => {
        this.employeeFieldRenderer = "colors" + Dispatchers.i;
        Dispatchers.i += 1;
        return new EntityDescriptor({
            name: "Dispatchers",
            icon: "male",
            miniFields: ["name"]
        }, false)
            .addFieldDescriptor({ name: "sequence", type: FieldType.number })
            .addFieldDescriptor({ name: "employee", type: this.employeeFieldRenderer })
            .addFieldDescriptor({ name: "category", type: FieldType.string })
            .addFieldDescriptor({ name: "team", type: FieldType.string })
            .addFieldDescriptor({ name: "freeTimeSuperGroup", type: FieldType.number })
            .addFieldDescriptor({ name: "internalTelephone", type: FieldType.string })
            .addFieldDescriptor({ name: "internalGsm", type: FieldType.string })
            .addFieldDescriptor({ name: "pilotNumber", type: FieldType.number })
            .addFieldDescriptor({ name: "municipality", type: FieldType.string })
            .addFieldDescriptor({ name: "fromDate", type: FieldType.string })
            .addFieldDescriptor({ name: "toDate", type: FieldType.string })
            .addFieldDescriptor({ name: "object", type: FieldType.string });
    }

    protected refresh = () => {
        if (TestUtils.storybookMode) {
            return;
        }
        this.props.dispatchers.generateEmployees(this.props.filterPopup.category);
    }

    protected getTitle() {
        return { icon: "table", title: _msg("Dispatchers.overview.label") };
    }

    protected getTabbedPageCssClasses() {
        return super.getTabbedPageCssClasses() + " Dispatchers";
    }

    componentDidMount() {
        if (!this.props.rosterMode) {
            this.columns = this.columns.concat(this.additionalColumns);
            this.countdown = setInterval(this.refresh, this.props.timer * 1000);
        } else {
            this.columns.push({ name: "freeTimeSuperGroup", width: 120 })
            this.props.dispatchers.setInReduxState({
                filterPopup: {
                    ...this.props.filterPopup,
                    category: ProteusConstants.BOOTMAN_CATEGORY
                }
            })
        }
        if (!TestUtils.storybookMode && !this.props.rosterMode) {
            this.props.dispatchers.generateEmployees(this.props.filterPopup.category);
        }
        this.setEntity();
        this.entityDescriptor = this.createEntityDescriptor();
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceDispatchers>) {
        if (!_.isEqual(this.props.filterPopup, prevProps.filterPopup) || !_.isEqual(this.props.originalRoot, prevProps.originalRoot)) {
            this.setEntity();
        }
        if (!this.props.rosterMode && this.props.timer !== prevProps.timer) {
            clearInterval(this.countdown!);
            this.countdown = setInterval(this.refresh, this.props.timer * 1000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.countdown!);
        delete fieldRenderers[this.employeeFieldRenderer];
    }

    renderFieldDescriptor() {
        const that = this;
        const root = this.getFilteredEmployees();
        const props = this.props;
        const increaseCounter = (employee: Root) => {
            if (!TestUtils.storybookMode) {
                this.props.dispatchers.callEmployee(employee.employeeId, employee.category);
            }
            this.props.dispatchers.setCallCounter(employee);
        }

        fieldRenderers[this.employeeFieldRenderer] = class extends React.Component<FieldRendererProps> {
            render = () => {
                for (let i = 0; i < root.length; i++) {
                    if (_.isEqual(root[i], this.props.entity)) {
                        if (that.props.rosterMode) {
                            const isFirst = that.props.firstEmployees.filter(employee => employee.employeeId == root[i].employeeId).length > 0;
                            const category = root[i].category == ProteusConstants.PILOTS ? ProteusConstants.PILOTS_CODE : ProteusConstants.BOOTMAN;
                            return <div>{this.props.value}
                                {ProteusUtils.checkPermission(ProteusConstants.FREE_TIME_ORDER_CHANGE) && that.props.setFirstButton && <Button color={isFirst ? 'green' : undefined} onClick={() => { that.props.dispatchers.addFirstEmployee({ employeeId: root[i].employeeId, category: category, freeTimeSuperGroupId: root[i].freeTimeSuperGroup } as FreeTimeSetFirstEmployeeParameterInput) }} icon='arrow up' size="mini" floated="right" />}
                            </div>
                        }
                        if (root[i].status === FreeTimeStatusEnum.FREE) {
                            return <div>{this.props.value}
                                {root[i].category === ProteusConstants.PILOTS && ProteusUtils.checkPermission(ProteusConstants.FREE_TIME_MAKE_EMPLOYEE_UNAVAILABLE) && <Button onClick={() => props.dispatchers.makeEmployeeUnavailable(root[i].employeeId)} icon color="green" size="mini" floated="right">
                                    <Icon name="ban" />
                                </Button>}
                                {ProteusUtils.checkPermission(ProteusConstants.FREE_TIME_DISPATCHERS_CALL) && <Button onClick={() => { increaseCounter(root[i]) }} icon color="green" size="mini" floated="right">
                                    <Icon name="phone"></Icon>{root[i].callCount}
                                </Button>}
                            </div>;
                        } else if (root[i].status === FreeTimeStatusEnum.ABSENT) {
                            return <div>{this.props.value}
                                {root[i].category === ProteusConstants.PILOTS && ProteusUtils.checkPermission(ProteusConstants.FREE_TIME_MAKE_EMPLOYEE_UNAVAILABLE) && <Button onClick={() => props.dispatchers.makeEmployeeUnavailable(root[i].employeeId)} icon color="orange" size="mini" floated="right">
                                    <Icon name="ban" />
                                </Button>}
                                {ProteusUtils.checkPermission(ProteusConstants.FREE_TIME_DISPATCHERS_CALL) && <Button onClick={() => { increaseCounter(root[i]) }} icon color="orange" size="mini" floated="right">
                                    <Icon name="phone"></Icon>{root[i].callCount}
                                </Button>}
                            </div>;
                        } else if (root[i].status === FreeTimeStatusEnum.UNAVAILABLE) {
                            return this.props.value;
                        }
                    }
                }
                return <></>;
            }
        }

    }

    recalculate() {
        this.props.dispatchers.generateEmployees(this.props.filterPopup.category, true, true);
        this.props.dispatchers.setInReduxState({ firstEmployees: [] });
    }

    renderMain() {
        // hide sequence column for pilots
        const columnDisplayed = !this.props.rosterMode && this.props.filterPopup.category === ProteusConstants.PILOTS ? this.columns.filter(column => column.name !== ProteusConstants.SEQUENCE) : this.columns;
        return (
            <div className="Dispatchers flex">
                <Dimmer data-testid="AppMeta_dimmer" active={this.props.loading} page={true}>
                    <Loader size='large'>
                        <Header as="h3" inverted>{_msg("general.loading")}</Header>
                    </Loader>
                </Dimmer>
                <Segment>
                    {!this.props.rosterMode && <>
                        <Button key="refresh" color="green" onClick={this.refresh} icon="refresh" />
                        <Popup className="Dispatchers_popup" on='click' pinned basic position="bottom left"
                            trigger={<Button className="Dispatchers_popup_button" icon color="green"><Icon name="caret down" attached='right' /></Button>}
                            content={<Form unstackable>
                                <Form.Group>
                                    <Form.Field style={{ whiteSpace: "nowrap" }}> {_msg("popup.refresh")} </Form.Field>
                                    <Form.Field>
                                        <Input type="number" size="small" value={this.props.timer} control='input' min="10" onChange={(e: any, { timer, value }: any) => {
                                            this.props.dispatchers.setInReduxState({ timer: value < 10 ? 10 : value })
                                        }}>
                                        </Input>
                                    </Form.Field>
                                    <Form.Field>
                                        <Icon name="history" />
                                    </Form.Field>
                                </Form.Group>
                            </Form>}
                        />
                        <Popup
                            trigger={<Button className="Dispatchers_filter" icon><Icon className="small-margin-right" name="filter" />{_msg("general.filter")}</Button>}
                            content={<FilterPopup {...this.props.filterPopup} dispatchers={this.props.dispatchers.filterPopup} />}
                            on='click' pinned basic position="bottom left"
                        />
                        {ProteusUtils.checkPermission("FREE_TIME_CACHE_CLEAR") && <Button icon="trash" onClick={() => this.props.dispatchers.clearCache()}>{_msg("Dispatchers.clearCache.label")}</Button>}
                        {ProteusUtils.checkPermission("FREE_TIME_CACHE_VIEW") && <Button onClick={() => this.props.dispatchers.loadCache()}>{_msg("Dispatchers.cache.label")}</Button>}
                    </>}
                    {this.props.recalculateButton && <Button className="Dispatchers_headerButton" onClick={() => this.recalculate()}>{_msg('Dispatchers.recalculate.label')}</Button>}
                    {this.props.setFirstButton && <Button className="Dispatchers_headerButton" disabled={this.props.firstEmployees.length == 0} onClick={() => this.props.dispatchers.applyChanges()}>{_msg('general.apply')}</Button>}
                </Segment>
                {this.renderFieldDescriptor()}
                {this.entityDescriptor && <div className="Dispatchers_table">
                    <EntityTableCustomized id={uniqueId("DispatchersEntityTableCustomized-")} ref={this.entityTableSimpleCustomizedRef} entityDescriptor={this.entityDescriptor} columns={columnDisplayed} />
                </div>}
            </div>
        );
    }
}

export const infoDispatchers = new ConnectedPageInfo(sliceDispatchers, Dispatchers, "Dispatchers");
infoDispatchers.routeProps = { permission: "FREE_TIME_DISPATCHERS_SCREEN_VIEW" };
