'use strict';

var css = require('./input.css');

/*@ngInject*/
function input($compile) {
	
	return {
		restrict: 'E',
		require: '?ngModel', // Array = multiple requires, ? = optional, ^ = check parent elements
		link: function($scope, $elt, $attrs, ngModelCtrl) {
			if ('clear' in $attrs) {
				let scope = createIsolateScope(require('./input-clear.html'));
				scope.clearModel = function() {
					if (!$elt.attr('disabled')) {
						$elt.val('');
						ngModelCtrl.$setViewValue('');
					}
				};
			}
			if ('addon' in $attrs) {
				var addon = $scope.$eval($attrs.addon);
				let scope = createIsolateScope(require('./input-addon.html'), addon);
				scope.addon = addon;
			}

			function createIsolateScope(tpl, addon) {
				var compileFn = $compile(tpl);
				var scope = $scope.$new(true); // isolate
				scope.modelCtrl = ngModelCtrl;
				$elt.after(compileFn(scope));
				return scope;
			}
		}
	};
}

module.exports = input;