import { apolloClient, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, Utils } from '@crispico/foundation-react';
import { ModalExt, Severity } from '@crispico/foundation-react/components/ModalExt/ModalExt';
import { CodeEntity } from 'apollo-gen/CodeEntity';
import { getRegistration_planningServiceFacadeBean_registration_type } from 'apollo-gen/getRegistration';
import { getResources_resourcesServiceEndpoint_resources_Employee } from 'apollo-gen/getResources';
import { PLANNING_SERVICE_FACADE_BEAN_REMOVE_REGISTRATIONS_BY_ID, REGISTRATION_SERVICE_FACADE_BEAN_MAP_REGISTRATIONS_BY_ID, RESOURCES_SERVICE_ENDPOINT_GET_RESOURCES, RESOURCES_SERVICE_ENDPOINT_GET_SELECTABLE_LEAVE_GROUPS_DATA, ROUNDING_TYPE_SERVICE_GET_ROUNDING_TYPES } from 'graphql/queries';
import _ from 'lodash';
import moment from 'moment';
import { HIDE_DETAIL_COLOR } from 'pages/planningPage/planningGantt/itemRenderer/RegistrationItemRenderer';
import { MetadataPropertyEnum } from 'pages/planningPage/planningGantt/mapper/PlanningGanttAdapter';
import { ConstraintDetail, EDIT_MODE, ExchangeDetail, Registration, RegistrationDetailObjectType, RegistrationEditor, REGISTRATION_EDITOR_MODE, sliceRegistrationEditor, LayoutTypeCode } from 'pages/registrationEditor/RegistrationEditor';
import { ProteusConstants } from 'ProteusConstants';
import { ProteusUtils } from 'ProteusUtils';
import React from 'react';
import { Button, Grid, Icon, Menu, Modal, SemanticWIDTHS } from 'semantic-ui-react';
import { Color } from 'utils/ColorUtil';
import { MetadataProviderHelper } from 'utils/MetadataProviderHelper';
import { AgendaRegistrations, ConstraintGroup, ConstraintGroupData, DataForEmployee, GroupSnapshot, RegistrationTypeOption } from './AgendaTypes';
import { AGENDA_MODE, WHITE } from './AgendaUtils';
import { EmployeeInfo } from './ResourcesInfo';
import { SwitchRegistrationEditor, SwitchRegistrationEditorRaw } from './SwitchRegistrationEditor';
import { WorkperiodInfo } from './WorkperiodInfo';
import { UiApiHelper } from '@famiprog-foundation/tests-are-demo/dist-lib/lib/uiApi/UiApiHelper';

const HALF_DAY_AM_PREFIX = '(VM) ';
const HALF_DAY_PM_PREFIX = '(NM) ';
/**
 * Default columns are:
 * 1. Date column
 * 2. Shift Cell Occupancy column
 * 3. Create new registration column
 */
const NUMBER_OF_DEFAULT_COLUMNS = 3;
const NOT_WORKING_DESCRIPTON = "Niet Werken";

export const sliceAgendaGrid = createSliceFoundation(class SliceAgendaGrid {

    initialState = {
        playButtonPressed: false,
        monthPlayButtonPressedStart: undefined as number | undefined,
        dayPlayButtonPressedStart: undefined as number | undefined,
        dayPlayButtonPressedEnd: undefined as number | undefined,
        newRegistrationStartDate: undefined as Date | undefined,
        newRegistrationEndDate: undefined as Date | undefined,
        newRegistrationSplit: undefined as boolean | undefined,
        halfDaySetting: undefined as string | undefined,
        workperiodInfoModalOpen: false,
        exchangeInfoModalOpen: false,
        workperiodOrExchangeModalData: undefined as DataForEmployee | undefined,
        workperiodInfoEmployees: [] as EmployeeInfo[],
        registrationSelected: undefined as Registration | undefined,
        registrationMenuPosition: false as [number, number] | boolean,
        employeeForEditor: undefined as unknown as any,
        registrationForEditor: undefined as unknown as Registration,
        isWaitingRegistration: false as boolean,
        selectedRegistration: undefined as unknown as AgendaRegistrations,
        confirmDeleteModalOpen: false,
        switchNoShiftModalOpen: false,
        roundingTypes: [] as CodeEntity[],
        openCompartmentsSelectedGroup: undefined as GroupSnapshot | undefined
    }

    nestedSlices = {
        registrationEditor: sliceRegistrationEditor
    }

    reducers = {
        ...getBaseReducers<SliceAgendaGrid>(this),

        resetNewRegistrationData(state: StateFrom<SliceAgendaGrid>) {
            state.playButtonPressed = false;
            state.dayPlayButtonPressedStart = undefined;
            state.dayPlayButtonPressedEnd = undefined;
            state.newRegistrationStartDate = undefined;
            state.newRegistrationEndDate = undefined;
            state.monthPlayButtonPressedStart = undefined;
        }
    }

    impures = {
        ...getBaseImpures<SliceAgendaGrid>(this),

        async getEmployees(employeesIds: number[], fromDate: Date, toDate: Date, registrationTypeCode: string | undefined) {
            let result: getResources_resourcesServiceEndpoint_resources_Employee[] = (await apolloClient.query({
                query: RESOURCES_SERVICE_ENDPOINT_GET_RESOURCES,
                variables: {
                    param: {
                        employeesIds,
                        fromDate,
                        toDate,
                        includeContactPersons: false
                    }
                }
            })).data.resourcesServiceEndpoint_resources;

            const newEmployees: EmployeeInfo[] = await Promise.all(result.map(async e => {
                const constraintData: ConstraintGroupData = (await apolloClient.query({
                    query: RESOURCES_SERVICE_ENDPOINT_GET_SELECTABLE_LEAVE_GROUPS_DATA,
                    variables: {
                        resource: e.id,
                        fromDateString: moment(fromDate).format(Utils.dateTimeFormat),
                        toDateString: moment(toDate).format(Utils.dateTimeFormat),
                        registrationTypeCode: registrationTypeCode
                    }
                })).data.resourcesServiceEndpoint_selectableLeaveGroupsData;
                const code = constraintData.preselectedGroupSnapshotCode;
                const defaultLeaveGroup = constraintData.leaveGroupSnapshotsForReact?.filter(group => group.code === code)[0];
                return {
                    ...e,
                    defaultLeaveGroup: defaultLeaveGroup?.name
                } as EmployeeInfo;
            }));

            let currentEmployees = _.cloneDeep(this.getState().workperiodInfoEmployees);
            const employees = currentEmployees.concat(newEmployees);
            this.getDispatchers().setInReduxState({ workperiodInfoEmployees: employees });
        }
    }
});

interface UiApiAgendaGridNewRegistrationButtons {
    onClickPlayStop(): void;
    onClickStop(): void;
    onClickCancel(): void;
}

export const uiApiAgendaGridNewRegistrationButtons = UiApiHelper.INSTANCE.create<UiApiAgendaGridNewRegistrationButtons>("AgendaGridNewRegistrationButtons");

export interface AgendaGridProps {
    agendaData: DataForEmployee[] | null;
    selectedRegistrationType: RegistrationTypeOption | undefined;
    selectedRegistrationTypeMetadata: { [key: string]: any };
    resource: number | null;
    calendarEvents: { [key: string]: boolean } | null;
    mode: AGENDA_MODE;
    selectedGroup: GroupSnapshot | undefined;
    refresh: () => void,
    currentMonth: number | undefined,
    agendaDate: Date,
    contraintGroupsOpenCompartments: ConstraintGroup[] | null,
    showConstraintGroup: () => void
}

type Props = PropsFrom<typeof sliceAgendaGrid> & AgendaGridProps;

export class AgendaGrid extends React.Component<Props> {
    switchRegistrationEditorRef = React.createRef<SwitchRegistrationEditorRaw>();

    getNumberOfRegistrationColumns() {
        let columns = 0;
        this.props.agendaData?.forEach(d => {
            columns = d.agendaRegistrations.length > columns ? d.agendaRegistrations.length : columns;
        });
        return columns;
    }

    renderRegistrationCells(data: DataForEmployee, day: number) {
        return Array(this.getNumberOfRegistrationColumns()).fill(0).map((_, i) => this.renderRegistrationCell(i, data.agendaRegistrations, day));
    }

    getPrefix(prefix: string | undefined) {
        if (prefix === ProteusConstants.HALF_DAY_AM) {
            return HALF_DAY_AM_PREFIX;
        } else if (prefix === ProteusConstants.HALF_DAY_PM) {
            return HALF_DAY_PM_PREFIX;
        }
    }

    renderRegistrationButton(agendaRegistration: AgendaRegistrations, day: number, hideDetails: any, registrationColor: any, index: number) {
        const showButton = new Date(agendaRegistration.registration.fromDate).getDate() === day;
        if (!showButton) { return; }
        const isExchange = agendaRegistration?.registration?.type?.code === ProteusConstants.EXCHANGE_REGISTRATION_TYPE;
        const registrationCode = !!hideDetails ? _msg('Registration.hideDetail.code') : agendaRegistration.registration.type?.name;
        return <Button onClick={e => this.openRegistrationMenu(e, agendaRegistration)} className={'AgendaGrid_button ' + (isExchange ? 'AgendaGrid_wisselButton' : '')} style={{ backgroundColor: registrationColor }}>
            <div data-testid={"AgendaGrid_buttonText_" + day + "_" + index} className={this.getRegistrationButtonTextStyle(registrationColor)}>
                {this.getPrefix(agendaRegistration.prefix)}
                {agendaRegistration.isWaitingRegistration ? <Icon name='warning sign' /> : null}
                {registrationCode}
            </div>
        </Button>
    }

    renderRegistrationCell(i: number, agendaRegistrations: AgendaRegistrations[], day: number) {
        const agendaRegistration = agendaRegistrations?.[i];
        let hideDetails, opacity = 1, registrationColor;
        if (!!agendaRegistration) {
            hideDetails = MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider(), agendaRegistration.registration.type?.code!, agendaRegistration.registration.status?.code!, MetadataPropertyEnum.HIDE_REGISTRATION_DETAIL, ProteusConstants.AGENDA);
            opacity = (100 - agendaRegistration.metadata[ProteusConstants.TRANSPARENCY_PERCENT]) / 100;
            registrationColor = !!hideDetails ? Color(HIDE_DETAIL_COLOR).hex() : agendaRegistration.metadata[ProteusConstants.BACKGROUND_COLOR];
        } 
        return (
            <Grid.Column className='AgendaGrid_cell' style={{ backgroundColor: registrationColor, opacity: opacity, color: 'white' }}>
                {!!agendaRegistration && this.renderRegistrationButton(agendaRegistration, day, hideDetails, registrationColor, i)}
            </Grid.Column>);
    }

    getRegistrationButtonTextStyle(color: any) {
        const contrastingColorAsHex = Utils.convertColorToHex(Utils.getContrastingForegroundColor(Utils.convertColorFromHex(color)));
        return 'AgendaGrid_buttonText ' + (contrastingColorAsHex.toLowerCase() === '#ffffff' ? 'AgendaGrid_registrationTextWhite' : 'AgendaGrid_registrationTextBlack');
    }

    getShiftCellOccupancy(data: DataForEmployee) {
        if (!data.constraints) {
            return 'AgendaGrid_shiftCell';
        }
        let occupancy = 0;
        data.constraints.forEach(constraint => {
            let limit = Number.MIN_SAFE_INTEGER;
            if (constraint.available != null && constraint.available != 0) {
                limit = Math.max(limit, constraint.used - constraint.available);
            }
            if (constraint.minimum != 0) {
                limit = Math.max(limit, constraint.minimum - constraint.minimumAvailable);
            }

            if (limit < 0) {
                occupancy |= 0; 		// if all are free => result will be free (occupancy == 0)
            } else if (limit == 0) {
                occupancy |= 1;			// if at least one is full, and none overrun => result will be full (occupancy == 1)
            } else {
                occupancy |= 2;			// if at least one is overrun => result will be overrun (occupancy == 2 or occupancy == 3)
            }
        });

        if (occupancy == 0) {
            return 'AgendaGrid_shiftCell AgendaGrid_shiftCellFree';
        } else if (occupancy == 1) {
            return 'AgendaGrid_shiftCell AgendaGrid_shiftCellFull';
        } else {
            return 'AgendaGrid_shiftCell AgendaGrid_shiftCellOverrun';
        }
    }

    renderNewRegistrationButton(data: DataForEmployee, day: number) {
        const api = uiApiAgendaGridNewRegistrationButtons(day);
        const icon = this.props.playButtonPressed ? 'stop' : 'play';
        const backgroundColor = this.props.selectedRegistrationTypeMetadata?.[ProteusConstants.BACKGROUND_COLOR];
        let style: any = { backgroundColor, color: 'white' };
        let content = null;
        if (this.props.dayPlayButtonPressedStart && this.props.dayPlayButtonPressedEnd && this.props.dayPlayButtonPressedStart !== day) {
            const addStyle = this.props.dayPlayButtonPressedStart < day && this.props.dayPlayButtonPressedEnd >= day;
            content = null;
            style = addStyle ? style : undefined;
        } else if (this.props.dayPlayButtonPressedStart === day && this.props.currentMonth === this.props.monthPlayButtonPressedStart) {
            content = <Button.Group>
                <Button data-testid={api.testids.onClickStop} className='AgendaGrid_newRegistrationButton' style={style} icon='stop' onClick={() => api.dispatcher.onClickStop() } />
                <Button data-testid={api.testids.onClickCancel} className='AgendaGrid_newRegistrationButton' color='red' icon='dont' onClick={() => api.dispatcher.onClickCancel() } />
            </Button.Group>
        } else if (this.props.dayPlayButtonPressedStart && this.props.dayPlayButtonPressedStart > day) {
            style = undefined;
            content = null;
        } else {
            style = undefined;
            const showPlayStopButton = this.shouldShowPlayStopButton(data, this.props.selectedRegistrationTypeMetadata);
            content = showPlayStopButton && <Button data-testid={api.testids.onClickPlayStop} className='AgendaGrid_button' icon={icon} onClick={() => api.dispatcher.onClickPlayStop()} />
        }
        return (<Grid.Column className='AgendaGrid_cell' style={style}>
            <api.Listener implementation={{
                onClickPlayStop: () => this.onNewRegistrationClick(data.start, data.end, day),
                onClickStop: () => this.onNewRegistrationClick(undefined, data.end, day),
                onClickCancel: () => this.props.dispatchers.resetNewRegistrationData()
            }} />
            {content}
        </Grid.Column>);
    }

    /**
     * If the employee has a work period, then show play/stop
     * However, registrations with flexible availability can be created only when the employee does not work (for a single day).
     */
    shouldShowPlayStopButton(data: DataForEmployee, metadata: {[key: string]: any}) {
        const isWorking = data.status !== NOT_WORKING_DESCRIPTON && data.workRosterType;
        return metadata?.[ProteusConstants.LAYOUT_TYPE_CODE] === LayoutTypeCode.OVERTIME || (metadata?.[ProteusConstants.LAYOUT_TYPE_CODE] === LayoutTypeCode.FLEXIBLE_AVAILABILITY ? (this.props.dayPlayButtonPressedStart ? false : !isWorking) : isWorking);
    }

    openRegistrationMenu(e: any, agendaRegistration: AgendaRegistrations) {
        let currentTargetRect = e.currentTarget.getBoundingClientRect();
        this.props.dispatchers.setInReduxState({ registrationMenuPosition: [currentTargetRect.left, currentTargetRect.bottom], selectedRegistration: agendaRegistration });
    }

    onNewRegistrationClick(start: Date | undefined, end: Date, day: number) {
        if (!this.props.playButtonPressed) {
            this.props.dispatchers.setInReduxState({ playButtonPressed: true, newRegistrationStartDate: start, dayPlayButtonPressedStart: day, monthPlayButtonPressedStart: this.props.currentMonth })
            return;
        }
        this.props.dispatchers.setInReduxState({ newRegistrationEndDate: end, dayPlayButtonPressedEnd: day });
        this.handleCreateNewRegistration(ProteusUtils.getCurrentUser(), this.props.newRegistrationStartDate!, end);
    }

    renderRow(data: DataForEmployee, day: number) {
        const date = new Date(data.start);
        const isHoliday = this.props.calendarEvents?.[date.getDate()];
        const isWeekend = date.getDay() === 0 || date.getDay() === 6;
        const className = isHoliday ? ' AgendaGrid_holiday' : isWeekend ? ' AgendaGrid_weekend' : '';
        return (
            <Grid.Row key={day} stretched className='AgendaGrid_row'>
                <Grid.Column className={'AgendaGrid_dateCell' + className}>
                    <Button className={'AgendaGrid_button' + className} onClick={() => this.openWorkperiodOrExchangeInfoModal(data)}>{String(date.getDate()).padStart(2, '0')}</Button>
                </Grid.Column>
                <Grid.Column className={this.getShiftCellOccupancy(data)} style={{ backgroundColor: data.backgroundColor, 'opacity': (100 - data.transparencyPercent) / 100 }} />
                {this.renderRegistrationCells(data, date.getDate())}
                {this.renderNewRegistrationButton(data, date.getDate())}
            </Grid.Row>
        );
    }

    openWorkperiodOrExchangeInfoModal(data: DataForEmployee) {
        if (data.workRosterType || data.constraints) {
            this.props.dispatchers.setInReduxState({ workperiodInfoModalOpen: true, workperiodOrExchangeModalData: data });
        } else if (data.agendaRegistrations.find(r => r.registration.type?.code === ProteusConstants.EXCHANGE_REGISTRATION_TYPE)) {
            this.props.dispatchers.setInReduxState({ exchangeInfoModalOpen: true, workperiodOrExchangeModalData: data });
        }
    }

    renderExchangeModal() {
        const data = this.props.workperiodOrExchangeModalData!;
        const registration = data.agendaRegistrations.find(r => r.registration.type?.code === ProteusConstants.EXCHANGE_REGISTRATION_TYPE)!.registration;
        return (
            <ModalExt className="Agenda_modal" addNiceLookingOffsets closeIcon centered={false} open={this.props.exchangeInfoModalOpen} onClose={() => this.props.dispatchers.setInReduxState({ exchangeInfoModalOpen: false, workperiodOrExchangeModalData: undefined })}>
                <Modal.Header className="Agenda_modalHeader">
                    <Icon name='info circle' />
                </Modal.Header>
                <Modal.Content>
                    <Grid columns={2}>
                        <Grid.Column>
                            <Icon name='calendar outline' />
                            {moment(data.start).format(Utils.dateFormat)}
                        </Grid.Column>
                        <Grid.Column>
                            <Icon name='clock outline' />
                            {moment(registration.fromDate).format(Utils.timeFormat)} - {moment(registration.toDate).format(Utils.timeFormat)}
                        </Grid.Column>
                        <Grid.Column>
                            <Icon name='exchange' />
                            {`${(registration.detail as ExchangeDetail).secondParty.name} ${(registration.detail as ExchangeDetail).secondParty.firstName} `}
                            <span style={{ color: '#bbbbbb' }}>{`(${registration.status?.description})`}</span>
                        </Grid.Column>
                        <Grid.Column>
                            <Icon name='point' />
                            {(registration.detail as ExchangeDetail).secondParty.locationHistoryItem?.location.description}
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </ModalExt>
        );
    }

    getWorkperiodModalColorClassName(backgroundColor: string) {
        if (!backgroundColor) {
            return '';
        }
        const contrastingColorAsHex = Utils.convertColorToHex(Utils.getContrastingForegroundColor(Utils.convertColorFromHex(backgroundColor)));
        return contrastingColorAsHex.toLowerCase() === WHITE ? 'AgendaGrid_workPeriodPopupWhite' : 'AgendaGrid_workPeriodPopupBlack';
    }

    renderWorkperiodModal() {
        const data = this.props.workperiodOrExchangeModalData!;
        const modalColor = this.getWorkperiodModalColorClassName(data.backgroundColor);
        return (
            <ModalExt className="Agenda_modal" addNiceLookingOffsets closeIcon centered={false} open={this.props.workperiodInfoModalOpen} onClose={() => this.props.dispatchers.setInReduxState({ workperiodInfoModalOpen: false, workperiodOrExchangeModalData: undefined })}>
                <Modal.Header className={`AgendaGrid_workperiodInfoModalHeader" ${modalColor}`} style={{ backgroundColor: data.backgroundColor }}>
                    <Icon name='info circle' /> {data.workRosterType}
                </Modal.Header>
                <Modal.Content className='AgendaGrid_workperiodInfoModalContent'>
                    <WorkperiodInfo data={data} employees={this.props.workperiodInfoEmployees} getEmployees={(employeesIds: number[], fromDate: Date, toDate: Date) => this.props.dispatchers.getEmployees(employeesIds, fromDate, toDate, this.props.selectedRegistrationType?.code)}
                        onClose={() => this.props.dispatchers.setInReduxState({ workperiodInfoEmployees: [] })} />
                </Modal.Content>
            </ModalExt>
        );
    }

    renderEmptyGrid() {
        return (
            <div className='AgendaGrid_empty'>
                {_msg("Agenda.grid.empty")}
            </div>
        )
    }

    closeRegistrationMenu() {
        this.props.dispatchers.setInReduxState({ registrationMenuPosition: false });
    }

    isSelectedRegistrationEditable() {
        if (this.props.selectedRegistration) {
            return this.props.selectedRegistration.metadata[ProteusConstants.UPDATE_ROLE]
        }
        if (!this.props.selectedRegistration && this.props.registrationForEditor) {
            return true;
        }
        return false;
    }

    hideViewOrEditAction() {
        return !this.props.selectedRegistration.metadata[ProteusConstants.UPDATE_ROLE] && !this.props.selectedRegistration.metadata[ProteusConstants.READ_ROLE];
    }

    async viewOrEdit() {
        const employee = ProteusUtils.getCurrentUser();
        const employeeForEditor = {
            id: employee.id,
            name: employee.name,
            firstName: employee.firstName,
            employeeNumber: employee.contractHistoryItem?.employeeNumber
        };
        let roundingTypes = this.props.roundingTypes;
        if (!this.props.roundingTypes || this.props.roundingTypes.length === 0) {
            roundingTypes = await this.getRoundingTypes();
        }
        this.props.dispatchers.setInReduxState({ registrationForEditor: this.props.selectedRegistration.registration, employeeForEditor, roundingTypes })
    }

    hideSplitAction() {
        return !this.props.selectedRegistration.metadata[ProteusConstants.CREATE_ROLE] && !this.props.selectedRegistration.metadata[ProteusConstants.UPDATE_ROLE];
    }

    disableSplitAction() {
        return !this.props.selectedRegistration.metadata[ProteusConstants.ALLOW_MAP_TO_WORKPERIOD];
    }

    getRegistrationContext() {
        return {
            errorPolicy: {
                policy: ProteusConstants.ENFORCE_ALL,
            },
            metadataContext: ProteusConstants.AGENDA,
            processingWaitingRegistrations: false,
            removeWaiting: false,
            split: false,
            trainingRegistrationUpdated: false,
            updatedFromSessions: false,
            useDefaultMetadataRounding: true,
            validationExceptionsForWaitingRegistrations: false,
        }
    }

    async split() {
        await apolloClient.mutate({
            mutation: REGISTRATION_SERVICE_FACADE_BEAN_MAP_REGISTRATIONS_BY_ID,
            variables: {
                registrationIds: [this.props.selectedRegistration.registration.id],
                context: { ...this.getRegistrationContext(), useDefaultMetadataRounding: true }
            }
        });
        this.props.refresh();
    }

    hideSwitchAction() {
        const typeCode = this.props.selectedRegistration.registration.type!.code;
        return typeCode != ProteusConstants.LEAVE_TYPE_CODE && typeCode != ProteusConstants.TIME_CREDIT_TYPE_CODE && typeCode != ProteusConstants.PARENTAL_LEAVE_TYPE_CODE;
    }

    switch() {
        const registration = this.props.selectedRegistration.registration;
        // first check how many shifts this registration is covering
        let shifts = 0;
        registration.calculationRelations?.forEach((relation: any) => {
            if ((ProteusConstants.LEAVE_TYPE_CODE == registration.type!.code && relation.calculation.code.indexOf(ProteusConstants.INITIAL_CALCULATION_PREFIX + ProteusConstants.LEAVE_TYPE_CODE) >= 0) ||
                (ProteusConstants.TIME_CREDIT_TYPE_CODE == registration.type!.code && relation.calculation.code.indexOf(ProteusConstants.INITIAL_CALCULATION_PREFIX + ProteusConstants.TIME_CREDIT_TYPE_CODE) >= 0) ||
                (ProteusConstants.PARENTAL_LEAVE_TYPE_CODE == registration.type!.code && relation.calculation.code.indexOf(ProteusConstants.INITIAL_CALCULATION_PREFIX + ProteusConstants.PARENTAL_LEAVE_TYPE_CODE) >= 0)) {
                shifts += relation.result;
            }
        });
        if (shifts != 1) {
            Utils.showGlobalAlert({ message: _msg("PlanningGantt.contextMenu.switch.notAllowed.label"), title: _msg("PlanningGantt.contextMenu.switch.validation.label"), severity: Severity.ERROR });
            return;
        }
        this.switchRegistrationEditorRef.current?.props.r.open(this.props.selectedRegistration.registration);
    }

    hideDeleteAction() {
        return !this.props.selectedRegistration.metadata[ProteusConstants.CREATE_ROLE] && !this.props.selectedRegistration.metadata[ProteusConstants.UPDATE_ROLE];
    }

    disableDeleteAction() {
        return !this.props.selectedRegistration.metadata[ProteusConstants.DELETE_ROLE];
    }

    renderDeleteConfirmModal() {
        return (
            <ModalExt className="Agenda_modal" addNiceLookingOffsets closeIcon centered={false} open={this.props.confirmDeleteModalOpen} onClose={() => this.props.dispatchers.setInReduxState({ confirmDeleteModalOpen: false })}>
                <Modal.Header className="Agenda_modalHeader">
                    <Icon name='question circle' />
                    {_msg("general.delete")}
                </Modal.Header>
                <Modal.Content>
                    {_msg("dto_crud.deleteConfirmation")}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.props.dispatchers.setInReduxState({ confirmDeleteModalOpen: false })}>
                        {_msg('general.cancel')}
                    </Button>
                    <Button primary onClick={() => this.delete()}>
                        {_msg('general.ok')}
                    </Button>
                </Modal.Actions>
            </ModalExt>
        );
    }

    async delete() {
        await apolloClient.mutate({
            mutation: PLANNING_SERVICE_FACADE_BEAN_REMOVE_REGISTRATIONS_BY_ID,
            variables: {
                registrations: [this.props.selectedRegistration.registration.id],
                context: this.getRegistrationContext()
            }
        });
        this.props.dispatchers.setInReduxState({ confirmDeleteModalOpen: false });
        this.props.refresh();
    }

    renderRegistrationMenu() {
        return (<>
            <ModalExt className='EntityTableSimple_menuModal' transparentDimmer closeIcon={false} open={this.props.registrationMenuPosition} onClick={() => this.closeRegistrationMenu()} onClose={() => this.closeRegistrationMenu()}>
                {this.props.registrationMenuPosition && <Menu vertical className="wh100">
                    {!this.hideViewOrEditAction() && <Menu.Item onClick={() => this.viewOrEdit()}>
                        <Icon name={this.isSelectedRegistrationEditable() ? 'edit' : 'file alternate'} />{this.isSelectedRegistrationEditable() ? _msg("general.edit") : _msg("AgendaRegistrationEditor.readOnly.label")}
                    </Menu.Item>}
                    {!this.hideSplitAction() && <Menu.Item disabled={this.disableSplitAction()} onClick={() => this.split()}><Icon name='cut' />{_msg("RegistrationEditor.context.split.label")}</Menu.Item>}
                    {!this.hideSwitchAction() && <Menu.Item onClick={() => this.switch()}><Icon name='exchange' />{_msg("PlanningGantt.contextMenu.switch.validation.label")}</Menu.Item>}
                    {!this.hideDeleteAction() && <Menu.Item disabled={this.disableDeleteAction()} onClick={() => this.props.dispatchers.setInReduxState({ confirmDeleteModalOpen: true })}><Icon name='delete' />{_msg("general.delete")}</Menu.Item>}
                </Menu>}
            </ModalExt>
        </>);
    }

    private createRegistration(resource: number, fromDate: Date, toDate: Date) {
        return {
            resource, fromDate, toDate,
            type: this.props.selectedRegistrationType as getRegistration_planningServiceFacadeBean_registration_type,
            attachments: [],
            bedrijf: null,
            calculationRelations: [],
            costCenters: [],
            cyclusId: null,
            environment: ProteusConstants.BRABO,
            externId: null,
            melding: null,
            objectVersion: null,
            creationDate: null,
            creationUser: null,
            modificationDate: null,
            modificationUser: null,
            personenMiddelen: [],
            remarks: [],
            status: null,
            workRosterItem: null,
            id: null,
            detail: null
        }
    }

    protected handleCreateNewRegistration = async (employee: any, fromDate: Date, toDate: Date, groupCode?: string) => {
        const employeeForEditor = {
            id: employee.id,
            name: employee.name,
            firstName: employee.firstName,
            employeeNumber: employee.contractHistoryItem?.employeeNumber
        };
        const registration = this.createRegistration(employee.id, fromDate, toDate);
        if (groupCode) {
            (registration as any).detail = { groupCode, objectType: RegistrationDetailObjectType.CONSTRAINT_GROUP_DETAIL } as ConstraintDetail;
        }
        let roundingTypes = this.props.roundingTypes;
        if (!this.props.roundingTypes || this.props.roundingTypes.length === 0) {
            roundingTypes = await this.getRoundingTypes();
        }
        this.props.dispatchers.setInReduxState({
            registrationForEditor: registration,
            employeeForEditor,
            isWaitingRegistration: false,
            roundingTypes
        });
    }

    protected onRegistrationEditorClose = async (shouldReloadData?: boolean) => {
        this.props.dispatchers.setInReduxState({ registrationForEditor: undefined, selectedRegistration: undefined });
        this.props.dispatchers.resetNewRegistrationData();
        if (shouldReloadData) {
            this.props.refresh();
        }
    }

    async getRoundingTypes() {
        let roundingTypes = (await apolloClient.query({ query: ROUNDING_TYPE_SERVICE_GET_ROUNDING_TYPES })).data.roundingTypeService_roundingTypes;
        roundingTypes.map((roundingType: { __typename: any; }) => delete roundingType.__typename);
        return roundingTypes;
    }

    showConstraintGroup(group: any) {
        this.props.dispatchers.setInReduxState({ openCompartmentsSelectedGroup: group as GroupSnapshot });
        this.props.showConstraintGroup();
    }

    renderOpenCompartmentsHeaderRow(data: any) {
        return (
            <Grid.Row className='AgendaGrid_openCompartmentsHeaderRow' stretched>
                <Grid.Column className='AgendaGrid_openCompartmentsHeaderDateOccupancy' />
                {data?.groupStatuses.map((g: any) => {
                    const className = 'AgendaGrid_openCompartmentsHeaderCell ' + (g.group.isSelectable ? '' : 'AgendaGrid_openCompartmentsNotSelectableGroupHeader AgendaGrid_openCompartmentsNotSelectable');
                    return <Grid.Column className={className}>
                        <span className="AgendaGrid_openCompartmentsGroupName" onClick={() => this.showConstraintGroup({ name: g.group.groupName, code: g.group.groupCode } as GroupSnapshot)}>{g.group.groupName}</span>
                    </Grid.Column>
                })}
            </Grid.Row>
        );
    }

    getGroupCellClassName(groupStatus: any) {
        const colorClass = groupStatus.status >= 2 ? 'AgendaGrid_openCompartmentsRed' : groupStatus.status == 1 ? 'AgendaGrid_openCompartmentsOrange' : groupStatus.status === 0 ? 'AgendaGrid_openCompartmentsGreen' : 'AgendaGrid_openCompartmentsGrey';
        const notSelectableClass = groupStatus.group.isSelectable ? '' : ' AgendaGrid_openCompartmentsNotSelectable';
        return 'AgendaGrid_openCompartmentsCell ' + colorClass + notSelectableClass;
    }

    renderOpenCompartmentsRow(data: ConstraintGroup) {
        const date = new Date(data.start);
        const isHoliday = this.props.calendarEvents?.[date.getDate()];
        const isWeekend = date.getDay() === 0 || date.getDay() === 6;
        const className = isHoliday ? ' AgendaGrid_holiday' : isWeekend ? ' AgendaGrid_weekend' : '';
        const agendaData = this.props.agendaData?.[date.getDate() - 1]!;
        return (
            <Grid.Row className='AgendaGrid_openCompartmentsRow' stretched>
                <Grid.Column className={'AgendaGrid_dateCell' + className}>
                    <Button className={'AgendaGrid_button' + className}>{String(date.getDate()).padStart(2, '0')}</Button>
                </Grid.Column>
                <Grid.Column style={{ backgroundColor: agendaData?.backgroundColor, 'opacity': (100 - agendaData?.transparencyPercent) / 100, width: '25px' }} />
                {data.groupStatuses.map(groupStatus => <Grid.Column className={this.getGroupCellClassName(groupStatus)}>
                    {groupStatus.group.isSelectable && groupStatus.status === 0 && <Button onClick={() => this.handleCreateNewRegistration(ProteusUtils.getCurrentUser(), agendaData.start, agendaData.end, groupStatus.group.groupCode)} className='AgendaGrid_openCompartmentsButton' />}
                </Grid.Column>)}
            </Grid.Row>
        );
    }

    render() {
        if (!this.props.agendaData) {
            return this.renderEmptyGrid();
        }
        const columnNumber = this.props.mode === AGENDA_MODE.MODE_MONTH ? this.getNumberOfRegistrationColumns() + NUMBER_OF_DEFAULT_COLUMNS : this.props.contraintGroupsOpenCompartments?.[0].groupStatuses.length! + 1;
        return (
            <>
                {this.props.mode === AGENDA_MODE.MODE_OPEN_COMPARTMENTS && <Grid className='AgendaGrid_openCompartmentsHeader' celled columns={columnNumber as SemanticWIDTHS}>
                    {this.renderOpenCompartmentsHeaderRow(this.props.contraintGroupsOpenCompartments?.[0])}
                </Grid>}
                <Grid className='AgendaGrid' celled columns={columnNumber as SemanticWIDTHS}>
                    {this.props.mode === AGENDA_MODE.MODE_MONTH ? this.props.agendaData?.map((data, i) => this.renderRow(data, i)) : this.props.contraintGroupsOpenCompartments?.map((data, i) => this.renderOpenCompartmentsRow(data))}
                </Grid>
                {this.props.registrationForEditor && <RegistrationEditor {...this.props.registrationEditor} dispatchers={this.props.dispatchers.registrationEditor}
                    employee={this.props.employeeForEditor} registration={this.props.registrationForEditor}
                    isWaitingRegistration={this.props.isWaitingRegistration} onDrawerClose={this.onRegistrationEditorClose}
                    operatingMode={EDIT_MODE} viewMode={!this.isSelectedRegistrationEditable()} roundingTypes={this.props.roundingTypes} metadataContext={ProteusConstants.AGENDA} mode={REGISTRATION_EDITOR_MODE.AGENDA}
                />}
                {this.props.workperiodInfoModalOpen && this.renderWorkperiodModal()}
                {this.props.exchangeInfoModalOpen && this.renderExchangeModal()}
                {this.renderRegistrationMenu()}
                {this.renderDeleteConfirmModal()}
                <SwitchRegistrationEditor id="SwitchRegistrationEditor" ref={this.switchRegistrationEditorRef} refresh={() => this.props.refresh()} />
            </>
        );
    }
}