import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { EMPLOYEE_SERVICE_GET_EMPLOYEE_BY_ID_WITH_CONTRACT } from "graphql/queries";
import _ from "lodash";
import { Person } from "pages/employeeDetail/EmployeeDetailEntityDescriptor";
import { PersonAssociationFieldEditor } from "pages/realization/RealizationEntityDescriptor";
import React from "react";

export class GroupContextEntityDescriptor extends EntityDescriptor {

    protected customize() {

        const groupContextEntityDescriptor = this;

        const sliceGroupContextEditor = groupContextEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceGroupContextEditor extends SliceEntityEditorPage {

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                initialOwner: undefined as unknown as Person
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceGroupContextEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceGroupContextEditor>(this),

                async getInitialEmployeeData(id: number) {
                    let initialOwner = (await apolloClient.query({
                        query: EMPLOYEE_SERVICE_GET_EMPLOYEE_BY_ID_WITH_CONTRACT,
                        variables: {
                            employeeId: id
                        }
                    })).data.employeeService_employee;

                    initialOwner = { ...initialOwner, contractHistoryItem: { employeeNumber: initialOwner?.detail?.contractHistory[0]?.employeeNumber } };
                    delete initialOwner?.detail;
                    this.getDispatchers().setInReduxState({ initialOwner });
                },

                superLoad: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.superLoad(id);
                    if (entity !== undefined && entity.owner !== null && entity.owner !== undefined) {
                        await this.getInitialEmployeeData(entity.owner);
                    }
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    if (entity.owner !== null && entity.owner !== undefined && entity.owner.id !== undefined) {
                        entity = { ...entity, owner: entity.owner.id };
                    }
                    await this.saveSuper(entity);
                }
            }
        }).setEntityDescriptor(groupContextEntityDescriptor);

        groupContextEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceGroupContextEditor> & EntityEditorPageProps> {

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "GroupContext" }, false);
                // replace owner field descriptor with owner picker descriptor
                groupContextEntityDescriptor.doForFields(null, (fieldDescriptor) => result.entityDescriptor.addFieldDescriptor(fieldDescriptor));
                result.entityDescriptor.removeFieldDescriptors("owner");
                result.entityDescriptor.addFieldDescriptor({ name: "owner", type: "EmployeeSnapshot" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        const newProps = {
                            ...props,
                            includeAllWithValidContract: true,
                            queryLimit: -1,
                        };
                        return React.createElement(PersonAssociationFieldEditor as any, newProps as FieldEditorProps);
                    }
                });
                if (result.entity.owner !== undefined && result.entity.owner !== null && result.entity.owner.id === undefined
                    && result.entity.owner === this.props.initialOwner?.id) {
                    result.entity = { ...result.entity, owner: this.props.initialOwner };
                }
                return result;
            }

            protected getEntityValuesFromForm() {
                // because in the form the "owner" field is of type "object" and in the table it is of type number (person id), a conversion
                // is needed when navigating from "edit properties" to "view properties" (from form to table)
                let values = super.getEntityValuesFromForm();
                if (values.owner && values.owner.id !== undefined) {
                    this.props.dispatchers.setInReduxState({ initialOwner: values.owner });
                    values = { ...values, owner: values.owner.id };
                }
                return values;
            }
        }
    }
}