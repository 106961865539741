import { fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { createSliceFoundation, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { HistoryTable, sliceHistoryTable } from "components/HistoryTable";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Grid } from "semantic-ui-react";
import { groupResourcesHistoryDescriptor, securityDefinitionProfilesDescriptor, SecurityTabTableRenderer } from "./customFieldRenderersEditors";
import { HistoryItem, PROFILES } from "./GroupsManagement";

export interface SecurityDefinitionHistory extends HistoryItem {
    profiles: SecurityProfiles[]
}

export interface SecurityProfiles {
    id: number,
    code: string,
    description: string,
    permissions: SecurityPermissions[]
}

export interface SecurityProfilesTable {
    [PROFILES]: {
        id: number,
        description: string,
        code?: string
        objectVersion?: string
    }
}

export interface SecurityPermissions {
    id: number,
    code: string,
    description: string
}

export const sliceSecurityTab = createSliceFoundation(class SliceSecurityTab {

    initialState = {
        selectedSecurityDefinitionHistory: undefined as number | undefined
    }

    nestedSlices = {
        securityDefinitionHistoryTable: sliceHistoryTable,
        securityDefinitionProfilesTable: sliceEntityTableLight
    }

    reducers = {
        ...getBaseReducers<SliceSecurityTab>(this)
    }
})

type PropsNotFromState = {
    selectedGroup: any;
    deleteSecurityDefinitionHistory: (remainingSecurityDefinition: SecurityDefinitionHistory[]) => void;
    addOrUpdateSecurityDefinitionHistory: (securityDefinitionHistory: SecurityDefinitionHistory[]) => void;
    deleteProfileFromSecurityHistoryItem: (securityHistoryItem: SecurityDefinitionHistory, profile: SecurityProfilesTable) => void;
    addProfileToSecurityHistoryItem: (securityHistoryItem: SecurityDefinitionHistory, addedProfile: SecurityProfilesTable) => void;
}

export class SecurityTab extends React.Component<PropsFrom<typeof sliceSecurityTab> & PropsNotFromState> {
    protected securityDefinitionHistoryTableRef = React.createRef<HistoryTable>();
    protected securityDefinitionProfilesTableRef = React.createRef<EntityTableLight>();

    setSecurityDefinitionHistoryTable(securityDefinitionHistory: SecurityDefinitionHistory[]) {
        const oldEntities = this.securityDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities();
        const entities = securityDefinitionHistory.sort((a: SecurityDefinitionHistory, b: SecurityDefinitionHistory) => moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf());
        // historyItems are sorted by validFrom value
        this.securityDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(entities);

        if (!_.isEqual(oldEntities, entities)) {
            const selected = this.securityDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!;
            this.onSecurityDefinitionHistoryChange(this.securityDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected(), entities[selected]);
        }
    }

    setSecurityProfilesTable(securityProfiles: SecurityProfiles[]) {
        let securityProfilesForTable: SecurityProfilesTable[] = [];
        for (let i = 0; i < securityProfiles?.length; i++) {
            securityProfilesForTable.push({ [PROFILES]: { id: securityProfiles[i].id, description: securityProfiles[i].description } });
        }
        // profiles are sorted by description
        this.securityDefinitionProfilesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(securityProfilesForTable.sort((a: SecurityProfilesTable, b: SecurityProfilesTable) =>
            a[PROFILES].description > b[PROFILES].description ? 1 : -1));
    }

    render() {
        fieldRenderers["SecurityProfile"] = SecurityTabTableRenderer;
        const selectedId = this.props.selectedSecurityDefinitionHistory!;
        const selectedEntityInTable = this.securityDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[selectedId];
        return (
            <>
                <Grid className="GroupsManagement_flexGrow flex">
                    <Grid.Row columns={2}>
                        <Grid.Column className="GroupSnapshotTab_block">
                            <HistoryTable {...this.props.securityDefinitionHistoryTable} dispatchers={this.props.dispatchers.securityDefinitionHistoryTable} ref={this.securityDefinitionHistoryTableRef}
                                onDelete={(remainingSecurityDefinition: SecurityDefinitionHistory[]) => {
                                    this.props.deleteSecurityDefinitionHistory(remainingSecurityDefinition);
                                    this.securityDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                                    this.securityDefinitionProfilesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                                    this.props.dispatchers.setInReduxState({ selectedSecurityDefinitionHistory: undefined });
                                }}
                                onSave={(securityDefinitionHistory: SecurityDefinitionHistory[]) => {
                                    this.props.addOrUpdateSecurityDefinitionHistory(securityDefinitionHistory);
                                    this.securityDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                                    this.securityDefinitionProfilesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                                    this.props.dispatchers.setInReduxState({ selectedSecurityDefinitionHistory: undefined });
                                }}
                                onAdd={sliceHistoryTable.reducers.onAdd} actions={{ showDeleteButton: false, showAddButton: true }}
                                formCustomizer={{ headerContent: _msg("GroupResourcesHistoryItem.addForm.label"), headerIcon: "history" }}
                                entityDescriptor={groupResourcesHistoryDescriptor} fieldsToBeCopied={[PROFILES]}
                                onSelectItem={(itemId: any) => this.onSecurityDefinitionHistoryChange(itemId, this.securityDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[itemId])}
                            />
                        </Grid.Column>
                        <Grid.Column className="GroupSnapshotTab_block">
                            <EntityTableLight {...this.props.securityDefinitionProfilesTable} dispatchers={this.props.dispatchers.securityDefinitionProfilesTable} ref={this.securityDefinitionProfilesTableRef}
                                entityDescriptor={securityDefinitionProfilesDescriptor}
                                actions={{ showDeleteButton: false, disableAddButton: selectedId === undefined, showEditButton: false, doNotAddEntityToTable: true }}
                                onDelete={(remainingSecurityProfiles: SecurityProfilesTable[], securityProfileToBeDeletedIndex: number) => {
                                    this.props.deleteProfileFromSecurityHistoryItem(selectedEntityInTable,
                                        this.securityDefinitionProfilesTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[securityProfileToBeDeletedIndex]);

                                }}
                                onSave={(profiles: SecurityProfilesTable[], addedProfile: { rowIndex: number, values: SecurityProfilesTable }) => {
                                    this.props.addProfileToSecurityHistoryItem(selectedEntityInTable, addedProfile.values);
                                }}
                                formCustomizer={{ headerContent: _msg("SuccessionTab.successionMembers.label"), headerIcon: "user" }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );
    }

    selectedGroupChanged() {
        this.setSecurityDefinitionHistoryTable([...this.props.selectedGroup.securityDefinition.history]);
        this.securityDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
    }

    onSecurityDefinitionHistoryChange(itemId: any, selectedEntityInTable: any) {
        this.props.dispatchers.setInReduxState({ selectedSecurityDefinitionHistory: itemId });
        if (itemId === undefined) {
            this.setSecurityProfilesTable([]);
        } else {
            this.setSecurityProfilesTable([...selectedEntityInTable.profiles]);
        }
    }

    componentDidMount() {
        this.selectedGroupChanged();
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceSecurityTab> & PropsNotFromState) {
        if (this.props.selectedGroup.id !== prevProps.selectedGroup.id) {
            this.selectedGroupChanged();
        }

        if (this.props.selectedGroup.securityDefinition.history !== prevProps.selectedGroup.securityDefinition.history) {
            this.setSecurityDefinitionHistoryTable([...this.props.selectedGroup.securityDefinition.history]);
        }
    }
}