'use strict';

/*@ngInject*/
function TrainingTimePeriodsListCtrl($scope, Flags, Education, ModalSpinner, $filter, FormModal, ErrorModal, WarningModal, AgendaConstants, Resource) {
	var data;
	var realizationsMap = {};
	var trainingTimePeriodsSession = $scope.groupRegistrations.timePeriods ? $scope.groupRegistrations.timePeriods.session : null;
	if (trainingTimePeriodsSession && !$scope.groupRegistrations.session.selectOrCreateNewSession && ((trainingTimePeriodsSession.id && $scope.groupRegistrations.session.id) || (trainingTimePeriodsSession.id == $scope.groupRegistrations.session.id))) {
		data = $scope.groupRegistrations.timePeriods && $scope.groupRegistrations.timePeriods.gridOptions ? $scope.groupRegistrations.timePeriods.gridOptions.data : null;
		realizationsMap = $scope.groupRegistrations.session.realizationsMap ? $scope.groupRegistrations.session.realizationsMap : {};
	}
	$scope.groupRegistrations.session.selectOrCreateNewSession = false;

	var invalidSaveSimulationOnEdit = true;
	var invalidSaveEffectiveOnEdit = true;

	var trainingTimePeriods = $scope.groupRegistrations.timePeriods = this;
	trainingTimePeriods.realizationStatuses = $scope.groupRegistrations.realizationStatuses;
	trainingTimePeriods.realizationsMap = $scope.groupRegistrations.session.realizationsMap = realizationsMap;

	trainingTimePeriods.setWizardInvalidForSave = function(invalidWizardForSave) {
		$scope.wizard.invalidForSave = invalidWizardForSave;
	}

	trainingTimePeriods.checkValid = function() {
		var incompleteData = false;
		var unsavedRegistrations = false;
		var simulationRegistrations = false;
		for (var i = 0; i < trainingTimePeriods.gridOptions.data.length; i++) {
			var trainingTimePeriod = trainingTimePeriods.gridOptions.data[i];
			if (!(trainingTimePeriod.timePeriod && trainingTimePeriod.timePeriod.trainingComponentVersion && trainingTimePeriod.timePeriod.fromDate && trainingTimePeriod.timePeriod.toDate) 
				|| !(trainingTimePeriod.participantRegistration && trainingTimePeriod.participantRegistration.resource)) {
				incompleteData = true;
				break;
			} else {
				if (!trainingTimePeriod.participantRegistration.id) {
					unsavedRegistrations = true;
				} else if (trainingTimePeriod.participantRegistration.status.code == "SIM") {
					simulationRegistrations = true;
				}
			}
		}
		var invalidSaveSimulation = true, invalidSaveEffective = true;
		if (!invalidSaveSimulationOnEdit) {
			invalidSaveSimulation = false;
		}
		if (!invalidSaveEffectiveOnEdit) {
			invalidSaveEffective = false;
		}
		if (incompleteData) {
			invalidSaveSimulation = true;
			invalidSaveEffective = true;
		} else if (unsavedRegistrations) {
			invalidSaveSimulation = false;
			invalidSaveEffective = false;
		} else if (simulationRegistrations) {
			invalidSaveEffective = false;
		}
		if (!incompleteData && (!$scope.groupRegistrations.invalidWizardForSave || trainingTimePeriods.session.registrationsToRemove.length > 0)) {
			invalidSaveSimulation = false;
		}
		trainingTimePeriods.setWizardInvalidForSave(invalidSaveSimulation);
		$scope.groupRegistrations.setSaveAsEffectiveInvalid(invalidSaveEffective);
	};

	trainingTimePeriods.gridOptions = {
		columnDefs: [{
				name: 'resource',
				headerTemplateUrl: '/group-registrations/training-time-periods-actions-header.html',
				cellTemplateUrl: '/group-registrations/training-time-periods-trainer.html',
				cellClass: function(row) {
					var result = '';
					if (!(row.entity.timePeriod && row.entity.timePeriod.trainingComponentVersion && row.entity.timePeriod.fromDate && row.entity.timePeriod.toDate) 
						|| !(row.entity.participantRegistration && row.entity.participantRegistration.resource)) {
						result += 'danger text-danger';
					}

					return result;
				},
				containerClass: 'training-time-periods-grid-min-width-cell'
			}, {
				name: 'registrationFromDate',
				field: 'participantRegistration.fromDate',
				displayName: 'education.groupRegistrations.registrationFromDate',
				cellTemplate: require('./training-time-periods-registration-from-date.html'),
				containerClass: 'training-time-periods-grid-min-width-cell'
			}, {
				name: 'registrationToDate',
				field: 'participantRegistration.toDate',
				displayName: 'education.groupRegistrations.registrationToDate',
				cellTemplate: require('./training-time-periods-registration-to-date.html'),
				containerClass: 'training-time-periods-grid-min-width-cell'
			}, {
				name: 'fromDate',
				field: 'participantRegistration.detail.realization.date',
				displayName: 'education.groupRegistrations.timePeriod.fromDate',
				cellTemplate: require('./training-time-periods-realization-date.html'),
				containerClass: 'training-time-periods-grid-max-width-cell'
			}, {
				name: 'toDate',
				field: 'participantRegistration.detail.realization.dueDate',
				displayName: 'education.groupRegistrations.timePeriod.toDate',
				cellTemplate: require('./training-time-periods-realization-due-date.html'),
				containerClass: 'training-time-periods-grid-max-width-cell'
			}, {
				name: 'status',
				field: 'participantRegistration.detail.realization.result.description',
				displayName: 'education.groupRegistrations.timePeriod.status',
				cellTemplate: require('./training-time-periods-realization-status.html')
			}, {
				name: 'socialHoursBalance',
				field: 'participantRegistration.detail.socialHoursBalance',
				displayName: 'education.groupRegistrations.socialHoursBalance',
				cellTemplate: require('./training-time-periods-realization-sociale-hours-balance.html'),
				containerClass: 'training-time-periods-grid-max-width-cell'
			}, {
				name: 'actions',
				headerTemplateUrl: '/group-registrations/training-time-periods-actions-header.html',
				cellTemplate: require('./training-time-periods-actions.html')
			}
		],

		onRegisterApi: function (gridApi) {
			trainingTimePeriods.gridApi = gridApi;
			gridApi.grouping.setGrouping({
				grouping: [{
					name: 'resource',
					criteria: 'trainer',
					fields: ['timePeriod.fromDate', 'timePeriod.toDate', 'timePeriod.trainer', 'timePeriod.trainingComponentVersion', 'timePeriod.trainingCenter']
				}]
			});
			gridApi.grouping.expandCollapseAll();
		}
	};

	function onChangeFromDate(newDate, row) {
		if (!newDate) { return; }
		const oldDate = row.entity.participantRegistration.currentFromDate ? new Date(row.entity.participantRegistration.currentFromDate) : new Date(row.entity.timePeriod.fromDate);

		if (isOutsideOfTimePeriod(row.entity.timePeriod, newDate)) {
			row.entity.participantRegistration.fromDate = oldDate;
			ErrorModal.open({
				titleId: ErrorModal.VALIDATION_ERROR, 
				message: $filter('translate')('education.groupRegistrations.wrongDate')
			});
			return;
		} else if (row.entity.participantRegistration.toDate && newDate.getTime() >= new Date(row.entity.participantRegistration.toDate).getTime()) {
			row.entity.participantRegistration.fromDate = oldDate;
			ErrorModal.open({
				titleId: ErrorModal.VALIDATION_ERROR, 
				message: $filter('translate')('education.groupRegistrations.wrongFromDate')
			});
			return;
		} else if (row.entity.participantRegistration.initialFromDate && row.entity.participantRegistration.initialFromDate.getTime() !==
			newDate.getTime()) {
			row.entity.participantRegistration.fromDate = newDate;
		} else if (oldDate.getTime() == newDate.getTime()) {
			row.entity.participantRegistration.fromDate = oldDate;
			return;
		}
		row.entity.participantRegistration.fromDate = new Date(newDate)
		row.entity.participantRegistration.currentFromDate = new Date(newDate);
		trainingTimePeriods.onChange(row);
	}

	function onChangeToDate(newDate, row) {
		if (!newDate) { return; }
		const oldDate = row.entity.participantRegistration.currentToDate ? new Date(row.entity.participantRegistration.currentToDate) : new Date(row.entity.timePeriod.toDate);
		
		if (isOutsideOfTimePeriod(row.entity.timePeriod, newDate)) {
			row.entity.participantRegistration.toDate = oldDate;
			ErrorModal.open({
				titleId: ErrorModal.VALIDATION_ERROR, 
				message: $filter('translate')('education.groupRegistrations.wrongDate')
			});
			return;
		} else if (row.entity.participantRegistration.fromDate && newDate.getTime() <= new Date(row.entity.participantRegistration.fromDate).getTime()) {
			row.entity.participantRegistration.toDate = oldDate;
			ErrorModal.open({
				titleId: ErrorModal.VALIDATION_ERROR, 
				message: $filter('translate')('education.groupRegistrations.wrongToDate')
			});
			return;
		}  else if (row.entity.participantRegistration.initialToDate && row.entity.participantRegistration.initialToDate.getTime() !==
			newDate.getTime()) {
			row.entity.participantRegistration.toDate = newDate;
		} else if (oldDate.getTime() == newDate.getTime()) {
			row.entity.participantRegistration.toDate = oldDate;
			return;
		}
		row.entity.participantRegistration.toDate = new Date(newDate);
		row.entity.participantRegistration.currentToDate = new Date(newDate);
		trainingTimePeriods.onChange(row);
	}

	trainingTimePeriods.refresh = function(sessionId, selectNew, showSpinner) {
		if (data && !selectNew) {
			trainingTimePeriods.gridOptions.data = data;
			trainingTimePeriods.session = trainingTimePeriodsSession;
			ModalSpinner.hide();
			trainingTimePeriods.checkValid();
			return;
		}

		if (showSpinner) {
			ModalSpinner.show('education.evaluationRequest.loading');
		}
		Education.getTrainingTimePeriods(null, {
			params: {
				id: sessionId ? sessionId : $scope.groupRegistrations.session.id
			}
		}).then(function(result) {
			trainingTimePeriods.session = result.data;
			delete trainingTimePeriods.gridOptions.data;
			trainingTimePeriods.gridOptions.data = [];

			var invalidSaveAsEffective = true;

			// process the time periods
			var k = 0;
			var timePeriods = result.data.timePeriods;
			for (var i = 0; i < timePeriods.length; i++) {
				if (timePeriods[i].participantRegistrations && timePeriods[i].participantRegistrations.length > 0) {
					for (var j = 0; j < timePeriods[i].participantRegistrations.length; j++) {
						var trainingTimePeriod = trainingTimePeriods.createNewTraining(timePeriods[i], timePeriods[i].participantRegistrations[j]);
						trainingTimePeriods.gridOptions.data[trainingTimePeriods.gridOptions.data.length] = trainingTimePeriod;
						timePeriods[i].participantRegistrations[j].resourceSnapshot = timePeriods[i].participants[timePeriods[i].participantRegistrations[j].resource]; 
						if (timePeriods[i].participantRegistrations[j].detail && timePeriods[i].participantRegistrations[j].detail.realization) {
							timePeriods[i].participantRegistrations[j].detail.realization.date = timePeriods[i].participantRegistrations[j].detail.realization.date ? new Date(timePeriods[i].participantRegistrations[j].detail.realization.date) : null;
							timePeriods[i].participantRegistrations[j].detail.realization.dueDate = timePeriods[i].participantRegistrations[j].detail.realization.dueDate ? new Date(timePeriods[i].participantRegistrations[j].detail.realization.dueDate) : null;
							timePeriods[i].participantRegistrations[j].fromDate = timePeriods[i].participantRegistrations[j].fromDate ? new Date(timePeriods[i].participantRegistrations[j].fromDate) : null;
							timePeriods[i].participantRegistrations[j].toDate = timePeriods[i].participantRegistrations[j].toDate ? new Date(timePeriods[i].participantRegistrations[j].toDate) : null;
							timePeriods[i].participantRegistrations[j].initialFromDate = timePeriods[i].participantRegistrations[j].fromDate;
							timePeriods[i].participantRegistrations[j].initialToDate = timePeriods[i].participantRegistrations[j].toDate;
						}
						timePeriods[i].realization = {};

						// if there are registration with SIMULATION status => enable "Save as effective" button
						if (timePeriods[i].participantRegistrations[j].status.code == 'SIM') {
							invalidSaveAsEffective = false;
						}
					}
				} else {
					trainingTimePeriods.gridOptions.data[k++] = trainingTimePeriods.createNewTraining(timePeriods[i], {resource:null});
				}
			}

			setGroupingAndExpand();
			trainingTimePeriods.checkValid();
		}).finally(function() {
			ModalSpinner.hide('education.evaluationRequest.loading');
		});
	}

	// initialise the table
	if (!$scope.groupRegistrations.session.id && !data) {
		// new session
		trainingTimePeriods.gridOptions.data = [];
		trainingTimePeriods.session = $scope.groupRegistrations.session;
		trainingTimePeriods.session.timePeriods = [];
		trainingTimePeriods.session.registrationsToRemove = [];

		setGroupingAndExpand();
		trainingTimePeriods.checkValid();
	} else {
		// load the existing session
		trainingTimePeriods.refresh(null, null, true);
	}

	function setGroupingAndExpand() {
		if (!trainingTimePeriods.gridApi) {
			return;
		}
		trainingTimePeriods.gridApi.grouping.setGrouping({
			grouping: [{
				name: 'resource',
				criteria: 'trainer',
				fields: ['timePeriod.fromDate', 'timePeriod.toDate', 'timePeriod.trainer', 'timePeriod.trainingComponentVersion', 'timePeriod.trainingCenter']
				}]
			});
		trainingTimePeriods.gridApi.grouping.expandCollapseAll();
	}
	
	trainingTimePeriods.getChildren = function(row) {
		return row.children;
	}

	function createNewRealization(trainingTimePeriod, date, dueDate, status) {
		var realization = {
			date: date,
			dueDate: dueDate,
			result: status, 
			person: trainingTimePeriod.participantRegistration.resource,
			componentVersion: trainingTimePeriod.timePeriod.trainingComponentVersion,
			objectType: "Realization"
		}

		if (!trainingTimePeriod.participantRegistration || !trainingTimePeriod.participantRegistration.resource) {
			return;
		}

		trainingTimePeriod.participantRegistration.detail.realization = realization;
		trainingTimePeriods.gridApi.core.computeRows(trainingTimePeriods.gridOptions.data);
	}

	trainingTimePeriods.changeGrouping = function(grid) {
		if (grid.api.grouping.config.resource.criteria == 'trainer') {
			grid.api.grouping.setGrouping({
				grouping: [{
					name: 'resource',
					criteria: 'trainee',
					field: 'participantRegistration.resource'
				}]
			});
		} else {
			grid.api.grouping.setGrouping({
				grouping: [{
					name: 'resource',
					criteria: 'trainer',
					fields: ['timePeriod.fromDate', 'timePeriod.toDate', 'timePeriod.trainer', 'timePeriod.trainingComponentVersion', 'timePeriod.trainingCenter']
				}]
			});
		}
		grid.api.grouping.expandCollapseAll();
	};

	trainingTimePeriods.createNewTraining = function(timePeriod, participantRegistration) {
		var timePeriodObject = {};
		timePeriodObject.timePeriod = timePeriod;
		timePeriodObject.participantRegistration = participantRegistration;
		return timePeriodObject;
	}

	trainingTimePeriods.openModal = function(label, template, model, callback, title) {
		FormModal.open({
			scope: {
				model: model,
				resourceLbl: label,
				template: template,
				title: title,
				isModelInvalid: function(model) {
					let invalidRealization = model.realization && (angular.isUndefined(model.realization.date) || model.realization.date === null);
					if (model.mode == 'trainer') {
						return invalidRealization || !model.timePeriod || (!model.timePeriod.trainingComponentVersion || !model.timePeriod.fromDate || !model.timePeriod.toDate);
					} else if (model.mode == 'trainee') {
						return invalidRealization || !model.participantRegistration || (!model.participantRegistration.resourceSnapshot || model.participantRegistration.resourceSnapshot.objectType != 'EmployeeSnapshot');
					}
					return invalidRealization || !model.resource || !model.resource.objectType;
				},
				allowOverflowContainer: true
			},
			windowClass: 'modal-absolute-content',
			actions: ['ok', 'cancel', 'close']
		}).result.then(function(model) {
			callback(model);
		});
	}

	trainingTimePeriods.addTrainer = function(grid) {
		var model = {
			newEntity: true,
			mode: 'trainer',
			editAllRealizations: true,
			timePeriod: {},
			participantRegistration: {},
			realizationStatuses: trainingTimePeriods.realizationStatuses
		};
		trainingTimePeriods.openModal(null, '<time-period-editor entity="model"></time-period-editor>', model,
			function (model) {
				addTrainer(model);
			},
			'add'
		);
	}

	function checkIfInstructorRegistrationsOverlap(existingTimePeriod, newTimePeriod) {
		// When one of the timeperiods does not have a trainer (or both don't), there is only one or none registrations so overlap is not possible
		// Check only if there is another timeperiod with exactly same dates, component etc.
		if (!!existingTimePeriod && (!newTimePeriod.trainer || !existingTimePeriod.trainer)) {
			if (((!newTimePeriod.fromDate && !existingTimePeriod.fromDate && !newTimePeriod.toDate && !existingTimePeriod.toDate) 
			|| ((new Date(newTimePeriod.fromDate)).setMilliseconds(0) === (new Date(existingTimePeriod.fromDate)).setMilliseconds(0) && (new Date(newTimePeriod.toDate)).setMilliseconds(0) === (new Date(existingTimePeriod.toDate)).setMilliseconds(0)))
			&& ((!newTimePeriod.trainingCenter && !existingTimePeriod.trainingCenter) || (newTimePeriod.trainingCenter && existingTimePeriod.trainingCenter && newTimePeriod.trainingCenter.id == existingTimePeriod.trainingCenter.id))
			&& (newTimePeriod.trainingComponentVersion && existingTimePeriod.trainingComponentVersion && newTimePeriod.trainingComponentVersion.id == existingTimePeriod.trainingComponentVersion.id)) {
				ErrorModal.open({
					titleId: ErrorModal.VALIDATION_ERROR, 
					message: $filter('translate')('education.groupRegistrations.identicalTimePeriod')
				});
				return true;
			}
			return false;
		}

		// Check if the instructor registration will overlap with an existing registration from the same session.
		// In case the registration will overlap with another registration from a different session, the validation is done on server side too.
		if (!!existingTimePeriod && ((!newTimePeriod.fromDate && !existingTimePeriod.fromDate) || (!newTimePeriod.toDate && !existingTimePeriod.toDate)
				|| (newTimePeriod.fromDate && existingTimePeriod.fromDate && newTimePeriod.toDate && existingTimePeriod.toDate 
				&& (new Date(newTimePeriod.fromDate)).setMilliseconds(0) <= (new Date(existingTimePeriod.toDate)).setMilliseconds(0) 
				&& (new Date(newTimePeriod.toDate)).setMilliseconds(0) >= (new Date(existingTimePeriod.fromDate)).setMilliseconds(0))
				&& (newTimePeriod.trainer && existingTimePeriod.trainer && newTimePeriod.trainer.id == existingTimePeriod.trainer.id && newTimePeriod.trainer.objectType != "ContactPersonSnapshot")
				&& ((!newTimePeriod.trainingCenter && !existingTimePeriod.trainingCenter) || (newTimePeriod.trainingCenter && existingTimePeriod.trainingCenter && newTimePeriod.trainingCenter.id == existingTimePeriod.trainingCenter.id))
				&& (newTimePeriod.trainingComponentVersion && existingTimePeriod.trainingComponentVersion && newTimePeriod.trainingComponentVersion.id == existingTimePeriod.trainingComponentVersion.id))) {
				return true;
		}
		return false;
	}

	function checkTimeperiodOverlapWithExisting(newTimePeriod) {
		if (trainingTimePeriods.gridOptions.data) {
			for (var i = 0; i < trainingTimePeriods.gridOptions.data.length; i++) {
				var trainingTimePeriod = trainingTimePeriods.gridOptions.data[i];
				var currentTimePeriod = trainingTimePeriod.timePeriod;
				// don't compare the overlap of the currentTimePeriod with itself
				const trainerRegistrationOverlap = !angular.equals(currentTimePeriod, newTimePeriod) && checkIfInstructorRegistrationsOverlap(currentTimePeriod, newTimePeriod);
				if (trainerRegistrationOverlap) {
					return true;
				}
			}
		}
		return false;
	}

	function addTrainer(model) {
		// create new time period -> trainer, component, period (from date - to date)
		var timePeriod = {};
		timePeriod.fromDate = model && model.timePeriod ? model.timePeriod.fromDate : null;
		timePeriod.toDate = model && model.timePeriod ? model.timePeriod.toDate : null;
		timePeriod.trainer = model && model.timePeriod && model.timePeriod.trainer && model.timePeriod.trainer.objectType ? model.timePeriod.trainer : null;
		timePeriod.trainerSocialHoursBalance = model && model.timePeriod ? model.timePeriod.trainerSocialHoursBalance : null;
		timePeriod.trainingCenter = model && model.timePeriod ? model.timePeriod.trainingCenter : null;
		timePeriod.trainingComponentVersion = model && model.timePeriod ? model.timePeriod.trainingComponentVersion : null;
		timePeriod.socialHoursBalance = model && model.timePeriod ? model.timePeriod.socialHoursBalance : null;
		timePeriod.realization = {};
		// if the created timePeriod contains a trainer, automatically set its default errorPolicy to "ENFORCE_ALL".
		timePeriod.resourcesToErrorPolicy = {};
		if (model && model.timePeriod && model.timePeriod.trainer) {
			timePeriod.resourcesToErrorPolicy[model.timePeriod.trainer.id] = {errorPolicy: "ENFORCE_ALL"};
		}

		if (checkTimeperiodOverlapWithExisting(timePeriod)) {
			return;
		}

		timePeriod.registrationsToUpdate = [];
		timePeriod.participants = {};

		if (model && model.timePeriod && model.timePeriod.trainer && model.timePeriod.trainer.contractHistoryItem) {
			timePeriod.trainerRegistration = {
				resource: model.timePeriod.trainer.id,
				environment: AgendaConstants.ENVIRONMENT,
				detail: {
					objectType: AgendaConstants.DETAIL_INSTRUCTOR,
					componentVersion: model.timePeriod.trainingComponentVersion,
					socialHoursBalance: timePeriod.trainerSocialHoursBalance || 0
				}
			}
		}

		// dummy participant registration
		var participantRegistration = {};
		participantRegistration.resource = null;

		// add the new training time period to table + session
		var trainingTimePeriod = trainingTimePeriods.createNewTraining(timePeriod, participantRegistration);
		trainingTimePeriods.gridOptions.data[trainingTimePeriods.gridOptions.data.length] = trainingTimePeriod;
		if (!trainingTimePeriods.session.timePeriods) {
			trainingTimePeriods.session.timePeriods = [timePeriod];
		} else {
			trainingTimePeriods.session.timePeriods[trainingTimePeriods.session.timePeriods.length] = timePeriod;
		}

		trainingTimePeriods.checkValid();

		return trainingTimePeriod;
	}

	trainingTimePeriods.addTrainerForTrainingTimePeriod = function(grid, trainingTimePeriod) {
		var model = {
			newEntity: true,
			mode: 'trainer',
			editAllRealizations: true,
			timePeriod: {},
			participantRegistration: {},
			realizationStatuses: trainingTimePeriods.realizationStatuses
		};
		trainingTimePeriods.openModal(null, '<time-period-editor entity="model"></time-period-editor>', model,
			function (model) {
				ModalSpinner.show();
				addNewTrainer(grid, trainingTimePeriod, model);
				ModalSpinner.hide();
			},
			'add'
		);
	}

	function addNewTrainer(grid, trainingTimePeriod, model) {
		// if the trainer is also a participant, return.
		if (model && model.timePeriod && model.timePeriod.trainer && model.timePeriod.trainer.id === trainingTimePeriod.participantRegistration.resource) {
			return;
		}

		trainingTimePeriods.removeIncompleteTrainingTimePeriods(grid, 'participant', trainingTimePeriod);

		// create new time period -> trainer, component, period (from date - to date)
		var timePeriod = {};
		timePeriod.fromDate = model && model.timePeriod ? model.timePeriod.fromDate : null;
		timePeriod.toDate = model && model.timePeriod ? model.timePeriod.toDate : null;
		timePeriod.trainer = model && model.timePeriod && model.timePeriod.trainer && model.timePeriod.trainer.objectType ? model.timePeriod.trainer : null;
		timePeriod.trainerSocialHoursBalance = model && model.timePeriod ? model.timePeriod.trainerSocialHoursBalance : null;
		timePeriod.trainingCenter = model && model.timePeriod ? model.timePeriod.trainingCenter : null;
		timePeriod.trainingComponentVersion = model && model.timePeriod ? model.timePeriod.trainingComponentVersion : null;
		timePeriod.realization = {};
		timePeriod.socialHoursBalance = model && model.timePeriod ? model.timePeriod.socialHoursBalance : null;
		// if the timePeriod contains a participant, automatically set its default errorPolicy to "ENFORCE_ALL".
		timePeriod.resourcesToErrorPolicy = {};
		if (trainingTimePeriod && trainingTimePeriod.participantRegistration) {
			timePeriod.resourcesToErrorPolicy[trainingTimePeriod.participantRegistration.resource] = {errorPolicy: "ENFORCE_ALL"};
		}

		if (timePeriod.trainer) {
			// if the timePeriod contains a trainer, automatically set its default errorPolicy to "ENFORCE_ALL".
			timePeriod.resourcesToErrorPolicy[timePeriod.trainer.id] = {errorPolicy: "ENFORCE_ALL"};
		}

		if (!timePeriod.registrationsToUpdate) {
			timePeriod.registrationsToUpdate = [];
		}

		if (model && model.timePeriod && model.timePeriod.trainer && model.timePeriod.trainer.contractHistoryItem) {
			timePeriod.trainerRegistration = {
				resource: model.timePeriod.trainer.id,
				environment: AgendaConstants.ENVIRONMENT,
				detail: {
					objectType: AgendaConstants.DETAIL_INSTRUCTOR,
					componentVersion: model ? model.timePeriod.trainingComponentVersion : null,
					socialHoursBalance: timePeriod.trainerSocialHoursBalance || 0
				}
			} 
		}

		// add participant registration to training time period
		var resource = trainingTimePeriod.participantRegistration.resource;
		var participantRegistration = {
			resource: resource,
			resourceSnapshot: trainingTimePeriod.participantRegistration.resourceSnapshot,
			environment: AgendaConstants.ENVIRONMENT
		}
		participantRegistration.detail = {
			objectType: AgendaConstants.DETAIL_TRAINING,
			trainer: timePeriod.trainer,
			componentVersion: timePeriod.trainingComponentVersion,
			company: timePeriod.trainingCenter,
			socialHoursBalance: timePeriod.socialHoursBalance || 0
		};

		timePeriod.participantRegistrations = [participantRegistration];
		timePeriod.participants = timePeriod.participants ? timePeriod.participants : {};
		timePeriod.participants[resource] = trainingTimePeriod.participantRegistration.resourceSnapshot;

		// add the new training time period to table + session
		var trainingTimePeriod = trainingTimePeriods.createNewTraining(timePeriod, participantRegistration);
		trainingTimePeriods.gridOptions.data[trainingTimePeriods.gridOptions.data.length] = trainingTimePeriod;

		if (!!trainingTimePeriod.timePeriod && !!trainingTimePeriod.timePeriod.trainingComponentVersion) {
			getRealization(grid, resource, participantRegistration, trainingTimePeriod);
		}

		if (model) {
			if (!trainingTimePeriods.session.timePeriods) {
				trainingTimePeriods.session.timePeriods = [timePeriod];
			} else {
				trainingTimePeriods.session.timePeriods[trainingTimePeriods.session.timePeriods.length] = timePeriod;
			}
		}

		if (!!trainingTimePeriod.timePeriod && !!trainingTimePeriod.timePeriod.trainingComponentVersion) {
			trainingTimePeriods.removeIncompleteTrainingTimePeriods(grid, 'participant', trainingTimePeriod);
		}

		trainingTimePeriods.checkValid();
	}

	trainingTimePeriods.addParticipant = function(grid) {
		var model = {
			newEntity: true,
			timePeriod: {},
			participantRegistration: {}
		};
		trainingTimePeriods.openModal('Deelnemer', require('../evaluation/request/resource-picker-popup.html'), model,
			function(model) {
				ModalSpinner.show();
				// check if participant is duplicate for trainee grouping
				if (trainingTimePeriods.gridOptions.data) {
					for (var i = 0; i < trainingTimePeriods.gridOptions.data.length; i++) {
						var trainingTimePeriod = trainingTimePeriods.gridOptions.data[i];
						if (trainingTimePeriod.participantRegistration && trainingTimePeriod.participantRegistration.resource == model.resource.id) {
							return;
						}
					} 
				}
				
				// create participant registration
				var participantRegistration = {
					resource: model.resource.id,
					environment: AgendaConstants.ENVIRONMENT,
					resourceSnapshot: model.resource
				}
				participantRegistration.detail = {
					objectType: AgendaConstants.DETAIL_TRAINING
				};

				// add the new training time period to table
				trainingTimePeriods.gridOptions.data[trainingTimePeriods.gridOptions.data.length] = trainingTimePeriods.createNewTraining({}, participantRegistration);

				trainingTimePeriods.checkValid();
				ModalSpinner.hide();
			},
			'add'
		);
	}

	trainingTimePeriods.addParticipantForTrainingTimePeriod = function(grid, trainingTimePeriod) {
		var model = {
			newEntity: true,
			timePeriod: {},
			participantRegistration: {}
		};
		trainingTimePeriods.openModal('Deelnemer', require('../evaluation/request/resource-picker-popup.html'), model,
			function(model) {
				ModalSpinner.show();
				addNewParticipant(grid, trainingTimePeriod, model.resource);
				ModalSpinner.hide();
			},
			'add'
		);
	}

	// Check if the resource is already in time period as a participant or as a trainer.
	// In case the resource already exists, prevent its duplication in the time period.
	function checkIfParticipantAlreadyInTimePeriod(trainingTimePeriod, resource) {
		if (!resource) {
			return false;
		}

		if (trainingTimePeriod.timePeriod.participants[resource.id] || (trainingTimePeriod.timePeriod.trainer && trainingTimePeriod.timePeriod.trainer.id === resource.id)) {
			return true;
		}
		return false;
	}

	function addNewParticipant(grid, trainingTimePeriod, resource, latestRealizationsForResourcesAndComponent) {
		if (checkIfParticipantAlreadyInTimePeriod(trainingTimePeriod, resource)) {
			return;
		}

		trainingTimePeriods.removeIncompleteTrainingTimePeriods(grid, 'trainer', trainingTimePeriod);
		var that = trainingTimePeriod;

		// create participant registration
		var participantRegistration = {
			resource: resource ? resource.id : null,
			fromDate: trainingTimePeriod.timePeriod.fromDate,
			toDate: trainingTimePeriod.timePeriod.toDate,
			environment: AgendaConstants.ENVIRONMENT,
			resourceSnapshot: resource
		}
		participantRegistration.detail = {
			objectType: AgendaConstants.DETAIL_TRAINING,
			componentVersion: trainingTimePeriod.timePeriod.trainingComponentVersion,
			trainer: trainingTimePeriod.timePeriod.trainer,
			company: trainingTimePeriod.timePeriod.trainingCenter,
			socialHoursBalance: trainingTimePeriod.timePeriod.socialHoursBalance || 0
		};

		// add the new training time period to table
		var newTrainingTimePeriod = trainingTimePeriods.createNewTraining(that.timePeriod, participantRegistration);
		if (resource) {
			newTrainingTimePeriod.timePeriod.resourcesToErrorPolicy[resource.id] = {policy: "ENFORCE_ALL"};
		}
		trainingTimePeriods.gridOptions.data[trainingTimePeriods.gridOptions.data.length] = newTrainingTimePeriod;

		// get existing realization
		if (resource && resource.id) {
			getRealization(grid, resource.id, participantRegistration, newTrainingTimePeriod, latestRealizationsForResourcesAndComponent);
		}

		// add participant registration to training time period
		if (resource) {
			if (!newTrainingTimePeriod.timePeriod.participantRegistrations) {
				newTrainingTimePeriod.timePeriod.participantRegistrations = [participantRegistration];
			} else {
				newTrainingTimePeriod.timePeriod.participantRegistrations[newTrainingTimePeriod.timePeriod.participantRegistrations.length] = participantRegistration;
			}
			newTrainingTimePeriod.timePeriod.participants[resource.id] = resource;
			trainingTimePeriods.removeIncompleteTrainingTimePeriods(grid, 'trainer', newTrainingTimePeriod);
		}

		trainingTimePeriods.checkValid();
	}

	trainingTimePeriods.edit = function(grid, row) {
		if (!row.rowEnabled) {
			return;
		}

		if (grid.api.grouping.config.resource.criteria == 'trainer') {
			if (row.grouping) {
				trainingTimePeriods.editTrainer(grid, row, true);
			} else {
				trainingTimePeriods.editParticipant(grid, row);
			}
		} else {
			if (row.grouping) {
				trainingTimePeriods.editParticipant(grid, row, true);
			} else {
				trainingTimePeriods.editTrainer(grid, row);
			}
		}	
	}

	trainingTimePeriods.editTrainer = function(grid, row, editAllRealizations) {
		var modalModel = {};
		modalModel.timePeriod = angular.extend({}, row.entity.timePeriod);
		modalModel.participantRegistration = angular.extend({}, row.entity.participantRegistration);
		modalModel.realizationStatuses = trainingTimePeriods.realizationStatuses;
		modalModel.mode = 'trainer';
		modalModel.editAllRealizations = editAllRealizations;

		if (editAllRealizations) {
			openModalForEditTrainer(grid, row, modalModel);
		} else {
			trainingTimePeriods.getRealizationsForParticipant(row.entity.participantRegistration.resource, row.entity.timePeriod.trainingComponentVersion.id, openModalForEditTrainer, grid, row, modalModel);
		}

	}

	function copyRealizationFields(dest, source) {
		if ((dest.id && source.id && dest.id != source.id) || (dest.id && !source.id) || (!dest.id && source.id)) {
			dest = source;
			return dest;
		}
		dest.date = source.date;
		dest.dueDate = source.dueDate;
		dest.result = source.result;

		// modify the realization if is used in multiple time periods
		for (var i in trainingTimePeriods.gridOptions.data) {
			var trainingTimePeriod = trainingTimePeriods.gridOptions.data[i];
			if (!!trainingTimePeriod && !!trainingTimePeriod.participantRegistration && !!trainingTimePeriod.participantRegistration.detail && !!trainingTimePeriod.participantRegistration.detail.realization) {
				var realization = trainingTimePeriod.participantRegistration.detail.realization;
				if ((dest.id && realization.id && dest.id == realization.id)
					|| (!dest.id && !realization.id && dest.person == realization.person && dest.componentVersion && realization.componentVersion && dest.componentVersion.id == realization.componentVersion.id)) {
					realization.date = source.date;
					realization.dueDate = source.dueDate;
					realization.result = source.result;
				}
			}
		}

		// update the new realizations
		var realizationString = dest.componentVersion ? dest.componentVersion.name + "-" + dest.person : "";
		if (!!trainingTimePeriods.realizationsMap && !!trainingTimePeriods.realizationsMap[realizationString]) {
			var realization = trainingTimePeriods.realizationsMap[realizationString];
			realization.date = source.date;
			realization.dueDate = source.dueDate;
			realization.result = source.result;
		}

		return dest;
	}
	
	function trainerFormDataHasBeenModified(oldTimePeriod, newTimePeriod) {
		if (new Date(oldTimePeriod.fromDate).getTime() !== new Date(newTimePeriod.fromDate).getTime() || 
			new Date(oldTimePeriod.toDate).getTime() !== new Date(newTimePeriod.toDate).getTime() || 
			oldTimePeriod.trainingComponentVersion.id !== newTimePeriod.trainingComponentVersion.id ||
			(oldTimePeriod.trainer && newTimePeriod.trainer && oldTimePeriod.trainer.id !== newTimePeriod.trainer.id) ||
			(!oldTimePeriod.trainer && newTimePeriod.trainer) || (oldTimePeriod.trainer && !newTimePeriod.trainer) ||
			(oldTimePeriod.trainingCenter && newTimePeriod.trainingCenter && oldTimePeriod.trainingCenter.id !== newTimePeriod.trainingCenter.id) || 
			(!oldTimePeriod.trainingCenter && newTimePeriod.trainingCenter) || (oldTimePeriod.trainingCenter && !newTimePeriod.trainingCenter) ||
			(oldTimePeriod.socialHoursBalance !== newTimePeriod.socialHoursBalance) ||
			(oldTimePeriod.trainerSocialHoursBalance !== newTimePeriod.trainerSocialHoursBalance)) {
				return true;
		}
		return false;
	}

	function updateErrorPolicyIfTrainerChanged(oldTimePeriod, newTimePeriod) {
		if (!oldTimePeriod.trainer && newTimePeriod.trainer) {
			// The trainer was added now and was null before. In this case, add the errorPolicy to the time period.
			newTimePeriod.resourcesToErrorPolicy[newTimePeriod.trainer.id] = {policy: "ENFORCE_ALL"};
		} else if (oldTimePeriod.trainer && !newTimePeriod.trainer) {
			// The trainer was removed now and was not null before. In this case, remove the trainer id from resourcesToErrorPolicy.
			delete newTimePeriod.resourcesToErrorPolicy[oldTimePeriod.trainer.id];
		} else if (oldTimePeriod.trainer && newTimePeriod.trainer && oldTimePeriod.trainer.id !== newTimePeriod.trainer.id) {
			// The trainer was changed. In this case, remove the old trainer id from resourcesToErrorPolicy and add the new trainer id.
			delete newTimePeriod.resourcesToErrorPolicy[oldTimePeriod.trainer.id];
			newTimePeriod.resourcesToErrorPolicy[newTimePeriod.trainer.id] = {policy: "ENFORCE_ALL"};
		}
	}

	function enableSaveButtons() {
		invalidSaveEffectiveOnEdit = false;
		invalidSaveSimulationOnEdit = false;
	}

	function copyTimePeriodValues(timePeriod, model) {
		timePeriod.fromDate = model && model.timePeriod ? model.timePeriod.fromDate : null;
		timePeriod.toDate = model && model.timePeriod ? model.timePeriod.toDate : null;
		timePeriod.trainingComponentVersion = model && model.timePeriod ? model.timePeriod.trainingComponentVersion : null;
		timePeriod.trainer = model && model.timePeriod && model.timePeriod.trainer && model.timePeriod.trainer.objectType ? model.timePeriod.trainer : null;
		timePeriod.trainerSocialHoursBalance = model && model.timePeriod ? model.timePeriod.trainerSocialHoursBalance : null;
		timePeriod.trainingCenter = model && model.timePeriod ? model.timePeriod.trainingCenter : null;
		timePeriod.socialHoursBalance = model && model.timePeriod ? model.timePeriod.socialHoursBalance : null;
	}

	function openModalForEditTrainer(grid, row, model) {
		trainingTimePeriods.openModal(null, '<time-period-editor entity="model" x-disabled="disabled"></time-period-editor>', model,
			function (model) {
				// if the trainer is already in time period (as a participant), return.
				if (model.timePeriod.trainer && model.timePeriod.participants[model.timePeriod.trainer.id]) {
					return;
				}
				ModalSpinner.show();

				// dates changed
				const periodChanged = model && model.timePeriod && (new Date(row.entity.timePeriod.fromDate).getTime() != new Date(model.timePeriod.fromDate).getTime() ||
					new Date(row.entity.timePeriod.toDate).getTime() != new Date(model.timePeriod.toDate).getTime());

				// get data from model
				let oldTimePeriod = Object.assign({}, row.entity.timePeriod);
				let oldRealization = row.entity.participantRegistration.detail ? Object.assign({}, row.entity.participantRegistration.detail.realization) : undefined;
				copyTimePeriodValues(row.entity.timePeriod, model);
				if (trainerFormDataHasBeenModified(oldTimePeriod, row.entity.timePeriod)) {
					if (checkTimeperiodOverlapWithExisting(row.entity.timePeriod)) {
						copyTimePeriodValues(row.entity.timePeriod, { timePeriod: oldTimePeriod });
						ModalSpinner.hide();
						return;
					}
					enableSaveButtons();
				}
				updateErrorPolicyIfTrainerChanged(oldTimePeriod, row.entity.timePeriod);

				// In case the date of the timePeriod changed, all new realizations should update their date accordingly. 
				if (new Date(oldTimePeriod.fromDate).getTime() !== new Date(row.entity.timePeriod.fromDate).getTime()) {
                	model.realization.date = new Date(new Date(row.entity.timePeriod.fromDate).getTime());
                	model.realization.editOnlyNewRealizations = true;
                } else {
                	model.realization.date = model.realization.date ? new Date(model.realization.date) : null;
                }

				model.realization.dueDate = model.realization.dueDate ? new Date(model.realization.dueDate) : null;

				if (grid.api.grouping.config.resource.criteria == 'trainer') {
					// we retrieve the last realization for each participant in a single request;
					// we do this only for this perspective because this is the most used perspective/grouping;
					// when this screen will be migrated we need to make sure that the realizations are retrieved only once
					// for each perspective
					let latestRealizationsForResourcesAndComponent = [];
					let participants = []
					angular.forEach(row.children, function(child) {
						participants.push(child.participantRegistration.resource);
					});
					Education.getLatestRealizations(latestRealizationsForResourcesAndComponent, {
						data: {
							resources: participants,
							componentVersionId: row.children[0].timePeriod.trainingComponentVersion.id
						}
					}).then(function() {
						updateTrainer(grid, row, model, oldTimePeriod, oldRealization, latestRealizationsForResourcesAndComponent.data, periodChanged)
						ModalSpinner.hide();
					}, function(error) {
						ModalSpinner.hide();
					});
				} else {
					updateTrainer(grid, row, model, oldTimePeriod, oldRealization, periodChanged);
					ModalSpinner.hide();
				}
			},
			'edit'
		);
	}

	function updateTrainer(grid, row, model, oldTimePeriod, oldRealization, latestRealizationsForResourcesAndComponent, periodChanged) {
		angular.forEach(row.children, function(child) {
			if (model.editAllRealizations && model.realization && (model.realization.editRealizations || model.realization.editOnlyNewRealizations)) {
				row.entity.timePeriod.realization = model.realization;

			 	if (child.participantRegistration.detail && (!child.participantRegistration.resource || !model.timePeriod.trainingComponentVersion || child.participantRegistration.detail.componentVersion.id == model.timePeriod.trainingComponentVersion.id)) {
					if (model.realization.createNewRealizations) {
						createNewRealization(child, model.realization.date, model.realization.dueDate, model.realization.result);
					} else {
						let shouldUpdateParticipantRealization = true;
						// Set shouldUpdateParticipantRealization to false only if the date of the timePeriod changed, but the realization is not new. 
						if (model.realization.editOnlyNewRealizations && child.participantRegistration.detail.realization.id) {
							shouldUpdateParticipantRealization = false;
						}

						if(shouldUpdateParticipantRealization) {
							child.participantRegistration.detail.realization.date = model.realization.date;
							child.participantRegistration.detail.realization.dueDate = model.realization.dueDate;
							child.participantRegistration.detail.realization.result = model.realization.result;
						}
					}
					enableSaveButtons();
				}
			}
			if (child.participantRegistration.resource && model.timePeriod.trainingComponentVersion && child.participantRegistration.detail.componentVersion.id != model.timePeriod.trainingComponentVersion.id) {
				getRealization(grid, child.participantRegistration.resource, child.participantRegistration, child, latestRealizationsForResourcesAndComponent);
			}

			// When the date of the timePeriod changes, update the date of unsaved realizations accordingly.
			if (new Date(child.participantRegistration.fromDate).getTime() != new Date(model.timePeriod.fromDate).getTime()) {
				updateNewRealizationDate(child, child.participantRegistration, false);
			}
		});
		// After all the realizations are updated, remove this field from model.realization. This is not needed anymore. 
		delete model.realization.editOnlyNewRealizations;

		if (!model.timePeriod.participantRegistrations) {
			model.timePeriod.participantRegistrations = [];
			checkAndUpdateTimePeriodTrainer(model, row, trainingTimePeriods);
		}

		let editedParticipantRegistrationDates = 0;
		for (var i = 0; i < model.timePeriod.participantRegistrations.length; i++) {
			if (!model.timePeriod.participantRegistrations[i].resource) {
				return;
			}

			var participantRegistration = model.timePeriod.participantRegistrations[i];
			const partialTimePeriod = trainingTimePeriods.partialTimePeriod(participantRegistration, oldTimePeriod);
			if (!partialTimePeriod) {
				participantRegistration.fromDate = model.timePeriod.fromDate;
				participantRegistration.toDate = model.timePeriod.toDate;
			}
			editedParticipantRegistrationDates = periodChanged && partialTimePeriod ? editedParticipantRegistrationDates + 1 : editedParticipantRegistrationDates;
			participantRegistration.detail.componentVersion = model.timePeriod.trainingComponentVersion;
			participantRegistration.detail.trainer = model && model.timePeriod && model.timePeriod.trainer && model.timePeriod.trainer.objectType ? model.timePeriod.trainer : null;
			participantRegistration.detail.company = model && model.timePeriod && model.timePeriod.trainingCenter && model.timePeriod.trainingCenter.objectType ? model.timePeriod.trainingCenter : null;
			// if the value from socialHoursBalance from editor is a number, then set this value to the participant;
			// if the value is empty:
			// 1. the participant registration already has a value => do not modify
			// 2. otherwise => set default value 0 
			if (model && model.timePeriod && model.timePeriod.socialHoursBalance) {
				participantRegistration.detail.socialHoursBalance = model.timePeriod.socialHoursBalance;
			} else if (!participantRegistration.detail.socialHoursBalance) {
				participantRegistration.detail.socialHoursBalance = 0;
			}

			// add in registrationsToUpdate
			if (participantRegistration.id) {
				row.entity.timePeriod.registrationsToUpdate[row.entity.timePeriod.registrationsToUpdate.length] = participantRegistration.id;
			}

			checkAndUpdateTimePeriodTrainer(model, row, trainingTimePeriods);
		}

		if (row.entity.timePeriod.trainerRegistration) {
			row.entity.timePeriod.trainerRegistration.fromDate = model.timePeriod.fromDate;
			row.entity.timePeriod.trainerRegistration.toDate = model.timePeriod.toDate;
			row.entity.timePeriod.trainerRegistration.detail.componentVersion = model.timePeriod.trainingComponentVersion;
			row.entity.timePeriod.trainerRegistration.detail.socialHoursBalance = model.timePeriod.trainerSocialHoursBalance;
			// add in registrationsToUpdate
			if (row.entity.timePeriod.trainerRegistration.id) {
				row.entity.timePeriod.registrationsToUpdate[row.entity.timePeriod.registrationsToUpdate.length] = row.entity.timePeriod.trainerRegistration.id;
			}
		}

		if (!model.editAllRealizations) {
			if (!model.realization.componentVersion) {
				createNewRealization(row.entity, model.realization.date, model.realization.dueDate, model.realization.result);
			} else {
				row.entity.participantRegistration.detail.realization = copyRealizationFields(row.entity.participantRegistration.detail.realization, model.realization);
			}
		}

		let oldFormRealization = grid.api.grouping.config.resource.criteria == 'trainer' ? oldTimePeriod.realization : oldRealization;
		let newFormRealization = grid.api.grouping.config.resource.criteria == 'trainer' ? row.entity.timePeriod.realization : row.entity.participantRegistration.detail.realization;
		if (realizationFormDataHasBeenModified(oldFormRealization, newFormRealization)) {
			enableSaveButtons();

			for (let i = 0; i < model.timePeriod.participantRegistrations.length; i++) {
				if (model.timePeriod.participantRegistrations[i].detail.realization) {
					updateParticipantRealizationFromOtherTimePeriods(model.timePeriod.participantRegistrations[i].detail.realization);
				}
			}
		}
		trainingTimePeriods.checkValid();

		if (editedParticipantRegistrationDates != 0) {
			FormModal.open({
				scope: {
					template: '<div ng-bind-html="message | replaceLineBreaks"></div>',
					icon: 'fa fa-info-circle',
					title: "Info",
					message: $filter('translate')('education.groupRegistrations.editedRegistrationDates') + editedParticipantRegistrationDates
				},
				actions: ['close']
			});
		}
	}
	
	function checkAndUpdateTimePeriodTrainer(model, row, trainingTimePeriods) {
    	if (model.timePeriod && model.timePeriod.trainer && model.timePeriod.trainer.objectType == "EmployeeSnapshot") {
        	if (row.entity.timePeriod.trainerRegistration) {
            	row.entity.timePeriod.trainerRegistration.resource = model.timePeriod.trainer.id;
            	row.entity.timePeriod.trainerRegistration.detail.componentVersion = model.timePeriod.trainingComponentVersion;
				row.entity.timePeriod.trainerRegistration.detail.socialHoursBalance = model.timePeriod.trainerSocialHoursBalance;
        	} else {
            	row.entity.timePeriod.trainerRegistration = {
                	resource: model.timePeriod.trainer.id,
                	environment: AgendaConstants.ENVIRONMENT,
                	fromDate: model.timePeriod.fromDate,
                	toDate: model.timePeriod.toDate,
                	detail: {
                    	objectType: AgendaConstants.DETAIL_INSTRUCTOR,
                    	componentVersion: model.timePeriod.trainingComponentVersion,
						socialHoursBalance: model.timePeriod.trainerSocialHoursBalance
                	}
            	}
        	}
    	} else {
        	if (row.entity.timePeriod.trainerRegistration && row.entity.timePeriod.trainerRegistration.id) {
            	trainingTimePeriods.session.registrationsToRemove[trainingTimePeriods.session.registrationsToRemove.length] = row.entity.timePeriod.trainerRegistration.id;
        	}
        	row.entity.timePeriod.trainerRegistration = null;
    	}
	}

	// This method is used to keep in sync the date of unsaved realizations with the date of earliest timePeriod.
	function updateNewRealizationDate(trainingTimePeriod, participantRegistration, timePeriodWasRemoved) {
		let participantPeriods = [];
		for (let i = 0; i < trainingTimePeriods.session.timePeriods.length; i++) {
			if (trainingTimePeriods.session.timePeriods[i].trainingComponentVersion.id === trainingTimePeriod.timePeriod.trainingComponentVersion.id
				&& trainingTimePeriods.session.timePeriods[i].participantRegistrations) {
				for (let j = 0; j < trainingTimePeriods.session.timePeriods[i].participantRegistrations.length; j++) {
					if (trainingTimePeriods.session.timePeriods[i].participantRegistrations[j].resource === participantRegistration.resource) {
						participantPeriods.push(trainingTimePeriods.session.timePeriods[i]);
					}
				}
			}
		}
				
		// We have all participant periods. Find the earliest period.
		participantPeriods = participantPeriods.sort((a, b) => new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime());
		if (timePeriodWasRemoved) {
			participantPeriods.shift();
		}
		if (participantPeriods.length === 0) {
			return;
		}
		
		// The date of the realization is the date of the earliest time period.
		const date = new Date(participantPeriods[0].fromDate);

		// Update all unsaved realizations of the participant to use this earliest date.
		for (let i = 0; i < trainingTimePeriods.session.timePeriods.length; i++) {
			if (trainingTimePeriods.session.timePeriods[i].trainingComponentVersion.id === trainingTimePeriod.timePeriod.trainingComponentVersion.id && trainingTimePeriods.session.timePeriods[i].participantRegistrations) {
				for (let j = 0; j < trainingTimePeriods.session.timePeriods[i].participantRegistrations.length; j++) {
					if (trainingTimePeriods.session.timePeriods[i].participantRegistrations[j].resource === participantRegistration.resource && trainingTimePeriods.session.timePeriods[i].participantRegistrations[j].detail.realization
					&& !trainingTimePeriods.session.timePeriods[i].participantRegistrations[j].detail.realization.id
					&& trainingTimePeriods.session.timePeriods[i].participantRegistrations[j].detail.realization.date.getTime() !== date.getTime()) {
						trainingTimePeriods.session.timePeriods[i].participantRegistrations[j].detail.realization.date = date;
					}
				}
			}
		}

		return date;
	}

	function getRealization(grid, resourceId, participationRegistration, trainingTimePeriod, latestRealizationsForResourceAndComponent) {
		if (latestRealizationsForResourceAndComponent !== undefined) {
			createOrReuseRealization(grid, participationRegistration, trainingTimePeriod, latestRealizationsForResourceAndComponent[resourceId]);
		} else {
			Education.getLatestRealization(null, {
				params: {
					id: resourceId,
					componentVersionId: trainingTimePeriod.timePeriod.trainingComponentVersion.id
				}
			}).then(function(result) {
				createOrReuseRealization(grid, participationRegistration, trainingTimePeriod, result.data);
			}, function(error) {
				ModalSpinner.hide();
			});
		}
	}

	function createOrReuseRealization(grid, participationRegistration, trainingTimePeriod, realization) {
		let registration = participationRegistration;
		let actualTimePeriod = trainingTimePeriod;
		if (realization) {
			// if a realization already exists, use that realization for the registration
			realization.date = realization.date ? new Date(realization.date) : null;
			realization.dueDate = realization.dueDate ? new Date(realization.dueDate) : null;
			registration.detail.realization = realization;
		} else {
			// otherwise, create a new realization
			let date = updateNewRealizationDate(trainingTimePeriod, participationRegistration, false);
			let dueDate;
			let realizationResult;
			if (grid.api.grouping.config.resource.criteria == 'trainer') {
				let parentRow;
				for (let i in grid.rows) {
					let rowTimePeriod = grid.rows[i].entity.timePeriod;
					if (grid.rows[i].children && rowTimePeriod.trainer == actualTimePeriod.timePeriod.trainer &&
						rowTimePeriod.trainingComponentVersion == actualTimePeriod.timePeriod.trainingComponentVersion &&
						(new Date(rowTimePeriod.fromDate)).getTime() == (new Date(actualTimePeriod.timePeriod.fromDate)).getTime() &&
						(new Date(rowTimePeriod.toDate)).getTime() == (new Date(actualTimePeriod.timePeriod.toDate)).getTime()) {
						parentRow = grid.rows[i];
						break;
					}
				}

				if (parentRow && parentRow.entity.timePeriod.realization && parentRow.entity.timePeriod.realization.date) {
					dueDate = parentRow.entity.timePeriod.realization.dueDate;
					realizationResult = parentRow.entity.timePeriod.realization.result;
				}
			}
			createNewRealization(trainingTimePeriod, date, dueDate, realizationResult);
		}
	}

	trainingTimePeriods.editParticipant = function(grid, row, editAllRealizations) {
		var model = {};
		model.timePeriod = angular.extend({}, row.entity.timePeriod);
		model.participantRegistration = angular.extend({}, row.entity.participantRegistration);
		model.mode = 'trainee';
		model.editAllRealizations = editAllRealizations;
		model.realizationStatuses = trainingTimePeriods.realizationStatuses;

		if (editAllRealizations) {
			openModalForEditParticipant(grid, row, model);
		} else {
			trainingTimePeriods.getRealizationsForParticipant(row.entity.participantRegistration.resource, row.entity.timePeriod.trainingComponentVersion.id, openModalForEditParticipant, grid, row, model);
		}
	}

	function realizationFormDataHasBeenModified(oldRealization, newRealization) {
		if ((!oldRealization && !newRealization) || (oldRealization && newRealization && !oldRealization.date && !newRealization.date)) {
			return false;
		}
	
		if (new Date(oldRealization.date).getTime() !== new Date(newRealization.date).getTime() ||
			new Date(oldRealization.dueDate).getTime() !== new Date(newRealization.dueDate).getTime() ||
			(oldRealization.result && newRealization.result && oldRealization.result.id !== newRealization.result.id) ||
			(oldRealization.result && !newRealization.result) || (!oldRealization.result && newRealization.result)) {
			return true;
		}
		return false;
	}

	function openModalForEditParticipant(grid, row, model) {
		trainingTimePeriods.openModal('Deelnemer', '<time-period-editor entity="model"></time-period-editor>', model,
			function (model) {
				// when editing the participant (in "participant" grouping view), check if the participant is also a trainer;
				if (model.timePeriod.trainer && model.participantRegistration.resourceSnapshot.id === model.timePeriod.trainer.id) {
					return;
				}
				ModalSpinner.show();

				model.realization.date = model.realization.date ? new Date(model.realization.date) : null;
				model.realization.dueDate = model.realization.dueDate ? new Date(model.realization.dueDate) : null;
				let oldRealization = Object.assign({}, row.entity.participantRegistration.detail.realization);
				if (!model.editAllRealizations) {
					// edit the current realization
					if (model.realization.componentVersion) {
						row.entity.participantRegistration.detail.realization = copyRealizationFields(row.entity.participantRegistration.detail.realization, model.realization);
					} else {
						createNewRealization(row.entity, model.realization.date, model.realization.dueDate, model.realization.result);
					}
				}

				angular.forEach(row.children, function(child) {
					if (model.editAllRealizations && model.realization && model.realization.editRealizations) {
					    row.entity.timePeriod.realization = model.realization;
						if (model.participantRegistration.resourceSnapshot.id == child.participantRegistration.resource) {
							if (model.realization.createNewRealizations) {
								createNewRealization(child, model.realization.date, model.realization.dueDate, model.realization.result);
							} else {
								child.participantRegistration.detail.realization.date = model.realization.date;
								child.participantRegistration.detail.realization.dueDate = model.realization.dueDate;
								child.participantRegistration.detail.realization.result = model.realization.result;
							}
						}
					}

					if (model.participantRegistration.resourceSnapshot.id != child.participantRegistration.resource) {
						child.participantRegistration.resource = model.participantRegistration.resourceSnapshot.id;
						child.participantRegistration.resourceSnapshot = model.participantRegistration.resourceSnapshot;
						child.timePeriod.participants[model.participantRegistration.resourceSnapshot.id] = model.participantRegistration.resourceSnapshot;

						getRealization(grid, model.participantRegistration.resourceSnapshot.id, child.participantRegistration, child);
					}

					child.timePeriod.registrationsToUpdate[child.timePeriod.registrationsToUpdate.length] = child.participantRegistration.id;
					updateErrorPolicyIfParticipantChanged(child.timePeriod, oldRealization.person, row.entity.participantRegistration.resource);
				});

				if (row.entity.participantRegistration.fromDate.getTime() !== model.participantRegistration.fromDate.getTime()) {
					onChangeFromDate(model.participantRegistration.fromDate, row);
				}
				if (row.entity.participantRegistration.toDate.getTime() !== model.participantRegistration.toDate.getTime()) {
					onChangeToDate(model.participantRegistration.toDate, row);
				}
				row.entity.participantRegistration.resource = model.participantRegistration.resourceSnapshot.id;
				row.entity.participantRegistration.resourceSnapshot = model.participantRegistration.resourceSnapshot;
				row.entity.timePeriod.participants[model.participantRegistration.resourceSnapshot.id] = model.participantRegistration.resourceSnapshot;
				row.entity.timePeriod.registrationsToUpdate[row.entity.timePeriod.registrationsToUpdate.length] = row.entity.participantRegistration.id;

				if (realizationFormDataHasBeenModified(oldRealization, row.entity.participantRegistration.detail.realization)) {
					enableSaveButtons();
					if (row.entity.participantRegistration.detail.realization.id) {
						updateParticipantRealizationFromOtherTimePeriods(row.entity.participantRegistration.detail.realization);
					}
				}

				if (row.entity.participantRegistration.resourceSnapshot.id !== oldRealization.person) {
					enableSaveButtons();
				}

				trainingTimePeriods.checkValid();
				ModalSpinner.hide();
			},
			'edit'
		);
	}

	function updateErrorPolicyIfParticipantChanged(timePeriod, oldParticipant, newParticipant) {
		if (oldParticipant !== newParticipant) {
			// The participant was changed. In this case, remove the old participant id from resourcesToErrorPolicy and add the new participant id.
			delete timePeriod.resourcesToErrorPolicy[oldParticipant];
			timePeriod.resourcesToErrorPolicy[newParticipant] = {policy: "ENFORCE_ALL"};
		}
	}

	trainingTimePeriods.duplicateTrainer = function(grid, row) {
		if (!row.duplicateEnabled) {
			return;
		}

		ModalSpinner.show();
		var originalTimePeriod = row.entity.timePeriod;
		var model = {
			timePeriod: {
				trainingCenter: originalTimePeriod.trainingCenter,
				trainer: originalTimePeriod.trainer,
				trainingComponentVersion: originalTimePeriod.trainingComponentVersion,
				trainerSocialHoursBalance: originalTimePeriod.trainerSocialHoursBalance
			}
		};
		var trainingTimePeriod = addTrainer(model);
		// we disable the button until we set a date for the duplicate
		row.entity.duplicateReference = trainingTimePeriod;
		row.duplicateEnabled = false;

		var participantRegistrations = originalTimePeriod.participantRegistrations;

		let latestRealizationsForResourcesAndComponent = [];
		let participants = [];
		for (let i = 0; i < participantRegistrations.length; i++) {
			participants.push(participantRegistrations[i].resource);
		}
		Education.getLatestRealizations(latestRealizationsForResourcesAndComponent, {
			data: {
				resources: participants,
				componentVersionId: trainingTimePeriod.timePeriod.trainingComponentVersion.id
			}
		}).then(function() {
			for (let i = 0; i < participantRegistrations.length; i++) {
				addNewParticipant(grid, trainingTimePeriod, originalTimePeriod.participants[participantRegistrations[i].resource], latestRealizationsForResourcesAndComponent.data);
			}
			ModalSpinner.hide();
		}, function(error) {
			ModalSpinner.hide();
		});
	}

	trainingTimePeriods.removeTrainingTimePeriod = function(grid, row) {
		ModalSpinner.show();
		if (!row.grouping) {
			// don't delete single row with dummy child
			if (row.parentRow.children.length == 1) {
				if (grid.api.grouping.config.resource.criteria == 'trainer') {
					if (!row.entity.participantRegistration.resource) {
						ModalSpinner.hide();
						return;
					}
				} else {
					if (!row.entity.timePeriod.fromDate && !row.entity.timePeriod.toDate && !row.entity.timePeriod.trainingComponentVersion
						&& !row.entity.timePeriod.trainer) {
						ModalSpinner.hide();
						return;
					}
				}
			}

			removeTrainingTimePeriod(grid, row.entity, row.parentRow.children.length);
		} else {
			angular.forEach(row.children, function(child) {
				removeTrainingTimePeriod(grid, child);
			});

			if (grid.api.grouping.config.resource.criteria == 'trainer') {
				// remove a time period: delete the time period from the session and trainer's registration
				var timePeriodSessionIndex = -1;
				for (var i = 0; i < trainingTimePeriods.session.timePeriods.length; i++) {
					if (row.entity.timePeriod === trainingTimePeriods.session.timePeriods[i]) {
						timePeriodSessionIndex = i;
					}
				}
				trainingTimePeriods.session.timePeriods.splice(timePeriodSessionIndex, 1);

				if (row.entity.timePeriod.trainerRegistration && row.entity.timePeriod.trainerRegistration.id) {
					trainingTimePeriods.session.registrationsToRemove[trainingTimePeriods.session.registrationsToRemove.length] = row.entity.timePeriod.trainerRegistration.id;
				}	
			} 
		}

		trainingTimePeriods.checkValid();
		ModalSpinner.hide();
	};

	function removeTrainingTimePeriod(grid, trainingTimePeriod, childrenSize) {
		if (trainingTimePeriod.participantRegistration.resource && trainingTimePeriod.participantRegistration.detail.realization
			&& !trainingTimePeriod.participantRegistration.detail.realization.id
			&& new Date(trainingTimePeriod.participantRegistration.detail.realization.date).getTime() == new Date(trainingTimePeriod.timePeriod.fromDate).getTime()) {
			// The realization has the date of the removed registration => update other realizations date.
			updateNewRealizationDate(trainingTimePeriod, trainingTimePeriod.participantRegistration, true);
		}

		var idx = -1;
		var idxTimePeriod = -1;
		for (var i = 0; i < grid.options.data.length; i++) {
			if (trainingTimePeriod === grid.options.data[i]) {
				idx = i;
				if (trainingTimePeriod.timePeriod.resourcesToErrorPolicy) {
					let idToRemove = grid.api.grouping.config.resource.criteria == 'trainer' ? trainingTimePeriod.participantRegistration.resource : 
						trainingTimePeriod.participantRegistration.detail.trainer ? trainingTimePeriod.participantRegistration.detail.trainer.id : null;
					if (idToRemove) {
						delete trainingTimePeriod.timePeriod.resourcesToErrorPolicy[idToRemove];
					}
				}
				if (trainingTimePeriod.participantRegistration.id) {
					trainingTimePeriods.session.registrationsToRemove[trainingTimePeriods.session.registrationsToRemove.length] = trainingTimePeriod.participantRegistration.id;
				}
				if (trainingTimePeriod.timePeriod && trainingTimePeriod.timePeriod.participantRegistrations) {
					for (var j = 0; j < trainingTimePeriod.timePeriod.participantRegistrations.length; j++) {
						if (trainingTimePeriod.participantRegistration == trainingTimePeriod.timePeriod.participantRegistrations[j]) {
							idxTimePeriod = j;
						}
					}
				}
			}
		}

		if (childrenSize == 1) {
			// if we remove the only child, the parent must remain with "Geen" as child
			if (grid.api.grouping.config.resource.criteria == 'trainer') {
				addNewParticipant(grid, trainingTimePeriod, null);
			} else {
				addNewTrainer(grid, trainingTimePeriod, null);
			}
		}

		if (grid.api.grouping.config.resource.criteria != 'trainer' && trainingTimePeriod.timePeriod 
			&& trainingTimePeriod.timePeriod.participantRegistrations && trainingTimePeriod.timePeriod.participantRegistrations.length == 1) {
			// remove the time period only if there is no other participant registration in that training time period
			var timePeriodSessionIndex = -1;
			for (var i = 0; i < trainingTimePeriods.session.timePeriods.length; i++) {
				if (trainingTimePeriod.timePeriod === trainingTimePeriods.session.timePeriods[i]) {
					timePeriodSessionIndex = i;
				}
			}

			if (timePeriodSessionIndex > -1) {
				trainingTimePeriods.session.timePeriods.splice(timePeriodSessionIndex, 1);
			}

			// remove trainer's registration
			if (trainingTimePeriod.timePeriod.trainerRegistration && trainingTimePeriod.timePeriod.trainerRegistration.id) {
				trainingTimePeriods.session.registrationsToRemove[trainingTimePeriods.session.registrationsToRemove.length] = trainingTimePeriod.timePeriod.trainerRegistration.id;
			}
		}

		// remove from the table and remove from the participant registrations list
		grid.options.data.splice(idx, 1);
		if (trainingTimePeriod.timePeriod && trainingTimePeriod.timePeriod.participantRegistrations) {
			trainingTimePeriod.timePeriod.participantRegistrations.splice(idxTimePeriod, 1);
		}

		if (grid.api.grouping.config.resource.criteria == 'trainer' && trainingTimePeriod.timePeriod && trainingTimePeriod.timePeriod.participants 
			&& trainingTimePeriod.timePeriod.participants[trainingTimePeriod.participantRegistration.resource]) {
				delete trainingTimePeriod.timePeriod.participants[trainingTimePeriod.participantRegistration.resource];
		}
	}

	// This method is called when an existing realization (that is used in multiple time periods) is updated.
	function updateParticipantRealizationFromOtherTimePeriods(modifiedRealization) {
		for (let i = 0; i < trainingTimePeriods.session.timePeriods.length; i++) {
			if (trainingTimePeriods.session.timePeriods[i].trainingComponentVersion.id === modifiedRealization.componentVersion.id && trainingTimePeriods.session.timePeriods[i].participantRegistrations) {
				for (let j = 0; j < trainingTimePeriods.session.timePeriods[i].participantRegistrations.length; j++){
					if (trainingTimePeriods.session.timePeriods[i].participantRegistrations[j].resource === modifiedRealization.person && ((trainingTimePeriods.session.timePeriods[i].participantRegistrations[j].detail.realization.id && trainingTimePeriods.session.timePeriods[i].participantRegistrations[j].detail.realization.id === modifiedRealization.id) || !trainingTimePeriods.session.timePeriods[i].participantRegistrations[j].detail.realization.id)) {
						trainingTimePeriods.session.timePeriods[i].participantRegistrations[j].detail.realization = modifiedRealization;
					}
				}
			}
		}
	}

	trainingTimePeriods.removeIncompleteTrainingTimePeriods = function(grid, grouping, trainingTimePeriod) {
		var timePeriod = trainingTimePeriod.timePeriod;
		var participantRegistration = trainingTimePeriod.participantRegistration;
		for (var i = grid.options.data.length; i--;) {
			var data = grid.options.data[i];
			if (grouping == 'trainer') {
				var currentTimePeriod = data.timePeriod;
				if (!currentTimePeriod.fromDate && !currentTimePeriod.toDate && !currentTimePeriod.trainingComponentVersion) {
					if ((participantRegistration.resource || data.participantRegistration.resource) && (!participantRegistration.resource || !data.participantRegistration.resource || participantRegistration.resource != data.participantRegistration.resource)) {
						continue;
					}
				} else {
					if (((currentTimePeriod.fromDate || timePeriod.fromDate) && (!currentTimePeriod.fromDate || !timePeriod.fromDate || (new Date(currentTimePeriod.fromDate)).getTime() != (new Date(timePeriod.fromDate)).getTime())) 
						|| ((currentTimePeriod.toDate || timePeriod.toDate) && (!currentTimePeriod.toDate || !timePeriod.toDate || (new Date(currentTimePeriod.toDate)).getTime() != (new Date(timePeriod.toDate)).getTime())) 
						|| ((currentTimePeriod.trainer || timePeriod.trainer) && (!currentTimePeriod.trainer || !timePeriod.trainer || currentTimePeriod.trainer.id != timePeriod.trainer.id))
						|| ((currentTimePeriod.trainingComponentVersion || timePeriod.trainingComponentVersion) && (!currentTimePeriod.trainingComponentVersion || !timePeriod.trainingComponentVersion || currentTimePeriod.trainingComponentVersion.id != timePeriod.trainingComponentVersion.id)) 
						|| ((currentTimePeriod.trainingCenter || timePeriod.trainingCenter) && (!currentTimePeriod.trainingCenter || !timePeriod.trainingCenter || currentTimePeriod.trainingCenter.id != timePeriod.trainingCenter.id))) {
						continue;
					} else {
						if (data.participantRegistration && data.participantRegistration.resource) {
							continue;
						}
					}
				}
			} else {
				var currentParticipantRegistration = data.participantRegistration;
				if (!currentParticipantRegistration.resource) {
					if (((data.timePeriod.fromDate || timePeriod.fromDate) && (!data.timePeriod.fromDate || !timePeriod.fromDate || (new Date(data.timePeriod.fromDate)).getTime() != (new Date(timePeriod.fromDate)).getTime())) 
					|| ((data.timePeriod.toDate || timePeriod.toDate) && (!data.timePeriod.toDate || !timePeriod.toDate || (new Date(data.timePeriod.toDate)).getTime() != (new Date(timePeriod.toDate)).getTime())) 
					|| ((data.timePeriod.trainer || timePeriod.trainer) && (!data.timePeriod.trainer || !timePeriod.trainer || data.timePeriod.trainer.id != timePeriod.trainer.id))
					|| ((data.timePeriod.trainingComponentVersion || timePeriod.trainingComponentVersion) && (!data.timePeriod.trainingComponentVersion || !timePeriod.trainingComponentVersion || data.timePeriod.trainingComponentVersion.id != timePeriod.trainingComponentVersion.id)) 
					|| ((data.timePeriod.trainingCenter || timePeriod.trainingCenter) && (!data.timePeriod.trainingCenter || !timePeriod.trainingCenter || data.timePeriod.trainingCenter.id != timePeriod.trainingCenter.id))) {
						continue;
					}
				} else {
					if (currentParticipantRegistration.resource != participantRegistration.resource) {
						continue;
					} else {
						if (data.timePeriod && data.timePeriod.trainingComponentVersion && data.timePeriod.fromDate && data.timePeriod.toDate) {
							continue;
						}
					}
				}
			}

			removeTrainingTimePeriod(grid, data);
		}
	}

	trainingTimePeriods.getResourceCompleteName = function(row, groupingCriteria) {
		if (!row.entity.participantRegistration.resource) {
			return;
		}

		var participant;
		if (groupingCriteria == 'trainee' || !row.entity.timePeriod.participants) {
			participant = row.entity.participantRegistration.resourceSnapshot;
		} else {
			participant = row.entity.timePeriod.participants[row.entity.participantRegistration.resource];
		}

		if (!participant) {
			return;
		}
		return participant.name + ' ' + participant.firstName;
	}

	trainingTimePeriods.isExpired = function(date) {
		if (!date) {
			return false;
		}

		date = new Date(date);
		var currentDate = new Date();
		currentDate.setHours(23,59,59,999);
		return date < currentDate;
	}

	trainingTimePeriods.isNewRealization = function(realization) {
		if (!realization) {
			return false;
		}
		return !realization.id;
	}

	trainingTimePeriods.getRealizationsForParticipant = function(resource, trainingComponentVersionId, callback, grid, row, model) {
		Education.getRealizationsByCriteria(trainingTimePeriods, {
			data: {
				personId: resource,
				componentVersionId: trainingComponentVersionId
			}
		}).then(function(result) {
			var realizations = result.data;
			if (result.data.length == 0) {
				realizations = [];
			}
			realizations.splice( 0, 0, {componentVersion: null} );
			model.realizations = realizations;

			callback(grid, row, model);
		});
	}

	trainingTimePeriods.saveSession = function(effectiveStatus) {
		if (!$scope.groupRegistrations.session) {
 			return;
 		}

 		ModalSpinner.show('education.groupRegistrations.saving');
 		trainingTimePeriods.session.owner = $scope.groupRegistrations.session.ownerSnapshot;
		trainingTimePeriods.session.name = $scope.groupRegistrations.session.name;
		trainingTimePeriods.session.remark = $scope.groupRegistrations.session.remark;
		trainingTimePeriods.session.effectiveStatus = effectiveStatus;
		
 		Education.saveSession(null, {
 			silent: true,
			data: trainingTimePeriods.session
		}).then(function(result) {
			$scope.groupRegistrations.onSave();

			$scope.groupRegistrations.invalidWizardForSave = true;
			trainingTimePeriods.refresh(result.data, true);
			if (result.data) {
				$scope.groupRegistrations.initiallySelectedId = result.data;
			}
			trainingTimePeriods.realizationsMap = {};
			$scope.groupRegistrations.refresh(true);
			if (trainingTimePeriods.session.effectiveStatus) {
				invalidSaveEffectiveOnEdit = true;
				invalidSaveSimulationOnEdit = true;
			} else {
				invalidSaveSimulationOnEdit = true;
			}
		}, function(error) {
			if (error.data.errorCode === 'WARNING' && error.data.validationInformation) {
				WarningModal.open({
					titleId: WarningModal.VALIDATION_WARNING, 
					message: WarningModal.IGNORE_VALIDATIONS,
					messageId: error.data.message,
					onYes: function() {
						trainingTimePeriods.modifyErrorPolicyForReturnedRegistration(error.data.validationInformation.registration);
						trainingTimePeriods.saveSession(trainingTimePeriods.session.effectiveStatus);
					},
					onClose: function() {
						trainingTimePeriods.revertModifiedErrorPolicies();
					}
				});
			} else {
				ErrorModal.open({
					titleId: ErrorModal.VALIDATION_ERROR, 
					message: error.data.errorCode ? (error.data.errorCode + ' - ' + error.data.message) : error.data.message
				});
			}
		}).finally(function() {
			ModalSpinner.hide('education.groupRegistrations.saving');
		});
 	}

 	trainingTimePeriods.revertModifiedErrorPolicies = function() {
 		for (let i = 0; i < trainingTimePeriods.session.timePeriods.length; i++) {
 			for (let key in trainingTimePeriods.session.timePeriods[i].resourcesToErrorPolicy) {
 				trainingTimePeriods.session.timePeriods[i].resourcesToErrorPolicy[key] = {policy: "ENFORCE_ALL"};
 			}
 		}
 	}

 	trainingTimePeriods.modifyErrorPolicyForReturnedRegistration = function(returnedRegistration) {
 		if (returnedRegistration.type.code === "LESG") {
 			// The registration for which the exception was thrown is an instructor registration.
 			// Search the timePeriod that corresponds to this instructor registration and modify the errorPolicy of trainer.
			for (let i = 0; i < trainingTimePeriods.session.timePeriods.length; i++) {
				if (trainingTimePeriods.session.timePeriods[i].trainer
					&& trainingTimePeriods.session.timePeriods[i].trainer.id === returnedRegistration.resource
					&& (new Date(trainingTimePeriods.session.timePeriods[i].fromDate)).setMilliseconds(0) === new Date(returnedRegistration.fromDate).setMilliseconds(0)
					&& (new Date(trainingTimePeriods.session.timePeriods[i].toDate)).setMilliseconds(0) === new Date(returnedRegistration.toDate).setMilliseconds(0)
					&& trainingTimePeriods.session.timePeriods[i].trainingComponentVersion.id === returnedRegistration.detail.componentVersion.id) {
						trainingTimePeriods.session.timePeriods[i].resourcesToErrorPolicy[returnedRegistration.resource].policy = 'IGNORE_WARNINGS';
				}
			}
 		} else {
 			// The registration for which the exception was thrown is a training registration.
 			// Search the timePeriod that corresponds to this training registration and modify the errorPolicy of participant.
 			for (let i = 0; i < trainingTimePeriods.session.timePeriods.length; i++) {
				if ((new Date(trainingTimePeriods.session.timePeriods[i].fromDate)).setMilliseconds(0) === new Date(returnedRegistration.fromDate).setMilliseconds(0)
					&& (new Date(trainingTimePeriods.session.timePeriods[i].toDate)).setMilliseconds(0) === new Date(returnedRegistration.toDate).setMilliseconds(0)
					&& trainingTimePeriods.session.timePeriods[i].trainingComponentVersion.id === returnedRegistration.detail.componentVersion.id) {
					// Found a timePeriod with the same dates and component version as the participant registration.
					// Check if the participant is part of this time period and modify its errorPolicy.
					for (let j = 0; j < trainingTimePeriods.session.timePeriods[i].participants; j++) {
						if (trainingTimePeriods.session.timePeriods[i].participants[j]) {
							trainingTimePeriods.session.timePeriods[i].resourcesToErrorPolicy[returnedRegistration.resource].policy = 'IGNORE_WARNINGS';
							break;
						}
					}	
				}
			}
 		}
 	}
 
 	trainingTimePeriods.onChange = function(row) {
 		if (row.entity.participantRegistration.id) {
			row.entity.timePeriod.registrationsToUpdate[row.entity.timePeriod.registrationsToUpdate.length] = row.entity.participantRegistration.id;
		}
 		enableSaveButtons();
 		trainingTimePeriods.checkValid();
 	}

 	trainingTimePeriods.isEditDisabled = function(grid, row) {
		if (!row.grouping) {
			if ((!row.entity.participantRegistration || !row.entity.participantRegistration.resource) || !(row.entity.timePeriod && row.entity.timePeriod.trainingComponentVersion && row.entity.timePeriod.fromDate && row.entity.timePeriod.toDate) ) {
				row.rowEnabled = false;
				return true;
			}
		}
		row.rowEnabled = true;
		return false;
 	}
 
 	trainingTimePeriods.isDuplicateDisabled = function(grid, row) {
 		if (row.grouping && grid.api.grouping.config.resource.criteria == 'trainer') {
			if (!(row.entity.timePeriod && row.entity.timePeriod.trainingComponentVersion && row.entity.timePeriod.fromDate && row.entity.timePeriod.toDate)
				|| (row.children.length == 1 && row.children[0].participantRegistration && !row.children[0].participantRegistration.resource)) {
				row.duplicateEnabled = false;
				return true;
			}
		}
		if (!!row.entity.duplicateReference && !!trainingTimePeriods.gridOptions && !!trainingTimePeriods.gridOptions.data) {
			var timePeriod = row.entity.duplicateReference.timePeriod;
			for (var i = 0; i < trainingTimePeriods.gridOptions.data.length; i++) {
				var currentTimePeriod = trainingTimePeriods.gridOptions.data[i].timePeriod;
				if (((currentTimePeriod.fromDate || timePeriod.fromDate) && (!currentTimePeriod.fromDate || !timePeriod.fromDate || (new Date(currentTimePeriod.fromDate)).getTime() != (new Date(timePeriod.fromDate)).getTime())) 
					|| ((currentTimePeriod.toDate || timePeriod.toDate) && (!currentTimePeriod.toDate || !timePeriod.toDate || (new Date(currentTimePeriod.toDate)).getTime() != (new Date(timePeriod.toDate)).getTime())) 
					|| currentTimePeriod.trainer != timePeriod.trainer || currentTimePeriod.trainingComponentVersion != timePeriod.trainingComponentVersion || currentTimePeriod.trainingCenter != timePeriod.trainingCenter) {
					continue;
				}

				if (!(row.entity.duplicateReference && row.entity.duplicateReference.timePeriod && row.entity.duplicateReference.timePeriod.trainingComponentVersion && row.entity.duplicateReference.timePeriod.fromDate && row.entity.duplicateReference.timePeriod.toDate)) {
					row.duplicateEnabled = false;
					return false;
				} else {
					delete row.entity.duplicateReference;
				}
			};

		}
		row.duplicateEnabled = true;
		return false;
	}

	trainingTimePeriods.partialTimePeriod = function (participantRegistration, timePeriod) {
		if (!participantRegistration.fromDate || !participantRegistration.toDate) {
			return false;
		}
		return !(new Date(timePeriod.fromDate).getTime() === new Date(participantRegistration.fromDate).getTime()
			&& new Date(timePeriod.toDate).getTime() === new Date(participantRegistration.toDate).getTime());
	}

	function isOutsideOfTimePeriod(timePeriod, newDate) {
		return newDate.getTime() < new Date(timePeriod.fromDate).getTime() || newDate.getTime() > new Date(timePeriod.toDate).getTime();
	}
}
module.exports = TrainingTimePeriodsListCtrl;