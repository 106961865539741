'use strict';

var css = require('./login/proteuswarning.css');

angular.module('proteus.account', ['proteus.messaging'])

.run(function($templateCache) {
	$templateCache.put('account/login/login-page.html', require('./login/login-page.html'));
	$templateCache.put('account/login/login-with-proteus/login-with-proteus-page.html', require('./login/login-with-proteus/login-with-proteus-page.html'));
})
.controller('LoginPageCtrl',                 require('./login/login-page.controller'))
.controller('LoginWithProteusPageCtrl',      require('./login/login-with-proteus/login-with-proteus-page.controller'));