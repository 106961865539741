import { apolloClient, ApolloContext, CatchedGraphQLError, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, Utils } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { getEmployee_employeeService_employee } from "apollo-gen/getEmployee";
import { getRegistrationSnapshotsForCurriculum_employeeService_registrationSnapshotsForCurriculum, getRegistrationSnapshotsForCurriculum_employeeService_registrationSnapshotsForCurriculum_a_status, getRegistrationSnapshotsForCurriculum_employeeService_registrationSnapshotsForCurriculum_a_type } from "apollo-gen/getRegistrationSnapshotsForCurriculum";
import { BACKGROUND_COLOR, RegistrationTypeCategoryTree, RegistrationTypeCategoryTreeRaw } from "components/registrationTypeCategoryTree/RegistrationTypeCategoryTree";
import { CellProps } from "fixed-data-table-2";
import { EMPLOYEE_SERVICE_GET_INITIALLY_SELECTED_TYPES_AND_STATUSES_FROM_METADATA, EMPLOYEE_SERVICE_GET_REGISTRATION_SNAPSHOTS_CURRICULUM, EMPLOYEE_SERVICE_GET_REGISTRATION_TYPE_TREE, PLANNING_SERVICE_FACADE_BEAN_REMOVE_REGISTRATION_BY_ID, REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_TREE_FOR_PLANNING } from "graphql/queries";
import moment from "moment";
import { DateNavigationPanel, sliceDateNavigation } from "pages/DateNavigationPanel/DateNavigationPanel";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import React from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { ColorUtil } from "utils/ColorUtil";
import { MetadataProviderHelper } from "utils/MetadataProviderHelper";
import { curriculumRegistrationsEntityDescriptor, HISTORY_FIELD_TYPE } from "./customFieldRenderersEditors";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { EDIT_MODE, READ_ONLY_MODE, Registration, RegistrationEditor, REGISTRATION_EDITOR_MODE, sliceRegistrationEditor } from "pages/registrationEditor/RegistrationEditor";
import Interweave from "interweave";
import { fieldEditors } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";

const MILISECONDS_IN_MINUTE = 1000 * 60;
const MILISECONDS_IN_HOUR = MILISECONDS_IN_MINUTE * 60;
const MILISECONDS_IN_DAY = MILISECONDS_IN_HOUR * 24;

export const PLANNING_CURRICULUM = "PLANNING_CURRICULUM";

export enum ModalOperations {
    CONFIRM_DELETE_REGISTRATION = "CONFIRM_DELETE_REGISTRATION",
    CONFIRM_IGNORE_EXCEPTIONS = "CONFIRM_IGNORE_EXCEPTIONS"
};

interface RegistrationTable {
    id: number | null | undefined,
    type: getRegistrationSnapshotsForCurriculum_employeeService_registrationSnapshotsForCurriculum_a_type | null | undefined,
    status: getRegistrationSnapshotsForCurriculum_employeeService_registrationSnapshotsForCurriculum_a_status | null | undefined,
    fromDate: any | null,
    toDate: any | null,
    duration: string | null | undefined,
    creationDate: any | null,
    creationUser: string | null | undefined,
    modificationDate: any | null,
    modificationUser: string | null | undefined
}

export const sliceCurriculumTab = createSliceFoundation(class SliceCurriculumTab {

    initialState = {
        treeModalOpen: false as boolean,
        showModal: { open: false as boolean, message: "" as string, operation: "" as string },
        isRegistrationDrawerOpen: false as boolean,
        registrationForEditor: undefined as unknown as Registration,
        operatingMode: EDIT_MODE as number,
        registrationTypeTree: [] as any,
        initiallySelectedTypes: [] as string[],
        appliedRegistrationTypes: [] as string[],
        firstLoad: true
    }

    nestedSlices = {
        curriculumDatePanel: sliceDateNavigation,
        registrationsTable: sliceEntityTableLight,
        registrationEditor: sliceRegistrationEditor
    }

    reducers = {
        ...getBaseReducers<SliceCurriculumTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceCurriculumTab>(this),

        calculateDuration(registration: getRegistrationSnapshotsForCurriculum_employeeService_registrationSnapshotsForCurriculum): string {
            let fromDate = registration.a?.fromDate ? new Date(registration.a.fromDate) : null;
            let toDate = registration.a?.toDate ? new Date(registration.a?.toDate) : null;
            if (!fromDate || !toDate) {
                return "~";
            }
            fromDate = this.roundDate(fromDate, false);
            toDate = this.roundDate(toDate, true);

            if ((toDate.getMinutes() == 29 && (fromDate.getMinutes() == 0 || fromDate.getMinutes() == 30)) ||
                (toDate.getMinutes() == 59 && (fromDate.getMinutes() == 0 || fromDate.getMinutes() == 30))) {
                toDate = moment(toDate).add(1, 'minutes').toDate();
            }

            const difference = toDate.getTime() - fromDate.getTime();
            const days = Math.floor(difference / MILISECONDS_IN_DAY);
            const hours = Math.floor((difference - (days * MILISECONDS_IN_DAY)) / MILISECONDS_IN_HOUR);
            const mins = Math.floor((difference - (days * MILISECONDS_IN_DAY) - (hours * MILISECONDS_IN_HOUR)) / MILISECONDS_IN_MINUTE);

            return (days > 0 ? days.toString() + "d" : "") +
                (hours > 0 ? hours.toString() + "u" : "") +
                (mins > 0 ? mins.toString() + "m" : "");
        },

        roundDate(date: Date, roundUp: boolean): Date {
            date.setSeconds(0);
            date.setMilliseconds(0);
            if (roundUp && date.getSeconds() > 0) {
                date.setMinutes(date.getMinutes() + 1);
            }
            return date;
        },

        async removeRegistration(id: number, retry?: boolean) {
            await apolloClient.mutate({
                mutation: PLANNING_SERVICE_FACADE_BEAN_REMOVE_REGISTRATION_BY_ID,
                variables: {
                    context: {
                        errorPolicy: {
                            policy: retry ? ProteusConstants.IGNORE_WARNINGS : ProteusConstants.ENFORCE_ALL
                        },
                        processingWaitingRegistrations: false,
                        removeWaiting: false,
                        split: false,
                        trainingRegistrationUpdated: false,
                        updatedFromSessions: false,
                        useDefaultMetadataRounding: false,
                        validationExceptionsForWaitingRegistrations: false,
                        metadataContext: PLANNING_CURRICULUM
                    },
                    registration: id
                },
                context: {
                    [ApolloContext.ON_ERROR_HANDLER]: ProteusUtils.getApolloErrorHandlerForValidationException(ProteusConstants.PLANNING_IGNORE_VALIDATION_ERRORS, (e: CatchedGraphQLError) => {
                        this.getDispatchers().setInReduxState({
                            showModal: {
                                open: true,
                                message: e.graphQLErrors?.[0]?.extensions?.ORIGINAL_EXCEPTION_MESSAGE,
                                operation: ModalOperations.CONFIRM_IGNORE_EXCEPTIONS
                            }
                        });
                    })
                }
            });
        },
        async loadInitiallySelectedRegistrationTypesAndStatusesFromMetadata() {
            const initiallySelected = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_INITIALLY_SELECTED_TYPES_AND_STATUSES_FROM_METADATA,
                variables: {
                    metadataContext: PLANNING_CURRICULUM
                }
            })).data.employeeService_initiallySelectedRegistrationTypesAndStatusFromMetadata;

            this.getDispatchers().setInReduxState({ initiallySelectedTypes: initiallySelected, appliedRegistrationTypes: initiallySelected });
        },
    }
});

type PropsNotFromState = {
    isEditable: boolean;
    employee: getEmployee_employeeService_employee | undefined | null;
    renderAdditionalFieldsInForm: (additionalFields: JSX.Element) => JSX.Element;
    getOperatingMode: (registration: Registration, context: string) => number;
    loadRegistrationJson: (registrationId: number) => Promise<any>;
}

export class CurriculumTab extends TabbedPage<PropsFrom<typeof sliceCurriculumTab> & PropsNotFromState> {
    protected refEntityTableLight = React.createRef<EntityTableLight>();
    protected registrationTypeCategoryTreeRef = React.createRef<RegistrationTypeCategoryTreeRaw>();

    getEntityTableLightRef() {
        return this.refEntityTableLight;
    }

    async getRegistrationSnapshots(employeeId: number | null | undefined, typesAndStatuses: string[], fromDate?: Date | number | undefined, toDate?: Date | number | undefined) {
        fromDate = fromDate ? new Date(fromDate) : new Date(this.props.curriculumDatePanel.fromDate);
        toDate = toDate ? new Date(toDate) : new Date(this.props.curriculumDatePanel.untilDate);
        let registrations: RegistrationTable[] = [];

        if (employeeId) {
            let registrationSnapshots: getRegistrationSnapshotsForCurriculum_employeeService_registrationSnapshotsForCurriculum[] = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_REGISTRATION_SNAPSHOTS_CURRICULUM,
                variables: {
                    employeeId: employeeId,
                    fromDate: fromDate,
                    toDate: toDate,
                    registrationTypesAndStatus: typesAndStatuses
                }
            })).data.employeeService_registrationSnapshotsForCurriculum;
            registrationSnapshots = registrationSnapshots.sort((reg1: getRegistrationSnapshotsForCurriculum_employeeService_registrationSnapshotsForCurriculum, reg2: getRegistrationSnapshotsForCurriculum_employeeService_registrationSnapshotsForCurriculum) =>
                moment(reg1.a?.fromDate).valueOf() - moment(reg2.a?.fromDate).valueOf());

            for (let registrationSnapshot of registrationSnapshots) {
                registrations.push({
                    id: registrationSnapshot.a?.id,
                    type: registrationSnapshot.a?.type || null,
                    status: registrationSnapshot.a?.status || null,
                    fromDate: registrationSnapshot.a?.fromDate ? moment(registrationSnapshot.a?.fromDate).format(ProteusConstants.DATE_TIME_FORMAT) : "",
                    toDate: registrationSnapshot.a?.toDate ? moment(registrationSnapshot.a?.toDate).format(ProteusConstants.DATE_TIME_FORMAT) : "",
                    duration: this.props.dispatchers.calculateDuration(registrationSnapshot) || "",
                    creationDate: registrationSnapshot.b?.creationDate ? moment(registrationSnapshot.b?.creationDate).format(ProteusConstants.DATE_TIME_FORMAT) : "",
                    creationUser: registrationSnapshot.b?.creationUser || "",
                    modificationDate: registrationSnapshot.b?.modificationDate ? moment(registrationSnapshot.b?.modificationDate).format(ProteusConstants.DATE_TIME_FORMAT) : "",
                    modificationUser: registrationSnapshot.b?.modificationUser || ""
                });
            }
        }
        this.refEntityTableLight.current?.open(registrations);
        this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
    }

    protected onTreeButtonClick = async (selectedStatuses: string[], registrationTree: any) => {
        this.props.dispatchers.setInReduxState({ treeModalOpen: false, appliedRegistrationTypes: selectedStatuses,
            registrationTypeTree: registrationTree, firstLoad: false });
        await this.getRegistrationSnapshots(this.props.employee?.id, selectedStatuses);
    }

    protected onRevertButtonClick = async (selectedStatuses: string[], registrationTree: any) => {
        this.props.dispatchers.setInReduxState({ treeModalOpen: false, appliedRegistrationTypes: selectedStatuses,
            registrationTypeTree: registrationTree, firstLoad: true });
        await this.getRegistrationSnapshots(this.props.employee?.id, selectedStatuses);
    }

    public renderSaveBarAdditionalContent = () => {
        return <>
            <div key="div1" className="FunctionsTab_barDivider" />
            <div>
                <Button className="CurriculumTab_selectRegistrationTypes" disabled={!this.props.employee} primary onClick={() => {
                    this.props.dispatchers.setInReduxState({ treeModalOpen: true });
                }}>
                    <img src="subtask.png" width="14" height="14" alt="Registratie types"></img>
                    <span>{_msg("EmployeeEditor.curriculumTab.selectRegistrationTypes.label")}</span>
                </Button>
            </div>
            <DateNavigationPanel {...this.props.curriculumDatePanel} dispatchers={this.props.dispatchers.curriculumDatePanel} onChangePeriod={this.onDateNavigationChange} />
        </>
    }

    protected onDateNavigationChange = async (e: any) => {
        await this.getRegistrationSnapshots(this.props.employee?.id, this.props.appliedRegistrationTypes, new Date(e.fromDate), new Date(e.untilDate));
    }

    public styleRegistrations(registration: RegistrationTable): { color: string, backgroundColor: string } {
        const backgroundColor = Number(MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!, registration.type?.code || "", registration.status?.code || "", BACKGROUND_COLOR, PLANNING_CURRICULUM));
        return { color: Utils.convertColorToHex(ColorUtil.getForeground(backgroundColor)), backgroundColor: Utils.convertColorToHex(backgroundColor) };
    }

    protected renderCellCustomizer = (props: CellProps) => {
        const registration: RegistrationTable = this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[props.rowIndex!];
        const { color, backgroundColor } = this.styleRegistrations(registration);
        props.style = { 'backgroundColor': backgroundColor, 'color': color, 'borderTop': '1px solid white', 'borderBottom': '1px solid white' };
    }

    protected renderConfirmationModal() {
        return (
            <ModalExt size="small" open={this.props.showModal?.open} severity={Severity.WARNING}
                onClose={() => this.props.dispatchers.setInReduxState({ showModal: undefined })}>
                <Modal.Header>{_msg("warning.label")}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Interweave content={this.props.showModal?.message} />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button key="cancel" onClick={() => this.props.dispatchers.setInReduxState({ showModal: undefined })}>{_msg("general.cancel")}</Button>
                    <Button key="ok" primary onClick={() => {
                        const retry = this.props.showModal?.operation === ModalOperations.CONFIRM_IGNORE_EXCEPTIONS ? true : false;
                        this.onRemoveRegistration([], this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!, retry);
                        this.props.dispatchers.setInReduxState({ showModal: undefined });
                    }}>{_msg("general.ok")}
                    </Button>
                </Modal.Actions>
            </ModalExt>
        );
    }

    protected renderTreeModal() {
        return <ModalExt closeIcon open={this.props.treeModalOpen} className="EntityFormLight_modal ConstraintTab_modal CurriculumTab_modal"
            onClose={() => this.props.dispatchers.setInReduxState({ treeModalOpen: false })}>
            <Modal.Header>
                <Icon name="file alternate outline" />{_msg("RegistrationType.label")}
            </Modal.Header>
            <Modal.Content>
                <div className="ConstraintTab_formTree">
                    <RegistrationTypeCategoryTree
                        id="RegistrationTypeCategoryTree"
                        hasStatuses={true} currentContext={PLANNING_CURRICULUM} hasCheckboxes={true}
                        filterWorkPeriodTypes={false} showApplyAndRevertButtons={true} onApplyClick={(selectedStatuses: string[], registrationTree: any) =>
                            this.onTreeButtonClick(selectedStatuses, registrationTree)}
                        onRevertChangesClick={async () => 
                                this.onRevertButtonClick(this.props.initiallySelectedTypes!, await this.loadRegistrationTypeTree(true, true, ProteusConstants.PLANNING_CURRICULUM))}
                        appliedRegistrationTypes={this.props.appliedRegistrationTypes} initiallySelectedTypes={this.props.initiallySelectedTypes}
                        registrationTypeTree={this.props.registrationTypeTree} firstLoad={this.props.firstLoad} />
                </div>
            </Modal.Content>
        </ModalExt>
    }

    protected onRemoveRegistration = async (entities: RegistrationTable[], index: number, retry?: boolean) => {
        const registrationId = this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[index].id;
        await this.props.dispatchers.removeRegistration(registrationId, retry);
        await this.getRegistrationSnapshots(this.props.employee?.id, this.props.appliedRegistrationTypes);
    }

    protected onEditRegistration = async (registration: RegistrationTable) => {
        const loadedRegistration = await this.props.loadRegistrationJson(registration.id!);
        if (!loadedRegistration) {
            return;
        }

        const operatingMode = !this.props.isEditable ? READ_ONLY_MODE : this.props.getOperatingMode(loadedRegistration, PLANNING_CURRICULUM);
        this.props.dispatchers.setInReduxState({
            isRegistrationDrawerOpen: true,
            registrationForEditor: loadedRegistration,
            operatingMode: operatingMode
        });
    }

    protected getEmployeeForRegistrationEditor() {
        return {
            id: this.props.employee?.id,
            name: this.props.employee?.name || "",
            firstName: this.props.employee?.firstName || "",
            detail: {
                contractHistoryItem: {
                    employeeNumber: fieldEditors[HISTORY_FIELD_TYPE].retrieveCurrentHistoryItem(this.props.employee?.detail?.contractHistory, this.props.registrationForEditor?.fromDate)?.employeeNumber
                }
            }
        } as any;
    }

    protected onRegistrationDrawerClose = async (shouldReloadData?: boolean) => {
        this.props.dispatchers.setInReduxState({ isRegistrationDrawerOpen: false, registrationForEditor: undefined });
        if (shouldReloadData) {
            await this.getRegistrationSnapshots(this.props.employee?.id, this.props.appliedRegistrationTypes);
        }
    }

    render() {
        return <>
            {this.renderTreeModal()}
            {this.renderConfirmationModal()}
            <div className="CurriculumTab">
                {this.props.renderAdditionalFieldsInForm(
                    <>
                        <Form>
                            <Form.Field>
                                <label><Icon name="file alternate outline" />{_msg("EmployeeEditor.curriculumTab.registrations.label")}</label>
                            </Form.Field>
                        </Form>
                        <EntityTableLight {...this.props.registrationsTable} dispatchers={this.props.dispatchers.registrationsTable} ref={this.refEntityTableLight}
                            actions={{ showAddButton: false, showEditButton: true, doNotRemoveEntityFromTable: true }}
                            entityDescriptor={curriculumRegistrationsEntityDescriptor} renderCellCustomizer={this.renderCellCustomizer}
                            formCustomizer={{ onItemCustomEdit: this.onEditRegistration }}
                            getConditionForShowingDelete={() => this.props.isEditable ? true : false}
                            onDelete={() => this.props.dispatchers.setInReduxState({ showModal: { open: true, operation: ModalOperations.CONFIRM_DELETE_REGISTRATION, message: _msg("dto_crud.deleteConfirmation") } })}
                        />
                    </>
                )}
                {this.props.isRegistrationDrawerOpen &&
                    <RegistrationEditor {...this.props.registrationEditor} dispatchers={this.props.dispatchers.registrationEditor}
                        employee={this.getEmployeeForRegistrationEditor()} registration={this.props.registrationForEditor}
                        isWaitingRegistration={false} onDrawerClose={this.onRegistrationDrawerClose} operatingMode={this.props.operatingMode} metadataContext={ProteusConstants.PLANNING} mode={REGISTRATION_EDITOR_MODE.PLANNING}
                    />
                }
            </div>
        </>;
    }

    getRegistrationTypeCategoryTreeRef() {
        return this.registrationTypeCategoryTreeRef.current;
    }

    async loadRegistrationTypeTree(getStatuses: boolean, reloadScreen: boolean, currentContext: string) {
        let data = (await apolloClient.query({
            query: currentContext === PLANNING_CURRICULUM ? REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_TREE_FOR_PLANNING : EMPLOYEE_SERVICE_GET_REGISTRATION_TYPE_TREE,
            variables: {
                getStatuses: getStatuses,
                filterVisibleStatuses: true,
                metadataContext: currentContext ? currentContext : ProteusConstants.SYSTEM
            },
            context: { showSpinner: reloadScreen }
        })).data
        let registrationTypeTree = currentContext === ProteusConstants.PLANNING_CURRICULUM ? data.registrationServiceFacadeBean_registrationTypeTreeForPlanning.a : data.employeeService_registrationTypeTree;
        return registrationTypeTree;
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.employee?.id !== this.props.employee?.id) {
            this.getRegistrationSnapshots(this.props.employee?.id, this.props.initiallySelectedTypes);
        }
    }

    async componentDidMount() {
        this.props.dispatchers.curriculumDatePanel.setInReduxState({
            fromDate: moment().startOf('month').valueOf(),
            untilDate: moment().endOf('month').valueOf(),
            periodDropdownValue: 1
        });

        this.props.dispatchers.setInReduxState({
            registrationTypeTree: await this.loadRegistrationTypeTree(true, true, ProteusConstants.PLANNING_CURRICULUM) });
        await this.props.dispatchers.loadInitiallySelectedRegistrationTypesAndStatusesFromMetadata();

        setTimeout(() =>
           this.getRegistrationSnapshots(this.props.employee?.id, this.props.initiallySelectedTypes)
        );
    }
}