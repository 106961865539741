import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { FileUploadButton, sliceFileUploadButton } from "@crispico/foundation-react/components/fileUploadButton/FileUploadButton";
import { UploadFile } from "antd/lib/upload/interface";
import React from "react";

const ATTACHMENT_SIZE = 100;
const ATTACHMENT_TYPE = "upload";

export interface BasicEntity {
    creationDate: Date | null,
    creationUser: string | null,
    modificationDate: Date | null,
    modificationUser: string | null,
    objectVersion: number,
    id: number,
}

export interface AttachmentEntity extends BasicEntity {
    name: string,
    fileName: string,
    content?: any | null;
}

export const sliceRegistrationAndEducationFileUploadButton = createSliceFoundation(class SliceRegistrationAndEducationFileUploadButton {
    initialState = {
        attachments: [] as AttachmentEntity[],
        allowEdit: true
    };

    nestedSlices = {
        fileUploadButton: sliceFileUploadButton
    };

    reducers = {
        ...getBaseReducers<SliceRegistrationAndEducationFileUploadButton>(this),
        setAttachments(state: StateFrom<SliceRegistrationAndEducationFileUploadButton>, attachments: AttachmentEntity[]) {
            state.attachments = attachments;
        }
    };

    impures = {
        ...getBaseImpures<SliceRegistrationAndEducationFileUploadButton>(this),
    };
});

type Props = PropsFrom<typeof sliceRegistrationAndEducationFileUploadButton> & {
    allowEdit?: boolean,
    type: string,
    class: string,
    onRemoveFile: (file: UploadFile) => void;
    setAttachments: (attachments: AttachmentEntity[]) => void;
};

export class RegistrationAndEducationFileUploadButton extends React.Component<Props> {
    onUploadFiles = async (files: UploadFile[]) => {
        const uploadedFile = files[files.length - 1].originFileObj!;
        const fd = new FormData();
        fd.append('entitiesFile', uploadedFile);
        const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
        const url = Utils.adjustUrlToServerContext(additionalUrlParam +`attachment/upload?type=${this.props.type}`);
        const response = await fetch(url as string, { method: 'POST', body: fd });
        if (response.status === 200) {
            let attachments = this.props.attachments ? this.props.attachments : [];
            let newAttachment: AttachmentEntity & { classType: string } 
                = {
                name: (uploadedFile as File).name,
                fileName: (uploadedFile as File).name,
                id: Number(await response.text()),
                objectVersion: 0,
                classType: this.props.class,
                creationDate: null,
                creationUser: null,
                modificationDate: null,
                modificationUser: null,
            };
            attachments = attachments.concat(newAttachment);
            this.props.setAttachments(attachments);
            this.props.dispatchers.setAttachments(attachments);
            return _msg("FileUploadButton.uploadSuccessfully");
        }
        return _msg("FileUploadButton.uploadError");
    };

    protected getAttachmentsFileList() {
        let fileList: { name: string, uid: string, size: number, type: string; }[] = [];
        if (this.props.attachments) {
            fileList = this.props.attachments.map((item: AttachmentEntity | null) => ({
                name: item?.name || "",
                uid: String(item?.id),
                size: ATTACHMENT_SIZE,
                type: ATTACHMENT_TYPE
            }));
        }
        return fileList;
    }

    protected onDownloadFile = async (file: UploadFile) => {
        const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
        const url = Utils.adjustUrlToServerContext(additionalUrlParam + `attachment/download?type=${this.props.type}&id=${Number(file.uid)}`);
        const response = await fetch(url!, { method: 'POST' });
        let blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(new Blob([blob]));
        let link = document.createElement('a');
        link.href = fileUrl;
        link.download = file.name;
        link.click();
    };

    render(): React.ReactNode {
        return (<FileUploadButton {...this.props.fileUploadButton} dispatchers={this.props.dispatchers.fileUploadButton} fileList={this.getAttachmentsFileList()}
            showUploadList={{ showRemoveIcon: this.props.allowEdit }} onUploadFiles={this.onUploadFiles} multiple={false} onPreview={this.onDownloadFile}
            onRemove={this.props.onRemoveFile} disableUploadButton={!this.props.allowEdit} useDragAndDrop={true}
        />);
    }
}