'use strict';

var css = require('./select.css');

/**
 * An editable select. Model object:
 * {
 * 	data: Array,
 *  selection: Object,
 *  labelField: String (name by default)
 *  onSelect: Function, (optional)
 *  onEdit: Function,   (optional)
 *  onAdd: Function     (optional)
 *  onAfterOpen: Function     (optional)
 *  get: Function
 *  style: see ng-class
 * } 
 */
/*@ngInject*/
function proteusSelect(FormModal, ModalSpinner, Collection, $http) {
	
	return {
		restrict: 'E',
		template: require('./select.html'),
		scope: {
			label: '@',
			editable: '=',
			multiselect: '=',
			model: '=',
			options: '=?'
		},
		link: function($scope) {
			var model = $scope.model,
				collection = null;
			model.selectionCollection = Collection.create([]);
			model.options = $scope.options;

			model.getOptions = function() {
				return $scope.options;
			}
			
			$scope.select = function(option) {
				if ($scope.multiselect) {
					if (model.selectionCollection.contains(option)) {
						model.selectionCollection.remove(option);
					} else {
						model.selectionCollection.add(option);
					}
				}  else {
					if (model.selection === option) {
						return;
					}
					model.selection = option;
					model.onSelect && model.onSelect(option);
				}
			};
			
			$scope.getLabel = function(option) {
				if (!option) {
					return "";
				}
				if (model.labelField) {
					return option[model.labelField];
				}

				if (model.labelFn) {
					return model.labelFn(option);
				}
				return option.name;
			};
			
			if (model.spinner) {
				var ms = ModalSpinner.show(angular.isString(model.spinner) ? model.spinner : '');
			}
			if (model.get) {
				if (angular.isFunction(model.get)) {
					model.get(model).then(function() {
						collection = Collection.create(model.data);
						if (model.data.length > 0 && !$scope.multiselect && angular.isUndefined(model.selection)) {
							$scope.select(model.data[0]);
						}
					}).finally(function() {
						ModalSpinner.hide(ms);
						model.onAfterOpen && model.onAfterOpen();
					});
				} else if (angular.isString(model.get)) {
					$http.get(model.get).then(function(result) {
						model.data = result.data;
						collection = Collection.create(model.data);
						if (model.allowNull) {
							collection.add({
								code: null,
								description: '(Alle)'
							}, 0);
						}
						if (model.data.length > 0 && !$scope.multiselect && angular.isUndefined(model.selection)) {
							$scope.select(model.data[0]);
						}
					});
				}
			} else {
				collection = Collection.create(model.data);
				if (model.data.length > 0 && !this.multiselect && angular.isUndefined(model.selection)) {
					$scope.select(model.data[0]);
				}
				ModalSpinner.hide(ms);
				model.onAfterOpen && model.onAfterOpen();
			}

			/**
			 * Open form view.
			 */
			$scope.edit = function(option) {
				var instance = FormModal.open({
					scope: {
						model: angular.copy(option),
						title: option.name,
						template: model.template
					},
					actions: ['ok', 'cancel', 'close']
				});
				instance.result.then(function(result) {
					ModalSpinner.show();
					model.save(null, {
						data: result,
						params: {
							id: result.id
						}
					}).then(function(result) {
						collection.replace(result.data);
						model.onEdit && model.onEdit(result.data);
					}).finally(function() {
						ModalSpinner.hide();
					});
				});
			};
			
			$scope.add = function() {
				var instance = FormModal.open({
					scope: {
						model: model.create(),
						title: 'add',
						template: model.template
					},
					actions: ['ok', 'cancel', 'close']
				});
				instance.result.then(function(result) {
					ModalSpinner.show();
					model.save(null, {
						data: result
					}).then(function(result) {
						collection.add(result.data);
						model.onAdd && model.onAdd(result);
					}).finally(function() {
						ModalSpinner.hide();
					});
				});
			};
		}
	};
}

module.exports = proteusSelect;