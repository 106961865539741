import { createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, StateFrom } from "@crispico/foundation-react";
import { FieldRendererProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { EntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { push } from "connected-react-router";
import _ from "lodash";
import moment from "moment";
import { HistoryTable, sliceHistoryTable } from "components/HistoryTable";
import { ProteusConstants } from "ProteusConstants";
import React from "react";
import { Form, Input } from "semantic-ui-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { PREMIUM_PARAMETER_SERVICE_SAVE_PREMIUM_PARAMETER } from "graphql/queries";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";

const COLUMN_WIDTH = 350;
const TYPE_NUMBER = "number";
const DEFAULT_VALUE = 0.0;

export class PremiumParameterEntityDescriptor extends EntityDescriptor {

    getGraphQlFieldsToRequest() {
        return "id, objectVersion, modificationDate, modificationUser, creationDate, creationUser " +
            " premiumParameterValueHistory {id, objectVersion, modificationDate, modificationUser, creationDate, creationUser, value, validFrom, validUntil } " +
            " record {id, objectVersion, modificationDate, modificationUser, creationDate, creationUser, code, description } " +
            " loaCategory {id, objectVersion, modificationDate, modificationUser, creationDate, creationUser, code, description, lengthFrom, lengthTo} " +
            " route {id, objectVersion, modificationDate, modificationUser, creationDate, creationUser, code, description } " +
            " type {id, objectVersion, modificationDate, modificationUser, creationDate, creationUser, code, description } ";
    }

    protected customize() {

        const premiumParameterEntityDescriptor = this;
        premiumParameterEntityDescriptor.addFieldDescriptor({ name: "premiumParameterValueHistory", type: "PremiumParameterHistoryItem", isInDefaultColumnConfigForEditor: false });

        const slicePremiumParameterEditor = premiumParameterEntityDescriptor.infoEditor.slice = createSliceFoundation(class SlicePremiumParameterEditor extends SliceEntityEditorPage {

            /**
             * @override
             */
            getSaveOperationName(): string {
                return `premiumParameterService_savePremiumParameter`;
            }

            /**
             * @override
             */
            getLoadOperationName(): string {
                return `parameterService_findParameterById`;
            }

            initQueries() {
                super.initQueries();
                this.saveMutation = PREMIUM_PARAMETER_SERVICE_SAVE_PREMIUM_PARAMETER;
            }

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                selectedEntity: undefined
            }

            nestedSlices = {
                ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
                historyTable: sliceHistoryTable
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SlicePremiumParameterEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SlicePremiumParameterEditor>(this),

                /**
                * Because the save function (server-side) has additional logic, the default "save-service-function" has been overridden. 
                * Adapt options.variables for the overridden save function
                */
                superMutation: sliceEntityEditorPageOnlyForExtension.impures.invokeSaveMutation,
                async invokeSaveMutation(options: any) {
                    let localEntity = { ...this.getState().entity };
                    if (this.getState().duplication) {
                        localEntity = {
                            ...this.setNullEntityValuesWhenDuplicating(localEntity),
                            premiumParameterValueHistory: this.setNullHistoryValuesWhenDuplicating(localEntity.premiumParameterValueHistory)
                        };
                    }
                    options.variables = { entity: localEntity };
                    return await this.superMutation(options);
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    const result = await this.saveSuper(entity);

                    //if no exceptions occured, go back to table
                    if (result !== true) {
                        this.getDispatchers().dispatch(push(premiumParameterEntityDescriptor.getEntityTableUrl()));
                    }
                },

                setNullHistoryValuesWhenDuplicating(history: []) {
                    let newHistory = [];
                    for (let i = 0; i < history.length; i++) {
                        newHistory.push(this.setNullEntityValuesWhenDuplicating(history[i]));
                    }
                    return newHistory;
                },

                setNullEntityValuesWhenDuplicating(entity: any) {
                    return {
                        ...entity,
                        id: null,
                        creationDate: null,
                        creationUser: null,
                        modificationDate: null,
                        modificationUser: null,
                        objectVersion: null
                    };
                }
            }

        }).setEntityDescriptor(premiumParameterEntityDescriptor);

        premiumParameterEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof slicePremiumParameterEditor> & EntityEditorPageProps> {
            protected historyTableRef = React.createRef<HistoryTable>();

            constructor(props: any) {
                super(props);
                this.updatePremiumParameterValue = this.updatePremiumParameterValue.bind(this);
            }

            setPremiumParameterHistoryTableEntities(premiumParameterValueHistory: any[]) {
                if (premiumParameterValueHistory) {
                    const entities = [...premiumParameterValueHistory].sort((a: any, b: any) => moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf());
                    this.historyTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(entities);
                    if (!this.historyTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()) {
                        this.props.dispatchers.setInReduxState({ selectedEntity: entities[0] });
                    } else {
                        this.props.dispatchers.setInReduxState({ selectedEntity: entities[this.historyTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!] });
                    }
                } else {
                    this.historyTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                }
            }

            async load(id: any) {
                const entity = await super.load(id);
                this.setPremiumParameterHistoryTableEntities(entity.premiumParameterValueHistory);
            }

            protected nestedEntityDescriptor: EntityDescriptor = new EntityDescriptor({
                name: "PremiumParameterHistoryItemTable",
                miniFields: ["code"]
            }, false)
                .addFieldDescriptor({ name: ProteusConstants.VALID_FROM, type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT }) })
                .addFieldDescriptor({ name: ProteusConstants.VALID_UNTIL, type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT }) })

            protected columns = [{ name: ProteusConstants.VALID_FROM, width: COLUMN_WIDTH }, { name: ProteusConstants.VALID_UNTIL, width: COLUMN_WIDTH }];

            async updatePremiumParameterValue(updatedPremiumParameterValue: [{ key: any }, any]) {
                const entity = super.getEntityValuesFromForm();
                let newParameters = [];
                for (let i = 0; i < updatedPremiumParameterValue.length; i++) {
                    if (updatedPremiumParameterValue[i].value === undefined) {
                        newParameters.push({ ...updatedPremiumParameterValue[i], value: 0.0 });
                    } else {
                        newParameters.push(updatedPremiumParameterValue[i]);
                    }
                }
                this.props.dispatchers.setInReduxState({ entity: { ...entity, premiumParameterValueHistory: newParameters } });
                this.setPremiumParameterHistoryTableEntities(newParameters);
            }

            replaceModifiedParameterValueInEntity(updatedPremiumParameterValue: any) {
                let newParameters = this.props.entity.premiumParameterValueHistory.filter((item: any) => item.validFrom !== updatedPremiumParameterValue.validFrom);
                newParameters.push(updatedPremiumParameterValue);
                this.updatePremiumParameterValue(newParameters);
            }

            renderInputValueComponent(selectedEntity: any) {
                return <Form>
                    <Form.Field className="PremiumParameter_historyValueField"
                        label={_msg("PremiumParameter.historyValueLabel.label")}>
                    </Form.Field>
                    <Form.Field>
                        <Input value={selectedEntity?.value || DEFAULT_VALUE} type={TYPE_NUMBER} step={0.1} fluid onChange={(e, data) => {
                            let updatedPremiumParameterValue = { ...selectedEntity, value: parseFloat(data.value) };
                            this.replaceModifiedParameterValueInEntity(updatedPremiumParameterValue);
                        }} />
                    </Form.Field>
                </Form>
            }

            renderForm() {
                let hasValue = this.props.entity.premiumParameterValueHistory.length > 0;
                return (<>
                    {super.renderForm()}
                    {this.props.entity.premiumParameterValueHistory?.length > 0 && <label className="PremiumParameter_historyTableLabel">{_msg("PremiumParameter.historyTableLabel.label")}</label>}
                    <div className="PremiumParameter_historyTable">
                        <HistoryTable onSave={this.updatePremiumParameterValue} onDelete={this.updatePremiumParameterValue} columns={this.columns}
                            onAdd={sliceHistoryTable.reducers.onAdd} {...this.props.historyTable} dispatchers={this.props.dispatchers.historyTable}
                            formCustomizer={{ headerContent: _msg("PremiumParameterHistoryItem.label"), headerIcon: "calendar alternate outline" }}
                            entityDescriptor={this.nestedEntityDescriptor} actions={{ showDeleteButton: false, showAddButton: true }} ref={this.historyTableRef}
                            onSelectItem={(itemId) => this.props.dispatchers.setInReduxState({ selectedEntity: this.historyTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[itemId] })} />
                    </div>
                    <div className="PremiumParameter_valueField">
                        {hasValue && this.renderInputValueComponent(this.props.selectedEntity)}
                    </div>
                </>
                );
            }
        }

        fieldRenderers["PremiumParameterHistoryItem"] = class extends React.Component<FieldRendererProps>{
            render = () => {
                //for premiumParameterValueHistory, return the value for the item with latest validUntil or with validUntil null
                if (this.props.value.length === 0) {
                    return null;
                }
                let maxDate = this.props.value[0].validUntil;
                let maxIndex = 0;
                if (this.props.value.length > 1 && maxDate !== null && maxDate !== undefined) {
                    for (let i = 0; i < this.props.value.length; i++) {
                        if (this.props.value[i].validUntil === null || this.props.value[i].validUntil === undefined) {
                            maxIndex = i;
                            break;
                        } else if (new Date(this.props.value[i].validUntil).getTime() > new Date(maxDate).getTime()) {
                            maxDate = this.props.value[i].validUntil;
                            maxIndex = i;
                        }
                    }
                }
                return this.props.value[maxIndex].value?.toString() || "";
            }
        }
    }
}