'use strict';

function mqDetector() {
	  
	return {
		restrict: 'A',
		template: require('./mq-detector.html'),
		scope: {
			mq: '@',
			callback: '='
		},
		link: function($scope, elt, attrs) {
			var mqs = $scope.mq.split(' ');
			
			function isMqVisible(mq) {
				return elt.find('.visible-' + mq).css('display') !== 'none';
			}

			function checkForResize() {
				for (var i = 0; i < mqs.length; i++) {
					var mq = mqs[i];
					var visible = isMqVisible(mq);
					if (visible != mqs[mq]) {
						mqs[mq] = visible;
						$scope.callback(mq, visible);
					}
				}
			}

			$(window).on('resize', checkForResize);
			$scope.$on('$destroy', function() {
				$(window).off('resize', checkForResize);
			});

			if ($scope.callback) {
				checkForResize();
			}
		}
	}
};

module.exports = mqDetector;