'use strict';

/*@ngInject*/
function EvaluationComponentsListCtrl($scope, ModalSpinner, Education) {
	var evaluationComponents = $scope.requests.evaluationComponents = this;
	
	evaluationComponents.gridOptions = {
		columnDefs: [{
			name: 'name',
			displayName: 'education.evaluationRequest.name',
			field: 'name'
		}, {
			name: 'version',
			displayName: 'education.evaluationRequest.version',
			field: 'version',
			width: '50px'
		}, {
			name: 'active',
			displayName: 'education.evaluationRequest.active',
			field: 'active',
			width: '50px',
			cellTemplate: '<i class="fa fa-check" ng-if="row.entity.active"></i>'
		}],

		onRegisterApi: function (gridApi) {
			gridApi.selection.on.rowSelectionChanged($scope, function(row) {
				$scope.evaluationComponents.evaluationComponent = row.entity;
			});
		}
	};

	ModalSpinner.show();

	Education.getEvaluationComponents(null, {
		params: {
			id: 1
		}
	}).then(function(result) {
		var data = [];
		angular.forEach(result.data, function(evaluationComponent) {
			data.push(evaluationComponent.currentComponentVersion);
		});
		evaluationComponents.gridOptions.data = data;
	}).finally(function() {
		ModalSpinner.hide();
	});
}

module.exports = EvaluationComponentsListCtrl;