import { apolloClient, ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { TRAINING_SERVICE_ENDPOINT_GET_EVALUATIONS_TO_FILL_IN, TRAINING_SERVICE_ENDPOINT_UPDATE_EVALUATION } from "graphql/queries";
import { ProteusUtils } from "ProteusUtils";
import React from "react";
import { Button, Card, Checkbox, Form, Grid, Image, Segment, Step, TextArea, TextAreaProps } from 'semantic-ui-react'
import { format } from "date-fns"
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { FieldRendererProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors"
import { VIEW_COMPETENCE, evaluationCriteriaEntityDescriptor, evaluationCriterionEntityDescriptor, SHOW_SCORE } from "./customFieldRenderersEditors";
import { UploadFile } from "antd/lib/upload/interface";
import { listPencilIcon } from "@crispico/foundation-react/utils/GroupedIconsRegistry";
import { AttachmentEntity, RegistrationAndEducationFileUploadButton, sliceRegistrationAndEducationFileUploadButton } from "components/uploadButton/RegistrationAndEducationFileUploadButton";

const CLASS = "com.brabo.training.core.evaluation.domain.EvaluationAttachment";
const EVALUATION = "EVALUATION";

export interface Attachment {
    classType: string | null;
    creationDate: Date | null;
    creationUser: string | null;
    fileName: string | null;
    id: number | null;
    modificationDate: Date | null;
    modificationUser: string | null;
    name: string | null;
    objectVersion: number | null;
}

export interface CompetenceVersion {
    description: string;
    levelDefinitions: LevelDefinition[];
    name: string;
}

export interface ComponentVersion {
    name: string;
}

export interface Evaluation {
    attachments: AttachmentEntity[];
    averageScore: number;
    date: Date;
    description: string;
    environment: string;
    evaluationDefinitionVersion: EvaluationDefinitionVersion;
    evaluator: Evaluator;
    finished: boolean;
    id: number;
    published: boolean;
    realization: Realization;
    remark: string;
    resource: Resource;
    scores: (Score | null)[];
    targetDate: Date;
    totalNorm: number;
    totalScore: number;
}

export interface EvaluationCriterionVersion {
    competenceVersion: CompetenceVersion;
    id: number;
    normPercentage: number;
    ownScore: boolean;
    required: boolean;
    totalScore: number;
    weight: number;
}

export interface EvaluationDefinitionVersion {
    evaluationCriterionVersions: EvaluationCriterionVersion[];
}

export interface Evaluator {
    firstName: string;
    id: number;
    name: string;
}

export interface LevelDefinition {
    description: string;
    id: number;
    percentage: number;
    scale: number;
}

export interface Realization {
    componentVersion: ComponentVersion;
}

export interface Resource {
    firstName: string;
    id: number;
    name: string;
}

export interface Score {
    evaluationCriteriumVersion: EvaluationCriterionVersion;
    id: number;
    percentage: number;
    scaleScore: number;
}

export function convertNumberToLetter(number: number) {
    return number > 0 ? String.fromCharCode(64 + number) : "";
}

export const sliceEvaluationsFillIn = createSliceFoundation(class SliceEvaluationsFillIn {

    initialState = {
        currentEvaluationId: -1 as number,
        currentStep: 1 as number,
        evaluations: [] as Evaluation[]
    }

    nestedSlices = {
        table: sliceEntityTableLight,
        attachmentUpload: sliceRegistrationAndEducationFileUploadButton
    }

    reducers = {
        ...getBaseReducers<SliceEvaluationsFillIn>(this),

        setAttachments(state: StateFrom<SliceEvaluationsFillIn>, attachments: AttachmentEntity[]) {
            for (let i in state.evaluations) {
                if (state.evaluations[i].id == state.currentEvaluationId) {
                    state.evaluations[i].attachments = attachments;
                    break;
                }
            }
        },

        setFinished(state: StateFrom<SliceEvaluationsFillIn>) {
            for (let i in state.evaluations) {
                if (state.evaluations[i].id == state.currentEvaluationId) {
                    state.evaluations[i].finished = !state.evaluations[i].finished;
                    break;
                }
            }
        },

        setRemark(state: StateFrom<SliceEvaluationsFillIn>, remark: string) {
            for (let i in state.evaluations) {
                if (state.evaluations[i].id == state.currentEvaluationId) {
                    state.evaluations[i].remark = remark;
                    break;
                }
            }
        },

        setScore(state: StateFrom<SliceEvaluationsFillIn>, addedScore: any) {
            let foundScore = false;
            const evaluations = state.evaluations;

            if (addedScore.values.score !== undefined) {
                for (let evaluationNo in state.evaluations) {
                    if (evaluations[evaluationNo].id === addedScore.values.evaluationId) {
                        const criteria = state.evaluations[evaluationNo].evaluationDefinitionVersion.evaluationCriterionVersions;
                        for (let criterionNo in criteria) {
                            const criterion = criteria[criterionNo];
                            if (criterion.id === addedScore.values.evaluationCriterionVersion.id) {
                                let index = 0;
                                if (addedScore.values.score.id === undefined) {
                                    index = state.evaluations[evaluationNo].scores.length;
                                } else {
                                    for (let i = 0; i < state.evaluations[evaluationNo].scores.length; i++) {
                                        if (state.evaluations[evaluationNo].scores[i]!.evaluationCriteriumVersion.id === criterion.id) {
                                            index = Number(i);
                                            break;
                                        }
                                    }
                                }
                                state.evaluations[evaluationNo].scores[index] = addedScore.values.score;
                                foundScore = true;
                                break;
                            }
                        }

                        if (foundScore) {
                            break;
                        }
                    }
                }
            }
        }
    }

    impures = {
        ...getBaseImpures<SliceEvaluationsFillIn>(this),

        async loadEvaluations() {
            let evaluationsToFillIn = (await apolloClient.query({
                query: TRAINING_SERVICE_ENDPOINT_GET_EVALUATIONS_TO_FILL_IN,
                variables: {
                    evaluatorId: ProteusUtils.getCurrentUser().id!
                }
            })).data.trainingServiceEndpoint_evaluationsToFillIn;
            evaluationsToFillIn && this.getDispatchers().setInReduxState({ evaluations: evaluationsToFillIn });
        },

        async refresh() {
            this.getDispatchers().setInReduxState({
                evaluations: [] as Evaluation[],
                currentStep: 1 as number,
                currentEvaluationId: -1
            });
            await this.loadEvaluations();
        },

        async saveEvaluation() {
            await apolloClient.mutate({
                mutation: TRAINING_SERVICE_ENDPOINT_UPDATE_EVALUATION,
                variables: {
                    evaluation: this.getCurrentEvaluation()
                },
                context: { showSpinner: true }
            });
            this.refresh();
        },

        getEvaluation(evaluationId: number) {
            return this.getState().evaluations.filter((evaluation: Evaluation) => evaluation.id === evaluationId)[0];
        },

        getCurrentEvaluation() {
            return this.getState().evaluations.filter((evaluation: Evaluation) => evaluation.id === this.getState().currentEvaluationId)[0];
        },
    }
})

type Props = PropsFrom<typeof sliceEvaluationsFillIn>
export class EvaluationsFillIn<T extends Props = Props> extends TabbedPage<T> {
    protected refEntityTableLight = React.createRef<EntityTableLight>();

    setTableEntity(data: Evaluation) {
        let entities: any[] = [];
        for (let i in data.evaluationDefinitionVersion.evaluationCriterionVersions) {
            let criterion = data.evaluationDefinitionVersion.evaluationCriterionVersions[i];
            entities.push({
                competence: criterion.competenceVersion,
                evaluationCriterionVersion: criterion,
                evaluationId: data.id,
                levelDefinitions: criterion.competenceVersion.levelDefinitions,
                name: criterion.competenceVersion.name,
                ownScore: criterion.ownScore,
                required: criterion.required,
                score: data.scores.filter((score: Score | null) => score?.evaluationCriteriumVersion.id === criterion.id)[0],
                weight: criterion.weight,
            })
        }
        this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(entities);
    }

    protected getTitle() {
        return { icon: listPencilIcon, title: _msg("employee.fillInEvaluation.label") };
    }

    componentDidMount(): void {
        this.props.dispatchers.loadEvaluations();
        this.renderEvaluationCriteriaDescriptor();

        if (!ProteusUtils.checkPermission(VIEW_COMPETENCE)) {
            evaluationCriteriaEntityDescriptor.removeFieldDescriptors("weight");
        }
    }

    protected countFilledScores(scores: (Score | null)[]) {
        let count = 0;
        scores.forEach(function (score) {
            if (score !== null && score.percentage !== null && score.percentage !== undefined && score.percentage >= 0) {
                count++;
            }
        })

        return count;
    }

    protected formatScore(props: FieldRendererProps) {
        if (!props.entity.score || props.entity.score.percentage === null) {
            return "";
        }

        const percentage = props.entity.score.percentage as number;
        const scaleScore = props.entity.score.scaleScore as number;
        let showScore = "";

        if (ProteusUtils.checkPermission(VIEW_COMPETENCE)) {
            showScore = " " + percentage + "% " + "(" + convertNumberToLetter(scaleScore) + ")";
        }

        if (percentage >= 0) {
            return (
                <i className="fa fa-check" aria-hidden="true">{showScore}</i>
            );
        }

        return "";
    }

    protected formatTargetDate(targetDate: Date) {
        return targetDate ? format(new Date(targetDate), "DD/MM/YYYY") : "";
    }

    protected getEvaluationDetails() {
        const currentEvaluation = this.props.dispatchers.getCurrentEvaluation();
        return (
            <>
                <div className="h6 col-sm-3">
                    <label className="EvaluationsFillIn_label_muted" style={{padding: "0px"}}>{`${_msg("EvaluationsFillIn.name.label")}: `}</label>
                    <label className="EvaluationsFillIn_label">{`${currentEvaluation.resource.name} ${currentEvaluation.resource.firstName}`}</label>
                </div>
                <div className="h6 col-sm-8">
                    <label className="EvaluationsFillIn_label_muted" style={{padding: "0px"}}>{`${_msg("EvaluationsFillIn.evaluation.label")}: `}</label>
                    <label className="EvaluationsFillIn_label">{`${currentEvaluation.realization.componentVersion.name}`}</label>
                </div>
            </>
        );
    }

    protected getTableHeaderTitle(entity: any) {
        return entity.name;
    }

    protected onRemoveFile = (file: UploadFile) => {
        const remainingFiles = this.props.dispatchers.getCurrentEvaluation().attachments?.filter((item: AttachmentEntity | null) =>
            item?.id !== Number(file.uid));
        this.props.dispatchers.setAttachments(remainingFiles);
    }

    protected selectEvaluation(evaluationId: number) {
        this.props.dispatchers.setInReduxState({ currentEvaluationId: evaluationId });
    }

    protected renderEvaluationCriteriaDescriptor() {
        fieldRenderers[SHOW_SCORE] = (props: FieldRendererProps) => { return this.formatScore(props) }
    }

    protected renderEvaluations() {
        const evaluations = this.props.dispatchers.getState().evaluations;
        if (!evaluations.length) {
            return <></>;
        }

        const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
        const pictureServletUrl = additionalUrlParam + "resources/getPicture?resource=";

        return evaluations.map((evaluation, index) =>
            <Card data-testid={`EvaluationsFillIn_evaluation${index}`} className="EvaluationsFillIn_card" key={evaluation.resource.id} onClick={() => this.selectEvaluation(evaluation.id)}>
                <Card.Content cardactive={evaluation.id === this.props.dispatchers.getState().currentEvaluationId ? "true" : undefined}>
                    <Segment>
                        <div style={{ background: "white", display: "flex" }} >
                            <span style={{flexShrink: 0}}>
                                    <Image centered size="tiny" src={Utils.adjustUrlToServerContext(pictureServletUrl) + "" + evaluation.resource.id} />
                            </span>
                            <span>
                                <div className="EvaluationsFillIn_cardRow" data-testid={`EvaluationsFillIn_nameLabel${index}`}>
                                    <label className="EvaluationsFillIn_label_muted">{`${_msg("name.label")}`}</label>
                                    <label className="EvaluationsFillIn_label">{`${evaluation.resource.name} ${evaluation.resource.firstName}`}</label>
                                </div>
                                <div className="EvaluationsFillIn_cardRow" data-testid={`EvaluationsFillIn_evaluationLabel${index}`}>
                                    <label className="EvaluationsFillIn_label_muted">{`${_msg("EvaluationsFillIn.evaluation.label")}`}</label>
                                    <label className="EvaluationsFillIn_label">{`${evaluation.realization.componentVersion.name}`}</label>
                                </div>
                                <div className="EvaluationsFillIn_cardRow" data-testid={`EvaluationsFillIn_targetDateLabel${index}`}>
                                    <label className="EvaluationsFillIn_label_muted">{`${_msg("EvaluationsFillIn.targetDate.label")}`}</label>
                                    <label className="EvaluationsFillIn_label">{this.formatTargetDate(evaluation.targetDate)}</label>
                                </div>
                                <div className="EvaluationsFillIn_cardRow" data-testid={`EvaluationsFillIn_statusLabel${index}`}>
                                    <label className="EvaluationsFillIn_label_muted">{`${_msg("status.label")}`}</label>
                                    <label className="EvaluationsFillIn_label">{`${this.countFilledScores(evaluation.scores)} / ${evaluation.evaluationDefinitionVersion.evaluationCriterionVersions.length}`}</label>
                                </div>
                            </span>
                        </div>
                    </Segment>
                </Card.Content>
            </Card>
        );
    }

    protected isStepActive(step: number) {
        return step === this.props.dispatchers.getState().currentStep;
    }

    protected isStepCompleted(step: number) {
        return step < this.props.dispatchers.getState().currentStep;
    }

    protected isStepDisabled(step: number) {
        return this.props.dispatchers.getState().currentEvaluationId === -1 && step !== this.props.dispatchers.getState().currentStep;
    }

    protected renderSteps() {
        return (
            <Step.Group data-testid="EvaluationsFillIn_steps" ordered>
                {this.renderStep(1, _msg("EvaluationsFillIn.selectEvaluation.label"))}
                {this.renderStep(2, _msg("EvaluationsFillIn.giveScore.label"))}
                {this.renderStep(3, _msg("EvaluationsFillIn.end.label"))}
            </Step.Group>);
    }

    protected renderStep(stepNumber: number, title: String) {
        return <Step data-testid={`EvaluationsFillIn_step${stepNumber}`}
            className="EvaluationsFillIn_step"
            disabled={this.isStepDisabled(stepNumber)}
            active={this.isStepActive(stepNumber)}
            completed={this.isStepCompleted(stepNumber)}
            onClick={() => this.props.dispatchers.setInReduxState({ currentStep: stepNumber })}
        >
            <Step.Content>
                <Step.Title>{title}</Step.Title>
            </Step.Content>
        </Step>
    }

    protected renderStepContent() {
        const step = this.props.dispatchers.getState().currentStep;

        switch (step) {
            case 1: {
                return (
                    <div className="EvaluationsFillIn_padding EvaluationsFillIn_stepContainer">
                        <div className="h5">
                            {`${_msg("EvaluationsFillIn.selectEvaluation.description.label")}`}
                        </div>
                        <Card.Group data-testid="EvaluationsFillIn_cards" className=" ">
                            {this.renderEvaluations()}
                        </Card.Group>
                    </div>
                );
            }
            case 2: {
                return (
                    <>
                        <div>
                            {this.getEvaluationDetails()}
                        </div>
                        <div className="h5 EvaluationsFillIn_padding">
                            {`${_msg("EvaluationsFillIn.giveScore.description.label")}`}
                        </div>
                        <div data-testid="EvaluationsFillIn_step2Container" className="EvaluationsFillIn_step2Container EvaluationsFillIn_padding flex-grow">
                            <EntityTableLight {...this.props.table} dispatchers={this.props.dispatchers.table} entityDescriptor={evaluationCriteriaEntityDescriptor}
                                formCustomizer={{ headerContent: this.getTableHeaderTitle(this.props.dispatchers.getCurrentEvaluation()), customEntityDescriptorForEditor: evaluationCriterionEntityDescriptor }}
                                onSave={(entities: any, addedScore: any) => { this.props.dispatchers.setScore(addedScore) }}
                                getConditionForShowingDelete={(entity: any) => false}
                                actions={{ showAddButton: false }} ref={this.refEntityTableLight}
                            />
                        </div>
                    </>
                );
            }
            case 3: {
                return (
                    <div className="EvaluationsFillIn_stepContainer flex">
                        <div>
                            {this.getEvaluationDetails()}
                            <Button floated="right" primary onClick={() => this.props.dispatchers.saveEvaluation()}>{_msg("general.save")}</Button>
                        </div>
                        <div className="h5 EvaluationsFillIn_padding">
                            {`${_msg("EvaluationsFillIn.end.description.label")}`}
                        </div>
                        <div className="h5 EvaluationsFillIn_padding">
                            <Checkbox
                                label={_msg("EvaluationsFillIn.complete.label")}
                                checked={this.props.dispatchers.getCurrentEvaluation().finished}
                                onClick={this.props.dispatchers.setFinished}
                            />
                        </div>
                        <div className="h5 EvaluationsFillIn_padding">
                            <Form>
                                <label className="text-muted">{`${_msg("EvaluationsFillIn.remark.label")}`}</label>
                                <TextArea
                                    value={this.props.dispatchers.getCurrentEvaluation().remark === null ? "" : this.props.dispatchers.getCurrentEvaluation().remark}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
                                        this.props.dispatchers.setRemark(data.value as string);
                                    }}
                                />
                            </Form>
                        </div>
                        <div className="h5 EvaluationsFillIn_padding">
                            <Form>
                                <label className="text-muted">{`${_msg("EvaluationsFillIn.attachments.label")}`}</label>
                                <RegistrationAndEducationFileUploadButton {...this.props.attachmentUpload} dispatchers={this.props.dispatchers.attachmentUpload}
                                    attachments={this.props.dispatchers.getCurrentEvaluation().attachments} class={CLASS} onRemoveFile={this.onRemoveFile} type={EVALUATION}
                                    setAttachments={this.props.dispatchers.setAttachments} />
                            </Form>
                        </div>
                    </div>
                );
            }
        }
    }

    protected renderMain() {
        return (
            <div className="flex grow EvaluationsFillIn">
                {this.renderSteps()}
                {this.renderStepContent()}
            </div>
        );
    }

    componentDidUpdate(prevProps: Readonly<T>) {
        if (this.props.currentStep === 2 && this.props.currentStep !== prevProps.currentStep) {
            this.setTableEntity(this.props.dispatchers.getEvaluation(this.props.currentEvaluationId));
        }
    }
}

export const infoEvaluationsFillIn = new ConnectedPageInfo(sliceEvaluationsFillIn, EvaluationsFillIn, "EvaluationsFillIn");
infoEvaluationsFillIn.routeProps = { permission: "EDUCATION_WEB_VIEW_EVALUATION_COMPLETION" };
