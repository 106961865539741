'use strict';

/**
 * This service is used to apply a filter over an element in a scope.
 *
 * Filter configuration object: {
 * 	elementName: <String> - used to retrieve the element from the scope, as scope[elementName]; default is 'element'
 * 	elementFn: <Function> - used to retrieve the element from the scope, as elementFn(scope); default will return scope[elementName]
 * }
 *
 * This service will add the following to the filter object: {
 * 	watch: <Function> - call to register a scope for filtering
 * 	onChange: <Function> - call to notify that a property value has changed, and reapply the filter
 * }
 * 
 */
/*@ngInject*/ 
function Filter(filterFilter) {

	return {

		filter: function(filter) {
			if (filter.values) {
				return;
			}

			filter.elementName = filter.elementName || 'element';
			filter.elementFn = filter.elementFn || function(scope) {
				return scope[filter.elementName];
			};
			
			filter.watches = [];
			filter.parents = {};
			
			filter.reset = function() {
				filter.values = {};
				filter.locals = {};
				filter.onChange();
			};

			filter.watch = function(scope) {
				filter.watches.push(scope);
				if (filter.parent) {
					keepParent(scope);
				}
				
				apply(scope, getExpression(filter.values));
			};

			filter.onChange = function(property, value) {
				if (angular.isDefined(property)) {
					if (angular.isUndefined(value)) {
						delete filter.values[property];
					} else {
						filter.values[property] = value;
					}
				}
				var expression = getExpression(filter.values);
				var notifyParents = {};
				angular.forEach(filter.watches, function(scope) {
					apply(scope, expression, notifyParents);
				});
				
				// angular.forEach(notifyParents, function(value, key) {
				// 	propagateToParent(filter.parents[key]);
				// });
			};
			
			filter.reset();

			function apply(scope, expression, notifyParents) {
				var element = filter.elementFn(scope);
				var result = filterFilter([element], expression).length == 0;
				if (scope && scope.filter_hide != result) {
					scope.filter_hide = result;
					// scope.$parent can be null: in realization editor, we search a component using the filter, change tab to certificates and click 'x' on filter
					notifyParents && scope.$parent && (notifyParents[scope.$parent.$id] = true);
				}
				return result;
			}
			
			function getExpression(values) {
				return values;
			}
			
			function keepParent(scope) {
				var parent = scope.$parent;
				if (parent && parent[filter.parent]) {
					filter.parents[parent.$id] = parent;
					keepParent(parent);
				}
			}
			
			function propagateToParent(scope) {
				if (!scope) {
					return;
				}
					
				var filter_hide = true;
				var child = scope.$$childHead;
				do {
					filter_hide = filter_hide && child.filter_hide;
					child = child.$$nextSibling;
				} while (child);
				
				if (scope.filter_hide != filter_hide) {
					scope.filter_hide = filter_hide;
					var parent = filter.parents[scope.$parent.$id];
					propagateToParent(parent);
				}
			}
		}

	};

}

module.exports = Filter;