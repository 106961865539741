import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react";
import { fieldEditors } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FormikProps } from "formik";
import React from "react";
import { Form, CheckboxProps, Input, Radio } from "semantic-ui-react";
import { convertNumberToLetter, LevelDefinition, Score } from "./EvaluationsFillIn";
import { ProteusUtils } from "ProteusUtils";
import { FieldEditor, FieldEditorNotUsableStandAloneProps } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";

export const SCALE = "SCALE";
export const SHOW_SCORE = "SHOW_SCORE";
export const VIEW_COMPETENCE = "EDUCATION_VIEW_EVALUATION_COMPETENCE_DETAILS";
const NOT_FILLED_IN_CLASS = "EvaluationsFillIn_noneCriteria";
const NO_LEVEL = -1;

enum SourceCriterion {
    LEVEL, OWN_SCORE, NONE
}

export const evaluationCriteriaEntityDescriptor = new EntityDescriptor({ name: "EvaluationCriteria" })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
    .addFieldDescriptor({ name: "weight", type: FieldType.number })
    .addFieldDescriptor({ name: "required", type: FieldType.boolean })
    .addFieldDescriptor({ name: "score", type: SHOW_SCORE });

export const evaluationCriterionEntityDescriptor = new EntityDescriptor({ name: "EvaluationCriterion" })
    .addFieldDescriptor({ name: "levelDefinitions", type: SCALE }, new class extends FieldDescriptor {
        getLabel(getDefaultMeasurementUnitSymbol?: boolean, withMeasurementUnitSymbol?: boolean, formikProps?: FormikProps<any> | undefined): string {
            return formikProps?.values?.competence?.description;
        }
    }());

fieldEditors[SCALE] = class extends FieldEditor<any, FieldEditorNotUsableStandAloneProps, { levelDefinitionId: number, ownScore: number, ownScoreChecked: boolean }> {
    levelDefinitions: LevelDefinition[];

    constructor(props: FieldEditorNotUsableStandAloneProps) {
        super(props);
        this.state = {
            levelDefinitionId: NO_LEVEL,
            ownScore: this.props.formikProps.values.score === undefined ? 0 : (this.props.formikProps.values.score.scaleScore === 0 ? this.props.formikProps.values.score.percentage : 0),
            ownScoreChecked: this.props.formikProps.values.score === undefined ? false : (this.props.formikProps.values.score.scaleScore === 0),
        }
        this.levelDefinitions = this.props.formikProps.values.levelDefinitions;
        this.onRadioChange = this.onRadioChange.bind(this);
        this.setFieldValues = this.setFieldValues.bind(this);
        this.isChecked = this.isChecked.bind(this);
    }

    getOwnScoreField() {
        return (
            <Form.Field data-testid="EvaluationsFillIn_ownScore" className="EvaluationsFillIn_ownScore" key={"ownScore"}>
                <Radio data-testid="EvaluationsFillIn_ownScoreButton"
                    name='levelDefinitionDescriptionGroup'
                    value={undefined}
                    checked={this.isChecked(undefined, 0, SourceCriterion.OWN_SCORE)}
                    onChange={this.onRadioChange}
                />
                <Input data-testid="EvaluationsFillIn_ownScoreInput"
                    type="number"
                    value={(this.state.ownScore)}
                    onChange={(e: any, { value }: any) => {
                        let ownScore = value < 0 ? 0 : (value > 100 ? 100 : value);
                        this.setState({ ownScore: Number(ownScore) });
                        this.setFieldValues(ownScore, 0, false);
                    }}
                />
                %
            </Form.Field>
        );
    }

    onRadioChange(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) {
        this.setState({ levelDefinitionId: data.value as number, ownScoreChecked: (data.value === undefined) });

        const id = data.value as number;
        if (id) {
            const levelDefinition = this.props.formikProps.values.levelDefinitions.filter((levelDefinition: LevelDefinition) => levelDefinition.id === id)[0] as LevelDefinition;
            this.setFieldValues(levelDefinition.percentage, levelDefinition.scale, true);
        } else {
            if (event.currentTarget?.className.includes(NOT_FILLED_IN_CLASS)) {
                this.setFieldValues(undefined, undefined, true);
            } else {
                this.setFieldValues(this.state.ownScore, 0, true);
            }
        }
    }

    formatLabel(levelDefinition: LevelDefinition) {
        if (ProteusUtils.checkPermission(VIEW_COMPETENCE)) {
            return (<label>
                <b>{`${convertNumberToLetter(levelDefinition.scale)}. `}</b>
                {levelDefinition.description}
                <b><i>{` ${levelDefinition.percentage}%`}</i></b>
            </label>);
        }
        return levelDefinition.description;
    }

    findLevelDefinitionByScale(scale: number | null) {
        if (scale === null) {
            return NO_LEVEL;
        }
        for (let i = 0; i < this.levelDefinitions.length; i++) {
            if (this.levelDefinitions[i].scale === scale) {
                return this.levelDefinitions[i].id;
            }
        }
    }

    isChecked(levelDefinitionId: number | undefined, levelDefinitionScale: number | null, source: SourceCriterion): boolean {
        const score = this.props.formikProps.values.score as Score;
        if (!score) {
            return false;
        }

        const actualLevelDefinitionId = this.state.levelDefinitionId !== NO_LEVEL ? this.state.levelDefinitionId : this.findLevelDefinitionByScale(score.scaleScore);
        const levelChecked = (!!levelDefinitionId && actualLevelDefinitionId === levelDefinitionId) || (!!levelDefinitionScale && levelDefinitionScale !== 0 && !!score.scaleScore && score.scaleScore !== 0 && score.scaleScore === levelDefinitionScale);
        if (source === SourceCriterion.OWN_SCORE) {
            return (score.percentage !== null && score.percentage !== undefined) && (!actualLevelDefinitionId || actualLevelDefinitionId === NO_LEVEL); 
        } else if (source === SourceCriterion.NONE) {
            return (score.percentage === null || score.percentage === undefined) && (!actualLevelDefinitionId || actualLevelDefinitionId === NO_LEVEL);
        }
        return levelChecked;
    }

    setFieldValues(percentage: any, scale: number | undefined, fromRadioChange: boolean) {
        if (this.state.ownScoreChecked || fromRadioChange) {
            this.props.formikProps.setFieldValue('score.percentage', (percentage === "" ? undefined : percentage));
            this.props.formikProps.setFieldValue('score.scaleScore', scale);
            this.props.formikProps.setFieldValue('score.evaluationCriteriumVersion', this.props.formikProps.values.evaluationCriterionVersion);
        }
    }

    render() {
        if (!this.levelDefinitions) {
            return <></>;
        }
        return (
            <Form data-testid="EvaluationsFillIn_competenceForm">
                <div data-testid="EvaluationsFillIn_levelDefinitions">
                    {this.levelDefinitions.map((levelDefinition: LevelDefinition) =>
                        <Form.Field key={levelDefinition.id}>
                            <Radio
                                label={this.formatLabel(levelDefinition)}
                                name='levelDefinitionDescriptionGroup'
                                value={levelDefinition.id}
                                checked={this.isChecked(levelDefinition.id, levelDefinition.scale, SourceCriterion.LEVEL)}
                                onChange={this.onRadioChange}
                            />
                        </Form.Field>
                    )}
                </div>
                {this.props.formikProps.values.ownScore && this.getOwnScoreField()}
                {!this.props.formikProps.values.required &&
                    <div>
                        <Form.Field key={"EvaluationsFillIn_noneCriteria"}>
                            <Radio
                                className={NOT_FILLED_IN_CLASS}
                                label={_msg("EvaluationsFillIn.criteria.none.label")}
                                name='criteriaNotFilledIn'
                                checked={this.isChecked(undefined, null, SourceCriterion.NONE)}
                                onChange={this.onRadioChange}
                            />
                        </Form.Field>
                    </div>
                }
            </Form>
        );
    }
}