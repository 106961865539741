'use strict';

/**
 * Options: {
 * 	data: [],
 * 	columnDefs: [ { name, field, displayName }]
 *  onRegisterApi: function(gridApi)
 * }
 * 
 * Optional for a columnDef: headerTemplateUrl, cellTemplateUrl, labelFn, hideHeader, showFilter, sortable
 */

/*@ngInject*/
function proteusGrid($compile, Uid ,SCROLLBAR_WIDTH, Flags) {

	return {
		restrict: 'E',
		scope: {
			options: '='
		},
 		template: require('./grid.html'),
		controllerAs: 'grid',
		controller: /*@ngInject*/ function($scope) {
			var grid = this;
			grid.SCROLLBAR_WIDTH = SCROLLBAR_WIDTH;
			grid.appScope = $scope.$parent;
			grid.options = $scope.options || {};
			grid.api = {
				core: {
					computeRows: function(data) {
						grid.zebra();

						var done = false;
						angular.forEach(grid.api, function(f) {
							if (f.dataChanged) {
								done |= f.dataChanged(data);
							}
						});

						if (done) {
							// data was processed by one of the grid features
							return;
						}

						var rows = [];
						angular.forEach(data, function(entity) {
							rows.push({
								entity: entity,
								uid: Uid.next()
							});
						});
						grid.rows = rows;
					}
				}
			};
			
			var anyColumnHasFilters = false;
			if ($scope.options) {
				for (var i = 0; i < $scope.options.columnDefs.length; i++) {
					var col = $scope.options.columnDefs[i];
					if (col.showFilter) {
						anyColumnHasFilters = true;
						break;
					}
					if (col.sortable) {
						col.sortState = 'UNSORTED';
					}
				}
			}
			
			/**
			 * TODO: calling a function like checkAllRowFilters from ng-show is performance sensitive. Instead ng-show should just 
			 * check a flag. The code should be refactored to use filter.directive.js or use an approach like there.
			 */
			grid.checkAllRowFilters = function (entity) {
				var matches = true;
				if (anyColumnHasFilters) {									
					for (var i = 0; i < $scope.options.columnDefs.length; i++) {
						var col = $scope.options.columnDefs[i];
						matches = matches && filterColumn(entity, col);
					}
				}
				return matches;
			};
			
			function filterColumn(entity, col) {
				if (!col.showFilter || !col.$filterValue) {
					return true;
				}
				var fieldValue = getFieldValue(entity, col.field);
				var filterValue = col.$filterValue.toLowerCase();
				if (fieldValue && fieldValue.toLowerCase().indexOf(filterValue) >= 0) {
					return true;
				}
				return false;
			}			

			function getFieldValue(object, path) {
				var segments = path.split(".");
				var value = object;
				
				for (var i = 0; i < segments.length; i++) {
					if (!value) {
						break;
					}
					value = value[segments[i]];
				}
				return value;
			}

			
			grid.sortExpression = [];
			grid.sort = function(col, direction) {
				if (direction) {
					col.sortState = direction;
				} else if (col.sortState =='UNSORTED') {
					col.sortState = 'ASC';
				} else if (col.sortState == 'ASC') {
					col.sortState = 'DESC';
				} else if (col.sortState =='DESC') {
					col.sortState ='UNSORTED';
				}

				
				var sortExpression = [];
				for (var i = 0; i < grid.options.columnDefs.length; i++) {
					var column = grid.options.columnDefs[i];
					if (column.name != col.name) {
						column.sortState = 'UNSORTED';
						continue;
					}
					if (column.sortState == 'ASC') {
						sortExpression.push('+entity.' + column.field);
					} else if (column.sortState == 'DESC') {
						sortExpression.push('-entity.' + column.field);
					}
				}
				grid.sortExpression = sortExpression;
				grid.zebra();
			};
			
			grid.api.core.sort = grid.sort;

			var dataChangeListeners = [];

			$scope.$watchCollection('options.data', function(data) {
				grid.api.core.computeRows(data);
			});

			$scope.$watchCollection('options.columnDefs', function(columnDefs) {
				angular.forEach(grid.api, function(f) {
					if (f.columnDefsChanged) {
						f.columnDefsChanged(columnDefs);
					}
				});
			});

			$scope.$on('proteus.visible', function() {
				grid.zebra();
			});
		},
		link: function($scope, $elt) {
			/**
			 * For performance reasons, we are using ng-show instead of ng-if, which would take 
			 * a lot of time for larger data. This breaks the zebra striping which relies on :nth-child(),
			 * so we have to do it here, using the jquery selector :visible.
			 */
			$scope.grid.zebra = function() {
				window.requestAnimationFrame(function() {
					var odd = true;
					$($elt).find('.proteus-grid-row:visible').each(function() {
						odd ? $(this).addClass('odd') : $(this).removeClass('odd');
						odd = !odd;
					});
				});
			};

			if ($scope.grid.options.onRegisterApi) {
				$scope.grid.options.onRegisterApi($scope.grid.api);
			}
		}
	};

}

module.exports = proteusGrid;