import { apolloClient, ApolloContext, CatchedGraphQLError, createSliceFoundation, EntityDescriptor, EntityEditorFormSimple, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import React, { ReactNode } from "react";
import { Button, Segment } from "semantic-ui-react";
import { Drawer } from "antd";
import { ProteusUtils } from "ProteusUtils";
import _ from "lodash";
import { PlanningShipItem, Ship, Spare } from "pages/planningPage/planningGantt/PlanningGantt";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { ProteusConstants } from "ProteusConstants";
import { EMPLOYEE_PLANNING_SERVICE_GET_AVAILABLE_SPARES, EMPLOYEE_PLANNING_SERVICE_REPLACE_EMPLOYEE_ON_SHIFT, EMPLOYEE_PLANNING_SERVICE_UPDATE_REGISTRATION_STATUS } from "graphql/queries";
import moment from "moment";
import { SpareFieldEditor } from "./customFieldRenderersEditor";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import Interweave from "interweave";
import { getAvailableSpares_employeePlanningServiceImpl_availableSpares } from "apollo-gen/getAvailableSpares";
import DropdownFieldEditor from "@crispico/foundation-react/entity_crud/fieldEditors/DropdownFieldEditor";
import { SelectExtOption } from "@crispico/foundation-react/components/selectExt/SelectExt";
import {  NOT_WORKING, TO_PLAN } from "pages/planningPage/planningGantt/itemRenderer/ShipWorkPeriodItemRenderer";

const EFFECTIEF = "EFFECTIEF";
const EXCHANGED = "GEWISSELD";
const SPARE_ITEM = "Spare";
const PLANNED = "GEPLAND";

export const sliceShipRosterEditor = createSliceFoundation(class SliceShipRosterEditor {

    initialState = {
        entityHasBeenModified: false as boolean,
        availableSpares: [] as getAvailableSpares_employeePlanningServiceImpl_availableSpares[],
        initialSpareId: undefined as number | undefined,
        newSpareId: undefined as number | undefined,
        newStatus: undefined as string | undefined,
        showModal: { open: false as boolean, message: "" as string, showNoButton: true as boolean }
    }

    nestedSlices = {
    }

    reducers = {
        ...getBaseReducers<SliceShipRosterEditor>(this)
    }

    impures = {
        ...getBaseImpures<SliceShipRosterEditor>(this),

        async changeStatus(registrationId: number) {
            await apolloClient.mutate({
                mutation: EMPLOYEE_PLANNING_SERVICE_UPDATE_REGISTRATION_STATUS,
                variables: {
                    registrationId: registrationId,
                    statusCode: this.getState().newStatus
                }
            });
        },

        async replace(newReplacementRegistration: any, oldReplacementRegistrationId: number | null, type: string, category: string, ship: any, context: any, initialEmployee: number) {
            const absenceRemovalRequestRegistrationId = this.getState().availableSpares.find(spare => spare.id === this.getState().newSpareId)?.absenceRemovalRequestId;
            await apolloClient.mutate({
                mutation: EMPLOYEE_PLANNING_SERVICE_REPLACE_EMPLOYEE_ON_SHIFT,
                variables: {
                    newReplacementRegistration,
                    oldReplacementRegistrationId,
                    absenceRemovalRequestRegistrationId,
                    type,
                    category,
                    ship,
                    context,
                    initialEmployee
                },
                context: {
                    [ApolloContext.ON_ERROR_HANDLER]: ProteusUtils.getApolloErrorHandlerForValidationException(ProteusConstants.PLANNING_IGNORE_VALIDATION_ERRORS, (e: CatchedGraphQLError) => {
                        this.getDispatchers().setInReduxState({
                            showModal: {
                                open: true,
                                message: e.graphQLErrors?.[0]?.extensions?.ORIGINAL_EXCEPTION_MESSAGE,
                                showNoButton: true
                            }
                        });
                    })
                }
            },
            );
        },

        async getAvailableSpares(planningShipItem: PlanningShipItem) {
            const type = planningShipItem.rosterRegistration.type.code;
            if (type === ProteusConstants.SPARE_REGISTRATION_TYPE) {
                return;
            }
            const result: getAvailableSpares_employeePlanningServiceImpl_availableSpares[] = (await apolloClient.query({
                query: EMPLOYEE_PLANNING_SERVICE_GET_AVAILABLE_SPARES,
                variables: {
                    shiftCode: type,
                    fromDate: moment(planningShipItem.rosterRegistration.fromDate).toDate(),
                    toDate: moment(planningShipItem.rosterRegistration.toDate).toDate(),
                    shiftsFromDate: moment(planningShipItem.startDateOfShifts).toDate(),
                    shiftsToDate: moment(planningShipItem.endDateOfShifts).toDate(),
                    category: planningShipItem.rosterRegistration.categoryCode
                }
            })).data.employeePlanningServiceImpl_availableSpares;
            this.getDispatchers().setInReduxState({ availableSpares: result, initialSpareId: planningShipItem.spare });
        }
    }
});

type PropsNotFromState = {
    onDrawerClose: (shouldReloadData?: boolean) => void;
    planningShipItem: PlanningShipItem,
    shipRosterParent: Spare | Ship,
    groupCode: string
}

type Props = PropsFrom<typeof sliceShipRosterEditor> & PropsNotFromState;

export class ShipRosterEditor extends React.Component<Props> {

    isSpare() {
        return this.props.shipRosterParent.objectType === SPARE_ITEM;
    }

    getHeaderContent(): ReactNode {
        return (
            <>
                <div>{this.props.shipRosterParent.description}</div>
                {!this.isSpare() && <div>{this.props.planningShipItem.rosterRegistration.type.name}</div>}
            </>
        );
    }

    getNewReplacementRegistration() {
        return {
            resource: this.props.newSpareId,
            fromDate: moment(this.props.planningShipItem.rosterRegistration.fromDate).toDate(),
            toDate: moment(this.props.planningShipItem.rosterRegistration.toDate).toDate()
        };
    }

    getRegistrationContext(retry?: boolean) {
        return {
            removeWaiting: false,
            split: false,
            validationExceptionsForWaitingRegistrations: false,
            errorPolicy: {
                policy: retry ? ProteusConstants.IGNORE_WARNINGS : ProteusConstants.ENFORCE_ALL
            },
            processingWaitingRegistrations: false,
            trainingRegistrationUpdated: false,
            updatedFromSessions: false,
            useDefaultMetadataRounding: false,
            metadataContext: ProteusConstants.PLANNING,
            skipWorkflow: false
        }
    }

    getShip() {
        return {
            ...this.props.shipRosterParent,
            creationDate: moment(this.props.shipRosterParent.creationDate).toDate(),
            modificationDate: moment(this.props.shipRosterParent.modificationDate).toDate()
        };
    }

    async onSaveButtonClick(retry?: boolean) {
        if (this.props.newSpareId) {
            const registration = this.getNewReplacementRegistration();
            const type = this.props.planningShipItem.rosterRegistration.type.code === ProteusConstants.EARLY_SHIFT ? ProteusConstants.REPLACEMENT_EARLY : ProteusConstants.REPLACEMENT_LATE;
            const context = this.getRegistrationContext(retry);
            await this.props.dispatchers.replace(registration, this.props.planningShipItem.replacementRegistrationId, type, this.props.planningShipItem.rosterRegistration.categoryCode || '', this.getShip(), context, this.props.planningShipItem.resource);
        } else if (this.props.newStatus) {
            await this.props.dispatchers.changeStatus(this.props.planningShipItem.rosterRegistrationId);
        }
        this.onClose(true);
    }

    renderSaveSegment() {
        return <Segment>
            <Button primary disabled={!this.props.entityHasBeenModified} onClick={async () => await this.onSaveButtonClick()}>
                {_msg("general.save")}
            </Button>
        </Segment>
    }

    protected showModalInfoMessage() {
        return (
            <ModalExt header={_msg("warning.label")} content={<Interweave content={this.props.showModal?.message} />} actions={[
                <Button key="cancel" onClick={() => this.props.dispatchers.setInReduxState({ showModal: undefined })}>{_msg("general.cancel")}</Button>,
                <Button key="ok" primary onClick={async () => {
                    this.onSaveButtonClick(true);
                    await this.onClose(true);
                    this.props.dispatchers.setInReduxState({ showModal: undefined });
                }}>{_msg("general.ok")}</Button>
            ]} onClose={() => this.props.dispatchers.setInReduxState({ showModal: undefined })}
                open={this.props.showModal?.open === true} size='small' severity={Severity.WARNING}>
            </ModalExt>
        );
    }

    getBaseEntity() {
        return {
            shipRoster: {
                ...this.props.planningShipItem
            }
        }
    }

    protected getRosterRegistrationDescriptor() {
        const that = this;
        let descriptor = new EntityDescriptor({ name: "ShipRosterEditor" }, false)
            .addFieldDescriptor({
                name: "shipRoster.resourceName",
                type: FieldType.string,
                enabled: false
            })
            .addFieldDescriptor({
                name: "shipRoster.status",
                type: FieldType.dropdown,
                enabled: this.props.planningShipItem.status === TO_PLAN,
                fieldDescriptorSettings: { fieldIntervals: [{ label: this.props.planningShipItem.status, from: this.props.planningShipItem.status }, { label: EFFECTIEF, from: ProteusConstants.EFFECTIEF }, { label: NOT_WORKING, from: ProteusConstants.NOT_WORKING_STATUS_CODE }] }
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        onChange: (status: SelectExtOption) => that.onStatusChange(status),
                        isClearable: false
                    }
                    return React.createElement(DropdownFieldEditor as any, newProps as FieldEditorProps);
                }
            });
        if (this.props.planningShipItem.rosterRegistration.type.code !== ProteusConstants.SPARE_REGISTRATION_TYPE) {
            descriptor.addFieldDescriptor({
                name: "shipRoster.spare",
                type: "EmployeeSnapshot",
                enabled: this.canReplace(),
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        spares: that.props.availableSpares,
                        onChange: (spare: any) => that.onSpareChange(spare),
                        onFocus: () => that.props.availableSpares.length <= 1 && that.props.dispatchers.getAvailableSpares(that.props.planningShipItem)
                    }
                    return React.createElement(SpareFieldEditor as any, newProps as FieldEditorProps);
                }
            })
            .addFieldDescriptor({
                name: "shipRoster.spareStatus",
                type: FieldType.string,
                enabled: false
            });
        }
        descriptor.addFieldDescriptor({
            name: "shipRoster.rosterRegistration.fromDate",
            type: FieldType.date,
            enabled: false,
            additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, {
                format: ProteusConstants.DATE_TIME_FORMAT,
                allowClear: false
            })
        })
            .addFieldDescriptor({
                name: "shipRoster.rosterRegistration.toDate",
                type: FieldType.date,
                enabled: false,
                additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, {
                    format: ProteusConstants.DATE_TIME_FORMAT,
                    allowClear: false
                })
            });

        return descriptor;
    }

    canReplace() {
        return (this.props.planningShipItem.status && TO_PLAN !== this.props.planningShipItem.status && EXCHANGED !== this.props.planningShipItem.spareStatus) || !this.props.planningShipItem.resource; 
    }

    onSpareChange(spare: getAvailableSpares_employeePlanningServiceImpl_availableSpares) {
        const isNewSpare = spare.id != this.props.initialSpareId;
        this.props.dispatchers.setInReduxState({ entityHasBeenModified: isNewSpare, newSpareId: isNewSpare ? spare.id! : undefined });
    }

    onStatusChange(status: SelectExtOption) {
        const isNewStatus = status.value !== TO_PLAN;
        this.props.dispatchers.setInReduxState({ entityHasBeenModified: isNewStatus, newStatus: isNewStatus ? status.value : undefined });
    }

    renderRosterForm() {
        return <div className="RegistrationEditor_registrationTab flex">
            <EntityEditorFormSimple hideButtonBar entityDescriptor={this.getRosterRegistrationDescriptor()} entity={this.getBaseEntity()} />
        </div>
    }

    onClose(shouldReloadData?: boolean) {
        this.props.dispatchers.setInReduxState({ availableSpares: [], initialSpareId: undefined, newSpareId: undefined, entityHasBeenModified: false, newStatus: undefined });
        this.props.onDrawerClose(shouldReloadData);
    }

    render() {
        return (
            <div>
                <Drawer destroyOnClose placement="right" visible={true} className="ShipRosterEditor_drawer"
                    closeIcon={<Button icon="close" className="less-padding" circular />} onClose={() => this.onClose()}>
                    {ProteusUtils.renderDrawerHeader(this.getHeaderContent(), "ShipRosterEditor_drawerTitle")}
                    <div className="ShipRosterEditor_content">
                        {(this.props.planningShipItem.rosterRegistration.type.code !== ProteusConstants.SPARE_REGISTRATION_TYPE || this.props.planningShipItem.status === TO_PLAN) && this.renderSaveSegment()}
                        {this.renderRosterForm()}
                    </div>
                </Drawer >
            </div>
        );
    }

    componentDidMount(): void {
        if (!this.props.planningShipItem.spare) { return; }
        const availableSpares = [];
        availableSpares.push({
            id: this.props.planningShipItem.spare,
            fullName: this.props.planningShipItem.spareName,
            shift: this.props.planningShipItem.hasExchange ? this.props.planningShipItem.rosterRegistration.type.code : this.props.planningShipItem.rosterRegistration.type.code === ProteusConstants.EARLY_SHIFT ? ProteusConstants.REPLACEMENT_EARLY : ProteusConstants.REPLACEMENT_LATE,
            status: this.props.planningShipItem.spareStatus || this.props.planningShipItem.status === EXCHANGED ? EXCHANGED : PLANNED,
            ship: this.props.shipRosterParent.code,
            absenceRemovalRequestId: null
        });
        this.props.dispatchers.setInReduxState({ availableSpares });
    }

}