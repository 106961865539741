import { createSliceFoundation, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react";
import { UploadFile } from "antd/lib/upload/interface";
import { CatalogItemVersion } from "../Catalog";
import _ from "lodash";
import React from "react";
import { Checkbox, Form, Grid, GridColumn, GridRow, Input, Label, MenuItem, Tab, TabProps, TextArea } from "semantic-ui-react";
import { AttachmentEntity, RegistrationAndEducationFileUploadButton, sliceRegistrationAndEducationFileUploadButton } from "components/uploadButton/RegistrationAndEducationFileUploadButton";

const CATALOG = "CATALOG";
const CLASS = "com.brabo.training.core.catalog.domain.CatalogAttachment";

export class SliceCatalogItemEditor {
    initialState = {
        dirty: false,
        // we need a default item, which is the one that the editor was initialized with to be able to check if the editor is "dirty"
        defaultCatalogItemVersion: {} as CatalogItemVersion,
        catalogItemVersion: {} as CatalogItemVersion,
        createNewMajorVersion: false,
        isOldVersionSelected: false,
        activeIndex: 0 as number | string | undefined
    };

    nestedSlices = {
        attachmentUpload: sliceRegistrationAndEducationFileUploadButton,
    };

    reducers = {
        ...getBaseReducers<SliceCatalogItemEditor>(this),

        setCatalogItemVersion(state: StateFrom<SliceCatalogItemEditor>, catalogItemVersion: CatalogItemVersion) {
            state.catalogItemVersion = catalogItemVersion;
            state.defaultCatalogItemVersion = catalogItemVersion;
        },
    };
}

export const sliceCatalogItemEditor = createSliceFoundation(SliceCatalogItemEditor);

type Props = PropsFrom<typeof sliceCatalogItemEditor>;

/**
 * This is the base editor that only has General and Attachments tabs
 * The other editors should extend this one and implement other tabs
 */
export class CatalogItemEditor<T extends Props = Props> extends React.Component<T> {
    protected onRemoveFile = (file: UploadFile) => {
        const remainingFiles = this.props.catalogItemVersion.attachments?.filter((item: AttachmentEntity | null) =>
            item?.id !== Number(file.uid));
        this.setAttachments(remainingFiles);
    };

    componentDidUpdate(prevProps: Props) {
        if (prevProps.createNewMajorVersion !== this.props.createNewMajorVersion) {
            this.update(this.props.catalogItemVersion);
        }
    }

    update(catalogItemVersion: CatalogItemVersion) {
        const dirty = this.isDirty(catalogItemVersion);
        this.props.dispatchers.setInReduxState({ catalogItemVersion, dirty });
    }

    isDirty(catalogItemVersion: CatalogItemVersion) {
        return !_.isEqual(catalogItemVersion, this.props.defaultCatalogItemVersion) || this.props.createNewMajorVersion;
    }

    setAttachments = (attachments: AttachmentEntity[]) => {
        let catalogItemVersion = { ...this.props.catalogItemVersion, attachments: attachments };
        this.props.dispatchers.setInReduxState({ catalogItemVersion });
        this.update(catalogItemVersion);
    };

    renderAdditionalContent() {
        return <></>;
    }

    getGeneralTabContent() {
        return <Tab.Pane className="flex-grow">
            <Form>
                <Grid className="flex flex-grow">
                    <GridRow columns={1} stretched>
                        <GridColumn className="flex" width={16}>
                            {_msg("CatalogItemEditor.name.label")}
                            <Input value={this.props.catalogItemVersion.name} onChange={(event) => {
                                let catalogItemVersion = { ...this.props.catalogItemVersion, name: event.target.value };
                                this.update(catalogItemVersion);
                            }} disabled={this.props.isOldVersionSelected}>
                            </Input>
                        </GridColumn>
                    </GridRow>
                    <GridRow columns={2} stretched>
                        <GridColumn className="flex" width={8}>
                            {_msg("CatalogItemEditor.version.label")}
                            <Input value={this.props.catalogItemVersion?.majorVersion ? `${this.props.catalogItemVersion?.majorVersion}.${this.props.catalogItemVersion?.minorVersion}` : ''} disabled></Input>
                        </GridColumn>
                        <GridColumn className="flex" width={8} stretched verticalAlign="middle">
                            {_msg("Catalog.forceNewVersion.label")}
                            <Checkbox toggle onChange={() => {
                                let createNewMajorVersionChanged = !this.props.createNewMajorVersion;
                                this.props.dispatchers.setInReduxState({ createNewMajorVersion: createNewMajorVersionChanged });
                            }} disabled={this.props.isOldVersionSelected}></Checkbox>
                        </GridColumn>
                    </GridRow>
                    <GridRow columns={1} stretched>
                        <GridColumn className="flex" width={16} stretched verticalAlign="middle">
                            {_msg("CatalogItemEditor.active.label")}
                            <Checkbox toggle onChange={() => {
                                let catalogItemVersion = { ...this.props.catalogItemVersion, active: !this.props.catalogItemVersion.active };
                                this.update(catalogItemVersion);
                            }}
                                checked={this.props.catalogItemVersion.active !== undefined && this.props.catalogItemVersion.active === true} />
                        </GridColumn>
                    </GridRow>
                    <GridRow columns={1} stretched>
                        <GridColumn className="flex" width={16} stretched verticalAlign="middle">
                            {_msg("CatalogItemEditor.description.label")}
                            <TextArea value={this.props.catalogItemVersion.description === null ? "" : this.props.catalogItemVersion.description} onChange={(event) => {
                                let catalogItemVersion = { ...this.props.catalogItemVersion, description: event.target.value };
                                this.update(catalogItemVersion);
                            }} disabled={this.props.isOldVersionSelected}></TextArea>
                        </GridColumn>
                    </GridRow>
                    {this.renderAdditionalContent()}
                </Grid>
            </Form>
        </Tab.Pane>;
    };

    getGeneralTabMenuItem() {
        return <MenuItem key="general">
            {_msg("Catalog.generalTab.label")}
        </MenuItem>;
    };

    getGeneralTab() {
        return {
            menuItem: this.getGeneralTabMenuItem(),
            render: () => {
                return this.getGeneralTabContent();
            }
        };
    };

    getAttachmentsTab() {
        return {
            menuItem: <MenuItem key="attachments">
                {_msg("Catalog.attachmentsTab.label")}<Label>{this.props.catalogItemVersion.attachments?.length}</Label>
            </MenuItem>,
            render: () => <Tab.Pane>
                <RegistrationAndEducationFileUploadButton {...this.props.attachmentUpload} dispatchers={this.props.dispatchers.attachmentUpload}
                    attachments={this.props.catalogItemVersion.attachments} class={CLASS} onRemoveFile={this.onRemoveFile} allowEdit={!this.props.isOldVersionSelected} type={CATALOG}
                    setAttachments={this.setAttachments} />
            </Tab.Pane>
        };
    }

    getPanes = () => [
        this.getGeneralTab(),
        this.getAttachmentsTab()
    ];

    protected onTabChange(event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
        this.props.dispatchers.setInReduxState({ activeIndex: data.activeIndex });
    }

    render() {
        return (
            <Tab panes={this.getPanes()} activeIndex={this.props.activeIndex} onTabChange={(event, data) => this.onTabChange(event, data)} menu={{ stackable: true }} className="flex flex-grow" />
        );
    }
}
