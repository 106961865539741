import { apolloClient, ApolloContext, apolloGetExtensionFromError, CatchedGraphQLError, GraphQLErrorExtensions } from "@crispico/foundation-react/apolloClient";
import { createSliceFoundation, getBaseImpures } from "@crispico/foundation-react/reduxHelpers";
import { getGroupEmployeesByIds_employeeService_employees } from "apollo-gen/getGroupEmployeesByIds";
import { getGroupMembers_employeeService_employeeSnapshotsByGroup } from "apollo-gen/getGroupMembers";
import { CALENDAR_DEFINITION_SERVICE_GET_CALENDAR_DEFINITIONS, COMPOSITION_GROUP_SERVICE_GET_COMPOSITION_GROUP, COMPOSITION_GROUP_SERVICE_SAVE_OR_UPDATE_COMPOSITION_GROUP, DYNAMIC_GROUP_SERVICE_GET_DYNAMIC_GROUP, DYNAMIC_GROUP_SERVICE_SAVE_OR_UPDATE_DYNAMIC_GROUP, EMPLOYEE_SERVICE_GET_EMPLOYEES, EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_GROUP, GROUP_SERVICE_FACADE_BEAN_GET_GROUP, GROUP_SERVICE_FACADE_BEAN_GET_GROUP_CONTEXT, GROUP_SERVICE_FACADE_BEAN_GET_PARENT_GROUPS, RESOURCES_SERVICE_FACADE_BEAN_GET_GROUP_RELATIONS_OR_NULL, STATIC_GROUP_SERVICE_GET_STATIC_GROUP, STATIC_GROUP_SERVICE_SAVE_OR_UPDATE_STATIC_GROUP } from "graphql/queries";
import { ProteusConstants } from "ProteusConstants";

export const sliceGroupsManagementBase = createSliceFoundation(class SliceGroupsManagementBase {

    impures = {
        ...getBaseImpures<SliceGroupsManagementBase>(this),

        async getStaticGroup(groupId: number) {
            const group = (await apolloClient.query({
                query: STATIC_GROUP_SERVICE_GET_STATIC_GROUP,
                variables: {
                    id: groupId
                }
            })).data.staticGroupService_staticGroup;
            return await this.adaptGroupConstraintDefinition(group);
        },

        async getDynamicGroup(groupId: number) {
            const group = (await apolloClient.query({
                query: DYNAMIC_GROUP_SERVICE_GET_DYNAMIC_GROUP,
                variables: {
                    id: groupId
                }
            })).data.dynamicGroupService_dynamicGroup;
            return await this.adaptGroupConstraintDefinition(group);
        },

        async getCompositionGroup(groupId: number) {
            const group = (await apolloClient.query({
                query: COMPOSITION_GROUP_SERVICE_GET_COMPOSITION_GROUP,
                variables: {
                    id: groupId
                }
            })).data.compositionGroupService_compositionGroup;
            return await this.adaptGroupConstraintDefinition(group);
        },

        async getGroup(groupId: number) {
            return (await apolloClient.query({
                query: GROUP_SERVICE_FACADE_BEAN_GET_GROUP,
                variables: {
                    id: groupId
                }
            })).data.groupServiceFacadeBean_group;
        },

        async getGroupRelations(groupId: number) {
            return (await apolloClient.query({
                query: RESOURCES_SERVICE_FACADE_BEAN_GET_GROUP_RELATIONS_OR_NULL,
                variables: {
                    groupIds: groupId,
                    relationType: null
                }
            })).data.resourcesServiceFacadeBean_groupRelationsOrNull;
        },

        async getParentGroups(childId: number) {
            return (await apolloClient.query({
                query: GROUP_SERVICE_FACADE_BEAN_GET_PARENT_GROUPS,
                variables: {
                    child: childId
                }
            })).data.groupServiceFacadeBean_parentGroups;
        },

        async getGroupContext(groupContextCode: string) {
            return (await apolloClient.query({
                query: GROUP_SERVICE_FACADE_BEAN_GET_GROUP_CONTEXT,
                variables: {
                    code: groupContextCode
                }
            })).data.groupServiceFacadeBean_groupContext;
        },

        async getGroupByType(groupId: number, groupType: string) {
            if (groupType === ProteusConstants.STATIC_GROUP_CODE || groupType === ProteusConstants.STATIC_GROUP) {
                return await this.getStaticGroup(groupId);
            } else if (groupType === ProteusConstants.DYNAMIC_GROUP_CODE || groupType === ProteusConstants.DYNAMIC_GROUP) {
                return await this.getDynamicGroup(groupId);
            } else if (groupType === ProteusConstants.COMPOSITION_GROUP_CODE || groupType === ProteusConstants.COMPOSITION_GROUP) {
                return await this.getCompositionGroup(groupId);
            }
        },

        getGroupMutationByType(groupType: string) {
            if (groupType === ProteusConstants.STATIC_GROUP || groupType === ProteusConstants.STATIC_GROUP_CODE) {
                return STATIC_GROUP_SERVICE_SAVE_OR_UPDATE_STATIC_GROUP;
            } else if (groupType === ProteusConstants.DYNAMIC_GROUP || groupType === ProteusConstants.DYNAMIC_GROUP_CODE) {
                return DYNAMIC_GROUP_SERVICE_SAVE_OR_UPDATE_DYNAMIC_GROUP;
            } else if (groupType === ProteusConstants.COMPOSITION_GROUP || groupType === ProteusConstants.COMPOSITION_GROUP_CODE) {
                return COMPOSITION_GROUP_SERVICE_SAVE_OR_UPDATE_COMPOSITION_GROUP;
            }
            return STATIC_GROUP_SERVICE_SAVE_OR_UPDATE_STATIC_GROUP;
        },

        getMutationContext() {
            return ({
                [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                    if (ProteusConstants.VALIDATION_EXCEPTION === apolloGetExtensionFromError(e, GraphQLErrorExtensions.EXCEPTION_SIMPLE_NAME)) {
                        return true;
                    }
                }
            });
        },

        async adaptGroupConstraintDefinition(group: any) {
            if (group.constraintDefinitions.length === 0) {
                group = { ...group, constraintDefinitions: null };
            } else {
                // get all calendarDefinition ids from the current group and load the corresponding calendarDefinition objects from db
                let calendarDefinitionIds = [];
                for (let i = 0; i < group.constraintDefinitions.length; i++) {
                    for (let j = 0; j < group.constraintDefinitions[i].history.length; j++) {
                        calendarDefinitionIds.push(group.constraintDefinitions[i].history[j].calendar);
                    }
                }
                if (calendarDefinitionIds.length > 0) {
                    const calendarDefinitions = (await apolloClient.query({
                        query: CALENDAR_DEFINITION_SERVICE_GET_CALENDAR_DEFINITIONS,
                        variables: {
                            ids: calendarDefinitionIds
                        }
                    })).data.calendarDefinitionService_calendarDefinitions;

                    // replace all calendarDefinitions ids from selected group with its corresponding objects
                    let constraintDefinitions = [];
                    for (let i = 0; i < group.constraintDefinitions.length; i++) {
                        let history = [];
                        for (let j = 0; j < group.constraintDefinitions[i].history.length; j++) {
                            let newCalendar = calendarDefinitions.filter((item: any) => item.id === group.constraintDefinitions[i].history[j].calendar)[0];
                            history.push({ ...group.constraintDefinitions[i].history[j], calendar: newCalendar });
                        }
                        constraintDefinitions.push({ ...group.constraintDefinitions[i], history })
                    }
                    group = { ...group, constraintDefinitions };
                }
            }
            return group;
        },

        async getGroupMembers(groupCode: string, fromDate: string | Date, toDate: string | Date) {
            const groupMembers: getGroupMembers_employeeService_employeeSnapshotsByGroup[] = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_GROUP,
                variables: {
                    groupCode,
                    fromDate,
                    toDate
                }
            })).data.employeeService_employeeSnapshotsByGroup;
            return groupMembers;
        },

        async getResourcesEmployees(employeesIds: number[]) {
            const employees: getGroupEmployeesByIds_employeeService_employees[] = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_EMPLOYEES,
                variables: {
                    employeesIds: employeesIds
                }
            })).data.employeeService_employees;
            return employees;
        }
    }
})