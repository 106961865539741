import { ProteusConstants } from "ProteusConstants";

export const BLACK = "#000000";
export const CONSTRAINED = "CONSTRAINED";
export const INITIAL = "_INITIAL";
export const LEAVE = "LEAVE";
export const LEAVE_CALENDAR = "VERLOFKALENDER";
export const RESULT = "RESULT";
export const _RESULT = "_RESULT";
export const WHITE = '#ffffff';

export enum AGENDA_MODE {
    MODE_MONTH, MODE_OPEN_COMPARTMENTS
}

export const METADATA_PROPERTIES: string = [ProteusConstants.LAYOUT_TYPE_CODE, ProteusConstants.BACKGROUND_COLOR, ProteusConstants.TRANSPARENCY_PERCENT, ProteusConstants.CREATE_ROLE,
ProteusConstants.READ_ROLE, ProteusConstants.UPDATE_ROLE, ProteusConstants.DELETE_ROLE, ProteusConstants.ALLOW_MAP_TO_WORKPERIOD, ProteusConstants.MAP_TO_WORKPERIOD,
ProteusConstants.CONSTRAINED, ProteusConstants.ROUNDING_TYPE_CODE, ProteusConstants.MILESTONE].join(';');
