import { apolloClient, TestUtils, Utils } from "@crispico/foundation-react";
import { ModalExt, ModalExtOpen } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { OnSelectParams, RenderItemParams, Tree, PropsNotFromState as TreeProps, TreeReducers, TreeState } from "@crispico/foundation-react/components/TreeRRC/Tree";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { getRegistrationTypeTree_employeeService_registrationTypeTree } from "apollo-gen/getRegistrationTypeTree";
import { loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider } from "apollo-gen/loadMetadataProvider";
import { EMPLOYEE_SERVICE_GET_REGISTRATION_TYPE_TREE, REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_TREE_FOR_PLANNING } from "graphql/queries";
import _ from "lodash";
import { PLANNING_CURRICULUM } from "pages/employeeEditor/CurriculumTab";
import { REGISTRATION_TYPE, REGISTRATION_TYPE_CATEGORY } from "pages/registrationMetadata/RegistrationMetadata";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import React from "react";
import { Button, Checkbox, Form, Grid, Icon, Input, Menu, Popup, Segment } from "semantic-ui-react";
import { MetadataProviderHelper } from "utils/MetadataProviderHelper";

export const REGISTRATION_STATUS = "RegistrationTypeStatusRelation";
export const STATUS_INITIALLY_SELECTED = "STATUS_INITIALLY_SELECTED";
export const STATUS_SELECTABLE = "STATUS_SELECTABLE";
export const BACKGROUND_COLOR = "BACKGROUND_COLOR";
export const CATEGORY_CHILDREN = "categoryChildren";
export const TYPES = "types";
export const STATUSES = "statuses";

export enum RegistrationTypeCategoryTreeMode {
    DEFAULT, FILTER_CREATE
}

export class RegistrationTreeState extends TreeState { }
export class RegistrationTreeReducers<S extends RegistrationTreeState = RegistrationTreeState> extends TreeReducers<S> {
    protected _hasChildren(item: any) {
        if (Array.isArray(item)) {
            return true;
        }
        if (item.__typename === REGISTRATION_TYPE_CATEGORY || (item.__typename === REGISTRATION_TYPE && item.statuses && item.statuses.length !== 0)) {
            return super._hasChildren(item, {});
        } else {
            return false;
        }
    }

    protected _getChildren(item: any) {
        if (Array.isArray(item)) {
            return super._getChildren(item, {});
        }
        let children = [] as any;
        const categoryChildren = item.categoryChildren as Array<any>;
        const types = item.types as Array<any>;
        const statuses = item.statuses as Array<any>;
        if (categoryChildren && categoryChildren.length !== 0) {
            children = children.concat(categoryChildren.map((item, index) => ({ localId: CATEGORY_CHILDREN + Utils.defaultIdSeparator + index, item })));
        }
        if (types && types.length !== 0) {
            children = children.concat(types.map((item, index) => ({ localId: TYPES + Utils.defaultIdSeparator + index, item })));
        }
        if (statuses && statuses.length !== 0) {
            children = children.concat(statuses.map((item, index) => ({ localId: STATUSES + Utils.defaultIdSeparator + index, item })));
        }
        return children;
    }
}

export class RegistrationTypeCategoryState extends State {
        registrationTypeTree = [] as getRegistrationTypeTree_employeeService_registrationTypeTree[];
        filteredCreateRegistrationTypeTree = [] as any[];
        filteredRegistrationTypeTree = [] as getRegistrationTypeTree_employeeService_registrationTypeTree[];
        searchedRegistrationType = "" as string;
        appliedRegistrationTypes = [] as string[];
        initiallySelectedStatusesFromMetadata = [] as string[];
        selectedRegistrationTypes = [] as string[];
        temporarySearchedRegistrationType = "" as string;
        root = [] as any;
        openContextMenuModal = undefined as unknown as ModalExtOpen;
        treeElement = undefined as any;
        mode = RegistrationTypeCategoryTreeMode.DEFAULT as RegistrationTypeCategoryTreeMode;
}

export type RegistrationTreeProps = TreeProps & {
    renderItemFunction: Function,
    adminStatusChanged?: boolean,
    searchTextIsNull?: boolean;
} & RRCProps<RegistrationTreeState, RegistrationTreeReducers>;

export class RegistrationTreeRaw<T extends RegistrationTreeProps = RegistrationTreeProps> extends Tree<T> {
    protected renderItem(params: RenderItemParams) {
        return this.props.renderItemFunction(params);
    }
}

export class RegistrationTypeCategoryReducers<S extends RegistrationTypeCategoryState = RegistrationTypeCategoryState> extends Reducers<S> {

        setTreeRoot() {
            let state = this.s;
            if (state.registrationTypeTree.length === 0) {
                state.root = [];
            } else if (state.searchedRegistrationType !== "") {
                state.root = state.filteredRegistrationTypeTree;
            } else {
                state.root = state.registrationTypeTree;
            }
        }

        enrichTreeWithCheckedFlags(metadataProvider: loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider,
            hasStatuses: boolean, firstLoad: boolean, appliedRegistrationTypes?: string[], currentContext?: string) {
            let state = this.s;
            for (let c of state.registrationTypeTree) {
                this.enrichTreeWithCheckedFlagsReccursive(
                    c,
                    firstLoad,
                    metadataProvider,
                    true,
                    undefined,
                    undefined,
                    appliedRegistrationTypes,
                    currentContext
                );
            }
            this.setCheckedRegistrations(hasStatuses, firstLoad);
        }

        // this method is used to change state of tree. It adds "isChecked" property and updates it every time an element is checked or unchecked
        enrichTreeWithCheckedFlagsReccursive(category: any, firstLoad: boolean, metadataProvider: loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider,
            isInitialization?: boolean, isChecked?: boolean,
            getSelectedRegistrations?: boolean,
            initiallySelectedTypes?: string[], currentContext?: string
        ) {
            if (isInitialization || !getSelectedRegistrations) {
                category.isChecked = isInitialization ? false : isChecked;
            }
            if (category.categoryChildren) {
                // if the item has categoryChildren, call this method recursive
                for (let childCategory of category.categoryChildren) {
                    this.enrichTreeWithCheckedFlagsReccursive(
                        childCategory,
                        firstLoad,
                        metadataProvider,
                        isInitialization,
                        isChecked,
                        getSelectedRegistrations,
                        initiallySelectedTypes,
                        currentContext
                    );
                }
            }
            if (category.types) {
                // if the item has types, navigate through all types
                for (let type of category.types) {
                    if (isInitialization || !getSelectedRegistrations) {
                        // check if the type is in initiallySelectedTypes. If it is, type.isChecked will be true. This happens only for initialization (first render)
                        if (isInitialization && initiallySelectedTypes !== undefined && type.statuses.length === 0) {
                            type.isChecked = initiallySelectedTypes.filter((item: string) => item === type.code).length > 0 ? true : false;
                        } else {
                            type.isChecked = isInitialization ? false : isChecked;
                        }
                    }
                    if (type.statuses.length !== 0) {
                        // if the type has statuses, iterate through all statuses. For each status, check if it must be initially selected or not. 
                        // this feature is used only for tree from planning
                        for (let status of type.statuses) {
                            status.isChecked = this.checkOrUncheckStatus(status, isInitialization, getSelectedRegistrations, currentContext, isChecked, metadataProvider);
                        }
                    }
                }
            }
            if (category.statuses) {
                // if the item has statuses, iterate through all statuses. The logic is the same as above
                for (let status of category.statuses) {
                    status.isChecked = this.checkOrUncheckStatus(status, isInitialization, getSelectedRegistrations, currentContext, isChecked, metadataProvider);
                }
            }
        }

        checkOrUncheckStatus(status: any, isInitialization: boolean | undefined, getSelectedRegistrations: boolean | undefined, currentContext: string | undefined, isChecked: boolean | undefined,
            metadataProvider: loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider) {
            if (isInitialization || !getSelectedRegistrations) {
                let initialSelected: any = false;
                if (currentContext !== undefined) {
                    initialSelected = MetadataProviderHelper.getPropertyValue(metadataProvider, status?.type.code, status?.status.code, STATUS_INITIALLY_SELECTED, currentContext);
                }
                return isInitialization ? initialSelected : isChecked;
            }
        }

        checkChildren(treeElement: any, checkForType: boolean, hoveredId: string | undefined) {
            let state = this.s;
            let locationTokens = hoveredId?.split(Utils.defaultIdSeparator) as string[];
            let root: any = state.searchedRegistrationType === "" ? state.registrationTypeTree : state.filteredRegistrationTypeTree;
            let initialRoot = _.cloneDeep(root);
            let calculatedParents = [];
            for (let i = 0; i < locationTokens.length - 1; i++) {
                root = root[locationTokens[i]];
                let treeElement = state.searchedRegistrationType === "" ? root : Array.isArray(root) ? [...root] : { ...root };
                if (state.searchedRegistrationType !== "") {
                    // root can be an object (i.e. a registration category node from the tree) or an array (i.e. categoryChildren array of a node)
                    if (root.id !== undefined) {
                        // if the root is an object and the tree is filtered, find the corresponding object in original tree root
                        // because its original (not filtered) children must be considered when checking/unchecking 
                        const elementIndexInTree = this.findElementIndexInTree(state.registrationTypeTree, treeElement, "");
                        treeElement = Utils.navigate(state.registrationTypeTree, elementIndexInTree!);
                    } else {
                        // if the root is an array, find the upper node. Retrieve the upper node from the original root and get its children.
                        // example: if the tree is filtered and node A has 3 children in filtered tree, but 5 children in original tree
                        // in this case, the root.length = 3, but all 5 children must be considered when checking/unchecking node A based on root checks
                        let upperNode = _.cloneDeep(initialRoot);
                        for (let k = 0; k < i; k++) {
                            upperNode = upperNode[locationTokens[k]];
                        }
                        let upperNodeIndex = this.findElementIndexInTree(state.registrationTypeTree, upperNode, "");
                        upperNode = Utils.navigate(state.registrationTypeTree, upperNodeIndex!);
                        treeElement = upperNode[locationTokens[i]];
                    }
                }
                calculatedParents.push(treeElement);
            }
            // when we have children, we must check/uncheck them, it's not the case of root (lenght = 1, ["0"])
            if (calculatedParents.length > 1) {
                this.recursivelyParentCheck(calculatedParents, 1, checkForType, treeElement);
            }
        }

        recursivelyParentCheck(calculatedParents: any[], levelInTree: number, isChecked: boolean, currentCheckedTreeElement: any) {
            // levelInTree represents the index of an element in the tree hierarchy
            let shouldParentBeClicked = true;
            let parent = calculatedParents[calculatedParents.length - levelInTree - 1];
            let siblings = (parent.categoryChildren !== undefined ? parent.categoryChildren : [])
                .concat(parent.types !== undefined ? parent.types : [])
                .concat(parent.statuses !== undefined ? parent.statuses : []);
            for (let i = 0; i < siblings.length; i++) {
                // for the current checked object, because the checked/unchecked state has not been propagated yet, retrieve the isChecked value from the currentCheckedObject object.
                let checked = currentCheckedTreeElement.__typename === siblings[i].__typename && currentCheckedTreeElement.id === siblings[i].id ? currentCheckedTreeElement.isChecked : siblings[i].isChecked;
                if (!checked) {
                    shouldParentBeClicked = false;
                    break;
                }
            }
            // all children are checked or one child is unclicked
            if (shouldParentBeClicked || shouldParentBeClicked === isChecked) {
                levelInTree++;
                calculatedParents[calculatedParents.length - levelInTree].isChecked = isChecked;
                levelInTree++;
                if (calculatedParents.length - levelInTree >= 0) {
                    this.recursivelyParentCheck(calculatedParents, levelInTree, isChecked, currentCheckedTreeElement);
                }
            }
        }

        findElementIndexInTree(root: any, element: any, indexInTree: string): string | undefined {
            // given an element and the root of tree, we can find the element "address". The address looks like this: "0|/|categoryChildren|/|0|/|categoryChildren|/|2|/|types|/|0"
            let index = "" as string | undefined;
            for (let i = 0; i < root.length; i++) {
                // if the element is of type RegistrationTypeCategory and we found it in tree, just add its index in tree to the "address" string
                if (element.__typename === REGISTRATION_TYPE_CATEGORY && root[i].code === element.code && root[i].__typename === element.__typename) {
                    return indexInTree + i;
                }
                if (root[i].types !== undefined && root[i].types.length > 0 && (element.__typename === REGISTRATION_TYPE || element.__typename === REGISTRATION_STATUS)) {
                    // if the current element in root (root[i]) has types, navigate through all types and check if our searched element is in root[i].types
                    if (element.__typename === REGISTRATION_TYPE) {
                        for (let j = 0; j < root[i].types.length; j++) {
                            if (root[i].types[j].code === element.code) {
                                return this.getElementRouteInTree(TYPES, indexInTree, i, j);
                            }
                        }
                    } else if (element.__typename === REGISTRATION_STATUS) {
                        index = this.findElementIndexInTree(root[i].types, element, this.getElementRouteInTree(TYPES, indexInTree, i));
                        if (index !== undefined) {
                            return index;
                        }
                    }
                }
                if (element.__typename === REGISTRATION_STATUS && root[i].statuses !== undefined && root[i].statuses.length > 0) {
                    // if the current element in root (root[i]) has statuses, navigate through all statuses and check if our searched element is in root[i].statuses
                    for (let j = 0; j < root[i].statuses.length; j++) {
                        if (root[i].statuses[j].status.code === element.status.code && root[i].statuses[j].type.code === element.type.code) {
                            return this.getElementRouteInTree(STATUSES, indexInTree, i, j);
                        }
                    }
                }
                if (root[i].categoryChildren !== undefined && root[i].categoryChildren.length > 0) {
                    // if the current element in root (root[i]) has categoryChildren, call this method recursive
                    index = this.findElementIndexInTree(root[i].categoryChildren, element, this.getElementRouteInTree(CATEGORY_CHILDREN, indexInTree, i));
                    if (index !== undefined) {
                        return index;
                    }
                }
            }
            return undefined;
        }

        onCheckboxClick(treeElement: any, metadataProvider: loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider,
            hasStatuses: boolean, hoveredId?: string, isChecked?: boolean, currentContext?: string) {
            let state = this.s;
            const elementIndexInTree = this.findElementIndexInTree(state.registrationTypeTree, treeElement, "");
            let treeElementLocal = Utils.navigate(state.registrationTypeTree, elementIndexInTree!);
            treeElementLocal.isChecked = isChecked;
            this.enrichTreeWithCheckedFlagsReccursive(treeElementLocal, false, metadataProvider, false, isChecked, undefined, undefined, currentContext);
            this.checkChildren(treeElementLocal, isChecked!, hoveredId);
            this.setCheckedRegistrations(hasStatuses, false);
        }

        setCheckedRegistrations(hasStatuses: boolean, firstLoad: boolean) {
            let state = this.s;
            let selectedRegistrationTypes = this.getCheckedRegistrationsReccursive(hasStatuses, state.registrationTypeTree);
            state.selectedRegistrationTypes = selectedRegistrationTypes;
            if (firstLoad) {
                // initiallySelectedStatusesFromMetadata are used to store the selected statuses loaded from the metadata
                // they are needed to revert the selections made to the tree (when "revert" button is presses)
                state.initiallySelectedStatusesFromMetadata = selectedRegistrationTypes;
                state.appliedRegistrationTypes = selectedRegistrationTypes;
            }
        }

        getCheckedRegistrationsReccursive(hasStatuses: boolean, root: any) {
            let registrations: string[] = [];
            for (let i = 0; i < root.length; i++) {
                if (root[i].__typename === REGISTRATION_TYPE) {
                    if (!hasStatuses && root[i].isChecked) {
                        registrations = [...registrations, root[i].code];
                    } else if (hasStatuses && root[i].statuses.length > 0) {
                        registrations = registrations.concat(this.getCheckedRegistrationsReccursive(hasStatuses, root[i].statuses));
                    }
                } else if (root[i].__typename === REGISTRATION_TYPE_CATEGORY) {
                    if (root[i].types.length > 0) {
                        registrations = registrations.concat(this.getCheckedRegistrationsReccursive(hasStatuses, root[i].types));
                    }
                    if (root[i].categoryChildren.length > 0) {
                        registrations = registrations.concat(this.getCheckedRegistrationsReccursive(hasStatuses, root[i].categoryChildren));
                    }
                } else if (root[i].__typename === REGISTRATION_STATUS && root[i].isChecked && hasStatuses) {
                    registrations = [...registrations, root[i].type.code + ":" + root[i].status.code];
                }
            }
            return registrations;
        }

        filterTree(root: any, value: string) {
            let state = this.s;
            state.filteredRegistrationTypeTree = this.filterTreeRecursive(root, value);
            state.searchedRegistrationType = value;
            this.setTreeRoot();
        }

        /**
        * When searching an element in tree, we actually search for an element with __typename === REGISTRATION_TYPE
        * When an element is found, all its parents should be shown 
        */
        filterTreeRecursive(root: any, value: string) {
            let filteredTree = [] as any;
            for (let i = 0; i < root.length; i++) {
                let localElement = root[i];
                if (root[i].__typename === REGISTRATION_TYPE) {
                    if (root[i].name.toLowerCase().includes(value.toLowerCase())) {
                        filteredTree.push(root[i]);
                    }
                } else if (root[i].__typename === REGISTRATION_TYPE_CATEGORY) {
                    if (root[i].types.length > 0) {
                        let types = this.filterTreeRecursive(root[i].types, value);
                        if (types.length > 0) {
                            localElement = { ...localElement, types: types };
                        } else {
                            localElement = { ...localElement, types: [] };
                        }
                    }

                    if (root[i].categoryChildren.length > 0) {
                        let categoryChildren = this.filterTreeRecursive(root[i].categoryChildren, value);
                        if (categoryChildren.length > 0) {
                            localElement = { ...localElement, categoryChildren };
                        } else {
                            localElement = { ...localElement, categoryChildren: [] };
                        }
                    }
                    if (localElement.types.length > 0 || localElement.categoryChildren.length > 0) {
                        filteredTree.push(localElement);
                    }
                }
            }
            return filteredTree;
        }
        getElementRouteInTree(elementType: string, indexInTree: string, i: number, j?: number): string {
            return indexInTree + i + Utils.defaultIdSeparator + elementType + Utils.defaultIdSeparator + (j !== undefined ? j : "");
        }
}

export const RegistrationTree = ReduxReusableComponents.connectRRC(RegistrationTreeState, RegistrationTreeReducers, RegistrationTreeRaw);

type PropsNotFromState = {
    hasStatuses: boolean;
    hasCheckboxes: boolean;
    initiallySelectedTypes?: string[];
    appliedRegistrationTypes?: string[];
    // currentContext is needed when the tree is used from planning and when initial data must be loaded from metadata context
    // !if this parameter is not provided, the initial values for tree will not be retrieved
    currentContext?: string;
    filterWorkPeriodTypes?: boolean;
    showApplyAndRevertButtons?: boolean;
    onApplyClick?: (selectedStatuses: string[], registrationTree: any) => void;
    onRevertChangesClick?: (registrationTree: any) => void;
    onSelectItem?: (params: OnSelectParams, item: any) => void;
    onSearchChange?: (data: string) => void;
    onTreeCollapse?: () => void;
    onExpandCollapseItem?: (params: OnSelectParams) => void;
    handleDelete?: (treeElement: any) => void;
    handleEdit?: (treeElement: any) => void;
    registrationTypeTree?: any;
    filterCreateTypes?: boolean;
    filteredRegistrationTypeTree?: any;
    firstLoad?: boolean;
    renderAdditionalActionsInTreeItem?: (treeElement: any) => JSX.Element;
};

export type RegistrationTypeCategoryTreeProps = PropsNotFromState & RRCProps<RegistrationTypeCategoryState, RegistrationTypeCategoryReducers>;

export class RegistrationTypeCategoryTreeRaw extends TabbedPage<RegistrationTypeCategoryTreeProps> {
    registrationTreeRef = React.createRef<RegistrationTreeRaw>();
    /**
    * Load data for tree and set the tree root
    */
        async loadRegistrationTypeTree(getStatuses: boolean, firstLoad: boolean, reloadScreen: boolean, hasCheckboxes: boolean, initiallySelectedTypes?: string[], currentContext?: string, filterWorkPeriodTypes?: boolean) {
            let data = (await apolloClient.query({
                query: currentContext === ProteusConstants.PLANNING || currentContext === ProteusConstants.PLANNING_CURRICULUM ? REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_TREE_FOR_PLANNING : EMPLOYEE_SERVICE_GET_REGISTRATION_TYPE_TREE,
                variables: {
                    getStatuses,
                    filterVisibleStatuses: true,
                    metadataContext: currentContext ? currentContext : ProteusConstants.SYSTEM
                },
                context: { showSpinner: reloadScreen }
            })).data;
            let registrationTypeTree = currentContext === ProteusConstants.PLANNING || currentContext === ProteusConstants.PLANNING_CURRICULUM ? data.registrationServiceFacadeBean_registrationTypeTreeForPlanning.a : data.employeeService_registrationTypeTree;
            let filteredCreateRegistrationTypeTree = currentContext === ProteusConstants.PLANNING || currentContext === ProteusConstants.PLANNING_CURRICULUM ? data.registrationServiceFacadeBean_registrationTypeTreeForPlanning.b : [];

            if (filterWorkPeriodTypes) {
                // needed in GroupsManagements screen, Constraint tab => validForRegistrationTypesTable (add new type)
                this.filterWorkPeriodTypes(registrationTypeTree);
            }
            this.props.r.setInReduxState({ registrationTypeTree, filteredCreateRegistrationTypeTree });
            if (this.props.s.searchedRegistrationType !== "") {
                this.props.r.filterTree(registrationTypeTree, this.props.s.searchedRegistrationType);
            }
            this.props.r.setTreeRoot();

            if (firstLoad) {
                this.expandTree();
            }

            if (hasCheckboxes) {
                this.props.r.enrichTreeWithCheckedFlags(
                    ProteusUtils.getMetadataProvider()!,
                    getStatuses,
                    true,
                    initiallySelectedTypes,
                    currentContext
                );
            }
        }

        filterWorkPeriodTypes(root: any[]) {
            for (let elem of root) {
                if (elem.types.length > 0) {
                    for (let i = 0; i < elem.types.length; i++) {
                        if (elem.types[i] !== undefined) {
                            const layoutTypeCode = MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!,
                                elem.types[i].code, undefined, ProteusConstants.LAYOUT_TYPE_CODE, ProteusConstants.SYSTEM);
                            if (layoutTypeCode?.toString() === ProteusConstants.WERK_PERIODE) {
                                elem.types.splice(i, 1);
                                i--;
                            }
                        }
                    }
                }
                this.filterWorkPeriodTypes(elem.categoryChildren);
            }
        }

        getElementRouteInTree(elementType: string, indexInTree: string, i: number, j?: number): string {
            return indexInTree + i + Utils.defaultIdSeparator + elementType + Utils.defaultIdSeparator + (j !== undefined ? j : "");
        }

        expandTree() {
            this.setTreeExpandedIds(this.expandTreeRecursive(this.registrationTreeRef.current?.props.root, ""));
            this.registrationTreeRef.current?.props.r.linearize(this.props.s.root, {});
        }

        setTreeExpandedIds(expandedIds: {}) {
            this.registrationTreeRef.current?.setInitialExpandedIds(expandedIds);
        }

        setTreeSelectedId(selectedId: string | undefined) {
            this.registrationTreeRef.current?.setInitialSelectedId(selectedId);
        }

        setTreeHoveredId(hoveredId: string | undefined) {
            this.registrationTreeRef.current?.props.r.setInReduxState({ hoveredId });
        }

        expandTreeRecursive(root: any, expandedId: string) {
            let expandedIds = {};
            for (let i = 0; i < root.length; i++) {
                expandedIds = { ...expandedIds, [expandedId + i]: true };
                if (root[i].__typename === REGISTRATION_TYPE_CATEGORY) {
                    if (root[i].categoryChildren.length > 0) {
                        expandedIds = { ...expandedIds, ...this.expandTreeRecursive(root[i].categoryChildren, this.getElementRouteInTree(CATEGORY_CHILDREN, expandedId, i)) };
                    } else if (root[i].types.length > 0) {
                        for (let j = 0; j < root[i].types.length; j++) {
                            if (root[i].types[j].statuses.length > 0) {
                                expandedIds = { ...expandedIds, [this.getElementRouteInTree(TYPES, expandedId, i, j)]: true };
                            }
                        }
                    }
                }
            }
            return expandedIds;
        }

        findTheTreeElementInOriginalRoot(root: any, treeElementFromFilteredRoot: any): any {
            for (let i = 0; i < root.length; i++) {
                let treeElement = {};
                if (root[i].__typename === REGISTRATION_STATUS && root[i].__typename === treeElementFromFilteredRoot.__typename && root[i].status.code === treeElementFromFilteredRoot.status.code
                    && root[i].type.code === treeElementFromFilteredRoot.type.code) {
                    return root[i];
                } else if (root[i].code === treeElementFromFilteredRoot.code && root[i].__typename === treeElementFromFilteredRoot.__typename && root[i].__typename !== REGISTRATION_STATUS) {
                    return root[i];
                }
                if (root[i].__typename === REGISTRATION_TYPE_CATEGORY && root[i].categoryChildren.length > 0) {
                    treeElement = this.findTheTreeElementInOriginalRoot(root[i].categoryChildren, treeElementFromFilteredRoot);
                    if (treeElement !== undefined) {
                        return treeElement;
                    }
                }
                if (root[i].__typename === REGISTRATION_TYPE_CATEGORY && root[i].types.length > 0) {
                    treeElement = this.findTheTreeElementInOriginalRoot(root[i].types, treeElementFromFilteredRoot);
                    if (treeElement !== undefined) {
                        return treeElement;
                    }
                }
                if (root[i].__typename === REGISTRATION_TYPE && root[i].statuses && root[i].statuses.length > 0) {
                    treeElement = this.findTheTreeElementInOriginalRoot(root[i].statuses, treeElementFromFilteredRoot);
                    if (treeElement !== undefined) {
                        return treeElement;
                    }
                }
            }
            return undefined;
    }

    protected renderItemTree = (params: RenderItemParams) => {
        const treeElementId = params.linearizedItem.itemId.split(Utils.defaultIdSeparator);
        if (this.props.s.registrationTypeTree.length === 0 && treeElementId.length > 0) {
            return <></>;
        }
        let treeElement = Utils.navigate(this.props.s.searchedRegistrationType === "" ? this.props.s.registrationTypeTree : this.props.s.filteredRegistrationTypeTree, treeElementId);
        if (this.props.s.searchedRegistrationType !== "" && this.props.hasCheckboxes) {
            // when the tree is filtered, the tree root is filteredRegistrationTypeTree, but the checked/ unchecked flag is updated in registrationTypeTree
            // because of this, the object must be found in registrationTypeTree to retriere its correct flag
            treeElement = this.findTheTreeElementInOriginalRoot(this.props.s.registrationTypeTree, treeElement);
        }
        if (treeElement.__typename === REGISTRATION_TYPE) {
            return <><Icon name="sticky note" size="large" />
                <div className="flex-grow-shrink-no-overflow">{treeElement.name}</div>
                {this.renderAdditionalButtons(treeElement)}
            </>
        } else if (treeElement.__typename === REGISTRATION_TYPE_CATEGORY) {
            return <><Icon name="file alternate outline" size="large" />
                <div className="flex-grow-shrink-no-overflow">{treeElement.name}</div>
                {this.renderAdditionalButtons(treeElement)}
            </>
        } else {
            return <><Icon name="circle" size="small" />
                <div className="flex-grow-shrink-no-overflow">{treeElement.status.description}</div>
                {this.renderAdditionalButtons(treeElement)}
            </>
        }
    }

    renderMenuContext = () => {
        return <ModalExt open={this.props.s.openContextMenuModal}
            onClick={() => this.props.r.setInReduxState({ openContextMenuModal: false })}
            onClose={() => this.props.r.setInReduxState({ openContextMenuModal: false })}
            className="RegistrationTypeCategoryTree_modal"
        >
            <Menu vertical>
                {this.props.handleEdit && <Menu.Item
                    name="edit"
                    content={_msg("RegistrationTypeCategoryTree.edit.label")}
                    icon="edit"
                    onClick={() => this.props.handleEdit?.(this.props.s.treeElement)}
                />}
                {this.props.handleDelete && <Menu.Item
                    name="delete"
                    content={_msg("RegistrationTypeCategoryTree.delete.label")}
                    icon="delete"
                    onClick={() => this.props.handleDelete?.(this.props.s.treeElement)}
                />
                }
            </Menu>
        </ModalExt>;
    };

    openMenuContext = (open: ModalExtOpen, treeElement: any) => {
        this.props.r.setInReduxState({ openContextMenuModal: open, treeElement });
    };

    protected isDefaultMode(treeMode: RegistrationTypeCategoryTreeMode = this.props.s.mode) {
        return treeMode === RegistrationTypeCategoryTreeMode.DEFAULT;
    }

    protected renderAdditionalButtons(treeElement: any) {
        return (<>
            {this.props.hasCheckboxes && this.isDefaultMode() && this.renderTreeCheckboxes(treeElement)}
            {treeElement.__typename !== REGISTRATION_STATUS && (this.props.handleDelete || this.props.handleEdit) &&
                <Button primary icon="bars" size="mini" className="RegistrationTypeCategoryTree_tree_buttons"
                    onClick={(event) => this.openMenuContext!([event.clientX, event.clientY], treeElement)}
                />}
            {this.props.renderAdditionalActionsInTreeItem && this.props.renderAdditionalActionsInTreeItem(treeElement)}
        </>)
    }

    renderTreeCheckboxes(treeElement: any) {
        return (<Checkbox onChange={(i, data) => {
            this.props.r.onCheckboxClick(
                treeElement, ProteusUtils.getMetadataProvider()!,
                this.props.hasStatuses, this.registrationTreeRef.current?.props.s.hoveredId, data.checked, this.props.currentContext
            )
            this.props.r.filterTree(this.props.s.registrationTypeTree, this.props.s.searchedRegistrationType);
        }} checked={treeElement.isChecked} />)
    }

    protected onTreeRevertChangesButtonClick = () => {
        this.props.r.enrichTreeWithCheckedFlags(
            ProteusUtils.getMetadataProvider()!,
            this.props.hasStatuses,
            false,
            this.props.initiallySelectedTypes,
            this.props.currentContext
        );
        this.props.r.filterTree(this.props.s.registrationTypeTree, this.props.s.searchedRegistrationType);
        this.props.r.setInReduxState({ appliedRegistrationTypes: this.props.initiallySelectedTypes });
        setTimeout(() => {        
            this.props.onRevertChangesClick?.(this.props.s.registrationTypeTree);
        });
    }

    protected onTreeApplyButtonClick = () => {
        this.props.r.setInReduxState({ appliedRegistrationTypes: this.props.s.selectedRegistrationTypes });
        this.props.onApplyClick?.(this.props.s.selectedRegistrationTypes, this.props.s.registrationTypeTree);
    }

    onTreeFilterCreateButtonClick = async () => {
        if (!this.props.filterCreateTypes) {
            return;
        }
        const newMode = this.isDefaultMode() ? RegistrationTypeCategoryTreeMode.FILTER_CREATE : RegistrationTypeCategoryTreeMode.DEFAULT;
        const isFilterCreateMode = !this.isDefaultMode(newMode);
        this.props.r.setInReduxState({ mode: newMode, searchedRegistrationType: '', temporarySearchedRegistrationType: '', filteredRegistrationTypeTree: [] });
        const registrationTypeTree = this.props.registrationTypeTree ? this.props.registrationTypeTree : this.props.s.registrationTypeTree;
        const filteredRegistrationTypeTree = this.props.filteredRegistrationTypeTree ? this.props.filteredRegistrationTypeTree : this.props.s.filteredCreateRegistrationTypeTree;
        this.props.r.setInReduxState({ registrationTypeTree: isFilterCreateMode ? filteredRegistrationTypeTree : registrationTypeTree });
        this.props.r.setTreeRoot();
    }

    protected onSelectItem = (params: OnSelectParams) => {
        let root = undefined;
        if (this.props.s.searchedRegistrationType === "") {
            root = this.props.s.registrationTypeTree;
        } else {
            root = this.props.s.filteredRegistrationTypeTree;
        }
        const item = Utils.navigate(root, params.itemId);
        this.props.onSelectItem?.(params, item);
    }

    render() {
        return (<>
            <Segment>
                {this.props.showApplyAndRevertButtons &&
                    <Grid.Row>
                        <Segment>
                            {this.isDefaultMode() && <Button primary onClick={() => this.onTreeApplyButtonClick()}>{_msg("RegistrationTree.apply.label")}</Button>}
                            {this.isDefaultMode() && <Button onClick={() => this.onTreeRevertChangesButtonClick()}>{_msg("RegistrationTree.reset.label")}</Button>}
                            {this.props.filterCreateTypes && <Button primary={!this.isDefaultMode()} onClick={() => { this.onTreeFilterCreateButtonClick() }}>{this.isDefaultMode() ? _msg("RegistrationTree.filterCreate.label") : <><Icon name='arrow left' />{_msg("RegistrationTree.goBack.label")}</>}</Button>}
                        </Segment>
                    </Grid.Row>
                }
                <Grid.Row>
                    <Segment>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column className="GroupsManagement_treeSearch">
                                    <Form>
                                        <Form.Field>
                                            <Input icon="search" placeholder={_msg("searchRegistrationType.label")} value={this.props.s.temporarySearchedRegistrationType}
                                                onChange={(e, data) => this.props.r.setInReduxState({ temporarySearchedRegistrationType: data.value })}
                                                onKeyDown={(e: any) => {
                                                    // the search is applied only on "Enter" key press
                                                    if (e.key === 'Enter') {
                                                        // in some cases, after pressing enter, page reload is triggered. To prevent this we use e.preventDefault()
                                                        e.preventDefault();
                                                        if (this.props.onSearchChange) {
                                                            this.props.onSearchChange(e.target.value);
                                                        } else {
                                                            this.props.r.filterTree(this.props.s.registrationTypeTree, e.target.value);
                                                            this.setTreeSelectedId(undefined);
                                                        }
                                                    }
                                                }}>
                                            </Input>
                                        </Form.Field>
                                    </Form>
                                </Grid.Column>
                                {this.isDefaultMode() && <Grid.Column className="GroupsManagement_expandCollapse">
                                    {/* For expand and collapse buttons, type="button" is needed because this tree is used in a form (on ConstraintTab).
                                    If the type is not added, the form will see this button as a submit button */}
                                    <Popup content={_msg("RegistrationMetadata.tree.expandAll.label")} trigger={<Button type="button" basic icon="plus" onClick={() =>
                                        this.expandTree()
                                    } />}
                                    />
                                    <Popup content={_msg("RegistrationMetadata.tree.collapseAll.label")} trigger={<Button type="button" basic icon="minus" onClick={() => {
                                        if (this.props.onTreeCollapse) {
                                            this.props.onTreeCollapse();
                                        } else {
                                            this.registrationTreeRef.current?.props.r.collapseAll(this.props.s.root, {});
                                        }
                                    }
                                    } />}
                                    />
                                </Grid.Column>}
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Grid.Row>
                <Grid.Row className="RegistrationTypeCategoryTree">
                    <RegistrationTree id={"registrationTree"} ref={this.registrationTreeRef} root={this.props.s.root} adminStatusChanged={!this.props.handleDelete}
                        searchTextIsNull={this.props.s.searchedRegistrationType === ""} renderItemFunction={this.renderItemTree} onSelectItem={this.onSelectItem}
                        onExpandCollapseItem={this.props.onExpandCollapseItem}/>
                </Grid.Row>
                {this.renderMenuContext()}
            </Segment>
        </>);
    }

    transformRoot(getStatuses: boolean, firstLoad: boolean, hasCheckboxes: boolean, registrationTypeTree: any, appliedRegistrationTypes?: string[], currentContext?: string, filterWorkPeriodTypes?: boolean) {
        if (filterWorkPeriodTypes) {
            // needed in GroupsManagements screen, Constraint tab => validForRegistrationTypesTable (add new type)
            this.filterWorkPeriodTypes(registrationTypeTree);
        }
        this.props.r.setInReduxState({ registrationTypeTree });
        setTimeout(() => {
            this.props.r.setTreeRoot();
            setTimeout(() => {
                if (hasCheckboxes) {
                    this.props.r.enrichTreeWithCheckedFlags(
                        ProteusUtils.getMetadataProvider()!,
                        getStatuses,
                        true,
                        appliedRegistrationTypes,
                        currentContext
                    );
                }
                if (firstLoad) {
                    this.expandTree();
                }
            });
        });
    }

    componentDidMount() {
        if (TestUtils.storybookMode) {
            // this method is called in componentDidMount() to check the checkboxes in accordance with initiallySelectedTypes
            // normally, this method is called from loadRegistrationTypeTree, but for storybook this must be called manually
            this.props.r.enrichTreeWithCheckedFlags(
                ProteusUtils.getMetadataProvider()!,
                this.props.hasStatuses,
                true,
                this.props.initiallySelectedTypes,
                this.props.currentContext
            );
            this.expandTree();
        }
        if (this.props.currentContext !== ProteusConstants.PLANNING && this.props.currentContext !== PLANNING_CURRICULUM) {
            // for PLANNING and PLANNING_CURRICULUM, the registrations are loaded on "PlanningPage"/"EmployeeEditor" componentDidMount() method, because they are needed before the registration tree is mounted.
            this.loadRegistrationTypeTree(this.props.hasStatuses, true, true, this.props.hasCheckboxes, this.props.initiallySelectedTypes, this.props.currentContext, this.props.filterWorkPeriodTypes);
        } else {
           if (this.props.firstLoad) {
                let registrationTypeTree = _.cloneDeep(this.props.registrationTypeTree);
                this.transformRoot(this.props.hasStatuses, true, this.props.hasCheckboxes, registrationTypeTree, this.props.appliedRegistrationTypes, this.props.currentContext, this.props.filterWorkPeriodTypes);
           } else {
                this.props.r.setInReduxState({ registrationTypeTree: this.props.registrationTypeTree, selectedRegistrationTypes: this.props.appliedRegistrationTypes });
                this.props.r.setTreeRoot();
                setTimeout(() => {
                    this.expandTree();
                });
            }
        }
    }
}

export const RegistrationTypeCategoryTree = ReduxReusableComponents.connectRRC(RegistrationTypeCategoryState, RegistrationTypeCategoryReducers, RegistrationTypeCategoryTreeRaw);
