import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorFormSimple, EntityEditorFormSimpleProps, FieldDescriptor, getBaseReducers, PropsFrom, Utils } from "@crispico/foundation-react";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { getEmployee_employeeService_employee } from "apollo-gen/getEmployee";
import { getResourceRosterRelations_workrosterServiceFacadeBean_resourceRosterRelations } from "apollo-gen/getResourceRosterRelations";
import { getResourceWorkRosters_resourceWorkRosterService_resourceWorkRosters } from "apollo-gen/getResourceWorkRosters";
import axios from "axios";
import { FormikProps } from "formik";
import { EMPLOYEE_SERVICE_REMOVE_PICTURE } from "graphql/queries";
import { ProteusConstants } from "ProteusConstants";
import React from "react";
import { Image, Button, Icon, Modal } from "semantic-ui-react";
import { HISTORY_FIELD_TYPE } from "./customFieldRenderersEditors";
import { EMPLOYEE_EDITOR, EmployeeEditorBase } from "./EmployeeEditorBase";

export const CATEGORY_HISTORY_ITEM = "CategoryHistoryItem";
export const COMPANY_HISTORY_ITEM = "CompanyHistoryItem";
export const DEPARTMENT_HISTORY_ITEM = "DepartmentHistoryItem";
export const HOUR_REGIME_HISTORY_ITEM = "HourRegimeHistoryItem";
export const LOCATION_HISTORY_ITEM = "LocationHistoryItem";
export const RESOURCE_ROSTER_RELATION = "ResourceRosterRelation";
export const RESOURCE_WORK_ROSTER_HISTORY_ITEM = "ResourceWorkRosterHistoryItem";
export const TEAM_HISTORY_ITEM = "TeamHistoryItem";

export const PERSONAL_DETAILS_SCRIPTABLE_UI_ID = "EmployeeEditor_PersonalDetailsTab";

export enum ModalOperations {
    REMOVE_IMAGE = "REMOVE_IMAGE",
    UPDATE_IMAGE = "UPDATE_IMAGE"
};

interface FormState {
    showModal: {
        open: boolean,
        operation: ModalOperations
    }
}

export type EntityEditorFormCustomizedProps = EntityEditorFormSimpleProps & {
    fileChange: (e: any) => void;
    removePicture: (id: number) => void;
    isEditable: boolean;
}

export class EntityEditorFormCustomized extends EntityEditorFormSimple<EntityEditorFormCustomizedProps> {

    protected fileInputRef: any = React.createRef();
    constructor(props: any) {
        super(props);
        this.state = { showModal: { open: false, operation: ModalOperations.UPDATE_IMAGE } } as FormState
    }
    /**
     * This method is overriden because a div with employee's image must be added inside form
     * To obtain the src of the image, a GET call is done to server. The call depends on two parameters: id and objectVersion of employee.
     * Only when at least one of them is changed, a new call is done, if not the picture is cached by the browser.
     */
    renderForm(formikProps: FormikProps<any>, duplicateFromId?: boolean) {
        const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
        const pictureServletUrl = additionalUrlParam + "resources/getPicture?resource=";
        const url = Utils.adjustUrlToServerContext(pictureServletUrl);
        let form = super.renderForm(formikProps, duplicateFromId);
        const firstTwoRows = form.props.children[1].splice(0, 2)
        // retrieve the first 2 rows and wrap them in a div, because the image of the employee must be placed in the right part of this div.
        let firstTwoRowsDiv = React.createElement("div", { key: "2rows_div" }, firstTwoRows);
        let imageDiv = <div key="img_div">
            <Image src={url + this.props.entity.employee.id + "&objectVersionForCache=" + this.props.entity.employee.objectVersion}
                className="PersonalDetailsTab_image" onError={(e: any) => this.onImageError(e)} />
            <Button.Group icon>
                <Button key={1} onClick={() => this.setState({ showModal: { open: true, operation: ModalOperations.UPDATE_IMAGE } })} type="button" disabled={!this.props.isEditable}>
                    <input ref={this.fileInputRef} type="file" accept="image/*" hidden onChange={this.props.fileChange} />
                    <Icon name='edit' color="blue" />
                </Button>
                <Button key={2} onClick={() => this.setState({ showModal: { open: true, operation: ModalOperations.REMOVE_IMAGE } })} type="button" disabled={!this.props.isEditable}>
                    <Icon name='delete' color="red" />
                </Button>
            </Button.Group>
        </div>;
        // the parent div contains 2 children: the div with first 2 rows and the div with the image.
        let parentDiv = React.createElement("div", { className: "PersonalDetailsTab_rowsWithImage", key: "parent_div" }, firstTwoRowsDiv, imageDiv);
        form.props.children[1].splice(0, 0, parentDiv);
        return form;
    }

    onImageError(e: any) {
        const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "html5/" : "";
        e.target.src = Utils.adjustUrlToServerContext(additionalUrlParam + "images/no-image.png");
    }

    protected showModalInfoMessage() {
        return (
            <ModalExt onClose={() => this.setState({ showModal: { open: false } })}
                open={(this.state as FormState).showModal.open === true} size='small' severity={Severity.WARNING}>
                <Modal.Header>{_msg("warning.label")}</Modal.Header>
                <Modal.Content>{(this.state as FormState).showModal.operation === ModalOperations.REMOVE_IMAGE ?
                    _msg("EmployeeEditor.personalDetails.removeImage.label") : _msg("EmployeeEditor.personalDetails.updateImage.label")}</Modal.Content>
                <Modal.Actions>
                    <Button key="cancel" onClick={() => { this.setState({ showModal: { open: false } }) }} content={_msg("general.cancel")} />
                    <Button key="ok" primary content={_msg("general.ok")} onClick={async () => {
                        if ((this.state as FormState).showModal.operation === ModalOperations.REMOVE_IMAGE) {
                            this.props.removePicture(this.props.entity.employee.id)
                        } else if ((this.state as FormState).showModal.operation === ModalOperations.UPDATE_IMAGE) {
                            this.fileInputRef.current.click();
                        }
                        this.setState({ showModal: { open: false } });
                    }} />
                </Modal.Actions>
            </ModalExt>
        );
    }

    render() {
        return <>
            {super.render()}
            {this.showModalInfoMessage()}
        </>
    }
}

export const slicePersonalDetailsTab = createSliceFoundation(class SlicePersonalDetailsTab { })

type PropsNotFromState = {
    refPersonalDetailsEditor: React.RefObject<EntityEditorFormCustomized>;
    employee: getEmployee_employeeService_employee | undefined | null;
    resourceRosterRelations: getResourceRosterRelations_workrosterServiceFacadeBean_resourceRosterRelations[];
    resourceWorkRosters: getResourceWorkRosters_resourceWorkRosterService_resourceWorkRosters[],
    onOpenTableLight: (fieldDescriptor: FieldDescriptor, entities: any[]) => void;
    isEditable: boolean;
    referenceDate: Date | number;
    employeeEditor: EmployeeEditorBase<any>;
}

export class PersonalDetailsTab extends TabbedPage<PropsFrom<typeof slicePersonalDetailsTab> & PropsNotFromState> {

    constructor(props: any) {
        super(props);
        this.state = { loadingImageAttempts: 0 };
    }

    /**
     * if there is no category configured yet => add both fields;
     * if only has CAPTAINS or SAILORS category => show only ResourceWorkRoster
     * if only has other category => show only ResourceRosterRelation,
     * otherwise => show both
     */
    addWorkRosterFieldDescriptors(descriptor: EntityDescriptor) {
        let hasOtherCategory: boolean = false;
        let isBraxoCategory: boolean = false;
        const categoryHistory = this.props.employee?.detail?.categoryHistory;
        let showBothFields: boolean = false;
        if (!categoryHistory || !categoryHistory.length) {
            showBothFields = true;
        } else {
            categoryHistory.forEach(categoryHistoryItem => {
                if (categoryHistoryItem?.category?.code === ProteusConstants.CAPTAINS_CATEGORY_CODE ||
                    categoryHistoryItem?.category?.code === ProteusConstants.SAILORS_CATEGORY_CODE ||
                    categoryHistoryItem?.category?.code === ProteusConstants.DEKSMAN_CATEGORY_CODE) {
                    isBraxoCategory = true;
                } else {
                    hasOtherCategory = true;
                }
            });
        }

        if (isBraxoCategory || showBothFields) {
            descriptor.addFieldDescriptor({ name: "resourceWorkRosters", type: HISTORY_FIELD_TYPE, childFields: ["offset"], entityName: RESOURCE_WORK_ROSTER_HISTORY_ITEM, mandatoryFields: ["offset", "validFrom"], icon: "file" })
            descriptor.doForFields(["resourceWorkRosters"], (fieldDescriptor) => {
                fieldDescriptor.validFrom = this.props.referenceDate;
                this.customizeFieldDescriptor(fieldDescriptor);

            });
        }
        if (hasOtherCategory || showBothFields) {
            descriptor.addFieldDescriptor({ name: "rosterRelations", type: HISTORY_FIELD_TYPE, childFields: ["roster", "description"], entityName: RESOURCE_ROSTER_RELATION, mandatoryFields: ["roster", "referenceDate"], icon: "file" })
            descriptor.doForFields(["rosterRelations"], (fieldDescriptor) => {
                fieldDescriptor.referenceDate = this.props.referenceDate;
                this.customizeFieldDescriptor(fieldDescriptor);
            });
        }
    }

    protected getPersonalDetailEntityDescriptor() {
        let descriptor = new EntityDescriptor({ name: EMPLOYEE_EDITOR }, false)
            .addFieldDescriptor({ name: "employee.name", type: FieldType.string, enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.firstName", type: FieldType.string, enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.vocative", type: "Vocative", enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.title", type: FieldType.string, enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.companyHistory", type: HISTORY_FIELD_TYPE, childFields: ["company", "name"], entityName: COMPANY_HISTORY_ITEM, mandatoryFields: ["company"], icon: "suitcase" })
            .addFieldDescriptor({ name: "employee.detail.timeRegistration", type: FieldType.boolean, enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.departmentHistory", type: HISTORY_FIELD_TYPE, childFields: ["department", "description"], entityName: DEPARTMENT_HISTORY_ITEM, mandatoryFields: ["department"], icon: "building" })
            .addFieldDescriptor({ name: "employee.detail.categoryHistory", type: HISTORY_FIELD_TYPE, childFields: ["category", "description"], entityName: CATEGORY_HISTORY_ITEM, mandatoryFields: ["category"], icon: "clipboard" })
            .addFieldDescriptor({ name: "employee.detail.teamHistory", type: HISTORY_FIELD_TYPE, childFields: ["team", "description"], entityName: TEAM_HISTORY_ITEM, mandatoryFields: ["team"], icon: "users" })
            .addFieldDescriptor({ name: "employee.detail.locationHistory", type: HISTORY_FIELD_TYPE, childFields: ["location", "description"], entityName: LOCATION_HISTORY_ITEM, mandatoryFields: ["location"], icon: "map marker alternate" })
            .addFieldDescriptor({ name: "employee.detail.hourRegimeHistory", type: HISTORY_FIELD_TYPE, childFields: ["hourRegime", "description"], entityName: HOUR_REGIME_HISTORY_ITEM, mandatoryFields: ["hourRegime"], icon: "clock" })
            .addFieldDescriptor({ name: "employee.gender", type: "Gender", enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.nativeLanguage", type: "Language", enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.nationality", type: "Nationality", enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.detail.dateOfBirth", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT}), enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.detail.birthplace", type: "City", enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.detail.dateOfDeath", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT}), enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.detail.nationalRegisterNumber", type: FieldType.string, enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.detail.dimonaNumber", type: FieldType.string, enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.detail.healthInsuranceNumber", type: FieldType.string, enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.detail.childAllowanceFund", type: "ChildAllowanceFund", enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.detail.childAllowanceFundNumber", type: FieldType.string, enabled: this.props.isEditable })
            .addFieldDescriptor({ name: "employee.detail.barema", type: "Scale", enabled: this.props.isEditable })
        this.addWorkRosterFieldDescriptors(descriptor);
        descriptor.doForFields(["employee.companyHistory", "employee.departmentHistory", "employee.detail.categoryHistory",
            "employee.detail.teamHistory", "employee.detail.locationHistory", "employee.detail.hourRegimeHistory"],
            (fieldDescriptor) => this.customizeFieldDescriptor(fieldDescriptor));
        return descriptor;
    }

    customizeFieldDescriptor(fieldDescriptor: FieldDescriptor) {
        fieldDescriptor.onOpenTableLight = this.props.onOpenTableLight;
        // "isDisabled" was used in place of "disabled" because I don't want to disable the whole field, I want to keep the history button always enabled.
        fieldDescriptor.isDisabled = !this.props.isEditable
    }

    protected fileChange = async (e: any) => {
        const config = {
            headers: {
                "Content-type": "multipart/form-data"
            }
        };
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
        const url = Utils.adjustUrlToServerContext(additionalUrlParam + "resources/uploadPicture?resource=" + (String(this.props.employee?.id)));
        await axios.post(url!, formData, config);
        e.target.value = null;
        this.props.employeeEditor.saveAndReloadEmployee(ProteusConstants.IGNORE_WARNINGS);
    }

    protected removePicture = async (id: number) => {
        await apolloClient.mutate({
            mutation: EMPLOYEE_SERVICE_REMOVE_PICTURE,
            variables: { resourceId: id }
        });
        this.props.employeeEditor.saveAndReloadEmployee(ProteusConstants.IGNORE_WARNINGS);
    }

    render() {
        return (
            <EntityEditorFormCustomized scriptableUiId={PERSONAL_DETAILS_SCRIPTABLE_UI_ID} ref={this.props.refPersonalDetailsEditor} entityDescriptor={this.getPersonalDetailEntityDescriptor()}
                isEditable={this.props.isEditable} hideButtonBar entity={{ employee: this.props.employee, rosterRelations: this.props.resourceRosterRelations || [], resourceWorkRosters: this.props.resourceWorkRosters || [] }}
                removePicture={this.removePicture} fileChange={this.fileChange} />
        );
    }
}