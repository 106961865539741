'use strict';

/*@ngInject*/
function CompetencesCategoriesTreeCtrl($scope) {

	var competences = $scope.competences = $scope.competences || {};

	competences.filter = {
		properties: ['name'],
		parent: 'parentCategory',
		elementFn: function(scope) {
			return scope.category;
		}
	};

	var tree = {
		label: '{{category.name}}',
		icon: "'fa fa-folder-open fa-fw'",
		select: 'competences.select(this)',
		active: 'competences.category.parentCategory.id === category.id',
		expandable: true,
		if: 'competences.category.id !== category.id',
		filter: 'competences.filter'
	};

	competences.tree = angular.extend({}, tree, {
		repeat: 'category in competences.data track by category.id',
		children: [
			angular.extend({}, tree, {
				repeat: 'category in category.categoryChildren track by category.id',
				recurse: 'competences-categories'
			})
		]
	});

	competences.select = function(scope) {
		if (scope.category.id !== competences.category.id) {
			competences.category.parentCategory = scope.category;
			$scope.parentDropdownOpen = false;
		}
	};

}

module.exports = CompetencesCategoriesTreeCtrl;