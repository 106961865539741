import { apolloClient, EntityDescriptor, FieldDescriptor, Utils } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { TreeReducers, TreeState, PropsNotFromState as TreeProps, Tree, RenderItemParams } from "@crispico/foundation-react/components/TreeRRC/Tree";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { EntityFormLight, EntityFormLightRaw } from "@crispico/foundation-react/entity_crud/light_crud/EntityFormLight";
import { ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import React from "react";
import { Button, Checkbox, Form, Grid, Header, Icon, Input, Menu, Popup, Segment, SemanticICONS, Tab } from "semantic-ui-react";
import { ProteusConstants } from "ProteusConstants";
import _ from "lodash";
import { getGroupContext_groupServiceFacadeBean_groupContext } from "apollo-gen/getGroupContext";
import { getGroupSnapshots_groupServiceFacadeBean_groupSnapshots } from "apollo-gen/getGroupSnapshots";
import { ProteusUtils } from "ProteusUtils";
import { EmployeeSnapshotAssociationFieldEditor } from "./customFieldRenderersEditors";
import { Group, GroupContextTab, GroupOption, GroupTable, sliceGroupContextTab } from "./GroupContextTab";
import { sliceGroupsManagementBase } from "./sliceGroupsManagementBase";
import { BasicFields, CUSTOM_VALUE, EMPLOYEE, EMPLOYEE_SNAPSHOT, GroupPropertyEditor, GroupSnapshotTab, GROUP_PROPERTY_CT, ResourcesHistoryItem, sliceGroupSnapshotTab } from "./GroupSnapshotTab";
import moment from "moment";
import { ResourceTable, sliceSuccessionTab, SuccessionDefinitionHistory, SuccessionTab } from "./SuccessionTab";
import { SecurityDefinitionHistory, SecurityProfiles, SecurityProfilesTable, SecurityTab, sliceSecurityTab } from "./SecurityTab";
import { GroupRelation, GroupRelationEditor, GroupRelationTable, IN, OUT, RelationTab, sliceRelationTab } from "./RelationTab";
import { getGroupMembers_employeeService_employeeSnapshotsByGroup } from "apollo-gen/getGroupMembers";
import { getCompositionGroup_compositionGroupService_compositionGroup_groups_properties } from "apollo-gen/getCompositionGroup";
import { CALENDAR, ConstraintDefinition, ConstraintDefinitionEditor, ConstraintDefinitionHistory, ConstraintsTab, RegistrationType, sliceConstraintsTab, VALID_FOR_REGISTRATION_TYPES, VALID_ON_REGISTRATION_TYPES } from "./ConstraintsTab";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { CalendarDefinition } from "pages/calendarDefinition/CalendarDefinitionEditor";
import { CALENDAR_DEFINITION_SERVICE_SAVE_CALENDAR_DEFINITIONS, GROUP_SERVICE_FACADE_BEAN_ADD_CONTEXT_TO_GROUPS, GROUP_SERVICE_FACADE_BEAN_GET_GROUP_CONTEXTS, GROUP_SERVICE_FACADE_BEAN_GET_GROUP_SNAPSHOTS, GROUP_SERVICE_FACADE_BEAN_REMOVE_CONTEXT_FROM_GROUPS, GROUP_SERVICE_FACADE_BEAN_REMOVE_GROUP, GROUP_SERVICE_FACADE_BEAN_REMOVE_GROUPS, GROUP_SERVICE_FACADE_BEAN_REMOVE_GROUP_CONTEXT, GROUP_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_GROUP_CONTEXT } from "graphql/queries";
import { SequenceDefinitionHistory, SequenceTab, sliceSequenceTab } from "./SequenceTab";
import { LeavePageModal } from "components/LeavePageModal";
import { ModalExt, ModalExtOpen, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { ShortcutRefForTest } from "@famiprog-foundation/tests-are-demo";

export const GROUP_CONTEXT = "GroupContext";
export const GROUP_SNAPSHOT = "GroupSnapshot";
export const DYNAMIC = "Dynamisch";
export const STATIC = "Statisch";
export const COMPOSITION = "Compositie";
export const LABEL = ".label";

// constants used for field names
export const GROUPS = "groups";
export const OWNER = "owner";
export const CONTEXTS = "contexts";
export const CONTEXT = "context";
export const PROPERTIES = "properties";
export const PROPERTY = "property";
export const RELATIONS = "relations";
export const CODE = "code";
export const NAME = "name";
export const DESCRIPTION = "description";
export const TYPE = "type";
export const RESOURCES = "resources";
export const PROFILES = "profiles";
export const WORK_PERIOD_TYPE = "workPeriodType";
export const HISTORY = "history";

const groupTypes = [
    { from: ProteusConstants.STATIC_GROUP_CODE },
    { from: ProteusConstants.DYNAMIC_GROUP_CODE },
    { from: ProteusConstants.COMPOSITION_GROUP_CODE }
];

export interface HistoryItem {
    id?: number,
    validFrom: string,
    validUntil?: string | null | undefined
}
export class GroupContextTreeReducers<S extends TreeState> extends TreeReducers<S> {

    protected _getChildren(item: any): { localId: string, item: any; }[] {
        if (Array.isArray(item)) {
            return super._getChildren(item, {});
        }

        const groups = item.groups as Array<any>;
        if (groups) {
            if (groups.length === 0) {
                return [];
            }
            return (groups.map((item, index) => ({ localId: GROUPS + Utils.defaultIdSeparator + index, item })));
        } else {
            return item.name;
        }
    }

    protected _hasChildren(item: any) {
        if (item.contexts) {
            return false;
        }
        return true;
    }
}

export type groupsContextsTreeRootType = (getGroupContext_groupServiceFacadeBean_groupContext & { groups: getGroupSnapshots_groupServiceFacadeBean_groupSnapshots[] });
export type GroupContextTreeProps = TreeProps & {
    renderItemFunction: Function,
} & RRCProps<TreeState, GroupContextTreeReducers<TreeState>>;

export class GroupContextTreeRaw<T extends GroupContextTreeProps = GroupContextTreeProps> extends Tree<T> {
    protected renderItem(params: RenderItemParams) {
        return this.props.renderItemFunction(params);
    }
}

export function filteringTree(groupsContextsTreeRoot: groupsContextsTreeRootType[], searchedGroupOrContext:string) {
    let filteredGroupsContextsTreeRoot = [];
    for (let i = 0; i < groupsContextsTreeRoot.length; i++) {
        const treeRootElement = groupsContextsTreeRoot[i];
        if (treeRootElement.name?.toLowerCase().includes(searchedGroupOrContext?.toLowerCase())) {
            filteredGroupsContextsTreeRoot.push(treeRootElement);
        } else {
            let context = { ...treeRootElement, groups: [] as any };
            for (let j = 0; j < treeRootElement.groups.length; j++) {
                if (treeRootElement.groups[j].name?.toLowerCase().includes(searchedGroupOrContext?.toLowerCase())) {
                    context.groups.push(treeRootElement.groups[j]);
                }
            }

            if (context.groups.length !== 0) {
                filteredGroupsContextsTreeRoot.push(context);
            }
        }
    }
    return filteredGroupsContextsTreeRoot;
}

export const GroupContextTreeRRC = ReduxReusableComponents.connectRRC(TreeState, GroupContextTreeReducers, GroupContextTreeRaw);

export const sliceGroupsManagement = createSliceFoundation(class SliceGroupsManagement {

    initialState = {
        groupsContextsTreeRoot: [] as groupsContextsTreeRootType[],
        filteredGroupsContextsTreeRoot: [] as groupsContextsTreeRootType[],
        searchedGroupOrContext: "" as string,
        confirmations: undefined as unknown as { [key: string]: boolean },
        selectedGroupAndContext: { selectedGroup: undefined, selectedContext: undefined } as any,
        // unmodifiedSelectedGroupAndContext is keeping the original values for the object
        unmodifiedSelectedGroupAndContext: { selectedGroup: undefined, selectedContext: undefined } as any,
        groupOrContextEditorContent: { text: "" as string, icon: "" as SemanticICONS, type: "" },
        confirmLeavePage: false as boolean,
        newOperationToBeConfirmed: undefined as unknown as any,
        expandCollapseItemId: undefined as unknown as string,
        groupSnapshotOptions: [] as GroupOption[],
        employeesOptions: [] as { key: number, text: string, value: number }[],
        groupsToBeDeletedFromCurrentContext: [] as Group[],
        groupsToBeAddedToCurrentContext: [] as number[],
        showModalMessage: undefined as unknown as { isOpen: boolean, entityName: string, additionalFields?: string[], selfRelation?: boolean },
        activeTabIndex: 0 as number,
        oldTabData: undefined as unknown as { [key: string]: ConstraintDefinition[] | SuccessionDefinitionHistory | SecurityDefinitionHistory | GroupRelation | undefined },
        root: [] as any,
        openContextMenuModal: undefined as unknown as ModalExtOpen,
        treeElement: undefined as any,
        treeElementId: [] as String[]
    }

    nestedSlices = {
        groupsManagementBase: sliceGroupsManagementBase,
        groupContextTab: sliceGroupContextTab,
        groupSnapshotTab: sliceGroupSnapshotTab,
        successionTab: sliceSuccessionTab,
        securityTab: sliceSecurityTab,
        relationTab: sliceRelationTab,
        constraintsTab: sliceConstraintsTab,
        sequenceTab: sliceSequenceTab
    }

    reducers = {
        ...getBaseReducers<SliceGroupsManagement>(this),

        setGroupsContextsTreeRoot(state: StateFrom<SliceGroupsManagement>) {
            if (state.groupsContextsTreeRoot.length === 0) {
                state.root = [];
            } else if (state.searchedGroupOrContext === "") {
                state.root = state.groupsContextsTreeRoot;
            } else {
                state.root = state.filteredGroupsContextsTreeRoot;
            }
        },

        /**
        * Open formLight with custom message and icon
        */
        openFormLight(state: StateFrom<SliceGroupsManagement>, groupOrContext: any) {
            let type = GROUP_CONTEXT;
            let icon: SemanticICONS = "setting";
            let text = _msg("entityCrud.editor.subheader.add", _msg("context.label"));
            if (groupOrContext.__typename === GROUP_SNAPSHOT) {
                type = GROUP_SNAPSHOT;
                icon = "group";
                text = _msg("entityCrud.editor.subheader.add", _msg("group.label"));
            }
            state.groupOrContextEditorContent = { text, icon, type };
        },

        setFieldsForSelectedGroup(state: StateFrom<SliceGroupsManagement>, data: { fieldName: string, fieldValue: any }) {
            if (data.fieldName === RELATIONS) {
                state.selectedGroupAndContext.selectedGroup[data.fieldName] = data.fieldValue;
            } else {
                state.selectedGroupAndContext.selectedGroup.group[data.fieldName] = data.fieldValue;
            }
        },

        setFieldsForSelectedContext(state: StateFrom<SliceGroupsManagement>, data: { fieldName: string, fieldValue: any }) {
            state.selectedGroupAndContext.selectedContext[data.fieldName] = data.fieldValue;
        },

        setSuccessionDefinitionHistory(state: StateFrom<SliceGroupsManagement>, history: SuccessionDefinitionHistory[]) {
            state.selectedGroupAndContext.selectedGroup.group.successionDefinition.history = history;
        },

        setSecurityDefinitionHistory(state: StateFrom<SliceGroupsManagement>, history: SecurityDefinitionHistory[]) {
            state.selectedGroupAndContext.selectedGroup.group.securityDefinition.history = history;
        },

        setSequenceDefinitionHistory(state: StateFrom<SliceGroupsManagement>, history: SequenceDefinitionHistory[]) {
            state.selectedGroupAndContext.selectedGroup.group.sequenceDefinition.history = history;
        },

        setConstraintDefinitions(state: StateFrom<SliceGroupsManagement>, constraintDefinitions: ConstraintDefinition[]) {
            state.selectedGroupAndContext.selectedGroup.group.constraintDefinitions = constraintDefinitions;
        }
    }

    impures = {
        ...getBaseImpures<SliceGroupsManagement>(this),

        async loadGroupsContextsForTree(expandTree: boolean, loadScreen: boolean, groupsContextTreeRef: React.RefObject<GroupContextTreeRaw<GroupContextTreeProps>>) {
            const groupContexts = (await apolloClient.query({
                query: GROUP_SERVICE_FACADE_BEAN_GET_GROUP_CONTEXTS,
                variables: {
                    owner: ProteusUtils.getCurrentUser().id
                },
                context: {
                    showSpinner: loadScreen
                }
            })).data.groupServiceFacadeBean_groupContexts;
            const groupSnapshots = (await apolloClient.query({
                query: GROUP_SERVICE_FACADE_BEAN_GET_GROUP_SNAPSHOTS,
                variables: {
                    owner: ProteusUtils.getCurrentUser().id
                }
            })).data.groupServiceFacadeBean_groupSnapshots;
            this.getDispatchers().setInReduxState({
                groupSnapshotOptions: groupSnapshots.map((item: any) => ({
                    key: item?.id as number,
                    text: item?.name as string,
                    value: item?.id as number,
                    type: item?.type?.code as string,
                    contexts: item?.contexts as any
                })).sort((a: any, b: any) => a.text > b.text ? 1 : -1)
            });
            this.getGroupsContextsAsRootOfTree(groupContexts, groupSnapshots, expandTree, groupsContextTreeRef);
        },

        async addGroupsToCompositionGroup(groupToBeAdded: BasicFields) {
            // check if group already exists in current composition group
            if (this.getState().selectedGroupAndContext.selectedGroup.group.groups.filter((item: any) => item.id === groupToBeAdded.id).length > 0) {
                return;
            }
            const group = await this.getDispatchers().groupsManagementBase.getGroup(groupToBeAdded.id);
            const groupType = this.getState().groupSnapshotOptions.filter((item: any) => item.key === group.id)[0].type;
            if (groupType === ProteusConstants.COMPOSITION_GROUP_CODE) {
                this.getDispatchers().setInReduxState({ showModalMessage: { isOpen: true, entityName: GROUP_SNAPSHOT } });
                return;
            }
            const groups = [group].concat(this.getState().selectedGroupAndContext.selectedGroup.group.groups);
            this.getDispatchers().setFieldsForSelectedGroup({ fieldName: GROUPS, fieldValue: groups });
        },

        async removeGroupsFromCurrentContext() {
            let groupsToBeDeleted = this.getState().groupsToBeDeletedFromCurrentContext;
            if (this.getState().selectedGroupAndContext.selectedContext.id === undefined) {
                // the selected context is Geen context: all groups from groupsToBeDeletedFromCurrentContext must be permanently deleted
                const ids = groupsToBeDeleted.map((item: any) => item.id);
                await apolloClient.mutate({
                    mutation: GROUP_SERVICE_FACADE_BEAN_REMOVE_GROUPS,
                    variables: {
                        ids
                    }
                });
            } else {
                // for each group from groupsToBeDeletedFromCurrentContext we must delete the current context from its contexts array
                await apolloClient.mutate({
                    mutation: GROUP_SERVICE_FACADE_BEAN_REMOVE_CONTEXT_FROM_GROUPS,
                    variables: {
                        groupsId: groupsToBeDeleted.map((item: any) => item.id),
                        contextId: this.getState().selectedGroupAndContext.selectedContext.id
                    }
                });
            }
            this.getDispatchers().setInReduxState({ groupsToBeDeletedFromCurrentContext: [] });
        },

        async addGroupsToCurrentContext() {
            // the groups can only be added from a valid context (no Geen context)
            await apolloClient.mutate({
                mutation: GROUP_SERVICE_FACADE_BEAN_ADD_CONTEXT_TO_GROUPS,
                variables: {
                    groupsId: this.getState().groupsToBeAddedToCurrentContext,
                    contextCode: this.getState().selectedGroupAndContext.selectedContext.code
                }
            });
            this.getDispatchers().setInReduxState({ groupsToBeAddedToCurrentContext: [] });
        },

        reselectGroupOrContextInTree(groupOrContext: any, groupsContextTreeRef: React.RefObject<GroupContextTreeRaw<GroupContextTreeProps>>) {
            const index = this.findNewlyAddedElementInTree(groupOrContext);
            if (index !== -1) {
                groupsContextTreeRef.current?.props.r.setInReduxState({ selectedId: String(index) });
                document.getElementsByName(index)[0]?.scrollIntoView(false);
            } else {
                groupsContextTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
            }
        },

        /**
        * This method is called when an existing context or group is edited (saved)
        */
        async saveOrUpdateGroupOrContext(groupsContextTreeRef: React.RefObject<GroupContextTreeRaw<GroupContextTreeProps>>) {
            let selectedContext = { ...this.getState().selectedGroupAndContext.selectedContext };
            let selectedGroup = { ...this.getState().selectedGroupAndContext.selectedGroup };
            let unmodifiedSelectedGroupAndContext = this.getState().unmodifiedSelectedGroupAndContext;
            // selectedGroup is undefined so we want to save the context
            if (_.isEmpty(selectedGroup)) {
                // verify if the context groups are modified. If they are, we must update those groups too
                if (this.getState().groupsToBeDeletedFromCurrentContext.length !== 0) {
                    await this.removeGroupsFromCurrentContext();
                }
                if (this.getState().groupsToBeAddedToCurrentContext.length !== 0) {
                    await this.addGroupsToCurrentContext();
                }

                // because "groups" was added for tree hierarchy, it must be deleted before we send it to server 
                const groupsOfSelectedContext = selectedContext.groups;
                if (selectedContext.id !== undefined) {
                    delete selectedContext.groups;
                    await apolloClient.mutate({
                        mutation: GROUP_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_GROUP_CONTEXT,
                        variables: {
                            groupContext: selectedContext
                        },
                        context: this.getDispatchers().groupsManagementBase.getMutationContext()
                    });
                }
                await this.loadGroupsContextsForTree(false, false, groupsContextTreeRef);
                if (selectedContext.id !== undefined) {
                    selectedContext = { ...await this.getDispatchers().groupsManagementBase.getGroupContext(selectedContext.code), groups: groupsOfSelectedContext };
                } else {
                    selectedContext = { name: _msg("GroupsManagement.noContext.label"), groups: groupsOfSelectedContext };
                }

                if (unmodifiedSelectedGroupAndContext.selectedContext.name !== selectedContext.name) {
                    this.reselectGroupOrContextInTree(selectedContext, groupsContextTreeRef);
                }
                this.getDispatchers().setInReduxState({
                    selectedGroupAndContext: { selectedGroup: undefined, selectedContext },
                    unmodifiedSelectedGroupAndContext: { selectedGroup: undefined, selectedContext },
                    oldTabData: undefined
                });
            } else {
                // save a group
                // if empty securityDefinition or successionDefinition was added, replace it with null
                // even if it does not trigger the "save" button, there might be other changes that triggerd it
                selectedGroup = {
                    ...selectedGroup,
                    group: this.checkIfDefinitionShouldBeAdded(selectedGroup.group, [ProteusConstants.SECURITY_DEFINITION, ProteusConstants.SUCCESSION_DEFINITION], { history: [] })
                };

                // if the group has a securityDefinition, add the group to SecurityContext. If the securityTab was deleted, remove the group from SecurityContext
                selectedGroup = this.addOrDeleteGroupFromSecurityContext(selectedGroup, unmodifiedSelectedGroupAndContext);

                // before saving the group, check if it has calendarDefinitions and save all calendars
                selectedGroup = { ...selectedGroup, group: await this.saveOrUpdateCalendarDefinitions(selectedGroup.group) };
                let groupMustBeReselectedInTree: boolean = false;
                if ((selectedContext.id !== undefined && selectedGroup.group.contexts.filter((item: any) =>
                    item.id === selectedContext.id).length === 0) ||
                    (selectedContext.id === undefined && selectedGroup.group.contexts.length !== 0 &&
                        this.getState().unmodifiedSelectedGroupAndContext.selectedGroup.group.contexts.length === 0) ||
                    this.getState().unmodifiedSelectedGroupAndContext.selectedGroup.group.name !== selectedGroup.group.name) {
                    // there might be 2 cases: 1. the group was deleted from a current context. 2. the group was in Geen context and it was added to a different context
                    // in this case, we must re-select the group in the tree, but from a different context,
                    // because it will not exist in the current context anymore after save
                    groupMustBeReselectedInTree = true;
                }
                await apolloClient.mutate({
                    mutation: this.getDispatchers().groupsManagementBase.getGroupMutationByType(selectedGroup.group.__typename),
                    variables: {
                        group: selectedGroup.group,
                        relations: selectedGroup.relations === null ? [] : selectedGroup.relations
                    },
                    context: this.getDispatchers().groupsManagementBase.getMutationContext()
                });
                const savedGroup = selectedGroup.group;
                await this.loadGroupsContextsForTree(false, false, groupsContextTreeRef);

                if (groupMustBeReselectedInTree) {
                    this.reselectGroupOrContextInTree(savedGroup, groupsContextTreeRef);
                }
                const group = {
                    group: await this.getDispatchers().groupsManagementBase.getGroupByType(selectedGroup.group.id, selectedGroup.group.__typename),
                    relations: await this.getDispatchers().groupsManagementBase.getGroupRelations(selectedGroup.group.id),
                    parentGroups: await this.getDispatchers().groupsManagementBase.getParentGroups(selectedGroup.group.id)
                };
                this.getDispatchers().setInReduxState({
                    selectedGroupAndContext: { ...this.getState().selectedGroupAndContext, selectedGroup: group },
                    unmodifiedSelectedGroupAndContext: { ...this.getState().unmodifiedSelectedGroupAndContext, selectedGroup: group },
                    oldTabData: undefined
                });
            }
        },

        addOrDeleteGroupFromSecurityContext(selectedGroup: any, unmodifiedSelectedGroupAndContext: any) {
            if (selectedGroup.group.securityDefinition !== null) {
                // check if the group is member of SecurityContext
                const isMemberOfSecurityContext = selectedGroup.group.contexts.filter((item: any) => item.code === ProteusConstants.SECURITY).length !== 0;
                if (!isMemberOfSecurityContext) {
                    let securityContext: any = { ...this.getState().groupsContextsTreeRoot.filter((item: any) => item.code === ProteusConstants.SECURITY)[0] };
                    delete securityContext.groups;
                    const contexts = [securityContext].concat(selectedGroup.group.contexts);
                    selectedGroup = { ...selectedGroup, group: { ...selectedGroup.group, contexts } };
                }
            } else {
                if (unmodifiedSelectedGroupAndContext.selectedGroup.group.securityDefinition !== null) {
                    // the group had a securityDefinition and it was unchecked. In this case, remove the group from the SecurityContext too
                    const contexts = selectedGroup.group.contexts.filter((item: any) => item.code !== ProteusConstants.SECURITY);
                    selectedGroup = { ...selectedGroup, group: { ...selectedGroup.group, contexts } };
                }
            }
            return selectedGroup;
        },

        checkIfDefinitionShouldBeAdded(group: any, fieldsName: string[], fieldValue: any) {
            for (let i = 0; i < fieldsName.length; i++) {
                if (_.isEqual(group[fieldsName[i]], fieldValue)) {
                    group = { ...group, [fieldsName[i]]: null };
                }
            }
            return group;
        },

        /**
        * This method is called when a new context or group is created
        */
        async createNewGroupOrContext(groupOrContext: any, groupsContextTreeRef: React.RefObject<GroupContextTreeRaw<GroupContextTreeProps>>, handleSelectedId: any) {
            let index;
            // the new created entity is a context
            if (groupOrContext.__typename === GROUP_CONTEXT) {
                const id = (await apolloClient.mutate({
                    mutation: GROUP_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_GROUP_CONTEXT,
                    variables: {
                        groupContext: { ...groupOrContext, owner: groupOrContext.owner?.id }
                    },
                    context: this.getDispatchers().groupsManagementBase.getMutationContext()
                })).data.groupServiceFacadeBean_saveOrUpdateGroupContext;
                groupsContextTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
                await this.loadGroupsContextsForTree(true, true, groupsContextTreeRef);
                index = this.findNewlyAddedElementInTree({ ...groupOrContext, id: id });
            } else {
                // the new created entity is a group. The group can be created for a specified context or for no context (Geen context)
                let group = { ...groupOrContext, contexts: [], owner: groupOrContext.owner?.id };
                if (this.getState().selectedGroupAndContext.selectedContext !== undefined && this.getState().selectedGroupAndContext.selectedContext.id !== undefined) {
                    // the group will be added for the current selected context
                    let context = { ...this.getState().selectedGroupAndContext.selectedContext };
                    delete context.groups;
                    group.contexts.push(context);
                }
                const mutation = this.getDispatchers().groupsManagementBase.getGroupMutationByType(group.type);
                delete group.type;
                await apolloClient.mutate({
                    mutation: mutation,
                    variables: {
                        group: group,
                        relations: []
                    },
                    context: this.getDispatchers().groupsManagementBase.getMutationContext()
                });
                groupsContextTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
                await this.loadGroupsContextsForTree(true, true, groupsContextTreeRef);
                index = this.findNewlyAddedElementInTree(group);
            }
            if (index !== -1) {
                groupsContextTreeRef.current?.props.r.setInReduxState({ selectedId: String(index) });
                handleSelectedId(String(index));
                document.getElementsByName(index)[0]?.scrollIntoView(false);
            }
        },

        findNewlyAddedElementInTree(groupOrContext: any) {
            if (groupOrContext.__typename === GROUP_CONTEXT) {
                // groupOrContext is a context
                return this.getState().root.findIndex((item: any) => item.id === groupOrContext.id);
            } else {
                // groupOrContext is a group
                let contextIndex, groupIndex;
                if (groupOrContext.contexts.length === 0) {
                    // the group has no contexts, so the group is member of Geen context. In this case, get the index of Geen context
                    contextIndex = this.getState().root.findIndex((item: any) => item.id === undefined);
                } else {
                    // the group has contexts. In this case, get the index of first context of the group
                    contextIndex = this.getState().root.findIndex((item: any) => item.id === groupOrContext.contexts[0].id);
                }
                if (contextIndex >= 0) {
                    // search the group index in current context
                    groupIndex = this.getState().root[contextIndex].groups.findIndex((item: any) => item.code === groupOrContext.code);
                    if (groupIndex >= 0) {
                        return contextIndex + Utils.defaultIdSeparator + GROUPS + Utils.defaultIdSeparator + groupIndex;
                    }
                }
                return -1;
            }
        },

        getGroupsContextsAsRootOfTree(groupContexts: any, groupSnapshots: any, expandTree: boolean, groupsContextTreeRef: React.RefObject<GroupContextTreeRaw<GroupContextTreeProps>>) {
            let groupsContextsTreeRoot = [];
            let emptyContext = { name: _msg("GroupsManagement.noContext.label"), groups: [], __typename: GROUP_CONTEXT } as any;
            for (let i = 0; i < groupContexts.length; i++) {
                let groupContext = { ...groupContexts[i], groups: [] };
                for (let j = 0; j < groupSnapshots.length; j++) {
                    for (let k = 0; k < groupSnapshots[j].contexts.length; k++) {
                        if (groupSnapshots[j].contexts[k].code === groupContext.code) {
                            groupContext.groups.push(groupSnapshots[j]);
                        }
                    }
                }
                groupsContextsTreeRoot.push(groupContext);
            }

            // add all groups with empty contexts property to empty context (Geen context)
            for (let i = 0; i < groupSnapshots.length; i++) {
                if (groupSnapshots[i].contexts.length === 0) {
                    emptyContext.groups.push(groupSnapshots[i]);
                }
            }

            // add empty context (Geen context) to tree
            groupsContextsTreeRoot.push(emptyContext);

            // sort contexts and groups from each context by name 
            groupsContextsTreeRoot.sort((a: any, b: any) => a.name < b.name ? -1 : 1);
            for (let i = 0; i < groupsContextsTreeRoot.length; i++) {
                groupsContextsTreeRoot[i].groups = groupsContextsTreeRoot[i].groups.sort((a: any, b: any) => a.name < b.name ? -1 : 1);
            }

            // expand and filter the tree
            if (expandTree) {
                this.expandGroupsContextsTree(groupsContextsTreeRoot, groupsContextTreeRef);
            }
            this.getDispatchers().setInReduxState({ groupsContextsTreeRoot });
            this.filterGroupsContextsTree();
        },

        expandGroupsContextsTree(groupsContextsTreeRoot: any, groupsContextTreeRef: React.RefObject<GroupContextTreeRaw<GroupContextTreeProps>>) {
            let expandedIds = {};
            for (let i = 0; i < groupsContextsTreeRoot.length; i++) {
                expandedIds = { ...expandedIds, [i]: true };
            }
            groupsContextTreeRef.current?.props.r.setInReduxState({ expandedIds });
            groupsContextTreeRef.current?.props.r.linearize(this.getState().root, {});
        },

        filterGroupsContextsTree() {
            if (this.getState().searchedGroupOrContext === "") {
                this.getDispatchers().setGroupsContextsTreeRoot();
                return;
            }
            let filteredGroupsContextsTreeRoot = filteringTree(this.getState().groupsContextsTreeRoot, this.getState().searchedGroupOrContext);
            this.getDispatchers().setInReduxState({ filteredGroupsContextsTreeRoot });
            this.getDispatchers().setGroupsContextsTreeRoot();
        },

        async removeGroupOrContext(selectedGroupAndContext: { selectedGroup: any, selectedContext: any; }, groupsContextTreeRef: React.RefObject<GroupContextTreeRaw<GroupContextTreeProps>>) {
            // a context will be removed
            if (selectedGroupAndContext.selectedGroup === undefined) {
                // if the context has groups, do nothing here. A warning has already been shown
                if (selectedGroupAndContext.selectedContext.groups.length !== 0) {
                    return;
                }
                // if context has no groups, remove context
                await apolloClient.mutate({
                    mutation: GROUP_SERVICE_FACADE_BEAN_REMOVE_GROUP_CONTEXT,
                    variables: {
                        id: selectedGroupAndContext.selectedContext.id
                    }
                });
            } else {
                // a group will be removed
                if (selectedGroupAndContext.selectedGroup.group.contexts.length === 0) {
                    // for groups from Geen context, check it the group has relations or parents
                    // if group has relations or parents, do nothing. A warning has already been shown
                    if ((selectedGroupAndContext.selectedGroup.relations !== null && selectedGroupAndContext.selectedGroup.relations.length > 0) ||
                        selectedGroupAndContext.selectedGroup.parentGroups.length > 0) {
                        return;
                    }
                    await apolloClient.mutate({
                        mutation: GROUP_SERVICE_FACADE_BEAN_REMOVE_GROUP,
                        variables: {
                            id: selectedGroupAndContext.selectedGroup.group.id
                        }
                    });
                } else {
                    // group is under a context, so remove the group from the context
                    // relations are kept and you need to remove it manually before deleting the group from (Geen context), so they are sent null in mutation
                    let contexts = this.getState().selectedGroupAndContext.selectedGroup.group.contexts.filter((context: any) =>
                        context.id !== this.getState().selectedGroupAndContext.selectedContext.id);
                    const groupWithCalendarId = _.cloneDeep(this.getState().selectedGroupAndContext.selectedGroup.group);
                    if (groupWithCalendarId.constraintDefinitions) {
                        groupWithCalendarId.constraintDefinitions = groupWithCalendarId.constraintDefinitions.map((constraintDefinition: any) => {
                            let newConstraintDefinition = constraintDefinition;
                            newConstraintDefinition.history = newConstraintDefinition.history.map((history: any) => {
                                history.calendar = history.calendar.id;
                                return history;
                            });
                            return newConstraintDefinition;
                        });
                    }
                    await apolloClient.mutate({
                        mutation: this.getDispatchers().groupsManagementBase.getGroupMutationByType(this.getState().selectedGroupAndContext.selectedGroup.group.__typename),
                        variables: {
                            group: { ...groupWithCalendarId, contexts: contexts },
                            relations: null
                        },
                        context: this.getDispatchers().groupsManagementBase.getMutationContext()
                    });
                }
            }
            groupsContextTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
            this.getDispatchers().setInReduxState({
                selectedGroupAndContext: { selectedGroup: undefined, selectedContext: undefined },
                unmodifiedSelectedGroupAndContext: { selectedGroup: undefined, selectedContext: undefined }
            });
            await this.loadGroupsContextsForTree(false, true, groupsContextTreeRef);
        },

        async saveOrUpdateCalendarDefinitions(group: any) {
            // get all calendarDefinitions from group
            if (group.constraintDefinitions === null || group.constraintDefinitions === undefined) {
                return group;
            }

            let calendarDefinitions: CalendarDefinition[] = [];
            for (let i = 0; i < group.constraintDefinitions.length; i++) {
                for (let j = 0; j < group.constraintDefinitions[i].history.length; j++) {
                    calendarDefinitions.push(group.constraintDefinitions[i].history[j].calendar);
                }
            }

            // save all calendarDefinitions
            if (calendarDefinitions.length > 0) {
                const savedCalendarDefinitions = (await apolloClient.mutate({
                    mutation: CALENDAR_DEFINITION_SERVICE_SAVE_CALENDAR_DEFINITIONS,
                    variables: {
                        calendarDefinitions: calendarDefinitions
                    }
                })).data.calendarDefinitionService_saveCalendarDefinitions;

                // replace all calendarDefinitions objects from selected group with its corresponding ids
                let constraintDefinitions = [];
                for (let i = 0; i < group.constraintDefinitions.length; i++) {
                    let history = [];
                    for (let j = 0; j < group.constraintDefinitions[i].history.length; j++) {
                        let newCalendarId = savedCalendarDefinitions.filter((item: any) => item.code === group.constraintDefinitions[i].history[j].calendar.code && (
                            group.constraintDefinitions[i].history[j].calendar.id === undefined || item.id === group.constraintDefinitions[i].history[j].calendar.id
                        ))[0].id;
                        history.push({ ...group.constraintDefinitions[i].history[j], calendar: newCalendarId });
                    }
                    constraintDefinitions.push({ ...group.constraintDefinitions[i], history });
                }
                group = { ...group, constraintDefinitions };
            }
            return group;
        }
    }
})

export class GroupsManagement extends TabbedPage<PropsFrom<typeof sliceGroupsManagement>> {

    protected constraintsTabRef = React.createRef<ConstraintsTab>();
    protected entityFormLightRef = React.createRef<EntityFormLightRaw>();
    protected groupsContextTreeRef = React.createRef<GroupContextTreeRaw>();
    protected sequenceTabRef = React.createRef<SequenceTab>();
    protected prevGroupsContextTreeRef = {} as any;

    getGroupOrContextDescriptor() {
        let groupOrContextDescriptor = new EntityDescriptor({ name: "GroupOrContext" })
            .addFieldDescriptor({ name: CODE, type: FieldType.string })
            .addFieldDescriptor({ name: NAME, type: FieldType.string })
            .addFieldDescriptor({ name: DESCRIPTION, type: FieldType.text })
            .addFieldDescriptor({ name: OWNER, type: "EmployeeSnapshot" }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        includeAllWithValidContract: true,
                        allowSystemSelection: true,
                        isClearable: false,
                        queryLimit: -1,
                    };
                    return React.createElement(EmployeeSnapshotAssociationFieldEditor as any, newProps as FieldEditorProps);
                }
            }());
        if (this.props.groupOrContextEditorContent.type === GROUP_SNAPSHOT) {
            groupOrContextDescriptor.addFieldDescriptor({ name: TYPE, type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: groupTypes } });
        }
        return groupOrContextDescriptor;
    }

    ////////////////////////////////////
    // METHODS USED BY TREE
    ////////////////////////////////////

    openMenuContext = (open: ModalExtOpen, treeElement: any, treeElementId: String[]) => {
        this.props.dispatchers.setInReduxState({ openContextMenuModal: open, treeElement, treeElementId });
    };

    /**
    * This method is needed by the tree (mandatory)
    */
    protected renderItem = (params: RenderItemParams) => {
        const objectId = params.linearizedItem.itemId.split(Utils.defaultIdSeparator);
        const object = Utils.navigate(this.props.root, objectId);
        if (object.__typename === GROUP_CONTEXT) {
            if (object.code) {
                return <>
                    <Header as="h5" className="GroupsManagement_contextsHeader"><Icon name="setting"></Icon>{object.name}</Header>
                    {this.renderAdditionalButtons(object, objectId)}
                    </>
            }
            return <Header as="h5" className="GroupsManagement_contextsHeader"><Icon name="setting"></Icon>{_msg("GroupsManagement.noContext.label")}</Header>
        } else {
            return <>
                <Icon name="group" size="large" className="GroupsManagement_groupsHeader"></Icon>{object.name}
                {this.renderAdditionalButtons(object, objectId)}
                </>
        }
    }

    renderAdditionalButtons(object: any, objectId: string[]) {
        return <>
            <Button primary icon="bars" size="mini" className="GroupsManagement_tree_buttons"
                onClick={event => {
                    this.openMenuContext!([event.clientX, event.clientY], object, objectId);
                }}
            />
        </>
    }

    renderMenuContext = () => {
        return <ModalExt open={this.props.openContextMenuModal}
            onClick={() => this.props.dispatchers.setInReduxState({ openContextMenuModal: false })}
            onClose={() => this.props.dispatchers.setInReduxState({ openContextMenuModal: false })}
            className="GroupsManagement_modal"
        >
            <Menu vertical>
                <Menu.Item
                    name="delete"
                    content={_msg("GroupsManagement.delete.label")}
                    icon="delete"
                    onClick={() => {
                        if (!this.entityHasBeenModified()) {
                            this.props.dispatchers.setInReduxState({ confirmations: { confirmDeleteGroupOrContext: true } });
                        } else {
                            this.props.dispatchers.setInReduxState({ confirmLeavePage: true, confirmations: { confirmDeleteGroupOrContext: true } });
                        }
                    }}
                />
            </Menu>
        </ModalExt>;
    };

    protected onDeleteCancel = () => {
        this.props.dispatchers.setInReduxState({ confirmations: undefined });
    }

    protected onDeleteConfirm = () => {
        this.props.dispatchers.removeGroupOrContext(this.props.selectedGroupAndContext, this.groupsContextTreeRef);
        this.props.dispatchers.setInReduxState({ confirmations: undefined });
    }

    /**
    * This method is used by Tree to prevent changing the current selected item in tree if it is modified
    */
    handleSelectedId = async (selectedId: string | undefined) => {
        if (selectedId) {
            const groupOrContext = Utils.navigate(this.props.root, selectedId);
            this.props.dispatchers.setInReduxState({ activeTabIndex: 0, oldTabData: undefined });
            if (groupOrContext.__typename === GROUP_CONTEXT) {
                // unmodifiedSelectedGroupAndContext is keeping the original values for the object, 
                // so the modified entity (selectedGroupAndContext) can be compared with the original one
                // in this case, the unmodified data can not be retrieved from the tree because the group used by tree is a GroupSnapshot, 
                // not a static, dynamic or composition group
                let context = this.props.groupsContextsTreeRoot.filter((item: any) => item.id === groupOrContext.id)[0];
                this.props.dispatchers.setInReduxState({
                    selectedGroupAndContext: { selectedGroup: undefined, selectedContext: context },
                    unmodifiedSelectedGroupAndContext: { selectedGroup: undefined, selectedContext: context },
                });
            } else {
                let group = await this.props.dispatchers.groupsManagementBase.getGroupByType(groupOrContext.id, groupOrContext.type.code);
                const relations = await this.props.dispatchers.groupsManagementBase.getGroupRelations(groupOrContext.id);
                const parentGroups = await this.props.dispatchers.groupsManagementBase.getParentGroups(groupOrContext.id);
                const selectedIdTokens = selectedId.split(Utils.defaultIdSeparator);
                const selectedGroupAndContext = { selectedGroup: { group, relations, parentGroups }, selectedContext: this.props.root[Number(selectedIdTokens[0])] };
                this.props.dispatchers.setInReduxState({ selectedGroupAndContext: selectedGroupAndContext, unmodifiedSelectedGroupAndContext: selectedGroupAndContext });
            }
        }
    }

    protected onSelectItem = (item: any) => {
        if (this.entityHasBeenModified()) {
            this.props.dispatchers.setInReduxState({ confirmLeavePage: true, newOperationToBeConfirmed: { ...this.props.newOperationToBeConfirmed, newSelectedElementInTree: item.itemId } });
            item.prevent = true;
        }
    }

    /**
    * This method is used by Tree to prevent expanding/collapsing elements in tree if the current selected element is modified
    */
    protected onExpandCollapseItem = (item: any) => {
        if (this.entityHasBeenModified()) {
            this.props.dispatchers.setInReduxState({ expandCollapseItemId: item.itemId });
            item.prevent = true;
        }
    }

    ////////////////////////////////////
    // METHODS USED BY GROUP CONTEXT TAB
    ////////////////////////////////////

    renderOnChangeForGroupOrContext = (fieldName: string, data: string | number | undefined) => {
        const fieldValue = fieldName === OWNER ? (String(data).length > 0 && data !== -1 ? Number(data) : null) : data;
        if (this.props.selectedGroupAndContext.selectedGroup === undefined) {
            // edit values from a context accordion
            this.props.dispatchers.setFieldsForSelectedContext({ fieldName, fieldValue });
        } else {
            // edit values from a group accordion
            this.props.dispatchers.setFieldsForSelectedGroup({ fieldName, fieldValue });
        }
    }

    removeGroupFromGroupContextTable = (groupToBeDeletedFromCurrentContext: GroupTable) => {
        if (this.props.selectedGroupAndContext.selectedContext.id === undefined) {
            // current context is Geen context
            if ((groupToBeDeletedFromCurrentContext.hasParents || groupToBeDeletedFromCurrentContext.hasRelations)) {
                // if the group has relations or is part of a composition group, it can not be deleted. Do nothing here, a warning has been shown
                return;
            }
        }
        let remainingGroups = this.props.selectedGroupAndContext.selectedContext.groups.filter((item: any) => item.id !== groupToBeDeletedFromCurrentContext.group.id);
        this.props.dispatchers.setInReduxState({
            groupsToBeDeletedFromCurrentContext: [...this.props.groupsToBeDeletedFromCurrentContext, groupToBeDeletedFromCurrentContext.group]
        });
        this.props.dispatchers.setFieldsForSelectedContext({ fieldName: GROUPS, fieldValue: remainingGroups });
    }

    addExistingGroupToCurrentContext = (groupId: number) => {
        // check if this group already exists in current context
        if (this.props.selectedGroupAndContext.selectedContext.groups.filter((item: any) => item.id === groupId).length > 0) {
            // current context already contains the group, do nothing here
            return;
        }
        let group = [];
        let groupContexts = this.props.groupSnapshotOptions.filter((item: GroupOption) => item.key === groupId)[0].contexts;
        let contextId: undefined | number | null = undefined;
        // because we need to add the group to current selected context, we need to find the group with its full informations
        if (groupContexts.length > 0) {
            // this group has context (is not part of Geen context), so find its first context id
            contextId = groupContexts[0].id;
        }
        group = this.props.groupsContextsTreeRoot.filter((item: any) => item.id === contextId)[0].groups.filter((item: any) =>
            item.id === groupId);
        this.props.dispatchers.setFieldsForSelectedContext({ fieldName: GROUPS, fieldValue: this.props.selectedGroupAndContext.selectedContext.groups.concat(group) });
        this.props.dispatchers.setInReduxState({ groupsToBeAddedToCurrentContext: [...this.props.groupsToBeAddedToCurrentContext, groupId] });
    }

    ////////////////////////////////////
    // METHODS USED BY GROUP SHAPSHOT TAB (GENERAL)
    ////////////////////////////////////

    addContextToCurrentGroup = (contextToBeAdded: BasicFields) => {
        if (contextToBeAdded === undefined) {
            this.props.dispatchers.setInReduxState({ showModalMessage: { isOpen: true, entityName: CONTEXT } });
            return;
        }
        // check if the context already exists for the current group
        if (this.props.selectedGroupAndContext.selectedGroup.group.contexts.filter((item: any) => item.id === contextToBeAdded.id).length > 0) {
            // this context already exists in the group, do nothing here
            return;
        }
        let context: any = { ...this.props.groupsContextsTreeRoot.filter((item: any) => item.id === contextToBeAdded.id)[0] };
        delete context.groups;
        let contexts = [context].concat(this.props.selectedGroupAndContext.selectedGroup.group.contexts);
        this.props.dispatchers.setFieldsForSelectedGroup({ fieldName: CONTEXTS, fieldValue: contexts });
    }

    deleteContextFromCurrentGroup = (contextToBeDeleted: BasicFields) => {
        let contexts = this.props.selectedGroupAndContext.selectedGroup.group.contexts.filter((item: any) => item.id !== contextToBeDeleted.id);
        this.props.dispatchers.setFieldsForSelectedGroup({ fieldName: CONTEXTS, fieldValue: contexts });
    }

    addOrUpdateGroupProperty = (editedProperty: GroupPropertyEditor) => {
        if (editedProperty.values[GROUP_PROPERTY_CT]?.code === undefined || editedProperty.values[CUSTOM_VALUE].toString().trim().length === 0) {
            this.props.dispatchers.setInReduxState({ showModalMessage: { isOpen: true, entityName: PROPERTY } });
            return;
        }
        let properties = this.props.selectedGroupAndContext.selectedGroup.group.properties;
        let property = properties.filter((item: any) => item.code === editedProperty.values[GROUP_PROPERTY_CT].code);
        let editedPropertyDataType = this.props.groupSnapshotTab.groupPropertiesConstants.filter((item: any) =>
            item.code === editedProperty.values[GROUP_PROPERTY_CT]?.code)[0].dataType;
        if (property.length === 0 || (editedPropertyDataType === ProteusConstants.STRING && editedProperty.rowIndex === -1)) {
            // the property does not exists in the current selected group or the property exists but its dataType is STRING => add the property
            properties = properties.concat({ code: editedProperty.values[GROUP_PROPERTY_CT].code, value: String(editedProperty.values[CUSTOM_VALUE]).trim() });
        } else {
            if (editedPropertyDataType === ProteusConstants.STRING) {
                // the property is edited. Because two properties with the same code (and without an id) can exists, we must find the right one to be edited
                property = properties.filter((item: any) => item.code === editedProperty.values[GROUP_PROPERTY_CT].code && item.value === editedProperty.values[GROUP_PROPERTY_CT].value);
                properties = properties.filter((item: any) => item.code !== property[0].code || item.value !== property[0].value);
            } else {
                properties = properties.filter((item: any) => item.code !== property[0].code);
            }
            properties = properties.concat({ ...property[0], value: String(editedProperty.values[CUSTOM_VALUE]).trim() });
        }
        this.props.dispatchers.setFieldsForSelectedGroup({ fieldName: PROPERTIES, fieldValue: properties });
    }

    deleteGroupProperty = (propertyToBeDeleted: getCompositionGroup_compositionGroupService_compositionGroup_groups_properties) => {
        let properties = this.props.selectedGroupAndContext.selectedGroup.group.properties.filter((property: any) =>
            property.code !== propertyToBeDeleted.code || property.value !== propertyToBeDeleted.value);
        this.props.dispatchers.setFieldsForSelectedGroup({ fieldName: PROPERTIES, fieldValue: properties });
    }

    ////////////////////////////////////
    // METHODS USED BY GROUP SHAPSHOT TAB (COMPOSITION GROUP TYPE)
    ////////////////////////////////////

    addGroupsToCompositionGroup = (groupToBeAdded: BasicFields) => {
        this.props.dispatchers.addGroupsToCompositionGroup(groupToBeAdded);
    }

    deleteGroupFromCompositionGroup = (groupToBeDeleted: { id: number, group: string; }) => {
        let groups = this.props.selectedGroupAndContext.selectedGroup.group.groups.filter((item: any) => item.id !== groupToBeDeleted.id);
        this.props.dispatchers.setFieldsForSelectedGroup({ fieldName: GROUPS, fieldValue: groups });
    }

    ////////////////////////////////////
    // METHODS USED BY GROUP SHAPSHOT TAB (STATIC GROUP TYPE)
    ////////////////////////////////////

    addOrUpdateResourcesHistory = (resourcesHistory: ResourcesHistoryItem[]) => {
        let groupResourcesHistory = this.addOrUpdateHistoryItem(resourcesHistory, RESOURCES, []);
        this.props.dispatchers.setFieldsForSelectedGroup({ fieldName: ProteusConstants.GROUP_RESOURCES_HISTORY, fieldValue: groupResourcesHistory });
    }

    deleteResourceHistory = (remainingResourcesHistory: ResourcesHistoryItem[]) => {
        this.props.dispatchers.setFieldsForSelectedGroup({ fieldName: ProteusConstants.GROUP_RESOURCES_HISTORY, fieldValue: remainingResourcesHistory });
    }

    addResourceToResourceHistoryItem = (resourceHistoryItem: any, resource: ResourceTable) => {
        if (resource[EMPLOYEE]?.id === undefined) {
            this.props.dispatchers.setInReduxState({ showModalMessage: { isOpen: true, entityName: EMPLOYEE_SNAPSHOT } });
            return;
        }
        // check if the resource already exists
        if (resourceHistoryItem.resources.filter((item: any) => item === resource[EMPLOYEE].id).length > 0) {
            return;
        }
        let resources = [...resourceHistoryItem.resources, resource[EMPLOYEE].id];
        this.setResourcesToCurrentResourceHistoryItem(resourceHistoryItem, resources);
    }

    deleteResourceFromResourceHistoryItem = (resourceHistoryItem: ResourcesHistoryItem, resource: ResourceTable) => {
        let resources = resourceHistoryItem.resources.filter((item: any) => item !== resource[EMPLOYEE].id);
        this.setResourcesToCurrentResourceHistoryItem(resourceHistoryItem, resources);
    }

    setResourcesToCurrentResourceHistoryItem(resourceHistoryItem: any, resources: any) {
        let editedResourceHistoryItem = { ...resourceHistoryItem, resources };
        let resourcesHistoryItem = this.props.selectedGroupAndContext.selectedGroup.group.groupResourcesHistory.filter((item: any) =>
            item.validFrom !== editedResourceHistoryItem.validFrom);
        resourcesHistoryItem.push(editedResourceHistoryItem);
        this.props.dispatchers.setFieldsForSelectedGroup({ fieldName: ProteusConstants.GROUP_RESOURCES_HISTORY, fieldValue: resourcesHistoryItem });
    }

    addOrUpdateHistoryItem(historyItem: any, additionalFieldName: string, additionalFieldValue: any, isCopy?: boolean, validFrom?: string) {
        let newHistoryItem = [];
        for (let i = 0; i < historyItem.length; i++) {
            newHistoryItem.push({
                ...historyItem[i],
                validFrom: historyItem[i].validFrom === null || historyItem[i].validFrom === undefined ? undefined :
                    this.formatDate(historyItem[i].validFrom),
                validUntil: historyItem[i].validUntil === null || historyItem[i].validUntil === undefined ? undefined :
                    this.formatDate(historyItem[i].validUntil),
                [additionalFieldName]: (historyItem[i][additionalFieldName] === null || historyItem[i][additionalFieldName] === undefined ||
                    (additionalFieldName === CALENDAR && !historyItem[i].id && historyItem[i].calendar !== undefined && isCopy && this.formatDate(validFrom!.toString()) === this.formatDate(historyItem[i].validFrom))) ?
                    additionalFieldValue : historyItem[i][additionalFieldName]
            });
        }
        return newHistoryItem;
    }

    ////////////////////////////////////
    // METHODS USED BY SUCCESSION TAB
    ////////////////////////////////////

    deleteSuccessionDefinitionHistory = (remainingSuccessionDefinitionHistory: SuccessionDefinitionHistory[]) => {
        this.props.dispatchers.setSuccessionDefinitionHistory(remainingSuccessionDefinitionHistory);
    }

    addOrUpdateSuccessionDefinitionHistory = (successionDefinitionHistory: SuccessionDefinitionHistory[]) => {
        let successionHistory = this.addOrUpdateHistoryItem(successionDefinitionHistory, RESOURCES, []);
        this.props.dispatchers.setSuccessionDefinitionHistory(successionHistory);
    }

    deleteResourceFromSuccessionHistoryItem = (successionHistoryItem: SuccessionDefinitionHistory, resource: ResourceTable) => {
        let resources = successionHistoryItem.resources.filter((item: number) => item !== resource[EMPLOYEE].id);
        this.setResourcesToCurrentSuccessionHistoryItem(successionHistoryItem, resources);
    }

    addResourceToSuccessionHistoryItem = (successionHistoryItem: SuccessionDefinitionHistory, addedResource: ResourceTable) => {
        if (addedResource[EMPLOYEE]?.id === undefined) {
            this.props.dispatchers.setInReduxState({ showModalMessage: { isOpen: true, entityName: EMPLOYEE_SNAPSHOT } });
            return;
        }
        // check if the resource already exists
        if (successionHistoryItem.resources.filter((item: any) => item === addedResource[EMPLOYEE].id).length > 0) {
            return;
        }
        let resources: number[] = [...successionHistoryItem.resources, addedResource[EMPLOYEE].id!];
        this.setResourcesToCurrentSuccessionHistoryItem(successionHistoryItem, resources);
    }

    setResourcesToCurrentSuccessionHistoryItem(successionHistoryItem: SuccessionDefinitionHistory, resources: number[]) {
        let editedSuccessionHistoryItem = { ...successionHistoryItem, resources };
        let successionHistory = this.props.selectedGroupAndContext.selectedGroup.group.successionDefinition.history.filter((item: any) =>
            item.validFrom !== editedSuccessionHistoryItem.validFrom);
        successionHistory.push(editedSuccessionHistoryItem);
        this.props.dispatchers.setSuccessionDefinitionHistory(successionHistory);
    }

    addGroupMembersToSuccession = (successionHistoryItem: SuccessionDefinitionHistory, groupMembers: getGroupMembers_employeeService_employeeSnapshotsByGroup[], successionMembers: number[]) => {
        let atLeastOneMemberHasBeenAdded = { value: false };
        let members = this.addGroupMembersToHistoryItem(groupMembers, successionMembers, atLeastOneMemberHasBeenAdded);

        // check if at least one member has been added. There can be cases when all group members are already members of succession
        // in this case, no new member is added, but when setResourcesToCurrentSuccessionHistoryItem is called, the order of history objects in successionHistory array
        // might change and this will trigger the "entityHasBeenChanged" method and enable save button, even if no new member is added
        if (atLeastOneMemberHasBeenAdded.value) {
            this.setResourcesToCurrentSuccessionHistoryItem(successionHistoryItem, members);
        }
    }

    addGroupMembersToHistoryItem = (groupMembers: getGroupMembers_employeeService_employeeSnapshotsByGroup[], historyMembers: number[], atLeastOneMemberHasBeenAdded: { value: boolean }) => {
        let members = [...historyMembers];
        for (let i = 0; i < groupMembers.length; i++) {
            if (groupMembers[i].id !== null) {
                if (members.includes(groupMembers[i].id!)) {
                    // the group member is already member of succession
                    continue;
                }
                members.push(groupMembers[i].id!);
                atLeastOneMemberHasBeenAdded.value = true;
            }
        }
        return members;
    }

    moveSuccessionResourcesUpOrDown = (successionHistoryItem: SuccessionDefinitionHistory, resourceId: number, moveUp: boolean) => {
        let history = [...this.props.selectedGroupAndContext.selectedGroup.group.successionDefinition.history];
        history = this.moveResourceUpOrDown(history, successionHistoryItem, resourceId, moveUp);
        this.props.dispatchers.setSuccessionDefinitionHistory(history);
    }

    moveResourceInArray(resources: number[], resourceIndex: number, resourceId: number, position: number) {
        resources.splice(resourceIndex, 1);
        resources.splice(resourceIndex + position, 0, resourceId);
    }

    moveResourceUpOrDown = (history: SuccessionDefinitionHistory[], historyItem: SuccessionDefinitionHistory, resourceId: number, moveUp: boolean) => {
        let indexOfCurrentHistoryItem = history.indexOf(history.filter((item: SuccessionDefinitionHistory) => item.validFrom === historyItem.validFrom)[0]);
        let currentHistoryItem = history.splice(indexOfCurrentHistoryItem, 1)[0];
        let resources = [...currentHistoryItem.resources];
        let resourceIndex = resources.indexOf(resourceId);
        if (resourceIndex > 0 && moveUp) {
            this.moveResourceInArray(resources, resourceIndex, resourceId, -1);
        } else if (resourceIndex < resources.length - 1 && !moveUp) {
            this.moveResourceInArray(resources, resourceIndex, resourceId, 1);
        }
        currentHistoryItem = { ...currentHistoryItem, resources };
        history.splice(indexOfCurrentHistoryItem, 0, currentHistoryItem);
        return history;
    }

    ////////////////////////////////////
    // METHODS USED BY SECURITY TAB
    ////////////////////////////////////

    addOrUpdateSecurityDefinitionHistory = (securityDefinitionHistory: SecurityDefinitionHistory[]) => {
        let securityDefinition = this.addOrUpdateHistoryItem(securityDefinitionHistory, PROFILES, []);
        this.props.dispatchers.setSecurityDefinitionHistory(securityDefinition);
    }

    deleteSecurityDefinitionHistory = (remainingSecurityDefinitionHistory: SecurityDefinitionHistory[]) => {
        this.props.dispatchers.setSecurityDefinitionHistory(remainingSecurityDefinitionHistory);
    }

    deleteProfileFromSecurityHistoryItem = (securityHistoryItem: SecurityDefinitionHistory, profile: SecurityProfilesTable) => {
        let profiles = securityHistoryItem.profiles.filter((item: any) => item.id !== profile[PROFILES].id);
        this.setProfilesToCurrentSecurityHistoryItem(securityHistoryItem, profiles);
    }

    addProfileToSecurityHistoryItem = (securityHistoryItem: SecurityDefinitionHistory, addedProfile: SecurityProfilesTable) => {
        if (addedProfile[PROFILES]?.id === undefined) {
            this.props.dispatchers.setInReduxState({ showModalMessage: { isOpen: true, entityName: PROFILES } });
            return;
        }
        // check if the profile already exists
        if (securityHistoryItem.profiles.filter((item: any) => item.id === addedProfile[PROFILES].id).length > 0) {
            return;
        }
        let profiles: any = [...securityHistoryItem.profiles, addedProfile[PROFILES]];
        this.setProfilesToCurrentSecurityHistoryItem(securityHistoryItem, profiles);
    }

    setProfilesToCurrentSecurityHistoryItem(securityHistoryItem: SecurityDefinitionHistory, profiles: SecurityProfiles[]) {
        let editedSecurityHistoryItem = { ...securityHistoryItem, profiles };
        let securityHistory = this.props.selectedGroupAndContext.selectedGroup.group.securityDefinition.history.filter((item: any) =>
            item.validFrom !== editedSecurityHistoryItem.validFrom);
        securityHistory.push(editedSecurityHistoryItem);
        this.props.dispatchers.setSecurityDefinitionHistory(securityHistory);
    }

    ////////////////////////////////////
    // METHODS USED BY RELATION TAB
    ////////////////////////////////////

    addOrUpdateGroupRelation = (groupRelation: GroupRelationEditor) => {
        let relations = [...this.props.selectedGroupAndContext.selectedGroup.relations];
        let relation: GroupRelation;
        // the row was edited
        if (groupRelation.rowIndex !== -1) {
            // find the corresponding relation in group relations
            relation = relations.filter((item: any) => {
                if (groupRelation.values.direction === IN) {
                    return item.target === groupRelation.values.id && item.type.code === groupRelation.values.relationTypeCode;
                } else {
                    return item.source === groupRelation.values.id && item.type.code === groupRelation.values.relationTypeCode;
                }
            })[0];
            if (!this.groupRelationTypeChanged(relation, groupRelation)) {
                // this is the case when the group relation type editor is opened and saved without actually modifying the relation type
                // in this case, do not trigger any entity change
                return;
            }
            // filter the edited relation. It will be added later
            relations = relations.filter((item: any) => item.source !== relation.source || item.target !== relation.target || item.type.code !== relation.type?.code);
            if (relation.type?.inLabel !== groupRelation.values.relationType && relation.type?.outLabel !== groupRelation.values.relationType) {
                // the relation type must be changed
                const relationType = this.props.relationTab.groupRelationTypes.filter((item: any) => item.inLabel === groupRelation.values.relationType ||
                    item.outLabel === groupRelation.values.relationType)[0];
                relation = { ...relation, type: relationType };
            }
            relation = this.changeRelationDirection(groupRelation, relation);
        } else {
            // a new relation is added. Check if it has a group and a relation type 
            if (groupRelation.values.group === undefined || groupRelation.values.group === null || groupRelation.values.relationType === undefined) {
                this.props.dispatchers.setInReduxState({ showModalMessage: { isOpen: true, entityName: RELATIONS } });
                return;
            }

            // a group can not have a relation with itself
            if (groupRelation.values.group.id === this.props.selectedGroupAndContext.selectedGroup.group.id) {
                this.props.dispatchers.setInReduxState({ showModalMessage: { isOpen: true, entityName: RELATIONS, selfRelation: true } });
                return;
            }

            const relationType = this.props.relationTab.groupRelationTypes.filter((item: any) =>
                item.inLabel === groupRelation.values.relationType || item.outLabel === groupRelation.values.relationType)[0];
            relation = {
                source: groupRelation.values.relationType === relationType.inLabel ? this.props.selectedGroupAndContext.selectedGroup.group.id : groupRelation.values.group.id,
                target: groupRelation.values.relationType === relationType.inLabel ? groupRelation.values.group.id : this.props.selectedGroupAndContext.selectedGroup.group.id,
                type: relationType
            };
        }
        // check if there is already a relation with the same source, target and relation type
        // in this case, show a message to user and do not do any change
        if (this.groupRelationAlreadyExists(relation, relations)) {
            this.props.dispatchers.setInReduxState({
                showModalMessage: {
                    isOpen: true,
                    entityName: RELATIONS,
                    additionalFields: [groupRelation.values.relationType, groupRelation.values.name !== undefined ? groupRelation.values.name : groupRelation.values.group.name]
                }
            });
            return;
        }
        relations.push(relation);
        this.props.dispatchers.setFieldsForSelectedGroup({ fieldName: RELATIONS, fieldValue: relations });
    }

    groupRelationAlreadyExists(relation: GroupRelation, groupRelations: GroupRelation[]) {
        return groupRelations.filter((item: any) =>
            item.source === relation.source && item.target === relation.target && item.type.code === relation.type.code).length === 0 ? false : true;
    }

    groupRelationTypeChanged(relation: GroupRelation, groupRelation: GroupRelationEditor) {
        if ((groupRelation.values.direction === IN && groupRelation.values.relationType === relation.type.inLabel) ||
            (groupRelation.values.direction === OUT && groupRelation.values.relationType === relation.type.outLabel)) {
            return false;
        }
        return true;
    }

    changeRelationDirection(groupRelation: GroupRelationEditor, relation: GroupRelation) {
        if (groupRelation.values.relationType === relation.type.inLabel) {
            relation = { ...relation, source: this.props.selectedGroupAndContext.selectedGroup.group.id, target: groupRelation.values.id };
        } else {
            relation = { ...relation, source: groupRelation.values.id, target: this.props.selectedGroupAndContext.selectedGroup.group.id };
        }
        return relation;
    }

    deleteGroupRelation = (groupRelation: GroupRelationTable) => {
        const remainingRelations = this.props.selectedGroupAndContext.selectedGroup.relations.filter((item: any) => {
            if (groupRelation.direction === IN) {
                return item.target !== groupRelation.id || item.type.code !== groupRelation.relationTypeCode;
            } else {
                return item.source !== groupRelation.id || item.type.code !== groupRelation.relationTypeCode;
            }
        });
        this.props.dispatchers.setFieldsForSelectedGroup({ fieldName: RELATIONS, fieldValue: remainingRelations });
    }

    ////////////////////////////////////
    // METHODS USED BY CONSTRAINTS TAB
    ////////////////////////////////////

    addOrUpdateConstraintDefinition = (constraintDefinition: ConstraintDefinitionEditor) => {
        let constraints = [...this.props.selectedGroupAndContext.selectedGroup.group.constraintDefinitions];
        const constraintNameNotNull = constraintDefinition.values.name !== null && constraintDefinition.values.name !== undefined && constraintDefinition.values.name !== "";
        let constraintNameIsUnique = true;
        // check if there exists another constraint with the same name
        for (let i = 0; i < constraints.length; i++) {
            if (i !== constraintDefinition.rowIndex && constraints[i].name === constraintDefinition.values.name) {
                constraintNameIsUnique = false;
                break;
            }
        }
        if (!constraintNameNotNull || !constraintNameIsUnique) {
            this.props.dispatchers.setInReduxState({ showModalMessage: { isOpen: true, entityName: ProteusConstants.CONSTRAINT_DEFINITIONS } });
            return;
        }

        let constraint = undefined;
        if (constraintDefinition.rowIndex === -1) {
            // add a new constraintDefinition
            constraint = {
                name: constraintDefinition.values.name,
                description: constraintDefinition.values.description,
                validForRegistrationTypes: constraintDefinition.values.validForRegistrationTypes !== undefined ? constraintDefinition.values.validForRegistrationTypes : [],
                validOnRegistrationTypes: constraintDefinition.values.validOnRegistrationTypes !== undefined ? constraintDefinition.values.validOnRegistrationTypes : [],
                history: []
            };
            constraints.push(constraint);
        } else {
            // edit an existing constraintDefinition
            constraint = constraints.splice(constraintDefinition.rowIndex, 1)[0];
            constraint = {
                ...constraint,
                name: constraintDefinition.values.name,
                description: constraintDefinition.values.description
            };
            constraints.splice(constraintDefinition.rowIndex, 0, constraint);
        }
        this.props.dispatchers.setFieldsForSelectedGroup({ fieldName: ProteusConstants.CONSTRAINT_DEFINITIONS, fieldValue: constraints });
    }

    deleteConstraintDefinition = (remainingConstraints: ConstraintDefinition[]) => {
        this.props.dispatchers.setFieldsForSelectedGroup({ fieldName: ProteusConstants.CONSTRAINT_DEFINITIONS, fieldValue: remainingConstraints });
    }

    addValidOnRegistrationType = (currentConstraintIndex: number, registrationType: RegistrationType) => {
        let constraints = [...this.props.selectedGroupAndContext.selectedGroup.group.constraintDefinitions];

        // check if the registration type already exists for the current selected constraint
        if (registrationType === undefined || constraints[currentConstraintIndex].validOnRegistrationTypes.filter((item: string) => item === registrationType.code).length > 0) {
            this.props.dispatchers.setInReduxState({ showModalMessage: { isOpen: true, entityName: WORK_PERIOD_TYPE } });
            return;
        }
        const newRegistrationTypes = [...constraints[currentConstraintIndex].validOnRegistrationTypes, registrationType.code];
        this.setFieldValueToCurrentConstraint(currentConstraintIndex, newRegistrationTypes, VALID_ON_REGISTRATION_TYPES);
    }

    deleteValidOnRegistrationType = (currentConstraintIndex: number, remainingRegistrationTypes: string[]) => {
        this.setFieldValueToCurrentConstraint(currentConstraintIndex, remainingRegistrationTypes, VALID_ON_REGISTRATION_TYPES);
    }

    setFieldValueToCurrentConstraint = (currentConstraintIndex: number, fieldValue: any, fieldName: string) => {
        const constraints: ConstraintDefinition[] = [...this.props.selectedGroupAndContext.selectedGroup.group.constraintDefinitions];
        let constraint = constraints.splice(currentConstraintIndex, 1)[0];
        constraint = { ...constraint, [fieldName]: fieldValue };
        constraints.splice(currentConstraintIndex, 0, constraint);
        this.props.dispatchers.setFieldsForSelectedGroup({ fieldName: ProteusConstants.CONSTRAINT_DEFINITIONS, fieldValue: constraints });
    }

    addValidForRegistrationType = (currentConstraintIndex: number, registrationType: string[]) => {
        this.setFieldValueToCurrentConstraint(currentConstraintIndex, registrationType, VALID_FOR_REGISTRATION_TYPES);
    }

    deleteValidForRegistrationType = (currentConstraintIndex: number, remainingRegistrationTypes: string[]) => {
        this.setFieldValueToCurrentConstraint(currentConstraintIndex, remainingRegistrationTypes, VALID_FOR_REGISTRATION_TYPES);
    }

    deleteConstraintDefinitionHistory = (currentConstraintIndex: number, remainingHistory: ConstraintDefinitionHistory[]) => {
        const history = this.updateCalendarDescription(remainingHistory);
        this.setFieldValueToCurrentConstraint(currentConstraintIndex, history, HISTORY);
    }

    addOrUpdateConstraintDefinitionHistory = (currentConstraintIndex: number, constraintDefinitionHistory: ConstraintDefinitionHistory[], addedConstraint: any) => {
        let newCalendar = {};
        let isCopy: boolean = false;
        if (addedConstraint.values.id === undefined && addedConstraint.values.calendar !== undefined && addedConstraint.rowIndex === -1) {
            newCalendar = this.updateIdsForCalendarDefinition(addedConstraint.values.calendar);
            isCopy = true;
        } else {
            newCalendar = {
                code: "CC_" + new Date().valueOf(),
                items: [],
                properties: [],
                extendDefinitions: []
            };
        }
        let history = this.addOrUpdateHistoryItem(constraintDefinitionHistory, CALENDAR, newCalendar, isCopy, addedConstraint.values.validFrom);
        history = this.updateCalendarDescription(history);
        this.setFieldValueToCurrentConstraint(currentConstraintIndex, history, HISTORY);
    }

    updateCalendarDescription(history: (ConstraintDefinitionHistory & { description?: string })[]) {
        let newHistory = [];
        for (let i = 0; i < history.length; i++) {
            let item = { ...history[i] };
            if (item.description !== undefined) {
                item = { ...item, calendar: { ...item.calendar, description: item.description } };
                delete item.description;
            }
            newHistory.push(item);
        }
        return newHistory;
    }

    updateIdsForCalendarDefinition(calendarDefintion: CalendarDefinition) {
        let newCalendarDefintion: any = { id: undefined, code: "CC_" + new Date().valueOf() };
        let items = [];
        let properties = [];

        for (let item of calendarDefintion.items) {
            items.push(this.updateIdsForCalendarItem(item));
        }

        for (let property of calendarDefintion.properties) {
            property = { ...property, id: undefined };
            if (property.item) {
                property = { ...property, item: this.updateIdsForCalendarItem(property.item) };
            }
            properties.push(property);
        }
        newCalendarDefintion = { ...newCalendarDefintion, items, properties, extendDefinitions: calendarDefintion.extendDefinitions };
        return newCalendarDefintion;
    }

    updateIdsForCalendarItem(item: any) {
        let newItem = { ...item, id: undefined, objectVersion: undefined };
        if (item.recurrenceDefinitionDto) {
            newItem = { ...newItem, recurrenceDefinitionDto: { ...newItem.recurrenceDefinitionDto, id: undefined, objectVersion: undefined } };
            if (item.recurrenceDefinitionDto.range) {
                newItem = { ...newItem, recurrenceDefinitionDto: { ...newItem.recurrenceDefinitionDto, range: { ...newItem.recurrenceDefinitionDto.range, id: undefined, objectVersion: undefined } } };
            }
            if (item.recurrenceDefinitionDto.pattern) {
                newItem = { ...newItem, recurrenceDefinitionDto: { ...newItem.recurrenceDefinitionDto, pattern: { ...newItem.recurrenceDefinitionDto.pattern, id: undefined, objectVersion: undefined } } };
            }
        }
        return newItem;
    }

    updateCalendarDefinition(calendarDefinition: CalendarDefinition, currentConstraintIndex: number, currentHistoryItemIndex: number) {
        let constraintDefinitions = [...this.props.selectedGroupAndContext.selectedGroup.group.constraintDefinitions];
        let currentConstraintDefinition = constraintDefinitions.splice(currentConstraintIndex, 1)[0];
        let historyItems = [...currentConstraintDefinition.history];
        const currentHistoryItemIndexInGroup = historyItems.findIndex((item: ConstraintDefinitionHistory) =>
            item.validFrom === this.constraintsTabRef.current?.constraintDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[currentHistoryItemIndex].validFrom);
        let historyItem = historyItems.splice(currentHistoryItemIndexInGroup, 1)[0];
        historyItem = { ...historyItem, calendar: calendarDefinition };
        historyItems.splice(currentHistoryItemIndexInGroup, 0, historyItem);
        currentConstraintDefinition = { ...currentConstraintDefinition, history: historyItems };
        constraintDefinitions.splice(currentConstraintIndex, 0, currentConstraintDefinition);
        this.props.dispatchers.setConstraintDefinitions(constraintDefinitions);
    }

    ////////////////////////////////////
    // METHODS USED BY SEQUENCE TAB
    ////////////////////////////////////

    deleteSequenceDefinitionHistory = (remainingHistoryItems: SequenceDefinitionHistory[]) => {
        this.props.dispatchers.setSequenceDefinitionHistory(remainingHistoryItems);
    }

    addOrUpdateSequenceDefinitionHistory = (historyItems: SequenceDefinitionHistory[]) => {
        let history = this.addOrUpdateHistoryItem(historyItems, RESOURCES, []);
        this.props.dispatchers.setSequenceDefinitionHistory(history);
    }

    moveSequenceResourcesUpOrDown = (sequenceHistoryItem: SequenceDefinitionHistory, resourceId: number, moveUp: boolean) => {
        let history = [...this.props.selectedGroupAndContext.selectedGroup.group.sequenceDefinition.history];
        history = this.moveResourceUpOrDown(history, sequenceHistoryItem, resourceId, moveUp);
        this.props.dispatchers.setSequenceDefinitionHistory(history);
    }

    addGroupMembersToSequence = (sequenceHistoryItem: SequenceDefinitionHistory, groupMembers: getGroupMembers_employeeService_employeeSnapshotsByGroup[], sequenceMembers: number[]) => {
        let atLeastOneMemberHasBeenAdded = { value: false };
        let members = this.addGroupMembersToHistoryItem(groupMembers, sequenceMembers, atLeastOneMemberHasBeenAdded);

        if (atLeastOneMemberHasBeenAdded.value) {
            this.setResourcesToCurrentSequenceHistoryItem(sequenceHistoryItem, members);
            return members;
        }
    }

    setResourcesToCurrentSequenceHistoryItem(sequenceHistoryItem: SequenceDefinitionHistory, resources: number[]) {
        let editedSequenceHistoryItem = { ...sequenceHistoryItem, resources };
        let sequenceHistory = this.props.selectedGroupAndContext.selectedGroup.group.sequenceDefinition.history.filter((item: SequenceDefinitionHistory) =>
            item.validFrom !== editedSequenceHistoryItem.validFrom);
        sequenceHistory.push(editedSequenceHistoryItem);
        this.props.dispatchers.setSequenceDefinitionHistory(sequenceHistory);
    }

    deleteResourceFromSequenceHistoryItem = (sequenceHistoryItem: SequenceDefinitionHistory, resourceIndex: number) => {
        let resources = [...sequenceHistoryItem.resources];
        resources.splice(resourceIndex, 1);
        this.setResourcesToCurrentSequenceHistoryItem(sequenceHistoryItem, resources);
    }

    addResourceToSequenceHistoryItem = (sequenceHistoryItem: SequenceDefinitionHistory, resource: number | null) => {
        if (resource === null || resource === undefined) {
            this.props.dispatchers.setInReduxState({ showModalMessage: { isOpen: true, entityName: EMPLOYEE_SNAPSHOT } });
            return;
        }
        let resources = [...sequenceHistoryItem.resources];
        if (resources.includes(resource)) {
            return;
        } else {
            resources.push(resource);
            this.setResourcesToCurrentSequenceHistoryItem(sequenceHistoryItem, resources);
        }
    }

    checkIfGroupIsPartOfSequenceContext(): boolean {
        return this.props.selectedGroupAndContext?.selectedGroup?.group?.contexts?.filter((item: { code: string }) =>
            item.code === ProteusConstants.SEQUENCE_CONTEXT_CODE).length > 0;
    }

    ////////////////////////////////////
    // METHODS USED BY GROUPS MANAGEMENT
    ////////////////////////////////////

    protected getTabbedPageCssClasses() {
        return super.getTabbedPageCssClasses() + " container_fullHeight";
    }

    renderTabPanes() {
        let tabPanes = [];
        // an element from tree is selected. We need to decide if it is a GroupContext or GroupSnapshot.
        if (this.groupsContextTreeRef.current?.props.s.selectedId !== undefined && this.props.selectedGroupAndContext.selectedContext !== undefined) {
            // selected element is GroupContext
            if (this.props.selectedGroupAndContext.selectedGroup === undefined) {
                tabPanes.push({
                    menuItem: this.props.selectedGroupAndContext.selectedContext.name,
                    render: () => this.renderGroupContextTab()
                });
            } else {
                tabPanes.push({
                    menuItem: this.props.selectedGroupAndContext.selectedGroup.group.name,
                    render: () => this.renderGroupSnapshotTab()
                });

                if (this.props.selectedGroupAndContext.selectedGroup.group.sequenceDefinition !== null) {
                    tabPanes.push({
                        menuItem: { content: _msg("GroupsOfContext.sequence.label"), "data-testid": "SequenceTab" },
                        render: () => this.renderSequenceTab()
                    });
                }

                if (this.props.selectedGroupAndContext.selectedGroup.group.successionDefinition !== null) {
                    tabPanes.push({
                        menuItem: _msg("GroupsOfContext.succession.label"),
                        render: () => this.renderSuccessionTab()
                    });
                }

                if (this.props.selectedGroupAndContext.selectedGroup.group.constraintDefinitions !== null) {
                    tabPanes.push({
                        menuItem: _msg("GroupsOfContext.contraints.label"),
                        render: () => this.renderConstraintsTab()
                    });
                }

                if (this.props.selectedGroupAndContext.selectedGroup.group.securityDefinition !== null) {
                    tabPanes.push({
                        menuItem: _msg("GroupsOfContext.security.label"),
                        render: () => this.renderSecurityTab()
                    });
                }

                if (this.props.selectedGroupAndContext.selectedGroup.relations !== null) {
                    tabPanes.push({
                        menuItem: _msg("GroupsOfContext.relation.label"),
                        render: () => this.renderRelationTab()
                    });
                }
            }
        }
        return tabPanes;
    }

    renderGroupContextTab() {
        return <Tab.Pane className="GroupsManagement_flexGrow GroupsManagement_tab GroupsManagement_paddingBottom flex flex-grow">
            <GroupContextTab {...this.props.groupContextTab} dispatchers={this.props.dispatchers.groupContextTab}
                currentContext={this.props.selectedGroupAndContext.selectedContext}
                removeGroupFromGroupContext={this.removeGroupFromGroupContextTable} addExistingGroupToCurrentContext={this.addExistingGroupToCurrentContext}
                renderOnChangeForGroupOrContext={this.renderOnChangeForGroupOrContext} initialEmployeesOptions={this.props.employeesOptions} />
        </Tab.Pane >
    }

    renderGroupSnapshotTab() {
        return <Tab.Pane className="GroupsManagement_flexGrow GroupsManagement_tab GroupsManagement_paddingBottom flex flex-grow">
            <GroupSnapshotTab {...this.props.groupSnapshotTab} dispatchers={this.props.dispatchers.groupSnapshotTab}
                initialEmployeesOptions={this.props.employeesOptions}
                addContextToCurrentGroup={this.addContextToCurrentGroup} deleteContextFromCurrentGroup={this.deleteContextFromCurrentGroup}
                addOrUpdateGroupProperty={this.addOrUpdateGroupProperty} renderOnChangeForGroupOrContext={this.renderOnChangeForGroupOrContext}
                deleteGroupProperty={this.deleteGroupProperty} addGroupsToCompositionGroup={this.addGroupsToCompositionGroup}
                deleteResourceHistory={this.deleteResourceHistory} addOrUpdateResourcesHistory={this.addOrUpdateResourcesHistory}
                deleteGroupFromCompositionGroup={this.deleteGroupFromCompositionGroup} selectedGroupAndContext={this.props.selectedGroupAndContext}
                deleteResourceFromResourceHistoryItem={this.deleteResourceFromResourceHistoryItem} addResourceToResourceHistoryItem={this.addResourceToResourceHistoryItem} />
        </Tab.Pane>
    }

    renderSuccessionTab() {
        return <Tab.Pane className="GroupsManagement_flexGrow GroupsManagement_tab GroupsManagement_paddingBottom flex">
            <SuccessionTab {...this.props.successionTab} dispatchers={this.props.dispatchers.successionTab}
                selectedGroup={this.props.selectedGroupAndContext?.selectedGroup?.group} deleteSuccessionDefinitionHistory={this.deleteSuccessionDefinitionHistory}
                addOrUpdateSuccessionDefinitionHistory={this.addOrUpdateSuccessionDefinitionHistory} deleteResourceFromSuccessionHistoryItem={this.deleteResourceFromSuccessionHistoryItem}
                addResourceToSuccessionHistoryItem={this.addResourceToSuccessionHistoryItem} addGroupMembersToSuccession={this.addGroupMembersToSuccession}
                moveSuccessionResourcesUpOrDown={this.moveSuccessionResourcesUpOrDown} />
        </Tab.Pane>
    }

    renderSecurityTab() {
        return <Tab.Pane className="GroupsManagement_flexGrow GroupsManagement_tab GroupsManagement_paddingBottom flex">
            <SecurityTab {...this.props.securityTab} dispatchers={this.props.dispatchers.securityTab}
                selectedGroup={this.props.selectedGroupAndContext?.selectedGroup?.group} addOrUpdateSecurityDefinitionHistory={this.addOrUpdateSecurityDefinitionHistory}
                deleteSecurityDefinitionHistory={this.deleteSecurityDefinitionHistory} deleteProfileFromSecurityHistoryItem={this.deleteProfileFromSecurityHistoryItem}
                addProfileToSecurityHistoryItem={this.addProfileToSecurityHistoryItem} />
        </Tab.Pane>
    }

    renderRelationTab() {
        return <Tab.Pane className="GroupsManagement_flexGrow GroupsManagement_tab GroupsManagement_paddingBottom flex">
            <RelationTab {...this.props.relationTab} dispatchers={this.props.dispatchers.relationTab} deleteGroupRelation={this.deleteGroupRelation}
                selectedGroup={this.props.selectedGroupAndContext?.selectedGroup} addOrUpdateGroupRelation={this.addOrUpdateGroupRelation} />
        </Tab.Pane>
    }

    renderConstraintsTab() {
        return <Tab.Pane className="GroupsManagement_flexGrow GroupsManagement_tab GroupsManagement_paddingBottom flex">
            <ConstraintsTab {...this.props.constraintsTab} dispatchers={this.props.dispatchers.constraintsTab} ref={this.constraintsTabRef}
                selectedGroup={this.props.selectedGroupAndContext?.selectedGroup?.group} addOrUpdateConstraintDefinition={this.addOrUpdateConstraintDefinition}
                deleteConstraintDefinition={this.deleteConstraintDefinition} addValidOnRegistrationType={this.addValidOnRegistrationType}
                deleteValidOnRegistrationType={this.deleteValidOnRegistrationType} addValidForRegistrationType={this.addValidForRegistrationType}
                deleteValidForRegistrationType={this.deleteValidForRegistrationType} deleteConstraintDefinitionHistory={this.deleteConstraintDefinitionHistory}
                addOrUpdateConstraintDefinitionHistory={this.addOrUpdateConstraintDefinitionHistory} />
        </Tab.Pane>
    }

    renderSequenceTab() {
        return <Tab.Pane data-testid="SequenceTab" className="GroupsManagement_flexGrow GroupsManagement_tab GroupsManagement_paddingBottom flex">
            <SequenceTab {...this.props.sequenceTab} dispatchers={this.props.dispatchers.sequenceTab} groupIsPartOfSequenceContext={this.checkIfGroupIsPartOfSequenceContext()}
                selectedGroup={this.props.selectedGroupAndContext?.selectedGroup?.group} deleteSequenceDefinitionHistory={this.deleteSequenceDefinitionHistory}
                addOrUpdateSequenceDefinitionHistory={this.addOrUpdateSequenceDefinitionHistory} moveSequenceResourcesUpOrDown={this.moveSequenceResourcesUpOrDown}
                addGroupMembersToSequence={this.addGroupMembersToSequence} deleteResourceFromSequenceHistoryItem={this.deleteResourceFromSequenceHistoryItem}
                addResourceToSequenceHistoryItem={this.addResourceToSequenceHistoryItem} ref={this.sequenceTabRef} />
        </Tab.Pane>
    }

    protected entityHasBeenModified() {
        const selectedGroup = this.props.selectedGroupAndContext.selectedGroup;
        const unmodifiedSelectedGroup = this.props.unmodifiedSelectedGroupAndContext.selectedGroup;
        if (_.isEqual(this.props.selectedGroupAndContext, this.props.unmodifiedSelectedGroupAndContext)) {
            return false;
        } else {
            if (selectedGroup !== undefined) {
                const fieldsToBeExcluded = [ProteusConstants.CONSTRAINT_DEFINITIONS, ProteusConstants.SUCCESSION_DEFINITION, ProteusConstants.SECURITY_DEFINITION, RELATIONS,
                ProteusConstants.SEQUENCE_DEFINITION];
                return this.entityHasBeenModifiedWithFieldExclusion(fieldsToBeExcluded, selectedGroup, unmodifiedSelectedGroup);
            }
            return true;
        }
    }

    protected entityHasBeenModifiedWithFieldExclusion(fieldToBeExcluded: string[], selectedGroup: any, unmodifiedSelectedGroup: any) {
        for (let key in selectedGroup.group) {
            if (fieldToBeExcluded.filter((item: string) => item === key).length === 0 && !_.isEqual(selectedGroup.group[key], unmodifiedSelectedGroup.group[key])) {
                return true;
            }
        }

        let shouldBeSaved: boolean = false;
        // for constraintDefinitions, the modification from null to [] (checking the box) does not trigger any change to the entity
        for (let i = 0; i < fieldToBeExcluded.length; i++) {
            if (fieldToBeExcluded[i] === ProteusConstants.CONSTRAINT_DEFINITIONS) {
                shouldBeSaved = this.checkIfEntityShouldBeSaved(selectedGroup.group, unmodifiedSelectedGroup.group, ProteusConstants.CONSTRAINT_DEFINITIONS, []);
                if (shouldBeSaved === true) {
                    return true;
                }
            } else if (fieldToBeExcluded[i] === ProteusConstants.SUCCESSION_DEFINITION) {
                shouldBeSaved = this.checkIfEntityShouldBeSaved(selectedGroup.group, unmodifiedSelectedGroup.group, ProteusConstants.SUCCESSION_DEFINITION, { history: [] });
                if (shouldBeSaved === true) {
                    return true;
                }
            } else if (fieldToBeExcluded[i] === ProteusConstants.SECURITY_DEFINITION) {
                shouldBeSaved = this.checkIfEntityShouldBeSaved(selectedGroup.group, unmodifiedSelectedGroup.group, ProteusConstants.SECURITY_DEFINITION, { history: [] });
                if (shouldBeSaved === true) {
                    return true;
                }
            } else if (fieldToBeExcluded[i] === ProteusConstants.SEQUENCE_DEFINITION) {
                shouldBeSaved = this.checkIfEntityShouldBeSaved(selectedGroup.group, unmodifiedSelectedGroup.group, ProteusConstants.SEQUENCE_DEFINITION, { history: [] });
                if (shouldBeSaved === true) {
                    return true;
                }
            } else if (fieldToBeExcluded[i] === RELATIONS) {
                shouldBeSaved = this.checkIfEntityShouldBeSaved(selectedGroup, unmodifiedSelectedGroup, RELATIONS, []);
                if (shouldBeSaved === true) {
                    return true;
                }
            }
        }
        return shouldBeSaved;
    }

    checkIfEntityShouldBeSaved(selectedGroup: any, unmodifiedSelectedGroup: any, fieldName: string, valueToIgnore: any) {
        if (!_.isEqual(selectedGroup[fieldName], unmodifiedSelectedGroup[fieldName])) {
            if (_.isEqual(selectedGroup[fieldName], valueToIgnore) && unmodifiedSelectedGroup[fieldName] === null) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }

    formatDate(date: string | Date) {
        return moment(new Date(date), ProteusConstants.DATE_TIME_FORMAT).toISOString()?.split(".")[0] + "Z";
    }

    getTitle() {
        return _msg("GroupsManagement");
    }

    requiredFieldsAreNull() {
        // check if code and name are not empty or null. If they are, disable save button
        const selectedGroup = this.props.selectedGroupAndContext.selectedGroup;
        const selectedContext = this.props.selectedGroupAndContext.selectedContext;
        if (selectedContext === undefined) {
            return true;
        }
        if (selectedGroup === undefined) {
            if (selectedContext.code === undefined || selectedContext.code.trim().length === 0 || selectedContext.name === undefined || selectedContext.name.trim().length === 0) {
                return true;
            }
        } else {
            if (selectedGroup.group.code === undefined || selectedGroup.group.code.trim().length === 0 || selectedGroup.group.name === undefined || selectedGroup.group.name.trim().length === 0) {
                return true;
            }
        }
        return false;
    }

    showModalMessage() {
        let messageKey = "GroupSnapshotTab.messages.propertyCanNotBeAdded";
        if (this.props.showModalMessage?.entityName === CONTEXT) {
            messageKey = "GroupSnapshotTab.messages.contextCanNotBeAdded";
        } else if (this.props.showModalMessage?.entityName === EMPLOYEE_SNAPSHOT) {
            messageKey = "GroupSnapshotTab.messages.employeeCanNotBeAdded";
        } else if (this.props.showModalMessage?.entityName === PROFILES) {
            messageKey = "SecurityTab.message.groupProfileCanNotBeAdded";
        } else if (this.props.showModalMessage?.entityName === RELATIONS) {
            if (this.props.showModalMessage?.additionalFields !== undefined) {
                messageKey = "RelationTab.message.relationAlreadyExists";
            } else if (this.props.showModalMessage?.selfRelation === true) {
                messageKey = "RelationTab.message.selfRelationCanNotBeAdded";
            } else {
                messageKey = "RelationTab.message.relationCanNotBeAdded";
            }
        } else if (this.props.showModalMessage?.entityName === GROUP_SNAPSHOT) {
            messageKey = "GroupSnapshotTab.messages.groupCanNotBeAddedToComposition";
        } else if (this.props.showModalMessage?.entityName === ProteusConstants.CONSTRAINT_DEFINITIONS) {
            messageKey = "ConstraintsTab.message.nameMandatoryAndUnique";
        } else if (this.props.showModalMessage?.entityName === WORK_PERIOD_TYPE) {
            messageKey = "ConstraintDefinitionValidOn.message.mandatoryAndUnique";
        } else if (this.props.showModalMessage?.entityName === ProteusConstants.SEQUENCE_DEFINITION) {
            messageKey = "GroupsManagement.categoryAndTeamForSequence.label";
        }

        let message = _msg(messageKey);
        if (this.props.showModalMessage?.additionalFields !== undefined) {
            message = _msg(messageKey, ...this.props.showModalMessage.additionalFields);
        }

        return <ModalExt
            severity={Severity.INFO}
            open={this.props.showModalMessage?.isOpen === true}
            content={message}
            header={_msg("general.info")}
            onClose={this.onModalClose}
            actions={[
                <Button key="ok" primary onClick={this.onModalClose}>{_msg("general.ok")}</Button>
            ]}
        />
    }

    protected onModalClose = () => {
        this.props.dispatchers.setInReduxState({ showModalMessage: undefined });
    }

    handleTabsCheckbox(fieldName: string, fieldValue: any, selectedGroup: any) {
        if (selectedGroup[fieldName] === null) {
            // the tab was unchecked and the user checked it now
            // in case the checkbox is checked/unchecked multiple times before being saved, the data can be retrieved from oldTabData
            if (this.props.oldTabData?.[fieldName] === undefined) {
                this.props.dispatchers.setFieldsForSelectedGroup({ fieldName, fieldValue });
            } else {
                this.props.dispatchers.setFieldsForSelectedGroup({ fieldName, fieldValue: this.props.oldTabData[fieldName] });
                this.props.dispatchers.setInReduxState({ oldTabData: { ...this.props.oldTabData, [fieldName]: undefined } });
            }
            // get added tab index and navigate to the tab
            // add +1 to the index tab because the first tab is always the GroupSnapshot tab 
            const groupTabs = this.getCurrentGroupTabs(fieldName);
            this.props.dispatchers.setInReduxState({ activeTabIndex: groupTabs.indexOf(fieldName) + 1 });
            if (fieldName === ProteusConstants.SEQUENCE_DEFINITION) {
                // When the Sequence tab is checked, check if the group has CATEGORIE and PLOEG properties.
                // If one of them is missing, show an info modal and aware the user that he needs those properties.
                const groupHasTeamAndCategory = selectedGroup.properties.filter((item: any) => item.code === ProteusConstants.CATEGORIE.toUpperCase()).length > 0
                    && selectedGroup.properties.filter((item: any) => item.code === ProteusConstants.PLOEG.toUpperCase()).length > 0

                if (!groupHasTeamAndCategory) {
                    this.props.dispatchers.setInReduxState({ showModalMessage: { isOpen: true, entityName: ProteusConstants.SEQUENCE_DEFINITION } });
                }
            }
        } else {
            // the tab was checked and the user unchecked it now
            this.props.dispatchers.setInReduxState({ activeTabIndex: 0, oldTabData: { ...this.props.oldTabData, [fieldName]: selectedGroup[fieldName] } });
            this.props.dispatchers.setFieldsForSelectedGroup({ fieldName, fieldValue: null });
        }
    }

    getCurrentGroupTabs(addedTab: string) {
        const selectedGroup = this.props.selectedGroupAndContext.selectedGroup;
        let allTabs: string[] = [ProteusConstants.SEQUENCE_DEFINITION, ProteusConstants.SUCCESSION_DEFINITION, ProteusConstants.CONSTRAINT_DEFINITIONS,
        ProteusConstants.SECURITY_DEFINITION, RELATIONS];
        let groupTabs: string[] = [...allTabs];
        for (let i = 0; i < allTabs.length; i++) {
            if (selectedGroup.group[allTabs[i]] === null && addedTab !== allTabs[i]) {
                groupTabs = groupTabs.filter((item: any) => item !== allTabs[i]);
            }
        }
        return groupTabs;
    }

    isCompositionGroup() {
        if (this.props.selectedGroupAndContext?.selectedGroup?.group?.__typename === ProteusConstants.COMPOSITION_GROUP) {
            return true;
        }
        return false;
    }

    checkIfCalendarDefinitionChanged(prevProps: PropsFrom<typeof sliceGroupsManagement>) {
        const calendarFromCalendarEditor = this.props.constraintsTab.calendarDefinitionEditor.calendarDefinition;
        const prevCalendarFromCalendarEditor = prevProps.constraintsTab.calendarDefinitionEditor.calendarDefinition;
        // check if calendarDefinition has been changed in calendarDefinitionEditor component
        // if it was, it must be updated in GroupsManagement too
        if (prevCalendarFromCalendarEditor !== undefined && calendarFromCalendarEditor !== undefined &&
            calendarFromCalendarEditor.code === prevCalendarFromCalendarEditor.code &&
            (!_.isEqual(calendarFromCalendarEditor.extendDefinitions, prevCalendarFromCalendarEditor.extendDefinitions) ||
                !_.isEqual(calendarFromCalendarEditor.items, prevCalendarFromCalendarEditor.items) ||
                !_.isEqual(calendarFromCalendarEditor.properties, prevCalendarFromCalendarEditor.properties))) {
            this.updateCalendarDefinition(calendarFromCalendarEditor, this.constraintsTabRef.current?.constraintDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!,
                this.constraintsTabRef.current?.constraintDefinitionHistoryTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!);
        }
    }

    onRefresh = () => {
        this.props.dispatchers.setInReduxState({ selectedGroupAndContext: this.props.unmodifiedSelectedGroupAndContext, searchedGroupOrContext: "" });
        this.props.dispatchers.loadGroupsContextsForTree(true, true, this.groupsContextTreeRef);
        this.groupsContextTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
    }

    protected onLeavePageCancel = (e:any) => {
        if (this.props.confirmations?.confirmDeleteGroupOrContext !== undefined && !e.onLeavePageConfirm) {
            this.props.dispatchers.setInReduxState({ confirmations: undefined });
        }
        this.props.dispatchers.setInReduxState({ confirmLeavePage: false, newOperationToBeConfirmed: undefined, expandCollapseItemId: undefined });
    }

    protected openFormLight = (entity: any) => {
        this.props.dispatchers.openFormLight(entity);
        this.entityFormLightRef.current!.open(entity, 0);
    }

    protected onLeavePageConfirm = async (e:any) => {
        // the "leave page" was called by clicking on a new element in tree
        if (this.props.newOperationToBeConfirmed?.newSelectedElementInTree !== undefined) {
            if (this.props.expandCollapseItemId !== undefined) {
                const isExpand = this.props.expandCollapseItemId in this.groupsContextTreeRef.current!.props.s.expandedIds;
                this.groupsContextTreeRef.current?.props.r.expandCollapse(this.props.root, this.props.expandCollapseItemId, !isExpand, {});
            } else {
                this.groupsContextTreeRef.current?.props.r.selectItem(this.props.newOperationToBeConfirmed.newSelectedElementInTree);
                this.handleSelectedId(this.props.newOperationToBeConfirmed.newSelectedElementInTree);
            }
        } else if (this.props.newOperationToBeConfirmed?.newSearchedGroupOrContext !== undefined &&
            this.props.searchedGroupOrContext !== this.props.newOperationToBeConfirmed?.newSearchedGroupOrContext) {
            // the "leave page" was called by searching a new element in tree
            this.props.dispatchers.setInReduxState({ searchedGroupOrContext: this.props.newOperationToBeConfirmed.newSearchedGroupOrContext });
            this.props.dispatchers.filterGroupsContextsTree();
            this.groupsContextTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
        } else if (this.props.newOperationToBeConfirmed?.newGroupOrContextToBeAdded !== undefined) {
            // the "leave page" was called by pressing on "New context" or "New group" button
            this.openFormLight(this.props.newOperationToBeConfirmed.newGroupOrContextToBeAdded);
        } else if (this.props.newOperationToBeConfirmed?.collapseTree) {
            // the "leave page" was called by pressing on "Collapse all" button
            this.groupsContextTreeRef.current?.props.r.collapseAll(this.props.root, {});
        } else if (this.props.newOperationToBeConfirmed?.refresh) {
            this.onRefresh();
        }
        this.props.dispatchers.setInReduxState({ confirmLeavePage: false, newOperationToBeConfirmed: undefined, expandCollapseItemId: undefined });
        e.onLeavePageConfirm = true;
    }

    renderConfirmModal() {
        return <ModalExt
            severity={Severity.INFO}
            open={this.props.confirmations?.confirmDeleteGroupOrContext !== undefined && !this.props.confirmLeavePage &&
                ((this.props.treeElement?.__typename === GROUP_CONTEXT && this.props.treeElement?.id === this.props.selectedGroupAndContext.selectedContext?.id)
                    || (!this.props.selectedGroupAndContext.selectedGroup || (this.props.treeElement?.id === this.props.selectedGroupAndContext.selectedGroup?.group.id &&
                        this.props.root[Number(this.props.treeElementId?.[0])].id === this.props.selectedGroupAndContext?.selectedContext?.id)))}
            header={this.props.treeElement?.__typename === GROUP_CONTEXT ? _msg("GroupsManagement.deleteContextConfirmation.header.label",
                this.props.selectedGroupAndContext.selectedContext?.name) : _msg("GroupsManagement.deleteGroupConfirmation.header.label",
                    this.props.selectedGroupAndContext.selectedGroup?.group.name)}
            content={this.props.treeElement?.__typename === GROUP_CONTEXT ? (this.props.selectedGroupAndContext.selectedContext?.groups?.length === 0 ?
                _msg("GroupsManagement.deleteContextConfirmation.content.label", this.props.selectedGroupAndContext.selectedContext?.name) :
                _msg("GroupsManagement.deleteContextConfirmation.warning.label")) : (this.props.selectedGroupAndContext.selectedGroup?.group?.contexts?.length !== 0 ?
                    _msg("GroupsManagement.deleteGroupWithContextConfirmation.content.label", this.props.selectedGroupAndContext.selectedGroup?.group?.name,
                        this.props.selectedGroupAndContext.selectedContext?.name) : (this.props.selectedGroupAndContext.selectedGroup?.relations?.length > 0 ?
                            _msg("GroupsManagement.deleteGroupWithRelations.warning.label") : this.props.selectedGroupAndContext.selectedGroup?.parentGroups.length > 0 ?
                                _msg("GroupsManagement.deleteGroupWithParents.warning.label") : _msg("GroupsManagement.deleteGroupWithoutContextConfirmation.content.label",
                                    this.props.selectedGroupAndContext.selectedGroup?.group?.name)))}
            onClose={this.onDeleteCancel}
            actions={[
                <Button key="cancel" onClick={this.onDeleteCancel}>{_msg("general.cancel")}</Button>,
                <Button key="ok" primary onClick={this.onDeleteConfirm}>{_msg("general.ok")}</Button>
            ]}
        />
    }

    protected renderMain() {
        if (!this.props.root) {
            this.props.dispatchers.setGroupsContextsTreeRoot();
        }
        const panes = this.renderTabPanes();
        return (
            <>
            <ShortcutRefForTest objectToPublish={this} />
            <div className="GroupsManagement GroupsManagement_paddingBottom flex">
                <LeavePageModal when={this.entityHasBeenModified()} />
                {this.showModalMessage()}
                <ModalExt
                    severity={Severity.WARNING}
                    open={this.props.confirmLeavePage}
                    content={_msg("unsavedDataWarning.label")}
                    header={_msg("warning.label")}
                    onClose={this.onLeavePageCancel}
                    actions={[
                        <Button key="cancel" onClick={this.onLeavePageCancel}>{_msg("general.cancel")}</Button>,
                        <Button key="ok" primary onClick={this.onLeavePageConfirm}>{_msg("general.ok")}</Button>
                    ]}
                />
                <EntityFormLight id="GroupManagement_entityFormLight" ref={this.entityFormLightRef} onSubmit={async (entity: any) => {
                    entity = { ...entity, values: { ...entity.values, owner: entity.values.owner.id === -1 ? null : entity.values.owner } };
                    await this.props.dispatchers.createNewGroupOrContext(entity.values, this.groupsContextTreeRef, this.handleSelectedId);
                }}
                    entityDescriptor={this.getGroupOrContextDescriptor()}
                    headerContent={this.props.groupOrContextEditorContent.text} headerIcon={this.props.groupOrContextEditorContent.icon} />
                <Segment className="GroupsManagement_topSegment">
                    <div>
                        <Button icon color="green" onClick={() => {
                            const context = { __typename: GROUP_CONTEXT, owner: ProteusUtils.getCurrentUser(), environment: ProteusConstants.BRABO };
                            if (this.entityHasBeenModified()) {
                                this.props.dispatchers.setInReduxState({ confirmLeavePage: true, newOperationToBeConfirmed: { newGroupOrContextToBeAdded: context } });
                            } else {
                                this.openFormLight(context);
                            }
                        }}><Icon name="add" /> {_msg("newContext.label")}</Button>
                        <Button icon color="green" onClick={() => {
                            const group = { __typename: GROUP_SNAPSHOT, owner: ProteusUtils.getCurrentUser(), environment: ProteusConstants.BRABO, type: ProteusConstants.STATIC_GROUP_CODE };
                            if (this.entityHasBeenModified()) {
                                this.props.dispatchers.setInReduxState({ confirmLeavePage: true, newOperationToBeConfirmed: { newGroupOrContextToBeAdded: group } });
                            } else {
                                this.openFormLight(group);
                            }
                        }}><Icon name="add" /> {_msg("newGroup.label")}</Button>
                        <Button data-testid="GroupsManagement_saveButton" primary disabled={!this.entityHasBeenModified() || this.requiredFieldsAreNull()} onClick={() => {
                            this.props.dispatchers.saveOrUpdateGroupOrContext(this.groupsContextTreeRef);
                            this.sequenceTabRef.current?.getSequenceDefinitionHistoryTableRef().current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                        }}>{_msg("dto_crud.save")}</Button>
                        <Button icon="refresh" color="green" onClick={() => {
                            if (this.entityHasBeenModified()) {
                                this.props.dispatchers.setInReduxState({ confirmLeavePage: true, newOperationToBeConfirmed: { refresh: true } });
                            } else {
                                this.onRefresh();
                            }
                        }} />
                        {this.props.selectedGroupAndContext.selectedGroup !== undefined && <div key="div1" className="EntityTablePage_barDivider" />}
                    </div>
                    {this.props.selectedGroupAndContext.selectedGroup !== undefined && <div className="GroupsManagement_tabsCheckboxes">
                        <Checkbox label={_msg("GroupsOfContext.sequence.label")} disabled={this.isCompositionGroup()}
                            checked={this.props.selectedGroupAndContext?.selectedGroup?.group?.sequenceDefinition !== null}
                            onChange={() => this.handleTabsCheckbox(ProteusConstants.SEQUENCE_DEFINITION, { history: [] }, this.props.selectedGroupAndContext.selectedGroup.group)} />
                        <Checkbox label={_msg("GroupsOfContext.succession.label")} disabled={this.isCompositionGroup()}
                            checked={this.props.selectedGroupAndContext?.selectedGroup?.group?.successionDefinition !== null}
                            onChange={() => this.handleTabsCheckbox(ProteusConstants.SUCCESSION_DEFINITION, { history: [] }, this.props.selectedGroupAndContext.selectedGroup.group)} />
                        <Checkbox label={_msg("GroupsOfContext.contraints.label")} checked={this.props.selectedGroupAndContext?.selectedGroup?.group?.constraintDefinitions !== null}
                            onChange={() => this.handleTabsCheckbox(ProteusConstants.CONSTRAINT_DEFINITIONS, [], this.props.selectedGroupAndContext.selectedGroup.group)} />
                        <Checkbox label={_msg("GroupsOfContext.security.label")} disabled={this.isCompositionGroup()}
                            checked={this.props.selectedGroupAndContext?.selectedGroup?.group?.securityDefinition !== null}
                            onChange={() => this.handleTabsCheckbox(ProteusConstants.SECURITY_DEFINITION, { history: [] }, this.props.selectedGroupAndContext.selectedGroup.group)} />
                        <Checkbox label={_msg("GroupsOfContext.relation.label")} checked={this.props.selectedGroupAndContext?.selectedGroup?.relations !== null}
                            onChange={() => this.handleTabsCheckbox(RELATIONS, [], this.props.selectedGroupAndContext.selectedGroup)} />
                    </div>
                    }
                </Segment>
                <Segment className="GroupsManagement_paddingBottom flex-grow">
                    <Grid className="GroupsManagement_fullHeight">
                        <Grid.Row className="GroupsManagement_fullHeight GroupsManagement_paddingBottom">
                            <Grid.Column width="4">
                                <Segment className="GroupsManagement_fullHeight flex">
                                    <Grid.Row>
                                        <Segment>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column className="GroupsManagement_treeSearch">
                                                        <Form>
                                                            <Form.Field>
                                                                <Input value={this.props.searchedGroupOrContext} onChange={(e, data) => {
                                                                    if (!this.entityHasBeenModified()) {
                                                                        this.props.dispatchers.setInReduxState({ searchedGroupOrContext: data.value as string });
                                                                        this.props.dispatchers.filterGroupsContextsTree();
                                                                        this.groupsContextTreeRef.current?.props.r.setInReduxState({ selectedId: undefined });
                                                                    } else {
                                                                        this.props.dispatchers.setInReduxState({
                                                                            newOperationToBeConfirmed: {
                                                                                newSearchedGroupOrContext: data.value
                                                                            }, confirmLeavePage: true
                                                                        });
                                                                    }
                                                                }} placeholder={_msg("searchGroupOrContext.label")} icon="search"></Input>
                                                            </Form.Field>
                                                        </Form>
                                                    </Grid.Column>
                                                    <Grid.Column className="GroupsManagement_expandCollapse">
                                                        <Popup content={_msg("GroupsManagement.expandAll.label")} trigger={<Button basic icon="plus" onClick={() => {
                                                            this.props.dispatchers.expandGroupsContextsTree(this.props.groupsContextsTreeRoot, this.groupsContextTreeRef);
                                                        }} />} />
                                                        <Popup content={_msg("GroupsManagement.collapseAll.label")} trigger={<Button basic icon="minus" onClick={() => {
                                                            if (!this.entityHasBeenModified()) {
                                                                this.groupsContextTreeRef.current?.props.r.collapseAll(this.props.root, {});
                                                            } else {
                                                                this.props.dispatchers.setInReduxState({
                                                                    newOperationToBeConfirmed:
                                                                        { collapseTree: true }, confirmLeavePage: true
                                                                });
                                                            }
                                                        }} />} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Row>
                                    <Grid.Row data-testid="GroupsManagement_tree" className="GroupsManagement_tree">
                                        <GroupContextTreeRRC id="treeTable" ref={this.groupsContextTreeRef} root={this.props.root} renderItemFunction={this.renderItem}
                                            onSelectItem={this.onSelectItem} onSelectedIdChanged={this.handleSelectedId} onExpandCollapseItem={this.onExpandCollapseItem}
                                        />
                                    </Grid.Row>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width="12">
                                {panes.length > 0 && <Tab activeIndex={this.props.activeTabIndex} className="GroupsManagement_tab flex" panes={panes} onTabChange={(e: any, data) => {
                                    this.props.dispatchers.setInReduxState({ activeTabIndex: Number(data.activeIndex) });
                                }}></Tab>}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {this.renderMenuContext()}
                    {this.renderConfirmModal()}
                </Segment>
            </div>
            </>
        )
    }

    protected setEmployeesOptions() {
        const loggedInUser = ProteusUtils.getCurrentUser();
        this.props.dispatchers.setInReduxState({
            employeesOptions: [{
                key: loggedInUser.id || 0,
                text: ((loggedInUser?.name) as string).concat(" ", loggedInUser?.firstName as string, (loggedInUser?.contractHistoryItem?.employeeNumber ?
                    " (" + loggedInUser?.contractHistoryItem?.employeeNumber + ")" : "") as string),
                value: loggedInUser.id || 0
            }]
        });
    }

    getPrevRefSnapshot() {
        const stateChild = this.groupsContextTreeRef.current?.props.s;
        this.prevGroupsContextTreeRef = {
            selectedId: stateChild?.selectedId
        };
    }

    componentDidMount() {
        this.props.dispatchers.loadGroupsContextsForTree(true, true, this.groupsContextTreeRef);
        this.setEmployeesOptions();
        this.getPrevRefSnapshot();
    }

    async componentDidUpdate(prevProps: PropsFrom<typeof sliceGroupsManagement>) {
        // a new element in tree is selected or deselected
        // moved the logic in handleSelectedId, because it being here would not cause a rerender
        if (this.groupsContextTreeRef.current?.props.s.selectedId !== this.prevGroupsContextTreeRef.selectedId) {
            this.getPrevRefSnapshot();
        }
        this.checkIfCalendarDefinitionChanged(prevProps);
    }
}

export const infoGroupsManagement = new ConnectedPageInfo(sliceGroupsManagement, GroupsManagement, "GroupsManagement");
infoGroupsManagement.routeProps = { permission: "GROUPS_MANAGEMENT_VIEW" };