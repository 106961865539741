import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import React from "react";
import { Button, Form, Icon, Modal, Segment, Table } from "semantic-ui-react";
import { apolloClient } from "@crispico/foundation-react/apolloClient"
import { COMMON_SERVICE_FACADE_BEAN_COMMUNICATION_WITH_APICS_IS_RUNNING, COMMON_SERVICE_FACADE_BEAN_FIND_CALLS, COMMON_SERVICE_FACADE_BEAN_START_COMMUNICATION_WITH_APICS, COMMON_SERVICE_FACADE_BEAN_STOP_COMMUNICATION_WITH_APICS } from "graphql/queries";
import moment from "moment";
import { findCalls_commonServiceFacadeBean_findCalls } from "apollo-gen/findCalls";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { ProteusConstants } from "ProteusConstants";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";

const BLOB = "BLOB";
const CONDITIONALLY_ACCEPTED = "CA";
const EMPTY = "";
const ERROR = "ERR";
const PROCESSING = "PROCESSING";

export const sliceCommunication = createSliceFoundation(class SliceCommunication {

    initialState = {
        communicationIsRunning: false as boolean,
        confirmStartStop: false as boolean,
        date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0) as Date,
        communicationData: [] as findCalls_commonServiceFacadeBean_findCalls[],
        contentCell: undefined as unknown as string
    }

    reducers = {
        ...getBaseReducers<SliceCommunication>(this)
    }

    impures = {
        ...getBaseImpures<SliceCommunication>(this),

        async communicationWithApicsIsRunning() {
            const communicationIsRunning = (await apolloClient.query({
                query: COMMON_SERVICE_FACADE_BEAN_COMMUNICATION_WITH_APICS_IS_RUNNING
            })).data.commonServiceFacadeBean_communicationWithApicsIsRunning;
            this.getDispatchers().setInReduxState({ communicationIsRunning });
        },

        async startCommunicationWithApics() {
            await apolloClient.mutate({ mutation: COMMON_SERVICE_FACADE_BEAN_START_COMMUNICATION_WITH_APICS });
            this.getDispatchers().setInReduxState({ communicationIsRunning: true });
        },

        async stopCommunicationWithApics() {
            await apolloClient.mutate({ mutation: COMMON_SERVICE_FACADE_BEAN_STOP_COMMUNICATION_WITH_APICS });
            this.getDispatchers().setInReduxState({ communicationIsRunning: false });
        },

        async checkCommunication() {
            this.getDispatchers().setInReduxState({ communicationData: [] });
            const communicationData = (await apolloClient.query({
                query: COMMON_SERVICE_FACADE_BEAN_FIND_CALLS,
                variables: {
                    date: this.getState().date
                }
            })).data.commonServiceFacadeBean_findCalls;
            this.getDispatchers().setInReduxState({ communicationData });
        }
    }
})

export class Communication extends TabbedPage<PropsFrom<typeof sliceCommunication>> {

    protected tableColumns = [_msg("Communication.callId.label"), _msg("Communication.callDate.label"), _msg("Communication.callDirection.label"),
    _msg("Communication.callStatus.label"), _msg("Communication.messageId.label"), _msg("Communication.checkpoint.label"), _msg("Communication.content.label"),
    _msg("Communication.messageStatus.label"), _msg("Communication.messageType.label"), _msg("Communication.serviceId.label"), _msg("Communication.serviceCode.label"),
    _msg("Communication.serviceStatus.label")];

    protected getTabbedPageCssClasses() {
        return super.getTabbedPageCssClasses() + " Communication_noOverflow";
    }

    protected onCancel = () => {
        this.props.dispatchers.setInReduxState({ confirmStartStop: false });
    }

    protected onConfirm = () => {
        this.props.communicationIsRunning ? this.props.dispatchers.stopCommunicationWithApics() : this.props.dispatchers.startCommunicationWithApics();
        this.props.dispatchers.setInReduxState({ confirmStartStop: false });
    }

    protected renderConfirmForm() {
        return (
            <ModalExt
                severity={Severity.WARNING}
                open={this.props.confirmStartStop}
                content={this.props.communicationIsRunning ? _msg("Communication.stopCommunication.message") : _msg("Communication.startCommunication.message")}
                header={_msg("warning.label")}
                onClose={this.onCancel}
                actions={[
                    <Button key="cancel" onClick={this.onCancel}>{_msg("general.cancel")}</Button>,
                    <Button key="ok" primary onClick={this.onConfirm}>{_msg("general.ok")}</Button>
                ]}
            />
        );
    }

    protected renderTableHeader() {
        let header = [];
        for (let i = 0; i < this.tableColumns.length; i++) {
            header.push(<Table.HeaderCell textAlign="center" key={i}>{this.tableColumns[i]}</Table.HeaderCell>);
        }
        return header;
    }

    protected renderTableBody() {
        let row = [];
        for (let i = 0; i < this.props.communicationData.length; i++) {
            if (this.props.communicationData[i].messages && this.props.communicationData[i].messages!.length > 0) {
                for (let j = 0; j < this.props.communicationData[i].messages!.length; j++) {
                    if (this.props.communicationData[i].messages![j]!.services && this.props.communicationData[i].messages![j]!.services!.length > 0) {
                        for (let k = 0; k < this.props.communicationData[i].messages![j]!.services!.length; k++) {
                            row.push(<Table.Row key={i + j + k}>{this.renderTableCell(this.props.communicationData[i], j, k)}</Table.Row>);
                        }
                    } else {
                        row.push(<Table.Row key={i + j}>{this.renderTableCell(this.props.communicationData[i], j, undefined)}</Table.Row>);
                    }
                }
            } else {
                row.push(<Table.Row key={i}>{this.renderTableCell(this.props.communicationData[i], undefined, undefined)}</Table.Row>);
            }
        }
        return row;
    }

    protected renderContentCell(contentCell: string | null | undefined) {
        if (contentCell === null || contentCell === undefined) {
            return EMPTY;
        }
        return <Button compact primary onClick={() => this.props.dispatchers.setInReduxState({ contentCell: contentCell })}>{BLOB}</Button>;
    }

    protected onModalClose = () => {
        this.props.dispatchers.setInReduxState({ contentCell: undefined });
    }

    protected renderContentModal() {
        return (
            <ModalExt className="Communication_modal" onClose={this.onModalClose}
                open={this.props.contentCell !== undefined} size='small' severity={Severity.INFO}>
                <Modal.Header>
                    {_msg("Communication.blobContent.label")}
                </Modal.Header>
                <Modal.Content scrolling>
                    <pre>{this.props.contentCell}</pre>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={this.onModalClose}>{_msg("general.ok")}</Button>
                </Modal.Actions>
            </ModalExt>
        );
    }

    protected renderTableCell(communicationData: findCalls_commonServiceFacadeBean_findCalls, messageIndex: number | undefined, serviceIndex: number | undefined) {
        const currentMessage = messageIndex !== undefined && communicationData.messages ? communicationData.messages[messageIndex] : undefined;
        const currentService = messageIndex !== undefined && serviceIndex !== undefined && communicationData.messages &&
            communicationData.messages[messageIndex]?.services ? communicationData.messages[messageIndex]?.services?.[serviceIndex] : undefined;
        let cells = [];
        cells.push(
            <Table.Cell textAlign="center" verticalAlign="middle" key={1}>
                {communicationData.id || EMPTY}
            </Table.Cell>
        );
        cells.push(
            <Table.Cell textAlign="center" verticalAlign="middle" key={2}>
                {moment(new Date(communicationData.date)).format(ProteusConstants.DATE_TIME_FORMAT)}
            </Table.Cell>
        );
        cells.push(
            <Table.Cell textAlign="center" verticalAlign="middle" key={3}>
                {communicationData.direction?.code || EMPTY}
            </Table.Cell>
        );
        cells.push(
            <Table.Cell textAlign="center" verticalAlign="middle" key={4} error={communicationData.status?.code === ERROR}
                warning={communicationData.status?.code === PROCESSING || communicationData.status?.code === CONDITIONALLY_ACCEPTED}>
                <b>{communicationData.status?.code || EMPTY}</b>
            </Table.Cell>
        );
        cells.push(
            <Table.Cell textAlign="center" verticalAlign="middle" key={5}>
                {currentMessage?.id || EMPTY}
            </Table.Cell>
        );
        cells.push(
            <Table.Cell textAlign="center" verticalAlign="middle" key={6}>
                {currentMessage?.checkpoint || EMPTY}
            </Table.Cell>
        );
        cells.push(
            <Table.Cell textAlign="center" verticalAlign="middle" key={7}>
                {messageIndex !== undefined ? this.renderContentCell(currentMessage?.content) : EMPTY}
            </Table.Cell>
        );
        cells.push(
            <Table.Cell textAlign="center" verticalAlign="middle" key={8} error={currentMessage?.status?.code === ERROR}
                warning={currentMessage?.status?.code === PROCESSING || currentMessage?.status?.code === CONDITIONALLY_ACCEPTED}>
                {currentMessage?.status?.code !== undefined ? <b>{currentMessage.status.code}</b> : EMPTY}
            </Table.Cell>
        );
        cells.push(
            <Table.Cell textAlign="center" verticalAlign="middle" key={9}>
                {currentMessage?.type || EMPTY}
            </Table.Cell>
        );
        cells.push(
            <Table.Cell textAlign="center" verticalAlign="middle" key={10}>
                {currentService?.id || EMPTY}
            </Table.Cell>
        );
        cells.push(
            <Table.Cell textAlign="center" verticalAlign="middle" key={11}>
                {currentService?.code || EMPTY}
            </Table.Cell>
        );
        cells.push(
            <Table.Cell textAlign="center" verticalAlign="middle" key={12} error={currentService?.status?.code === ERROR}
                warning={currentService?.status?.code === PROCESSING || currentService?.status?.code === CONDITIONALLY_ACCEPTED}>
                {currentService?.status?.code !== undefined ? <b>{currentService.status.code}</b> : EMPTY}
            </Table.Cell>
        );
        return cells;
    }

    protected renderTable() {
        return (
            <Table celled unstackable compact striped>
                <Table.Header>
                    <Table.Row>
                        {this.renderTableHeader()}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.renderTableBody()}
                </Table.Body>
            </Table>
        );
    }

    protected renderMain() {
        return (
            <div className="Communication">
                {this.renderConfirmForm()}
                {this.renderContentModal()}
                <Button className={this.props.communicationIsRunning ? "Communication_stopButton" : "Communication_startButton"} icon
                    onClick={() => {
                        this.props.dispatchers.setInReduxState({ confirmStartStop: true });
                    }}>
                    <Icon name={this.props.communicationIsRunning ? "stop" : "play"} />
                    {this.props.communicationIsRunning ? _msg("Communication.stopCommunication.label") : _msg("Communication.startCommunication.label")}
                </Button>
                <Segment>
                    <Form>
                        <Form.Group>
                            <Form.Field>
                                <DatePickerReactCalendar value={moment(this.props.date)} format={ProteusConstants.DATE_FORMAT} allowClear={false}
                                    onChange={(date) => {
                                        this.props.dispatchers.setInReduxState({ date: date?.startOf('date').toDate() });
                                    }}
                                />
                            </Form.Field>
                            <Button primary onClick={async () => await this.props.dispatchers.checkCommunication()}>
                                {_msg("Communication.checkCommunication.label")}
                            </Button>
                        </Form.Group>
                    </Form>
                </Segment>
                <Segment>
                    {this.renderTable()}
                </Segment>
            </div>
        );
    }

    componentDidMount() {
        this.props.dispatchers.communicationWithApicsIsRunning();
    }
}

export const infoCommunication = new ConnectedPageInfo(sliceCommunication, Communication, "Communication");
infoCommunication.routeProps = { permission: "COMMUNICATION_VIEW" };