import { apolloClient, ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { OnSelectParams } from "@crispico/foundation-react/components/TreeNew/Tree";
import { DragToCreateParam } from "@crispico/react-timeline-10000";
import { ShortcutRefForTest } from "@famiprog-foundation/tests-are-demo";
import { Drawer } from "antd";
import { getConstraintCounterOverview_planningServiceFacadeBean_constraintCounterOverview, getConstraintCounterOverviewVariables } from "apollo-gen/getConstraintCounterOverview";
import { getGroupSnapshotResourcesByGroupContext_employeeService_groupSnapshotsByContext } from "apollo-gen/getGroupSnapshotResourcesByGroupContext";
import { getRegistrationTypeShortcuts_planningServiceFacadeBean_registrationTypeShortcuts, getRegistrationTypeShortcuts_planningServiceFacadeBean_registrationTypeShortcuts_type } from "apollo-gen/getRegistrationTypeShortcuts";
import { getResourceSnapshots, getResourceSnapshots_employeeService_employeeSnapshots, getResourceSnapshotsVariables } from "apollo-gen/getResourceSnapshots";
import { loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider } from "apollo-gen/loadMetadataProvider";
import { BACKGROUND_COLOR, RegistrationTypeCategoryTree, RegistrationTypeCategoryTreeMode, RegistrationTypeCategoryTreeRaw } from "components/registrationTypeCategoryTree/RegistrationTypeCategoryTree";
import { EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS, EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT_FOR_PLANNING, EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_OF_MEMBERS_FOR_PLANNING, EMPLOYEE_SERVICE_GET_INITIALLY_SELECTED_TYPES_AND_STATUSES_FROM_METADATA, EMPLOYEE_SERVICE_GET_SHIPS_PLANNING_GROUPS, EMPLOYEE_SERVICE_GET_SNAPSHOT, PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION, PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_JSON, PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_SHORTCUTS, REGISTRATION_METADATA_SERVICE_FACADE_BEAN_GET_METADATA_PROVIDER, REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_TREE_FOR_PLANNING, REGISTRATION_TYPE_SHORTCUT_SERVICE_ADD_SHORTCUT, REGISTRATION_TYPE_SHORTCUT_SERVICE_DELETE_BY_ID, ROUNDING_TYPE_SERVICE_GET_ROUNDING_TYPES, WORKFLOW_SERVICE_FACADE_BEAN_GET_INBOX_ITEMS_ALL_ACTION } from "graphql/queries";
import {  PLANNING_SERVICE_FACADE_BEAN_GET_CONSTRAINT_COUNTER_OVERVIEW } from "graphql/queries";
import Interweave from "interweave";
import _ from "lodash";
import moment, { Moment } from "moment";
import { DateNavigationPanel, NavigationConstants, sliceDateNavigation } from "pages/DateNavigationPanel/DateNavigationPanel";
import { EmployeeEditor, sliceEmployeeEditorOnlyForExtension } from "pages/employeeEditor/EmployeeEditor";
import { EMPLOYEE_SNAPSHOT } from "pages/groupsManagement/GroupSnapshotTab";
import { MESSAGES_REFRESH_RATE, Message, MessagesTable, sliceMessagesTable, DELETE_ACTION } from "pages/messages/MessagesTable";
import { MetadataPropertyEnum } from "pages/planningPage/planningGantt/mapper/PlanningGanttAdapter";
import { PlanningGantt, PlanningShipItem, RegistrationSnapshot, Ship, slicePlanningGantt, Spare } from "pages/planningPage/planningGantt/PlanningGantt";
import { EDIT_MODE, EDIT_ONLY_ONCE_MODE, READ_ONLY_MODE, Registration, RegistrationEditor, REGISTRATION_EDITOR_MODE, sliceRegistrationEditor } from "pages/registrationEditor/RegistrationEditor";
import { REGISTRATION_TYPE } from "pages/registrationMetadata/RegistrationMetadata";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import React, { RefObject } from "react";
import { MetadataProviderHelper } from "utils/MetadataProviderHelper";
import { Button, Dropdown, Form, Menu, Modal, Popup, Segment, Table } from "semantic-ui-react";
import { Color, ColorUtil } from "utils/ColorUtil";
import { ArrowScrollContainer, ArrowScrollDirection } from "components/scrollableContainer/ArrowScrollContainer";
import { CodeEntity } from "apollo-gen/CodeEntity";
import { ShipRosterEditor, sliceShipRosterEditor } from "pages/ShipRosterEditor/ShipRosterEditor";
import { getGroupSnapshotsByContextForPlanning_employeeService_groupSnapshotsByContextForPlanning } from "apollo-gen/getGroupSnapshotsByContextForPlanning";
import { CustomItem } from "pages/planningPage/planningGantt/mapper/GanttAdapter";

export const PLANNING_EDIT_PAST_REGISTRATIONS = "PLANNING_EDIT_PAST_REGISTRATIONS";
export const PLANNING_WEB_VIEW = "PLANNING_WEB_VIEW";
export const PLANNING_ALLOW_CHANGE_REGISTRATION_FOR_OTHER_EMPLOYEE = "PLANNING_ALLOW_CHANGE_REGISTRATION_FOR_OTHER_EMPLOYEE";
export const PLANNING_EMPLOYEE_EDITOR_VIEW = "PLANNING_EMPLOYEE_EDITOR_VIEW";
export const PLANNING_EMPLOYEE_EDITOR_UPDATE = "PLANNING_EMPLOYEE_EDITOR_UPDATE";
export const PLANNING_OTHER_EMPLOYEE_EDITOR_VIEW = "PLANNING_OTHER_EMPLOYEE_EDITOR_VIEW";
export const PLANNING_OTHER_EMPLOYEE_EDITOR_UPDATE = "PLANNING_OTHER_EMPLOYEE_EDITOR_UPDATE";

export const CONSTRAINT_SEGMENT_ITEM = "ConstraintSegmentItem";
export const PLANNING_SHIP_ITEM = "PlanningShipItem";
export const REGISTRATION_ID = "registrationId";

export const PLANNING_CONTEXT = "PLANNING";
const STATUS_PRESENT = "AANWEZIG";
const STATUS_LIMITED_PRESENCE = "BEPERKT_AANWEZIG";
const STATUS_ABSENT = "AFWEZIG";

const DARK_TEXT_COLOR = 'rgba(0, 0, 0, 0.87)';
const LIGHT_TEXT_COLOR = 'white';
const TRANSPARENT_PROPERTY = 'transparent';

const getStatus = (status: string | undefined) => {
    if (!status || status === STATUS_PRESENT) {
        return 0;
    } else if (status === STATUS_LIMITED_PRESENCE) {
        return 1;
    } else if (status === STATUS_ABSENT) {
        return 2;
    }

    return 0;
};

export enum ActiveDrawerPage {
    NO_ACTIVE_PAGE = 0,
    EMPLOYEE_EXPLORER = 1,
    REGISTRATION_TREE = 2,
    MESSAGES = 3,
    OCCUPATION_OVERVIEW = 4
};

export const slicePlanning = createSliceFoundation(class SlicePlanning {
    nestedSlices = {
        datePanel: sliceDateNavigation,
        gantt: slicePlanningGantt,
        registrationEditor: sliceRegistrationEditor,
        employeeEditor: sliceEmployeeEditorOnlyForExtension,
        messagesTable: sliceMessagesTable,
        shipRosterEditor: sliceShipRosterEditor,
    }

    initialState = {
        groupSnapshots: [] as getGroupSnapshotsByContextForPlanning_employeeService_groupSnapshotsByContextForPlanning[] | null,
        groupSnapshotsForDropdown: [] as getGroupSnapshotsByContextForPlanning_employeeService_groupSnapshotsByContextForPlanning[] | null,
        groupSnapshotsMember: [] as getGroupSnapshotsByContextForPlanning_employeeService_groupSnapshotsByContextForPlanning[] | null,
        searchEmployee: "" as string | number,
        selectedEmployee: {} as any | undefined,
        employeesOptions: [] as any,
        employeeForEditor: undefined as unknown as any,
        registrationForEditor: undefined as unknown as Registration,
        isRegistrationDrawerOpen: false as boolean,
        operatingMode: EDIT_MODE as number,
        isWaitingRegistration: false as boolean,
        activeDrawerPage: ActiveDrawerPage.NO_ACTIVE_PAGE as number,
        selectedRegistrationType: undefined as unknown as { type: getRegistrationTypeShortcuts_planningServiceFacadeBean_registrationTypeShortcuts_type, color: string, id?: number },
        registrationTypesShortcuts: [] as { id: number, type: getRegistrationTypeShortcuts_planningServiceFacadeBean_registrationTypeShortcuts_type, color: string }[],
        leavePageModal: { open: false as boolean, newActiveDrawerPage: ActiveDrawerPage.NO_ACTIVE_PAGE as ActiveDrawerPage },
        registrationTypeTree: undefined as any,
        filteredRegistrationTypeTree: undefined as any,
        firstLoadRegistrationTree: true,
        appliedRegistrationTypes: [] as string[],
        initiallySelectedTypes: [] as string[],
        selectedRowIsEmployee: true as boolean,
        metadataProvider: {} as loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider,
        constraintCounterOverview: {} as getConstraintCounterOverview_planningServiceFacadeBean_constraintCounterOverview,
        messages: [] as Message[],
        hasActionMessages: false,
        roundingTypes: [] as CodeEntity[],
        showWarningShortcutModal: false as boolean,
        shipRosterItem: undefined as unknown as PlanningShipItem,
        shipRosterParent: undefined as unknown as Ship | Spare,
        isShipRosterDrawerOpen: false as boolean,
        initialFromDate: undefined as unknown as Date,
        initialToDate: undefined as unknown as Date,
        forceDateRounding: false as boolean
    }

    reducers = {
        ...getBaseReducers<SlicePlanning>(this),

        removeRegistrationType(state: StateFrom<SlicePlanning>, registrationTypeCategoryTreeRef: RefObject<RegistrationTypeCategoryTreeRaw>) {
            state.selectedRegistrationType = undefined as any;
            state.selectedRowIsEmployee = true;
            registrationTypeCategoryTreeRef.current?.setTreeSelectedId(undefined);
            registrationTypeCategoryTreeRef.current?.setTreeHoveredId(undefined);
        }
    }

    impures = {
        ...getBaseImpures<SlicePlanning>(this),

        async loadGroupSnapshots(owner: number) {
            const currentPermissions = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient.currentPermissions;
            const groupSnapshots = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT_FOR_PLANNING,
                variables: {
                    owner: owner,
                    context: ProteusConstants.PRIMARY_PLANNING_TABS,
                    permissions: currentPermissions ? Object.keys(currentPermissions) : null
                }
            })).data.employeeService_groupSnapshotsByContextForPlanning
            this.getDispatchers().setInReduxState({ groupSnapshots });
        },

        async loadInitiallySelectedRegistrationTypesAndStatusesFromMetadata() {
            const initiallySelected = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_INITIALLY_SELECTED_TYPES_AND_STATUSES_FROM_METADATA,
                variables: {
                    metadataContext: PLANNING_CONTEXT
                }
            })).data.employeeService_initiallySelectedRegistrationTypesAndStatusFromMetadata;
            this.getDispatchers().setInReduxState({ initiallySelectedTypes: initiallySelected, appliedRegistrationTypes: initiallySelected });
        },

        async loadMetadataProvider() {
            const metadataProvider = (await apolloClient.query({
                query: REGISTRATION_METADATA_SERVICE_FACADE_BEAN_GET_METADATA_PROVIDER,
                variables: {
                }
            })).data.registrationMetadataServiceFacadeBean_metadataProvider;
            this.getDispatchers().setInReduxState({ metadataProvider });
        },

        async loadGroupSnapshotsForDropdown(owner: number) {
            const currentPermissions = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient.currentPermissions;
            const groupSnapshotsForDropdown = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT_FOR_PLANNING,
                variables: {
                    owner: owner,
                    context: ProteusConstants.SECONDARY_PLANNING_DROPDOWN_GROUPS,
                    permissions: currentPermissions ? Object.keys(currentPermissions) : null
                }
            })).data.employeeService_groupSnapshotsByContextForPlanning
            this.getDispatchers().setInReduxState({ groupSnapshotsForDropdown });
        },

        async loadGroupSnapshotsOfMember(owner: number, member: number, fromDate: Date, untilDate: Date, useExcludeFromSearch: boolean) {
            const groupSnapshotsMember: getGroupSnapshotsByContextForPlanning_employeeService_groupSnapshotsByContextForPlanning[] = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_OF_MEMBERS_FOR_PLANNING,
                variables: {
                    owner: owner,
                    member: member,
                    fromDate: fromDate,
                    untilDate: untilDate,
                    useExcludeFromSearch: useExcludeFromSearch
                }
            })).data.employeeService_groupSnapshotsOfMemberForPlanning;
            this.getDispatchers().setInReduxState({ groupSnapshotsMember });
        },

        async searchEmployee(text: string, fromDate: Date, untilDate: Date) {
            if (text.length > 2) {
                const employees = (await apolloClient.query<getResourceSnapshots, getResourceSnapshotsVariables>({
                    query: EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS,
                    variables: {
                        searchText: text,
                        fromDate: fromDate,
                        untilDate: untilDate
                    },
                    context: { showSpinner: false }
                })).data.employeeService_employeeSnapshots

                if (employees === undefined || employees === null) {
                    return;
                }
                this.getDispatchers().setInReduxState({
                    employeesOptions: employees.filter((employee: getResourceSnapshots_employeeService_employeeSnapshots, index: number, array: getResourceSnapshots_employeeService_employeeSnapshots[]) =>
                        array.findIndex((employee2: getResourceSnapshots_employeeService_employeeSnapshots) => (employee2.id === employee.id)) === index).map(item => ({
                            key: item.id as number,
                            text: item.name + " " + item.firstName + (item.contractHistoryItem ? " (" + item.contractHistoryItem.employeeNumber + ")" : ""),
                            value: item.id as number,
                            filteredEmployee: ((item?.firstName) as string).concat(" ", item?.name as string),
                            name: item.name,
                            firstName: item.firstName,
                            employeeNumber: item.contractHistoryItem?.employeeNumber
                        }))
                })
            }
        },

        async loadRegistrationJson(registrationId: number) {
            const registration = (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_JSON,
                variables: { id: registrationId }
            })).data.planningServiceFacadeBean_registrationAsJson;
            if (!registration) {
                return null;
            }
            return JSON.parse(registration);
        },

        async getRegistrationTypesShortcuts() {
            let registrationTypesShortcuts = (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_SHORTCUTS,
                variables: {
                    resource: ProteusUtils.getCurrentUser().id
                }
            })).data.planningServiceFacadeBean_registrationTypeShortcuts;
            registrationTypesShortcuts = registrationTypesShortcuts
                .sort((a: getRegistrationTypeShortcuts_planningServiceFacadeBean_registrationTypeShortcuts, b: getRegistrationTypeShortcuts_planningServiceFacadeBean_registrationTypeShortcuts) =>
                    new Date(a.creationDate).getTime() > new Date(b.creationDate).getTime() ? 1 : -1
                );
            let shortcuts = [];
            for (let i = 0; i < registrationTypesShortcuts.length; i++) {
                const color = this.getRegistrationTypeColor(registrationTypesShortcuts[i]?.type?.code);
                shortcuts.push({ type: registrationTypesShortcuts[i].type, color: Color(color).hex(), id: registrationTypesShortcuts[i].id });
            }
            this.getDispatchers().setInReduxState({ registrationTypesShortcuts: shortcuts });
        },

        getRegistrationTypeColor(typeCode: string) {
            return Number(MetadataProviderHelper.getPropertyValue(this.getState().metadataProvider, typeCode || "", undefined, BACKGROUND_COLOR, ProteusConstants.PLANNING));
        },

        async onEmployeeChange(value: number, selectedEmployee: any, updateDatesForShipsPlanning: (fromDate: Date, untilDate: Date, groupCode: string) => { fromDate: Date, untilDate: Date; }) {
            this.getDispatchers().setInReduxState({ searchEmployee: value })
            var teamCode: string = this.getState().gantt.teamCode;
            if (value) {
                await this.loadGroupSnapshotsOfMember(ProteusUtils.getCurrentUser().id!, value, moment(this.getState().datePanel.fromDate).toDate(), moment(this.getState().datePanel.untilDate).toDate(), true);
                const groups = this.getState().groupSnapshotsMember?.filter(group => !group.planningForShips);
                if (groups && groups.length) {
                    teamCode = groups[0].code as string;
                    this.getDispatchers().gantt.setInReduxState({
                        teamName: groups[0].name as string,
                        teamCode
                    })
                }
                const employeeForEditor = {
                    id: value,
                    name: selectedEmployee.name,
                    firstName: selectedEmployee.firstName,
                    detail: {
                        contractHistoryItem: {
                            employeeNumber: selectedEmployee.employeeNumber
                        }
                    }
                };
                this.getDispatchers().setInReduxState({ searchEmployee: value, employeeForEditor: employeeForEditor });
            } else {
                this.getDispatchers().setInReduxState({ groupSnapshotsMember: null, employeesOptions: [], searchEmployee: "", employeeForEditor: undefined });
            }

            if (!value || (value && this.getState().groupSnapshotsMember && this.getState().groupSnapshotsMember!.length)) {
                if (teamCode !== "") {
                    await this.getDispatchers().gantt.loadPlanningData(moment(this.getState().datePanel.fromDate).toDate(), moment(this.getState().datePanel.untilDate).toDate(), selectedEmployee?.filteredEmployee.toString() || "",
                        this.getState().appliedRegistrationTypes, teamCode as string, this.getState().metadataProvider, updateDatesForShipsPlanning, true);
                }
            }
        },

        async goToRegistration(dateNavigationPanel: any, registrationId: number, updateDatesForShipsPlanning: (fromDate: Date, untilDate: Date, groupCode: string) => { fromDate: Date, untilDate: Date; }) {
            this.getDispatchers().messagesTable.closeMessageView();
            this.getDispatchers().setInReduxState({ activeDrawerPage: ActiveDrawerPage.NO_ACTIVE_PAGE });
            const registration: Registration = (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION,
                variables: {
                    id: registrationId
                }
            })).data.planningServiceFacadeBean_registration;

            if (!registration) {
                Utils.showGlobalAlert({ title: _msg("general.error"), message: _msg("PlanningPage.deletedRegistration.label"), severity: Severity.INFO });
                return;
            }
            const resource = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_SNAPSHOT,
                variables: {
                    employeeId: registration.resource,
                    fromDate: registration.fromDate,
                    untilDate: registration.toDate
                }
            })).data.employeeService_employeeSnapshotBetween;
            this.getDispatchers().datePanel.updatePeriod(NavigationConstants.PERIOD);
            dateNavigationPanel.updateDates(NavigationConstants.PERIOD, registration.fromDate, registration.toDate);
            await this.searchEmployee(resource.name + " " + resource.firstName, registration.fromDate, registration.toDate);
            const selectedEmployee = this.getState().employeesOptions.filter((item: { key: number, text: string }) => item.key === resource.id)[0];
            await this.onEmployeeChange(resource.id, selectedEmployee, updateDatesForShipsPlanning);
        },

        async getConstraintCounterOverview(overviewInput: getConstraintCounterOverviewVariables) {
            return (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_GET_CONSTRAINT_COUNTER_OVERVIEW,
                variables: {
                    ...overviewInput
                }
            })).data.planningServiceFacadeBean_constraintCounterOverview;
        },

        async loadMessages() {
            const inboxItems: Message[] = (await apolloClient.query({
                query: WORKFLOW_SERVICE_FACADE_BEAN_GET_INBOX_ITEMS_ALL_ACTION,
                variables: { person: ProteusUtils.getCurrentUser().id, fromDate: this.getState().messagesTable.messagesDatePanel.fromDate, toDate: this.getState().messagesTable.messagesDatePanel.untilDate },
                context: { showSpinner: false }
            })).data.workflowServiceFacadeBean_inboxItemsAllAction;
            const actionMessages = inboxItems.filter(inboxItem => inboxItem.parameters.every(param => param.name !== DELETE_ACTION));
            this.getDispatchers().setInReduxState({ hasActionMessages: actionMessages.length > 0, messages: inboxItems });
        },

        async deleteRegistrationTypeShortcut(id?: number) {
            if (!id) {
                id = this.getState().selectedRegistrationType.id;
            }
            await apolloClient.mutate({
                mutation: REGISTRATION_TYPE_SHORTCUT_SERVICE_DELETE_BY_ID,
                variables: {
                    id
                }
            });
            await this.getRegistrationTypesShortcuts();
            this.getDispatchers().setInReduxState({ selectedRegistrationType: undefined });
        },

        async addRegistrationTypeShortcut(registrationType: any) {
            await apolloClient.mutate({
                mutation: REGISTRATION_TYPE_SHORTCUT_SERVICE_ADD_SHORTCUT,
                variables: {
                    shortcut: {
                        type: registrationType,
                        resource: ProteusUtils.getCurrentUser().id
                    }
                }
            });
            await this.getRegistrationTypesShortcuts();
        }
    }
})

export class PlanningPage extends TabbedPage<PropsFrom<typeof slicePlanning>> {

    protected registrationTypeCategoryTreeRef = React.createRef<RegistrationTypeCategoryTreeRaw>();
    dateNavigationPanelRef = React.createRef<DateNavigationPanel>();
    employeeEditorRef = React.createRef<EmployeeEditor>();
    protected messagesTableRef = React.createRef<MessagesTable>();

    constructor(props: any) {
        super(props);
        this.renderAddRegistrationTypeShortcutButton = this.renderAddRegistrationTypeShortcutButton.bind(this);
        this.updateDatesForShipsPlanning = this.updateDatesForShipsPlanning.bind(this);
    }

    ////////////////////////////////////
    // METHODS USED BY PLANNING DRAWER
    ////////////////////////////////////

    protected renderPlanningDrawer() {
        return (
            <Drawer destroyOnClose placement="right" visible={true} className="PlanningPage_drawer"
                closeIcon={<Button icon="close" className="less-padding" circular />}
                onClose={() => this.onLeaveDrawerPage(ActiveDrawerPage.NO_ACTIVE_PAGE)}>
                {ProteusUtils.renderDrawerHeader(this.getDrawerTitle())}
                {this.props.activeDrawerPage === ActiveDrawerPage.MESSAGES && this.renderMessages()}
                {this.props.activeDrawerPage !== ActiveDrawerPage.MESSAGES &&
                    <div data-testid="PlanningPage_drawerContent" className={"PlanningPage_drawerContent " + (this.props.activeDrawerPage === ActiveDrawerPage.REGISTRATION_TREE ? "overflow-hidden" : "")}>
                        {!this.props.activeDrawerPage && this.renderDrawerSegment()}
                        {this.props.activeDrawerPage === ActiveDrawerPage.EMPLOYEE_EXPLORER && this.renderEmployeeExplorer()}
                        {this.props.activeDrawerPage === ActiveDrawerPage.REGISTRATION_TREE && this.renderRegistrationTree()}
                        {this.props.activeDrawerPage === ActiveDrawerPage.OCCUPATION_OVERVIEW && this.renderOccupationOverview()}
                    </div>}
            </Drawer >
        );
    }

    protected showModalInfoMessage() {
        return (
            <ModalExt onClose={() => this.props.dispatchers.setInReduxState({ leavePageModal: undefined })}
                open={this.props.leavePageModal?.open === true} size='small'>
                <Modal.Header>{_msg("warning.label")}</Modal.Header>
                <Modal.Content>{_msg("leavePage.message.label")}</Modal.Content>
                <Modal.Actions>
                    <Button key={1} onClick={() => { this.props.dispatchers.setInReduxState({ leavePageModal: undefined }) }} content={_msg("general.cancel")} />
                    <Button key={2} primary content={_msg("general.ok")} onClick={() => {
                        this.props.dispatchers.setInReduxState({ leavePageModal: undefined, activeDrawerPage: this.props.leavePageModal?.newActiveDrawerPage })
                    }} />
                </Modal.Actions>
            </ModalExt>
        );
    }

    protected onLeaveDrawerPage(newActiveDrawerPage: ActiveDrawerPage) {
        if (this.props.activeDrawerPage === ActiveDrawerPage.EMPLOYEE_EXPLORER && this.props.employeeEditor.employee && this.employeeEditorRef.current?.isDirty()) {
            this.props.dispatchers.setInReduxState({ leavePageModal: { open: true, newActiveDrawerPage }, selectedEmployee: undefined });
        } else {
            this.props.dispatchers.setInReduxState({ activeDrawerPage: newActiveDrawerPage, selectedEmployee: undefined });
        }
    }

    protected renderDrawerSegment() {
        return (
            <div className="PlanningPage_drawerSegment">
                <Segment className="flex">
                    <div>
                        {(this.canViewOrUpdateOtherEmployeeDetail() || this.canViewOrUpdateOwnEmployeeDetail()) && <Popup key={1} basic content={_msg("PlanningPage.employeeEditor.label")} trigger={
                            <Button circular basic compact icon="address card outline" className="PlanningPage_drawerSegmentButton" onClick={() => this.onLeaveDrawerPage(ActiveDrawerPage.EMPLOYEE_EXPLORER)} />}
                        />}
                        <Popup key={2} basic content={_msg("PlanningPage.registrationTree.label")} trigger={
                            <Button circular basic compact icon="sitemap" className="PlanningPage_drawerSegmentButton" onClick={() => this.onLeaveDrawerPage(ActiveDrawerPage.REGISTRATION_TREE)} />}
                        />
                        <Popup key={3} basic content={_msg("PlanningPage.workflow.label")} trigger={
                            <Button circular basic data-testid="PlanningPage_messagesButton" className={(this.props.hasActionMessages ? "PlanningPage_messagesButton " : "") + "PlanningPage_drawerSegmentButton"}
                                compact icon="envelope outline" onClick={() => this.onLeaveDrawerPage(ActiveDrawerPage.MESSAGES)} />}
                        />
                    </div>
                    <ArrowScrollContainer direction={ArrowScrollDirection.VERTICAL} content={this.renderRegistrationTypesShortcuts()} />
                    {this.props.selectedRegistrationType?.id && <Popup key={4} basic content={_msg("PlanningPage.shortcut.deleteBtn.popupLabel")} trigger={
                        <Button circular basic compact icon="trash" className="PlanningPage_drawerSegmentButton" onClick={() => this.props.dispatchers.deleteRegistrationTypeShortcut()} />}
                    />}
                </Segment>
            </div>
        );
    }

    protected renderRegistrationTypesShortcuts() {
        let shortcuts = [];
        for (let i = 0; i < this.props.registrationTypesShortcuts.length; i++) {
            const backgroundColor = this.props.selectedRegistrationType?.type?.id === this.props.registrationTypesShortcuts[i].type.id ?
                this.props.selectedRegistrationType?.color : TRANSPARENT_PROPERTY;
            const color = backgroundColor === TRANSPARENT_PROPERTY || Color(backgroundColor).isLight() ? DARK_TEXT_COLOR : LIGHT_TEXT_COLOR;
            shortcuts.push(
                <Popup basic
                    content={
                        <>
                            <b>{this.props.registrationTypesShortcuts[i].type?.name}</b>
                            <div>{this.props.registrationTypesShortcuts[i].type?.description || ""}</div>
                        </>
                    }
                    trigger={
                        <Button key={i} size="mini" circular icon value={i} style={{
                            borderWidth: '2px',
                            borderStyle: 'solid',
                            borderColor: this.props.registrationTypesShortcuts[i].color,
                            backgroundColor,
                            color
                        }} onClick={(e: any, data: any) => {
                            this.props.dispatchers.setInReduxState({ selectedRegistrationType: this.props.registrationTypesShortcuts[e.currentTarget.value] });
                        }}>
                            <span style={{fontSize: '12px'}}><b>{this.props.registrationTypesShortcuts[i].type?.code?.slice(0, 2)}</b></span>
                        </Button>}
                />
            );
        }
        return shortcuts;
    }

    handleGroupRowClick = (source: any) => {
        if (source.objectType === EMPLOYEE_SNAPSHOT) {
            if ((source.id === ProteusUtils.getCurrentUser().id && !this.canViewOrUpdateOwnEmployeeDetail()) || (source.id !== ProteusUtils.getCurrentUser().id && !this.canViewOrUpdateOtherEmployeeDetail())) {
                return;
            }
            this.props.dispatchers.setInReduxState({ activeDrawerPage: ActiveDrawerPage.EMPLOYEE_EXPLORER, selectedEmployee: source });
        }
    };

    protected getDrawerTitle() {
        let title = "";
        if (this.props.activeDrawerPage === ActiveDrawerPage.REGISTRATION_TREE) {
            title = _msg("PlanningPage.selected.label") + ": ";
            if (!this.props.selectedRegistrationType) {
                title += _msg("PlanningPage.noRegistration.label");
            } else {
                title += this.props.selectedRegistrationType.type.code;
            }
            return <Popup basic content={this.getPopupContentForSelectedRegistrationType()} trigger={<div>{title}</div>} />
        } else if (this.props.activeDrawerPage === ActiveDrawerPage.EMPLOYEE_EXPLORER) {
            return this.props.dispatchers.employeeEditor.getEmployeeDrawerTitle();
        } else if (this.props.activeDrawerPage === ActiveDrawerPage.MESSAGES) {
            return _msg("employee.messages.label");
        } else if (this.props.activeDrawerPage === ActiveDrawerPage.OCCUPATION_OVERVIEW) {
            return this.props.constraintCounterOverview.counterTitle
        }
        return title;
    }

    ////////////////////////////////////
    // METHODS USED BY PLANNING DRAWER (REGISTRATION TREE)
    ////////////////////////////////////

    protected getPopupContentForSelectedRegistrationType() {
        if (!this.props.selectedRegistrationType) {
            return _msg("PlanningPage.noRegistrationTypeSelected.label");
        }
        return _msg("PlanningPage.registrationTypeSelected.label", this.props.selectedRegistrationType.type.name);
    }

    protected getTextColorBasedOnBackground() {
        if (!this.props.selectedRegistrationType) {
            return "inherit";
        }
        return Color(ColorUtil.getForeground(Color(this.props.selectedRegistrationType?.color).rgbNumber())).hex();
    }

    protected onSelectTreeItem = (params: OnSelectParams, item: any) => {
        if (item.__typename === REGISTRATION_TYPE) {
            const color = this.props.dispatchers.getRegistrationTypeColor(item?.code);
            this.props.dispatchers.setInReduxState({ selectedRegistrationType: { type: item, color: Color(color).hex() } });
        }
    }

    protected renderWarningShortcutModal() {
        return (
            <ModalExt size="small" open={this.props.showWarningShortcutModal} severity={Severity.WARNING}
                onClose={() => this.props.dispatchers.setInReduxState({ showWarningShortcutModal: false })}
                content={_msg("Planning.validation.duplicateRegistrationTypeShortcut")}
                actions={[
                    <Button key="ok" primary onClick={() => {
                        this.props.dispatchers.setInReduxState({ showWarningShortcutModal: false });
                    }}>{_msg("general.ok")}
                    </Button>
                ]}
            />
        );
    }

    protected onAddRegistrationTypeShortcut(registrationType: any) {
        const existingShortcut = this.props.registrationTypesShortcuts.find(shortcut => shortcut.type.code === registrationType.code);
        if (existingShortcut) {
            this.props.dispatchers.setInReduxState({ showWarningShortcutModal: true });
            return;
        }
        this.props.dispatchers.addRegistrationTypeShortcut(registrationType);
        this.props.dispatchers.setInReduxState({ activeDrawerPage: ActiveDrawerPage.NO_ACTIVE_PAGE });
    }

    protected renderAddRegistrationTypeShortcutButton(treeElement: any) {
        if (this.registrationTypeCategoryTreeRef.current?.props.s.mode !== RegistrationTypeCategoryTreeMode.FILTER_CREATE) {
            return <></>;
        }

        const currentShortcut = this.props.registrationTypesShortcuts.find(shortcut => shortcut.type.code === treeElement.code);

        return <Button basic primary={!currentShortcut} negative={!!currentShortcut} icon={currentShortcut ? "delete" : "share"} onClick={(event) => {
            // on click row in tree that contains registration type, it is enable drag-to-create mode for that registration type;
            // we want to prevent this from happening, we only want to add the shortcut when clicking the button
            event.stopPropagation();
            if (!currentShortcut) {
            	// if there isn't any shortcut for this registration type => add shortcut 
                this.onAddRegistrationTypeShortcut(treeElement);
            } else {
            	// if there is a shortcut for this registration type => delete shortcut 
                this.props.dispatchers.deleteRegistrationTypeShortcut(currentShortcut.id);
            }
        }}
        />;
    }

    protected renderRegistrationTree() {
        return <div className="PlanningPage_registrationTypeTree">
            <RegistrationTypeCategoryTree id="RegistrationTypeCategoryTree" ref={this.registrationTypeCategoryTreeRef}
                appliedRegistrationTypes={this.props.appliedRegistrationTypes} initiallySelectedTypes={this.props.initiallySelectedTypes}
                firstLoad={this.props.firstLoadRegistrationTree} hasStatuses={true} hasCheckboxes={true} filterWorkPeriodTypes={false}
                currentContext={PLANNING_CONTEXT} showApplyAndRevertButtons={true} onApplyClick={this.onTreeApplyClick}
                onRevertChangesClick={this.onTreeRevertChangesButtonClick} onSelectItem={this.onSelectTreeItem} registrationTypeTree={this.props.registrationTypeTree}
                filterCreateTypes={true}  filteredRegistrationTypeTree={this.props.filteredRegistrationTypeTree}
                renderAdditionalActionsInTreeItem={this.renderAddRegistrationTypeShortcutButton}
            />
        </div>;
    }

    protected onTreeApplyClick = async (selectedStatuses: string[], registrationTree: any) => {
        this.props.dispatchers.setInReduxState({ appliedRegistrationTypes: selectedStatuses, firstLoadRegistrationTree: false, registrationTypeTree: registrationTree });
        if (this.props.gantt.teamCode !== "") {
            await this.loadGanttData(this.props.datePanel.fromDate, this.props.datePanel.untilDate, this.props.gantt.teamCode, this.getSearchedEmployee(), selectedStatuses);
        }
    }

    protected onTreeRevertChangesButtonClick = async (registrationTree: any) => {
        this.props.dispatchers.setInReduxState({ appliedRegistrationTypes: this.props.initiallySelectedTypes, firstLoadRegistrationTree: false, registrationTypeTree: registrationTree });
        await this.loadGanttData(this.props.datePanel.fromDate, this.props.datePanel.untilDate, this.props.gantt.teamCode,
            this.getSearchedEmployee(), this.props.initiallySelectedTypes);
    }

    ////////////////////////////////////
    // METHODS USED BY PLANNING DRAWER (EMPLOYEE EXPLORER)
    ////////////////////////////////////

    protected renderEmployeeExplorer() {
        return <EmployeeEditor {...this.props.employeeEditor} dispatchers={this.props.dispatchers.employeeEditor} ref={this.employeeEditorRef}
            selectedEmployeeIdFromPlanning={this.getSelectedEmployeeIdFromPlanning()} employeeOptionsFromPlanning={this.getEmployeeOptionsFromPlanning()}
            datePanel={this.props.datePanel} getOperatingMode={this.getOperatingMode} loadRegistrationJson={this.props.dispatchers.loadRegistrationJson} />
    }

    getSelectedEmployeeIdFromPlanning() {
        if (this.canViewOrUpdateOtherEmployeeDetail()) {
            const selectedEmploye = this.props.selectedEmployee?.id || this.props.searchEmployee;
            if (!this.canViewOrUpdateOwnEmployeeDetail() && selectedEmploye === ProteusUtils.getCurrentUser().id) {
                return "";
            }
            return selectedEmploye;
        } else if (this.canViewOrUpdateOwnEmployeeDetail()) {
            return ProteusUtils.getCurrentUser().id as number;
        }
        return "";
    }

    getEmployeeOptionsFromPlanning() {
        if (!this.props.selectedEmployee || this.props.employeesOptions.find((employeesOption: any) => employeesOption.key === this.props.selectedEmployee.id)) {
            return this.props.employeesOptions;
        }
        const item = this.props.selectedEmployee;
        return this.props.employeesOptions.concat([{
            key: item.id as number,
            text: item.name + " " + item.firstName + (item.contractHistoryItem ? " (" + item.contractHistoryItem.employeeNumber + ")" : ""),
            value: item.id as number,
            filteredEmployee: ((item?.firstName) as string).concat(" ", item?.name as string),
            name: item.name,
            firstName: item.firstName,
            employeeNumber: item.contractHistoryItem?.employeeNumber
        }]);
    }

    canViewOrUpdateOwnEmployeeDetail() {
        const allowOwnEmployeeDetailView = AppMetaTempGlobals.appMetaInstance.hasPermission(PLANNING_EMPLOYEE_EDITOR_VIEW);
        const allowOwnEmployeeDetailUpdate = AppMetaTempGlobals.appMetaInstance.hasPermission(PLANNING_EMPLOYEE_EDITOR_UPDATE);
        return allowOwnEmployeeDetailView || allowOwnEmployeeDetailUpdate;
    }

    canViewOrUpdateOtherEmployeeDetail() {
        const allowOtherEmployeeDetailView = AppMetaTempGlobals.appMetaInstance.hasPermission(PLANNING_OTHER_EMPLOYEE_EDITOR_VIEW);
        const allowOtherEmployeeDetailUpdate = AppMetaTempGlobals.appMetaInstance.hasPermission(PLANNING_OTHER_EMPLOYEE_EDITOR_UPDATE);
        return allowOtherEmployeeDetailView || allowOtherEmployeeDetailUpdate;
    }

    ////////////////////////////////////
    // METHODS USED BY PLANNING DRAWER (MESAGES)
    ////////////////////////////////////

    protected renderMessages() {
        return <MessagesTable {...this.props.messagesTable} dispatchers={this.props.dispatchers.messagesTable} ref={this.messagesTableRef}
            goToRegistration={(registrationId: number) => this.props.dispatchers.goToRegistration(this.dateNavigationPanelRef.current, registrationId, this.updateDatesForShipsPlanning)} loadMessages={() => this.props.dispatchers.loadMessages()} messages={this.props.messages} />
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION EDITOR DRAWER
    ////////////////////////////////////

    protected onRegistrationDrawerClose = async (shouldReloadData?: boolean) => {
        const registrationId = this.props.registrationForEditor.id;
        if (!shouldReloadData && registrationId && this.props.initialFromDate && this.props.initialToDate) {
            this.props.dispatchers.gantt.updateItemDates({ registrationId, fromDate: this.props.initialFromDate, toDate: this.props.initialToDate });
        }
        this.props.dispatchers.setInReduxState({ isRegistrationDrawerOpen: false, registrationForEditor: undefined, initialFromDate: undefined, initialToDate: undefined, forceDateRounding: false });
        if (shouldReloadData) {
            await this.loadGanttData(this.props.datePanel.fromDate, this.props.datePanel.untilDate, this.props.gantt.teamCode, this.getSearchedEmployee());
        }
    }

    ////////////////////////////////////
    // METHODS USED BY SHIP ROSTER EDITOR DRAWER
    ////////////////////////////////////

    protected onShipRosterDrawerClose = async (shouldReloadData?: boolean) => {
        this.props.dispatchers.setInReduxState({ isShipRosterDrawerOpen: false, shipRosterItem: undefined, shipRosterParent: undefined });
        if (shouldReloadData) {
            await this.loadGanttData(this.props.datePanel.fromDate, this.props.datePanel.untilDate, this.props.gantt.teamCode, this.getSearchedEmployee());
        }
    }

    ////////////////////////////////////
    // METHODS USED BY PLANNING PAGE
    ////////////////////////////////////

    protected getTabbedPageCssClasses() {
        return super.getTabbedPageCssClasses() + " PlanningPage";
    }

    protected onDateNavigationChange = async (e: any) => {
        await this.loadGanttData(e.fromDate, e.untilDate, this.props.gantt.teamCode, this.getSearchedEmployee(), undefined, true);
    }

    protected async loadGanttData(fromDate: Date | number, untilDate: Date | number, groupCode: string, searchEmployee: string, selectedRegistrationTypes?: string[], getGroupSnapshots?: boolean, updateDates?: boolean) {
        if (getGroupSnapshots && this.props.searchEmployee) {
            await this.props.dispatchers.loadGroupSnapshotsOfMember(ProteusUtils.getCurrentUser().id!, Number(this.props.searchEmployee), moment(fromDate).toDate(), moment(untilDate).toDate(), true);
            const groups = this.props.groupSnapshotsMember?.filter(group => !group.planningForShips);
            if (groups && groups.length) {
                let teamCode = groups[0].code as string;
                this.props.dispatchers.gantt.setInReduxState({
                    teamName: groups[0].name as string,
                    teamCode
                });
                groupCode = teamCode;
            }
        }

        // Load the data for gantt only if a group is selected.
        if (groupCode !== "") {
            await this.props.dispatchers.gantt.loadPlanningData(moment(fromDate).toDate(), moment(untilDate).toDate(), searchEmployee.toString(),
                selectedRegistrationTypes ? selectedRegistrationTypes : this.props.appliedRegistrationTypes, groupCode as string, this.props.metadataProvider, this.updateDatesForShipsPlanning, updateDates);
        }
    }

    async loadRegistrationTypeTree(getStatuses: boolean, reloadScreen: boolean) {
        let registrationTypeTree = (await apolloClient.query({
            query: REGISTRATION_SERVICE_FACADE_BEAN_GET_REGISTRATION_TYPE_TREE_FOR_PLANNING,
            variables: {
                getStatuses,
                filterVisibleStatuses: true,
                metadataContext: ProteusConstants.PLANNING
            },
            context: { showSpinner: reloadScreen }
        })).data.registrationServiceFacadeBean_registrationTypeTreeForPlanning;
        return registrationTypeTree;
    }

    async componentDidMount() {
        this.props.dispatchers.loadMetadataProvider();
        this.props.dispatchers.loadGroupSnapshots(ProteusUtils.getCurrentUser().id!);
        const result = await this.loadRegistrationTypeTree(true, true);
        this.props.dispatchers.setInReduxState({ registrationTypeTree: result.a, filteredRegistrationTypeTree: result.b });
        await this.props.dispatchers.loadInitiallySelectedRegistrationTypesAndStatusesFromMetadata();
        this.props.dispatchers.loadGroupSnapshotsForDropdown(ProteusUtils.getCurrentUser().id!);
        await this.props.dispatchers.getRegistrationTypesShortcuts();
        await this.props.dispatchers.loadMessages();
    }

    protected getSearchedEmployee() {
        let searchEmployee = this.props.searchEmployee || "";
        if (searchEmployee !== "") {
            searchEmployee = this.props.employeesOptions.filter((item: { key: number, filteredEmployee: string }) => item.key === Number(searchEmployee))[0].filteredEmployee;
        }
        return searchEmployee.toString();
    }

    protected getGroupSnapshotOptions() {
        return this.props.groupSnapshotsForDropdown?.map((item: getGroupSnapshotResourcesByGroupContext_employeeService_groupSnapshotsByContext) => ({
            key: item.id!,
            text: item.name!,
            value: item.code!
        }));
    }

    protected isSegmentInThePast(start: Moment): boolean {
        return start.valueOf() < new Date().valueOf();
    }

    /**
     * Checks if the given segment is read only. A segment is read only if it starts in the past and the user does not have PLANNING_EDIT_PAST_REGISTRATIONS permission or
     * if the UPDATE operation for that specific registration type is not allowed. 
     * This condition should block any edit/remove/create for such segments.
     */
    protected isReadOnlySegment(registration: Registration, context: string): boolean {
        if (this.isSegmentInThePast(moment(registration.fromDate)) && !ProteusUtils.checkPermission(PLANNING_EDIT_PAST_REGISTRATIONS)) {
            return true;
        }
        return !MetadataProviderHelper.isOperationAllowed(ProteusConstants.UPDATE_ROLE, registration.type!, registration.status, context);
    }

    protected async openEditor(segment: any) {
        const registration = await this.props.dispatchers.loadRegistrationJson(segment.node.source.id);
        this.openRegistrationEditor(registration, segment);
    }

    protected async openRegistrationEditor(registration: any, segment: any, entityHasBeenModified: boolean = false, forceDateRounding: boolean = false) {
        if (!registration) {
            return;
        }
        if (!this.props.roundingTypes || this.props.roundingTypes.length === 0) {
            const roundingTypes = await this.getRoundingTypes();
            this.props.dispatchers.setInReduxState({ roundingTypes: roundingTypes });
        }

        this.props.dispatchers.setInReduxState({
            employeeForEditor: segment.node.parent.source,
            isRegistrationDrawerOpen: true,
            operatingMode: this.getOperatingMode(registration, ProteusConstants.PLANNING),
            isWaitingRegistration: segment.node.isWaitingRegistration === true ? true : false,
            registrationForEditor: registration,
            forceDateRounding
        });
        const useBalance = Boolean(MetadataProviderHelper.getPropertyValue(this.props.metadataProvider, registration.type?.code || "", "", ProteusConstants.USE_BALANCE_COUNTER, ProteusConstants.SYSTEM)) ||
        		Boolean(MetadataProviderHelper.getPropertyValue(this.props.metadataProvider, registration.type?.code || "", "", ProteusConstants.USE_HOURS_BALANCE_COUNTER, ProteusConstants.SYSTEM));
        this.props.dispatchers.registrationEditor.setInReduxState({ useBalance, entityHasBeenModified });
    }

    protected async openShipRosterRegistrationEditor(segment: CustomItem, extraSegments?: CustomItem[]) {
        const shiftsPeriodInDay = this.mergePeriods(extraSegments);
        let planningShipItem: PlanningShipItem = {...segment.node.source as PlanningShipItem, startDateOfShifts: shiftsPeriodInDay?.start, endDateOfShifts: shiftsPeriodInDay?.end};
        const shipRosterParent = segment.parent.source;
        this.props.dispatchers.setInReduxState({ isShipRosterDrawerOpen: true, shipRosterItem: planningShipItem, shipRosterParent: shipRosterParent });
    }

    protected mergePeriods(extraSegments?: any[]) {
        if (!extraSegments) {
            return null;
        }
        let minDate = extraSegments[0].start;
        let maxDate = extraSegments[0].end;
        for (let i in extraSegments) {
            if (minDate > extraSegments[i].start) {
                minDate = extraSegments[i].start;
            }
            if (maxDate < extraSegments[i].end) {
                maxDate = extraSegments[i].end
            }
        }
        return {start: minDate, end: maxDate};
    }

    protected getOperatingMode = (registration: Registration, context: string) => {
        let operatingMode = EDIT_MODE;
        if (registration.id && !isNaN(registration.id) && this.isReadOnlySegment(registration, context)) {
            // don't have the rights to edit a registration that it is in the past or edit an already existing exchange or sequence exchange
            // in this case it is opened in read-only mode
            operatingMode = READ_ONLY_MODE;
        } else if (registration.type?.code === ProteusConstants.EXCHANGE_REGISTRATION_TYPE ||
            registration.type?.code === ProteusConstants.SEQUENCE_EXCHANGE_REGISTRATION_TYPE) {
            // open registration form for edit
            operatingMode = EDIT_ONLY_ONCE_MODE;
        }
        return operatingMode;
    }

    protected renderOccupationOverview() {
        let employeesAlreadyInTree: string[] = [];
        return <div className="PlanningPage_occupationOverview">
            <Table celled compact>
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>{_msg("PlanningPage.occupationOverviewEmployee.label")}</Table.HeaderCell>
                        <Table.HeaderCell>{_msg("PlanningPage.occupationOverviewConstraintGroup.label")}</Table.HeaderCell>
                        <Table.HeaderCell>{_msg("PlanningPage.occupationOverviewRegistrationType.label")}</Table.HeaderCell>
                        <Table.HeaderCell>{_msg("PlanningPage.occupationOverviewRegistrationFromDate.label")}</Table.HeaderCell>
                        <Table.HeaderCell>{_msg("PlanningPage.occupationOverviewRegistrationToDate.label")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        this.props.constraintCounterOverview?.items &&
                        _.cloneDeep(this.props.constraintCounterOverview?.items).sort((a: any, b: any) => {
                            if (getStatus(a.status) === getStatus(b.status)) {
                                return a.employeeName.localeCompare(b.employeeName);
                            }
                            return getStatus(b.status) - getStatus(a.status);
                        }).map((item: any) => {
                            const registrationDateFrom = item.registrationFromDate ? moment(item.registrationFromDate).format('DD-MM-YYYY HH:mm') : "";
                            const registrationDateTo = item.registrationToDate ? moment(item.registrationToDate).format('DD-MM-YYYY HH:mm') : "";
                            // If there are multiple registrations for the same employee, we show the employee name only on the row corresponding
                            // to the first registration.
                            const showEmployeeName = employeesAlreadyInTree.indexOf(item.employeeName) === -1 ? true : false;
                            if (showEmployeeName) {
                                employeesAlreadyInTree.push(item.employeeName);
                            }

                            return <Table.Row>
                                {showEmployeeName ?
                                    <Table.Cell className={item.status === STATUS_LIMITED_PRESENCE ? "PlanningPage_tableCellLimitedPresence" : (item.status === STATUS_ABSENT ? "PlanningPage_tableCellAbsence" : "")}>
                                        {item.employeeName}
                                    </Table.Cell> : <Table.Cell />}
                                <Table.Cell>
                                    {item.group}
                                </Table.Cell>
                                <Table.Cell>
                                    {item.registrationType}
                                </Table.Cell>
                                <Table.Cell>
                                    {registrationDateFrom}
                                </Table.Cell>
                                <Table.Cell>
                                    {registrationDateTo}
                                </Table.Cell>
                            </Table.Row>;
                        })
                    }
                </Table.Body>
            </Table>
        </div>;
    }

    protected async showOccupation(segment: any) {
        // in individual view, the group is in the counter model
        let groupCode = '';
        if (segment.parent.source['groupCode']) {
            groupCode = segment.parent.source.groupCode;
        } else {
            // get the group from the parent
            for (let i in segment.parent.children) {
                let child = segment.parent.children[i];
                if (child.source['groupCode']) {
                    groupCode = child.source.groupCode;
                }
            }
        }

        let date: Date = new Date(segment.start);
        if (date.getDate() === 1 && date.getHours() === 0 && date.getMinutes() === 0) {
            // if (DateUtil.areEqual(date, DateUtil.getFirstDateOfMonth(date))) {
            // 	// we don't have any shifts that start at 00:00 => if it's the first day of the month,
            // 	// then this counter corresponds to a shift that started a day before
            date = new Date(date.getTime() - 86400000);
        }

        const constraintCounterOverview = await this.props.dispatchers.getConstraintCounterOverview({
            dayOccuppation: true,
            groupCode,
            registrationTypeCode: null,
            resourceFilter: null,
            skipVelofExchanged: false,
            calculateLoa: false,
            date
        });

        this.props.dispatchers.setInReduxState({
            activeDrawerPage: ActiveDrawerPage.OCCUPATION_OVERVIEW,
            constraintCounterOverview
        });
    }

    protected onGanttItemDoubleClick = async (segment: CustomItem, extraItems?: CustomItem[] ) => {
        if (segment.node.source.objectType === CONSTRAINT_SEGMENT_ITEM) {
            await this.showOccupation(segment);
        } else if (segment.node.source.objectType === PLANNING_SHIP_ITEM) {
            await this.openShipRosterRegistrationEditor(segment, extraItems);
        } else {
            const registrationSnapshot: RegistrationSnapshot = segment.node.source as RegistrationSnapshot;
            const hideDetails = MetadataProviderHelper.getPropertyValue(segment.metadata, registrationSnapshot.type.code, registrationSnapshot.status.code, MetadataPropertyEnum.HIDE_REGISTRATION_DETAIL, ProteusConstants.PLANNING);
            const allowViewRegistrationDetails = AppMetaTempGlobals.appMetaInstance.hasPermission(ProteusConstants.PLANNING_VIEW_DETAIL_PREFIX + this.props.gantt.teamCode + ProteusConstants.PLANNING_VIEW_DETAIL_SUFFIX);
            if (hideDetails && !allowViewRegistrationDetails) {
                return;
            }
            await this.openEditor(segment);
        }
    }

    protected onGanttDragToCreateStarted = (param: DragToCreateParam) => {
        const source = this.props.gantt.groups[param.groupIndex]?.source;
        // @ts-ignore
        if (source && source.objectType === EMPLOYEE_SNAPSHOT && this.isRegistrationChangeAllowed(source.id)) {
            this.props.dispatchers.setInReduxState({ selectedRowIsEmployee: true });
        } else {
            this.props.dispatchers.setInReduxState({ selectedRowIsEmployee: false });
        }
    }

    protected onDragToCreateEnded = (param: DragToCreateParam) => {
        if (!MetadataProviderHelper.isOperationAllowed(ProteusConstants.CREATE_ROLE, this.props.selectedRegistrationType.type, null, ProteusConstants.PLANNING)) {
            Utils.showGlobalAlert({ message: _msg('PlanningErrorMessage.createNotAllowed.label'), severity: Severity.ERROR });
            return;
        }
        this.handleCreateNewRegistration(this.props.gantt.groups[param.groupIndex].source, param.itemStart as moment.Moment, param.itemEnd as moment.Moment);
    }

    async getRoundingTypes() {
        let roundingTypes = (await apolloClient.query({ query: ROUNDING_TYPE_SERVICE_GET_ROUNDING_TYPES })).data.roundingTypeService_roundingTypes;
        roundingTypes.map((roundingType: { __typename: any; }) => delete roundingType.__typename);
        return roundingTypes;
    }

    private createRegistration(resource: number, fromDate: Date, toDate: Date) {
        return {
            resource, fromDate, toDate,
            type: this.props.selectedRegistrationType.type,
            attachments: [],
            bedrijf: null,
            calculationRelations: [],
            costCenters: [],
            cyclusId: null,
            environment: ProteusConstants.BRABO,
            externId: null,
            melding: null,
            objectVersion: null,
            creationDate: null,
            creationUser: null,
            modificationDate: null,
            modificationUser: null,
            personenMiddelen: [],
            remarks: [],
            status: null,
            workRosterItem: null,
            id: null,
            detail: null
        }
    }

    protected handleCreateNewRegistration = async (employee: any, fromDate: moment.Moment, toDate: moment.Moment) => {
        const employeeForEditor = {
            id: employee.id,
            name: employee.name,
            firstName: employee.firstName,
            employeeNumber: employee.employeeNumber
        };
        const registration = this.createRegistration(employee.id, fromDate.toDate(), toDate.toDate());
        let roundingTypes = this.props.roundingTypes;
        if (!this.props.roundingTypes || this.props.roundingTypes.length === 0) {
            roundingTypes = await this.getRoundingTypes();
        }

        this.props.dispatchers.setInReduxState({
            registrationForEditor: registration,
            employeeForEditor,
            isRegistrationDrawerOpen: true,
            isWaitingRegistration: false,
            operatingMode: EDIT_MODE,
            roundingTypes
        });
    }

    isRegistrationChangeAllowed(resource: number | string | null) {
        const canCreateForOthers = AppMetaTempGlobals.appMetaInstance.hasPermission(PLANNING_ALLOW_CHANGE_REGISTRATION_FOR_OTHER_EMPLOYEE);
        return canCreateForOthers || ProteusUtils.getCurrentUser().id == resource;
    }

    getTimebarResolution() {
        switch (this.props.datePanel.periodDropdownValue) {
            case NavigationConstants.YEAR: {
                return { top: 'year', bottom: 'month' }
            }
            case NavigationConstants.MONTH: {
                return { top: "month", bottom: 'day' }
            }
            case NavigationConstants.WEEK: {
                return { top: "month", bottom: 'day' }
            }
            case NavigationConstants.WORKINGWEEK: {
                return { top: "month", bottom: 'day' }
            }
            case NavigationConstants.DAY: {
                return { top: "day", bottom: 'hour' }
            }
            default:
                return undefined;
        }
    }

    async refresh() {
        const props = this.props;
        await this.props.dispatchers.loadMetadataProvider();
        if (this.props.gantt.teamCode !== "") {
            let searchEmployee = this.props.searchEmployee;
            if (searchEmployee !== "") {
                searchEmployee = this.props.employeesOptions.filter((item: { key: number, filteredEmployee: string }) => item.key === Number(searchEmployee))[0].filteredEmployee;
            }
            await this.props.dispatchers.gantt.loadPlanningData(moment(props.datePanel.fromDate).toDate(), moment(props.datePanel.untilDate).toDate(),
                searchEmployee as string, this.props.appliedRegistrationTypes!, this.props.gantt.teamCode as string, this.props.metadataProvider, this.updateDatesForShipsPlanning);
        }
    }

    updateDatesForShipsPlanning(fromDate: Date, untilDate: Date, groupCode: string): { fromDate: Date, untilDate: Date; } {
        const currentDate = new Date();
        const date = moment(currentDate).isBetween(fromDate, untilDate) ? currentDate : fromDate;
        let planningForShips = false;
        const groups = [...(this.props.groupSnapshots || []), ...(this.props.groupSnapshotsForDropdown || []), ...(this.props.groupSnapshotsMember || [])];
        for (let i in groups) {
            if (groups[i].planningForShips && groups[i].code === groupCode) {
                planningForShips = true;
                break;
            }
        }
        if (planningForShips) {
            fromDate = moment(date).startOf('week').toDate();
            untilDate = moment(date).endOf('week').toDate();
            if (this.props.datePanel.periodDropdownValue !== NavigationConstants.WEEK) {
                this.props.dispatchers.datePanel.updatePeriod(NavigationConstants.WEEK);
            }
            this.dateNavigationPanelRef.current?.updateDates(NavigationConstants.WEEK, fromDate, untilDate, false);
        } else if (!planningForShips && this.props.datePanel.periodDropdownValue !== NavigationConstants.MONTH) {
            fromDate = moment(date).startOf('month').toDate();
            untilDate = moment(date).endOf('month').toDate();
            this.props.dispatchers.datePanel.updatePeriod(NavigationConstants.MONTH);
            this.dateNavigationPanelRef.current?.updateDates(NavigationConstants.MONTH, fromDate, untilDate, false);
        }
        return { fromDate, untilDate };
    }

    updateRegistrationDates = async (segment: CustomItem, fromDateChange: number, toDateChange: number) => {
        const registration = await this.props.dispatchers.loadRegistrationJson(segment.node.source.id);
        this.props.dispatchers.setInReduxState({ initialFromDate: registration.fromDate, initialToDate: registration.toDate });
        registration.fromDate = moment(registration.fromDate).add(fromDateChange, 'minutes').toDate();
        registration.toDate = moment(registration.toDate).add(toDateChange, 'minutes').toDate();
        await this.openRegistrationEditor(registration, segment, true, true);
    }

    protected renderMain() {
        const props = this.props;
        const timebarResolution = this.getTimebarResolution();
        return (
            <>
                <ShortcutRefForTest objectToPublish={this} className={"ShortcutRefForTest"} />
                <div className="PlanningPage_mainDiv">
                    <div className="flex flex-grow">
                        {this.showModalInfoMessage()}
                        <Segment className="PlanningFilters_header">
                            <div>
                                <Form>
                                    <Form.Field>
                                        <Button icon="refresh" color="green" onClick={() => this.refresh()} />
                                    </Form.Field>
                                </Form>
                                <DateNavigationPanel ref={this.dateNavigationPanelRef} {...props.datePanel} dispatchers={props.dispatchers.datePanel} onChangePeriod={this.onDateNavigationChange} showNowButton={true} />
                            </div>
                            <div>
                                <div key="div1" className="EntityTablePage_barDivider PlanningPage_barDivider" />
                                <Form className="PlanningFilters_comboGroup">
                                    <Form.Field>
                                        {_msg("group.label")}
                                        <Dropdown fluid selection search selectOnNavigation={false} noResultsMessage={_msg("general.noResultsFound")}
                                            options={this.getGroupSnapshotOptions()}
                                            value={this.props.gantt.teamCode}
                                            placeholder={_msg("searchGroup.label")}
                                            onChange={async (e: any, data: any) => {
                                                this.props.dispatchers.setInReduxState({ searchEmployee: "", employeesOptions: [], employeeForEditor: undefined });
                                                let group = data.options.filter((item: { key: number, text: string, value: string }) => item.value === data.value)[0];
                                                props.dispatchers.gantt.setInReduxState({
                                                    teamName: group.text as string,
                                                    teamCode: group.value as string
                                                });
                                                await this.loadGanttData(props.datePanel.fromDate, props.datePanel.untilDate, group.value, "", undefined, undefined, true);
                                            }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        {_msg("employee.label")}
                                        <Dropdown fluid selection search clearable selectOnNavigation={false} noResultsMessage={_msg("general.noResultsFound")}
                                            options={this.props.employeesOptions}
                                            value={this.props.searchEmployee}
                                            placeholder={_msg("searchEmployee.label")}
                                            onChange={async (e: any, data: any) => {
                                                const selectedEmployee = data.options.filter((item: { key: number, text: string }) => item.key === data.value)[0];
                                                this.props.dispatchers.onEmployeeChange(data.value, selectedEmployee, this.updateDatesForShipsPlanning);
                                            }}
                                            onSearchChange={(e: any) => {
                                                props.dispatchers.searchEmployee(e.target.value as unknown as string, moment(props.datePanel.fromDate).toDate(), moment(props.datePanel.untilDate).toDate());
                                            }}
                                        />
                                    </Form.Field>
                                </Form>
                                <div key="div2" className="EntityTablePage_barDivider PlanningPage_barDivider" />
                                <Form className="PlanningPage_newRegistrationForm">
                                    <div>
                                        <label>{_msg("OccupancyParams.registrationType.label")}</label>
                                        <Form.Field>
                                            <Segment style={{ backgroundColor: this.props.selectedRegistrationType?.color || "white" }}>
                                                <Popup content={this.getPopupContentForSelectedRegistrationType()} basic
                                                    trigger={
                                                        <div style={{ color: this.getTextColorBasedOnBackground() }}>
                                                            {this.props.selectedRegistrationType?.type?.code || _msg("PlanningPage.noRegistration.label")}
                                                        </div>}
                                                />
                                                {this.props.selectedRegistrationType && <Button negative icon="delete" className="RegistrationMetadata_removeStatusRelation"
                                                    onClick={() => this.props.dispatchers.removeRegistrationType(this.registrationTypeCategoryTreeRef)} />}
                                            </Segment>
                                        </Form.Field>
                                    </div>
                                </Form>
                                <div key="div3" className="EntityTablePage_barDivider PlanningPage_barDivider" />
                            </div>
                            <Form>
                                <Form.Field>
                                    <div>{_msg("planning.gantt.tree.switch")}</div>
                                    <Button primary active={props.gantt.simpleTreeStructure} icon={props.gantt.simpleTreeStructure ? 'sitemap' : 'list'} onClick={async () => {
                                        props.dispatchers.gantt.setInReduxState({ simpleTreeStructure: !props.gantt.simpleTreeStructure });
                                        await this.props.dispatchers.gantt.mapGanttData(moment(props.datePanel.fromDate).toDate(), moment(props.datePanel.untilDate).toDate(),
                                            this.props.gantt.ganttData, this.props.metadataProvider, this.props.gantt.calendarEvents);
                                    }} />
                                </Form.Field>
                            </Form>
                        </Segment>
                        <Menu className="PlanningFilters_tabBar" size="small" compact>
                            {props.groupSnapshots?.map(item =>
                                <Menu.Item key={item.id as number}
                                    active={props.gantt.teamCode === item.code}
                                    onClick={async (e: any) => {
                                        props.dispatchers.gantt.setInReduxState({ teamName: item.name as string, teamCode: item.code as string });
                                        this.props.dispatchers.setInReduxState({ searchEmployee: "", employeesOptions: [] });
                                        this.loadGanttData(moment(props.datePanel.fromDate).toDate(),
                                            moment(props.datePanel.untilDate).toDate(), item.code!, '', undefined, undefined, true);
                                    }}
                                >{item.name}
                                </Menu.Item>)}
                        </Menu>
                        {props.gantt.teamCode === "" &&
                            <Segment className="PlanningPage_noGroup">
                                <div>
                                    <Interweave content={_msg("planning.noselection.label")} />
                                </div>
                            </Segment>
                        }
                        {props.gantt.teamCode !== "" &&
                            <div className="PlanningPage_gantt flex flex-grow">
                                <PlanningGantt {...props.gantt} registrationTypesAndStatus={this.props.appliedRegistrationTypes!}
                                    startDate={moment(props.datePanel.fromDate).toDate()} endDate={moment(props.datePanel.untilDate).toDate()}
                                    ganttData={[]} dispatchers={props.dispatchers.gantt} metadataProvider={this.props.metadataProvider}
                                    onItemDoubleClick={async (item: CustomItem, extraItems?: CustomItem[]) => await this.onGanttItemDoubleClick(item, extraItems)}
                                    onDragToCreateStarted={this.onGanttDragToCreateStarted} onDragToCreateEnded={this.onDragToCreateEnded} cancelDrag={!this.props.selectedRowIsEmployee}
                                    bottomResolution={timebarResolution?.bottom} topResolution={timebarResolution?.top} dragToCreateMode={this.props.selectedRegistrationType ? true : false}
                                    onGroupRowClick={this.handleGroupRowClick} refresh={() => this.refresh()} updateRegistrationDates={this.updateRegistrationDates}
                                    isRegistrationChangeAllowed={this.isRegistrationChangeAllowed} />
                            </div>
                        }
                        {this.props.isRegistrationDrawerOpen &&
                            <RegistrationEditor {...props.registrationEditor} dispatchers={props.dispatchers.registrationEditor}
                                employee={this.props.employeeForEditor} registration={this.props.registrationForEditor}
                                isWaitingRegistration={this.props.isWaitingRegistration} onDrawerClose={this.onRegistrationDrawerClose}
                                operatingMode={this.props.operatingMode} loadRoundingValues={() => { }} viewMode={!this.isRegistrationChangeAllowed(this.props.registrationForEditor?.resource)}
                                roundingTypes={this.props.roundingTypes} metadataContext={ProteusConstants.PLANNING} mode={REGISTRATION_EDITOR_MODE.PLANNING}
                                goToRegistration={(registrationId: number) => this.props.dispatchers.goToRegistration(this.dateNavigationPanelRef.current, registrationId, this.updateDatesForShipsPlanning)}
                                forceDateRounding={this.props.forceDateRounding}
                            />
                        }
                        {this.props.isShipRosterDrawerOpen &&
                            <ShipRosterEditor {...props.shipRosterEditor} dispatchers={props.dispatchers.shipRosterEditor} onDrawerClose={this.onShipRosterDrawerClose}
                            planningShipItem={this.props.shipRosterItem} shipRosterParent={this.props.shipRosterParent} groupCode={this.props.gantt.teamCode} />
                        }
                        {this.props.activeDrawerPage !== ActiveDrawerPage.NO_ACTIVE_PAGE && this.renderPlanningDrawer()}
                    </div>
                    {this.renderDrawerSegment()}
                </div>
                {this.renderWarningShortcutModal()}
            </>
        );
    }

    tadOnLeaveDrawerPage(newActiveDrawerPage: ActiveDrawerPage) {
        this.onLeaveDrawerPage(newActiveDrawerPage);
    }

    tadLoadMessages() {
        this.props.dispatchers.loadMessages();
    }
}

export const planningPageInfo = new ConnectedPageInfo(slicePlanning, PlanningPage, "Planning");
planningPageInfo.routeProps = { permission: "PLANNING_WEB_VIEW" };