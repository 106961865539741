import { ModalExt, Severity } from '@crispico/foundation-react/components/ModalExt/ModalExt';
import { FC } from 'react';
import { Button, Modal } from 'semantic-ui-react';

interface CatalogErrorModalProps {
    isOpen: boolean,
    onClose: () => void,
    errorCode?: string,
    errorMessage?: string,
}

const CatalogErrorModal: FC<CatalogErrorModalProps> = ({ isOpen, onClose, errorCode, errorMessage }) => {
    return (
        <ModalExt open={isOpen} severity={Severity.ERROR}>
            <Modal.Header>
                {_msg("CatalogErrorModal.validationError.label")}
            </Modal.Header>
            <Modal.Content>
                {errorMessage}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => onClose()}>
                    {_msg("Catalog.ok.label")}
                </Button>
            </Modal.Actions>
        </ModalExt>
    );
};

export default CatalogErrorModal;