'use strict';

/*@ngInject*/
function AgendaPageCtrl($scope, $interpolate, $location, $log, $filter, $securityContext, FormModal, ErrorModal, ModalSpinner, Confirm, Agenda, AgendaConstants, useOtherAgendaContext, RegistrationMetadata, onlyWaitingRegistrations, Principal) {
	
	$log.debug('initializing...');
	
	RegistrationMetadata.initProperties();
	var qParams = $location.search();
	
	var agenda = this;
	
	agenda.useOtherAgendaContext = useOtherAgendaContext;
	agenda.loggedInUser = $securityContext.resource;
	agenda.onlyWaitingRegistrations = onlyWaitingRegistrations;

	// start from current date
	agenda.date = qParams.date ? new Date(qParams.date) : new Date();

	// start in month mode
	agenda.mode = AgendaConstants.MODE_MONTH;
	
	if (!agenda.useOtherAgendaContext) {
		// use the current user
		agenda.resource = $securityContext.resource;
		agenda.editable = true;
	} else {
		agenda.editable = false;
		ModalSpinner.hide();
	}

	agenda.showBalanceBadge = false;
	if (agenda.loggedInUser.categoryHistoryItem.category.code == 'BTM') {
		Principal.hasPermissions('ALLOW_NON_ZERO_BALANCE').then(function(result) {
			if (!result) {
				agenda.showBalanceBadge = true;		
			}
		});
	}
	
	$scope.$watch('agenda.resource', function(resource) {
		if (resource) {
			agenda.refresh && agenda.refresh();
		}
	});
	
	agenda.editColumn = {
		name: 'agendaRegistration',
		actions: true,
		headerCellClass: 'hide',
		cellClass: function(grid, row, col, rIdx, cIdx) {
			return 'ui-grid-cell-no-border';
		},
		colorField: 'row.entity.agendaRegistration.metadata',
		minWidth: 100,
		cellTemplate: require('./agenda-grid/agenda-grid-registration-actions.html')
	}
	
	// TODO ???
	agenda.registrationMetadata = {
		disabled: true
	};

	agenda.constraints = {
		visible: false
	}
	
	agenda.startEndIdxChangedListeners = [];

	function cancelCurrentRegistration() {
		agenda.start = true;
		agenda.agendaRegistration = { registration: {} };
	}

	// ready to start creating registrations!
	cancelCurrentRegistration();

	$scope.$watch('agenda.registrationType', cancelCurrentRegistration);
	$scope.$watch('agenda.mode', cancelCurrentRegistration);
	$scope.$watch('agenda.date', function() {
		var startDate = agenda.agendaRegistration.registration.fromDate;
		var endDate = agenda.agendaRegistration.registration.toDate;
		if (startDate) {
			if (new Date(startDate).getTime() > agenda.mode.getLastDate(agenda.date).getTime()) {
				 // went earlier than the selected start date =>
				 // remove the current STARTED registration
				cancelCurrentRegistration();
			}
		}
		if (endDate) {
			// if the user changes the date (to different month/day) =>
			// remove the current COMPLETE registration
			cancelCurrentRegistration();
		}
	});

	/**
	 * Handler for the start/end button.
	 */
	agenda.computeRegistration = function(scope) {
		var entry = scope.row.entity;
		if (agenda.start) {
			$log.debug('starting registration...');

			// start the registration => set start date and other information
			agenda.agendaRegistration.registration.fromDate = entry.start;
			agenda.agendaRegistration.registration.type = agenda.registrationType;
			agenda.agendaRegistration.registration.resource = $securityContext.resource.id;
			agenda.agendaRegistration.registration.environment = AgendaConstants.ENVIRONMENT;

			agenda.agendaRegistration.metadata = agenda.registrationTypesMetadata[agenda.registrationType.code];

			// set placeholder
			agenda.addPlaceholder([agenda.agendaRegistration]);

			agenda.start = false;
		} else {
			$log.debug('ending registration...');

			// end the registration => set end date
			if (agenda.agendaRegistration.metadata[AgendaConstants.MILESTONE]) {
				agenda.agendaRegistration.registration.toDate = entry.start;
			} else {
				agenda.agendaRegistration.registration.toDate = entry.end;
			}
			
			agenda.openDetail(agenda.agendaRegistration);
			
			var stopOnIdx = agenda.mode.getIndex(entry.start);
			if (stopOnIdx == agenda.mode.getIndex(entry.end)) {
				agenda.addPlaceholder([agenda.agendaRegistration]);
			} else {
				agenda.addPlaceholder([agenda.agendaRegistration], null, stopOnIdx);
			}

			delete agenda.start; 
		}
		
		findMaxEndIdx();
		agenda.createColumnDefs();
	}

	/**
	 * Open the dialog for the registration.
	 */
	function openDialog(agendaRegistration, updateMode) {
		var original = angular.copy(agendaRegistration);
		var modal = FormModal.open({
			scope: {
				template: require('./registration/registration-detail.html'),
				title: agendaRegistration.registration.type.name,
				icon: 'fa fa-pencil-square-o',
				agendaRegistration: agendaRegistration,
				isFormDisabled: function() {
					return !agendaRegistration.metadata[AgendaConstants.CREATE_ROLE] && !agendaRegistration.metadata[AgendaConstants.UPDATE_ROLE];
				},
				options: {
					agenda: agenda,
					updateMode: updateMode,
					owner: $securityContext.resource.id,
					date: agendaRegistration.registration.fromDate
				},
				model: {
					form: {},
					form_remarks: {}
				},
				isModelInvalid: function(model) {
					if (agendaRegistration.registration.id && model.form.$pristine && model.form_remarks.$pristine || model.form.$invalid || model.form_remarks.$invalid) {
						return true;
					}
					if (model.scope.status && model.scope.status.exchange && !model.scope.agendaRegistration.registration.detail.secondParty) {
						return true;
					}
					if (model.scope.status && model.scope.status.showDatePicker && !model.scope.agendaRegistration.registration.detail.secondDate) {
						return true;
					}
					if (model.form.fromDate && model.form.toDate && new Date(model.form.fromDate.$viewValue || model.form.fromDate.$modelValue) > new Date(model.form.toDate.$viewValue || model.form.toDate.$modelValue)) {
						return true;
					}
					return false;
				}
			},
			actions: ['ok', 'cancel', 'close']
		});
		
		modal.result.then(function(model) {
			model.form.$commitViewValue();
			model.form_remarks.$commitViewValue();
			if (model.scope.status.leaveGroup) {
				model.scope.agendaRegistration.registration.detail.groupCode = model.scope.status.leaveGroup.code;
			}
			model.scope.agendaRegistration.registration.remarks = model.scope.status.remarks;
			
			findMaxEndIdx();

			// update the registrations on screen
			agenda.removePlaceholder(original);
			agenda.addPlaceholder([agendaRegistration]);

			agenda.createColumnDefs();

			agenda.agendaRegistration = agendaRegistration;
			agenda.save();
		}, function () {
			if (!updateMode) {
				$scope.cancelRegistration(agendaRegistration);
			}
		});
	}

	/**
	 * Create the detail for the registration and then open the dialog.
	 */
	agenda.openDetail = function(agendaRegistration, updateMode) {
		// CALLBACK: need the registration type metadata from remote before opening the dialog
		function doOpenDialog() {
			openDialog(agendaRegistration, updateMode);
		}
		
		var metadata = agenda.registrationTypesMetadata[agendaRegistration.registration.type.code];
		if (metadata) {
			doOpenDialog();
		} else {
			// request registration type metadata before opening the dialog
			agenda.getRegistrationTypeMetadata(agendaRegistration.registration.type.code).then(function(result) {
				agenda.registrationTypesMetadata[agendaRegistration.registration.type.code] = result.data;
				doOpenDialog();
			});
		}
	}
	
	/**
	 * Find the last day when the current registration may end. The start/end button
	 * will be enabled for all the days until that day.
	 */
	function findMaxEndIdx() {
		if (angular.isUndefined(agenda.start)) {
			agenda.startIdx = agenda.maxEndIdx = -1;
		} else if (agenda.start) {
			agenda.startIdx = 0;
			agenda.maxEndIdx = 100;
		} else {
			// in end mode => need to find the max end index
			if (agenda.mode.getFirstDate(agenda.date) < agenda.mode.getFirstDate(agenda.agendaRegistration.registration.fromDate)) {
				// selected a previous date => cannot end the possible registration
				agenda.maxEndIdx = -1;

				$log.debug('selected prev date => cannot end registration')
				return;
			}

			if (agenda.mode.getFirstDate(agenda.date).getTime() == agenda.mode.getFirstDate(agenda.agendaRegistration.registration.fromDate).getTime()) {
				// same month/day => probably a refresh, otherwise start from top
				agenda.startIdx = agenda.mode.getIndex(agenda.agendaRegistration.registration.fromDate);
			} else {
				agenda.startIdx = 0;
			}

			// initialize
			agenda.maxEndIdx = 100;
		}

		$log.debug('computed maxEndIdx = ' + agenda.maxEndIdx);
		
		angular.forEach(agenda.startEndIdxChangedListeners, function(listener) {
			listener(agenda.startIdx, agenda.maxEndIdx);
		});
	}
	
	/**
	 * Call the remote "switch registration" operation.
	 */
	agenda.switchRegistration = function(agendaRegistration) {
		var registration = agendaRegistration.registration;
		
		// first check how many shifts this registration is covering
		var shifts = 0;
		angular.forEach(registration.calculationRelations, function(relation) {
			if ((AgendaConstants.CODE_VC == registration.type.code && relation.calculation.code.indexOf(AgendaConstants.INITIAL_CALCULATION_PREFIX + AgendaConstants.CODE_VC) >= 0) || 
			(AgendaConstants.CODE_TK == registration.type.code && relation.calculation.code.indexOf(AgendaConstants.INITIAL_CALCULATION_PREFIX + AgendaConstants.CODE_TK) >= 0) ||
			(AgendaConstants.CODE_OUV == registration.type.code && relation.calculation.code.indexOf(AgendaConstants.INITIAL_CALCULATION_PREFIX + AgendaConstants.CODE_OUV) >= 0)) {
				shifts += relation.result;
			}
		});
		if (shifts != 1) {
			ErrorModal.open({
				titleId: ErrorModal.ERROR,
				message: $filter('translate')('agenda.switch.not1ShiftError')
			});
		} else {
			var model = {
				form: {},
				date: new Date(registration.fromDate)
			};
			var modal = FormModal.open({
				scope: {
					template: require('./registration/registration-switch-leave.html'),
					title: $filter('translate')('agenda.switch'),
					icon: 'fa fa-exchange',
					options: {
						agenda: agenda
					},
					model: model,
					selectExchangeDate: function(supporter) {
						var exchangeDatePicker = FormModal.open({
							windowClass: 'modal-full modal-no-padding',
							scope: {
								template: require('./agenda-grid/agenda-grid.html'),
								title: 'agenda.selectCompensation',
								agenda: {
									date: model.date,
									mode: AgendaConstants.MODE_MONTH,
									resource: supporter,
									useOtherAgendaContext: true,
									constraints: {},

									selectable: true,
									selectColumn: {
										name: 'select',
										headerCellClass: 'hide',
										cellClass: 'ui-grid-cell-no-border',
										minWidth: 100, 
										cellTemplate: require('./registration/agenda-grid-select.html')
									},
									selectDate: function(date) {
										model.exchangeDate = new Date(date);
										exchangeDatePicker.close();
										ModalSpinner.hide();
									}
								}
							},
							actions: ['close']
						});
					},
					isModelInvalid: function(model) {
						if (model.supporter && model.supporter.id && model.exchangeDate) {
							return false;
						}
						return true;
					}
				},
				actions: ['ok', 'cancel', 'close']
			});

			modal.result.then(function(model) {
				ModalSpinner.show('agenda.loading.switch');
				Agenda.switchRegistration({}, { 
					silent: true,
					data: {
						registration: registration,
						supporter: model.supporter.id,
						switchDate: model.exchangeDate
					}
				}).then(function(result) {
					debugger;
				}, function(error) {
					ErrorModal.open({
						titleId: ErrorModal.VALIDATION_ERROR,
						message: error.data.message
					});
				}).finally(function() {
					agenda.refresh();
				});
			});
		}
	};
	
	/**
	 * Call the remote "split" operation.
	 */
	agenda.splitRegistration = function(registration) {
		ModalSpinner.show('agenda.loading.split');
		Agenda.split({}, { 
			silent: true,
			params: {
				id: registration.id,
				errorPolicy: AgendaConstants.ERROR_POLICY_ENFORCE_ALL
			}
		}).then(function(result) {
			// nothing to do
		}, function(error) {
			ErrorModal.open({
				titleId: ErrorModal.VALIDATION_ERROR,
				message: error.data.message
			});
		}).finally(function() {
			agenda.refresh();
		});
	};

	/**
	 * Call the remote "remove" operation, after user confirmation.
	 */
	agenda.removeRegistration = function(registration) {
		var instance = Confirm.open({
			title: 'clear',
			message: 'agenda.remove.confirm'
		});
		instance.result.then(function() {
			ModalSpinner.show('agenda.loading.remove');
			var data = {};
			data.errorPolicy = {
				policy: AgendaConstants.ERROR_POLICY_ENFORCE_ALL
			};
			data.metadataContext = AgendaConstants.CONTEXT;
			data.registrationId = registration.id;
			Agenda.remove(agenda, {
				silent: true,
				data: data,
				params: {
					id: registration.id
				},
			}).then(function(result) {
				// nothing to do
			}, function(error) {
				ErrorModal.open({
					titleId: ErrorModal.VALIDATION_ERROR, 
					message: error.data.message
				});
			}).finally(function() {
				agenda.refresh();
			});
		});
	};

	/**
	 * Cancel the local changes for the registration. If it's a new registration, simply remove it.
	 * If it's an existing registration, overwrite the local changes with a fresh version from the server.
	 */
	agenda.cancelRegistration = function(scope) {
		var name = scope.col.colDef.name;
		var agendaRegistration = scope.row.entity[name];
		if (!agendaRegistration.placeholder) {
			// not a placeholder registration
			return;
		}
		$scope.cancelRegistration(agendaRegistration);
	}
	
	$scope.cancelRegistration = function(agendaRegistration) {
		var registration = agendaRegistration.registration;
		agenda.removePlaceholder(agendaRegistration);
			
		cancelCurrentRegistration();

		if (!registration.id) {
			agenda.createColumnDefs();
			findMaxEndIdx();
		} else {
			// existing registration, go back to previous
			ModalSpinner.show('agenda.loading.get');
			Agenda.get({}, { 
				params: {
					id: registration.id
				}
			}).then(function(result) {
				// this is an existing registration, we just want to add it to the 
				// calendar without refreshing, which would bring all the registrations back
				agenda.addOrRemovePlaceholder({ registration: result.data }, null, true, true);
			}).finally(function() {
				agenda.createColumnDefs();
				ModalSpinner.hide();
			})
		}
	}

	/**
	 * Call the remote "save" operation.
	 */
	agenda.save = function() {
		ModalSpinner.show('agenda.loading.save');
		
		var data = {};
		data.split = agenda.agendaRegistration.split;
		data.halfDaySetting = agenda.agendaRegistration.halfDay ? agenda.agendaRegistration.halfDay.setting : null;
		data.errorPolicy = {
			policy: AgendaConstants.ERROR_POLICY_ENFORCE_ALL
		};
		data.metadataContext = AgendaConstants.CONTEXT;
		data.registration = agenda.agendaRegistration.registration;

		if (new Date(data.registration.fromDate) > new Date(data.registration.toDate)) {
			ErrorModal.open({
				titleId: ErrorModal.VALIDATION_ERROR, 
				message: "Vanaf datum kan niet na de tot datum liggen."
			});
			if (agenda.agendaRegistration.registration.id) {
				// an attempt to edit an existing registration has failed
				// => reset the changes to the registration
				cancelCurrentRegistration();
			}
			return;
		}
		data.leaveGroupsData = agenda.agendaRegistration.leaveGroupsData;
		
		data.useDefaultMetadataRounding = true; // agenda.mode == AgendaConstants.MODE_MONTH or MODE_DAY

		Agenda.save(agenda, { 
			silent: true,
			data: data 
		}).then(function() {
			cancelCurrentRegistration();
		}, function(error) {
			var message = $interpolate('<b>{{error.config.data.registration.type.name}}:</b>'
					+ '<p/>{{error.data.message}}')({error: error});
			ErrorModal.open({
				titleId: ErrorModal.VALIDATION_ERROR, 
				message: message
			});

			if (agenda.agendaRegistration.registration.id) {
				// an attempt to edit an existing registration has failed
				// => reset the changes to the registration
				cancelCurrentRegistration();
			}
		}).finally(function() {
			agenda.refresh();
		});
	}

	agenda.reset = function() {
		findMaxEndIdx();
	}

	agenda.onResourceChange = function(resource) {
		agenda.resource = resource;
	}
	
	$log.debug('initialized');

};

module.exports = AgendaPageCtrl;