import { createSliceFoundation, EntityDescriptor, EntityEditorFormSimple, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { HistoryTable, sliceHistoryTable } from "components/HistoryTable";
import moment from "moment";
import { SCALE_HISTORY_ITEM } from "pages/employeeEditor/ContractTab";
import { scaleEntityDescriptor } from "pages/employeeEditor/customFieldRenderersEditors";
import { ProteusConstants } from "ProteusConstants";
import React from "react";
import { SCALE_HISTORY_FIELD } from "./customFieldRenderersEditors";

export const sliceContractEditor = createSliceFoundation(class SliceContractEditor {

    initialState = {
        openedTableLight: undefined as unknown as { fieldName: string, fieldType: string, mandatoryFields: string[]; },
        date: undefined as Date | undefined
    }

    nestedSlices = {
        historyTable: sliceHistoryTable,
    }

    reducers = {
        ...getBaseReducers<SliceContractEditor>(this),
    }

    impures = {
        ...getBaseImpures<SliceContractEditor>(this)
    }
})

type PropsNotFromState = {
    refContractInfoGroup: any,
    entity: any,
    startDate: Date,
    onModalOk: (employeeDetail: any) => void
}

export class ContractEditor extends React.Component<PropsFrom<typeof sliceContractEditor> & PropsNotFromState> {
    protected refHistoryTable = React.createRef<HistoryTable>();

    protected getContractDescriptor() {
        let descriptor = new EntityDescriptor({ name: "EmployeeContract" }, false)
            .addFieldDescriptor({ name: "type", type: "ContractType" })
            .addFieldDescriptor({ name: "status", type: "ContractStatus" })
            .addFieldDescriptor({ name: "workArrangement", type: "WorkArrangement" })
            .addFieldDescriptor({ name: "employmentBreak", type: "EmploymentBreak" })
            .addFieldDescriptor({ name: "detail.scaleHistory", type: SCALE_HISTORY_FIELD, entityName: SCALE_HISTORY_ITEM, mandatoryFields: ["scalePercentage"], icon: "file", onOpenTableLight: this.onOpenTableLight });

        return descriptor;
    }

    protected onOpenTableLight = (fieldDescriptor: FieldDescriptor, entities: any[]) => {
        let date = this.props.startDate;
        if (this.props.entity.detail.scaleHistory && this.props.entity.detail.scaleHistory.length > 0) {
            const firstElement = this.props.entity.detail.scaleHistory[0];
            date = firstElement.validUntil ? moment(firstElement.validUntil).add(1, 'day').startOf('day').toDate() : moment(firstElement.validFrom).add(1, 'year').toDate();
        }
        this.props.dispatchers.setInReduxState({
            openedTableLight: { fieldType: fieldDescriptor.entityName, fieldName: fieldDescriptor.name, mandatoryFields: fieldDescriptor.mandatoryFields },
            date
        });
        this.props.dispatchers.historyTable.setInReduxState({ allowHoles: false, entities });
        this.refHistoryTable.current?.open(this.props.historyTable.entities);
    }

    protected addHistoryFieldsToDescriptor = (descriptor: EntityDescriptor) => {
        descriptor.addFieldDescriptor({ name: "validFrom", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT, allowClear: false }) });
        descriptor.addFieldDescriptor({ name: "validUntil", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT }) });
        return descriptor;
    }

    getDefaultValuesForHistoryFields = () => {
        return {
            validFrom: this.props.date,
            validUntil: moment(this.props.date).add(1, 'year').subtract(1, 'minute').toDate()
        }
    }

    protected onModalCancel = () => {
        this.props.dispatchers.setInReduxState({ openedTableLight: undefined });
        this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
    }

    protected onModalOk = (entities: any[]) => {
        let fieldNameAsArray = this.props.openedTableLight.fieldName.split(".").slice(1);
        const field: string = fieldNameAsArray[0];
        const employeeDetail = { [field]: [...entities].sort((a: any, b: any) => moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf()) };
        this.props.onModalOk(employeeDetail);
        this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
        this.props.dispatchers.setInReduxState({ openedTableLight: undefined });
    }

    onDelete(remainingEntities: any) {
        this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
        if (remainingEntities.length > 0) {
            remainingEntities.sort((a: any, b: any) => moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf());
            const firstElement = remainingEntities[0];
            this.props.dispatchers.setInReduxState({ date: moment(firstElement.validUntil).add(1, 'day').startOf('day').toDate() });
        } else {
            this.props.dispatchers.setInReduxState({ date: this.props.startDate });
        }
    }

    onSave(addedEntity: any) {
        this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
        if (addedEntity.values.validUntil) {
            this.props.dispatchers.setInReduxState({ date: moment(addedEntity.values.validUntil).add(1, 'day').startOf('day').toDate() });
        } else {
            this.props.dispatchers.setInReduxState({ date: moment(addedEntity.values.validFrom).add(1, 'year').toDate() });
        }
    }

    protected renderHistoryTableLight() {
        const descriptor = this.addHistoryFieldsToDescriptor(scaleEntityDescriptor);
        return <HistoryTable actions={{ showAddButton: true, showEditButton: true }} ref={this.refHistoryTable}
            formCustomizer={{
                showInModal: true, headerIcon: descriptor.icon || "file", headerContent: _msg(this.props.openedTableLight?.fieldType + ".label"),
                mandatoryFields: this.props.openedTableLight?.mandatoryFields
            }} {...this.props.historyTable} onAdd={() => this.getDefaultValuesForHistoryFields()}
            onDelete={(remainingEntities: any) => this.onDelete(remainingEntities)} onSave={(entities: any, addedEntity: any) => this.onSave(addedEntity)}
            dispatchers={this.props.dispatchers.historyTable} entityDescriptor={descriptor} modalCustomizer={{ onModalOk: this.onModalOk, onModalCancel: this.onModalCancel }}
            onSelectItem={(itemId: any) => this.refHistoryTable.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[itemId]}
        />
    }

    render() {
        return (<>
            <EntityEditorFormSimple ref={this.props.refContractInfoGroup} entityDescriptor={this.getContractDescriptor()} entity={this.props.entity} hideButtonBar />
            {this.renderHistoryTableLight()}
        </>
        );
    }
}