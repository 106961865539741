'use strict';


var css = require('./profiles.css');

/**
 * <profiles-typeahead ng-model="catalogs.trainer.trainerProfileVersion"></profiles-typeahead>
 */
module.exports = {

	template: require('./typeahead-with-add.html'),
	//template: require('components/typeahead/typeahead.html'),
	require: {
		ngModelCtrl: 'ngModel' // is available only after $onInit
	},
	bindings: {
		readOnly: '<',
		onAddNewProfile: '&',
		onProfileNameChange: '&'
	},
	controllerAs: 'profilesTypeahead',
	controller: /*@ngInject*/ function($scope, Typeahead) {
		var profilesTypeahead = $scope.profilesTypeahead;
		
		profilesTypeahead.mode = 'SELECT';
		
		profilesTypeahead.filter = {
			addon: {
				icon: 'fa fa-refresh fa-spin'
			},
			show: true,
		};

		var typeahead = profilesTypeahead.typeahead = $scope.typeahead = new Typeahead($scope, profilesTypeahead.filter, getName);
		typeahead.dropdownTpl = '/profiles/profiles-typeahead.html';

		// link to internal input
		profilesTypeahead.$onInit = function() {
			// add a formatter to display the name in the filter input
			profilesTypeahead.ngModelCtrl.$formatters.push(function(value) {
				typeahead.ngModelCtrl = profilesTypeahead.ngModelCtrl;
				typeahead.onInit();
				
				if (typeahead.ngModelCtrl.$modelValue && typeahead.ngModelCtrl.$modelValue.id) {
					// for an existing profile version, show the select dropdown
					profilesTypeahead.mode = 'SELECT';
				}
				if (typeahead.ngModelCtrl.$modelValue /*&& typeahead.ngModelCtrl.$modelValue.id*/) {
					// for an existing profile version, show the select dropdown
					//profilesTypeahead.mode = 'SELECT';
					profilesTypeahead.newProfileName = null;
				}
			});
			profilesTypeahead.filter.disabled = profilesTypeahead.readOnly;
		};
		
		profilesTypeahead.$onChanges = function(changesObj) {
			if (profilesTypeahead.filter) {
				profilesTypeahead.filter.disabled = profilesTypeahead.readOnly;
			}
		};

		profilesTypeahead.onClick = function() {
			if (!profilesTypeahead.readOnly) {
				typeahead.onClick();	
			}
		}
		
		profilesTypeahead.select = function(rowData) {
			typeahead.select(rowData);			
		};

		profilesTypeahead.switchToAdd = function() {
			profilesTypeahead.mode = 'ADD';
			profilesTypeahead.onAddNewProfile({shouldSave: true });
		};
		
		profilesTypeahead.switchToSelect = function() {
			profilesTypeahead.mode = 'SELECT';
			profilesTypeahead.onAddNewProfile({shouldSave: false});
		};
		
		profilesTypeahead.updateProfileName = function() {
			profilesTypeahead.onProfileNameChange({name: profilesTypeahead.newProfileName});
		}
		
		function getName() {
			return profilesTypeahead.ngModelCtrl.$modelValue ? profilesTypeahead.ngModelCtrl.$modelValue.name : '';
		}
	}
};