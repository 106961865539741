'use strict';

/*@ngInject*/
function registrationDetailDate(DATE_TIME_FORMAT, Agenda, AgendaConstants) {

	return {
		template: require('./registration-detail-date.html'),
		restrict: 'A',
		scope: {
			name: '@',
			minDate: '@',
			maxDate: '@',
			dateFormat: '@',
			status: '=',
			callback: '&'
		},
		require: 'ngModel',
		controller: angular.noop,
		link: function($scope, elt, attrs, ngModelCtrl) {
			if (!$scope.dateFormat) {
				$scope.dateFormat = DATE_TIME_FORMAT; 
			}

			ngModelCtrl.$formatters.push(function(modelValue) {
				var date = new Date(modelValue);
				$scope.date = date;
			});

			$scope.$watch('date', function(newDate, oldDate) {
				if (newDate && (oldDate == null || new Date(newDate).getTime() !== new Date(oldDate).getTime())) {
					// We need to see if the result of the oldDate is the same date as newDate. If it is the same date, we have to make sure that 
					// we don't get the shift for the newDate, because it is probably a different result.
					// For example, we have a day shift on 03/05/2019 06:30 - 03/05/2019 18:29 and night shift on 04/05/2019 18:30 - 05/05/2019 06:29.
					// We want to create a leave on 03/05, but after we open the dialog, we want to have also a leave on 04/05. So, we change the end date to
					// 04/05. The new end date should be 05/05/2019 06:29 (the end of the shift starting on 04/05). But it will re-enter this function because
					// the new date is 05/05 06:29 and the old date is 04/05 18:29. If we don't make sure that the result of the old date is equal with the new date,
					// then when it will try to get the new date for 05/05 the result will be 05/05 23:59 because there is no sfift starting on this date.
					if (oldDate == null) {
						// in case the oldDate is null (was removed so the user could edit the date), take the registration initial date as the old date
						oldDate = $scope.$parent.$parent.$parent.$parent.agendaRegistration.registration[$scope.name];
					}
					updateDate(oldDate, newDate);
				}
			});

			function updateDate(newDate, comparingDate) {
				if ($scope.status.roundingType === "") {
					// no rounding type - allow user to change the dates
					if (newDate !== comparingDate) {
						ngModelCtrl.$setViewValue(comparingDate);
						$scope.date = comparingDate;
						if ($scope.status.milestone && $scope.name == "fromDate" && $scope.callback) {
							$scope.callback({date: $scope.date});
						}
						if ($scope.minDate === true) {
							$scope.status.minDate = comparingDate;
						} else if ($scope.maxDate === true) {
							$scope.status.maxDate = comparingDate;
						}
						var registrationDetailCtrl = $scope.$parent.$parent.$parent.$parent;
						var agendaDays = registrationDetailCtrl.$parent.options.agenda.gridOptions.data;
						$scope.status.computeNumberOfShifts(new Date($scope.status.minDate), new Date($scope.status.maxDate), agendaDays);
					}
					return;
				}
				
				// get the rounding value for the date
				var config = {
					data: {
						resource: $scope.status.resource,
						fromDate: AgendaConstants.MODE_DAY.getFirstDate(new Date(newDate)),
						toDate: AgendaConstants.MODE_DAY.getLastDate(new Date(newDate)),
						roundingType: $scope.status.roundingType,
						halfDayRounding: $scope.status.halfDayRounding
					}
				}
				if ($scope.minDate) {
					// this is needed to see if there is a night shift ending on this date; if it is 
					// we will consider the minDate as the end of that shift and not as the beginning of this day
					config.data.previousWorkPeriod = true;
				}
				var workPeriod = null;
				Agenda.getRoundingValue({}, config).then(function(result) {
					workPeriod = result.data;
				}).finally(function() {
					var currentDate;
					if ($scope.minDate) {
						if (workPeriod) {
							currentDate = workPeriod.fromDate;
							if (newDate != comparingDate) {
								// first time in this function and it means that we want to see if the result of the old date is the new date
								if (currentDate == comparingDate) {
									return;
								} else {
									updateDate(comparingDate, comparingDate);
									return;
								}
							}
						}
						$scope.status.minDate = new Date(currentDate);
					} else if ($scope.maxDate) {
						if (workPeriod) {
							currentDate = workPeriod.toDate;
							if (newDate != comparingDate) {
								if (currentDate == comparingDate) {
									return;
								} else {
									updateDate(comparingDate, comparingDate);
									return;
								}
							}
						}
						$scope.status.maxDate = new Date(currentDate);
					}
					
					ngModelCtrl.$setViewValue(currentDate);
					$scope.date = new Date(currentDate);
					var registrationDetailCtrl = $scope.$parent.$parent.$parent.$parent;
					var agendaDays = registrationDetailCtrl.$parent.options.agenda.gridOptions.data;
					$scope.status.computeNumberOfShifts(new Date($scope.status.minDate), new Date($scope.status.maxDate), agendaDays);
				});
			}

			$scope.readonly = !!window.datePicker;

			$scope.isReadonly = function() {
				if ($scope.status.milestone && $scope.name == "toDate") {
					return true;
				}
				return $scope.readonly || $scope.status.updateMode && !$scope.status.editable || $scope.status.roundingType;
			}

			$scope.showDatePicker = function(openDesktopPicker) {
				if (window.datePicker) {
					// mobile
					var options = {
						mode: 'date',
						date: new Date($scope.date),
						minDate: $scope.maxDate ? new Date($scope.status.minDate).getTime() : NaN,
						maxDate: $scope.minDate ? new Date($scope.status.maxDate).getTime() : NaN
					};

					console.log('minDate ' + options.minDate);
					console.log('maxDate ' + options.maxDate);

					window.datePicker.show(options, function(date) {
						$scope.$apply(function() {
							// can't set it directly because we lose the time (hours+mins)
							var d = new Date($scope.date);
							d.setDate(date.getDate());
							d.setMonth(date.getMonth());
							d.setFullYear(date.getFullYear());
							$scope.date = d;
						});
					});
				} else if (openDesktopPicker) {
					// desktop
					$scope.open = !$scope.open;
				}
			}
		}
	}
}

module.exports = registrationDetailDate;