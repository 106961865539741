import { apolloClient, ApolloContext, apolloGetExtensionFromError, CatchedGraphQLError, createSliceFoundation, EntityDescriptor, EntityEditorFormSimple, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { FileUploadButton, sliceFileUploadButton } from "@crispico/foundation-react/components/fileUploadButton/FileUploadButton";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { Drawer } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { CodeEntity } from "apollo-gen/CodeEntity";
import { getLeaveDistributionCalculationRelation_planningServiceFacadeBean_leaveDistributionCalculationRelationsWithJsonRegistration } from "apollo-gen/getLeaveDistributionCalculationRelation";
import { getPersonSnapshotsByCriteria_employeeService_personSnapshotsByCriteria } from "apollo-gen/getPersonSnapshotsByCriteria";
import { getRealizationsByCriteria_educationServiceFacadeBean_realizations, getRealizationsByCriteria_educationServiceFacadeBean_realizations_componentVersion } from "apollo-gen/getRealizationsByCriteria";
import { getRegistration_planningServiceFacadeBean_registration, getRegistration_planningServiceFacadeBean_registration_attachments, getRegistration_planningServiceFacadeBean_registration_calculationRelations, getRegistration_planningServiceFacadeBean_registration_remarks } from "apollo-gen/getRegistration";
import { getSelectableLeaveGroupsData_resourcesServiceFacadeBean_selectableLeaveGroupsData } from "apollo-gen/getSelectableLeaveGroupsData";
import { getTrainingRelations_registrationServiceFacadeBean_trainingRelations } from "apollo-gen/getTrainingRelations";
import { CompanyInput, RealizationCriteriaInput } from "apollo-gen/globalTypes";
import { BalanceCalculation, sliceBalanceCalculation } from "components/balanceCalculation/BalanceCalculation";
import { COMMON_SERVICE_FACADE_BEAN_GET_PARAMETER_VALUE, EDUCATION_SERVICE_FACADE_BEAN_GET_REALIZATIONS_BY_CRITERIA, EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_OF_MEMBER, GROUP_SERVICE_FACADE_BEAN_GET_GROUP_SNAPSHOT_BY_CODE, GROUP_SERVICE_FACADE_BEAN_GET_GROUPS, HALF_DAY_SERVICE_GET_HALF_DAY_BY_CODE, HALF_DAY_SERVICE_GET_HALF_DAYS, PLANNING_SERVICE_FACADE_BEAN_GET_LEAVE_DISTRIBUTION_CALCULATION_RELATIONS, PLANNING_SERVICE_FACADE_BEAN_GET_MEDICAL_CHECKUP_TYPES, PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_JSON, PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_SNAPSHOTS, PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_STATUS_BY_CODE, PLANNING_SERVICE_FACADE_BEAN_GET_ROUNDING_VALUE, PLANNING_SERVICE_FACADE_BEAN_MATCHING_ROUNDING_TYPE, PLANNING_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_REGISTRATION, REGISTRATION_SERVICE_FACADE_BEAN_GET_TRAINING_RELATIONS, RESOURCES_SERVICE_FACADE_BEAN_GET_GROUP_RELATIONS, RESOURCES_SERVICE_FACADE_BEAN_GET_SELECTABLE_LEAVE_GROUPS_DATA, SECURITY_SERVICE_FACADE_BEAN_GET_SECURITY_PERMISSIONS_FOR_RESOURCE, WORKFLOW_SERVICE_FACADE_BEAN_GET_WORKFLOW_PROCESSES } from "graphql/queries";
import Interweave from "interweave";
import moment, { Moment } from "moment";
import { Person } from "pages/employeeDetail/EmployeeDetailEntityDescriptor";
import { PersonAssociationFieldEditor } from "pages/realization/RealizationEntityDescriptor";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import React, { ReactNode } from "react";
import { Button, Card, Form, Grid, Header, Icon, Label, Modal, Popup, Segment, Tab, Table } from "semantic-ui-react";
import { MetadataProviderHelper } from "utils/MetadataProviderHelper";
import { CompanyByCriteriaAssociationFieldEditor, CustomBooleanFieldDescriptor, CustomDateFieldEditor, CustomDateFieldWithDatePickerEditor, CustomDoubleFieldEditor, EmployeeByCriteriaAssociationFieldEditor, FlexibleAvailabilityFieldEditor, MedicalCheckupFieldEditor, PersonByCriteriaAssociationFieldEditor, RealizationFieldEditor, RegistrationStatusAssociationFieldEditor, RelatedRegistrationsFieldEditor, WorkflowProcessesFieldEditor } from "./customFieldRenderersEditors";

// READ_ONLY_MODE: if the user doesn’t have the rights to edit a registration that is in the past (planning_edit_past_registrations) 
// or if he tries to edit an already existing exchange or sequence exchange 
export const READ_ONLY_MODE = 1;
export const EDIT_MODE = 2;
// EDIT_ONLY_ONCE_MODE: used only for registrations of type exchange or sequence exchange
export const EDIT_ONLY_ONCE_MODE = 3;

const NEW_REALIZATION_DAYS = "NEW_REALIZATION_DAYS";
const REGISTRATION_EDITOR = "RegistrationEditor";
const REGISTRATION = "REGISTRATION";
const ATTACHMENT_SIZE = 100;
const ATTACHMENT_TYPE = "upload";

// tab keys
const BASIC_TAB = '1';
const ATTACHMENTS_TAB = '2';
const REMARKS_TAB = '3';
const CALCULATIONS_TAB = "4";
const BALANCES_TAB = "5";
const COST_CENTERS_TAB = "6";
const RELATIONS_TAB = "7";
const SYSTEM_TAB = "8";
export const EDUCATION_COMPANY_TYPE = "EDUCATION";

export enum ModalOperations {
    CONFIRM_LEAVE_PAGE = "CONFIRM_LEAVE_PAGE",
    CONFIRM_IGNORE_EXCEPTIONS = "CONFIRM_IGNORE_EXCEPTIONS",
    CONFIRM_SPLIT_REGISTRATION = "CONFIRM_SPLIT_REGISTRATION",
    CONFIRM_NOT_ELIGIBLE_FOR_RELAPSE = "CONFIRM_NOT_ELIGIBLE_FOR_RELAPSE",
    CONFIRM_NEW_REALIZATION = "CONFIRM_NEW_REALIZATION"
};

export enum REGISTRATION_EDITOR_MODE {
    AGENDA, PLANNING
}

enum FLEXIBLE_ROUNDING_TYPES {
    EARLY = 'EARLY', LATE = 'LATE', ALL_DAY = 'ALL_DAY', EMPTY = 'EMPTY'
}

export const FLEXIBLE_ROUNDING_VALUES = [
    { code: 'EARLY', description: 'Vroege' },
    { code: 'LATE', description: 'Late' },
    { code: 'ALL_DAY', description: 'De hele dag' }
]

export enum ProteusGraphQLErrorExtensions {
    VALIDATION_EXCEPTIONS_FOR_WAITING_REGISTRATIONS = "validationExceptionsForWaitingRegistrations",
    ORIGINAL_PROTEUS_EXCEPTION = "originalProteusException",
    ORIGINAL_CAUSE_MESSAGE = "originalCauseMessage",
    ORIGINAL_CAUSE_ERROR_CODE = "originalCauseErrorCode"
};

export enum LayoutTypeCode {
    DELEGATION = "DELEGATIE",
    EDUCATION = "OPLEIDING",
    EXCHANGE = "WISSEL",
    ILLNESS = "ZIEKTE",
    INSTRUCTOR = "LESGEVER",
    LEAVE = "VERLOF",
    MEDICAL_CHECKUP = "MEDISCH_ONDERZOEK",
    OPERATIONAL = "PRESTATIE",
    SEQUENCE_EXCHANGE = "BEURTWISSEL",
    TIME_CREDIT = "TIJDSKREDIET",
    WORK_ACCIDENT = "ARBEIDSONGEVAL",
    WORK_PERIOD = "WERK_PERIODE",
    SHIP_WORK_PERIOD = "SCHIP_WERK_PERIODE",
    FLEXIBLE_AVAILABILITY = "FLEX_BESCHIKBAARHEID",
    OVERTIME = "OVERUREN"
};

export interface RegistrationContext {
    split: boolean,
    roundingType: CodeEntity,
    halfDay: CodeEntity | null,
    removeWaiting: boolean,
    flexibleRounding?: string
}

export enum RegistrationDetailObjectType {
    LEAVE_DETAIL = "LeaveDetail",
    CONSTRAINT_GROUP_DETAIL = "ConstraintGroupDetail",
    OPERATIONAL_DETAIL = "OperationalDetail",
    ILLNESS_DETAIL = "IllnessDetail",
    INSTRUCTOR_DETAIL = "InstructorDetail",
    EXCHANGE_DETAIL = "ExchangeDetail",
    SEQUENCE_EXCHANGE_DETAIL = "SequenceExchangeDetail",
    DELEGATION_DETAIL = "DelegationDetail",
    MEDICAL_CHECKUP_DETAIL = "MedicalCheckupDetail",
    TRAINING_DETAIL = "TrainingDetail",
    SHIP_ROSTER_DETAIL = "ShipRosterDetail",
    OVERTIME_DETAIL = "OvertimeDetail",
    WORK_ACCIDENT_DETAIL = "WorkAccidentDetail"
}

export interface RegistrationDetail {
    objectType: string
}

export interface ConstraintDetail extends RegistrationDetail {
    groupCode: string | null | undefined
}

export interface OperationalDetail extends RegistrationDetail {
    choicePilot: boolean,
    superPilot: boolean,
    babySuperPilot: boolean,
    doublePilot: boolean,
    seaCanal: boolean,
    regime: string,
    direction: string,
    annulation: boolean,
    delay: boolean,
    info: string,
    call: string,
    assignmentCode: string,
    from: { code: string, name: string },
    to: { code: string, name: string },
    ship: { code: string, name: string, loa: number, breadth: number },
    agent: { code: string, name: string },
    role: { code: string, description: string }
}

export interface IllnessDetail extends RegistrationDetail {
    fromDateCertificate: Date | string,
    toDateCertificate: Date | string,
    creditCorrection: number,
    relapseOf: { fromDate: string | Date, toDate: string | Date, resource: number },
    relapseRegistrations: { fromDate: string | Date, toDate: string | Date, resource: number }[]
}

export interface ExchangeDetail extends RegistrationDetail {
    secondDate: Date | string,
    secondParty: Person,
    applicant: boolean,
    exchange: Exchange,
    compensation: Exchange
}

export interface Exchange {
    fromDate: Date | string,
    toDate: Date | string,
    workPeriodType: { name: string },
    substitute: Person,
    substituted: Person
}

export interface SequenceExchangeDetail extends RegistrationDetail {
    secondParty: Person,
    applicant: boolean
}

export interface DelegationDetail extends RegistrationDetail {
    delegate: Person,
    workflows: string
}

export interface MedicalCheckupDetail extends RegistrationDetail {
    typeCode: string | null
}

export interface TrainingDetail extends RegistrationDetail {
    trainer: Person,
    componentVersion: getRealizationsByCriteria_educationServiceFacadeBean_realizations_componentVersion & { component?: { type: { code: string } } },
    realization: getRealizationsByCriteria_educationServiceFacadeBean_realizations,
    company: CompanyInput,
    socialHoursBalance: number
}

export interface InstructorDetail extends RegistrationDetail {
    componentVersion: getRealizationsByCriteria_educationServiceFacadeBean_realizations_componentVersion & { component?: { type: { code: string } } },
    socialHoursBalance: number
}

export interface ShipRosterDetail extends RegistrationDetail {
    ship: CodeEntity,
    spare: CodeEntity,
    category: CodeEntity
}

export interface OvertimeDetail extends RegistrationDetail {
    type: CodeEntity
}

export interface Registration extends getRegistration_planningServiceFacadeBean_registration {
    detail: ConstraintDetail | OperationalDetail | IllnessDetail | ExchangeDetail |
    SequenceExchangeDetail | DelegationDetail | MedicalCheckupDetail | TrainingDetail |
    InstructorDetail | ShipRosterDetail | OvertimeDetail | null
}

export const sliceRegistrationEditor = createSliceFoundation(class SliceRegistrationEditor {

    initialState = {
        context: undefined as unknown as RegistrationContext,
        entityHasBeenModified: false as boolean,
        showModal: { open: false as boolean, message: "" as string, operation: "" as string, showNoButton: true as boolean },
        validationExceptionsForWaitingRegistrations: false as boolean,
        leaveData: undefined as unknown as getSelectableLeaveGroupsData_resourcesServiceFacadeBean_selectableLeaveGroupsData,
        selectedLeaveGroup: undefined as unknown as { id: number | null, code: string | null, name: string | null } | null | undefined,
        defaultRegistrationStatus: undefined as unknown as CodeEntity,
        relatedRegistrations: [] as Registration[],
        exchangeGroupCodes: [] as string[],
        workflowProcesses: [] as { name: string, description: string }[],
        medicalCheckupTypes: [] as CodeEntity[],
        newRealizationDaysParam: undefined as unknown as number | null,
        realizationsOptions: [] as getRealizationsByCriteria_educationServiceFacadeBean_realizations[],
        disableRealizationDueDate: false as boolean,
        registrationAttachments: [] as (getRegistration_planningServiceFacadeBean_registration_attachments | null)[] | null,
        registrationRemarks: [] as (getRegistration_planningServiceFacadeBean_registration_remarks | null)[] | null,
        editRemarkModal: { open: false, index: -1, remark: "" } as { open: boolean, index: number, remark: string },
        registrationCalculationRelations: [] as (getRegistration_planningServiceFacadeBean_registration_calculationRelations | null)[],
        shouldLoadBalanceData: true as boolean,
        shoudlLoadCalculationData: true as boolean,
        educationRelations: [] as getTrainingRelations_registrationServiceFacadeBean_trainingRelations[],
        resourcePermissions: [] as CodeEntity[],
        halfDaysOptions: [] as CodeEntity[],
        shouldDisplayHalfDayPicker: false as boolean,
        defaultRoundingDates: undefined as unknown as { fromDate: Date | string, toDate: Date | string },
        useBalance: false as boolean,
        rosterHasHalfDay: false as boolean,
        roundingTypes: [] as CodeEntity[]
    }

    nestedSlices = {
        fileUploadButton: sliceFileUploadButton,
        balanceCalculation: sliceBalanceCalculation
    }

    reducers = {
        ...getBaseReducers<SliceRegistrationEditor>(this)
    }

    impures = {
        ...getBaseImpures<SliceRegistrationEditor>(this),

        /**
        * Save a registration with the given context. If the save failed with validationWarningException, 
        * the user can skip the errors if it has PLANNING_IGNORE_VALIDATION_ERRORS permission.
        * In this case a confirmation popup will appear for retry.
        */
        async saveRegistration(registration: Registration, context: RegistrationContext, retry: boolean, metadataContext: string, isModeAgenda: boolean, halfDay: string | null | undefined) {
            let savedContext = {
                split: context.split,
                roundingType: context.roundingType?.code,
                halfDaySetting: halfDay,
                removeWaiting: context.removeWaiting,
                validationExceptionsForWaitingRegistrations: retry ? this.getState().validationExceptionsForWaitingRegistrations : false,
                leaveGroupsData: this.getState().leaveData,
                errorPolicy: {
                    policy: retry ? ProteusConstants.IGNORE_WARNINGS : ProteusConstants.ENFORCE_ALL
                },
                processingWaitingRegistrations: false,
                trainingRegistrationUpdated: false,
                updatedFromSessions: false,
                useDefaultMetadataRounding: false,
                metadataContext: metadataContext,
                skipWorkflow: false
            };
            await apolloClient.mutate({
                mutation: PLANNING_SERVICE_FACADE_BEAN_SAVE_OR_UPDATE_REGISTRATION,
                variables: {
                    registrationAsJson: JSON.stringify(registration),
                    context: savedContext
                },
                context: {
                    [ApolloContext.ON_ERROR_HANDLER]: ProteusUtils.getApolloErrorHandlerForValidationException(ProteusConstants.PLANNING_IGNORE_VALIDATION_ERRORS, (e: CatchedGraphQLError) => {
                        this.getDispatchers().setInReduxState({
                            showModal: {
                                open: true,
                                message: e.graphQLErrors?.[0]?.extensions?.ORIGINAL_EXCEPTION_MESSAGE,
                                operation: ModalOperations.CONFIRM_IGNORE_EXCEPTIONS,
                                showNoButton: true
                            },
                            validationExceptionsForWaitingRegistrations: apolloGetExtensionFromError(e, ProteusGraphQLErrorExtensions.VALIDATION_EXCEPTIONS_FOR_WAITING_REGISTRATIONS)
                        });
                    }, !isModeAgenda)
                }
            });
        },

        async getRegistrationStatusByCode(code: string) {
            const registrationStatus = (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_STATUS_BY_CODE,
                variables: {
                    code: code
                }
            })).data.planningServiceFacadeBean_registrationStatus;
            this.getDispatchers().setInReduxState({ defaultRegistrationStatus: registrationStatus });
            return registrationStatus;
        },

        async getHalfDayByCode(code: string) {
            return (await apolloClient.query({
                query: HALF_DAY_SERVICE_GET_HALF_DAY_BY_CODE,
                variables: {
                    code: code
                }
            })).data.halfDayService_halfDayByCode;
        },

        async getRoundingValue(resource: number, fromDate: Date, toDate: Date, roundingType: CodeEntity, halfDayRounding: string | null) {
            return (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_GET_ROUNDING_VALUE,
                variables: { resource, fromDate, toDate, roundingType, halfDayRounding }
            })).data.planningServiceFacadeBean_roundingValue;
        },

        async getMatchingRoundingType(resource: number | null, fromDate: string | Date, toDate: string | Date) {
            return (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_MATCHING_ROUNDING_TYPE,
                variables: {
                    resource: resource,
                    actualToDate: toDate,
                    actualFromDate: fromDate
                }
            })).data.planningServiceFacadeBean_matchingRoundingType;
        },

        async getSelectableLeaveData(registration: Registration) {
            let selectedGroup = undefined;
            const leaveData = (await apolloClient.query({
                query: RESOURCES_SERVICE_FACADE_BEAN_GET_SELECTABLE_LEAVE_GROUPS_DATA,
                variables: {
                    resource: registration.resource,
                    fromDate: registration.fromDate,
                    toDate: registration.toDate,
                    registrationTypeCode: registration.type?.code
                }
            })).data.resourcesServiceFacadeBean_selectableLeaveGroupsData;

            //check if leave data contains the selected group. If not, load the group
            const groupFromLeaveData = leaveData.leaveGroupSnapshotsForReact.filter((item: { code: string }) => item.code === ((registration.detail) as ConstraintDetail)?.groupCode);
            if (groupFromLeaveData.length === 0) {
                selectedGroup = (await apolloClient.query({
                    query: GROUP_SERVICE_FACADE_BEAN_GET_GROUP_SNAPSHOT_BY_CODE,
                    variables: {
                        code: (registration.detail as ConstraintDetail)?.groupCode
                    }
                })).data.groupServiceFacadeBean_groupSnapshot;
            } else {
                selectedGroup = groupFromLeaveData[0];
            }
            this.getDispatchers().setInReduxState({ leaveData, selectedLeaveGroup: selectedGroup });
        },

        async getRelatedRegistrations(registration: Registration) {
            let searchCriteria = {
                rangeCriteria: {
                    fromDate: moment(new Date(registration.fromDate)).subtract(15, "days"),
                    toDate: new Date(registration.toDate.valueOf()),
                    inclusiveStartBefore: true,
                    inclusiveEndAfter: false
                },
                resources: [registration.resource],
                typeCodes: [
                    ProteusConstants.ZIEKTE,
                    ProteusConstants.ARBEIDSONGEVAL,
                    ProteusConstants.PRIVE_ONGEVAL_MET_VERZEKERING,
                    ProteusConstants.PRIVE_ONGEVAL_ZONDER_VERZEKERING
                ],
                canIncludeRegistrationsWithNullConstraintGroup: false,
                ignoreRemovedRegistrations: true,
                onlyConstrainedRegistrationsIncluded: false,
                loadDetailsOnlyForConstrainedTypes: false,
                onlyWaitingRegistrations: false
            }
            let relatedRegistrations = (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_SNAPSHOTS,
                variables: {
                    criteria: searchCriteria
                }
            })).data.planningServiceFacadeBean_registrationSnapshots;
            relatedRegistrations = [...relatedRegistrations].sort((a: Registration, b: Registration) => new Date(a.fromDate).getTime() < new Date(b.fromDate).getTime() ? 1 : -1);
            this.getDispatchers().setInReduxState({ relatedRegistrations });
        },

        async getRegistration(id: number) {
            const registration = (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_GET_REGISTRATION_JSON,
                variables: {
                    id: id
                }
            })).data.planningServiceFacadeBean_registrationAsJson;
            return JSON.parse(registration);
        },

        async getSecurityPermissions(resourceId: number | null) {
            if (!resourceId) {
                return;
            }

            const securityPermissions = (await apolloClient.query({
                query: SECURITY_SERVICE_FACADE_BEAN_GET_SECURITY_PERMISSIONS_FOR_RESOURCE,
                variables: {
                    id: resourceId
                }
            })).data.securityServiceFacadeBean_securityPermissionsForResource;
            this.getDispatchers().setInReduxState({ resourcePermissions: securityPermissions });
        },

        /**
        * To get the exchangeGroupCodes, all groups in which the resource is member must be loaded.
        * If the groups have relations, load the other groups that are part of the relation and add their codes to exchangeGroupCodes.
        * exchangeGroupCodes is used when a resource is searched for an exchange. It limits the returned resources.
        */
        async getExchangeGroups(resourceId: number | null) {
            // load all resource groups
            const groups: { id: number, code: string }[] = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_OF_MEMBER,
                variables: {
                    owner: ProteusUtils.getCurrentUser().id,
                    groupContextCode: LayoutTypeCode.LEAVE,
                    member: resourceId,
                    fromDate: new Date(),
                    untilDate: new Date(),
                    useExcludeFromSearch: false
                }
            })).data.employeeService_groupSnapshotsOfMember;
            if (groups === null || groups === undefined || groups.length === 0) {
                return;
            }

            // for each resource group, get its relations
            const groupsIds = groups.map((group: { id: number }) => group.id);
            const groupRelations = (await apolloClient.query({
                query: RESOURCES_SERVICE_FACADE_BEAN_GET_GROUP_RELATIONS,
                variables: {
                    groupIds: groupsIds,
                    relationType: LayoutTypeCode.EXCHANGE
                }
            })).data.resourcesServiceFacadeBean_groupRelations;

            let ids = [];
            for (let i = 0; i < groupRelations.length; i++) {
                for (let j = 0; j < groups.length; j++) {
                    // for each relation, get the other group id that is part of the relation
                    const other = this.getOtherGroupFromRelation(groupRelations[i], groups[j].id);
                    if (other !== null) {
                        ids.push(other);
                    }
                }
            }
            // because the code is needed in place of id, load the groups and retrieve their codes
            if (ids.length === 0) {
                return;
            }

            const groupsFromRelation = (await apolloClient.query({
                query: GROUP_SERVICE_FACADE_BEAN_GET_GROUPS,
                variables: {
                    groupIds: ids
                }
            })).data.groupServiceFacadeBean_groups;
            // exchangeGroupCodes is used when a resource is searched for an exchange. It limits the returned resources. 
            this.getDispatchers().setInReduxState({ exchangeGroupCodes: groupsFromRelation.map((group: { code: string }) => group.code) });
        },

        getOtherGroupFromRelation(relation: { source: number, target: number }, groupId: number) {
            if (groupId === relation.source) {
                return relation.target;
            } else if (groupId === relation.target) {
                return relation.source;
            } else {
                return null;
            }
        },

        async getWorkflowProcesses() {
            const workflowProcesses = (await apolloClient.query({
                query: WORKFLOW_SERVICE_FACADE_BEAN_GET_WORKFLOW_PROCESSES
            })).data.workflowServiceFacadeBean_workflowProcesses;
            this.getDispatchers().setInReduxState({ workflowProcesses });
        },

        async getMedicalCheckupTypes() {
            const medicalCheckupTypes = (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_GET_MEDICAL_CHECKUP_TYPES
            })).data.planningServiceFacadeBean_medicalCheckupTypes;
            this.getDispatchers().setInReduxState({ medicalCheckupTypes });
        },

        /**
         * For a new education registration, in case a "last" realization is found that is more than `newRealizationDaysParam` days in the past, 
         * the realization is still selected but there will be an ignorable warning. 
         */
        async getNewRealizationDaysParam() {
            const newRealizationDaysParam = (await apolloClient.query({
                query: COMMON_SERVICE_FACADE_BEAN_GET_PARAMETER_VALUE,
                variables: {
                    name: NEW_REALIZATION_DAYS,
                    date: moment(new Date()).startOf("date").toISOString()
                }
            })).data.commonServiceFacadeBean_parameterValue;
            this.getDispatchers().setInReduxState({ newRealizationDaysParam });
        },

        async getRealization(criteria: RealizationCriteriaInput) {
            return (await apolloClient.query({
                query: EDUCATION_SERVICE_FACADE_BEAN_GET_REALIZATIONS_BY_CRITERIA,
                variables: {
                    criteria
                }
            })).data.educationServiceFacadeBean_realizations;
        },

        async getHalfDays() {
            const halfDaysOptions = (await apolloClient.query({ query: HALF_DAY_SERVICE_GET_HALF_DAYS })).data.halfDayService_halfDays;
            this.getDispatchers().setInReduxState({ halfDaysOptions });
        },

        async getRoundingValues(resource: number, fromDate: Date | string, toDate: Date | string, roundingType: CodeEntity | null | undefined, halfDayRounding: string | null | undefined) {
            return (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_GET_ROUNDING_VALUE,
                variables: {
                    resource,
                    fromDate,
                    toDate,
                    roundingType,
                    halfDayRounding
                },
                context: this.getQueryContext()
            })).data.planningServiceFacadeBean_roundingValue;
        },

        async getLeaveDistributionCalculationRelations(registration: Registration, registrationCalculationRelations: (getRegistration_planningServiceFacadeBean_registration_calculationRelations | null)[]) {
            const leaveDistributionCalculationRelations = (await apolloClient.query({
                query: PLANNING_SERVICE_FACADE_BEAN_GET_LEAVE_DISTRIBUTION_CALCULATION_RELATIONS,
                variables: {
                    registrationAsJson: JSON.stringify(registration)
                },
                context: this.getCalculationRelationsQueryContext(registrationCalculationRelations)
            })).data.planningServiceFacadeBean_leaveDistributionCalculationRelationsWithJsonRegistration;
            return leaveDistributionCalculationRelations;
        },

        getCalculationRelationsQueryContext(registrationCalculationRelations: (getRegistration_planningServiceFacadeBean_registration_calculationRelations | null)[]) {
            return {
                [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                    if (registrationCalculationRelations) {
                        // in case an exception occurs when getting the 
                        this.getDispatchers().setInReduxState({ registrationCalculationRelations, shoudlLoadCalculationData: false });
                    }
                    return true;
                }
            }
        },

        getQueryContext(loadedBalance?: any) {
            return {
                [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                    if (loadedBalance) {
                        // in case an exception occurs when getting leave distribution balance data, set the already loaded balance into state
                        this.getDispatchers().setInReduxState({ shouldLoadBalanceData: false });
                        this.getDispatchers().balanceCalculation.setInReduxState({ balanceData: loadedBalance })
                    }
                    return true;
                }
            }
        },

        async getRegistrationEducationRelations(registrationId: number | null) {
            if (!registrationId) {
                return;
            }

            const educationRelations = (await apolloClient.query({
                query: REGISTRATION_SERVICE_FACADE_BEAN_GET_TRAINING_RELATIONS,
                variables: {
                    registration: registrationId
                }
            })).data.registrationServiceFacadeBean_trainingRelations;
            this.getDispatchers().setInReduxState({ educationRelations });
        }
    }
});

type PropsNotFromState = {
    employee: any;
    registration: Registration;
    isWaitingRegistration?: boolean;
    operatingMode: number;
    onDrawerClose: (shouldReloadData?: boolean) => void;
    // this method must be implemented in Planning page. It will return the rounding values and modify the segment startDate and endDate-
    // after the segment is modified, the registration fromDate and toDate must be updated too, accordingly
    loadRoundingValues?: () => void;
    viewMode?: boolean;
    goToRegistration?: (id: number) => void;
    metadataContext: string;
    mode: REGISTRATION_EDITOR_MODE;
    forceDateRounding?: boolean;
}

type Props = PropsFrom<typeof sliceRegistrationEditor> & PropsNotFromState;

export class RegistrationEditor extends React.Component<Props> {

    protected refBasicEditor = React.createRef<EntityEditorFormSimple>();
    protected refDetailsCompartment = React.createRef<EntityEditorFormSimple>();
    protected refBalanceCalculation = React.createRef<BalanceCalculation>();

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - BASIC COMPARTMENT
    ////////////////////////////////////

    /**
    * The "milestone" button simulates a milestone registration. It sets the toDate = fromDate, but the registration will not become a "milestone" registration.
    * The result of method isMilestone() which checks for the MILESTONE property from metadataContext will not be changed by this button.
    */
    protected onMilestoneButtonClick() {
        const newEndDate = this.getRegistrationValuesFromForm().fromDate;
        if (newEndDate !== null && newEndDate !== undefined && newEndDate !== this.getRegistrationValuesFromForm().toDate) {
            this.refBasicEditor.current?.formikContext.setFieldValue("registration.toDate", newEndDate);
            this.fromDateOrToDateChanged(null, newEndDate, false);
            this.onEntityChange();
        }
    }

    /**
    * When the @param fromDate or @param toDate properties are manually changed (from their datePickers), the selected roundingType and halfDay must be set to null.
    * @param roundingTypeChanged is used to mark if the fromDate or toDate were changed during rounding type change. In this case, the roundingType and halfDay must NOT be set to null.
    */
    protected fromDateOrToDateChanged(newFromDate: Moment | null, newEndDate: Moment | null, roundingTypeChanged: boolean) {
        let registration = this.getRegistrationToBeSaved();
        // because when this method is called, fromDate and toDate values are not propagated yet into registration,
        // we must take it as method parameter and update the registration we send to the following methods accordingly
        if (newFromDate !== null) {
            registration = { ...registration, fromDate: newFromDate };
        }
        if (newEndDate !== null) {
            registration = { ...registration, toDate: newEndDate };
        }
        if (!roundingTypeChanged) {
            this.refBasicEditor.current?.formikContext.setFieldValue("contextRoundingType", null);
            this.refBasicEditor.current?.formikContext.setFieldValue("contextHalfDay", null);
        }
        if (this.shouldLoadSelectableLeaveGroupsData(registration)) {
            this.processSelectableLeaveGroupData(registration);
        }
        if (this.shouldLoadRelatedRegistrations()) {
            this.props.dispatchers.getRelatedRegistrations(registration);
            this.refDetailsCompartment.current?.formikContext.setFieldValue("registration.detail.relapseOf", null);
        }
        this.onEntityChange();
        this.props.dispatchers.setInReduxState({ shoudlLoadCalculationData: true });
    }

    protected getDefaultRoundingTypeForRegistration(registration: Registration) {
        return MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!,
            registration?.type?.code!, registration?.status?.code, ProteusConstants.ROUNDING_TYPE_CODE, this.props.metadataContext);
    }

    protected async getDefaultHalfDayRoundingForRegistration(registration: Registration) {
        return MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!,
            registration?.type?.code!, (!this.props.registration.status ? await this.getDefaultRegistrationStatus(registration) : this.props.registration.status).code, ProteusConstants.HALF_DAY_ROUNDING_PROPERTY, this.props.metadataContext);
    }

    protected async getRoundingTypeAndHalfDay(registration: Registration) {
        let roundingType = undefined;
        let halfDay = undefined;
        let defaultRoundingDates = undefined;
        if ((registration && !registration.id) || this.props.forceDateRounding) {
            // for newly created registrations, get the default value for roundingType. HalfDay does not have a default value, it should be the first value from the options
            const roundingTypeCode = this.getDefaultRoundingTypeForRegistration(registration)?.toString();
            if (roundingTypeCode) {
                let filteredRoundingTypes = this.props.roundingTypes.filter(roundingType => roundingType.code === roundingTypeCode);
                if (filteredRoundingTypes && filteredRoundingTypes.length > 0) {
                    roundingType = filteredRoundingTypes[0];
                }

                halfDay = this.props.halfDaysOptions[0];
                defaultRoundingDates = await this.props.dispatchers.getRoundingValues(registration.resource!, registration.fromDate, registration.toDate, roundingType, halfDay?.code);
                this.props.dispatchers.setInReduxState({ rosterHasHalfDay: defaultRoundingDates.rosterHasHalfDay });
                if (roundingTypeCode === ProteusConstants.WORK_PERIOD_ROUNDING_TYPE && defaultRoundingDates.rosterHasHalfDay) {
                    this.props.dispatchers.setInReduxState({ shouldDisplayHalfDayPicker: true });
                }
            }
        } else {
            const result = await this.props.dispatchers.getMatchingRoundingType(registration.resource, registration.fromDate, registration.toDate);
            roundingType = result?.roundingType;
            if (roundingType && roundingType.code === ProteusConstants.WORK_PERIOD_ROUNDING_TYPE && result?.halfDay) {
                halfDay = await this.props.dispatchers.getHalfDayByCode(result?.halfDay);
                this.props.dispatchers.setInReduxState({ shouldDisplayHalfDayPicker: true });
            }
        }
        return { roundingType, halfDay, defaultRoundingDates };
    }

    protected getSplit(registration: Registration) {
        return Boolean(MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!,
            registration?.type?.code!, registration?.status?.code, ProteusConstants.MAP_TO_WORKPERIOD, this.props.metadataContext));
    }

    getFlexibleRounding(registration: { fromDate: any, toDate: any, type: any }) {
        if (MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!,
            registration?.type?.code!, null, ProteusConstants.LAYOUT_TYPE_CODE, this.props.metadataContext)?.toString() !== LayoutTypeCode.FLEXIBLE_AVAILABILITY) {
            return undefined;
        }
        const startHour = moment(registration.fromDate).get('hour');
        const endHour = moment(registration.toDate).get('hour');
        if (startHour === 0) {
            if (endHour === 11) {
                return FLEXIBLE_ROUNDING_TYPES.EARLY;
            } else {
                return FLEXIBLE_ROUNDING_TYPES.ALL_DAY;
            }
        } else if (startHour == 12) {
            return FLEXIBLE_ROUNDING_TYPES.LATE;
        }
        return FLEXIBLE_ROUNDING_TYPES.EMPTY;
    }

    protected async computeRegistrationContext(registration: Registration) {
        const roundingTypeAndHalfDay = await this.getRoundingTypeAndHalfDay(registration);
        let context: any = {
            split: this.getSplit(registration),
            roundingType: roundingTypeAndHalfDay.roundingType,
            halfDay: roundingTypeAndHalfDay.halfDay,
            removeWaiting: false,
            flexibleRounding: this.getFlexibleRounding(registration)
        };
        this.props.dispatchers.setInReduxState({ context, defaultRoundingDates: roundingTypeAndHalfDay.defaultRoundingDates });
        return roundingTypeAndHalfDay.defaultRoundingDates;
    }

    /**
    * Checks if a registration is milestone by looking for MILESTONE property value from MetadataContext
    */
    protected isMilestone(registration: Registration) {
        if (registration === null || registration === undefined) {
            return false;
        }
        return Boolean(MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!,
            registration?.type?.code!, registration?.status?.code, ProteusConstants.MILESTONE, this.props.metadataContext));
    }

    protected isResourceChangeAllowed(registration: Registration) {
        if (registration === null || registration === undefined) {
            return false;
        }
        return Boolean(MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!,
            registration?.type?.code!, registration?.status?.code, ProteusConstants.ALLOW_RESOURCE_CHANGE_IN_REG_EDITOR, this.props.metadataContext));
    }

    protected disableFromDate = (current: Moment) => {
        const endDate = this.getRegistrationValuesFromForm().toDate;
        // Can not select days after toDate
        if (endDate) {
            return current && current > moment(endDate);
        }
        return false;
    }

    protected disableEndDate = (current: Moment): boolean => {
        const fromDate = this.getRegistrationValuesFromForm().fromDate;
        // Can not select days before fromDate
        if (fromDate) {
            return current && current < moment(fromDate);
        }
        return false;
    }

    protected onFromDateChange = (date: Moment | null, roundingTypeChanged: boolean) => {
        // TODO #35440  - validation for the temporary DatePicker, remove after fix issues with DatePicker
        const endDate = this.getRegistrationValuesFromForm().toDate;
        if (endDate) {
            if (date && date > moment(endDate)) {
                Utils.showGlobalAlert({ message: _msg('RegistrationEditor.registration.dateValidator.error'), severity: Severity.INFO });
                return false;
            }
        }
        // end of change

        const registration = this.getRegistrationValuesFromForm();
        const oldDate = this.getRegistrationValuesFromForm().fromDate;
        let newEndDate = null;
        if (date !== null && date !== undefined && date !== oldDate && this.isMilestone(registration)) {
            // in case of milestone registration, change the "toDate" accordingly
            this.refBasicEditor.current?.formikContext.setFieldValue("registration.toDate", date);
            newEndDate = date;
        }
        this.syncNewRealizationDateWithRegistrationDate(date);
        this.fromDateOrToDateChanged(date, newEndDate, roundingTypeChanged);
    }

    protected onToDateChange = (date: Moment | null, roundingTypeChanged: boolean) => {
        // TODO #35440  - validation for the temporary DatePicker, remove after fix issues with DatePicker
        const startDate = this.getRegistrationValuesFromForm().fromDate;
        if (startDate) {
            if (date && date < moment(startDate)) {
                Utils.showGlobalAlert({ message: _msg('RegistrationEditor.registration.dateValidator.error'), severity: Severity.INFO });
                return false;
            }
        }
        // end of change
        this.fromDateOrToDateChanged(null, date, roundingTypeChanged);
    }

    /**
    * Remove waiting registration checkbox is visible when the registration is in waiting mode (edited or removed).
    * The registrations created directly with waiting status (when the balance is > 0) can be removed directly, there is no need to use "Remove waiting registration" checkbox.
    * An effective registration which was edited or removed is a waiting registration that has the status "EFF", until the balance becomes 0 and all waiting registrations are resolved.
    * A registration created when the balance is > 0 has the status "AHW" (waiting).
    */
    protected isRemoveWaitingRegistrationVisible() {
        let visible = false;
        if (this.props.isWaitingRegistration && this.props.registration !== null && this.props.registration !== undefined &&
            this.props.registration.id !== undefined && this.props.registration.id !== null && this.props.registration.status?.code !== ProteusConstants.CODE_WAITING) {
            if (ProteusUtils.checkPermission(ProteusConstants.OVERRIDE_ZERO_LEAVE_BALANCE_LOGIC)) {
                visible = true;
            }
        }
        return visible;
    }

    protected isEditable() {
        return this.props.operatingMode !== READ_ONLY_MODE && !this.props.viewMode;
    }

    protected hasMetadataRounding() {
        const roundingTypeCode = this.getDefaultRoundingTypeForRegistration(this.props.registration)?.toString();
        return (this.props.mode !== REGISTRATION_EDITOR_MODE.AGENDA && ProteusUtils.checkPermission(ProteusConstants.PLANNING_EDIT_DATES_IN_DETAIL)) || !roundingTypeCode;
    }

    protected onRegistrationResourceChange = async (data: Person) => {
        let registration = this.getRegistrationToBeSaved();
        registration = { ...registration, resource: data.id };
        if (this.shouldLoadSelectableLeaveGroupsData(registration)) {
            this.processSelectableLeaveGroupData(registration);
        }
        if (this.shouldLoadRelatedRegistrations()) {
            this.props.dispatchers.getRelatedRegistrations(registration);
            this.refDetailsCompartment.current?.formikContext.setFieldValue("registration.detail.relapseOf", null);
        }
        if (this.shouldLoadExchangeGroups()) {
            this.props.dispatchers.getExchangeGroups(data.id);
            this.refDetailsCompartment.current?.formikContext.setFieldValue("registration.detail.secondParty", null);
        }
        if (this.shouldLoadDelegationData()) {
            this.refDetailsCompartment.current?.formikContext.setFieldValue("registration.detail.delegate", null);
        }
        if (this.shouldLoadEducationData()) {
            await this.loadEducationData(registration);
            const newRealization = this.props.realizationsOptions.filter((item: getRealizationsByCriteria_educationServiceFacadeBean_realizations) =>
                item.id === undefined)[0];
            this.refDetailsCompartment.current?.formikContext.setFieldValue("registration.detail.realization", newRealization);
        }
        this.onEntityChange();
        this.props.dispatchers.setInReduxState({ shouldLoadBalanceData: true, shoudlLoadCalculationData: true });
    }

    protected modifyHalfDayPicker(value: any, displayPicker: boolean) {
        // AssociationFieldEditor is used as fieldEditor, and can't have in formiks as 'context.halfDay' because AssociationFieldEditor can't navigate to the correct value
        this.refBasicEditor.current?.formikContext.setFieldValue("contextHalfDay", value);
        this.props.dispatchers.setInReduxState({ shouldDisplayHalfDayPicker: displayPicker });
    }

    protected onRoundingTypeOrHalfDayChange = async (roundingType: CodeEntity & { __typename?: string }, halfDay: CodeEntity | null) => {
        // if the rounding type is not present, do nothing. The current dates from the form will be kept. 
        if (!roundingType) {
            this.onEntityChange();
            return;
        }

        const registration = this.getRegistrationToBeSaved();
        delete roundingType.__typename;
        const newFromDateToDate = await this.props.dispatchers.getRoundingValues(registration.resource, registration.fromDate, registration.toDate, roundingType, halfDay?.code);
        this.props.dispatchers.setInReduxState({ rosterHasHalfDay: newFromDateToDate.rosterHasHalfDay });
        if (roundingType?.code === ProteusConstants.WORK_PERIOD_ROUNDING_TYPE && newFromDateToDate.rosterHasHalfDay) {
            this.props.dispatchers.setInReduxState({ shouldDisplayHalfDayPicker: true });
        }
        this.refBasicEditor.current?.formikContext.setFieldValue("registration.fromDate", newFromDateToDate.fromDate);
        this.refBasicEditor.current?.formikContext.setFieldValue("registration.toDate", newFromDateToDate.toDate);
        // this method must be implemented in planning page. It will modify the segment start and end based on the newFromDateToDate.
        this.props.loadRoundingValues?.();
        this.onFromDateChange(newFromDateToDate.fromDate, true);
        this.onToDateChange(newFromDateToDate.toDate, true);
    }

    protected onRoundingTypeChange = async (roundingType: CodeEntity & { __typename?: string }) => {
        let halfDayCode = null;
        if (roundingType?.code === ProteusConstants.WORK_PERIOD_ROUNDING_TYPE) {
            halfDayCode = this.props.halfDaysOptions?.[0];
            this.modifyHalfDayPicker(this.props.halfDaysOptions?.[0], true);
        } else {
            this.modifyHalfDayPicker(null, false);
        }
        await this.onRoundingTypeOrHalfDayChange(roundingType, halfDayCode);
    }

    protected getAttachmentsRemarksCheckIcon(name: string, descriptor: EntityDescriptor) {
        descriptor.addFieldDescriptor({
            name: name,
            type: FieldType.string,
            enabled: this.allowEdit()
        }, new class extends FieldDescriptor {
            protected renderFieldEditorInternal() {
                return <Icon color="green" name="check"></Icon>
            }
        });
        return descriptor;
    }

    protected getRegistrationTabBasicDescriptor() {
        // TODO #35440  - there are some issues with DatePicker on iOS, for the moment we have replaced it with DatePicker from antd in agenda.
        // There is some duplicate code because we needed a quick fix: in agenda - DatePicker from antd, in planning - DatePickerFieldEditor
        const that = this;
        const registration = this.getRegistrationValuesFromForm();
        let descriptor = new EntityDescriptor({ name: REGISTRATION_EDITOR }, false);
        if (!this.isModeAgenda()) {
            descriptor.addFieldDescriptor({
                name: "registration.resource",
                type: "EmployeeSnapshot",
                enabled: this.isEditable() && this.isResourceChangeAllowed(registration)
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        includeAllWithValidContract: true,
                        isClearable: false,
                        onChange: that.onRegistrationResourceChange,
                        hidePersonId: true,
                        queryLimit: -1,
                    };
                    return React.createElement(PersonAssociationFieldEditor as any, newProps as FieldEditorProps);
                }
            });
            descriptor.addFieldDescriptor({
                name: "registration.type.name",
                type: FieldType.string,
                enabled: false
            });
            descriptor.addFieldDescriptor({
                name: "registration.fromDate",
                type: FieldType.date,
                enabled: this.isEditable() && this.allowFieldModificationForEducation() && this.hasMetadataRounding(),
                additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, {
                    format: ProteusConstants.DATE_TIME_FORMAT,
                    allowClear: false,
                   disabledDate: this.isMilestone(registration) ? () => false : this.disableFromDate
                })
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = { ...props, onDateChange: that.onFromDateChange };
                    return React.createElement(CustomDateFieldEditor as any, newProps as FieldEditorProps);
                }
            })
            descriptor.addFieldDescriptor({
                name: "registration.toDate",
                type: FieldType.date,
                enabled: this.isMilestone(registration) ? false : this.isEditable() && this.allowFieldModificationForEducation() && this.hasMetadataRounding(),
                additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, {
                    format: ProteusConstants.DATE_TIME_FORMAT,
                    allowClear: false,
                   disabledDate: this.disableEndDate
                })
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    if (that.isMilestone(registration) && new Date(props.formikProps?.values.registration.fromDate).getTime() !==
                        new Date(props.formikProps?.values.registration.toDate).getTime()) {
                        props.formikProps?.setFieldValue(props.fieldDescriptor?.name!, props.formikProps?.values.registration.fromDate);
                    }
                    const newProps = { ...props, onDateChange: that.onToDateChange };
                    return (
                        <div className="RegistrationEditor_milestoneDiv">
                            <CustomDateFieldEditor {...newProps} />
                            <Popup content={_msg("RegistrationEditor.milestone.label")} basic trigger={<Button primary className="RegistrationEditor_milestoneBtn" onClick={() => that.onMilestoneButtonClick()}><div className="RegistrationEditor_milestoneIcon"></div></Button>} />
                        </div>
                    )
                }
            }());
        }

        if (this.isModeAgenda()) {
        descriptor.addFieldDescriptor({
            name: "registration.fromDate",
            type: FieldType.date,
            enabled: this.isEditable() && this.allowFieldModificationForEducation() && this.hasMetadataRounding(),
            additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, {
                format: ProteusConstants.DATE_TIME_FORMAT,
                allowClear: false
            })
        }, new class extends FieldDescriptor {
            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                const newProps = { ...props, onDateChange: that.onFromDateChange };
                return React.createElement(CustomDateFieldWithDatePickerEditor as any, newProps as FieldEditorProps);
            }
        })
        descriptor.addFieldDescriptor({
            name: "registration.toDate",
            type: FieldType.date,
            enabled: this.isMilestone(registration) ? false : this.isEditable() && this.allowFieldModificationForEducation() && this.hasMetadataRounding(),
            additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, {
                format: ProteusConstants.DATE_TIME_FORMAT,
                allowClear: false
            })
        }, new class extends FieldDescriptor {
            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                if (that.isMilestone(registration) && new Date(props.formikProps?.values.registration.fromDate).getTime() !==
                    new Date(props.formikProps?.values.registration.toDate).getTime()) {
                    props.formikProps?.setFieldValue(props.fieldDescriptor?.name!, props.formikProps?.values.registration.fromDate);
                }
                const newProps = { ...props, onDateChange: that.onToDateChange };
                return (
                    <div className="RegistrationEditor_milestoneDiv">
                        <CustomDateFieldWithDatePickerEditor {...newProps} />
                        <Popup content={_msg("RegistrationEditor.milestone.label")} basic trigger={<Button primary className="RegistrationEditor_milestoneBtn" onClick={() => that.onMilestoneButtonClick()}><div className="RegistrationEditor_milestoneIcon"></div></Button>} />
                    </div>
                )
            }
        }());
        }
        descriptor.addFieldDescriptor({
            name: "registration.status", type: "RegistrationStatus", enabled: this.allowEdit() && !this.isModeAgenda()
        }, new class extends FieldDescriptor {
            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                const newProps = {
                    ...props,
                    registrationType: that.props.registration?.type,
                    isClearable: false,
                    onChange: that.onEntityChange
                };
                return React.createElement(RegistrationStatusAssociationFieldEditor as any, newProps as FieldEditorProps);
            }
        })
        if (!this.isModeAgenda()) {
            descriptor.addFieldDescriptor({
                // AssociationFieldEditor is used as fieldEditor, and can't have in formiks as 'context.roundingType' because AssociationFieldEditor can't navigate to the correct value
                name: "contextRoundingType", type: "RoundingType", enabled: this.allowEdit() && !this.isMilestone(registration) && this.allowFieldModificationForEducation() && !this.isModeAgenda()
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = { ...props, onChange: that.onRoundingTypeChange };
                    return React.createElement(EditorClass as any, newProps as FieldEditorProps);
                }
            });
        }

        if (!this.isModeAgenda() && this.props.shouldDisplayHalfDayPicker && this.hasPermission(ProteusConstants.PLANNING_ALLOW_HALF_DAY_REGISTRATIONS)) {
            // AssociationFieldEditor is used as fieldEditor, and can't have in formiks as 'context.halfDay' because AssociationFieldEditor can't navigate to the correct value
            descriptor.addFieldDescriptor({
                name: "contextHalfDay",
                type: "HalfDay",
                enabled: this.isEditable()
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        onChange: that.onHalfDayChange,
                        isClearable: false
                    };
                    return React.createElement(AssociationFieldEditor as any, newProps as FieldEditorProps);
                }
            });
        }

        if (this.isRemoveWaitingRegistrationVisible()) {
            descriptor.addFieldDescriptor({
                name: "context.removeWaiting",
                type: FieldType.boolean
            }, new CustomBooleanFieldDescriptor(that.onEntityChange))
        }

        descriptor.addFieldDescriptor({
            name: "context.split",
            type: FieldType.boolean,
            enabled: this.allowEdit() && !this.isMilestone(registration) && this.allowFieldModificationForEducation() && this.props.registration.id
        }, new CustomBooleanFieldDescriptor(that.onEntityChange))

        if (this.props.registrationAttachments && this.props.registrationAttachments.length > 0) {
            descriptor = this.getAttachmentsRemarksCheckIcon("registration.attachments", descriptor);
        }
        if (this.props.registrationRemarks && this.props.registrationRemarks.length > 0) {
            descriptor = this.getAttachmentsRemarksCheckIcon("registration.remarks", descriptor);
        }
        return descriptor;
    }

    protected renderRegistrationTab() {
        return <div view-mode={this.props.viewMode + ""} className="RegistrationEditor_registrationTab flex">
            <Label horizontal><Header as='h4'>{_msg("RegistrationEditor.base.label")}</Header></Label>
            <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_BasicTab"} ref={this.refBasicEditor} hideButtonBar entityDescriptor={this.getRegistrationTabBasicDescriptor()} entity={this.getBaseEntity()} />
            {this.renderAdditionalCompartments()}
        </div>
    }

    protected onSplitConfirmed() {
        this.refBasicEditor.current?.formikContext.setFieldValue("registration.toDate", this.props.leaveData?.splitDate);
        this.fromDateOrToDateChanged(null, this.props.leaveData?.splitDate, false);
    }

    /**
     * For EducationCompartent and InstructorCompartment, if the registration is part of at least one "EDUCATION" relation,
     * the following fields should be disabled: fromDate, toDate, componentVersion, roundingType, split.
     */
    protected allowFieldModificationForEducation() {
        return this.props.educationRelations.length === 0;
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - DETAILS COMPARTMENT (GENERAL)
    ////////////////////////////////////

    protected renderAdditionalCompartments() {
        const registration = this.props.registration;
        if (registration === undefined || registration.type === undefined) {
            return;
        }
        let compartments = [];
        let details = [];
        const typeCode = registration.type?.code;
        const layoutTypeCode = this.getLayoutType()?.toString();
        if (layoutTypeCode === LayoutTypeCode.OPERATIONAL) {
            compartments.push(this.renderOperationalTaskCompartment());
        } else if (layoutTypeCode === LayoutTypeCode.LEAVE && typeCode === ProteusConstants.LEAVE_TYPE_CODE) {
            compartments.push(this.renderHolidayCompartment());
        } else if (layoutTypeCode === LayoutTypeCode.ILLNESS) {
            compartments.push(this.renderIllnessCompartment());
        } else if (layoutTypeCode === LayoutTypeCode.WORK_ACCIDENT) {
            compartments.push(this.renderWorkAccidentCompartment());
        } else if (layoutTypeCode === LayoutTypeCode.EXCHANGE) {
            compartments.push(this.renderExchangeCompartment());
        } else if (layoutTypeCode === LayoutTypeCode.SEQUENCE_EXCHANGE) {
            compartments.push(this.renderSequenceExchangeCompartment());
        } else if (layoutTypeCode === LayoutTypeCode.EDUCATION) {
            compartments.push(this.renderEducationCompartment());
        } else if (layoutTypeCode === LayoutTypeCode.DELEGATION) {
            compartments.push(this.renderDelegationCompartment());
        } else if (layoutTypeCode === LayoutTypeCode.MEDICAL_CHECKUP) {
            compartments.push(this.renderMedicalCheckupCompartment());
        } else if (layoutTypeCode === LayoutTypeCode.INSTRUCTOR) {
            compartments.push(this.renderInstructorCompartment());
        } else if (layoutTypeCode === LayoutTypeCode.SHIP_WORK_PERIOD) {
            compartments.push(this.renderShipWorkPeriodCompartment());
        } else if (layoutTypeCode === LayoutTypeCode.FLEXIBLE_AVAILABILITY) {
            compartments.push(this.renderFlexibleAvailabilityCompartment());
        } else if (layoutTypeCode === LayoutTypeCode.OVERTIME) {
            compartments.push(this.renderOvertimeCompartment());
        }

        // check for typeCode !== LEAVE_TYPE_CODE because the "Leave" compartment already contains the Constrained compartment
        if (typeCode !== ProteusConstants.LEAVE_TYPE_CODE) {
            const isConstrainedRegistration: boolean = this.isConstrained(registration);
            if (isConstrainedRegistration) {
                compartments.push(this.renderConstrainedRegistrationCompartment());
            }
        }

        for (let i = 0; i < compartments.length; i++) {
            details.push(
                <div className="RegistrationEditor_details">
                    <Label horizontal><Header as='h4'>{_msg("RegistrationEditor.details.label")}</Header></Label>
                    {compartments[i]}
                </div>
            );
        }
        return details;
    }

    protected getObjectTypeForRegistrationDetail() {
        const typeCode = this.props.registration?.type?.code;
        const layoutTypeCode = this.getLayoutType()?.toString();
        if (layoutTypeCode === LayoutTypeCode.OPERATIONAL) {
            return RegistrationDetailObjectType.OPERATIONAL_DETAIL;
        } else if (layoutTypeCode === LayoutTypeCode.LEAVE && typeCode === ProteusConstants.LEAVE_TYPE_CODE) {
            return RegistrationDetailObjectType.LEAVE_DETAIL;
        } else if (layoutTypeCode === LayoutTypeCode.ILLNESS) {
            return RegistrationDetailObjectType.ILLNESS_DETAIL;
        } else if (layoutTypeCode === LayoutTypeCode.WORK_ACCIDENT) {
            return RegistrationDetailObjectType.WORK_ACCIDENT_DETAIL;
        } else if (layoutTypeCode === LayoutTypeCode.EXCHANGE) {
            return RegistrationDetailObjectType.EXCHANGE_DETAIL;
        } else if (layoutTypeCode === LayoutTypeCode.SEQUENCE_EXCHANGE) {
            return RegistrationDetailObjectType.SEQUENCE_EXCHANGE_DETAIL;
        } else if (layoutTypeCode === LayoutTypeCode.DELEGATION) {
            return RegistrationDetailObjectType.DELEGATION_DETAIL;
        } else if (layoutTypeCode === LayoutTypeCode.MEDICAL_CHECKUP) {
            return RegistrationDetailObjectType.MEDICAL_CHECKUP_DETAIL;
        } else if (layoutTypeCode === LayoutTypeCode.EDUCATION) {
            return RegistrationDetailObjectType.TRAINING_DETAIL;
        } else if (layoutTypeCode === LayoutTypeCode.INSTRUCTOR) {
            return RegistrationDetailObjectType.INSTRUCTOR_DETAIL;
        } else if (typeCode !== ProteusConstants.LEAVE_TYPE_CODE && this.isConstrained(this.props.registration)) {
            return RegistrationDetailObjectType.CONSTRAINT_GROUP_DETAIL;
        } else if (layoutTypeCode === LayoutTypeCode.SHIP_WORK_PERIOD) {
            return RegistrationDetailObjectType.SHIP_ROSTER_DETAIL;
        } else if (layoutTypeCode === LayoutTypeCode.OVERTIME) {
            return RegistrationDetailObjectType.OVERTIME_DETAIL;
        } else {
            return undefined;
        }
    }

    protected getInitialRegistrationDetail() {
        const layoutTypeCode = this.getLayoutType()?.toString();
        let detail = this.props.registration.detail === null || this.props.registration.detail === undefined ?
            (this.getObjectTypeForRegistrationDetail() === undefined ? undefined : { objectType: this.getObjectTypeForRegistrationDetail() }) :
            this.props.registration.detail;
        if ((this.props.registration.id === null || this.props.registration.id === undefined) &&
            (layoutTypeCode === LayoutTypeCode.EXCHANGE || layoutTypeCode === LayoutTypeCode.SEQUENCE_EXCHANGE)) {
            detail = { ...detail as ExchangeDetail, applicant: true };
        } else if (layoutTypeCode === LayoutTypeCode.MEDICAL_CHECKUP && (detail as MedicalCheckupDetail).typeCode == undefined) {
            detail = { ...detail as MedicalCheckupDetail, typeCode: this.props.medicalCheckupTypes[0]?.code }
        } else if (layoutTypeCode === LayoutTypeCode.EDUCATION) {
            detail = { ...detail, socialHoursBalance: ((detail as TrainingDetail)?.socialHoursBalance || 0) } as TrainingDetail;
        } else if (layoutTypeCode === LayoutTypeCode.INSTRUCTOR) {
            detail = { ...detail, socialHoursBalance: ((detail as InstructorDetail)?.socialHoursBalance || 0) } as InstructorDetail;
        }
        return detail;
    }

    // needed for registration types with half day
    protected shouldLoadResourcePermissions() {
        return this.getDefaultHalfDayRoundingForRegistration(this.props.registration);
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - LEAVE COMPARTMENT
    ////////////////////////////////////

    protected shouldLoadHalfDays() {
        return this.hasPermission(ProteusConstants.PLANNING_ALLOW_HALF_DAY_REGISTRATIONS);
    }

    protected onHalfDayChange = async (halfDay: CodeEntity) => {
        const context = this.getContextValuesFromForm();
        await this.onRoundingTypeOrHalfDayChange({ ...context.roundingType }, halfDay);
    }

    protected renderHolidayCompartment() {
        return (
            <>
                {this.renderConstrainedRegistrationCompartment()}
            </>
        );
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - CONSTRAINED COMPARTMENT
    ////////////////////////////////////

    protected async processSelectableLeaveGroupData(registration: Registration, setPreselectedGroupSnapshotCode: boolean = true) {
        await this.props.dispatchers.getSelectableLeaveData(registration);
        if (registration.id === null || registration.id === undefined) {
            // if the "leaveData" object has a splitDate, a confirmation popup is shown
            // when confirmed, the "toDate" of the registration is set to the "splitDate", and the leaveData is reloaded with the new "toDate"
            if (this.props.leaveData.splitDate !== null && this.props.leaveData.splitDate !== undefined) {
                this.props.dispatchers.setInReduxState({
                    showModal: {
                        showNoButton: false,
                        open: true,
                        message: _msg("RegistrationEditor.splitRegistrationConfirmation.message",
                            registration?.type?.name,
                            this.formatDate(registration?.fromDate) + " - " + this.formatDate(registration?.toDate),
                            this.formatDate(this.props.leaveData?.splitDate)),
                        operation: ModalOperations.CONFIRM_SPLIT_REGISTRATION
                    }
                });
                return;
            } else if (setPreselectedGroupSnapshotCode) {
                /* Selected Leave Group is reset on date change because after changing the period, the user might be part of other group.
                   In this case the loaded selected leave groups. */
                const selectedGroup = this.props.leaveData.leaveGroupSnapshotsForReact?.filter((item: ({ code: string | null, name: string | null } | null)) =>
                    item?.code === this.props.leaveData.preselectedGroupSnapshotCode)[0];
                this.props.dispatchers.setInReduxState({ selectedLeaveGroup: selectedGroup, entityHasBeenModified: true });
            }
        }
    }

    protected shouldLoadSelectableLeaveGroupsData(registration: Registration): boolean {
        const layoutTypeCode = this.getLayoutType()?.toString();
        if (((layoutTypeCode === LayoutTypeCode.LEAVE && registration.type?.code === ProteusConstants.LEAVE_TYPE_CODE) ||
            this.isConstrained(registration)) && this.isEditable()) {
            return true
        }
        return false;
    }

    protected renderConstrainedRegistrationCompartment() {
        let groups: ({ code: string | null, name: string | null } | null)[] | null = this.props.leaveData?.leaveGroupSnapshotsForReact || [];
        const selectedGroupIsNotPartOfOptions = groups.filter((item: ({ code: string | null, name: string | null } | null)) =>
            item?.code === this.props.selectedLeaveGroup?.code).length === 0;
        if (selectedGroupIsNotPartOfOptions && this.props.selectedLeaveGroup !== undefined && this.props.selectedLeaveGroup !== null) {
            groups = [...groups, this.props.selectedLeaveGroup];
        }
        return (
            <Form>
                <Grid className="EntityEditorPage_grid" stackable columns='equal'>
                    <Grid.Row className="EntityEditorPage_grid_row" verticalAlign="middle">
                        <Grid.Column className="EntityEditorPage_grid_row_column">
                            <Form.Select selection search clearable={this.props.leaveData?.allowNull} selectOnNavigation={false} floating fluid scrolling
                                options={this.getDropdownOptionsFromEntity(groups)} className="RegistrationEditor_additionalField" disabled={!this.isEditable() || this.props.leaveData?.disableDropDown || (this.isModeAgenda() && !!this.props.registration.id)}
                                label={_msg("RegistrationEditor.groupRestrictions.label")} noResultsMessage={_msg("general.noResultsFound")} value={this.props.selectedLeaveGroup?.code || ""}
                                onChange={(e, data) => {
                                    const selectedGroup = this.props.leaveData.leaveGroupSnapshotsForReact?.filter((item: ({ code: string | null, name: string | null } | null)) =>
                                        item?.code === data.value)[0];
                                    this.props.dispatchers.setInReduxState({ selectedLeaveGroup: selectedGroup, entityHasBeenModified: true });
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        )
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - OPERATIONAL COMPARTMENT
    ////////////////////////////////////

    /**
    * This is always read only.
    * This compartment shows operational data captured from external APICS from Port Authority.
    */
    protected renderOperationalTaskCompartment() {
        return <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_" + LayoutTypeCode.OPERATIONAL} ref={this.refDetailsCompartment} hideButtonBar entityDescriptor={this.getOperationalTaskCompartmentDescriptor()}
            entity={this.getBaseEntity()} fieldsLayout={[["registration.detail.ship.name"], ["registration.detail.ship.code"], ["registration.detail.ship.loa"],
            ["registration.detail.ship.breadth"], ["registration.detail.call"], ["registration.detail.agent.name"],
            ["registration.detail.from.code", "registration.detail.from.name"], ["registration.detail.to.code", "registration.detail.to.name"],
            ["registration.detail.regime"], ["registration.detail.choicePilot", "registration.detail.annulation"],
            ["registration.detail.superPilot", "registration.detail.delay"], ["registration.detail.babySuperPilot", "registration.detail.seaCanal"]]} />
    }

    protected getOperationalTaskCompartmentDescriptor() {
        let descriptor = new EntityDescriptor({ name: REGISTRATION_EDITOR }, false)
            .addFieldDescriptor({ name: "registration.detail.ship.name", type: FieldType.string, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.ship.code", type: FieldType.string, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.ship.loa", type: FieldType.string, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.ship.breadth", type: FieldType.string, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.call", type: FieldType.string, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.agent.name", type: FieldType.string, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.from.code", type: FieldType.string, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.from.name", type: FieldType.string, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.to.code", type: FieldType.string, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.to.name", type: FieldType.string, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.regime", type: FieldType.string, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.choicePilot", type: FieldType.boolean, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.superPilot", type: FieldType.boolean, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.babySuperPilot", type: FieldType.boolean, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.annulation", type: FieldType.boolean, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.delay", type: FieldType.boolean, enabled: false })
            .addFieldDescriptor({ name: "registration.detail.seaCanal", type: FieldType.boolean, enabled: false })
        return descriptor;
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - ILLNESS COMPARTMENT
    ////////////////////////////////////

    protected getIllnessCompartmentDateFieldDescriptor(entityDescriptor: EntityDescriptor, name: string, disableDate: (current: Moment) => boolean) {
        const that = this;
        entityDescriptor.addFieldDescriptor({
            name: name,
            type: FieldType.date,
            enabled: this.isEditable(),
            additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, {
                format: ProteusConstants.DATE_TIME_FORMAT,
                allowClear: true,
                disabledDate: disableDate
            })
        }, new class extends FieldDescriptor {
            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                const newProps = { ...props, onDateChange: that.onEntityChange };
                return (
                    <Popup content={_msg("RegistrationEditor.registration.detail.fromDateToDateCertificate.tooltip.message")}
                        trigger={<div>
                            {!that.isModeAgenda() && <CustomDateFieldEditor {...newProps} />}
                            {that.isModeAgenda() && <CustomDateFieldWithDatePickerEditor {...newProps} />}
                            </div>}
                    />
                );
            }
        });
        return entityDescriptor;
    }

    protected getIllnessCompartmentDescriptor() {
        const that = this;
        let descriptor = new EntityDescriptor({ name: REGISTRATION_EDITOR }, false);
        descriptor = this.getIllnessCompartmentDateFieldDescriptor(descriptor, "registration.detail.fromDateCertificate", this.disableFromDateCertificate);
        descriptor = this.getIllnessCompartmentDateFieldDescriptor(descriptor, "registration.detail.toDateCertificate", this.disableToDateCertificate);
        descriptor.addFieldDescriptor({
            name: "registration.detail.relapseOf",
            type: FieldType.string,
        }, new class extends FieldDescriptor {
            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                const newProps = {
                    ...props,
                    onRelatedRegistrationChange: that.onRelatedRegistrationChange,
                    relatedRegistrations: that.props.relatedRegistrations,
                    getCustomLabelForRegistration: that.getCustomLabelForRegistration
                };
                return React.createElement(RelatedRegistrationsFieldEditor as any, newProps as FieldEditorProps);
            }
        })
        return descriptor;
    }

    protected getWorkAccidentCompartmentDescriptor() {
        var that = this;
        return this.getIllnessCompartmentDescriptor()
            .addFieldDescriptor({name: "registration.detail.accidentOnCommute", type: FieldType.boolean}, new CustomBooleanFieldDescriptor(that.onEntityChange));
    }

    /**
    * Get the label for related (relapse of) registrations. This is a two dates range (the start and end dates of the registration).
    */
    protected getCustomLabelForRegistration = (registration: Registration) => {
        return this.formatDate(registration.fromDate) + " - " + this.formatDate(registration.toDate);
    }

    protected onRelatedRegistrationChange = async (data: any, that: any) => {
        if (data.value === null || data.value === undefined || data.value === "") {
            that.props.formikProps.setFieldValue(that.props.fieldDescriptor.name, undefined);
            this.onEntityChange();
        } else {
            // load the full registration to check the detail if possible to link
            const registration = await this.props.dispatchers.getRegistration(data.value);
            if (this.isRegistrationEligibleForRelapse(registration)) {
                const relatedRegistration = this.props.relatedRegistrations.filter((item) => item.id === registration.id)[0];
                that.props.formikProps.setFieldValue(that.props.fieldDescriptor.name, relatedRegistration);
                this.onEntityChange();
            } else {
                // can't change to new selection => reset back to old selected value
                that.props.formikProps.setFieldValue(that.props.fieldDescriptor.name, (this.getRegistrationValuesFromForm().detail as IllnessDetail).relapseOf);
                this.props.dispatchers.setInReduxState({
                    showModal: {
                        showNoButton: false,
                        open: true,
                        message: _msg("RegistrationEditor.notEligibleForRelapse.label"),
                        operation: ModalOperations.CONFIRM_NOT_ELIGIBLE_FOR_RELAPSE
                    }
                });
            }
        }
    }

    /**
    * Decides if this registration can be a relapse of the selectedRegistration.
    * The selectedRegistration can be referenced through relpase relation only if it is not already linked with other registration, 
    * or in other words if it does not have already a relapse registered.
    * This condition ensures that the registrations will link in a chain and never two registrations will point towards the same registration 
    * through 'relapseOf'.
    * Example:
    * 1) incorrect chain of relapse registrations (where A is registered first then B then C) - A should refere to C as a relapse through B
    *   A
    *  / \
    * B   C 
    * 2) correct chain of relapse registrations 
    * A - B - C  
    */
    protected isRegistrationEligibleForRelapse(selectedRegistration: Registration): boolean {
        // Fact: a registration can only be the relapse of a registration in the past two weeks
        // To make sure the registrations are always linked in a chain (for registrations A - B - C - D,
        // D should never be allowed to link with B if C is already linked with B)  
        for (let i = 0; i < (selectedRegistration.detail as IllnessDetail).relapseRegistrations.length; i++) {
            // in other words, this registration can be a relapse of a past registration that is linked only with registrations before it
            if (new Date((selectedRegistration.detail as IllnessDetail).relapseRegistrations[i].fromDate).getTime() >= new Date(selectedRegistration.toDate).getTime()) {
                return false;
            }
        }
        return true;
    }

    protected disableFromDateCertificate = (current: Moment): boolean => {
        const toDateCertificate = this.getRegistrationValuesFromForm().detail.toDateCertificate;
        // Can not select days after toDateCertificate
        if (toDateCertificate) {
            return current && current > moment(toDateCertificate);
        }
        return false;
    }

    protected disableToDateCertificate = (current: Moment): boolean => {
        const fromDateCertificate = this.getRegistrationValuesFromForm().detail.fromDateCertificate;
        // Can not select days before fromDateCertificate
        if (fromDateCertificate) {
            return current && current < moment(fromDateCertificate);
        }
        return false;
    }

    protected shouldLoadRelatedRegistrations(): boolean {
        // relatedRegistrations should be loaded only if IllnessCompartment is present
        const layoutTypeCode = this.getLayoutType()?.toString();
        if (layoutTypeCode === LayoutTypeCode.ILLNESS || layoutTypeCode === LayoutTypeCode.WORK_ACCIDENT) {
            return true;
        }
        return false;
    }

    protected renderIllnessCompartment() {
        return <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_" + LayoutTypeCode.ILLNESS} ref={this.refDetailsCompartment} hideButtonBar entityDescriptor={this.getIllnessCompartmentDescriptor()} entity={this.getBaseEntity()} />
    }

    protected renderWorkAccidentCompartment() {
        return <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_" + LayoutTypeCode.WORK_ACCIDENT} ref={this.refDetailsCompartment} hideButtonBar entityDescriptor={this.getWorkAccidentCompartmentDescriptor()} entity={this.getBaseEntity()} />
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - EXCHANGE COMPARTMENT
    ////////////////////////////////////

    protected getEmployeeByCriteriaFieldDescriptor(descriptor: EntityDescriptor, name: string, isForExchange: boolean) {
        const that = this;
        descriptor.addFieldDescriptor({
            name: name,
            type: "EmployeeSnapshot",
            enabled: this.isEditable(),
        }, new class extends FieldDescriptor {
            getLabel() {
                if (isForExchange) {
                    return (that.getRegistrationToBeSaved().detail as ExchangeDetail).applicant ?
                        _msg("RegistrationEditor.registration.supporter.label") :
                        _msg("RegistrationEditor.registration.applicant.label");
                }
                return super.getLabel();
            }

            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                const newProps = {
                    ...props,
                    criteria: {
                        avoidLoadingEmployeeDetails: false,
                        groupCodes: that.props.exchangeGroupCodes,
                        excludeId: that.getRegistrationToBeSaved().resource,
                        groupContextCode: ProteusConstants.GROUP_CODE_EMPLOYEE_EXPLORER,
                        ignoreContract: false,
                        includeAllWithValidContract: true,
                        includesAllRegardlessOfThecontract: false
                    },
                    date: that.props.registration.fromDate,
                    onChange: that.onEntityChange,
                    queryLimit: -1,
                }
                return React.createElement(EmployeeByCriteriaAssociationFieldEditor as any, newProps as FieldEditorProps);
            }
        });
        return descriptor;
    }

    protected getExchangeCompartmentDescriptor() {
        const that = this;
        let descriptor = new EntityDescriptor({ name: REGISTRATION_EDITOR }, false);
        descriptor = this.getEmployeeByCriteriaFieldDescriptor(descriptor, "registration.detail.secondParty", true);
        descriptor.addFieldDescriptor({
            name: "registration.detail.secondDate",
            type: FieldType.date,
            enabled: this.isEditable(),
            additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, {
                allowClear: false,
                format: ProteusConstants.DATE_TIME_FORMAT
            })
        }, new class extends FieldDescriptor {
            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                const newProps = {
                    ...props,
                    onDateChange: that.onEntityChange,
                    startOfDay: true
                }
                const fieldEditor = that.isModeAgenda() ? CustomDateFieldWithDatePickerEditor : CustomDateFieldEditor;
                return React.createElement(fieldEditor as any, newProps as FieldEditorProps);
            }
        });
        return descriptor;
    }

    protected renderExchangeCompartment() {
        return (
            <>
                <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_" + LayoutTypeCode.EXCHANGE} ref={this.refDetailsCompartment} hideButtonBar entityDescriptor={this.getExchangeCompartmentDescriptor()} entity={this.getBaseEntity()} />
                {this.props.registration.id !== null && this.props.registration.id !== undefined && this.renderExchangeCompartmentTable()}
            </>
        );
    }

    protected formatDateTableCell(item: Exchange) {
        return moment(new Date(item.fromDate)).format(ProteusConstants.DATE_TIME_FORMAT).toString();
    }

    protected getEmployeeNameTableCell(item: Person) {
        return item.name + " " + item.firstName;
    }

    protected renderExchangeCompartmentTable() {
        const detail = this.props.registration.detail as ExchangeDetail;
        return (
            <div>
                <Table celled compact unstackable className="RegistrationEditor_exchangeTable">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width="5" />
                            <Table.HeaderCell verticalAlign="middle" textAlign="center">{_msg("RegistrationEditor.substitute.label")}</Table.HeaderCell>
                            <Table.HeaderCell verticalAlign="middle" textAlign="center">{_msg("RegistrationEditor.substituted.label")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell verticalAlign="middle" textAlign="center">
                                <div>
                                    <b>{this.formatDateTableCell(detail.exchange)}</b>
                                </div>
                                <div>
                                    <b>{detail.exchange.workPeriodType.name}</b>
                                </div>
                            </Table.Cell>
                            <Table.Cell verticalAlign="middle" textAlign="center">
                                {this.getEmployeeNameTableCell(detail.exchange.substitute)}
                            </Table.Cell>
                            <Table.Cell verticalAlign="middle" textAlign="center">
                                {this.getEmployeeNameTableCell(detail.exchange.substituted)}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell verticalAlign="middle" textAlign="center">
                                <div>
                                    <b>{this.formatDateTableCell(detail.compensation)}</b>
                                </div>
                                <div>
                                    <b>{detail.compensation.workPeriodType.name}</b>
                                </div>
                            </Table.Cell>
                            <Table.Cell verticalAlign="middle" textAlign="center">
                                {this.getEmployeeNameTableCell(detail.compensation.substitute)}
                            </Table.Cell>
                            <Table.Cell verticalAlign="middle" textAlign="center">
                                {this.getEmployeeNameTableCell(detail.compensation.substituted)}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        );
    }

    protected shouldLoadExchangeGroups() {
        const layoutTypeCode = this.getLayoutType()?.toString();
        if (layoutTypeCode === LayoutTypeCode.EXCHANGE && this.isEditable()) {
            return true;
        }
        return false;
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - SEQUENCE EXCHANGE COMPARTMENT
    ////////////////////////////////////

    protected renderSequenceExchangeCompartment() {
        return <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_" + LayoutTypeCode.SEQUENCE_EXCHANGE} ref={this.refDetailsCompartment} hideButtonBar entityDescriptor={this.getSequenceExchangeCompartmentDescriptor()} entity={this.getBaseEntity()} />
    }

    protected getSequenceExchangeCompartmentDescriptor() {
        let descriptor = new EntityDescriptor({ name: REGISTRATION_EDITOR }, false);
        return this.getEmployeeByCriteriaFieldDescriptor(descriptor, "registration.detail.secondParty", true);
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - DELEGATION COMPARTMENT
    ////////////////////////////////////

    protected renderDelegationCompartment() {
        return <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_" + LayoutTypeCode.DELEGATION} ref={this.refDetailsCompartment} hideButtonBar entityDescriptor={this.getDelegationCompartmentDescriptor()} entity={this.getBaseEntity()} />
    }

    protected getDelegationCompartmentDescriptor() {
        const that = this;
        let descriptor = new EntityDescriptor({ name: REGISTRATION_EDITOR }, false);
        descriptor = this.getEmployeeByCriteriaFieldDescriptor(descriptor, "registration.detail.delegate", false);
        descriptor.addFieldDescriptor({
            name: "registration.detail.workflows",
            type: FieldType.string,
            enabled: this.isEditable(),
        }, new class extends FieldDescriptor {
            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                const newProps = {
                    ...props,
                    onChange: that.onEntityChange,
                    workflowProcesses: that.props.workflowProcesses
                }
                return React.createElement(WorkflowProcessesFieldEditor as any, newProps as FieldEditorProps);
            }
        });
        return descriptor;
    }

    protected shouldLoadDelegationData() {
        const layoutTypeCode = this.getLayoutType()?.toString();
        if (layoutTypeCode === LayoutTypeCode.DELEGATION) {
            return true;
        }
        return false;
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - MEDICAL CHECK-UP COMPARTMENT
    ////////////////////////////////////

    protected renderMedicalCheckupCompartment() {
        return <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_" + LayoutTypeCode.MEDICAL_CHECKUP} ref={this.refDetailsCompartment} hideButtonBar entityDescriptor={this.getMedicalCheckupCompartmentDescriptor()} entity={this.getBaseEntity()} />
    }

    protected getMedicalCheckupCompartmentDescriptor() {
        const that = this;
        let descriptor = new EntityDescriptor({ name: REGISTRATION_EDITOR }, false);
        descriptor.addFieldDescriptor({
            name: "registration.detail.typeCode",
            type: "MedicalCheckupType",
            enabled: this.isEditable(),
        }, new class extends FieldDescriptor {
            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                const newProps = {
                    ...props,
                    onChange: that.onEntityChange,
                    medicalCheckupTypes: that.props.medicalCheckupTypes
                }
                return React.createElement(MedicalCheckupFieldEditor as any, newProps as FieldEditorProps);
            }
        });
        return descriptor;
    }

    protected shouldLoadMedicalCheckupData() {
        const layoutTypeCode = this.getLayoutType()?.toString();
        if (layoutTypeCode === LayoutTypeCode.MEDICAL_CHECKUP) {
            return true;
        }
        return false;
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - EDUCATION COMPARTMENT
    ////////////////////////////////////

    protected renderEducationCompartment() {
        return <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_" + LayoutTypeCode.EDUCATION} ref={this.refDetailsCompartment} hideButtonBar entityDescriptor={this.getEducationCompartmentDescriptor()} entity={this.getBaseEntity()} />
    }

    protected addSocialHoursBalanceFieldDescriptor(descriptor: EntityDescriptor) {
        const that = this;
        descriptor.addFieldDescriptor({
            name: "registration.detail.socialHoursBalance",
            type: FieldType.double,
            enabled: this.isEditable(),
        }, new class extends FieldDescriptor {

            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                const newProps = {
                    ...props,
                    onChangeValue: that.onEntityChange
                }
                return React.createElement(CustomDoubleFieldEditor as any, newProps as FieldEditorProps)
            }
        })
    }

    protected getEducationCompartmentDescriptor() {
        const that = this;
        let descriptor = new EntityDescriptor({ name: REGISTRATION_EDITOR }, false)
            .addFieldDescriptor({
                name: "registration.detail.componentVersion",
                type: "ComponentVersion",
                enabled: this.isEditable() && this.allowFieldModificationForEducation(),
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        onChange: that.onComponentVersionChange,
                        isClearable: false,
                        sorts: [{ field: "name", direction: "ASC" }, { field: "majorVersion", direction: "DESC" }, { field: "minorVersion", direction: "DESC" }]
                    }
                    return React.createElement(AssociationFieldEditor as any, newProps as FieldEditorProps);
                }
            })
            .addFieldDescriptor({
                name: "registration.detail.realization",
                type: FieldType.string,
                enabled: this.isEditable(),
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        realizations: that.props.realizationsOptions,
                        onChange: that.onRealizationChange
                    }
                    return React.createElement(RealizationFieldEditor as any, newProps as FieldEditorProps);
                }
            })
            .addFieldDescriptor({
                name: "registration.detail.realization.date",
                type: FieldType.date,
                enabled: false,
                additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, {
                    format: ProteusConstants.DATE_TIME_FORMAT
                })
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = { ...props, onDateChange: that.onEntityChange };
                    const fieldEditor = that.isModeAgenda() ? CustomDateFieldWithDatePickerEditor : CustomDateFieldEditor;
                    return React.createElement(fieldEditor as any, newProps as FieldEditorProps);
                }
            })
            .addFieldDescriptor({
                name: "registration.detail.realization.dueDate",
                type: FieldType.date,
                enabled: !this.props.disableRealizationDueDate && this.isEditable(),
                additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, {
                    disabledDate: this.disableDueDate,
                    format: ProteusConstants.DATE_TIME_FORMAT
                })
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = { ...props, onDateChange: that.onEntityChange };
                    const fieldEditor = that.isModeAgenda() ? CustomDateFieldWithDatePickerEditor : CustomDateFieldEditor;
                    return React.createElement(fieldEditor as any, newProps as FieldEditorProps);
                }
            })
            .addFieldDescriptor({
                name: "registration.detail.trainer",
                type: "EmployeeSnapshot",
                enabled: this.isEditable(),
            }, new class extends FieldDescriptor {

                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        criteria: {
                            avoidLoadingEmployeeDetails: false,
                            groupCodes: [],
                            groupContextCode: ProteusConstants.TRAINERS_EXPLORER_CONTEXT,
                            ignoreContract: false,
                            includeAllWithValidContract: false,
                            includesAllRegardlessOfThecontract: false,
                            companyTypeCodes: [EDUCATION_COMPANY_TYPE],
                            companyCode: {
                                enforceNull: false,
                                value: (that.getRegistrationToBeSaved().detail as TrainingDetail).company?.code
                            }
                        },
                        date: new Date(),
                        hidePersonId: true,
                        onChange: that.onTrainerChange,
                        queryLimit: -1,
                    }
                    return React.createElement(PersonByCriteriaAssociationFieldEditor as any, newProps as FieldEditorProps);
                }
            })
            .addFieldDescriptor({
                name: "registration.detail.company",
                type: "Company",
                enabled: this.isEditable(),
            }, new class extends FieldDescriptor {

                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        criteria: {
                            companyTypeCodes: [EDUCATION_COMPANY_TYPE],
                            includeContactPersons: false
                        },
                        onChange: that.onCompanyChange,
                        queryLimit: -1,
                    }
                    return React.createElement(CompanyByCriteriaAssociationFieldEditor as any, newProps as FieldEditorProps);
                }
            });
        this.addSocialHoursBalanceFieldDescriptor(descriptor);
        return descriptor;
    }

    protected onTrainerChange = (trainer: getPersonSnapshotsByCriteria_employeeService_personSnapshotsByCriteria) => {
        this.onEntityChange();
        if (trainer) {
            this.refDetailsCompartment.current?.formikContext.setFieldValue("registration.detail.company", trainer.company);
        }
    }

    protected onCompanyChange = () => {
        this.onEntityChange();
        this.refDetailsCompartment.current?.formikContext.setFieldValue("registration.detail.trainer", null);
    }

    protected disableDueDate = (current: Moment): boolean => {
        const date = (this.getRegistrationToBeSaved().detail as TrainingDetail).realization?.date;
        // Can not select days before date
        if (date) {
            return current && current < moment(date);
        }
        return false;
    }

    protected onRealizationChange = (realization: getRealizationsByCriteria_educationServiceFacadeBean_realizations) => {
        this.enableOrDisableDueDateField(realization);
        this.onEntityChange();
    }

    protected enableOrDisableDueDateField(realization: getRealizationsByCriteria_educationServiceFacadeBean_realizations) {
        if (realization.id !== undefined && this.props.disableRealizationDueDate === false) {
            this.props.dispatchers.setInReduxState({ disableRealizationDueDate: true });
        } else if (realization.id === undefined && this.props.disableRealizationDueDate === true) {
            this.props.dispatchers.setInReduxState({ disableRealizationDueDate: false });
        }
    }

    protected onComponentVersionChange = async (value: getRealizationsByCriteria_educationServiceFacadeBean_realizations_componentVersion & { component?: { type: { code: string } } }) => {
        await this.getDefaultRealization(value);
        const detail = this.getRegistrationToBeSaved().detail as TrainingDetail;
        // For components of type "CERT", the logged in user is automatically selected as the trainer of the registration,
        // if not other trainer or company is selected. 
        if (value.component?.type?.code === ProteusConstants.CERTIFICATE && !detail.trainer && !detail.company) {
            const loggedInUser = ProteusUtils.getCurrentUser();
            this.refDetailsCompartment.current?.formikContext.setFieldValue("registration.detail.trainer", loggedInUser);
            this.onTrainerChange(loggedInUser);
        }
        this.onEntityChange();
    }

    protected syncNewRealizationDateWithRegistrationDate(date: Moment | null) {
        const registration = this.getRegistrationValuesFromForm();
        const detail = registration.detail as TrainingDetail;
        if (!detail || detail.objectType !== RegistrationDetailObjectType.TRAINING_DETAIL || !detail.realization) {
            return;
        }

        if (!detail.realization.id) {
            this.refDetailsCompartment.current?.formikContext.setFieldValue("registration.detail.realization.date", date);
        }
        const realizations = this.props.realizationsOptions.filter((item: getRealizationsByCriteria_educationServiceFacadeBean_realizations) => item.id);
        const newRealization = { ...this.props.realizationsOptions.filter((item: getRealizationsByCriteria_educationServiceFacadeBean_realizations) => !item.id)[0] };
        newRealization.date = date;
        realizations.push(newRealization);
        this.props.dispatchers.setInReduxState({ realizationsOptions: realizations });
    }

    protected shouldLoadEducationData() {
        const layoutTypeCode = this.getLayoutType()?.toString();
        if (layoutTypeCode === LayoutTypeCode.EDUCATION) {
            return true;
        }
        return false;
    }

    protected shouldLoadEducationRelations() {
        const layoutTypeCode = this.getLayoutType()?.toString();
        if (layoutTypeCode === LayoutTypeCode.EDUCATION || layoutTypeCode === LayoutTypeCode.INSTRUCTOR) {
            return true;
        }
        return false;
    }

    protected getNewRealizationMilisecons() {
        return this.props.newRealizationDaysParam! * moment.duration(1, 'days').asMilliseconds();
    }

    protected getNewRealization(currentComponentVersion: getRealizationsByCriteria_educationServiceFacadeBean_realizations_componentVersion, registration: Registration) {
        return {
            date: registration.fromDate,
            person: registration.resource,
            componentVersion: currentComponentVersion,
            objectType: "Realization"
        };
    }

    protected setNewRealizationToDetail(currentComponentVersion: getRealizationsByCriteria_educationServiceFacadeBean_realizations_componentVersion) {
        const newRealization = this.getNewRealization(currentComponentVersion, this.getRegistrationToBeSaved());
        this.refDetailsCompartment.current?.formikContext.setFieldValue("registration.detail.realization", newRealization);
        this.onEntityChange();
    }

    protected async setRealizationsOptions(criteria: RealizationCriteriaInput, currentComponentVersion: getRealizationsByCriteria_educationServiceFacadeBean_realizations_componentVersion, registration: Registration) {
        const newRealization = this.getNewRealization(currentComponentVersion, registration);
        let realizationsOptions = await this.props.dispatchers.getRealization(criteria);
        realizationsOptions = realizationsOptions.concat([newRealization])
        const existingRealization = (registration.detail as TrainingDetail).realization;
        // check if the current value of realization is part of options. If not, add it to realizationsOptions
        if (existingRealization !== null && existingRealization !== undefined && existingRealization.id !== undefined) {
            const realizationInOptions: boolean = realizationsOptions.filter((item: getRealizationsByCriteria_educationServiceFacadeBean_realizations) =>
                item.id === existingRealization.id).length > 0;
            if (!realizationInOptions && existingRealization.person === registration.resource) {
                realizationsOptions = realizationsOptions.concat(existingRealization);
            }
        }
        this.props.dispatchers.setInReduxState({ realizationsOptions });
    }

    protected getRealizationCriteria(currentComponentVersionId: number, resourceId: number) {
        return {
            opened: true,
            personId: resourceId,
            componentVersionId: currentComponentVersionId,
            orderBy: "date",
            order: "DESC",
        };
    }

    protected async getDefaultRealization(currentComponentVersion: getRealizationsByCriteria_educationServiceFacadeBean_realizations_componentVersion) {
        const registration = this.getRegistrationToBeSaved();
        let criteria: RealizationCriteriaInput = this.getRealizationCriteria(currentComponentVersion.id!, registration.resource);
        await this.setRealizationsOptions(criteria, currentComponentVersion, registration);
        criteria = { ...criteria, maxResults: 1 };
        const realizations = await this.props.dispatchers.getRealization(criteria);
        if (realizations.length === 1) {
            this.refDetailsCompartment.current?.formikContext.setFieldValue("registration.detail.realization", realizations[0]);
            // for a new education registration, in case a "last" realization is found that is more than parameter [NEW_REALIZATION_DAYS]
            // days in the past, the realization is still selected but there should be an ignorable warning:
            if (realizations[0].date !== null && realizations[0].date !== undefined &&
                this.props.newRealizationDaysParam !== null && !isNaN(this.props.newRealizationDaysParam)) {
                // if more that NEW_REALIZATION_DAYS in the past, show warning
                if (new Date().getTime() - this.getNewRealizationMilisecons() > new Date(realizations[0].date).getTime()) {
                    this.props.dispatchers.setInReduxState({
                        showModal: {
                            open: true,
                            showNoButton: true,
                            operation: ModalOperations.CONFIRM_NEW_REALIZATION,
                            message: _msg("RegistrationEditor.realizationDaysInThePast.message", this.props.newRealizationDaysParam)
                        }
                    })
                }
            }
        } else {
            this.setNewRealizationToDetail(currentComponentVersion);
        }
    }

    async loadEducationData(registration: Registration) {
        if ((registration.detail as TrainingDetail).componentVersion?.id !== undefined) {
            const componentVersion = (registration.detail as TrainingDetail).componentVersion;
            const realizationCriteria = this.getRealizationCriteria(componentVersion.id!, registration.resource!);
            await this.setRealizationsOptions(realizationCriteria, componentVersion, registration);
            this.enableOrDisableDueDateField((registration.detail as TrainingDetail).realization);
        }
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - INSTRUCTOR COMPARTMENT
    ////////////////////////////////////

    protected renderInstructorCompartment() {
        return <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_" + LayoutTypeCode.INSTRUCTOR} ref={this.refDetailsCompartment} hideButtonBar entityDescriptor={this.getInstructorCompartmentDescriptor()} entity={this.getBaseEntity()} />
    }

    protected getInstructorCompartmentDescriptor() {
        const that = this;
        let descriptor = new EntityDescriptor({ name: REGISTRATION_EDITOR }, false)
            .addFieldDescriptor({
                name: "registration.detail.componentVersion",
                type: "ComponentVersion",
                enabled: this.isEditable() && this.allowFieldModificationForEducation(),
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        onChange: that.onEntityChange,
                        isClearable: false,
                        sorts: [{ field: "name", direction: "ASC" }, { field: "majorVersion", direction: "DESC" }, { field: "minorVersion", direction: "DESC" }]
                    }
                    return React.createElement(AssociationFieldEditor as any, newProps as FieldEditorProps);
                }
            })
        this.addSocialHoursBalanceFieldDescriptor(descriptor);
        return descriptor;
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - SHIP WORK PERIOD COMPARTMENT
    ////////////////////////////////////
    protected renderShipWorkPeriodCompartment() {
        return <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_" + LayoutTypeCode.SHIP_WORK_PERIOD} ref={this.refDetailsCompartment} hideButtonBar entityDescriptor={this.getShipWorkPeriodCompartmentDescriptor()} entity={this.getBaseEntity()} />
    }

    protected getShipWorkPeriodCompartmentDescriptor() {
        const that = this;
        let descriptor = new EntityDescriptor({ name: REGISTRATION_EDITOR }, false)
            .addFieldDescriptor({
                name: "registration.detail.ship",
                type: "ShipCt",
                enabled: this.isEditable(),
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        onChange: that.onEntityChange,
                        isClearable: true
                    }
                    return React.createElement(AssociationFieldEditor as any, newProps as FieldEditorProps);
                }
            })
            .addFieldDescriptor({
                name: "registration.detail.spare",
                type: "Spare",
                enabled: this.isEditable(),
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        onChange: that.onEntityChange,
                        isClearable: true
                    }
                    return React.createElement(AssociationFieldEditor as any, newProps as FieldEditorProps);
                }
            })
            .addFieldDescriptor({
                name: "registration.detail.categoryCode",
                type: FieldType.string,
            })
        return descriptor;
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - FLEXIBLE AVAILABILITY COMPARTMENT
    ////////////////////////////////////
    protected renderFlexibleAvailabilityCompartment() {
        return <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_" + LayoutTypeCode.FLEXIBLE_AVAILABILITY} ref={this.refDetailsCompartment} hideButtonBar entityDescriptor={this.getFlexibleAvailabilityCompartmentDescriptor()} entity={this.getBaseEntity()} />
    }

    protected onFlexibleRoundingChange = async (rounding: string) => {
        const currentFromDate = moment(this.refBasicEditor.current?.formikContext?.values?.registration.fromDate);
        this.refBasicEditor.current?.formikContext.setFieldValue("contextFlexibleRounding", rounding);
        let newFromDate = currentFromDate.clone();
        let newToDate = currentFromDate.clone();
        if (rounding === FLEXIBLE_ROUNDING_TYPES.EARLY) {
            newFromDate.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
            newToDate.set({ hours: 11, minutes: 59, seconds: 0, milliseconds: 0 });
        } else if (rounding === FLEXIBLE_ROUNDING_TYPES.LATE) {
            newFromDate.set({ hours: 12, minutes: 0, seconds: 0, milliseconds: 0 });
            newToDate.set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 });
        } if (rounding === FLEXIBLE_ROUNDING_TYPES.ALL_DAY) {
            newFromDate.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
            newToDate.set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 });
        }
        this.refBasicEditor.current?.formikContext.setFieldValue("registration.fromDate", newFromDate);
        this.refBasicEditor.current?.formikContext.setFieldValue("registration.toDate", newToDate);
        this.onFromDateChange(newFromDate, true);
        this.onToDateChange(newToDate, true);
    };

    protected getFlexibleAvailabilityCompartmentDescriptor() {
        const that = this;
        let descriptor = new EntityDescriptor({ name: REGISTRATION_EDITOR }, false)
            .addFieldDescriptor({
                name: "contextFlexibleRounding",
                enabled: this.isEditable()
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        onChange: that.onFlexibleRoundingChange,
                        workperiodTypes: FLEXIBLE_ROUNDING_VALUES
                    }
                    return React.createElement(FlexibleAvailabilityFieldEditor as any, newProps as FieldEditorProps);
                }
            });
        return descriptor;
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - OVERTIME COMPARTMENT
    ////////////////////////////////////
    protected renderOvertimeCompartment() {
        return <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_" + LayoutTypeCode.OVERTIME} ref={this.refDetailsCompartment} hideButtonBar entityDescriptor={this.getOvertimeCompartmentDescriptor()} entity={this.getBaseEntity()} />
    }

    protected getOvertimeCompartmentDescriptor() {
        const that = this;
        let descriptor = new EntityDescriptor({ name: REGISTRATION_EDITOR }, false)
            .addFieldDescriptor({
                name: "registration.detail.type",
                type: "OvertimeType",
                enabled: this.isEditable(),
            }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const newProps = {
                        ...props,
                        onChange: that.onEntityChange,
                        isClearable: true
                    }
                    return React.createElement(AssociationFieldEditor as any, newProps as FieldEditorProps);
                }
            })
            .addFieldDescriptor({
                name: "registration.detail.overtimeReason",
                type: FieldType.text,
                enabled: this.isEditable()
            });
        return descriptor;
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION TAB - GENERAL
    ////////////////////////////////////

    protected async getDefaultRegistrationStatus(registration: Registration) {
        if (this.props.defaultRegistrationStatus) {
            return this.props.defaultRegistrationStatus;
        }
        const defaultStatusCode = String(MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!,
            registration?.type?.code!, undefined, ProteusConstants.DEFAULT_STATUS_CODE, this.props.metadataContext));
        return (await this.props.dispatchers.getRegistrationStatusByCode(defaultStatusCode));
    }

    protected isConstrained(registration: Registration) {
        return Boolean(MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!,
            registration?.type?.code!, registration?.status?.code, ProteusConstants.CONSTRAINED, ProteusConstants.SYSTEM));
    }

    protected updateRegistrationResourceBeforeSave(registration: Registration & { resource: { id: number } }) {
        return { ...registration, resource: registration?.resource?.id };
    }

    protected getLayoutType() {
        if (this.props.registration?.type?.code === null || this.props.registration?.type?.code === undefined) {
            return null;
        }
        return MetadataProviderHelper.getPropertyValue(ProteusUtils.getMetadataProvider()!,
            this.props.registration.type.code!, undefined, ProteusConstants.LAYOUT_TYPE_CODE, this.props.metadataContext);
    }

    ////////////////////////////////////
    // METHODS USED BY ATTACHMENTS TAB
    ////////////////////////////////////

    protected renderAttachmentsTab() {
        return (
            <div view-mode={this.props.viewMode + ""} className="RegistrationEditor_attachmentsTab">
                <FileUploadButton {...this.props.fileUploadButton} dispatchers={this.props.dispatchers.fileUploadButton} fileList={this.getAttachmentsFileList()}
                    showUploadList={{ showRemoveIcon: this.allowEdit() }} onUploadFiles={this.onUploadFiles} multiple={false} onPreview={this.onDownloadFile}
                    onRemove={this.onRemoveFile} disableUploadButton={!this.allowEdit()} useDragAndDrop={true}
                />
            </div>
        );
    }

    protected onRemoveFile = (file: UploadFile) => {
        const remainingFiles = this.props.registrationAttachments?.filter((item: getRegistration_planningServiceFacadeBean_registration_attachments | null) =>
            item?.id !== Number(file.uid));
        this.props.dispatchers.setInReduxState({ registrationAttachments: remainingFiles });
        this.onEntityChange();
    }

    protected onDownloadFile = async (file: UploadFile) => {
        const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
        const url = Utils.adjustUrlToServerContext(additionalUrlParam + `attachment/download?type=${REGISTRATION}&id=${Number(file.uid)}`);
        const response = await fetch(url!, { method: 'POST' });
        let blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(new Blob([blob]));
        let link = document.createElement('a');
        link.href = fileUrl;
        link.download = file.name;
        link.click();
    }

    protected onUploadFiles = async (files: UploadFile[]) => {
        const uploadedFile = files[files.length - 1].originFileObj!;
        const fd = new FormData();
        fd.append('entitiesFile', uploadedFile);
        const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
        const url = Utils.adjustUrlToServerContext(additionalUrlParam + `attachment/upload?type=${REGISTRATION}`);
        const response = await fetch(url as string, { method: 'POST', body: fd });
        if (response.status === 200) {
            let registrationAttachments = this.props.registrationAttachments ? this.props.registrationAttachments : [];
            let newAttachment: getRegistration_planningServiceFacadeBean_registration_attachments & { classType: string } = {
                name: (uploadedFile as File).name,
                fileName: (uploadedFile as File).name,
                id: Number(await response.text()),
                objectVersion: 0,
                classType: "com.brabo.planning.core.registration.domain.RegistrationAttachment",
                creationDate: null,
                creationUser: null,
                modificationDate: null,
                modificationUser: null,
                content: null
            };
            registrationAttachments = registrationAttachments.concat(newAttachment);
            this.props.dispatchers.setInReduxState({ registrationAttachments });
            this.onEntityChange();
            return _msg("FileUploadButton.uploadSuccessfully");
        }
        return _msg("FileUploadButton.uploadError");
    }

    protected getAttachmentsFileList() {
        let fileList: { name: string, uid: string, size: number, type: string }[] = [];
        if (this.props.registrationAttachments) {
            fileList = this.props.registrationAttachments.map((item: getRegistration_planningServiceFacadeBean_registration_attachments | null) => ({
                name: item?.name || "",
                uid: String(item?.id),
                size: ATTACHMENT_SIZE,
                type: ATTACHMENT_TYPE
            }));
        }
        return fileList;
    }

    ////////////////////////////////////
    // METHODS USED BY REMAKRS TAB
    ////////////////////////////////////

    protected onRemarkDelete = (remarkIndex: number) => {
        if (!this.props.registrationRemarks) {
            return;
        }
        let remarks = [...this.props.registrationRemarks];
        remarks.splice(remarkIndex, 1);
        this.props.dispatchers.setInReduxState({ registrationRemarks: remarks });
        this.onEntityChange();
    }

    protected renderRemarksCards() {
        if (!this.props.registrationRemarks) {
            return <></>;
        }

        let cards = [];
        for (let i = 0; i < this.props.registrationRemarks.length; i++) {
            let remark = this.props.registrationRemarks[i];
            cards.push(
                <Card className="RegistrationEditor_remarksCard">
                    <Card.Content>
                        <Card.Header>
                            <div>{remark?.creationUser}</div>
                            <div>{moment(remark?.creationDate).format(ProteusConstants.DATE_TIME_FORMAT).toString()}</div>
                        </Card.Header>
                        {remark?.modificationUser &&
                            <Card.Meta>
                                {_msg("RegistrationEditor.remarksModifiedBy.label",
                                    remark?.modificationUser ? remark.modificationUser : "-",
                                    remark?.modificationDate ? moment(remark?.modificationDate).format(ProteusConstants.DATE_TIME_FORMAT).toString() : "-")}
                            </Card.Meta>
                        }
                        <Card.Description>
                            {remark?.value}
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Button content={_msg("general.edit")} value={i} primary key={1} onClick={(e: any) => {
                            const index = e.currentTarget.value;
                            this.props.dispatchers.setInReduxState({ editRemarkModal: { open: true, index: index, remark: this.props.registrationRemarks?.[index]?.value || "" } });
                        }} />
                        <Button content={_msg("general.remove")} value={i} negative key={2} onClick={(e: any) => this.onRemarkDelete(e.currentTarget.value)} disabled={this.props.viewMode} />
                    </Card.Content>
                </Card>
            );
        }
        return cards;
    }

    protected closeRemarkModal = () => {
        this.props.dispatchers.setInReduxState({ editRemarkModal: { open: false, index: -1, remark: "" } });
    }

    protected sortRemarks = (remarks: (getRegistration_planningServiceFacadeBean_registration_remarks | null)[]) => {
        return [...remarks].sort((a: getRegistration_planningServiceFacadeBean_registration_remarks | null, b: getRegistration_planningServiceFacadeBean_registration_remarks | null) => {
            const date1 = a?.modificationDate ? a.modificationDate : a?.creationDate;
            const date2 = b?.modificationDate ? b.modificationDate : b?.creationDate;
            if (date1 && date2 && new Date(date1).getTime() > new Date(date2).getTime()) {
                return -1;
            }
            return 1;
        });
    }

    protected saveRemark = () => {
        const index = Number(this.props.editRemarkModal.index);
        if (!this.props.registrationRemarks) {
            return;
        }

        let remarks = [...this.props.registrationRemarks];
        let remark = undefined;
        if (index === -1) {
            remark = {
                id: null,
                objectVersion: null,
                modificationDate: null,
                modificationUser: null,
                value: this.props.editRemarkModal.remark,
                creationDate: new Date().getTime(),
                creationUser: ProteusUtils.getUserName()
            };
            remarks.push(remark);
        } else {
            remark = remarks.splice(index, 1)[0];
            if (remark) {
                remark = { ...remark, value: this.props.editRemarkModal.remark };
                remarks.splice(index, 0, remark);
            }
        }
        remarks = this.sortRemarks(remarks);
        this.props.dispatchers.setInReduxState({ registrationRemarks: remarks });
        this.onEntityChange();
        this.closeRemarkModal();
    }

    protected renderAddRemarkButton() {
        return <Button disabled={!this.allowEdit()} primary content={_msg("RegistrationEditor.addRemark.label")}
            onClick={() => this.props.dispatchers.setInReduxState({ editRemarkModal: { open: true, index: -1, remark: "" } })} />
    }

    protected renderRemarksTab() {
        return (<>
            <fieldset className="RegistrationEditor_remarksTab" disabled={!this.allowEdit()}>
                <Card.Group itemsPerRow={2}>
                    {this.renderRemarksCards()}
                </Card.Group>
            </fieldset>
            <ModalExt open={this.props.editRemarkModal.open} size="small"
                onClose={this.closeRemarkModal}>
                <Modal.Header>{_msg("RegistrationRemark.label")}</Modal.Header>
                <Modal.Content view-mode={this.props.viewMode + ""} className='flex-container-row'>
                    <Form className='flex-grow'>
                        <Form.TextArea className='flex-grow' rows={3} value={this.props.editRemarkModal.remark}
                            onChange={(e, data) => this.props.dispatchers.setInReduxState({ editRemarkModal: { ...this.props.editRemarkModal, remark: data.value as string } })} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button key="cancel" onClick={this.closeRemarkModal}>{_msg("general.cancel")}</Button>
                    <Button key="ok" primary onClick={this.saveRemark} disabled={this.props.viewMode}>{_msg("general.ok")}</Button>
                </Modal.Actions>
            </ModalExt>
        </>
        );
    }

    ////////////////////////////////////
    // METHODS USED BY CALCULATION TAB
    ////////////////////////////////////

    protected renderCalculationsTableCell(registrationCalculation: getLeaveDistributionCalculationRelation_planningServiceFacadeBean_leaveDistributionCalculationRelationsWithJsonRegistration | null) {
        return [
            <Table.Cell>{registrationCalculation?.calculation?.description}</Table.Cell>,
            <Table.Cell textAlign="center">{(registrationCalculation)?.year}</Table.Cell>,
            <Table.Cell textAlign="center">{this.roundCalculationValue((registrationCalculation)?.result)}</Table.Cell>
        ];
    }

    protected roundCalculationValue(value: number | undefined | null) {
        if (value === undefined || value === null) {
            return;
        }
        const roundedNumber = value.toFixed(2);
        return Number.isInteger(value) ? parseInt(roundedNumber) : parseFloat(roundedNumber);
    }

    protected renderCalculationsTableRow() {
        let rows = [];
        for (let i = 0; i < this.props.registrationCalculationRelations?.length; i++) {
            rows.push(
                <Table.Row verticalAlign="middle">
                    {this.renderCalculationsTableCell(this.props.registrationCalculationRelations[i] as getLeaveDistributionCalculationRelation_planningServiceFacadeBean_leaveDistributionCalculationRelationsWithJsonRegistration)}
                </Table.Row>
            );
        }
        return rows;
    }

    protected renderCalculationTab() {
        return (
            <div className="RegistrationEditor_calculationsTab">
                <Table celled compact unstackable striped>
                    <Table.Header>
                        <Table.Row textAlign="center" verticalAlign="middle">
                            <Table.HeaderCell width="8">{_msg("description.label")}</Table.HeaderCell>
                            <Table.HeaderCell width="4">{_msg("year.label")}</Table.HeaderCell>
                            <Table.HeaderCell width="4">{_msg("value.label")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderCalculationsTableRow()}
                    </Table.Body>
                </Table>
            </div>
        );
    }

    protected shouldLoadLeaveCalculationRelations(registration: Registration) {
        return registration.type?.code === ProteusConstants.LEAVE_TYPE_CODE;
    }

    protected async setCalculationsRelations() {
        let leaveDistributionCalculationRelations = [];
        const registrationCalculations = this.props.registration.calculationRelations ? this.props.registration.calculationRelations : [];
        if (this.shouldLoadLeaveCalculationRelations(this.props.registration)) {
            leaveDistributionCalculationRelations = await this.props.dispatchers.getLeaveDistributionCalculationRelations(this.props.registration, registrationCalculations);
        }
        const allRegistrationCalculations = registrationCalculations.concat(leaveDistributionCalculationRelations);
        this.props.dispatchers.setInReduxState({ registrationCalculationRelations: allRegistrationCalculations, shoudlLoadCalculationData: false });
    }

    ////////////////////////////////////
    // METHODS USED BY COST CENTERS TAB
    ////////////////////////////////////

    protected renderCostCenterTab() {
        return (
            <div view-mode={this.props.viewMode + ""} className="RegistrationEditor_calculationsTab">
                <Table celled compact unstackable striped>
                    <Table.Header>
                        <Table.Row textAlign="center" verticalAlign="middle">
                            <Table.HeaderCell width="8">{_msg("code.label")}</Table.HeaderCell>
                            <Table.HeaderCell width="8">{_msg("description.label")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderCostCentersTableRow()}
                    </Table.Body>
                </Table>
            </div>
        );
    }

    protected renderCostCentersTableRow() {
        let rows = [];
        let costCenters = this.props.registration.costCenters || [];
        for (let i = 0; i < costCenters.length; i++) {
            rows.push(
                <Table.Row verticalAlign="middle">
                    {this.renderCostCentersTableCell(costCenters[i])}
                </Table.Row>
            );
        }
        return rows;
    }

    protected renderCostCentersTableCell(costCenters: any) {
        return [
            <Table.Cell textAlign="center">{costCenters.code}</Table.Cell>,
            <Table.Cell textAlign="center">{costCenters.description}</Table.Cell>
        ];
    }

    ////////////////////////////////////
    // METHODS USED BY BALANCE TAB
    ////////////////////////////////////

    protected renderBalanceTab() {
        return (
            <BalanceCalculation
                {...this.props.balanceCalculation}
                dispatchers={this.props.dispatchers.balanceCalculation}
                ref={this.refBalanceCalculation}
                employee={this.props.employee?.id}
                balanceType={this.getBalanceType()}
            />
        );
    }

    protected getBalanceType() {
        return { code: this.props.registration.type!.code!, description: this.props.registration.type!.name! };
    }

    protected shouldLoadLeaveDistributionBalance() {
        return this.getBalanceType().code === ProteusConstants.LEAVE_TYPE_CODE;
    }

    ////////////////////////////////////
    // METHODS USED BY SYSTEM TAB (AUDIT)
    ////////////////////////////////////

    protected renderSystemTab() {
        return <div className="RegistrationEditor_systemTab flex">
            <EntityEditorFormSimple scriptableUiId={REGISTRATION_EDITOR + "_SystemTab"} hideButtonBar entityDescriptor={this.getSystemTabDescriptor()} entity={this.getBaseEntity()} />
        </div>
    }

    protected getSystemTabDescriptor() {
        let descriptor = new EntityDescriptor({ name: REGISTRATION_EDITOR }, false)
            .addFieldDescriptor({ name: "registration.creationDate", type: FieldType.string })
            .addFieldDescriptor({ name: "registration.creationUser", type: FieldType.string })
            .addFieldDescriptor({ name: "registration.modificationDate", type: FieldType.string })
            .addFieldDescriptor({ name: "registration.modificationUser", type: FieldType.string })
            .addFieldDescriptor({ name: "registration.id", type: FieldType.string })
            .addFieldDescriptor({ name: "registration.type.id", type: FieldType.string })
            .addFieldDescriptor({ name: "registration.type.code", type: FieldType.string })
            .addFieldDescriptor({ name: "registration.resource.id", type: FieldType.string })
        return descriptor;
    }

    ////////////////////////////////////
    // METHODS USED BY REGISTRATION EDITOR
    ////////////////////////////////////

    protected hasPermission(permission: string) {
        for (let i = 0; i < this.props.resourcePermissions.length; i++) {
            if (permission === this.props.resourcePermissions[i].code) {
                return true;
            }
        }
        return false;
    }

    protected isModeAgenda() {
        return this.props.mode === REGISTRATION_EDITOR_MODE.AGENDA;
    }

    protected getDrawerTitle() {
        return (
            <div className="RegistrationEditor_drawerTitle">
                <div> {this.props.employee ? this.props.employee?.name + " " + this.props.employee?.firstName : ""}</div>
                <div>{this.props.registration?.type?.name}</div>
            </div>
        );
    }

    protected computeEmployeeForFirstRender() {
        return {
            id: this.props.employee?.id,
            name: this.props.employee?.name,
            firstName: this.props.employee?.firstName,
            contractHistoryItem: {
                employeeNumber: this.props.employee?.detail?.contractHistory?.[0]?.employeeNumber
            }
        }
    }

    protected getBaseEntity() {
        const roundingTypeCode = this.getDefaultRoundingTypeForRegistration(this.props.registration);
        let filteredRoundingTypes = this.props.roundingTypes.filter(type => type.code === roundingTypeCode);
        let roundingType;
        if (filteredRoundingTypes && filteredRoundingTypes.length > 0) {
            roundingType = filteredRoundingTypes[0];
        }
        let fromDate = this.props.defaultRoundingDates ? this.props.defaultRoundingDates.fromDate : this.props.registration.fromDate;
        let toDate = this.props.defaultRoundingDates ? this.props.defaultRoundingDates.toDate : this.props.registration.toDate;
        if (this.isModeAgenda() && (this.props.registration?.detail as ShipRosterDetail)?.spare) {
            fromDate = moment(this.props.registration.fromDate).startOf('day');
            toDate = moment(this.props.registration.fromDate).endOf('day');
        }
        const registration = {
            ...this.props.registration,
            fromDate,
            toDate,
            creationDate: this.props.registration.creationDate ? moment(this.props.registration.creationDate).format(ProteusConstants.DATE_TIME_FORMAT) : null,
            modificationDate: this.props.registration.modificationDate ? moment(this.props.registration.modificationDate).format(ProteusConstants.DATE_TIME_FORMAT) : null,
            resource: this.computeEmployeeForFirstRender(),
            status: this.props.registration.status === null || this.props.registration.status === undefined ? this.props.defaultRegistrationStatus : this.props.registration.status,
            detail: this.getInitialRegistrationDetail()
        };
        return {
            registration,
            context: {
                split: this.getSplit(this.props.registration),
                removeWaiting: false
            },
            'contextRoundingType': roundingType,
            'contextHalfDay': this.props.halfDaysOptions?.[0],
            'contextFlexibleRounding': this.getFlexibleRounding(registration)
        };
    }

    protected getRegistrationValuesFromForm() {
        const registration = {
            ...this.refBasicEditor.current?.formikContext?.values?.registration,
            detail: this.refDetailsCompartment.current !== null ? {
                ...this.refDetailsCompartment.current?.formikContext?.values?.registration?.detail
            } : this.getBaseEntity().registration.detail
        };
        return registration;
    }

    protected getContextValuesFromForm() {
        return {
            ...this.refBasicEditor.current?.formikContext?.values?.context,
            halfDay: this.refBasicEditor.current?.formikContext?.values?.contextHalfDay,
            roundingType: this.refBasicEditor.current?.formikContext?.values?.contextRoundingType,
            flexibleRounding: this.refBasicEditor.current?.formikContext?.values?.contextFlexibleRounding,
        };
    }

    protected onEntityChange = () => {
        if (this.props.entityHasBeenModified === false) {
            this.props.dispatchers.setInReduxState({ entityHasBeenModified: true });
        }
    }

    protected allowEdit() {
        return (this.props.operatingMode === EDIT_MODE || this.props.operatingMode === EDIT_ONLY_ONCE_MODE) && !this.props.viewMode;
    }

    protected isValid(context: any) {
        const registration = this.getRegistrationToBeSaved();
        if (context.flexibleRounding === FLEXIBLE_ROUNDING_TYPES.EMPTY) {
            return false;
        }
        if (registration.fromDate !== null && registration.fromDate !== undefined &&
            registration.type !== null && registration.type !== undefined &&
            registration.status !== null && registration.status !== undefined &&
            registration.resource !== null && registration.resource !== undefined &&
            (registration.toDate == null || new Date(registration.toDate).getTime() - new Date(registration.fromDate).getTime() >= 0)) {
            if (this.isConstrained(registration)) {
                if (registration.detail !== null && registration.detail !== undefined) {
                    return true;
                }
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    protected renderTabContent(content: any, additionalContentForSaveSegment?: any) {
        return (
            <>
                {this.renderSaveSegment(additionalContentForSaveSegment)}
                {content}
            </>
        );
    }

    protected renderTabPanes() {
        const layoutType = this.getLayoutType()?.toString();
        let tabPanes = [];

        // Registration tab
        tabPanes.push({
            menuItem: _msg("RegistrationEditor.registration.label"),
            pane: {
                key: BASIC_TAB,
                content: this.renderTabContent(this.renderRegistrationTab())
            }
        });

        if (this.isModeAgenda()) {
            tabPanes.push({
                menuItem: _msg("RegistrationEditor.remarks.label"),
                pane: {
                    key: REMARKS_TAB,
                    content: this.renderTabContent(this.renderRemarksTab(), this.renderAddRemarkButton())
                }
            });
            return tabPanes;
        }

        // every employee can see only the `Registration/Registratie` tab in registration editor,
        // only employees with PLANNING_REGISTRATION_EDITOR_VIEW_ALL_TABS permission can see all the tabs in the editor
        if (!AppMetaTempGlobals.appMetaInstance.hasPermission(ProteusConstants.PLANNING_REGISTRATION_EDITOR_VIEW_ALL_TABS)) {
            return tabPanes;
        }

        // Attachments tab
        tabPanes.push({
            menuItem: _msg("RegistrationEditor.attachments.label"),
            pane: {
                key: ATTACHMENTS_TAB,
                content: this.renderTabContent(this.renderAttachmentsTab())
            }
        });

        // Remarks tab
        tabPanes.push({
            menuItem: _msg("RegistrationEditor.remarks.label"),
            pane: {
                key: REMARKS_TAB,
                content: this.renderTabContent(this.renderRemarksTab(), this.renderAddRemarkButton())
            }
        });

        // Calculation tab
        tabPanes.push({
            menuItem: _msg("RegistrationEditor.calculations.label"),
            pane: {
                key: CALCULATIONS_TAB,
                content: this.renderTabContent(this.renderCalculationTab())
            }
        });

        // Balance tab
        if (this.props.useBalance) {
            tabPanes.push({
                menuItem: _msg("RegistrationEditor.balance.label"),
                pane: {
                    key: BALANCES_TAB,
                    content: this.renderTabContent(this.renderBalanceTab(), this.refBalanceCalculation.current?.renderBalanceDateInput())
                }
            });
        }

        // CostCenter tab
        if (layoutType === LayoutTypeCode.OPERATIONAL) {
            tabPanes.push({
                menuItem: _msg("RegistrationEditor.costCenters.label"),
                pane: {
                    key: COST_CENTERS_TAB,
                    content: this.renderTabContent(this.renderCostCenterTab())
                }
            });
        }

        // Relations tab
        if (layoutType === LayoutTypeCode.INSTRUCTOR) {
            tabPanes.push({
                menuItem: _msg("RegistrationEditor.relations.label"),
                pane: {
                    key: RELATIONS_TAB,
                    content: this.renderTabContent(this.renderRelationsTab())
                }
            });
        }

        // System tab
        tabPanes.push({
            menuItem: _msg("RegistrationEditor.system.label"),
            pane: {
                key: SYSTEM_TAB,
                content: this.renderTabContent(this.renderSystemTab())
            }
        });

        return tabPanes;
    }

    /**
    * Map the entity to the dropdown options format (key, text, value)
    */
    protected getDropdownOptionsFromEntity(entity: ({ code: string | null, description?: string | null, name: string | null } | null)[] | null | undefined) {
        if (entity === undefined || entity === null) {
            return [];
        }
        return entity.map((item: ({ code: string | null, description?: string | null, name: string | null } | null) | null | undefined) => ({
            key: item?.code as string,
            text: item?.description ? item?.description : item?.name as string,
            value: item?.code as string
        }));
    }

    protected renderRelationsTab() {
        return (
            <div className="RegistrationEditor_relationsTab">
                <Table celled compact unstackable striped>
                    <Table.Header>
                        <Table.Row textAlign="center" verticalAlign="middle">
                            <Table.HeaderCell width="8">{_msg("RegistrationEditor.relationsTab.participant.label")}</Table.HeaderCell>
                            <Table.HeaderCell width="8">{_msg("RegistrationEditor.relationsTab.registrationId.label")}</Table.HeaderCell>
                            {this.props.goToRegistration && <Table.HeaderCell></Table.HeaderCell>}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderRelationsTableRow()}
                    </Table.Body>
                </Table>
            </div>
        );
    }

    protected renderRelationsTableRow() {
        let rows = [];
        for (let i = 0; i < this.props.educationRelations.length; i++) {
            rows.push(
                <Table.Row verticalAlign="middle">
                    {this.renderRelationsTableCell(this.props.educationRelations[i] as getTrainingRelations_registrationServiceFacadeBean_trainingRelations)}
                </Table.Row>
            );
        }
        return rows;
    }

    protected renderRelationsTableCell(relation: getTrainingRelations_registrationServiceFacadeBean_trainingRelations) {
        let tableCells = [
            <Table.Cell textAlign="center">{relation.participantName}</Table.Cell>,
            <Table.Cell textAlign="center">{relation.registration?.id}</Table.Cell>];
        if (this.props.goToRegistration) {
            tableCells.push(<Table.Cell textAlign="center">{!!relation.registration && !!relation.registration?.id && <Button primary onClick={() => this.props.goToRegistration && this.props.goToRegistration(relation.registration?.id as number)}><Icon name="search" /></Button>}</Table.Cell>);
        }
        return tableCells;
    }

    protected getRegistrationToBeSaved() {
        let registration = this.updateRegistrationResourceBeforeSave(this.getRegistrationValuesFromForm());
        if (this.shouldLoadSelectableLeaveGroupsData(registration)) {
            // because the constraint group code is outside of the formik, it must be updated manually to registration detail
            registration = { ...registration, detail: { ...registration.detail!, groupCode: this.props.selectedLeaveGroup?.code } }
        }
        let detail = undefined;
        if (registration.detail) {
            const objectType = this.getObjectTypeForRegistrationDetail() === undefined ? undefined : this.getObjectTypeForRegistrationDetail();
            if (objectType) {
                detail = { ...registration.detail, objectType: this.getObjectTypeForRegistrationDetail() } as any;
            }
        }
        registration = {
            ...registration,
            attachments: this.props.registrationAttachments,
            remarks: this.props.registrationRemarks,
            creationDate: new Date(registration.creationDate).getTime(),
            modificationDate: new Date(registration.modificationDate).getTime(),
            detail
        };
        return registration;
    }

    protected showModalInfoMessage() {
        return (
            <ModalExt onClose={() => this.props.dispatchers.setInReduxState({ showModal: undefined })}
                open={this.props.showModal?.open === true} size='small' severity={Severity.WARNING}>
                <Modal.Header>
                    {_msg("warning.label")}
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Interweave content={this.props.showModal?.message} />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    {this.props.showModal?.showNoButton === true &&
                        <Button key="cancel"
                            onClick={() => {
                                if (this.props.showModal?.operation === ModalOperations.CONFIRM_NEW_REALIZATION) {
                                    // newRealization must be set to detail
                                    const componentVersion = this.props.realizationsOptions.filter((item: getRealizationsByCriteria_educationServiceFacadeBean_realizations) =>
                                        item.id === null || item.id === undefined)[0].componentVersion;
                                    this.setNewRealizationToDetail(componentVersion!);
                                }
                                this.props.dispatchers.setInReduxState({ showModal: undefined });
                            }
                            }>
                            {_msg("general.cancel")}
                        </Button>
                    }
                    <Button primary key="ok"
                        onClick={async () => {
                            if (this.props.showModal?.operation === ModalOperations.CONFIRM_LEAVE_PAGE) {
                                this.props.onDrawerClose();
                            } else if (this.props.showModal?.operation === ModalOperations.CONFIRM_IGNORE_EXCEPTIONS) {
                                const registration = this.getRegistrationToBeSaved();
                                const context = this.getContextValuesFromForm();
                                await this.props.dispatchers.saveRegistration(registration, context, true, this.props.metadataContext, this.isModeAgenda(), this.refBasicEditor.current?.formikContext.values.contextHalfDay?.code);
                                await this.props.onDrawerClose(true);
                            } else if (this.props.showModal?.operation === ModalOperations.CONFIRM_SPLIT_REGISTRATION) {
                                this.onSplitConfirmed();
                            }
                            this.props.dispatchers.setInReduxState({ showModal: undefined });
                        }
                        }>
                        {_msg("general.ok")}
                    </Button>
                </Modal.Actions>
            </ModalExt>
        );
    }

    protected async onSaveButtonClick() {
        const registration = this.getRegistrationToBeSaved();
        const context = this.getContextValuesFromForm();
        if (this.isValid(context)) {
            await this.props.dispatchers.saveRegistration(registration, context, false, this.props.metadataContext, this.isModeAgenda(), this.refBasicEditor.current?.formikContext.values.contextHalfDay?.code);
            await this.props.onDrawerClose(true);
        } else {
            this.props.dispatchers.setInReduxState({
                showModal: {
                    open: true,
                    message: _msg("RegistrationEditor.invalidFieldsValues.message"),
                    showNoButton: false,
                    operation: ""
                }
            });
        }
    }

    protected formatDate(date: number) {
        return moment(new Date(date)).format(ProteusConstants.DATE_TIME_FORMAT);
    }

    protected onTabPaneChange = async (e: any, data: any) => {
        // this method is used to check if Balance or Calculation data should be loaded
        // because this data is not displayed on the first tab, it is not loaded on mount, 
        // but when the user clicks on the Balance/Calculation tab for the first time for that registration
        const activeTabKey = data.panes[data.activeIndex].pane.key;
        if (activeTabKey === BALANCES_TAB && this.props.shouldLoadBalanceData) {
            const categoryHistoryItem = this.props.employee.categoryHistoryItem;
            let balanceType = this.getBalanceType().code;
            if (categoryHistoryItem && (categoryHistoryItem.category.code === ProteusConstants.SAILORS_CATEGORY_CODE ||
                categoryHistoryItem.category.code === ProteusConstants.CAPTAINS_CATEGORY_CODE || categoryHistoryItem.category.code === ProteusConstants.DEKSMAN_CATEGORY_CODE ||
                categoryHistoryItem.category.code === ProteusConstants.ADMIN_CATEGORY_CODE || categoryHistoryItem.category.code === ProteusConstants.TECHNICAL_CATEGORY_CODE)) {
                balanceType += ProteusConstants.HOURS_BALANCE_SUFFIX;
            }
            await this.props.dispatchers.balanceCalculation.loadBalanceTabData(this.getRegistrationToBeSaved().resource, balanceType, this.shouldLoadLeaveDistributionBalance(), false);
        } else if (activeTabKey === CALCULATIONS_TAB && this.props.shoudlLoadCalculationData) {
            await this.setCalculationsRelations();
        }
    }

    protected getHeaderContent(): ReactNode {
        if (this.isModeAgenda()) {
            return <div>{this.props.registration?.type?.name}</div>;
        }
        return (
            <>
                <div>{this.props.employee ? this.props.employee?.name + " " + this.props.employee?.firstName : ""}</div>
                <div>{this.props.registration?.type?.name}</div>
            </>
        );
    }

    protected renderSaveSegment(additionalContentForSaveSegment?: any) {
        return this.isEditable() && <Segment>
            <Button primary disabled={!this.props.entityHasBeenModified && this.props.registration.id !== null && this.props.registration.id !== undefined}
                onClick={async () => await this.onSaveButtonClick()}>
                {_msg("general.save")}
            </Button>
            {additionalContentForSaveSegment}
        </Segment>
    }

    render() {
        return (
            <div>
                {this.showModalInfoMessage()}
                <Drawer destroyOnClose placement="right" visible={true} className={`RegistrationEditor_drawer ${this.isModeAgenda() ? "Agenda_editor" : ""}`}
                    closeIcon={<Button icon="close" className="less-padding" circular />} onClose={async () => {
                        if (this.props.entityHasBeenModified || !this.props.registration.id) {
                            this.props.dispatchers.setInReduxState({ showModal: { open: true, message: _msg("unsavedDataWarning.label"), operation: ModalOperations.CONFIRM_LEAVE_PAGE, showNoButton: true } });
                        } else {
                            this.props.onDrawerClose();
                        }
                    }}>
                    {this.isModeAgenda() ?
                        <div className="CrudHeader_color"><div className="CrudHeader flex-row-left"><h2 className="EntityCrudHeader_white RegistrationEditor_title">{this.getHeaderContent()}</h2></div></div> :
                        <>{ProteusUtils.renderDrawerHeader(this.getHeaderContent(), "RegistrationEditor_drawerTitle")}</>
                    }
                    <Tab panes={this.renderTabPanes()} menu={{ secondary: true, pointing: true }} renderActiveOnly={false}
                        onTabChange={this.onTabPaneChange} className="RegistrationEditor_tab" />
                </Drawer >
            </div>
        );
    }

    async componentDidMount() {
        let registration = { ...this.props.registration };
        if (await this.shouldLoadResourcePermissions()) {
            await this.props.dispatchers.getSecurityPermissions(this.props.registration?.resource);
        }
        if (this.shouldLoadHalfDays()) {
            await this.props.dispatchers.getHalfDays();
        }
        const defaultRoundingDates = await this.computeRegistrationContext(this.props.registration);
        this.refBasicEditor.current?.formikContext.setFieldValue('contextRoundingType', this.props.context.roundingType);
        this.refBasicEditor.current?.formikContext.setFieldValue('contextHalfDay', this.props.context.halfDay);
        this.refBasicEditor.current?.formikContext.setFieldValue('contextFlexibleRounding', this.props.context.flexibleRounding);
        if (defaultRoundingDates) {
            registration = { ...registration, fromDate: defaultRoundingDates.fromDate, toDate: defaultRoundingDates.toDate };
        }
        this.props.dispatchers.balanceCalculation.setInReduxState({ balanceToDate: moment(this.props.registration?.fromDate).endOf('year').toDate() });

        if (this.shouldLoadSelectableLeaveGroupsData(registration)) {
            await this.processSelectableLeaveGroupData(registration, !(registration.detail as ConstraintDetail)?.groupCode);
        }
        if (registration.status === null || registration.status === undefined) {
            await this.getDefaultRegistrationStatus(registration);
        }
        if (this.shouldLoadRelatedRegistrations()) {
            await this.props.dispatchers.getRelatedRegistrations(registration);
        }
        if (this.shouldLoadExchangeGroups()) {
            await this.props.dispatchers.getExchangeGroups(registration.resource);
        }
        if (this.shouldLoadDelegationData()) {
            await this.props.dispatchers.getWorkflowProcesses();
        }
        if (this.shouldLoadMedicalCheckupData()) {
            await this.props.dispatchers.getMedicalCheckupTypes();
        }
        if (this.shouldLoadEducationData()) {
            await this.props.dispatchers.getNewRealizationDaysParam();
            await this.loadEducationData(this.getRegistrationToBeSaved());
        }
        if (this.shouldLoadEducationRelations()) {
            await this.props.dispatchers.getRegistrationEducationRelations(this.props.registration.id);
        }
        if (registration.attachments && registration.attachments.length > 0) {
            this.props.dispatchers.setInReduxState({ registrationAttachments: registration.attachments });
        }
        if (registration.remarks && registration.remarks.length > 0) {
            this.props.dispatchers.setInReduxState({ registrationRemarks: this.sortRemarks(registration.remarks) });
        }
    }

    componentWillUnmount() {
        this.props.dispatchers.setInReduxState({
            entityHasBeenModified: false,
            validationExceptionsForWaitingRegistrations: false,
            leaveData: undefined,
            selectedLeaveGroup: undefined,
            defaultRegistrationStatus: undefined,
            relatedRegistrations: [],
            exchangeGroupCodes: [],
            workflowProcesses: [],
            medicalCheckupTypes: [],
            newRealizationDaysParam: undefined,
            realizationsOptions: [],
            disableRealizationDueDate: false,
            registrationAttachments: [],
            registrationRemarks: [],
            registrationCalculationRelations: [],
            shouldLoadBalanceData: true,
            shoudlLoadCalculationData: true,
            educationRelations: [],
            resourcePermissions: [],
            defaultRoundingDates: undefined,
            shouldDisplayHalfDayPicker: false,
            useBalance: false
        });
    }
}