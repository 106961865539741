'use strict';

/*@ngInject*/
function agendaGridRegistrationActions(AgendaConstants) {
	
	return {
		restrict: 'A',
		link: function($scope, elt, attrs) {
			
			var startEndButton = elt.find('.start-end-button');
			var startEndIcon = startEndButton.find('.fa');
			var editButton = elt.find('.edit-button');
			var cancelButton = elt.find('.cancel-button');
			var registrationCode = elt.find('.registration-code');
			
			var agenda = $scope.grid.appScope.agenda;
			
			var index = $scope.rowRenderIndex;

			function shouldShowPlayStopButton(isPlay, startIdx, idx) {
				return agenda.registrationMetadata.data['LAYOUT_TYPE_CODE'] === AgendaConstants.LAYOUT_TYPE_FLEXIBLE_AVAILABILITY ?
						(isPlay ? !$scope.row.entity["workRosterType"] : (startIdx === idx))
						 : $scope.row.entity["workRosterType"];
			}
			
			function registrationChanged(n, o) {
				// use the `$parent` scope associated to the grid row,
				// so the other columns will also have access to the agendaRegistration and graphic properties
				var agendaRegistration = $scope.row.entity[$scope.col.colDef.name];
				
				// show/hide the button when the registration changes
				var showStartEndBtn, showEditBtn, showCancelBtn, first, notSaved = false;
				
				if (shouldShowPlayStopButton()) {
					if (agendaRegistration) {
						if (agendaRegistration.first) {
							// first day => allow cancel
							showCancelBtn = true;

							first = true;
							if (agendaRegistration.placeholder) {
								notSaved = true;
							}

							if (agendaRegistration.registration.toDate) {
								// finished registration => allow edit
								showEditBtn = true;
							} else {
								// allow ending the registration on the same day
								showStartEndBtn = true;
							}
						}
					} else {
						// no registration
						if (angular.isDefined(agenda.start) && agenda.startIdx <= index && index <= agenda.maxEndIdx) {
							showStartEndBtn = true;
						}
					}
				}
				
				if (showStartEndBtn) {
					if (agenda.start) {
						startEndIcon.addClass('fa-play').removeClass('fa-stop');
					} else {
						startEndIcon.addClass('fa-stop').removeClass('fa-play');
					}
					startEndButton.show();
				} else {
					startEndButton.hide();
				}
				
				showEditBtn ? editButton.show() : editButton.hide();
				showCancelBtn ? cancelButton.show() : cancelButton.hide();
				
				first ? elt.parent().addClass('first') : elt.parent().removeClass('first');
				notSaved ? elt.addClass('not-saved') : elt.removeClass('not-saved');
			}
			
			// $watch() if SOME properties of the registration change
			// we could use $watchCollection(), but we don't need to know about all registration properties
			var name = 'row.entity.' + $scope.col.colDef.name;
			$scope.$watchGroup([name + '.registration.fromDate', name + '.registration.toDate', name + '.first'], registrationChanged);
			$scope.$watchGroup(['row.entity.start', 'row.entity.end', name + '.first'], registrationChanged);
			
			// show/hide the button when the startIdx/maxEndIdx change
			
			agenda.startEndIdxChangedListeners[index] = function(startIdx, maxEndIdx) {
				var agendaRegistration = $scope.row.entity[$scope.col.colDef.name];
				var hideStartEnd = false;
				if (startIdx <= index && index <= maxEndIdx && !agendaRegistration && shouldShowPlayStopButton(agenda.start, startIdx, index)) {
					if (agenda.start) {
						startEndIcon.addClass('fa-play').removeClass('fa-stop');
					} else {
						startEndIcon.addClass('fa-stop').removeClass('fa-play');
					}
					startEndButton.show();
				} else {
					startEndButton.hide();
				}
			}
		}
	}
};

module.exports = agendaGridRegistrationActions;
