import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { FieldEditorProps, FieldRendererProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { SALARY_COMPONENT_SERVICE_SAVE_SALARY_COMPONENT } from "graphql/queries";
import _ from "lodash";
import moment from "moment";
import { ProteusConstants } from "ProteusConstants";
import React, { ReactNode } from "react";

export class SalaryComponentEntityDescriptor extends EntityDescriptor {

    getGraphQlFieldsToRequest() {
        return `id code description objectVersion modificationDate modificationUser creationDate creationUser 
                values { id objectVersion modificationDate modificationUser creationDate creationUser category value 
                salaryPeriod { id validFrom, validUntil, description, simulation id objectVersion modificationDate modificationUser creationDate creationUser } 
                salaryComponent { id code description objectVersion modificationDate modificationUser creationDate creationUser } }`;
    }

    protected customize() {

        const salaryComponentEntityDescriptor = this;

        const sliceSalaryComponentEditor = salaryComponentEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceSalaryComponentEditor extends SliceEntityEditorPage {

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            initQueries() {
                super.initQueries();
                this.saveMutation = SALARY_COMPONENT_SERVICE_SAVE_SALARY_COMPONENT;
                this.saveOperationName = 'salaryComponentService_saveSalaryComponent';
            }

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                currentValues: [] as any[],
                newEntities: [] as any[],
                deletedValues: [] as number[]
            }

            nestedSlices = {
                ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
                entityTableLight: sliceEntityTableLight
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceSalaryComponentEditor>(this),
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceSalaryComponentEditor>(this),

                /**
                * Because the save function (server-side) has additional logic, the default "save-service-function" has been overridden. 
                * Adapt options.variables for the overridden save function
                */
                superMutation: sliceEntityEditorPageOnlyForExtension.impures.invokeSaveMutation,
                async invokeSaveMutation(options: any) {
                    let localEntity = { ...this.getState().entity };
                    if (this.getState().duplication) {
                        localEntity = {
                            ...this.setNullEntityValuesWhenDuplicating(localEntity),
                            values: this.setNullHistoryValuesWhenDuplicating(localEntity.values)
                        };
                    }

                    let addedValues = this.getState().newEntities.filter(entity => this.getState().currentValues.filter(v => _.isEqual(v, entity)).length === 0);
                    let component = _.clone(localEntity);
                    delete component.values;
                    addedValues = addedValues?.map((value: any) => {
                        return {
                            ...value,
                            category: typeof value.category === 'object' ? value.category.code : value.category,
                            salaryComponent: component
                        }
                    });

                    options.variables = { entity: localEntity, addedValues: addedValues, deletedValues: this.getState().deletedValues };
                    return await this.superMutation(options);
                },

                setNullHistoryValuesWhenDuplicating(values: []) {
                    let newHistory = [];
                    for (let i = 0; i < values.length; i++) {
                        newHistory.push(this.setNullEntityValuesWhenDuplicating(values[i]));
                    }
                    return newHistory;
                },

                setNullEntityValuesWhenDuplicating(entity: any) {
                    return {
                        ...entity,
                        id: null,
                        creationDate: null,
                        creationUser: null,
                        modificationDate: null,
                        modificationUser: null,
                        objectVersion: null
                    };
                }

            }

        }).setEntityDescriptor(salaryComponentEntityDescriptor);

        salaryComponentEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceSalaryComponentEditor> & EntityEditorPageProps> {
            entityTableLightRef = React.createRef<EntityTableLight>();

            protected nestedSalaryComponentValueEntityDescriptor: EntityDescriptor = new EntityDescriptor({
                name: "SalaryComponentValueTable",
            }, false)
                .addFieldDescriptor({ name: 'salaryPeriod', type: 'SalaryPeriod' })
                .addFieldDescriptor(new class extends FieldDescriptor {
                    name = "category"
                    type = "Category"
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        return React.createElement(CategoryAssociationFieldEditor as any, props as FieldEditorProps)
                    }

                    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
                        const renderClass = fieldRenderers[FieldType.string];
                        const categoryCode = typeof props.entity.category === 'object' ? props.entity.category.code : props.entity.category;
                        const newProps = {
                            ...props,
                            entity: {
                                ...props.entity,
                                category: categoryCode
                            },
                            value: categoryCode
                        };
                        return super.renderFieldInternal(renderClass, newProps, entity);
                    }

                })
                .addFieldDescriptor({ name: ProteusConstants.VALUE, type: FieldType.number });

            constructor(props: any) {
                super(props);
                this.updateSalaryComponentValue = this.updateSalaryComponentValue.bind(this);
                this.onDelete = this.onDelete.bind(this);
            }

            componentDidMount() {
                this.setColumnConfig();
            }

            componentDidUpdate(prevProps: any) {
                super.componentDidUpdate(prevProps);
                if (!_.isEqual(prevProps.entity?.objectVersion, this.props.entity?.objectVersion)) {
                    this.entityTableLightRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(this.props.entity.values);
                }
            }

            setColumnConfig() {
                const defaultCC = this.nestedSalaryComponentValueEntityDescriptor.getDefaultColumnConfig();
                defaultCC.configObject.columns = [{ name: 'salaryPeriod', width: 400 }, { name: 'category', width: 150 }, { name: ProteusConstants.VALUE, width: 150 }];
                this.props.dispatchers.entityTableLight.columnConfigDropdown.setInReduxState({ columnConfig: defaultCC });
            }

            protected async load(id: any) {
                const entity = await this.props.dispatchers.load(id);
                if (entity) {
                    let salaryComponentValues = [...entity.values];
                    this.entityTableLightRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(salaryComponentValues);
                    this.props.dispatchers.setInReduxState({ currentValues: salaryComponentValues });
                }
            }

            protected async onSaveInternal() {
                const result = (await this.props.dispatchers.save(this.props.entity, undefined, undefined, { componentProps: this.props }))[this.props.dispatchers.getSlice().saveOperationName];
                let salaryComponentValues = [...result.values].sort((a: any, b: any) => moment(b.salaryPeriod.validFrom).valueOf() - moment(a.salaryPeriod.validFrom).valueOf());
                this.entityTableLightRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(salaryComponentValues);
                this.props.dispatchers.setInReduxState({ currentValues: salaryComponentValues, deletedValues: [], newEntities: [], entity: result });
                this.setColumnConfig();
            }

            async updateSalaryComponentValue(updatedSalaryComponentValue: [{ key: any }, any], addedEntity: any) {
                let newEntities = [];
                for (let i = 0; i < updatedSalaryComponentValue.length; i++) {
                    newEntities.push(updatedSalaryComponentValue[i]);
                }
                newEntities = [...newEntities].sort((a: any, b: any) => moment(b.salaryPeriod.validFrom).valueOf() - moment(a.salaryPeriod.validFrom).valueOf());
                this.entityTableLightRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(newEntities);
                this.props.dispatchers.setInReduxState({ newEntities });
            }

            onDelete(remainingEntities: any, removedEntityIndex: number) {
                const removedValue = this.entityTableLightRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[removedEntityIndex];
                if (!removedValue?.id) {
                    return;
                }
                this.props.dispatchers.setInReduxState({ deletedValues: [...this.props.deletedValues, removedValue?.id] });
            }

            renderForm() {
                const salaryComponentValues = this.entityTableLightRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities();
                return (<>
                    {super.renderForm()}
                    {salaryComponentValues && <label className="SalaryComponent_historyTableLabel">{_msg("SalaryComponent.historyTableLabel.label")}</label>}
                    <div className="SalaryComponent_historyTable">
                        <EntityTableLight ref={this.entityTableLightRef} {...this.props.entityTableLight} dispatchers={this.props.dispatchers.entityTableLight} useDefaultColumnsWidths
                            formCustomizer={{ headerContent: _msg("SalaryComponentValueTable.label"), headerIcon: "calendar alternate outline", mandatoryFields: ['salaryPeriod', 'category', ProteusConstants.VALUE] }}
                            entityDescriptor={this.nestedSalaryComponentValueEntityDescriptor} actions={{ showDeleteButton: false, showAddButton: true }}
                            onSave={this.updateSalaryComponentValue} onDelete={this.onDelete} />
                    </div>
                </>
                );
            }

        }
    }
}

export class CategoryAssociationFieldEditor extends AssociationFieldEditor<FieldEditorProps & { value?: any, placeholder?: any, onChange?: (entity: any) => void }> {

    async componentDidMount() {
        if (typeof this.props.formikProps.values.category === 'string') {
            const operationName = `${_.lowerFirst(this.props.innerEntityDescriptor!.name)}Service_findByCode`;
            let { name, query } = this.createQuery(operationName);
            let result = (await apolloClient.query({
                query: query,
                variables: this.createFindByStringParams(this.props.formikProps.values.category ? this.props.formikProps.values.category : ""),
                context: { showSpinner: false }
            })).data[name];

            this.setState({ selectedOption: result[0] });
        }
    }
}