'use strict';

module.exports = 'proteus.components';

// first jquery, then angular
// so angular know to use jquery, and not its own jqLite
window.jQuery = window.$ = require('jquery');
require('angular');

var moduleFn = angular.module;
angular.module = function() {
	// delegate
	var module = moduleFn.apply(angular, arguments);
	module.noInterpolationDirective = function(name, attrs, options) {
		var defaults = {
			restrict: 'A',
			priority: -1
		};
		
		// initialize directive definition object
		options = options || {};
		var directive = options.directive || {};

		// fill defaults
		directive.restrict = directive.restrict || defaults.restrict;
		directive.priority = directive.priority || defaults.priority;

		// compile function: prevent interpolation by removing the attributes
		directive.compile = function($elt, $attrs) {
			var values = {};
			angular.forEach(attrs, function(attr) {
				values[attr] = $attrs[attr];
				$attrs.$set(attr, null);
			});

			// link function: set the attributes to their original values
			return function($scope, $elt, $attrs) {
				angular.forEach(attrs, function(attr) {
					var value = values[attr];
					$elt.attr(attr, value);
					$attrs[attr] = value;
				});
			};
		};

		this.directive(name, function() {
			return directive;
		});
	};
	return module;
};

var uiBootstrap    = require('angular-ui-bootstrap'),
	uiGrid         = require('./ui-grid/ui-grid.module'),
	resourcePicker = require('./resource-picker/resource-picker.module'),
	companyPicker  = require('./company-picker/company-picker.module'),
	wizard         = require('./wizard/wizard.module'),
	grid           = require('./grid/grid.module'),
	tree           = require('./tree/tree.module'),
	refresh 	   = require('./refresh/refresh.module');

angular.module('proteus.components', [uiBootstrap, uiGrid, resourcePicker, companyPicker, wizard, grid, tree, refresh])

.factory('ModalSpinner',  require('./modal-spinner/modal-spinner.service'))
.factory('ErrorModal',    require('./error-modal/error-modal.service'))
.factory('WarningModal',  require('./warning-modal/warning-modal.service'))
.factory('FormModal',     require('./form-modal/form-modal.service'))
.factory('DataSource',    require('./data-source/data-source.service'))
.factory('Common',        require('./common/common.service'))
.factory('User',          require('./user/user.service'))
.factory('Principal',     require('./principal/principal.service'))
.factory('Confirm',       require('./confirm/confirm.service'))
.factory('Flags',         require('./flags/flags.service'))
.factory('RGBAConverter', require('./util/rgba-converter.service'))
.factory('Uid',           require('./util/uid.service'))
.factory('Collection',    require('./collection/collection.service'))
.factory('TreeIterator',  require('./iterators/tree-iterator.service'))
.factory('Filter',        require('./filter/filter.service.js'))
.factory('Typeahead',     require('./typeahead/typeahead.service.js'))

.filter('replaceLineBreaks', require('./util/replace-line-breaks.filter'))
.filter('ifNullOrEmpty',     require('./if-null-or-empty/if-null-or-empty.filter'))

.directive('proteusDatePicker',          require('./date-picker/date-picker.directive'))
.directive('proteusSelect',              require('./select/select.directive'))
.directive('proteusSpinner',             require('./spinner/spinner.directive'))
.directive('proteusMasterDetail',        require('./master-detail/master-detail.directive'))
.directive('proteusGoToMaster', 	     require('./master-detail/go-to-master.directive'))
.directive('proteusDrawer',              require('./drawer/drawer.directive'))
.directive('proteusFilter',              require('./filter/filter.directive'))
.directive('proteusAppear',              require('./appear/appear.directive'))
.directive('input',                      require('./input/input.directive'))
.directive('textarea',                   require('./textarea/textarea.directive'))
.directive('tabPane',                    require('./uib-tab-pane/uib-tab-pane.directive'))
.directive('navbarHideIfChildrenHidden', require('./drawer/navbar-hide-if-children-hidden.directive'))
.directive('hasPermissions',             require('./has-permissions/has-permissions.directive'))
.directive('mqDetector',                 require('./mq-detector/mq-detector.directive'))
.directive('bindHtmlCompile', 			 require('./bind-html-compile/bind-html-compile'))
.directive('proteusFilterBox', 			 require('./filter-box/filter-box.directive'))

.decorator('dateFilter', require('./date-filter/date-filter.decorator'))
.decorator('$uibModal',  require('./uib-modal/uib-modal.decorator'))
.decorator('$q',         require('./q/q.decorator'))

.controller('GwtContainerCtrl', require('./gwt-container/gwt-container.controller'))

.constant('DATE_TIME_FORMAT',    'dd/MM/yyyy HH:mm')
.constant('DATE_FORMAT',    'dd/MM/yyyy')
.constant('SCROLLBAR_WIDTH', (function() {

	// Thanks to http://stackoverflow.com/a/13382873/888165
	var outer = document.createElement("div");
	outer.style.visibility = "hidden";
	outer.style.width = "100px";
	outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

	document.body.appendChild(outer);

	var widthNoScroll = outer.offsetWidth;
	// force scrollbars
	outer.style.overflow = "scroll";

	// add innerdiv
	var inner = document.createElement("div");
	inner.style.width = "100%";
	outer.appendChild(inner);

	var widthWithScroll = inner.offsetWidth;
	
	// remove divs
	outer.parentNode.removeChild(outer);

	return widthNoScroll - widthWithScroll;
})())
.run(function($templateCache) {
	$templateCache.put('/drawer/drawer-default-nav-action-template.html', require("./drawer/drawer-default-nav-action-template.html"));
});