'use strict'

/*@ngInject*/
function User(DataSource, Common, $rootScope, $location) {

	var config = {
		baseUrl : '/user',
	}

	var ds = new DataSource(config);

	ds.config.login = ds.config.process || {};

	ds.config.login.method = 'POST';
	ds.config.login.url = '/login';
	ds.config.login.silent = true;

	if (!ds.login) {
		ds.registerOperation('login', function(result) {
			if (!result.data) {
				$location.url('/account/login');
				return;
			}
			
			// save security context
			$rootScope.securityContext = result.data.securityContext;
			$rootScope.existInboxItemsWithAction = result.data.existInboxItemsWithAction;
			$rootScope.availableForFreeTime = result.data.availableForFreeTime;

			var navRoute = $rootScope.getNavRoute('#/inboxItems');
			// The inbox items are order first, by action, and then, by date.
			// To check if we have inbox items with action, it is enough to 
			// check if the first item requires an action. 
			if (navRoute && navRoute.parentRoute) {
				if ($rootScope.existInboxItemsWithAction) {;
					navRoute.parentRoute.navRouteClass = "proteus-badge-danger";
				} else {
					navRoute.parentRoute.navRouteClass = null;
				}
			}

			navRoute = $rootScope.getNavRoute('#/free-time');
			if (navRoute && navRoute.parentRoute) {
				if ($rootScope.availableForFreeTime) {;
					navRoute.parentRoute.navRouteClass = "proteus-badge-success";
				} else {
					navRoute.parentRoute.navRouteClass = "proteus-badge-danger";
				} 
			}
			
			// define an user authenticated event, which is dispatched by window => captured by GWT client (ClientBootstrapper)
			var event = document.createEvent('Event');
			event.initEvent('user-authenticated', true, true);
			window.dispatchEvent(event);
			
			// get server context
			Common.getParameterValue({}, {
				data: {
					parameterName: 'IS_PRODUCTION_DB',
					date: new Date()
				}
			}).then(function(result) {
				// save server context
				$rootScope.serverContext = result.data;
			});
		}, function(error) {
			ds.error = error;
			$location.url('/account/login');
		});
	}

	ds.config.logout = ds.config.logout || {};

	ds.config.logout.method = 'POST';
	ds.config.logout.url = '/logout';

	if (!ds.logout) {
		ds.registerOperation('logout');
	}

	return ds;
};

module.exports = User;