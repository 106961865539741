'use strict'

/*@ngInject*/
function BackgroundTasks(DataSource) {

	var config = {
		baseUrl : '/restful/commonServiceEndpoint/backgroundTask',
		pageSize : 20,
		paramPageSize : 'maxResults',
		paramFirstItemIndex : 'firstResult',
		totalItemsFieldName : 'totalSize',
		itemsFieldName : 'currentPageItems'
	}

	var ds = new DataSource(config);

	ds.config.getAll = ds.config.getAll || {};

	ds.config.getAll.method = 'POST';
	ds.config.getAll.url = '/getBackgroundTasks';
	ds.config.getAll.data = ds.paginationOptions;
	if (!ds.getAll) {
		ds.registerOperation('getAll');
	}

	
	ds.config.runInBackground = ds.config.runInBackground || {};
	ds.config.runInBackground.method = 'POST';
	ds.config.runInBackground.url = '/runInBackground';
	if (!ds.runInBackground) {
		ds.registerOperation('runInBackground');
	}
	
	return ds;
}

module.exports = BackgroundTasks;