'use strict';

/*@ngInject*/
function dateFilter($delegate, DATE_TIME_FORMAT) {

	return function() {
		var date = arguments[0];

		if (!date) {
			return '';
		}

		var format = arguments[1] ? arguments[1] : DATE_TIME_FORMAT;
		var timezone = arguments[2];
		return $delegate(date, format, timezone);
	}
}

module.exports = dateFilter;