'use strict';

module.exports = function() {

	return function($scope, filter, getNameFn) {
		var typeahead = this;
		typeahead.filter = filter;
		typeahead.open = false;

		typeahead.onClick = function() {
			if (!filter.values.name) {
				typeahead.open = !typeahead.open;
			}
		};

		/**
		 * Delegate to NgModelController.
		 * This is preferred to implementing a $validator for the controller,
		 * since we know exactly when to validate/invalidate the input.
		 */
		typeahead.setValidity = function(state) {
			typeahead.filter.form && typeahead.filter.form.text.$setValidity('component', state);
		};

		typeahead.select = function(value) {
			typeahead.ngModelCtrl.$setViewValue(value);
			filter.values.name = getNameFn();
			typeahead.open = false;
			typeahead.setValidity(true);
		};

		typeahead.unselect = function() {
			typeahead.ngModelCtrl.$setViewValue(null);
			typeahead.setValidity(false);
		};

		typeahead.onInit = function() {
			typeahead.filter.values.name = getNameFn();
			typeahead.open = false;
		};

		$scope.$watch('typeahead.filter.values.name', function(name) {
			if (name == getNameFn()) {
				name && typeahead.setValidity(true);
				return;
			}
			typeahead.open = true;
			typeahead.unselect();
		});

		return typeahead;
	};

};