import { createSliceFoundation, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { getEmployee_employeeService_employee } from "apollo-gen/getEmployee";
import React from "react";
import { Form, Icon } from "semantic-ui-react";
import { cardEntityDescriptor } from "./customFieldRenderersEditors";

export const sliceCardTab = createSliceFoundation(class SliceCardTab {

    nestedSlices = {
        cardTable: sliceEntityTableLight
    }

    reducers = {
        ...getBaseReducers<SliceCardTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceCardTab>(this)
    }
})

type PropsNotFromState = {
    employee: getEmployee_employeeService_employee | undefined | null;
    isEditable: boolean;
    onOpenTableLight: (fieldDescriptor: FieldDescriptor, entities: any[]) => void;
    renderAdditionalFieldsInForm: (additionalFields: JSX.Element) => JSX.Element;
    cardPaneActive: boolean;
}

export class CardTab extends TabbedPage<PropsFrom<typeof sliceCardTab> & PropsNotFromState> {
    protected refEntityTableLight = React.createRef<EntityTableLight>();

    getEntityTableLightRef() {
        return this.refEntityTableLight;
    }

    componentDidMount() {
        this.refEntityTableLight.current?.open(this.props.employee?.detail?.cards || []);
    }

    componentDidUpdate(prevProps: any) {
        {/* Render cards table only if the CardTab is active.
            This will assure that the table is mounted when its container is mounted and the 
            table can get its parent width to calculate the initial width of columns.
            Otherwise, the initial width of columns will not be equal! */}
        if (this.props.cardPaneActive && !prevProps.cardPaneActive) {
            this.refEntityTableLight.current?.setEqualColumnsWidth();
        }
    }

    render() {
        return (
            <div className={"CardTab wh100" + (this.props.isEditable ? "" : " CardTab_disabled")}>
                {this.props.renderAdditionalFieldsInForm(
                    <>
                        <Form>
                            <Form.Field disabled={!this.props.isEditable}>
                                <label><Icon name="credit card outline" />{_msg("Cards.label")}</label>
                            </Form.Field>
                        </Form>
                        <EntityTableLight {...this.props.cardTable} dispatchers={this.props.dispatchers.cardTable} entityDescriptor={cardEntityDescriptor} ref={this.refEntityTableLight}
                            actions={{ showAddButton: this.props.isEditable, showEditButton: this.props.isEditable }}
                            formCustomizer={{ headerIcon: "credit card outline", headerContent: _msg("Cards.label"), mandatoryFields: ["type", "number"] }}
                        />
                    </>
                )}
            </div>
        );
    }
}