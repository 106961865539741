import { createSliceFoundation, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import React from "react";
import { Checkbox, Form, Grid, Radio } from "semantic-ui-react";

const LOODS = "Loods";
const BOOTMAN = "Bootman";

export const sliceFilterPopup = createSliceFoundation(class SliceFilterPopup {

    initialState = {
        category: LOODS as string,
        availableChecked: true as boolean,
        unavailableChecked: true as boolean
    }

    reducers = {
        ...getBaseReducers<SliceFilterPopup>(this),
    }
})

export function FilterPopup(props: PropsFrom<typeof sliceFilterPopup>) {
    
    const handleChange = (e: any, { value }: any) => props.dispatchers.setInReduxState({ category: value });
    const setAvailable = () => props.dispatchers.setInReduxState({ availableChecked: !props.availableChecked });
    const setUnavailable = () => props.dispatchers.setInReduxState({ unavailableChecked: !props.unavailableChecked });
    return (
        <Grid className="FilterPopup_grid">
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Form size="large">
                        <Form.Field>
                            {_msg("popup.category")}
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label={_msg("popup.pilots")} name='radioGroup' value={LOODS} checked={props.category === LOODS} onChange={handleChange} />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label={_msg('popup.boatmen')} name='radioGroup' value={BOOTMAN} checked={props.category === BOOTMAN} onChange={handleChange} />
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column>
                    <Form size="large">
                        <Form.Field>
                            {_msg("popup.availability")}
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                checked={props.availableChecked} label={_msg('popup.available')} onChange={setAvailable} />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                checked={props.unavailableChecked} label={_msg('popup.unavailable')} onChange={setUnavailable} />
                        </Form.Field>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}