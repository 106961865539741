import { apolloHttpLinkOptions, AppContainer, AppMeta, ConnectedPageHelper, CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY, DEFAULT, ENTITY, EntityDescriptor, EntityFormLightOpenType, ENT_AUDIT, ENT_EXTERNAL_LINK, ENT_READ, FoundationInitializationsForClient, GLOBAL, infoAppContainer, Optional, Utils, DATABASE_MENU_ENTRY } from '@crispico/foundation-react';
import { AppMetaTempGlobals } from '@crispico/foundation-react/AppMetaTempGlobals';
import { columnConfigEntityDescriptor } from '@crispico/foundation-react/components/ColumnConfig/ColumnConfigEntityDescriptor';
import { customQueryEntityDescriptor } from '@crispico/foundation-react/components/CustomQuery/CustomQueryEntityDescriptor';
import { Severity } from '@crispico/foundation-react/components/ModalExt/ModalExt';
import { CrudGlobalSettings } from "@crispico/foundation-react/entity_crud/CrudGlobalSettings";
import { entityDescriptors, FieldGen } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptorPopulatorFromEntityGen } from "@crispico/foundation-react/entity_crud/EntityDescriptorPopulatorFromEntityGen";
import { FieldRendererProps, fieldRenderers } from '@crispico/foundation-react/entity_crud/fieldRenderersEditors';
import { FieldType } from '@crispico/foundation-react/entity_crud/FieldType';
import { listPaperPlaneIcon } from '@crispico/foundation-react/utils/GroupedIconsRegistry';
import { QueryOptions } from 'apollo-client';
import { loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider } from 'apollo-gen/loadMetadataProvider';
import { AppEntityDescriptors, backgroundTaskEntityDescriptor, balanceCounterHistoryItemEntityDescriptor, calendarDefinitionEntityDescriptor, parameterEntityDescriptor, reportDefinitionAdminEntityDescriptor, reportDefinitionEntityDescriptor, securityPermissionEntityDescriptor, securityProfileEntityDescriptor } from "AppEntityDescriptors";
import { Location } from 'history';
import { infoCommunication } from 'pages/communication/Communication';
import { infoCompanyResources } from 'pages/companyResources/CompanyResources';
import { competences } from 'pages/competences/Competences';
import { infoDispatchers } from "pages/freeTimeDispatchers/Dispatchers";
import { infoEvaluationsFillIn } from "pages/evaluationsFillIn/EvaluationsFillIn"
import { infoFreeTime } from "pages/freeTimeDispatchers/FreeTime";
import { infoGroupsManagement } from 'pages/groupsManagement/GroupsManagement';
import { infoMeetRoger } from 'pages/meetRoger/MeetRoger';
import { planningPageInfo } from 'pages/Planning/PlanningPage';
import { infoRegistrationMetadata } from 'pages/registrationMetadata/RegistrationMetadata';
import { infoSequences } from 'pages/sequences/Sequences';
import { infoTestCommunication } from 'pages/testCommunication/TestCommunication';
import { infoUsersRoles } from 'pages/usersRoles/UsersRoles';
import { infoWorkPeriodGeneration } from 'pages/workPeriodsGeneration/WorkPeriodsGeneration';
import { ProteusConstants } from 'ProteusConstants';
import { ProteusUtils } from 'ProteusUtils';
import { infoCatalog } from 'pages/catalog/Catalog';
import { Redirect } from 'react-router';
import { Label, Menu } from "semantic-ui-react";
import { listPencilIcon } from '@crispico/foundation-react/utils/GroupedIconsRegistry';
import "./apollo-gen-crud/entitiesGen";
import "./proteus.css";
import { agenda } from 'pages/agenda/Agenda';
import { AssociationFieldRenderer } from '@crispico/foundation-react/entity_crud/fieldRenderers/AssociationFieldRenderer';
import { infoUsersGroups } from 'pages/userGroups/UsersGroups';
import { FieldInterval } from '@crispico/foundation-react/entity_crud/CrudSettings';

export interface EmployeeSnapshot {
    id: number | null;
    name: string | null;
    firstName: string | null;
    objectType: string | null;
    contractHistoryItem: {
        employeeNumber: string | null
    } | null;
    categoryHistoryItem: {
        category: {
            code: string | null
        }
    } | null
    company: {
        id: number | null,
        code: string | null,
        name: string | null,
        objectType: string | null,
        objectVersion: number | null
    } | null;
}

export interface ValueField {
    value: string;
}

export interface EnumProperty {
    name: string;
    values: ValueField[];
}

export interface EnumSettings {
    forProperties: EnumProperty[];
}

(window as any).angularInitHandler = function () {
    (window as any).App.loginSuccessHandler = () => AppMetaTempGlobals.appMetaInstance.loadInitializationsForClient();
}

CrudGlobalSettings.INSTANCE = new class extends CrudGlobalSettings {
    constructor() {
        super();
        this.fieldVersion = "objectVersion";
        this.fieldsToSemiHide = ["creationDate", "creationUser", "modificationDate", "modificationUser"];
        this.defaultGraphQlIdType = "Int";
        this.defaultJavaIdType = "Integer";
        this.fieldsLikeName = ["description"];
    }
}

EntityDescriptorPopulatorFromEntityGen.INSTANCE = new class extends EntityDescriptorPopulatorFromEntityGen {
    remainingEntitiesGenToIgnore = ["AbstractRecurrencePattern", "AbstractRecurrenceRange", "RecurrenceDefinition", "Employee", "RegistrationSnapshotExtra",
        "RegistrationCalculationRelation", "ResourceAttachment", "ReportParameter"];

    protected processField(ed: EntityDescriptor, fieldGen: FieldGen) {
        if (fieldGen.name.startsWith("PROPERTY_")) {
            return;
        }
        super.processField(ed, fieldGen);
    }
}

apolloHttpLinkOptions.uri = "/proteus/graphql";

export interface InitializationsForClient extends FoundationInitializationsForClient {
    isProductionDB: boolean;
    metadataProvider: loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider;
    currentUserAdditionalInfo: EmployeeSnapshot;
    currentEnvironmentId: Optional<number>;
    currentUserHasInboxItemsWithAction: boolean;
    currentUserAvailableForFreeTime: boolean;
    currentUserIsAdmin: boolean;
    enumSettings: EnumSettings;
}

class ProteusAppMeta extends AppMeta {

    public finalizeEntityDescriptors() {
        new AppEntityDescriptors().init();
        var entityCrudMenus = super.finalizeEntityDescriptors();
        // The menu entries that appear in Proteus custom menu should not appear in "Database" menu
        const menuEntriesAlreadyInProteusCustomMenu = [balanceCounterHistoryItemEntityDescriptor.getMenuEntry().to,
        securityProfileEntityDescriptor.getMenuEntry().to,
        securityPermissionEntityDescriptor.getMenuEntry().to,
        reportDefinitionAdminEntityDescriptor.getMenuEntry().to,
        reportDefinitionEntityDescriptor.getMenuEntry().to,
        parameterEntityDescriptor.getMenuEntry().to,
        calendarDefinitionEntityDescriptor.getMenuEntry().to,
        backgroundTaskEntityDescriptor.getMenuEntry().to];
        var filteredMenuEntries = entityCrudMenus.filter(menuEntry => !menuEntriesAlreadyInProteusCustomMenu.includes(menuEntry.to));
        return filteredMenuEntries;
    }

    /**
     * @override
     */
    public hasPermission(permission: string, showErrorMessageIfNoPermission?: boolean, permissions?: any): boolean {
        // In Proteus, all the users have apply permission for filters
        if (CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY === permission) {
            return true;
        }

        // Map foundation entity permissions to proteus like permissions with less granularity
        if (permission.startsWith(ENTITY)) {
            const entityName = permission.split(".")[1];

            // For CustomQuery and ColumnConfig entities, the user should always have access to fieldsRead and fieldsWrite in the form.
            // To allow the user to access the corresponding table pages, the ENT_READ|EntityName permission is still needed.
            if (entityName == customQueryEntityDescriptor.name || entityName == columnConfigEntityDescriptor.name) {
                return true;
            }

            // For manually added descriptors (used by editor/ table) always return true
            if (Object.values(entityDescriptors).filter(ed => ed.name === entityName).length === 0) {
                return true;
            }

            // CZ: For Balance Counter, there are 2 possible permissions
            if (entityName === balanceCounterHistoryItemEntityDescriptor.name) {
                return this.checkBalanceCounterHistoryItemPermissions(showErrorMessageIfNoPermission, permissions);
            }

            const nonCrudScreenPermission = this.getPermissionsForNonCrudScreens(entityName);
            if (nonCrudScreenPermission === undefined) {
                permission = Utils.pipeJoin([ENT_READ, entityName]);
            } else {
                permission = nonCrudScreenPermission;
            }
        } else {
            // For all the screens outside of "Database" menu, keep the old permission format.
            // All CRUD operations are allowed to this screens if the "VIEW" permission exists.
            const entityName = permission.split("|")[1];
            if (!permission.startsWith(ENT_AUDIT) && !!entityName && entityName === balanceCounterHistoryItemEntityDescriptor.name) {
                return this.checkBalanceCounterHistoryItemPermissions(showErrorMessageIfNoPermission, permissions);
            }
            const nonCrudScreenPermission = this.getPermissionsForNonCrudScreens(entityName);
            if (nonCrudScreenPermission !== undefined && !permission.startsWith(ENT_AUDIT) && !permission.startsWith(ENT_EXTERNAL_LINK)) {
                permission = nonCrudScreenPermission;
            }
        }

        // Delegate to angular app to check 
        // return (window as any).App.hasPermission(permission);
        return super.hasPermission(permission, showErrorMessageIfNoPermission, permissions);
    }

    protected checkBalanceCounterHistoryItemPermissions(showErrorMessageIfNoPermission?: boolean, permissions?: any) {
        const hasPermission = super.hasPermission(ProteusConstants.BALANCES_VIEW, false, permissions) || super.hasPermission(ProteusConstants.BALANCES_VIEW_IN_EMPLOYEE_EDITOR, false, permissions);
        if (!hasPermission && showErrorMessageIfNoPermission) {
            this.helperAppContainer.dispatchers.showGlobalAlert({ message: _msg("error.insufficient.rights.title") + "\n" + _msg("error.insufficient.rights.details", '"' + ProteusConstants.BALANCES_VIEW + " or " + ProteusConstants.BALANCES_VIEW_IN_EMPLOYEE_EDITOR + '"'), severity: Severity.ERROR });
        }
        return hasPermission;
    }

    protected getPermissionsForNonCrudScreens(entityName: string) {
        let permission = undefined;
        if (!entityName) {
            return permission;
        }
        if (entityName === reportDefinitionEntityDescriptor.name) {
            permission = "REPORTS_VIEW";
        } else if (entityName === securityProfileEntityDescriptor.name) {
            permission = "SECURITY_PROFILES_VIEW";
        } else if (entityName === securityPermissionEntityDescriptor.name) {
            permission = "SECURITY_PERMISSIONS_VIEW";
        } else if (entityName === reportDefinitionAdminEntityDescriptor.name) {
            permission = "REPORTS_ADMIN_VIEW"
        } else if (entityName === parameterEntityDescriptor.name) {
            permission = "APPLICATION_PARAMETERS_VIEW";
        } else if (entityName === calendarDefinitionEntityDescriptor.name) {
            permission = "CALENDARS_VIEW";
        } else if (entityName === backgroundTaskEntityDescriptor.name) {
            permission = "NEW_BACKGROUND_TASKS_VIEW";
        }
        return permission;
    }

    /**
     * In the Angular app if the user tries to access a page for that it does not have permissions, it redirects to home page
     * But in foundation it redirects the user to an error page.
     * For keeping the consistency redirect to homepage
     *  
     * @override
     * @author Daniela Buzatu
     */
    public getRedirectToError(permission: string, location?: any) {
        return <Redirect to={{ pathname: '/', state: { from: location } }} />;
    }

    protected getSecurityFieldsForInitializationsForClient() {
        return " username currentPermissions currentUserIsAdmin currentEnvironmentId currentUserHasInboxItemsWithAction currentUserAvailableForFreeTime " +
            "currentUserAdditionalInfo { id, name, firstName, contractHistoryItem { employeeNumber }, categoryHistoryItem { category { code } }, company { id, code, name, objectType, objectVersion }, objectType}";
    }

    protected getFieldsForInitializationsForClient() {
        return "isProductionDB metadataProvider {allChaches properties typePropertyCodes} enumSettings { forProperties { name values { value }}}" + super.getFieldsForInitializationsForClient();
    }

    public getAdditionalItemsForRightMenu() {
        const isProductionDB = (this.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient).isProductionDB;
        return <Menu.Item header className="less-padding"><Label className="app_productionTestLabel" color={isProductionDB ? "red" : "green"}>{isProductionDB ? "P" : "T"}</Label></Menu.Item>
    }

    protected async invokeGetInitializationsForClientQuery(options: QueryOptions) {
        const result = await super.invokeGetInitializationsForClientQuery(options);
        result.data.clientInitializationService_initializationsForClient.internationalizationSettings.defaultLanguage = 'nl';
        if (result.data.clientInitializationService_initializationsForClient.username) {
            result.data.clientInitializationService_initializationsForClient.currentUser = {
                username: result.data.clientInitializationService_initializationsForClient.username,
                isAdmin: result.data.clientInitializationService_initializationsForClient.currentUserIsAdmin
            };
        }
        window.sessionStorage.projectVersion = result.data.clientInitializationService_initializationsForClient.version[0];
        entityDescriptors["EmployeeFunction"].addFieldDescriptor({
            name: "ejobType", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: this.getFieldIntervalsForEjobType(result.data.clientInitializationService_initializationsForClient.enumSettings) }
        });
        return result;
    }

    getFieldIntervalsForEjobType(enumSettings: any) {
        const ejobTypeSettings = enumSettings.forProperties.filter((p: any) => p.name === ProteusConstants.EJOB_TYPE)[0];
        if (!ejobTypeSettings?.values) {
            return [];
        }
        const fieldIntervals: FieldInterval[] = Object.keys(ejobTypeSettings?.values).filter(key => ejobTypeSettings?.values[key].value)
            .map(key => { return { from: ejobTypeSettings?.values[key].value.toString(), label: ejobTypeSettings?.values[key].value } as FieldInterval });
        return fieldIntervals;
    }

    public async loadInitializationsForClient() {
        const initializationsForClient = await super.loadInitializationsForClient();
        if (initializationsForClient.currentUser?.isAdmin || AppMetaTempGlobals.appMetaInstance.hasPermission(DATABASE_MENU_ENTRY, false, initializationsForClient.currentPermissions)) {
            // database submenu is moved in administration submenu and has to be removed from the main menu
            this.menuEntries.splice(this.menuEntries.length - 1, 1);
        }
        return initializationsForClient;
    }

    protected hasHomePageMenuEntry() {
        return false;
    }

    // Overridden method because Audit is not implemented yet in Proteus
    public async loadAuditMapping() {
    }

    protected createMenuEntries(initializationsForClient: InitializationsForClient) {
        const canViewBalanceCounterMenuEntry = super.hasPermission(ProteusConstants.BALANCES_VIEW, false, initializationsForClient.currentPermissions);
        const personeelSubmenu = canViewBalanceCounterMenuEntry ? [balanceCounterHistoryItemEntityDescriptor.getMenuEntry(), { content: _msg("Credit.label"), to: "/credit", icon: "balance", permission: "CREDIT_VIEW" }]
            : [{ content: _msg("Credit.label"), to: "/credit", icon: "balance", permission: "CREDIT_VIEW" }];
        var personnelSubmenu = { content: _msg("Personeel"), icon: "users", submenus: personeelSubmenu };

        var educationSubmenu = {
            content: _msg("education.label"), icon: "graduation", submenus: [
                { content: _msg("education.catalog.label"), to: "/education/catalogs", icon: "book", permission: "EDUCATION_VIEW_CATALOG" },
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoCatalog.sliceName as string), to: "/" + infoCatalog.sliceName, icon: "book", permission: infoCatalog.routeProps!.permission },
                { content: _msg("education.competences.label"), to: "/education/competences", icon: "certificate", permission: "EDUCATION_VIEW_DICTIONARY" },
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, competences.sliceName as string), to: "/" + competences.sliceName, icon: "certificate", permission: competences.routeProps!.permission },
                { content: _msg("education.evaluationRequest.label"), to: "/education/evaluationRequest", icon: listPaperPlaneIcon, permission: "EDUCATION_VIEW_EVALUATION_REQUEST" },
                { content: _msg("education.curriculum.label"), to: "/education/curriculum", icon: "road", permission: "EDUCATION_CURRICULUM_VIEW" },
                { content: _msg("education.session.label"), to: "/education/groupRegistrations", icon: "podcast", permission: "EDUCATION_VIEW_TRAINING_SESSIONS" }
            ]
        };
        var employeeSubmenu = {
            content: _msg("employee.label"), icon: "user",
            className: initializationsForClient.currentUserHasInboxItemsWithAction ? "MenuEntry_red" : "",
            submenus: [
                { content: _msg("employee.messages.label"), to: "/inboxItems", icon: "mail", permission: "MESSAGES_VIEW" },
                { content: _msg("employee.myAgenda.label"), to: "/agenda", icon: "address book", permission: "AGENDA_VIEW" },
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, agenda.sliceName as string), to: "/" + agenda.sliceName, icon: "address book", permission: agenda.routeProps!.permission },
                { content: _msg("employee.otherAgenda.label"), to: "/other-agenda", icon: "book", permission: "OTHER_AGENDA_VIEW" },
                { content: _msg("employee.waitingAgenda.label"), to: "/waiting-agenda", icon: "time", permission: "WAITING_AGENDA_VIEW" },
                { content: _msg("employee.fillInEvaluation.label"), to: "/education/evaluationFillIn", icon: listPencilIcon, permission: "EDUCATION_VIEW_EVALUATION_COMPLETION" },
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoEvaluationsFillIn.sliceName as string), to: "/" + infoEvaluationsFillIn.sliceName, icon: listPencilIcon, permission: infoEvaluationsFillIn.routeProps!.permission },
                { content: _msg("employee.backgroundTasks.label"), to: "/backgroundTasks", icon: "tasks", permission: "BACKGROUND_TASKS_VIEW" },
                backgroundTaskEntityDescriptor.getMenuEntry()
            ]
        };

        var freetimeSubmenu = {
            content: _msg("freetime.label"), icon: "clock outline",
            className: initializationsForClient.currentUserAvailableForFreeTime ? "MenuEntry_green" : "MenuEntry_red",
            submenus: [
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoFreeTime.sliceName as string), to: "/" + infoFreeTime.sliceName, icon: "clock", permission: infoFreeTime.routeProps!.permission },
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoDispatchers.sliceName as string), to: "/" + infoDispatchers.sliceName, icon: "table", permission: infoDispatchers.routeProps!.permission },
            ]
        };
        var administrationSubmenu = {
            content: _msg("administration.label"), icon: "key", submenus: [
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoGroupsManagement.sliceName as string), to: "/" + infoGroupsManagement.sliceName, icon: "group", permission: infoGroupsManagement.routeProps!.permission },
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoUsersGroups.sliceName as string), to: "/" + infoUsersGroups.sliceName, icon: "search plus", permission: infoUsersGroups.routeProps!.permission },
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoRegistrationMetadata.sliceName as string), to: "/" + infoRegistrationMetadata.sliceName, icon: "paint brush", permission: infoRegistrationMetadata.routeProps!.permission },
                { content: _msg("administration.security.label"), icon: "lock", permission: 'SECURITY_SUBMENU_VIEW',  submenus: [
                    { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoUsersRoles.sliceName as string), to: "/" + infoUsersRoles.sliceName, icon: "unlock alternate", permission: infoUsersRoles.routeProps!.permission },
                    securityProfileEntityDescriptor.getMenuEntry(),
                    securityPermissionEntityDescriptor.getMenuEntry()
                ]},
                reportDefinitionAdminEntityDescriptor.getMenuEntry(),
                parameterEntityDescriptor.getMenuEntry(),
                calendarDefinitionEntityDescriptor.getMenuEntry(),
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoWorkPeriodGeneration.sliceName as string), to: "/" + infoWorkPeriodGeneration.sliceName, icon: "retweet", permission: infoWorkPeriodGeneration.routeProps!.permission },
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoCommunication.sliceName as string), to: "/" + infoCommunication.sliceName, icon: "comments", permission: infoCommunication.routeProps!.permission },
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoTestCommunication.sliceName as string), to: "/" + infoTestCommunication.sliceName, icon: "comments", permission: infoTestCommunication.routeProps!.permission },
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoMeetRoger.sliceName as string), to: "/" + infoMeetRoger.sliceName, icon: "comments outline", permission: infoMeetRoger.routeProps!.permission }
            ]
        };

        if (initializationsForClient.currentUser?.isAdmin || AppMetaTempGlobals.appMetaInstance.hasPermission(DATABASE_MENU_ENTRY, false, initializationsForClient.currentPermissions)) {
            administrationSubmenu.submenus.push({ content: _msg("Menu.database"), icon: "database", submenus: this.entityCrudMenus, permission: DATABASE_MENU_ENTRY});
        }

        var operationalSubmenu = {
            content: _msg("Operational"), icon: "cogs", submenus: [
                { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoSequences.sliceName as string), to: "/" + infoSequences.sliceName, icon: "sort numeric up", permission: infoSequences.routeProps!.permission }
            ]
        };
        return [
            { content: planningPageInfo.sliceName, to: "/" + planningPageInfo.sliceName, icon: "chart bar outline", permission: planningPageInfo.routeProps!.permission },
            { content: _msg({ missingKeyStrategy: "RETURN_KEY" }, infoCompanyResources.sliceName as string), to: "/" + infoCompanyResources.sliceName, icon: "address card", permission: infoCompanyResources.routeProps!.permission },
            personnelSubmenu,
            educationSubmenu,
            employeeSubmenu,
            reportDefinitionEntityDescriptor.getMenuEntry(),
            freetimeSubmenu,
            administrationSubmenu,
            operationalSubmenu,
        ];
    }

    public getAdditionalUserMenuEntries(): any[] {
        let entries = super.getAdditionalUserMenuEntries();
        const currentUserIsAdmin = (this.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient).currentUserIsAdmin;
        if (currentUserIsAdmin) {
            entries.push({ key: 'switchAccount', text: _msg("Account.switch.label"), onClick: async () => {
                    // re-direct to proteus login
                    AppMetaTempGlobals.history.push("/account/login-with-proteus?switch");
                }
            });
        }
        return entries;
    }

    /**
     * @override
     */
    async logout() {
        // Call angular logout
        const that = this;
        (window as any).App.logout().then(function () {
            that.resetMenuEntries();
            that.loadInitializationsForClient();
        });
    }

    /**
     * @override
     */
    protected resetMenuEntries() {
        this.menuEntries = [];
    }

};

export function createMeta(): ProteusAppMeta {
    const appMeta = new ProteusAppMeta([]);

    appMeta.addConnectedPageInfos([
        planningPageInfo,
        infoCompanyResources,
        infoFreeTime,
        infoDispatchers,
        infoUsersRoles,
        infoUsersGroups,
        infoRegistrationMetadata,
        infoGroupsManagement,
        infoWorkPeriodGeneration,
        infoTestCommunication,
        infoMeetRoger,
        infoCommunication,
        infoSequences,
        infoEvaluationsFillIn,
        competences,
        infoCatalog,
        agenda
    ]);

    appMeta.showHorizontalMenu = true;
    appMeta.showNotifications = false;
    appMeta.showOrganization = false;
    appMeta.showMyAccountMenuEntry = false;
    appMeta.showTimeZone = false;
    appMeta.removeMenuEntryWhenNoPermission = true;
    appMeta.showCrudButtons = {
        showImportButton: false,
        showExportButton: false,
        showAuditButton: false,
        showShareLinkButton: false,
        showCustomQueryButton: true,
        showColumnConfigButton: true,
        showCustomQueryEmailSchedule: false,
        showExportAsCsvButton: true
    };
    appMeta.showViewPropertiesTab = false;
    appMeta.dashboardsAvailable = false;
    appMeta.entityFormLightOpenType = EntityFormLightOpenType.DRAWER;
    appMeta.scrollOnlyContentInEditor = true;
    return appMeta;
}

export const appMeta = createMeta();

declare global {
    namespace JSX {
        interface IntrinsicElements {
            heyo: any,
            proteusDrawer: any
        }
    }
}

ConnectedPageHelper.tempEnableSecurity = true;

const ANGULAR_ROUTES: string[] = ['/', '/account/login', '/credit', '/education/catalogs', '/education/competences', '/education/evaluationRequest',
    '/education/curriculum', '/education/groupRegistrations', '/inboxItems', '/inboxItem', '/agenda',
    '/other-agenda', '/waiting-agenda', '/education/evaluationFillIn', '/backgroundTasks'
];

class CustomAssociationFieldRenderer extends AssociationFieldRenderer {

    protected getShowManyToOneCellAsLink() {
        return ProteusUtils.checkPermission(ProteusConstants.TABLE_PAGE_ALLOW_ASSOCIATED_LINKS);
    }
}

// Override the default many to one renderer to enable / disable the navigation links based on "TABLE_PAGE_ALLOW_ASSOCIATED_LINKS" user permission.
fieldRenderers[FieldType.defaultManyToOne] = (props: FieldRendererProps) => {
    return <CustomAssociationFieldRenderer {...props} asLink />
};

/**
 * @author Daniela Buzatu
 */
class ProteusAppContainer extends AppContainer {

    protected onLocationChanged(location: Location<any>) {
        super.onLocationChanged(location);
        var isAngularRoute: boolean = false;
        for (const key in ANGULAR_ROUTES) {
            const angularRoute = ANGULAR_ROUTES[key];
            if (angularRoute == "/" && location.pathname == "/" || angularRoute != "/" && location.pathname.startsWith(angularRoute)) {
                isAngularRoute = true;
                break;
            }
        }
        // We need to hide and show the react root view because it has a 100% height relative to the page, no matter if it has any content or not
        // Angular auto removes its parent view if the current route is not recognized, so no need to hide show angular root view
        if (!isAngularRoute) {
            // Show react root view
            document.getElementById("root")!.setAttribute("style", "padding-top: 53px!important");
            this.props.dispatchers.setInReduxState({ avoidDisplayingInitializationsLoadingSpinner: false });
        } else {
            // Hide react root view
            // We can not use the clasic display:none/display:block because we need the absolute position menu bar to be always visible
            // This problem would be solved if we could insert the angular view inside the ProteusAppContainer. This was not possible 
            // because ProteusAppContainer rendered a little bit later and the react router needed its ng-view div earlier
            document.getElementById("root")!.setAttribute("style", "height:0px !important; padding:0px !important");

            // Whenever an angular route is accesed, the angular app adds a modal spinner
            // When the react application loads its initializationsForClient and also is on an angular route both (angular and react) spinners are shown
            // In this case aavoid displaying the react spinner
            this.props.dispatchers.setInReduxState({ avoidDisplayingInitializationsLoadingSpinner: true });
        }
    }

    protected getHeaderClassName() {
        const isProductionDB = (this.props.initializationsForClient as InitializationsForClient).isProductionDB;
        return !isProductionDB ? "ContainerWithHeader_headerTest" : "";
    }

}
infoAppContainer.wrappedComponentClass = ProteusAppContainer;
export default appMeta.getAppComponent();
