import { apolloClient, ConnectedPageInfo, createSliceFoundation, EntityDescriptor, getBaseImpures, getBaseReducers, OnSelectParams, PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityFormLight, EntityFormLightRaw } from "@crispico/foundation-react/entity_crud/light_crud/EntityFormLight";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { UploadFile } from "antd/lib/upload/interface";
import { AttachmentEntity, RegistrationAndEducationFileUploadButton, sliceRegistrationAndEducationFileUploadButton } from "components/uploadButton/RegistrationAndEducationFileUploadButton";
import { TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE, TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE_CATEGORIES, TRAINING_SERVICE_ENDPOINT_REMOVE_COMPETENCE_CATEGORY, TRAINING_SERVICE_ENDPOINT_SAVE_COMPETENCE_CATEGORY, TRAINING_SERVICE_ENDPOINT_REMOVE_COMPETENCE, TRAINING_SERVICE_ENDPOINT_SAVE_COMPETENCE, TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE_VERSION, TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE_VERSIONS, TRAINING_SERVICE_ENDPOINT_REMOVE_COMPETENCE_VERSION, TRAINING_SERVICE_ENDPOINT_SAVE_COMPETENCE_VERSION } from "graphql/queries";
import _ from "lodash";
import React from "react";
import { Button, Divider, Form, Label, Menu, MenuItem, Modal, Segment, Tab } from "semantic-ui-react";
import { CompetenceCategoryDescriptor } from "./CompetenceCategoryDescriptor";
import { CompetenceTreeRRC } from "./CompetenceTree";
import { EducationsDescriptor } from "./EducationsDescriptor";

const COMPETENCE = "COMPETENCE";
const CLASS = "com.brabo.training.core.competence.domain.catalog.CompetenceAttachment";

interface BasicEntity {
    id: number,
    objectVersion: number,
    creationDate: any,
    creationUser: any,
    modificationDate: any,
    modificationUser: any;
}

interface LevelDefinition {
    description: string,
    percentage: number,
    scale: number;
}

interface Catalog extends BasicEntity {
    name: string,
    description: string;
}

interface Attachment extends BasicEntity {
    fileName: string,
    id: number,
    name: string,
    classType: string;
}

export interface TrainingComponentVersion extends BasicEntity {
    name: string,
    description: string,
    majorVersion: number,
    minorVersion: number,
    component: BasicEntity;
}

export interface CompetenceVersion extends BasicEntity {
    name: string,
    description: string,
    active: boolean,
    majorVersion: number,
    minorVersion: number,
    levelDefinitions: LevelDefinition[],
    trainingComponentVersions: TrainingComponentVersion[],
    attachments: Attachment[],
}

export interface CompetenceCategory extends BasicEntity {
    competences: Competence[],
    categoryChildren: CompetenceCategory[],
    catalog: Catalog,
    name: string,
    description: string;
}

export interface Competence extends BasicEntity {
    category: CompetenceCategory,
    currentCompetenceVersion: CompetenceVersion,
}

export interface FullCompetenceInput {
    competence: Competence,
    createNewMajorVersion: boolean,
    addedComponents: number[],
    removedComponents: number[];
}

enum Action {
    NO_ACTION = 0,
    DELETE_COMPETENCE = 1,
    UPDATE_COMPETENCE = 2,
    DELETE_COMPETENCE_CATEGORY = 3,
    ADD_COMPETENCE_CATEGORY = 4,
    ADD_COMPETENCE = 5,
    UPDATE_COMPETENCE_CATEGORY = 6
}

const levelDefinitionDescriptor = new EntityDescriptor({ name: "LevelDefinitionTable" })
    .addFieldDescriptor({ name: "scale", type: FieldType.string })
    .addFieldDescriptor({ name: "percentage", type: FieldType.number })
    .addFieldDescriptor({ name: "description", type: FieldType.string });

const levelDefinitionEditorDescriptor = new EntityDescriptor({ name: "LevelDefinitionEditor" })
    .addFieldDescriptor({ name: "percentage", type: FieldType.number })
    .addFieldDescriptor({ name: "description", type: FieldType.string });

const competenceCategoryDescriptor = new CompetenceCategoryDescriptor({ name: "CompetenceCategoryEditor" });

const educationsDescriptor = new EducationsDescriptor({ name: "EducationsEditor" });

export const sliceCompetences = createSliceFoundation(class SliceCompetences {

    initialState = {
        // Properties for current competence/competence version
        competence: undefined as Competence | undefined,
        // We keep also properties from competence because, sometimes, competence may contain a
        // CompetenceVersion and accessing methods differ
        name: undefined as unknown as string,
        description: undefined as unknown as string,
        active: undefined as unknown as boolean,
        majorVersion: undefined as number | undefined,
        minorVersion: undefined as number | undefined,
        addedComponents: [] as number[],
        removedComponents: [] as number[],
        attachments: [] as AttachmentEntity[],
        educations: [] as TrainingComponentVersion[],
        selectedEducation: undefined as unknown as BasicEntity,
        // createNewMajorVersion is used when we try to save changes for a competence
        createNewMajorVersion: undefined as unknown as boolean,
        isCompetence: false as boolean,
        // When renderCompetenceEditor is true, tabs with details about a competence will be rendered 
        renderCompetenceEditor: false as boolean,
        enableSaveButton: false as boolean,
        openLevelDefinitionErrorModal: undefined as unknown as boolean,
        // root contains the entire tree of competences
        root: undefined as unknown as CompetenceCategory[],
        renderTree: false as boolean,
        // The following properties are used to render the modal to confirm we want
        // to delete something
        openDeleteModal: undefined as unknown as boolean,
        deleteModalMessage: undefined as unknown as string,
        deletedEntityId: undefined as unknown as number,
        // Option used to render dropdown with options for a competence or a competence category
        openContextMenuModal: false as [number, number] | boolean,
        // Options which contain last component selected (category, component, component version) and a list
        // of ids that represents the way to category through the tree
        lastComponentSelectedId: [] as string[],
        lastComponentSelected: undefined as unknown as any,
        // Options which contain new parent for a category and a list of ids
        newParentId: [] as string[],
        newParentCompetenceCategory: undefined as unknown as CompetenceCategory,
        // searchExpression store a part from competence name for searching into tree
        searchExpression: "" as string,
        // Fields which store details about a competence category (these are updated from editor)
        editorCompetenceCategoryName: "" as string,
        editorCompetenceCategoryDescription: "" as string,
        activeFilter: false as boolean,
        notActiveFilter: false as boolean,
        openOptionsModalForCompetence: false as boolean,
        deleteMessage: "" as string,
        tabChanged: false,
        activeIndex: 0 as number | string | undefined,
        levelDefinitions: [] as any[],
        educationComponents: [] as any[]
    };

    nestedSlices = {
        levelDefinitionTableLight: sliceEntityTableLight,
        educationComponentsLight: sliceEntityTableLight,
        attachmentUpload: sliceRegistrationAndEducationFileUploadButton
    };

    reducers = {
        ...getBaseReducers<SliceCompetences>(this),

        onNewAttachmentCreated(state: StateFrom<SliceCompetences>, params: { fileName: string, attachmentId: number; }) {
            let newAttachment = {
                name: params.fileName,
                fileName: params.fileName,
                id: Number(params.attachmentId),
                objectVersion: 0,
                classType: "com.brabo.training.core.competence.domain.catalog.CompetenceAttachment",
                creationDate: null,
                creationUser: null,
                modificationDate: null,
                modificationUser: null,
            };
            state.attachments.push(newAttachment);
            state.enableSaveButton = true;
        },

        loadRightPanelDetails(state: StateFrom<SliceCompetences>, params: { competence: any, isCompetence: boolean, lastComponentSelectedId?: string[]; }) {
            let competenceFields = params.competence;
            if (params.isCompetence && params.competence) {
                competenceFields = params.competence.currentCompetenceVersion;
            }
            state.name = !params.competence ? "" : competenceFields.name;
            state.description = !params.competence || competenceFields.description === null ? "" : competenceFields.description;
            state.active = !params.competence ? true : competenceFields.active;
            state.majorVersion = !params.competence ? 0 : competenceFields.majorVersion;
            state.minorVersion = !params.competence ? 0 : competenceFields.minorVersion;
            state.attachments = !params.competence ? [] : competenceFields.attachments;
            state.isCompetence = params.isCompetence;
            state.educations = !params.competence ? [] : competenceFields.trainingComponentVersions;
            state.renderCompetenceEditor = true;
            state.enableSaveButton = false;
            state.createNewMajorVersion = false;
            state.competence = params.competence;
            if (params.lastComponentSelectedId) {
                state.lastComponentSelectedId = params.lastComponentSelectedId;
            }
        }
    };

    impures = {
        ...getBaseImpures<SliceCompetences>(this),

        async getCompetences() {
            let competenceCategories = (await apolloClient.query({ query: TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE_CATEGORIES, variables: { catalogId: 1 } })).data.trainingServiceEndpoint_competenceCategories;

            this.getDispatchers().setInReduxState({
                root: competenceCategories,
                renderTree: true
            });
        },

        getNewPosition(index: number, length: number, categories: CompetenceCategory[]) {
            if (index === length - 1 || (index !== 0 && categories[index].name < categories[index - 1].name)) {
                let newIndex = index - 1;
                while (newIndex >= 0) {
                    if (categories[newIndex].name < categories[index].name) {
                        break;
                    }
                    newIndex--;
                }
                return newIndex + 1;
            }
            let newIndex = index + 1;
            while (newIndex < length) {
                if (categories[index].name < categories[newIndex].name) {
                    break;
                }
                newIndex++;
            }
            return newIndex - 1;
        },

        async updateCompetence(action: Action, parent: any, index: number, competence: any, newRoot: any) {
            if (action === Action.DELETE_COMPETENCE) {
                parent.competences.splice(index, 1);
                this.getDispatchers().setInReduxState({ root: newRoot });
                return;
            }

            let versions = (await apolloClient.query({
                query: TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE_VERSIONS,
                variables: { competenceId: competence.id }
            })).data.trainingServiceEndpoint_competenceVersions;

            if (versions.length === 0) {
                parent.competences.splice(index, 1);
                this.getDispatchers().setInReduxState({ root: newRoot });
                return;
            }

            competence.versions = versions.reverse();

            if (action === Action.UPDATE_COMPETENCE) {
                competence.currentCompetenceVersion.name = this.getState().name;
                competence.currentCompetenceVersion.majorVersion = this.getState().majorVersion;
                competence.currentCompetenceVersion.minorVersion = this.getState().minorVersion;
                competence.currentCompetenceVersion.active = this.getState().active;
            }

            if (versions[0].majorVersion !== competence.currentCompetenceVersion.majorVersion ||
                versions[0].minorVersion !== competence.currentCompetenceVersion.minorVersion) {
                competence.currentCompetenceVersion.name = versions[0].name;
                competence.currentCompetenceVersion.majorVersion = versions[0].majorVersion;
                competence.currentCompetenceVersion.minorVersion = versions[0].minorVersion;
                competence.currentCompetenceVersion.attachments = versions[0].attachments;
                competence.currentCompetenceVersion.levelDefinitions = versions[0].levelDefinitions;
                competence.currentCompetenceVersion.description = versions[0].description;
                competence.currentCompetenceVersion.active = versions[0].active;
            }

            this.getDispatchers().setInReduxState({ root: newRoot });
        },

        updateCompetenceCategory(newComponent: CompetenceCategory, competenceCategory: any, newRoot: any, objectId: string[],
            newParentId: string[] | undefined, index: number | undefined, parent: any) {
            competenceCategory.name = newComponent.name;
            competenceCategory.description = newComponent.description;
            competenceCategory.modificationDate = newComponent.modificationDate;
            competenceCategory.modificationUser = newComponent.modificationUser;
            competenceCategory.objectVersion = newComponent.objectVersion;
            if (objectId.length === 1) {
                if (index !== undefined) {
                    let newIndex = this.getNewPosition(index, newRoot.length, newRoot);
                    let category = newRoot.splice(index, 1);
                    newRoot.splice(newIndex, 0, category[0]);
                }
            }
            if (newParentId && index !== undefined) {
                if (objectId.length === 1) {
                    newRoot.splice(index, 1);
                } else {
                    parent.categoryChildren.splice(index, 1);
                }
                let newParent: any = newRoot;
                let firstIteration = true;
                if (newParentId[0] === "") {
                    competenceCategory.parentCategory = null;

                    newParent.push(competenceCategory);
                    let newIndex = this.getNewPosition(newRoot.length - 1, newRoot.length, newRoot);
                    let category = newRoot.splice(newRoot.length - 1, 1);
                    newRoot.splice(newIndex, 0, category[0]);

                    this.getDispatchers().setInReduxState({ root: newRoot });
                    return;
                }
                for (let field of newParentId) {
                    if (firstIteration) {
                        firstIteration = false;
                        for (index = 0; index < newParent.length; index++) {
                            if (newParent[index].id === Number(field)) {
                                newParent = newParent[index];
                                break;
                            }
                        }
                    } else {
                        if (newParent.categoryChildren) {
                            let found = false;
                            for (let category of newParent.categoryChildren) {
                                if (category.id === Number(field)) {
                                    newParent = category;
                                    found = true;
                                    break;
                                }
                            }
                            if (found) {
                                continue;
                            }
                        }
                    }
                }
                let { competences, categoryChildren, ...category } = newParent;
                competenceCategory.parentCategory = category;
                newParent.categoryChildren.push(competenceCategory);
                this.getDispatchers().setInReduxState({ root: newRoot });
            } else {
                this.getDispatchers().setInReduxState({ root: newRoot });
            }
        },

        async updateTree(objectId: string[], action: Action, newComponent?: any, newParentId?: string[]) {
            let newRoot = _.cloneDeep(this.getState().root);
            let object: any = newRoot;
            let parent: any = newRoot;
            let firstIteration = true;
            // I declared i here because sometimes we need to find an object following path from objectId,
            // but we need also to know his position to make operations like delete (using splice with
            // position i)
            let i;
            // objectId contains some ids which represent the path to the object that should be updated,
            // or the path to the parent of a new / deleted object
            for (let field of objectId) {
                // First time, object is an array of competence categories
                if (firstIteration) {
                    firstIteration = false;
                    for (i = 0; i < object.length; i++) {
                        if (object[i].id === Number(field)) {
                            object = object[i];
                            break;
                        }
                    }
                    parent = object;
                } else {
                    let found = false;
                    for (i = 0; i < object.competences.length; i++) {
                        // If the last object is a competence, we should update or delete it
                        // (based on field action)
                        if (object.competences[i].id === Number(field)) {
                            parent = object;
                            object = object.competences[i];
                            this.updateCompetence(action, parent, i, object, newRoot);
                            return;
                        }
                    }
                    // We check the children categories of the current one
                    for (i = 0; i < object.categoryChildren.length; i++) {
                        if (object.categoryChildren[i].id === Number(field)) {
                            parent = object;
                            object = object.categoryChildren[i];
                            found = true;
                            break;
                        }
                    }
                    // If we found the category with the current field as id, we will continue searching
                    // for the rest of the path
                    if (found) {
                        continue;
                    }
                }
            }
            // If the last object has categoryChildren defined and we need to add competence, we
            // will add new component and will save tree
            if (object.categoryChildren && action === Action.ADD_COMPETENCE) {
                object.competences.push(newComponent);
                this.getDispatchers().setInReduxState({
                    root: newRoot,
                    renderCompetenceEditor: false,
                });
                return;
            } else if (object.categoryChildren && action === Action.UPDATE_COMPETENCE_CATEGORY) {
                // object will be updated using properties of newComponent and update the parent of the object
                // to the component given by newParentId
                this.updateCompetenceCategory(newComponent, object, newRoot, objectId, newParentId, i, parent);
                return;
            } else if (object.categoryChildren && action === Action.ADD_COMPETENCE_CATEGORY) {
                // If the object is a category and we need to add a new category, we push category (newComponent)
                // into categoryChildren field
                object.categoryChildren.push(newComponent);
            } else if (object.categoryChildren && action === Action.DELETE_COMPETENCE_CATEGORY) {
                if (i !== undefined) {
                    // If objectId has length 1, this means that we are on the first level and we need to
                    // delete category from newRoot
                    if (objectId.length === 1) {
                        newRoot.splice(i, 1);
                    } else {
                        // Otherwise, we will delete category from his parent
                        parent.categoryChildren.splice(i, 1);
                    }
                }
            } else if (objectId.length === 1 && objectId[0] === "" && action === Action.ADD_COMPETENCE_CATEGORY) {
                // objectId[0] === "" means that newComponent will have as parent root directly.
                // It is pushed into root to use method getNewPosition (which takes last element of newRoot
                // and compares its name with the other elements because categories from root need to be
                // sorted)
                newRoot.push(newComponent);
                let newIndex = this.getNewPosition(newRoot.length - 1, newRoot.length, newRoot);
                let category = newRoot.splice(newRoot.length - 1, 1);
                newRoot.splice(newIndex, 0, category[0]);
            }
            this.getDispatchers().setInReduxState({ root: newRoot });
        },

        async onSubmitNewCompetenceCategory(value: any) {
            // Competences of the parent category shouldn't have versions because
            // they aren't fetched with the rest of the components and server will give an error
            let parentComponent = this.getState().newParentCompetenceCategory === undefined ?
                this.getState().lastComponentSelected :
                this.getState().newParentCompetenceCategory;
            let parentId = this.getState().newParentId === undefined ?
                this.getState().lastComponentSelectedId :
                this.getState().newParentId;

            if (parentComponent && parentComponent.competences) {
                parentComponent = _.cloneDeep(parentComponent);
                for (let i = 0; i < parentComponent.competences.length; i++) {
                    if (parentComponent.competences[i].versions) {
                        let { versions, ...newCompetence } = parentComponent.competences[i];
                        parentComponent.competences[i] = newCompetence;
                    }
                }
            }

            let newCategory = (await apolloClient.mutate({
                mutation: TRAINING_SERVICE_ENDPOINT_SAVE_COMPETENCE_CATEGORY,
                variables: {
                    competenceCategory: {
                        name: value.name,
                        description: value.description,
                        parentCategory: parentComponent,
                        // Can appear some problems if our catalog doesn't have any elements
                        catalog: parentComponent ? parentComponent.catalog : this.getState().root[0].catalog
                    }
                }
            })).data.trainingServiceEndpoint_saveCompetenceCategory;

            this.updateTree(parentId, Action.ADD_COMPETENCE_CATEGORY, newCategory);
        },

        async onEditCompetenceCategory() {
            let competenceCategory = _.cloneDeep(this.getState().lastComponentSelected);

            let actualParentId = [...this.getState().lastComponentSelectedId];
            actualParentId.splice(actualParentId.length - 1, 1);

            for (let i = 0; i < competenceCategory.competences.length; i++) {
                if (competenceCategory.competences[i].versions) {
                    let { versions, ...newCompetence } = competenceCategory.competences[i];
                    competenceCategory.competences[i] = newCompetence;
                }
            }

            competenceCategory.name = this.getState().editorCompetenceCategoryName;
            competenceCategory.description = this.getState().editorCompetenceCategoryDescription;
            if (this.getState().newParentCompetenceCategory !== undefined) {
                competenceCategory.parentCategory = _.cloneDeep(this.getState().newParentCompetenceCategory);
                if (competenceCategory.parentCategory) {
                    for (let i = 0; i < competenceCategory.parentCategory.competences.length; i++) {
                        if (competenceCategory.parentCategory.competences[i].versions) {
                            let { versions, ...newCompetence } = competenceCategory.parentCategory.competences[i];
                            competenceCategory.parentCategory.competences[i] = newCompetence;
                        }
                    }
                }
            }

            let newCategoryDetails = (await apolloClient.mutate({
                mutation: TRAINING_SERVICE_ENDPOINT_SAVE_COMPETENCE_CATEGORY,
                variables: { competenceCategory }
            })).data.trainingServiceEndpoint_saveCompetenceCategory;

            if (_.isEqual(actualParentId, this.getState().newParentId)) {
                this.updateTree(this.getState().lastComponentSelectedId, Action.UPDATE_COMPETENCE_CATEGORY,
                    newCategoryDetails);
            } else {
                this.updateTree(this.getState().lastComponentSelectedId, Action.UPDATE_COMPETENCE_CATEGORY,
                    newCategoryDetails, this.getState().newParentId);
            }
        }
    };
});

export class SliceCompetences extends TabbedPage<PropsFrom<typeof sliceCompetences>> {

    protected refEditorCategory = React.createRef<EntityFormLightRaw>();
    protected refAddCategory = React.createRef<EntityFormLightRaw>();
    protected refAddEducation = React.createRef<EntityTableLight>();
    protected levelDefinitionTableRef = React.createRef<EntityTableLight>();
    protected educationComponentsRef = React.createRef<EntityTableLight>();

    /**
    * By default, a new levelDefinition is added at the end of the table. We will extract new
    * definition and will check if the percentage is in the correct form. After this, the percentage
    * of the current definition will be compared with the left and right neighbours (given by left and
    * right index). 
    * 
    * New element will be inserted and the scale (A, B, C etc.) is changed for the rest of the vector
    * (we use splice to separate the vector into two parts and insert the new element).
    * 
    * NOTE: In the database, scale is stored as integer, but we must show it
    * as a character from the alphabet. We add 64 to obtain ASCII code of a character
    */
    onAddInLevelDefinitionTable(leftIndex: number, rightIndex: number, entities: any[]) {
        let startIndex = -1;
        let entity = entities.pop();
        if (entity.percentage > 100 || entity.percentage < 1 || entity.percentage === undefined) {
            this.props.dispatchers.setInReduxState({ openLevelDefinitionErrorModal: true });
            return;
        }
        if (entity.percentage < entities[leftIndex].percentage) {
            entity.scale = entities[leftIndex].scale;
            startIndex = leftIndex + 1;
            entities.splice(leftIndex, 0, entity);
        } else if (entity.percentage > entities[rightIndex - 1].percentage) {
            entity.scale = String.fromCharCode(64 + rightIndex + 1);
            startIndex = rightIndex + 1;
            entities.splice(rightIndex, 0, entity);
        } else {
            entity.scale = entities[rightIndex - 1].scale;
            startIndex = rightIndex;
            entities.splice(rightIndex - 1, 0, entity);
        }
        for (let i = startIndex; i < entities.length; i++) {
            entities[i].scale = String.fromCharCode(64 + i + 1);
        }
        this.props.dispatchers.setInReduxState({
            enableSaveButton: true,
            createNewMajorVersion: true
        });
        this.props.dispatchers.setInReduxState({ levelDefinitions: entities });
        this.levelDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(entities);
    }

    /*
    * We need to update scale for levelDefinitions before assign them to the variable from
    * table because we can have a case where we have 3 definitions A, B, C and we delete B and 
    * the result is A, C. We iterate through vector and we change scale for all elements.
    */
    onDeleteLevelDefinition(entities: any, removedEntityIndex: number) {
        let newEntities = _.cloneDeep(entities);
        this.props.dispatchers.setInReduxState({
            enableSaveButton: true,
            createNewMajorVersion: true
        });
        for (let i = removedEntityIndex; i < newEntities.length; i++) {
            newEntities[i].scale = String.fromCharCode(64 + i + 1);
        }
        this.props.dispatchers.setInReduxState({ levelDefinitions: newEntities });
        this.levelDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(newEntities);
    }

    updateStateProperties(competence: any, isCompetence: boolean, lastComponentSelectedId?: string[]) {
        this.props.dispatchers.loadRightPanelDetails({ competence, isCompetence, lastComponentSelectedId });

        const levelDefinitions = this.mapLevelDefinitions(competence, isCompetence);
        this.props.dispatchers.setInReduxState({ levelDefinitions });
        this.levelDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(levelDefinitions);

        competence = isCompetence ? competence.currentCompetenceVersion : competence;
        if (competence.trainingComponentVersions) {
            const educationComponents = this.mapEducationComponents(competence.trainingComponentVersions);
            this.props.dispatchers.setInReduxState({ educationComponents });
            this.educationComponentsRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(educationComponents);
        }
    }

    componentDidMount() {
        this.props.dispatchers.getCompetences();
    }

    componentDidUpdate() {
        if (this.levelDefinitionTableRef.current && this.props.tabChanged) {
            this.levelDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(this.props.levelDefinitions);
        }

        if (this.educationComponentsRef.current && this.props.tabChanged) {
            this.educationComponentsRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(this.props.educationComponents);
        }

        this.props.dispatchers.setInReduxState({ tabChanged: false });
    }

    mapLevelDefinitions(competence: any, isCompetence: boolean) {
        let levelDefinitions = [];
        competence = isCompetence ? competence.currentCompetenceVersion : competence;
        for (let i = 0; i < competence.levelDefinitions.length; i++) {
            levelDefinitions.push({
                scale: String.fromCharCode(competence.levelDefinitions[i].scale + 64),
                percentage: competence.levelDefinitions[i].percentage,
                description: competence.levelDefinitions[i].description,
            });
        }
        return levelDefinitions;
    }

    mapEducationComponents(trainingComponentVersions: any[]) {
        let educationComponents = [];
        if (trainingComponentVersions) {
            for (let i = 0; i < trainingComponentVersions.length; i++) {
                if (educationComponents.length - 1 >= 0 && educationComponents[educationComponents.length - 1].educationComponent.component.id ===
                    trainingComponentVersions[i].component.id) {
                    educationComponents.pop();
                }
                educationComponents.push({
                    educationComponent: trainingComponentVersions[i],
                });
            }
        }
        return educationComponents;
    }

    shouldComponentUpdate(nextProps: Readonly<PropsFrom<SliceCompetences>>, nextState: Readonly<{}>, nextContext: any): boolean {
        return !(_.isEqual(this.props, nextProps));
    }

    getCompetence = async (id: number, parentId: string[]) => {
        let competence = (await apolloClient.query({ query: TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE, variables: { competenceId: id } })).data.trainingServiceEndpoint_competence;

        this.updateStateProperties(competence, true, parentId);
        this.props.dispatchers.setInReduxState({ activeIndex: 0 });
    };

    getCompetenceVersion = async (id: number) => {
        let competenceVersion = (await apolloClient.query({ query: TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE_VERSION, variables: { versionId: id } })).data.trainingServiceEndpoint_competenceVersion;

        this.updateStateProperties(competenceVersion, false);
    };

    protected saveCompetenceVersion = async () => {
        let competenceVersion = _.cloneDeep(this.props.competence as unknown as CompetenceVersion);

        if (this.props.active === competenceVersion.active) {
            this.props.dispatchers.setInReduxState({ enableSaveButton: false });
            return;
        }

        // If is a competence version, we should update only active field
        competenceVersion.active = this.props.active;

        await apolloClient.mutate({
            mutation: TRAINING_SERVICE_ENDPOINT_SAVE_COMPETENCE_VERSION, variables: {
                competenceVersion
            }
        });

        this.props.dispatchers.setInReduxState({ enableSaveButton: false });

        this.getCompetenceVersion(competenceVersion.id);
    };

    protected saveCompetence = async () => {
        if (!this.props.isCompetence) {
            this.saveCompetenceVersion();
            return;
        }

        let competence: any;
        let addCompetence = false;

        // Change scale into number (in client side it is used as string)
        let entities: LevelDefinition[] = [];
        let levelDefinitionTableEntities = this.levelDefinitionTableRef.current ? this.levelDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities() : this.props.levelDefinitions;
        if (!levelDefinitionTableEntities) {
            levelDefinitionTableEntities = [];
        }
        for (let i = 0; i < levelDefinitionTableEntities.length; i++) {
            entities.push({
                description: levelDefinitionTableEntities[i].description,
                percentage: levelDefinitionTableEntities[i].percentage,
                scale: i + 1,
            });
        }

        if (this.props.competence !== undefined) {
            competence = _.cloneDeep(this.props.competence);
            competence.currentCompetenceVersion.name = this.props.name;
            competence.currentCompetenceVersion.description = this.props.description;
            competence.currentCompetenceVersion.active = this.props.active;
            competence.currentCompetenceVersion.levelDefinitions = entities;
            competence.currentCompetenceVersion.trainingComponentVersions = this.props.educations;
            competence.currentCompetenceVersion.attachments = this.props.attachments;
        } else {
            let { competences, categoryChildren, ...category } = this.props.lastComponentSelected;
            competence = {
                currentCompetenceVersion: {
                    name: this.props.name,
                    description: this.props.description,
                    active: this.props.active,
                    levelDefinitions: entities,
                    attachments: this.props.attachments,
                    trainingComponentVersions: this.props.educations,
                    majorVersion: 1,
                    minorVersion: 0,
                },
                category: category
            };
            addCompetence = true;
        }

        const param: FullCompetenceInput = {
            competence: competence,
            createNewMajorVersion: this.props.createNewMajorVersion,
            addedComponents: this.props.addedComponents,
            removedComponents: this.props.removedComponents
        };

        competence = (await apolloClient.mutate({
            mutation: TRAINING_SERVICE_ENDPOINT_SAVE_COMPETENCE,
            variables: { param }
        })).data.trainingServiceEndpoint_saveCompetence;
        this.props.dispatchers.setInReduxState({
            majorVersion: competence.currentCompetenceVersion.majorVersion,
            minorVersion: competence.currentCompetenceVersion.minorVersion,
            competence: competence,
            addedComponents: [],
            removedComponents: [],
            isCompetence: true,
            enableSaveButton: false,
            createNewMajorVersion: false
        });

        let educations = [];
        for (let i = 0; i < competence.currentCompetenceVersion.trainingComponentVersions.length; i++) {
            educations.push({
                educationComponent: competence.currentCompetenceVersion.trainingComponentVersions[i]
            });
        }

        this.educationComponentsRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(educations);

        if (addCompetence === true) {
            this.props.dispatchers.updateTree(this.props.lastComponentSelectedId, Action.ADD_COMPETENCE,
                competence);
        }
        this.props.dispatchers.updateTree(this.props.lastComponentSelectedId, Action.UPDATE_COMPETENCE);
        this.updateStateProperties(competence, true, this.props.lastComponentSelectedId);
    };

    protected insertNewLevelDefinition(entities: any, addedEntity: any, leftIndex: number, rightIndex: number, firstIteration: boolean = true) {
        if (entities.length === 1) {
            let entity = { ...addedEntity };
            if (entity.percentage > 100 || entity.percentage < 1 || entity.percentage === undefined) {
                this.props.dispatchers.setInReduxState({ openLevelDefinitionErrorModal: true });
                this.levelDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                return;
            }
            entity.scale = String.fromCharCode(65);
            this.levelDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([entity]);
            this.props.dispatchers.setInReduxState({
                enableSaveButton: true,
                createNewMajorVersion: true
            });
            this.props.dispatchers.setInReduxState({ levelDefinitions: [entity] })
            return;
        } else {
            entities = Object.assign([], entities);
            if (addedEntity.scale && firstIteration) {
                // it means it was edited
                let editedLevelDefinitionIndex;
                for (let i = 0; i < entities.length; i++) {
                    if (entities[i].scale === addedEntity.scale && entities[i].description === addedEntity.description &&
                        entities[i].percentage === addedEntity.percentage) {
                            editedLevelDefinitionIndex = i;
                            break;
                        }
                }
                addedEntity = _.clone(addedEntity);
                delete addedEntity['scale'];
                entities.splice(editedLevelDefinitionIndex, 1);
                for (let i = 0; i < entities.length; i++) {
                    entities[i] = _.clone(entities[i]);
                    entities[i].scale = String.fromCharCode(64 + i + 1);
                }
                entities.push(addedEntity);
            } else {
                for (let i = 0; i < entities.length; i++) {
                    entities[i] = _.clone(entities[i]);
                }
            }
            if (rightIndex - leftIndex === 1) {
                this.onAddInLevelDefinitionTable(leftIndex, rightIndex, entities);
            } else {
                let midIndex = Math.floor((rightIndex - leftIndex) / 2) + leftIndex;
                if (addedEntity.percentage < entities[midIndex].percentage) {
                    this.insertNewLevelDefinition(entities, addedEntity, leftIndex, midIndex, false);
                } else {
                    this.insertNewLevelDefinition(entities, addedEntity, midIndex, rightIndex, false);
                }
            }
        }
    }

    protected renderLevelDefinitionErrorModal() {
        return (
            <ModalExt
                severity={Severity.ERROR}
                open={this.props.openLevelDefinitionErrorModal}
                onClose={() => this.props.dispatchers.setInReduxState({ openLevelDefinitionErrorModal: false })}
                header={_msg("LevelDefinitionEditor.errorModal.header.label")}
                content={_msg("LevelDefinitionEditor.errorModal.message.label")}
                actions={[
                    <Button primary onClick={() => this.props.dispatchers.setInReduxState({ openLevelDefinitionErrorModal: false })}>
                        {_msg("general.ok")}
                    </Button>
                ]} />
        );
    }

    activateDeleteModal = (deletedEntityId: number, lastComponentSelectedId: string[], message: string) => {
        this.props.dispatchers.setInReduxState({
            openDeleteModal: true,
            deleteModalMessage: message,
            deletedEntityId: deletedEntityId,
            lastComponentSelectedId: lastComponentSelectedId,
        });
    };

    protected renderDeleteModal() {
        return (
            <ModalExt
                severity={Severity.CONFIRMATION}
                closeIcon
                open={this.props.openDeleteModal}
                onClose={() => this.props.dispatchers.setInReduxState({ openDeleteModal: false })}
            >
                <Modal.Header>{_msg("general.remove")}</Modal.Header>
                <Modal.Content><p>{this.props.deleteModalMessage}</p></Modal.Content>
                <Modal.Actions>
                    <Button content={_msg("general.yes")} onClick={async () => {
                        this.props.dispatchers.setInReduxState({ openDeleteModal: false });
                        if (this.props.deleteModalMessage === _msg("Competences.delete.message.competence")) {
                            await apolloClient.mutate({
                                mutation: TRAINING_SERVICE_ENDPOINT_REMOVE_COMPETENCE,
                                variables: { competenceId: this.props.deletedEntityId }
                            });
                            this.props.dispatchers.updateTree(this.props.lastComponentSelectedId, Action.DELETE_COMPETENCE);
                        } else if (this.props.deleteModalMessage === _msg("Competences.delete.message.competenceVersion")) {
                            await apolloClient.mutate({
                                mutation: TRAINING_SERVICE_ENDPOINT_REMOVE_COMPETENCE_VERSION,
                                variables: { competenceVersionId: this.props.deletedEntityId }
                            });

                            this.props.dispatchers.updateTree(this.props.lastComponentSelectedId, Action.NO_ACTION);
                        } else if (this.props.deleteModalMessage === _msg("Competences.delete.message.competenceCategory")) {
                            await apolloClient.mutate({
                                mutation: TRAINING_SERVICE_ENDPOINT_REMOVE_COMPETENCE_CATEGORY,
                                variables: { categoryId: this.props.deletedEntityId }
                            });

                            this.props.dispatchers.updateTree(this.props.lastComponentSelectedId, Action.DELETE_COMPETENCE_CATEGORY);
                        }
                        this.props.dispatchers.setInReduxState({ renderCompetenceEditor: false });
                    }} />
                    <Button
                        content={_msg("general.no")}
                        onClick={() => this.props.dispatchers.setInReduxState({ openDeleteModal: false })} />
                </Modal.Actions>
            </ModalExt>
        );
    }

    protected changeField = (value: any, label: any) => {
        if (label === "") {
            return;
        }
        if (!this.props.enableSaveButton) {
            this.props.dispatchers.setInReduxState({ [label]: value, enableSaveButton: true });
            return;
        }
        this.props.dispatchers.setInReduxState({ [label]: value });
    };

    protected renderFormInput(fieldName: string, value: any, field: string, disabled?: boolean) {
        return <Form.Input className="Competences_generalField" disabled={disabled} label={_msg(fieldName)} value={value}
            onChange={(e, data) => this.changeField(data.value, field)}>
        </Form.Input>;
    }

    protected onRemoveFile = (file: UploadFile) => {
        const remainingFiles = this.props.attachments.filter((item) =>
            item.id !== Number(file.uid));
        this.props.dispatchers.setInReduxState({
            attachments: remainingFiles,
            enableSaveButton: true
        });
    };

    protected renderAttachementsTab() {
        return <Tab.Pane className="Competences flex flex-grow">
            <RegistrationAndEducationFileUploadButton {...this.props.attachmentUpload} dispatchers={this.props.dispatchers.attachmentUpload}
                attachments={this.props.attachments} class={CLASS} onRemoveFile={this.onRemoveFile} allowEdit={true} type={COMPETENCE}
                setAttachments={(attachments) => {
                    this.props.dispatchers.setInReduxState({
                        attachments: attachments,
                        enableSaveButton: true
                    });
                }} />
        </Tab.Pane>;
    }

    protected renderGeneralTab() {
        return <Tab.Pane className="Competences flex flex-grow">
            <Form>
                {this.renderFormInput("Competences.general.name.label", this.props.name, "name", !this.props.isCompetence)}
                {this.renderFormInput("Competences.general.version.label", this.props.majorVersion + "." + this.props.minorVersion, "", true)}
                <label className="Competences_activeCheckbox">{_msg("Competences.general.active.label")}</label>
                <Form.Checkbox toggle checked={this.props.active} onChange={(e, data) => this.props.dispatchers.setInReduxState({
                    active: !this.props.active,
                    enableSaveButton: true
                })} />
                <Form.TextArea label={_msg("Competences.general.description.label")} value={this.props.description}
                    onChange={(e, data) => this.changeField(data.value, "description")} disabled={!this.props.isCompetence}>
                </Form.TextArea>
            </Form>
        </Tab.Pane>;
    }

    protected renderLevelDefinitionsTab() {
        return <Tab.Pane className={`Competences_tableContainer flex flex-grow ${!this.props.isCompetence ? 'Competences_disabled' : ''}`}>
            {this.renderLevelDefinitionErrorModal()}
            <EntityTableLight {...this.props.levelDefinitionTableLight} useDefaultColumnsWidths
                dispatchers={this.props.dispatchers.levelDefinitionTableLight}
                ref={this.levelDefinitionTableRef}
                entityDescriptor={levelDefinitionDescriptor}
                getConditionForShowingDelete={(entity) => {
                    return this.props.isCompetence ? true : false;
                }}
                onSave={(entities: any, addedEntity: any) => {
                    this.insertNewLevelDefinition(entities, addedEntity.values, 0, entities.length - 1);
                }}
                onDelete={(entities, removedEntityIndex) => {
                    this.onDeleteLevelDefinition(entities, removedEntityIndex);
                }}
                actions={{
                    showEditButton: this.props.isCompetence ? true : false,
                    disableAddButton: this.props.isCompetence ? false : true
                }}
                formCustomizer={{
                    customEntityDescriptorForEditor: levelDefinitionEditorDescriptor,
                    headerContent: _msg("LevelDefinitionEditor.header.label")
                }}
            />
        </Tab.Pane >;
    }

    activateContextMenuModal = (coordinates: [number, number], openOptionsModalForCompetence: boolean,
        lastComponentSelected: any, lastComponentSelectedId: string[], deleteMessage: string) => {
        this.props.dispatchers.setInReduxState({
            openContextMenuModal: coordinates,
            lastComponentSelected: lastComponentSelected,
            lastComponentSelectedId: lastComponentSelectedId,
            openOptionsModalForCompetence: openOptionsModalForCompetence,
            deleteMessage: deleteMessage,
            newParentCompetenceCategory: undefined,
            newParentId: undefined
        });
    };

    changeEditorState = (value: any, label: any) => {
        this.props.dispatchers.setInReduxState({ [label]: value });
    };

    changeNewParentId = (parentId: string[], newParentCompetenceCategory: any) => {
        this.props.dispatchers.setInReduxState({
            newParentId: parentId,
            newParentCompetenceCategory: newParentCompetenceCategory
        });
    };

    protected onEditCompetenceCategory() {
        this.props.dispatchers.setInReduxState({
            editorCompetenceCategoryName: this.props.lastComponentSelected.name,
            editorCompetenceCategoryDescription: this.props.lastComponentSelected.description,
            renderCompetenceEditor: false
        });

        let competenceCategoryParentId = _.cloneDeep(this.props.lastComponentSelectedId);
        competenceCategoryParentId.splice(competenceCategoryParentId.length - 1, 1);

        this.refEditorCategory.current?.open(
            {
                competenceCategory: {
                    name: this.props.lastComponentSelected.name,
                    description: this.props.lastComponentSelected.description,
                    competenceCategoryParentId,
                    parentName: !this.props.lastComponentSelected.parentCategory ?
                        _msg("CompetenceCategoryEditor.root.label") :
                        this.props.lastComponentSelected.parentCategory.name,
                },
                changeEditorState: this.changeEditorState,
                changeParentId: this.changeNewParentId,
                categoryToBeHidden: this.props.lastComponentSelectedId[this.props.lastComponentSelectedId.length - 1],
                treeRoot: this.props.root.filter((value: any, index: any) => value.id !== Number(this.props.lastComponentSelectedId[this.props.lastComponentSelectedId.length - 1])),
            },
            this.props.lastComponentSelected.id
        );
    }

    protected onAddCompetenceCategory() {
        this.props.dispatchers.setInReduxState({
            editorCompetenceCategoryName: "",
            editorCompetenceCategoryDescription: "",
            renderCompetenceEditor: false
        });

        this.refAddCategory.current?.open(
            {
                competenceCategory: {
                    name: "",
                    description: "",
                    competenceCategoryParentId: this.props.lastComponentSelectedId,
                    parentName: this.props.lastComponentSelected.name,
                },
                changeEditorState: this.changeEditorState,
                changeParentId: this.changeNewParentId,
                treeRoot: this.props.root,
            },
            this.props.lastComponentSelected.id
        );
    }

    protected renderContextMenuModal() {
        return <>
            <ModalExt open={this.props.openContextMenuModal}
                onClick={() => this.props.dispatchers.setInReduxState({ openContextMenuModal: false })}
                onClose={() => this.props.dispatchers.setInReduxState({ openContextMenuModal: false })}
                className="Competences_modal"
            >
                <Menu vertical>
                    {!this.props.openOptionsModalForCompetence && <>
                        <Menu.Item
                            name="categorie"
                            content={_msg("Category.label")}
                            icon="add"
                            onClick={() => this.onAddCompetenceCategory()} />
                        <Menu.Item
                            name="competence"
                            content={_msg("Competence.label")}
                            icon="add"
                            onClick={() => {
                                this.levelDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                                this.educationComponentsRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                                this.props.dispatchers.setInReduxState({ levelDefinitions: [], educationComponents: [], activeIndex: 0 });
                                this.props.dispatchers.loadRightPanelDetails({ competence: undefined, isCompetence: true });
                            }} />
                        <Menu.Item
                            name="edit"
                            content={_msg("general.edit")}
                            icon="edit"
                            onClick={() => this.onEditCompetenceCategory()} />
                    </>}
                    <Menu.Item
                        name="delete"
                        content={_msg("general.delete")}
                        icon="delete"
                        onClick={() => {
                            this.activateDeleteModal(this.props.lastComponentSelected.id,
                                this.props.lastComponentSelectedId, this.props.deleteMessage);
                        }
                        } />
                </Menu>
            </ModalExt>
            <EntityFormLight ref={this.refEditorCategory}
                id="categoryEditor" entityDescriptor={competenceCategoryDescriptor}
                headerContent={_msg("CompetenceCategoryEditor.editHeader.label")}
                onSubmit={async () => {
                    this.props.dispatchers.onEditCompetenceCategory();
                }} />
            <EntityFormLight ref={this.refAddCategory}
                id="categoryAdd" entityDescriptor={competenceCategoryDescriptor}
                headerContent={_msg("CompetenceCategoryEditor.addHeader.label")}
                onSubmit={() => this.props.dispatchers.onSubmitNewCompetenceCategory({
                    name: this.props.editorCompetenceCategoryName,
                    description: this.props.editorCompetenceCategoryDescription
                })} />
        </>;

    }

    protected onDeleteEducation(remainingEntities: any, removedEntityIndex: number) {
        let removedEducation = this.educationComponentsRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[removedEntityIndex].educationComponent;
        let educations = _.cloneDeep(this.props.educations);
        educations = educations.filter((education) => education.component.id !== removedEducation.component.id);

        let removedComponents = _.cloneDeep(this.props.removedComponents);
        removedComponents.push(removedEducation.component.id);

        this.props.dispatchers.setInReduxState({
            removedComponents,
            educations,
            enableSaveButton: true,
            createNewMajorVersion: true,
            educationComponents: this.mapEducationComponents(educations)
        });
    }

    protected onAddEducation(entities: any, addedEntity: any) {
        if (_.isEmpty(addedEntity.values)) {
            let entitiesFromTable = _.cloneDeep(this.educationComponentsRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities());
            if (!entitiesFromTable) {
                entitiesFromTable = [];
            }
            entitiesFromTable.pop();
            this.educationComponentsRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(entitiesFromTable);
            return;
        }

        let educations = _.cloneDeep(this.props.educations);
        educations.push(addedEntity.values.educationComponent);

        let entitiesFromTable = _.cloneDeep(this.educationComponentsRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities());
        if (!entitiesFromTable) {
            entitiesFromTable = [];
        }
        let filteredEntitiesFromTable = entitiesFromTable.filter((entity) => entity.educationComponent.component.id === addedEntity.values.educationComponent.component.id);

        if (filteredEntitiesFromTable.length > 1) {
            entitiesFromTable.pop();
            this.educationComponentsRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(entitiesFromTable);
            return;
        }

        let addedComponents = _.cloneDeep(this.props.addedComponents);
        addedComponents.push(addedEntity.values.educationComponent.component.id);

        this.props.dispatchers.setInReduxState({
            addedComponents,
            educations,
            enableSaveButton: true,
            createNewMajorVersion: true,
            educationComponents: this.mapEducationComponents(educations)
        });
    }

    protected renderEducationsTab() {
        return <Tab.Pane className={`Competences_tableContainer flex flex-grow ${!this.props.isCompetence ? 'Competences_disabled' : ''}`}>
            <EntityTableLight {...this.props.educationComponentsLight}
                dispatchers={this.props.dispatchers.educationComponentsLight}
                ref={this.educationComponentsRef}
                entityDescriptor={educationsDescriptor}
                getConditionForShowingDelete={(entity) => {
                    return this.props.isCompetence ? true : false;
                }}
                formCustomizer={{
                    headerContent: _msg("EducationsEditor.label")
                }}
                onSave={(entities, addedEntity) => {
                    this.onAddEducation(entities, addedEntity);
                }}
                onDelete={(entities, index) => this.onDeleteEducation(entities, index)}
                actions={{
                    showEditButton: false,
                    disableAddButton: this.props.isCompetence ? false : true
                }}
            />
        </Tab.Pane>;
    }

    protected renderTabPanes() {
        let tabPanes = [];
        const menuItems = [
            _msg("Competences.general"),
            <MenuItem key="levelDefinitions">
                {_msg("Competences.levelDefinitions")}
                <Label>{this.props.levelDefinitions.length}</Label>
            </MenuItem>,
            <MenuItem key="educations">
                {_msg("Competences.educations")}
                <Label>{this.props.educationComponents.length}</Label>
            </MenuItem>,
            <MenuItem key="attachements">
                {_msg("Competences.attachements")}
                <Label>{this.props.attachments.length}</Label>
            </MenuItem>
        ];
        const renderFunctions = [
            () => this.renderGeneralTab(),
            () => this.renderLevelDefinitionsTab(),
            () => this.renderEducationsTab(),
            () => this.renderAttachementsTab()
        ];
        for (let i = 0; i < menuItems.length; i++) {
            tabPanes.push({
                menuItem: menuItems[i],
                render: renderFunctions[i]
            });
        }
        return tabPanes;
    }

    protected loadVersions = (params: OnSelectParams) => {
        const objectId = params.itemId.split(Utils.defaultIdSeparator);
        this.props.dispatchers.updateTree(objectId, Action.NO_ACTION);
    };

    protected renderMain() {
        return (
            <div className="Competences flex">
                {this.renderDeleteModal()}
                {this.renderContextMenuModal()}
                <SplitPaneExt defaultSize="30%">
                    {this.props.renderTree ?
                        <div className="flex flex-grow">
                            <h2 className="Competences_title">{_msg("Competences")}</h2>
                            <Divider style={{margin: '5px 0'}}/>
                                <Form>
                                    <div className="flex" style={{ flexDirection: "row", flexWrap: "wrap" }}>
                                        <span className="flex-grow" style={{ minWidth: "200px", paddingRight: "10px" }}>
                                            <Form.Input placeholder={_msg("Competences.search")} icon="search"
                                                onChange={(e, data) => this.props.dispatchers.setInReduxState({ searchExpression: data.value })} />
                                        </span>
                                        <span className="flex-row">
                                            <span style={{ display: "flex", alignItems: "center", paddingRight: "10px" }}>
                                                <Form.Checkbox label={_msg("Competences.general.active.label")}
                                                    onChange={(e, data) => this.props.dispatchers.setInReduxState({ activeFilter: !this.props.activeFilter })} />
                                            </span>
                                            <span style={{ display: "flex", alignItems: "center" }}><Form.Checkbox label={_msg("Competences.general.notActive.label")}
                                                onChange={(e, data) => this.props.dispatchers.setInReduxState({ notActiveFilter: !this.props.notActiveFilter })} />
                                            </span>
                                        </span>
                                    </div>
                                </Form>
                            <div className="flex flex-grow">
                                <CompetenceTreeRRC onSelectCompetence={this.getCompetence}
                                    onSelectVersion={this.getCompetenceVersion} activateDeleteModal={this.activateDeleteModal}
                                    openContextMenuModal={this.activateContextMenuModal} onExpandCollapseItem={this.loadVersions}
                                    onSelectCategory={() => { }} active={this.props.activeFilter}
                                    notActive={this.props.notActiveFilter}
                                    id="CompetenceTreeRRC" root={this.props.root}
                                    searchExpression={this.props.searchExpression} />
                            </div>
                        </div> :
                        null}
                    {this.props.renderCompetenceEditor
                        ? <div className="Competences_rightPanel flex">
                            <Segment>
                                <p className="Competences_name" >{this.props.name}
                                    <Button primary disabled={!this.props.enableSaveButton}
                                        onClick={this.saveCompetence}
                                        content={_msg("general.save")} />
                                </p>
                            </Segment>
                            <Tab className="Competences_details flex" activeIndex={this.props.activeIndex} onTabChange={(e, { activeIndex }) => this.onTabChange(activeIndex)} panes={this.renderTabPanes()} />
                        </div>
                        : this.props.renderTree ? <div className="Competences_noCompetence flex">
                            {_msg("Competences.noCompetence")}
                        </div> : null}
                </SplitPaneExt>
            </div>
        );
    }

    onTabChange(activeIndex: number | string | undefined) {
        this.props.dispatchers.setInReduxState({ tabChanged: true, activeIndex: activeIndex });

        if (this.levelDefinitionTableRef.current) {
            this.props.dispatchers.setInReduxState({ levelDefinitions: this.levelDefinitionTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities() });
        }

        if (this.educationComponentsRef.current) {
            this.props.dispatchers.setInReduxState({ educationComponents: this.educationComponentsRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities() });
        }
    }
}

export const competences = new ConnectedPageInfo(sliceCompetences, SliceCompetences, "Competences");
competences.routeProps = { permission: "EDUCATION_VIEW_WEB_DICTIONARY" };