/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AggregateFunctionType {
  AVG = "AVG",
  COUNT = "COUNT",
  MAX = "MAX",
  MIN = "MIN",
  SUM = "SUM",
}

export enum FreeTimeStatusEnum {
  ABSENT = "ABSENT",
  FREE = "FREE",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum VariableInstanceType {
  ACCEPT_REFUSAL = "ACCEPT_REFUSAL",
  DELETE = "DELETE",
}

export enum WaitingRegistrationStateEnum {
  HAS_WAITING = "HAS_WAITING",
  WAITING_RESOLVED = "WAITING_RESOLVED",
}

export interface AbstractDataSourceInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  type?: ReportDataSourceTypeInput | null;
}

export interface AbstractDateCriteriaInput {
  dummyField?: string | null;
}

export interface AbstractRecurrencePatternInput {
  creationDate?: any | null;
  creationUser?: string | null;
  definition?: RecurrenceDefinitionInput | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface AbstractRecurrenceRangeInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  startDate?: any | null;
}

export interface AccountHistoryItemInput {
  country?: CountryInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  institution?: FinancialInstitutionInput | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  number?: string | null;
  objectVersion?: number | null;
  type?: AccountTypeInput | null;
  usage?: AccountUsageInput | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface AccountInput {
  creationDate?: any | null;
  creationUser?: string | null;
  environment?: EnvironmentInput | null;
  failedAttemptsNumber?: number | null;
  id?: number | null;
  isGeneratedPassword?: boolean | null;
  lastPasswordInvalidDate?: any | null;
  lastPasswordUpdateDate?: any | null;
  locked?: boolean | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  password?: string | null;
  passwordHistory?: (PasswordHistoryItemInput | null)[] | null;
  person?: number | null;
  userName?: string | null;
}

export interface AccountTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface AccountUsageInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface AddressHistoryItemInput {
  address?: AddressInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface AddressInput {
  box?: string | null;
  city?: CityInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  houseNumber?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  street?: string | null;
  type?: AddressTypeInput | null;
}

export interface AddressTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface AggregateFunctionInput {
  field?: string | null;
  type?: AggregateFunctionType | null;
}

export interface BackgroundTaskInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  inputParameters?: (InputParameterInput | null)[] | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  outputFiles?: (OutputFileInput | null)[] | null;
  owner?: PersonInput | null;
  processingEndDate?: any | null;
  processingStartDate?: any | null;
  status?: string | null;
  type?: string | null;
  visibility?: string | null;
  visibilityGroups?: (VisibilityGroupInput | null)[] | null;
}

export interface BalanceCounterHistoryItemInput {
  balanceType?: string | null;
  counterType?: CounterTypeInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  person?: number | null;
  remark?: string | null;
  validFrom?: any | null;
  validUntil?: any | null;
  value?: number | null;
  workPeriodType?: string | null;
}

export interface CalculationInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  persist?: boolean | null;
}

export interface CalendarDefinitionInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  extendDefinitions?: (CalendarDefinitionInput | null)[] | null;
  id?: number | null;
  items?: (CalendarItemInput | null)[] | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  properties?: (CalendarPropertyInput | null)[] | null;
}

export interface CalendarItemInput {
  allDay?: boolean | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  endDate?: any | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  recurrenceDefinition?: RecurrenceDefinitionInput | null;
  recurrenceDefinitionDto?: RecurrenceDefinitionDtoInput | null;
  recurring?: boolean | null;
  startDate?: any | null;
  subject?: string | null;
}

export interface CalendarPropertyInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  item?: CalendarItemInput | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  value?: string | null;
}

export interface CardInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  number?: string | null;
  objectVersion?: number | null;
  type?: CardTypeInput | null;
  validUntil?: any | null;
}

export interface CardTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface CatalogAttachmentInput {
  classType?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  fileName?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
}

export interface CatalogSnapshotInput {
  active?: boolean | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
}

export interface CategoryHistoryItemInput {
  category?: CategoryInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface CategoryInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
}

export interface ChildAllowanceFundInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface CityInput {
  code?: string | null;
  country?: CountryInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  structName?: string | null;
}

export interface CivilStatusHistoryItemInput {
  civilStatus?: CivilStatusInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  dateOfBirthPartner?: any | null;
  firstNamePartner?: string | null;
  id?: number | null;
  incomeForm?: IncomeFormInput | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  namePartner?: string | null;
  objectVersion?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface CivilStatusInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface CompanyHistoryItemInput {
  company?: CompanyInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface CompanyInput {
  addressHistory?: (AddressHistoryItemInput | null)[] | null;
  attachment?: ResourceAttachmentInput | null;
  businessHours?: string | null;
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  employersCode?: string | null;
  environment?: EnvironmentInput | null;
  externId?: number | null;
  id?: number | null;
  internet?: string | null;
  legalForm?: LegalFormInput | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
  parent?: CompanyInput | null;
  remarks?: string | null;
  telecomList?: (TelecomInput | null)[] | null;
  types?: (CompanyTypeInput | null)[] | null;
  vatNumber?: string | null;
}

export interface CompanyTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface CompetenceAttachmentInput {
  classType?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  fileName?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
}

export interface CompetenceCatalogSnapshotInput {
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
}

export interface CompetenceCategoryInput {
  catalog?: CompetenceCatalogSnapshotInput | null;
  categoryChildren?: (CompetenceCategoryInput | null)[] | null;
  competences?: (CompetenceInput | null)[] | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  parentCategory?: CompetenceCategoryInput | null;
}

export interface CompetenceCategorySnapshotInput {
  catalog?: CompetenceCatalogSnapshotInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  parentCategory?: CompetenceCategorySnapshotInput | null;
}

export interface CompetenceInput {
  category?: CompetenceCategorySnapshotInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  currentCompetenceVersion?: CompetenceVersionInput | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface CompetenceVersionInput {
  active?: boolean | null;
  attachments?: (CompetenceAttachmentInput | null)[] | null;
  competence?: CompetenceInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  levelDefinitions?: (LevelDefinitionInput | null)[] | null;
  majorVersion: number;
  minorVersion: number;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  trainingComponentVersions?: (EducationComponentVersionInput | null)[] | null;
  version?: any | null;
}

export interface ComponentInput {
  catalog?: CatalogSnapshotInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  currentComponentVersion?: ComponentVersionInput | null;
  externalId?: number | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
  type?: ComponentTypeInput | null;
}

export interface ComponentTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface ComponentVersionInput {
  active?: boolean | null;
  attachments?: (CatalogAttachmentInput | null)[] | null;
  component?: ComponentInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  majorVersion: number;
  minorVersion: number;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
  version?: any | null;
}

export interface CompositionGroupInput {
  code?: string | null;
  constraintDefinitions?: (ConstraintDefinitionInput | null)[] | null;
  contexts?: (GroupContextInput | null)[] | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  groups?: (GroupInput | null)[] | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  owner?: number | null;
  properties?: (GroupPropertyInput | null)[] | null;
  securityDefinition?: SecurityDefinitionInput | null;
  sequenceDefinition?: SequenceDefinitionInput | null;
  successionDefinition?: SuccessionDefinitionInput | null;
}

export interface ConstraintDefinitionInput {
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  history?: (ConstraintHistoryItemInput | null)[] | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  validForRegistrationTypes?: (string | null)[] | null;
  validOnRegistrationTypes?: (string | null)[] | null;
}

export interface ConstraintGroupHistoryItemInput {
  constraintGroup?: ConstraintGroupInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface ConstraintGroupInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface ConstraintHistoryItemInput {
  calendar?: number | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface ContractHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  employeeNumber?: string | null;
  employmentBreak?: EmploymentBreakInput | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
  reasonContractEnd?: ReasonContractEndInput | null;
  status?: ContractStatusInput | null;
  suspensionHistory?: (ContractSuspensionHistoryItemInput | null)[] | null;
  type?: ContractTypeInput | null;
  validFrom?: any | null;
  validUntil?: any | null;
  workArrangement?: WorkArrangementInput | null;
}

export interface ContractStatusInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
}

export interface ContractSuspensionHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  reasonSuspension?: ReasonContractSuspensionInput | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface ContractTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface CostCenterInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface CounterTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface CountryInput {
  autoCode?: string | null;
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  internationalPhoneCode?: string | null;
  iso3Code?: string | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  web?: string | null;
}

export interface DataTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface DayInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface DayOfWeekIndexInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface DepartmentHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  department?: DepartmentInput | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface DepartmentInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface DynamicGroupInput {
  code?: string | null;
  constraintDefinitions?: (ConstraintDefinitionInput | null)[] | null;
  contexts?: (GroupContextInput | null)[] | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  owner?: number | null;
  properties?: (GroupPropertyInput | null)[] | null;
  securityDefinition?: SecurityDefinitionInput | null;
  sequenceDefinition?: SequenceDefinitionInput | null;
  sql?: string | null;
  successionDefinition?: SuccessionDefinitionInput | null;
}

export interface EducationCardAttachmentInput {
  classType?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  fileName?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
}

export interface EducationComponentVersionInput {
  active?: boolean | null;
  attachments?: (CatalogAttachmentInput | null)[] | null;
  competenceVersions?: (CompetenceVersionInput | null)[] | null;
  component?: ComponentInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  majorVersion: number;
  minorVersion: number;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
  trainerProfileVersion?: number | null;
  version?: any | null;
}

export interface EmployeeCriteriaInput {
  avoidLoadingEmployeeDetails: boolean;
  categoryCodes?: (string | null)[] | null;
  companyCode?: FieldCriteria_StringInput | null;
  companyTypeCodes?: (string | null)[] | null;
  dateCriteria?: AbstractDateCriteriaInput | null;
  email?: string | null;
  employeeNumbers?: (string | null)[] | null;
  excludeId?: number | null;
  excludeLocationAndHourRegime?: boolean | null;
  externIds?: (number | null)[] | null;
  groupCodes?: (string | null)[] | null;
  groupContextCode?: string | null;
  groupMembershipDateCriteria?: AbstractDateCriteriaInput | null;
  hourRegimeCodes?: (string | null)[] | null;
  ids?: (number | null)[] | null;
  ignoreContract: boolean;
  includeAllWithValidContract: boolean;
  includeFunction?: boolean | null;
  includesAllRegardlessOfThecontract: boolean;
  locationCodes?: (string | null)[] | null;
  searchText?: string | null;
  teamCodes?: (string | null)[] | null;
}

export interface EmployeeDetailInput {
  accountHistory?: (AccountHistoryItemInput | null)[] | null;
  barema?: ScaleInput | null;
  birthplace?: CityInput | null;
  cards?: (CardInput | null)[] | null;
  categoryHistory?: (CategoryHistoryItemInput | null)[] | null;
  childAllowanceFund?: ChildAllowanceFundInput | null;
  childAllowanceFundNumber?: string | null;
  civilStatusHistory?: (CivilStatusHistoryItemInput | null)[] | null;
  constraintGroupHistory?: (ConstraintGroupHistoryItemInput | null)[] | null;
  contractHistory?: (ContractHistoryItemInput | null)[] | null;
  creationDate?: any | null;
  creationUser?: string | null;
  dateOfBirth?: any | null;
  dateOfDeath?: any | null;
  dimonaNumber?: string | null;
  functionHistory?: (FunctionHistoryItemInput | null)[] | null;
  healthInsurance?: HealthInsuranceInput | null;
  healthInsuranceNumber?: string | null;
  hourRegimeHistory?: (HourRegimeHistoryItemInput | null)[] | null;
  id?: number | null;
  locationHistory?: (LocationHistoryItemInput | null)[] | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  nationalRegisterNumber?: string | null;
  objectVersion?: number | null;
  person?: number | null;
  pilotNumber?: number | null;
  scaleHistory?: (ScaleHistoryItemInput | null)[] | null;
  shareRegisterHistory?: (ShareRegisterHistoryItemInput | null)[] | null;
  startDateCredit?: any | null;
  taxStatusHistory?: (TaxStatusHistoryItemInput | null)[] | null;
  teamHistory?: (TeamHistoryItemInput | null)[] | null;
  timeRegistration?: boolean | null;
}

export interface EmployeeFunctionInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  ejobType?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface EmployeeInput {
  addressHistory?: (AddressHistoryItemInput | null)[] | null;
  attachment?: ResourceAttachmentInput | null;
  companyHistory?: (CompanyHistoryItemInput | null)[] | null;
  creationDate?: any | null;
  creationUser?: string | null;
  departmentHistory?: (DepartmentHistoryItemInput | null)[] | null;
  detail?: EmployeeDetailInput | null;
  environment?: EnvironmentInput | null;
  externId?: number | null;
  firstName?: string | null;
  gender?: GenderInput | null;
  id?: number | null;
  licenseType?: LicenseTypeInput | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  nationality?: NationalityInput | null;
  nativeLanguage?: LanguageInput | null;
  objectType?: string | null;
  objectVersion?: number | null;
  remarks?: string | null;
  telecomList?: (TelecomInput | null)[] | null;
  title?: string | null;
  vocative?: VocativeInput | null;
}

export interface EmploymentBreakInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface EntityCriteriaInput {
  filters?: (FilterCriteriaInput | null)[] | null;
  firstResult: number;
  joins?: (JoinCriteriaInput | null)[] | null;
  maxResults: number;
}

export interface EnvironmentInput {
  code?: string | null;
  company?: number | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface ErrorMessagePolicyInput {
  policy?: string | null;
}

export interface EvaluationAttachmentInput {
  classType?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  fileName?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
}

export interface EvaluationCriterionVersionInput {
  competenceVersion?: CompetenceVersionInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  normPercentage?: number | null;
  objectVersion?: number | null;
  ownScore?: boolean | null;
  ratedEvaluations?: number | null;
  required?: boolean | null;
  totalScore?: number | null;
  weight?: number | null;
}

export interface EvaluationDefinitionInput {
  alsoSelfEvaluation?: boolean | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  visibleToEmployees?: boolean | null;
}

export interface EvaluationDefinitionVersionInput {
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  evaluationCriterionVersions?: (EvaluationCriterionVersionInput | null)[] | null;
  evaluationDefinition?: EvaluationDefinitionInput | null;
  id?: number | null;
  majorVersion: number;
  minorVersion: number;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  version?: any | null;
}

export interface EvaluationInput {
  attachments?: (EvaluationAttachmentInput | null)[] | null;
  averageScore?: number | null;
  creationDate?: any | null;
  creationUser?: string | null;
  date?: any | null;
  description?: string | null;
  environment?: string | null;
  evaluationDefinitionVersion?: EvaluationDefinitionVersionInput | null;
  evaluator?: PersonSnapshotInput | null;
  finished?: boolean | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  published?: boolean | null;
  realization?: RealizationInput | null;
  remark?: string | null;
  resource?: PersonSnapshotInput | null;
  scores?: (ScoreInput | null)[] | null;
  targetDate?: any | null;
  totalNorm?: number | null;
  totalScore?: number | null;
}

export interface FieldCriteria_StringInput {
  enforceNull: boolean;
  value?: string | null;
}

export interface FilterCriteriaInput {
  name?: string | null;
  sortOrder?: string | null;
  value?: any | null;
}

export interface FilterInput {
  cast?: string | null;
  field?: string | null;
  filters?: (FilterInput | null)[] | null;
  operator?: string | null;
  timezone?: string | null;
  value?: string | null;
}

export interface FinancialInstitutionInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface FindByFilterParamsInput {
  aggregateFunctions?: (AggregateFunctionInput | null)[] | null;
  bypassOrganizationFilter?: boolean | null;
  countMode?: boolean | null;
  filter?: FilterInput | null;
  pageSize: number;
  sorts?: (SortInput | null)[] | null;
  startIndex: number;
}

export interface FindByStringParamsInput {
  aggregateFunctions?: (AggregateFunctionInput | null)[] | null;
  bypassOrganizationFilter?: boolean | null;
  countMode?: boolean | null;
  filter?: FilterInput | null;
  pageSize: number;
  sorts?: (SortInput | null)[] | null;
  startIndex: number;
  string?: string | null;
}

export interface FreeTimeSetFirstEmployeeParameterInput {
  category?: string | null;
  employeeId?: number | null;
  freeTimeSuperGroupId?: number | null;
}

export interface FullCompetenceInput {
  addedComponents?: (number | null)[] | null;
  competence?: CompetenceInput | null;
  createNewMajorVersion?: boolean | null;
  removedComponents?: (number | null)[] | null;
}

export interface FunctionHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  employeeFunction?: EmployeeFunctionInput | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface GenderInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface GetResourcesParameterInput {
  companyCode?: string | null;
  companyTypeCodes?: (string | null)[] | null;
  employeesIds?: (number | null)[] | null;
  excludeResourceId?: number | null;
  fromDate?: any | null;
  groupCodes?: (string | null)[] | null;
  groupContextCode?: string | null;
  groupMembershipFromDate?: any | null;
  groupMembershipToDate?: any | null;
  includeAllWithValidContract?: boolean | null;
  includeContactPersons: boolean;
  limitResultsToCategoryOfEmployee?: number | null;
  searchText?: string | null;
  toDate?: any | null;
}

export interface GroupContextInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  groups?: (GroupSnapshotInput | null)[] | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  owner?: ResourceSnapshotInput | null;
}

export interface GroupInput {
  code?: string | null;
  contexts?: (GroupContextInput | null)[] | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  owner?: number | null;
  properties?: (GroupPropertyInput | null)[] | null;
}

export interface GroupPropertyInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  group?: number | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  value?: string | null;
}

export interface GroupRelationIdInput {
  source?: number | null;
  target?: number | null;
  type?: GroupRelationTypeInput | null;
}

export interface GroupRelationInput {
  creationDate?: any | null;
  creationUser?: string | null;
  groupRelationId?: GroupRelationIdInput | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  source?: number | null;
  target?: number | null;
  type?: GroupRelationTypeInput | null;
}

export interface GroupRelationTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  inLabel?: string | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  outLabel?: string | null;
}

export interface GroupResourcesHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  resources?: (number | null)[] | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface GroupSnapshotInput {
  code?: string | null;
  contexts?: (GroupContextInput | null)[] | null;
  contraints?: boolean | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  name?: string | null;
  owner?: ResourceSnapshotInput | null;
  relation?: boolean | null;
  resources?: (ResourceSnapshotInput | null)[] | null;
  security?: boolean | null;
  sequence?: boolean | null;
  succession?: boolean | null;
  type?: GroupTypeInput | null;
}

export interface GroupTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface HealthInsuranceInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface HourRegimeHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  hourRegime?: HourRegimeInput | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface HourRegimeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
}

export interface IncomeFormInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface InputParameterInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  label?: string | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  value?: any | null;
}

export interface IsContrainedRegistrationTypeInput {
  fromDate?: any | null;
  leaveGroups?: (GroupSnapshotInput | null)[] | null;
  registrationTypeCode?: string | null;
  toDate?: any | null;
  user?: number | null;
}

export interface JoinCriteriaInput {
  filters?: (FilterCriteriaInput | null)[] | null;
  joins?: (JoinCriteriaInput | null)[] | null;
  on?: string | null;
}

export interface LanguageInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface LeaveGroupsDataInput {
  allowNull: boolean;
  disableDropDown: boolean;
  leaveGroupSnapshotsByTimePeriod?: any | null;
  leaveGroupSnapshotsForReact?: (GroupSnapshotInput | null)[] | null;
  nonOverlappingGroups: boolean;
  preselectedGroupSnapshotCode?: string | null;
  splitDate?: any | null;
}

export interface LegalFormInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface LevelDefinitionInput {
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  percentage?: number | null;
  scale?: number | null;
}

export interface LicenseTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface LocationHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  location?: LocationInput | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface LocationInput {
  address?: AddressInput | null;
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
}

export interface MetadataContextInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: number | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
}

export interface ModuleInput {
  catalog?: CatalogSnapshotInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  currentModuleVersion?: ModuleVersionInput | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
}

export interface ModuleVersionInput {
  active?: boolean | null;
  attachments?: (CatalogAttachmentInput | null)[] | null;
  componentVersions?: (ComponentVersionInput | null)[] | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  majorVersion: number;
  minorVersion: number;
  modificationDate?: any | null;
  modificationUser?: string | null;
  module?: ModuleInput | null;
  name?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
  version?: any | null;
}

export interface MonthInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface NationalityInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface OutputFileInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
}

export interface ParameterInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  dataType?: DataTypeInput | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  parameterValueHistory?: (ParameterValueHistoryItemInput | null)[] | null;
}

export interface ParameterValueHistoryItemInput {
  bytes?: any | null;
  bytesAsString?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
  value?: any | null;
}

export interface PasswordHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  password?: string | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface PersonCriteriaInput {
  avoidLoadingEmployeeDetails: boolean;
  categoryCodes?: (string | null)[] | null;
  companyCode?: FieldCriteria_StringInput | null;
  companyTypeCodes?: (string | null)[] | null;
  dateCriteria?: AbstractDateCriteriaInput | null;
  email?: string | null;
  excludeId?: number | null;
  externIds?: (number | null)[] | null;
  groupCodes?: (string | null)[] | null;
  groupContextCode?: string | null;
  groupMembershipDateCriteria?: AbstractDateCriteriaInput | null;
  ids?: (number | null)[] | null;
  ignoreContract: boolean;
  includeAllWithValidContract: boolean;
  includesAllRegardlessOfThecontract: boolean;
  searchText?: string | null;
}

export interface PersonInput {
  addressHistory?: (AddressHistoryItemInput | null)[] | null;
  attachment?: ResourceAttachmentInput | null;
  companyHistory?: (CompanyHistoryItemInput | null)[] | null;
  creationDate?: any | null;
  creationUser?: string | null;
  departmentHistory?: (DepartmentHistoryItemInput | null)[] | null;
  environment?: EnvironmentInput | null;
  externId?: number | null;
  firstName?: string | null;
  gender?: GenderInput | null;
  id?: number | null;
  licenseType?: LicenseTypeInput | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  nationality?: NationalityInput | null;
  nativeLanguage?: LanguageInput | null;
  objectType?: string | null;
  objectVersion?: number | null;
  remarks?: string | null;
  telecomList?: (TelecomInput | null)[] | null;
  title?: string | null;
  vocative?: VocativeInput | null;
}

export interface PersonSnapshotInput {
  company?: CompanyInput | null;
  environment?: string | null;
  externId?: number | null;
  firstName?: string | null;
  id?: number | null;
  name?: string | null;
  objectType?: string | null;
  title?: string | null;
}

export interface PilotProgressCategoryInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  months?: number | null;
  objectVersion?: number | null;
  order?: number | null;
  relationType?: PilotProgressRelationInput | null;
  tasks?: number | null;
  totalTasks?: number | null;
}

export interface PilotProgressHistoryItemInput {
  category?: PilotProgressCategoryInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  extras: number;
  generated?: boolean | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  owner?: PersonInput | null;
  tasks?: number | null;
  totalTasks?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface PilotProgressRelationInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface PreferenceHolderInput {
  contextValue?: PropertyValueInput | null;
  systemValue?: PropertyValueInput | null;
  userValue?: UserValueInput | null;
}

export interface PremiumHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  record?: PremiumRecordInput | null;
  resource?: number | null;
  taskCounter?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface PremiumLoaCategoryInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  lengthFrom?: number | null;
  lengthTo?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface PremiumParameterHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  parameter?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
  value: number;
}

export interface PremiumParameterInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  loaCategory?: PremiumLoaCategoryInput | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  premiumParameterValueHistory?: (PremiumParameterHistoryItemInput | null)[] | null;
  record?: PremiumRecordInput | null;
  route?: PremiumRouteInput | null;
  type?: PremiumParameterTypeInput | null;
}

export interface PremiumParameterTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface PremiumRecordInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface PremiumRouteInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface PremiumSuspensionHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  resource?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface PropertyCriteriaInput {
  categories?: (string | null)[] | null;
  contextCode?: string | null;
  environment?: number | null;
  propertyCodes?: (string | null)[] | null;
  registrationStatuses?: (string | null)[] | null;
  registrationTypes?: (string | null)[] | null;
  typesToLoad?: number | null;
}

export interface PropertyInput {
  category?: string | null;
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  dataType?: string | null;
  defaultValue?: any | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  isForType: boolean;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  userOverridePossible: boolean;
}

export interface PropertyValueInput {
  context?: number | null;
  creationDate?: any | null;
  creationUser?: string | null;
  environment?: number | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  property?: PropertyInput | null;
  registrationStatus?: string | null;
  registrationType?: string | null;
  value?: any | null;
}

export interface RealizationCriteriaInput {
  componentVersionId?: number | null;
  maxResults?: number | null;
  opened: boolean;
  order?: string | null;
  orderBy?: string | null;
  personId?: number | null;
}

export interface RealizationInput {
  attachments?: (EducationCardAttachmentInput | null)[] | null;
  componentVersion?: ComponentVersionInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  date?: any | null;
  dueDate?: any | null;
  externId?: number | null;
  finalReviewer?: number | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  notFollowed: boolean;
  objectType?: string | null;
  objectVersion?: number | null;
  person?: number | null;
  remark?: string | null;
  result?: RealizationResultInput | null;
}

export interface RealizationResultInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface ReasonContractEndInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface ReasonContractSuspensionInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  export?: boolean | null;
  exportCode?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface RecurrenceDefinitionDtoInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  pattern?: RecurrencePatternDtoInput | null;
  range?: RecurrenceRangeDtoInput | null;
}

export interface RecurrenceDefinitionInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  item?: CalendarItemInput | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  pattern?: AbstractRecurrencePatternInput | null;
  range?: AbstractRecurrenceRangeInput | null;
}

export interface RecurrencePatternDtoInput {
  creationDate?: any | null;
  creationUser?: string | null;
  day?: DayInput | null;
  dayOfMonth?: number | null;
  dayOfYear?: number | null;
  days?: (DayInput | null)[] | null;
  id?: number | null;
  index?: DayOfWeekIndexInput | null;
  interval?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  month?: MonthInput | null;
  objectType?: string | null;
  objectVersion?: number | null;
  startPoint?: StartPointTypeInput | null;
}

export interface RecurrenceRangeDtoInput {
  creationDate?: any | null;
  creationUser?: string | null;
  endDate?: any | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
  occurrence?: number | null;
  startDate?: any | null;
}

export interface RegistrationAttachmentInput {
  classType?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  fileName?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
}

export interface RegistrationCalculationRelationInput {
  calculation?: CalculationInput | null;
  category?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  length?: number | null;
  longConstant?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  result?: number | null;
  shortConstant?: number | null;
  year?: number | null;
}

export interface RegistrationCriteriaInput {
  assignmentCodes?: (string | null)[] | null;
  canIncludeRegistrationsWithNullConstraintGroup: boolean;
  constraintGroupCodes?: (string | null)[] | null;
  cyclusId?: number | null;
  excludeConstraintGroupCodes?: (string | null)[] | null;
  excludeIds?: (number | null)[] | null;
  externIds?: (any | null)[] | null;
  ids?: (number | null)[] | null;
  ignoreRemovedRegistrations?: boolean | null;
  loadDetailsOnlyForConstrainedTypes: boolean;
  metadataCriteria?: RegistrationMetadataCriteriaInput | null;
  metadataPropertyValues?: (PreferenceHolderInput | null)[] | null;
  onlyConstrainedRegistrationsIncluded: boolean;
  onlyWaitingRegistrations: boolean;
  rangeCriteria?: RegistrationRangeCriteriaInput | null;
  realizations?: (number | null)[] | null;
  resources?: (number | null)[] | null;
  statusCodes?: (string | null)[] | null;
  taskRegime?: string | null;
  typeAndStatusCodes?: (string | null)[] | null;
  typeCategoryCodes?: (string | null)[] | null;
  typeCodes?: (string | null)[] | null;
  workRosterItem?: string | null;
}

export interface RegistrationDetailInput {
  dummyField?: string | null;
}

export interface RegistrationInput {
  attachments?: (RegistrationAttachmentInput | null)[] | null;
  bedrijf?: string | null;
  calculationRelations?: (RegistrationCalculationRelationInput | null)[] | null;
  costCenters?: (CostCenterInput | null)[] | null;
  creationDate?: any | null;
  creationUser?: string | null;
  cyclusId?: number | null;
  detail?: RegistrationDetailInput | null;
  environment?: string | null;
  externId?: any | null;
  fromDate?: any | null;
  id?: number | null;
  melding?: string | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  personenMiddelen?: (number | null)[] | null;
  remarks?: (RegistrationRemarkInput | null)[] | null;
  resource?: number | null;
  status?: RegistrationStatusInput | null;
  toDate?: any | null;
  type?: RegistrationTypeInput | null;
  workRosterItem?: boolean | null;
}

export interface RegistrationMetadataCriteriaInput {
  context?: string | null;
  operation?: string | null;
  properties?: any | null;
}

export interface RegistrationRangeCriteriaInput {
  fromDate?: any | null;
  inclusiveEndAfter?: boolean | null;
  inclusiveStartBefore?: boolean | null;
  orderByFromDate?: boolean | null;
  orderByToDate?: boolean | null;
  orderDirection?: number | null;
  toDate?: any | null;
}

export interface RegistrationRemarkInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  value?: string | null;
}

export interface RegistrationServiceContextInput {
  checkAllowChangeForOtherEmployee?: boolean | null;
  createDetailIfMissing?: boolean | null;
  errorPolicy?: ErrorMessagePolicyInput | null;
  excludeIds?: (number | null)[] | null;
  halfDaySetting?: string | null;
  instructorRegistrations?: (RegistrationInput | null)[] | null;
  leaveBalance?: any | null;
  leaveGroupsData?: LeaveGroupsDataInput | null;
  merge?: boolean | null;
  metadataContext?: string | null;
  processingWaitingRegistrations: boolean;
  removeWaiting: boolean;
  roundingType?: string | null;
  skipWorkflow?: boolean | null;
  split: boolean;
  switchRegistration?: RegistrationInput | null;
  trainingRegistrationUpdated: boolean;
  updatedFromSessions: boolean;
  useDefaultMetadataRounding: boolean;
  validationExceptionsForWaitingRegistrations: boolean;
  waitingRegistrationState?: WaitingRegistrationStateEnum | null;
}

export interface RegistrationStatusInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  type?: RegistrationTypeInput | null;
}

export interface RegistrationTypeCategoryInput {
  categoryChildren?: (RegistrationTypeCategoryInput | null)[] | null;
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  parentCategory?: RegistrationTypeCategoryInput | null;
  types?: (RegistrationTypeInput | null)[] | null;
}

export interface RegistrationTypeInput {
  category?: RegistrationTypeCategoryInput | null;
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  statuses?: (RegistrationTypeStatusRelationInput | null)[] | null;
}

export interface RegistrationTypeShortcutInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  resource?: number | null;
  type?: RegistrationTypeInput | null;
}

export interface RegistrationTypeStatusRelationInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  status?: RegistrationStatusInput | null;
  type?: RegistrationTypeInput | null;
}

export interface ReportCategoryInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface ReportDataSourceTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface ReportDefinitionInput {
  category?: ReportCategoryInput | null;
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  dataSource?: AbstractDataSourceInput | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  online?: boolean | null;
  orderIndex?: number | null;
  parameters?: (ReportParameterInput | null)[] | null;
  reportName?: string | null;
  rootReport?: string | null;
  xmls?: (ReportDefinitionXMLInput | null)[] | null;
}

export interface ReportDefinitionXMLInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
}

export interface ReportParameterInput {
  completeName?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  defaultValue?: string | null;
  hasDefault?: boolean | null;
  id?: number | null;
  label?: string | null;
  mandatory?: boolean | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  prompting?: boolean | null;
  securityRoleForEditingParameter?: string | null;
  type?: string | null;
}

export interface ResourceAttachmentInput {
  classType?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  fileName?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
}

export interface ResourceRosterRelationInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  leaveOnHoliday?: boolean | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  referenceDate?: any | null;
  resource?: number | null;
  roster?: RosterInput | null;
  rosterRegistrations?: (RosterRegistrationInput | null)[] | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface ResourceSnapshotInput {
  environment?: string | null;
  externId?: number | null;
  id?: number | null;
}

export interface ResourceWorkRosterInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  offset: number;
  resource?: number | null;
  status?: RegistrationStatusInput | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface RosterInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  definition?: any | null;
  definitionAsString?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface RosterRegistrationInput {
  amendTime?: any | null;
  amexportDuration?: number | null;
  amstartTime?: any | null;
  callTime?: any | null;
  category?: string | null;
  cyclusId?: number | null;
  duringPublicHoliday?: boolean | null;
  endTime?: any | null;
  exportDuration1?: number | null;
  exportDuration2?: number | null;
  maxEndTime?: any | null;
  maxStartTime?: any | null;
  minEndTime?: any | null;
  minStartTime?: any | null;
  noFreeTimeAllowed?: boolean | null;
  pmendTime?: any | null;
  pmexportDuration?: number | null;
  pmstartTime?: any | null;
  resource?: number | null;
  restDuration?: number | null;
  ship?: string | null;
  spare?: string | null;
  startTime?: any | null;
  type?: string | null;
}

export interface RotationSystemInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface RoundingTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface SalaryComponentInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  values?: (SalaryComponentValueInput | null)[] | null;
}

export interface SalaryComponentValueInput {
  category?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  salaryComponent?: SalaryComponentInput | null;
  salaryPeriod?: SalaryPeriodInput | null;
  value?: number | null;
}

export interface SalaryPeriodInput {
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  simulation: number;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface SaveOrUpdateRegistrationParameterInput {
  errorPolicy?: ErrorMessagePolicyInput | null;
  halfDaySetting?: string | null;
  leaveGroupsData?: LeaveGroupsDataInput | null;
  metadataContext?: string | null;
  registration?: RegistrationInput | null;
  split: boolean;
  statusCode?: string | null;
  typeCode?: string | null;
  useDefaultMetadataRounding: boolean;
}

export interface ScaleHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  extraPercentage?: number | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  scalePercentage?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface ScaleInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface ScoreInput {
  creationDate?: any | null;
  creationUser?: string | null;
  evaluationCriteriumVersion?: EvaluationCriterionVersionInput | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  percentage?: number | null;
  scaleScore?: number | null;
}

export interface SecurityDefinitionInput {
  creationDate?: any | null;
  creationUser?: string | null;
  history?: (SecurityHistoryItemInput | null)[] | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface SecurityHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  profiles?: (SecurityProfileInput | null)[] | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface SecurityPermissionInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface SecurityProfileInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  permissions?: (SecurityPermissionInput | null)[] | null;
}

export interface SequenceDefinitionInput {
  creationDate?: any | null;
  creationUser?: string | null;
  history?: (SequenceHistoryItemInput | null)[] | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface SequenceHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  jump?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  referenceDate?: any | null;
  resources?: (number | null)[] | null;
  rotationSystem?: RotationSystemInput | null;
  startingPosition?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface ShareRegisterHistoryItemInput {
  amount?: number | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface ShipCtInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
}

export interface SortInput {
  direction?: string | null;
  field?: string | null;
}

export interface StartPointTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface StaticGroupInput {
  code?: string | null;
  constraintDefinitions?: (ConstraintDefinitionInput | null)[] | null;
  contexts?: (GroupContextInput | null)[] | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  groupResourcesHistory?: (GroupResourcesHistoryItemInput | null)[] | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectVersion?: number | null;
  owner?: number | null;
  properties?: (GroupPropertyInput | null)[] | null;
  securityDefinition?: SecurityDefinitionInput | null;
  sequenceDefinition?: SequenceDefinitionInput | null;
  successionDefinition?: SuccessionDefinitionInput | null;
}

export interface SuccessionDefinitionInput {
  creationDate?: any | null;
  creationUser?: string | null;
  history?: (SuccessionHistoryItemInput | null)[] | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface SuccessionHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  resources?: (number | null)[] | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface TaxStatusHistoryItemInput {
  aantalAnderen?: number | null;
  aantalKinderen?: number | null;
  aantalMinderValideAnderen?: number | null;
  aantalMinderValideKinderen?: number | null;
  creationDate?: any | null;
  creationUser?: string | null;
  echtgenootMinderValide?: boolean | null;
  echtgenootTenLaste?: boolean | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  taxStatus?: TaxStatusInput | null;
  validFrom?: any | null;
  validUntil?: any | null;
  werknemerMinderValide?: boolean | null;
}

export interface TaxStatusInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface TeamHistoryItemInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  team?: TeamInput | null;
  validFrom?: any | null;
  validUntil?: any | null;
}

export interface TeamInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  environment?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
}

export interface TelecomInput {
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  type?: TelecomTypeInput | null;
  value?: string | null;
}

export interface TelecomTypeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface TrackInput {
  catalog?: CatalogSnapshotInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  currentTrackVersion?: TrackVersionInput | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
}

export interface TrackVersionChildInput {
  componentVersion?: ComponentVersionInput | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  moduleVersion?: ModuleVersionInput | null;
  objectVersion?: number | null;
}

export interface TrackVersionInput {
  active?: boolean | null;
  attachments?: (CatalogAttachmentInput | null)[] | null;
  children?: (TrackVersionChildInput | null)[] | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  majorVersion: number;
  minorVersion: number;
  modificationDate?: any | null;
  modificationUser?: string | null;
  name?: string | null;
  objectType?: string | null;
  objectVersion?: number | null;
  track?: TrackInput | null;
  version?: any | null;
}

export interface UserValueInput {
  context?: number | null;
  creationDate?: any | null;
  creationUser?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
  systemPropertyValue?: number | null;
  user?: number | null;
  value?: any | null;
}

export interface VisibilityGroupInput {
  creationDate?: any | null;
  creationUser?: string | null;
  groupCode?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface VocativeInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface WorkArrangementInput {
  code?: string | null;
  creationDate?: any | null;
  creationUser?: string | null;
  description?: string | null;
  id?: number | null;
  modificationDate?: any | null;
  modificationUser?: string | null;
  objectVersion?: number | null;
}

export interface WorkflowItemInput {
  activityInstanceId?: number | null;
  parameters?: (WorkflowParameterInput | null)[] | null;
  person?: number | null;
  processDefinition?: string | null;
  processInstanceId?: number | null;
  username?: string | null;
}

export interface WorkflowParameterInput {
  key?: string | null;
  value?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
