'use strict';

/*@ngInject*/
function Balance(DataSource) {

	var config = {
		baseUrl : '/restful/balanceService'
	};

	var ds = new DataSource(config);

	ds.config.getByCriteria = ds.config.getByCriteria || {};
	ds.config.getByCriteria.method = 'POST';
	ds.config.getByCriteria.url = '/getByCriteria';
	ds.config.getByCriteria.dataType = 'json';
	if (!ds.getByCriteria) {
		ds.registerOperation('getByCriteria');
	}

	ds.config.saveOrUpdate = ds.config.saveOrUpdate || {};
	ds.config.saveOrUpdate.method = 'POST';
	ds.config.saveOrUpdate.url = '/saveOrUpdate';
	if (!ds.saveOrUpdate) {
		ds.registerOperation('saveOrUpdate');
	}
	
	//getCounterTypes
	ds.config.getCounterTypes = ds.config.getCounterTypes || {};
	ds.config.getCounterTypes.method = 'GET';
	ds.config.getCounterTypes.url = '/getCounterTypes';
	if (!ds.getCounterTypes) {
		ds.registerOperation('getCounterTypes');
	}
		
	ds.config.getBalanceTypeByCode = ds.config.getBalanceTypeByCode || {};
	ds.config.getBalanceTypeByCode.method = 'GET';
	ds.config.getBalanceTypeByCode.url = '/getBalanceTypeByCode';
	if (!ds.getBalanceTypeByCode) {
		ds.registerOperation('getBalanceTypeByCode');
	}
	
	ds.config.removeBalanceCounterHistoryItem = ds.config.removeBalanceCounterHistoryItem || {};
	ds.config.removeBalanceCounterHistoryItem.method = 'DELETE';
	ds.config.removeBalanceCounterHistoryItem.url = '/remove';
	if (!ds.removeBalanceCounterHistoryItem) {
		ds.registerOperation('removeBalanceCounterHistoryItem');
	}
	
	ds.config.getCreditBalanceSummary = ds.config.getCreditBalanceSummary || {};
	ds.config.getCreditBalanceSummary.method = 'GET';
	ds.config.getCreditBalanceSummary.url = '/getCreditBalanceSummary';
	if (!ds.getCreditBalanceSummary) {
		ds.registerOperation('getCreditBalanceSummary');
	}
	
	
	ds.config.remove = ds.config.remove || {};
	ds.config.remove.url = '/remove/:id';
	if (!ds.remove) {
		ds.registerOperation('remove');
	}
	
	return ds;
}

module.exports = Balance;