'use strict';

var css = require('./messaging.css');

angular.module('proteus.messaging', ['ui.grid', 'ui.grid.pagination', 'ui.grid.selection', 'ui.grid.resizeColumns'])

.run(function($templateCache) {
	$templateCache.put('messaging/inbox-items-page.html', require('./inbox-items-page.html'));
	$templateCache.put('messaging/inbox-item-page.html', require('./inbox-item-page.html'));
})

.factory('InboxItems', require('./inbox-items.service'))

.controller('InboxItemsPageCtrl', require('./inbox-items-page.controller'))
.controller('InboxItemPageCtrl',  require('./inbox-item-page.controller'));

module.exports = 'proteus.messaging';