import { ColorsDecimal, Color } from "utils/ColorUtil";
import { CustomItem } from "../mapper/GanttAdapter";
import { PlanningShipItem } from "../PlanningGantt";
import { BaseItemRenderer } from "./BaseItemRenderer";

export const ABSENCE = "AFWEZIGHEID";
export const ABSENCE_REQUESTED = "IN_AANVRAAG";
export const ABSENCE_REMOVAL_REQUESTED = "IN_AANVRAAG_VERWIJDERING";
export const EXCHANGED = "GEWISSELD";
export const NOT_WORKING = "NIET_WERKEN";
export const REPLACED = "VERVANGEN";
export const SUBSTITUTE = "VERVANGING";
export const TO_PLAN = "TE_PLANNEN";

const statusToColorMap: { [key in string]?: ColorsDecimal } = {
    [ABSENCE_REQUESTED]: ColorsDecimal.YELLOW,
    [ABSENCE_REMOVAL_REQUESTED]: ColorsDecimal.YELLOW,
    [ABSENCE]: ColorsDecimal.RED,
    [SUBSTITUTE]: ColorsDecimal.RED,
    [REPLACED]: ColorsDecimal.ORANGE,
    [EXCHANGED]: ColorsDecimal.ORANGE,
    [TO_PLAN]: ColorsDecimal.BLUE,
    [NOT_WORKING]: ColorsDecimal.RED
};

export class ShipWorkPeriodItemRenderer extends BaseItemRenderer {

    getTitle(): string | JSX.Element {
        const planningShipItem: PlanningShipItem = (this.props.item as CustomItem).node.source;
        // If there is a spare => show spare initials, otherwise show original employee's name.
        // If it does not have an original employee => segment is empty.
        let resourceName = planningShipItem.spareName || planningShipItem.resourceName;
        if (!resourceName) {
            return '';
        }
        const names = resourceName.split(/[ -]+/);
        return names.map(name => name.charAt(0)).join("");
    }

    calculateColor(): ColorsDecimal {
        const planningShipItem: PlanningShipItem = (this.props.item as CustomItem).node.source;
        if (planningShipItem == null) {
            return ColorsDecimal.NULL;
        }
        if (!planningShipItem.resource  && !planningShipItem.spare) {
            return ColorsDecimal.RED;
        }
        return statusToColorMap[planningShipItem.spareStatus as string] || statusToColorMap[planningShipItem.status as string] || ColorsDecimal.GREEN;
    }

    getBackgroundGradient(): string {
        const item: CustomItem = this.props.item as CustomItem;
        const planningShipItem: PlanningShipItem = item.node.source as PlanningShipItem;
        return this.getBaseBackgroundGradient(item, planningShipItem.rosterRegistration.type.code, null);
    }

    getStyle(): object {
        const item: CustomItem = this.props.item as CustomItem;
        const planningShipItem: PlanningShipItem = (this.props.item as CustomItem).node.source;
        let style: object = this.getBaseStyle(item, planningShipItem.rosterRegistration.type.code, null, planningShipItem.rosterRegistration.fromDate, planningShipItem.rosterRegistration.toDate);
        style = {
            ...style,
            borderColor: Color(this.calculateColor()).lightness(25).hex(),
        }
        return style;
    }
}