import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { CrudHeader } from "@crispico/foundation-react/entity_crud/CrudHeader";
import { InitializationsForClient } from "app";
import { ReactNode } from "react";
import React from 'react';
import { Segment } from "semantic-ui-react";
import { apolloGetExtensionFromError, apolloGlobalErrorHandler, CatchedGraphQLError, GraphQLErrorExtensions } from "@crispico/foundation-react";
import { ProteusGraphQLErrorExtensions } from "pages/registrationEditor/RegistrationEditor";
import { ProteusConstants } from "ProteusConstants";

/**
 * @author Daniela Buzatu
 */
export class ProteusUtils {
    static checkPermission(permission: string) {
        return AppMetaTempGlobals.appMetaInstance.hasPermission(permission);
    }

    static getCurrentUser() {
        return (AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient).currentUserAdditionalInfo;
    }

    static getUserCompleteName() {
        return this.getCurrentUser().name + " " + this.getCurrentUser().firstName;
    }

    static getUserName() {
        return AppMetaTempGlobals.appMetaInstance.getCurrentUser()!.username;
    }

    static getEnvironmentId() {
        return (AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient).currentEnvironmentId;
    }

    static getMetadataProvider() {
        return (AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient).metadataProvider;
    }

    static renderDrawerHeader(headerContent: ReactNode, headerClassName?: string) {
        return (
            <>
                <CrudHeader
                    content={{className: "CrudHeader_editorHeight", component:
                        <Segment className="EntityEditorPage_header_content">
                            <div className="flex-container-row flex-center gap5">
                                <h2 className="EntityCrudHeader_white no-margin wh100">
                                    <div className={headerClassName || ""}>
                                        {headerContent}
                                    </div>
                                </h2>
                            </div>
                        </Segment>
                    }}
                />
            </>
        )
    }

    static getApolloErrorHandlerForValidationException(permission: string, callback?: (e: CatchedGraphQLError) => void, additionalCondition: boolean = true) {
        return (e: CatchedGraphQLError) => {
            if (ProteusConstants.VALIDATION_EXCEPTION === apolloGetExtensionFromError(e, GraphQLErrorExtensions.EXCEPTION_SIMPLE_NAME)) {
                const isValidationWarningException = apolloGetExtensionFromError(e, ProteusGraphQLErrorExtensions.ORIGINAL_PROTEUS_EXCEPTION).includes("ValidationWarningException");
                // If the user has "permission", show a confirmation popup to ignore exceptions and retry save
                if (ProteusUtils.checkPermission(permission) && isValidationWarningException && additionalCondition) {
                    callback?.(e);
                } else {
                    return true;
                }
            } else {
                apolloGlobalErrorHandler(e);
                return true;
            }
        };
    }

}