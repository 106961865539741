'use strict';

/*@ngInject*/
function proteusWizardPaneTitle() {

	return {
		restrict: 'AE',
		transclude: true,
		replace: true,
		template: require('./wizard-pane-title.html')
	}

}

module.exports = proteusWizardPaneTitle;