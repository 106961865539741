import { apolloClient, EntityDescriptor, FieldDescriptor, Utils } from "@crispico/foundation-react";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { FieldEditorProps, fieldEditors, FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { getEmployee_employeeService_employee_addressHistory, getEmployee_employeeService_employee_detail_civilStatusHistory, getEmployee_employeeService_employee_detail_contractHistory, getEmployee_employeeService_employee_detail_functionHistory, getEmployee_employeeService_employee_detail_shareRegisterHistory, getEmployee_employeeService_employee_detail_taxStatusHistory, getEmployee_employeeService_employee_telecomList } from "apollo-gen/getEmployee";
import { getEmployeeWithDetailByDate_employeeService_employee_detail_scaleHistory } from "apollo-gen/getEmployeeWithDetailByDate";
import { getPremiumHistory_planningServiceFacadeBean_premiumHistory } from "apollo-gen/getPremiumHistory";
import { getStatusesForTypes_registrationTypeStatusRelationService_statusesForTypes } from "apollo-gen/getStatusesForTypes";
import { REGISTRATION_TYPE_STATUS_RELATION_SERVICE_GET_STATUSES_FOR_TYPE } from "graphql/queries";
import moment from "moment";
import { HistoryItem } from "pages/groupsManagement/GroupsManagement";
import { ProteusConstants } from "ProteusConstants";
import React from "react";
import { Button, Form, Icon } from "semantic-ui-react";
import { ADDRESS_HISTORY_ITEM, MOVE_UP_DOWN } from "./AddressTab";
import { CONSTRAINT_GROUP_FIELD } from "./ConstraintGroupTab";
import { CONTRACT_HISTORY, SCALE_HISTORY_ITEM } from "./ContractTab";
import { CHILDREN_IN_MY_RESPONSABILITY, CHILDREN_WITH_DISABILITIES, CIVIL_STATUS_HISTORY_ITEM, OTHERS_IN_MY_RESPONSABILITY, OTHERS_WITH_DISABILITIES, PARTNER_IN_MY_RESPONSABILITY, PARTNER_WITH_DISABILITY, PERSON_WITH_DISABILITY, SHARE_REGISTER_HISTORY_ITEM, TAX_STATUS_HISTORY } from "./ExtraTab";
import { EMPLOYEE_FUNCTION_FIELD, FUNCTION_HISTORY } from "./FunctionTab";
import { RESOURCE_WORK_ROSTER_HISTORY_ITEM } from "./PersonalDetailsTab";
import { PILOT_PROGRESSION_FIELD_TYPE, PREMIUM_HISTORY } from "./ProgressionTab";
import { FieldEditor, FieldEditorNotUsableStandAloneProps } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";

export const HISTORY_FIELD_TYPE = "historyFieldType";
export const CUSTOM_HISTORY_FIELD = "customHistoryField";
const CUSTOM_NUMBERS = "CustomNumbers";
export const RESOURCE_WORK_ROSTER_STATUS = "ResourceWorkRosterStatus";

export const accountEntityDescriptor = new EntityDescriptor({ name: "AccountTable", icon: "credit card" })
    .addFieldDescriptor({ name: "number", type: FieldType.string })
    .addFieldDescriptor({ name: "institution", type: "FinancialInstitution" })
    .addFieldDescriptor({ name: "usage", type: "AccountUsage" })
    .addFieldDescriptor({ name: "type", type: "AccountType" })
    .addFieldDescriptor({ name: "country", type: "Country" })

export const addressEntityDescriptor = new EntityDescriptor({ name: "AddressTable", icon: "home" })
    .addFieldDescriptor({ name: "address.type", type: "AddressType" })
    .addFieldDescriptor({ name: "address.street", type: FieldType.string })
    .addFieldDescriptor({ name: "address.houseNumber", type: FieldType.string })
    .addFieldDescriptor({ name: "address.box", type: FieldType.string })
    .addFieldDescriptor({ name: "address.city", type: "City" })

export const cardEntityDescriptor = new EntityDescriptor({ name: "CardTable", icon: "credit card outline" })
    .addFieldDescriptor({ name: "type", type: "CardType" })
    .addFieldDescriptor({ name: "number", type: FieldType.string })
    .addFieldDescriptor({ name: "code", type: FieldType.string })
    .addFieldDescriptor({ name: "validUntil", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT}) })

export const categoryEntityDescriptor = new EntityDescriptor({ name: "CategoryHistoryItemTable", icon: "clipboard" })
    .addFieldDescriptor({ name: "category", type: "Category" })

export const civilStatusEntityDescriptor = new EntityDescriptor({ name: "CivilStatusHistoryItemTable", icon: "user plus" })
    .addFieldDescriptor({ name: "civilStatus", type: "CivilStatus" })
    .addFieldDescriptor({ name: "firstNamePartner", type: FieldType.string })
    .addFieldDescriptor({ name: "namePartner", type: FieldType.string })
    .addFieldDescriptor({ name: "dateOfBirthPartner", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT}) })
    .addFieldDescriptor({ name: "incomeForm", type: "IncomeForm" })

export const companyEntityDescriptor = new EntityDescriptor({ name: "CompanyHistoryItemTable", icon: "suitcase" })
    .addFieldDescriptor({ name: "company", type: "Company" })

export const constraintGroupsEntityDescriptor = new EntityDescriptor({ name: "ConstraintGroupsTable", icon: "group" })
    .addFieldDescriptor({ name: "constraintGroup", type: "ConstraintGroup" })

export const contractEntityDescriptor = new EntityDescriptor({ name: "ContractHistoryItemTable", icon: "file alternate" })
    .addFieldDescriptor({ name: "employeeNumber", type: FieldType.string })
    .addFieldDescriptor({ name: "type", type: "ContractType" })
    .addFieldDescriptor({ name: "status", type: "ContractStatus" })
    .addFieldDescriptor({ name: "workArrangement", type: "WorkArrangement" })
    .addFieldDescriptor({ name: "employmentBreak", type: "EmploymentBreak" })
    .addFieldDescriptor({ name: "reasonContractEnd", type: "ReasonContractEnd" })

export const contractSuspensionEntityDescriptor = new EntityDescriptor({ name: "ContractSuspensionTable", icon: "file alternate" })
    .addFieldDescriptor({ name: "reasonSuspension", type: "ReasonContractSuspension" })

export const curriculumRegistrationsEntityDescriptor = new EntityDescriptor({ name: "CurriculumRegistrations", icon: "file" })
    .addFieldDescriptor({ name: "type.name", type: FieldType.string })
    .addFieldDescriptor({ name: "status.description", type: FieldType.string })
    .addFieldDescriptor({ name: "fromDate", type: FieldType.string })
    .addFieldDescriptor({ name: "toDate", type: FieldType.string })
    .addFieldDescriptor({ name: "duration", type: FieldType.string })
    .addFieldDescriptor({ name: "creationDate", type: FieldType.string })
    .addFieldDescriptor({ name: "creationUser", type: FieldType.string })
    .addFieldDescriptor({ name: "modificationDate", type: FieldType.string })
    .addFieldDescriptor({ name: "modificationUser", type: FieldType.string })

export const departmentEntityDescriptor = new EntityDescriptor({ name: "DepartmentHistoryItemTable", icon: "building" })
    .addFieldDescriptor({ name: "department", type: "Department" })

export const functionEntityDescriptor = new EntityDescriptor({ name: "FunctionHistoryTable", icon: "briefcase" })
    .addFieldDescriptor({ name: "employeeFunction", type: "EmployeeFunction" })

export const hourRegimeEntityDescriptor = new EntityDescriptor({ name: "HourRegimeHistoryItemTable", icon: "clock" })
    .addFieldDescriptor({ name: "hourRegime", type: "HourRegime" })

export const locationEntityDescriptor = new EntityDescriptor({ name: "LocationHistoryItemTable", icon: "map marker alternate" })
    .addFieldDescriptor({ name: "location", type: "Location" })

export const premiumHistoryEntityDescriptor = new EntityDescriptor({ name: "PremiumHistoryItemTable", icon: "trophy" })
    .addFieldDescriptor({ name: "record", type: "PremiumRecord" })
    .addFieldDescriptor({ name: "taskCounter", type: CUSTOM_NUMBERS })

export const resourceRosterRelationsEntityDescriptor = new EntityDescriptor({ name: "ResourceRosterRelationsTable", icon: "file" })
    .addFieldDescriptor({ name: "roster", type: "Roster" })
    .addFieldDescriptor({ name: "referenceDate", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT}) })
    .addFieldDescriptor({ name: "leaveOnHoliday", type: FieldType.boolean })


export const resourceWorkRosterEntityDescriptor = new EntityDescriptor({ name: "ResourceWorkRosterHistoryTable", icon: "file" })
    .addFieldDescriptor({ name: "offset", type: FieldType.number });
addResourceWorkRosterStatusFieldEditor(resourceWorkRosterEntityDescriptor);

export const scaleEntityDescriptor = new EntityDescriptor({ name: "ScaleHistoryTable", icon: "briefcase" })
    .addFieldDescriptor({ name: "scalePercentage", type: FieldType.number })
    .addFieldDescriptor({ name: "extraPercentage", type: FieldType.number });

export const shareRegisterEntityDescriptor = new EntityDescriptor({ name: "ShareRegisterHistoryItemTable", icon: "external share" })
    .addFieldDescriptor({ name: "amount", type: CUSTOM_NUMBERS })
    .addFieldDescriptor({ name: "name", type: FieldType.string })

export const taxStatusEntityDescriptor = new EntityDescriptor({ name: "TaxStatusHistoryTable", icon: "money bill alternate" })
    .addFieldDescriptor({ name: "taxStatus", type: "TaxStatus" })
    .addFieldDescriptor({ name: PERSON_WITH_DISABILITY, type: FieldType.boolean })
    .addFieldDescriptor({ name: PARTNER_IN_MY_RESPONSABILITY, type: FieldType.boolean })
    .addFieldDescriptor({ name: PARTNER_WITH_DISABILITY, type: FieldType.boolean })
    .addFieldDescriptor({ name: CHILDREN_IN_MY_RESPONSABILITY, type: CUSTOM_NUMBERS })
    .addFieldDescriptor({ name: CHILDREN_WITH_DISABILITIES, type: CUSTOM_NUMBERS })
    .addFieldDescriptor({ name: OTHERS_IN_MY_RESPONSABILITY, type: CUSTOM_NUMBERS })
    .addFieldDescriptor({ name: OTHERS_WITH_DISABILITIES, type: CUSTOM_NUMBERS })

export const teamEntityDescriptor = new EntityDescriptor({ name: "TeamHistoryItemTable", icon: "users" })
    .addFieldDescriptor({ name: "team", type: "Team" })

export const telecomEditorEntityDescriptor = new EntityDescriptor({ name: "TelecomTable", icon: "comment alternate" })
    .addFieldDescriptor({ name: "type", type: "TelecomType" })
    .addFieldDescriptor({ name: "value", type: FieldType.string })

export const telecomTableEntityDescriptor = new EntityDescriptor({ name: "TelecomTable", icon: "comment alternate" })
    .addFieldDescriptor({ name: "moveUpDown", type: MOVE_UP_DOWN })
    .addFieldDescriptor({ name: "type", type: "TelecomType" })
    .addFieldDescriptor({ name: "value", type: FieldType.string })

fieldEditors[HISTORY_FIELD_TYPE] = class extends FieldEditor<any, FieldEditorNotUsableStandAloneProps> {

    protected static retrieveCurrentHistoryItem(historyItems: HistoryItem[], referenceDate?: Date) {
        if (!historyItems || historyItems.length === 0) {
            return undefined;
        }
        const date = referenceDate ? new Date(referenceDate) : new Date();
        let currentItemIndex = -1;
        let currentItemValidUntil = Number.MAX_SAFE_INTEGER;
        for (let i = 0; i < historyItems.length; i++) {
            if ((!historyItems[i].validUntil && new Date(historyItems[i].validFrom!).getTime() <= date.getTime())
                || (historyItems[i].validUntil && new Date(historyItems[i].validFrom!).getTime() <= date.getTime() && new Date(historyItems[i].validUntil!).getTime() >= date.getTime())) {
                currentItemIndex = i;
                break;
            } else if (new Date(historyItems[i].validUntil!).getTime() > currentItemValidUntil) {
                currentItemValidUntil = new Date(historyItems[i].validUntil!).getTime();
                currentItemIndex = i;
            }
        }
        if (currentItemIndex !== -1) {
            return historyItems[currentItemIndex];
        } else {
            return undefined;
        }
    }

    protected getCivilStatusDisplayedValue(currentHistoryValue: getEmployee_employeeService_employee_detail_civilStatusHistory) {
        let value: string | null | undefined = "", displayedValue = "";
        value = currentHistoryValue.civilStatus?.description;
        displayedValue = value ? (displayedValue + value + " ") : displayedValue;

        value = currentHistoryValue.firstNamePartner;
        displayedValue = value ? (displayedValue + value + " ") : displayedValue;

        value = currentHistoryValue.namePartner;
        displayedValue = value ? (displayedValue + value + " ") : displayedValue;

        value = currentHistoryValue.dateOfBirthPartner;
        displayedValue = value ? (displayedValue + moment(new Date(value)).format(ProteusConstants.DATE_FORMAT) + " ") : displayedValue;

        value = currentHistoryValue.incomeForm?.description;
        displayedValue = value ? (displayedValue + " (" + value + ")") : displayedValue;

        return displayedValue;
    }

    protected getTaxStatusDisplayedValue(currentHistoryValue: getEmployee_employeeService_employee_detail_taxStatusHistory) {
        let employeeFiscalStatus = currentHistoryValue.taxStatus?.description;
        let noPersonsInEmployeeResponsability = true;
        const withDisabilities = _msg("EmployeeEditor.withDisabilities.label") + " ";
        const withoutDisabilities = _msg("EmployeeEditor.withoutDisabilities.label") + " ";

        let partnerLabel = "";
        if (currentHistoryValue[PARTNER_IN_MY_RESPONSABILITY]) {
            noPersonsInEmployeeResponsability = false;
            if (currentHistoryValue[PARTNER_WITH_DISABILITY]) {
                partnerLabel = withDisabilities + _msg("EmployeeEditor.wife.label") + ", ";
            } else {
                partnerLabel = withoutDisabilities + _msg("EmployeeEditor.wife.label") + ", ";
            }
        }

        let numberOfHealthyChildrenLabel = "";
        if (currentHistoryValue[CHILDREN_IN_MY_RESPONSABILITY]! - currentHistoryValue[CHILDREN_WITH_DISABILITIES]! > 0) {
            noPersonsInEmployeeResponsability = false;
            numberOfHealthyChildrenLabel = (currentHistoryValue[CHILDREN_IN_MY_RESPONSABILITY]! - currentHistoryValue[CHILDREN_WITH_DISABILITIES]!) + withoutDisabilities + _msg("EmployeeEditor.children.label") + ", ";
        }

        let numberOfChildrenWithDisabilitiesLabel = "";
        if (currentHistoryValue[CHILDREN_WITH_DISABILITIES]! > 0) {
            noPersonsInEmployeeResponsability = false;
            numberOfChildrenWithDisabilitiesLabel = currentHistoryValue[CHILDREN_WITH_DISABILITIES]! + withDisabilities + _msg("EmployeeEditor.children.label") + ", ";
        }

        let numberOfOtherHealthyPersonsLabel = "";
        if (currentHistoryValue[OTHERS_IN_MY_RESPONSABILITY]! - currentHistoryValue[OTHERS_WITH_DISABILITIES]! > 0) {
            noPersonsInEmployeeResponsability = false;
            numberOfOtherHealthyPersonsLabel = (currentHistoryValue[OTHERS_IN_MY_RESPONSABILITY]! - currentHistoryValue[OTHERS_WITH_DISABILITIES]!) + withoutDisabilities + _msg("EmployeeEditor.others.label") + ", ";
        }

        let numberOfOtherPersonsWithDisabilitiesLabel = "";
        if (currentHistoryValue[OTHERS_WITH_DISABILITIES]! > 0) {
            noPersonsInEmployeeResponsability = false;
            numberOfOtherPersonsWithDisabilitiesLabel = currentHistoryValue[OTHERS_WITH_DISABILITIES]! + withDisabilities + _msg("EmployeeEditor.others.label") + ", ";
        }

        let displayedValue = currentHistoryValue[PERSON_WITH_DISABILITY] ? withDisabilities : withoutDisabilities;
        displayedValue += employeeFiscalStatus ? employeeFiscalStatus + " " : ("(" + _msg("none.label") + " " + _msg("TaxStatus.label") + ") ");
        displayedValue += "(" + _msg("EmployeeEditor.payable.label") + ": ";

        if (noPersonsInEmployeeResponsability) {
            displayedValue += _msg("EmployeeEditor.noPerson.label") + ")";
        } else {
            displayedValue += partnerLabel + numberOfHealthyChildrenLabel + numberOfChildrenWithDisabilitiesLabel + numberOfOtherHealthyPersonsLabel + numberOfOtherPersonsWithDisabilitiesLabel;
            // remove the last comma and last space   
            displayedValue = displayedValue.substr(0, displayedValue.length - 2) + ")";
        }
        return displayedValue;
    }

    protected getShareRegisterDisplayedValue(currentHistoryValue: getEmployee_employeeService_employee_detail_shareRegisterHistory) {
        return _msg("ShareRegisterHistoryItem.amount.label") + ": " + currentHistoryValue.amount +
            ", " + _msg("ShareRegisterHistoryItem.name.label") + ": " + currentHistoryValue.name;
    }

    protected getAddressDisplayedValue(currentHistoryValue: getEmployee_employeeService_employee_addressHistory) {
        const street = currentHistoryValue.address?.street;
        const nr = currentHistoryValue.address?.houseNumber;
        const postCode = currentHistoryValue.address?.box;
        const city = currentHistoryValue.address?.city;
        return (street ? _msg("Address.street.label") + ": " + street + ", " : "")
            + (nr ? _msg("Address.houseNumber.label").toLowerCase() + ": " + nr + ", " : "")
            + (postCode ? _msg("Address.box.label").toLowerCase() + ": " + postCode + ", " : "")
            + (city ? (city.code + " " + city.description + (city.country ? " (" + city.country.description + ")" : "")) : "");
    }

    protected getContractDisplayedValue(currentHistoryValue: getEmployee_employeeService_employee_detail_contractHistory) {
        const type = currentHistoryValue.type?.description;
        const status = currentHistoryValue.status?.description;
        return (_msg("ContractHistoryItemTable.employeeNumber.label") + ": " + currentHistoryValue.employeeNumber)
            + (type ? ", " + _msg("ContractHistoryItemTable.type.label").toLowerCase() + ": " + type : "")
            + (status ? ", " + _msg("ContractHistoryItemTable.status.label").toLowerCase() + ": " + status : "");
    }

    protected getPremiumHistoryDisplayedValue(currentHistoryValue: getPremiumHistory_planningServiceFacadeBean_premiumHistory) {
        const record = currentHistoryValue.record ? currentHistoryValue.record.description : ("(" + _msg("none.label") + ")");
        return record + " + " + (currentHistoryValue.taskCounter ? currentHistoryValue.taskCounter : "0") + " " + _msg("tasks.label").toLowerCase();
    }

    protected getScaleHistoryDisplayedValue(currentHistoryValue: getEmployeeWithDetailByDate_employeeService_employee_detail_scaleHistory) {
        return _msg("ScaleHistoryItem.scalePercentage.label") + ": " + currentHistoryValue.scalePercentage +
            (currentHistoryValue.extraPercentage ? ", " + _msg("ScaleHistoryItem.extraPercentage.label") + ": " + currentHistoryValue.extraPercentage : "");
    }

    protected getResourceWorkRosterHistoryDisplayedValue(currentHistoryValue: any) {
        return _msg("ResourceWorkRoster.offset.label") + ": " + currentHistoryValue.offset + ", " + _msg("ResourceWorkRoster.status.label") + ": " + (currentHistoryValue.status?.description || "") + ", " + _msg("ResourceWorkRoster.validFrom.label") + ": " + moment(currentHistoryValue.validFrom).format(Utils.dateTimeFormat);
    }

    render = () => {
        const { fieldDescriptor } = this.props;
        let value = this.getValue() || [];
        // for history fields, "value" is actually an array of history values. From this array, we must retrieve the most recent value to be displayed in the dropdown
        let currentHistoryValue = fieldEditors[HISTORY_FIELD_TYPE].retrieveCurrentHistoryItem(value, this.props.fieldDescriptor.referenceDate);
        let displayedValue = "";
        if (currentHistoryValue) {
            if (fieldDescriptor.entityName === CIVIL_STATUS_HISTORY_ITEM) {
                displayedValue = this.getCivilStatusDisplayedValue(currentHistoryValue);
            } else if (fieldDescriptor.entityName === TAX_STATUS_HISTORY) {
                displayedValue = this.getTaxStatusDisplayedValue(currentHistoryValue);
            } else if (fieldDescriptor.entityName === SHARE_REGISTER_HISTORY_ITEM) {
                displayedValue = this.getShareRegisterDisplayedValue(currentHistoryValue);
            } else if (fieldDescriptor.entityName === ADDRESS_HISTORY_ITEM) {
                displayedValue = this.getAddressDisplayedValue(currentHistoryValue);
            } else if (fieldDescriptor.entityName === CONTRACT_HISTORY) {
                displayedValue = this.getContractDisplayedValue(currentHistoryValue);
            } else if (fieldDescriptor.entityName === PREMIUM_HISTORY) {
                displayedValue = this.getPremiumHistoryDisplayedValue(currentHistoryValue);
            } else if (fieldDescriptor.entityName === SCALE_HISTORY_ITEM) {
                displayedValue = this.getScaleHistoryDisplayedValue(currentHistoryValue);
            } else if (fieldDescriptor.entityName === RESOURCE_WORK_ROSTER_HISTORY_ITEM) {
                displayedValue = this.getResourceWorkRosterHistoryDisplayedValue(currentHistoryValue);
            } else if (fieldDescriptor.childFields?.length > 0) {
                displayedValue = Utils.navigate(currentHistoryValue, fieldDescriptor.childFields);
            }
        }

        return (
            <Form.Field className="EmployeeEditor_historyFields">
                <Form.Input value={displayedValue} readOnly className={fieldDescriptor.isDisabled ? "disabled" : ""} />
                <Button icon type="button" onClick={() => fieldDescriptor.onOpenTableLight(fieldDescriptor, value)}><Icon name="edit" color="blue" /></Button>
            </Form.Field>
        );
    }
}

fieldEditors[PILOT_PROGRESSION_FIELD_TYPE] = class extends FieldEditor<any, FieldEditorNotUsableStandAloneProps> {

    getCurrentCategory() {
        let value = this.getValue();
        const date = this.props.fieldDescriptor.referenceDate ? new Date(this.props.fieldDescriptor.referenceDate) : new Date();
        let currentItemIndex = -1;
        let currentItemValidUntil = Number.MAX_SAFE_INTEGER;
        for (let i = 0; i < value.length; i++) {
            if ((!value[i].validUntil && new Date(value[i].validFrom!).getTime() <= date.getTime())
                || (value[i].validUntil && new Date(value[i].validFrom!).getTime() < date.getTime() && new Date(value[i].validUntil!).getTime() > date.getTime())) {
                currentItemIndex = i;
                break;
            } else if (new Date(value[i].validUntil!).getTime() > currentItemValidUntil) {
                currentItemValidUntil = new Date(value[i].validUntil!).getTime();
                currentItemIndex = i;
            }
        }
        if (currentItemIndex !== -1) {
            return value[currentItemIndex].category?.description;
        }
        return '';
    }

    render = () => {
        const { fieldDescriptor } = this.props;

        return (
            <Form.Field className="EmployeeEditor_historyFields">
                <Form.Input value={this.getCurrentCategory()} readOnly className={fieldDescriptor.isDisabled ? "disabled" : ""} />
                <Button icon type="button" onClick={() => fieldDescriptor.onModalOpenClose(true)}><Icon name="edit" color="blue" /></Button>
            </Form.Field>
        );
    }
}

fieldEditors[CUSTOM_HISTORY_FIELD] = class extends FieldEditor<any, FieldEditorNotUsableStandAloneProps> {

    /**
     * This method is used to process functionHistory and constraintGroupHistory fields.
     * This method transforms the functionHistory/constraintGroupHistory array to an object where elements are grouped by employeeFunction/constraintGroup field.
     * Each key of this object represents the value of "employeeFunction.id"/"constraintGroup.id" field.
     * Each value of this object represents an sub-array of functionHistory/constraintGroupHistory elements that has the "employeeFunction.id"/"constraintGroup.id" equals with the object key.
     * When a new function/constraintGroup field is added but it has no values yet, the key of the object is -1, until an employeeFunction/constraintGroup is selected for that field.
     * If an element already exists (was added directly fro DB) without a function/constraintGroup, the key is -2.
     */
    protected processHistoryField(historyField: any[], keyField: string) {
        let processedFields: { [key: number]: getEmployee_employeeService_employee_detail_functionHistory[] } = {};
        if (!historyField || historyField.length === 0) {
            return processedFields;
        }
        for (let i = 0; i < historyField.length; i++) {
            if (historyField[i]?.[keyField]?.id) {
                if (processedFields[historyField[i][keyField]!.id!]) {
                    let value = processedFields[historyField[i][keyField]!.id!];
                    value.push(historyField[i]);
                    processedFields[historyField[i][keyField]!.id!] = value;
                } else {
                    processedFields[historyField[i][keyField]!.id!] = [historyField[i]];
                }
            } else if (historyField[i]?.id && !historyField[i]?.[keyField]?.id) {
                let value = processedFields[-2] || [];
                value.push(historyField[i]);
                processedFields[-2] = value;
            } else {
                processedFields[-1] = [];
            }
        }
        return processedFields;
    }

    protected renderDisplayedFields(processedFields: { [key: number]: any[] }, fieldDescriptor: FieldDescriptor, keyField: string) {
        let fields: JSX.Element[] = [];

        for (const [key, value] of Object.entries(processedFields)) {
            let currentValue = fieldEditors[HISTORY_FIELD_TYPE].retrieveCurrentHistoryItem(value, this.props.fieldDescriptor.referenceDate);
            const fieldElement = <Form.Field className={"EmployeeEditor_historyFields" + (currentValue ? " FunctionsTab_activeFunctions" : "")}>
                <Form.Input value={value[0]?.[keyField]?.description || ""} readOnly className={fieldDescriptor.isDisabled ? "disabled" : ""} />
                <Button icon type="button" onClick={() => {
                    fieldDescriptor.onOpenCustomHistoryTable(fieldDescriptor, value, Number(key));
                }}>
                    <Icon name="edit" color="blue" />
                </Button>
                <Button disabled={fieldDescriptor.isDisabled} icon type="button" onClick={() => fieldDescriptor.onRemoveCustomHistoryField(Number(key), keyField)}><Icon name="remove" color="red" /></Button>
            </Form.Field>;
            // show the current fields on top
            if (currentValue) {
                fields.unshift(fieldElement);
            } else {
                fields.push(fieldElement);
            }
        }
        return fields;
    }

    render = () => {
        const { fieldDescriptor } = this.props;
        let keyField: string = this.props.fieldDescriptor.entityName === FUNCTION_HISTORY ? EMPLOYEE_FUNCTION_FIELD : CONSTRAINT_GROUP_FIELD;
        let historyFieldValues = this.getValue();
        let processedhistoryFieldValues = this.processHistoryField(historyFieldValues, keyField);
        return this.renderDisplayedFields(processedhistoryFieldValues, fieldDescriptor, keyField);
    }
}

export class ResourceWorkRosterStatusFieldEditor extends AssociationFieldEditor<FieldEditorProps> {
    protected async performQuery(searchQuery?: string, operationName?: string) {
        let result: getStatusesForTypes_registrationTypeStatusRelationService_statusesForTypes[] = (await apolloClient.query({
            query: REGISTRATION_TYPE_STATUS_RELATION_SERVICE_GET_STATUSES_FOR_TYPE,
            variables: {
                typeCodes: [ProteusConstants.EARLY_SHIFT, ProteusConstants.LATE_SHIFT]
            },
            context: { showSpinner: false }
        })).data.registrationTypeStatusRelationService_statusesForTypes;
        this.setState({ entities: result });
    }
}

export function addResourceWorkRosterStatusFieldEditor(entityDescriptor: EntityDescriptor) {
    entityDescriptor.addFieldDescriptor({ name: "status", type: "RegistrationStatus" }, new class extends FieldDescriptor {
        protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
            return React.createElement(ResourceWorkRosterStatusFieldEditor as any, props);
        }
    });
}

interface MoveTelecomRendererProps extends FieldRendererProps {
    moveRowUpDown: (telecom: getEmployee_employeeService_employee_telecomList, moveUp: boolean) => void;
}

export class MoveTelecomTableRenderer extends React.Component<MoveTelecomRendererProps> {

    onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, moveUp: boolean) => {
        event.stopPropagation();
        this.props.moveRowUpDown(this.props.entity, moveUp);
    }

    onDoubleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
    }

    render = () => {
        return <div className="AddressTab_arrowButtons">
            <Button onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.onClick(event, false)}
                onDoubleClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.onDoubleClick(event)}
                icon="arrow down" color="green" type="button" />
            <Button onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.onClick(event, true)}
                onDoubleClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.onDoubleClick(event)}
                icon="arrow up" color="green" type="button" />
        </div>
    }
}