'use strict';

function proteusWizardPane() {
	
	return {
		restrict: 'AE',
		require: '^^proteusWizard',
		scope: true,
		template: require('./wizard-pane.html'),
		transclude: {
			title: '?proteusWizardPaneTitle',
			content: 'proteusWizardPaneContent'
		},
		link: function($scope, $element, $attrs, wizard, $transclude) {
			if ($transclude.isSlotFilled('title')) {
				wizard.addStep($scope, $transclude.$$boundTransclude.$$slots.title);
				$scope.$watch('wizard.selected', function(selected) {
					if ($scope.step.index == selected) {
						$attrs.$addClass('active');
					} else {
						$attrs.$removeClass('active');
					}
				});
			}

			if ($attrs.disabled) {
				$scope.$watch($attrs.disabled, function(disabled) {
					$scope.step.disabled = disabled;
				});
			}

			if (angular.isDefined($attrs.optional)) {
				$scope.step.optional = true;
			}
			
			$scope.step.onShow = !$attrs.onShow ? angular.noop : function() {
				$scope.$eval($attrs.onShow);
			}
		}
	}
}

module.exports = proteusWizardPane;