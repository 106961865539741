import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { fieldEditors } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { getEmployee_employeeService_employee } from "apollo-gen/getEmployee";
import { balanceCounterHistoryItemEntityDescriptor } from "AppEntityDescriptors";
import React from "react";
import { HISTORY_FIELD_TYPE } from "./customFieldRenderersEditors";

export const sliceBalanceCounterTab = createSliceFoundation(class SliceBalanceCounterTab {

    nestedSlices = {
        balance: balanceCounterHistoryItemEntityDescriptor.infoTable.slice
    };

    reducers = {
        ...getBaseReducers<SliceBalanceCounterTab>(this)
    };

    impures = {
        ...getBaseImpures<SliceBalanceCounterTab>(this)
    };
});

type PropsNotFromState = {
    employee: getEmployee_employeeService_employee | undefined | null;
    isEditable: boolean;
    balancePaneActive: boolean;
};

/**
 * This tab is used in EmployeeEditor to manipulate the initial counters for different balances
 */
export class BalanceCounterTab extends TabbedPage<PropsFrom<typeof sliceBalanceCounterTab> & PropsNotFromState> {

    protected getEmployeeCurrentContract() {
        if (!this.props.employee?.detail?.contractHistory) {
            return undefined;
        }
        return fieldEditors[HISTORY_FIELD_TYPE].retrieveCurrentHistoryItem(this.props.employee.detail.contractHistory);
    }

    protected getEmployeeLabel() {
        const name = this.props.employee?.name;
        const firstName = this.props.employee?.firstName;
        const employeeNumber = this.getEmployeeCurrentContract()?.employeeNumber;
        return (name ? name + " " : "") + (firstName ? firstName + " " : "") + (employeeNumber ? "(" + employeeNumber + ")" : "");
    }

    render() {
        const employee = {
            id: this.props.employee?.id,
            text: this.getEmployeeLabel(),
            contractCode: this.getEmployeeCurrentContract()?.status?.code
        };
        const ed = balanceCounterHistoryItemEntityDescriptor;

        return (
            <div className={"BalanceCounterTab" + (this.props.isEditable ? "" : " BalanceCounterTab_embededTableDisabled")}>
                {this.props.balancePaneActive &&
                    <ed.infoTable.wrappedComponentClass {...this.props.balance} dispatchers={this.props.dispatchers.balance}
                        embeddedMode employee={employee} />
                }
            </div>
        );
    }
}