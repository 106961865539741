'use strict';

module.exports = {
	name: 'proteus.messaging',
	path: 'messaging.module.js',
	authRoutes: {
		'/inboxItems': {
			templateUrl: 'messaging/inbox-items-page.html',
			controller: 'InboxItemsPageCtrl',
			controllerAs: 'inboxItemsPage'
		},
		'/inboxItem/:id': {
			templateUrl: 'messaging/inbox-item-page.html',
			controller: 'InboxItemPageCtrl',
			controllerAs: 'inboxItemPage'
		}
	}
};