'use strict';

var treeItemAction = require('./tree-item-action.html'),
	treeItemMenu   = require('./tree-item-menu.html'),
	treeItemToggle = require('./tree-item-toggle.html');

/**
 * {
 * 	repeat:
 *  label:
 *  filter:
 *  expandable: show a + icon allowing to expand/collapse rows
 *  children: []
 * }
 * 
 */
/*@ngInject*/
function proteusTree($templateCache, $compile) {
	
	var defaults = {
		repeat: 'element in elements.data track by element.id',
		label: 'element.name'
	};
	
	/**
	 * Warning configTree.shouldShowExpandButton and shouldShowActions, show stay in the root, even if they are used on a deeper level in the tree.
	 */
	function Tree(configTree) {
		var tree = this;
		
		tree.expandCollapse = function(scope, $event) {
			$event.stopPropagation();
			scope.collapsed = !scope.collapsed;
		};

		tree.openCloseMenu = function(scope, event) {
			event.stopPropagation();
			scope.is_menu_open = !scope.is_menu_open;
		};
		
		tree.run = function(scope, event) {
			event.stopPropagation();
			scope.$parent.is_menu_open = false;
			scope.action.run(scope);
		};
		
		tree.shouldShowExpandButton = function(scope) {
			if (configTree.shouldShowExpandButton) {
				return configTree.shouldShowExpandButton(scope);
			}
			return true;
		};

		tree.shouldShowActions = function(scope) {
			if (configTree.shouldShowActions) {
				return configTree.shouldShowActions(scope);
			}
			return true;
		};
		
		return tree;
	}

	function createChild($scope, tree, parent) {
		var child = $(require('./tree-item.html'));
		child.addClass('proteus-tree ng-will-animate');

		parent && parent.append(child);

		// repeat items and set label (minimum to show the tree)
		child.attr('ng-repeat', tree.repeat || defaults.repeat);
		child.find('.tree-item-label').first().prepend(tree.label || defaults.label);
		child.attr('ng-hide', '$parent.collapsed');
		
		if (tree.expandable) {
			var toggle = $(treeItemToggle);
			child.find('.tree-item').first().prepend(toggle);
			
			if (angular.isString(tree.expandable)) {
				toggle.attr('ng-click', toggle.attr('ng-click') + '; ' + tree.expandable);
				child.attr('ng-init', 'collapsed = true');
			}
		} 
		
		// show detail (optional)
		if (tree.detail) {
			child.find('.tree-item-detail').first().append(tree.detail);
		}

		// show icon (optional)
		if (tree.icon) {
			child.find('.tree-item-icon').first().attr('ng-class', tree.icon);
		}
				
		// we also have the icon above, but is static (just some css classes); 
		// if the icon is dynamically determined we can use this frontDecorator (that as a template can have an angular component/directive)
		if (tree.frontDecorator) {
			if (tree.frontDecorator.template) {
				child.find('.tree-item-icon').first().append(tree.frontDecorator.template);
			}
		}

		// enable selection (optional)
		if (tree.select) {
			child.find('.list-group-item').first().attr('ng-click', tree.select);
		}

		if (tree.active) {
			child.find('.list-group-item').first().attr('ng-class', '{active: ' + tree.active + '}');
		}

		if (tree.disabled) {
			child.find('.list-group-item').first().attr('ng-disabled', tree.disabled);
		}

		if (tree.if) {
			child.attr('ng-if', tree.if);
		}

		// enable filtering (optional)
		if (tree.filter) {
			child.find('.list-group-item').first().attr('ng-init', tree.filter + '.watch(this)');
			child.find('.list-group-item').first().attr('ng-hide', '$parent.collapsed || filter_hide');
		}

		if (tree.action) {
			if (tree.action.template) {
				// here tree.action is an object with the template property
				child.find('.list-group-item').first().append(tree.action.template);
				// TODO: daca nu afisez actions, vreau sa adaug un padding!
			} else {
				// here tree.action is a string containing the action
				var action = $(treeItemAction);
				action.attr('ng-init', 'action = ' + tree.action);
				child.find('.list-group-item').first().append(action);
			}
		}
		
		// enable actions menu (optional)
		if (tree.actions) {
			var menu = $(treeItemMenu);
			menu.find('.proteus-tree-action').attr('ng-repeat', 'action in ' + tree.actions + ' track by action.index');
			child.find('.list-group-item').first().append(menu);
		}

		// recurse
		if (parent && tree.recurse) {
			var id ='proteus-tree/' + tree.recurse;
			var recurse = $('<div></div>');
			recurse.attr('ng-include', '\'' + id + '\'');
			child.append(recurse);
			var tpl = '';
			parent.children('.proteus-tree').each(function(index, elt) {
				tpl += elt.outerHTML;
			});

			$templateCache.put(id, tpl);

			parent.children('.proteus-tree').remove();
			parent.append(recurse);
		}

		angular.forEach(tree.children, function(tree) {
			createChild($scope, tree, child);
		});

		return child;
	}
	
	return {
		restrict: 'A',
		controller: angular.noop,
		link: function($scope, $elt, $attr) {
			
			var tree = $scope.$eval($attr.proteusTree);
			$scope.tree = new Tree(tree);
			
			var child = createChild($scope, tree);
			
			// compile
			var compileFn = $compile(child);
			$elt.empty().append(compileFn($scope));
		}
	};
}

module.exports = proteusTree;