import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import React from "react";
import { Button, Form, Segment } from "semantic-ui-react";
import { apolloClient } from "@crispico/foundation-react/apolloClient"
import { COMMON_SERVICE_ENDPOINT_RUN_IN_BACKGROUND, COMMUNICATION_TEST_PROCESS_REGISTRATIONS, COMMUNICATION_TEST_PROCESS_SERVICE_LIST } from "graphql/queries";
import moment from "moment";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { ProteusConstants } from "ProteusConstants";
import { Utils } from "@crispico/foundation-react";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";

const START_DATE = "startDate";
const TEST_COMMUNICATION = "TEST_COMMUNICATION";

export const sliceTestCommunication = createSliceFoundation(class SliceTestCommunication {

    initialState = {
        dataToProcess: "" as string,
        startDate: new Date() as Date
    }

    reducers = {
        ...getBaseReducers<SliceTestCommunication>(this)
    }

    impures = {
        ...getBaseImpures<SliceTestCommunication>(this),

        async processServiceList() {
            await apolloClient.mutate({
                mutation: COMMUNICATION_TEST_PROCESS_SERVICE_LIST,
                variables: {
                    serviceList: this.getState().dataToProcess
                }
            });
        },

        async generateOccupationList() {
            const backgroundTask = {
                name: _msg("TestCommunication"),
                type: TEST_COMMUNICATION,
                inputParameters: {
                    name: START_DATE,
                    label: START_DATE,
                    value: this.getState().startDate.toLocaleDateString()
                }
            };
            await apolloClient.mutate({
                mutation: COMMON_SERVICE_ENDPOINT_RUN_IN_BACKGROUND,
                variables: {
                    backgroundTask: backgroundTask
                }
            });
        },

        async processRegistrations() {
            await apolloClient.mutate({
                mutation: COMMUNICATION_TEST_PROCESS_REGISTRATIONS,
                variables: {
                    message: this.getState().dataToProcess
                }
            });
        },
    }
})

export class TestCommunication extends TabbedPage<PropsFrom<typeof sliceTestCommunication>> {

    renderProcessData() {
        return (
            <Segment className="TestCommunication_processServiceList">
                <Form>
                    <Button primary onClick={() => {
                        this.props.dispatchers.processServiceList();
                        Utils.showGlobalAlert({ message: _msg("TestCommunication.processServiceList.message"), severity: Severity.INFO });
                    }}> {_msg("TestCommunication.processServiceList.label")}
                    </Button>
                    <Button primary onClick={() => {
                        this.props.dispatchers.processRegistrations();
                        Utils.showGlobalAlert({ message: _msg("TestCommunication.processRegistrations.message"), severity: Severity.INFO });
                    }}> {_msg("TestCommunication.processRegistrations.label")}
                    </Button>
                </Form>
                <Form>
                    <Form.TextArea value={this.props.dataToProcess}
                        onChange={(e: any) => this.props.dispatchers.setInReduxState({ dataToProcess: e.target.value })} />
                </Form>
            </Segment>
        );
    }

    renderGenerateOccupationList() {
        return (
            <Segment className="TestCommunication_generateOccupationList">
                <Form>
                    <Form.Group>
                        <Form.Field>
                            <Button primary onClick={() => {
                                this.props.dispatchers.generateOccupationList();
                                Utils.showGlobalAlert({ message: _msg("TestCommunication.generateOccupationList.message"), severity: Severity.INFO });
                            }}> {_msg("TestCommunication.generateOccupationList.label")}
                            </Button>
                        </Form.Field>
                        <Form.Field>
                            <DatePickerReactCalendar format={ProteusConstants.DATE_FORMAT} allowClear={false} value={moment(this.props.startDate)}
                                onChange={(data) => this.props.dispatchers.setInReduxState({ startDate: new Date(data!.toDate()) })} />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>
        );
    }

    renderMain() {
        return (
            <div className="TestCommunication">
                {this.renderGenerateOccupationList()}
                {this.renderProcessData()}
            </ div>
        );
    }
}

export const infoTestCommunication = new ConnectedPageInfo(sliceTestCommunication, TestCommunication, "TestCommunication");
infoTestCommunication.routeProps = { permission: "TEST_COMMUNICATION_VIEW" };