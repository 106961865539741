'use strict'

/*@ngInject*/
function RegistrationMetadata(DataSource, Collection, AgendaConstants) {

	var NO_STATUS = "NO_STATUS";
	
	var config = {
		baseUrl : '/restful/registrationMetadataServiceEndpoint'
	}

	var ds = new DataSource(config);
	ds.config.get = ds.config.get || {};
	ds.config.get.method = 'GET';
	ds.config.get.url = '/metadataPropertyValue';
	if (!ds.get) {
		ds.registerOperation('get');
	}

	
	ds.config.getMetadataProvider = ds.config.getMetadataProvider || {};
	ds.config.getMetadataProvider.method = 'POST';
	ds.config.getMetadataProvider.url = '/getMetadataProvider';
	if (!ds.getMetadataProvider) {
		ds.registerOperation('getMetadataProvider');
	}

	ds.metadataProvider = {};
	
	ds.initProperties = function() {
		// metadata properties are loaded only for SYSTEM and AGENDA contexts (see server side implementation)
		ds.getMetadataProvider({}, {			
			data: {
				registrationTypes: ["WISSEL", "GEEN_VRIJETIJD", "VC", "STBY", "OUV", "TK", "BEURTWISSEL"],
				propertyCodes: [AgendaConstants.Properties.ROUNDING_TYPE_CODE, AgendaConstants.Properties.DEFAULT_STATUS_CODE]
			}
		}).then(function(result){
			ds.metadataProvider = result.data;
			ds.metadataProvider.typePropertyCodes = Collection.create(ds.metadataProvider.typePropertyCodes); 
		});
	}
	
	// Attention: we have access to a subset of metadata properties 
	ds.getPropertyValue = function(context, propertyCode, registrationTypeCode, registrationStatusCode) {		
		var value = null;
		if (AgendaConstants.MetadataContexts.SYSTEM != context) {
			var cache = ds.metadataProvider.allChaches["USER"];
			var keyForUserCache = getKeyForUserCache(context, registrationTypeCode, registrationStatusCode, propertyCode);
		 	value = cache[keyForUserCache];
		}
		
		var key = getKey(context, registrationTypeCode, registrationStatusCode, propertyCode);
		if (!value) {
			cache = ds.metadataProvider.allChaches[context];
			value = cache[key];
		}
		 
		if (!value && AgendaConstants.MetadataContexts.SYSTEM != context) {
			cache = ds.metadataProvider.allChaches[AgendaConstants.MetadataContexts.SYSTEM];				
			value = cache[key];
		}
		 
		if (!value) {
			value = ds.metadataProvider.properties[propertyCode].defaultValue;
		}
		 
		return value;
	}
	
	function getKeyForUserCache(context, registrationTypeCode, registrationStatusCode, propertyCode) {
		var status = null;
		if (ds.metadataProvider.typePropertyCodes.contains(propertyCode)) {
			status = NO_STATUS;
		} else if (registrationStatusCode) {
			status = registrationStatusCode;
		} else {
			status = ds.getPropertyValue(context, AgendaConstants.Properties.DEFAULT_STATUS_CODE, registrationTypeCode, NO_STATUS);
		}
		var key = context + "-" + registrationTypeCode + "-" + status + "-" + propertyCode;
		return key;
	}

	function getKey(context, registrationTypeCode, registrationStatusCode, propertyCode) {		
		var status = null;
		if (ds.metadataProvider.typePropertyCodes.contains(propertyCode)) {
			status = NO_STATUS;
		} else if (registrationStatusCode) {
			status = registrationStatusCode;
		} else {
			status = ds.getPropertyValue(context, AgendaConstants.Properties.DEFAULT_STATUS_CODE, registrationTypeCode, NO_STATUS);
		}
		var key = registrationTypeCode + "-" + status + "-" + propertyCode;
		return key;
	}
	
	return ds;
};

module.exports = RegistrationMetadata;