import { AppMeta } from '@crispico/foundation-react';
import { TestsAreDemoAppWrapper } from "@famiprog-foundation/tests-are-demo";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import "./proteus.css";

//@ts-ignore
window.isProteusReact = true;
require("./scripts/app/app");
require("./scripts/app/account/account.module");
require('./scripts/app/messaging/messaging.module');
require('./scripts/app/tasks/tasks.module');
require('./scripts/app/planning/planning.module');
require('./scripts/app/credit/credit.module');
require('./scripts/app/education/education.module');
require('./scripts/app/react/react.module');

ReactDOM.render(<TestsAreDemoAppWrapper app={<App />} importTestsCallback={async () => await import("./appTests")} serverUrlPrefix="proteus/" />, document.getElementById('root'));

