import { apolloClient, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { getEmployee_employeeService_employee } from "apollo-gen/getEmployee";
import { registrationTypes_planningServiceEndpoint_registrationTypesWithProperty } from "apollo-gen/registrationTypes";
import { BalanceCalculation, BalanceType, sliceBalanceCalculation } from "components/balanceCalculation/BalanceCalculation";
import { BALANCE_SERVICE_FACADE_BEAN_FIND_BALANCE_TYPES_FOR_REGISTRATIONS } from "graphql/queries";
import { ProteusConstants } from "ProteusConstants";
import { createRef, SyntheticEvent } from "react";
import { Dropdown, DropdownItemProps, Form } from "semantic-ui-react";

const VERLOF_COMPENSATIE_CODE = "VC";

export const sliceBalanceTab = createSliceFoundation(class SliceBalanceTab {
    initialState = {
        registrationTypesWithBalanceCounters: undefined as unknown as registrationTypes_planningServiceEndpoint_registrationTypesWithProperty[],
        selectedBalance: undefined as unknown as registrationTypes_planningServiceEndpoint_registrationTypesWithProperty,
        defaultBalance: undefined as unknown as registrationTypes_planningServiceEndpoint_registrationTypesWithProperty,
    };

    nestedSlices = {
        balanceCalculation: sliceBalanceCalculation
    };

    reducers = {
        ...getBaseReducers<SliceBalanceTab>(this)
    };

    impures = {
        ...getBaseImpures<SliceBalanceTab>(this),

        async getRegistrationTypesWithUseBalanceCounter() {
            let name = `balanceServiceFacadeBean_balanceTypesFromRegistrationType`;
            let query = BALANCE_SERVICE_FACADE_BEAN_FIND_BALANCE_TYPES_FOR_REGISTRATIONS;

            let registrationTypesWithBalanceCounters: registrationTypes_planningServiceEndpoint_registrationTypesWithProperty[] = (await apolloClient.query({
                query: query,
                context: { showSpinner: false }
            })).data[name];

            let defaultBalance = registrationTypesWithBalanceCounters.filter(registration => registration.code === VERLOF_COMPENSATIE_CODE)[0];
            if (!defaultBalance) {
                defaultBalance = registrationTypesWithBalanceCounters[0];
            }

            this.getDispatchers().setInReduxState({ registrationTypesWithBalanceCounters, selectedBalance: defaultBalance, defaultBalance });
        }
    };
});

type BalanceTabExtraProps = {
    employee: getEmployee_employeeService_employee | undefined | null;
};

type BalanceTabProps = PropsFrom<typeof sliceBalanceTab> & BalanceTabExtraProps;

/**
 * This tab is used to see the results of different balances for the current year
 */
export class BalanceTab extends TabbedPage<BalanceTabProps> {
    refBalanceCalculation = createRef<BalanceCalculation>();

    resetBalanceData = async () => {
        this.props.dispatchers.setInReduxState({ selectedBalance: this.props.defaultBalance });
        this.props.dispatchers.balanceCalculation.setInReduxState({ balanceData: [] });
    };

    getOptionsForSelect = () => {
        const selectOptions: DropdownItemProps[] = this.props.registrationTypesWithBalanceCounters?.map(registration => {
            return {
                value: registration.code!,
                text: registration.description
            };
        });

        return selectOptions;
    };

    shouldLoadLeaveDistributionBalance(code: string) {
        return code === ProteusConstants.LEAVE_TYPE_CODE || code == ProteusConstants.LEAVE_HOURS_BALANCE_TYPE_CODE;
    }

    handleSelectOptionChange = async (event: SyntheticEvent<HTMLElement, Event>, data: any) => {
        let selectedBalance = this.props.registrationTypesWithBalanceCounters.filter(registration => registration.code === data.value)[0];
        this.props.dispatchers.setInReduxState({ selectedBalance });
        await this.props.dispatchers.balanceCalculation.loadBalanceTabData(
            this.props.employee?.id!,
            data.value,
            this.shouldLoadLeaveDistributionBalance(data.value)
        );
    };

    renderSaveBarAdditionalContent() {
        return <>
            <Form.Field>
                <Dropdown fluid selection noResultsMessage={_msg("general.noResultsFound")}
                    options={this.getOptionsForSelect()} value={this.props.selectedBalance?.code!} placeholder={"Select balance"}
                    onChange={async (e: any, data: any) => this.handleSelectOptionChange(e, data)}
                    defaultValue={this.props.defaultBalance?.name!}
                />
            </Form.Field>
            {this.refBalanceCalculation.current?.renderBalanceDateInput()}
        </>;
    }

    render() {
        return this.props.employee?.id ? <BalanceCalculation
            {...this.props.balanceCalculation}
            dispatchers={this.props.dispatchers.balanceCalculation}
            ref={this.refBalanceCalculation}
            employee={this.props.employee?.id}
            balanceType={this.props.selectedBalance as BalanceType}
            addDeductionInfo={true}
        /> : <></>;
    }

    componentWillUnmount(): void {
        this.props.dispatchers.setInReduxState({
            selectedBalance: this.props.defaultBalance
        });
    }
}