import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { apolloClient, Utils } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { getGroupSequenceOverview_sequenceServiceFacadeBean_groupSequenceOverview, getGroupSequenceOverview_sequenceServiceFacadeBean_groupSequenceOverview_employeesSequences, getGroupSequenceOverview_sequenceServiceFacadeBean_groupSequenceOverview_employeesSequences_sequencesItems } from "apollo-gen/getGroupSequenceOverview";
import { getGroupSnapshotsByContext_employeeService_groupSnapshotsByContext } from "apollo-gen/getGroupSnapshotsByContext";
import { getReportDefinitionByCode_reportingServiceFacadeBean_reportDefinition } from "apollo-gen/getReportDefinitionByCode";
import { COMMON_SERVICE_ENDPOINT_RUN_IN_BACKGROUND, EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT, REPORTING_SERVICE_FACADE_BEAN_GET_REPORT_DEFINITION, SEQUENCE_SERVICE_FACADE_BEAN_GET_GROUP_SEQUENCE_OVERVIEW } from "graphql/queries";
import Interweave from "interweave";
import moment from "moment-timezone";
import { DATE, GROUP_PICKER } from "pages/reportDefinition/ReportDefinitionEntityDescriptor";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import React from "react";
import { Button, Dropdown, Form, Icon, Segment, Table } from "semantic-ui-react";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";

const MAX_COLUMNS = 23;

export const sequences = createSliceFoundation(class SliceSequences {

    initialState = {
        groups: undefined as unknown as getGroupSnapshotsByContext_employeeService_groupSnapshotsByContext[],
        selectedGroup: undefined as unknown as getGroupSnapshotsByContext_employeeService_groupSnapshotsByContext,
        selectedDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1) as string | Date,
        sequenceReportDefinition: undefined as unknown as getReportDefinitionByCode_reportingServiceFacadeBean_reportDefinition,
        groupSequenceOverview: undefined as unknown as getGroupSequenceOverview_sequenceServiceFacadeBean_groupSequenceOverview
    }

    reducers = {
        ...getBaseReducers<SliceSequences>(this)
    }

    impures = {
        ...getBaseImpures<SliceSequences>(this),

        async getInitialData(groupCode: string | undefined) {
            const groups = (await apolloClient.query({
                query: EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT,
                variables: {
                    owner: ProteusUtils.getCurrentUser().id,
                    groupContextCode: ProteusConstants.SEQUENCE_CONTEXT_CODE
                }
            })).data.employeeService_groupSnapshotsByContext;

            const sequenceReportDefinition = (await apolloClient.query({
                query: REPORTING_SERVICE_FACADE_BEAN_GET_REPORT_DEFINITION,
                variables: {
                    code: ProteusConstants.SEQUENCE_REPORT_CODE
                }
            })).data.reportingServiceFacadeBean_reportDefinition;

            let selectedGroup = groupCode !== undefined ? groups.filter((item: getGroupSnapshotsByContext_employeeService_groupSnapshotsByContext) => item.code === groupCode)[0] : undefined;
            if (selectedGroup !== undefined) {
                await this.loadDataForTable(selectedGroup.code, this.getState().selectedDate, false);
            }

            this.getDispatchers().setInReduxState({
                groups: groups,
                selectedGroup: selectedGroup,
                sequenceReportDefinition: sequenceReportDefinition
            });
        },

        async loadDataForTable(groupCode: string, fromDate: string | Date, loadScreen: boolean) {
            let toDate = new Date(fromDate);
            toDate.setMonth(toDate.getMonth() + 7);
            const groupSequenceOverview = (await apolloClient.query({
                query: SEQUENCE_SERVICE_FACADE_BEAN_GET_GROUP_SEQUENCE_OVERVIEW,
                variables: {
                    groupCode: groupCode,
                    from: fromDate,
                    to: toDate,
                    maxNumberOfRosterRegistrations: MAX_COLUMNS
                },
                context: { showSpinner: loadScreen }
            })).data.sequenceServiceFacadeBean_groupSequenceOverview;
            this.getDispatchers().setInReduxState({ groupSequenceOverview });
        },

        runReport() {
            const currentUser = ProteusUtils.getUserCompleteName();
            let runVariable: { [k: string]: any } = {
                type: ProteusConstants.REPORT_GENERATION,
                name: ProteusConstants.SEQUENCE_REPORT_NAME
            };
            let inputParameters = [];
            const parameters = this.getState().sequenceReportDefinition.parameters || [];
            inputParameters.push({ name: "RapportGebruiker", value: currentUser });
            inputParameters.push({ name: "reportCode", value: ProteusConstants.SEQUENCE_REPORT_CODE });
            for (let i = 0; i < parameters.length; i++) {
                let value = "";
                if (parameters[i]?.prompting === true) {
                    if (parameters[i]?.type === DATE) {
                        const date = new Date(this.getState().selectedDate);
                        value = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
                    } else if (parameters[i]?.type === (GROUP_PICKER + ProteusConstants.SEQUENCE_CONTEXT_CODE)) {
                        value = this.getState().selectedGroup.code || "";
                    }
                    if (value !== "") {
                        inputParameters.push({ name: parameters[i]?.completeName, value: value, label: parameters[i]?.label });
                    }
                }
            }
            runVariable.inputParameters = inputParameters;
            Utils.showGlobalAlert({ message: _msg("ReportDefinition.runMessage", ProteusConstants.SEQUENCE_REPORT_NAME), severity: Severity.INFO });
            apolloClient.mutate({
                mutation: COMMON_SERVICE_ENDPOINT_RUN_IN_BACKGROUND,
                variables: {
                    backgroundTask: runVariable
                }
            });
        }
    }
})

export class Sequences extends TabbedPage<PropsFrom<typeof sequences>> {

    protected getTabbedPageCssClasses() {
        return super.getTabbedPageCssClasses() + " Sequences_noOverflow";
    }

    protected getGroupOptions() {
        if (this.props.groups === undefined) {
            return [];
        }
        return this.props.groups.map((item: getGroupSnapshotsByContext_employeeService_groupSnapshotsByContext) => ({
            key: item.id as number,
            text: item.name as string,
            value: item.id as number
        }));
    }

    protected onArrowsClick(dayToAdd: number) {
        let date = new Date(this.props.selectedDate);
        date.setDate(date.getDate() + dayToAdd);
        if (this.props.selectedGroup !== undefined) {
            this.props.dispatchers.loadDataForTable(this.props.selectedGroup.code!, date, true);
        }
        this.props.dispatchers.setInReduxState({ selectedDate: date });
    }

    protected formatDate(date: string) {
        return moment(new Date(date)).format("DD/MM");
    }

    protected getTableHeaderValue(sequence: getGroupSequenceOverview_sequenceServiceFacadeBean_groupSequenceOverview_employeesSequences_sequencesItems | null): string {
        if (!sequence) {
            return "";
        } else if (sequence.workPeriodType?.code === ProteusConstants.DAG_24H) {
            return this.formatDate(sequence.from) + " - " + this.formatDate(sequence.to);
        } else {
            return this.formatDate(sequence.from);
        }
    }

    protected renderTableHeader() {
        if (!this.props.groupSequenceOverview) {
            return;
        }

        let header = [];
        header.push(<Table.HeaderCell textAlign='center' width={2} className="Sequences_nameColumn" key={0}>{this.props.selectedGroup.name}</Table.HeaderCell>);
        if (this.props.groupSequenceOverview.employeesSequences && this.props.groupSequenceOverview.employeesSequences.length > 0 &&
            this.props.groupSequenceOverview.employeesSequences[0]!.sequencesItems) {
            // if the group contains employees, get the header content from sequencesItems, because this object contains the "workPeriodType" too
            let sequences = this.props.groupSequenceOverview.employeesSequences[0]!.sequencesItems;
            for (let i = 0; i < sequences.length; i++) {
                header.push(<Table.HeaderCell textAlign='center' key={i + 1}>{this.getTableHeaderValue(sequences[i])}</Table.HeaderCell>);
            }
        } else if (this.props.groupSequenceOverview.workPeriods && this.props.groupSequenceOverview.workPeriods.length > 0) {
            // in case there are no employees, get the header content from employeesSequences.workPeriods
            for (let i = 0; i < this.props.groupSequenceOverview.workPeriods!.length; i++) {
                header.push(<Table.HeaderCell textAlign='center' key={i + 1}>{this.formatDate(this.props.groupSequenceOverview.workPeriods[i]?.fromDate)}</Table.HeaderCell>);
            }
        }
        return header;
    }

    protected getTableRowValue(sequence: getGroupSequenceOverview_sequenceServiceFacadeBean_groupSequenceOverview_employeesSequences | null) {
        if (!sequence || !sequence.sequencesItems) {
            return;
        }

        let row = [];
        row.push(<Table.Cell textAlign='left' key={0}>{sequence?.employee?.name + " " + sequence?.employee?.firstName}</Table.Cell>);
        for (let i = 0; i < sequence.sequencesItems.length; i++) {
            row.push(<Table.Cell textAlign='center' key={i + 1}>{sequence.sequencesItems[i]!.sequenceNumber}</Table.Cell>);
        }
        return row;
    }

    protected renderTableBody() {
        if (!this.props.groupSequenceOverview?.employeesSequences) {
            return;
        }

        let body = [];
        for (let i = 0; i < this.props.groupSequenceOverview.employeesSequences.length; i++) {
            body.push(<Table.Row key={i}>{this.getTableRowValue(this.props.groupSequenceOverview.employeesSequences[i])}</Table.Row>);
        }
        return body;
    }

    protected renderTable() {
        if (!this.props.groupSequenceOverview || !this.props.selectedGroup) {
            return;
        }

        return (
            <Table celled unstackable compact selectable>
                <Table.Header>
                    <Table.Row>
                        {this.renderTableHeader()}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.renderTableBody()}
                </Table.Body>
            </Table>
        );
    }

    protected getGroupCodeIfRedirectedFromSequenceTab() {
        let location: string = (this.props as any).history.location.pathname;
        // the location looks like "/Sequences" or "/Sequences/group_code"
        return location.split("/")[2];
    }

    protected renderMain() {
        return (
            <div className="Sequences flex">
                <Segment className="Sequences_topSegment">
                    <Button icon="refresh" color="green" disabled={!this.props.selectedGroup} onClick={async () => {
                        await this.props.dispatchers.loadDataForTable(this.props.selectedGroup.code!, this.props.selectedDate, true);
                    }} />
                    <Form.Field inline>
                        <label>{_msg("group.label")}</label>&nbsp;
                        <Dropdown floating selectOnBlur={false} scrolling wrapSelection search={true} options={this.getGroupOptions()}
                            selection value={this.props.selectedGroup?.id || ""} noResultsMessage={_msg("general.noResultsFound")}
                            placeholder={_msg("general.selectGroup")} onChange={(e, data) => {
                                const group = this.props.groups.filter((item: getGroupSnapshotsByContext_employeeService_groupSnapshotsByContext) => item.id === data.value)[0];
                                this.props.dispatchers.loadDataForTable(group.code!, this.props.selectedDate, true);
                                this.props.dispatchers.setInReduxState({ selectedGroup: group });
                            }}
                        />
                    </Form.Field>
                    <Form>
                        <div key="div1" className="EntityTablePage_barDivider Sequences_barDivider" />
                        <label>{_msg("fromDate.label")}</label>&nbsp;
                        <Button icon onClick={() => this.onArrowsClick(-1)}>
                            <Icon name="arrow left" />
                        </Button>
                        <Form.Field>
                            <DatePickerReactCalendar format="DD/MM/YYYY" allowClear={false} value={moment(this.props.selectedDate)}
                                onChange={(date) => {
                                    if (this.props.selectedGroup !== undefined) {
                                        this.props.dispatchers.loadDataForTable(this.props.selectedGroup.code!, date!.toDate(), true);
                                    }
                                    this.props.dispatchers.setInReduxState({ selectedDate: date!.toDate() });
                                }}
                            />
                        </Form.Field>
                        <Button icon onClick={() => this.onArrowsClick(1)}>
                            <Icon name="arrow right" />
                        </Button>
                        <div key="div2" className="EntityTablePage_barDivider Sequences_barDivider" />
                    </Form>
                    <Form>
                        <Form.Field>
                            <Button primary onClick={() => this.props.dispatchers.runReport()} disabled={!this.props.selectedGroup}>
                                {_msg("Sequences.report.label")}
                            </Button>
                        </Form.Field>
                    </Form>
                </Segment>
                <Segment className="Sequences_tableSegment">
                    {this.props.selectedGroup === undefined &&
                        <div>
                            <Interweave content={_msg("Sequences.noGroupSelected.label")} />
                        </div>
                    }
                    {this.renderTable()}
                </Segment>
            </div>
        );
    }

    componentDidMount() {
        const groupCode = this.getGroupCodeIfRedirectedFromSequenceTab();
        this.props.dispatchers.getInitialData(groupCode);
    }
}

export const infoSequences = new ConnectedPageInfo(sequences, Sequences, "Sequence");
infoSequences.routeProps = { permission: "SEQUENCES_VIEW" };