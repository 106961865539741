'use strict';

function balanceCounterEditor() {
	
	return {
		restrict: 'E',
		template: require('./balance-counter-editor.html'),
		scope: {
			item: '=' /* the balance counter history item*/
		}, 
		controller: /*@ngInject*/ function($scope, Balance) {
			var bcEditor = $scope.bcEditor;
			bcEditor.item = $scope.item;
			bcEditor.item.validFrom = !bcEditor.item.validFrom ? null : new Date(bcEditor.item.validFrom);
			bcEditor.item.validUntil = !bcEditor.item.validUntil ? null : new Date(bcEditor.item.validUntil);
			
			bcEditor.counterTypesSelectModel = {
					data: [],
					selection: bcEditor.item.counterType,
					labelField: 'description',
					onSelect: function(data) {
						bcEditor.item.counterType = data;
					},
					style: {
						"dropdown-menu-left": true
					}
				};
			
			Balance.getCounterTypes({}, {
				params: {
					balanceTypeCode: bcEditor.item.balanceType.code
				}
			}).then(function(r){
				bcEditor.counterTypesSelectModel.data = r.data; // counter types	
				
				if (!bcEditor.item.id && !bcEditor.item.counterType) {
					bcEditor.counterTypesSelectModel.selection = bcEditor.counterTypesSelectModel.data[0];
					bcEditor.item.counterType = bcEditor.counterTypesSelectModel.data[0];
				} 
			});
			
		},
		controllerAs: 'bcEditor'
	};
}

module.exports = balanceCounterEditor;