import { createSliceFoundation, EntityDescriptor, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { FieldEditor, FieldEditorNotUsableStandAloneProps, ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { NumberFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/NumberFieldEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import _ from "lodash";
import { Competence, CompetenceCategory } from "pages/competences/Competences";
import { CompetenceTreeRRC } from "pages/competences/CompetenceTree";
import React from "react";
import { Button, Form, Icon, InputProps, Label, Menu, Modal, Tab } from "semantic-ui-react";
import { Component, ComponentVersion } from "../Catalog";
import { evaluationCriteriaDescriptor, NAME_VERSION_SEPARATOR } from "../customFieldRendererEditors";
import { CatalogItemEditor } from "./CatalogItemEditor";
import { SliceComponentItemEditor } from "./ComponentItemEditor";

const COMPETENCE_VERSION = "competenceVersion";
const NAME = "name";
const NORM_PERCENTAGE = "normPercentage";
const OWN_SCORE = "ownScore";
const REQUIRED = "required";
const SELECTED_COMPETENCE_VERSION = "selectedCompetenceVersion";
const WEIGHT = "weight";

const sliceComponentItemEditor = createSliceFoundation(SliceComponentItemEditor, true);

interface EvaluationDetails {
    competence?: any,
    normPercentage?: number | string,
    weight?: number | string,
    required?: boolean,
    ownScore?: boolean;
}

export const sliceEvaluationComponentItemEditor = createSliceFoundation(class SliceEvaluationcationComponentItemEditor extends SliceComponentItemEditor {
    initialState = {
        ...sliceComponentItemEditor.initialState,
        rootCompetences: undefined as unknown as CompetenceCategory[],
        openModalAddCompetences: false,
        evaluationToBeAddedDetails: {} as EvaluationDetails,
        isCompetenceTreeModalOpen: false,
        initialCompetenceVersion: undefined as any
    };

    nestedSlices = {
        ...sliceComponentItemEditor.nestedSlices,
        evaluationCriteriaTable: sliceEntityTableLight,
    };

    reducers = {
        ...sliceComponentItemEditor.reducers,
        ...getBaseReducers<SliceEvaluationcationComponentItemEditor>(this),

        update(state: StateFrom<SliceEvaluationcationComponentItemEditor>, p: { componentVersion: ComponentVersion, dirty: boolean; }) {
            state.component.currentComponentVersion = p.componentVersion;
            state.dirty = p.dirty;
        },

        resetSelectedCompetence(state: StateFrom<SliceEvaluationcationComponentItemEditor>) {
            state.evaluationToBeAddedDetails.competence = undefined;
        },

        onSaveComponent(state: StateFrom<SliceEvaluationcationComponentItemEditor>) {
            state.createNewMajorVersion = false;
            state.defaultComponent = state.component;
            state.dirty = false;
        }
    };

    impures = {
        ...getBaseImpures<SliceEvaluationcationComponentItemEditor>(this),

    };
});

type CompetenceFieldEditorProps = FieldEditorNotUsableStandAloneProps & {
    rootCompetences: any;
    criterias: any;
    initialCompetenceVersion: any;
};

export class CompetenceFieldEditor extends FieldEditor<any, CompetenceFieldEditorProps, { isCompetenceTreeModalOpen: boolean; }> {

    constructor(props: CompetenceFieldEditorProps) {
        super(props);
        this.state = {
            isCompetenceTreeModalOpen: false,
        };
    }

    componentDidMount(): void {
        if (!this.props.formikProps.values.normPercentage) {
            this.props.formikProps.setFieldValue(NORM_PERCENTAGE, 0);
        }

        if (!this.props.formikProps.values.required) {
            this.props.formikProps.setFieldValue(REQUIRED, true);
        }

        if (!this.props.formikProps.values.ownScore) {
            this.props.formikProps.setFieldValue(OWN_SCORE, false);
        }
    }

    onSelectCompetence = (competence: Competence) => {
        this.props.formikProps.setFieldValue(SELECTED_COMPETENCE_VERSION, competence.currentCompetenceVersion);
    };

    setCompetence = () => {
        this.props.formikProps.setFieldValue(COMPETENCE_VERSION, this.props.formikProps.values.selectedCompetenceVersion);
        this.props.formikProps.setFieldValue(NAME, this.props.formikProps.values.selectedCompetenceVersion.name);
        this.setState({ isCompetenceTreeModalOpen: false });
    };

    resetCompetence = () => {
        this.setState({ isCompetenceTreeModalOpen: false });
        this.props.formikProps.setFieldValue(SELECTED_COMPETENCE_VERSION, undefined);
    };

    render = () => {
        const competenceVersion = this.props.formikProps.values[COMPETENCE_VERSION];
        const isCompetenceVersionDuplicate = this.props.criterias && this.props.criterias?.length !== 0 && competenceVersion?.id !== this.props.initialCompetenceVersion?.id && this.props.criterias?.filter((criteria: any) => criteria.competenceVersion.id === competenceVersion?.id).length !== 0;
        return <>
            <div className="CompetenceRenderer_formContainer">
                <span className="flex-grow CompetenceRenderer_inputContainer">
                    <Form.Input className="w100" readOnly value={this.props.formikProps.values.name?.split(NAME_VERSION_SEPARATOR)[0]} />
                </span>
                <Button icon type="button" onClick={() => this.setState({ isCompetenceTreeModalOpen: true })}>
                    <Icon name='edit' color="blue" />
                </Button>
            </div>
            {isCompetenceVersionDuplicate && <span style={{color: 'var(--red)'}}>{_msg("EvaluationComponentItemEditor.duplicateCriterion.label")}</span>}
            <ModalExt open={this.state.isCompetenceTreeModalOpen} size="large" onClose={() => this.setState({ isCompetenceTreeModalOpen: false })}>
                <Modal.Header>
                    {_msg("EvaluationComponentItemEditor.competences.label")}
                </Modal.Header>
                <Modal.Content scrolling>
                    <CompetenceTreeRRC
                        id="CompetenceTreeRRC"
                        searchExpression=""
                        root={this.props.rootCompetences}
                        active notActive hideActions
                        onSelectCompetenceTemp={this.onSelectCompetence}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Modal.Actions>
                        <Button onClick={() => this.resetCompetence()}>
                            {_msg("Catalog.cancel.label")}
                        </Button>
                        <Button onClick={() => this.setCompetence()}>
                            {_msg("Catalog.ok.label")}
                        </Button>
                    </Modal.Actions>
                </Modal.Actions>
            </ModalExt>
        </>;
    };
}

type WeightFieldEditorProps = FieldEditorNotUsableStandAloneProps & {
    totalWeight: number;
};

export class WeightFieldEditor extends FieldEditor<number, WeightFieldEditorProps, { initialWeight: number; }> {

    constructor(props: WeightFieldEditorProps) {
        super(props);
        this.state = {
            initialWeight: this.getValue() | 0
        };
    }

    componentDidMount(): void {
        if (!this.getValue()) {
            this.props.formikProps.setFieldValue(WEIGHT, 0);
            this.props.formikProps.setFieldValue(NORM_PERCENTAGE, 1);
        }
    }

    onChange (value: any, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const newValue = Number(value) > 100 ? 100 : Number(value) < 0 ? 0 : Number(value);
        s.setFieldValue(hw, newValue);
    };

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        return <>
            <Form.Input value={this.getValue()} type="number" onChange={(event, data) => this.onChange(data.value, s, hw)} />
            {_msg("EvaluationCriteria.weightMessage.label")}
            <Label color={this.props.totalWeight - this.state.initialWeight + this.getValue() <= 100 ? "green" : "red"}>
                {this.props.totalWeight - this.state.initialWeight + this.getValue()}
            </Label>
        </>;
    };
}

export class NormFieldEditor extends NumberFieldEditor<FieldEditorProps> {
    constructor(props: FieldEditorProps) {
        super(props);

        this.scriptableUiImpl = ScriptableUi.extendImpl(this.scriptableUiImpl, original => ({
            setFieldValue: (value: any) => {
                let customValue = Number(value) > 100 ? 100 : Number(value) < 1 ? 1 : Number(value);
                original.setFieldValue(customValue);
            }
        }));
    }

    protected getInputProps(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper): InputProps | undefined {
        return { ...super.getInputProps(s, hw), min: 1, max: 100 };
    }
}

type Props = PropsFrom<typeof sliceEvaluationComponentItemEditor>;

export class EvaluationComponentItemEditor extends CatalogItemEditor<Props> {
    protected entityTableLightRef = React.createRef<EntityTableLight>();

    setEvaluationComponent(component: Component, competenceCategories: CompetenceCategory[]) {
        const children = component.currentComponentVersion.evaluationDefinitionVersion?.evaluationCriterionVersions;
        this.props.dispatchers.setInReduxState({ rootCompetences: competenceCategories });
        this.setEvaluationCriterionTable(children);
        this.props.dispatchers.setComponent(component);
    }

    setEvaluationCriterionTable(children: any) {
        let childrenForTable = [];
        for (let i = 0; i < children?.length; i++) {
            childrenForTable.push({
                name: children[i].competenceVersion.name + NAME_VERSION_SEPARATOR +
                    `v${children[i].competenceVersion.majorVersion}.${children[i].competenceVersion.minorVersion}`,
                normPercentage: children[i].normPercentage,
                weight: children[i].weight,
                required: children[i].required,
                ownScore: children[i].ownScore,
                competenceVersion: children[i].competenceVersion,
                selectedCompetenceVersion: children[i].competenceVersion
            });
        }
        this.entityTableLightRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(childrenForTable);
    }

    getEditorForEvaluationCriteria() {
        let that = this;
        return new EntityDescriptor({ name: "EvaluationCriteria" })
            .addFieldDescriptor({ name: "evaluationCriteria" }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: CompetenceFieldEditorProps) {
                    const newProps = {
                        ...props,
                        rootCompetences: that.props.rootCompetences,
                        criterias: that.props.component.currentComponentVersion.evaluationDefinitionVersion?.evaluationCriterionVersions,
                        initialCompetenceVersion: that.props.initialCompetenceVersion
                    };
                    return React.createElement(CompetenceFieldEditor as any, newProps as FieldEditorProps);
                }
            }())
            .addFieldDescriptor({ name: NORM_PERCENTAGE }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    return React.createElement(NormFieldEditor as any, props as FieldEditorProps);
                }
            }())
            .addFieldDescriptor({ name: WEIGHT }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: CompetenceFieldEditorProps) {
                    const newProps = {
                        ...props,
                        totalWeight: that.getTotalWeight()
                    };
                    return React.createElement(WeightFieldEditor as any, newProps as FieldEditorProps);
                }
            }())
            .addFieldDescriptor({ name: REQUIRED, type: FieldType.boolean })
            .addFieldDescriptor({ name: OWN_SCORE, type: FieldType.boolean });
    }

    onAddCompetenceToEvaluation = (evaluationCriteria: any[], modifiedEvaluationCriterion: any) => {
        let component = _.cloneDeep(this.props.component);
        if (!component.currentComponentVersion.evaluationDefinitionVersion) {
            component.currentComponentVersion.evaluationDefinitionVersion = {
                evaluationCriterionVersions: [],
                name: " ",
                version: 0,
                evaluationDefinition: {
                    alsoSelfEvaluation: false,
                    visibleToEmployees: false
                }
            };
        }
        if (modifiedEvaluationCriterion.rowIndex === -1) {
            component.currentComponentVersion.evaluationDefinitionVersion?.evaluationCriterionVersions.push({
                ...modifiedEvaluationCriterion.values,
                name: undefined
            });
        } else {
            component.currentComponentVersion.evaluationDefinitionVersion.evaluationCriterionVersions[modifiedEvaluationCriterion.rowIndex] = {
                ...component.currentComponentVersion.evaluationDefinitionVersion.evaluationCriterionVersions[modifiedEvaluationCriterion.rowIndex],
                ...modifiedEvaluationCriterion.values,
                name: undefined
            };
        }

        this.setEvaluationCriterionTable(component.currentComponentVersion.evaluationDefinitionVersion?.evaluationCriterionVersions);
        this.props.dispatchers.setInReduxState({ component });
        this.update(component.currentComponentVersion);
    };

    onDeleteCompetenceFromEvaluation(remainingEntities: any[], removedEntityIndex: number) {
        let component = _.cloneDeep(this.props.component);
        component.currentComponentVersion.evaluationDefinitionVersion?.evaluationCriterionVersions.splice(removedEntityIndex, 1);

        this.setEvaluationCriterionTable(component.currentComponentVersion.evaluationDefinitionVersion?.evaluationCriterionVersions);
        this.props.dispatchers.setInReduxState({ component });
        this.update(component.currentComponentVersion);
    }

    onItemCustomEdit(entity: any, rowIndex: number) {
        this.props.dispatchers.setInReduxState({ initialCompetenceVersion: entity[COMPETENCE_VERSION] });
        this.entityTableLightRef.current!.getEntityFormLightRef().current!.open(entity, rowIndex);
    }

    onAdd() {
        this.props.dispatchers.setInReduxState({ initialCompetenceVersion: undefined });
        return;
    }

    getEvaluationsTab() {
        const children = this.props.component.currentComponentVersion?.evaluationDefinitionVersion?.evaluationCriterionVersions;
        return {
            menuItem: (
                <Menu.Item key='competences'>
                    {this.validateWeight() && <Icon name="exclamation triangle" color="red" />}
                    {_msg("EvaluationComponentItemEditor.evaluationCriterion.label")}
                    <Label className="EvaluationComponentItemEditor_tabLabel">
                        {children?.length}
                    </Label>
                    <Label className="EvaluationComponentItemEditor_tabLabel" color={this.validateWeight() ? "red" : "green"}>
                        {this.getTotalWeight()}
                    </Label>
                </Menu.Item>
            ),
            render: () => {
                const initialCompetenceVersion = this.props.initialCompetenceVersion;
                let competenceVersion: any;
                if (this.entityTableLightRef.current?.getEntityFormLightRef().current?.props.refEditor?.current) {
                    competenceVersion = this.entityTableLightRef.current?.getEntityFormLightRef().current?.props.refEditor?.current?.formik?.current?.values[COMPETENCE_VERSION];
                } else {
                    competenceVersion = this.props.initialCompetenceVersion;
                }
                const criterias = this.props.component.currentComponentVersion?.evaluationDefinitionVersion?.evaluationCriterionVersions;
                const isCompetenceVersionDuplicate = criterias && criterias.length !== 0 && competenceVersion?.id !== initialCompetenceVersion?.id && criterias.filter((criteria: any) => criteria.competenceVersion.id === competenceVersion?.id).length !== 0;
                return <Tab.Pane className="flex flex-container flex-grow">
                    <div className={`flex flex-grow flex-container Catalog ${this.props.isOldVersionSelected ? 'Catalog_disabled' : ''}`}>
                        <EntityTableLight {...this.props.evaluationCriteriaTable}
                            dispatchers={this.props.dispatchers.evaluationCriteriaTable}
                            entityDescriptor={evaluationCriteriaDescriptor}
                            ref={this.entityTableLightRef}
                            actions={{
                                showAddButton: !this.props.isOldVersionSelected,
                                showEditButton: !this.props.isOldVersionSelected,
                                doNotAddEntityToTable: true,
                                saveFormDisabled: _.isUndefined(competenceVersion) || isCompetenceVersionDuplicate
                            }}
                            onSave={(evaluationCriteria: any[], modifiedEvaluationCriterion: any) => this.onAddCompetenceToEvaluation(evaluationCriteria, modifiedEvaluationCriterion)}
                            onDelete={(remainingEntities, removedEntityIndex) => this.onDeleteCompetenceFromEvaluation(remainingEntities, removedEntityIndex)} onAdd={() => this.onAdd()}
                            formCustomizer={{ headerIcon: "plus", headerContent: _msg("Catalog.add.label"), customEntityDescriptorForEditor: this.getEditorForEvaluationCriteria(), onItemCustomEdit: (entity: any, rowIndex: number) => this.onItemCustomEdit(entity, rowIndex) }}
                            getConditionForShowingDelete={() => !this.props.isOldVersionSelected} />
                    </div>
                </Tab.Pane>;
            },
        };
    }

    getTotalWeight = () => {
        const competences = this.props.dispatchers.getState().component.currentComponentVersion?.evaluationDefinitionVersion?.evaluationCriterionVersions;
        if (!competences) {
            return 0;
        }
        return competences?.reduce((acc: number, competence: any) => acc + competence.weight, 0);
    };

    validateWeight = () => {
        return this.getTotalWeight() !== 100;
    };

    isDirty(): boolean {
        return (!_.isEqual(this.props.dispatchers.getState().component, this.props.defaultComponent) || this.props.createNewMajorVersion) && !this.validateWeight();
    }

    update(componentVersion: ComponentVersion): void {
        const dirty = this.isDirty();
        this.props.dispatchers.update({ componentVersion, dirty });
        super.update(componentVersion);
    }

    getPanes = () => [
        this.getGeneralTab(),
        this.getEvaluationsTab(),
        this.getAttachmentsTab()
    ];

    componentDidUpdate() {
        const children = this.props.component.currentComponentVersion?.evaluationDefinitionVersion?.evaluationCriterionVersions;
        if (this.entityTableLightRef.current && children && children.length > 0 && this.entityTableLightRef.current.getEntityTableSimpleCustomizedRef().current?.getEntities().length === 0) {
            this.setEvaluationCriterionTable(children);
        }
    }
}