import { apolloClient } from "@crispico/foundation-react/apolloClient";
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldEditor, ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { FieldEditorProps, fieldEditors, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { TRAINING_SERVICE_ENDPOINT_GET_COMPONENTS } from "graphql/queries";
import React from "react";
import { Form, Segment } from "semantic-ui-react";

export class EducationsDescriptor extends EntityDescriptor {
    protected customize() {
        let educationsDescriptor = this;
        educationsDescriptor.addFieldDescriptor({ name: "educationComponent", type: "DropdownEducation" });
        fieldRenderers["DropdownEducation"] = class extends React.Component<FieldEditorProps & { value: any }, {}> {
            render() {
                if (this.props.value) {
                    return <>{this.props.value.name} <span className="EducationsDescriptor_renderer">{"v" + this.props.value.majorVersion + "." + this.props.value.minorVersion}</span></>;
                }
                return <></>;
            }
        }
        fieldEditors["DropdownEducation"] = class extends FieldEditor<any, FieldEditorProps, {
            educationComponents: any[],
            searchValue: string,
            selectedComponentIndex: number
        }> {
            constructor(props: FieldEditorProps | Readonly<FieldEditorProps>) {
                super(props);

                this.state = {
                    searchValue: "",
                    educationComponents: [],
                    selectedComponentIndex: -1
                };
            }

            async componentDidMount() {
                let educationComponents = (await apolloClient.query({
                    query: TRAINING_SERVICE_ENDPOINT_GET_COMPONENTS,
                    variables: { catalogId: 1 }
                })).data.trainingServiceEndpoint_components;

                educationComponents = educationComponents.filter((component: any) => component.type.code === "OPL");
                this.setState({ educationComponents });
            }

            protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
                return <>
                    <Form>
                        <Form.Input placeholder={_msg("EducationEditor.input.label")} value={this.state.searchValue}
                            onChange={(e, data) => this.setState({ searchValue: data.value })} icon="search" />
                        <div className="EducationsDescriptor_container wh100">
                            <div className="flex-grow EducationsDescriptor_components">
                                {this.state.educationComponents.map((component, index) => {
                                    if (component.currentComponentVersion.name.toLowerCase().includes(this.state.searchValue.toLowerCase())) {
                                        return <Segment key={"EducationComponent_" + index} onClick={(event: any) => {
                                            this.setState({ selectedComponentIndex: index });
                                            s.setFieldValue(hw, this.state.educationComponents[index].currentComponentVersion);
                                        }}
                                            className={index === this.state.selectedComponentIndex ? "EducationDescriptor_component selectedItem" : "EducationDescriptor_component"}>
                                            {component.currentComponentVersion.name} <span className="EducationsDescriptor_renderer">{"v" + component.currentComponentVersion.majorVersion + "." + component.currentComponentVersion.minorVersion}</span>
                                        </Segment>
                                    }
                                })}
                            </div>
                        </div>
                    </Form>
                </>;
            }
        }
    }
}