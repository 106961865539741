'use strict';
	
var profilesTreeComponent = {
		template: require('./profiles-tree.html'),
		bindings: {
			filter: '=?',
			onSelect: '&?' // rowData is the callback argument (see below)
		},
		controllerAs: 'profilesTree',
		controller: /*@ngInject*/ function ($scope, Profile, ModalSpinner) {
			var profilesTree = $scope.profilesTree;
			
			ModalSpinner.show('profiles.loading');
			Profile.getProfileData({}, {
			}).then(function(response) {
				profilesTree.data = response.data;
			}).finally(function() {
				 ModalSpinner.hide();
			});
			
			profilesTree.$onInit = function() {
				
				profilesTree.filter = profilesTree.filter || {};
				angular.extend(profilesTree.filter, {
					properties: ['name'],
					elementFn: getProfileVersion
				});

				profilesTree.tree = {
      					repeat: 'profileData in profilesTree.data',
      					label: '{{profilesTree.getLabel(this)}}',
      					detail: 'v{{profileData.profile.currentProfileVersion.version}}',
      					icon: "'fa fa-folder-open fa-fw'",
      					select: 'profilesTree.select(this)',
      					filter: 'profilesTree.filter',	
      					expandable: true,
      					children:  [
	      					{
	      						repeat: 'profileVersion in profileData.profileVersions',
	      						label: '{{profilesTree.getLabel(this)}}',
	      						detail: 'v{{profileVersion.version}}',
	      						icon: "'fa fa-file fa-fw text-muted'",
	      						select: 'profilesTree.select(this)',
	      						filter: 'profilesTree.filter',
	      					}
      					]
      			};				
			};
			
			profilesTree.getLabel = function(scope) {				
				return getProfileVersion(scope).name;
			};
			
			profilesTree.select = function(scope) {
				// http://www.codelord.net/2016/05/13/understanding-angulars-and-binding/
				profilesTree.onSelect && profilesTree.onSelect({rowData: getProfileVersion(scope)});
			};
			
			function getProfileVersion(scope) {
				if(scope.profileData) {
					return scope.profileData.profile.currentProfileVersion;
				} else if (scope.profileVersion) {
					return scope.profileVersion;
				}
				return null;
			}
		}
	};

module.exports = profilesTreeComponent;