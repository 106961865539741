import { Utils } from "@crispico/foundation-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { getBalance_planningServiceFacadeBean_balance_deductionRegistrations } from "apollo-gen/getBalance";
import moment from "moment";
import React from "react";
import { Icon, Modal, Table } from "semantic-ui-react";

const DEDUCTION_DIRECT = "DIRECTE_INLEVERING";
const DEDUCTION_LONG = "RESTSALDO_INLEVERING_LANG";
const DEDUCTION_SHORT = "RESTSALDO_INLEVERING_KORT";

export class DeductionInfoState extends State {
    deductionInfoTableModalOpen: boolean = false;
}

export class DeductionInfoReducers<S extends DeductionInfoState = DeductionInfoState> extends Reducers<S> {

    open() {
        this.s.deductionInfoTableModalOpen = true;
    }

    close() {
        this.s.deductionInfoTableModalOpen = false;
    }
}

type DeductionInfoProps = RRCProps<DeductionInfoState, DeductionInfoReducers> & {
    resourceId: number;
    date: Date;
    deductionRegistrations: (getBalance_planningServiceFacadeBean_balance_deductionRegistrations | null)[];
};

export class DeductionInfoRaw extends React.Component<DeductionInfoProps> {

    async open() {
        if (this.props.deductionRegistrations != null && this.props.deductionRegistrations.length > 0) {
            this.props.r.open();
        }
    }

    renderTable() {
        let totalShort = 0;
        let totalLong = 0;
        let totalDirect = 0;
        return (
            <div>
                <Table celled compact unstackable striped>
                    <Table.Header>
                        <Table.Row textAlign="center" verticalAlign="middle">
                            <Table.HeaderCell>{_msg("DeductionInfo.registration.from.label")}</Table.HeaderCell>
                            <Table.HeaderCell>{_msg("DeductionInfo.registration.to.label")}</Table.HeaderCell>
                            <Table.HeaderCell>{_msg("DeductionInfo.registration.type.label")}</Table.HeaderCell>
                            <Table.HeaderCell>{_msg("DeductionInfo.category.label")}</Table.HeaderCell>
                            <Table.HeaderCell>{_msg("DeductionInfo.numberOfDays.label")}</Table.HeaderCell>
                            <Table.HeaderCell>{_msg("DeductionInfo.short.label")}</Table.HeaderCell>
                            <Table.HeaderCell>{_msg("DeductionInfo.long.label")}</Table.HeaderCell>
                            <Table.HeaderCell>{_msg("DeductionInfo.direct.label")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.deductionRegistrations.map(r => {
                            const short = this.getShortDeduction(r);
                            totalShort += short;
                            const long = this.getLongDeduction(r);
                            totalLong += long;
                            const direct = this.getDirectDeduction(r);
                            totalDirect += direct;
                            return <Table.Row verticalAlign="middle" textAlign="center">
                                <Table.HeaderCell>{moment(r?.fromDate).format(Utils.dateTimeFormat)}</Table.HeaderCell>
                                <Table.HeaderCell>{moment(r?.toDate).format(Utils.dateTimeFormat)}</Table.HeaderCell>
                                <Table.HeaderCell>{r?.type?.code}</Table.HeaderCell>
                                <Table.HeaderCell>{this.getCategory(r)}</Table.HeaderCell>
                                <Table.HeaderCell>{this.getNumberOfDays(r)}</Table.HeaderCell>
                                <Table.HeaderCell>{short.toFixed(2)}</Table.HeaderCell>
                                <Table.HeaderCell>{long.toFixed(2)}</Table.HeaderCell>
                                <Table.HeaderCell>{direct.toFixed(2)}</Table.HeaderCell>
                            </Table.Row>
                        })}
                        <Table.Row textAlign="center" verticalAlign="middle">
                        <Table.HeaderCell>{_msg("DeductionInfo.totalWithoutRounding.label")}</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>{totalShort.toFixed(2)}</Table.HeaderCell>
                            <Table.HeaderCell>{totalLong.toFixed(2)}</Table.HeaderCell>
                            <Table.HeaderCell>{totalDirect.toFixed(2)}</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row textAlign="center" verticalAlign="middle">
                            <Table.HeaderCell>{_msg("DeductionInfo.total.label")}</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>{Math.floor(totalShort)}</Table.HeaderCell>
                            <Table.HeaderCell>{Math.floor(totalLong)}</Table.HeaderCell>
                            <Table.HeaderCell>{Math.floor(totalDirect)}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        );
    }

    getShortDeduction(registration: any) {
        return this.filterRelations(registration, DEDUCTION_SHORT).result;
    }

    getLongDeduction(registration: any) {
        return this.filterRelations(registration, DEDUCTION_LONG).result;
    }

    getDirectDeduction(registration: any) {
        return this.filterRelations(registration, DEDUCTION_DIRECT).result;
    }

    getCategory(registration: any) {
        const relation = this.filterRelations(registration, DEDUCTION_DIRECT);
        return `${relation.category} (${relation.shortConstant}, ${relation.longConstant})`;
    }

    getNumberOfDays(registration: any) {
        return this.filterRelations(registration, DEDUCTION_DIRECT).length;
    }

    filterRelations(registration: any, relationCode: string) {
        return registration.calculationRelations.filter((rel: any) => rel.year === moment(this.props.date).year() && rel.calculation.code === relationCode)[0];
    }

    renderInfoTableModal() {
        if (!this.props.s.deductionInfoTableModalOpen || this.props.deductionRegistrations.length == 0) {
            return null;
        }
        return <ModalExt addNiceLookingOffsets closeIcon centered={false} open={this.props.s.deductionInfoTableModalOpen} onClose={() => this.props.r.close()}>
            <Modal.Header>
                <Icon name='calculator' />
                {_msg("DeductionInfo.title.label")}
            </Modal.Header>
            <Modal.Content>
                {this.renderTable()}
            </Modal.Content>
        </ModalExt>
    }

    render() {
        return <>
            <Icon className="DeductionInfo_infoButton" size="large" link name='calculator' onClick={() => this.open()} />
            {this.renderInfoTableModal()}
        </>;
    }
}

export const DeductionInfo = ReduxReusableComponents.connectRRC(DeductionInfoState, DeductionInfoReducers, DeductionInfoRaw);