import { ADD, apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, EntityTablePage, getBaseImpures, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension } from "@crispico/foundation-react";
import { push } from "connected-react-router";
import { CALENDAR_DEFINITION_SERVICE_GET_CALENDAR_DEFINITION, CALENDAR_DEFINITION_SERVICE_REMOVE_CALENDAR_DEFINITION, CALENDAR_DEFINITION_SERVICE_SAVE_CALENDAR_DEFINITION } from "graphql/queries";
import _ from "lodash";
import moment from "moment-timezone";
import React from "react";
import { CalendarDefinitionEditor, CalendarItem, CalendarProperty, sliceCalendarDefinition } from "./CalendarDefinitionEditor";

export class CalendarDefinitionEntityDescriptor extends EntityDescriptor {

    toMiniString(entityWithMiniFields: any) {
        return entityWithMiniFields?.code === null ? entityWithMiniFields?.description :
            entityWithMiniFields?.code + (entityWithMiniFields?.description === null ? "" : " - " + entityWithMiniFields?.description);
    }

    protected customize() {

        const calendarDefinitionEntityDescriptor = this;

        const sliceCalendarDefinitionEditor = calendarDefinitionEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceCalendarDefinitionEditor extends SliceEntityEditorPage {

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            getSaveOperationName(): string {
                return 'calendarDefinitionService_saveCalendarDefinition';
            }

            initQueries() {
                super.initQueries();
                this.saveMutation = CALENDAR_DEFINITION_SERVICE_SAVE_CALENDAR_DEFINITION;
            }

            nestedSlices = {
                ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
                calendarDefinitionEditor: sliceCalendarDefinition
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceCalendarDefinitionEditor>(this),

                superMutation: sliceEntityEditorPageOnlyForExtension.impures.invokeSaveMutation,
                async invokeSaveMutation(options: any) {
                    let localEntity = { ...this.getState().entity };
                    if (this.getState().duplication) {
                        localEntity = {
                            ...this.setNullEntityValuesWhenDuplicating(localEntity),
                            items: this.setNullItemsValuesWhenDuplicating(localEntity.items),
                            properties: this.setNullPropertiesValuesWhenDuplicating(localEntity.properties)
                        };
                    }
                    options.variables = { entity: localEntity };
                    return await this.superMutation(options);
                },

                superSave: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    const result = await this.superSave(entity);

                    // if no exceptions occured, go back to table
                    if (result !== true) {
                        this.getDispatchers().dispatch(push(calendarDefinitionEntityDescriptor.getEntityTableUrl()));
                    }
                },

                getLoadQueryParams() {
                    return {
                        loadOperationName: 'calendarDefinitionService_calendarDefinition',
                        loadQuery: CALENDAR_DEFINITION_SERVICE_GET_CALENDAR_DEFINITION
                    };
                },

                loadSuper: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    await this.loadSuper(id);
                    // id is either the entity id (when editing the entity) or "add" (when adding a new entity)
                    if (id === ADD) {
                        // when an entity is added, it is by default initialized with {}, but to use the CalendarDefinitionEditor, it must be initialized as below
                        this.getDispatchers().setInReduxState({ entity: { items: [], extendDefinitions: [], properties: [], code: "", description: "" } });
                    }
                },

                setNullEntityValuesWhenDuplicating(entity: any) {
                    return {
                        ...entity,
                        id: null,
                        creationDate: null,
                        creationUser: null,
                        modificationDate: null,
                        modificationUser: null,
                        objectVersion: null
                    };
                },

                setNullItemsValuesWhenDuplicating(items: CalendarItem[]) {
                    let newItems = [];
                    for (let i = 0; i < items.length; i++) {
                        let newRecurrenceDefinitionDto = {
                            ...this.setNullEntityValuesWhenDuplicating(items[i].recurrenceDefinitionDto),
                            pattern: this.setNullEntityValuesWhenDuplicating(items[i].recurrenceDefinitionDto?.pattern),
                            range: this.setNullEntityValuesWhenDuplicating(items[i].recurrenceDefinitionDto?.range)
                        };
                        newItems.push({
                            ...this.setNullEntityValuesWhenDuplicating(items[i]),
                            recurrenceDefinitionDto: newRecurrenceDefinitionDto
                        });
                    }
                    return newItems;
                },

                setNullPropertiesValuesWhenDuplicating(properties: CalendarProperty[]) {
                    let newProperties = [];
                    for (let i = 0; i < properties.length; i++) {
                        newProperties.push({
                            ...this.setNullEntityValuesWhenDuplicating(properties[i]),
                            item: this.setNullEntityValuesWhenDuplicating(properties[i].item)
                        });
                    }
                    return newProperties;
                }
            }
        }).setEntityDescriptor(calendarDefinitionEntityDescriptor);

        calendarDefinitionEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceCalendarDefinitionEditor> & EntityEditorPageProps> {

            renderForm() {
                return (
                    <div className="CalendarDefinitionEntityDescriptor_calendarEditor">
                        {super.renderForm()}
                        <CalendarDefinitionEditor {...this.props.calendarDefinitionEditor} dispatchers={this.props.dispatchers.calendarDefinitionEditor}
                            calendar={this.props.entity} disableAddButton={false} defaultStartDateValue={new Date(moment().startOf("date").toDate())}/>
                    </div>
                );
            }

            checkIfCalendarDefinitionChanged(prevProps: PropsFrom<typeof sliceCalendarDefinitionEditor>) {
                const calendarFromCalendarEditor = this.props.calendarDefinitionEditor.calendarDefinition;
                const prevCalendarFromCalendarEditor = prevProps.calendarDefinitionEditor.calendarDefinition;
                const calendarsNotNullOrEmpty = prevCalendarFromCalendarEditor !== undefined && Object.keys(prevCalendarFromCalendarEditor).length > 0 &&
                    calendarFromCalendarEditor !== undefined && Object.keys(calendarFromCalendarEditor).length > 0;

                // check if calendarDefinition has been changed in calendarDefinitionEditor component and update it accordingly in entity
                if (calendarsNotNullOrEmpty && calendarFromCalendarEditor.id === prevCalendarFromCalendarEditor.id &&
                    (!_.isEqual(calendarFromCalendarEditor.extendDefinitions, prevCalendarFromCalendarEditor.extendDefinitions) ||
                        !_.isEqual(calendarFromCalendarEditor.items, prevCalendarFromCalendarEditor.items) ||
                        !_.isEqual(calendarFromCalendarEditor.properties, prevCalendarFromCalendarEditor.properties))) {
                    this.props.dispatchers.setInReduxState({
                        entity: {
                            ...(this.refFormSimple.current?.formik as any).current.values,
                            items: calendarFromCalendarEditor.items,
                            extendDefinitions: calendarFromCalendarEditor.extendDefinitions,
                            properties: calendarFromCalendarEditor.properties
                        }
                    });
                }
            }

            componentDidUpdate(prevProps: PropsFrom<typeof sliceCalendarDefinitionEditor>) {
                this.checkIfCalendarDefinitionChanged(prevProps);
            }
        }

        calendarDefinitionEntityDescriptor.infoTable.slice = createSliceFoundation(class SliceCalendarDefinitionTable extends SliceEntityTablePage {
            impures = {
                ...sliceEntityTablePageOnlyForExtension.impures,
                ...getBaseImpures<SliceCalendarDefinitionTable>(this)
            }
        }).setEntityDescriptor(calendarDefinitionEntityDescriptor);

        calendarDefinitionEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage {
            async deleteEntity(this: any, entityDescriptorName: string, id: number) {
                await apolloClient.mutate({
                    mutation: CALENDAR_DEFINITION_SERVICE_REMOVE_CALENDAR_DEFINITION,
                    variables: {
                        id
                    }
                });
                this.refresh();
            }
        }
    }
}