'use strict';

var css = require('./planning.css');
var balanceCss = require('./balance/balance-table.css');

angular.module('proteus.planning', ['ui.grid', require('ng-file-upload')])

.run(function($templateCache) {
	$templateCache.put('planning/agenda-page.html', require('./agenda-page.html'));
	$templateCache.put('/agenda-grid/agenda-grid.html', require('./agenda-grid/agenda-grid.html'));
	$templateCache.put('planning/registration-tab.html', require('./registration/registration-detail-registration-tab.html'));
	$templateCache.put('planning/remarks-tab.html', require('./registration/registration-detail-remarks-tab.html'));
	$templateCache.put('planning/balance-table.html', require('./balance/balance-table.html'));
})

.constant('AgendaConstants',  require('./agenda-page.constants'))

.factory('Agenda',               require('./agenda.service'))
.factory('RegistrationMetadata', require('./registration/registration-metadata.service'))
.factory('RegistrationTypes',    require('./registration/registration-types.service'))
.factory('ConstraintGroups',     require('./constraint-groups.service'))

.controller('AgendaPageCtrl',         require('./agenda-page.controller'))
.controller('AgendaGridCtrl',         require('./agenda-grid/agenda-grid.controller'))
.controller('LeaveGroupCtrl',         require('./leave-group.controller'))
.controller('RegistrationTypeCtrl',   require('./registration/registration-type.controller'))
.controller('RegistrationDetailCtrl', require('./registration/registration-detail.controller'))
.controller('BalanceTableCtrl',       require('./balance/balance-table.controller'))

.directive('calendarEventMarker',           require('./agenda-grid/calendar-event-marker.directive'))
.directive('agendaGridRegistration',        require('./agenda-grid/agenda-grid-registration.directive'))
.directive('agendaGridRegistrationActions', require('./agenda-grid/agenda-grid-registration-actions.directive'))
.directive('agendaGridAction',              require('./agenda-grid-action/agenda-grid-action.directive'))
.directive('registrationDetailDate',        require('./registration/registration-detail-date.directive'))
.directive('uibTimepicker',                 require('./registration/uib-timepicker.directive'))
.directive('resourceGrid',                  require('./resource-grid/resource-grid.directive'))

.component('balanceUploadPage',     		require('./balance-upload/balance-upload-page.component'));

module.exports = 'proteus.planning';