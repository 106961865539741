'use strict';

/*@ngInject*/
function uiGridRow(RGBAConverter) {
	return {
		link: function($scope, element) {
			$scope.$watch('row', function(newValue, oldValue) {
				function styleElement() {
					if ($scope.$even) {
						element.css('background-color', '#f3f3f3');
					} else if ($scope.$odd) {
						element.css('background-color', '#fdfdfd');
					} else {
						throw "Row must be either odd or even";
					}
					element.css('color', '#666');
				}
				
				if (element.height()) {
					$scope.row.height = element.height();
					if ($scope.rowRenderIndex === $scope.grid.getVisibleRowCount()) {
						$scope.grid.updateCanvasHeight();
					}
				}
				
				styleElement();
				
				if ($scope.row.grid.api.selection) {
					$scope.$watch('row.isSelected', function(newValue, oldValue) {
						if (newValue !== oldValue) {
							if (newValue) {
								element.css('background-color', '#2196f3');
								element.css('color', '#fff')
							} else {
								styleElement();
							}
						}
					});
				}
			});
		}
	};
}

module.exports = uiGridRow;