import { apolloClient, createSliceFoundation, EntityDescriptor, EntityTablePage, EntityTablePageProps, getBaseImpures, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FREE_TIME_OVERVIEW_CALCULATOR_GET_DATE_UNTIL_NEXT_CHANGE, FREE_TIME_OVERVIEW_CALCULATOR_GET_FREE_TIME_OVERVIEW, ROSTER_SERVICE_FIND_ROSTER_BY_ID } from "graphql/queries";
import _ from "lodash";
import moment from "moment";
import { Dispatchers, sliceDispatchers } from "pages/freeTimeDispatchers/Dispatchers";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import { Grid } from "semantic-ui-react";

export class RosterEntityDescriptor extends EntityDescriptor {

    toMiniString(entityWithMiniFields: any) {
        return entityWithMiniFields?.description;
    }

    protected customize() {

        const rosterEntityDescriptor = this;
        rosterEntityDescriptor.isInDefaultColumnConfig(false, "definition");
        rosterEntityDescriptor.doForFields(null, (fieldDescriptor) => {
            if (fieldDescriptor.name === "definitionAsString") {
                fieldDescriptor.type = FieldType.text;
                fieldDescriptor.isInDefaultColumnConfigForTable = false;
            }
            return fieldDescriptor;
        });

        rosterEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceRosterEditor extends SliceEntityEditorPage {

            getSaveOperationName(): string {
                return `rosterService_saveRoster`;
            }

            getLoadOperationName(): string {
                return `rosterService_findRosterById`;
            }

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceRosterEditor>(this),

                getLoadQueryParams() {
                    return {
                        loadOperationName: 'rosterService_findRosterById',
                        loadQuery: ROSTER_SERVICE_FIND_ROSTER_BY_ID
                    };
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    await this.saveSuper(entity);
                    if (ProteusUtils.checkPermission(ProteusConstants.FREE_TIME_ORDER_CHANGE) && entity.definitionAsString.includes(ProteusConstants.FREE_TIME)) {
                        const oldFreeTimeDispatchers = AppMetaTempGlobals.appMetaInstance.newRootReducer.getMountedHelper<typeof sliceRosterEntityTable>(rosterEntityDescriptor.infoTable.sliceName!).dispatchers.oldFreeTimeDispatchers;
                        if (oldFreeTimeDispatchers.getState().originalRoot.length === 0) {
                            oldFreeTimeDispatchers.generateEmployees(ProteusConstants.BOOTMAN_CATEGORY, true, true);
                        }
                        Utils.showGlobalAlert({ title: _msg("Dispatchers.freeTimeAlert.header.label"), message: _msg("Dispatchers.freeTimeAlert.content.label"), severity: Severity.INFO })
                    }
                },

                loadSuper: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.loadSuper(id);
                    if (ProteusUtils.checkPermission(ProteusConstants.FREE_TIME_ORDER_CHANGE) && entity.definitionAsString.includes(ProteusConstants.FREE_TIME)) {
                        // Calculate here to assure that free time changes are updated
                        await apolloClient.query({ query: FREE_TIME_OVERVIEW_CALCULATOR_GET_FREE_TIME_OVERVIEW, variables: { category: ProteusConstants.BOOTMAN, clearCacheForCategory: true } });
                        await apolloClient.query({ query: FREE_TIME_OVERVIEW_CALCULATOR_GET_FREE_TIME_OVERVIEW, variables: { category: ProteusConstants.PILOTS_CODE, clearCacheForCategory: true } });
                        let nextChangeDate = (await apolloClient.query({ query: FREE_TIME_OVERVIEW_CALCULATOR_GET_DATE_UNTIL_NEXT_CHANGE })).data.freeTimeOverviewCalculator_dateUntilNextChange;
                        nextChangeDate = moment(nextChangeDate);
                        const duration = moment.duration(nextChangeDate.diff(moment.now()));
                        const minutes = Math.ceil(duration.asMinutes()); 
                        const date = nextChangeDate.format(Utils.dateTimeFormat);
                        Utils.showGlobalAlert({ title: _msg("Dispatchers.freeTimeChangeAlert.header.label", minutes, date), message: _msg("Dispatchers.freeTimeChangeAlert.content.label", minutes, date), severity: Severity.INFO })
                    }
                },
            }
        }).setEntityDescriptor(rosterEntityDescriptor);

        const sliceRosterEntityTable = rosterEntityDescriptor.infoTable.slice = createSliceFoundation(class extends SliceEntityTablePage {
            nestedSlices = {
                ...sliceEntityTablePageOnlyForExtension.nestedSlices,
                oldFreeTimeDispatchers: sliceDispatchers,
                newFreeTimeDispatchers: sliceDispatchers
            }
        }).setEntityDescriptor(rosterEntityDescriptor);

        rosterEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<PropsFrom<typeof sliceRosterEntityTable> & EntityTablePageProps> {

            protected getExtraTabPanes() {
                if (!ProteusUtils.checkPermission(ProteusConstants.FREE_TIME_ORDER_CHANGE)) {
                    return [];
                }
                const extraTabs = [{
                    routeProps: { path: "/freeTimeOverview" }, menuItemProps: { icon: "table", content: _msg("Dispatchers") },

                    render: () => {
                        return <Grid className="RosterEntity_freeTimeOverview">
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Dispatchers {...this.props.oldFreeTimeDispatchers} dispatchers={this.props.dispatchers.oldFreeTimeDispatchers} rosterMode={true} />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Dispatchers {...this.props.newFreeTimeDispatchers} dispatchers={this.props.dispatchers.newFreeTimeDispatchers} rosterMode={true} recalculateButton={true} setFirstButton={true} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    }
                }];
                return extraTabs;
            }
        }
    }
}