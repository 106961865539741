import { apolloClient, ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { Button, Divider } from "semantic-ui-react";
import { TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE_CATEGORIES, TRAINING_SERVICE_ENDPOINT_SAVE_COMPONENT, TRAINING_SERVICE_ENDPOINT_SAVE_COMPONENT_VERSION, TRAINING_SERVICE_ENDPOINT_SAVE_MODULE, TRAINING_SERVICE_ENDPOINT_SAVE_MODULE_VERSION, TRAINING_SERVICE_ENDPOINT_SAVE_TRACK, TRAINING_SERVICE_ENDPOINT_SAVE_TRACK_VERSION } from "graphql/queries";
import { CatalogItemEditor, sliceCatalogItemEditor } from "./editors/CatalogItemEditor";
import { ModuleItemEditor, sliceModuleItemEditor } from "./editors/ModuleItemEditor";
import { sliceTrackItemEditor, TrackItemEditor } from "./editors/TrackItemEditor";
import { ComponentItemEditor, sliceComponentItemEditor } from "./editors/ComponentItemEditor";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { CatalogTree, DEFAULT_CATALOG, EDUCATION_COMPONENT_VERSION, EVALUATION_COMPONENT_VERSION, sliceCatalogTree } from "./CatalogTree";
import { AttachmentEntity, BasicEntity } from "components/uploadButton/RegistrationAndEducationFileUploadButton";
import _ from "lodash";
import { EducationComponentItemEditor, sliceEducationComponentItemEditor } from "./editors/EducationComponentItemEditor";
import { EvaluationComponentItemEditor, sliceEvaluationComponentItemEditor } from "./editors/EvaluationComponentItemEditor";
import { CompetenceCategory } from "pages/competences/Competences";
import CatalogErrorModal from "./CatalogErrorModal";
import React from "react";

export const RESPONSE_OK = 200;
const CERTIFICATEN = "Certificaten";
const EMPTY_FUNCTION = () => { };
const DEFAULT_CATALOG_ID = 1;

export interface CatalogSnapshot extends BasicEntity {
    active: boolean,
    description: string,
    environment: string,
    name: string,
}

export interface ComponentType extends BasicEntity {
    code: string,
    description: string;
}

export interface CatalogItemVersion extends BasicEntity {
    active: boolean | null,
    description: string | null,
    majorVersion: number,
    minorVersion: number,
    name: string | null,
    attachments: AttachmentEntity[],
    objectType: string;
}

export interface ComponentVersion extends CatalogItemVersion, BasicEntity {
    competenceVersions?: any,
    trainerProfileVersion?: number,
    evaluationDefinitionVersion?: any,
}

export interface ModuleVersion extends CatalogItemVersion {
    componentVersions: ComponentVersion[],
}

export interface TrackVersion extends CatalogItemVersion {
    children: Child[],
}

export interface Child {
    componentVersion: ComponentVersion,
    moduleVersion: ModuleVersion,
}

export interface Track extends BasicEntity {
    catalog: CatalogSnapshot,
    versions: [],
    currentTrackVersion: TrackVersion,
    objectType: string,
}

export interface Component extends BasicEntity {
    catalog: CatalogSnapshot,
    versions: [],
    currentComponentVersion: ComponentVersion,
    objectType: string,
    type: ComponentType,
}

export interface Module extends BasicEntity {
    catalog: CatalogSnapshot,
    versions: [],
    currentModuleVersion: ModuleVersion,
    objectType: string,
}

export enum EditorType {
    DEFAULT = "DEFAULT",
    EMPTY = "",
    MODULE = "MODULE",
    TRACK = "TRACK",
    COMPONENT = "COMPONENT",
    EDUCATION_COMPONENT = "EDUCATION_COMPONENT",
    EVALUATION_COMPONENT = "EVALUATION_COMPONENT",
}

export interface TrainingCompetenceVersion extends BasicEntity {
    categories: CompetenceCategory[],
}

export const sliceCatalog = createSliceFoundation(class SliceCatalog {

    initialState = {
        editorType: EditorType.EMPTY,
        isErrorModalOpen: false,
        errorMessage: undefined as string | undefined,
        errorCode: undefined as string | undefined
    };

    nestedSlices = {
        catalogItemEditor: sliceCatalogItemEditor,
        moduleItemEditor: sliceModuleItemEditor,
        trackItemEditor: sliceTrackItemEditor,
        componentItemEditor: sliceComponentItemEditor,
        educationComponentItemEditor: sliceEducationComponentItemEditor,
        evaluationComponentItemEditor: sliceEvaluationComponentItemEditor,
        catalogTree: sliceCatalogTree
    };

    reducers = {
        ...getBaseReducers<SliceCatalog>(this),

        setIsErrorModalOpen(state: StateFrom<SliceCatalog>, p: { isOpen: boolean; }) {
            state.isErrorModalOpen = p.isOpen;
        }
    };

    impures = {
        ...getBaseImpures<SliceCatalog>(this),

        async saveOrUpdateModuleVersion(module: Module) {
           const result = (await apolloClient.mutate({
                mutation: TRAINING_SERVICE_ENDPOINT_SAVE_MODULE_VERSION,
                variables: {
                    moduleVersion: { ...module.currentModuleVersion, module: { ...module, currentModuleVersion: undefined, versions: undefined } }
                }
            })).data.trainingServiceEndpoint_saveModuleVersion;
            this.getDispatchers().catalogTree.updateModuleChild({ child: result, parent: module });
        },

        async saveOrUpdateTrackVersion(track: Track) {
            const result = (await apolloClient.mutate({
                mutation: TRAINING_SERVICE_ENDPOINT_SAVE_TRACK_VERSION,
                variables: {
                    trackVersion: { ...track.currentTrackVersion, track: { ...track, currentTrackVersion: undefined, versions: undefined } }
                }
            })).data.trainingServiceEndpoint_saveTrackVersion;
            this.getDispatchers().catalogTree.updateTrackChild({ child: result, parent: track });
        },

        async saveOrUpdateComponent(component: Component, createNewMajorVersion: boolean, addToComponents: Function, updateComponent: Function) {
            let componentToSave = _.cloneDeep({ ...component, versions: undefined });

            let updatedComponent = (await apolloClient.mutate({
                mutation: TRAINING_SERVICE_ENDPOINT_SAVE_COMPONENT,
                variables: {
                    component: componentToSave,
                    createNewMajorVersion: createNewMajorVersion
                }
            })).data.trainingServiceEndpoint_saveComponent;
            if (component.id === undefined) {
                addToComponents(updatedComponent);
            } else {
                updateComponent(updatedComponent);
            }
            this.getDispatchers().componentItemEditor.setComponent(updatedComponent);
            return updatedComponent;
        },

        async saveOrUpdateComponentVersion(component: Component) {
            await apolloClient.mutate({
                mutation: TRAINING_SERVICE_ENDPOINT_SAVE_COMPONENT_VERSION,
                variables: {
                    componentVersion: { ...component.currentComponentVersion, component: { ...component, currentComponentVersion: undefined, versions: undefined } }
                }
            });
        },

        async getCompetences() {
            return (await apolloClient.query({
                query: TRAINING_SERVICE_ENDPOINT_GET_COMPETENCE_CATEGORIES,
                variables: {
                    catalogId: DEFAULT_CATALOG_ID
                }
            })).data.trainingServiceEndpoint_competenceCategories;
        },

    };
});

const EmptyItemEditor = () => (
    <div className="Catalog_emptyEditor">
        <h3 className="Catalog_emptyEditor_header">{_msg("Catalog.nothingSelected.label")}</h3>
    </div>
);

type Props = PropsFrom<typeof sliceCatalog>;

export class Catalog<T extends Props = Props> extends TabbedPage<T> {

    protected refEvaluationComponentItemEditor = React.createRef<EvaluationComponentItemEditor>();
    protected refModuleItemEditor = React.createRef<ModuleItemEditor>();
    protected refTrackItemEditor = React.createRef<TrackItemEditor>();

    async saveOrUpdateModule(module: Module, createNewMajorVersion: boolean, addToModules: Function, updateModule: Function) {
        let moduleToSave = { ...module, versions: undefined };
        let createMajorVersion = createNewMajorVersion || !_.isEqual(module.currentModuleVersion.componentVersions, this.props.moduleItemEditor.defaultModule.currentModuleVersion.componentVersions);
        let updatedModule = (await apolloClient.mutate({
            mutation: TRAINING_SERVICE_ENDPOINT_SAVE_MODULE,
            variables: {
                module: moduleToSave,
                createNewMajorVersion: createMajorVersion
            }
        })).data.trainingServiceEndpoint_saveModule;
        this.props.dispatchers.catalogTree.loadTracks(DEFAULT_CATALOG, this.props.catalogTree.renderExpands);
        if (module.id === undefined) {
            addToModules(updatedModule);
        } else {
            updateModule(updatedModule);
        }
        this.refModuleItemEditor.current?.setModule(updatedModule);
        return module;
    }

    async saveOrUpdateTrack(track: Track, createNewMajorVersion: boolean, addToTracks: Function, updateTrack: Function) {
        let trackToSave = { ...track, versions: undefined };
        let createMajorVersion = createNewMajorVersion || !_.isEqual(track.currentTrackVersion.children, this.props.trackItemEditor.defaultTrack.currentTrackVersion.children);
        let updatedTrack = (await apolloClient.mutate({
            mutation: TRAINING_SERVICE_ENDPOINT_SAVE_TRACK,
            variables: {
                track: trackToSave,
                createNewMajorVersion: createMajorVersion
            }
        })).data.trainingServiceEndpoint_saveTrack;
        if (track.id === undefined) {
            addToTracks(updatedTrack);
        } else {
            updateTrack(updatedTrack);
        }
        this.refTrackItemEditor.current?.setTrack(updatedTrack);
        return updatedTrack;
    }

    isDirty() {
        return this.getEditor()?.dirty;
    }

    getEditor() {
        if (this.props.editorType === EditorType.MODULE) {
            return this.props.moduleItemEditor;
        } else if (this.props.editorType === EditorType.TRACK) {
            return this.props.trackItemEditor;
        } else if (this.props.editorType === EditorType.COMPONENT) {
            return this.props.componentItemEditor;
        } else if (this.props.editorType === EditorType.EDUCATION_COMPONENT) {
            return this.props.educationComponentItemEditor;
        } else if (this.props.editorType === EditorType.EVALUATION_COMPONENT) {
            return this.props.evaluationComponentItemEditor;
        }
        return this.props.catalogItemEditor;
    }

    setEditorType = (editorType: EditorType) => {
        this.props.dispatchers.setInReduxState({ editorType });
    };

    onTrackSelect = (track: Track) => {
        if (!track) {
            this.setEditorType(EditorType.EMPTY);
            return;
        }

        this.props.dispatchers.trackItemEditor.setInReduxState({ isOldVersionSelected: false });
        if (track.versions?.filter((child: TrackVersion) => child.majorVersion === track.currentTrackVersion.majorVersion && child.minorVersion === track.currentTrackVersion.minorVersion).length > 0) {
            this.props.dispatchers.trackItemEditor.setInReduxState({ isOldVersionSelected: true });
        }

        this.props.dispatchers.trackItemEditor.setInReduxState({ track });
        this.refTrackItemEditor.current?.setTrack(track);
        this.setEditorType(EditorType.TRACK);
        this.props.dispatchers.trackItemEditor.setInReduxState({ activeIndex: 0 });
    };

    onModuleSelect = (module: Module) => {
        if (!module) {
            this.setEditorType(EditorType.EMPTY);
            return;
        }

        this.props.dispatchers.moduleItemEditor.setInReduxState({ isOldVersionSelected: false });
        if (module.versions?.filter((child: ModuleVersion) => child.majorVersion === module.currentModuleVersion.majorVersion && child.minorVersion === module.currentModuleVersion.minorVersion).length > 0) {
            this.props.dispatchers.moduleItemEditor.setInReduxState({ isOldVersionSelected: true });
        }

        this.props.dispatchers.moduleItemEditor.setInReduxState({ module });
        this.refModuleItemEditor.current?.setModule(module);
        this.setEditorType(EditorType.MODULE);
        this.props.dispatchers.moduleItemEditor.setInReduxState({ activeIndex: 0 });
    };

    getComponentEditorType(component: Component) {
        if (component.currentComponentVersion.objectType === EDUCATION_COMPONENT_VERSION) {
            return EditorType.EDUCATION_COMPONENT;
        };
        if (component.currentComponentVersion.objectType === EVALUATION_COMPONENT_VERSION) {
            return EditorType.EVALUATION_COMPONENT;
        }

        return EditorType.COMPONENT;
    }

    onComponentSelect = async (component: Component) => {
        if (!component) {
            this.setEditorType(EditorType.EMPTY);
            return;
        }
        const editorType = this.getComponentEditorType(component);
        let isOldVersionSelected = false;

        // check if an older version, other than the current version is selected
        if (component.versions?.filter((child: ComponentVersion) => child.majorVersion === component.currentComponentVersion.majorVersion && child.minorVersion === component.currentComponentVersion.minorVersion).length > 0) {
            isOldVersionSelected = true;
        }

        if (editorType === EditorType.EDUCATION_COMPONENT) {
            const competenceCategories = this.props.educationComponentItemEditor.rootCompetences ?
                this.props.educationComponentItemEditor.rootCompetences :
                await this.props.dispatchers.getCompetences();
            this.props.dispatchers.educationComponentItemEditor.setInReduxState({ isOldVersionSelected: isOldVersionSelected, component, activeIndex: 0 });
            this.props.dispatchers.educationComponentItemEditor.setEducationComponent({ component, competenceCategories });
        } else if (editorType === EditorType.EVALUATION_COMPONENT) {
            const competenceCategories = this.props.evaluationComponentItemEditor.rootCompetences ?
                this.props.evaluationComponentItemEditor.rootCompetences :
                await this.props.dispatchers.getCompetences();
            if (this.refEvaluationComponentItemEditor.current) {
                this.refEvaluationComponentItemEditor.current?.setEvaluationComponent(component, competenceCategories);
            } else {
                this.props.dispatchers.evaluationComponentItemEditor.setComponent(component);
                this.props.dispatchers.evaluationComponentItemEditor.setCatalogItemVersion(component.currentComponentVersion);
            }
            this.props.dispatchers.evaluationComponentItemEditor.setInReduxState({ isOldVersionSelected: isOldVersionSelected, rootCompetences: competenceCategories, component, activeIndex: 0 });
        } else {
            this.props.dispatchers.componentItemEditor.setInReduxState({ isOldVersionSelected: isOldVersionSelected, activeIndex: 0 });
            this.props.dispatchers.componentItemEditor.setComponent(component);
        }

        this.setEditorType(editorType);
    };

    onSaveComponent = (json: any) => {
        let updatedComponent = json;
        if (this.props.componentItemEditor.component.id === undefined) {
            this.props.dispatchers.catalogTree.addToComponents(updatedComponent);
        } else {
            this.props.dispatchers.catalogTree.updateComponent(updatedComponent);
        }
        this.props.dispatchers.componentItemEditor.setComponent(updatedComponent);
        this.props.dispatchers.componentItemEditor.onSaveComponent();
    };

    handleSave = async () => {
        if (this.props.editorType === EditorType.MODULE) {
            this.props.dispatchers.moduleItemEditor.setInReduxState({ dirty: false });
            if (this.props.moduleItemEditor.isOldVersionSelected) {
                this.props.dispatchers.saveOrUpdateModuleVersion(this.props.moduleItemEditor.module);
            } else {
                this.saveOrUpdateModule(this.props.moduleItemEditor.module, this.props.moduleItemEditor.createNewMajorVersion,
                    this.props.dispatchers.catalogTree.addToModules, this.props.dispatchers.catalogTree.updateModule);
                this.props.dispatchers.moduleItemEditor.setInReduxState({ createNewMajorVersion: false });
            }
            this.props.dispatchers.catalogTree.filterRoot(this.props.catalogTree.searchExpression);
        } else if (this.props.editorType === EditorType.TRACK) {
            this.props.dispatchers.trackItemEditor.setInReduxState({ dirty: false });
            if (this.props.trackItemEditor.isOldVersionSelected) {
                this.props.dispatchers.saveOrUpdateTrackVersion(this.props.trackItemEditor.track);
            } else {
                this.saveOrUpdateTrack(this.props.trackItemEditor.track, this.props.trackItemEditor.createNewMajorVersion,
                    this.props.dispatchers.catalogTree.addToTracks, this.props.dispatchers.catalogTree.updateTrack);
                this.props.dispatchers.trackItemEditor.setInReduxState({ createNewMajorVersion: false });
            }
            this.props.dispatchers.catalogTree.filterRoot(this.props.catalogTree.searchExpression);
        } else if (this.props.editorType === EditorType.COMPONENT) {
            this.props.dispatchers.componentItemEditor.setInReduxState({ dirty: false });
            if (this.props.componentItemEditor.isOldVersionSelected) {
                await this.handleSaveComponent(this.props.componentItemEditor.component, EMPTY_FUNCTION, true);
            } else {
                await this.handleSaveComponent(this.props.componentItemEditor.component, this.onSaveComponent);
            }
            this.props.dispatchers.catalogTree.filterRoot(this.props.catalogTree.searchExpression);
        } else if (this.props.editorType === EditorType.EDUCATION_COMPONENT) {
            this.props.dispatchers.educationComponentItemEditor.setInReduxState({ dirty: false });
            if (this.props.educationComponentItemEditor.isOldVersionSelected) {
                await this.handleSaveComponent(this.props.educationComponentItemEditor.component, EMPTY_FUNCTION, true);
            } else {
                await this.handleSaveEducationComponent();
            }
        } else if (this.props.editorType === EditorType.EVALUATION_COMPONENT) {
            this.props.dispatchers.evaluationComponentItemEditor.setInReduxState({ dirty: false });
            if (this.props.educationComponentItemEditor.isOldVersionSelected) {
                await this.handleSaveComponent(this.props.evaluationComponentItemEditor.component, EMPTY_FUNCTION, true);
            } else {
                await this.handleSaveEvaluationComponent();
            }
        }
    };

    /*******************************************
     ******* Save methods old method ***********
     *******************************************/

    // We did not fix the graphql polymorphism problem so we have to use the old save requests
    private async handleSaveComponent(component: Component, onSave: (json: any) => void, isComponentVersion?: boolean) {
        const createNewMajorVersion = this.props.componentItemEditor.createNewMajorVersion;
        const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
        const url = isComponentVersion ? Utils.adjustUrlToServerContext(additionalUrlParam + `restful/trainingServiceEndpoint/componentVersion/${component.currentComponentVersion.id}`) :
            Utils.adjustUrlToServerContext(additionalUrlParam + `restful/trainingServiceEndpoint/component/${component.id}/${createNewMajorVersion}`);

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: isComponentVersion ? JSON.stringify(component.currentComponentVersion) : JSON.stringify(component)
        };

        fetch(url as string, requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(response);
            })
            .then((json) => {
                this.reloadModulesAndTracks();
                onSave(json);
                if (isComponentVersion) {
                    this.props.dispatchers.catalogTree.updateComponentChild({ child: json, parent: component });
                }
            })
            .catch((response) => {
                response.json().then((json: any) => {
                    this.props.dispatchers.setInReduxState({ errorCode: json.errorCode, errorMessage: json.message, isErrorModalOpen: true });
                });
            });
    }

    private async handleSaveEvaluationComponent() {
        const createNewMajorVersion = this.props.evaluationComponentItemEditor.createNewMajorVersion || 
            !_.isEqual(this.props.evaluationComponentItemEditor.component.currentComponentVersion.evaluationDefinitionVersion?.evaluationCriterionVersions, this.props.evaluationComponentItemEditor.defaultComponent.currentComponentVersion.evaluationDefinitionVersion?.evaluationCriterionVersions);
            const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
            const url = Utils.adjustUrlToServerContext(additionalUrlParam + `restful/trainingServiceEndpoint/component/${this.props.evaluationComponentItemEditor.component.id}/${createNewMajorVersion}`);
        let component = this.props.evaluationComponentItemEditor.component;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(component)
        };

        fetch(url as string, requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(response);
            })
            .then((json) => {
                this.reloadModulesAndTracks();
                let updatedComponent = json;
                if (component.id === undefined) {
                    this.props.dispatchers.catalogTree.addToComponents(updatedComponent);
                } else {
                    this.props.dispatchers.catalogTree.updateComponent(updatedComponent);
                }
                this.props.dispatchers.evaluationComponentItemEditor.setComponent(updatedComponent);
                this.props.dispatchers.evaluationComponentItemEditor.onSaveComponent();
            })
            .catch((response) => {
                response.json().then((json: any) => {
                    this.props.dispatchers.setInReduxState({ errorCode: json.errorCode, errorMessage: json.message, isErrorModalOpen: true });
                });
            });

    }

    private async handleSaveEducationComponent() {
        let component = _.cloneDeep(this.props.educationComponentItemEditor.component);
        const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";
        const url = Utils.adjustUrlToServerContext(additionalUrlParam + `restful/trainingServiceEndpoint/educationComponent/${this.props.educationComponentItemEditor.component.id}`);
        const createNewMajorVersion = this.props.educationComponentItemEditor.createNewMajorVersion ||
            this.props.educationComponentItemEditor.addedCompetences.length > 0 ||
            this.props.educationComponentItemEditor.removedCompetences.length > 0;

        component.currentComponentVersion.competenceVersions = component.currentComponentVersion?.competenceVersions?.map((version: any) => {
            return { ...version, levelDefinitions: undefined };
        });
        const fullEducationComponent = {
            addedCompetences: this.props.educationComponentItemEditor.addedCompetences,
            removedCompetences: this.props.educationComponentItemEditor.removedCompetences,
            createNewMajorVersion: createNewMajorVersion,
            profileVersion: !this.props.educationComponentItemEditor.profileVersion ? null : {
                ...this.props.educationComponentItemEditor.profileVersion,
                competenceProfileCriterionVersions: this.props.educationComponentItemEditor.profileVersion.competenceProfileCriterionVersions,
                components: this.props.educationComponentItemEditor.profileVersion.components,
            },
            component: component
        };

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(fullEducationComponent)
        };

        fetch(url as string, requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(response);
            })
            .then((json) => {
                this.reloadModulesAndTracks();
                let updatedComponent = json;
                if (component.id === undefined) {
                    this.props.dispatchers.catalogTree.addToComponents(updatedComponent);
                } else {
                    this.props.dispatchers.catalogTree.updateComponent(updatedComponent);
                }
                this.props.dispatchers.educationComponentItemEditor.setComponent(updatedComponent);
                this.props.dispatchers.educationComponentItemEditor.onSaveComponent();
            })
            .catch((response) => {
                response.json().then((json: any) => {
                    this.props.dispatchers.setInReduxState({ errorCode: json.errorCode, errorMessage: json.message, isErrorModalOpen: true });
                });
            });
    }

    reloadModulesAndTracks() {
        this.props.dispatchers.catalogTree.loadModules(DEFAULT_CATALOG, this.props.catalogTree.renderExpands);
        this.props.dispatchers.catalogTree.loadTracks(DEFAULT_CATALOG, this.props.catalogTree.renderExpands);
    }

    handleCloseModalError = () => {
        this.props.dispatchers.setInReduxState({ errorCode: undefined, errorMessage: undefined, isErrorModalOpen: false });
    };

    renderMain() {
        return <SplitPaneExt className="Catalog" minSize="20%" size="35%">
            <div className="flex w100">
                <h2 className="Catalog_headerNoMargin">{_msg("Catalog.label")}</h2>
                <Divider style={{margin: '5px 0'}}></Divider>
                <CatalogTree {...this.props.catalogTree} dispatchers={this.props.dispatchers.catalogTree} onTrackSelect={this.onTrackSelect} onModuleSelect={this.onModuleSelect}
                    onComponentSelect={this.onComponentSelect}
                />
            </div>
            <div className="flex-container w100">
                <div className="flex-row-spaced">
                    <h2>
                        {this.getEditor()?.catalogItemVersion?.name}
                    </h2>
                    {this.props.editorType !== EditorType.EMPTY && <Button primary disabled={!this.isDirty()} onClick={this.handleSave}>
                        {_msg("Catalog.save.label")}
                    </Button>}
                </div>
                {this.props.editorType === EditorType.EMPTY && <EmptyItemEditor />}
                {this.props.editorType === EditorType.TRACK && <TrackItemEditor {...this.props.trackItemEditor} dispatchers={this.props.dispatchers.trackItemEditor} ref={this.refTrackItemEditor} />}
                {this.props.editorType === EditorType.MODULE && <ModuleItemEditor {...this.props.moduleItemEditor} dispatchers={this.props.dispatchers.moduleItemEditor} ref={this.refModuleItemEditor} />}
                {this.props.editorType === EditorType.DEFAULT && <CatalogItemEditor {...this.props.catalogItemEditor} dispatchers={this.props.dispatchers.catalogItemEditor} />}
                {this.props.editorType === EditorType.COMPONENT && <ComponentItemEditor {...this.props.componentItemEditor} dispatchers={this.props.dispatchers.componentItemEditor} />}
                {this.props.editorType === EditorType.EDUCATION_COMPONENT && <EducationComponentItemEditor {...this.props.educationComponentItemEditor} dispatchers={this.props.dispatchers.educationComponentItemEditor} certificates={this.props.catalogTree.components[CERTIFICATEN]} />}
                {this.props.editorType === EditorType.EVALUATION_COMPONENT && <EvaluationComponentItemEditor {...this.props.evaluationComponentItemEditor} dispatchers={this.props.dispatchers.evaluationComponentItemEditor} ref={this.refEvaluationComponentItemEditor} />}
                <CatalogErrorModal
                    isOpen={this.props.isErrorModalOpen}
                    errorCode={this.props.errorCode}
                    errorMessage={this.props.errorMessage}
                    onClose={this.handleCloseModalError}
                />
            </div>
        </SplitPaneExt>;

    }
};

export const infoCatalog = new ConnectedPageInfo(sliceCatalog, Catalog, `Catalog`);
infoCatalog.routeProps = { permission: "EDUCATION_VIEW_WEB_CATALOG" };