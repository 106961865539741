'use strict';

/*@ngInject*/
function GridRenderContainer($delegate, gridUtil, $timeout) {
	// since we  use variable row heights, the canvas height is computed incorrectly 
	// => we override this logic to ensure that scrolling works correctly
	$delegate.prototype.getCanvasHeight = function() {

		var delegate = this;

		if (!delegate.cachedHeight && !delegate.computingCachedHeight) {
			delegate.computingCachedHeight = true;
			// using timeout to make sure the changes are displayed before computing the new height
			$timeout(function() {
				var height = 0;
				$(delegate.grid.element).find('.ui-grid-row').each(function() {
					height += $(this).outerHeight();
				});
				delegate.cachedHeight = height;
				delegate.computingCachedHeight = false;
			});
		}

		if (!delegate.hasCachedHeight) {
			delegate.hasCachedHeight = true;
			var resetCachedHeight = function() {
				delegate.cachedHeight = 0;
			}
			var api = delegate.grid.api;
			var scope = delegate.grid.appScope;

			// these are all events that may affect the canvas height

			api.core.on.columnVisibilityChanged(scope, resetCachedHeight);
			api.core.on.rowsRendered(scope, resetCachedHeight);
			api.colResizable && api.colResizable.on.columnSizeChanged(scope, resetCachedHeight);
			api.grouping && api.grouping.on.groupingChanged(scope, resetCachedHeight);
			api.treeBase && api.treeBase.on.rowCollapsed(scope, resetCachedHeight);
			api.treeBase && api.treeBase.on.rowExpanded(scope, resetCachedHeight);
		}

		return delegate.cachedHeight;
	}
	
	return $delegate;
}

module.exports = GridRenderContainer;