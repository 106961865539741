'use strict';

require('angular-ui-grid');

require('angular-ui-grid/ui-grid.css');
require('./ui-grid.css');

angular.module('ui.grid') // not registering a new module

.directive('uiGridRow',       require('./ui-grid-row.directive'))
.directive('uiGridCell',      require('./ui-grid-cell.directive'))
.directive('uiGridSelection', require('./ui-grid-selection.directive'))
.directive('uiGridGrouping',  require('./ui-grid-grouping.directive'))

.decorator('GridRenderContainer',     require('./grid-renderer-container.decorator'));

angular.module('ui.grid.pagination')
.decorator('uiGridPaginationService', require('./ui-grid-pagination-service.decorator'));

module.exports = 'ui.grid';

