'use strict';

var css = require('./evaluations-fill-in.css');

/*@ngInject*/
function EvaluationsFillInCtrl($scope, $rootScope, Education, Collection, ModalSpinner) {
	var evaluations = this;
	
	evaluations.refresh = function() {
		ModalSpinner.show('education.evaluationFillIn.loading');
		Education.getEvaluationsToFillIn(evaluations, {
			params: {
				id: $rootScope.securityContext.id
			}
		}).then(function() {
			if (evaluations.evaluation) {
				// keep selection
				var collection = Collection.create(evaluations.data);
				var index = collection.indexOf(evaluations.evaluation, collection.eqId);
				if (index < 0) {
					delete evaluations.evaluation;
				} else {
					evaluations.evaluation = collection.data[index];
				}
			}
		}).finally(function() {
			ModalSpinner.hide();
		});
	}
	
	evaluations.refresh();
	
	/**
	 * Click handler for evaluation card.
	 */
	evaluations.select = function(evaluation) {
		evaluations.evaluation = evaluation;
		evaluations.attachments = [];
		if (evaluations.evaluation.attachments) {
			evaluations.attachments = evaluations.evaluation.attachments;
		}
	}
	
	evaluations.save = function(evaluation) {
		ModalSpinner.show('education.evaluationFillIn.saving');
		// save evaluation too
		if (evaluations.attachments.length > 0) {
			evaluation.attachments = evaluations.attachments;
		} else {
			delete evaluation.attachments;
		}
		Education.saveEvaluationToFillIn(null, {
			data: evaluation,
			params: {
				evaluatorId: $rootScope.securityContext.id,
				evaluationId: evaluation.id,
			}
		}).then(function() {
			evaluations.refresh();
			$scope.wizard.goToIndex(1);
		}).finally(function() {
			ModalSpinner.hide();
		});
	}
	
	evaluations.getIndexOfScore = function(scores, competence) {
		return scores.indexOf(competence.id, function(id, s) {
			return s.evaluationCriteriumVersion.id === id;
		});
	}
	
	evaluations.getScore = function(scores, competence) {
		var index = evaluations.getIndexOfScore(scores, competence);
		var score;
		if (index >= 0) {
			score = scores.data[index];
			return score;
		}
	}
	
	evaluations.isScoreFilled = function(score) {
		if (score) {
			return angular.isNumber(score.percentage) && score.percentage >= 0;
		}
		return false;
	}
	
	evaluations.isFilled = function(scores, competence) {
		var score = evaluations.getScore(scores, competence);
		if (score) {
			return evaluations.isScoreFilled(score);
		}
		return false;
	}
	
	evaluations.countFilledScores = function(scores) {
		var count = 0;
		angular.forEach(scores, function(score) {
			if (evaluations.isScoreFilled(score)) {
				count++;
			};
		});
		return count;
	}
}

module.exports = EvaluationsFillInCtrl;