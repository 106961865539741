import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldEditorProps, FieldRendererProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { push } from "connected-react-router";
import _ from "lodash";
import moment from "moment";
import { HistoryTable, sliceHistoryTable } from "components/HistoryTable";
import { ProteusConstants } from "ProteusConstants";
import React from "react";
import { Button, Checkbox, Form, Input, TextArea } from "semantic-ui-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { PARAMETER_MANAGER_SERVICE_FACADE_BEAN_GET_DATA_TYPES, PARAMETER_SERVICE_SAVE_PARAMETER } from "graphql/queries";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { ActionMeta } from "react-select";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";

const COLUMN_WIDTH = 350;
const TYPE_NUMBER = "number";
const TYPE_TEXT = "text";
const TYPE_PASSWORD = "password";
const PASSWORD = "PASSWORD";

export class ParameterEntityDescriptor extends EntityDescriptor {

    getGraphQlFieldsToRequest() {
        return "id, code, description, objectVersion, modificationDate, modificationUser, creationDate, creationUser, dataType { id, code, description, objectVersion }, " +
            "parameterValueHistory {id, objectVersion, modificationDate, modificationUser, creationDate, creationUser, bytes, validFrom, validUntil, value, bytesAsString }";
    }

    protected customize() {

        const parameterEntityDescriptor = this;
        parameterEntityDescriptor.addFieldDescriptor({ name: "parameterValueHistory", type: "ParameterValueHistoryItem", isInDefaultColumnConfigForEditor: false, filterable: false });

        const sliceParameterEditor = parameterEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceParameterEditor extends SliceEntityEditorPage {

            /**
             * @override
             */
            getSaveOperationName(): string {
                return `parameterService_saveParameter`;
            }

            /**
             * @override
             */
            getLoadOperationName(): string {
                return `parameterService_findParameterById`;
            }

            initQueries() {
                super.initQueries();
                this.saveMutation = PARAMETER_SERVICE_SAVE_PARAMETER;
            }

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                showWarningMessage: false as boolean,
                newDataTypeValue: undefined as unknown as {},
                selectedEntity: undefined
            }

            nestedSlices = {
                ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
                historyTable: sliceHistoryTable
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceParameterEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceParameterEditor>(this),

                /**
                * Because the save function (server-side) has additional logic, the default "save-service-function" has been overridden. 
                * Adapt options.variables for the overridden save function
                */
                superMutation: sliceEntityEditorPageOnlyForExtension.impures.invokeSaveMutation,
                async invokeSaveMutation(options: any) {
                    let localEntity = { ...this.getState().entity };
                    if (this.getState().duplication) {
                        localEntity = {
                            ...this.setNullEntityValuesWhenDuplicating(localEntity),
                            parameterValueHistory: this.setNullHistoryValuesWhenDuplicating(localEntity.parameterValueHistory)
                        };
                    }
                    options.variables = { entity: localEntity };
                    return await this.superMutation(options);
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    const result = await this.saveSuper(entity);

                    //if no exceptions occured, go back to table
                    if (result !== true) {
                        this.getDispatchers().dispatch(push(parameterEntityDescriptor.getEntityTableUrl()));
                    }
                },

                /**
                * When a new entity is created, dataType dropdown has a default value. It can't be null or cleared
                */
                async getDefaultDataType(entity: any) {
                    if (entity.dataType === undefined) {
                        const defaultValue = ((await apolloClient.query({ query: PARAMETER_MANAGER_SERVICE_FACADE_BEAN_GET_DATA_TYPES })).data.parameterManagerServiceFacadeBean_dataTypes)[0];
                        this.getDispatchers().setInReduxState({ entity: { ...entity, dataType: defaultValue } });
                    }
                },

                setNullHistoryValuesWhenDuplicating(history: []) {
                    let newHistory = [];
                    for (let i = 0; i < history.length; i++) {
                        newHistory.push(this.setNullEntityValuesWhenDuplicating(history[i]));
                    }
                    return newHistory;
                },

                setNullEntityValuesWhenDuplicating(entity: any) {
                    return {
                        ...entity,
                        id: null,
                        creationDate: null,
                        creationUser: null,
                        modificationDate: null,
                        modificationUser: null,
                        objectVersion: null
                    };
                }
            }

        }).setEntityDescriptor(parameterEntityDescriptor);

        parameterEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceParameterEditor> & EntityEditorPageProps> {
            protected historyTableRef = React.createRef<HistoryTable>();

            constructor(props: any) {
                super(props);
                this.updateParameterValue = this.updateParameterValue.bind(this);
            }

            setParameterHistoryTableEntities(newParameters: any[]) {
                if (newParameters) {
                    const entities = [...newParameters].sort((a: any, b: any) => moment(b.validFrom).valueOf() - moment(a.validFrom).valueOf());
                    this.historyTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(entities);
                    if (!this.historyTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()) {
                        this.props.dispatchers.setInReduxState({ selectedEntity: entities[0] });
                    } else {
                        this.props.dispatchers.setInReduxState({ selectedEntity: entities[this.historyTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getSelected()!] });
                    }
                } else {
                    this.historyTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities([]);
                }
            }

            async load(id: any) {
                const entity = await super.load(id);
                this.setParameterHistoryTableEntities(entity?.parameterValueHistory);
                await this.props.dispatchers.getDefaultDataType(entity || {});
            }

            protected nestedEntityDescriptor: EntityDescriptor = new EntityDescriptor({
                name: "ParameterValueHistoryItemTable",
                miniFields: ["code"]
            }, false)
                .addFieldDescriptor({ name: ProteusConstants.VALID_FROM, type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT }) })
                .addFieldDescriptor({ name: ProteusConstants.VALID_UNTIL, type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: ProteusConstants.DATE_TIME_FORMAT }) })

            protected columns = [{ name: ProteusConstants.VALID_FROM, width: COLUMN_WIDTH }, { name: ProteusConstants.VALID_UNTIL, width: COLUMN_WIDTH }];

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                const that = this;
                result.entityDescriptor = new EntityDescriptor({ name: "Parameter" });
                result.entityDescriptor.addFieldDescriptor({ name: "code", type: FieldType.string })
                result.entityDescriptor.addFieldDescriptor({ name: "description", type: FieldType.string })
                result.entityDescriptor.addFieldDescriptor({ name: "dataType", type: "DataType", value: { code: "STRING", description: "String" } }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        let newProps = {
                            ...props,
                            onChange: async (newValue: any) => {
                                await that.onApply();
                                if (that.props.entity.parameterValueHistory?.length > 0) {
                                    that.props.dispatchers.setInReduxState({ showWarningMessage: true, newDataTypeValue: newValue });
                                } else {
                                    that.props.dispatchers.setInReduxState({ entity: { ...that.props.entity, dataType: newValue } });
                                }
                            },
                            isClearable: false,
                            disableDefaultOnChange: true,
                            selectedValue: Utils.navigate(props.formikProps?.values, "dataType", false, ".")
                        }
                        return React.createElement(DataTypeAssociationFieldEditor as any, newProps as FieldEditorProps);
                    }
                }())
                return result;
            }

            async updateParameterValue(updatedParameterValue: [{ key: any }, any]) {
                const entity = super.getEntityValuesFromForm();
                let newParameters = [];
                for (let i = 0; i < updatedParameterValue.length; i++) {
                    if (updatedParameterValue[i].value === undefined) {
                        if (this.props.entity.dataType.code === ProteusConstants.STRING) {
                            newParameters.push({ ...updatedParameterValue[i], value: "" });
                        } else if (this.props.entity.dataType.code === ProteusConstants.INTEGER) {
                            newParameters.push({ ...updatedParameterValue[i], value: 0 });
                        } else if (this.props.entity.dataType.code === ProteusConstants.DOUBLE) {
                            newParameters.push({ ...updatedParameterValue[i], value: 0.0 });
                        } else if (this.props.entity.dataType.code === ProteusConstants.BOOLEAN) {
                            newParameters.push({ ...updatedParameterValue[i], value: false });
                        } else if (this.props.entity.dataType.code === ProteusConstants.DATE) {
                            newParameters.push({ ...updatedParameterValue[i], value: this.formatDate(moment(new Date(), ProteusConstants.DATE_TIME_FORMAT).toISOString()) });
                        } else if (this.props.entity.dataType.code === ProteusConstants.BYTE_ARRAY) {
                            newParameters.push({ ...updatedParameterValue[i], value: "" });
                        }
                    } else {
                        newParameters.push(updatedParameterValue[i]);
                    }
                }
                if (newParameters.length < this.props.entity.parameterValueHistory.length) {
                    this.historyTableRef.current?.getEntityTableSimpleCustomizedRef().current?.setSelected(undefined);
                }
                this.props.dispatchers.setInReduxState({ entity: { ...entity, parameterValueHistory: newParameters } });
                this.setParameterHistoryTableEntities(newParameters);
            }

            replaceModifiedParameterValueInEntity(updatedParameterValue: any) {
                let newParameters = this.props.entity.parameterValueHistory.filter((item: any) => item.validFrom !== updatedParameterValue.validFrom);
                newParameters.push(updatedParameterValue);
                this.updateParameterValue(newParameters);
            }

            getLabelForValueField() {
                return <Form.Field className="Parameter_historyValueField"
                    label={this.props.entity.code ? _msg("Parameter.historyValueLabelForEntity.label", this.props.entity.code) : _msg("Parameter.historyValueLabel.label")}>
                </Form.Field>
            }

            renderInputValueComponent(selectedEntity: any, defaultValue: any, props?: any) {
                return <Form>
                    {this.getLabelForValueField()}
                    <Form.Field>
                        <Input value={selectedEntity?.value || defaultValue} {...props} fluid onChange={(e, data) => {
                            let updatedParameterValue = { ...selectedEntity, value: data.value };
                            if (props?.type === TYPE_NUMBER && !props.step) {
                                updatedParameterValue = { ...updatedParameterValue, value: parseInt(updatedParameterValue.value) };
                            } else if (props?.type === TYPE_NUMBER && props.step) {
                                updatedParameterValue = { ...updatedParameterValue, value: parseFloat(updatedParameterValue.value) };
                            }
                            this.replaceModifiedParameterValueInEntity(updatedParameterValue);
                        }} />
                    </Form.Field>
                </Form>
            }

            renderByteInputComponent(selectedEntity: any, defaultValue: any) {
                return <Form>
                    {this.getLabelForValueField()}
                    <Form.Field>
                        <TextArea value={selectedEntity?.bytesAsString || defaultValue} rows={10} fluid onChange={(e, data) => {
                            let updatedParameterValue = { ...selectedEntity, bytesAsString: data.value };
                            this.replaceModifiedParameterValueInEntity(updatedParameterValue);
                        }} />
                    </Form.Field>
                </Form>
            }

            renderCheckboxValueComponent(selectedEntity: any) {
                return <Form>
                    {this.getLabelForValueField()}
                    <Form.Field>
                        <Checkbox checked={selectedEntity?.value || false} className="Parameter_checkbox" onChange={() => {
                            let updatedParameterValue = { ...selectedEntity, value: !selectedEntity.value };
                            this.replaceModifiedParameterValueInEntity(updatedParameterValue);
                        }}></Checkbox>
                    </Form.Field>
                </Form>
            }

            formatDate(date: string | undefined) {
                return date?.split(".")[0] + "Z";
            }

            renderDatePickerValueComponent(selectedEntity: any) {
                let value;
                if (selectedEntity) {
                    value = moment(new Date(selectedEntity.value), ProteusConstants.DATE_TIME_FORMAT);
                } else {
                    value = moment(new Date(), ProteusConstants.DATE_TIME_FORMAT);
                }
                return <Form>
                    {this.getLabelForValueField()}
                    <Form.Field>
                        <DatePickerReactCalendar format={ProteusConstants.DATE_TIME_FORMAT} value={value} allowClear={true} onChange={(date) => {
                            let updatedParameterValue = { ...selectedEntity, value: this.formatDate(date?.toISOString()) };
                            this.replaceModifiedParameterValueInEntity(updatedParameterValue);
                        }}>
                        </DatePickerReactCalendar>
                    </Form.Field>
                </Form>
            }

            protected onCancel = () => {
                this.props.dispatchers.setInReduxState({ showWarningMessage: false, newDataTypeValue: undefined });
            }

            protected onConfirm = () => {
                this.props.dispatchers.setInReduxState({ showWarningMessage: false, entity: { ...this.props.entity, dataType: this.props.newDataTypeValue }, newDataTypeValue: undefined });
            }

            renderForm() {
                let hasValue = this.props.entity.parameterValueHistory.length > 0;
                const isPassword = this.props.entity?.code ? this.props.entity.code.split("_")[this.props.entity.code.split("_").length - 1] === PASSWORD : false;
                return (<>
                    {super.renderForm()}
                    <ModalExt
                        severity={Severity.WARNING}
                        open={this.props.showWarningMessage}
                        header={_msg("Parameter.warningMessage.header.label")}
                        content={_msg("Parameter.warningMessage.content.label")}
                        onClose={this.onCancel}
                        actions={[
                            <Button key="cancel" onClick={this.onCancel}>{_msg("general.cancel")}</Button>,
                            <Button key="ok" primary onClick={this.onConfirm}>{_msg("general.ok")}</Button>
                        ]}
                    />
                    {this.props.entity.parameterValueHistory?.length > 0 && <label className="Parameter_historyTableLabel">
                        {this.props.entity.code ? _msg("Parameter.historyTableLabelForEntity.label", this.props.entity.code) : _msg("Parameter.historyTableLabel.label")}</label>}
                    <div className="Parameter_historyTable">
                        <HistoryTable onSave={this.updateParameterValue} onDelete={this.updateParameterValue} columns={this.columns} ref={this.historyTableRef}
                            onAdd={sliceHistoryTable.reducers.onAdd} {...this.props.historyTable} dispatchers={this.props.dispatchers.historyTable}
                            formCustomizer={{ headerContent: _msg("ParameterValueHistoryItem.label"), headerIcon: "calendar alternate outline" }}
                            entityDescriptor={this.nestedEntityDescriptor} actions={{ showDeleteButton: false, showAddButton: true }}
                            onSelectItem={(itemId) => this.props.dispatchers.setInReduxState({ selectedEntity: this.historyTableRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[itemId] })} />
                    </div>
                    <div className="Parameter_valueField">
                        {hasValue && this.props.entity.dataType?.code === ProteusConstants.STRING && this.renderInputValueComponent(this.props.selectedEntity, "", { type: isPassword ? TYPE_PASSWORD : TYPE_TEXT })}
                        {hasValue && this.props.entity.dataType?.code === ProteusConstants.INTEGER && this.renderInputValueComponent(this.props.selectedEntity, 0, { type: TYPE_NUMBER })}
                        {hasValue && this.props.entity.dataType?.code === ProteusConstants.DOUBLE && this.renderInputValueComponent(this.props.selectedEntity, 0.0, { type: TYPE_NUMBER, step: 0.1 })}
                        {hasValue && this.props.entity.dataType?.code === ProteusConstants.DATE && this.renderDatePickerValueComponent(this.props.selectedEntity)}
                        {hasValue && this.props.entity.dataType?.code === ProteusConstants.BOOLEAN && this.renderCheckboxValueComponent(this.props.selectedEntity)}
                        {hasValue && this.props.entity.dataType?.code === ProteusConstants.BYTE_ARRAY && this.renderByteInputComponent(this.props.selectedEntity, "")}
                    </div>
                </>
                );
            }

            componentDidUpdate(prevProps: any) {
                if (prevProps.entity?.dataType !== this.props.entity?.dataType && prevProps.entity?.dataType !== undefined) {
                    //change dataType value => delete all parameterValueHistory values
                    this.props.dispatchers.setInReduxState({ entity: { ...this.props.entity, parameterValueHistory: [] } });
                    this.setParameterHistoryTableEntities([]);
                }
            }
        }

        class DataTypeAssociationFieldEditor extends AssociationFieldEditor<FieldEditorProps>{
            protected updateCurrentOption(value: any, action: ActionMeta<any>, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
                let option = null;
                if (value) {
                    option = value.entity;
                }
                if (this.props.onChange) {
                    this.props.onChange(option)
                }
            }
        }

        fieldRenderers["ParameterValueHistoryItem"] = class extends React.Component<FieldRendererProps>{
            render = () => {
                const isPassword = this.props.entity?.code ? this.props.entity.code.split("_")[this.props.entity.code.split("_").length - 1] === PASSWORD : false;
                //for parameterValueHistory, return the value for the item with latest validUntil or with validUntil null
                if (this.props.value.length === 0) {
                    return null;
                }
                let maxDate = this.props.value[0].validUntil;
                let maxIndex = 0;
                if (this.props.value.length > 1 && maxDate !== null && maxDate !== undefined) {
                    for (let i = 0; i < this.props.value.length; i++) {
                        if (this.props.value[i].validUntil === null || this.props.value[i].validUntil === undefined) {
                            maxIndex = i;
                            break;
                        } else if (new Date(this.props.value[i].validUntil).getTime() > new Date(maxDate).getTime()) {
                            maxDate = this.props.value[i].validUntil;
                            maxIndex = i;
                        }
                    }
                }
                if (this.props.entity.dataType.code === ProteusConstants.DATE) {
                    return new Date(this.props.value[maxIndex].value).toDateString();
                }
                const displayedValue = this.props.value[maxIndex].value?.toString() || "";
                if (!isPassword) {
                    return displayedValue;
                } else {
                    return _.repeat("*", displayedValue.length);
                }
            }
        }
    }
}