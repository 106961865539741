import { apolloClient, LinearizedItem, Utils } from "@crispico/foundation-react";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { RenderItemParams, Tree, Props as TreeProps, TreeReducers, TreeState } from "@crispico/foundation-react/components/TreeRRC/Tree";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Scriptable, ScriptableContainer } from "@famiprog-foundation/scriptable-ui";
import { EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS, EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_GROUP, EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT, EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_OF_MEMBER } from "graphql/queries";
import moment from "moment";
import { ProteusConstants } from "ProteusConstants";
import { ProteusUtils } from "ProteusUtils";
import { createRef, ReactElement } from "react";
import { Dropdown, Form, Header, Icon, Segment, SemanticICONS } from "semantic-ui-react";

interface Employee {
    description: string,
    team: {
        description: string,
        location: {
            description: string,
            hourRegime: {
                description: string,
                employees: {
                    name: string,
                    id: number
                }[]
            }[]
        }[]
    }[]
}

export interface Options {
    key: string,
    text: string,
    value: string
}

class TreeExpandedReducers<S extends TreeState> extends TreeReducers<S> {
    protected _getChildren(item: any): { localId: string, item: any; }[] {
        if (Array.isArray(item)) {
            return super._getChildren(item, {});
        }

        const team = item.team as Array<any>;
        const location = item.location as Array<any>;
        const hourRegime = item.hourRegime as Array<any>;
        const employees = item.employees as Array<any>;
        if (team && team.length !== 0) {
            return (team.map((item, index) => ({ localId: "team" + Utils.defaultIdSeparator + index, item })));
        } else if (location && location.length !== 0) {
            return (location.map((item, index) => ({ localId: "location" + Utils.defaultIdSeparator + index, item })));
        } else if (hourRegime && hourRegime.length !== 0) {
            return (hourRegime.map((item, index) => ({ localId: "hourRegime" + Utils.defaultIdSeparator + index, item })));
        } else if (employees && employees.length !== 0) {
            return (employees.map((item, index) => ({ localId: "employees" + Utils.defaultIdSeparator + index, item })));
        } else {
            return item.name;
        }
    }

    protected _hasChildren(item: any) {
        if (item.name) {
            return false;
        }
        return true;
    }
}

class TreeExpandedRaw extends Tree {
    protected renderItem = (params: RenderItemParams) => {
        const objectId = params.linearizedItem.itemId.split(Utils.defaultIdSeparator);
        const object = Utils.navigate(this.props.root, objectId);

        if (object.name) {
            return <div><Icon name="user"></Icon>{object.name}</div>;
        }
        let iconName: SemanticICONS = "clock";
        if (object.team) {
            iconName = "user circle";
        } else if (object.location) {
            iconName = "users";
        } else if (object.hourRegime) {
            iconName = "industry";
        }
        return <Header as="h5" key={params.linearizedItem.itemId} className="EmployeesTree_treeHeader"><Icon name={iconName}></Icon>{object.description}</Header>;
    };

    protected renderItemWrapper(linearizedItem: LinearizedItem, itemWrapperProps: any) {
        return <Scriptable id={linearizedItem.itemId} key={linearizedItem.itemId} onClick={async (e: any) => itemWrapperProps.onClick(e)}>{scriptable => {
            const newItemWrapperProps = { ...itemWrapperProps };
            newItemWrapperProps.onClick = async (e: any) => await scriptable.proxy.onClick({ target: { id: e.target.id } });
            return super.renderItemWrapper(linearizedItem, newItemWrapperProps);
        }}
        </Scriptable>
    }

    protected renderMain(mainProps: any, mainChildren: Array<any>): ReactElement {
        return <ScriptableContainer id="EmployeeTree">
            {super.renderMain(mainProps, mainChildren)}
        </ScriptableContainer>
    }
}

const TreeExpanded = ReduxReusableComponents.connectRRC(TreeState, TreeExpandedReducers, TreeExpandedRaw);

export type EmployeesTreeProps = { onSelectedIdChanged?: (params: string | undefined, dateChanged?: boolean) => void; } & RRCProps<EmployeesTreeState, EmployeesTreeReducers>;

export class EmployeesTreeState extends TreeState {
    employees: Employee[] = [];
    filteredEmployees: Employee[] = [];
    selectedEmployeeId: undefined | number;
    groupOptions: Options[] = [];
    groupCode: string = "";
    employeesOptions: Options[] = [];
    date: string = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0).toISOString();
    tree: TreeProps = {} as TreeProps;
    root: any = [];
}

export class EmployeesTreeReducers<S extends EmployeesTreeState = EmployeesTreeState> extends Reducers<S> { }

export class EmployeesTreeRaw extends TabbedPage<EmployeesTreeProps> {
    treeRef = createRef<Tree>();
    constructor(props: any) {
        super(props);
        this.setTreeRoot();
    }

    setTreeRoot() {
        let root = undefined as any;
        if (this.props.s.employees.length === 0 || this.props.s.selectedEmployeeId === undefined) {
            root = this.props.s.employees;
        } else {
            root = this.props.s.filteredEmployees;
        }
        this.props.r.setInReduxState({ root });
    }
    setInitialExpandedIds(expandedIds: {}) {
        this.treeRef.current?.setInitialExpandedIds(expandedIds);
    }

    setSelectedId(value: string | undefined) {
        this.treeRef.current?.props.r.setInReduxState({ selectedId: value });
    }

    getSelectedId() {
        return this.treeRef.current?.props.s.selectedId;
    }

    getTreeRoot() {
        return this.treeRef.current?.props.root;
    }

    protected async groupOrDateChanged(dateChanged: boolean) {
        await this.getEmployeeSnapshots();
        this.setSelectedId(undefined);
        this.props.onSelectedIdChanged?.(undefined, dateChanged);
    }

    findNewlyAddedElementInTree(root: any, employeeId: number, indexInTree: string): string | undefined {
        let index = undefined as string | undefined;
        for (let i = 0; i < root.length; i++) {
            if (root[i].employees) {
                for (let j = 0; j < root[i].employees.length; j++) {
                    if (root[i].employees[j].id == employeeId) {
                        return indexInTree + Utils.defaultIdSeparator + i + Utils.defaultIdSeparator + "employees" + Utils.defaultIdSeparator + j;
                    }
                }
            } else if (root[i].team) {
                index = this.findNewlyAddedElementInTree(root[i].team, employeeId, i + Utils.defaultIdSeparator + "team");
            } else if (root[i].location) {
                index = this.findNewlyAddedElementInTree(root[i].location, employeeId, indexInTree + Utils.defaultIdSeparator + i + Utils.defaultIdSeparator + "location");
            } else if (root[i].hourRegime) {
                index = this.findNewlyAddedElementInTree(root[i].hourRegime, employeeId, indexInTree + Utils.defaultIdSeparator + i + Utils.defaultIdSeparator + "hourRegime");
            }
            if (index != undefined) {
                return index;
            }
        }
        return undefined;
    }

    async getGroups() {
        const groups = (await apolloClient.query({
            query: EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_BY_CONTEXT,
            variables: {
                owner: ProteusUtils.getCurrentUser().id,
                groupContextCode: ProteusConstants.GROUP_CODE_EMPLOYEE_EXPLORER
            }
        })).data.employeeService_groupSnapshotsByContext;
        this.props.r.setInReduxState({
            groupOptions: groups.map((item: any) => ({
                key: item.code as string,
                text: item.name as string,
                value: item.code as string
            })),
            groupCode: groups[0].code
        });
    }

    /**
    * Called when we search for an employee. The query returns all the employee's groups
    * If he is member of the current selected group, search him in current group. Else, search the employee in first group of the returned groups
    */
    async getGroupOfSelectedEmployee(member: number) {
        const groups = (await apolloClient.query({
            query: EMPLOYEE_SERVICE_GET_GROUP_SNAPSHOTS_OF_MEMBER,
            variables: {
                owner: ProteusUtils.getCurrentUser().id,
                groupContextCode: ProteusConstants.GROUP_CODE_EMPLOYEE_EXPLORER,
                member,
                fromDate: this.props.s.date,
                untilDate: moment(this.props.s.date).endOf("day"),
                useExcludeFromSearch: false
            }
        })).data.employeeService_groupSnapshotsOfMember;
        let isInCurrentGroup = groups.filter((group: any) => group.code === this.props.s.groupCode).length > 0;
        if (!isInCurrentGroup) {
            this.props.r.setInReduxState({ groupCode: groups[0].code });
        }
    }

    async getEmployeeSnapshots() {
        const employees = (await apolloClient.query({
            query: EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS_BY_GROUP,
            variables: {
                fromDate: moment(this.props.s.date).toDate(),
                untilDate: moment(this.props.s.date).endOf("day").toDate(),
                groupCode: this.props.s.groupCode
            }
        })).data.employeeService_employeeSnapshotsByGroup;
        this.getEmployeesAsRootOfTree(employees);
    }

    async searchEmployee(text: string) {
        const result = ((await apolloClient.query({
            query: EMPLOYEE_SERVICE_GET_EMPLOYEE_SNAPSHOTS,
            variables: {
                searchText: text,
                fromDate: this.props.s.date,
                untilDate: moment(this.props.s.date).endOf("day")
            },
            context: { showSpinner: false }
        })).data.employeeService_employeeSnapshots);
        if (result === undefined || result === null) {
            return;
        }
        this.props.r.setInReduxState({
            employeesOptions: result.map((item: { id: number; name: string; firstName: string; contractHistoryItem: { employeeNumber: string; }; }) => ({
                key: item?.id as number,
                text: ((item?.name) as string).concat(" ", item?.firstName as string, (item?.contractHistoryItem?.employeeNumber ? " (" + item?.contractHistoryItem?.employeeNumber + ")" : "") as string),
                value: item?.id as number
            }))
        });
    }

    sortAndExpandEmployees(employees: Employee[]) {
        let expandedIds = {};
        employees = employees.sort((a: any, b: any) => a.description < b.description ? -1 : 1);
        for (let i = 0; i < employees.length; i++) {
            expandedIds = { ...expandedIds, [i]: true };
            employees[i].team = employees[i].team.sort((a: any, b: any) => a.description < b.description ? -1 : 1);
            for (let j = 0; j < employees[i].team.length; j++) {
                expandedIds = { ...expandedIds, [i + Utils.defaultIdSeparator + "team" + Utils.defaultIdSeparator + j]: true };
                employees[i].team[j].location = employees[i].team[j].location.sort((a: any, b: any) => a.description < b.description ? -1 : 1);
                for (let k = 0; k < employees[i].team[j].location.length; k++) {
                    expandedIds = {
                        ...expandedIds, [i + Utils.defaultIdSeparator + "team" + Utils.defaultIdSeparator + j + Utils.defaultIdSeparator +
                            "location" + Utils.defaultIdSeparator + k]: true
                    };
                    employees[i].team[j].location[k].hourRegime = employees[i].team[j].location[k].hourRegime.sort((a: any, b: any) => a.description < b.description ? -1 : 1);
                    for (let l = 0; l < employees[i].team[j].location[k].hourRegime.length; l++) {
                        expandedIds = {
                            ...expandedIds, [i + Utils.defaultIdSeparator + "team" + Utils.defaultIdSeparator + j + Utils.defaultIdSeparator +
                                "location" + Utils.defaultIdSeparator + k + Utils.defaultIdSeparator + "hourRegime" + Utils.defaultIdSeparator + l]: true
                        };
                        employees[i].team[j].location[k].hourRegime[l].employees = employees[i].team[j].location[k].hourRegime[l].employees.sort((a: any, b: any) =>
                            a.name < b.name ? -1 : 1);
                    }
                }
            }
        }
        this.setInitialExpandedIds(expandedIds);
    }

    /**
    * Adapt the employee object for the tree. The employees are grouped by category, team, location and hourRegime
    */
    getEmployeesAsRootOfTree(employees: any) {
        let employeesAsRoot: Employee[] = [];
        for (let i = 0; i < employees.length; i++) {
            let category = { description: employees[i].categoryHistoryItem?.category?.description ? employees[i].categoryHistoryItem?.category?.description : _msg("EmployeesTree.noCategory.label"), team: [] };
            if (employeesAsRoot.filter((item: { description: any; }) => item.description === category.description).length === 0) {
                employeesAsRoot.push(category);
            }
            let categoryIndex = employeesAsRoot.findIndex((x: { description: any; }) => x.description === category.description);

            let team = { description: employees[i].teamHistoryItem?.team?.description ? employees[i].teamHistoryItem?.team?.description : _msg("EmployeesTree.noTeam.label"), location: [] };
            if (employeesAsRoot[categoryIndex].team.filter((item: { description: any; }) => item.description === team.description).length === 0) {
                employeesAsRoot[categoryIndex].team.push(team);
            }
            let teamIndex = employeesAsRoot[categoryIndex].team.findIndex((x: { description: any; }) => x.description === team.description);

            let location = { description: employees[i].locationHistoryItem?.location?.description ? employees[i].locationHistoryItem.location.description : _msg("EmployeesTree.noLocation.label"), hourRegime: [] };
            if (employeesAsRoot[categoryIndex].team[teamIndex].location.filter((item: { description: any; }) => item.description === location.description).length === 0) {
                employeesAsRoot[categoryIndex].team[teamIndex].location.push(location);
            }
            let locationIndex = employeesAsRoot[categoryIndex].team[teamIndex].location.findIndex((x: { description: any; }) => x.description === location.description);

            let hourRegime = { description: employees[i].hourRegimeHistoryItem?.hourRegime?.description ? employees[i].hourRegimeHistoryItem?.hourRegime?.description : _msg("EmployeesTree.noHourRegime.label"), employees: [] };
            if (employeesAsRoot[categoryIndex].team[teamIndex].location[locationIndex].hourRegime.filter((item: { description: any; }) => item.description === hourRegime.description).length === 0) {
                employeesAsRoot[categoryIndex].team[teamIndex].location[locationIndex].hourRegime.push(hourRegime);
            }
            let hourRegimeIndex = employeesAsRoot[categoryIndex].team[teamIndex].location[locationIndex].hourRegime.findIndex((x: { description: any; }) => x.description === hourRegime.description);
            employeesAsRoot[categoryIndex].team[teamIndex].location[locationIndex].hourRegime[hourRegimeIndex].employees.push({ name: (employees[i].name + " " + employees[i].firstName), id: employees[i].id });
        }
        this.sortAndExpandEmployees(employeesAsRoot);
        this.props.r.setInReduxState({ employees: employeesAsRoot });
        this.filterEmployees();
    }

    filterEmployees() {
        if (this.props.s.selectedEmployeeId === undefined) {
            this.setTreeRoot();
            return;
        }
        let filteredEmployees: Employee[] = [];
        let employees = this.props.s.employees;
        for (let i = 0; i < employees.length; i++) {
            let category = { description: employees[i].description, team: [] as any };
            for (let j = 0; j < employees[i].team.length; j++) {
                let team = { description: employees[i].team[j].description, location: [] as any };
                for (let k = 0; k < employees[i].team[j].location.length; k++) {
                    let location = { description: employees[i].team[j].location[k].description, hourRegime: [] as any };
                    for (let l = 0; l < employees[i].team[j].location[k].hourRegime.length; l++) {
                        let hourRegime = { description: employees[i].team[j].location[k].hourRegime[l].description, employees: [] as any };
                        for (let m = 0; m < employees[i].team[j].location[k].hourRegime[l].employees.length; m++) {
                            if (employees[i].team[j].location[k].hourRegime[l].employees[m].id === this.props.s.selectedEmployeeId) {
                                hourRegime.employees.push(employees[i].team[j].location[k].hourRegime[l].employees[m]);
                            }
                        }
                        if (hourRegime.employees.length > 0) {
                            location.hourRegime.push(hourRegime);
                        }
                    }
                    if (location.hourRegime.length > 0) {
                        team.location.push(location);
                    }
                }
                if (team.location.length > 0) {
                    category.team.push(team);
                }
            }
            if (category.team.length > 0) {
                filteredEmployees.push(category);
            }
        }
        this.props.r.setInReduxState({ filteredEmployees });
        this.setTreeRoot();
    }

    render() {
        return (<>
            <Segment className="EmployeeTree">
                <Segment>
                    <Form>
                        <Form.Field>
                            <Dropdown placeholder={_msg("searchEmployee.label")} selection search clearable selectOnNavigation={false} noResultsMessage={_msg("general.noResultsFound")}
                                onSearchChange={(e, data) => {
                                    this.searchEmployee(data.searchQuery as unknown as string);
                                }}
                                onChange={async (e: any, data: any) => {
                                    if (String(data.value).length >= 1) {
                                        this.props.r.setInReduxState({ selectedEmployeeId: data.value });
                                        this.setSelectedId(undefined);
                                        let oldGroup = this.props.s.groupCode;
                                        await this.getGroupOfSelectedEmployee(data.value);
                                        //if the employee is in the same group, just filter the current employee array
                                        //else, if the group changed, componentDidUpdate() will call groupOrDateChanged(), so do nothing here
                                        if (oldGroup === this.props.s.groupCode) {
                                            this.filterEmployees();
                                        }
                                    } else {
                                        //when we clear the searched employee
                                        this.props.r.setInReduxState({ selectedEmployeeId: undefined, employeesOptions: [] });
                                        this.setSelectedId(undefined);
                                        setTimeout(() => {
                                            this.setTreeRoot();
                                        });
                                    }
                                }}
                                options={this.props.s.employeesOptions} value={this.props.s.selectedEmployeeId}
                            ></Dropdown>
                        </Form.Field>
                    </Form>
                    <Form>
                        <Form.Field>
                            <Dropdown selection search selectOnNavigation={false} options={this.props.s.groupOptions} onChange={(e, data) => {
                                this.props.r.setInReduxState({ groupCode: data.value?.toString() });
                            }} placeholder={_msg("general.selectGroup")} value={this.props.s.groupCode} noResultsMessage={_msg("general.noResultsFound")} />
                        </Form.Field>
                    </Form>
                    <Form>
                        <Form.Field>
                            <DatePickerReactCalendar value={moment(this.props.s.date)} format={ProteusConstants.DATE_FORMAT} onChange={(date) => {
                                this.props.r.setInReduxState({ date: date?.startOf('date').toISOString() });
                            }} allowClear={false}></DatePickerReactCalendar>
                        </Form.Field>
                    </Form>
                </Segment>
                <TreeExpanded id="tree" root={this.props.s.root} ref={this.treeRef} onSelectedIdChanged={this.props.onSelectedIdChanged}></TreeExpanded>
            </Segment>
        </>)
    }

    componentDidMount() {
        //load groupOptions only for the first time
        if (this.props.s.groupOptions.length === 0) {
            this.getGroups();
        }
    }

    async componentDidUpdate(prevProps: any) {
        if ((prevProps.s.groupCode !== this.props.s.groupCode || prevProps.s.date !== this.props.s.date) && this.props.s.groupCode !== "") {
            await this.groupOrDateChanged(prevProps.s.date !== this.props.s.date);
        }
    }
}

export const EmployeesTree = ReduxReusableComponents.connectRRC(EmployeesTreeState, EmployeesTreeReducers, EmployeesTreeRaw);