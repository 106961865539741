import { apolloClient, EntityDescriptor } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, } from "@crispico/foundation-react/reduxHelpers";
import { getGroupRelations_resourcesServiceFacadeBean_groupRelations_type } from "apollo-gen/getGroupRelations";
import { getGroupRelationTypes_groupServiceFacadeBean_groupRelationTypes } from "apollo-gen/getGroupRelationTypes";
import { GROUP_SERVICE_FACADE_BEAN_GET_GROUPS, GROUP_SERVICE_FACADE_BEAN_GET_GROUP_RELATION_TYPES } from "graphql/queries";
import _ from "lodash";
import React from "react";
import { Grid } from "semantic-ui-react";
import { groupRelationTableDescriptor } from "./customFieldRenderersEditors";

export const IN = "in";
export const OUT = "out";

export interface Option {
    key: string,
    label: string,
    from: string
}

export interface GroupRelationTable {
    id: number,
    name: string,
    description: string,
    direction: string
    relationType: string,
    relationTypeCode: string | null
}

export interface GroupRelationEditor {
    rowIndex: number,
    values: GroupRelationTable & {
        group: {
            id: number,
            name: string
        }
    }
}

export interface GroupRelation {
    source: number,
    target: number,
    type: getGroupRelations_resourcesServiceFacadeBean_groupRelations_type
}

export const sliceRelationTab = createSliceFoundation(class SliceRelationTab {

    initialState = {
        groupRelationTypes: undefined as unknown as getGroupRelationTypes_groupServiceFacadeBean_groupRelationTypes[]
    }

    nestedSlices = {
        relationTable: sliceEntityTableLight
    }

    reducers = {
        ...getBaseReducers<SliceRelationTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceRelationTab>(this),

        async getGroupRelationTypes() {
            let groupRelationTypes = (await apolloClient.query({
                query: GROUP_SERVICE_FACADE_BEAN_GET_GROUP_RELATION_TYPES
            })).data.groupServiceFacadeBean_groupRelationTypes;
            this.getDispatchers().setInReduxState({ groupRelationTypes });
        },

        addOptionsToGroupRelationTypeDropdown(groupRelationTypeOptions: Option[], option: string | null) {
            if (option !== null) {
                groupRelationTypeOptions.push({ key: option, label: option, from: option });
            }
        },

        getOtherGroupsIds(selectedGroup: any) {
            let otherGroupsIds: number[] = []
            for (let i = 0; i < selectedGroup.relations.length; i++) {
                let otherGroup = this.getOtherGroup(selectedGroup.group, selectedGroup.relations[i]);
                if (!otherGroupsIds.includes(otherGroup)) {
                    otherGroupsIds.push(otherGroup);
                }
            }
            return otherGroupsIds;
        },

        getOtherGroup(group: any, relation: GroupRelation) {
            return group.id === relation.source ? relation.target : relation.source;
        },

        getRelationDirection(group: any, relation: GroupRelation) {
            return group.id === relation.source ? IN : OUT;
        },

        getRelationsForTable(selectedGroup: any, otherGroups: { id: number, name: string, description: string }[]) {
            let relationsForTable: GroupRelationTable[] = [];
            for (let i = 0; i < selectedGroup.relations.length; i++) {
                const groupRelation = selectedGroup.relations[i];
                const otherGroupId = this.getOtherGroup(selectedGroup.group, groupRelation);
                const otherGroup = otherGroups.filter((item: { id: number, name: string, description: string }) => item.id === otherGroupId)[0];
                const direction = this.getRelationDirection(selectedGroup.group, groupRelation);
                let relation = {
                    id: otherGroup.id,
                    name: otherGroup.name,
                    description: otherGroup.description,
                    direction: direction,
                    relationType: direction === IN ? groupRelation.type.inLabel : groupRelation.type.outLabel,
                    relationTypeCode: this.getState().groupRelationTypes.filter((item: getGroupRelationTypes_groupServiceFacadeBean_groupRelationTypes) =>
                        item.inLabel === groupRelation.type.inLabel || item.outLabel === groupRelation.type.outLabel)[0].code
                };
                relationsForTable.push(relation);
            }
            return relationsForTable;
        }
    }
})

type PropsNotFromState = {
    selectedGroup: any;
    addOrUpdateGroupRelation: (groupRelation: GroupRelationEditor) => void;
    deleteGroupRelation: (groupRelation: GroupRelationTable) => void;
}

export class RelationTab extends React.Component<PropsFrom<typeof sliceRelationTab> & PropsNotFromState> {
    protected entityTableLightRef = React.createRef<EntityTableLight>();

    setRelationTable(relations: GroupRelationTable[]) {
        this.entityTableLightRef.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(relations.sort((a: GroupRelationTable, b: GroupRelationTable) => a.name > b.name ? 1 : -1));
    }

    async getGroupsFromRelation(selectedGroup: any) {
        let relationsForTable: GroupRelationTable[] = [];
        if (selectedGroup.relations.length > 0) {
            const otherGroupsIds = this.props.dispatchers.getOtherGroupsIds(selectedGroup);
            // load data (name and description) for groups from relation
            let otherGroups = (await apolloClient.query({
                query: GROUP_SERVICE_FACADE_BEAN_GET_GROUPS,
                variables: {
                    groupIds: otherGroupsIds
                }
            })).data.groupServiceFacadeBean_groups;
            relationsForTable = this.props.dispatchers.getRelationsForTable(selectedGroup, otherGroups);
        }
        this.setRelationTable(relationsForTable);
    }

    getGroupRelationTypesOptions() {
        const groupRelationTypeOptions: Option[] = [];
        for (let i = 0; i < this.props.groupRelationTypes?.length; i++) {
            this.props.dispatchers.addOptionsToGroupRelationTypeDropdown(groupRelationTypeOptions, this.props.groupRelationTypes[i].inLabel);
            if (this.props.groupRelationTypes[i].inLabel !== this.props.groupRelationTypes[i].outLabel) {
                this.props.dispatchers.addOptionsToGroupRelationTypeDropdown(groupRelationTypeOptions, this.props.groupRelationTypes[i].outLabel);
            }
        }
        return groupRelationTypeOptions;
    }

    getCustomEntityDescriptorForEditor = () => {
        let descriptor = new EntityDescriptor({ name: "GroupRelationsEditor" });
        if (this.entityTableLightRef.current?.getEntityFormLightRef().current?.props.s.entity.relationType === undefined) {
            descriptor.addFieldDescriptor({ name: "group", type: "GroupSnapshot" });
        }
        descriptor.addFieldDescriptor({
            name: "relationType", type: FieldType.dropdown,
            fieldDescriptorSettings: { fieldIntervals: this.getGroupRelationTypesOptions() }
        });
        return descriptor;
    }

    getCustomEditorHeaderMessage = () => {
        if (this.entityTableLightRef.current?.getEntityFormLightRef().current?.props.s.entity.relationType === undefined) {
            return _msg("GroupRelationsEditor.header.add.label");
        }
        return _msg("GroupRelationsEditor.header.edit.label");
    }

    render() {
        return (
            <>
                <Grid className="GroupSnapshotTab_staticGroup GroupsManagement_flexGrow">
                    <Grid.Row columns={1}>
                        <Grid.Column className="GroupSnapshotTab_block">
                            <EntityTableLight {...this.props.relationTable} ref={this.entityTableLightRef} dispatchers={this.props.dispatchers.relationTable} entityDescriptor={groupRelationTableDescriptor}
                                actions={{ showDeleteButton: false, showAddButton: true, doNotAddEntityToTable: true }}
                                formCustomizer={{ customEntityDescriptorForEditor: this.getCustomEntityDescriptorForEditor(), headerIcon: "chain", headerContent: this.getCustomEditorHeaderMessage() }}
                                onDelete={(remainingRelations: GroupRelationTable[], relationIndex: number) => {
                                    this.props.deleteGroupRelation(this.entityTableLightRef.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[relationIndex]);
                                }}
                                onSave={(allRelations: GroupRelationTable[], relation: GroupRelationEditor) => {
                                    this.props.addOrUpdateGroupRelation(relation);
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );
    }

    async componentDidMount() {
        await this.props.dispatchers.getGroupRelationTypes();
        await this.getGroupsFromRelation(this.props.selectedGroup);
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceRelationTab> & PropsNotFromState) {
        if (!_.isEqual(this.props.selectedGroup.relations, prevProps.selectedGroup.relations)) {
            this.getGroupsFromRelation(this.props.selectedGroup);
        }
    }

    componentWillUnmount() {
        this.setRelationTable([]);
    }
}