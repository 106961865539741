import { Optional } from '@crispico/foundation-react';
import { AppMetaTempGlobals } from '@crispico/foundation-react/AppMetaTempGlobals';
import { RegistrationStatus } from 'apollo-gen/RegistrationStatus';
import { RegistrationType } from 'apollo-gen/RegistrationType';
import { appMeta, InitializationsForClient } from 'app';
import { ProteusConstants } from 'ProteusConstants';
import { loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider } from '../apollo-gen/loadMetadataProvider';

const NO_STATUS: string = "NO_STATUS";
const DEFAULT_STATUS_CODE: string = "DEFAULT_STATUS_CODE";
const USER_CACHE: string = "USER";
const CONTEXT_SYSTEM: string = "SYSTEM";
const DIRECT: string = "DIRECT";
const VIA_WF: string = "VIA_WF";
const NOT_ALLOWED: string = "NOT_ALLOWED";
const CREATE_PREFIX: string = "CREATE_";
const READ_PREFIX: string = "READ_";
const UPDATE_PREFIX: string = "UPDATE_";
const DELETE_PREFIX: string = "DELETE_";
const DIRECT_SUFFIX: string = "_DIRECT";
const VIA_WF_SUFFIX: string = "_VIA_WF";

export class MetadataProviderHelper {
    static getKey(metadataProvider: loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider,
        registrationTypeCode: string, registrationStatusCode: Optional<string>, propertyCode: string, currentContext: string, isKeyForUserCache?: boolean): Optional<string> {
        var typePropertyCodes: any = metadataProvider.typePropertyCodes;
        var status: Optional<string> = null;
        if (typePropertyCodes.indexOf(propertyCode) != -1) {
            status = NO_STATUS;
        } else if (registrationStatusCode != null) {
            status = registrationStatusCode;
        } else {
            var propertyValue: Optional<object> = MetadataProviderHelper.getPropertyValue(metadataProvider, registrationTypeCode, NO_STATUS, DEFAULT_STATUS_CODE, currentContext);
            status = propertyValue != null ? propertyValue.toString() : null;
        }
        return (isKeyForUserCache ? currentContext + "-" : "") + registrationTypeCode + "-" + status + "-" + propertyCode;
    }

    static getPropertyValue(metadataProvider: loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider, registrationType: string,
        registrationStatus: Optional<string>, property: string, currentContext: string): Optional<object> {

        if (metadataProvider == null) {
            return null;
        }

        var value: Optional<object> = null;
        var cache: any;

        // current context is not SYSTEM: first get the properties from the
        // current context and if the property doesn't exist get it from the default properties;
        // current context is SYSTEM: first it gets the property from the user
        // cache, then from the current context and finally from the default properties
        var allMetadataCaches: any = metadataProvider.allChaches;
        if (CONTEXT_SYSTEM != currentContext) {
            cache = allMetadataCaches[USER_CACHE];
            var keyForUserCache: Optional<string> = MetadataProviderHelper.getKey(metadataProvider, registrationType, registrationStatus, property, currentContext, true);
            if (keyForUserCache != null) {
                value = cache[keyForUserCache];
            }
        }

        var key: Optional<string> = MetadataProviderHelper.getKey(metadataProvider, registrationType, registrationStatus, property, currentContext);
        if (value == null && key != null) {
            cache = allMetadataCaches[currentContext];
            value = cache[key];
        }

        if (value == null && key != null && CONTEXT_SYSTEM != currentContext) {
            cache = allMetadataCaches[CONTEXT_SYSTEM];
            value = cache[key];
        }

        if (value == null) {
            const p =  metadataProvider.properties[property];
            if (!p) {
                throw Error(_msg("MetadataProviderHelper.missingProperty.label", property, registrationType, registrationStatus, currentContext));
            }
            value = metadataProvider.properties[property].defaultValue;
        }
        return value;
    }

    /**
     * An operation is allowed based on 2 criteria: the user's permissions and the roles values from metadata context for that specific registration type. 
     * metadata value: null or NOT_ALLOWED => false.
     * permission: operation + DIRECT or operation + registration type + DIRECT => true.
     * permission: (operation + VIA_WF or operation + registration type + VIA_WF) and metadata value: VIA_WF => true.
     * any other combination: false.
     */
    static isOperationAllowed(operation: string, registrationType: RegistrationType, registrationStatus: RegistrationStatus | null = null, currentContext: string): Boolean {
        let role: string = this.getUserRole(operation, registrationType.code!);
        const metadataPropertyValue = String(this.getPropertyValue((appMeta.helperAppContainer.dispatchers.getState().initializationsForClient as any).metadataProvider, registrationType.code!, registrationStatus?.code, operation, currentContext));
        if (metadataPropertyValue === null || metadataPropertyValue === NOT_ALLOWED) {
            // this operation is not allowed, regardless of the user's role
            return false;
        }
        return role === DIRECT || role === VIA_WF && metadataPropertyValue === VIA_WF;
    }

    /**
     * This method returns user role (DIRECT or VIA_WF) for a certain operation applied on a registration type.
     * It is looking first into all user's permissions to find the permissions that corresponds to the operation, regardless the registration type 
     * (of type operation prefix + DIRECT or operation prefix + VIA_WF).
     * If it finds a permission composed by prefix + VIA_WF or if no permission of this type exists, 
     * the algorithm is searching for permissions of type prefix + registration type + (DIRECT or VIA_WF).
     * Ex: operation UPDATE_ROLE, registrationType: VC, permissions: UPDATE_VC_VIA_WF.
     * The prefix will be "UPDATE_" => registrationTypePrefix = "UPDATE_VC".
     * First, search all permissions starting with "UPDATE_". For each permission, check if it is equals with "UPDATE_DIRECT". 
     * If yes, the user role is DIRECT. Else, check if the permission equals "UPDATE_VC_DIRECT".
     * If yes, the user role is DIRECT. Else, if the permission equals UPDATE_VC_VIA_WF or _UPDATE_VIA_WF, the user role is VIA_WF. 
     */
    static getUserRole(operation: string, registrationType: string): string {
        const permissions = (AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient).currentPermissions;
        const prefix: string | null = this.getPrefix(operation);
        if (prefix === null) {
            return "";
        }
        const registrationTypePrefix: string = prefix + registrationType;

        let result: string = NOT_ALLOWED;
        for (const permission in permissions) {
            if (permission.startsWith(prefix)) {
                if (this.isForAll(permission, prefix, DIRECT)) {
                    return DIRECT;
                }
                if (this.isForAll(permission, prefix, VIA_WF)) {
                    result = VIA_WF;
                    continue;
                }
                if (permission.startsWith(registrationTypePrefix)) {
                    if (permission.endsWith(DIRECT_SUFFIX)) {
                        return DIRECT;
                    }
                    if (permission.endsWith(VIA_WF_SUFFIX)) {
                        result = VIA_WF;
                    }
                }
            }
        }
        return result;
    }

    /**
     * The operation is composed of prefix + "ROLE".
     * The prefix is the name of the operation (CREATE, UPDATE, DELETE, READ) + "_".
     */
    static getPrefix(operation: string): string | null {
        switch (operation) {
            case ProteusConstants.CREATE_ROLE:
                return CREATE_PREFIX;
            case ProteusConstants.READ_ROLE:
                return READ_PREFIX;
            case ProteusConstants.UPDATE_ROLE:
                return UPDATE_PREFIX;
            case ProteusConstants.DELETE_ROLE:
                return DELETE_PREFIX;
        }
        return null;
    }

    static isForAll(code: string, prefix: string, mode: string): boolean {
        return code === prefix + mode;
    }
}