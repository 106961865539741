import { Optional } from "@crispico/foundation-react";
import { ItemRenderer } from "@crispico/react-timeline-10000";
import moment from "moment";
import { ProteusConstants } from "ProteusConstants";
import { Color, ColorsDecimal, ColorUtil } from "utils/ColorUtil";
import { MetadataProviderHelper } from "utils/MetadataProviderHelper";
import { CustomItem } from "../mapper/GanttAdapter";
import { MetadataPropertyEnum } from "../mapper/PlanningGanttAdapter";

export class BaseItemRenderer extends ItemRenderer {

    protected calculateColor(): ColorsDecimal {
        return ColorsDecimal.NULL;
    }

    getColor() {
        return Color(this.calculateColor()).hex();
    }

    getTextColor(): string {
        return Color(ColorUtil.getForeground(this.calculateColor())).hex();
    }

    extractColors = (text: string, opacity: number) => {
        const colorRegex = /#[^\s)]+/g;
        const colorsAsHex = text.match(colorRegex);
        return colorsAsHex?.map(colorAsHex => {
            const color = Color(colorAsHex);
            return `rgba(${color.red()}, ${color.green()}, ${color.blue()}, ${opacity - 0.1})`;
        });
    }

    getHeight(): string | number {
        return (this.props.height as number) + 10;
    }

    convertOptionalObjectToNumber(o: Optional<object>): number {
        return o != null ? Number(o) : Number(0);
    }

    getBaseBackgroundGradient(item: CustomItem, typeCode: string, statusCode: string | null): string {
        let gradient = super.getBackgroundGradient();
        const transparency: Optional<object> = MetadataProviderHelper.getPropertyValue(item.metadata, typeCode,
            statusCode, MetadataPropertyEnum.TRANSPARENCY_PERCENT, ProteusConstants.PLANNING);
        const opacity = 1 - this.convertOptionalObjectToNumber(transparency) / 100;
        const colors = this.extractColors(gradient, opacity);

        if (!colors) {
            return '';
        }
        return `linear-gradient(${colors[0]} ${this.getGradientStop()}%, ${colors[1]})`;
    }

    getBaseStyle(item: CustomItem, typeCode: string, statusCode: string | null, fromDate: Date, toDate: Date): object {
        let style: object = super.getStyle();
        const borderThickness: Optional<object> = MetadataProviderHelper.getPropertyValue(item.metadata, typeCode,
            statusCode, MetadataPropertyEnum.BORDER_THICKNESS, ProteusConstants.PLANNING);
        const segmentRadius: Optional<object> = MetadataProviderHelper.getPropertyValue(item.metadata, typeCode,
            statusCode, MetadataPropertyEnum.SEGMENT_RADIUS, ProteusConstants.PLANNING);
        let borderRadius: number = 0;
        if (!moment(fromDate).isSame(moment(toDate))) {
            borderRadius = this.convertOptionalObjectToNumber(segmentRadius) / 2;
        }

        style = {
            ...style,
            borderWidth: borderThickness,
            borderRadius: borderRadius
        }
        return style;
    }

}