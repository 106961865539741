import { createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, StateFrom } from "@crispico/foundation-react";
import { EntityTableLight, sliceEntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { CodeEntity } from "apollo-gen/CodeEntity";
import { push } from "connected-react-router";
import { COMPANY_SERVICE_SAVE_COMPANY } from "graphql/queries";
import _ from "lodash";
import { MessageType } from "pages/securityProfile/SecurityProfileEntityDescriptor";
import React from "react";
import { Form, Input, Message } from "semantic-ui-react";
import { companyTypesEditorEntityDescriptor, companyTypesTableEntityDescriptor } from "./customFieldRenderersEditors";

export class CompanyEntityDescriptor extends EntityDescriptor {

    toMiniString(entityWithMiniFields: any) {
        return entityWithMiniFields?.name;
    }

    protected customize() {

        const companyEntityDescriptor = this;
        companyEntityDescriptor.addFieldDescriptor({ name: "types", type: "CompanyType", isInDefaultColumnConfig: false });
        companyEntityDescriptor.addFieldDescriptor({ name: "addressHistory", type: "AddressHistoryItem", isInDefaultColumnConfig: false });

        const sliceCompanyEditor = companyEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceCompanyEditor extends SliceEntityEditorPage {

            /**
            * @override
            */
            getSaveOperationName(): string {
                return `companyService_saveCompany`;
            }

            initQueries() {
                super.initQueries();
                this.saveMutation = COMPANY_SERVICE_SAVE_COMPANY;
            }

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                showMessage: undefined as unknown as { type: MessageType }
            }

            nestedSlices = {
                ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
                entityTable: sliceEntityTableLight
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceCompanyEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceCompanyEditor>(this),

                superMutation: sliceEntityEditorPageOnlyForExtension.impures.invokeSaveMutation,
                async invokeSaveMutation(options: any) {
                    let localEntity = { ...this.getState().entity };
                    if (!localEntity.objectType) {
                        localEntity.objectType = "Company";
                    }
                    if (this.getState().duplication) {
                        localEntity = {
                            ...localEntity,
                            id: null,
                            creationDate: null,
                            creationUser: null,
                            modificationDate: null,
                            modificationUser: null,
                            objectVersion: null
                        };
                    }
                    options.variables = { entity: localEntity };
                    return await this.superMutation(options);
                },

                superSave: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    const result = await this.superSave(entity);

                    //if no exceptions occured, go back to table
                    if (result !== true) {
                        this.getDispatchers().setInReduxState({ showMessage: undefined });
                        this.getDispatchers().dispatch(push(companyEntityDescriptor.getEntityTableUrl()));
                    }
                }
            }
        }).setEntityDescriptor(companyEntityDescriptor);

        companyEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceCompanyEditor> & EntityEditorPageProps> {
            protected refEntityTableLight = React.createRef<EntityTableLight>();

            setTableEntity(data: CodeEntity[] | null) {
                let entities: CodeEntity[] = [];
                if (data) {
                    entities = data;
                } else {
                    entities = (this.props.entity?.types !== undefined ? this.props.entity.types : []);
                }
                this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.setEntities(_.sortBy(entities, "code"));
            }

            async load(id: number) {
                await super.load(id);
                this.setTableEntity(null);
            }

            filterCompanyTypes(searchedCompanyType: String) {
                if (searchedCompanyType === "") {
                    this.setTableEntity(null);
                    return;
                }
                let filteredCompanyTypes: CodeEntity[] = [];
                for (let i = 0; i < this.props.entity.types.length; i++) {
                    if (this.props.entity.types[i].code.toLowerCase().includes(searchedCompanyType.toLowerCase())) {
                        filteredCompanyTypes.push(this.props.entity.types[i]);
                    }
                }
                this.setTableEntity(filteredCompanyTypes);
            }

            /**
             * Overridden method to add custom css properties to EntityEditorPage
             */
            protected getContainerCssClasses() {
                let cssClasses = super.getContainerCssClasses();
                cssClasses.inner = cssClasses.inner + " CompanyEditor_segment";
                return cssClasses;
            }

            protected getTabbedPageCssClasses() {
                return super.getTabbedPageCssClasses() + " CompanyEditor";
            }

            async removeType(type: CodeEntity) {
                await this.onApply();
                let types = this.props.entity.types.filter((item: CodeEntity) => item.id !== type.id);
                this.props.dispatchers.setInReduxState({ entity: { ...this.props.entity, types }, showMessage: { type: MessageType.DELETE_SUCCESS } });
                setTimeout(() => { this.props.dispatchers.setInReduxState({ showMessage: undefined }); }, 3000);
            }

            async addType(type: CodeEntity) {
                if (type === undefined || type === null) {
                    return;
                }
                await this.onApply();
                let types = this.props.entity.types !== undefined ? [...this.props.entity.types] : []
                const typeAlreadyExists = types.filter((item: CodeEntity) => item.id === type.id).length > 0;
                if (typeAlreadyExists) {
                    this.props.dispatchers.setInReduxState({ showMessage: { type: MessageType.ADD_FAIL } });
                } else {
                    types.push(type);
                    this.props.dispatchers.setInReduxState({ entity: { ...this.props.entity, types }, showMessage: { type: MessageType.ADD_SUCCESS } });
                    this.setTableEntity(types);
                }
                setTimeout(() => { this.props.dispatchers.setInReduxState({ showMessage: undefined }); }, 3000);
            }

            renderForm() {
                return (
                    <>
                        {super.renderForm()}
                        {this.props.showMessage?.type === MessageType.ADD_SUCCESS ?
                            <Message className="CompanyEditor_message" success>
                                {_msg("entity.added.message", _msg("CompanyType.label"))}
                            </Message> : null}
                        {this.props.showMessage?.type === MessageType.ADD_FAIL ?
                            <Message error className="CompanyEditor_message" >
                                {_msg("entity.exists.message", _msg("CompanyType.label").toLowerCase())}
                            </Message> : null}
                        {this.props.showMessage?.type === MessageType.DELETE_SUCCESS ?
                            <Message success className="CompanyEditor_message">
                                {_msg("entity.deleted.message", _msg("CompanyType.label"))}
                            </Message> : null}
                        <Form>
                            <Form.Field>
                                <label>{_msg("CompanyType.label")}</label>
                                <Input  placeholder={_msg("searchCompanyType.label")} icon="search"
                                    onChange={(e, data) => {
                                        this.filterCompanyTypes(data.value as string);
                                    }}>
                                </Input>
                            </Form.Field>
                        </Form>
                        <div className="CompanyEditor_typesTable">
                            <EntityTableLight {...this.props.entityTable} dispatchers={this.props.dispatchers.entityTable} entityDescriptor={companyTypesTableEntityDescriptor} ref={this.refEntityTableLight}
                                formCustomizer={{ customEntityDescriptorForEditor: companyTypesEditorEntityDescriptor, headerIcon: "lock", headerContent: _msg("Company.addCompanyType.label") }}
                                actions={{ doNotAddEntityToTable: true, showDeleteButton: false, showEditButton: false }}
                                onDelete={async (types: CodeEntity[], removedTypeIndex: number) => {
                                    await this.removeType(this.refEntityTableLight.current?.getEntityTableSimpleCustomizedRef().current?.getEntities()[removedTypeIndex]);
                                }}
                                onSave={async (types: CodeEntity[], addedType: { rowIndex: number, values: { types: CodeEntity } }) => {
                                    await this.addType(addedType.values.types);
                                }}
                            />
                        </div>
                    </>
                );
            }

            componentDidUpdate(prevProps: PropsFrom<typeof sliceCompanyEditor>) {
                if (prevProps.match?.params.id !== this.props.match?.params.id) {
                    this.props.dispatchers.setInReduxState({ showMessage: undefined });
                }
            }

            componentDidMount() {
                this.props.dispatchers.setInReduxState({ showMessage: undefined });
            }
        }
    }
}