'use strict';

/*@ngInject*/
function Profile(DataSource) {

	var config = {
		baseUrl : '/restful/profileService'
	};

	var ds = new DataSource(config);

	ds.config.getAllProfiles = ds.config.getAllProfiles || {};
	ds.config.getAllProfiles.method = 'GET';
	ds.config.getAllProfiles.url = '/getAllProfiles';
	if (!ds.getAllProfiles) {
		ds.registerOperation('getAllProfiles');
	}


	ds.config.getProfileData = ds.config.getProfileData || {};
	ds.config.getProfileData.method = 'GET';
	ds.config.getProfileData.url = '/getProfileData';
	if (!ds.getProfileData) {
		ds.registerOperation('getProfileData');
	}
	
	return ds;
}

module.exports = Profile;