'use strict';

/**
 * Controller for the definitions view.
 */
/*@ngInject*/
function DefinitionsCtrl($scope, Collection, FormModal, scaleFilter, $translate, ErrorModal) {
	var definitions = $scope.competences.definitions = this,
		collection = null, 
		// editSource is used to determine if caller of the edit function is the add function or not;
		// values: "add" for add function and "null" for edit 
		editSource = null;
	definitions.disableSave = $scope.competences.disableSave;
	
	$scope.$watch('competences.version', function(version) {
		collection = Collection.create(version.levelDefinitions);
		definitions.options.data = collection.data;
	});
	
	definitions.options = {
		columnDefs: [
			{
				name: 'scale',
				width: '56px',
				displayName: 'education.competences.definitions.scale',
				labelFn: function(definition) {
					return scaleFilter(definition.scale);
				}
			},
			{
				name: 'percentage',
				width: '32px',
				displayName: 'education.competences.definitions.percentage.short',
				field: 'percentage'
			},
			{
				name: 'description',
				displayName: 'education.competences.definitions.description',
				field: 'description'
			},
			{
				name: 'actions',
				width: '32px',
				headerTemplateUrl: '/competences/definitions.header.html',
				cellTemplateUrl: '/competences/definitions.row.html'
			}
		],
		onRegisterApi: function(gridApi) {
			gridApi.selection.on.rowSelectionChanged(null, function(row) {
				definitions.edit(row.entity);
			});
		}
	};
	
	definitions.edit = function(definition) {
		var modal = FormModal.open({
			scope: {
				template: require('./competences-definition-form.html'),
				title: 'edit',
				model: angular.copy(definition)
			},
			actions: ['ok', 'cancel', 'close']
		});
		modal.result.then(function(result) {
			// add new level = > create new major version
			if (!result.id) {
				$scope.competences.createNewMajorVersion = true;
			}

			var oldDefinition = angular.copy(definition);
			var oldIndex = collection.indexOf(definition);
			angular.merge(definition, result);
			if (result.percentage > 100) {
				$translate('education.competences.definitions.percentage.error').then(function(msg) {
					ErrorModal.open({
						titleId: ErrorModal.VALIDATION_ERROR,
						message: msg
					});
				});
			} else {
				// we need to adjust the scales, only if the percentage has changed (for an edit);
				if (!editSource) {
					if (oldDefinition.percentage == result.percentage) {
						definitions.disableSave(false);
						editSource = null;
						return;
					}
				}	

				if (!editSource) {
					// edit: remove, then add
					definitions.remove(result, null, oldIndex);
				}			

				// add
				var adjustScaleIndex = -1;
				for (var i = 0; i < collection.data.length; i++) {
					if (result.percentage < collection.data[i].percentage) {
						result.scale = collection.data[i].scale.valueOf();
						adjustScaleIndex = i;
						break;
					}
				}

				if (adjustScaleIndex > -1) {
					for (var i = adjustScaleIndex; i < collection.data.length; i++) {
						collection.data[i].scale++;
					}
					collection.add(result, adjustScaleIndex);
				} else {
					collection.add(result);
					if (!editSource) {
						// if the definition was edited and it should be at the bottom; we need to adjust the scale
						result.scale = collection.data.length;
					}
				}

				// create new major version: change scale/percentage
				if (oldDefinition.scale != definition.scale || oldDefinition.percentage != definition.percetage) {
					$scope.competences.createNewMajorVersion = true;
				}

				definitions.disableSave(false);
			}
			editSource = null;
		});
		return modal;
	};
	
	definitions.add = function(insertPoint, $event) {
		$event && $event.stopPropagation();

		editSource = "add";
		definitions.edit({
			scale: insertPoint ? insertPoint.scale : collection.data.length + 1,
			percentage: 0,
			description: ''
		});
	};
	
	definitions.remove = function(definition, $event, oldIndex) {
		if ($event) {
			$event.stopPropagation();
		}

		var index = collection.indexOf(definition);
		if (oldIndex || oldIndex === 0) {
			index = oldIndex;
		}
		collection.removeByIndex(index);
		// adjust scales
		for (var i = index; i < collection.data.length; i++) {
			collection.data[i].scale--;
		}

		// create new major version
		$scope.competences.createNewMajorVersion = true;

		definitions.disableSave(false);
	};
}

module.exports = DefinitionsCtrl;