'use strict';

/*@ngInject*/
function uibModal($delegate, $rootScope) {
	
	var open = $delegate.open;

	$delegate.open = function(modalOptions) {
		if (modalOptions.spinner || $rootScope.securityContext) {
			return open.call($delegate, modalOptions);
		}
		return null;
	}

	return $delegate;

}

module.exports = uibModal;