'use strict';

module.exports = {

	template: require('../../../components/typeahead/typeahead.html'),
	bindings: {
		// inputs
		visible: '<', // ['TRACK', 'MODULE', 'COMPONENT']
		name: '@',
		type: '@',
		version: '@',
		disabled: '<',
		menuClass: '@',
		ignoreActions: '@',
		openByType: '@',
		onSelect: '&',
		required: '@',
		hideVersionLabel: '@',
		showActiveVersions: '@',
		editable: '@',
		selectedComponentForDropdown: '<',
		excludeTypes: '<'
	},
	require: {
		ngModelCtrl: 'ngModel' // is available only after $onInit
	},
	controllerAs: 'catalogs',
	controller: /*@ngInject*/ function($scope, catalogsFilter, Typeahead, $filter) {
		var catalogs = this;
		
		// initialize filter, it will be extended by CatalogsGroupsCtrl
		var addon = {
			icon: 'fa fa-refresh fa-spin'
		};
		catalogs.filter = {
			addon: addon,
			show: true,
			placeholder: $filter('translate')('education.catalogs.search')
		};
		var typeahead = catalogs.typeahead = $scope.typeahead =
			new Typeahead($scope, catalogs.filter, getName);
		typeahead.dropdownTpl = '/catalogs/catalogs-groups.html';
		
		// link to internal input
		catalogs.$onInit = function() {
			// add a formatter to display the name in the filter input
			typeahead.filter.required = this.required;
			typeahead.menuClass = catalogs.menuClass;
			catalogs.ngModelCtrl.$formatters.push(function(value) {
				typeahead.ngModelCtrl = catalogs.ngModelCtrl;
				typeahead.onInit();
			});
			typeahead.filter.required = catalogs.required;
			catalogs.filter.hideActive = !catalogs.showActiveVersions;

			$scope.$watch('catalogs.disabled', function(disabled) {
				typeahead.filter.disabled = disabled;
			});
			catalogs.catalogs = {};
			catalogs.catalogs.onAfterOpen = function() {
				if (!catalogs.selection && catalogs.openByType) {
					catalogs.openGroupByType("tracks");
				}
			}
		};

		catalogs.loadSpin = {
			start: function() {
				addon.show = true;
			},
			end: function() {
				addon.show = false;
			}
		};

		/**	
		 * Select function. Update the filter input with the name of the newly selected element and
		 * close the dropdown.
		 */
		catalogs.select = function() {
			typeahead.select(isVersionMode() ? catalogs.elementSelectedVersion : catalogs.element);
			catalogs.onSelect({catalog: catalogs});
		};

		/**
		 * Unselect function. Invalidate the filter input.
		 */
		catalogs.unselect = function() {
			typeahead.unselect();
		};

		function isVersionMode() {
			return angular.isDefined(catalogs.version);
		}

		function getName() {
			var name = catalogsFilter(catalogs.ngModelCtrl.$modelValue, 'name');
			if (name && !catalogs.hideVersionLabel) {
				name = name + " v" + catalogsFilter(catalogs.ngModelCtrl.$modelValue, 'version');
			}
			return name;
		}
	}
};