import { EmployeeSnapshot, ConstraintSegmentItem, RegistrationSnapshot, BaseSource, RosterRegistration, NodeData, Ship, Spare, PlanningShipItem } from '../PlanningGantt';
import { GanttAdapter, GanttMap, BaseMapper, CustomItem, RendererType } from './GanttAdapter';
import { MetadataProviderHelper } from '../../../../utils/MetadataProviderHelper'
import { loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider } from 'apollo-gen/loadMetadataProvider';
import { Optional } from '@crispico/foundation-react';
import { Color } from 'utils/ColorUtil';
import _ from 'lodash';
import { ProteusConstants } from 'ProteusConstants';

const SHIFT_SEGMENT_OPACITY = 0.2;

export enum MetadataPropertyEnum {
    BACKGROUND_COLOR = 'BACKGROUND_COLOR',
    BORDER_THICKNESS = 'BORDER_THICKNESS',
    BORDER_COLOR = 'BORDER_COLOR',
    HIDE_REGISTRATION_DETAIL = 'HIDE_REGISTRATION_DETAIL',
    TRANSPARENCY_PERCENT = 'TRANSPARENCY_PERCENT',
    GLOW_ON_HOVER = 'GLOW_ON_HOVER',
    SEGMENT_RADIUS = 'SEGMENT_RADIUS'
}

interface PlanningGanttGroupMapper extends BaseMapper {
    Team: Function,
    Location: Function,
    HourRegime: Function,
    EmployeeSnapshot: Function,
    Category: Function,
    ConstraintNode: Function,
    ConstraintTeamNode: Function
}

interface PlanningGanttSimpleGroupMapper extends BaseMapper {
    EmployeeSnapshot: Function,
    Category: Function,
    ConstraintNode: Function,
    ConstraintTeamNode: Function
}

interface PlanningGanttItemMapper extends BaseMapper {
    ConstraintSegmentItem: Function,
    RegistrationSnapshot: Function,
    PlanningShipItem: Function
}

interface PlanningGanttRowLayersMapper extends BaseMapper {
    RosterRegistration: Function
}

const SIMPLE_VIEW_TREE = {
    base: (source: BaseSource) => {
        return {
            title: source.description
        }
    },
    Category: (node: {description: string}) => { 
        return {title: <b>{node.description}</b>}
    },
    EmployeeSnapshot: (employee: EmployeeSnapshot) => {
        return {
            title: employee.completeName,
            icon: 'user'
        }
    },
    ConstraintNode: (node: NodeData) => {
        return {
            title: node.label
        }
    },
    ConstraintTeamNode: (node: NodeData) => {
        return {
            title: node.label,
            icon: 'users'
        }
    },
    Ship: (node: Ship) => {
        return {
            title: node.description,
            icon: 'ship'
        }
    },
    Spare: (node: Spare) => {
        return {
            title: node.description,
            icon: 'user'
        }
    }
};

const FULL_VIEW_TREE = {
    ...SIMPLE_VIEW_TREE,
    Team: () => {
        return {
            icon: 'users'
        }
    },
    Location: () => {
        return {
            icon: 'industry'
        }
    },
    HourRegime: () => {
        return {
            icon: 'clock'
        }
    }
};

export class PlanningGanttAdapter extends GanttAdapter {
    constructor(metadataProvider: loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider, simpleTreeStructure: boolean, allowViewRegistrationDetails: boolean, overlappingItems: Record<string, any[]>) {
        var counterId = 0;
        var shipRosterRegistration = 0;
        var maps = {} as GanttMap<PlanningGanttGroupMapper | PlanningGanttSimpleGroupMapper, PlanningGanttItemMapper, PlanningGanttRowLayersMapper>;
        maps = {
            groups: simpleTreeStructure ? SIMPLE_VIEW_TREE : FULL_VIEW_TREE,
            items: {
                base: (source: BaseSource) => {
                    return {
                        start: source.fromDate,
                        end: source.toDate
                    }
                },
                ConstraintSegmentItem: (constraintItem: ConstraintSegmentItem) => {
                    var item: CustomItem = {} as CustomItem;
                    item.key = counterId++;
                    item.type = RendererType.CONSTRAINT;
                    item.className = "PlanningGantt_counterItem";
                    return item;
                },
                RegistrationSnapshot: (registrationSnapshot: RegistrationSnapshot) => {
                    var item: CustomItem = {} as CustomItem;
                    item.key = registrationSnapshot.id;
                    item.type = RendererType.REGISTRATION;
                    item.metadata = metadataProvider;
                    item.allowViewRegistrationDetails = allowViewRegistrationDetails;
                    return item;
                },
                PlanningShipItem: (planningShipItem: PlanningShipItem, rowIndex: number) => {
                    const key = `${planningShipItem.rosterRegistration.type.code}-${planningShipItem.rosterRegistration.fromDate}-${planningShipItem.rosterRegistration.toDate}-${rowIndex}`;
                    if (!overlappingItems[key]) {
                        overlappingItems[key] = [];
                    }
                    var item = {
                        key: shipRosterRegistration++,
                        type: RendererType.SHIP_WORK_PERIOD,
                        start: planningShipItem.rosterRegistration.fromDate,
                        end: planningShipItem.rosterRegistration.toDate
                    }
                    overlappingItems[key].push(item);
                    return item;
                }
            },
            rowLayers: {
                base: (source: BaseSource) => {
                    return {
                        start: source.fromDate,
                        end: source.toDate
                    }
                },
                RosterRegistration: (rosterRegistration: RosterRegistration) => {
                    var backgroundColor: Optional<object> = MetadataProviderHelper.getPropertyValue(metadataProvider, rosterRegistration.type.code,
                        null, MetadataPropertyEnum.BACKGROUND_COLOR, ProteusConstants.PLANNING);
                    var color = Color(this.convertOptionalObjectToNumber(backgroundColor));

                    return {
                        style: {
                            backgroundColor: `rgba(${color.red()}, ${color.green()}, ${color.blue()}, ${SHIFT_SEGMENT_OPACITY})`
                        }
                    }
                }
            }
        }

        super(maps);
    }

    convertOptionalObjectToNumber(o: Optional<object>): number {
        return o != null ? Number(o) : Number(0);
    }
}

