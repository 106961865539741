import { createSliceFoundation, EntityDescriptor, EntityEditorFormSimple, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { EntityTableLight } from "@crispico/foundation-react/entity_crud/light_crud/EntityTableLight";
import { getEmployee_employeeService_employee, getEmployee_employeeService_employee_detail_contractHistory_suspensionHistory } from "apollo-gen/getEmployee";
import { HistoryTable, sliceHistoryTable } from "components/HistoryTable";
import React from "react";
import { Icon } from "semantic-ui-react";
import { contractSuspensionEntityDescriptor, HISTORY_FIELD_TYPE } from "./customFieldRenderersEditors";
import { EMPLOYEE_EDITOR } from "./EmployeeEditorBase";

export const CONTRACT_HISTORY = "ContractHistory";
export const CONTRACT_HISTORY_ITEM = "ContractHistoryItem";
export const SCALE_HISTORY_ITEM = "ScaleHistoryItem";

export const CONTRACT_SCRIPTABLE_UI_ID = "EmployeeEditor_ContractTab";

export const sliceContractTab = createSliceFoundation(class SliceContractTab {

    nestedSlices = {
        contractSuspensionTable: sliceHistoryTable
    }

    reducers = {
        ...getBaseReducers<SliceContractTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceContractTab>(this)
    }
})

type PropsNotFromState = {
    employee: getEmployee_employeeService_employee | undefined | null;
    isEditable: boolean;
    onOpenTableLight: (fieldDescriptor: FieldDescriptor, entities: any[]) => void;
    selectedContractIndex: number | undefined | null;
    addHistoryFieldsToDescriptor: (description: EntityDescriptor) => EntityDescriptor;
    updateContractHistoryTable: (suspensionHistory: getEmployee_employeeService_employee_detail_contractHistory_suspensionHistory[]) => void;
    referenceDate: Date | number;
}

export class ContractTab extends TabbedPage<PropsFrom<typeof sliceContractTab> & PropsNotFromState> {
    protected refHistoryTable = React.createRef<HistoryTable>();

    getHistoryTableRef() {
        return this.refHistoryTable;
    }

    protected getContractEntityDescriptor() {
        return new EntityDescriptor({ name: EMPLOYEE_EDITOR }, false)
            .addFieldDescriptor({
                name: "employee.detail.contractHistory",
                type: HISTORY_FIELD_TYPE,
                entityName: CONTRACT_HISTORY,
                mandatoryFields: ["employeeNumber"],
                icon: "file alternate",
                onOpenTableLight: this.props.onOpenTableLight,
                isDisabled: !this.props.isEditable,
                referenceDate: this.props.referenceDate
            })
            .addFieldDescriptor({
                name: "employee.detail.scaleHistory",
                type: HISTORY_FIELD_TYPE,
                entityName: SCALE_HISTORY_ITEM,
                mandatoryFields: ["scalePercentage"],
                icon: "file",
                onOpenTableLight: this.props.onOpenTableLight,
            });
    }

    public getContractModalAdditionalContent() {
        return (
            <>
                <div><b><Icon name="calendar minus outline"></Icon>{_msg("ContractSuspensionHistoryItem.label")}</b></div>
                <HistoryTable actions={{ showAddButton: this.props.isEditable && this.props.selectedContractIndex !== undefined, showEditButton: this.props.isEditable }} ref={this.refHistoryTable}
                    formCustomizer={{ headerIcon: "calendar minus outline", headerContent: _msg("ContractSuspensionHistoryItem.label") }}
                    {...this.props.contractSuspensionTable} onAdd={sliceHistoryTable.reducers.onAdd}
                    dispatchers={this.props.dispatchers.contractSuspensionTable}
                    onSave={(suspensionHistory: getEmployee_employeeService_employee_detail_contractHistory_suspensionHistory[]) => {
                        this.props.updateContractHistoryTable(suspensionHistory);
                    }}
                    onDelete={(suspensionHistory: getEmployee_employeeService_employee_detail_contractHistory_suspensionHistory[]) => {
                        this.props.updateContractHistoryTable(suspensionHistory);
                    }}
                    entityDescriptor={this.props.addHistoryFieldsToDescriptor(contractSuspensionEntityDescriptor)}
                />
            </>
        );
    }

    render() {
        return (
            <EntityEditorFormSimple scriptableUiId={CONTRACT_SCRIPTABLE_UI_ID} entityDescriptor={this.getContractEntityDescriptor()}
                hideButtonBar entity={{ employee: this.props.employee }} />
        );
    }
}