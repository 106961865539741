'use strict';

function resourcePickerTypeahead() {
	
	return {
		restrict: 'E',
		template: require('./resource-picker-typeahead.html'),
		scope: {
			resource: '=?',
			date: '<',
			limitResultsToCategoryOfEmployee: '@',
			onResourceChange: '<',
			disabled: '=?', // should be used as x-disabled
			required: '=?', // should be used as x-required,
			groupContextCode: '<',
			groupCodes: '<',
			excludedResource: '<',
			showEmployeeNumber: '<',
			includeContactPersons: '<',
			includeAllWithValidContract: '<',
			companyCode: '=',
			companyTypes: '=?'
		}, 
		controller: /*@ngInject*/ function($scope, Resources, ResourcePickerConstants, DATE_TIME_FORMAT) {
			var resourcePickerCtrl = $scope.resourcePickerCtrl; // this
			
			 $scope.$watch('resource', function(newResource, oldResource) {
			 	if (((!angular.isObject($scope.resource) && newResource != oldResource) || (angular.isObject($scope.resource) && ((newResource || oldResource) && (!newResource || !oldResource || newResource.id != oldResource.id)))) && $scope.onResourceChange && (!angular.isString($scope.resource) || (angular.isString($scope.resource) && $scope.resource == ''))) {
					$scope.onResourceChange($scope.resource);
				}
			 });
			// Watch for changes to the 'x-disabled' attribute
            $scope.$watch('disabled', function (newValue) {
                if (newValue !== undefined) {
                    // 'x-disabled' attribute has been defined
                    $scope.disabled = newValue;
                }
            });
			
			$scope.date = $scope.date || new Date();
			$scope.limitResultsToCategoryOfEmployee = $scope.limitResultsToCategoryOfEmployee || "-1";

			$scope.getEmployeeNumber = function(resource) {
				if (this.showEmployeeNumber && resource && resource.employeeNumber) {
					return ' (' + resource.employeeNumber + ')';
				}
				return '';
			}			

			resourcePickerCtrl.clearResource = function() {
				if ($scope.disabled) {
					return;
				}
				$scope.resource = null;
			};
			
			resourcePickerCtrl.getResources = function(searchText) {
				var config = {
					data: {
						searchText: searchText,
						fromDate: $scope.date,
						groupMembershipFromDate: $scope.date,
						groupContextCode: $scope.groupContextCode,
						groupCodes: $scope.groupCodes,
						limitResultsToCategoryOfEmployee: $scope.limitResultsToCategoryOfEmployee,
						excludeResourceId: $scope.excludedResource,
						includeContactPersons: $scope.includeContactPersons,
						includeAllWithValidContract: $scope.includeAllWithValidContract,
						companyCode: $scope.companyCode,
						companyTypeCodes: $scope.companyTypes
					}
				};
				return Resources.getResourceSnapshots({}, config).then(function(result) {
					return result.data;
				});
			};
			
		},
		controllerAs: 'resourcePickerCtrl'
	};
}

module.exports = resourcePickerTypeahead;