'use strict';

/*@ngInject*/
function uiGridPaginationService($delegate) {
	
	var _defaultGridOptions = $delegate.defaultGridOptions;
	
	$delegate.defaultGridOptions = function(gridOptions) {
		if (!gridOptions.paginationTemplate) {
			gridOptions.paginationTemplate = require('./pagination.html');
		}
		
		_defaultGridOptions.call(this, gridOptions);
	}
	
	return $delegate;
}

module.exports = uiGridPaginationService;