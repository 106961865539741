import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { CrudFormInEditorProps } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { EMPLOYEE_SERVICE_GET_EMPLOYEE_BY_ID_WITH_CONTRACT } from "graphql/queries";
import _ from "lodash";
import { PersonAssociationFieldEditor } from "pages/realization/RealizationEntityDescriptor";
import { ProteusConstants } from "ProteusConstants";
import React from "react";

interface Person {
    name: string,
    firstName: string,
    id: number,
    contractHistoryItem: {
        employeeNumber: number
    }
}

export class ResourceRosterRelationEntityDescriptor extends EntityDescriptor {

    protected customize() {

        const resourceRosterRelationEntityDescriptor = this;

        const sliceResourceRosterRelationEditor = resourceRosterRelationEntityDescriptor.infoEditor.slice = createSliceFoundation(class SliceResourceRosterRelationEditor extends SliceEntityEditorPage {

            getSaveOperationName(): string {
                return `resourceRosterRelationService_saveResourceRosterRelation`;
            }

            isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
                return true;
            }

            initialState = {
                ...sliceEntityEditorPageOnlyForExtension.initialState,
                initialResource: undefined as unknown as Person
            }

            reducers = {
                ...sliceEntityEditorPageOnlyForExtension.reducers,
                ...getBaseReducers<SliceResourceRosterRelationEditor>(this)
            }

            impures = {
                ...sliceEntityEditorPageOnlyForExtension.impures,
                ...getBaseImpures<SliceResourceRosterRelationEditor>(this),

                async getInitialEmployeeData(id: number) {
                    let initialResource = (await apolloClient.query({
                        query: EMPLOYEE_SERVICE_GET_EMPLOYEE_BY_ID_WITH_CONTRACT,
                        variables: {
                            employeeId: id
                        }
                    })).data.employeeService_employee;
                    initialResource = { ...initialResource, contractHistoryItem: { employeeNumber: initialResource?.detail?.contractHistory[0]?.employeeNumber } };
                    delete initialResource?.detail;
                    this.getDispatchers().setInReduxState({ initialResource });
                },

                superLoad: sliceEntityEditorPageOnlyForExtension.impures.load,
                async load(id: any) {
                    const entity = await this.superLoad(id);
                    if (entity !== undefined) {
                        if (entity.resource !== null && entity.resource !== undefined) {
                            await this.getInitialEmployeeData(entity.resource);
                        }
                    }
                },

                saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
                async save(entity: any) {
                    if (entity.resource !== null && entity.resource !== undefined && entity.resource.id !== undefined) {
                        entity = { ...entity, resource: entity.resource.id };
                    }
                    await this.saveSuper(entity);
                }
            }
        }).setEntityDescriptor(resourceRosterRelationEntityDescriptor);

        resourceRosterRelationEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceResourceRosterRelationEditor> & EntityEditorPageProps> {

            protected getPropsForFormSimple(): CrudFormInEditorProps {
                const result = super.getPropsForFormSimple();
                result.entityDescriptor = new EntityDescriptor({ name: "ResourceRosterRelation" }, false);
                resourceRosterRelationEntityDescriptor.doForFields(null, (fieldDescriptor) => result.entityDescriptor.addFieldDescriptor(fieldDescriptor));
                result.entityDescriptor.removeFieldDescriptors(ProteusConstants.RESOURCE);
                result.entityDescriptor.addFieldDescriptor({ name: ProteusConstants.RESOURCE, type: "EmployeeSnapshot" }, new class extends FieldDescriptor {
                    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                        const newProps = {
                            ...props,
                            includeAllWithValidContract: false,
                            isClearable: false,
                            queryLimit: -1,
                        };
                        return React.createElement(PersonAssociationFieldEditor as any, newProps as FieldEditorProps);
                    }
                });
                if (result.entity.resource !== undefined && result.entity.resource !== null && result.entity.resource.id === undefined &&
                    result.entity.resource === this.props.initialResource?.id) {
                    result.entity = { ...result.entity, resource: this.props.initialResource };
                }
                return result;
            }

            protected getEntityValuesFromForm() {
                // because in the form the "resource" field is of type "object" and in the table it is of type number (resource id), a conversion
                // is needed when navigating from "edit properties" to "view properties" (from form to table)
                let values = super.getEntityValuesFromForm();
                if (values.resource && values.resource.id !== undefined) {
                    this.props.dispatchers.setInReduxState({ initialResource: values.resource });
                    values = { ...values, resource: values.resource.id };
                }
                return values;
            }
        }
    }
}