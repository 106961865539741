'use strict';
	

var balanceUpload = {
		template: require('./balance-upload-page.html'),
		controllerAs: 'balanceUploadPage',
		controller: /*@ngInject*/ function ($scope, Upload, ModalSpinner, APP_URL) {
				
			ModalSpinner.hide();
			 
			var balanceUploadPage = this;					
			balanceUploadPage.year = (new Date()).getFullYear() + 1;
			
			balanceUploadPage.add = function(file) {
				if (!file) {
					// the add button is used multiple times
					// it will call this function with null before choosing another file
					return;
				}
				
				ModalSpinner.show();
				Upload.upload({
					url: APP_URL + '/restful/commonServiceEndpoint/backgroundTask/createAndRunBackgroundTask/',
					data: {
						taskType: 'PROCESS_BALANCE_COUNTERS_FILE',
						year: balanceUploadPage.year, 
						file: file
					}
				}).finally(function() {
					ModalSpinner.hide();
				});
			}
		}
	};

module.exports = balanceUpload;