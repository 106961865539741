'use strict';

/**
 * Some containers may hide content using 'display: none'; this can disrupt the behavior of certain
 * components that need to be visible in order to compute some initial properties. Adding 'proteus-appear'
 * to a component will broadcast the 'proteus.visible' event on the element scope and its children.
 */
function appear() {
	
	return {
		require: '?^tabPane',
		link: function($scope, $elt, $attrs, pane) {
			if (pane) {
				var tab = pane.tab;
				var onSelect = tab.onSelect;
				tab.onSelect = function() {
					onSelect.apply(tab, arguments);
					$scope.$broadcast('proteus.visible', true);
				}

				var onDeselect = tab.onDeselect;
				tab.onDeselect = function() {
					onDeselect.apply(tab, arguments);
					$scope.$broadcast('proteus.visible', false);
				}
			}
		}
	}
	
}

module.exports = appear;