import { apolloClient, ApolloContext, apolloGetExtensionFromError, CatchedGraphQLError, createSliceFoundation, getBaseImpures, getBaseReducers, Optional, PropsFrom, StateFrom, TestUtils, Utils } from '@crispico/foundation-react';
import { getCalendarEvents, getCalendarEventsVariables } from 'apollo-gen/getCalendarEvents';
import { loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider } from 'apollo-gen/loadMetadataProvider';
import React from 'react';
import { RegistrationType as RegistrationTypeFromApollo } from 'apollo-gen/RegistrationType';
import { RegistrationStatus as RegistrationStatusFromApollo } from 'apollo-gen/RegistrationStatus';
import { Group, CustomItem, Layer, Visitor, RendererType } from './mapper/GanttAdapter';
import { PlanningGanttAdapter } from './mapper/PlanningGanttAdapter';
import { GroupRenderer } from './renderer/GroupRenderer';
import { CustomItemRenderer } from './renderer/CustomItemRenderer';
import { TimebarFormat } from './renderer/TimebarFormat';
import { BackgroundLayer, DragToCreateParam, HighlightedInterval, IGanttAction, IGanttActionParamForRun, IGanttOnContextMenuShowParam, Item } from '@crispico/react-timeline-10000';
import TimelineExt from './TimelineExt';
import { Column, Table } from "fixed-data-table-2";
import { format } from 'date-fns';
import '@crispico/react-timeline-10000/lib/style.css';

/*
 * It's a separate CSS file, as it overwrites some react-timeline-10000 styles
 * and proteus.css is loaded too early.
 */
import './gantt.css';
import { CALENDAR_SERVICE_GET_CALENDAR_EVENTS, PLANNING_SERVICE_FACADE_BEAN_REMOVE_REGISTRATIONS_BY_ID, REGISTRATION_SERVICE_FACADE_BEAN_MAP_REGISTRATIONS_BY_ID, REGISTRATION_SERVICE_FACADE_BEAN_MERGE_REGISTRATIONS_BY_ID } from 'graphql/queries';
import _ from 'lodash';
import { AppMetaTempGlobals } from '@crispico/foundation-react/AppMetaTempGlobals';
import { ProteusConstants } from 'ProteusConstants';
import { CONSTRAINT_SEGMENT_ITEM, PLANNING_SHIP_ITEM } from 'pages/Planning/PlanningPage';
import { MetadataProviderHelper } from 'utils/MetadataProviderHelper';
import { ModalExt, Severity } from '@crispico/foundation-react/components/ModalExt/ModalExt';
import { Button, Modal } from 'semantic-ui-react';
import { ProteusGraphQLErrorExtensions, Registration } from 'pages/registrationEditor/RegistrationEditor';
import { ProteusUtils } from 'ProteusUtils';
import Interweave from 'interweave';
import { IActionParam } from '@crispico/react-timeline-10000/types/components/ContextMenu/IAction';
import { SwitchRegistrationEditor, SwitchRegistrationEditorRaw } from 'pages/agenda/SwitchRegistrationEditor';
import { CodeEntity } from 'apollo-gen/CodeEntity';
var moment = require('moment');

// change moment.js language
var localization = require('moment/locale/nl-be');
moment.updateLocale('nl-be', localization);

enum OPERATION { DELETE, MERGE, SPLIT, SWITCH, NONE };
const PUBLIC_HOLIDAY_CODE: string = 'VERLOFKALENDER';
const PLANNING_ITEM_HEIGHT: number = 25;
const PLANNING_HEADER_TITLE: string = 'PlanningGantt_teamHeader';
const REGISTRATION_SEGMENT_ITEM = "RegistrationSnapshot";

export interface Segment {
    source?: object | null,
    parent: Optional<Node>,
    isWaitingRegistration: boolean | null
}

export interface Node {
    children: Node[],
    source: Optional<object>,
    parent: Optional<Node>,
    data: Segment[]
}

export interface BaseSource {
    description: string,
    fromDate: Date,
    toDate: Date,
    objectType: string,
    title: string
}

export interface EmployeeSnapshot {
    completeName: string,
    employeeNumber: number
}

export interface ConstraintSegmentItem {
    actualValue: number,
    constraintValue: number,
    counterType: string,
    usedWithNullOrPrivateConstraintGroup: boolean
}

export interface PlanningShipItem {
    status: string,
    spare: number,
    spareName: string,
    spareStatus: string,
    resource: number,
    resourceName: string,
    rosterRegistration: RosterRegistration,
    rosterRegistrationId: number,
    replacementRegistrationId: number,
    startDateOfShifts?: number,
    endDateOfShifts?: number,
    hasExchange?: boolean
}

export interface RegistrationType {
    name: string,
    code: string
}

export interface RegistrationStatus {
    code: string,
    description: string
}

export interface RegistrationSnapshot {
    id: number,
    type: RegistrationType,
    status: RegistrationStatus,
    fromDate: Date,
    toDate: Date
}

export interface RosterRegistration {
    type: RegistrationType,
    status: RegistrationStatus,
    fromDate: Date,
    toDate: Date,
    categoryCode?: string
}

export interface CalendarEvent {
    fromDate: Date,
    tillDate: Date
}

export interface NodeData {
    label: String
}

export interface Ship extends CodeEntity {
    objectType: string
}

export interface Spare extends CodeEntity {
    objectType: string
}

export const slicePlanningGantt = createSliceFoundation(class SlicePlanningGantt {
    initialState = {
        ganttData: undefined as unknown as Node[],
        groups: [] as Group[],
        items: [] as CustomItem[],
        itemsFullyOverlapping: {} as Record<string, any[]>,
        rowLayers: [] as Layer[],
        highlightedIntervals: [] as JSX.Element[],
        startDate: undefined as unknown as Date,
        endDate: undefined as unknown as Date,
        calendarEvents: [] as unknown as CalendarEvent[],
        teamName: undefined as unknown as string,
        registrationTypesAndStatus: [] as string[],
        teamCode: '' as string,
        simpleTreeStructure: false as boolean,
        infoModal: { open: false as boolean, message: '' as string, title: '' as string, severity: undefined as Severity | undefined, registrationIds: [] as number[], operation: OPERATION.NONE as OPERATION, retry: false as boolean },
        validationExceptionsForWaitingRegistrations: false as boolean,
        shouldUpdate: false as boolean
    }

    reducers = {
        ...getBaseReducers<SlicePlanningGantt>(this),

        updateItemDates(state: StateFrom<SlicePlanningGantt>, params: { registrationId: number, fromDate: Date, toDate: Date }) {
            const item = state.items.filter(item => item.node.source.id === params.registrationId)[0];
            item.start = params.fromDate;
            item.end = params.toDate;
            state.shouldUpdate = true;
        }
    }

    impures = {
        ...getBaseImpures<SlicePlanningGantt>(this),

        async loadPlanningData(fromDate: Date, untilDate: Date, resourceFilter: string, registrationTypesAndStatus: string[],
            groupCode: string, metadataProvider: loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider, updateDatesForShipsPlanning: (fromDate: Date, untilDate: Date, groupCode: string) => { fromDate: Date, untilDate: Date; }, updateDates?: boolean) {
            if (updateDates) {
                const dates = updateDatesForShipsPlanning(fromDate, untilDate, groupCode);
                fromDate = dates.fromDate;
                untilDate = dates.untilDate
            }

            let requestData = {
                groupCode: groupCode,
                fromDate: fromDate,
                untilDate: untilDate, registrationTypesAndStatus: registrationTypesAndStatus,
                resourceFilter: resourceFilter
            }
            const additionalUrlParam = (window.location.pathname as string).includes("proteus") ? "" : "proteus/";

            let result;
            const url = Utils.adjustUrlToServerContext(additionalUrlParam + `restful/planningServiceEndpoint/planningData`);
            const response = await fetch(url!, {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            });
            if (response.status === 200) {
                result = await response.json();
            } else if (response.status === 500) {
                let error = await response.json();
                Utils.showGlobalAlert({ message: error.message, title: _msg("general.error"), severity: Severity.ERROR });
            }

            this.getDispatchers().setInReduxState({
                ganttData: result as Node[]
            });
            var calendarEvents: CalendarEvent[] = await this.loadPublicHolidaysCalendar(fromDate, untilDate);
            await this.mapGanttData(fromDate, untilDate, result as Node[], metadataProvider, calendarEvents);
        },

        async loadPublicHolidaysCalendar(fromDate: Date, tillDate: Date) {
            const result = (await apolloClient.query<getCalendarEvents, getCalendarEventsVariables>({
                query: CALENDAR_SERVICE_GET_CALENDAR_EVENTS,
                variables: {
                    fromDate: fromDate, tillDate: tillDate, calendarDefinitionCode: PUBLIC_HOLIDAY_CODE
                }
            })).data.calendarService_calendarEvents;
            this.getDispatchers().setInReduxState({
                calendarEvents: result as CalendarEvent[]
            });
            return result as CalendarEvent[];
        },

        mapGanttData(fromDate: Date, untilDate: Date, ganttData: Node[], metadataProvider: loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider,
            calendarEvents: CalendarEvent[]) {
            const allowViewRegistrationDetails = AppMetaTempGlobals.appMetaInstance.hasPermission(ProteusConstants.PLANNING_VIEW_DETAIL_PREFIX + this.getState().teamCode + ProteusConstants.PLANNING_VIEW_DETAIL_SUFFIX);
            let itemsFullyOverlapping = {} as Record<string, any[]>;
            const adapter = new PlanningGanttAdapter(metadataProvider, this.getState().simpleTreeStructure, allowViewRegistrationDetails, itemsFullyOverlapping);

            let highlightedIntervals: JSX.Element[] = [];
            // create HighlightedInterval for each calendar event (holiday)
            calendarEvents.map(calendarEvent => {
                highlightedIntervals.push(<HighlightedInterval start={calendarEvent.fromDate} end={calendarEvent.tillDate} className="PlanningGantt_highlightedInterval" />);
            });

            var visitor = new Visitor(adapter, fromDate, untilDate, this.getState().simpleTreeStructure);
            var ganttItems = [] as CustomItem[];
            var ganttGroups = [] as Group[];
            var ganttRowLayers = [] as Layer[];

            if (ganttData) {
                ganttData.map(ganttItem => {
                    var groups = [] as Group[];
                    var items = [] as CustomItem[];
                    var rowLayers = [] as Layer[];
                    visitor.visit(ganttItem, null, groups, items, rowLayers);

                    ganttItems = ganttItems.concat(items);
                    ganttGroups = ganttGroups.concat(groups);
                    ganttRowLayers = ganttRowLayers.concat(rowLayers);
                });
            }
            // TODO #34083: this is a temp fix in order to see the last row in gantt. This should be removed when the issue is fixed.
            // workaround: add an extra empty row
            ganttGroups.push({
                id: ganttGroups.length,
                source: {},
                title: '',
                icon: '',
                children: []
            });
            this.getDispatchers().setInReduxState({
                items: ganttItems,
                groups: ganttGroups,
                rowLayers: ganttRowLayers,
                highlightedIntervals: highlightedIntervals,
                itemsFullyOverlapping
            });
        }
    }
})

type PropsNotFromState = {
    metadataProvider: loadMetadataProvider_registrationMetadataServiceFacadeBean_metadataProvider;
    onItemDoubleClick: (item: CustomItem, extraItems?: CustomItem[]) => void;
    onGroupRowClick: (group: any) => void;
    topResolution?: string;
    bottomResolution?: string;
    dragToCreateMode?: boolean;
    cancelDrag?: boolean;
    onDragToCreateStarted?: (param: DragToCreateParam) => void;
    onDragToCreateEnded?: (param: DragToCreateParam) => void;
    refresh?: () => void;
    updateRegistrationDates?: (segment: CustomItem, fromDateChange: number, toDateChange: number) => void;
    isRegistrationChangeAllowed?: (resource: number | string | null) => boolean;
}

export class PlanningGantt extends React.Component<PropsFrom<typeof slicePlanningGantt> & PropsNotFromState> {
    switchRegistrationEditorRef = React.createRef<SwitchRegistrationEditorRaw>();
    timelineRef = React.createRef<TimelineExt>();

    constructor(props: any) {
        super(props);
        this.displayItemOnSeparateRowOnlyForSelectedRow = this.displayItemOnSeparateRowOnlyForSelectedRow.bind(this);
    }

    componentDidMount() {
        if (TestUtils.storybookMode) {
            this.props.dispatchers.mapGanttData(this.props.startDate, this.props.endDate, this.props.ganttData,
                this.props.metadataProvider, this.props.calendarEvents);
        }
    }

    componentDidUpdate(prevProps: Readonly<PropsFrom<typeof slicePlanningGantt> & PropsNotFromState>) {
        if (this.props.cancelDrag != undefined && prevProps.cancelDrag !== this.props.cancelDrag) {
            this.timelineRef.current?.setState({ dragCancel: this.props.cancelDrag });
        }
        if (this.props.shouldUpdate) {
            this.props.dispatchers.setInReduxState({ shouldUpdate: false });
        }
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        // on dragend the timeline set dragCancel to false, need change on each update of component
        this.timelineRef.current?.setState({ dragCancel: this.props.cancelDrag });
        if (this.props.shouldUpdate ||
            this.props.bottomResolution !== nextProps.bottomResolution ||
            this.props.topResolution !== nextProps.topResolution ||
            !_.isEqual(this.props.startDate, nextProps.startDate) ||
            !_.isEqual(this.props.endDate, nextProps.endDate) ||
            !_.isEqual(this.props.registrationTypesAndStatus, nextProps.registrationTypesAndStatus) ||
            !_.isEqual(this.props.groups, nextProps.groups) ||
            !_.isEqual(this.props.items, nextProps.items) ||
            !_.isEqual(this.props.rowLayers, nextProps.rowLayers) ||
            !_.isEqual(this.props.highlightedIntervals, nextProps.highlightedIntervals) ||
            !_.isEqual(this.props.dragToCreateMode, nextProps.dragToCreateMode) ||
            !_.isEqual(this.props.cancelDrag, nextProps.cancelDrag) ||
            !_.isEqual(this.props.infoModal.open, nextProps.infoModal.open) ||
            !_.isEqual(this.props.infoModal.message, nextProps.infoModal.message)) {
            return true;
        }
        return false;
    }

    handleItemDoubleClick = (e: any, key: any) => {
        const clickedItems = this.props.items.filter((item: CustomItem) => item.key === key);
        if (clickedItems === undefined || clickedItems === null || clickedItems.length <= 0) {
            return;
        }
        const selectedItem = clickedItems[0];
        let extraItems;
        if (selectedItem.node.source.objectType === PLANNING_SHIP_ITEM) {
            const selectedDate = format(new Date(selectedItem.start), 'DD/MM/YYYY');
            extraItems = this.props.items.filter((item: CustomItem) => selectedDate === format(new Date(item.start), 'DD/MM/YYYY') && selectedItem.row === item.row);
        }
        this.props.onItemDoubleClick(selectedItem, extraItems);
    };

    resetInfoModal() {
        this.props.dispatchers.setInReduxState({
            infoModal: { open: false, message: '', title: '', severity: undefined, registrationIds: [], operation: OPERATION.NONE, retry: false },
            validationExceptionsForWaitingRegistrations: false
        });
    }

    getMutationContext() {
        return {
            [ApolloContext.ON_ERROR_HANDLER]: ProteusUtils.getApolloErrorHandlerForValidationException(ProteusConstants.PLANNING_IGNORE_VALIDATION_ERRORS, (e: CatchedGraphQLError) => {
                this.props.dispatchers.setInReduxState({
                    infoModal: {
                        ...this.props.infoModal,
                        message: e.graphQLErrors?.[0]?.extensions?.ORIGINAL_EXCEPTION_MESSAGE,
                        retry: true
                    },
                    validationExceptionsForWaitingRegistrations: apolloGetExtensionFromError(e, ProteusGraphQLErrorExtensions.VALIDATION_EXCEPTIONS_FOR_WAITING_REGISTRATIONS)
                });
            })
        }
    }

    getRegistrationContext(retry?: boolean) {
        return {
            errorPolicy: {
                policy: retry ? ProteusConstants.IGNORE_WARNINGS : ProteusConstants.ENFORCE_ALL,
            },
            metadataContext: ProteusConstants.PLANNING,
            processingWaitingRegistrations: false,
            removeWaiting: false,
            split: false,
            trainingRegistrationUpdated: false,
            updatedFromSessions: false,
            useDefaultMetadataRounding: false,
            validationExceptionsForWaitingRegistrations: retry ? this.props.validationExceptionsForWaitingRegistrations : false,
            checkAllowChangeForOtherEmployee: true
        }
    }

    async doOperation(mutation: any, variables: any) {
        await apolloClient.mutate({ mutation, variables, context: this.getMutationContext() });
        this.resetInfoModal();
        this.props.refresh?.();
    }

    async removeRegistrations(registrations: number[], retry?: boolean) {
        await this.doOperation(PLANNING_SERVICE_FACADE_BEAN_REMOVE_REGISTRATIONS_BY_ID, { context: this.getRegistrationContext(retry), registrations: registrations });
    }

    async mergeRegistrations(registrations: number[], retry?: boolean) {
        await this.doOperation(REGISTRATION_SERVICE_FACADE_BEAN_MERGE_REGISTRATIONS_BY_ID, { context: this.getRegistrationContext(retry), registrationIds: registrations });
    }

    async splitRegistrations(registrations: number[], retry?: boolean) {
        await this.doOperation(REGISTRATION_SERVICE_FACADE_BEAN_MAP_REGISTRATIONS_BY_ID, { context: { ...this.getRegistrationContext(retry), useDefaultMetadataRounding: true }, registrationIds: registrations });
    }

    hasOperations(item: CustomItem | undefined) {
        return item && item.node.source.objectType !== CONSTRAINT_SEGMENT_ITEM && item.node.source.objectType !== PLANNING_SHIP_ITEM;
    }

    isDeleteVisible(param: IActionParam) {
        if (param.selection.length > 0) {
            for (let key of param.selection) {
                const item = this.props.items.find(item => item.key === key);
                if (!this.hasOperations(item)) {
                    return false;
                }
                if (item && item.node.source.objectType === REGISTRATION_SEGMENT_ITEM && item.node.source.status.code === ProteusConstants.REMOVED_STATUS_CODE) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    deleteRegistrations(param: IGanttActionParamForRun) {
        const registrationIds = [];
        let removeInstructorRegistration = false;

        for (let key of param.selection) {
            const item = this.props.items.find(item => item.key === key);
            const registrationSnapshot: RegistrationSnapshot = item!.node.source as RegistrationSnapshot;
            if (MetadataProviderHelper.isOperationAllowed(ProteusConstants.DELETE_ROLE, registrationSnapshot.type! as RegistrationTypeFromApollo, registrationSnapshot.status as RegistrationStatusFromApollo, ProteusConstants.PLANNING)) {
                registrationIds.push(registrationSnapshot.id);
                if (registrationSnapshot.type.code === ProteusConstants.INSTRUCTOR_REGISTRATION_TYPE) {
                    removeInstructorRegistration = true;
                }
            }
        }

        if (registrationIds.length === 0) {
            Utils.showGlobalAlert({ message: _msg("PlanningGantt.contextMenu.delete.noneAllowed.label"), title: _msg("general.delete"), severity: Severity.ERROR });
            return;
        }
        let message = "";
        let severity = undefined;

        if (removeInstructorRegistration) {
            message += _msg("PlanningErrorMessage.warningRemovingInstructorRegistration.label");
        }
        if (registrationIds.length < param.selection.length) {
            message += _msg("PlanningGantt.contextMenu.delete.someAllowed.label");
            severity = Severity.WARNING;
        } else {
            message += _msg("PlanningGantt.contextMenu.delete.allAllowed.label");
            severity = Severity.INFO;
        }
        this.props.dispatchers.setInReduxState({ infoModal: { open: true, message, title: _msg("general.delete"), severity, registrationIds, operation: OPERATION.DELETE, retry: false } })
    }

    isMergeVisible(param: IActionParam) {
        if (param.selection.length > 1) {
            let type = null;
            let parent = null;
            for (let key of param.selection) {
                const item = this.props.items.find(item => item.key === key);
                if (!this.hasOperations(item)) {
                    return false;
                }
                if (parent === null) {
                    parent = item!.node.parent;
                    type = item!.node.source.type.id;
                } else if (item!.parent != parent) {
                    return false;
                } else if (item!.node.source.type.id != type) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    doMergeRegistrations(param: IGanttActionParamForRun) {
        const registrationIds = param.selection.map(key => {
            const item = this.props.items.find(item => item.key === key);
            const registrationSnapshot: RegistrationSnapshot = item!.node.source as RegistrationSnapshot;
            return registrationSnapshot.id
        })
        for (let key of param.selection) {
            const item = this.props.items.find(item => item.key === key);
            const registration: RegistrationSnapshot = item!.node.source as RegistrationSnapshot;
            if (!this.isSplitable(registration)) {
                return;
            }
        }
        this.props.dispatchers.setInReduxState({ infoModal: { open: true, message: _msg("PlanningGantt.contextMenu.merge.validation.label"), title: _msg("PlanningGantt.contextMenu.merge.label"), severity: Severity.INFO, registrationIds: registrationIds, operation: OPERATION.MERGE, retry: false } })
    }

    isSplitable(registration: RegistrationSnapshot) {
        if (registration.status.code !== ProteusConstants.EFFECTIEF && registration.status.code !== ProteusConstants.CODE_WAITING) {
            Utils.showGlobalAlert({ message: _msg("PlanningGantt.contextMenu.merge.statusError.label"), title: _msg("PlanningGantt.contextMenu.merge.label"), severity: Severity.WARNING });
            return false;
        }
        if (!MetadataProviderHelper.isOperationAllowed(ProteusConstants.UPDATE_ROLE, registration.type! as RegistrationTypeFromApollo, registration.status as RegistrationStatusFromApollo, ProteusConstants.PLANNING)) {
            Utils.showGlobalAlert({ message: _msg("PlanningGantt.contextMenu.merge.notAllowed.label", registration.type.code), title: _msg("PlanningGantt.contextMenu.merge.label"), severity: Severity.WARNING });
            return false;
        }
        return true;
    }

    isSplitVisible(param: IActionParam) {
        if (param.selection.length == 1) {
            const item = this.props.items.find(item => item.key === param.selection[0]);
            if (!this.hasOperations(item)) {
                return false;
            }
            return true;
        }
        return false;
    }

    doSplitRegistrations(param: IGanttActionParamForRun) {
        const registrationIds = param.selection.map(key => {
            const item = this.props.items.find(item => item.key === key);
            const registrationSnapshot: RegistrationSnapshot = item!.node.source as RegistrationSnapshot;
            return registrationSnapshot.id
        })
        for (let key of param.selection) {
            const item = this.props.items.find(item => item.key === key);
            const registration: RegistrationSnapshot = item!.node.source as RegistrationSnapshot;
            if (!this.isSplitable(registration)) {
                return;
            }
        }
        this.props.dispatchers.setInReduxState({ infoModal: { open: true, message: _msg("PlanningGantt.contextMenu.split.validation.label"), title: _msg("RegistrationEditor.context.split.label"), severity: Severity.INFO, registrationIds: registrationIds, operation: OPERATION.SPLIT, retry: false } })
    }

    isExchangeVisible(param: IActionParam) {
        if (param.selection.length !== 1) {
            return false;
        }
        const item = this.props.items.find(item => item.key === param.selection[0]);
        if (!this.hasOperations(item)) {
            return false;
        }
        const registration: RegistrationSnapshot = item?.node.source as RegistrationSnapshot;
        return registration.type.code === ProteusConstants.LEAVE_TYPE_CODE || registration.type.code === ProteusConstants.PARENTAL_LEAVE_TYPE_CODE || registration.type.code === ProteusConstants.TIME_CREDIT_TYPE_CODE;
    }

    async openExchangeEditor(param: IGanttActionParamForRun) {
        const item = this.props.items.find(item => item.key === param.selection[0]);
        const registration: RegistrationSnapshot = item?.node.source as RegistrationSnapshot;
        const shifts = this.props.rowLayers.filter(row => row.rowNumber == item?.row);
        let overlappingShifts = 0;
        for (let shift of shifts) {
            if (new Date(registration.fromDate).getTime() < new Date(shift.end).getTime() && new Date(shift.start).getTime() < new Date(registration.toDate).getTime()) {
                overlappingShifts++;
            }
        }
        if (overlappingShifts != 1) {
            Utils.showGlobalAlert({ message: _msg("PlanningGantt.contextMenu.switch.notAllowed.label"), title: _msg("PlanningGantt.contextMenu.switch.validation.label"), severity: Severity.ERROR });
            return;
        }
        this.switchRegistrationEditorRef.current?.props.r.open(registration as Registration);
    }

    getContextMenuActions(contextMenuShowParam: IGanttOnContextMenuShowParam) {
        const actions: IGanttAction[] = [
            {
                icon: "delete",
                label: _msg("general.delete"),
                isVisible: param => this.isDeleteVisible(param),
                run: param => this.deleteRegistrations(param)
            },
            {
                icon: 'linkify',
                label: _msg("PlanningGantt.contextMenu.merge.label"),
                isVisible: param => this.isMergeVisible(param),
                run: param => this.doMergeRegistrations(param)
            },
            {
                icon: "cut",
                label: _msg("RegistrationEditor.context.split.label"),
                isVisible: param => this.isSplitVisible(param),
                run: param => this.doSplitRegistrations(param)
            },
            {
                icon: "exchange",
                label: _msg("PlanningGantt.contextMenu.switch.validation.label"),
                isVisible: param => this.isExchangeVisible(param),
                run: param => this.openExchangeEditor(param)
            }
        ];

        return actions;
    }

    showInfoModal() {
        return (
            <ModalExt onClose={() => this.resetInfoModal()}
                open={this.props.infoModal.open} size='small' severity={this.props.infoModal.retry ? Severity.WARNING : this.props.infoModal.severity}>
                <Modal.Header>
                    {this.props.infoModal.title}
                </Modal.Header>
                <Modal.Content>
                    <Interweave content={this.props.infoModal.message} />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.resetInfoModal()}>
                        {_msg("general.cancel")}
                    </Button>
                    <Button primary onClick={async () => {
                        if (this.props.infoModal.operation === OPERATION.DELETE) {
                            this.removeRegistrations(this.props.infoModal.registrationIds, this.props.infoModal.retry);
                        } else if (this.props.infoModal.operation === OPERATION.MERGE) {
                            this.mergeRegistrations(this.props.infoModal.registrationIds, this.props.infoModal.retry);
                        } else if (this.props.infoModal.operation === OPERATION.SPLIT) {
                            this.splitRegistrations(this.props.infoModal.registrationIds, this.props.infoModal.retry);
                        }
                    }}>
                        {_msg("general.ok")}
                    </Button>
                </Modal.Actions>
            </ModalExt>
        );
    }

    displayItemOnSeparateRowOnlyForSelectedRow(item: Item, rowIndex: number) {
        if ((item as CustomItem).type == RendererType.SHIP_WORK_PERIOD) {
            const key = `${(item as CustomItem).node.source.rosterRegistration.type.code}-${item.start}-${item.end}-${rowIndex}`;
            const items = this.props.itemsFullyOverlapping[key];
            if (items && items.length > 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    canUpdateRegistration(segment: CustomItem) {
        const registration = segment?.node?.source;
        if (!registration) {
            return false;
        }
        return this.props.isRegistrationChangeAllowed?.(registration.resource) && MetadataProviderHelper.isOperationAllowed(ProteusConstants.UPDATE_ROLE, registration.type! as RegistrationTypeFromApollo, registration.status as RegistrationStatusFromApollo, ProteusConstants.PLANNING);
    }

    handleInteraction = (type: string, changes: any, items: any[]) => {
        if (this.props.items.filter(item => item.type == RendererType.SHIP_WORK_PERIOD).length > 0) {
            return;
        }
        switch (type) {
            case TimelineExt.changeTypes.resizeStart:
                let resizeItem = this.props.items.filter(item => item.key === items?.[0])[0];
                return this.canUpdateRegistration(resizeItem) ? items : undefined;
            case TimelineExt.changeTypes.dragStart:
                const moveItem = this.props.items.filter(item => item.key === items?.[0])[0];
                return this.canUpdateRegistration(moveItem) ? items : undefined;
            case TimelineExt.changeTypes.dragEnd:
                this.props.updateRegistrationDates && this.canUpdateRegistration(items?.[0]) && this.props.updateRegistrationDates(items?.[0], changes.timeDelta, changes.timeDelta);
                break;
            case TimelineExt.changeTypes.resizeEnd:
                this.props.updateRegistrationDates && this.canUpdateRegistration(items?.[0]) && this.props.updateRegistrationDates(items?.[0], changes.isStartTimeChange ? changes.timeDelta : 0, changes.isStartTimeChange ? 0 : changes.timeDelta);
                break;
        }
    }

    render() {
        return (<><TimelineExt ref={this.timelineRef}
            table={<Table width={300} headerHeight={PLANNING_ITEM_HEIGHT} rowHeight={PLANNING_ITEM_HEIGHT} rowsCount={this.props.groups.length}>
                <Column key={1} columnKey={1} width={300} flexGrow={1}
                    header={<div className={PLANNING_HEADER_TITLE}>{this.props.teamName}</div>}
                    cell={({ rowIndex }) => this.props.groups[rowIndex] && <GroupRenderer {...this.props.groups[rowIndex]} onClick={() => this.props.onGroupRowClick(this.props.groups[rowIndex].source)} />}
                />
            </Table>}
            itemHeight={PLANNING_ITEM_HEIGHT}
            groups={this.props.groups}
            items={_.cloneDeep(this.props.items)}
            rowLayers={this.props.rowLayers}
            startDate={this.props.startDate.valueOf()}
            endDate={this.props.endDate.valueOf()}
            itemRenderer={CustomItemRenderer}
            onInteraction={this.handleInteraction}
            showCursorTime={false}
            timebarFormat={TimebarFormat}
            backgroundLayer={<BackgroundLayer verticalGrid verticalGridStyle={{ borderColor: '#ccc' }}
                nowMarker highlightedIntervals={this.props.highlightedIntervals}
                highlightWeekends highlightWeekendsClassName='PlanningGantt_highlightedWeekend' />}
            onItemDoubleClick={this.handleItemDoubleClick}
            bottomResolution={this.props.bottomResolution}
            topResolution={this.props.topResolution}
            onDragToCreateStarted={this.props.onDragToCreateStarted}
            onDragToCreateEnded={this.props.onDragToCreateEnded}
            onContextMenuShow={(contextMenuShowParam: IGanttOnContextMenuShowParam) => this.getContextMenuActions(contextMenuShowParam)}
            forceDragToCreateMode={this.props.dragToCreateMode}
            displayItemOnSeparateRowIfOverlap={this.displayItemOnSeparateRowOnlyForSelectedRow}
        />
            {this.showInfoModal()}
            <SwitchRegistrationEditor id="SwitchRegistrationEditor" ref={this.switchRegistrationEditorRef} refresh={() => this.props.refresh?.()} />
        </>);
    }
}
