'use strict'

/*@ngInject*/
function RegistrationTypeCtrl($scope, RegistrationTypes, Agenda, RegistrationMetadata, AgendaConstants, ModalSpinner, ErrorModal, $filter) {
	
	var agenda = $scope.agenda;
	$scope.agendaButtonStyle = {'background-color': 'blue'};
	
	ModalSpinner.show('agenda.loading.types');

	if (agenda.onlyWaitingRegistrations) {
		Agenda.getRegistrationTypesWithProperty({}, {
		silent: true,
			params: {
				property: "USE_BALANCE_COUNTER_WAITING_LOGIC",
				value: "true",
				type: "boolean"
			}
		}).then(function(result) {
			agenda.registrationTypeOptions = result.data;
			if (agenda.registrationTypeOptions.length == 0) {
				ModalSpinner.hide();
				ErrorModal.open({
					titleId: ErrorModal.ERROR,
					message: $filter('translate')('agenda.loading.noRegistrationTypes')
				});
			}

			agenda.getDefaultRegistrationType(agenda.registrationTypeOptions);
		});
	} else {
		RegistrationTypes.getAll().then(function(result) {
			agenda.registrationTypeOptions = result.data;

			if (agenda.registrationTypeOptions.length == 0) {
				ModalSpinner.hide();
				ErrorModal.open({
					titleId: ErrorModal.ERROR,
					message: $filter('translate')('agenda.loading.noRegistrationTypes')
				});
			}

			agenda.getDefaultRegistrationType(agenda.registrationTypeOptions);
		});
	}
	agenda.registrationTypesMetadata = {};
	
	
	$scope.registrationTypeChanged = function() {
		// disable buttons while we wait for registration metadata from server
		agenda.registrationMetadata.disabled = true;
		
		if (!agenda.registrationType) {
			// enable buttons
			agenda.registrationMetadata.disabled = false;
			
			// registration type is set => we can try a refresh
			agenda.registrationTypeSet = true;
			
			return;
		}

		// perform request
		agenda.getRegistrationTypeMetadata(agenda.registrationType.code, agenda.registrationMetadata).then(function(result) {
			// we got the registration metadata from server
			agenda.registrationTypesMetadata[result.config.params.registrationType] = result.data;
			
			if (agenda.registrationMetadata.data[AgendaConstants.BACKGROUND_COLOR] == '#000000') {
				$scope.agendaButtonStyle = {
						'background-color': agenda.registrationMetadata.data['BACKGROUND_COLOR'],
						'box-shadow': '1px 1px 4px rgba(255, 255, 255, .4)' // wissel has a black background, so a white shadow is desirable                                           
				};
			} else {
				$scope.agendaButtonStyle = {
						'background-color': agenda.registrationMetadata.data['BACKGROUND_COLOR']                                           
				};
			}
						
			// enable buttons
			agenda.registrationMetadata.disabled = false;
			
			// registration type is set => we can try a refresh
			agenda.registrationTypeSet = true;

			agenda.getLeaveGroups();
		});
	}

	var metadataProperties = [AgendaConstants.LAYOUT_TYPE_CODE, 
	     					 AgendaConstants.BACKGROUND_COLOR, 
	    					 AgendaConstants.TRANSPARENCY_PERCENT,
	    					 AgendaConstants.CREATE_ROLE,
	    					 AgendaConstants.READ_ROLE,
	    					 AgendaConstants.UPDATE_ROLE,
	    					 AgendaConstants.DELETE_ROLE,
	    					 AgendaConstants.ALLOW_MAP_TO_WORKPERIOD,
	    					 AgendaConstants.MAP_TO_WORKPERIOD, 
	    					 AgendaConstants.CONSTRAINED,
	    					 AgendaConstants.Properties.ROUNDING_TYPE_CODE,
	    					 AgendaConstants.MILESTONE].join(';')
	
	agenda.getRegistrationTypeMetadata = function(code, metadata) {
		return RegistrationMetadata.get(metadata, {
			params: {
				registrationType: code,
				context: agenda.useOtherAgendaContext ? AgendaConstants.OTHER_CONTEXT : AgendaConstants.CONTEXT,
				propertyName: metadataProperties
			}
		});
	}
	
	agenda.getDefaultRegistrationType = function(registrationTypes) {
		for (var i = 0; i < registrationTypes.length; i++) {
			var registrationType = registrationTypes[i];
			if (registrationType.code === AgendaConstants.CODE_VC) {
				// selected by default
				agenda.registrationType = registrationType;
			}
		}
		if (!agenda.registrationType) {
			agenda.registrationType = registrationType;
		}

		// notify changed, for some reason it doesn't work automatically
		$scope.registrationTypeChanged();
	}
	
	agenda.onSelectRegistrationType = function(rt) {
		agenda.registrationType = rt;
		$scope.registrationTypeChanged();
	}
	
};

module.exports = RegistrationTypeCtrl;
