import { apolloClient, createSliceFoundation, EntityDescriptor, EntityEditorFormSimple, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { PasswordEditor } from "@crispico/foundation-react/pages/user/PasswordEditor";
import { getAccountByPerson_employeeService_accountByPerson } from "apollo-gen/getAccountByPerson";
import { getEmployee_employeeService_employee } from "apollo-gen/getEmployee";
import { ACCOUNT_SERVICE_SAVE_OR_UPDATE_ACCOUNT_WITH_PASSWORD_CONFIRMATION, EMPLOYEE_SERVICE_GET_ACCOUNT_BY_PERSON, SECURITY_SERVICE_FACADE_BEAN_REMOVE_ACCOUNT } from "graphql/queries";
import { ProteusUtils } from "ProteusUtils";
import React from "react";
import { Button, Form, Icon, InputOnChangeData, Popup } from "semantic-ui-react";
import { HISTORY_FIELD_TYPE } from "./customFieldRenderersEditors";
import { EMPLOYEE_EDITOR } from "./EmployeeEditorBase";

export const ACCOUNT_HISTORY_ITEM = "AccountHistoryItem";
export const CIVIL_STATUS_HISTORY_ITEM = "CivilStatusHistoryItem";
export const SHARE_REGISTER_HISTORY_ITEM = "ShareRegisterHistoryItem";
export const TAX_STATUS_HISTORY = "TaxStatusHistory";

// Fields used for TaxStatusHistory.
export const CHILDREN_IN_MY_RESPONSABILITY = "aantalKinderen";
export const CHILDREN_WITH_DISABILITIES = "aantalMinderValideKinderen";
export const OTHERS_IN_MY_RESPONSABILITY = "aantalAnderen";
export const OTHERS_WITH_DISABILITIES = "aantalMinderValideAnderen";
export const PERSON_WITH_DISABILITY = "werknemerMinderValide";
export const PARTNER_IN_MY_RESPONSABILITY = "echtgenootTenLaste";
export const PARTNER_WITH_DISABILITY = "echtgenootMinderValide";

export const EXTRA_SCRIPTABLE_UI_ID = "EmployeeEditor_ExtraTab";

enum AccountEditorMode {
    REMOVE, EDIT
}

export const sliceExtraTab = createSliceFoundation(class SliceExtraTab {

    initialState = {
        shouldSaveAccount: false as boolean,
        shouldRemoveAccount: false as boolean,
        account: undefined as unknown as getAccountByPerson_employeeService_accountByPerson,
        editPasswordEnabled: false as boolean,
        accountEditorMode: undefined as unknown as AccountEditorMode,
        initialAccount: undefined as unknown as getAccountByPerson_employeeService_accountByPerson
    }

    reducers = {
        ...getBaseReducers<SliceExtraTab>(this)
    }

    impures = {
        ...getBaseImpures<SliceExtraTab>(this),

        async loadAccount(personId: number | undefined | null) {
            let account = undefined;

            if (personId) {
                account = (await apolloClient.query({
                    query: EMPLOYEE_SERVICE_GET_ACCOUNT_BY_PERSON,
                    variables: { personId }
                })).data.employeeService_accountByPerson;
            }
            this.resetPasswordState(account);
            this.getDispatchers().setInReduxState({ initialAccount: account, accountEditorMode: account ? AccountEditorMode.EDIT : AccountEditorMode.REMOVE });
        },

        async saveAndReloadAccount(refPasswordEditor: PasswordEditor | null) {
            let account = this.getState().account;
            if (!account) {
                return;
            }

            // when the password editor is open, retrieve the password from it.
            // otherwise, do not modify the password, use the existing one.
            let passwordForm = refPasswordEditor && this.getState().editPasswordEnabled ?
                refPasswordEditor.formikContext : null;
            account = { ...account, password: passwordForm ? passwordForm.values.newPassword : account.password };
            await apolloClient.mutate({
                mutation: ACCOUNT_SERVICE_SAVE_OR_UPDATE_ACCOUNT_WITH_PASSWORD_CONFIRMATION,
                variables: {
                    confirmationNewPassword: passwordForm ? passwordForm.values.newPasswordConfirmation : null,
                    account: account
                }
            });
            passwordForm?.resetForm();
            await this.loadAccount(account.person);
        },

        async removeAccount() {
            if (this.getState().initialAccount) {
                await apolloClient.mutate({
                    mutation: SECURITY_SERVICE_FACADE_BEAN_REMOVE_ACCOUNT,
                    variables: {
                        account: this.getState().initialAccount
                    }
                });
            }
            this.getDispatchers().setInReduxState({ shouldRemoveAccount: false });
        },

        resetPasswordState(account: getAccountByPerson_employeeService_accountByPerson | undefined) {
            this.getDispatchers().setInReduxState({
                shouldSaveAccount: false,
                shouldRemoveAccount: false,
                editPasswordEnabled: false,
                account
            });
        }
    }
})

type PropsNotFromState = {
    employee: getEmployee_employeeService_employee | undefined | null;
    onOpenTableLight: (fieldDescriptor: FieldDescriptor, entities: any[]) => void;
    refPasswordEditor: React.RefObject<PasswordEditor>;
    isEditable: boolean;
    renderAdditionalFieldsInForm: (additionalFields: JSX.Element) => JSX.Element;
    referenceDate: Date | number;
}

export class ExtraTab extends TabbedPage<PropsFrom<typeof sliceExtraTab> & PropsNotFromState> {

    protected getExtraEntityDescriptor() {
        let descriptor = new EntityDescriptor({ name: EMPLOYEE_EDITOR }, false)
            .addFieldDescriptor({ name: "employee.detail.civilStatusHistory", type: HISTORY_FIELD_TYPE, entityName: CIVIL_STATUS_HISTORY_ITEM, mandatoryFields: ["civilStatus"], icon: "user plus" })
            .addFieldDescriptor({ name: "employee.detail.taxStatusHistory", type: HISTORY_FIELD_TYPE, entityName: TAX_STATUS_HISTORY, mandatoryFields: ["taxStatus"], icon: "money bill alternate" })
            .addFieldDescriptor({ name: "employee.detail.accountHistory", type: HISTORY_FIELD_TYPE, childFields: ["number"], entityName: ACCOUNT_HISTORY_ITEM, mandatoryFields: ["number"], icon: "credit card" })
            .addFieldDescriptor({ name: "employee.detail.shareRegisterHistory", type: HISTORY_FIELD_TYPE, entityName: SHARE_REGISTER_HISTORY_ITEM, mandatoryFields: ["name"], icon: "external share" });

        descriptor.doForFields(["employee.detail.accountHistory", "employee.detail.civilStatusHistory",
            "employee.detail.taxStatusHistory", "employee.detail.shareRegisterHistory"],
            (fieldDescriptor) => {
                fieldDescriptor.referenceDate = this.props.referenceDate;
                fieldDescriptor.onOpenTableLight = this.props.onOpenTableLight;
                // "isDisabled" was used in place of "disabled" because I don't want to disable the whole field, I want to keep the history button always enabled.
                fieldDescriptor.isDisabled = !this.props.isEditable
            });
        return descriptor;
    }

    protected editPasswordChangeHandler = () => {
        // can not deactivate the password editor when new account is added
        if (!this.props.account?.id) {
            return;
        }
        this.props.dispatchers.setInReduxState({ editPasswordEnabled: !this.props.editPasswordEnabled });
    }

    protected lockUnlockAccount = () => {
        this.props.dispatchers.setInReduxState({ account: { ...this.props.account, locked: !this.props.account.locked }, shouldSaveAccount: true });
    }

    protected onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        this.props.dispatchers.setInReduxState({ account: { ...this.props.account, userName: data.value }, shouldSaveAccount: true });
    }

    protected addOrRemoveAccount = (removePressed: boolean) => {
        if (!removePressed) {
            // when add button is pressed and the account does not exist, create a new account and set it into state.
            this.props.dispatchers.setInReduxState({
                account: {
                    creationDate: null,
                    creationUser: null,
                    environment: {
                        id: ProteusUtils.getEnvironmentId()!,
                        company: null,
                        code: null,
                        description: null,
                        objectVersion: 0,
                        creationDate: null,
                        creationUser: null,
                        modificationDate: null,
                        modificationUser: null
                    },
                    failedAttemptsNumber: 0,
                    id: null,
                    isGeneratedPassword: false,
                    lastPasswordInvalidDate: null,
                    lastPasswordUpdateDate: null,
                    locked: false,
                    modificationDate: null,
                    modificationUser: null,
                    objectVersion: null,
                    password: null,
                    passwordHistory: [],
                    person: this.props.employee?.id || null,
                    userName: ""
                },
                editPasswordEnabled: true,
                accountEditorMode: AccountEditorMode.EDIT
            });
        } else if (removePressed) {
            this.props.dispatchers.setInReduxState({
                shouldSaveAccount: false,
                shouldRemoveAccount: true,
                editPasswordEnabled: false,
                account: undefined,
                accountEditorMode: AccountEditorMode.REMOVE
            });
        }
    }

    protected renderAccountForm() {
        return <>
            <Form className="ExtraTab_accountForm">
                {this.props.renderAdditionalFieldsInForm(
                    <>
                        <Form.Input label={_msg("userName.label")} value={this.props.account?.userName || ""} className={!this.props.isEditable ? "disabled" : ""}
                            disabled={this.props.accountEditorMode === AccountEditorMode.REMOVE} onChange={this.onUsernameChange} />
                        <div>
                            <Popup key={1} basic content={this.props.editPasswordEnabled ? _msg("EmployeeEditor.account.hidePasswordForm.label") : _msg("EmployeeEditor.account.showPasswordForm.label")}
                                trigger={
                                    <Button icon onClick={this.editPasswordChangeHandler} disabled={this.props.accountEditorMode === AccountEditorMode.REMOVE || !this.props.isEditable}>
                                        <Icon name={this.props.editPasswordEnabled ? "check square outline" : "square outline"} color="blue" />
                                    </Button>
                                }
                            />
                            <Popup key={2} basic content={this.props.accountEditorMode === AccountEditorMode.REMOVE ? _msg("EmployeeEditor.account.create.label") : _msg("EmployeeEditor.account.remove.label")}
                                trigger={
                                    <Button disabled={!this.props.isEditable} icon onClick={() => this.addOrRemoveAccount(this.props.accountEditorMode === AccountEditorMode.REMOVE ? false : true)}>
                                        <Icon name={this.props.accountEditorMode === AccountEditorMode.REMOVE ? "plus" : "remove"} color={this.props.accountEditorMode === AccountEditorMode.REMOVE ? "green" : "red"} />
                                    </Button>
                                }
                            />
                            <Popup key={3} basic content={this.props.account?.locked ? _msg("EmployeeEditor.account.unlock.label") : _msg("EmployeeEditor.account.lock.label")}
                                trigger={
                                    <Button icon onClick={this.lockUnlockAccount} disabled={this.props.accountEditorMode === AccountEditorMode.REMOVE || !this.props.isEditable}>
                                        <Icon name={this.props.account?.locked ? "lock" : "unlock alternate"} color="blue" />
                                    </Button>
                                }
                            />
                        </div>
                    </>
                )}
            </Form>
            {this.props.editPasswordEnabled && <PasswordEditor conditionsForPassword={{ PASSWORD_MIN_LENGTH: 8 }}
                hasInputForCurrentPassword={false} ref={this.props.refPasswordEditor} className="UserEditorPage_additionalField" />}
        </>
    }

    render() {
        return (
            <div>
                <EntityEditorFormSimple scriptableUiId={EXTRA_SCRIPTABLE_UI_ID} entityDescriptor={this.getExtraEntityDescriptor()}
                    hideButtonBar entity={{ employee: this.props.employee }} />
                {this.renderAccountForm()}
            </div>
        );
    }

    componentWillUnmount() {
        this.props.dispatchers.setInReduxState({ initialAccount: undefined });
        this.props.dispatchers.resetPasswordState(undefined);
    }
}