'use strict';

function Uid() {
	
	var count = 0;

	return {
		next: function() {
			return 'UID' + ++count;
		}
	}
	
}

module.exports = Uid;